import { Timestamp } from 'src/app/firebase';

export interface ISessionAsset {
  id: string;
  _asset_title_: string;
  _session_title_: string;
  assetId: string;
  sessionId: string;
  createdAt: Timestamp;
  createdBy: string;
  updatedAt: Timestamp;
  updatedBy: string;
}
