import { Injectable } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';

import {
  AppointmentDialogComponent,
  DeclineMeetingDialogComponent,
  MaintenanceDialogComponent,
  ConfirmDialogComponent,
  CreateAccountDialogComponent,
  ClarificationDialogComponent,
  EventConsentTypePopUpDialogComponent,
  SusbcriptionDialogComponent,
  SubscriptionExpiredDialogComponent,
} from 'src/app/shared';
import { ICourseLandingPageConfig, IEvent, IUser, IUserEvent } from 'src/app/core/models';
import { WebPageUnlayerEditorComponent } from 'src/app/standalone/shared/components/unlayer-editor';

@Injectable({
  providedIn: 'root',
})
export class OpenDialogService {
  constructor(private dialogService: DialogService) {}

  openMaintenanceDynamicDialog(event: IEvent): DynamicDialogRef {
    return this.dialogService.open(MaintenanceDialogComponent, {
      width: '80vw',
      height: '80vh',
      contentStyle: {
        'flex-grow': 1,
      },
      data: event,
    });
  }

  openAppointmentDialog(user?: IUser, isOpenFromChat?: boolean): DynamicDialogRef {
    return this.dialogService.open(AppointmentDialogComponent, {
      width: '85.625rem',
      height: '46.875rem',
      styleClass: 'appointment-dialog',
      contentStyle: { padding: '0', 'background-color': '#181818' },
      data: { user, isOpenFromChat },
    });
  }

  openDeclineMeetingDialog(): DynamicDialogRef {
    return this.dialogService.open(DeclineMeetingDialogComponent, {
      width: '45rem',
      height: '18.75rem',
      contentStyle: { background: '#121212' },
    });
  }

  openClarificationDialog(
    event?: IEvent,
    eventUsers?: { [eventId: string]: IUserEvent },
    className?: string,
  ): DynamicDialogRef {
    return this.dialogService.open(ClarificationDialogComponent, {
      width: '80vw',
      height: '80vh',
      styleClass: 'clarification-dialog',
      data: { event, eventUsers, className },
    });
  }

  openSubscriptionDialog(): DynamicDialogRef {
    return this.dialogService.open(SusbcriptionDialogComponent, {
      width: '80vw',
      height: '80vh',
      styleClass: 'clarification-dialog',
    });
  }

  openSubscriptionExpiredDialog(): DynamicDialogRef {
    return this.dialogService.open(SubscriptionExpiredDialogComponent, {
      width: '80vw',
      height: '80vh',
      styleClass: 'clarification-dialog',
    });
  }

  openCreateAccountDialog(url: string, isSalesforceSSO?: boolean): DynamicDialogRef {
    return this.dialogService.open(CreateAccountDialogComponent, {
      width: '80vw',
      height: 'min-content',
      styleClass: 'create-account-dialog',
      data: { url, isSalesforceSSO },
    });
  }

  openConfirmDialog(
    titleKey: string,
    descriptionKey: string,
    confirmBtnKey: string,
    cancelBtnKey: string,
  ): DynamicDialogRef {
    return this.dialogService.open(ConfirmDialogComponent, {
      closable: false,
      styleClass: 'confirm-dialog',
      data: { titleKey, descriptionKey, confirmBtnKey, cancelBtnKey },
    });
  }

  openDeleteTicketDialog(
    titleKey: string,
    descriptionKey: string,
    confirmBtnKey: string,
    cancelBtnKey: string,
    hideCancelBtn: boolean,
    hideConfirmBtn?: boolean,
  ): DynamicDialogRef {
    return this.dialogService.open(ConfirmDialogComponent, {
      closable: false,
      styleClass: 'confirm-dialog',
      data: {
        titleKey,
        descriptionKey,
        confirmBtnKey,
        cancelBtnKey,
        hideCancelBtn,
        hideConfirmBtn,
      },
    });
  }

  openEventConsentTypePopUpDialog(
    titleKey: string,
    descriptionKey: string,
    confirmBtnKey: string = 'buttons.confirm',
    cancelBtnKey: string = 'buttons.cancel',
  ): DynamicDialogRef {
    return this.dialogService.open(EventConsentTypePopUpDialogComponent, {
      width: '80vw',
      height: 'min-content',
      styleClass: 'event-consent-type-pop-up-dialog',
      closeOnEscape: false,
      closable: false,
      baseZIndex: 10000,
      data: {
        titleKey,
        descriptionKey,
        confirmBtnKey,
        cancelBtnKey,
      },
    });
  }

  openDisconnectStripeDialog(
    titleKey: string,
    descriptionKey: string,
    confirmBtnKey: string = 'buttons.confirm',
    cancelBtnKey: string = 'buttons.cancel',
  ): DynamicDialogRef {
    return this.dialogService.open(ConfirmDialogComponent, {
      closable: false,
      styleClass: 'confirm-dialog',
      data: { titleKey, descriptionKey, confirmBtnKey, cancelBtnKey },
    });
  }

  openWebPageUnlayerEditorDialog(
    templates: unknown,
    currentTemplate: ICourseLandingPageConfig,
  ): DynamicDialogRef {
    return this.dialogService.open(WebPageUnlayerEditorComponent, {
      closable: false,
      styleClass: 'unlayer-email-editor-dialog',
      data: { templates, currentTemplate },
    });
  }
}
