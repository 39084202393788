import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import {
  IAdminTableColumn,
  SharedModule,
  imgPreloader,
  jpgImg,
  logoAvatar,
  pdfImg,
  pngImg,
  videoImg,
  zipImg,
} from 'src/app/shared';
import { IAsset } from 'src/app/core/models';
import { AssetType, AssetFileTypes } from 'src/app/core/enums';
import { AssetEventBannerComponent } from 'src/app/standalone/shared';

@Component({
  selector: 'app-attach-asset-to-chapter',
  standalone: true,
  imports: [CommonModule, SharedModule, AssetEventBannerComponent],
  templateUrl: './attach-asset-to-chapter.component.html',
  styleUrls: ['./attach-asset-to-chapter.component.scss'],
})
export class AttachAssetToChapterComponent implements OnInit {
  assets: IAsset[] = [];
  selectedEntries: IAsset[] = [];
  imgPreloader = imgPreloader;
  logoAvatar = logoAvatar;
  columns: IAdminTableColumn[] = [
    { field: 'title', template: 'titleCell', titleTranslationKey: 'adminCourseMedia.tableTitle' },
    { field: 'type', titleTranslationKey: 'adminCourseMedia.type' },
    { field: 'isGlobal', template: 'statusCell', titleTranslationKey: 'Global' },
  ];
  assetType = AssetType;
  assetEventBannerContainerStyles = {
    background: '#323232',
    'border-radius': '0.25rem',
  };
  assetEventBannerMonthStyles = {
    'font-size': '0.625rem',
    'line-height': '0.625rem',
  };
  assetEventBannerDateStyle = {
    'font-size': '1.125rem',
    'line-height': '1.125rem',
  };

  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
  ) {}

  ngOnInit(): void {
    this.assets = this.config.data.assets;
  }

  getImagePlaceholder(asset: IAsset): string {
    if (asset.type === AssetType.VIDEO) {
      return videoImg;
    }

    switch (asset.fileType) {
      case AssetFileTypes.PNG:
        return pngImg;
      case AssetFileTypes.JPG:
        return jpgImg;
      case AssetFileTypes.PDF:
        return pdfImg;
      case AssetFileTypes.ZIP:
        return zipImg;
      default:
        return pngImg;
    }
  }

  async attach(): Promise<void> {
    this.ref.close(this.selectedEntries);
  }

  onCloseDialog(): void {
    this.ref.close();
  }
}
