import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ILoader } from './loader.model';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private loader = new BehaviorSubject<ILoader>({ id: '', status: false });

  public loaderStatus$ = this.loader.asObservable();

  public show(id: string = ''): void {
    this.loader.next({ id, status: true });
  }

  public hide(id: string = ''): void {
    this.loader.next({ id, status: false });
  }
}
