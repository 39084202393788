import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { TranslateModule } from '@ngx-translate/core';
import { MobxAngularModule } from 'mobx-angular';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { AccordionModule } from 'primeng/accordion';
import { MessageService } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { CalendarModule } from 'primeng/calendar';
import { CarouselModule } from 'primeng/carousel';
import { CheckboxModule } from 'primeng/checkbox';
import { ColorPickerModule } from 'primeng/colorpicker';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import {
  DialogService,
  DynamicDialogModule,
  DynamicDialogRef,
  DynamicDialogConfig,
} from 'primeng/dynamicdialog';
import { FileUploadModule } from 'primeng/fileupload';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MenubarModule } from 'primeng/menubar';
import { MenuModule } from 'primeng/menu';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PasswordModule } from 'primeng/password';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ButtonModule } from 'primeng/button';
import { SidebarModule } from 'primeng/sidebar';
import { SkeletonModule } from 'primeng/skeleton';
import { StepsModule } from 'primeng/steps';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TimelineModule } from 'primeng/timeline';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { PaginatorModule } from 'primeng/paginator';
import { InputNumberModule } from 'primeng/inputnumber';
import { BadgeModule } from 'primeng/badge';
import { RippleModule } from 'primeng/ripple';
import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { EditorModule } from 'primeng/editor';
import { OrderListModule } from 'primeng/orderlist';
import { PanelModule } from 'primeng/panel';
import { PickListModule } from 'primeng/picklist';
import { DataViewModule } from 'primeng/dataview';
import { ProgressBarModule } from 'primeng/progressbar';
import { ScrollTopModule } from 'primeng/scrolltop';

import {
  FormGroupComponent,
  EmptyStateComponent,
  TagsMultiListComponent,
  PresenceComponent,
  DailyMeetingComponent,
  DefaultCoversComponent,
  ImageComponent,
  CalendlyDialogComponent,
  EventbriteTicketComponent,
  UpdateDefaultCoversComponent,
  InputErrorMessageComponent,
  AdminTableComponent,
  AdminTableHeaderNavbarComponent,
  AdminTableFilterNavbarComponent,
  CalendlyLinkComponent,
  ProductsMultiListComponent,
  BrandProductDetailsComponent,
  EventCardComponent,
  UserLittleCardComponent,
  BrandProductCardComponent,
  AppointmentAcceptedNotificationComponent,
  NotificationBellComponent,
  AppointmentCanceledInviteNotificationComponent,
  AppointmentAcceptedInviteNotificationComponent,
  CountDownComponent,
  AppointmentNewInviteNotificationComponent,
  NotificationsComponent,
  BrandCardComponent,
  BrandCardSkeletonComponent,
  AssetFileCardComponent,
  DefaultBrandComponent,
  DefaultUserComponent,
  EventCardSkeletonComponent,
  UserTicketCardComponent,
  ColorPickerComponent,
  TooltipComponent,
  EventBookmarkButtonComponent,
  AddEventToCalendarButtonComponent,
  InputLabelComponent,
  ExportFieldsSelectorComponent,
} from './components';
import {
  ConfirmDialogComponent,
  ClarificationDialogComponent,
  CreateAccountDialogComponent,
  DeleteDialogComponent,
  EventConsentTypePopUpDialogComponent,
  AppointmentDialogComponent,
  MaintenanceDialogComponent,
  ProgressBarDialogComponent,
  DeclineMeetingDialogComponent,
  ProductDialogComponent,
  WebPushNotificationDialogComponent,
} from './dialogs';
import {
  EPTemplateKeyDirective,
  InputOnlyPhoneNumberValuesDirective,
  InputOnlyPostalCodeNumberValuesDirective,
  DateMaskDirective,
} from './directives';
import {
  TimePipe,
  DatePipe,
  LineBreaksPipe,
  TruncatePipe,
  MinutesSecondsPipe,
  AddSeparatorPipe,
  LinkifyPipe,
} from './pipes';
import { PickListComponent } from './pick-list/pick-list.component';
import { LoaderModule } from '../core/modules/loader/loader.module';
import { ImageSizeModule } from '../core/modules/image-size/image-size.module';
import { ClickOutsideDirective } from '../standalone/shared/directives';
import { SusbcriptionDialogComponent } from './dialogs/susbcription-dialog/susbcription-dialog.component';
import { SubscriptionExpiredDialogComponent } from './dialogs/subscription-expired-dialog/subscription-expired-dialog.component';
import { ToastComponent } from '../standalone/shared/components/toast/toast.component';

const components = [
  FormGroupComponent,
  EmptyStateComponent,
  TagsMultiListComponent,
  PresenceComponent,
  DailyMeetingComponent,
  DefaultCoversComponent,
  ImageComponent,
  CalendlyDialogComponent,
  EventbriteTicketComponent,
  UpdateDefaultCoversComponent,
  InputErrorMessageComponent,
  AdminTableComponent,
  AdminTableHeaderNavbarComponent,
  AdminTableFilterNavbarComponent,
  CalendlyLinkComponent,
  ProductsMultiListComponent,
  BrandProductDetailsComponent,
  EventCardComponent,
  UserLittleCardComponent,
  BrandProductCardComponent,
  AppointmentAcceptedNotificationComponent,
  AppointmentCanceledInviteNotificationComponent,
  AppointmentAcceptedInviteNotificationComponent,
  AppointmentNewInviteNotificationComponent,
  NotificationsComponent,
  BrandCardComponent,
  BrandCardSkeletonComponent,
  AssetFileCardComponent,
  DefaultBrandComponent,
  DefaultUserComponent,
  EventCardSkeletonComponent,
  ColorPickerComponent,
  TooltipComponent,
  EventBookmarkButtonComponent,
  AddEventToCalendarButtonComponent,
  InputLabelComponent,
  PickListComponent,
  UserTicketCardComponent,
  CountDownComponent,
  NotificationBellComponent,
  ExportFieldsSelectorComponent,
];

const dialogComponents = [
  ConfirmDialogComponent,
  ClarificationDialogComponent,
  CreateAccountDialogComponent,
  DeleteDialogComponent,
  EventConsentTypePopUpDialogComponent,
  AppointmentDialogComponent,
  MaintenanceDialogComponent,
  ProgressBarDialogComponent,
  DeclineMeetingDialogComponent,
  ProductDialogComponent,
];

const directives = [
  EPTemplateKeyDirective,
  InputOnlyPhoneNumberValuesDirective,
  InputOnlyPostalCodeNumberValuesDirective,
  DateMaskDirective,
];

const pipes = [
  TimePipe,
  DatePipe,
  LineBreaksPipe,
  TruncatePipe,
  MinutesSecondsPipe,
  AddSeparatorPipe,
  LinkifyPipe,
];

const primeNgModules = [
  ConfirmDialogModule,
  DropdownModule,
  SidebarModule,
  InputTextModule,
  PasswordModule,
  CheckboxModule,
  ToastModule,
  StepsModule,
  InputSwitchModule,
  FileUploadModule,
  TabViewModule,
  TooltipModule,
  DividerModule,
  OverlayPanelModule,
  TimelineModule,
  DialogModule,
  DynamicDialogModule,
  AccordionModule,
  CarouselModule,
  RadioButtonModule,
  ButtonModule,
  TableModule,
  BreadcrumbModule,
  CalendarModule,
  InputTextareaModule,
  MenubarModule,
  MenuModule,
  ColorPickerModule,
  ProgressSpinnerModule,
  SkeletonModule,
  MessagesModule,
  MessageModule,
  MultiSelectModule,
  EditorModule,
  PaginatorModule,
  InputNumberModule,
  RippleModule,
  BadgeModule,
  OrderListModule,
  PanelModule,
  DataViewModule,
  ProgressBarModule,
  ScrollTopModule,
];

const modules = [
  CommonModule,
  FormsModule,
  HttpClientModule,
  ReactiveFormsModule,
  TranslateModule,
  LoaderModule,
  MobxAngularModule,
  PickerModule,
  LazyLoadImageModule,
  PickListModule,
  ImageSizeModule,
];

@NgModule({
  declarations: [
    ...components,
    ...dialogComponents,
    ...directives,
    ...pipes,
    WebPushNotificationDialogComponent,
    SusbcriptionDialogComponent,
    SubscriptionExpiredDialogComponent,
  ],
  imports: [...modules, ...primeNgModules, ClickOutsideDirective, ToastComponent],
  exports: [
    ...components,
    ...dialogComponents,
    ...directives,
    ...pipes,
    ...modules,
    ...primeNgModules,
  ],
  providers: [
    DialogService,
    MessageService,
    ConfirmationService,
    DynamicDialogConfig,
    DynamicDialogRef,
  ],
})
export class SharedModule {}
