<ng-container *ngIf="loading">
  <app-loader class="admin-course-loading-container"></app-loader>
</ng-container>

<div
  *ngIf="!loading"
  class="new-course-container">
  <div class="header c-p-5">
    <div class="title">
      {{ 'adminNewCourse.createCourse' | translate }}
    </div>
    <i
      class="fa-regular fa-xmark close-icon"
      (click)="onCloseDialog()"></i>
  </div>

  <form [formGroup]="form">
    <div class="form-container c-p-5">
      <!-- Title input -->
      <div class="form-field-block">
        <app-input-label
          labelType="required"
          for="title-input"
          text="adminNewCourse.title">
        </app-input-label>
        <input
          class="custom-input-text custom-input-text-required"
          formControlName="title"
          id="title-input"
          pInputText
          type="text"
          placeholder="{{ 'adminNewCourse.titlePlaceholder' | translate }}" />
        <app-input-error-message
          [showMessage]="form.controls.title.invalid && form.controls.title.touched"
          [errorMessage]="titleErrorMessage">
        </app-input-error-message>
      </div>

      <!-- Tagline input -->
      <div class="form-field-block">
        <app-input-label
          for="tagline-input"
          text="adminNewCourse.tagline">
        </app-input-label>
        <input
          class="custom-input-text custom-input-text-required"
          formControlName="tagline"
          id="tagline-input"
          pInputText
          type="text"
          placeholder="{{ 'adminNewCourse.taglinePlaceholder' | translate }}" />
        <app-input-error-message
          [showMessage]="form.controls.tagline.invalid && form.controls.tagline.touched"
          errorMessage="{{ 'application.forms.msxLengthErrorText' | translate }}">
        </app-input-error-message>
      </div>

      <!-- Accounting number input -->
      <div class="form-field-block">
        <app-input-label
          labelType="tooltip"
          for="accounting-number-input"
          text="adminNewCourse.accountingNumber"
          tooltipText="adminNewCourse.accountingNumberTooltip"
          [tooltipEvent]="isMobile ? 'focus' : 'hover'"
          [tabIndex]="isMobile ? 0 : null">
        </app-input-label>
        <input
          class="custom-input-text custom-input-text-required"
          formControlName="accountingNumber"
          id="accounting-number-input"
          pInputText
          type="text"
          placeholder="{{ 'adminNewCourse.accountingNumberPlaceholder' | translate }}" />
        <app-input-error-message
          [showMessage]="
            form.controls.accountingNumber.invalid && form.controls.accountingNumber.touched
          "
          errorMessage="{{ 'application.forms.required' | translate }}">
        </app-input-error-message>
      </div>

      <!-- Currency input -->
      <div class="form-field-block">
        <app-input-label
          labelType="required"
          for="currency-input"
          text="adminNewCourse.currency">
        </app-input-label>
        <p-dropdown
          class="custom-dropdown custom-dropdown-required"
          panelStyleClass="custom-dropdown-panel"
          [options]="currencies"
          formControlName="currency"
          inputId="currency-input"
          appendTo="body"
          placeholder="{{ 'adminNewCourse.currency' | translate }}">
        </p-dropdown>
        <app-input-error-message
          [showMessage]="form.controls.currency.invalid && form.controls.currency.touched"
          errorMessage="{{ 'application.forms.required' | translate }}">
        </app-input-error-message>
      </div>

      <!-- Tags input -->
      <div class="form-field-block c-mb-4">
        <app-input-label
          for="tags-input"
          text="adminNewCourse.tags">
        </app-input-label>
        <p-multiSelect
          class="custom-multi-select"
          panelStyleClass="custom-multi-select-panel-style-class"
          [options]="possibleTags"
          [virtualScroll]="true"
          [virtualScrollItemSize]="virtualScrollItemSize"
          appendTo="body"
          optionLabel="title"
          optionValue="id"
          formControlName="tags"
          inputId="tags-input"
          placeholder="{{ 'adminNewCourse.tagsPlaceholder' | translate }}">
        </p-multiSelect>
      </div>
    </div>
  </form>

  <div class="footer c-p-2 c-pl-5">
    <div class="col text-left form-field-input-checkbox-block c-p-0">
      <p-checkbox
        class="custom-input-checkbox-sm"
        binary="true"
        [formControl]="createAnotherControl"
        inputId="another-course-input">
      </p-checkbox>
      <app-input-label
        labelType="switch-checkbox"
        for="another-course-input"
        text="adminNewCourse.createAnother">
      </app-input-label>
    </div>
    <div class="col text-right flex justify-content-end align-items-center">
      <app-button
        buttonText="{{ 'adminNewCourse.cancelButton' | translate }}"
        buttonIconLeft="fa-regular fa-xmark"
        [buttonStyle]="buttonStyle.GHOST_PRIMARY"
        [buttonSize]="buttonSize.MEDIUM"
        (click)="onCloseDialog()" />
      <app-button
        class="c-ml-2"
        buttonText="{{ 'adminNewCourse.createBtn' | translate }}"
        buttonIconLeft="far fa-check"
        [buttonSize]="buttonSize.MEDIUM"
        [loading]="isUpdating"
        [disabled]="!canUpdate"
        (click)="create()" />
    </div>
  </div>
</div>
