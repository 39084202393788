import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { getUserNameAbbreviation } from 'src/app/core/utils';
import { SharedModule } from 'src/app/shared';
import { ICourse, IEvent, IHub, IUser } from 'src/app/core/models';
import { CoursesService, UsersService } from 'src/app/core/services';
import { HubsStore } from 'src/app/core/stores';
import { SidebarUserInfoComponent } from '../sidebar-user-info/sidebar-user-info.component';
import { SidebarUserInfoEventsComponent } from '../sidebar-user-info-events/sidebar-user-info-events.component';
import { SidebarUserInfoCoursesComponent } from '../sidebar-user-info-courses/sidebar-user-info-courses.component';

@Component({
  selector: 'app-sidebar-user-info-container',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    SharedModule,
    SidebarUserInfoComponent,
    SidebarUserInfoEventsComponent,
    SidebarUserInfoCoursesComponent,
  ],
  templateUrl: './sidebar-user-info-container.component.html',
  styleUrls: ['./sidebar-user-info-container.component.scss'],
})
export class SidebarUserInfoContainerComponent implements OnChanges {
  @Input() userId: string;
  @Output() closeSidebar = new EventEmitter<void>();

  user: IUser;
  userEvents: IEvent[];
  userCourses: ICourse[];
  selectedTab = 1;
  imageText = getUserNameAbbreviation;

  constructor(
    private usersService: UsersService,
    private coursesService: CoursesService,
    private hubsStore: HubsStore,
  ) {}

  get hub(): IHub {
    return this.hubsStore.hub;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.userId && changes.userId.previousValue !== changes.userId.currentValue) {
      this.getUser();
      this.getUserEvents();
      this.getUserCourses();
    }
  }

  onClose(): void {
    this.closeSidebar.emit();
  }

  private async getUser(): Promise<void> {
    this.user = await this.usersService.getOne(this.userId);
  }

  private async getUserEvents(): Promise<void> {
    this.userEvents = await this.usersService.getUserAllEvents(this.userId, this.hub.id);
  }

  private async getUserCourses(): Promise<void> {
    this.userCourses = await this.coursesService.getAllCoursesByUser(this.userId, this.hub.id);
  }
}
