import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { IAdminTableColumn } from 'src/app/shared';
import { IPrivacySettingsPage } from 'src/app/core/models/system/privacy-settings-page.model';
import { SettingsService } from 'src/app/core/services';
import { ButtonSize, ButtonType, ButtonStyle } from 'src/app/standalone';

@Component({
  selector: 'app-system-pages',
  templateUrl: './system-pages.component.html',
  styleUrls: ['./system-pages.component.scss'],
})
export class SystemPagesComponent implements OnInit {
  loading = true;
  loadingEntries = true;
  searchValue = '';
  searchFilterFields: string[] = ['title', 'language'];
  columns: IAdminTableColumn[] = [
    { field: 'title', titleTranslationKey: 'adminEventPagesList.tableColumnTitle', sortable: true },
    {
      field: 'status',
      titleTranslationKey: 'adminEventPagesList.tableColumnStatus',
      template: 'statusCell',
      sortable: true,
    },
    { field: 'actions', template: 'actionsCell' },
  ];
  entries: IPrivacySettingsPage[] = [];
  scssClassForTable = 'table-border-top-radius';
  buttonSize = ButtonSize;
  buttonType = ButtonType;
  buttonStyle = ButtonStyle;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private settingsService: SettingsService,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.fetchPages();
    this.loadingEntries = false;
    this.loading = false;
  }

  edit(event: Event, entry: IPrivacySettingsPage): void {
    event.stopPropagation();
    this.router.navigate([entry.id], {
      relativeTo: this.route,
      state: {
        entry: entry,
      },
    });
  }

  private async fetchPages(): Promise<void> {
    this.entries = await (
      await this.settingsService.getAllPages()
    ).map((e) => {
      return e;
    });
  }
}
