import * as moment from 'moment';
import { Injectable } from '@angular/core';

import { UsersService, AlgoliaService, StorageService } from 'src/app/core/services';
import { AppStore } from 'src/app/app.store';
import { IStage, AlgoliaSearchResult, IBrand, ISession, IUser } from 'src/app/core/models';
import { BrandsStore, StagesStore, SpeakersStore, UsersStore } from 'src/app/core/stores';
import { CollectionTypes, parseToMoment, API_ROUTES as apiRoutes } from 'src/app/shared';
import { ArrayRemove, Firestore, IFirestore, Timestamp } from 'src/app/firebase';

@Injectable({
  providedIn: 'root',
})
export class StagesService {
  private firestore: IFirestore;

  constructor(
    private stagesStore: StagesStore,
    private storageService: StorageService,
    private speakersStore: SpeakersStore,
    private brandsStore: BrandsStore,
    private algoliaService: AlgoliaService,
    private usersService: UsersService,
    public appStore: AppStore,
    private usersStore: UsersStore,
  ) {
    this.firestore = Firestore();
  }

  async getAll(eventId: string): Promise<IStage[]> {
    let stages: IStage[] = [];
    try {
      const stagesQuery = await await this.firestore
        .collection(`${CollectionTypes.EVENTS}/${eventId}/${CollectionTypes.STAGES}`)
        .orderBy('order')
        .get();

      if (!stagesQuery.empty) {
        stages = await Promise.all(
          stagesQuery.docs.map(async (doc) => {
            const stage = doc.data() as IStage;
            return stage;
          }),
        );
      }

      return stages;
    } catch (error) {
      console.warn(error);
      return stages;
    }
  }

  async fetch(
    eventId: string = null,
    pageIndex: number,
    pageSize: number,
    searchTerm: string,
  ): Promise<AlgoliaSearchResult<IStage>> {
    return await this.algoliaService.search<IStage>(
      'eventStages',
      [eventId, searchTerm].join(' '),
      pageSize,
      pageIndex * pageSize,
    );
  }

  async getStatus(eventId: string, stageId: string) {
    let firstSession: ISession = null;

    const startOfDay = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate();
    const endOfDay = moment().set({ hour: 23, minute: 59, second: 59, millisecond: 0 }).toDate();

    const query = await this.firestore
      .collection(`${CollectionTypes.EVENTS}/${eventId}/${CollectionTypes.SESSIONS}`)
      .where('stageId', '==', stageId)
      .where('start', '>=', startOfDay)
      .where('start', '<=', endOfDay)
      .orderBy('start')
      .limit(1)
      .get();

    if (!query.empty) {
      firstSession = query.docs[0].data() as ISession;
      const start = parseToMoment(firstSession.start);
      const sessionTime = moment().set({
        hour: start.hour(),
        minute: start.minute(),
        second: 0,
        millisecond: 0,
      });
      return moment().isSameOrAfter(sessionTime);
    } else {
      return false;
    }
  }

  async getStageSessionsAndSpeakersAndBrands(
    eventId: string,
    stageId: string,
    day: Date,
  ): Promise<{ sessions: ISession[]; speakers: IUser[]; brands: IBrand[] }> {
    const speakerIds = [];
    const brandsIds = [];
    const brands = [];
    const speakers = [];
    const sessions: ISession[] = [];

    const startOfDay = day;
    const endOfDay = moment(day).set({ hour: 23, minute: 59, second: 59, millisecond: 0 }).toDate();

    const sessionsQuery = await this.firestore
      .collection(`${CollectionTypes.EVENTS}/${eventId}/${CollectionTypes.SESSIONS}`)
      .where('stageId', '==', stageId)
      .where('start', '>=', startOfDay)
      .where('start', '<=', endOfDay)
      .orderBy('start')
      .get();

    if (!sessionsQuery.empty) {
      sessionsQuery.forEach((doc) => {
        const data = doc.data();
        sessions.push(data as ISession);

        (data.speakers || []).forEach((speakerId) => {
          if (!speakerIds.includes(speakerId)) {
            speakerIds.push(speakerId);
          }
        });

        (data.brands || []).forEach((brandId) => {
          if (!brandsIds.includes(brandId)) {
            brandsIds.push(brandId);
          }
        });
      });
    }

    let querySpeakersByIds = null;

    while (speakerIds.length > 0) {
      const chunk = speakerIds.splice(0, 10);

      querySpeakersByIds = await this.firestore
        .collection(CollectionTypes.USERS)
        .where('id', 'in', chunk)
        .get();

      if (!querySpeakersByIds.empty) {
        querySpeakersByIds.forEach((doc) => {
          let speaker = doc.data() as IUser;

          if (this.appStore.generalSystemSettings.enableEncryption) {
            speaker = this.usersService.decryptUserData(speaker) as IUser;
            console.log('↑ getStageSessionsAndSpeakersAndBrands decrypted');
          }

          this.speakersStore.setSpeakers([speaker]);
          speakers.push(speaker);
        });
      }
    }

    let queryBrandsByIds = null;

    while (brandsIds.length > 0) {
      const chunk = brandsIds.splice(0, 10);

      queryBrandsByIds = await this.firestore
        .collection(CollectionTypes.BRANDS)
        .where('id', 'in', chunk)
        .get();

      if (!queryBrandsByIds.empty) {
        queryBrandsByIds.forEach((doc) => {
          const brand = doc.data() as IBrand;
          this.brandsStore.setBrands([brand]);
          brands.push(brand);
        });
      }
    }

    sessions.sort((a, b) => a.start.seconds - b.start.seconds);

    return {
      sessions,
      speakers,
      brands,
    };
  }

  async getOne(eventId: string, stageId: string, forceUpdate: boolean = false): Promise<IStage> {
    if (!forceUpdate && Object.keys(this.stagesStore.stageMap).includes(stageId)) {
      return this.stagesStore.stageMap[stageId];
    }

    try {
      const stage = (
        await this.firestore
          .doc(`${CollectionTypes.EVENTS}/${eventId}/${CollectionTypes.STAGES}/${stageId}`)
          .get()
      ).data() as IStage;

      this.stagesStore.setStage(stage);
      return stage;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async getEventStages(eventId: string, forceUpdate: boolean = false): Promise<IStage[]> {
    if (!forceUpdate && this.stagesStore.stages.length > 0) {
      return this.stagesStore.stages;
    }

    try {
      const stages = (
        await this.firestore
          .collection(`${CollectionTypes.EVENTS}/${eventId}/${CollectionTypes.STAGES}`)
          .get()
      ).docs.map((doc) => doc.data() as IStage);

      this.stagesStore.setStages(stages);
      return stages;
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  async getAllEventStages(eventId: string): Promise<IStage[]> {
    try {
      const stages = (
        await this.firestore
          .collection(`${CollectionTypes.EVENTS}/${eventId}/${CollectionTypes.STAGES}`)
          .get()
      ).docs.map((doc) => doc.data() as IStage);

      return stages;
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  async create(eventId: string, stage: any): Promise<IStage> {
    try {
      const preStageReq = await this.firestore
        .collection(`${CollectionTypes.EVENTS}/${eventId}/${CollectionTypes.STAGES}`)
        .doc();
      stage.id = preStageReq.id;

      const stageDocument = this.firestore
        .collection(`${CollectionTypes.EVENTS}/${eventId}/${CollectionTypes.STAGES}`)
        .doc(stage.id);

      if (
        stage.videoThumbnail instanceof File ||
        stage.banner instanceof File ||
        stage.ivsPreviewImage instanceof File
      ) {
        const stageDocData = await stageDocument.get();

        if (stage.videoThumbnail instanceof File) {
          const currentLogoImage = stageDocData.get('logo');

          if (currentLogoImage) {
            await this.storageService.delete(currentLogoImage);
          }

          if (stage.videoThumbnail !== null) {
            const fileName = `file_${new Date().getTime()}`;
            const newLogoImage = await this.storageService.upload(
              stage.videoThumbnail,
              'stages',
              fileName,
            );
            stage.videoThumbnail = newLogoImage;
          } else {
            stage.videoThumbnail = null;
          }
        }

        if (stage.banner instanceof File) {
          const currentBannerImage = stageDocData.get('banner');

          if (currentBannerImage) {
            await this.storageService.delete(currentBannerImage);
          }

          if (stage.banner !== null) {
            const fileName = `file_${new Date().getTime()}`;
            const newCoverImage = await this.storageService.upload(
              stage.banner,
              'stages',
              fileName,
            );
            stage.banner = newCoverImage;
          } else {
            stage.banner = null;
          }
        }

        if (stage.ivsPreviewImage instanceof File) {
          const currentIvsPreviewImage = stageDocData.get('ivsPreviewImage');

          if (currentIvsPreviewImage) {
            await this.storageService.delete(currentIvsPreviewImage);
          }

          if (stage.ivsPreviewImage !== null) {
            const fileName = `file_${new Date().getTime()}`;
            const newIvsPreviewImage = await this.storageService.upload(
              stage.ivsPreviewImage,
              'stages',
              fileName,
            );
            stage.ivsPreviewImage = newIvsPreviewImage;
          } else {
            stage.ivsPreviewImage = null;
          }
        }
      }

      const order =
        (
          await this.firestore
            .collection(`${CollectionTypes.EVENTS}/${eventId}/${CollectionTypes.STAGES}`)
            .get()
        ).size + 1;

      await stageDocument.set({ ...stage, order });

      return stage as IStage;
    } catch (error) {
      throw error;
    }
  }

  async update(eventId: string, stageId: string, stage: any): Promise<IStage> {
    try {
      const stageDocument = this.firestore.collection(apiRoutes.stages(eventId)).doc(stageId);
      const stageDocData = await stageDocument.get();

      if (
        stage.videoThumbnail instanceof File ||
        stage.banner instanceof File ||
        stage.ivsPreviewImage instanceof File
      ) {
        if (stage.videoThumbnail instanceof File) {
          const currentLogoImage = stageDocData.get('logo');

          if (currentLogoImage) {
            await this.storageService.delete(currentLogoImage);
          }

          if (stage.videoThumbnail !== null) {
            const fileName = `file_${new Date().getTime()}`;
            const newLogoImage = await this.storageService.upload(
              stage.videoThumbnail,
              'stages',
              fileName,
            );
            stage.videoThumbnail = newLogoImage;
          } else {
            stage.videoThumbnail = null;
          }
        }

        if (stage.banner instanceof File) {
          const currentBannerImage = stageDocData.get('banner');

          if (currentBannerImage) {
            await this.storageService.delete(currentBannerImage);
          }

          if (stage.banner !== null) {
            const fileName = `file_${new Date().getTime()}`;
            const newCoverImage = await this.storageService.upload(
              stage.banner,
              'stages',
              fileName,
            );
            stage.banner = newCoverImage;
          } else {
            stage.banner = null;
          }
        }

        if (stage.ivsPreviewImage instanceof File) {
          const currentIvsPreviewImage = stageDocData.get('ivsPreviewImage');

          if (currentIvsPreviewImage) {
            await this.storageService.delete(currentIvsPreviewImage);
          }

          if (stage.ivsPreviewImage !== null) {
            const fileName = `file_${new Date().getTime()}`;
            const newIvsPreviewImage = await this.storageService.upload(
              stage.ivsPreviewImage,
              'stages',
              fileName,
            );
            stage.ivsPreviewImage = newIvsPreviewImage;
          } else {
            stage.ivsPreviewImage = null;
          }
        }
      }

      if (!stageDocData.get('isMainStage') && stage.isMainStage) {
        stage.order = null;

        const allStages = (
          await this.firestore.collection(apiRoutes.stages(eventId)).orderBy('order').get()
        ).docs;

        for (const doc of allStages) {
          const s = doc.data() as IStage;
          let shouldUpdate: boolean;

          if (s.isMainStage) {
            s.isMainStage = false;
            s.order = allStages.length;
            shouldUpdate = true;
          }

          if (s.order > stageDocData.get('order')) {
            s.order--;
            shouldUpdate = true;
          }

          if (shouldUpdate) {
            await this.firestore
              .collection(apiRoutes.stages(eventId))
              .doc(doc.id)
              .update({ ...s });
          }
        }
      } else if (stageDocData.get('isMainStage') && !stage.isMainStage) {
        stage.order = (await this.firestore.collection(apiRoutes.stages(eventId)).get()).size;
      }

      const stageForUpdate = {
        ...stage,
        id: stageId,
        updatedAt: Timestamp.now(),
        updatedBy: this.usersStore.user.id,
      };
      await stageDocument.update({ ...stageForUpdate });

      return stageForUpdate as IStage;
    } catch (error) {
      throw error;
    }
  }

  async remove(docId: string, eventId: string): Promise<boolean> {
    try {
      await this.firestore
        .collection(`${CollectionTypes.EVENTS}/${eventId}/${CollectionTypes.STAGES}`)
        .doc(docId)
        .delete();
      return true;
    } catch (error) {
      throw error;
    }
  }

  async reorder(eventId: string, orderedIds: string[]): Promise<void> {
    let order = 1;

    orderedIds.forEach((stageId) =>
      this.firestore
        .collection(`${CollectionTypes.EVENTS}/${eventId}/${CollectionTypes.STAGES}`)
        .doc(stageId)
        .update({ order: order++ }),
    );
  }

  async removeSpeakerFromStage(
    eventId: string,
    stageId: string,
    speakerId: string,
  ): Promise<boolean> {
    try {
      await this.firestore
        .collection(apiRoutes.stages(eventId))
        .doc(stageId)
        .update({
          speakers: ArrayRemove(speakerId),
        });

      return true;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async deleteSpeakerFromAllStagesInEvent(eventId: string, speakerId: string): Promise<boolean> {
    try {
      const stages: IStage[] = await this.getAll(eventId);
      await Promise.all(
        stages.map((stage: IStage) => this.removeSpeakerFromStage(eventId, stage.id, speakerId)),
      );

      return true;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async removeModeratorFromStage(
    eventId: string,
    stageId: string,
    moderatorId: string,
  ): Promise<boolean> {
    try {
      await this.firestore
        .collection(apiRoutes.stages(eventId))
        .doc(stageId)
        .update({
          moderators: ArrayRemove(moderatorId),
        });

      return true;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async deleteModeratorFromAllStagesInEvent(
    eventId: string,
    moderatorId: string,
  ): Promise<boolean> {
    try {
      const stages: IStage[] = await this.getAll(eventId);
      await Promise.all(
        stages.map((stage: IStage) =>
          this.removeModeratorFromStage(eventId, stage.id, moderatorId),
        ),
      );

      return true;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}
