<ng-container *ngIf="loading">
  <app-loader></app-loader>
</ng-container>

<div
  *ngIf="!loading"
  class="brand-view-container grid">
  <div class="lg:col-12 md:col-12 sm:col-12 col-12 brand-container">
    <div class="brand-details-inner-container">
      <div class="brand-header">
        <app-update-default-covers
          *ngIf="brand?.banner"
          [url]="brand?.banner"
          [color]="defaultCoversColor">
        </app-update-default-covers>
        <div class="brand-info-header">
          <img
            *ngIf="brand?.logo"
            class="brand-logo"
            [src]="brand?.logo || userAvatar | imageSize"
            alt="{{ 'shared.partnerLogo' | translate }}" />
          <button
            *ngIf="showBackBtn"
            class="button-back"
            pButton
            label="{{ 'brands.back' | translate }}"
            icon="far fa-arrow-left"
            (click)="onBack()"></button>
        </div>
      </div>

      <div class="brand-body">
        <div class="grid">
          <div class="lg:col-4 md:col-12 sm:col-12 col-12 brand-name-col">
            <div class="brand-main-info-box">
              <h1>{{ brand?.name }}</h1>
              <p>{{ brand?.slogan }}</p>
            </div>

            <button
              *ngIf="showBookmarkContent"
              class="bookmarkBtn"
              pButton
              label="{{ 'brands.bookmark' | translate }}"
              (click)="onBookmark()">
              <i [ngClass]="getBookmarkedIconClass()"></i>
            </button>

            <div class="social-links-box">
              <span
                [ngClass]="{ 'icon-disabled': brand?.website == null || brand?.website == '' }"
                (click)="onGoToLink(brand.website)">
                <i class="fal fa-globe"></i>
              </span>
              <a
                href="mailto:{{ brand?.email }}"
                [ngClass]="{ 'icon-disabled': !brand?.email }">
                <i class="fal fa-envelope-open-text"></i>
              </a>
              <span
                [ngClass]="{ 'icon-disabled': brand?.facebook == null || brand?.facebook == '' }"
                (click)="onGoToLink(brand.facebook)">
                <i class="fab fa-facebook-f"></i>
              </span>
              <span
                [ngClass]="{ 'icon-disabled': !brand?.instagram }"
                (click)="onGoToLink(brand.instagram)">
                <i class="fab fa-instagram"></i>
              </span>
              <span
                [ngClass]="{ 'icon-disabled': !brand?.youtube }"
                (click)="onGoToLink(brand.youtube)">
                <i class="fab fa-youtube"></i>
              </span>
              <span
                [ngClass]="{ 'icon-disabled': brand?.linkedin == null || brand?.linkedin == '' }"
                (click)="onGoToLink(brand.linkedin)">
                <i class="fab fa-linkedin-in"></i>
              </span>
              <span
                [ngClass]="{ 'icon-disabled': !brand?.twitter }"
                (click)="onGoToLink(brand.twitter)">
                <i class="fab fa-twitter"></i>
              </span>
              <span
                [ngClass]="{ 'icon-disabled': brand?.xing == null || brand?.xing == '' }"
                (click)="onGoToLink(brand.xing)">
                <i class="fab fa-xing"></i>
              </span>
            </div>
            <app-calendly-link
              [link]="brand?.calendly"
              [openInDialog]="true"></app-calendly-link>
          </div>
          <div class="lg:col-8 md:col-12 sm:col-12 col-12 brand-data-col">
            <div
              class="brand-bio"
              [innerHTML]="brand?.description"></div>
            <div class="tags-list">
              <app-tags-multi-list
                [edit]="false"
                [possibleTags]="tags"></app-tags-multi-list>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
