import { Timestamp } from 'src/app/firebase';
import { Agreement, TravelDocument, IUserType } from 'src/app/shared';
import { ITag } from '../tag/tag.model';
import { ITicket } from '..';
import {
  AreaOfActivity,
  CompanySize,
  Industry,
  OptionalPosition,
  SubIndustry,
  UserSubscriptionTypes,
} from '../../enums';

export interface IUser {
  id: string;
  displayEmail: string;
  email: string;
  firstName: string;
  lastName: string;
  company: string;
  position: string;
  _firstName_: string;
  _lastName_: string;
  _company_: string;
  _position_: string;
  department: string;
  bio: string;
  title: string;
  addressSuffix: string;
  salutation: string;
  profileImage: string;
  coverImage: string;
  phone: string;
  country: string;
  city: string;
  street: string;
  postcode: string;
  website: string;
  facebook: string;
  linkedin: string;
  instagram: string;
  youtube: string;
  twitter: string;
  xing: string;
  calendly: string;
  acceptNews: boolean;
  allowChat: boolean;
  allowAppointment: boolean;
  isActive: boolean;
  brandId: string;
  hubId: string;
  role: string;
  tags: string[];
  systemLanguage: string;
  systemTheme: string;
  travelDocument: TravelDocument;
  vegetarian: Agreement;
  shoeSize: number;
  clothingSize: string;
  industry: Industry;
  subIndustry: SubIndustry;
  areaOfActivity: AreaOfActivity;
  optionalPosition: OptionalPosition;
  companySize: CompanySize;
  updatedAt: Timestamp;
  updatedBy: string;
  createdAt: Timestamp | any;
  createdBy: string;
  tagsList?: ITag[]; // TODO maybe remove
  type?: IUserType;
  itemsLeft?: number; //TODO check if needed
  isFromEventBriteSync?: boolean;
  ticket?: ITicket;

  docRef?: any;
  oldPassword?: string;
  password?: string;
  eventStatus?;
  isOwner?: boolean;
  eventTimeStamp?;
  eventRole?;
  brandStatus?;
  brandTimeStamp?;
  brandRole?;
  brandUserId?;
  userHubId?;
  hubRole?;
  salesforceId?: string;
  subscriptionIds?: string[];
  isActionBtnShowingSpinner?: boolean;

  birthDate?: Timestamp;
  dateOfExpiry?: Timestamp;
  dateOfIssue?: Timestamp;
  emergencyContactName?: string;
  emergencyContactPhone?: string;
  mobile?: string;
  nationality?: string;
  passportNumber?: string;
  stripeId?: string;
  lastEventUrl?: string;
  lastEventId?: string;
  name?: string;
  accountVerifiedAt?: Timestamp;
  checkedIn?: boolean;
  isPlenigoConnected?: boolean;
  subscriptionType?: UserSubscriptionTypes;
  hasEverSubscribed: boolean;
  createdHubsCount: number;
  tenantId?: string;
}

// TODO Remove and use only interface
export class User implements IUser {
  id: string;
  displayEmail: string;
  email: string;
  firstName: string;
  lastName: string;
  company: string;
  position: string;
  _firstName_: string;
  _lastName_: string;
  _company_: string;
  _position_: string;
  department: string;
  bio: string;
  title: string;
  addressSuffix: string;
  salutation: string;
  clothingSize: string;
  shoeSize: number;
  profileImage: string;
  coverImage: string;
  phone: string;
  country: string;
  city: string;
  street: string;
  postcode: string;
  website: string;
  facebook: string;
  instagram: string;
  youtube: string;
  linkedin: string;
  twitter: string;
  xing: string;
  calendly: string;
  acceptNews: boolean;
  allowChat: boolean;
  allowAppointment: boolean;
  isActive: boolean;
  brandId: string;
  hubId: string;
  role: string;
  tags: string[];
  systemLanguage: string;
  systemTheme: string;
  updatedAt: any;
  updatedBy: any;
  createdAt: any;
  createdBy: any;
  tagsList: ITag[];
  isFromEventBriteSync: boolean;
  salesforceId?: string;
  subscriptionIds: string[];
  stripeId: string;
  isPlenigoConnected: boolean;
  subscriptionType?: UserSubscriptionTypes;
  hasEverSubscribed: boolean;
  createdHubsCount: number;
  travelDocument: TravelDocument;
  vegetarian: Agreement;
  industry: Industry;
  subIndustry: SubIndustry;
  areaOfActivity: AreaOfActivity;
  optionalPosition: OptionalPosition;
  companySize: CompanySize;

  constructor(
    id: string = null,
    displayEmail: string = null,
    email: string = null,
    firstName: string = null,
    lastName: string = null,
    company: string = null,
    position: string = null,
    department: string = null,
    bio: string = null,
    title: string = null,
    addressSuffix: string = null,
    salutation: string = null,
    profileImage: string = null,
    coverImage: string = null,
    phone: string = null,
    country: string = null,
    city: string = null,
    street: string = null,
    postcode: string = null,
    website: string = null,
    facebook: string = null,
    instagram: string = null,
    youtube: string = null,
    linkedin: string = null,
    twitter: string = null,
    xing: string = null,
    calendly: string = null,
    acceptNews: boolean = null,
    allowChat: boolean = null,
    allowAppointment: boolean = null,
    isActive: boolean = null,
    brandId: string = null,
    hubId: string = null,
    role: string = 'user',
    tags: string[] = null,
    systemLanguage: string = null,
    systemTheme: string = null,
    updatedAt: any = null,
    updatedBy: any = null,
    createdAt: any = null,
    createdBy: any = null,
    isFromEventBriteSync: boolean = false,
    salesforceId = null,
    subscriptionIds = [],
    stripeId = null,
    isPlenigoConnected = false,
    subscriptionType = UserSubscriptionTypes.NONE,
    hasEverSubscribed = false,
    createdHubsCount = 0,
    travelDocument = null,
    vegetarian = null,
    shoeSize = null,
    industry = null,
    subIndustry = null,
    areaOfActivity = null,
    optionalPosition = null,
    companySize = null,
    clothingSize = null,
  ) {
    this.id = id;
    this.displayEmail = displayEmail;
    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
    this.company = company;
    this.position = position;

    this._firstName_ = firstName ? firstName.toLowerCase() : firstName;
    this._lastName_ = lastName ? lastName.toLowerCase() : lastName;
    this._company_ = company ? company.toLowerCase() : company;
    this._position_ = position ? position.toLowerCase() : position;

    this.department = department;
    this.bio = bio;
    this.title = title;
    this.addressSuffix = addressSuffix;
    this.salutation = salutation;
    this.profileImage = profileImage;
    this.coverImage = coverImage;
    this.phone = phone;
    this.country = country;
    this.city = city;
    this.street = street;
    this.postcode = postcode;
    this.website = website;
    this.facebook = facebook;
    this.instagram = instagram;
    this.youtube = youtube;
    this.linkedin = linkedin;
    this.twitter = twitter;
    this.xing = xing;
    this.calendly = calendly;
    this.acceptNews = acceptNews;
    this.allowChat = allowChat;
    this.allowAppointment = allowAppointment;
    this.isActive = isActive;
    this.brandId = brandId;
    this.hubId = hubId;
    this.role = role;
    this.tags = tags;
    this.systemLanguage = systemLanguage;
    this.systemTheme = systemTheme;
    this.updatedAt = updatedAt;
    this.updatedBy = updatedBy;
    this.createdAt = createdAt;
    this.createdBy = createdBy;

    this.isFromEventBriteSync = isFromEventBriteSync;
    this.salesforceId = salesforceId;
    this.subscriptionIds = subscriptionIds;
    this.stripeId = stripeId;
    this.isPlenigoConnected = isPlenigoConnected;
    this.subscriptionType = subscriptionType;
    this.hasEverSubscribed = hasEverSubscribed;
    this.createdHubsCount = createdHubsCount;
    this.travelDocument = travelDocument;
    this.vegetarian = vegetarian;
    this.shoeSize = shoeSize;
    this.industry = industry;
    this.subIndustry = subIndustry;
    this.areaOfActivity = areaOfActivity;
    this.optionalPosition = optionalPosition;
    this.companySize = companySize;
    this.clothingSize = clothingSize;
  }
}

export interface IPlenigoUserData {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  activeSubscription: 'true' | 'false';
}
