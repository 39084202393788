import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ButtonComponent } from '../button/button.component';
import { ButtonSize, ButtonType, ButtonStyle } from '../../enums';

@Component({
  selector: 'app-item-detail-header',
  standalone: true,
  imports: [CommonModule, ButtonComponent],
  templateUrl: './item-detail-header.component.html',
  styleUrls: ['./item-detail-header.component.scss'],
})
export class ItemDetailHeaderComponent {
  @Input() title: string;
  @Output() backClick: EventEmitter<void> = new EventEmitter<void>();

  buttonSize = ButtonSize;
  buttonType = ButtonType;
  buttonStyle = ButtonStyle;

  onBackClick(): void {
    this.backClick.emit();
  }
}
