import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';

@Component({
  selector: 'app-products-multi-list',
  templateUrl: './products-multi-list.component.html',
  styleUrls: ['./products-multi-list.component.scss'],
})
export class ProductsMultiListComponent implements AfterViewInit {
  @Input() public possibleProducts: { id: string; title: string }[] = [];
  @Input() public showAsSingleRow = false;
  @Input() public adminTheme = false;
  @Input() public edit = true;
  @Input() set selectedProducts(ids: string[]) {
    this._selectedProducts = ids ? ids.slice() : [];
  }
  @Output() public onSelected = new EventEmitter<string[]>();
  @ViewChild('productsslider') productsSlider: ElementRef;

  private _selectedProducts: string[] = [];
  private slider: any;
  private isDown = false;
  private startX: number;
  private scrollLeft: number;

  ngAfterViewInit() {
    this.slider = this.productsSlider.nativeElement;
  }

  public productSelected(product: any): void {
    if (!this.edit) {
      return;
    }
    const clickedTagIndex = this._selectedProducts.indexOf(product.id);

    if (clickedTagIndex > -1) {
      this._selectedProducts.splice(clickedTagIndex, 1);
    } else {
      this._selectedProducts.push(product.id);
    }

    this.onSelected.emit(this._selectedProducts);
  }

  public isProductSelected(product: { id: string; title: string }): boolean {
    return this._selectedProducts.includes(product.id);
  }

  public onMouseDown(event: MouseEvent) {
    if ((event?.target as HTMLElement)?.id.includes('item')) {
      this.productSelected(
        this.possibleProducts.find((p) => p.id === (event?.target as HTMLElement).id.split('-')[1]),
      );
      return;
    }
    this.isDown = true;
    this.slider.classList.add('active');
    this.startX = event.pageX - this.slider.offsetLeft;
    this.scrollLeft = this.slider.scrollLeft;
  }

  public onMouseLeave() {
    this.isDown = false;
    this.slider.classList.remove('active');
  }

  public onMouseUp() {
    this.isDown = false;
    this.slider.classList.remove('active');
  }

  public onMouseMove(event: MouseEvent) {
    if (!this.isDown) {
      return;
    }
    event.preventDefault();
    const x = event.pageX - this.slider.offsetLeft;
    const walk = (x - this.startX) * 3; // scroll-fast
    this.slider.scrollLeft = this.scrollLeft - walk;
  }
}
