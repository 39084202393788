<ng-container *ngIf="loading">
  <app-loader></app-loader>
</ng-container>

<div
  *ngIf="!loading"
  class="admin-event-stages-table-container">
  <admin-table-header-navbar [title]="'adminEventStages.title' | translate">
    <app-button
      routerLink="/{{ hubsStore.useHubUrl }}/admin/event/{{ event?.link }}/setup/stages/new-stage"
      buttonText="{{ 'adminEventStages.addBtn' | translate }}"
      buttonIconLeft="far fa-plus"
      [buttonSize]="buttonSize.MEDIUM" />
  </admin-table-header-navbar>

  <div class="c-my-4">
    <p-divider styleClass="custom-divider-horizontal"></p-divider>
  </div>

  <admin-table-filter-navbar
    (onSearchTermChange)="searchValue = $event"></admin-table-filter-navbar>

  <admin-table
    [loading]="loadingEntries"
    [paginator]="true"
    [lazy]="false"
    [columns]="columns"
    [entries]="entries"
    [emptyStateKey]="'adminEventStages.emptyState'"
    [searchValue]="searchValue"
    [searchFilterFields]="searchFilterFields"
    [enableReorder]="true"
    [emptyStateIcon]="'fak fa-lox-stages'"
    (rowReorder)="reorder($event)"
    (rowClicked)="onRowClicked($event)">
    <ng-template
      let-entry
      epTemplateKey="titleCell">
      <div class="title-cell">
        <div>
          @if (entry.banner) {
            <img
              class="banner"
              [defaultImage]="imgPreloader"
              [lazyLoad]="entry.banner | imageSize" />
          } @else {
            <div class="placeholder-container">
              <img
                class="placeholder"
                [src]="logoAvatar | imageSize" />
            </div>
          }
        </div>
        <div>
          <p class="stage-title">{{ entry.name }}</p>
        </div>
      </div>
    </ng-template>

    <ng-template
      let-entry
      epTemplateKey="featuredCell">
      <app-tag
        [iconClass]="entry.featured ? 'fa-regular fa-check' : 'fa-regular fa-xmark'"
        [bgColor]="entry.featured ? 'var(--appPrimaryColor)' : '#ff244c'">
      </app-tag>
    </ng-template>

    <ng-template
      let-entry
      epTemplateKey="statusCell">
      <app-tag
        [iconClass]="entry.isMainStage ? 'fa-regular fa-check' : 'fa-regular fa-xmark'"
        [bgColor]="entry.isMainStage ? 'var(--appPrimaryColor)' : '#ff244c'">
      </app-tag>
    </ng-template>

    <ng-template
      let-entry
      epTemplateKey="actionsCell">
      <div class="table-btn-actions-container">
        <app-button
          [buttonType]="buttonType.ROUND"
          [buttonSize]="buttonSize.MEDIUM"
          [buttonStyle]="buttonStyle.FILLED_SECONDARY"
          buttonRoundIcon="far fa-pen-to-square"
          (click)="edit($event, entry)" />
        <app-button
          [buttonType]="buttonType.ROUND"
          [buttonSize]="buttonSize.MEDIUM"
          [buttonStyle]="buttonStyle.FILLED_DANGER"
          buttonRoundIcon="far fa-trash-can"
          (click)="remove($event, entry)" />
      </div>
    </ng-template>
  </admin-table>
</div>
