import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import {
  Firestore,
  IDocumentData,
  IDocumentReference,
  IDocumentSnapshot,
  IFirestore,
  Timestamp,
} from 'src/app/firebase';
import { ICourseTicket, ITicket, IUserEvent } from 'src/app/core/models';
import { API_ROUTES as apiRoutes } from 'src/app/shared';
import { TicketsStore, UsersStore } from '../../stores';
import { AuthorizationService } from '../auth';
import { UsersService } from '../users';
import { AppStore } from '../../../app.store';

@Injectable({
  providedIn: 'root',
})
export class TicketsService {
  private firestore: IFirestore;

  constructor(
    private usersStore: UsersStore,
    private authorizationService: AuthorizationService,
    private usersService: UsersService,
    private http: HttpClient,
    private ticketsStore: TicketsStore,
    public appStore: AppStore,
  ) {
    this.firestore = Firestore();
  }

  async getEventTickets(eventId: string): Promise<ITicket[]> {
    try {
      const result: ITicket[] = (
        await this.firestore.collection(apiRoutes.eventTickets(eventId)).get()
      ).docs.map((doc) => doc.data() as ITicket);

      return result;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getPublishedEventTickets(eventId: string): Promise<ITicket[]> {
    try {
      const result: ITicket[] = (
        await this.firestore
          .collection(apiRoutes.eventTickets(eventId))
          .where('isPublished', '==', true)
          .get()
      ).docs.map((doc) => doc.data() as ITicket);

      return result;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getOnDemandTickets(eventId: string): Promise<ITicket[]> {
    try {
      const tickets: ITicket[] = (
        await this.firestore
          .collection(apiRoutes.eventTickets(eventId))
          .where('isPublished', '==', true)
          .where('onDemand', '==', true)
          .where('eventBriteId', '==', null)
          .get()
      ).docs.map((doc) => doc.data() as ITicket);

      return tickets;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getCourseTickets(courseId: string): Promise<ICourseTicket[]> {
    try {
      const result: ICourseTicket[] = (
        await this.firestore.collection(apiRoutes.courseTickets(courseId)).get()
      ).docs.map((doc) => doc.data() as ICourseTicket);

      return result;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getPublishedCourseTickets(courseId: string): Promise<ICourseTicket[]> {
    try {
      const result: ICourseTicket[] = (
        await this.firestore
          .collection(apiRoutes.courseTickets(courseId))
          .where('isPublished', '==', true)
          .orderBy('createdAt', 'asc')
          .get()
      ).docs.map((doc) => doc.data() as ICourseTicket);

      return result;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getEventTicketsOnlyEventBrite(eventId: string): Promise<ITicket[]> {
    try {
      const result: ITicket[] = (
        await this.firestore
          .collection(apiRoutes.eventTickets(eventId))
          .where('isEventBrite', '==', true)
          .get()
      ).docs.map((doc) => doc.data() as ITicket);

      return result;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getPublishEventTicketsWithoutEventBrite(eventId: string): Promise<ITicket[]> {
    try {
      const result: ITicket[] = (
        await this.firestore
          .collection(apiRoutes.eventTickets(eventId))
          .where('eventBriteId', '==', null)
          .where('isPublished', '==', true)
          .orderBy('createdAt', 'asc')
          .get()
      ).docs.map((doc) => doc.data() as ITicket);

      return result;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getOneTicket(eventId: string, ticketId: string): Promise<ITicket> {
    try {
      const ticket = (
        await this.firestore.doc(apiRoutes.eventTicket(eventId, ticketId)).get()
      ).data() as ITicket;
      return ticket;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getOneCourseTicket(courseId: string, ticketId: string): Promise<ICourseTicket> {
    try {
      const ticket = (
        await this.firestore.doc(apiRoutes.courseTicket(courseId, ticketId)).get()
      ).data() as ICourseTicket;
      return ticket;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async createTicket(eventId: string, ticket: ITicket): Promise<ITicket> {
    try {
      const preTicketReq = await this.firestore.collection(apiRoutes.eventTickets(eventId)).doc();
      const newTicket: ITicket = {
        ...ticket,
        id: preTicketReq.id,
        _name_: ticket.name.toLowerCase(),
        createdAt: Timestamp.now(),
        createdBy: this.usersStore.user.id,
        quantity_sold: 0,
        quantitySold: 0,
      };
      await this.firestore
        .collection(apiRoutes.eventTickets(eventId))
        .doc(newTicket.id)
        .set({ ...newTicket });

      return newTicket;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async createCourseTicket(courseId: string, ticket: ICourseTicket): Promise<ICourseTicket> {
    try {
      const preTicketReq = await this.firestore.collection(apiRoutes.courseTickets(courseId)).doc();
      const newTicket: ICourseTicket = {
        ...ticket,
        id: preTicketReq.id,
        _name_: ticket.name.toLowerCase(),
        createdAt: Timestamp.now(),
        createdBy: this.usersStore.user.id,
      };
      await this.firestore
        .collection(apiRoutes.courseTickets(courseId))
        .doc(newTicket.id)
        .set({ ...newTicket });

      return newTicket;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async updateTicket(
    eventId: string,
    ticket: ITicket,
    isUpdateStore: boolean = false,
  ): Promise<ITicket> {
    try {
      const tagDocument = this.firestore.collection(apiRoutes.eventTickets(eventId)).doc(ticket.id);
      const newTicket = {
        ...ticket,
        _name_: ticket.name.toLowerCase(),
        updatedAt: Timestamp.now(),
        updatedBy: this.usersStore.user?.id ? this.usersStore.user.id : null,
      };
      await tagDocument.update({ ...newTicket });

      if (isUpdateStore) {
        this.ticketsStore.setAdminTicket(ticket);
      }

      return newTicket;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async updateCourseTicket(
    courseId: string,
    ticketId: string,
    ticket: ICourseTicket,
  ): Promise<ICourseTicket> {
    try {
      const tagDocument = this.firestore
        .collection(apiRoutes.courseTickets(courseId))
        .doc(ticketId);
      const newTicket = {
        ...ticket,
        _name_: ticket.name.toLowerCase(),
        updatedAt: Timestamp.now(),
        updatedBy: this.usersStore.user.id,
      };
      await tagDocument.update({ ...newTicket });

      return newTicket;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async syncFromEventBrite(eventId: string): Promise<boolean> {
    try {
      const headers = await this.authorizationService.buildHeaders();
      return this.http
        .get<boolean>(apiRoutes.fetchEventTickets(eventId), { headers: headers })
        .toPromise();
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getEventTicketByUserId(eventId: string, userId: string): Promise<IUserEvent> {
    try {
      let userEvent;
      (
        await this.firestore
          .collection(apiRoutes.userEvents)
          .where('eventId', '==', eventId)
          .where('userId', '==', userId)
          .get()
      ).forEach((doc: any) => {
        userEvent = doc.data();

        if (this.appStore.generalSystemSettings.enableEncryption) {
          userEvent = this.usersService.decryptUserData(userEvent) as IUserEvent;
          console.log('↑ getEventTicketByUserId decrypted userEvent');
        }
      });
      return userEvent;
    } catch (err) {
      console.warn(err);
      throw new Error(err);
    }
  }

  public async delete(docId: string, eventId: string): Promise<boolean> {
    try {
      await this.firestore.collection(apiRoutes.eventTickets(eventId)).doc(docId).delete();
      return true;
    } catch (err) {
      console.warn(err);
      throw new Error(err);
    }
  }

  public async deleteFromCourse(docId: string, courseId: string): Promise<boolean> {
    try {
      await this.firestore.collection(apiRoutes.courseTickets(courseId)).doc(docId).delete();
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  }

  public async unpublishAllPaidTickets(): Promise<void> {
    try {
      const eventsSnapshot = await this.firestore.collection(apiRoutes.events).get();
      eventsSnapshot.docs.map(async (doc) => {
        const ticketsSnapshot = await doc.ref.collection(apiRoutes.tickets).get();
        ticketsSnapshot.docs.map((doc) => {
          if (doc.data().isPaid) {
            doc.ref.update({ isPublished: false });
          }
        });
      });

      const coursesSnapshot = await this.firestore.collection(apiRoutes.courses).get();
      coursesSnapshot.docs.map(async (doc) => {
        const ticketsSnapshot = await doc.ref.collection(apiRoutes.tickets).get();
        ticketsSnapshot.docs.map((doc) => {
          if (doc.data().isPaid) {
            doc.ref.update({ isPublished: false });
          }
        });
      });
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async updateBookedNumbersInEventTicket(eventId: string, ticket: ITicket): Promise<void> {
    try {
      const ticketDocData: IDocumentReference<IDocumentData> = this.firestore
        .collection(apiRoutes.eventTickets(eventId))
        .doc(ticket.id);

      await this.firestore.runTransaction(async (transaction) => {
        const ticketSnapshot: IDocumentSnapshot<IDocumentData> =
          await transaction.get(ticketDocData);
        const ticketFromDb: ITicket = ticketSnapshot.data() as ITicket;

        const condition =
          ticket.quantityTotal >=
          (ticketFromDb?.numberBookedTickets || 0) +
            ticket.numberOfPurchaseTickets +
            (ticket.quantitySold || 0);
        if (condition) {
          transaction.update(ticketDocData, {
            numberBookedTickets:
              (ticketFromDb?.numberBookedTickets || 0) + ticket.numberOfPurchaseTickets,
          });
        }
      });
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }
}
