import { Injectable } from '@angular/core';
import * as firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';

/*
=== STANDARD EVENT NAMES ===
add_payment_info
add_shipping_info
add_to_cart
add_to_wishlist
begin_checkout
checkout_progress
exception
generate_lead
login
page_view
purchase
refund
remove_from_cart
screen_view
search
select_content
select_item
select_promotion
set_checkout_option
share
sign_up
timing_complete
view_cart
view_item
view_item_list
view_promotion
view_search_results
*/

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private analytics: any;

  constructor() {
    this.analytics = firebase.default.analytics();
  }

  logEvents(eventName: string, params?: { [key: string]: any }): void {
    this.analytics.logEvent(eventName);
  }

  logLoginEvent(): void {
    this.logEvents('login', { method: 'email/password' });
  }

  logSignUpEvent(): void {
    this.logEvents('sign_up', { method: 'email/password' });
  }

  logBeginCheckout(): void {
    this.logEvents('begin_checkout');
  }

  logFailedCheckout(): void {
    this.logEvents('failed_checkout');
  }

  logSuccessCheckout(): void {
    this.logEvents('success_checkout');
  }

  setUserContext(
    userId: string,
    firstName: string,
    lastName: string,
    email: string,
    company: string,
  ): void {
    this.analytics.setUserId(userId);
    this.analytics.setUserProperties({
      firstName,
      lastName,
      email,
      company,
    });
  }

  clearUserContext(): void {
    this.analytics.setUserId(undefined);
    this.analytics.setUserProperties({
      firstName: undefined,
      lastName: undefined,
      email: undefined,
      company: undefined,
    });
  }
}
