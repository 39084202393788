<p-sidebar
  [(visible)]="appStore.isTopicsSidebarVisible"
  (onShow)="onShow($event)"
  [style]="{ background: 'var(--background-sidebar)', color: 'var(--text-primary)', border: '0' }"
  position="right"
  baseZIndex="1">
  <i class="far fa-tags mr-2"></i>
  <span>{{ 'eventDetails.topicsTitle' | translate }}</span>

  <ng-container *ngIf="loading">
    <app-loader></app-loader>
  </ng-container>

  <ng-container *ngIf="!loading">
    <app-tags-multi-list
      (onSelected)="onSelectedTag($event)"
      [possibleTags]="tags"
      [selectedTags]="userTagIds"
      [showAsSingleRow]="false">
    </app-tags-multi-list>
  </ng-container>
</p-sidebar>
