<div [class.asset-event-container]="!!assetEventStatus">
  <div class="content">
    <i
      *ngIf="asset.type === assetType.EVENT && assetEventStatus === 'live'"
      class="fa-regular fa-signal-stream asset-live-icon-color"
      [class.file-icon]="isAssetWatched">
    </i>
    <i
      *ngIf="asset.type === assetType.EVENT && assetEventStatus === 'upcoming'"
      class="fa-regular fa-timer asset-upcoming-icon-color"
      [class.file-icon]="isAssetWatched">
    </i>
    <i
      *ngIf="asset.type === assetType.EVENT && !assetEventStatus"
      class="fa-regular fa-calendar"
      [class.file-icon]="isAssetWatched">
    </i>
    <i
      *ngIf="asset.type === assetType.FILE"
      class="fa-regular fa-file"
      [class.file-icon]="isAssetWatched">
    </i>
    <i
      *ngIf="asset.type === assetType.VIDEO"
      class="video-icon"
      [ngClass]="iconForVideoState"
      (click)="onVideoIconClick($event)">
    </i>
    <div class="banner-container">
      <img
        *ngIf="asset.image"
        class="asset-image"
        [src]="asset.image" />
      <div
        *ngIf="!asset.image && asset.type !== assetType.EVENT"
        class="placeholder-container">
        <img
          class="placeholder"
          [src]="imagePlaceholder" />
      </div>
      <div
        *ngIf="!asset.image && asset.type === assetType.EVENT"
        class="placeholder-container">
        <app-asset-event-banner
          [asset]="asset"
          [monthStyles]="assetEventBannerMonthStyles"
          [dateStyles]="assetEventBannerDateStyle">
        </app-asset-event-banner>
      </div>
      <div
        *ngIf="asset.type !== assetType.EVENT && !isHideProgressBar"
        class="progress-bar-container">
        <app-custom-progress-bar
          [value]="percentageWatched"
          [height]="customProgressBarHeight"
          [borderRadius]="customProgressBarBorderRadius"
          className="course-registered-progress-bar-watched">
        </app-custom-progress-bar>
      </div>
    </div>
    <div class="text-container">
      <p class="asset-title">{{ asset.title }}</p>
      <p class="asset-type-sub-title">
        <span *ngIf="asset.type === assetType.VIDEO">{{ asset.duration }}</span>
        <span *ngIf="asset.type === assetType.FILE">{{ asset.fileType.toLowerCase() }}</span>
        <span *ngIf="asset.type === assetType.EVENT && this.assetEventStatus === 'live'">
          {{ 'chapterAssetCard.nowLive' | translate }}
        </span>
        <span *ngIf="asset.type === assetType.EVENT && this.assetEventStatus === 'upcoming'">
          {{ assetUpcomingEventSubtitle }}
        </span>
        <span *ngIf="asset.type === assetType.EVENT && !this.assetEventStatus">{{
          assetEventSubtitle
        }}</span>
      </p>
    </div>
    <div
      *ngIf="isShowAssetDuration && asset.videoDuration"
      class="duration">
      {{ asset.videoDuration | minutesSeconds }}
    </div>
  </div>
</div>
