import { HubsStore } from './../../core/stores/hubs/hubs.store';
import { Router } from '@angular/router';
import { LibraryStore } from './../../core/stores/library/library.store';
import { LibraryService } from './../../core/services/library/library.service';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Route,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AdminLibraryAssetAccessGuard {
  constructor(
    private router: Router,
    private hubsStore: HubsStore,
    private libraryService: LibraryService,
    private libraryStore: LibraryStore,
  ) {}

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    let currentAsset = this.router.getCurrentNavigation().extras.state
      ? this.router.getCurrentNavigation().extras.state.asset
        ? this.router.getCurrentNavigation().extras.state.asset
        : null
      : null;

    if (!currentAsset) {
      currentAsset = await this.libraryService.getOne(next.params.assetId);
    }

    this.libraryStore.setAdminAsset(currentAsset);
    return currentAsset ? true : false;
  }
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.libraryStore.unsetAdminAsset();
    return true;
  }
  canLoad(
    route: Route,
    segments: UrlSegment[],
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
}
