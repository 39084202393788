import { Component, OnInit, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';

import { AttachAssetsDialogComponent } from 'src/app/admin';
import { AssetFileTypes, AssetType } from 'src/app/core/enums';
import { IAsset, ISessionAsset } from 'src/app/core/models';
import { SessionAssetsService } from 'src/app/core/services';
import { EventsStore, HubsStore, SessionsStore } from 'src/app/core/stores';
import {
  ConfirmDialogComponent,
  IAdminTableColumn,
  SharedModule,
  imgPreloader,
  jpgImg,
  logoAvatar,
  pdfImg,
  pngImg,
  videoImg,
  zipImg,
} from 'src/app/shared';
import {
  ButtonComponent,
  ButtonSize,
  ButtonStyle,
  ButtonType,
  ToastComponent,
} from 'src/app/standalone/shared';
import { NewAssetComponent } from 'src/app/standalone/shared/components/asset';

@Component({
  selector: 'app-event-session-assets',
  standalone: true,
  imports: [SharedModule, ButtonComponent, ToastComponent],
  templateUrl: './event-session-assets.component.html',
  styleUrl: './event-session-assets.component.scss',
})
export class EventSessionAssetsComponent implements OnInit {
  loading = signal<boolean>(true);
  loadingEntries = signal<boolean>(true);
  columns = signal<IAdminTableColumn[]>([
    {
      field: 'title',
      template: 'titleCell',
      titleTranslationKey: 'adminCourseMedia.tableTitle',
      sortable: true,
    },
    { field: 'type', titleTranslationKey: 'adminCourseMedia.type' },
    { field: 'isGlobal', template: 'statusCell', titleTranslationKey: 'Global' },
    { field: 'actions', template: 'actionsCell' },
  ]);
  allAssets = signal<IAsset[]>(null);
  entries = signal<IAsset[]>(null);
  totalRecords = signal<number>(0);
  pageSize = signal<number>(10);
  imgPreloader = imgPreloader;
  logoAvatar = logoAvatar;
  buttonSize = ButtonSize;
  buttonType = ButtonType;
  buttonStyle = ButtonStyle;

  constructor(
    private sessionAssetsService: SessionAssetsService,
    private sessionsStore: SessionsStore,
    private eventsStore: EventsStore,
    private dialogService: DialogService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    public hubsStore: HubsStore,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.fetchSessionAssets();
    this.loading.set(false);
    this.loadingEntries.set(false);
  }

  async onSearchTermChange(keyword: string): Promise<void> {
    this.loadingEntries.set(true);
    const newEntries: IAsset[] = this.allAssets().filter((asset: IAsset) =>
      asset._title_.includes(keyword.toLowerCase()),
    );
    this.entries.set(newEntries);
    this.loadingEntries.set(false);
  }

  async openAttachAssetsDialog(): Promise<void> {
    const dialogRef = this.dialogService.open(AttachAssetsDialogComponent, {
      width: '90%',
      height: '80%',
      closable: false,
      styleClass: 'attach-assets-dialog',
      data: { isForLibrary: true },
    });
    await dialogRef.onClose.pipe(take(1)).toPromise();
    this.loadingEntries.set(true);
    this.entries.set([]);
    await this.fetchSessionAssets();
    this.loadingEntries.set(false);
  }

  async onNewAsset(): Promise<void> {
    const newAssetDialogRef: DynamicDialogRef = this.dialogService.open(NewAssetComponent, {
      styleClass: 'new-library-asset-dialog',
      closable: false,
      data: {
        session: this.sessionsStore.adminSession,
      },
    });

    const newAssetIds: string[] = await newAssetDialogRef.onClose.pipe(take(1)).toPromise();
    if (newAssetIds?.length) {
      const hubUrl: string = this.hubsStore.useHubUrl;
      const eventLink: string = this.eventsStore.adminEvent.link;
      const sessionId: string = this.sessionsStore.adminSession.id;
      this.router.navigate([
        `/${hubUrl}/admin/event/${eventLink}/content/sessions/${sessionId}/session-assets/${newAssetIds.at(-1)}`,
      ]);
    }
  }

  removeEntry(assetForDeleting: IAsset): void {
    this.entries.update((entries: IAsset[]) =>
      entries.filter((asset: IAsset) => asset.id !== assetForDeleting.id),
    );
    this.totalRecords.update((value) => value - 1);
  }

  getImagePlaceholder(asset: IAsset): string {
    if (asset.type === AssetType.VIDEO) {
      return videoImg;
    }

    switch (asset.fileType) {
      case AssetFileTypes.PNG:
        return pngImg;
      case AssetFileTypes.JPG:
        return jpgImg;
      case AssetFileTypes.PDF:
        return pdfImg;
      case AssetFileTypes.ZIP:
        return zipImg;
      default:
        return pngImg;
    }
  }

  edit(event: Event, entry: IAsset): void {
    event.stopPropagation();
    const hubUrl: string = this.hubsStore.useHubUrl;
    const eventLink: string = this.eventsStore.adminEvent.link;
    const sessionId: string = this.sessionsStore.adminSession.id;

    this.router.navigate(
      [
        `/${hubUrl}/admin/event/${eventLink}/content/sessions/${sessionId}/session-assets/${entry.id}`,
      ],
      {
        relativeTo: this.route,
        state: {
          assetId: entry.id,
        },
      },
    );
  }

  async remove(event: Event, entry: IAsset): Promise<void> {
    event.stopPropagation();
    const deleteDialogRef = this.dialogService.open(ConfirmDialogComponent, {
      closable: false,
      styleClass: 'confirm-dialog',
      data: {
        titleKey: 'adminSessionAsset.deleteAsset',
        descriptionKey: 'adminSessionAsset.deleteAssetText',
        confirmBtnKey: 'adminSessionAsset.confirmBtn',
        cancelBtnKey: 'adminSessionAsset.cancelBtn',
        entry,
      },
    });

    const result: 'confirm' | 'cancel' = await deleteDialogRef.onClose.pipe(take(1)).toPromise();
    if (result === 'confirm') {
      try {
        const deleteResult = this.sessionAssetsService.delete(
          this.eventsStore.adminEvent.id,
          this.sessionsStore.adminSession.id,
          entry.id,
        );
        if (deleteResult) {
          this.removeEntry(entry);

          this.messageService.add({
            severity: 'success',
            summary: this.translateService.instant('success'),
            detail: this.translateService.instant('adminSessionAsset.successDeleteAsset'),
            styleClass: 'custom-toast',
          });
        }
      } catch (error) {
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('error'),
          detail: this.translateService.instant('adminSessionAsset.errorDeleteAsset'),
          styleClass: 'custom-toast',
        });
      }
    }
  }

  onRowClicked(id: string): void {
    const hubUrl: string = this.hubsStore.useHubUrl;
    const eventLink: string = this.eventsStore.adminEvent.link;
    const sessionId: string = this.sessionsStore.adminSession.id;

    this.router.navigate(
      [`/${hubUrl}/admin/event/${eventLink}/content/sessions/${sessionId}/session-assets/${id}`],
      {
        relativeTo: this.route,
        state: {
          assetId: id,
        },
      },
    );
  }

  private async fetchSessionAssets(): Promise<void> {
    const sessionAssets: ISessionAsset[] =
      await this.sessionAssetsService.getAllSessionAssetsBySessionAndEventId(
        this.sessionsStore.adminSession.id,
        this.eventsStore.adminEvent.id,
      );
    const sessionAssetIds: string[] = sessionAssets.map((sessionAsset) => sessionAsset.assetId);
    const allAssets: IAsset[] = await this.sessionAssetsService.getAssetsByIds(sessionAssetIds);
    this.allAssets.set(allAssets);
    this.entries.set(allAssets);
    this.totalRecords.set(allAssets.length);
  }
}
