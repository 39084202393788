export const systemEmailUserMergeTags = [
  'user.firstName',
  'user.lastName',
  'user.email',
  'user.company',
  'user.position',
  'user.bio',
  'user.phone',
  'user.department',
  'user.street',
  'user.city',
  'user.postcode',
  'user.country',
  'user.website',
  'user.facebook',
  'user.instagram',
  'user.linkedin',
  'user.twitter',
  'user.xing',
  'user.youtube',
  'user.calendly',
];

export const eventPageUserMergeTags = [
  'user.salutation',
  'user.firstName',
  'user.lastName',
  'user.email',
  'user.company',
  'user.position',
  'user.department',
  'user.bio',
  'user.street',
  'user.city',
  'user.postcode',
  'user.country',
  'user.phone',
  'user.mobile',
  'user.emergencyContactName',
  'user.emergencyContactPhone',
  'user.travelDocument',
  'user.passportNumber',
  'user.dateOfIssue',
  'user.dateOfExpiry',
  'user.vegetarian',
  'user.birthDate',
  'user.nationality',
];

export const brandMergeTags = [
  'brand.name',
  'brand.slogan',
  'brand.link',
  'brand.email',
  'brand.website',
  'brand.logo',
  'brand.description',
  'brand.facebook',
  'brand.instagram',
  'brand.linkedin',
  'brand.twitter',
  'brand.xing',
  'brand.youtube',
  'brand.calendly',
];

export const systemEmailEventMergeTags = [
  'event.title',
  'event.tagline',
  'event.start',
  'event.end',
  'event.duration',
  'event.timezone',
  'event.location',
  'event.link',
  'event.invitationURL',
  'event.logoDark',
  'event.icon',
  'event.banner',
  'event.primaryColor',
  'event.accentColor',
  'event.description',
  'event.shortDescription',
  'event.email',
  'event.emailName',
  'event.eventbriteEventId',
  'event.eventbriteToken',
  'event.facebook',
  'event.linkedin',
  'event.xing',
];

export const eventPageEventMergeTags = [
  'event.title',
  'event.tagline',
  'event.start',
  'event.end',
  'event.timezone',
  'event.location',
  'event.link',
  'event.logoDark',
  'event.icon',
  'event.banner',
  'event.primaryColor',
  'event.accentColor',
  'event.description',
  'event.shortDescription',
  'event.email',
];

export const courseMergeTags = [
  'course.title',
  'course.tagline',
  'course.link',
  'course.currency',
  'course.price',
  'course.start',
  'course.end',
  'course.duration',
  'course.banner',
  'course.featuredImage',
  'course.video',
  'course.description',
  'course.features',
];

export const hubMergeTags = [
  'hub.banner',
  'hub.icon',
  'hub.logoDark',
  'hub.primaryColor',
  'hub.tagline',
  'hub.title',
  'hub.url',
];

export const appointmentMergeTags = [
  'appointment.meetingName',
  'appointment.description',
  'appointment.startDate',
  'appointment.endDate',
];

export const userEventMergeTags = [
  'userEvent.role',
  'userEvent.ticketId',
  'userEvent.ticketPrice',
  'userEvent.hotel',
  'userEvent.roomType',
  'userEvent.roomPartner',
  'userEvent.preferredAirport',
  'userEvent.alternativeAirport',
  'userEvent.sessionRegistration',
  'userEvent.clothingSize',
  'userEvent.shoeSize',
  'userEvent.createdAt',
  'userEvent.qrCode',
];
