import { Component } from '@angular/core';

@Component({
  selector: 'app-event-dashboard',
  standalone: true,
  imports: [],
  templateUrl: './event-dashboard.component.html',
  styleUrl: './event-dashboard.component.scss',
})
export class EventDashboardComponent {}
