export interface ILanguage {
  id: string;
  _title_: string;
  title: string;
  code: string;
  translations: any;
  updatedAt: any;
  updatedBy: any;
  createdAt: any;
  createdBy: any;
}
