import { Injectable } from '@angular/core';
import { Firestore, IFirestore, Timestamp } from 'src/app/firebase';
import { IDevice } from '../../models/device/device.model';
import { API_ROUTES as apiRoutes } from 'src/app/shared';
import { useDevice } from '../../utils';

@Injectable({
  providedIn: 'root',
})
export class DevicesService {
  private firestore: IFirestore;

  private deviceInfo = useDevice();

  constructor() {
    this.firestore = Firestore();
  }

  async getByWebPushToken(token: string): Promise<IDevice> {
    try {
      const result: IDevice[] = (
        await this.firestore.collection(apiRoutes.devices).where('webPushToken', '==', token).get()
      ).docs.map((doc) => doc.data() as IDevice);

      return result[0];
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async getByUserId(userId: string): Promise<IDevice> {
    try {
      const result: IDevice[] = (
        await this.firestore.collection(apiRoutes.devices).where('userId', '==', userId).get()
      ).docs.map((doc) => doc.data() as IDevice);

      return result[0];
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  public async createDevice(
    token: string = null,
    userId: string = null,
    webPushPermission: boolean = false,
  ) {
    console.log('========================================   ADD USER TOKEN....');
    const devicePayload = {
      id: null,
      userId: userId,
      webPushToken: token,
      webPushPermission: true,
      deviceVersion: null,
      osVersion: null,
      os: this.deviceInfo.isMobilePlatform ? this.deviceInfo.mobileOS : this.deviceInfo.desktopOS,
      deviceName: null,
      language: null,
      createdAt: Timestamp.now(),
      createdBy: null,
      updatedAt: null,
      updatedBy: null,
    } as IDevice;
    await this.create(devicePayload);

    return devicePayload;
  }

  public async updateDevice(
    deviceId: string,
    token: string,
    userId: string = null,
    webPushPermission: boolean = false,
  ) {
    console.log('========================================   UPDATE USER TOKEN....');
    let devicePayload = {
      webPushPermission: webPushPermission,
      os: this.deviceInfo.isMobilePlatform ? this.deviceInfo.mobileOS : this.deviceInfo.desktopOS,
      updatedAt: Timestamp.now(),
      updatedBy: null,
    };

    if (userId) devicePayload['userId'] = userId;

    if (userId) devicePayload['webPushToken'] = token;

    await this.update(deviceId, devicePayload);
    return devicePayload;
  }

  public async create(device: IDevice): Promise<IDevice> {
    try {
      const preDeviceReqDoc = this.firestore.collection(apiRoutes.devices).doc();
      device.id = preDeviceReqDoc.id;
      await preDeviceReqDoc.set(device);
      return device;
    } catch (error) {
      throw error;
    }
  }

  public async update(deviceId: string, device: any): Promise<IDevice> {
    try {
      const deviceDocument = this.firestore.collection(apiRoutes.devices).doc(deviceId);
      await deviceDocument.update({ ...device });

      return device;
    } catch (error) {
      throw error;
    }
  }
}
