import { Component, OnDestroy, signal, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { IReactionDisposer, reaction, autorun } from 'mobx';
import { MessageService } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { TranslateService } from '@ngx-translate/core';

import { UsersStore, HubsStore, EventsStore } from 'src/app/core/stores';
import {
  ThemesService,
  AuthenticationService,
  MixpanelService,
  IntercomService,
  TranslationsService,
  UsersService,
  EventsService,
  StripeService,
} from 'src/app/core/services';
import { AppStore } from 'src/app/app.store';
import { environment } from 'src/environments/environment';
import { goToLink } from '../../utils';
import { IEvent, IGeneralSettings } from '../../models/index';
import { ServiceWorkerService } from '../../services/sw/service-worker.service';
@Component({
  selector: 'mobile-header',
  templateUrl: './mobile-header.component.html',
  styleUrls: ['./mobile-header.component.scss'],
})
export class MobileHeaderComponent implements OnDestroy {
  @ViewChild('profilePopUp', { static: true }) profilePopUp: OverlayPanel;

  languages: string[] = [];
  currentLanguage: string;
  selectLanguages: any[] = [];
  selectedLanguage: any = null;
  isSearchVisible: boolean;
  isSearchAvailable = false;
  isAuthenticated$: Observable<boolean>;
  isAdminPanel = false;
  inBrandDashboard = false;
  logo: string;
  generalSystemSettings: IGeneralSettings = null;
  searchLoading = true;
  hideEventSearch = false;
  isGlobalProfileVisible = false;
  isGlobalHelpVisible = false;
  billingPortalLoading = false;
  isLogoutScreen = signal(false);

  private disposer: IReactionDisposer;

  constructor(
    public usersStore: UsersStore,
    public themesService: ThemesService,
    public hubsStore: HubsStore,
    public eventsStore: EventsStore,
    private appStore: AppStore,
    private authenticationService: AuthenticationService,
    private router: Router,
    private titleService: Title,
    private mixpanelService: MixpanelService,
    private intercomService: IntercomService,
    private translationsService: TranslationsService,
    private usersService: UsersService,
    private eventsService: EventsService,
    public serviceWorkerService: ServiceWorkerService,
    private stripeService: StripeService,
    private translateService: TranslateService,
    private messageService: MessageService,
  ) {
    this.isAuthenticated$ = this.authenticationService.isAuthenticated$;
    this.isAdminPanel = this.router.url.indexOf('admin/') > -1;
    this.isLogoutScreen.set(this.router.url.indexOf('logout') > -1);

    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.isAdminPanel = val.urlAfterRedirects.includes('admin/');
        this.isLogoutScreen.set(val.urlAfterRedirects.includes('logout'));
        this.inBrandDashboard = val.url.includes('mybrand');
        this.setLogoAccordingToRoute();
      }
    });

    this.disposer = reaction(
      () => this.appStore.isSearchVisible,
      (value) => {
        this.isSearchAvailable = value;
      },
    );

    autorun(() => {
      this.setLogoAccordingToRoute();
      this.setProfilePopupItemsVisibility();
      this.checkEventSearchSettings();
    });

    this.languages = this.translationsService.getLanguages;
    this.currentLanguage = this.translationsService.getCurrentLanguage;

    this.selectLanguages = this.translationsService.getLanguages.map((l) => {
      const item = { name: this.translationsService.getTranslationSync('languages.' + l), code: l };
      if (this.currentLanguage === l) {
        this.selectedLanguage = item;
      }
      return item;
    });
  }

  setLogoAccordingToRoute(): void {
    if (this.isAdminPanel) {
      this.setLogoFromSystemSettings();
    } else if (
      this.eventsStore.event &&
      this.hasEventLogo() &&
      this.eventsStore.isEventRegistered
    ) {
      this.logo =
        this.themesService.activeTheme.name === 'dark'
          ? this.eventsStore.event.logoDark
          : this.eventsStore.event.logoLight;
    } else if (this.hubsStore.hub && this.hasHubLogo()) {
      this.logo =
        this.themesService.activeTheme.name === 'dark'
          ? this.hubsStore.hub.logoDark
          : this.hubsStore.hub.logoLight;
    } else {
      this.setLogoFromSystemSettings();
    }
  }

  setLogoFromSystemSettings(): void {
    if (this.themesService.activeTheme.name === 'dark') {
      this.logo = this.hubsStore.hub?.logoDark
        ? this.hubsStore.hub?.logoDark
        : this.themesService.systemAppearanceSettings?.logo;
    } else {
      this.logo = this.hubsStore.hub?.logoLight
        ? this.hubsStore.hub?.logoLight
        : this.themesService.systemAppearanceSettings?.logoLight;
    }
  }

  toggleSearch(): void {
    this.isSearchVisible = !this.isSearchVisible;
  }

  openProfile(): void {
    this.router.navigate([`${this.hubsStore.useHubUrl}/profile`], {
      state: { activeTab: 'profile' },
    });
  }

  async openView(viewPath: string): Promise<void> {
    this.profilePopUp.hide();

    if (viewPath === '/mybrand') {
      const brand = await this.usersService.getLoggedUserBrandData(this.usersStore.user?.brandId);
      this.router.navigate([`${this.hubsStore.useHubUrl}${viewPath}/${brand.link}`]);
    } else {
      this.router.navigate([`${this.hubsStore.useHubUrl}/${viewPath}`]);
    }
  }

  async logout(): Promise<void> {
    this.profilePopUp.hide();
    this.authenticationService.signOut();
    this.usersStore.reset();
    // send data to intercom
    this.intercomService.shutdown();
    this.intercomService.boot(this.appStore.environment.intercom.apiKey);
  }

  async setLanguage(lang: any): Promise<void> {
    await this.translationsService.setLanguage(lang.code);
  }

  onNavigateToHelp(): void {
    this.profilePopUp.hide();
    goToLink(environment.helpLink);
    const title = this.titleService.getTitle();
    // send event to mixpanel analytics
    this.mixpanelService.helpCenterClick(title, this.router.url);
  }

  onLogoClick(): void {
    if (this.eventsStore.eventId && this.eventsStore.isEventRegistered) {
      this.router.navigate([`${this.hubsStore.useHubUrl}/events/${this.eventsStore.event.id}`]);
    } else {
      this.router.navigate([`${this.hubsStore.useHubUrl}/`]);
    }
  }

  async setProfilePopupItemsVisibility(): Promise<void> {
    this.isGlobalHelpVisible =
      this.usersStore.user?.role !== 'user' ||
      (!this.appStore.generalSystemSettings.isPrivate && this.usersStore.user?.role === 'user');

    this.isGlobalHelpVisible =
      this.usersStore.user?.role !== 'user' ||
      (!this.appStore.generalSystemSettings.hideHelp && this.usersStore.user?.role === 'user');
  }

  openWVSigninDialog(): void {
    this.router.navigate([`${this.hubsStore.useHubUrl}/signin`], {
      queryParams: { salesforceSSO: true, hubUrl: this.hubsStore.useHubUrl },
    });
  }

  onSignUp(): void {
    this.router.navigate([`${this.hubsStore.useHubUrl}/signup`], {
      state: { url: this.router.url },
    });
  }

  onSignIn(): void {
    this.router.navigate([`${this.hubsStore.useHubUrl}/signin`], {
      state: { url: this.router.url },
    });
  }

  ngOnDestroy(): void {
    this.inBrandDashboard = this.router.url.indexOf('mybrand') > -1;
    this.disposer();
  }

  private async checkEventSearchSettings(): Promise<void> {
    const eventId = this.eventsStore.eventId;
    if (eventId) {
      const event: IEvent[] = await this.eventsService.getById(eventId);

      if (event.length && event[0].hideEventSearch) {
        this.hideEventSearch = true;
        this.searchLoading = false;
      } else {
        this.hideEventSearch = false;
        this.searchLoading = false;
      }
    }
    this.searchLoading = false;
  }

  private hasEventLogo(): boolean {
    const logo =
      this.themesService.activeTheme.name === 'dark'
        ? this.eventsStore.event.logoDark
        : this.eventsStore.event.logoLight;
    return !!logo;
  }

  private hasHubLogo(): boolean {
    const logo =
      this.themesService.activeTheme.name === 'dark'
        ? this.hubsStore.hub.logoDark
        : this.hubsStore.hub.logoLight;
    return !!logo;
  }

  async openCustomerProfile(): Promise<void> {
    try {
      this.billingPortalLoading = true;
      let returnUrl: string;
      if (environment.env === 'LOCAL') {
        returnUrl = `http://127.0.0.1:4200/${this.hubsStore.useHubUrl}/profile`;
      } else {
        returnUrl = `${this.hubsStore.environmentBaseUrl}/${this.hubsStore.useHubUrl}/profile`;
      }

      await this.stripeService.checkStripeCustomer();
      const isAdmin = (await this.usersService.getOne(this.usersStore.user.id)).hasEverSubscribed;
      const url: string = await this.stripeService.getCustomerPortalLink(
        this.usersStore.user.stripeId,
        returnUrl,
        isAdmin,
      );
      goToLink(url);
      this.billingPortalLoading = false;
    } catch (error) {
      this.showToastMessage('error', `stripeErrorCodes.${error.message}`);
    }
  }

  private showToastMessage(severity: 'success' | 'error', detail: string): void {
    const fallbackTranslationKey = 'toasters.error';
    let message;
    this.translateService.get(detail).subscribe((translation) => {
      message =
        translation === detail
          ? this.translateService.instant(fallbackTranslationKey)
          : translation;
    });
    this.messageService.add({
      severity: severity,
      summary: this.translateService.instant(severity),
      detail: this.translateService.instant(message),
      styleClass: 'custom-toast',
    });
  }
}
