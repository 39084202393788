import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DailyCall, DailyLanguageSetting } from '@daily-co/daily-js';

import { ISession, IStage } from 'src/app/core/models';
import {
  IntercomService,
  ChatService,
  DailyCoService,
  StorageService,
  StagesService,
} from 'src/app/core/services';
import { EventsStore, UsersStore } from 'src/app/core/stores';
import { AppStore } from 'src/app/app.store';

@Component({
  selector: 'app-daily-meeting',
  templateUrl: './daily-meeting.component.html',
  styleUrls: ['./daily-meeting.component.scss'],
})
export class DailyMeetingComponent implements OnInit, OnDestroy {
  @ViewChild('iframe', { static: true, read: ElementRef }) iframe: ElementRef;

  private unsubscribe$: Subject<void> = new Subject<void>();
  private daily: DailyCall;
  private session: ISession;
  private notification: { url: string; token: string };
  private stage: IStage;
  private playStreamingIconUrl: string;
  private stopStreamingIconUrl: string;

  constructor(
    private config: DynamicDialogConfig,
    private dailyCoService: DailyCoService,
    private translateService: TranslateService,
    private ref: DynamicDialogRef,
    private chatService: ChatService,
    private intercomService: IntercomService,
    private usersStore: UsersStore,
    private appStore: AppStore,
    private storageService: StorageService,
    private stagesService: StagesService,
    private eventsStore: EventsStore,
  ) {}

  ngOnInit(): void {
    this.intercomService.shutdown();
    const lang = (this.translateService.currentLang as DailyLanguageSetting) || 'en';
    this.session = this.config?.data?.session;
    this.notification = this.config?.data?.notification;
    this.stage = this.config?.data?.stage;
    if (!this.session?.meetingRoomUrl && !this.notification?.url) {
      this.ref.close();
      return;
    }
    // create call wrap
    this.daily = this.dailyCoService.createCallWrap(this.iframe.nativeElement, lang);
    // set theme for meeting room
    this.dailyCoService.setTheme(this.daily);
    if (this.session) {
      this.initWithSession();
    } else {
      this.openDaily(this.notification.url, this.notification.token);
    }

    this.daily.on('left-meeting', () => {
      this.ref.close();
      this.chatService.updateChatState();
      this.intercomService.boot(this.appStore.environment.intercom.apiKey, this.usersStore.user);
    });

    this.daily.on('custom-button-click', (ev) => {
      if (ev.button_id === 'startLiveStreaming') {
        this.startLiveStreaming();
        this.stagesService.update(this.eventsStore.eventId, this.stage.id, {
          ...this.stage,
          isLiveStream: true,
        });
      }

      if (ev.button_id === 'stopLiveStreaming') {
        this.stopLiveStreaming();
        this.stagesService.update(this.eventsStore.eventId, this.stage.id, {
          ...this.stage,
          isLiveStream: false,
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.daily?.destroy();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.chatService.updateChatState();
    this.intercomService.boot(this.appStore.environment.intercom.apiKey, this.usersStore.user);
  }

  private initWithSession(): void {
    if (this.session.moderators.includes(this.usersStore.userId)) {
      this.createAdmin();
    } else {
      this.createParticipant();
    }
  }

  private async createParticipant(): Promise<void> {
    const userName = `${this.usersStore.user.firstName} ${this.usersStore.user.lastName}`;
    const { token } = await this.dailyCoService
      .createToken(this.session.meetingRoomName, userName, this.usersStore.userId)
      .toPromise();
    this.openDaily(this.session.meetingRoomUrl, token);
  }

  private async createAdmin(): Promise<void> {
    const userName = `${this.usersStore.user.firstName} ${this.usersStore.user.lastName}`;
    const { token } = await this.dailyCoService
      .createToken(
        this.session.meetingRoomName,
        userName,
        this.usersStore.userId,
        true,
        this.session.isRecording,
        this.session?.recordingType,
      )
      .toPromise();
    if (this.session.isLiveStreaming) {
      this.openDailyWithStreaming(this.session.meetingRoomUrl, token);
    } else {
      this.openDaily(this.session.meetingRoomUrl, token);
    }
  }

  private async openDailyWithStreaming(url: string, token: string): Promise<void> {
    this.playStreamingIconUrl = await this.storageService.getOne(
      'settings/daily-co',
      'play-ls.svg',
    );
    this.stopStreamingIconUrl = await this.storageService.getOne(
      'settings/daily-co',
      'stop-ls.svg',
    );
    this.daily.join({
      url,
      token,
      customTrayButtons: {
        startLiveStreaming: {
          iconPath: this.playStreamingIconUrl,
          label: 'Start Stream',
          tooltip: 'Start Live Streaming',
        },
      },
    });
    this.chatService.setChatState(false);
  }

  private openDaily(url: string, token: string): void {
    this.daily.join({ url, token });
    this.chatService.setChatState(false);
  }

  private startLiveStreaming(): void {
    this.daily.updateCustomTrayButtons({
      stopLiveStreaming: {
        iconPath: this.stopStreamingIconUrl,
        label: 'Stop Stream',
        tooltip: 'Stop Live Streaming',
      },
    });
    this.daily.startLiveStreaming({ rtmpUrl: `${this.stage.streamServer}${this.stage.streamKey}` });
  }

  private stopLiveStreaming(): void {
    this.daily.updateCustomTrayButtons({
      startLiveStreaming: {
        iconPath: this.playStreamingIconUrl,
        label: 'Start Stream',
        tooltip: 'Start Live Streaming',
      },
    });
    this.daily.stopLiveStreaming();
  }
}
