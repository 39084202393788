import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-count-down',
  templateUrl: './count-down.component.html',
  styleUrls: ['./count-down.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountDownComponent implements OnInit, OnDestroy {
  @Input() targetDate: Date;

  secondsToTargetDay: string;
  minutesToTargetDay: string;
  hoursToTargetDay: string;
  daysToTargetDay: string;

  private milliSecondsInASecond = 1000;
  private hoursInADay = 24;
  private minutesInAnHour = 60;
  private secondsInAMinute = 60;
  private timeDifference: number;
  private subscription: Subscription;

  ngOnInit(): void {
    this.subscription = interval(1000)
      .pipe(
        tap(() => {
          this.getTimeDifference();
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private getTimeDifference(): void {
    this.timeDifference = this.targetDate.getTime() - new Date().getTime();
    this.allocateTimeUnits(this.timeDifference);
  }

  private allocateTimeUnits(timeDifference): void {
    this.secondsToTargetDay = String(
      Math.floor((timeDifference / this.milliSecondsInASecond) % this.secondsInAMinute),
    ).padStart(2, '0');
    this.minutesToTargetDay = String(
      Math.floor(
        (timeDifference / (this.milliSecondsInASecond * this.minutesInAnHour)) %
          this.secondsInAMinute,
      ),
    ).padStart(2, '0');

    this.hoursToTargetDay = String(
      Math.floor(
        (timeDifference /
          (this.milliSecondsInASecond * this.minutesInAnHour * this.secondsInAMinute)) %
          this.hoursInADay,
      ),
    ).padStart(2, '0');

    this.daysToTargetDay = String(
      Math.floor(
        timeDifference /
          (this.milliSecondsInASecond *
            this.minutesInAnHour *
            this.secondsInAMinute *
            this.hoursInADay),
      ),
    ).padStart(2, '0');
  }
}
