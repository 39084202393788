<div class="header">
  <div class="title">
    {{ 'attachAssetsDialog.attachAssets' | translate }}
  </div>
  <div class="btn-container">
    <button
      class="attach primary-text-color"
      pButton
      pRipple
      label="{{ 'attachAssetsDialog.attach' | translate }}"
      [disabled]="!selectedEntries.length"
      (click)="attach()"></button>
    <i
      class="fa-regular fa-xmark close-icon"
      (click)="onCloseDialog()"></i>
  </div>
</div>
<div class="container">
  <admin-table
    checkBoxSelection="true"
    emptyStateKey="attachAssetsDialog.emptyState"
    emptyStateIcon="far fa-paperclip"
    scssClass="attach-assets-table table-for-attach paginator-border-bottom-right-radius show-row-border-bottom"
    [columns]="columns"
    [entries]="assets"
    [paginator]="false"
    [(selectedEntries)]="selectedEntries">
    <ng-template
      let-entry
      epTemplateKey="titleCell">
      <div class="title-cell">
        <div *ngIf="entry.type !== assetType.EVENT">
          <img
            *ngIf="entry.image"
            class="banner"
            [defaultImage]="imgPreloader"
            [lazyLoad]="entry.image | imageSize" />
          <div
            *ngIf="!entry.image"
            class="placeholder-container">
            <img
              class="placeholder"
              [src]="getImagePlaceholder(entry) | imageSize" />
          </div>
        </div>
        <div
          *ngIf="entry.type === assetType.EVENT"
          class="banner">
          <app-asset-event-banner
            [asset]="entry"
            [containerStyles]="assetEventBannerContainerStyles"
            [monthStyles]="assetEventBannerMonthStyles"
            [dateStyles]="assetEventBannerDateStyle">
          </app-asset-event-banner>
        </div>
        <p class="asset-title">{{ entry.title }}</p>
      </div>
    </ng-template>
    <ng-template
      let-entry
      epTemplateKey="statusCell">
      <i
        class="{{
          (entry.isGlobal ? 'far fa-circle-check' : 'far fa-circle-xmark') | translate
        }}"></i>
    </ng-template>
  </admin-table>
</div>
