import { Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-tag',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './tag.component.html',
  styleUrl: './tag.component.scss',
})
export class TagComponent {
  rounded = input<boolean>();
  iconClass = input<string>();
  tagText = input<string>();
  filled = input<boolean>();
  bgColor = input<string>();
  textColor = input<string>('#FFFFFF');
}
