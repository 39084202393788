import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-save-discard-actions',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './save-discard-actions.component.html',
  styleUrls: ['./save-discard-actions.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({ opacity: 0 })),
      transition(':enter, :leave', [animate(300)]),
    ]),
    trigger('rotateAndReplace', [
      state('initial', style({ transform: 'rotateX(0)' })),
      state('spinner', style({ transform: 'rotateX(360deg)' })),
      transition('initial => spinner', [animate('500ms')]),
      transition('spinner => initial', [animate('500ms')]),
    ]),
    trigger('buttonHover', [
      state('initial', style({ transform: 'scale(1)' })),
      state('hovered', style({ transform: 'scale(1.05)' })),
      transition('initial => hovered', animate('300ms')),
      transition('hovered => initial', animate('300ms')),
    ]),
  ],
})
export class SaveDiscardActionsComponent implements OnChanges {
  @Input() btnDiscardText = 'shared.discard';
  @Input() btnConfirmText = 'shared.confirm';
  @Input() isShow = false;
  @Input() isLoading = false;
  @Output() discard = new EventEmitter<void>();
  @Output() confirm = new EventEmitter<void>();

  buttonState: 'initial' | 'spinner' = 'initial';
  buttonHoverState: 'initial' | 'hovered' = 'initial';

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.isLoading?.previousValue !== !!changes.isLoading?.currentValue) {
      this.toggleButtonState();
    }
  }

  onDiscard(): void {
    this.discard.emit();
  }

  onConfirm(): void {
    this.confirm.emit();
  }

  toggleButtonState(): void {
    this.buttonState = this.buttonState === 'initial' ? 'spinner' : 'initial';
  }
}
