import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

import {
  ISubscription,
  IEventSubscription,
  ICourseSubscription,
  ITicket,
  IEvent,
  ICourse,
} from 'src/app/core/models';
import { SubscriptionsService, EventsService, CoursesService } from 'src/app/core/services';
import { CoursesStore, EventsStore, HubsStore, TicketsStore } from 'src/app/core/stores';
import { IAdminTableColumn } from 'src/app/shared';
import { ButtonSize } from 'src/app/standalone';

@Component({
  selector: 'app-attach-subscriptions-dialog',
  templateUrl: './attach-subscriptions-dialog.component.html',
  styleUrls: ['./attach-subscriptions-dialog.component.scss'],
})
export class AttachSubscriptionsDialogComponent implements OnInit {
  loadingEntries = true;
  attachLoading = false;
  entries: ISubscription[] = [];
  selectedEntries: ISubscription[] = [];
  totalRecords = 0;
  pageSize = 25;
  isCourse = false;
  columns: IAdminTableColumn[] = [
    { field: 'name', titleTranslationKey: 'adminSubscriptions.tableCells.plan' },
  ];
  buttonSize = ButtonSize;

  get ticket(): ITicket {
    return this.ticketsStore.adminTicket;
  }

  get event(): IEvent {
    return this.eventsStore.adminEvent;
  }

  get course(): ICourse {
    return this.coursesStore.adminCourse;
  }

  constructor(
    private subscriptionService: SubscriptionsService,
    private ticketsStore: TicketsStore,
    private messageService: MessageService,
    private translateService: TranslateService,
    private eventsService: EventsService,
    private eventsStore: EventsStore,
    private dinamicDialogConfig: DynamicDialogConfig,
    private coursesService: CoursesService,
    private coursesStore: CoursesStore,
    private hubsStore: HubsStore,
    private ref: DynamicDialogRef,
  ) {}

  async ngOnInit(): Promise<void> {
    this.isCourse = this.dinamicDialogConfig.data?.isCourse;
    await Promise.all([this.fetchSubscibtions()]);
  }

  public async fetchSubscibtions(): Promise<void> {
    const subscriptions = await this.subscriptionService.getByHubId(this.hubsStore.hubId);
    this.entries = subscriptions;
    let subscriptionIds: string[] = [];
    if (!this.isCourse) {
      subscriptionIds = (await this.eventsService.getEventSubscriptions(this.event.id)).map(
        (s: IEventSubscription) => s.subscriptionId,
      );
    } else {
      subscriptionIds = await (
        await this.coursesService.getCourseSubscriptions(this.course.id)
      ).map((s: ICourseSubscription) => s.subscriptionId);
    }
    if (subscriptionIds.length > 0) {
      this.selectedEntries = await this.subscriptionService.getByIds(subscriptionIds);
    }

    this.totalRecords = this.entries.length;
    this.loadingEntries = false;
  }

  public async attach(): Promise<void> {
    this.attachLoading = true;
    try {
      const removedPlans = this.entries.filter(
        (entry) => !this.selectedEntries.find((selectedEntry) => entry.id == selectedEntry.id),
      );
      if (!this.isCourse) {
        await Promise.all(
          this.selectedEntries.map((entry) =>
            this.eventsService.addSubscriptionToEvent(this.event.id, entry),
          ),
        );
        await removedPlans.forEach(
          async (plan) =>
            await this.eventsService.removeSubscriptionToEvent(this.event.id, plan.id),
        );
      } else {
        await Promise.all(
          this.selectedEntries.map((entry) =>
            this.coursesService.addSubscriptionToCourse(this.course.id, entry),
          ),
        );
        await removedPlans.forEach(
          async (plan) =>
            await this.coursesService.removeSubscriptionToCourse(this.course.id, plan.id),
        );
      }

      this.messageService.add({
        severity: 'success',
        summary: this.translateService.instant('success'),
        detail: this.translateService.instant('adminEvents.planSuccessfullyAttached'),
        styleClass: 'custom-toast',
      });
      this.attachLoading = false;
    } catch (err) {
      console.log(err);
      this.attachLoading = false;
      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('error'),
        detail: this.translateService.instant('adminEvents.errorAttachPlans'),
        styleClass: 'custom-toast',
      });
    }
  }

  onCloseDialog(): void {
    this.ref.close();
  }
}
