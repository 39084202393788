import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { interval, Observable, Subject } from 'rxjs';
import { debounce, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { IReactionDisposer, reaction } from 'mobx';

import {
  SearchResultGroup,
  SearchResultGroupAndProvider,
  SearchResultItem,
  SearchService,
} from '../../services/search/search.service';
import { AppStore } from '../../../app.store';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit, OnDestroy {
  public currentSearchTerm: string = '';
  public isSearchVisible = false;
  public disposer: IReactionDisposer;
  public searchResults$: Observable<SearchResultGroup[] | undefined>;

  private searchTerm$ = new Subject<string>();

  constructor(
    private searchService: SearchService,
    private appStore: AppStore,
    private cd: ChangeDetectorRef,
  ) {
    this.disposer = reaction(
      () => this.appStore.isSearchVisible,
      (value) => {
        this.isSearchVisible = value;
        this.cd.detectChanges();
      },
    );

    this.searchResults$ = this.searchTerm$.pipe(
      distinctUntilChanged(),
      debounce((searchTerm) =>
        searchTerm && searchTerm.trim() !== '' ? interval(500) : interval(0),
      ),
      switchMap(async (searchTerm) => {
        if (!searchTerm || searchTerm.trim() === '') {
          return;
        } else {
          return await searchService.search(searchTerm);
        }
      }),
    );
  }

  ngOnInit(): void {
    this.isSearchVisible = this.appStore.isSearchVisible;
  }

  public onSearchQueryUpdate(event: Event): void {
    const searchTerm = (event.currentTarget as HTMLInputElement).value;
    this.searchTerm$.next(searchTerm);
  }

  public onSelectSearchResult(
    groupAndProvider: SearchResultGroupAndProvider,
    item: SearchResultItem,
  ): void {
    this.searchService.searchItemSelected(groupAndProvider, item);
    this.clearSearchResults();
  }

  public clearSearchAction(): void {
    this.clearSearchResults();
  }

  private clearSearchResults(): void {
    this.searchTerm$.next('');
    this.currentSearchTerm = '';
  }

  public ngOnDestroy(): void {
    this.disposer();
  }
}
