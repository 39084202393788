import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import {
  ChatConversation,
  ChatMessage,
  ChatMessageAttachment,
  IUser,
  IEvent,
} from 'src/app/core/models';
import { MixpanelService, OpenDialogService, ChatService } from 'src/app/core/services';
import { UsersStore, EventsStore, HubsStore } from 'src/app/core/stores';
import { isMobile } from 'src/app/core/utils/utils';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit, OnDestroy {
  public isChatEnabled = false;
  public isChatWindowOpen = false;
  public contextKey?: string;
  public unreadMessages?: number;
  public isChatAllowedForCurrentUser = false;

  private onDestroy$ = new Subject<void>();
  private chatConversations$ = new BehaviorSubject<ChatConversation[]>([]);
  private selectedChatConversationMessages$ = new BehaviorSubject<ChatMessage[]>([]);
  private selectedChatConversation?: ChatConversation;
  private selectedChatConversationMessagesSubescription?: Subscription;
  private chatConversationsSubscription?: Subscription;

  public get event(): IEvent {
    return this.eventsStore.event;
  }

  public get isMobile(): boolean {
    return isMobile();
  }

  public get isUserHasConversations(): boolean {
    return !!this.chatConversations$.getValue().length;
  }

  public get isChatAllowed(): boolean {
    // condition with event.hideChatPreviewScreen
    const condition =
      !this.event?.hideChatPreviewScreen ||
      (this.event?.hideChatPreviewScreen && this.isUserHasConversations);
    return this.isChatAllowedForCurrentUser && condition;
  }

  public get chatBubbleTooltip(): string {
    return this.event?.hideChatPreviewScreen && !this.isUserHasConversations
      ? this.translateService.instant('chat.bubble.tooltipTextForHideChatPreviewScreen')
      : '';
  }

  constructor(
    private chatService: ChatService,
    private router: Router,
    private usersStore: UsersStore,
    private mixpanelService: MixpanelService,
    private openDialogService: OpenDialogService,
    private eventsStore: EventsStore,
    private translateService: TranslateService,
    public hubsStore: HubsStore,
  ) {
    this.chatService
      .chatState()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((res) => {
        this.isChatEnabled = res.enabled;

        if (res.enabled) {
          // the chat is being enabled for the first time
          // so no chat conversations are subscribed
          if (!this.chatConversationsSubscription) {
            this.startListeningConversations(res.context.key, res.context.primaryColor);
          }
          // the chat is already enabled but the context changed,
          // so the current chatConversationSubscription needs to be unsubscribed
          // and a new subscription for the new context is required
          else if (this.contextKey !== res.context.key) {
            this.startListeningConversations(res.context.key, res.context.primaryColor);
          }
        } else {
          // the chat needs to be disabled
          // so if a previous chatConversationSubscription is active, it needs to be stoped

          this.contextKey = undefined;

          if (this.chatConversationsSubscription) {
            this.chatConversationsSubscription.unsubscribe();
            this.chatConversationsSubscription = undefined;
            this.unreadMessages = 0;
          }

          if (this.selectedChatConversationMessagesSubescription) {
            this.selectedChatConversationMessagesSubescription.unsubscribe();
            this.selectedChatConversationMessagesSubescription = undefined;
            this.selectedChatConversation = undefined;
          }
        }
      });
  }

  ngOnInit(): void {
    this.usersStore.currentUser
      .pipe(
        tap((user: IUser) => {
          this.isChatAllowedForCurrentUser = user?.allowChat;
        }),
        takeUntil(this.onDestroy$),
      )
      .subscribe();

    this.chatService
      .startNewChat()
      .pipe(
        tap(({ chatConversation }: { chatConversation: ChatConversation }) => {
          if (chatConversation) {
            this.isChatWindowOpen = true;
          }
        }),
        takeUntil(this.onDestroy$),
      )
      .subscribe();
  }

  chatConversations(): Observable<ChatConversation[]> {
    return this.chatConversations$;
  }

  selectedChatConversationMessage(): Observable<ChatMessage[]> {
    return this.selectedChatConversationMessages$;
  }

  toggleWindowVisibilityAction(): void {
    if (!this.isChatAllowed) {
      this.isChatWindowOpen = false;
    } else {
      this.isChatWindowOpen = !this.isChatWindowOpen;
    }
  }

  async onSendMessage(message: {
    text: string;
    attachment?: ChatMessageAttachment;
  }): Promise<void> {
    if (this.selectedChatConversation?.id && message && this.contextKey) {
      const messageId: string = await this.chatService.sendMessage(
        this.selectedChatConversation.id,
        message,
        this.contextKey,
      );
      // send event to mixpanel analytics
      this.mixpanelService.dmSent(
        this.selectedChatConversation.conversationWithUser,
        message,
        messageId,
      );
    }
  }

  onCloseWindow(): void {
    this.isChatWindowOpen = false;
  }

  public onNavigateTo(eventId: string): void {
    this.isChatWindowOpen = false;
    this.router.navigate([`/${this.hubsStore.useHubUrl}/events/${eventId}/attendees`]);
  }

  onChatConversationSelected(chatConversation: ChatConversation | undefined): void {
    this.selectedChatConversation = chatConversation;

    // start listening to chat conversation
    if (chatConversation) {
      if (this.selectedChatConversationMessagesSubescription) {
        this.selectedChatConversationMessagesSubescription.unsubscribe();
      }

      this.selectedChatConversationMessagesSubescription = this.chatService
        .chatMessages(chatConversation.id, this.contextKey)
        .subscribe((res) => this.selectedChatConversationMessages$.next(res));
    } else if (this.selectedChatConversationMessagesSubescription) {
      this.selectedChatConversationMessagesSubescription.unsubscribe();
      this.selectedChatConversationMessagesSubescription = undefined;
      this.selectedChatConversationMessages$.next([]);
    }

    this.updateUnreadMessagesCounter();
  }

  async onToggleMessageLike(chatMessage: ChatMessage): Promise<void> {
    if (!this.selectedChatConversation || !chatMessage) {
      return;
    }

    await this.chatService.toggleMessageLike(this.selectedChatConversation.id, chatMessage);
  }

  private startListeningConversations(contextKey: string, primaryColor: string): void {
    // unsubscribe possible previous chatConversations subscriptions
    if (this.chatConversationsSubscription) {
      this.chatConversationsSubscription.unsubscribe();
    }

    // define the accent color as a global css variable
    document.documentElement.style.setProperty('--chat-accent-color', primaryColor ?? '#78BEE9');

    // store the context key
    this.contextKey = contextKey;

    // start listening to conversations updates
    this.chatConversationsSubscription = this.chatService
      .chatConversations(contextKey)
      .subscribe((res) => {
        res.sort((a, b) => (a.lastMessage?.timestamp > b.lastMessage?.timestamp ? -1 : 1));
        this.chatConversations$.next(res);
        this.updateUnreadMessagesCounter();
      });
  }

  private updateUnreadMessagesCounter(): void {
    const chatConversations = this.chatConversations$.value?.filter(
      (c) => c.id !== this.selectedChatConversation?.id,
    );

    if (chatConversations && chatConversations.length > 0) {
      this.unreadMessages = chatConversations
        .map((c) => c.unreadMessages)
        .reduce((totalUnread, unread) => (totalUnread += unread));
    } else {
      this.unreadMessages = 0;
    }
  }

  onAppointmentDialog(user: IUser): void {
    this.openDialogService.openAppointmentDialog(user, true);
    this.isChatWindowOpen = false;
  }

  ngOnDestroy(): void {
    if (this.onDestroy$) {
      this.onDestroy$.next();
    }
  }
}
