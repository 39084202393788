import { Theme } from './theme.model';

export const darkTheme: Theme = {
  name: 'dark',
  properties: {
    '--appFont': 'Inter, sans-serif',
    '--appPrimaryColor': '#1A9FFF',
    '--app-primary': '#1A9FFF',
    '--app-primary-hover': '#157FCC',
    '--app-secondary': '#424242',
    '--app-secondary-hover': '#616161',
    '--background': '#0E0E0E',
    '--background-sidebar': '#131313',
    '--layer-01': '#1D1D1D',
    '--layer-02': '#222222',
    '--layer-03': '#292929',
    '--layer-hover-01': '#242424',
    '--layer-hover-02': '#2C2C2C',
    '--layer-hover-03': '#383838',
    '--border-strong-01': '#212121',
    '--border-strong-02': '#424242',
    '--border-strong-03': '#616161',
    '--text-primary': '#FFFFFF',
    '--text-secondary': '#9E9E9E',
    '--icon-primary': '#FFFFFF',
    '--icon-secondary': '#9E9E9E',
    '--icon-disabled': '#424242',
    '--field-01': '#2B2B2B',
    '--field-02': '#383838',
  },
};
