import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppStore } from 'src/app/app.store';
import { Firestore, IFirestore } from 'src/app/firebase';
import { HubsStore, UsersStore } from '../../stores';
import { AuthorizationService } from '../auth';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TenantsService {
  private firestore: IFirestore;

  constructor(
    private http: HttpClient,
    private authorizationService: AuthorizationService,
    private hubsStore: HubsStore,
    private usersStore: UsersStore,
    private appStore: AppStore,
  ) {
    this.firestore = Firestore();
  }

  async isEmailExist(email: string): Promise<{ exist: boolean; userId: string }> {
    try {
      const headers: HttpHeaders = await this.authorizationService.buildHeaders();

      const response = await this.http
        .post<any>(
          `${environment.apiUrl}tenants/${this.hubsStore.hub.tenantId}/users/check-exist-by-email`,
          { email: email },
          { headers },
        )
        .toPromise();
      return response;
    } catch (error) {
      console.warn(error);
      return { exist: false, userId: null };
    }
  }
}
