@if (tagText()) {
  <!-- Tag with text -->
  <div
    class="tag-container tc-with-text"
    [ngClass]="rounded() ? 'rounded' : 'rectangle'"
    [style.color]="textColor()"
    [style.background]="filled() ? bgColor() : 'transparent'"
    [style.borderColor]="bgColor()">
    @if (iconClass()) {
      <i
        [class]="iconClass()"
        [style.marginRight]="tagText() && iconClass() ? '0.25rem' : '0'">
      </i>
    }
    <span>{{ tagText() }}</span>
  </div>
}

@if (iconClass() && !tagText()) {
  <!-- Tag without text only with icon -->
  <div
    class="tag-container tc-without-text"
    [ngClass]="rounded() ? 'rounded' : 'rectangle'"
    [style.color]="textColor()"
    [style.background]="filled() ? bgColor() : 'transparent'"
    [style.borderColor]="bgColor()">
    <div class="icon-container">
      <i [class]="iconClass()"> </i>
    </div>
  </div>
}
