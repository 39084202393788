import { Injectable } from '@angular/core';

import { Firestore, IDocumentData, IDocumentSnapshot, IFirestore } from 'src/app/firebase';
import { CollectionTypes, API_ROUTES as apiRoutes } from 'src/app/shared';
import { IGeneralSettings, IPrivacySettingsPage } from 'src/app/core/models';
import { TypesOfAppSettings } from '../../enums';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private firestore: IFirestore;

  constructor() {
    this.firestore = Firestore();
  }

  async getSettings(
    typeOfAppSettings: TypesOfAppSettings = TypesOfAppSettings.GENERAL,
  ): Promise<IGeneralSettings> {
    try {
      const generalSettingsQuery: IDocumentSnapshot<IDocumentData> = await this.firestore
        .collection(apiRoutes.settings)
        .doc(typeOfAppSettings)
        .get();

      return generalSettingsQuery.data() as IGeneralSettings;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async getAllPages(): Promise<IPrivacySettingsPage[]> {
    try {
      const query = await this.firestore.collection(CollectionTypes.SYSTEM_PAGES).get();

      return query.docs.map((doc) => {
        const page = doc.data();

        return page as IPrivacySettingsPage;
      });
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getPageById(pageId: string): Promise<IPrivacySettingsPage> {
    try {
      const page = await this.firestore.collection(CollectionTypes.SYSTEM_PAGES).doc(pageId).get();

      return page.data() as IPrivacySettingsPage;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async update(page: IPrivacySettingsPage, pageId: string): Promise<IPrivacySettingsPage> {
    try {
      const pageRef = this.firestore.collection(CollectionTypes.SYSTEM_PAGES).doc(pageId);
      await pageRef.update(page);

      return page as IPrivacySettingsPage;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getCopyright(): Promise<string> {
    try {
      const generalSettings = await this.firestore
        .collection(CollectionTypes.SETTINGS)
        .doc('general')
        .get();

      return generalSettings.data().copyright;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getPageHtml(typeId: number): Promise<string> {
    try {
      const page = await this.firestore
        .collection(CollectionTypes.SYSTEM_PAGES)
        .where('type', '==', typeId)
        .get();

      return page.docs[0].data().html;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }
}
