<p-toast>
  <ng-template
    let-message
    pTemplate="message">
    <app-toast
      [severity]="message.severity"
      [summary]="message.summary"
      [detail]="message.detail">
    </app-toast>
  </ng-template>
</p-toast>
<div class="header">
  <div class="title">
    {{
      (isUpdate ? 'brandOwner.product.manageProduct' : 'brandOwner.product.createProduct')
        | translate
    }}
  </div>
  <div class="actions">
    <div
      *ngIf="isUpdate"
      class="delete"
      (click)="deleteProduct()">
      <span *ngIf="deleteInProgress">
        <i class="pi pi-spin pi-spinner"></i>
      </span>
      {{ 'brandOwner.product.deleteProduct' | translate }}
    </div>
    <p-button
      [disabled]="formGroup.invalid || saveInProgress"
      [icon]="saveInProgress ? 'pi pi-spin pi-spinner' : null"
      styleClass="save-btn primary-text-color"
      label="{{
        isUpdate
          ? ('brandOwner.product.saveChanges' | translate)
          : ('brandOwner.product.createBtn' | translate)
      }}"
      (onClick)="saveChanges()">
    </p-button>
  </div>
</div>

<form
  [formGroup]="formGroup"
  class="content">
  <div class="details">
    <div class="col-6">
      <div class="title">{{ 'brandOwner.product.name' | translate }}</div>

      <!-- Title input -->
      <div class="c-mb-4">
        <app-input-label
          labelType="required"
          for="title-input"
          text="brandOwner.product.productTitle">
        </app-input-label>
        <input
          class="custom-input-text custom-input-text-required"
          formControlName="title"
          id="title-input"
          pInputText
          type="text"
          maxlength="30"
          placeholder="{{ 'brandOwner.product.productTitle' | translate }}" />
        <app-input-error-message
          *ngIf="formGroup.controls.title.invalid && formGroup.controls.title.touched"
          [showMessage]="formGroup.controls.title.invalid && formGroup.controls.title.touched"
          errorMessage="{{ 'application.forms.required' | translate }}">
        </app-input-error-message>
        <p class="input-note input-note-with-showing-error">
          {{ 'brandOwner.notes.titleNote' | translate }}
        </p>
      </div>

      <!-- Description input -->
      <div class="c-mb-4">
        <app-input-label text="brandOwner.product.description"></app-input-label>
        <textarea
          class="custom-input-textarea resize-none"
          [rows]="3"
          pInputTextarea
          maxlength="500"
          formControlName="description"
          autoResize="autoResize"
          placeholder="{{ 'brandOwner.product.description' | translate }}">
        </textarea>
        <p class="input-note">
          {{ 'brandOwner.notes.descriptionNote' | translate }}
        </p>
      </div>

      <!-- Tagline input -->
      <div>
        <app-input-label
          for="tagline-input"
          text="brandOwner.product.tagline">
        </app-input-label>
        <input
          class="custom-input-text"
          formControlName="tagline"
          id="tagline-input"
          pInputText
          type="text"
          maxlength="50"
          placeholder="{{ 'brandOwner.product.tagline' | translate }}" />
        <p class="input-note">
          {{ 'brandOwner.notes.taglineNote' | translate }}
        </p>
      </div>
    </div>
    <div class="col-6">
      <div class="title">{{ 'brandOwner.product.file' | translate }}</div>

      <!-- Type input -->
      <div class="c-mb-4">
        <app-input-label
          for="type-input"
          text="brandOwner.product.type">
        </app-input-label>
        <p-dropdown
          class="custom-dropdown"
          panelStyleClass="custom-dropdown-panel"
          [options]="fileTypes"
          formControlName="type"
          inputId="type-input"
          placeholder="{{ 'brandOwner.product.type' | translate }}">
          <ng-template
            let-type
            pTemplate="selectedItem">
            {{ getFileTypeText(type) }}
          </ng-template>
          <ng-template
            let-type
            pTemplate="item">
            {{ getFileTypeText(type) }}
          </ng-template>
        </p-dropdown>
        <p class="input-note">
          {{ 'brandOwner.notes.typeNote' | translate }}
        </p>
      </div>

      <!-- File input -->
      <div class="img-wrapper">
        <input
          class="input"
          type="file"
          #fileInput
          [accept]="allowedFormats"
          (change)="uploadFile($event)" />
        <div
          *ngIf="!file"
          class="upload-icon"
          (click)="fileInput.click()">
          <i class="far fa-upload"></i>
          <span>{{ 'brandOwner.product.upload' | translate }}</span>
        </div>
        <iframe
          *ngIf="fileType === FileType.PDF && file"
          [src]="filePdfPreview">
        </iframe>
        <video
          *ngIf="fileType === FileType.Video && fileVideoPreview"
          [src]="fileVideoPreview"
          controls></video>
        <img
          *ngIf="(fileType === FileType.JPG || fileType === FileType.PNG) && file"
          [src]="file | imageSize"
          alt="file" />
        <i
          *ngIf="fileType === FileType.ZIP && file"
          class="fal fa-file-archive zip">
        </i>
        <button
          *ngIf="formGroup.get('file').value"
          class="p-button-rounded p-button-outlined clear-btn"
          pButton
          pRipple
          type="button"
          icon="far fa-circle-xmark"
          (click)="clearFile()"></button>
      </div>

      <!-- Cta input -->
      <div class="c-mt-4">
        <app-input-label
          for="cta-input"
          text="brandOwner.product.cta">
        </app-input-label>
        <div class="p-inputgroup custom-input-group">
          <span class="p-inputgroup-addon">URL</span>
          <input
            class="custom-input-text custom-input-text-required"
            formControlName="cta"
            id="cta-input"
            pInputText
            type="text"
            placeholder="{{ 'brandOwner.product.cta' | translate }}" />
        </div>
        <app-input-error-message
          *ngIf="formGroup.controls.cta.invalid && formGroup.controls.cta.touched"
          [showMessage]="formGroup.controls.cta.invalid && formGroup.controls.cta.touched"
          errorMessage="{{ 'profile.invalidLink' | translate }}">
        </app-input-error-message>
        <p class="input-note input-note-with-showing-error">
          {{ 'brandOwner.notes.videoLinkNote' | translate }}
        </p>
      </div>
    </div>
  </div>
  <div class="media-image">
    <div class="title">{{ 'brandOwner.product.mediaImage' | translate }}</div>

    <!-- File input -->
    <div class="img-wrapper">
      <input
        class="input"
        type="file"
        #imageInput
        accept="image/jpeg,image/jpg,image/png"
        (change)="uploadMediaImage($event)" />
      <div
        *ngIf="!image"
        class="upload-icon"
        (click)="imageInput.click()">
        <i class="far fa-upload"></i>
        <span>{{ 'brandOwner.product.upload' | translate }}</span>
      </div>
      <img
        *ngIf="image"
        [src]="image | imageSize"
        alt="image" />
      <button
        *ngIf="formGroup.get('image').value"
        class="p-button-rounded p-button-outlined clear-btn"
        pButton
        pRipple
        icon="far fa-circle-xmark"
        (click)="clearImage()"></button>
    </div>
    <div class="input-note">
      {{ 'brandOwner.notes.mediaImageNote' | translate }}
    </div>

    <!-- Video link input -->
    <div>
      <app-input-label
        for="video-link-input"
        text="brandOwner.product.video">
      </app-input-label>
      <div class="p-inputgroup custom-input-group">
        <span class="p-inputgroup-addon">Embed-URL</span>
        <input
          class="custom-input-text custom-input-text-required"
          formControlName="videoLink"
          id="video-link-input"
          pInputText
          type="text"
          placeholder="{{ 'brandOwner.product.video' | translate }}" />
      </div>
      <p class="input-note">
        {{ 'brandOwner.notes.videoHeaderNote' | translate }}
      </p>
    </div>
  </div>
</form>
