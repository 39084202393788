import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { DialogService } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { ClipboardService } from 'ngx-clipboard';
import { TranslateService } from '@ngx-translate/core';

import { CoursesStore, EventsStore, HubsStore, UsersStore } from 'src/app/core/stores';
import { emailsEventActions, eventAudience } from 'src/app/core/utils';
import { IAudience, ICourse, IEmail } from 'src/app/core/models';
import { EmailsService } from 'src/app/core/services';
import { ServerTimestamp } from 'src/app/firebase';
import { SystemEmailPreviewComponent } from './../system-email-preview/system-email-preview.component';

@Component({
  selector: 'app-system-email-html-editor',
  templateUrl: './system-email-html-editor.component.html',
  styleUrls: ['./system-email-html-editor.component.scss'],
})
export class SystemEmailHtmlEditorComponent implements OnInit {
  @Input() email: IEmail = null;

  loading = true;
  saving = false;
  sendingTestEmail = false;
  showMergeTags = false;
  testEmailForm: UntypedFormGroup;
  form: UntypedFormGroup;
  languages: any[] = [];
  actions: { value: string; label: string }[] = emailsEventActions;
  audience: IAudience[] = eventAudience;

  constructor(
    private router: Router,
    private translateService: TranslateService,
    private dialogService: DialogService,
    private messageService: MessageService,
    private emailsService: EmailsService,
    private clipboardService: ClipboardService,
    private usersStore: UsersStore,
    public hubsStore: HubsStore,
    private eventsStore: EventsStore,
    private coursesStore: CoursesStore,
  ) {}

  get emailErrorMessage(): string {
    if (this.testEmailForm.controls.email.errors?.email) {
      return this.translateService.instant('adminEmailTemplate.emailNotValid');
    }

    return this.translateService.instant('adminEmailTemplate.emailRequired');
  }

  get course(): ICourse {
    return this.coursesStore.adminCourse;
  }

  ngOnInit(): void {
    this.languages = this.translateService
      .getLangs()
      .map((l) => ({ name: this.translateService.instant('languages.' + l), code: l }));

    this.createForms();
    this.loading = false;
  }

  preview(): void {
    this.dialogService.open(SystemEmailPreviewComponent, {
      width: '510px',
      height: '90%',
      dismissableMask: true,
      contentStyle: {
        overflow: 'auto',
        padding: '0',
        'border-radius': '20px',
      },
      data: {
        html: this.form.controls.html.value,
      },
    });
  }

  mergeTags(): void {
    this.showMergeTags = !this.showMergeTags;
  }

  copy(): void {
    const value = this.form.get('html').value ? this.form.get('html').value : '';
    this.clipboardService.copy(value);
  }

  async sendTestEmail(): Promise<void> {
    if (!this.testEmailForm.valid) {
      return;
    }

    try {
      this.sendingTestEmail = true;

      await this.emailsService.sendTestEmail(this.testEmailForm.value.email, this.email.id);
      this.showToastMessage('success', 'adminEmailTemplate.succesSentTest');
      this.sendingTestEmail = false;
    } catch (error) {
      console.log(error);
      this.showToastMessage('error', 'adminEmailTemplate.errorSentTest');
      this.sendingTestEmail = false;
    }
  }

  async save(): Promise<void> {
    if (!this.form.valid) {
      return;
    }

    try {
      this.saving = true;
      const form = this.form.value;
      console.log(form);

      if (this.email) {
        const emailPayload = {
          title: form.title || null,
          _title_: form.title.toLowerCase(),
          language: form.language.code || null,
          subject: form.subject || null,
          from: form.from || null,
          status: form.status || null,
          audience: form.audience || null,
          action: form.action || null,
          html: form.html || null,
          default: form.default || false,
          updatedAt: ServerTimestamp(),
          updatedBy: this.usersStore.user.id,
        } as unknown as IEmail;

        if (this.eventsStore.adminEvent) {
          await this.emailsService.updateEventEmail(this.email.id, emailPayload);
        } else if (this.course) {
          await this.emailsService.updateCourseEmail(this.email.id, emailPayload);
        } else {
          await this.emailsService.update(this.email.id, emailPayload);
        }
      } else {
        const newEmail = {
          id: null,
          title: form.title || null,
          _title_: form.title.toLowerCase(),

          action: form.action || null,
          isHtmlMode: true,
          type: null,
          language: form.language.code || null,
          subject: form.subject || null,
          from: form.from || null,
          status: form.status || null,
          audience: form.audience || null,
          html: form.html || null,
          emailHeader: null,
          emailPreHeader: null,
          buttonText: null,
          buttonLink: null,
          description: null,
          isDeleted: false,
          default: form.default || false,

          updatedAt: null,
          updatedBy: null,
          createdAt: ServerTimestamp(),
          createdBy: this.usersStore.user.id,
        } as unknown as IEmail;
        const newEmailResponse = await this.emailsService.create(newEmail);
        this.router.navigate([
          `/${this.hubsStore.useHubUrl}/admin/system-settings/emails/${newEmailResponse.id}`,
        ]);
      }

      this.showToastMessage('success', 'adminEmailTemplate.succesSave');
      this.saving = false;
    } catch (error) {
      console.log(error);
      this.showToastMessage('error', 'adminEmailTemplate.errorSave');
      this.saving = false;
    }
  }

  private createForms(): void {
    this.testEmailForm = new UntypedFormGroup({
      email: new UntypedFormControl(null, [Validators.required, Validators.email]),
    });

    this.form = new UntypedFormGroup({
      title: new UntypedFormControl(this.email ? this.email.title : 'My New Email Title', [
        Validators.required,
      ]),
      language: new UntypedFormControl(
        this.email ? this.languages.find((x) => x.code === this.email.language) : null,
        Validators.required,
      ),
      action: new UntypedFormControl(
        this.email && this.email.action
          ? this.actions.find((x) => x.value === this.email.action).value
          : null,
      ),
      status: new UntypedFormControl(this.email ? this.email.status : false),
      default: new UntypedFormControl(this.email ? this.email.default : false),
      from: new UntypedFormControl(
        this.email
          ? this.email.from
          : this.translateService.instant('adminEmailTemplate.fromPlaceholder'),
        Validators.required,
      ),
      subject: new UntypedFormControl(
        this.email
          ? this.email.subject
          : this.translateService.instant('adminEmailTemplate.subjectPlaceholder'),
        Validators.required,
      ),
      audience: new UntypedFormControl(this.email ? this.email.audience : null),
      html: new UntypedFormControl(this.email ? this.email.html : null, Validators.required),
    });
  }

  private showToastMessage(severity: 'success' | 'error', detail: string): void {
    this.messageService.add({
      severity,
      summary: this.translateService.instant(severity),
      detail: this.translateService.instant(detail),
      styleClass: 'custom-toast',
    });
  }
}
