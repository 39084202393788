import * as moment from 'moment';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { IReactionDisposer, autorun } from 'mobx';
import { TranslateService } from '@ngx-translate/core';

import { ITicket, ICourse, ICourseTicket } from 'src/app/core/models';
import { TicketsService, OpenDialogService } from 'src/app/core/services';
import { CoursesStore, HubsStore } from 'src/app/core/stores';
import { IAdminTableColumn } from 'src/app/shared';
import { getTime, getTimeForEnglishLocale } from 'src/app/core/utils';
import { AttachSubscriptionsDialogComponent } from 'src/app/admin/dialogs';
import { ButtonSize, ButtonStyle, ButtonType } from 'src/app/standalone';

@Component({
  selector: 'app-course-tickets',
  templateUrl: './course-tickets.component.html',
  styleUrls: ['./course-tickets.component.scss'],
})
export class CourseTicketsComponent implements OnInit, OnDestroy {
  loading = true;
  searchValue = '';
  syncingTickets = false;
  columns: IAdminTableColumn[] = [
    { field: 'name', titleTranslationKey: 'adminEventsTickets.tableCells.name', sortable: true },
    {
      field: 'availability',
      titleTranslationKey: 'adminEventsTickets.tableCells.availability',
      template: 'availabilityCell',
    },
    {
      field: 'start',
      titleTranslationKey: 'adminEventsTickets.tableCells.start',
      template: 'startCell',
      sortable: true,
    },
    {
      field: 'end',
      titleTranslationKey: 'adminEventsTickets.tableCells.end',
      template: 'endCell',
      sortable: true,
    },
    {
      field: 'price',
      titleTranslationKey: 'adminEventsTickets.tableCells.price',
      template: 'priceCell',
      sortable: true,
    },
    {
      field: 'status',
      titleTranslationKey: 'adminEventsTickets.tableCells.status',
      template: 'statusCell',
      sortable: true,
    },
    { field: 'actions', template: 'actionsCell' },
  ];
  entries: ICourseTicket[] = [];
  buttonStyle = ButtonStyle;
  buttonType = ButtonType;
  buttonSize = ButtonSize;

  private disposer: IReactionDisposer;

  constructor(
    private ticketsService: TicketsService,
    private courseStore: CoursesStore,
    private router: Router,
    private hubsStore: HubsStore,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private openDialogService: OpenDialogService,
    private translateService: TranslateService,
    private dialogService: DialogService,
  ) {}

  get course(): ICourse {
    return this.courseStore.adminCourse;
  }

  async ngOnInit(): Promise<void> {
    this.disposer = autorun(async () => {
      if (this.course) {
        this.loading = true;
        await this.getAllCourseTickets();
      }
    });
  }

  textForAvailability(ticket: ITicket): string {
    const totalTickets: number = ticket.quantityTotal;
    const soldTickets: number = ticket?.quantitySold ?? 0;

    if (totalTickets === soldTickets) {
      return this.translateService.instant('adminCourseTickets.soldOut');
    }

    return `${soldTickets}/${totalTickets}`;
  }

  getDateForTicket(ticket: ITicket, period: 'start' | 'end'): string {
    return `${moment(ticket[period].toDate()).format('DD.MM.YYYY')}`;
  }

  getTimeForTicket(ticket: ITicket, period: 'start' | 'end'): string {
    const lang = this.translateService.currentLang;
    switch (lang) {
      case 'en':
        return getTimeForEnglishLocale(ticket[period]);
      case 'de':
        return `${getTime(ticket[period])} Uhr`;
    }
  }

  openAttachSubscriptionsDialog(): void {
    this.dialogService.open(AttachSubscriptionsDialogComponent, {
      width: '90%',
      height: '80%',
      closable: false,
      styleClass: 'attach-plans-dialog',
      data: { isCourse: true },
    });
  }

  edit(event: Event, entry: ITicket): void {
    event.stopPropagation();
    this.router.navigate(
      [
        `/${this.hubsStore.useHubUrl}/admin/courses/${this.courseStore.adminCourse.link}/setup/tickets/${entry.id}`,
      ],
      {
        relativeTo: this.route,
        state: { entry },
      },
    );
  }

  async remove(event: Event, ticket: ITicket): Promise<void> {
    event.stopPropagation();
    let deleteDialogRef: DynamicDialogRef;

    if (ticket.quantitySold) {
      deleteDialogRef = this.openDialogService.openDeleteTicketDialog(
        'adminTicket.deletionNotPossible',
        'adminTicket.deletionNotPossibleSoldText',
        'adminTicket.confirmBtn',
        'adminTicket.deletionNotPossibleCancelBtn',
        false,
        true,
      );
    } else {
      deleteDialogRef = this.openDialogService.openDeleteTicketDialog(
        'adminCourseTickets.deleteTicket',
        'adminCourseTickets.deleteTicketText',
        'adminCourseTickets.confirmBtn',
        'adminCourseTickets.cancelBtn',
        false,
      );
    }

    try {
      const result: 'cancel' | 'confirm' = await firstValueFrom(deleteDialogRef.onClose);

      if (result === 'confirm') {
        this.loading = true;
        await this.ticketsService.deleteFromCourse(ticket.id, this.course.id);
        await this.getAllCourseTickets();
        this.loading = false;
        this.showToastMessage('success', 'adminCourseTickets.removeCourseSuccessMsg');
      }
    } catch (error) {
      this.loading = false;
      this.showToastMessage('error', 'application.toasters.error');
    }
  }

  onRowClick(id: string) {
    this.router.navigate([
      `/${this.hubsStore.useHubUrl}/admin/courses/${this.courseStore.adminCourse.link}/setup/tickets/${id}`,
    ]);
  }

  ngOnDestroy(): void {
    this.disposer();
  }

  private async getAllCourseTickets(): Promise<void> {
    this.loading = true;
    const tickets: ICourseTicket[] = await this.ticketsService.getCourseTickets(
      this.courseStore.adminCourse.id,
    );
    console.log(tickets, 'tickets');
    this.entries = [...tickets];
    this.loading = false;
  }

  private showToastMessage(severity: 'success' | 'error', detail: string): void {
    this.messageService.add({
      severity,
      summary: this.translateService.instant(severity),
      detail: this.translateService.instant(detail),
      styleClass: 'custom-toast',
    });
  }
}
