<div class="event-ticket-container">
  <header>
    <h1>{{ 'adminTicket.editTicketTitle' | translate }}</h1>

    <!-- TODO uncomment during development -->
    <!-- <button
      pButton
      pRipple
      [disabled]="false"
      class="attach"
      [label]="'adminEventsTickets.attachPlans' | translate"
      icon="far fa-user-plus">
    </button> -->
  </header>

  <p-divider styleClass="custom-divider-horizontal"></p-divider>

  <app-admin-ticket-form></app-admin-ticket-form>
</div>
