export interface IUserAccount {
  disabled: boolean;
  email: string;
  emailVerified: boolean;
  metadata: IUserAccountMetadata;
  providerData: IUserAccountProviderData[];
  tenantId: string;
  tokensValidAfterTime: string;
  uid: string;
}

interface IUserAccountMetadata {
  creationTime: string;
  lastSignInTime: string;
}

interface IUserAccountProviderData {
  email: string;
  providerId: string;
  uid: string;
}
