import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { DividerModule } from 'primeng/divider';
import { TranslateModule } from '@ngx-translate/core';

import { logoAvatar, parseToMoment } from 'src/app/shared';
import { trackByFn } from 'src/app/core/utils';
import { Timestamp } from 'src/app/firebase';
import { IEvent } from 'src/app/core/models';

@Component({
  selector: 'app-sidebar-user-info-events',
  standalone: true,
  imports: [CommonModule, TranslateModule, NgOptimizedImage, DividerModule],
  templateUrl: './sidebar-user-info-events.component.html',
  styleUrls: ['./sidebar-user-info-events.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarUserInfoEventsComponent {
  @Input() userEvents: IEvent[];

  defaultEventImage = logoAvatar;
  trackByFn = trackByFn;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  getDateForShowing(date: Timestamp): string {
    return parseToMoment(date).format('DD.MM.YYYY');
  }

  onNavigateTo(event: IEvent): void {
    this.router.navigate([`../event/${event.link}`], {
      relativeTo: this.route,
      state: {
        event,
      },
    });
  }
}
