import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';

import { ILanguage } from 'src/app/core/models';
import { ConfirmDialogComponent, IAdminTableColumn } from 'src/app/shared';
import { TranslationsService } from 'src/app/core/services';
import { ButtonSize, ButtonStyle, ButtonType } from 'src/app/standalone';
import { SystemTranslationsEditorComponent } from './../../components/system-translations-editor/system-translations-editor.component';

@Component({
  selector: 'app-system-translations',
  templateUrl: './system-translations.component.html',
  styleUrls: ['./system-translations.component.scss'],
})
export class SystemTranslationsComponent implements OnInit {
  loading = true;
  loadingEntries = true;
  searchValue = '';
  searchFilterFields: string[] = ['title', 'code', 'updateTimeStamp'];
  columns: IAdminTableColumn[] = [
    {
      field: 'title',
      titleTranslationKey: 'adminSystemTranslations.tableColTitle',
      sortable: true,
    },
    { field: 'code', titleTranslationKey: 'adminSystemTranslations.tableColCode', sortable: true },
    {
      field: 'updateTimeStamp',
      titleTranslationKey: 'adminSystemTranslations.tableColTimeStampUpdated',
      sortable: true,
    },
    { field: 'actions', template: 'actionsCell' },
  ];
  entries: ILanguage[] = [];
  totalRecords = 0;
  pageSize = 10;
  buttonSize = ButtonSize;
  buttonType = ButtonType;
  buttonStyle = ButtonStyle;

  constructor(
    private messageService: MessageService,
    private dialogService: DialogService,
    private translationsService: TranslationsService,
    private translateService: TranslateService,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.fetchLanguages();
    this.loadingEntries = false;
    this.loading = false;
  }

  add(): void {
    const deleteDialogRef = this.dialogService.open(SystemTranslationsEditorComponent, {
      width: '90%',
      height: '80%',
      dismissableMask: true,
      closable: false,
      contentStyle: {
        overflow: 'auto',
        padding: '0',
        'border-radius': '20px',
      },
      data: {},
    });

    deleteDialogRef.onClose.pipe(take(1)).subscribe(async (res) => {
      if (res) {
        try {
          this.messageService.add({
            severity: 'success',
            summary: this.translateService.instant('success'),
            detail: this.translateService.instant('adminSystemTranslations.succesCreate'),
            styleClass: 'custom-toast',
          });
          this.loadingEntries = true;
          await this.fetchLanguages();
          this.loadingEntries = false;
        } catch (error) {
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('success'),
            detail: this.translateService.instant('adminSystemTranslations.errorCreate'),
            styleClass: 'custom-toast',
          });
        }
      }
    });
  }

  edit(event: Event, language: ILanguage): void {
    event.stopPropagation();
    const deleteDialogRef = this.dialogService.open(SystemTranslationsEditorComponent, {
      width: '90%',
      height: '80%',
      dismissableMask: true,
      closable: false,
      contentStyle: {
        overflow: 'auto',
        padding: '0',
        'border-radius': '20px',
      },
      data: {
        language: language,
      },
    });

    deleteDialogRef.onClose.pipe(take(1)).subscribe(async (res) => {
      if (res) {
        try {
          this.messageService.add({
            severity: 'success',
            summary: this.translateService.instant('success'),
            detail: this.translateService.instant('adminSystemTranslations.succesUpdate'),
            styleClass: 'custom-toast',
          });
          this.loadingEntries = true;
          await this.fetchLanguages();
          this.loadingEntries = false;
        } catch (error) {
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('success'),
            detail: this.translateService.instant('adminSystemTranslations.errorUpdate'),
            styleClass: 'custom-toast',
          });
        }
      }
    });
  }

  remove(event: Event, entry: ILanguage): void {
    event.stopPropagation();
    const deleteDialogRef = this.dialogService.open(ConfirmDialogComponent, {
      closable: false,
      styleClass: 'confirm-dialog',
      data: {
        titleKey: 'adminSystemTranslations.deleteTitle',
        descriptionKey: 'adminSystemTranslations.deleteText',
        confirmBtnKey: 'adminSystemTranslations.confirmBtn',
        cancelBtnKey: 'adminSystemTranslations.cancelBtn',
        entry: entry,
      },
    });

    deleteDialogRef.onClose.pipe(take(1)).subscribe(async (result: 'cancel' | 'confirm') => {
      if (result === 'confirm') {
        try {
          this.loadingEntries = true;
          let deleteResult = await this.translationsService.remove(entry.id);
          if (deleteResult)
            this.messageService.add({
              severity: 'success',
              summary: this.translateService.instant('success'),
              detail: this.translateService.instant('adminSystemTranslations.succesDelete'),
              styleClass: 'custom-toast',
            });
          await this.fetchLanguages();
          this.loadingEntries = false;
        } catch (error) {
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('success'),
            detail: this.translateService.instant('adminSystemTranslations.errorDelete'),
            styleClass: 'custom-toast',
          });
        }
      }
    });
  }

  private async fetchLanguages(): Promise<void> {
    this.entries = await this.translationsService.getAllLanguagesOrderedByTitle();
  }
}
