import { Firestore, IFirestore } from './../../../firebase';
import { ISession } from '../../models/session/session.model';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class VideosService {
  private firestore: IFirestore;

  constructor() {
    this.firestore = Firestore();
  }

  public async get(eventId: string, tagsIds: string[]): Promise<ISession[]> {
    try {
      let sessions = [];
      const tagsPayload = [...tagsIds];

      const query = this.firestore
        .collection(`events/${eventId}/sessions`)
        .where('videolink', '!=', null)
        .orderBy('videolink')
        .orderBy('start');

      if (tagsIds.length) {
        while (tagsPayload.length) {
          const chunk = tagsPayload.splice(0, 10);
          const sessionsQueary = await query.where('tags', 'array-contains-any', chunk).get();

          if (!sessionsQueary.empty) {
            sessionsQueary.forEach((doc) => sessions.push(doc.data()));
          }
        }
      } else {
        sessions = (await query.get()).docs.map((doc) => doc.data() as ISession);
      }

      return sessions;
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  public async getBasedOnTags(
    eventId: string,
    tags: string[],
    entry: ISession = null,
    pageSize: number = 12,
  ): Promise<ISession[]> {
    const sessions = [];
    let tagIds = [...tags];

    try {
      let queryEventsByTags = null;

      while (tagIds.length > 0) {
        const chunk = tagIds.splice(0, 10);

        queryEventsByTags = this.firestore
          .collection(`events/${eventId}/sessions`)
          .where('tags', 'array-contains-any', chunk)
          .where('videolink', '!=', null)
          .orderBy('videolink')
          .orderBy('start')
          .orderBy('_title_')
          .orderBy('id');

        if (entry) {
          queryEventsByTags = queryEventsByTags.startAfter(entry.start, entry._title_, entry.id);
        }

        queryEventsByTags = await queryEventsByTags.limit(pageSize).get();

        if (!queryEventsByTags.empty) {
          queryEventsByTags.forEach((doc) => {
            sessions.push(doc.data());
          });
        }

        if (sessions.length === pageSize) {
          tagIds = [];
        }
      }

      return sessions;
    } catch (error) {
      console.log(error);
      return sessions;
    }
  }

  public getOne(id: string) {}

  public getAll() {}

  public create(id: string) {}

  public update(id: string) {}

  public remove(id: string) {}
}
