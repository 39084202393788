import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';

import { IAppearanceSettings, IGeneralSettings, IUser } from 'src/app/core/models';
import { ThemesService, UsersService, FormService } from 'src/app/core/services';
import { UsersStore } from 'src/app/core/stores';
import { asyncDelay } from 'src/app/core/utils';

@Component({
  selector: 'app-general-settings',
  templateUrl: './general-settings.component.html',
  styleUrls: ['./general-settings.component.scss'],
})
export class GeneralSettingsComponent implements OnInit {
  loading = true;
  isUpdating: boolean;
  stripeLoading = false;
  isStripeConnected = false;
  stripeConnectId: string;
  freshUser: IUser;
  icon: string;
  state: string;
  stripeBtnLabel: string;
  hoverState: string;
  form: FormGroup;

  private generalSettings: IGeneralSettings;
  private appearanceSettings: IAppearanceSettings;

  get user(): IUser {
    return this.usersStore.user;
  }

  get adminUser(): IUser {
    return this.usersStore.adminUser;
  }

  get canUpdate(): boolean {
    return (
      !this.isUpdating && this.form.valid && this.form.dirty && this.formService.isValueChanged()
    );
  }

  constructor(
    private themeService: ThemesService,
    private messageService: MessageService,
    private cd: ChangeDetectorRef,
    private translateService: TranslateService,
    private usersStore: UsersStore,
    private usersService: UsersService,
    private formService: FormService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.initForm();
    await this.getData();
    this.setInitialValueForForm();
    this.formService.setForm(this.form);

    if (this.freshUser?.role === 'instanceOwner' || this.freshUser?.role === 'softwareDeveloper') {
      this.setAppOwner();
    }

    this.loading = false;
  }

  private async getData(): Promise<void> {
    const [general, appearance, user] = await Promise.all([
      this.themeService.getSystemGeneralSettings(),
      this.themeService.getSystemAppearanceSettings(),
      this.usersService.getOne(this.usersStore.user.id),
    ]);

    this.generalSettings = general;
    this.appearanceSettings = appearance;
    this.freshUser = user;
  }

  private initForm(): void {
    this.form = new FormGroup({
      name: new FormControl<string>(null),
      slogan: new FormControl<string>(null),
      copyright: new FormControl<string>(null),
      appOwner: new FormControl<string>(null),
      logo: new FormControl<string | File>(null),
      logoLight: new FormControl<string | File>(null),
      cover: new FormControl<string | File>(null),
      defaultEmailLogo: new FormControl<string | File>(null),
      primaryColor: new FormControl<string>(null),
      isPrivate: new FormControl<boolean>(null),
      enableEncryption: new FormControl<boolean>(null),
      hideProfile: new FormControl<boolean>(null),
      hideHelp: new FormControl<boolean>(null),
      globalSingleRoute: new FormControl<boolean>(null),
      ivsRegion: new FormControl<string>(null),
      ivsKey: new FormControl<string>(null),
      ivsSecret: new FormControl<string>(null),
    });
  }

  private setInitialValueForForm(): void {
    this.form.patchValue({
      ...this.generalSettings,
      ...this.appearanceSettings,
      primaryColor: this.appearanceSettings['darkTheme']['properties']['--appPrimaryColor'],
    });
  }

  setLogoValue(value: File): void {
    this.form.controls.logo.setValue(value);
    this.makeFormControlDirty('logo');
  }

  setLogoLightValue(value: File): void {
    this.form.controls.logoLight.setValue(value);
    this.makeFormControlDirty('logoLight');
  }

  setDefaultEmailLogoValue(value: File): void {
    this.form.controls.defaultEmailLogo.setValue(value);
    this.makeFormControlDirty('defaultEmailLogo');
  }

  setCoverValue(value: File): void {
    this.form.controls.cover.setValue(value);
    this.makeFormControlDirty('cover');
  }

  onSelectPrimaryColor(value: string): void {
    this.form.controls.primaryColor.setValue(value);
    this.makeFormControlDirty('primaryColor');
  }

  onSetPrimaryColorError(value: string): void {
    this.form.controls.primaryColor.setErrors({ [value]: true });
    this.makeFormControlDirty('primaryColor');
  }

  private makeFormControlDirty(formControlName: string): void {
    this.form.controls[formControlName].markAsDirty();
  }

  private async updateSettings(): Promise<void> {
    const {
      logo,
      logoLight,
      defaultEmailLogo,
      cover,
      primaryColor,
      globalSingleRoute,
      ...general
    } = this.form.getRawValue();

    const generalSettings: IGeneralSettings = { ...general };
    const appearanceSettings: IAppearanceSettings = {
      ...this.appearanceSettings,
      logo,
      logoLight,
      defaultEmailLogo,
      cover,
      primaryColor,
      globalSingleRoute,
    };
    appearanceSettings['darkTheme'] = this.appearanceSettings.darkTheme;
    appearanceSettings['darkTheme']['properties']['--appPrimaryColor'] =
      appearanceSettings.primaryColor;

    delete appearanceSettings.primaryColor;

    generalSettings.copyright = generalSettings.copyright ? generalSettings.copyright : null;

    await this.themeService.updateGeneralSettings(generalSettings);
    await this.themeService.updateAppearanceSettings(appearanceSettings);

    this.messageService.add({
      severity: 'success',
      summary: this.translateService.instant('success'),
      detail: this.translateService.instant('adminSystemSettings.successUpdateSettings'),
      styleClass: 'custom-toast',
    });
  }

  onDiscard(): void {
    this.setInitialValueForForm();
  }

  async onConfirm(): Promise<void> {
    try {
      this.isUpdating = true;
      await asyncDelay(1);
      await this.updateSettings();
      await this.getData();
      this.formService.setForm(this.form);
    } catch (error) {
      console.error(error);
    } finally {
      this.isUpdating = false;
    }
  }

  private setAppOwner(): void {
    this.form.patchValue({
      appOwner: `${this.user.firstName} ${this.user.lastName}`,
    });
  }
}
