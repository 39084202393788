<div class="tag-dialog-container">
  <i
    class="fa-regular fa-xmark close-icon"
    (click)="onCloseDialog()">
  </i>
  <h1>{{ pageTitle | translate }}</h1>

  <form
    [formGroup]="form"
    class="form">
    <app-input-label
      labelType="required"
      for="title-input"
      text="adminSystemTags.tagFormTitleLabel">
    </app-input-label>
    <input
      class="custom-input-text custom-input-text-required"
      formControlName="title"
      id="title-input"
      pInputText
      type="text"
      placeholder="{{ 'adminSystemTags.tagFormTitleLabel' | translate }}" />
    <app-input-error-message
      [showMessage]="form.controls.title.touched && form.controls.title.hasError('required')"
      errorMessage="{{ 'adminStage.nameReq' | translate }}">
    </app-input-error-message>
  </form>

  <div class="actions-container">
    <app-button
      buttonText="{{ 'adminSystemTags.tagFormConfirm' | translate }}"
      [loading]="saving"
      [buttonSize]="buttonSize.MEDIUM"
      [disabled]="form.invalid"
      (click)="save()" />
  </div>
</div>
