import { AfterViewInit, Component, ViewChild, ElementRef } from '@angular/core';
import {
  Router,
  NavigationEnd,
  NavigationStart,
  NavigationCancel,
  NavigationError,
} from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Observable, timer, fromEvent } from 'rxjs';
import { map, tap, filter } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { Intercom } from '@supy-io/ngx-intercom';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

import { scrollSectionToTop, isMobile, isDesktop } from 'src/app/core/utils';
import {
  AuthenticationService,
  GoogleTagManagerAnalyticsService,
  IntercomService,
  MixpanelService,
  ThemesService,
  EventMenuService,
  TranslationsService,
  EnvironmentService,
  IndexedDbService,
  ServiceWorkerService,
  WebPushService,
} from 'src/app/core/services';
import { EventsStore, UsersStore, HubsStore, CoursesStore } from 'src/app/core/stores';
import { IUser, IGeneralSettings, IAppearanceSettings } from 'src/app/core/models';
import { AppStore } from './app.store';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit {
  @ViewChild('adBanner') adBanner: ElementRef;
  isEnvironmentLoading$: Observable<boolean>;
  isSystemSettingsLoading$: Observable<boolean>;
  isTranslationsLoading$: Observable<boolean>;
  isAuthenticated$: Observable<boolean>;
  isAuthLoading$: Observable<boolean>;
  isHeaderAndFooterVisible: boolean;
  isMobileNavigationVisible: boolean;
  userDashboardActive = false;
  brandDashboardActive = false;
  generalSystemSettings: IGeneralSettings;
  appearanceSettings: IAppearanceSettings;
  footerHidden = false;
  isMobile = isMobile();
  isShowLoader = false;
  userHasAdblock = false;
  isAdminView: boolean;

  readonly isShowLoader$: Observable<boolean> = this.appStore.loaderState$;

  private currentUrl: string;

  constructor(
    private intercom: Intercom,
    public intercomService: IntercomService,
    public appStore: AppStore,
    public eventsStore: EventsStore,
    public themesService: ThemesService,
    private userStore: UsersStore,
    private authenticationService: AuthenticationService,
    private hubsStore: HubsStore,
    private router: Router,
    private googleTagManagerAnalyticsService: GoogleTagManagerAnalyticsService,
    private mixpanelService: MixpanelService,
    private titleService: Title,
    private translationsService: TranslationsService,
    private environmentService: EnvironmentService,
    private eventMenuService: EventMenuService,
    private cookieService: CookieService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private webPushService: WebPushService,
    public serviceWorkerService: ServiceWorkerService,
    private indexedDbService: IndexedDbService,
    private coursesStore: CoursesStore,
  ) {
    this.startIntercom();

    this.isEnvironmentLoading$ = this.environmentService.isEnvironmentLoading$;
    this.isTranslationsLoading$ = this.translationsService.translationsLoading$;
    this.isAuthLoading$ = this.authenticationService.isAuthLoading$;
    this.isAuthenticated$ = this.authenticationService.isAuthenticated$;

    this.router.events.subscribe((e) => {
      if (e instanceof NavigationStart && e.url.match('admin')) {
        this.appStore.loaderState$.next(true);
        this.isAdminView = true;
      }

      if (e instanceof NavigationStart && !e.url.match('admin')) {
        this.isAdminView = false;
      }

      if (e instanceof NavigationEnd) {
        scrollSectionToTop();
        this.currentUrl = e.urlAfterRedirects;
        const urlItems: string[] = this.currentUrl.split('/');
        const subUrl: string = urlItems.slice(0, urlItems.length - 1).join('/');

        this.userDashboardActive = [
          `/${this.hubsStore.useHubUrl}/brands`,
          `/${this.hubsStore.useHubUrl}/profile`,
          `/${this.hubsStore.useHubUrl}/notifications`,
        ].some((route) => this.currentUrl === route || subUrl === route);

        if (this.currentUrl.includes('/mybrand/')) {
          this.brandDashboardActive = [
            `/${this.hubsStore.useHubUrl}/mybrand/${this.currentUrl.split('mybrand/')[1].split('/')[0]}/dashboard`,
            `/${this.hubsStore.useHubUrl}/mybrand/${this.currentUrl.split('mybrand/')[1].split('/')[0]}/events/filter/created`,
            `/${this.hubsStore.useHubUrl}/mybrand/${this.currentUrl.split('mybrand/')[1].split('/')[0]}/events/filter/invited`,
            `/${this.hubsStore.useHubUrl}/mybrand/${this.currentUrl.split('mybrand/')[1].split('/')[0]}/events/filter/created?sort=desc`,
            `/${this.hubsStore.useHubUrl}/mybrand/${this.currentUrl.split('mybrand/')[1].split('/')[0]}/events/filter/invited?sort=desc`,
            `/${this.hubsStore.useHubUrl}/mybrand/${this.currentUrl.split('mybrand/')[1].split('/')[0]}/courses/filter/my`,
            `/${this.hubsStore.useHubUrl}/mybrand/${this.currentUrl.split('mybrand/')[1].split('/')[0]}/courses/filter/my?sort=desc`,
            `/${this.hubsStore.useHubUrl}/mybrand/${this.currentUrl.split('mybrand/')[1].split('/')[0]}/products/filter/all`,
            `/${this.hubsStore.useHubUrl}/mybrand/${this.currentUrl.split('mybrand/')[1].split('/')[0]}/products/filter/videos`,
            `/${this.hubsStore.useHubUrl}/mybrand/${this.currentUrl.split('mybrand/')[1].split('/')[0]}/products/filter/documents`,
            `/${this.hubsStore.useHubUrl}/mybrand/${this.currentUrl.split('mybrand/')[1].split('/')[0]}/products/filter/images`,
            `/${this.hubsStore.useHubUrl}/mybrand/${this.currentUrl.split('mybrand/')[1].split('/')[0]}/products/filter/all?sort=desc`,
            `/${this.hubsStore.useHubUrl}/mybrand/${this.currentUrl.split('mybrand/')[1].split('/')[0]}/products/filter/videos?sort=desc`,
            // eslint-disable-next-line max-len
            `/${this.hubsStore.useHubUrl}/mybrand/${this.currentUrl.split('mybrand/')[1].split('/')[0]}/products/filter/documents?sort=desc`,
            `/${this.hubsStore.useHubUrl}/mybrand/${this.currentUrl.split('mybrand/')[1].split('/')[0]}/products/filter/images?sort=desc`,
            `/${this.hubsStore.useHubUrl}/mybrand/${this.currentUrl.split('mybrand/')[1].split('/')[0]}/team/members/all`,
            `/${this.hubsStore.useHubUrl}/mybrand/${this.currentUrl.split('mybrand/')[1].split('/')[0]}/team/members/accepted`,
            `/${this.hubsStore.useHubUrl}/mybrand/${this.currentUrl.split('mybrand/')[1].split('/')[0]}/team/members/pending`,
            `/${this.hubsStore.useHubUrl}/mybrand/${this.currentUrl.split('mybrand/')[1].split('/')[0]}/team/members/all?sort=desc`,
            `/${this.hubsStore.useHubUrl}/mybrand/${this.currentUrl.split('mybrand/')[1].split('/')[0]}/team/members/accepted?sort=desc`,
            `/${this.hubsStore.useHubUrl}/mybrand/${this.currentUrl.split('mybrand/')[1].split('/')[0]}/team/members/pending?sort=desc`,
            `/${this.hubsStore.useHubUrl}/mybrand/${this.currentUrl.split('mybrand/')[1].split('/')[0]}/settings/profile`,
            `/${this.hubsStore.useHubUrl}/mybrand/${this.currentUrl.split('mybrand/')[1].split('/')[0]}/settings/plans`,
          ].some((route) => this.currentUrl.includes(route));
        }

        const shouldHideHeaderAndFooter =
          ['/events/', '/register'].every((route) => e.url.includes(route)) ||
          ['/signup', '/signin', '/resetpassword', '/brand-register'].includes(e.url) ||
          e.url.split('/').includes('code') ||
          e.url.split('/').includes('sign-up-with-event-code') ||
          e.url.split('/').includes('thank-you') ||
          (e.url.includes('/signin') && e.url.includes('verifiedEmail=')) ||
          e.url.match('/signin') ||
          e.url.match('/signup') ||
          e.url.match('/maintenance') ||
          (e.url.includes('/signup') && e.url.includes('invite=')) ||
          e.url.includes('/event-check-in') ||
          e.url.includes('/resetpassword');

        this.isHeaderAndFooterVisible =
          !shouldHideHeaderAndFooter &&
          ((this.appStore.appearanceSettings.globalSingleRoute && !!this.hubsStore.hub) ||
            !this.appStore.appearanceSettings.globalSingleRoute);

        this.appStore.setIsAsideNavigationVisible(
          e.url.includes('/events/') &&
            !!this.userStore.userEventsMap[this.eventsStore.eventId] &&
            !e.url.includes('/register'),
        );

        // eslint-disable-next-line max-len
        this.isMobileNavigationVisible =
          ['/dashboard', '/events', '/saved', '/courses', '/brands'].some((route) =>
            this.currentUrl.includes(route),
          ) &&
          !this.eventsStore.isEventRegistered &&
          !this.currentUrl.includes('register') &&
          !(this.coursesStore.course && this.currentUrl.includes('/courses') && !isDesktop()) &&
          !(
            this.eventsStore.event &&
            !this.eventsStore.isEventRegistered &&
            this.currentUrl.includes('/events') &&
            !isDesktop()
          );

        if (
          this.hubsStore.hub &&
          !this.currentUrl.includes(this.hubsStore.useHubUrl) &&
          !this.hubsStore.bindedWithDomain
        ) {
          this.hubsStore.setHub(null);
        }

        this.googleTagManagerAnalyticsService.createAndPushEvent(e);
        this.sendDataToMixPanel(e);

        this.setUserCentricsScriptTag();
        this.appStore.loaderState$.next(false);
      }

      if (e instanceof NavigationCancel) {
        this.appStore.loaderState$.next(false);
      }
      if (e instanceof NavigationError) {
        this.appStore.loaderState$.next(false);
      }

      if (this.appStore.environment.firebase.projectId.includes('pmg-hub')) {
        const favIcon: HTMLLinkElement = document.querySelector('#favicon-link');
        favIcon.href = `../assets/images/pmg/favicon.ico`;
        this.cookieService.deleteAll('/');
      }
    });

    this.eventMenuService.eventMenuItems().subscribe((res) => {
      if (!res.length) {
        this.footerHidden = true;
      } else {
        this.footerHidden = false;
      }
    });
  }

  get isShowScrollTop(): boolean {
    return !!this.router.url.match('admin');
  }

  get isShowSpecialFooterForLogoutUsers(): boolean {
    const user = this.userStore.user;
    return (
      !user &&
      this.hubsStore.hub &&
      ['/dashboard', '/events', '/courses', '/brands'].some((route) =>
        this.currentUrl?.includes(route),
      )
    );
  }

  async ngAfterViewInit(): Promise<void> {
    if (window.screen.width > 480) {
      this.sendDataToIntercom();
    }

    await this.getSystemSettings();
    this.detectAdBlock();
  }

  showNavigation(): boolean {
    return (
      !this.generalSystemSettings?.isPrivate ||
      (this.generalSystemSettings?.isPrivate && this.userStore.user?.role !== 'user')
    );
  }

  hideFullFooter(): boolean {
    return (
      !this.generalSystemSettings?.isPrivate ||
      (this.generalSystemSettings?.isPrivate && this.userStore.user?.role !== 'user')
    );
  }

  onSignUp(): void {
    this.router.navigate([`${this.hubsStore.useHubUrl}/signup`], {
      state: { url: this.router.url },
    });
  }

  private setUserCentricsScriptTag(): void {
    const userCentricsScript = document.createElement('script');
    userCentricsScript.src = 'https://app.usercentrics.eu/browser-ui/latest/loader.js';
    userCentricsScript.setAttribute('data-settings-id', '8bdyQo_tS');
    userCentricsScript.setAttribute('id', 'usercentrics-cmp');
    userCentricsScript.setAttribute('async', '');

    if (!this.appStore.environment.firebase.projectId.includes('pmg-hub')) {
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(userCentricsScript, firstScriptTag);
    }
  }

  private sendDataToIntercom(): void {
    const user: IUser = this.userStore?.user;
    this.intercomService.boot(this.appStore.environment.intercom.apiKey, user);
  }

  private sendDataToMixPanel(event: NavigationEnd): void {
    const title = this.titleService.getTitle();
    this.mixpanelService.pageVisit(title, event.url);
  }

  private async startIntercom(): Promise<void> {
    this.intercom.boot({ app_id: this.appStore.environment.intercom.apiKey });
  }

  private async getSystemSettings() {
    this.generalSystemSettings = await this.themesService.getSystemGeneralSettings();
    this.appearanceSettings = await this.themesService.getSystemAppearanceSettings();

    this.appStore.setGeneralSystemSettingse(this.generalSystemSettings);
    this.appStore.setAppearanceSystemSettingse(this.appearanceSettings);
  }

  private detectAdBlock() {
    const adElement = this.adBanner.nativeElement;
    if (!adElement || adElement.offsetHeight === 0 || adElement.offsetWidth === 0) {
      this.userHasAdblock = true;
    } else {
      this.userHasAdblock = false;
    }

    if (this.userHasAdblock) {
      this.messageService.add({
        severity: 'warn',
        summary: this.translateService.instant('adblockDetection.detect'),
        detail: this.translateService.instant(`adblockDetection.message`),
        styleClass: 'custom-toast-warn',
        life: 5000,
      });
    }
  }
}
