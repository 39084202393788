<div class="header">
  <div class="title">
    {{ 'adminEventBrands.attachBrands' | translate }}
  </div>
  <span
    *ngIf="!appStore.generalSystemSettings.enableEncryption"
    class="search-input p-input-icon-left"
    [ngClass]="{ 'p-input-icon-right': filterSearch.value !== '' }">
    <i class="far fa-search search-icon"></i>
    <input
      class="custom-input-text main-search-input"
      type="text"
      #filterSearch
      name="filterSearch"
      (input)="brandSearchChanged($event.target.value)"
      pInputText
      placeholder="{{ 'adminTable.inputSearchPlaceholder' | translate }}" />
    <i
      *ngIf="filterSearch.value"
      class="reset-btn far fa-times-circle"
      (click)="onClearBrandsSearch()">
    </i>
  </span>
  <div class="btn-container">
    <app-button
      buttonText="{{ 'adminEventBrands.attach' | translate }}"
      [buttonSize]="buttonSize.MEDIUM"
      [loading]="attachLoading"
      [disabled]="!selectedEntries.length"
      (click)="attach()" />
    <i
      class="fa-regular fa-xmark close-icon"
      (click)="onCloseDialog()"></i>
  </div>
</div>
<div class="container">
  <div class="filters">
    <div class="title">{{ 'adminUsers.tagsFilterLabel' | translate }}</div>
    <span
      class="search-input p-input-icon-left"
      [ngClass]="{ 'p-input-icon-right': filterSearch.value !== '' }">
      <i class="far fa-search search-icon"></i>
      <input
        class="custom-input-text"
        type="text"
        #filterSearch
        name="filterSearch"
        (input)="tagSearchChanged($event.target.value)"
        pInputText
        placeholder="{{ 'adminTable.inputSearchPlaceholder' | translate }}" />
      <i
        *ngIf="filterSearch.value !== ''"
        class="reset-btn far fa-times-circle"
        (click)="filterSearch.value = ''">
      </i>
    </span>
    <div class="tag-list">
      <p-checkbox
        *ngFor="let tag of filteredTags"
        class="custom-input-checkbox-sm c-my-2 c-ml-1"
        (ngModelChange)="tagSelectionChanged($event)"
        [value]="tag"
        [label]="tag.title"
        [(ngModel)]="selectedTags">
      </p-checkbox>
    </div>
  </div>
  <admin-table
    checkBoxSelection="true"
    emptyStateKey="adminBrands.emptyState"
    emptyStateIcon="fak fa-lox-crown"
    scssClass="table-for-attach paginator-border-bottom-right-radius show-row-border-bottom"
    [lazyLoading]="loadingEntries"
    [columns]="columns"
    [entries]="entries"
    [pageSize]="pageSize"
    [totalRecords]="totalRecords"
    [(selectedEntries)]="selectedEntries"
    (lazyLoad)="fetchEntries($event)">
    <ng-template
      let-entry
      epTemplateKey="nameCell">
      <div class="name-cell">
        <div>
          <img
            *ngIf="entry.logo"
            class="banner"
            [defaultImage]="imgPreloader"
            [lazyLoad]="entry.logo | imageSize" />
          <div
            *ngIf="!entry.logo"
            class="placeholder-container">
            <img
              class="placeholder"
              [src]="logoAvatar | imageSize" />
          </div>
        </div>
        <p class="brand-name">{{ entry.name }}</p>
      </div>
    </ng-template>
  </admin-table>
</div>

<p-toast key="user">
  <ng-template
    let-message
    pTemplate="message">
    <app-toast
      [severity]="message.severity"
      [summary]="message.summary"
      [detail]="message.detail">
    </app-toast>
  </ng-template>
</p-toast>
