<div
  *ngIf="loggedUser"
  (click)="bookmark()"
  id="bookmarkBtn"
  class="bookmark-button">
  <i
    id="bookmarkBtn"
    [ngClass]="{
      fas: bookmarking,
      'fa-circle-notch': bookmarking,
      'fa-spin': bookmarking,
      far: !bookmarking,
      'fa-bookmark': !bookmarking,
      active: isBookmarked,
    }"></i>
</div>
