import { Component, Input, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

import { sessionFields, userEventFields, userFields } from 'src/app/core/utils/export-fields';
import {
  AttendeesService,
  EventsService,
  TicketsService,
  TranslationsService,
  UserSessionsService,
} from 'src/app/core/services';
import {
  IEvent,
  IEventConsent,
  IEventRegistrationForm,
  IExportField,
  ITicket,
} from 'src/app/core/models';
import { EventsStore, SessionsStore } from 'src/app/core/stores';

@Component({
  selector: 'app-export-fields-selector',
  templateUrl: './export-fields-selector.component.html',
  styleUrls: ['./export-fields-selector.component.scss'],
})
export class ExportFieldsSelectorComponent implements OnInit {
  @Input() isSessionRegistrationPage = false;

  userKeys: IExportField[] = userFields;
  userEventKeys: IExportField[] = userEventFields;
  eventConsentKeys: IExportField[] = [];
  sessionKeys: IExportField[] = sessionFields;
  selectedUserKeys: IExportField[] = [];
  selectedSessionKeys: IExportField[] = [];
  selectedUserEventKeys: IExportField[] = [];
  selectedEventConsentKeys: IExportField[] = [];
  event: IEvent;
  eventSettings: IEventRegistrationForm;
  lang: string;
  isLoading: boolean = false;
  eventTicketsInformation: ITicket[];

  constructor(
    private eventsService: EventsService,
    private translationsService: TranslationsService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private attendeesService: AttendeesService,
    private eventsStore: EventsStore,
    private sessionsStore: SessionsStore,
    private ticketsService: TicketsService,
    private userSessionsService: UserSessionsService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.lang = this.translationsService.getCurrentLanguage;
    const eventUrl = this.eventsStore.adminEvent.link;
    this.event = await this.eventsService.getByUrl(eventUrl);
    this.eventSettings = await this.eventsService.getEventRegisterSettings(this.event.id);
    this.eventTicketsInformation = await this.ticketsService.getEventTickets(this.event.id);

    const consents: IEventConsent[] = await this.eventsService.getAllConsentsFromEvent(
      this.event.id,
    );

    consents.map((c: IEventConsent, index: number) => {
      if (c.type == 'single' || c.type == 'pop-up') {
        this.eventConsentKeys.push({
          header: c.title,
          key: c.title,
          type: c.type,
          order: index,
        });
      } else {
        this.eventConsentKeys.push({
          header: c.options?.length ? c.options[0].title : null,
          key: c.options?.length ? c.options[0].title : null,
          type: c.type,
          order: index,
        });
        this.eventConsentKeys.push({
          header: c.options?.length ? c.options[1].title : null,
          key: c.options?.length ? c.options[1].title : null,
          type: c.type,
          order: index,
        });
      }
    });

    this.preselectDefaultFields();
    this.preselectVisibleFields();
  }

  preselectDefaultFields(): void {
    this.selectedUserKeys.push({ header: 'First Name', key: 'firstName', order: 2 });
    this.selectedUserKeys.push({ header: 'Last Name', key: 'lastName', order: 3 });
    this.selectedUserKeys.push({ header: 'Email Address', key: 'email', order: 4 });

    this.selectedUserEventKeys.push({ header: 'Attendance', key: 'status', order: 1 });
    this.selectedUserEventKeys.push({ header: 'Invitation Link', key: 'invitationLink', order: 2 });
    this.selectedUserEventKeys.push({ header: 'Role', key: 'role', order: 3 });

    this.selectUserEventField({
      header: 'Session Registrations',
      key: 'sessionRegistrations',
      order: 11,
    });

    if (this.eventTicketsInformation && this.eventTicketsInformation.length) {
      this.selectUserEventField({ header: 'Ticket', key: 'ticketId', order: 4 });
      this.selectUserEventField({ header: 'Ticket Price', key: 'ticketPrice', order: 5 });
    }

    if (this.eventSettings.isShowAccommodationTransfer) {
      this.selectUserField({ header: 'Travel Document', key: 'travelDocument', order: 15 });
      this.selectUserField({ header: 'Travel Document Number', key: 'passportNumber', order: 16 });
      this.selectUserField({ header: 'Date of Issue', key: 'dateOfIssue', order: 17 });
      this.selectUserField({ header: 'Date of Expiry', key: 'dateOfExpiry', order: 18 });

      this.selectUserEventField({ header: 'Hotel', key: 'hotel', order: 5 });
      this.selectUserEventField({ header: 'Room Type', key: 'roomType', order: 6 });
      this.selectUserEventField({ header: 'Room Partner', key: 'roomPartner', order: 7 });
      this.selectUserEventField({ header: 'Preferred Airport', key: 'preferredAirport', order: 8 });
      this.selectUserEventField({
        header: 'Alternative Airport',
        key: 'alternativeAirport',
        order: 9,
      });
      this.selectUserEventField({ header: 'Clothing Size', key: 'clothingSize', order: 11 });
    }
  }

  preselectVisibleFields(): void {
    const visibleFields = this.eventSettings.fields
      .filter((f) => f.isVisible)
      .map((f) => f.fieldName);
    this.userKeys
      .filter((uk) => visibleFields.includes(uk.key))
      .map((uk) => this.selectUserField(uk));

    this.userEventKeys
      .filter((uek) => visibleFields.includes(uek.key))
      .map((uek) => this.selectUserEventField(uek));
  }

  isSelectedUserKey(key: string): string {
    return this.selectedUserKeys.some((x) => x.key == key) ? 'selected' : '';
  }

  isSelectedUserEventKey(key: string): string {
    return this.selectedUserEventKeys.some((x) => x.key == key) ? 'selected' : '';
  }

  isSelectedEventConsentKey(key: string): string {
    return this.selectedEventConsentKeys.some((x) => x.key == key) ? 'selected' : '';
  }

  isSelectedSessionKey(key: string): string {
    return this.selectedSessionKeys.some((x) => x.key == key) ? 'selected' : '';
  }

  selectUserField(key: IExportField): void {
    this.selectedUserKeys.some((x) => x.key == key.key)
      ? (this.selectedUserKeys = this.selectedUserKeys.filter((x) => x.key != key.key))
      : this.selectedUserKeys.push(key);
  }

  selectUserEventField(key: IExportField): void {
    this.selectedUserEventKeys.some((x) => x.key == key.key)
      ? (this.selectedUserEventKeys = this.selectedUserEventKeys.filter((x) => x.key != key.key))
      : this.selectedUserEventKeys.push(key);
  }

  selectEventConsentField(key: IExportField): void {
    this.selectedEventConsentKeys.some((x) => x.key == key.key)
      ? (this.selectedEventConsentKeys = this.selectedEventConsentKeys.filter(
          (x) => x.key != key.key,
        ))
      : this.selectedEventConsentKeys.push(key);
  }

  selectSessionField(key: IExportField): void {
    this.selectedSessionKeys.some((selectedKey: IExportField) => selectedKey.key == key.key)
      ? (this.selectedSessionKeys = this.selectedSessionKeys.filter((x) => x.key != key.key))
      : this.selectedSessionKeys.push(key);
  }

  unselectAll(): void {
    this.selectedUserKeys = [];
    this.selectedUserEventKeys = [];
    this.selectedEventConsentKeys = [];
    this.selectedSessionKeys = [];
  }

  async exportSession(): Promise<void> {
    try {
      this.isLoading = true;
      const sessionId = this.sessionsStore.adminSession.id;
      await this.userSessionsService.exportRegisteredUsersFromSession(
        this.event.id,
        this.event.title,
        sessionId,
        this.lang,
        this.selectedUserKeys,
        this.selectedSessionKeys,
      );
    } catch (error) {
      this.showToastMessage('error', 'application.toasters.error');
    } finally {
      this.isLoading = false;
    }
  }

  export(): void {
    if (this.isSessionRegistrationPage) {
      this.exportSession();
    } else {
      this.exportAttendees();
    }
  }

  async exportAttendees(): Promise<void> {
    try {
      this.isLoading = true;
      await this.attendeesService.exportAttendees(
        this.event.id,
        this.event.title,
        this.lang,
        this.selectedUserKeys,
        this.selectedUserEventKeys,
        this.selectedEventConsentKeys,
      );
    } catch (error) {
      this.showToastMessage('error', 'application.toasters.error');
    } finally {
      this.isLoading = false;
    }
  }

  private showToastMessage(severity: 'success' | 'error', detail: string): void {
    this.messageService.add({
      severity: severity,
      summary: this.translateService.instant(severity),
      detail: this.translateService.instant(detail),
      styleClass: 'custom-toast',
    });
  }
}
