<div class="sidebar-navigation-container">
  <div class="header">
    <span class="back">
      <i
        class="far fa-arrow-left"
        (click)="appStore.setSidebarVisible(!appStore.isSideBarVisible)">
      </i>
    </span>
    <img
      [src]="logo | imageSize"
      alt="{{ 'shared.eventLogo' | translate }}" />
  </div>

  <div class="body">
    <div class="menu-container">
      <ng-container
        *ngFor="let menuItem of menuItems"
        [ngSwitch]="menuItem.type">
        <ng-container *ngSwitchCase="'divider'">
          <div class="divider"></div>
        </ng-container>
        <ng-container *ngSwitchCase="'link'">
          <div
            [routerLink]="menuItem.url"
            [routerLinkActive]="menuItem.url ? 'active' : ''"
            class="icon-box"
            (click)="menuItemClick(menuItem)">
            <i [attr.class]="menuItem.iconClass"></i>
            <span class="text">{{ menuItem.name | translate }}</span>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'custom-page'">
          <div
            [routerLink]="menuItem.url"
            [routerLinkActive]="menuItem.url ? 'active' : ''"
            class="icon-box"
            (click)="menuItemClick(menuItem)">
            <i [attr.class]="menuItem.iconClass"></i>
            <span class="text">{{ menuItem.name | translate }}</span>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'group'">
          <div
            [routerLink]="menuItem.pageId"
            [routerLinkActive]="menuItem.url ? 'active' : ''"
            class="icon-box"
            (click)="menuItemClick(menuItem)">
            <i [attr.class]="menuItem.iconClass"></i>
            <span class="text">{{ menuItem.name | translate }}</span>
            <span
              class="expand-arrow"
              (click)="onCloseShowDropDown()">
              <i
                [ngClass]="{
                  'far fa-chevron-down': !menuItem.expanded,
                  'far fa-chevron-up': menuItem.expanded,
                }"
                class="pi">
              </i>
            </span>
          </div>
          <div
            *ngIf="menuItem.expanded"
            class="sub-menu-items">
            <div
              *ngFor="let subMenuItem of menuItem.items"
              [routerLink]="subMenuItem.url"
              [routerLinkActive]="subMenuItem.url ? 'active' : ''"
              (click)="menuItemClick(menuItem)"
              class="icon-box">
              <span class="text">{{ subMenuItem.name | translate }}</span>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>

  <div class="footer flex align-items-center">
    <div
      (click)="onExitEventClick()"
      class="exit-event-btn">
      <i class="far fa-arrow-right-from-bracket"></i>
      <span class="pl-3 text">{{ 'asideNavigation.exitEvent' | translate }}</span>
    </div>
  </div>
</div>
