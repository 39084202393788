import firebase from 'firebase/compat/app';
import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { filter, finalize, map } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

import { Firestore, IFirestore, Storage, IStorage, Timestamp } from 'src/app/firebase';
import {
  ChatConversation,
  ChatConversationDTO,
  ChatMessage,
  ChatMessageAttachment,
  ChatMessageDTO,
  ChatState,
  IUser,
} from 'src/app/core/models';
import { EventsStore, UsersStore, HubsStore } from 'src/app/core/stores';
import { UsersService, AuthenticationService, ThemesService } from 'src/app/core/services';
import { API_ROUTES as apiRoutes } from 'src/app/shared';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  private chatState$ = new BehaviorSubject<ChatState>({ enabled: false });
  private firestore: IFirestore;
  private storage: IStorage;
  private startNewChat$ = new Subject<{ chatConversation: ChatConversation; contextKey: string }>();
  private usersCache: { [key: string]: IUser } = {};
  private chatTrigger = new BehaviorSubject<void>(null);

  constructor(
    private router: Router,
    private eventsStore: EventsStore,
    private usersStore: UsersStore,
    private usersService: UsersService,
    private authenticationService: AuthenticationService,
    private hubsStore: HubsStore,
    private themesService: ThemesService,
  ) {
    this.firestore = Firestore();
    this.storage = Storage();

    combineLatest([
      this.router.events.pipe(
        filter((e) => e instanceof NavigationEnd),
        map((e) => e as NavigationEnd),
      ),
      this.authenticationService.isAuthenticated$,
      this.chatTrigger.asObservable(),
    ]).subscribe(([navigationEndEvent, isUserAuthenticated]) => {
      // the chat can only be shown if the user is authenticated
      if (!isUserAuthenticated) {
        this.chatState$.next({ enabled: false });
        return;
      }

      // check if the route is under the 'events' path
      const condition: boolean =
        (navigationEndEvent.urlAfterRedirects.indexOf('/events/') !== 0 &&
          !this.eventsStore.event) ||
        navigationEndEvent.urlAfterRedirects.includes('/register');
      if (condition) {
        this.chatState$.next({ enabled: false });
        return;
      }

      // check if the user is registered on the event
      // only users registered on the event can have a chat
      if (!this.eventsStore.isEventRegistered) {
        this.chatState$.next({ enabled: false });
        return;
      }

      if (this.eventsStore.event?.hideChat) {
        this.chatState$.next({ enabled: false });
        return;
      }

      this.chatState$.next({
        enabled: true,
        context: {
          primaryColor: this.getPrimaryColor(),
          key: this.eventsStore.event.id,
        },
      });
    });
  }

  chatState(): Observable<ChatState> {
    return this.chatState$.asObservable();
  }

  setChatState(state: boolean): void {
    if (state) {
      this.chatState$.next({
        enabled: true,
        context: {
          primaryColor: this.getPrimaryColor(),
          key: this.eventsStore.event.id,
        },
      });
    } else {
      this.chatState$.next({ enabled: false });
    }
  }

  updateChatState(): void {
    this.chatTrigger.next();
  }

  chatConversations(contextKey: string = 'general'): Observable<ChatConversation[]> {
    const chatConversationsSubject = new Subject<ChatConversation[]>();
    const loggedUserId = this.usersStore.user.id;

    // subscribe to conversations updated for the provided context
    const unsubscribeFn = this.firestore
      .collection(apiRoutes.chats)
      .where('usersIds', 'array-contains-any', [this.usersStore.user.id])
      .onSnapshot({
        next: async (querySnapshot) => {
          if (!querySnapshot.empty) {
            const filteredDTOs = querySnapshot.docs
              .filter((d) => d.exists && d.get(`context.${contextKey}`) !== undefined)
              .map((d) => d.data() as ChatConversationDTO);

            // get a list of all the usersIds with conversations with the current logged user
            const usersIds = filteredDTOs.map(
              (dto) => dto.usersIds[dto.usersIds[0] === loggedUserId ? 1 : 0],
            );
            usersIds.push(loggedUserId);

            // get the users presented on the conversation
            const usersMap = await this.getUsersMap(usersIds);

            // build the conversation list
            const chatConversations: ChatConversation[] = filteredDTOs
              .map((data) => {
                // get the userId from the user that is talking with the current logged user
                const userId = data.usersIds[data.usersIds[0] === loggedUserId ? 1 : 0];

                // calculate the unread messages based on the totalMessages and the user's read messages
                const totalMessages = data.context[contextKey]?.totalMessages ?? 0;
                const totalReadMessages =
                  (data.context[contextKey]?.usersReadMessages
                    ? data.context[contextKey]?.usersReadMessages[loggedUserId]
                    : 0) ?? 0;
                const unreadMessages = Math.max(totalMessages - totalReadMessages, 0);

                // build the final ChatConversation obj
                return {
                  id: data.id,
                  conversationWithUser: usersMap[userId],
                  lastMessage: this.chatMessageMapper(
                    data.context[contextKey]?.lastMessage,
                    usersMap,
                  ),
                  unreadMessages,
                };
              })
              .sort((a, b) => (a.lastMessage < b.lastMessage ? -1 : 1));

            chatConversationsSubject.next(chatConversations);
          } else {
            chatConversationsSubject.next([]);
          }
        },
      });

    return chatConversationsSubject.pipe(finalize(() => unsubscribeFn()));
  }

  chatMessages(
    chatConversationId: string,
    contextKey: string = 'general',
  ): Observable<ChatMessage[]> {
    const subject = new Subject<ChatMessage[]>();
    const loggedUserId = this.usersStore.user.id;
    const unsubscribe = this.firestore
      .collection(apiRoutes.chatConversationMessages(chatConversationId))
      .where('context', '==', contextKey)
      .onSnapshot({
        next: async (querySnapshot) => {
          if (!querySnapshot.empty) {
            const dtos = querySnapshot.docs.map((d) => d.data() as ChatMessageDTO);

            // get a list of all the usersIds
            // repeated ids are removed
            const uniqueUsersIds = dtos
              .map((dto) => dto.userId)
              .filter((value, index, self) => self.indexOf(value) === index);

            // get the users presented on the conversation
            const usersCache = await this.getUsersMap(uniqueUsersIds);

            // build the conversation list
            const chatMessages: ChatMessage[] = dtos.map((data) =>
              this.chatMessageMapper(data, usersCache),
            );

            subject.next(chatMessages);

            // update the total messages read by the logged user
            const chatConversationDoc = this.firestore
              .collection(apiRoutes.chats)
              .doc(chatConversationId);

            // create the object with the changes
            const chatConversation = JSON.parse(
              `{"context": {"${contextKey}": {"usersReadMessages": {"${loggedUserId}": ${chatMessages.length}}}}}`,
            );
            await chatConversationDoc.set(chatConversation, { merge: true });
          }
        },
      });

    return subject.pipe(finalize(() => unsubscribe()));
  }

  async sendMessage(
    chatConversationId: string,
    message: { text: string; attachment?: ChatMessageAttachment },
    context: string = 'general',
  ): Promise<string> {
    const doc = this.firestore
      .collection(apiRoutes.chatConversationMessages(chatConversationId))
      .doc();
    const dto = {
      id: doc.id,
      userId: this.usersStore.user.id,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      context,
      message: '',
      attachment: null,
    };

    if (message.text) {
      dto.message = message.text;
    }

    if (message.attachment) {
      dto.attachment = message.attachment;
    }

    await doc.set(dto);
    const chatConversationDoc = this.firestore.collection(apiRoutes.chats).doc(chatConversationId);
    const chatConversation = {
      context: {},
    };

    chatConversation.context[context] = {
      lastMessage: dto,
      totalMessages: firebase.firestore.FieldValue.increment(1),
    };
    await chatConversationDoc.set(chatConversation, { merge: true });

    return dto.id;
  }

  async toggleMessageLike(chatConversationId: string, chatMessage: ChatMessage): Promise<void> {
    const doc = this.firestore
      .collection(apiRoutes.chatConversationMessages(chatConversationId))
      .doc(chatMessage.id);
    await doc.update({
      liked: !chatMessage.liked,
    });
  }

  async startChat(userId: string, context: string = 'general'): Promise<void> {
    // check if the chat is not started with the current logged user
    if (userId === this.usersStore.user.id) {
      return;
    }
    let chatConversation: ChatConversation;

    // get the cached users
    const cachedUsers = await this.getUsersMap([userId]);

    // check if there are already a chat with this user
    const res = await this.firestore
      .collection(apiRoutes.chats)
      .where('usersIds', 'array-contains', this.usersStore.user.id)
      .get();
    const currentConversationWithTheUser = res.docs.filter((d) =>
      d.get('usersIds').includes(userId),
    )[0];

    if (currentConversationWithTheUser) {
      const data = currentConversationWithTheUser.data();
      const message = data?.context ? data?.context[`${context}`]?.lastMessage : null;
      chatConversation = {
        id: data.id,
        conversationWithUser: cachedUsers[userId],
        lastMessage: message ? this.chatMessageMapper(message, cachedUsers) : null,
        unreadMessages: 0,
      };
    } else {
      // create a new one
      const doc = this.firestore.collection(apiRoutes.chats).doc();

      await doc.set({
        id: doc.id,
        usersIds: [userId, this.usersStore.user.id],
      });

      chatConversation = {
        id: doc.id,
        conversationWithUser: cachedUsers[userId],
        lastMessage: undefined,
        unreadMessages: 0,
      };
    }

    // launch the chat window
    this.startNewChat$.next({ chatConversation, contextKey: context });
  }

  startNewChat(): Observable<{ chatConversation: ChatConversation; contextKey: string }> {
    return this.startNewChat$.asObservable();
  }

  async uploadAttachment(conversationId: string, file: File): Promise<string> {
    const imageRef = await this.storage
      .ref(`/chats/${conversationId}`)
      .child(`${uuid()}_${file.name}`);
    await imageRef.put(file);
    const publicFilePath = await imageRef.getDownloadURL();
    return publicFilePath;
  }

  async getUserChats(userId: string): Promise<ChatConversationDTO[]> {
    try {
      const userChats: ChatConversationDTO[] = (
        await this.firestore
          .collection(apiRoutes.chats)
          .where('usersIds', 'array-contains-any', [userId])
          .get()
      ).docs.map((doc) => doc.data() as ChatConversationDTO);

      return userChats;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async deleteChat(chatId: string): Promise<boolean> {
    try {
      await this.firestore.collection(apiRoutes.chats).doc(chatId).delete();

      return true;
    } catch (err) {
      console.warn(err);
      throw new Error(err);
    }
  }

  private getPrimaryColor(): string {
    const currentTheme = localStorage.getItem('styleTheme');
    const currentThemeProps =
      this.themesService.systemAppearanceSettings[currentTheme + 'Theme'].properties;

    return (
      this.eventsStore.event.primaryColor ||
      this.hubsStore.hub?.primaryColor ||
      currentThemeProps['--appPrimaryColor']
    );
  }

  private chatMessageMapper(
    source: ChatMessageDTO,
    usersCache: { [key: string]: IUser },
  ): ChatMessage {
    if (!source) {
      return null;
    }

    return {
      id: source.id,
      user: usersCache[source.userId],
      timestamp: source.timestamp ? source.timestamp.toDate() : new Date(),
      message: source.message,
      attachment: source.attachment,
      liked: !!source.liked,
    };
  }

  private async getUsersMap(usersIds: string[]): Promise<{ [key: string]: IUser }> {
    // get all the users for the provided ids
    // if the user is cached, uses that user
    // otherwise, gets the user and caches it locally
    const res = await Promise.all<IUser>(
      usersIds.map((id) => {
        if (this.usersCache[id]) {
          return Promise.resolve(this.usersCache[id]);
        } else {
          return new Promise(async (resolve) => {
            const user = await this.usersService.getOne(id);
            this.usersCache[id] = user;
            resolve(user);
          });
        }
      }),
    );

    const users = {};

    // transforms the user array into a map
    // for faster querying
    res
      .filter((u) => u)
      .forEach((user) => {
        users[user.id] = user;
      });

    return users;
  }
}
