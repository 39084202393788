<div class="sidebar-container">
  <img
    [ngSrc]="logo"
    class="logo"
    width="48"
    height="20"
    priority />
  <nav class="nav">
    <ul class="list">
      <li
        *ngIf="isShowGlobalItem"
        class="list-item"
        [class.active]="currentUrl.includes('system-settings')">
        <a
          class="item-link"
          routerLink="/admin/system-settings">
          <i class="fa-regular fa-globe item-icon"></i>
          <span class="item-text">{{ 'adminMenu.systemSettings' | translate }}</span>
        </a>
      </li>
    </ul>
  </nav>
</div>
