import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  IHubTag,
  IHub,
  AlgoliaSearchResult,
  IBrand,
  IHubBrand,
  IUser,
  IUserHub,
  UserHubRoles,
} from 'src/app/core/models';
import { Firestore, IFirestore, ServerTimestamp } from 'src/app/firebase';
import { CollectionTypes } from 'src/app/shared';
import { capitalizeFirstLetter } from 'src/app/core/utils';
import { AppStore } from 'src/app/app.store';
import { HttpClientService } from './../http/http-client.service';
import { StorageService } from '../storage/storage.service';
import { AlgoliaService } from '../algolia/algolia.service';
import { UsersStore, HubsStore } from '../../stores';
import { UsersService } from '../users';
import { UserSubscriptionTypes } from 'src/app/core/enums';

@Injectable({
  providedIn: 'root',
})
export class HubsService {
  private firestore: IFirestore;

  constructor(
    private http: HttpClientService,
    private storageService: StorageService,
    private hubsStore: HubsStore,
    private algoliaService: AlgoliaService,
    private usersStore: UsersStore,
    private usersService: UsersService,
    public appStore: AppStore,
  ) {
    this.firestore = Firestore();
  }

  public async fetchUnattached(
    hubId: string,
    attachedUsers: string[],
    pageIndex: number,
    pageSize: number,
    searchTerm: string,
  ): Promise<AlgoliaSearchResult<IUser>> {
    const facetFilters = attachedUsers.map((id) => `userId:-${id}`).join(',');
    const resultFromAlgolia: AlgoliaSearchResult<IUserHub> =
      await this.algoliaService.search<IUserHub>(
        CollectionTypes.USER_HUBS,
        `${searchTerm} ${hubId}`,
        pageSize,
        pageIndex * pageSize,
        '',
        facetFilters,
      );

    const result: AlgoliaSearchResult<IUser> = {
      total: resultFromAlgolia.total,
      results: resultFromAlgolia.results.map((item: any) => item.user),
    };

    return result;
  }

  public async get(hub: IHub, pageSize: number = 20): Promise<IHub[]> {
    const hubs: IHub[] = [];

    const query = await this.firestore
      .collection(CollectionTypes.HUBS)
      .orderBy('title')
      .startAfter(hub ? hub.title : 0)
      .limit(pageSize)
      .get();

    if (!query.empty) {
      query.forEach((x) => {
        hubs.push(x.data() as IHub);
      });

      return hubs;
    } else {
      return null;
    }
  }

  public async getAll(): Promise<IHub[]> {
    const result: IHub[] = (
      await this.firestore.collection(CollectionTypes.HUBS).orderBy('_title_').get()
    ).docs.map((doc) => doc.data() as IHub);

    this.hubsStore.setHubs(result);

    return result;
  }

  public async getOne(hubId: string): Promise<IHub> {
    try {
      const hubQuery = await this.firestore.collection(CollectionTypes.HUBS).doc(hubId).get();
      if (hubQuery.exists) {
        return hubQuery.data() as IHub;
      } else {
        return null;
      }
    } catch (error) {
      throw error;
    }
  }

  public async getHubByBrandId(brandId: string): Promise<IHub> {
    try {
      const hubsQuery = await this.firestore
        .collection(CollectionTypes.HUBS)
        .where('brandId', '==', brandId)
        .get();

      if (hubsQuery.size > 0) {
        return hubsQuery.docs[0].data() as IHub;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  public async getByUrl(url: string): Promise<IHub> {
    try {
      const cachedHub = this.hubsStore.getHubByUrl(url);

      if (cachedHub) {
        return cachedHub;
      } else {
        const hubsQuery = await this.firestore
          .collection(CollectionTypes.HUBS)
          .where('url', '==', url)
          .get();

        if (hubsQuery.size > 0) {
          return hubsQuery.docs[0].data() as IHub;
        } else {
          return null;
        }
      }
    } catch (error) {
      throw error;
    }
  }

  public async getByBrandId(brandId: string): Promise<IHub> {
    try {
      const hubsQuery = await this.firestore
        .collection(CollectionTypes.HUBS)
        .where('brandId', '==', brandId)
        .get();

      if (hubsQuery.size > 0) {
        return hubsQuery.docs[0].data() as IHub;
      } else {
        return null;
      }
    } catch (error) {
      throw error;
    }
  }

  public checkHubByLinkExists(hubUrl: string, hubId: string = null): Observable<boolean> {
    const hubs = [];
    let hubsQuery = this.firestore.collection(CollectionTypes.HUBS).where('url', '==', hubUrl);

    return from(hubsQuery.get()).pipe(
      map((response) => {
        response.forEach((doc) => {
          if (doc.data().id === hubId) {
            return;
          }
          hubs.push(doc.data());
        });
        return hubs.length > 0;
      }),
    );
  }

  public async hasAccess(hub: string, userId: string): Promise<any> {
    try {
      const hubUsersQuery = await this.firestore
        .collection(CollectionTypes.USER_HUBS)
        .where('hubId', '==', hub)
        .where('userId', '==', userId)
        .get();

      if (hubUsersQuery.size > 0) {
        return hubUsersQuery.docs[0].data() as IHub;
      } else {
        return null;
      }
    } catch (error) {
      throw error;
    }
  }

  public async sendInvite(hub: IHub, userId: string): Promise<void> {
    return await this.http.get<any>(
      `hubs/${hub.id}/invite?userId=${userId}&tenantId=${hub.tenantId}`,
    );
  }

  public async addMember(hub: IHub, user: IUser, role: string = 'admin'): Promise<any> {
    try {
      const userHubDoc = this.firestore.collection(CollectionTypes.USER_HUBS).doc();
      let newFirestoreUserHub = {
        id: userHubDoc.id,
        hubId: hub.id,
        role,
        userId: user.id,
        _hub_title_: hub._title_,
        _firstName_: user._firstName_,
        _lastName_: user._lastName_,
        _company_: user._company_,
        _position_: user._position_,
        createdAt: null,
        createdBy: null,
        updatedAt: null,
        updatedBy: null,
      };

      if (this.appStore.generalSystemSettings.enableEncryption) {
        newFirestoreUserHub = this.usersService.encryptUserData(
          newFirestoreUserHub,
        ) as unknown as IUserHub;
        console.log('↑ hubs addMember encrypted');
      }

      await userHubDoc.set({ ...newFirestoreUserHub });

      if (this.appStore.generalSystemSettings.enableEncryption) {
        newFirestoreUserHub = this.usersService.decryptUserData(
          newFirestoreUserHub,
        ) as unknown as IUserHub;
        console.log('↑ hubs addMember return decrypted');
      }

      return newFirestoreUserHub;
    } catch (error) {
      throw error;
    }
  }

  public async removeMember(id: string): Promise<boolean> {
    try {
      await this.firestore.collection(CollectionTypes.USER_HUBS).doc(id).delete();
      await this.algoliaService.removeUserHub(id);

      return true;
    } catch (error) {
      throw error;
    }
  }

  public async getAdminTeamMembers(hubId: string): Promise<IUser[]> {
    try {
      const users: IUser[] = [];
      const hubUsersQuery = await this.firestore
        .collection(CollectionTypes.USER_HUBS)
        .where('hubId', '==', hubId)
        .where('role', 'in', ['owner', 'admin'])
        .orderBy('_firstName_')
        .orderBy('_lastName_')
        .get();

      const promises = [];
      const usersIds = [];
      const usersObjects = {};

      hubUsersQuery.docs.map(async (hubUserDoc) => {
        const hubUser = hubUserDoc.data();
        usersIds.push(hubUser.userId);
        usersObjects[hubUser.userId] = hubUser;
      });

      while (usersIds.length) {
        const chunk = usersIds.splice(0, 10);
        promises.push(
          this.firestore
            .collection(CollectionTypes.USERS)
            .where('id', 'in', chunk)
            .orderBy('_firstName_')
            .orderBy('_lastName_')
            .get(),
        );
      }

      const queryAllUsers = await Promise.all(promises);

      queryAllUsers.map((queryUsers) => {
        queryUsers.docs.map((doc) => {
          let user = doc.data() as IUser;

          if (this.appStore.generalSystemSettings.enableEncryption) {
            user = this.usersService.decryptUserData(user) as IUser;
            console.log('↑ getAdminTeamMembers decrypted user');
          }

          users.push(
            Object.assign(user, {
              userHubId: usersObjects[doc.id].id,
              hubRole: usersObjects[doc.id].role,
            }),
          );
        });
      });

      return users;
    } catch (error) {
      console.warn(error);
      return [];
    }
  }

  public async addBrand(hub: IHub, brand: IBrand): Promise<void> {
    const hubBrandDoc = this.firestore.collection(CollectionTypes.HUB_BRANDS).doc();

    const hubBrand: IHubBrand = {
      id: hubBrandDoc.id,
      name: brand.name,
      _brand_name_: brand.name.toLowerCase(),
      _hub_title_: hub.title.toLowerCase(),
      brandId: brand.id,
      hubId: hub.id,
      createdAt: ServerTimestamp(),
      createdBy: this.usersStore.user.id,
      updatedAt: null,
      updatedBy: null,
    };

    await hubBrandDoc.set({ ...hubBrand });
    await this.algoliaService.createHubBrand(hubBrand);
  }

  public async removeBrand(hubId: string, brandId: string): Promise<boolean> {
    try {
      const hubBrandId = (
        await this.firestore
          .collection(CollectionTypes.HUB_BRANDS)
          .where('hubId', '==', hubId)
          .where('brandId', '==', brandId)
          .get()
      ).docs[0].id;

      await this.firestore.collection(CollectionTypes.HUB_BRANDS).doc(hubBrandId).delete();
      await this.algoliaService.removeHubBrand(hubBrandId);

      return true;
    } catch (error) {
      console.log(error);
    }
  }

  public async fetchUnattachedBrands(
    eventId: string,
    pageIndex: number,
    pageSize: number,
    searchTerm: string,
  ): Promise<AlgoliaSearchResult<IBrand>> {
    const attachedBrands = await this.getBrandsFromHub(eventId);
    const facetFilters = attachedBrands
      .map((b: IHubBrand) => b.brandId)
      .map((id) => `objectID:-${id}`)
      .join(',');

    const result = await this.algoliaService.search<any>(
      'brands',
      searchTerm,
      pageSize,
      pageIndex * pageSize,
      '',
      facetFilters,
    );

    return {
      total: result.total,
      results: result.results,
    };
  }

  async getBrandsFromHub(hubId: string): Promise<IHubBrand[]> {
    const brands: IHubBrand[] = [];
    const eventBrandsQuery = await this.firestore
      .collection(CollectionTypes.HUB_BRANDS)
      .where('hubId', '==', hubId)
      .get();

    eventBrandsQuery.forEach((brandQuery) => {
      const brand: IHubBrand = brandQuery.data() as IHubBrand;
      brands.push(brand);
    });

    return brands;
  }

  public async getTeam(hub: string): Promise<IUserHub[]> {
    try {
      const team = [];
      const hubUsersQuery = await this.firestore
        .collection(CollectionTypes.USER_HUBS)
        .where('hubId', '==', hub)
        .where('role', 'in', ['owner', 'admin'])
        .get();

      if (hubUsersQuery.size > 0) {
        hubUsersQuery.forEach((e) => {
          let userHub = e.data();
          if (this.appStore.generalSystemSettings.enableEncryption) {
            userHub = this.usersService.decryptUserData(userHub) as IUserHub;
            console.log('↑ hubs.service getTeam decrypted userHub');
          }
          team.push(userHub);
        });
        return team;
      } else {
        return [];
      }
    } catch (error) {
      throw error;
    }
  }

  public async create(hub: IHub, user: IUser): Promise<IHub> {
    try {
      const preHubReq = await this.firestore.collection(CollectionTypes.HUBS).doc();
      hub.id = preHubReq.id;
      if (hub.logoDark !== null) {
        const fileName = `file_${new Date().getTime()}`;
        const newLogoImage = await this.storageService.upload(hub.logoDark, 'hubs', fileName);
        hub.logoDark = newLogoImage;
      } else {
        hub.logoDark = null;
      }
      await this.firestore
        .collection(CollectionTypes.HUBS)
        .doc(hub.id)
        .set({ ...hub });
      await this.algoliaService.createHub(hub);

      const preUserHubReq = await this.firestore.collection(CollectionTypes.USER_HUBS).doc();
      const newUserHub: IUserHub = {
        id: preUserHubReq.id,
        hubId: hub.id,
        userId: user.id,
        role: UserHubRoles.ADMIN,
        _hub_title_: hub._title_,
        _firstName_: user._firstName_,
        _lastName_: user._lastName_,
        _company_: user._company_,
        _position_: user._position_,
        createdAt: null,
        createdBy: null,
        updatedAt: null,
        updatedBy: null,
      };
      await this.firestore
        .collection(CollectionTypes.USER_HUBS)
        .doc(newUserHub.id)
        .set({ ...newUserHub });
      await this.algoliaService.createUserHub(newUserHub);
      const userHubs = this.usersStore.userHubs;
      userHubs.push(newUserHub);
      this.usersStore.setUserHubs(userHubs);
      const userRef = await this.firestore
        .collection(CollectionTypes.USERS)
        .doc(this.usersStore.user.id)
        .get();
      let userCreatedHubsCount = userRef.data().createdHubsCount;
      if (!userCreatedHubsCount) {
        userCreatedHubsCount = 1;
      } else {
        userCreatedHubsCount++;
      }
      await this.firestore
        .collection(CollectionTypes.USERS)
        .doc(this.usersStore.user.id)
        .update({ createdHubsCount: userCreatedHubsCount });

      return hub;
    } catch (error) {
      throw error;
    }
  }

  public async update(hubId: string, hub: IHub): Promise<IHub> {
    try {
      const hubDocument = this.firestore.collection(CollectionTypes.HUBS).doc(hubId);

      if (
        hub.logoDark instanceof File ||
        hub.logoLight instanceof File ||
        hub.banner instanceof File ||
        hub.icon instanceof File
      ) {
        const hubDocData = await hubDocument.get();

        if (hub.logoDark instanceof File) {
          const currentLogoImage = hubDocData.get('logoDark');

          if (currentLogoImage) {
            await this.storageService.delete(currentLogoImage);
          }

          if (hub.logoDark !== null) {
            const fileName = `file_${new Date().getTime()}`;
            const newLogoImage = await this.storageService.upload(hub.logoDark, 'hubs', fileName);
            hub.logoDark = newLogoImage;
          } else {
            hub.logoDark = null;
          }
        }

        if (hub.logoLight instanceof File) {
          const currentLogoImage = hubDocData.get('logoLight');

          if (currentLogoImage) {
            await this.storageService.delete(currentLogoImage);
          }

          if (hub.logoLight !== null) {
            const fileName = `file_${new Date().getTime()}`;
            const newLogoImage = await this.storageService.upload(hub.logoLight, 'hubs', fileName);
            hub.logoLight = newLogoImage;
          } else {
            hub.logoLight = null;
          }
        }

        if (hub.banner instanceof File) {
          const currentBannerImage = hubDocData.get('banner');
          console.log(currentBannerImage);

          if (currentBannerImage) {
            await this.storageService.delete(currentBannerImage);
          }

          if (hub.banner !== null) {
            const fileName = `file_${new Date().getTime()}`;
            const newCoverImage = await this.storageService.upload(hub.banner, 'hubs', fileName);
            hub.banner = newCoverImage;
          } else {
            hub.banner = null;
          }
        }

        if (hub.icon instanceof File) {
          const currentIcon = hubDocData.get('icon');

          if (currentIcon) {
            await this.storageService.delete(currentIcon);
          }

          if (hub.icon !== null) {
            const fileName = `file_${new Date().getTime()}`;
            const newIcon = await this.storageService.upload(hub.icon, 'hubs', fileName);
            hub.icon = newIcon;
          } else {
            hub.icon = null;
          }
        }
      }

      await hubDocument.update({ ...hub });

      return hub;
    } catch (error) {
      console.log(error);
    }
  }

  public async remove(hubDocId: string): Promise<boolean> {
    try {
      await this.firestore.collection(CollectionTypes.HUBS).doc(hubDocId).delete();
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  public async getTagsRelation(hubId: string): Promise<IHubTag[]> {
    try {
      const hubTags = await this.firestore
        .collection(CollectionTypes.HUB_TAGS)
        .where('hubId', '==', hubId)
        .get();

      if (hubTags.size > 0) {
        return hubTags.docs.map((doc) => doc.data()) as IHubTag[];
      } else {
        return [];
      }
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  public async saveTags(hubId: string, tags: Array<IHubTag>): Promise<Array<void>> {
    try {
      const tagsIdsToSave = tags.map((t) => t.tagId);
      const savedHubTagsQuery = await this.firestore
        .collection(CollectionTypes.HUB_TAGS)
        .where('hubId', '==', hubId)
        .get();
      if (!savedHubTagsQuery.empty) {
        savedHubTagsQuery.forEach(async (entry) => {
          const entryData = entry.data();

          if (!tagsIdsToSave.includes(entryData.tagId)) {
            await this.firestore.collection(CollectionTypes.HUB_TAGS).doc(entry.id).delete();
          }
        });
      }

      const promises: Array<Promise<void>> = tags.map(async (tag: IHubTag) => {
        if (tag.id) {
          return this.firestore
            .collection(CollectionTypes.HUB_TAGS)
            .doc(tag.id)
            .update(Object.assign({}, tag));
        } else {
          const preHubTagReq = await this.firestore.collection(CollectionTypes.HUB_TAGS).doc();
          tag.id = preHubTagReq.id;
          return preHubTagReq.set(Object.assign({}, tag));
        }
      });

      return Promise.all(promises);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  public async getTeamMembers(
    hubId: string,
    pageIndex: number,
    pageSize: number,
    searchTerm: string,
  ): Promise<AlgoliaSearchResult<IUser>> {
    const res = await this.algoliaService.search(
      CollectionTypes.USER_HUBS,
      `${searchTerm}`,
      pageSize,
      pageIndex * pageSize,
      `hubId:${hubId}`,
    );

    if (this.appStore.generalSystemSettings.enableEncryption) {
      console.log(`ALGOLIA hubs getTeamMembers  res.results `, res.results);
      res.results = res.results.map((b) => {
        console.log('res', b);
        return this.usersService.decryptUserData(b, ['_highlightResult', 'objectID']) as IUser;
      });
      console.log(`↑ ALGOLIA hubs getTeamMembers `);
    }

    return {
      total: res.total,
      results: res.results.map((r: any) =>
        Object.assign({}, r.user, {
          memberRole: r.role ? capitalizeFirstLetter(r.role) : 'Admin',
          hubUserId: r.id,
        }),
      ) as IUser[],
    };
  }

  async canAdminCreateNewHub(userId: string): Promise<boolean> {
    const user = await this.usersService.getOne(userId);
    return (
      (user.subscriptionType === UserSubscriptionTypes.BASIC && user.createdHubsCount < 1) ||
      (user.subscriptionType === UserSubscriptionTypes.PRO && user.createdHubsCount < 2) ||
      user.subscriptionType === UserSubscriptionTypes.UNLIMITED
    );
  }

  async doesAdminHaveActiveSubscription(userId: string): Promise<boolean> {
    const user = await this.usersService.getOne(userId);
    return user.hasEverSubscribed && user.subscriptionType !== UserSubscriptionTypes.NONE;
  }
}
