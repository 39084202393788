import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { MessageService } from 'primeng/api';
import { DividerModule } from 'primeng/divider';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { HubsStore, UsersStore } from 'src/app/core/stores';
import { userAvatar } from 'src/app/shared/utils';
import {
  AuthenticationService,
  IntercomService,
  MixpanelService,
  StripeService,
  ThemesService,
  UsersService,
} from 'src/app/core/services';
import { IGeneralSettings } from 'src/app/core/models';
import { environment } from 'src/environments/environment';
import { goToLink } from 'src/app/core/utils';
import { AppStore } from 'src/app/app.store';
import { LanguageSwitcherComponent } from '../language-switcher/language-switcher.component';

@Component({
  selector: 'app-user-account-navigation',
  standalone: true,
  imports: [
    CommonModule,
    OverlayPanelModule,
    NgOptimizedImage,
    TranslateModule,
    DividerModule,
    LanguageSwitcherComponent,
    FormsModule,
    ReactiveFormsModule,
  ],
  templateUrl: './user-account-navigation.component.html',
  styleUrls: ['./user-account-navigation.component.scss'],
})
export class UserAccountNavigationComponent implements OnInit {
  @ViewChild('profilePopUp', { static: true }) profilePopUp: OverlayPanel;

  generalSystemSettings: IGeneralSettings;
  stripeCustomerId: string;
  inBrandDashboard: boolean;
  billingPortalLoading = false;

  constructor(
    private usersStore: UsersStore,
    private usersService: UsersService,
    private router: Router,
    private hubsStore: HubsStore,
    private themesService: ThemesService,
    private stripeService: StripeService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private titleService: Title,
    private mixpanelService: MixpanelService,
    private authenticationService: AuthenticationService,
    private intercomService: IntercomService,
    private appStore: AppStore,
  ) {}

  get userAvatar(): string {
    return this.usersStore.user?.profileImage || userAvatar;
  }

  get isNavigationItemVisible(): boolean {
    return (
      this.usersStore.user?.role !== 'user' ||
      (!this.generalSystemSettings?.isPrivate && this.usersStore.user?.role === 'user')
    );
  }

  get isGlobalProfileVisible(): boolean {
    return (
      this.usersStore.user?.role !== 'user' ||
      (!this.generalSystemSettings?.hideProfile && this.usersStore.user?.role === 'user')
    );
  }

  get isGlobalHelpVisible(): boolean {
    return (
      this.usersStore.user?.role !== 'user' ||
      (!this.generalSystemSettings?.hideHelp && this.usersStore.user?.role === 'user')
    );
  }

  async ngOnInit(): Promise<void> {
    this.inBrandDashboard = this.router.url.includes('mybrand');
    this.generalSystemSettings = await this.themesService.getSystemGeneralSettings();
    this.stripeCustomerId = await this.stripeService.getCustomerId(this.usersStore.user?.id);
  }

  async openView(viewPath: string): Promise<void> {
    this.profilePopUp.hide();

    if (viewPath === '/mybrand') {
      const brand = await this.usersService.getLoggedUserBrandData(this.usersStore.user?.brandId);
      this.router.navigate([`${this.hubsStore.useHubUrl}${viewPath}/${brand.link}`]);
      this.inBrandDashboard = true;
    } else {
      this.router.navigate([`${this.hubsStore.useHubUrl}/${viewPath}`]);
      this.inBrandDashboard = false;
    }
  }

  openProfile(activeTab: 'profile' | 'plans'): void {
    this.profilePopUp.hide();
    this.router.navigate([`${this.hubsStore.useHubUrl}/profile`], { state: { activeTab } });
  }

  async openCustomerProfile(): Promise<void> {
    try {
      this.billingPortalLoading = true;
      let returnUrl: string;
      if (environment.env === 'LOCAL') {
        returnUrl = `http://127.0.0.1:4200/${this.hubsStore.useHubUrl}/profile`;
      } else {
        returnUrl = `${this.hubsStore.environmentBaseUrl}/${this.hubsStore.useHubUrl}/profile`;
      }

      await this.stripeService.checkStripeCustomer();
      const isAdmin = (await this.usersService.getOne(this.usersStore.user.id)).hasEverSubscribed;
      const url: string = await this.stripeService.getCustomerPortalLink(
        this.usersStore.user.stripeId,
        returnUrl,
        isAdmin,
      );
      goToLink(url);
      this.billingPortalLoading = false;
    } catch (error) {
      this.showToastMessage('error', `stripeErrorCodes.${error.message}`);
    }
  }

  onNavigateToHelp(): void {
    this.profilePopUp.hide();
    goToLink('https://intercom.help/ebner-media-group-gmbh--co-kg-16cb6e95f312/de');
    const title = this.titleService.getTitle();

    // send event to mixpanel analytics
    this.mixpanelService.helpCenterClick(title, this.router.url);
  }

  async logout(): Promise<void> {
    window.localStorage.setItem('logout-event', Math.random().toString());
    this.profilePopUp.hide();

    // If user sign outs from hub, we need to redirect them on specific logout page, otherwise on global dashboard
    if (this.hubsStore.hub) {
      this.authenticationService.signOut(false);
      this.router.navigate([`/${this.hubsStore.useHubUrl}/logout`]);
    } else if (this.generalSystemSettings.isPrivate) {
      this.authenticationService.signOut(false);
      this.router.navigate([`/signin`]);
    } else {
      this.authenticationService.signOut();
    }
    this.usersStore.reset();

    // send data to intercom
    this.intercomService.shutdown();
    this.intercomService.boot(this.appStore.environment.intercom.apiKey);
  }

  private showToastMessage(severity: 'success' | 'error', detail: string): void {
    const fallbackTranslationKey = 'toasters.error';
    let message;
    this.translateService.get(detail).subscribe((translation) => {
      message =
        translation === detail
          ? this.translateService.instant(fallbackTranslationKey)
          : translation;
    });
    this.messageService.add({
      severity,
      summary: this.translateService.instant(severity),
      detail: this.translateService.instant(message),
      styleClass: 'custom-toast',
    });
  }
}
