<div class="container">
  <image
    [src]="notification.user.profileImage"
    [text]="imageText(notification.user)"
    size="super-small"></image>
  <div class="main-block">
    <p
      class="text"
      [innerHTML]="notificationText"></p>
    <i
      class="fal fa-info-circle info-icon"
      pTooltip="{{ notification.appointment.description }}"
      tooltipPosition="bottom">
    </i>
    <!-- TODO delete or update during implementation for an appointment with external link -->
    <ng-container *ngIf="appointment?.location?.includes(dailyMeetingSublink)">
      <button
        *ngIf="isShowJoinBtn"
        pButton
        label="{{ 'buttons.joinMeeting' | translate }}"
        (click)="onJoiningMeeting()"></button>
      <div
        *ngIf="!isShowJoinBtn"
        [pTooltip]="textForTooltip">
        <button
          pButton
          [disabled]="true"
          label="{{ 'buttons.joinMeeting' | translate }}"></button>
      </div>
    </ng-container>
  </div>
</div>
