<div class="container">
  <div class="title">
    <span class="stat-name">{{ statName | translate }}</span>
    <i
      class="fa-regular fa-circle-info info"
      pTooltip="{{ tooltipText | translate }}"
      [tooltipPosition]="tooltipPosition"
      [tooltipStyleClass]="tooltipStyleClass"
      [tooltipEvent]="tooltipEvent"
      [tabIndex]="tabIndex">
    </i>
  </div>
  <div>
    <canvas
      baseChart
      style="height: 20.0075rem; width: content-box"
      [type]="chartType"
      [data]="chartData"
      [options]="chartOptions"
      [legend]="chartLegend">
    </canvas>
  </div>
</div>
