<div>
  <ng-container *ngIf="(chapters | async)?.length">
    <p-accordion
      expandIcon="far fa-chevron-down"
      collapseIcon="far fa-chevron-up"
      [styleClass]="stylesClassForAccordion">
      <ng-container *ngFor="let chapter of chapters | async; let i = index; trackBy: trackByFn">
        <p-accordionTab *ngIf="chapter.assetsFullModel.length">
          <ng-template pTemplate="header">
            <p class="chapter-title">{{ chapter.title }}</p>
          </ng-template>
          <div
            *ngFor="let asset of chapter.assetsFullModel; trackBy: trackByFn"
            class="chapter-asset-card-container"
            [class.selected]="asset.id === activeAsset.id">
            <app-chapter-asset-card
              [asset]="asset"
              (click)="onChangeActiveAsset(asset)">
            </app-chapter-asset-card>
          </div>
        </p-accordionTab>
      </ng-container>
    </p-accordion>
  </ng-container>

  <div
    *ngIf="!(chapters | async)?.length"
    class="empty-list">
    <app-empty-state
      icon="far fa-graduation-cap"
      text="{{ 'courseRegisteredPage.noChapters' | translate }}">
    </app-empty-state>
  </div>
</div>
