import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { autorun, IReactionDisposer } from 'mobx';
import { TranslateService } from '@ngx-translate/core';

import {
  IAdminTableFilter,
  IAdminTableFilterChange,
  imgPreloader,
  logoAvatar,
  ConfirmDialogComponent,
  IAdminTableColumn,
} from 'src/app/shared';
import { IBrand, IBrandMember, ICourse, ICourseBrand, IHub, ITag } from 'src/app/core/models';
import {
  BrandsService,
  TagsService,
  UsersService,
  CourseBrandsService,
  HubsService,
} from 'src/app/core/services';
import { HubsStore, CoursesStore } from 'src/app/core/stores';
import { AttachCourseBrandsDialogComponent, NewBrandComponent } from 'src/app/admin/';
import { capitalizeFirstLetter } from 'src/app/core/utils';
import { ButtonSize, ButtonStyle, ButtonType } from 'src/app/standalone';

@Component({
  selector: 'app-course-brands',
  templateUrl: './course-brands.component.html',
  styleUrls: ['./course-brands.component.scss'],
})
export class CourseBrandsComponent implements OnInit, OnDestroy {
  loading = true;
  loadingEntries = true;
  columns: IAdminTableColumn[] = [
    {
      field: 'name',
      template: 'nameCell',
      titleTranslationKey: 'adminCourseBrandsList.tableTitle',
    },
    {
      field: 'brandOwner',
      titleTranslationKey: 'adminCourseBrandsList.tableBrandOwner',
      sortable: true,
    },
    {
      field: 'brandOwnerEmail',
      titleTranslationKey: 'adminCourseBrandsList.tableBrandOwnerEmail',
      sortable: true,
    },
    { field: 'actions', template: 'actionsCell' },
  ];
  courseBrands: ICourseBrand[] = [];
  entries: ICourseBrand[] = [];
  totalRecords = 0;
  pageSize = 25;
  courseAssetIds: string[] = [];
  brands: IBrand[] = [];
  selectedBrands: string[] = [];
  tags: ITag[] = [];
  imgPreloader = imgPreloader;
  logoAvatar = logoAvatar;
  tableFilters: IAdminTableFilter[] = [];
  buttonStyle = ButtonStyle;
  buttonType = ButtonType;
  buttonSize = ButtonSize;

  private selectedTags: string[] = [];
  private disposer: IReactionDisposer;

  constructor(
    public hubsStore: HubsStore,
    public coursesStore: CoursesStore,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private dialogService: DialogService,
    private brandsService: BrandsService,
    private tagsService: TagsService,
    private courseBrandsService: CourseBrandsService,
    private usersService: UsersService,
    private translateService: TranslateService,
    private hubsService: HubsService,
  ) {}

  get course(): ICourse {
    return this.coursesStore.adminCourse;
  }

  async ngOnInit(): Promise<void> {
    this.disposer = autorun(async () => {
      if (this.course) {
        this.loadingEntries = true;
        this.loading = true;

        await this.fetchCourseBrands();

        this.loadingEntries = false;
        this.loading = false;
      }
    });

    this.tags = await this.tagsService.getByIdsOrderedByTitle(this.course.tags);
    this.brands = await this.brandsService.getAll();

    this.tableFilters = [
      {
        key: 'TAGS',
        label: 'adminCourseBrandsList.tags',
        options: this.tags.map((t) => ({ label: t.title, value: t.id })),
      },
    ];

    this.loadingEntries = false;
    this.loading = false;
  }

  async onSearchTermChange(e): Promise<void> {
    this.loadingEntries = true;
    this.entries = this.courseBrands.filter((x) => x._brandName_.includes(e.toLowerCase()));
    this.loadingEntries = false;
  }

  async fetchEntries(event: any): Promise<void> {
    this.loadingEntries = true;
    this.pageSize = event.rows;
    await this.fetchCourseBrands();
    this.loadingEntries = false;
  }

  filterDuplicates(array: any[]): any[] {
    const newArray = [];

    array.forEach((x) => {
      if (!newArray.includes(x)) {
        newArray.push(x);
      }
    });

    return newArray;
  }

  openAttachBrandsDialog(): void {
    this.dialogService
      .open(AttachCourseBrandsDialogComponent, {
        width: '90%',
        height: '80%',
        closable: false,
        styleClass: 'attach-assets-dialog',
      })
      .onClose.subscribe(async () => {
        this.loadingEntries = true;
        this.entries = [];
        await this.fetchCourseBrands();
        this.loadingEntries = false;
      });
  }

  async filtersChange(e: IAdminTableFilterChange[]): Promise<void> {
    this.loadingEntries = true;
    this.selectedTags = e.filter((f) => f.filterKey === 'TAGS').map((f) => f.optionKey);

    if (this.selectedTags.length) {
      const entries = this.courseBrands.filter((b) =>
        b.tags.some((r) => this.selectedTags.indexOf(r) >= 0),
      );
      this.entries = entries;
    } else {
      this.entries = this.courseBrands;
    }

    this.loadingEntries = false;
  }

  async openNewBrandDialog(): Promise<void> {
    let hub: IHub = this.hubsStore.hub;

    if (!hub) {
      hub = await this.hubsService.getOne(this.course.hubId);
    }
    const newBrandDialogRef: DynamicDialogRef = this.dialogService.open(NewBrandComponent, {
      width: '40%',
      height: '40%',
      closable: false,
      styleClass: 'attach-new-brand-dialog',
      data: {
        course: this.course,
        hub,
      },
    });

    const brandId: string = await firstValueFrom(newBrandDialogRef.onClose);
    if (brandId) {
      this.router.navigate([
        `/${this.hubsStore.useHubUrl}/admin/courses/${this.course.link}/setup/brands/${brandId}/profile`,
      ]);
    } else {
      await this.fetchCourseBrands();
    }
  }

  edit(event: Event, entry: ICourseBrand): void {
    event.stopPropagation();
    this.router.navigate(
      [
        `/${this.hubsStore.useHubUrl}/admin/courses/${this.coursesStore.adminCourse.link}/setup/brands/${entry.brandId}`,
      ],
      {
        relativeTo: this.route,
        state: {
          brandId: entry,
        },
      },
    );
  }

  remove(event: Event, entry: ICourseBrand): void {
    event.stopPropagation();
    const deleteDialogRef = this.dialogService.open(ConfirmDialogComponent, {
      closable: false,
      styleClass: 'confirm-dialog',
      data: {
        titleKey: 'adminCourseBrandsList.deleteBrand',
        descriptionKey: 'adminCourseBrandsList.deleteBrandText',
        confirmBtnKey: 'adminCourseBrandsList.confirmBtn',
        cancelBtnKey: 'adminCourseBrandsList.cancelBtn',
        entry,
      },
    });

    deleteDialogRef.onClose.pipe(take(1)).subscribe(async (result: 'cancel' | 'confirm') => {
      if (result === 'confirm') {
        try {
          const deleteResult = this.courseBrandsService.delete(entry.id);
          if (deleteResult) {
            this.removeEntry(entry);
            this.messageService.add({
              severity: 'success',
              summary: this.translateService.instant('success'),
              detail: this.translateService.instant('adminCourseBrandsList.successDeleteBrand'),
              styleClass: 'custom-toast',
            });
          }
        } catch (error) {
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('error'),
            detail: this.translateService.instant('adminCourseBrandsList.errorDeleteBrand'),
            styleClass: 'custom-toast',
          });
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.disposer();
  }

  private async fetchCourseBrands(): Promise<void> {
    const response: ICourseBrand[] = await this.courseBrandsService.getAllCourseBrandsByCourseId(
      this.coursesStore.adminCourse.id,
    );
    this.courseBrands = response;
    this.entries = this.courseBrands;
    this.totalRecords = this.entries.length;

    const brandIds: string[] = [];

    this.entries.forEach((courseBrand: ICourseBrand) => {
      if (!brandIds.includes(courseBrand.brandId)) {
        brandIds.push(courseBrand.brandId);
      }
    });

    const brandUsers: IBrandMember[] = await this.usersService.getUserBrandsByBrandIds(brandIds);
    const brands: IBrand[] = await this.brandsService.getByIds(brandIds);

    this.entries.forEach((courseBrand: ICourseBrand) => {
      const brandMember: IBrandMember = brandUsers.find(
        (brandUser: IBrandMember) => brandUser.brandId === courseBrand.brandId,
      );
      const brand: IBrand = brands.find(
        (brandItem: IBrand) => brandItem.id === courseBrand.brandId,
      );

      if (brandMember) {
        courseBrand.brandOwner = `${capitalizeFirstLetter(brandMember._firstName_)} ${capitalizeFirstLetter(brandMember._lastName_)}`;
        courseBrand.brandOwnerDisplayEmail = brandMember.displayEmail
          ? brandMember.displayEmail
          : brandMember.email;
        courseBrand.brandOwnerEmail = brandMember.email.toLowerCase();
        courseBrand.brandLogo = brand.logo;
      }
    });
  }

  private removeEntry(entry: ICourseBrand): void {
    this.entries.splice(this.entries.indexOf(entry), 1);
    this.totalRecords -= 1;
  }

  async onRowClicked(id: string): Promise<void> {
    const courseBrand: ICourseBrand = await this.courseBrandsService.getOne(id);
    this.router.navigate([
      `/${this.hubsStore.useHubUrl}/admin/courses/${this.coursesStore.adminCourse.link}/setup/brands/${courseBrand.brandId}/profile`,
    ]);
  }
}
