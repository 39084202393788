<app-loader *ngIf="loading"></app-loader>
<div>
  <stripe-pricing-table
    #stripeTable
    *ngIf="!loading"
    [attr.pricing-table-id]="stripe_table"
    [attr.publishable-key]="stripe_key"
    [attr.customer-email]="usersStore.user?.email"
    [attr.client-reference-id]="usersStore.user?.id">
  </stripe-pricing-table>
</div>
