import { Injectable } from '@angular/core';

import { IChannel, IClientConfiguration } from 'src/app/core/models';
import { HttpClientService } from '../http/http-client.service';

@Injectable({
  providedIn: 'root',
})
export class IvsService {
  constructor(private readonly httpClientService: HttpClientService) {}

  async createChannelAsync(
    clientConfiguration: IClientConfiguration,
    channelName: string,
    enableRecording: boolean,
  ): Promise<IChannel> {
    return this.httpClientService.post<IChannel>(`events/createStreamChannel`, {
      awsConfig: clientConfiguration,
      channelName: channelName,
      enableRecording: enableRecording,
    });
  }

  async getStream(channelArn: string) {
    return this.httpClientService.get(`events/createStreamChannel/${channelArn}`);
  }

  async deleteChannelAsync(
    clientConfiguration: IClientConfiguration,
    channelArn: string,
    recordingConfigurationArn: string,
    deleteRecording: boolean = false,
  ): Promise<void> {
    await this.httpClientService.post<IChannel>(`events/deleteStreamChannel`, {
      awsConfig: clientConfiguration,
      channelArn: channelArn,
      recordingConfigurationArn: recordingConfigurationArn,
      deleteRecording,
    });
  }
}
