import { Timestamp } from 'src/app/firebase';

export interface ICourseBrand {
  id: string;
  courseId: string;
  brandId: string;
  status: boolean;
  tags: string[];
  level: string;
  _brandName_: string;
  _courseTitle_: string;
  createdAt: Timestamp;
  createdBy: string;
  updatedAt: Timestamp;
  updatedBy: string;

  ownerId?: string;
  brandOwner?: string;
  brandOwnerDisplayEmail?: string;
  brandOwnerEmail?: string;
  brandLogo?: string;
}
