<div class="container">
  @if (!!course) {
    <div class="media-title c-mb-4">
      <app-button
        class="c-mr-2"
        [buttonType]="buttonType.ROUND"
        [buttonSize]="buttonSize.MEDIUM"
        [buttonStyle]="buttonStyle.GHOST_PRIMARY"
        buttonRoundIcon="fa-regular fa-chevron-left"
        (click)="onBack()" />
      <span class="title">{{ form?.value?.title }}</span>
      <div
        class="dropdown-container"
        (appClickOutside)="onCloseToggleDropdown()">
        <div
          class="dropdown"
          (click)="onToggleDropdown()">
          <div
            class="circle-mark"
            [ngClass]="getClassForCircleMark"></div>
          <span>{{ currentAssetStatus }}</span>
          <div
            [ngClass]="{ rotated: expandDropdown }"
            [@iconAnimation]="expandDropdown ? 'rotated' : 'initial'">
            <i class="fa-regular fa-chevron-down"></i>
          </div>
        </div>

        @if (expandDropdown) {
          <ul
            class="dropdown-list"
            [@dropDownMenu]>
            @for (status of assetStatuses; track $index) {
              <li
                class="dropdown-list-item"
                (click)="onSelectAssetStatus(status)">
                <div
                  class="circle-mark"
                  [ngClass]="{
                    green: assetStatus[status.value] === assetStatus.PUBLISHED,
                    orange: assetStatus[status.value] === assetStatus.DRAFT,
                  }"></div>
                {{ status.label }}
              </li>
            }
          </ul>
        }
      </div>
    </div>
  }

  @if (course || event) {
    <p-divider styleClass="custom-divider-horizontal"> </p-divider>
  }

  <form
    [formGroup]="form"
    class="form-container"
    [class.c-mt-8]="course || event">
    <div class="left-column">
      @if (form.controls.type.value !== assetType.EVENT) {
        <!-- Asset file -->
        <div class="block-wrapper">
          <div class="relative">
            @if (isShowFileVideo || isShowVimeoPlayer) {
              <div class="video-container">
                @if (isShowFileVideo) {
                  <video
                    [src]="assetFile"
                    controls></video>
                } @else if (isShowVimeoPlayer) {
                  <div
                    class="vimeo-player"
                    #iframe></div>
                }
              </div>
            } @else {
              <div
                class="file-input-wrapper"
                [ngClass]="{
                  'height-zip-image': form.controls.fileType.value === assetFileTypes.ZIP,
                }"
                (drop)="onDrop($event)"
                (dragover)="onDragOver($event)">
                <input
                  class="input"
                  type="file"
                  #filePreviewer
                  (change)="onUploadAssetFile($event)" />
                @if (!assetFile) {
                  <div class="upload-icon">
                    @if (form.controls.type.value === assetType.VIDEO) {
                      <i class="fa-regular fa-video"></i>
                    } @else {
                      <i class="fa-regular fa-rectangle-history"></i>
                    }
                    <span>
                      <span
                        class="choose-file"
                        (click)="filePreviewer.click()">
                        {{ 'adminLibraryAssetFrom.chooseFile' | translate }}
                      </span>
                      {{ 'adminLibraryAssetFrom.orDragAndDrop' | translate }}
                    </span>
                    <span>
                      {{
                        form.controls.type.value === assetType.VIDEO
                          ? ('adminLibraryAssetFrom.videoFileLimit' | translate)
                          : ('adminLibraryAssetFrom.typeFileDesc' | translate)
                      }}
                    </span>
                  </div>
                }
                @if (isShowFileImg) {
                  <img
                    class="asset-file-image"
                    [src]="assetFile | imageSize"
                    [ngClass]="{
                      'scale-zip-image': form.controls.fileType.value === assetFileTypes.ZIP,
                    }"
                    alt="image" />
                }
                @if (isShowFilePdfViewer) {
                  <div class="pdf-viewer-container">
                    <embed
                      [src]="newFilePreview"
                      width="100%"
                      height="368px"
                      type="application/pdf" />
                  </div>
                }
              </div>
            }

            @if (assetFile) {
              <button
                class="p-button-rounded p-button-outlined clear-btn"
                pButton
                pRipple
                type="button"
                icon="far fa-circle-xmark"
                (click)="clearNewFilePreview()"></button>
            }
          </div>

          @if (form.controls.type.value === assetType.VIDEO) {
            <div class="separator c-mt-5 c-mb-5">
              <div></div>
              <span>{{ 'adminLibraryAssetFrom.videoSeparatorText' | translate }}</span>
              <div></div>
            </div>
          }

          @if (form.controls.type.value === assetType.VIDEO) {
            <!-- Video link input -->
            <div>
              <app-input-label
                for="video-link-input"
                text="adminLibraryAssetFrom.videoLinkLabel">
              </app-input-label>
              <div class="p-inputgroup custom-input-group">
                <span class="p-inputgroup-addon">Embed-URL</span>
                <input
                  formControlName="videoLink"
                  id="video-link-input"
                  pInputText
                  type="text"
                  placeholder="{{ 'adminLibraryAssetFrom.videoLinkLabel' | translate }}" />
              </div>
              <p class="input-note">
                {{ 'asset.videoLinkFromVimeo' | translate }}
              </p>
            </div>
          }
        </div>
      }

      <div class="block-wrapper">
        <!-- Title input -->
        <div>
          <app-input-label
            labelType="required"
            for="media-title-input"
            text="adminLibraryAssetFrom.mediaTitleLabel">
          </app-input-label>
          <input
            class="custom-input-text custom-input-text-required"
            formControlName="title"
            id="media-title-input"
            pInputText
            type="text"
            placeholder="{{ 'adminLibraryAssetFrom.mediaTitlePlaceholder' | translate }}" />
          <app-input-error-message
            [showMessage]="form.controls.title.invalid && form.controls.title.touched"
            [errorMessage]="titleErrorMessage">
          </app-input-error-message>
        </div>

        <!-- Short description input -->
        <div class="c-mb-4">
          <app-input-label
            for="short-description-input"
            text="adminLibraryAssetFrom.shortDescriptionLabel">
          </app-input-label>
          <input
            class="custom-input-text"
            formControlName="shortDescription"
            id="short-description-input"
            pInputText
            type="text"
            placeholder="{{ 'adminLibraryAssetFrom.shortDescriptionPlaceholder' | translate }}" />
        </div>

        <!-- File type input -->
        <div
          [class.c-mb-8]="!(form.controls.type.value === assetType.EVENT)"
          [class.c-mb-4]="form.controls.type.value === assetType.EVENT">
          <app-input-label
            for="file-type-input"
            text="adminLibraryAssetFrom.typeLabel">
          </app-input-label>
          <p-dropdown
            class="custom-dropdown"
            panelStyleClass="custom-dropdown-panel"
            [options]="fileTypes"
            formControlName="type"
            inputId="file-type-input"
            placeholder="{{ 'adminLibraryAssetFrom.typeLabel' | translate }}">
            <ng-template
              let-type
              pTemplate="selectedItem">
              {{ getFileTypeText(type) }}
            </ng-template>
            <ng-template
              let-type
              pTemplate="item">
              {{ getFileTypeText(type) }}
            </ng-template>
          </p-dropdown>
        </div>

        @if (form.controls.type.value === assetType.EVENT) {
          <!-- Date input -->
          <div class="form-field-block">
            <app-input-label
              labelType="required"
              for="date-input"
              text="adminLibraryAssetFrom.dateLabel">
            </app-input-label>
            <p-calendar
              class="custom-calendar custom-calendar-required"
              panelStyleClass="custom-calendar-panel-style-class"
              formControlName="date"
              [monthNavigator]="true"
              [yearNavigator]="true"
              [showIcon]="true"
              dateFormat="dd/mm/yy"
              yearRange="1900:2050"
              inputId="date-input"
              icon="fak fa-lox-calendar"
              placeholder="23/01/2022"
              appendTo="body">
            </p-calendar>
            <app-input-error-message
              [showMessage]="form.controls.date.invalid && form.controls.date.touched"
              errorMessage="{{ 'application.forms.required' | translate }}">
            </app-input-error-message>
          </div>

          <div class="two-elements-block c-pr-0 c-pl-0">
            <!-- Start time input -->
            <div>
              <app-input-label
                labelType="required"
                for="start-time-input"
                text="adminLibraryAssetFrom.startTimeLabel">
              </app-input-label>
              <p-calendar
                class="custom-calendar custom-calendar-time custom-calendar-required"
                formControlName="start"
                placeholder="09:00"
                inputId="start-time-input"
                [timeOnly]="true"
                [showIcon]="true"
                icon="far fa-clock"
                appDateMask>
              </p-calendar>
              <app-input-error-message
                [showMessage]="form.controls.start.invalid && form.controls.start.touched"
                errorMessage="{{ 'application.forms.required' | translate }}">
              </app-input-error-message>
            </div>

            <!-- End time input -->
            <div>
              <app-input-label
                labelType="required"
                for="end-time-input"
                text="adminLibraryAssetFrom.endTimeLabel">
              </app-input-label>
              <p-calendar
                class="custom-calendar custom-calendar-time custom-calendar-required"
                formControlName="end"
                placeholder="09:00"
                inputId="end-time-input"
                [timeOnly]="true"
                [showIcon]="true"
                icon="far fa-clock"
                appDateMask>
              </p-calendar>
              <app-input-error-message
                [showMessage]="form.controls.end.invalid && form.controls.end.touched"
                errorMessage="{{ 'application.forms.required' | translate }}">
              </app-input-error-message>
            </div>
          </div>

          <!-- Instructors input -->
          <div class="form-field-block c-mb-4">
            <app-input-label
              labelType="tooltip-required"
              tooltipText="adminLibraryAssetFrom.instructorsTooltip"
              for="instructors-input"
              text="adminLibraryAssetFrom.instructorsLabel">
            </app-input-label>
            <p-multiSelect
              class="custom-multi-select custom-multi-select-required"
              panelStyleClass="custom-multi-select-panel-style-class"
              [options]="instructors"
              optionLabel="lastName"
              optionValue="id"
              formControlName="instructors"
              inputId="instructors-input"
              appendTo="body"
              placeholder="{{ 'adminLibraryAssetFrom.instructorsLabel' | translate }}">
              <ng-template
                let-instructor
                pTemplate="item">
                {{ instructor.firstName }} {{ instructor.lastName }}
              </ng-template>
            </p-multiSelect>
          </div>

          <!-- Asset Event Type -->
          <div class="form-field-block c-mb-4">
            <p class="form-field-title">
              {{ 'adminLibraryAssetFrom.eventType' | translate }}
              <i
                class="fa-regular fa-circle-info info"
                pTooltip="{{ 'adminLibraryAssetFrom.eventTypeTooltip' | translate }}"
                tooltipStyleClass="custom-tooltip">
              </i>
              <span class="star">*</span>
            </p>
            <div class="form-field-input-radio-block c-mb-4 c-mt-4 c-p-0">
              <p-radioButton
                class="custom-input-radio"
                [value]="eventType.BROADCAST"
                formControlName="eventType"
                inputId="broadcast-input">
              </p-radioButton>
              <app-input-label
                for="broadcast-input"
                text="adminLibraryAssetFrom.broadcastLabel"
                labelType="switch-checkbox">
              </app-input-label>
            </div>
            <div class="form-field-input-radio-block c-p-0">
              <p-radioButton
                class="custom-input-radio"
                [value]="eventType.MEETING"
                formControlName="eventType"
                inputId="meeting-input">
              </p-radioButton>
              <app-input-label
                for="meeting-input"
                text="adminLibraryAssetFrom.meetingLabel"
                labelType="switch-checkbox">
              </app-input-label>
            </div>
          </div>

          <!-- Recording -->
          <p class="form-field-title">
            {{ 'adminLibraryAssetFrom.recording' | translate }}
          </p>
          <div
            class="form-field-input-switch-block c-p-0"
            [ngClass]="form.controls.isRecording.value ? 'c-mb-4' : 'c-mb-8'">
            <p-inputSwitch
              class="custom-input-switch"
              formControlName="isRecording"
              inputId="is-recording-input">
            </p-inputSwitch>
            <app-input-label
              labelType="switch-checkbox"
              tooltipText="Need to add"
              for="is-recording-input"
              text="adminLibraryAssetFrom.enableRecording">
            </app-input-label>
          </div>

          @if (form.controls.isRecording.value) {
            <button
              class="download-button"
              pButton
              type="button"
              icon="far fa-download"
              [label]="'adminLibraryAssetFrom.downloadRecordingButton' | translate"
              [disabled]="!isAssetEventFinished"
              (click)="downloadRecord()"></button>
          }
        }

        <p-divider styleClass="custom-divider-horizontal"></p-divider>

        <!-- Tags multi select -->
        <div class="c-mt-8 c-mb-8">
          @if (tags?.length) {
            <app-input-label text="adminLibraryAssetFrom.tags"> </app-input-label>
          }
          <app-tags-multi-list
            [possibleTags]="tags"
            [selectedTags]="form.controls.tags.value"
            [adminTheme]="true"
            (onSelected)="selectTags($event)">
          </app-tags-multi-list>
          @if (!tags?.length) {
            <h3>{{ 'adminSession.noTagsAvailable' | translate }}</h3>
          }
        </div>

        <p-divider styleClass="custom-divider-horizontal"></p-divider>

        <!-- Description textarea -->
        <div class="c-mt-8 c-mb-4">
          <app-input-label text="adminLibraryAssetFrom.descriptionLabel"></app-input-label>
          <p-editor
            styleClass="custom-editor"
            formControlName="description"
            placeholder="{{ 'adminLibraryAssetFrom.descriptionPlaceholder' | translate }}">
          </p-editor>
        </div>

        <!-- Supported languages -->
        <div class="form-field-block c-mb-4">
          <app-input-label
            for="supported-languages-input"
            text="adminLibraryAssetFrom.supportedLanguagesTitle">
          </app-input-label>
          <p-multiSelect
            class="custom-multi-select"
            panelStyleClass="custom-multi-select-panel-style-class"
            [options]="supportedLangs()"
            formControlName="supportedLanguages"
            inputId="supported-languages-input"
            placeholder="{{ 'adminLibraryAssetFrom.supportedLanguagesPlaceholder' | translate }}">
            <ng-template
              let-selectedLangs
              pTemplate="selectedItems">
              @for (selectedLang of selectedLangs; track $index; let last = $last) {
                <span
                  class="
                    flag-icon flag-icon-squared flag-icon-circle
                    flag-icon-{{ selectedLang === 'en' ? 'gb' : selectedLang }}
                  ">
                </span>
                {{ 'languages.' + selectedLang | translate }}
                @if (!last) {
                  ,
                }
              }
            </ng-template>
            <ng-template
              let-lang
              pTemplate="item">
              <span
                class="
                  flag-icon flag-icon-squared flag-icon-circle
                  flag-icon-{{ lang === 'en' ? 'gb' : lang }}">
              </span>
              {{ 'languages.' + lang | translate }}
            </ng-template>
          </p-multiSelect>
          <p class="input-note">
            {{ 'adminLibraryAssetFrom.supportedLanguagesInputNote' | translate }}
          </p>
        </div>
        <div class="flex">
          <app-button
            buttonText="{{ 'adminLibraryAssetFrom.reviewEditTranslationsBtn' | translate }}"
            [buttonStyle]="buttonStyle.TERTIARY_PRIMARY"
            (click)="onOpenManageTranslationsDialog()" />
        </div>
      </div>

      @if (form.controls.type.value === assetType.EVENT) {
        <div class="block-wrapper">
          <h2 class="block-wrapper-title">
            {{ 'adminLibraryAssetFrom.eventSettings' | translate }}
          </h2>
          <div class="settings-container">
            @for (setting of eventAssetSettings; track setting.id) {
              <app-asset-event-setting
                [iconClass]="setting.iconClass"
                [settingName]="setting.settingUiName"
                [settingTooltip]="setting.settingTooltip"
                [settingValue]="form.controls[setting.id].value"
                [disabled]="setting.disabled"
                (changeValue)="onChangeEventSettingValue($event, setting.id)">
              </app-asset-event-setting>
            }
          </div>
        </div>
      }
    </div>
    <div class="right-column">
      <div class="block-wrapper">
        <!-- Image input -->
        <div>
          <p class="image-title">{{ 'asset.imageTitle' | translate }}</p>
          <div class="form-field-block image-input-wrapper">
            <input
              class="input"
              type="file"
              #assetImageInput
              (change)="onUploadAssetImage($event)" />
            @if (!assetImage) {
              <div
                class="upload-icon"
                (click)="assetImageInput.click()">
                <img
                  [src]="logoAvatar"
                  alt="Default image" />
              </div>
            }
            @if (assetImage) {
              <img
                class="asset-image"
                [src]="assetImage | imageSize"
                alt="Asset image" />
            }
            @if (form.controls.image.value) {
              <button
                class="p-button-rounded p-button-outlined clear-btn"
                pButton
                pRipple
                icon="far fa-circle-xmark"
                (click)="onClearAssetImage()"></button>
            }
          </div>
          <div class="image-note c-mt-4 c-mb-8">
            {{ 'asset.imageRecommendedResolution' | translate }}
          </div>
        </div>

        @if (hub.brands) {
          <p-divider styleClass="custom-divider-horizontal"></p-divider>

          <!-- Brands input -->
          <div class="c-mt-8 c-mb-4">
            <app-input-label
              for="brands-input"
              text="adminLibraryAssetFrom.brandLabel">
            </app-input-label>
            <p-dropdown
              class="custom-dropdown"
              panelStyleClass="custom-dropdown-panel"
              [options]="brands"
              optionLabel="name"
              optionValue="brandId"
              formControlName="brands"
              inputId="brands-input"
              placeholder="{{ 'adminLibraryAssetFrom.brandLabel' | translate }}">
            </p-dropdown>
          </div>

          <!-- Featured input -->
          <div class="input-switch-block c-mb-4">
            <p-inputSwitch
              class="custom-input-switch"
              formControlName="featured"
              inputId="featured-input">
            </p-inputSwitch>
            <app-input-label
              labelType="switch-checkbox"
              for="featured-input"
              text="adminLibraryAssetFrom.featuredLabel">
            </app-input-label>
          </div>

          <!-- Is visible input -->
          <div class="input-switch-block">
            <p-inputSwitch
              class="custom-input-switch"
              formControlName="isVisible"
              inputId="is-visible-input">
            </p-inputSwitch>
            <app-input-label
              labelType="switch-checkbox"
              for="is-visible-input"
              text="adminLibraryAssetFrom.showInListingLabel">
            </app-input-label>
          </div>
        }
      </div>
    </div>
  </form>
</div>

<div class="save-discard-actions-container">
  <app-save-discard-actions
    [isShow]="canUpdate"
    [isLoading]="isUpdating"
    (discard)="onDiscard()"
    (confirm)="onConfirm()">
  </app-save-discard-actions>
</div>

<p-toast>
  <ng-template
    let-message
    pTemplate="message">
    <app-toast
      [severity]="message.severity"
      [summary]="message.summary"
      [detail]="message.detail">
    </app-toast>
  </ng-template>
</p-toast>
