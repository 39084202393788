@if (loading) {
  <app-loader></app-loader>
} @else {
  <div class="admin-course-subscribers-container">
    <div class="admin-course-subscribers-table-container">
      <admin-table-header-navbar title="{{ 'courseReports.subscribersTitle' | translate }}">
      </admin-table-header-navbar>
      <div class="c-my-4">
        <p-divider styleClass="custom-divider-horizontal"></p-divider>
      </div>
      <admin-table-filter-navbar (onSearchTermChange)="onSearchTermChange($event)">
      </admin-table-filter-navbar>

      <admin-table
        [loading]="loadingEntries"
        [lazy]="false"
        [entries]="entries"
        [columns]="columns"
        [pageSize]="pageSize"
        [totalRecords]="totalRecords"
        (rowClicked)="onRowClick($event)"
        [searchFilterFields]="searchFilterFields"
        [searchValue]="searchTerm"
        emptyStateTitleKey="courseReports.emptyState"
        emptyStateKey="courseReports.emptyStateDescription"
        emptyStateIcon="fa-regular fa-users">
        <ng-template
          let-entry
          epTemplateKey="titleCell">
          <div class="title-cell">
            <div>
              <img
                *ngIf="entry.profileImage"
                class="img-wrapper"
                [defaultImage]="imgPreloader"
                [lazyLoad]="entry.profileImage | imageSize" />
              <div
                *ngIf="!entry.profileImage"
                class="placeholder-container">
                <img
                  class="placeholder"
                  [src]="userAvatar | imageSize" />
              </div>
            </div>
            <div class="user-info-wrapper">
              <p class="user-name">{{ entry.firstName }} {{ entry.lastName }}</p>
              <p class="user-email">{{ entry.email }}</p>
            </div>
          </div>
        </ng-template>

        <ng-template
          let-entry
          epTemplateKey="statusCell">
          <div class="count-cell">
            <div class="count">
              {{ entry.assetsViewed }}
            </div>
          </div>
        </ng-template>
        <ng-template
          let-entry
          epTemplateKey="progressCell">
          <div class="progress-container">
            <p-progressBar
              [value]="entry.averagePercentageWatched"
              [showValue]="false">
            </p-progressBar>
            <div class="percent-value">
              {{ getPercentageString(entry.averagePercentageWatched) }}
            </div>
          </div>
        </ng-template>
      </admin-table>
    </div>
  </div>
}
