<div class="container">
  <header class="header">
    <app-button
      [buttonType]="buttonType.ROUND"
      [buttonSize]="buttonSize.MEDIUM"
      [buttonStyle]="buttonStyle.GHOST_PRIMARY"
      buttonRoundIcon="fa-regular fa-chevron-left"
      (click)="onNavigateToSessionsList()" />
    <h2 class="session-name">{{ sessionTitle() }}</h2>
  </header>
  <aside class="aside">
    <app-event-session-sidebar></app-event-session-sidebar>
  </aside>
  <main class="main">
    <router-outlet></router-outlet>
  </main>
</div>
