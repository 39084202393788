import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { IEmail } from 'src/app/core/models';
import { EmailsService } from 'src/app/core/services';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss'],
})
export class EmailComponent implements OnInit {
  public loading = true;
  public email: IEmail = null;
  public emailId: string = null;

  constructor(
    private route: ActivatedRoute,
    private emailsService: EmailsService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.route.params.subscribe(async (params) => {
      this.emailId = params.id;
      this.email = await this.emailsService.get(this.emailId);
      this.loading = false;
    });
  }

  async onEmailChanged(newEmail: IEmail): Promise<void> {
    this.loading = true;
    this.emailId = newEmail.id;
    this.email = await this.emailsService.get(newEmail.id);
    this.loading = false;
  }
}
