<button
  class="btn"
  (click)="profilePopUp.toggle($event)">
  <i class="fak fa-lox-hamburger"></i>
  <img
    [ngSrc]="userAvatar"
    class="img"
    width="30"
    height="30"
    priority
    alt="{{ 'shared.avatar' | translate }}" />
</button>

<p-overlayPanel
  #profilePopUp
  styleClass="default-popup-menu-panel">
  <ng-template pTemplate>
    <div class="profile-popup-menu-container">
      <ul>
        <li (click)="openView('/dashboard')">
          <i class="fak fa-lox-home"></i>
          <a>{{ 'mainNavigation.dashboardBtn' | translate }}</a>
        </li>
        <li
          *ngIf="isNavigationItemVisible && isGlobalProfileVisible"
          (click)="openProfile('profile')">
          <i class="fa-regular fa-circle-user"></i>
          <a>{{ 'mainNavigation.userProfileBtn' | translate }}</a>
        </li>

        <li
          [icon]="'pi pi-spin pi-spinner'"
          (click)="openCustomerProfile()">
          <i class="fa-regular fa-money-check-dollar-pen"></i>
          <a>{{ 'mainNavigation.billingBtn' | translate }}</a>
          <i
            *ngIf="billingPortalLoading"
            class="fas fa-circle-notch fa-spin billing-loader"></i>
        </li>

        <div class="divider-container-1">
          <p-divider styleClass="custom-divider-horizontal"></p-divider>
        </div>

        <li
          *ngIf="usersStore.user?.brandId"
          (click)="inBrandDashboard ? openView('/dashboard') : openView('/mybrand')">
          <i class="fak fa-lox-crown"></i>
          <a *ngIf="inBrandDashboard">
            {{ 'mainNavigation.switchUserModelBtn' | translate }}
          </a>
          <a *ngIf="!inBrandDashboard">
            {{ 'mainNavigation.switchBrandModelBtn' | translate }}
          </a>
        </li>

        <div
          *ngIf="usersStore.user?.brandId"
          class="divider-container-1">
          <p-divider styleClass="custom-divider-horizontal"></p-divider>
        </div>

        <li
          *ngIf="
            isNavigationItemVisible &&
            isGlobalHelpVisible &&
            showUpgradeButton &&
            freshUser &&
            (freshUser.role === 'superAdmin' ||
              freshUser.role === 'internalAdmin' ||
              freshUser.role === 'instanceOwner' ||
              freshUser.role === 'softwareDeveloper')
          "
          (click)="openView('/admin')">
          <i class="far fa-cog"></i>
          <a>{{ 'mainNavigation.admin_btn' | translate }}</a>
        </li>
        <li
          *ngIf="isNavigationItemVisible && isGlobalHelpVisible"
          (click)="onNavigateToHelp()">
          <i class="far fa-info-circle"></i>
          <a>{{ 'mainNavigation.helpBtn' | translate }}</a>
        </li>
        <li (click)="logout()">
          <i class="far fa-sign-out"></i>
          <a>{{ 'mainNavigation.logOutBtn' | translate }}</a>
        </li>

        <div class="divider-container-2">
          <p-divider styleClass="custom-divider-horizontal"></p-divider>
        </div>

        <app-language-switcher></app-language-switcher>
      </ul>
    </div>
  </ng-template>
</p-overlayPanel>
