import { Timestamp } from 'src/app/firebase';
import { IFee } from './fee.model';
import { TicketPossibleBooked, CurrencyCode } from 'src/app/core/enums';

export interface ITicket {
  id: string;
  name: string;
  _name_: string;
  info: string;
  quantityTotal: number;
  quantitySold: number;
  quantity_sold: number;
  quantity_total: number;
  minimumQuantity: number;
  maximumQuantity: number;
  plan: string;
  start: Timestamp;
  end: Timestamp;
  isPaid: boolean;
  currency: CurrencyCode;
  price: string;
  ticketCost: IFee;
  ticketCostValue: number;
  ticketCostMajorValue: string;
  ticketCostDisplay: string;
  completeEvent: boolean;
  stages: string[];
  sessions: string[];
  onDemand: boolean;
  description: string;
  isIncludedBadge: boolean;
  isPublished: boolean;
  eventBriteId: string;
  isEventBrite: boolean;
  fee: IFee;
  major_value: string;
  createdAt: Timestamp;
  createdBy: string;
  updatedAt: Timestamp;
  updatedBy: string;
  actualFee: IFee;
  value: number;
  stripePriceId?: string;
  stripeProductId?: string;
  numberOfPurchaseTickets?: number;
  isSoldOut?: boolean;
  isPossibleBooked?: TicketPossibleBooked;
  numberBookedTickets?: number;
}

export const ticketTypes = [
  { name: 'Digital Ticket', code: 'DIGITAL' },
  { name: 'Hybrid Ticket', code: 'HYBRID' },
];
