<div
  class="presence"
  *ngIf="presence$ | async as presence"
  [style.display]="presence.status === 'online' ? 'block' : 'none'"
  [style.width]="stylesConfig.width"
  [style.height]="stylesConfig.height"
  [style.top]="stylesConfig.top"
  [style.right]="stylesConfig.right"
  [style.bottom]="stylesConfig.bottom"
  [style.left]="stylesConfig.left"
  [style.background]="stylesConfig.background"
  [style.border]="stylesConfig.border"></div>
