import * as moment from 'moment';
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

import { IAdminTableColumn } from 'src/app/shared';
import { ICourseCoupon } from 'src/app/core/models';
import { CouponsService, OpenDialogService, StripeService } from 'src/app/core/services';
import { CoursesStore, HubsStore } from 'src/app/core/stores';
import { SharedModule } from 'src/app/shared';
import { ButtonComponent } from 'src/app/standalone/shared/components';
import { ButtonSize, ButtonStyle, ButtonType } from 'src/app/standalone/shared';

@Component({
  selector: 'app-promotions',
  standalone: true,
  imports: [CommonModule, SharedModule, ButtonComponent],
  templateUrl: './promotions.component.html',
  styleUrls: ['./promotions.component.scss'],
})
export class PromotionsComponent implements OnInit {
  loading = true;
  searchValue: string = '';
  columns: IAdminTableColumn[] = [
    {
      field: 'name',
      titleTranslationKey: 'adminCourseCoupons.tableCells.name',
      sortable: true,
      template: 'nameCell',
    },
    {
      field: 'code',
      titleTranslationKey: 'adminCourseCoupons.tableCells.code',
      template: 'codeCell',
    },
    {
      field: 'expires',
      titleTranslationKey: 'adminCourseCoupons.tableCells.expires',
      template: 'expiresCell',
      sortable: true,
    },
    {
      field: 'redemptions',
      titleTranslationKey: 'adminCourseCoupons.tableCells.redemptions',
      template: 'redemptionsCell',
      sortable: true,
    },
    { field: 'actions', template: 'actionsCell' },
  ];
  entries: ICourseCoupon[] = [];
  buttonSize = ButtonSize;
  buttonType = ButtonType;
  buttonStyle = ButtonStyle;

  constructor(
    private couponsService: CouponsService,
    public courseStore: CoursesStore,
    private translateService: TranslateService,
    public hubsStore: HubsStore,
    private router: Router,
    private route: ActivatedRoute,
    private openDialogService: OpenDialogService,
    private messageService: MessageService,
    private stripeService: StripeService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.getAllCoupons();
  }

  getDateForCoupon(coupon: ICourseCoupon, period: string): string {
    if (coupon[period].seconds === 0) {
      return '';
    } else {
      return `${moment(coupon[period].toDate()).format('DD.MM.YYYY')}`;
    }
  }

  getTimeForCoupon(coupon: ICourseCoupon, period: 'start' | 'end'): string {
    if (coupon[period].seconds === 0) {
      return '';
    } else {
      return `${moment(coupon[period].toDate()).format('HH:mm')}`;
    }
  }

  getPercentageText(coupon: ICourseCoupon, period: string) {
    if (coupon.discountPercentage) {
      if (coupon[period].seconds === 0) {
        return `${coupon.discountPercentage}% off forever`;
      } else {
        return `${coupon.discountPercentage}%`;
      }
    } else {
      if (coupon[period].seconds === 0) {
        return `${coupon.discountAmount} ${coupon.currency} off forever`;
      } else {
        return `${coupon.discountAmount} ${coupon.currency}`;
      }
    }
  }

  onRowClicked(id: string): void {
    this.router.navigate([
      `/${this.hubsStore.useHubUrl}/admin/courses/${this.courseStore.adminCourse.link}/setup/promotions/${id}`,
    ]);
  }

  edit(event: Event, entry: ICourseCoupon): void {
    event.stopPropagation();
    this.router.navigate(
      [
        `/${this.hubsStore.useHubUrl}/admin/courses/${this.courseStore.adminCourse.link}/setup/promotions/${entry.id}`,
      ],
      {
        relativeTo: this.route,
        state: { entry },
      },
    );
  }

  async remove(event: Event, coupon: ICourseCoupon): Promise<void> {
    event.stopPropagation();
    let deleteDialogRef: DynamicDialogRef;
    deleteDialogRef = this.openDialogService.openDeleteTicketDialog(
      'adminCourseCoupons.deleteCoupon',
      'adminCourseCoupons.deleteCouponText',
      'adminCourseCoupons.confirmBtn',
      'adminCourseCoupons.cancelBtn',
      false,
    );
    try {
      const result: 'cancel' | 'confirm' = await firstValueFrom(deleteDialogRef.onClose);

      if (result === 'confirm') {
        this.loading = true;
        await this.couponsService.removeCouponFromCourse(
          this.courseStore.adminCourse.id,
          coupon.id,
        );
        await this.stripeService.deleteCoupon(coupon.stripeCouponId);
        await this.getAllCoupons();
        this.loading = false;
        this.showToastMessage('success', 'adminCourseCoupons.removeCourseSuccessMsg');
      }
    } catch (error) {
      this.loading = false;
      this.showToastMessage('error', 'application.toasters.error');
    }
  }

  private showToastMessage(severity: 'success' | 'error', detail: string): void {
    this.messageService.add({
      severity,
      summary: this.translateService.instant(severity),
      detail: this.translateService.instant(detail),
      styleClass: 'custom-toast',
    });
  }

  private async getAllCoupons(): Promise<void> {
    this.loading = true;
    const coupons: ICourseCoupon[] = await this.couponsService.getCourseCoupons(
      this.courseStore.adminCourse.id,
    );
    this.entries = await this.getInfoForCoupons(coupons);
    this.loading = false;
  }

  private async getInfoForCoupons(coupons: ICourseCoupon[]): Promise<any[]> {
    const infoPromises = coupons.map(async (c) => {
      const info = await this.stripeService.retrieveCoupon(c.stripePromotionCodeId);
      return { ...c, ...info };
    });
    return Promise.all(infoPromises);
  }
}
