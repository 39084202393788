import { Injectable } from '@angular/core';

import { Firestore, IFirestore } from './../../../firebase';
import { BrandsStore } from '../../stores/brands/brands.store';
import { EventsStore } from '../../stores/events/events.store';
import { UsersStore } from 'src/app/core/stores/users/users.store';
import { SpeakersStore } from '../../stores/speakers/speakers.store';
import { SpeakersService } from '../speakers/speakers.service';
import { AttendeesStore } from '../../stores/attendees/attendees.store';
import { SessionsStore } from '../../stores/sessions/sessions.store';
import { ProductsStore } from '../../stores';
import { BookmarkableCollection } from 'src/app/core/enums';

@Injectable({
  providedIn: 'root',
})
export class BookmarkService {
  private firestore: IFirestore;

  private readonly collectionStoreMap: {
    [key: number]: [
      string,
      SpeakersStore | AttendeesStore | SessionsStore | BrandsStore | ProductsStore,
    ];
  } = {
    [BookmarkableCollection.Speakers]: ['bookmarkedSpeakers', this.speakersStore],
    [BookmarkableCollection.Attendees]: ['bookmarkedAttendees', this.attendeesStore],
    [BookmarkableCollection.Sessions]: ['bookmarkedSessions', this.sessionsStore],
    [BookmarkableCollection.Brands]: ['bookmarkedBrands', this.brandsStore],
    [BookmarkableCollection.Products]: ['bookmarkedProducts', this.productsStore],
  };

  private readonly userEventsCollection: string = 'userEvents';

  constructor(
    private speakersStore: SpeakersStore,
    private speakersService: SpeakersService,
    private attendeesStore: AttendeesStore,
    private sessionsStore: SessionsStore,
    private brandsStore: BrandsStore,
    private productsStore: ProductsStore,
    private usersStore: UsersStore,
    private eventsStore: EventsStore,
  ) {
    this.firestore = Firestore();
  }

  public async fetchIds(
    collection: BookmarkableCollection,
    forceUpdate: boolean = false,
  ): Promise<boolean> {
    const [collectionName, store] = this.collectionStoreMap[collection];

    if (!forceUpdate && store.bookmarkedIds.length > 0) {
      return true;
    }

    try {
      const bookmarkedIds = (
        await this.firestore
          .collection(
            `${this.userEventsCollection}/${this.usersStore.getUserEvent(this.eventsStore.eventId).id}/${collectionName}`,
          )
          .get()
      ).docs.map((doc) => doc.id);

      store.setBookmarkedIds(bookmarkedIds);
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  public async bookmark(entityId: string, collection: BookmarkableCollection): Promise<boolean> {
    const [collectionName, store] = this.collectionStoreMap[collection];

    try {
      await this.firestore
        .collection(
          `${this.userEventsCollection}/${this.usersStore.getUserEvent(this.eventsStore.eventId).id}/${collectionName}`,
        )
        .doc(entityId)
        .set({});
      store.pushBookmarkedId(entityId);
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  public async removeBookmark(
    entityId: string,
    collection: BookmarkableCollection,
  ): Promise<boolean> {
    const [collectionName, store] = this.collectionStoreMap[collection];

    try {
      await this.firestore
        .collection(
          `${this.userEventsCollection}/${this.usersStore.getUserEvent(this.eventsStore.eventId).id}/${collectionName}`,
        )
        .doc(entityId)
        .delete();
      store.popBookmarkedId(entityId);

      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }
}
