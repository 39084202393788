<div class="container">
  <h1 class="title">{{ (course | async)?.title }}</h1>
  <div
    class="course-description-editor description"
    [innerHTML]="(course | async)?.description"></div>
  <div class="c-mt-8 c-mb-8">
    <p-divider styleClass="custom-divider-horizontal"></p-divider>
  </div>
  <div
    *ngIf="instructors?.length === 1"
    (click)="onOpenInstructor(instructors[0])">
    <user-little-card [user]="instructors[0]"></user-little-card>
  </div>
  <div *ngIf="instructors?.length > 1">
    <p-carousel
      [value]="instructors"
      [numVisible]="4"
      [numScroll]="4"
      [responsiveOptions]="responsiveOptions"
      indicatorsContentClass="carousel-dots-hide">
      <ng-template
        let-user
        pTemplate="item">
        <user-little-card
          [user]="user"
          (click)="onOpenInstructor(user)"></user-little-card>
      </ng-template>
    </p-carousel>
  </div>
</div>
