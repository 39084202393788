import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { parseToMoment } from 'src/app/shared';
import { IAppointment, INotification, IUser } from 'src/app/core/models';
import { getTime, getTimeForEnglishLocale, getUserNameAbbreviation } from 'src/app/core/utils';
import { TranslationsService } from 'src/app/core/services';

@Component({
  selector: 'app-appointment-new-invite-notification',
  templateUrl: './appointment-new-invite-notification.component.html',
  styleUrls: ['./appointment-new-invite-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppointmentNewInviteNotificationComponent {
  @Input() notification: INotification;
  @Output() accept: EventEmitter<INotification> = new EventEmitter();
  @Output() decline: EventEmitter<INotification> = new EventEmitter();

  imageText = getUserNameAbbreviation;

  get user(): IUser {
    return this.notification?.user;
  }

  get host(): IUser {
    return this.notification?.host;
  }

  get appointment(): IAppointment {
    return this.notification?.appointment;
  }

  get notificationText(): string {
    const lang: string = this.translationsService.getCurrentLanguage;
    const startDate = `<span>${parseToMoment(this.appointment.startDate).format('DD.MM.YYYY')}</span>`;
    const endDate = `<span>${parseToMoment(this.appointment.endDate).format('DD.MM.YYYY')}</span>`;
    let startTime = '';
    let endTime = '';

    switch (lang) {
      case 'en':
        startTime = `<span>${getTimeForEnglishLocale(this.appointment.startDate)}</span>`;
        endTime = `<span>${getTimeForEnglishLocale(this.appointment.endDate)}</span>`;
        break;
      case 'de':
        startTime = `<span>${getTime(this.appointment.startDate)}</span>`;
        endTime = `<span>${getTime(this.appointment.endDate)}</span>`;
    }
    if (
      parseToMoment(this.appointment.startDate).isSame(
        parseToMoment(this.appointment.endDate).toDate(),
        'day',
      )
    ) {
      return `
        ${this.host.firstName} ${this.host.lastName} 
        ${this.translationsService.getTranslationSync('notifications.invitedToMeetingSameDate', {
          title: this.appointment.meetingName,
          date: startDate,
          startTime,
          endTime,
        })}
    `;
    }

    return `
      ${this.host.firstName} ${this.host.lastName} 
      ${this.translationsService.getTranslationSync('notifications.invitedToMeetingDifferentDate', {
        title: this.appointment.meetingName,
        start: `${startDate} - ${startTime}`,
        end: `${endDate} - ${endTime}`,
      })}
    `;
  }

  constructor(private translationsService: TranslationsService) {}

  onAccept(): void {
    this.accept.emit(this.notification);
  }

  onDecline(): void {
    this.decline.emit(this.notification);
  }
}
