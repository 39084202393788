import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressBarModule } from 'primeng/progressbar';

@Component({
  selector: 'app-custom-progress-bar',
  standalone: true,
  imports: [CommonModule, ProgressBarModule],
  templateUrl: './custom-progress-bar.component.html',
  styleUrls: ['./custom-progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomProgressBarComponent {
  @Input() value = 20;
  @Input() showValue = false;
  @Input() className: string;
  @Input() height = '0.0625rem';
  @Input() borderRadius = '0';
  @Input() unit = '%';
  @Input() mode: 'determinate' | 'indeterminate' = 'determinate';
  @ViewChild('progressBar', { static: false }) progressBar: ElementRef<HTMLElement>;
}
