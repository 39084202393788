import { Component, OnInit } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { Router } from '@angular/router';

import { StatCardComponent } from 'src/app/standalone/shared';
import { SharedModule, IAdminTableColumn, imgPreloader, logoAvatar } from 'src/app/shared';
import { trackByFn } from 'src/app/core/utils';
import {
  CourseSubscribersService,
  CourseTrackingService,
  StripeService,
  UsersService,
} from 'src/app/core/services';
import { CoursesStore, HubsStore } from 'src/app/core/stores';
import { IStripeReport, IUser, IUserCourse } from 'src/app/core/models';
import { userAvatar } from 'src/app/shared/utils';

@Component({
  selector: 'app-subscribers-reports',
  standalone: true,
  imports: [CommonModule, StatCardComponent, SharedModule],
  templateUrl: './subscribers-reports.component.html',
  styleUrls: ['./subscribers-reports.component.scss'],
  providers: [CurrencyPipe],
})
export class SubscribersReportsComponent implements OnInit {
  loading = true;
  loadingEntries = true;
  trackByFn = trackByFn;
  courseSubscribers: IUserCourse[] = [];
  statCards: { iconClass: string; text: string; value: string }[];
  users: IUser[] = [];
  entries: any[] = [];
  courseId: string;
  columns: IAdminTableColumn[] = [];
  totalRecords = 0;
  pageSize = 10;
  imgPreloader = imgPreloader;
  logoAvatar = logoAvatar;
  userAvatar = userAvatar;
  searchTerm = '';
  searchFilterFields: string[] = [];

  constructor(
    private courseTrackingService: CourseTrackingService,
    private stripeService: StripeService,
    private coursesStore: CoursesStore,
    private courseSubscribersService: CourseSubscribersService,
    private usersService: UsersService,
    private router: Router,
    private hubsStore: HubsStore,
    private currencyPipe: CurrencyPipe,
  ) {}

  async ngOnInit(): Promise<void> {
    this.courseId = this.coursesStore.adminCourse.id;
    this.initializeStatCards();
    this.setSearchFilterFields();
    this.fetchStatisticsData();
    await this.fetchUsersData();
  }

  getCardsContainerWidth(): string {
    const maxWidthPercentage = 100;
    const minItemWidthPercentage = 10;
    const items = this.statCards.length > 0 ? this.statCards.length : 1;
    const calculatedWidth = Math.min(maxWidthPercentage, minItemWidthPercentage * items);
    return `${calculatedWidth}%`;
  }

  getPercentageString(percentage: number): string {
    return percentage === 0 ? `0 %` : `${percentage.toFixed(1)} %`;
  }

  fetchAllStatisticsData(): void {
    const courseId = this.coursesStore.adminCourse.id;
    const promises = [
      this.courseSubscribersService.getCountOfCourseSubscribers(courseId),
      this.stripeService.searchPaymentIntent(courseId),
      this.courseTrackingService.getViewersCountByCourseId(courseId),
    ];

    Promise.all(promises).then((result) => {
      this.statCards[0].value = result[0].toString();
      this.statCards[1].value = result[1]['totalSales'].toString();
      this.statCards[2].value = result[1]['totalRevenue'].toString();
      this.statCards[3].value = result[2].toString();
      this.loading = false;
    });
  }

  private async fetchStatisticsData(): Promise<void> {
    const [subscribers, totalSales, viewers] = await Promise.all([
      this.courseSubscribersService.getCountOfCourseSubscribers(this.courseId),
      this.stripeService.searchPaymentIntent(this.courseId),
      this.courseTrackingService.getViewersCountByCourseId(this.courseId),
    ]);

    this.updateStatCards(subscribers, totalSales, viewers);
    this.loading = false;
  }

  private updateStatCards(subscribers: number, stripeReport: IStripeReport, viewers: number): void {
    this.statCards[0].value = subscribers.toString();
    this.statCards[1].value = stripeReport.totalSales.toString();
    this.statCards[2].value = this.currencyPipe.transform(
      stripeReport.totalRevenue.toString(),
      this.coursesStore.adminCourse.currency,
    );
    this.statCards[3].value = viewers.toString();
  }

  async fetchUsersData(): Promise<void> {
    this.courseSubscribers = await this.courseSubscribersService.getAllCourseSubscribers(
      this.courseId,
    );
    this.users = await this.usersService.getAllByIds(this.courseSubscribers.map((cs) => cs.userId));
    this.entries = await this.getInfoForSubscribers(this.users);
    this.loadingEntries = false;
    this.totalRecords = this.entries.length;
  }

  async getInfoForSubscribers(users: IUser[]): Promise<any[]> {
    const infoPromises = users.map(async (cs) => {
      const info = await this.courseTrackingService.getAllInfoByUserId(cs.id, this.courseId);
      return { ...cs, ...info };
    });

    return Promise.all(infoPromises);
  }

  onRowClick(userId: string) {
    this.router.navigate([
      `${this.hubsStore.useHubUrl}/admin/courses/${this.coursesStore.adminCourse.link}/subscriber-reports/${userId}`,
    ]);
  }

  private initializeStatCards(): void {
    this.statCards = [
      {
        iconClass: 'fa-regular fa-users',
        text: 'courseReports.subscribers',
        value: '0',
      },
      {
        iconClass: 'fa-regular fa-money-bill',
        text: 'courseReports.totalSales',
        value: '0',
      },
      {
        iconClass: 'fa-regular fa-money-bill-trend-up',
        text: 'courseReports.totalRevenue',
        value: '0',
      },
      {
        iconClass: 'fa-regular fa-play',
        text: 'courseReports.viewers',
        value: '0',
      },
    ];

    this.columns = [
      {
        field: 'firstName',
        template: 'titleCell',
        titleTranslationKey: 'courseReports.subscribersName',
        sortable: true,
      },
      {
        field: 'assetsViewed',
        template: 'statusCell',
        titleTranslationKey: 'courseReports.assetsViewed',
        sortable: true,
      },
      // {
      //   field: 'views',
      //   template: 'countCell',
      //   titleTranslationKey: 'courseReports.assetViews',
      //   sortable: true
      // },
      {
        field: 'averagePercentageWatched',
        template: 'progressCell',
        titleTranslationKey: 'courseReports.assetAveragePercentWatched',
        sortable: true,
      },
    ];
  }

  private setSearchFilterFields() {
    this.searchFilterFields = [
      'email',
      'viewsCount',
      'assetsViewed',
      'averagePercentageWatched',
      'firstName',
      'lastName',
    ];
  }

  async onSearchTermChange(keyword: string): Promise<void> {
    this.searchTerm = keyword;
  }
}
