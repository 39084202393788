import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

import { TagsService, HubsService, UsersService, UserHubsService } from 'src/app/core/services';
import { HubsStore, UsersStore } from 'src/app/core/stores';
import { IUser, IHub, ITag, IUserHub, UserHubRoles, IHubTag } from 'src/app/core/models';
import { AppStore } from 'src/app/app.store';
import { IAdminTableColumn } from 'src/app/shared';
import { Timestamp } from 'src/app/firebase';

@Component({
  selector: 'app-attach-users-to-hub',
  templateUrl: './attach-users-to-hub.component.html',
  styleUrls: ['./attach-users-to-hub.component.scss'],
})
export class AttachUsersToHubComponent implements OnInit {
  attachLoading = false;
  loadingEntries = true;
  entries: IUser[] = [];
  selectedEntries: IUser[] = [];
  totalRecords = 0;
  pageSize = 10;
  tags: ITag[] = [];
  selectedTags: ITag[] = [];
  columns: IAdminTableColumn[] = [
    { field: 'firstName', titleTranslationKey: 'adminUsers.tableColFirstName' },
    { field: 'lastName', titleTranslationKey: 'adminUsers.tableColLastName' },
    { field: 'email', titleTranslationKey: 'adminUsers.tableColEmail' },
    { field: 'company', titleTranslationKey: 'adminUsers.tableColCompany' },
    { field: 'position', titleTranslationKey: 'adminUsers.tableColPosition' },
  ];

  private userSearchTerm = '';
  private tagSearchTerm = '';
  private page = 0;
  private memberIds = [];

  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private messageService: MessageService,
    private translateService: TranslateService,
    public hubsStore: HubsStore,
    private hubsService: HubsService,
    private tagsService: TagsService,
    private usersService: UsersService,
    private userHubsService: UserHubsService,
    private usersStore: UsersStore,
    public appStore: AppStore,
  ) {}

  get filteredTags(): ITag[] {
    return this.tags.filter((tag) =>
      tag.title.toLowerCase().includes(this.tagSearchTerm.toLowerCase()),
    );
  }

  get hub(): IHub {
    return this.hubsStore.hub;
  }

  async ngOnInit(): Promise<void> {
    this.memberIds = this.config.data?.memberIds || [];
    await this.fetchUsers();
    const hubTags: IHubTag[] = await this.hubsService.getTagsRelation(this.hubsStore.hubId);
    const hubTagIds: string[] = hubTags.map((hubTag) => hubTag.tagId);
    this.tags = await this.tagsService.getByIdsOrderedByTitle(hubTagIds);
    this.loadingEntries = false;
  }

  tagSearchChanged(value: string): void {
    this.tagSearchTerm = value;
  }

  async attendeeSearchChanged(value: string): Promise<void> {
    this.loadingEntries = true;
    this.userSearchTerm = value;
    await this.fetchUsers();
    this.loadingEntries = false;
  }

  async fetchEntries(event: any): Promise<void> {
    this.loadingEntries = true;
    this.pageSize = event.rows;
    this.page = event.first / this.pageSize;
    await this.fetchUsers();
    this.loadingEntries = false;
  }

  async attach(): Promise<void> {
    this.attachLoading = true;
    await Promise.all(
      this.selectedEntries.map(async (user: IUser) => {
        const userHub: IUserHub = await this.userHubsService.getUserHub(this.hub.id, user.id);
        const updatedUserHub: IUserHub = await this.userHubsService.updateUserHub({
          ...userHub,
          role: UserHubRoles.ADMIN,
          updatedAt: Timestamp.now(),
          updatedBy: this.usersStore.userId,
        });

        return updatedUserHub;
      }),
    );

    this.messageService.add({
      key: 'user',
      severity: 'success',
      summary: this.translateService.instant('success'),
      detail: this.translateService.instant('adminHubTeam.userSuccessfullyAttached', {
        count: this.selectedEntries.length,
      }),
      styleClass: 'custom-toast',
    });

    this.selectedEntries.map((u) => this.memberIds.push(u.id));
    this.attachLoading = false;
    this.ref.close(this.selectedEntries);
  }

  async tagSelectionChanged(tags: ITag[]): Promise<void> {
    this.selectedTags = tags;
    await this.fetchUsers();
  }

  onCloseDialog(): void {
    this.ref.close();
  }

  private async fetchUsers(): Promise<void> {
    const response = await this.hubsService.fetchUnattached(
      this.hub.id,
      this.memberIds,
      this.page,
      this.pageSize,
      [...this.selectedTags.map((t) => t.id), this.userSearchTerm].join(' '),
    );

    if (this.appStore.generalSystemSettings.enableEncryption) {
      console.log('ALGOLIA attach-users-to-hub fetchUsers response.results ', response.results);
      response.results = response.results.map((b) => {
        console.log('res', b);
        return this.usersService.decryptUserData(b, ['_highlightResult', 'objectID']) as IUser;
      });
      console.log('↑ ALGOLIA attach-users-to-hub fetchUsers decrypted');
    }

    this.entries = response.results;
    this.totalRecords = response.total;
  }
}
