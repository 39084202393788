<ng-container *ngIf="loading">
  <app-loader></app-loader>
</ng-container>

<div *ngIf="!loading">
  <form [formGroup]="form">
    <!-- Brand Level block -->
    <div class="form-block c-mb-9">
      <div class="left-side">
        <h2 class="title">{{ 'adminBrandCourseDetails.descBrandLevelTitle' | translate }}</h2>
        <p class="description">
          {{ 'adminBrandCourseDetails.brandDescBrandLevelDescription' | translate }}
        </p>
      </div>
      <div class="right-side">
        <!-- Course brand level input -->
        <div class="form-field-block">
          <app-input-label
            for="course-brand-level-input"
            text="adminBrandCourseDetails.level">
          </app-input-label>
          <p-dropdown
            class="custom-dropdown"
            panelStyleClass="custom-dropdown-panel"
            [options]="levels"
            optionLabel="value"
            optionValue="key"
            formControlName="level"
            inputId="course-brand-level-input"
            placeholder="{{ 'adminBrandCourseDetails.level' | translate }}">
            <ng-template
              let-selectedItem
              pTemplate="selectedItem">
              {{ selectedItem.value | translate }}
            </ng-template>
            <ng-template
              let-item
              pTemplate="item">
              {{ item.value | translate }}
            </ng-template>
          </p-dropdown>
        </div>
      </div>
    </div>

    <p-divider styleClass="custom-divider-horizontal"></p-divider>

    <!-- Tags block -->
    <div class="form-block c-mt-9 c-mb-4">
      <div class="left-side">
        <h2 class="title">{{ 'adminBrandCourseDetails.descTagsTitle' | translate }}</h2>
        <p class="description">
          {{ 'adminBrandCourseDetails.brandDescTagsDescription' | translate }}
        </p>
      </div>
      <div class="right-side">
        <!-- Tags multi select -->
        <div class="form-field-block">
          <app-tags-multi-list
            *ngIf="tags"
            [possibleTags]="tags"
            [selectedTags]="form.controls.tags.value"
            [adminTheme]="true"
            (onSelected)="selectTags($event)">
          </app-tags-multi-list>
        </div>
      </div>
    </div>

    <div class="text-right">
      <button
        pButton
        type="button"
        label="{{ 'adminBrandCourseDetails.updateBtn' | translate }}"
        [icon]="isUpdating ? 'pi pi-spin pi-spinner' : null"
        [disabled]="!canUpdate"
        (click)="update()"></button>
    </div>
  </form>
</div>
