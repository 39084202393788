import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import { autorun, IReactionDisposer } from 'mobx';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FilterMatchMode, MenuItem, MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

import {
  ConfirmDialogComponent,
  IAdminTableFilter,
  IAdminTableFilterChange,
  IAdminTableColumn,
} from 'src/app/shared';
import {
  IAsset,
  ICourse,
  ICourseAsset,
  IEmail,
  IHub,
  ITag,
  IUser,
  IUserCourse,
} from 'src/app/core/models';
import { CoursesStore, HubsStore } from 'src/app/core/stores';
import {
  CoursesService,
  TagsService,
  UsersService,
  CourseInstructorsService,
  HubsService,
  UserCoursesService,
  OpenDialogService,
  EmailsService,
  CourseTrackingService,
  LibraryService,
} from 'src/app/core/services';
import { asyncDelay, capitalizeFirstLetter, getUserNameAbbreviation } from 'src/app/core/utils';
import { ButtonSize, ButtonStyle, ButtonType } from 'src/app/standalone';
import {
  AttachInstructorsDialogComponent,
  NewUserComponent,
  SelectEmailForEventUserComponent,
} from '../../../dialogs';
import { AssetType } from 'src/app/core/enums';

interface IUserCourseInstructor extends IUserCourse {
  email: string;
}

@Component({
  selector: 'app-course-instructors',
  templateUrl: './course-instructors.component.html',
  styleUrls: ['./course-instructors.component.scss'],
})
export class CourseInstructorsComponent implements OnInit, OnDestroy {
  loading = true;
  loadingEntries = true;
  columns: IAdminTableColumn[] = [
    {
      field: '_firstName_',
      titleTranslationKey: 'adminEventAttendees.tableCells.firstName',
      sortable: true,
    },
    {
      field: '_lastName_',
      titleTranslationKey: 'adminEventAttendees.tableCells.lastName',
      sortable: true,
    },
    { field: 'email', titleTranslationKey: 'adminEventAttendees.tableCells.email', sortable: true },
    // TODO hide for now, uncomment later when we can show the correct company name
    // { field: '_company_', titleTranslationKey: 'adminEventAttendees.tableCells.company', sortable: true },
    {
      field: 'status',
      titleTranslationKey: 'adminEventAttendees.tableCells.status',
      template: 'statusCell',
      filterable: true,
      filterMatchMode: FilterMatchMode.IN,
      filterOptions: [
        { name: 'Active', value: 'active' },
        { name: 'Inactive', value: 'inactive' },
      ],
      filterType: 'text',
    },
    {
      field: 'createdAtString',
      titleTranslationKey: 'adminEventAttendees.tableCells.timeStamp',
      sortable: true,
    },
    { field: 'actions', template: 'actionsCell' },
  ];
  entries: IUserCourseInstructor[] = [];
  allEntries: IUserCourseInstructor[];
  selectedEntries: IUserCourseInstructor[] = [];
  totalRecords = 0;
  pageSize = 25;
  tags: ITag[] = [];
  tableFilters: IAdminTableFilter[] = [];
  searchTerm = '';
  searchFilterFields: string[] = ['_firstName_', '_lastName_', 'email', '_company_'];
  selectedTags: string[] = [];
  imageText = getUserNameAbbreviation;
  sidebarVisible = false;
  userIdForSidebar: string;
  btnListItems: MenuItem[] = [
    {
      label: this.translateService.instant('adminInstructors.addBtn'),
      icon: 'far fa-plus',
      command: () => {
        this.openNewInstructorDialog();
      },
    },
    {
      label: this.translateService.instant('adminInstructors.attachInstructors'),
      icon: 'fa-regular fa-user-plus',
      command: () => {
        this.openAttachInstructorsDialog();
      },
    },
  ];
  showExportSidebar = false;
  buttonSize = ButtonSize;
  buttonType = ButtonType;
  buttonStyle = ButtonStyle;

  private currentPage = 0;
  private disposer: IReactionDisposer;

  constructor(
    public hubsStore: HubsStore,
    private router: Router,
    private route: ActivatedRoute,
    private instructorsService: CourseInstructorsService,
    private tagService: TagsService,
    private dialogService: DialogService,
    private usersService: UsersService,
    private courseService: CoursesService,
    private courseStore: CoursesStore,
    private messageService: MessageService,
    private translateService: TranslateService,
    private hubsService: HubsService,
    private userCoursesService: UserCoursesService,
    private openDialogService: OpenDialogService,
    private emailsService: EmailsService,
    private courseTrackingService: CourseTrackingService,
    private libraryService: LibraryService,
  ) {}

  get course(): ICourse {
    return this.courseStore.adminCourse;
  }

  async ngOnInit(): Promise<void> {
    this.disposer = autorun(async () => {
      if (this.course) {
        this.loadingEntries = true;
        this.loading = true;
        await Promise.all([this.fetchInstructors(), this.getTags()]);
        this.loadingEntries = false;
        this.loading = false;

        this.tableFilters = [
          {
            key: 'TAGS',
            label: 'adminUsers.tagsFilterLabel',
            options: this.tags.map((t) => ({ label: t.title, value: t.id })),
          },
        ];
      }
    });
  }

  async filtersChange(e: IAdminTableFilterChange[]): Promise<void> {
    this.loadingEntries = true;
    this.selectedTags = e.filter((f) => f.filterKey === 'TAGS').map((f) => f.optionKey);
    console.log(this.selectedTags);

    if (this.selectedTags.length) {
      const filteredEntries = this.allEntries.filter((b) =>
        b.tags.some((r) => this.selectedTags.indexOf(r) >= 0),
      );
      this.entries = filteredEntries;
    } else {
      this.entries = this.allEntries;
    }

    await asyncDelay(100);
    this.loadingEntries = false;
  }

  openAttachInstructorsDialog(): void {
    this.dialogService
      .open(AttachInstructorsDialogComponent, {
        width: '90%',
        height: '80%',
        closable: false,
      })
      .onClose.subscribe(async () => await this.fetchInstructors());
  }

  async onSearchTermChange(e: string): Promise<void> {
    this.loadingEntries = true;
    this.searchTerm = e;
    await this.fetchInstructors();
    this.loadingEntries = false;
  }

  async fetchEntries(event: any): Promise<void> {
    this.loadingEntries = true;
    this.pageSize = event.rows;
    this.currentPage = event.first / this.pageSize;
    await this.fetchInstructors();
    this.loadingEntries = false;
  }

  async sendInvites(): Promise<void> {
    try {
      const selectEmailDialogRef: DynamicDialogRef = this.dialogService.open(
        SelectEmailForEventUserComponent,
        {
          width: '90%',
          height: '80%',
          dismissableMask: true,
          closable: false,
          contentStyle: {
            padding: '0',
            'border-radius': '20px',
          },
          data: {
            userType: 'instructor',
          },
        },
      );
      const email: IEmail = await firstValueFrom(selectEmailDialogRef.onClose.pipe(take(1)));

      if (!email) {
        return;
      }

      const deleteDialogRef: DynamicDialogRef = this.openDialogService.openConfirmDialog(
        'adminEventAttendees.sendUserInvitesDialog.header',
        this.translateService.instant('adminEventAttendees.sendUserInvitesDialog.message', {
          totalUsers: this.selectedEntries.length,
        }),
        'buttons.confirm',
        'buttons.cancel',
      );
      const result: 'cancel' | 'confirm' = await firstValueFrom(
        deleteDialogRef.onClose.pipe(take(1)),
      );

      if (result !== 'confirm') {
        return;
      }

      await Promise.all(
        this.selectedEntries.map((instructor: IUserCourseInstructor) =>
          this.emailsService.sendInviteWithEmailTemplate(
            this.course.id,
            instructor.userId,
            email.id,
            'course',
          ),
        ),
      );

      this.showToastMessage('success', 'adminEventAttendees.sendUserInvitesSuccessMessage');
    } catch (error) {
      this.showToastMessage('error', 'adminEventAttendees.sendUserInvitesErrorMessage');
    }
  }

  async openNewInstructorDialog(): Promise<void> {
    let hub: IHub = this.hubsStore.hub;

    if (!hub) {
      hub = await this.hubsService.getOne(this.course.hubId);
    }
    const newInstructorDialog: DynamicDialogRef = this.dialogService.open(NewUserComponent, {
      styleClass: 'attach-new-speaker-instructor-dialog',
      closable: false,
      data: {
        userType: 'instructor',
        course: this.course,
        hub,
      },
    });

    const id: string = await newInstructorDialog.onClose.pipe(take(1)).toPromise();
    if (id) {
      this.router.navigate([
        `/${this.hubsStore.useHubUrl}/admin/courses/${this.course.link}/setup/instructors/${id}/profile`,
      ]);
    } else {
      await this.fetchInstructors();
    }
  }

  onCloseSidebar(): void {
    this.sidebarVisible = false;
  }

  async onRowClick(id: string) {
    const userCourse = await this.userCoursesService.getUserCourseById(id);

    if (this.hubsStore.hub.isShowSidePanel) {
      this.sidebarVisible = true;
      this.userIdForSidebar = userCourse.userId;

      return;
    }

    this.router.navigate([`${userCourse.userId}`], { relativeTo: this.route });
  }

  toggleExportSidebar(): void {
    this.showExportSidebar = !this.showExportSidebar;
  }

  async unAttachInstructor(event: Event, entry: IUserCourseInstructor): Promise<void> {
    event.stopPropagation();
    const deleteDialogRef: DynamicDialogRef = this.dialogService.open(ConfirmDialogComponent, {
      closable: false,
      styleClass: 'confirm-dialog',
      data: {
        titleKey: 'adminUsers.deleteUser',
        descriptionKey: 'adminUsers.deleteUserText',
        confirmBtnKey: 'adminUsers.confirmBtn',
        cancelBtnKey: 'adminUsers.cancelBtn',
        entry,
      },
    });
    const result: 'cancel' | 'confirm' = await firstValueFrom(deleteDialogRef.onClose);
    if (result === 'confirm') {
      await this.courseService.unAttachUser(this.course.id, entry.userId, 'instructor');
      await this.courseTrackingService.deleteByCourseAndUserInfo(this.course.id, entry.userId);
      await this.updateAssetsInstructors(entry);
      this.loadingEntries = true;
      await this.fetchInstructors();
      this.loadingEntries = false;
    }
  }

  navigateToInstructor(event: Event, uc: IUserCourseInstructor): void {
    event.stopPropagation();
    if (this.hubsStore.hub.isShowSidePanel) {
      this.sidebarVisible = true;
      this.userIdForSidebar = uc.userId;

      return;
    }

    this.router.navigate([`${uc.userId}`], { relativeTo: this.route });
  }

  ngOnDestroy(): void {
    this.disposer();
  }

  private async fetchInstructors(): Promise<void> {
    const userCourses: IUserCourse[] = await this.instructorsService.getAllCourseInstructors(
      this.course.id,
    );
    const users: IUser[] = await Promise.all(
      userCourses.map((userCourse: IUserCourse) => this.usersService.getOne(userCourse.userId)),
    );
    const courseInstructors: IUserCourseInstructor[] = userCourses.map(
      (courseInstructor: IUserCourse) => ({
        ...courseInstructor,
        _firstName_: capitalizeFirstLetter(courseInstructor._firstName_),
        _lastName_: capitalizeFirstLetter(courseInstructor._lastName_),
        email: users.find((user: IUser) => courseInstructor.userId === user.id).email,
      }),
    );

    this.allEntries = courseInstructors;
    this.entries = courseInstructors;
    console.log(this.entries);

    this.totalRecords = courseInstructors.length;
  }

  private async getTags(): Promise<void> {
    this.tags = await this.tagService.getByIdsOrderedByTitle(this.course.tags);
  }

  private async updateAssetsInstructors(userCourse: IUserCourseInstructor): Promise<void> {
    const courseAssets: ICourseAsset[] = await this.libraryService.getAssetsFromCourse(
      this.course.id,
    );
    const assetIds: string[] = courseAssets.map((courseAsset: ICourseAsset) => courseAsset.assetId);
    const assets: IAsset[] = await this.libraryService.getByIds(assetIds, false, null);
    const eventAssets: IAsset[] = assets.filter((asset: IAsset) => asset.type === AssetType.EVENT);
    if (eventAssets?.length) {
      await Promise.all(
        eventAssets.map((asset: IAsset) =>
          this.libraryService.update(asset.hubId, {
            ...asset,
            instructors: asset.instructors.filter(
              (instructorId: string) => instructorId !== userCourse.userId,
            ),
          }),
        ),
      );
    }
  }

  private showToastMessage(severity: 'success' | 'error', message: string): void {
    this.messageService.add({
      severity,
      summary: this.translateService.instant(severity),
      detail: this.translateService.instant(message),
      styleClass: 'custom-toast',
    });
  }
}
