import { Injectable, signal } from '@angular/core';
import { makeAutoObservable } from 'mobx';

import { ISession } from 'src/app/core/models';

@Injectable()
export class SessionsStore {
  stageSessionsMap: { [stageId: string]: ISession[] } = {};
  bookmarkedIds: string[] = [];
  sessionsMap: { [key: string]: ISession } = {};
  adminSession: ISession = null;
  currentSessionTitle = signal('');

  readonly sessionTitle = this.currentSessionTitle.asReadonly();

  constructor() {
    makeAutoObservable(this, {
      currentSessionTitle: false,
      sessionTitle: false,
      setSessionTitle: false,
    });
  }

  setAdminSession(session: ISession): void {
    this.adminSession = session;
  }

  unsetAdminSession() {
    this.adminSession = null;
  }

  setStageSessions(stageId: string, sessions: ISession[]) {
    this.stageSessionsMap[stageId] = sessions;
  }

  reset(): void {
    this.stageSessionsMap = {};
  }

  setBookmarkedIds(ids: string[]): void {
    this.bookmarkedIds = ids;
  }

  pushBookmarkedId(id: string): void {
    if (!this.bookmarkedIds.includes(id)) {
      this.bookmarkedIds.push(id);
    }
  }

  popBookmarkedId(id: string): void {
    this.bookmarkedIds = this.bookmarkedIds.filter((_id) => _id !== id);
  }

  setSessionTitle(sessionTitle: string): void {
    this.currentSessionTitle.set(sessionTitle);
  }
}
