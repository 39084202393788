import { Component } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  styleUrls: ['./brand-dialog.component.scss'],
  templateUrl: './brand-dialog.component.html',
})
export class BrandDialogComponent {
  public readonly brandId: string;

  constructor(public config: DynamicDialogConfig) {
    this.brandId = config.data.brandId;
  }
}
