<div class="header-container">
  <h1>{{ 'exportFields.title' | translate }}</h1>
  <p>{{ 'exportFields.description' | translate }}</p>
</div>

<p-divider styleClass="custom-divider-horizontal"></p-divider>

<ng-container>
  <div class="body-container">
    <p-accordion
      [multiple]="true"
      styleClass="entities-keys-accordion">
      <p-accordionTab header="{{ 'exportFields.userFields' | translate }}">
        <div class="fields-list-section">
          <div class="entity-keys-list-container flex">
            <div
              *ngFor="let key of userKeys"
              class="key-item clickable"
              [ngClass]="isSelectedUserKey(key.key)"
              (click)="selectUserField(key)">
              {{ key.header | translate }}
            </div>
          </div>
        </div>
      </p-accordionTab>

      <p-accordionTab
        *ngIf="!isSessionRegistrationPage"
        header="{{ 'exportFields.userEventFields' | translate }}">
        <div class="fields-list-section">
          <div class="entity-keys-list-container flex">
            <div
              *ngFor="let key of userEventKeys"
              class="key-item clickable"
              [ngClass]="isSelectedUserEventKey(key.key)"
              (click)="selectUserEventField(key)">
              {{ key.header | translate }}
            </div>
          </div>
        </div>
      </p-accordionTab>

      <p-accordionTab
        *ngIf="!isSessionRegistrationPage"
        header="{{ 'exportFields.eventConsents' | translate }}">
        <div class="fields-list-section">
          <div class="entity-keys-list-container flex">
            <div
              *ngFor="let key of eventConsentKeys"
              class="key-item clickable"
              [ngClass]="isSelectedEventConsentKey(key.key)"
              (click)="selectEventConsentField(key)">
              {{ key.header | translate }}
            </div>
          </div>
        </div>
      </p-accordionTab>

      <p-accordionTab
        *ngIf="isSessionRegistrationPage"
        header="{{ 'exportFields.sessionFields' | translate }}">
        <div class="fields-list-section">
          <div class="entity-keys-list-container flex">
            <div
              *ngFor="let key of sessionKeys"
              class="key-item clickable"
              [ngClass]="isSelectedSessionKey(key.key)"
              (click)="selectSessionField(key)">
              {{ key.header | translate }}
            </div>
          </div>
        </div>
      </p-accordionTab>
    </p-accordion>

    <button
      pButton
      pRipple
      (click)="export()"
      class="export"
      label="{{ 'exportFields.downloadBtnLabel' | translate }}"
      [icon]="isLoading ? 'pi pi-spin pi-spinner' : null"></button>

    <button
      pButton
      pRipple
      (click)="unselectAll()"
      class="unselectBtn"
      label="{{ 'exportFields.unselectBtnLabel' | translate }}"></button>
  </div>

  <p-toast>
    <ng-template
      let-message
      pTemplate="message">
      <app-toast
        [severity]="message.severity"
        [summary]="message.summary"
        [detail]="message.detail">
      </app-toast>
    </ng-template>
  </p-toast>
</ng-container>
