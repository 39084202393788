import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';

import {
  ChatConversation,
  ChatMessage,
  ChatMessageAttachment,
  IUserEvent,
  IUser,
  IEvent,
} from 'src/app/core/models';
import { UsersService, ChatService } from 'src/app/core/services';
import { EventsStore, UsersStore } from 'src/app/core/stores';

@Component({
  selector: 'app-chat-window',
  templateUrl: './chat-window.component.html',
  styleUrls: ['./chat-window.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatWindowComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  loggedUser: IUser;
  isHideChatArrowButton = false;

  chatConversations$ = new BehaviorSubject<ChatConversation[]>([]);

  @Input() isOpen?: boolean;
  @Input() contextKey?: string;
  @Input() unreadMessages?: number;

  @Input() set chatConversations(value: ChatConversation[]) {
    this.chatConversations$.next(value);
  }

  @Input() selectedChatConversationMessages?: ChatMessage[];

  @Output() sendMessage = new EventEmitter<{ text: string; attachment?: ChatMessageAttachment }>();
  @Output() chatConversationSelected = new EventEmitter<ChatConversation>();
  @Output() toggleMessageLike = new EventEmitter<ChatMessage>();
  @Output() navigateTo: EventEmitter<string> = new EventEmitter<string>();
  @Output() closeWindow: EventEmitter<void> = new EventEmitter<void>();
  @Output() openAppointmentDialog: EventEmitter<IUser> = new EventEmitter<IUser>();

  selectedChatConversation?: ChatConversation;
  loadingConversation = true;

  state: 'EMPTY' | 'LIST' | 'CONVERSATION' = 'EMPTY';
  private selectedEventUser: IUserEvent;
  private selectedUser: IUser;

  get isShowOpenAppointmentBtn(): boolean {
    const appointmentStatus =
      this.selectedEventUser.role === 'speaker'
        ? this.eventsStorage.event?.appointmentSpeakersStatus
        : this.eventsStorage.event?.appointmentAttendeesStatus;

    return this.selectedUser?.allowAppointment && appointmentStatus;
  }

  constructor(
    public eventsStorage: EventsStore,
    private chatService: ChatService,
    private usersStore: UsersStore,
    private cdr: ChangeDetectorRef,
    private usersService: UsersService,
  ) {
    this.chatService.startNewChat().subscribe((res) => {
      if (res.chatConversation) {
        this.state = 'CONVERSATION';
      }
      this.selectedChatConversationMessages = [];
      this.onChatConversationSelected(res.chatConversation);
    });

    this.chatConversations$.subscribe((res) => {
      if (res.length === 0) {
        this.state = 'EMPTY';
      } else if (this.state === 'EMPTY') {
        this.state = 'LIST';
      }

      this.cdr.markForCheck();
    });
  }

  ngOnChanges(): void {
    const condition =
      this.isOpen && !this.chatConversations$.getValue()?.length && this.state === 'LIST';
    if (condition) {
      this.state = 'EMPTY';
    }
  }

  ngOnInit(): void {
    this.usersStore.currentUser
      .pipe(
        tap((user: IUser) => {
          this.loggedUser = user;
          this.cdr.detectChanges();
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();

    this.chatService
      .chatState()
      .pipe(
        filter(({ enabled }) => !enabled),
        tap(() => (this.isOpen = false)),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();

    this.eventsStorage.event$
      .pipe(
        tap((event: IEvent) => {
          this.isHideChatArrowButton = event?.hideChatPreviewScreen;
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();
  }

  async onChatConversationSelected(chatConversation: ChatConversation): Promise<void> {
    if (!chatConversation) {
      return;
    }

    try {
      this.loadingConversation = true;
      this.cdr.markForCheck();

      const user = chatConversation.conversationWithUser;
      this.selectedEventUser = await this.usersService.getUserEvent(
        this.eventsStorage.eventId,
        user.id,
      );
      this.selectedUser = await this.usersService.getOne(user.id);
      this.chatConversationSelected.emit(chatConversation);
      this.selectedChatConversation = chatConversation;
      this.state = 'CONVERSATION';

      this.loadingConversation = false;
      this.cdr.markForCheck();
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  onSendMessage(message: { text: string; attachment?: ChatMessageAttachment }): void {
    if (!message) {
      return;
    }

    this.sendMessage.emit(message);
  }

  onToggleMessageLike(chatMessage: ChatMessage): void {
    this.toggleMessageLike.emit(chatMessage);
  }

  returnToListAction(): void {
    this.state = 'LIST';
    this.selectedChatConversation = undefined;
    this.chatConversationSelected.emit(undefined);
  }

  closeWindowAction(): void {
    this.closeWindow.emit();
  }

  onNavigate(): void {
    this.navigateTo.emit(this.eventsStorage.eventId);
  }

  onOpenAppointmentDialog(): void {
    this.openAppointmentDialog.emit(this.selectedUser);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
