import {
  animate,
  group,
  query,
  sequence,
  stagger,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const dropDownAnimation = trigger('dropDownMenu', [
  transition(':enter', [
    style({ height: 0, overflow: 'hidden' }),
    query('.dropdown-list-item', [style({ opacity: 0, transform: 'translateY(-50px)' })]),
    sequence([
      animate('200ms', style({ height: '*' })),
      query('.dropdown-list-item', [
        stagger(-20, [animate('400ms ease', style({ opacity: 1, transform: 'none' }))]),
      ]),
    ]),
  ]),

  transition(':leave', [
    style({ height: '*', overflow: 'hidden' }),
    query('.dropdown-list-item', [style({ opacity: 1, transform: 'none' })]),
    sequence([
      query('.dropdown-list-item', [
        stagger(20, [animate('400ms ease', style({ opacity: 0, transform: 'translateY(-50px)' }))]),
      ]),
      animate('200ms', style({ height: 0 })),
    ]),
  ]),
]);

export const chevronIconAnimation = trigger('iconAnimation', [
  state(
    'initial',
    style({
      transform: 'rotate(0deg)',
    }),
  ),
  state(
    'rotated',
    style({
      transform: 'rotate(180deg)',
    }),
  ),
  transition('initial <=> rotated', animate('1s ease-in-out')),
]);
