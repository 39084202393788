import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { DialogService } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';

import { ConfirmDialogComponent, IAdminTableColumn } from 'src/app/shared';
import { EventsStore, HubsStore } from 'src/app/core/stores';
import { NotificationsService } from 'src/app/core/services';
import { INotification } from 'src/app/core/models';
import { ButtonSize, ButtonStyle, ButtonType } from 'src/app/standalone';

@Component({
  selector: 'app-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss'],
})
export class NotificationsListComponent implements OnInit {
  loading = true;
  loadingEntries = true;
  searchValue = '';
  searchFilterFields: string[] = ['title'];
  columns: IAdminTableColumn[] = [
    {
      field: 'title',
      titleTranslationKey: 'adminEventNotificationsList.tableColumnTitle',
      sortable: true,
    },
    {
      field: 'type',
      titleTranslationKey: 'adminEventNotificationsList.tableColumnType',
      template: 'typeCell',
      sortable: true,
    },
    {
      field: 'createdAt',
      titleTranslationKey: 'adminEventNotificationsList.tableColumnCreatedOn',
      sortable: true,
      template: 'createdOnCell',
    },
    { field: 'actions', template: 'actionsCell' },
  ];
  entries: INotification[] = [];
  totalRecords = 0;
  pageSize = 25;
  buttonType = ButtonType;
  buttonSize = ButtonSize;
  buttonStyle = ButtonStyle;

  constructor(
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
    private messageService: MessageService,
    public hubsStore: HubsStore,
    private eventsStore: EventsStore,
    private notificationService: NotificationsService,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.fetchNotifications();
    this.loadingEntries = false;
    this.loading = false;
  }

  add(): void {
    this.router.navigate(
      [
        `/${this.hubsStore.useHubUrl}/admin/event/${this.eventsStore.adminEvent.link}/communication/notifications/new-notification`,
      ],
      {
        relativeTo: this.route,
        state: {
          entry: null,
        },
      },
    );
  }

  edit(event: Event, entry: INotification): void {
    event.stopPropagation();
    this.router.navigate([entry.id], {
      relativeTo: this.route,
      state: {
        entry: entry,
      },
    });
  }

  remove(event: Event, entry: INotification): void {
    event.stopPropagation();
    const deleteDialogRef = this.dialogService.open(ConfirmDialogComponent, {
      closable: false,
      styleClass: 'confirm-dialog',
      data: {
        titleKey: 'adminEventNotificationsList.deleteTitle',
        descriptionKey: 'adminEventNotificationsList.deleteText',
        confirmBtnKey: 'adminEventNotificationsList.confirmBtn',
        cancelBtnKey: 'adminEventNotificationsList.cancelBtn',
        className: 'fa-solid fa-trash-xmark',
        entry: entry,
      },
    });

    deleteDialogRef.onClose.pipe(take(1)).subscribe(async (res) => {
      if (res) {
        try {
          this.loadingEntries = true;
          let deleteResult = await this.notificationService.removeEventNotification(
            entry.id,
            this.eventsStore.adminEvent.id,
          );
          if (deleteResult)
            this.messageService.add({
              severity: 'success',
              summary: this.translateService.instant('success'),
              detail: this.translateService.instant('adminEventNotificationsList.succesDelete'),
              styleClass: 'custom-toast',
            });
          await this.fetchNotifications();
          this.loadingEntries = false;
        } catch (error) {
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('success'),
            detail: this.translateService.instant('adminEventNotificationsList.errorDelete'),
            styleClass: 'custom-toast',
          });
        }
      }
    });
  }

  onRowClicked(id: string): void {
    this.router.navigate([id], {
      relativeTo: this.route,
    });
  }

  private async fetchNotifications(): Promise<void> {
    this.entries = await this.notificationService.getAllEventNotifications(
      this.eventsStore.adminEvent.id,
    );
  }
}
