import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';

import { IExportField, IUserSession } from 'src/app/core/models';
import {
  Firestore,
  IDocumentData,
  IDocumentReference,
  IFirestore,
  IQuerySnapshot,
} from 'src/app/firebase';
import { CollectionTypes } from 'src/app/shared';
import { environment } from 'src/environments/environment';
import { AuthorizationService } from '../auth';

@Injectable({
  providedIn: 'root',
})
export class UserSessionsService {
  private firestore: IFirestore;

  constructor(
    private authorizationService: AuthorizationService,
    private http: HttpClient,
  ) {
    this.firestore = Firestore();
  }

  async create(eventId: string, userSession: IUserSession): Promise<IUserSession> {
    try {
      const preUserSessionReq: IDocumentReference<IDocumentData> = await this.firestore
        .collection(`${CollectionTypes.EVENTS}/${eventId}/${CollectionTypes.USER_SESSIONS}`)
        .doc();
      const newUserSession: IUserSession = {
        ...userSession,
        id: preUserSessionReq.id,
      };
      await this.firestore
        .collection(`${CollectionTypes.EVENTS}/${eventId}/${CollectionTypes.USER_SESSIONS}`)
        .doc(newUserSession.id)
        .set({ ...newUserSession });
      return newUserSession;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getUserSessionsBySessionId(
    eventId: string,
    sessionId: string = null,
  ): Promise<IUserSession[]> {
    try {
      const result: IUserSession[] = (
        await this.firestore
          .collection(`${CollectionTypes.EVENTS}/${eventId}/${CollectionTypes.USER_SESSIONS}`)
          .where('sessionId', '==', sessionId)
          .get()
      ).docs.map((doc) => doc.data() as IUserSession);

      return result;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async deleteUserSessionByUserAndSessionIds(
    eventId: string,
    userId: string,
    sessionId: string,
  ): Promise<boolean> {
    try {
      const userSessionId: string = (
        await this.firestore
          .collection(`${CollectionTypes.EVENTS}/${eventId}/${CollectionTypes.USER_SESSIONS}`)
          .where('userId', '==', userId)
          .where('sessionId', '==', sessionId)
          .get()
      ).docs[0].id;

      await this.firestore
        .collection(`${CollectionTypes.EVENTS}/${eventId}/${CollectionTypes.USER_SESSIONS}`)
        .doc(userSessionId)
        .delete();
      return true;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async numberUserSessions(eventId: string, sessionId: string): Promise<number> {
    try {
      const numberUserSessions: number = (
        await this.firestore
          .collection(`${CollectionTypes.EVENTS}/${eventId}/${CollectionTypes.USER_SESSIONS}`)
          .where('sessionId', '==', sessionId)
          .get()
      ).docs.length;

      return numberUserSessions;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async isUserRegisteredInSession(
    eventId: string,
    sessionId: string,
    userId: string,
  ): Promise<boolean> {
    try {
      const userSessionQuery: IQuerySnapshot<IDocumentData> = await this.firestore
        .collection(`${CollectionTypes.EVENTS}/${eventId}/${CollectionTypes.USER_SESSIONS}`)
        .where('userId', '==', userId)
        .where('sessionId', '==', sessionId)
        .get();

      return !userSessionQuery.empty;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async allUserSessionsByUserId(eventId: string, userId: string): Promise<IUserSession[]> {
    try {
      const allUserSessions: IUserSession[] = (
        await this.firestore
          .collection(`${CollectionTypes.EVENTS}/${eventId}/${CollectionTypes.USER_SESSIONS}`)
          .where('userId', '==', userId)
          .get()
      ).docs.map((doc) => doc.data() as IUserSession);

      return allUserSessions;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async exportRegisteredUsersFromSession(
    eventId: string,
    eventTitle: string,
    sessionId: string,
    lang: string,
    userFields: IExportField[],
    sessionFields: IExportField[],
  ): Promise<void> {
    try {
      const responseType = 'blob';
      const headers = await this.authorizationService.buildHeaders();
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      const blob = await this.http
        .post(
          `${environment.apiUrl}${CollectionTypes.EVENTS}/${eventId}/${CollectionTypes.SESSION}/${sessionId}/${CollectionTypes.EXPORT}`,
          { lang, userFields, sessionFields, timezone },
          { headers, responseType },
        )
        .toPromise();

      const date = new Date();
      const reportName = `${eventTitle}-${date.getUTCDate().toString()}_${date.getMonth() + 1}_${date.getFullYear()}.xlsx`;
      saveAs(blob, reportName);
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }
}
