import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from 'src/app/shared';

@Component({
  selector: 'app-stat-card',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './stat-card.component.html',
  styleUrls: ['./stat-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatCardComponent {
  @Input() iconClass: string;
  @Input() text: string;
  @Input() value: string;
}
