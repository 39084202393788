<div class="picklist-container">
  <p-pickList
    [source]="source"
    [target]="target"
    sourceHeader="{{ 'pickList.availableTitle' | translate }}"
    targetHeader="{{ 'pickList.selectedTitle' | translate }}"
    dragdrop="true"
    [responsive]="true"
    [sourceStyle]="{ height: '300px' }"
    [targetStyle]="{ height: '300px' }"
    (onMoveAllToSource)="onMoveAllToSource($event)"
    (onMoveAllToTarget)="onMoveAllToTarget($event)"
    (onSourceReorder)="onSourceReorder($event)"
    (onTargetReorder)="onTargetReorder($event)"
    (onMoveToTarget)="onMoveToTarget($event)"
    (onMoveToSource)="onMoveToSource($event)"
    filterBy="title"
    sourceFilterPlaceholder="{{ 'pickList.searchByNameLabel' | translate }}"
    targetFilterPlaceholder="{{ 'pickList.searchByNameLabel' | translate }}">
    <ng-template
      let-asset
      pTemplate="item">
      <div class="asset-item grid">
        <i class="pi pi-align-justify picklist-item-icon"></i>
        <div class="image-container col-3">
          <img
            class="asset-image"
            [lazyLoad]="(asset?.image ? asset.image : logoAvatar) | imageSize"
            [defaultImage]="imgPreloader"
            [alt]="asset?.title" />
        </div>
        <div class="asset-list-detail col-9">
          <p class="mb-2">{{ asset?.title }}</p>
        </div>
      </div>
    </ng-template>

    <ng-template pTemplate="emptymessagesource">
      <div class="empty-source">{{ 'pickList.emptySourceList' | translate }}</div>
    </ng-template>
  </p-pickList>
</div>
