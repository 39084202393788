import { IAudience } from './../models/audience.model';

export const eventAudience: IAudience[] = [
  { label: 'Attendees', value: 'attendees' },
  { label: 'Speakers', value: 'speakers' },
  { label: 'Brand People', value: 'brandpeople' },
];

export const courseAudience: IAudience[] = [
  { label: 'Subscriber', value: 'subscriber' },
  { label: 'Instructor', value: 'instructor' },
];
