<div class="container">
  <div class="top-wrapper">
    <div class="icon-wrapper">
      <i [class]="iconClass"></i>
    </div>
    <p-inputSwitch
      class="custom-input-switch"
      [formControl]="checked"></p-inputSwitch>
  </div>
  <div class="bottom-wrapper">
    <span class="setting-name">{{ settingName | translate }}</span>
    <i
      class="fa-regular fa-circle-info info-icon"
      [pTooltip]="settingTooltip | translate"
      tooltipStyleClass="custom-tooltip">
    </i>
  </div>
</div>
