import { Component, OnInit } from '@angular/core';
import { from } from 'rxjs';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { SessionsService } from 'src/app/core/services';
import { EventsStore } from 'src/app/core/stores';
import { IUserType } from 'src/app/shared';

@Component({
  templateUrl: './user-details-dialog.component.html',
  styleUrls: ['./user-details-dialog.component.scss'],
})
export class UserDetailsDialogComponent implements OnInit {
  public loadingSessions: boolean;
  public userId: string;
  public userType: IUserType;
  public speakerSessions = [];

  public get isSpeaker(): boolean {
    return this.userType === IUserType.Speaker;
  }

  public get getUserContainerClass(): string {
    return this.userType === IUserType.Speaker ? 'md:col-8' : 'md:col-12';
  }

  constructor(
    private config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private sessionsService: SessionsService,
    private eventsStore: EventsStore,
  ) {
    this.userId = this.config.data.userId;
    this.userType = this.config.data.userType;
  }

  public ngOnInit(): void {
    if (this.userType === IUserType.Speaker) {
      this.loadingSessions = true;
      from(this.sessionsService.getBySpeaker(this.eventsStore.event.id, this.userId)).subscribe(
        (sessions) => {
          this.speakerSessions = sessions.sort((a, b) => a.start.seconds - b.start.seconds);
          this.loadingSessions = false;
        },
      );
    }
  }
}
