import * as moment from 'moment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { saveAs } from 'file-saver';

import { Firestore, IFirestore } from 'src/app/firebase';
import { CollectionTypes, parseToMoment, API_ROUTES as apiRoutes } from 'src/app/shared';
import {
  AlgoliaSearchResult,
  IUser,
  IUserHub,
  IUserCourse,
  IExportField,
} from 'src/app/core/models';
import { AlgoliaService } from '../algolia';
import { AuthorizationService } from '../auth';

@Injectable({
  providedIn: 'root',
})
export class CourseSubscribersService {
  private firestore: IFirestore;

  constructor(
    private algoliaService: AlgoliaService,
    private authorizationService: AuthorizationService,
    private http: HttpClient,
  ) {
    this.firestore = Firestore();
  }

  async fetchUnattached(
    hubId: string,
    courseId: string,
    pageIndex: number,
    pageSize: number,
    searchTerm: string,
  ): Promise<AlgoliaSearchResult<IUser>> {
    const attachedUsers = await this.getAllCourseSubscribersIds(courseId);
    const facetFilters = attachedUsers.map((id) => `userId:-${id}`).join(',');

    const resultFromAlgolia: AlgoliaSearchResult<IUserHub> =
      await this.algoliaService.search<IUserHub>(
        CollectionTypes.USER_HUBS,
        `${searchTerm} ${hubId}`,
        pageSize,
        pageIndex * pageSize,
        '',
        facetFilters,
      );

    const result: AlgoliaSearchResult<IUser> = {
      total: resultFromAlgolia.total,
      results: resultFromAlgolia.results.map((item: any) => item.user),
    };

    return result;
  }

  async fetchFromAlgolia(
    index: string,
    courseId: string = null,
    pageIndex: number,
    pageSize: number,
    searchTerm: string,
  ): Promise<AlgoliaSearchResult<IUser>> {
    const res = await this.algoliaService.search<{
      role: string;
      status?: 'active' | 'inactive' | 'pending';
      createdAt: any;
      user: IUser;
    }>(index, `${courseId} subscriber ${searchTerm}`, pageSize, pageIndex * pageSize);

    return {
      total: res.total,
      results: res.results.map((r) => ({
        status: r?.status ?? 'inactive',
        timeStamp: moment(r.createdAt).format('ll'),
        role: r.role,
        ...r.user,
      })),
    };
  }

  async getAllCourseSubscribers(courseId: string): Promise<IUserCourse[]> {
    const query = await this.firestore
      .collection(CollectionTypes.USER_COURSES)
      .where('courseId', '==', courseId)
      .where('role', '==', 'subscriber')
      .get();

    const subscribers = [];

    query.docs.forEach((doc) => {
      const subscriber = doc.data() as IUserCourse;
      subscriber.createdAtString = parseToMoment(subscriber.createdAt).format('ll');
      subscribers.push(subscriber);
    });

    return subscribers;
  }

  async getCountOfCourseSubscribers(courseId: string): Promise<number> {
    const query = await this.firestore
      .collection(CollectionTypes.USER_COURSES)
      .where('courseId', '==', courseId)
      .where('role', '==', 'subscriber')
      .get();

    const subscribers = [];

    query.docs.forEach((doc) => {
      const subscriber = doc.data() as IUserCourse;
      subscriber.createdAtString = parseToMoment(subscriber.createdAt).format('ll');
      subscribers.push(subscriber);
    });

    return subscribers.length;
  }

  async getAllCourseSubscribersIds(courseId: string): Promise<string[]> {
    const query = await this.firestore
      .collection(CollectionTypes.USER_COURSES)
      .where('courseId', '==', courseId)
      .where('role', '==', 'subscriber')
      .get();

    const subscribers = query.docs.map((doc) => doc.data().userId);

    return subscribers;
  }

  async exportSubscribers(
    courseId: string,
    courseTitle: string,
    userFields: IExportField[],
    userCourseFields: IExportField[],
    lang: string,
  ): Promise<void> {
    try {
      const responseType = 'blob';
      const headers = await this.authorizationService.buildHeaders();
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const blobResponse = await firstValueFrom(
        this.http.post(
          apiRoutes.exportSubscribers(courseId),
          { userRole: 'subscriber', userFields, userCourseFields, timezone, lang },
          { headers, responseType },
        ),
      );

      const date = new Date();
      const reportName = `${courseTitle}-${date.getUTCDate().toString()}_${date.getMonth() + 1}_${date.getFullYear()}.xlsx`;
      saveAs(blobResponse, reportName);
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }
}
