import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';

import { IBrandProduct } from 'src/app/core/models';
import { EventsStore, ProductsStore } from 'src/app/core/stores';
import { BookmarkService } from 'src/app/core/services';
import { defaultDialogConfig } from 'src/app/core/utils';
import { ProductDialogComponent } from '../../dialogs/product-dialog/product-dialog.component';
import { BookmarkableCollection } from 'src/app/core/enums';

@Component({
  selector: 'app-brand-product-card',
  templateUrl: './brand-product-card.component.html',
  styleUrls: ['./brand-product-card.component.scss'],
})
export class BrandProductCardComponent implements OnInit, AfterViewInit {
  @Input() public product: IBrandProduct;
  @Input() public disableDefaultOnClick: boolean = false;
  @Input() public showBookmarkContent: boolean;
  @Input() private background: string = 'var(--layer-01)';
  @ViewChild('data', { static: false }) dataBlock: ElementRef;

  public showBookmarkSpinner = true;

  private readonly bookmarkableCollection = BookmarkableCollection.Products;

  constructor(
    public eventStore: EventsStore,
    private router: Router,
    private productsStore: ProductsStore,
    private bookmarkService: BookmarkService,
    private dialogService: DialogService,
    private renderer2: Renderer2,
  ) {}

  async ngOnInit(): Promise<void> {
    if (this.showBookmarkContent) {
      await this.bookmarkService.fetchIds(this.bookmarkableCollection);
      this.showBookmarkSpinner = false;
    }
  }

  ngAfterViewInit(): void {
    this.renderer2.setStyle(
      this.dataBlock.nativeElement,
      'background',
      `linear-gradient(180deg, rgba(41, 41, 41, 0) 0%, ${this.background} 75%)`,
    );
  }

  public getBookmarkedIconClass(): string {
    return this.productsStore.bookmarkedIds.includes(this.product.id)
      ? 'fak fa-lox-bookmark-1 active'
      : 'fak fa-lox-bookmark-1';
  }

  public onClick(event): void {
    const target: HTMLElement = event.target;

    if (target.className.includes('bookmark')) {
      this.bookmark();
    } else if (!this.router.url.includes('brand/products')) {
      this.openDetails();
    }
  }

  private async bookmark(): Promise<void> {
    this.showBookmarkSpinner = true;

    if (this.productsStore.bookmarkedIds.includes(this.product.id)) {
      const result: boolean = await this.bookmarkService.removeBookmark(
        this.product.id,
        this.bookmarkableCollection,
      );
      if (result) {
        this.showBookmarkSpinner = false;
      }
    } else {
      const result: boolean = await this.bookmarkService.bookmark(
        this.product.id,
        this.bookmarkableCollection,
      );
      if (result) {
        this.showBookmarkSpinner = false;
      }
    }
  }

  private openDetails(): void {
    this.dialogService.open(
      ProductDialogComponent,
      Object.assign(defaultDialogConfig(), {
        data: {
          product: this.product,
          showBookmarkContent: this.showBookmarkContent,
        },
        height: '',
      }),
    );
  }
}
