<ng-container *ngIf="loading">
  <app-loader></app-loader>
</ng-container>

<div
  *ngIf="!loading"
  class="system-home-page-editor-container">
  <div class="grid grid-box">
    <div class="col-8 left-side">
      <div class="header-section grid">
        <div class="col">
          <!-- <h1>{{ 'adminSystemHomePages.editPageTitle' | translate }}</h1> -->
        </div>
        <div class="col txt-a-r">
          <button
            pButton
            pRipple
            [icon]="'far fa-copy'"
            class="p-button-rounded p-button-text"
            (click)="copy()"></button>
          <button
            pButton
            pRipple
            class="preview"
            (click)="preview()"
            [label]="'adminSystemPage.previewBtn' | translate"></button>
        </div>
      </div>

      <div class="html-editor">
        <app-input-label
          labelType="required"
          text="adminSystemPage.htmlPlaceholder">
        </app-input-label>
        <textarea
          class="custom-input-textarea resize-none custom-input-textarea-required textarea"
          pInputTextarea
          [formControl]="form.controls.html"
          placeholder="{{ 'adminSystemPage.htmlPlaceholder' | translate }}">
        </textarea>
      </div>
    </div>

    <div class="col-4 right-side">
      <div class="form-section">
        <h3>{{ 'adminSystemHomePages.editPageTitle' | translate }}</h3>

        <form [formGroup]="form">
          <div class="actions-container txt-a-r">
            <button
              class="primary-text-color"
              type="button"
              iconPos="right"
              pButton
              label="{{ 'adminSystemHomePages.saveBtn' | translate }}"
              [icon]="saving ? 'pi pi-spin pi-spinner' : ''"
              [disabled]="form.invalid"
              (click)="save()"></button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
