import { Component, inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { IUser } from 'src/app/core/models';
import { UsersStore } from 'src/app/core/stores';
import { SharedModule } from 'src/app/shared';
import { ButtonType, ButtonSize, ButtonStyle, ButtonComponent } from 'src/app/standalone/shared';
import { EventBrandPersonSidebarComponent } from './event-brand-person-sidebar/event-brand-person-sidebar.component';

@Component({
  selector: 'app-event-brand-person',
  standalone: true,
  imports: [SharedModule, ButtonComponent, EventBrandPersonSidebarComponent],
  templateUrl: './event-brand-person.component.html',
  styleUrl: './event-brand-person.component.scss',
})
export class EventBrandPersonComponent {
  buttonType = ButtonType;
  buttonSize = ButtonSize;
  buttonStyle = ButtonStyle;

  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private usersStore = inject(UsersStore);

  get user(): IUser {
    return this.usersStore.adminUser;
  }

  onNavigateToBrandPersonsList(): void {
    this.router.navigate([`../`], { relativeTo: this.route });
  }
}
