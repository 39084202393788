<div class="container">
  <div class="main">
    <div class="flexible-container">
      <!-- Active asset with type img -->
      <ng-container *ngIf="activeAssetFileType === 'img'">
        <img
          class="image absolute-block"
          [src]="fileSrc"
          alt="Course file" />
      </ng-container>

      <!-- Active asset with type zip -->
      <div
        *ngIf="activeAssetFileType === 'zip'"
        class="zip-container absolute-block">
        <img
          class="zip-image"
          [src]="fileSrc" />
        <p class="zip-name">{{ activeAsset.fileName }}</p>
        <button
          class="download-zip-btn"
          (click)="onDownloadZipFile()">
          {{ 'courseRegisteredPage.download' | translate }}
        </button>
      </div>

      <!-- Active asset with type pdf -->
      <ng-container *ngIf="activeAssetFileType === 'pdf'">
        <iframe
          *ngIf="isDesktop"
          class="embed-pdf absolute-block"
          [src]="fileSrc"
          lazy
          allow="payment: 'none'; camera: 'none'; microphone: 'none'">
        </iframe>
        <div
          *ngIf="!isDesktop"
          class="pdf-container absolute-block">
          <img
            class="pdf-image"
            [src]="pdfImg" />
          <p class="pdf-name">{{ activeAsset.fileName }}</p>
          <button
            class="open-pdf-btn"
            (click)="onOpenPdf()">
            {{ 'courseRegisteredPage.openPdf' | translate }}
          </button>
        </div>
      </ng-container>

      <!-- Active asset with type internalVideo -->
      <div *ngIf="activeAssetFileType === 'internalVideo'">
        <video
          class="internal-video absolute-block"
          controls
          [id]="defaultVideoPlayerId"
          [src]="fileSrc"></video>
      </div>

      <!-- Active asset with type vimeo -->
      <div *ngIf="activeAssetFileType === 'vimeo'">
        <div
          class="registered-course-vimeo-container"
          #iframe></div>
      </div>

      <!-- Active asset with type event -->
      <div
        *ngIf="activeAssetFileType === 'event'"
        class="asset-event-container">
        <div class="first-layer">
          <ng-container *ngFor="let i of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]">
            <div class="user-card">
              <i class="fa-regular fa-user"></i>
            </div>
          </ng-container>
        </div>
        <div class="second-layer">
          <!-- Past asset event -->
          <ng-container *ngIf="isPastAssetEvent">
            <p class="text">{{ 'courseRegisteredPage.assetPastEventText' | translate }}:</p>
            <p class="asset-name">{{ activeAsset.title }}</p>
            <div class="asset-event-date-wrapper">
              <app-asset-event-banner
                [asset]="activeAsset"
                [containerStyles]="assetEventBannerContainerStyles"
                [monthStyles]="assetEventBannerMonthStyles"
                [dateStyles]="assetEventBannerDateStyle">
              </app-asset-event-banner>
            </div>
          </ng-container>

          <!-- Upcoming asset event -->
          <ng-container *ngIf="isUpcomingAssetEvent">
            <p class="text">{{ 'courseRegisteredPage.assetEventText' | translate }}:</p>
            <p class="asset-name">{{ activeAsset.title }}</p>
            <div class="asset-event-date-wrapper">
              <app-asset-event-banner
                [asset]="activeAsset"
                [containerStyles]="assetEventBannerContainerStyles"
                [monthStyles]="assetEventBannerMonthStyles"
                [dateStyles]="assetEventBannerDateStyle">
              </app-asset-event-banner>
            </div>
            <app-count-down [targetDate]="activeAsset.eventAssetStartDate.toDate()">
            </app-count-down>
          </ng-container>

          <!-- Live asset event -->
          <ng-container *ngIf="!isUpcomingAssetEvent && !isPastAssetEvent">
            <p class="text">{{ 'courseRegisteredPage.assetEventText' | translate }}:</p>
            <p class="asset-name">{{ activeAsset.title }}</p>
            <div class="asset-event-date-wrapper">
              <app-asset-event-banner
                [asset]="activeAsset"
                [containerStyles]="assetEventBannerContainerStyles"
                [monthStyles]="assetEventBannerMonthStyles"
                [dateStyles]="assetEventBannerDateStyle">
              </app-asset-event-banner>
            </div>
            <button (click)="onJoin()">{{ 'courseRegisteredPage.joinBtnText' | translate }}</button>
          </ng-container>
        </div>
      </div>

      @if (!activeAssetFileType) {
        <div class="empty-list no-selected-asset">
          <app-empty-state
            icon="fa-regular fa-photo-film"
            text="{{ 'courseRegisteredPage.noSelectedAsset' | translate }}">
          </app-empty-state>
        </div>
      }
    </div>
    <div class="main-tabs">
      <p-tabView
        styleClass="custom-tab-view"
        [(activeIndex)]="activeMainTabIndex">
        <p-tabPanel header="{{ 'courseRegisteredPage.overviewTab' | translate }}">
          <div class="c-mt-8">
            <app-registered-course-overview></app-registered-course-overview>
          </div>
        </p-tabPanel>
        <p-tabPanel header="{{ 'courseRegisteredPage.assetDetailsTab' | translate }}">
          <div class="c-mt-8">
            <app-registered-course-asset-details></app-registered-course-asset-details>
          </div>
        </p-tabPanel>
        <!-- <p-tabPanel header="{{ 'courseRegisteredPage.q&A' | translate }}" [disabled]="true">
        </p-tabPanel>
        <p-tabPanel header="{{ 'courseRegisteredPage.notebookTab' | translate }}" [disabled]="true">
        </p-tabPanel>
        <p-tabPanel header="{{ 'courseRegisteredPage.reviewsTab' | translate }}" [disabled]="true">
        </p-tabPanel> -->
      </p-tabView>
    </div>
  </div>

  <!-- Sidebar -->
  <div class="sidebar-container">
    <div class="sidebar">
      <p-tabView styleClass="course-registered-sidebar-tab-view">
        <p-tabPanel header="{{ 'courseRegisteredPage.chaptersTab' | translate }}">
          <div class="c-mt-5">
            <app-chapters-sidebar-tab></app-chapters-sidebar-tab>
          </div>
        </p-tabPanel>
        <p-tabPanel header="{{ 'courseRegisteredPage.files' | translate }}">
          <app-files-sidebar-tab></app-files-sidebar-tab>
        </p-tabPanel>
      </p-tabView>
    </div>
  </div>
</div>

<p-toast position="bottom-center">
  <ng-template
    let-message
    pTemplate="message">
    <app-toast
      [severity]="message.severity"
      [summary]="message.summary"
      [detail]="message.detail">
    </app-toast>
  </ng-template>
</p-toast>
