import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Subject, tap } from 'rxjs';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TooltipModule } from 'primeng/tooltip';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-asset-event-setting',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InputSwitchModule,
    TooltipModule,
    TranslateModule,
  ],
  templateUrl: './asset-event-setting.component.html',
  styleUrls: ['./asset-event-setting.component.scss'],
})
export class AssetEventSettingComponent implements OnChanges, OnInit, OnDestroy {
  @Input() iconClass: string;
  @Input() settingName: string;
  @Input() settingTooltip: string;
  @Input() settingValue: boolean;
  @Input() disabled: boolean;
  @Output() changeValue = new EventEmitter<boolean>();

  checked = new FormControl(false);

  private unsubscribe$ = new Subject<void>();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.settingValue.previousValue !== changes.settingValue.currentValue) {
      this.checked.setValue(this.settingValue, { emitEvent: false });
    }
  }

  ngOnInit(): void {
    this.checked.setValue(this.settingValue, { emitEvent: false });
    if (this.disabled) {
      this.checked.disable();
    }

    this.checked.valueChanges
      .pipe(
        tap((value: boolean) => {
          this.changeValue.emit(value);
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
