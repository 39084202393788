import { Component, Input, OnInit } from '@angular/core';

import { ILoader } from '../loader.model';
import { LoaderService } from '../loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
  @Input() public autoHeight = false;
  @Input() private id = '';
  public loaderId = '';
  public show = true;

  constructor(private loaderService: LoaderService) {
    this.loaderId = this.id.length ? 'loader-id-' + this.id : '';
  }

  ngOnInit(): void {
    this.loaderService.loaderStatus$.subscribe((response: ILoader) => {
      if (this.id.length) {
        this.show = this.id === response.id && response.status;
      }
    });
  }
}
