<nav>
  <ul class="list">
    <li class="list-item">
      <a
        class="item-link"
        routerLink="event-details"
        routerLinkActive="active">
        <span>{{ 'adminBrand.brandTabEventDetails' | translate }}</span>
        <i class="fa-regular fa-chevron-right chevron-icon"></i>
      </a>
    </li>
    <li class="list-item">
      <a
        class="item-link"
        routerLink="profile"
        routerLinkActive="active">
        <span>{{ 'adminBrand.brandTabProfile' | translate }}</span>
        <i class="fa-regular fa-chevron-right chevron-icon"></i>
      </a>
    </li>
    <li class="list-item">
      <a
        class="item-link"
        routerLink="team"
        routerLinkActive="active">
        <span>{{ 'adminBrand.brandTabTeam' | translate }}</span>
        <i class="fa-regular fa-chevron-right chevron-icon"></i>
      </a>
    </li>
    <li class="list-item">
      <a
        class="item-link"
        routerLink="products"
        routerLinkActive="active">
        <span>{{ 'adminBrand.products' | translate }}</span>
        <i class="fa-regular fa-chevron-right chevron-icon"></i>
      </a>
    </li>
    <li class="list-item">
      <a
        class="item-link"
        routerLink="events"
        routerLinkActive="active">
        <span>{{ 'adminBrand.brandTabEvents' | translate }}</span>
        <i class="fa-regular fa-chevron-right chevron-icon"></i>
      </a>
    </li>
  </ul>
</nav>
