<ng-container *ngIf="loading">
  <app-loader></app-loader>
</ng-container>

<div
  *ngIf="!loading"
  class="edit-email-container">
  <h1>{{ page ? page?.title : title }}</h1>

  <div class="c-mt-4 c-mb-8">
    <p-divider styleClass="custom-divider-horizontal"></p-divider>
  </div>

  <app-event-page-html-editor
    *ngIf="true"
    [page]="page"></app-event-page-html-editor>
</div>
