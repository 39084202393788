import * as dayjs from 'dayjs';
import { Component, OnDestroy, OnInit, signal } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject, debounceTime, filter, firstValueFrom, merge, takeUntil, tap } from 'rxjs';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

import { IEvent, IEventBrand, ISession, IStage, IUser } from 'src/app/core/models';
import {
  BrandsService,
  DailyCoService,
  EventsService,
  FormService,
  SessionsService,
  StagesService,
} from 'src/app/core/services';
import { EventsStore, HubsStore, SessionsStore } from 'src/app/core/stores';
import { SharedModule } from 'src/app/shared';
import { SaveDiscardActionsComponent, ToastComponent } from 'src/app/standalone/shared';
import { asyncDelay, capitalizeFirstLetter, updateTime } from 'src/app/core/utils';
import { Timestamp } from 'src/app/firebase';

@Component({
  selector: 'app-event-session-basic-info',
  standalone: true,
  imports: [SharedModule, SaveDiscardActionsComponent, ToastComponent],
  templateUrl: './event-session-basic-info.component.html',
  styleUrl: './event-session-basic-info.component.scss',
})
export class EventSessionBasicInfoComponent implements OnInit, OnDestroy {
  loading = signal(true);
  isUpdating = signal(false);
  form: FormGroup;
  eventStages = signal<IStage[]>(null);
  eventSpeakers = signal<IUser[]>(null);
  eventBrands = signal<IEventBrand[]>(null);

  private unsubscribe$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private formService: FormService,
    private sessionsStore: SessionsStore,
    private hubsStore: HubsStore,
    private eventsStore: EventsStore,
    private stagesService: StagesService,
    private eventsService: EventsService,
    private brandsService: BrandsService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private dailyCoService: DailyCoService,
    private sessionsService: SessionsService,
  ) {}

  get session(): ISession {
    return this.sessionsStore.adminSession;
  }

  get isPossibleChangeDate(): boolean {
    return (
      !this.session.space ||
      (this.session.space && dayjs().isBefore(dayjs(this.session.end.toDate()).add(20, 'minutes')))
    );
  }

  get shouldShowBrands(): boolean {
    return this.hubsStore.hub.brands;
  }

  get canUpdate(): boolean {
    return (
      !this.isUpdating() && this.form.valid && this.form.dirty && this.formService.isValueChanged()
    );
  }

  get event(): IEvent {
    return this.eventsStore.adminEvent;
  }

  async ngOnInit(): Promise<void> {
    this.createForm();
    this.updateForm();
    this.formService.setForm(this.form);

    const [eventStagesResponse, eventSpeakersResponse, eventBrandsResponse] = await Promise.all([
      this.stagesService.getAllEventStages(this.event.id),
      this.eventsService.getAllEventUsersByRole(this.event.id, 'speaker'),
      this.brandsService.getBrandsFromEvent(this.event.id),
    ]);
    this.eventStages.set(eventStagesResponse);
    this.eventSpeakers.set(eventSpeakersResponse);
    this.eventBrands.set(
      eventBrandsResponse.map((eventBrand: IEventBrand) => {
        eventBrand['name'] = capitalizeFirstLetter(eventBrand._brand_name_);
        return eventBrand;
      }),
    );

    this.loading.set(false);

    const startTimeValue$ = this.form.controls.start.valueChanges;
    const endTimeValue$ = this.form.controls.end.valueChanges;

    const startEndTime$ = merge(startTimeValue$, endTimeValue$).pipe(
      debounceTime(300),
      filter((value) => !!value),
      tap(() => {
        const start = dayjs(`${this.form.controls.date.value}T${this.form.controls.start.value}`);
        const end = dayjs(`${this.form.controls.date.value}T${this.form.controls.end.value}`);

        if (dayjs(start).isAfter(end)) {
          const validEnd = start.add(1, 'hours');
          this.form.controls.end.setValue(validEnd.toDate(), { emitEvent: false });
        }
      }),
    );

    const title$ = this.form.controls.title.valueChanges.pipe(
      debounceTime(300),
      tap((value: string) => {
        this.sessionsStore.setSessionTitle(value);
      }),
    );

    merge(startEndTime$, title$).pipe(takeUntil(this.unsubscribe$)).subscribe();
  }

  onDiscard(): void {
    this.updateForm();
  }

  async onConfirm(): Promise<void> {
    try {
      this.isUpdating.set(true);
      await asyncDelay(1);
      await this.updateSession();
      this.formService.setForm(this.form);
    } catch (error) {
      console.error(error);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private createForm(): void {
    this.form = this.fb.group({
      title: [null, Validators.required],
      date: [{ value: null, disabled: !this.isPossibleChangeDate }, Validators.required],
      start: [{ value: null, disabled: !this.isPossibleChangeDate }, Validators.required],
      end: [{ value: null, disabled: !this.isPossibleChangeDate }, Validators.required],
      stageId: null,
      videolink: null,
      featuredSession: null,
      speakers: null,
      brands: null,
    });
  }

  private updateForm(): void {
    this.form.patchValue({
      ...this.session,
      date: this.session && this.session?.start ? this.session?.start.toDate() : null,
      start: this.session && this.session?.start ? this.session?.start.toDate() : null,
      end: this.session && this.session?.end ? this.session?.end.toDate() : null,
    });
  }

  private async updateSession(): Promise<void> {
    this.isUpdating.set(true);
    const { date, start, end, ...others } = this.form.getRawValue();

    const startDate = updateTime(date, start);
    const endDate = updateTime(date, end);

    const sessionForUpdate: ISession = {
      ...this.session,
      ...others,
      _title_: others.title.toLowerCase(),
      start: Timestamp.fromMillis(startDate.toDate().getTime()),
      end: Timestamp.fromMillis(endDate.toDate().getTime()),
      date: Timestamp.fromMillis(date.getTime()),
    };

    const isNeedToUpdateDailyRoom: boolean =
      !dayjs(this.session.end.toDate()).isSame(dayjs(sessionForUpdate.end.toDate())) &&
      this.session.space;

    try {
      if (isNeedToUpdateDailyRoom) {
        await firstValueFrom(
          this.dailyCoService.updateRoom(this.session.meetingRoomName, {
            exp: dayjs(sessionForUpdate.end.toDate()).add(20, 'minutes').unix(),
          }),
        );
      }

      await this.sessionsService.update(this.event.id, sessionForUpdate.id, sessionForUpdate);
      this.sessionsStore.setAdminSession({ ...sessionForUpdate });
      this.isUpdating.set(false);
      this.showToastMessage('success', 'adminSession.sessionSuccessfullyUpdated');
    } catch (error) {
      console.log(error);
      this.isUpdating.set(false);
      this.showToastMessage('error', 'adminSession.errorUpdateSession');
    }
  }

  private showToastMessage(severity: 'success' | 'error', detail: string): void {
    this.messageService.add({
      severity,
      summary: this.translateService.instant(severity),
      detail: this.translateService.instant(detail),
      styleClass: 'custom-toast',
    });
  }
}
