import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { IUser } from 'src/app/core/models';
import { trackByFn } from 'src/app/core/utils';

@Component({
  selector: 'app-sidebar-user-info',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './sidebar-user-info.component.html',
  styleUrls: ['./sidebar-user-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarUserInfoComponent implements OnChanges {
  @Input() user: IUser;

  arrForShowing: string[][];
  trackByFn = trackByFn;

  private keyValueMap: Map<string, string> = new Map();
  private neededUserKeys = [
    'firstName',
    'lastName',
    'company',
    'industry',
    'subIndustry',
    'areaOfActivity',
    'optionalPosition',
  ];
  private textInsteadUserKeys = [
    'sidebarUser.firstName',
    'sidebarUser.lastName',
    'sidebarUser.company',
    'sidebarUser.industry',
    'sidebarUser.subIndustry',
    'sidebarUser.areaOfActivity',
    'sidebarUser.position',
  ];

  ngOnChanges(): void {
    if (this.user) {
      const entries: string[][] = Object.entries(this.user);
      entries.forEach(([key, value]) => {
        if (this.neededUserKeys.includes(key)) {
          this.keyValueMap.set(key, value);
        }
      });
      this.arrForShowing = Array.from(this.keyValueMap)
        .sort(
          ([keyA], [keyB]) => this.neededUserKeys.indexOf(keyA) - this.neededUserKeys.indexOf(keyB),
        )
        .map(([, value], index) => [this.textInsteadUserKeys[index], value]);
    }
  }
}
