import { Injectable } from '@angular/core';
import { Firestore, IFirestore } from '../../../firebase';
import { HttpClientService } from '../http';
import { StorageService } from '../storage';
import { ProductsStore, UsersStore } from '../../stores';

@Injectable({
  providedIn: 'root',
})
export class EventProductsService {
  private firestore: IFirestore;
  private readonly eventProducts: string = 'brandProducts';
  private readonly eventProductsCollection: string = 'eventProducts';
  private readonly userEventsCollection: string = 'userEvents';

  constructor(
    private http: HttpClientService,
    private storageService: StorageService,
    private productsStore: ProductsStore,
    private usersStore: UsersStore,
  ) {
    this.firestore = Firestore();
  }
}
