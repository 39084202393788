import { Timestamp } from 'src/app/firebase';
import { IAsset } from '../library/asset.model';

export interface IChapter {
  id: string;
  banner: File | string;
  title: string;
  _title_: string;
  order: number;
  assets: string[];
  status: ChapterStatus;
  shortDescription: string;
  description: string;
  isSendEmailAfterChapterCompleted: boolean;
  createdAt: Timestamp;
  createdBy: string;
  updatedAt: Timestamp;
  updatedBy: string;
  assetsCount?: number;
  assetsFullModel?: IAsset[];
}

export enum ChapterStatus {
  PUBLISHED = 'published',
  DRAFT = 'draft',
}
