<div class="maintenance-container">
  <div class="maintenance-inner-container">
    <img
      src="./../../../../assets/images/plainned-maintenance.webp"
      alt="" />
    <h1>Under Maintenance...</h1>
    <small
      >The page you are looking for is currently under maintenance and will be back soon.</small
    >
  </div>
</div>
