<ng-container *ngIf="loading">
  <app-loader></app-loader>
</ng-container>

<div
  *ngIf="!loading"
  class="container">
  <form [formGroup]="form">
    <!-- Change user email & password block -->
    <div class="form-block c-mb-9">
      <div class="left-side">
        <h2 class="title">{{ 'adminUserAccount.emailPassTitle' | translate }}</h2>
        <p class="description">{{ 'adminUserAccount.emailPassDesc' | translate }}</p>
      </div>
      <div class="right-side">
        <!-- Email input -->
        <div class="form-field-block">
          <app-input-label
            for="email-input"
            text="adminUserAccount.emailLabel">
          </app-input-label>
          <input
            class="custom-input-text custom-input-text-required"
            formControlName="email"
            disabled
            id="email-input"
            pInputText
            type="email"
            placeholder="{{ 'adminUserAccount.emailLabel' | translate }}" />
          <app-input-error-message
            [showMessage]="form.controls.email.invalid && form.controls.email.touched"
            [errorMessage]="
              form.controls.email.hasError('email')
                ? ('adminUserAccount.emailNotValid' | translate)
                : ('adminUserAccount.emailReq' | translate)
            ">
          </app-input-error-message>
        </div>
      </div>
    </div>

    <p-divider styleClass="custom-divider-horizontal"></p-divider>

    <!-- Change user role and status block -->
    <div class="form-block c-my-9">
      <div class="left-side">
        <h2 class="title">{{ 'adminUserAccount.accSettingsTitle' | translate }}</h2>
        <p class="description">{{ 'adminUserAccount.accSettingsDesc' | translate }}</p>
      </div>
      <div class="right-side">
        <!-- Role input -->
        <div class="form-field-block c-mb-4">
          <app-input-label
            for="role-input"
            text="adminUserAccount.role">
          </app-input-label>
          <p-dropdown
            class="custom-dropdown"
            panelStyleClass="custom-dropdown-panel"
            [options]="roles"
            optionLabel="name"
            optionValue="code"
            formControlName="role"
            inputId="role-input"
            placeholder="{{ 'adminUserAccount.role' | translate }}">
          </p-dropdown>
        </div>

        <!-- Is active input -->
        <div class="form-field-input-switch-block c-mb-4">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="isActive"
            inputId="is-active-input">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="is-active-input"
            text="adminUserAccount.isActive">
          </app-input-label>
        </div>

        <!-- Verify email button -->
        <div class="form-field-block c-mb-4 btn-container">
          <app-button
            buttonText="{{ 'adminUser.verifyEmail' | translate }}"
            [buttonSize]="buttonSize.MEDIUM"
            [loading]="isVerifying"
            [disabled]="!canVerify"
            (click)="verifyEmail()" />
        </div>

        <!-- Verify email button -->
        <div class="form-field-block btn-container">
          <app-button
            buttonText="{{ 'adminUser.resetLoginAttempts' | translate }}"
            [buttonSize]="buttonSize.MEDIUM"
            [loading]="isResetting"
            [disabled]="!canReset"
            (click)="resetLoginAttempts()" />
        </div>
      </div>
    </div>
  </form>
</div>

<div class="save-discard-actions-container">
  <app-save-discard-actions
    [isShow]="canUpdate"
    [isLoading]="isUpdating"
    (discard)="onDiscard()"
    (confirm)="onConfirm()">
  </app-save-discard-actions>
</div>

<p-toast>
  <ng-template
    let-message
    pTemplate="message">
    <app-toast
      [severity]="message.severity"
      [summary]="message.summary"
      [detail]="message.detail">
    </app-toast>
  </ng-template>
</p-toast>
