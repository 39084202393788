import { SessionEventTypes } from 'src/app/core/enums';

export interface ISessionEvent {
  event: SessionEventTypes;
  eventName: string;
  eventId: string;
  sessionId: string;
  sessionName: string;
  sessionStartTime: string;
  sessionEndTime: string;
  sessionSpeakerIds: string[];
  sessionSpeakersName: string[];
  sessionBrandIds: string[];
  sessionBrandsName: string[];
  sessionTags: string[];
}
