import { Component, inject, OnInit, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { DividerModule } from 'primeng/divider';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Chart } from 'chart.js';

import {
  StatCardComponent,
  StatChartComponent,
  StatSingleProgressBarComponent,
} from 'src/app/standalone/shared/components/stats';
import { IStatCard, IStatChart } from 'src/app/standalone/shared/models';

@Component({
  selector: 'app-dashboard-analytics',
  standalone: true,
  imports: [
    StatCardComponent,
    DividerModule,
    StatChartComponent,
    StatSingleProgressBarComponent,
    CommonModule,
    TranslateModule,
  ],
  templateUrl: './dashboard-analytics.component.html',
  styleUrl: './dashboard-analytics.component.scss',
})
export class DashboardAnalyticsComponent implements OnInit {
  stats = signal<IStatCard[]>(null);
  charts = signal<IStatChart[]>(null);

  #route = inject(ActivatedRoute);
  #translateService = inject(TranslateService);

  ngOnInit(): void {
    this.initStats();
    this.initCharts();
  }

  private initStats(): void {
    this.stats.set([
      {
        iconClass: 'fa-regular fa-users',
        text: 'adminDashboardAnalytics.totalUsersStat',
        value: this.#route.snapshot.data['totalNumberOfUsersInHub'],
        isShowTooltip: true,
        tooltipText: this.#translateService.instant('adminDashboardAnalytics.totalUsersTooltip'),
      },
      {
        iconClass: 'fa-regular fa-money-bill-trend-up',
        text: 'adminDashboardAnalytics.totalRevenueStat',
        value: '$80.000',
        isShowTooltip: true,
        tooltipText: this.#translateService.instant('adminDashboardAnalytics.totalRevenueTooltip'),
      },
      {
        iconClass: 'fa-regular fa-calendar-circle-user',
        text: 'adminDashboardAnalytics.monthlyActiveUsersStat',
        value: '5.000',
        isShowTooltip: true,
        tooltipText: this.#translateService.instant(
          'adminDashboardAnalytics.monthlyActiveUsersTooltip',
        ),
      },
      {
        iconClass: 'fa-regular fa-calendar-circle-plus',
        text: 'adminDashboardAnalytics.monthlyNewSignUpsStat',
        value: '500',
        isShowTooltip: true,
        tooltipText: this.#translateService.instant(
          'adminDashboardAnalytics.monthlyNewSignUpsTooltip',
        ),
      },
    ]);
  }

  private initCharts(): void {
    const documentStyle = getComputedStyle(document.documentElement);
    this.charts.set([
      {
        statName: this.#translateService.instant('adminDashboardAnalytics.userGrowthGraphName'),
        type: 'line',
        data: {
          labels: [
            this.#translateService.instant('shortMonthName.january'),
            this.#translateService.instant('shortMonthName.february'),
            this.#translateService.instant('shortMonthName.march'),
            this.#translateService.instant('shortMonthName.april'),
            this.#translateService.instant('shortMonthName.may'),
            this.#translateService.instant('shortMonthName.june'),
          ],
          datasets: [
            {
              data: [8000, 8500, 9000, 9200, 9800, 10000],
              label: this.#translateService.instant(
                'adminDashboardAnalytics.userGrowthGraphLegend1',
              ),
              fill: false,
              tension: 0.5,
              borderColor: documentStyle.getPropertyValue('--brand-500'),
              pointRadius: 3,
              pointBackgroundColor: 'transparent',
              pointBorderColor: documentStyle.getPropertyValue('--brand-500'),
            },
            {
              data: [4000, 4100, 4500, 4900, 5000, 5100],
              label: this.#translateService.instant(
                'adminDashboardAnalytics.userGrowthGraphLegend2',
              ),
              fill: false,
              tension: 0.4,
              borderColor: documentStyle.getPropertyValue('--green-500'),
              pointRadius: 3,
              pointBackgroundColor: 'transparent',
              pointBorderColor: documentStyle.getPropertyValue('--green-500'),
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: this.#translateService.instant(
                  'adminDashboardAnalytics.userGrowthGraphAxisYTitle',
                ),
              },
            },
          },
          plugins: {
            legend: {
              labels: {
                generateLabels: function (chart) {
                  const original = Chart.defaults.plugins.legend.labels.generateLabels;
                  const labels = original.call(this, chart);
                  labels.forEach((label) => {
                    label.fillStyle = 'rgba(0, 0, 0, 0)';
                  });
                  return labels;
                },
              },
            },
          },
        },
        chartStyles: { height: '19rem' },
      },
      {
        statName: this.#translateService.instant('adminDashboardAnalytics.revenueTrendGraphName'),
        type: 'bar',
        data: {
          labels: [
            this.#translateService.instant('shortMonthName.january'),
            this.#translateService.instant('shortMonthName.february'),
            this.#translateService.instant('shortMonthName.march'),
            this.#translateService.instant('shortMonthName.april'),
            this.#translateService.instant('shortMonthName.may'),
            this.#translateService.instant('shortMonthName.june'),
          ],
          datasets: [
            {
              data: [175000, 190000, 210000, 230000, 240000, 250000],
              label: this.#translateService.instant(
                'adminDashboardAnalytics.revenueTrendGraphLegend1',
              ),
              borderColor: documentStyle.getPropertyValue('--brand-500'),
              backgroundColor: documentStyle.getPropertyValue('--brand-500'),
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: this.#translateService.instant(
                  'adminDashboardAnalytics.revenueTrendGraphAxisYTitle',
                ),
              },
            },
          },
        },
        chartStyles: { height: '19rem' },
      },
    ]);
  }
}
