export interface IUserPresenceStylesConfig {
  width?: string;
  height?: string;
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
  background?: string;
  border?: string;
}
