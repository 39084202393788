import { Injectable } from '@angular/core';

import { UsersStore } from 'src/app/core/stores';
import { ChapterStatus, IChapter } from 'src/app/core/models';
import {
  Firestore,
  IDocumentData,
  IDocumentReference,
  IFirestore,
  IQuerySnapshot,
  Timestamp,
} from 'src/app/firebase';
import { API_ROUTES as apiRoutes } from 'src/app/shared';
import { StorageService } from '../storage';

@Injectable({
  providedIn: 'root',
})
export class CourseChaptersService {
  private firestore: IFirestore;

  constructor(
    private userStore: UsersStore,
    private storageService: StorageService,
  ) {
    this.firestore = Firestore();
  }

  async getOne(courseId: string, id: string): Promise<IChapter> {
    try {
      const chapter = (
        await this.firestore.doc(apiRoutes.chapter(courseId, id)).get()
      ).data() as IChapter;

      return chapter;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async delete(courseId: string, id: string): Promise<boolean> {
    try {
      await this.firestore.collection(apiRoutes.chapters(courseId)).doc(id).delete();

      return true;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async reorderChapters(courseId: string, orderedIds: string[]): Promise<void> {
    let order = 1;

    orderedIds.forEach((chapterId) =>
      this.firestore
        .collection(apiRoutes.chapters(courseId))
        .doc(chapterId)
        .update({ order: order++ }),
    );
  }

  async updateChapters(courseId: string, chapters: IChapter[]): Promise<boolean> {
    try {
      const sessionQuery: IQuerySnapshot<IDocumentData> = await this.firestore
        .collection(apiRoutes.chapters(courseId))
        .get();

      if (!sessionQuery.empty) {
        sessionQuery.docs.forEach(async (existingChap) => {
          const newChap = chapters.find((chap) => existingChap.id === chap.id);
          if (newChap) {
            newChap.updatedBy = this.userStore.userId;
            newChap.updatedAt = Timestamp.now();

            await this.firestore
              .collection(apiRoutes.chapters(courseId))
              .doc(existingChap.id)
              .update(newChap);
          }
        });
      }

      return true;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async updateChapter(courseId: string, chapter: IChapter): Promise<boolean> {
    try {
      if (chapter.banner instanceof File) {
        const fileName = `file_${new Date().getTime()}`;
        const newFile = await this.storageService.upload(chapter.banner, 'chapters', fileName);
        chapter.banner = newFile;
      }

      await this.firestore
        .collection(apiRoutes.chapters(courseId))
        .doc(chapter.id)
        .update({ ...chapter });

      return true;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getAllByCourseId(courseId: string): Promise<IChapter[]> {
    try {
      const chapters: IChapter[] = [];
      const sessionQuery: IQuerySnapshot<IDocumentData> = await this.firestore
        .collection(apiRoutes.chapters(courseId))
        .orderBy('order')
        .get();
      if (!sessionQuery.empty) {
        sessionQuery.docs.forEach((x) => {
          chapters.push(x.data() as IChapter);
        });

        return chapters;
      }

      return [];
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getAllPublishedByCourseId(courseId: string): Promise<IChapter[]> {
    try {
      const chapters: IChapter[] = (
        await this.firestore
          .collection(apiRoutes.chapters(courseId))
          .where('status', '==', ChapterStatus.PUBLISHED)
          .orderBy('order')
          .get()
      ).docs.map((doc) => doc.data() as IChapter);

      return chapters;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getLastOneByCourseId(courseId: string): Promise<IChapter> {
    try {
      const sessionQuery: IQuerySnapshot<IDocumentData> = await this.firestore
        .collection(apiRoutes.chapters(courseId))
        .orderBy('order')
        .limitToLast(1)
        .get();

      if (sessionQuery.empty) {
        return null;
      }

      return sessionQuery.docs[0].data() as IChapter;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async addNewChapterToCourse(courseId: string, chapter: IChapter): Promise<boolean> {
    try {
      const preCourseReqDoc: IDocumentReference<IDocumentData> = this.firestore
        .collection(apiRoutes.chapters(courseId))
        .doc();
      chapter.id = preCourseReqDoc.id;
      await preCourseReqDoc.set(chapter);

      return true;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}
