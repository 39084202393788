import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';

import { imgPreloader, logoAvatar, IAdminTableColumn } from 'src/app/shared';
import { BrandsService, EventsService } from 'src/app/core/services';
import { HubsStore, BrandsStore } from 'src/app/core/stores';
import { IBrand, IEvent } from 'src/app/core/models';
import { ButtonSize, ButtonStyle, ButtonType } from 'src/app/standalone';

@Component({
  selector: 'app-brand-events',
  templateUrl: './brand-events.component.html',
  styleUrls: ['./brand-events.component.scss'],
  providers: [MessageService],
})
export class BrandEventsComponent implements OnInit {
  brandId: string = null;
  loading = true;
  loadingEntries = false;
  searchValue = '';
  searchFilterFields: string[] = ['title', 'start', 'end', 'location', 'status'];
  columns: IAdminTableColumn[] = [
    { field: 'title', titleTranslationKey: 'adminBrandEvents.tableColTitle', sortable: true },
    {
      field: 'logo',
      template: 'logoCell',
      titleTranslationKey: 'adminBrandEvents.tableColLogo',
    },
    {
      field: 'start',
      template: 'startDateCell',
      titleTranslationKey: 'adminBrandEvents.tableColStart',
      sortable: true,
    },
    {
      field: 'end',
      template: 'endDateCell',
      titleTranslationKey: 'adminBrandEvents.tableColEnd',
      sortable: true,
    },
    {
      field: 'location',
      titleTranslationKey: 'adminBrandEvents.tableColLocation',
      sortable: true,
    },
    {
      field: 'status',
      template: 'statusCell',
      titleTranslationKey: 'adminBrandEvents.tableColStatus',
      sortable: true,
    },
    { field: 'actions', template: 'actionsCell' },
  ];
  entries: IEvent[] = [];
  imgPreloader = imgPreloader;
  logoAvatar = logoAvatar;
  buttonSize = ButtonSize;
  buttonType = ButtonType;
  buttonStyle = ButtonStyle;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public hubsStore: HubsStore,
    private brandsService: BrandsService,
    private brandsStore: BrandsStore,
    private eventsService: EventsService,
  ) {
    this.brandId = this.route.parent.snapshot.paramMap.get('brandId');
  }

  get brand(): IBrand {
    return this.brandsStore.adminBrand;
  }

  async ngOnInit(): Promise<void> {
    await this.fetchEvents();
    this.loadingEntries = false;
    this.loading = false;
  }

  edit(event: Event, entry: IEvent): void {
    event.stopPropagation();
    this.router.navigate([`/${this.hubsStore.useHubUrl}/admin/event/${entry.link}`], {
      relativeTo: this.route,
      state: {
        event: entry,
      },
    });
  }

  async onRowClicked(id: string): Promise<void> {
    const event = await this.eventsService.getOne(id, false, false);
    this.router.navigate([`/${this.hubsStore.useHubUrl}/admin/event/${event.link}`]);
  }

  private async fetchEvents(): Promise<void> {
    this.entries = await this.brandsService.getBrandAllEvents(this.brand.id);
  }
}
