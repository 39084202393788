import { IUserCourse } from './../../models/course/user-course.model';
import { ICourse } from './../../models/course/course.model';
import { ITag } from './../../models/tag/tag.model';
import { parseToMoment } from '../../../shared';
import { Injectable } from '@angular/core';
import { makeAutoObservable } from 'mobx';
import * as moment from 'moment';

@Injectable()
export class CoursesStore {
  public course: ICourse = null;
  public userCourse: IUserCourse = null;
  public adminCourse: ICourse = null;
  public isCourseRegistered = false;

  constructor() {
    makeAutoObservable(this);
  }

  public get courseStart(): moment.Moment {
    return parseToMoment(this.course.start);
  }

  public get courseEnd(): moment.Moment {
    return parseToMoment(this.course.end);
  }

  public get isActive(): boolean {
    return moment().isBetween(parseToMoment(this.course.start), parseToMoment(this.course.end));
  }

  public get isOver(): boolean {
    return moment().isAfter(parseToMoment(this.course.end));
  }

  public get isUpcoming(): boolean {
    return moment().isBefore(parseToMoment(this.course.start));
  }

  public get daysLeft(): number {
    return parseToMoment(this.course.start).diff(moment(), 'days');
  }

  public get daysToStartFromPublish(): number {
    return parseToMoment(this.course.start).diff(parseToMoment(this.course.createdAt), 'days');
  }

  public get courseDateFormatted(): string {
    const start = parseToMoment(this.course.start).format('DD. MMM');
    const end = parseToMoment(this.course.end).format('DD. MMM YYYY');
    // I dont think we ever enter this if.
    if (start === end) {
      return start;
    } else {
      return `${start} - ${end}`;
    }
  }

  public setCourse(course: ICourse): void {
    this.course = course;
  }

  public setUserCourse(userCourse: IUserCourse): void {
    this.userCourse = userCourse;
  }

  public setAdminCourse(course: ICourse): void {
    this.adminCourse = course;
  }

  public unsetAdminCourse() {
    this.adminCourse = null;
  }

  public get courseId(): string {
    return this.course?.id;
  }

  public setCourseRegisterStatus(registered: boolean): void {
    this.isCourseRegistered = registered;
  }

  public setCourseTags(tags: ITag[]): void {
    const tagIds = tags.map((t) => t.id);
    if (this.course.tags.every((id) => tagIds.includes(id))) {
      this.course.tagsList = tags;
    }
  }

  public reset(): void {
    this.course = null;
    this.userCourse = null;
    this.isCourseRegistered = false;
  }
}
