<div
  class="container"
  *ngIf="!isSalesforceSSO">
  <h1 class="title">{{ 'createAccountDialog.title' | translate }}</h1>
  <button
    class="btn"
    (click)="onCreateAccount()">
    {{ 'createAccountDialog.createAccountBtn' | translate }}
  </button>
  <p class="question-text">{{ 'createAccountDialog.question' | translate }}</p>
  <a
    class="link"
    (click)="onLogIn()">
    {{ 'createAccountDialog.logIn' | translate }}
  </a>
  <h2 class="subtitle">{{ 'createAccountDialog.subtitle' | translate }}</h2>
  <ul class="list">
    <li class="list-item">
      <i class="far fa-check"></i>
      <div>
        <h5>{{ 'createAccountDialog.listSubtitle1' | translate }}</h5>
        <p>{{ 'createAccountDialog.listText1' | translate }}</p>
      </div>
    </li>
    <li class="list-item">
      <i class="far fa-check"></i>
      <div>
        <h5>{{ 'createAccountDialog.listSubtitle2' | translate }}</h5>
        <p>{{ 'createAccountDialog.listText2' | translate }}</p>
      </div>
    </li>
    <li class="list-item">
      <i class="far fa-check"></i>
      <div>
        <h5>{{ 'createAccountDialog.listSubtitle3' | translate }}</h5>
        <p>{{ 'createAccountDialog.listText3' | translate }}</p>
      </div>
    </li>
  </ul>
</div>

<div
  class="container salesforce-sso-container"
  *ngIf="isSalesforceSSO">
  <h1 class="title">{{ 'createAccountDialog.salesforceTitle' | translate }}</h1>
  <h2 class="subtitle">{{ 'createAccountDialog.salesforceSubtitle1' | translate }}</h2>
  <span>{{ 'createAccountDialog.salesforceSubtitle1Text' | translate }}</span>
  <button
    class="w-and-v-btn"
    (click)="onSignupWithSalesforce()">
    <img [src]="WVLogo | imageSize" />
    <span>{{ 'createAccountDialog.signupW&VBtnText' | translate }}</span>
  </button>
  <span>{{ 'createAccountDialog.salesforceQuestionsText' | translate }}</span>
</div>
