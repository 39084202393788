import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';

import { imgPreloader, logoAvatar, DeleteDialogComponent } from 'src/app/shared';
import { defaultDialogConfig } from 'src/app/core/utils';
import { IBrand, IBrandProduct, IEvent } from 'src/app/core/models';
import { BrandsStore, HubsStore } from 'src/app/core/stores';
import { BrandProductsService } from 'src/app/core/services';
import { ButtonSize, ButtonStyle, ButtonType } from 'src/app/standalone';

@Component({
  selector: 'app-brand-products',
  templateUrl: './brand-products.component.html',
  styleUrls: ['./brand-products.component.scss'],
})
export class BrandProductsComponent implements OnInit {
  brandId: string = null;
  loading = true;
  loadingEntries = false;
  searchValue = '';
  searchFilterFields: string[] = ['title', 'image'];
  columns: any[] = [
    { field: 'title', titleTranslationKey: 'adminBrandEvents.tableColTitle', sortable: true },
    {
      field: 'image',
      template: 'logoCell',
      titleTranslationKey: 'adminBrandEvents.tableColLogo',
    },
    { field: 'actions', template: 'actionsCell' },
  ];
  entries: IBrandProduct[] = [];
  imgPreloader = imgPreloader;
  logoAvatar = logoAvatar;
  buttonSize = ButtonSize;
  buttonType = ButtonType;
  buttonStyle = ButtonStyle;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public hubsStore: HubsStore,
    private brandProductsService: BrandProductsService,
    private brandsStore: BrandsStore,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private messageService: MessageService,
  ) {
    this.brandId = this.route.parent.snapshot.paramMap.get('brandId');
  }

  get brand(): IBrand {
    return this.brandsStore.adminBrand;
  }

  async ngOnInit(): Promise<void> {
    await this.fetchEvents();
    this.loadingEntries = false;
    this.loading = false;
  }

  onAddProduct(): void {
    this.router.navigate([`/${this.hubsStore.useHubUrl}/admin/brand/${this.brand.id}/new-product`]);
  }

  onRowClicked(id: string): void {
    this.router.navigate([
      `/${this.hubsStore.useHubUrl}/admin/brand/${this.brand.id}/products/${id}`,
    ]);
  }

  edit(event: Event, entry: IEvent): void {
    event.stopPropagation();
    this.router.navigate(
      [`/${this.hubsStore.useHubUrl}/admin/brand/${this.brand.id}/products/${entry.id}`],
      {
        relativeTo: this.route,
        state: {
          event: entry,
        },
      },
    );
  }

  async remove(event: Event, entry): Promise<void> {
    event.stopPropagation();
    const dialogRef: DynamicDialogRef = this.dialogService.open(
      DeleteDialogComponent,
      Object.assign(defaultDialogConfig(), {
        height: '35%',
        width: '35%',
        data: {
          title: 'brandOwner.product.deleteProduct',
          description: 'brandOwner.product.deleteProductDescription',
        },
      }),
    );
    const result = await dialogRef.onClose.pipe(take(1)).toPromise();
    if (result) {
      this.entries[this.entries.indexOf(entry)].isDeleting = true;
      try {
        await this.brandProductsService.delete(entry.id);
        this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant('success'),
          detail: this.translateService.instant('brandOwner.product.removeSuccess'),
          styleClass: 'custom-toast',
        });

        this.entries = [];
        this.loadingEntries = true;
        await this.fetchEvents();
        this.loadingEntries = false;
      } catch (error) {
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('error'),
          detail: this.translateService.instant('brandOwner.product.removeError'),
          styleClass: 'custom-toast',
        });
      }
    }
  }

  private async fetchEvents(): Promise<void> {
    this.entries = await this.brandProductsService.getAll(this.brand.id);
  }
}
