<ng-container *ngIf="loading">
  <app-loader></app-loader>
</ng-container>

<div
  *ngIf="!loading"
  class="container">
  <header class="header">
    <div class="title">{{ 'newChapter.createChapter' | translate }}</div>
    <i
      class="fa-regular fa-xmark close-icon"
      (click)="onCloseDialog()"></i>
  </header>
  <form
    class="form"
    [formGroup]="form">
    <!-- Title input -->
    <div>
      <app-input-label
        labelType="required"
        for="title-input"
        text="newChapter.nameFiled">
      </app-input-label>
      <input
        class="custom-input-text custom-input-text-required"
        formControlName="title"
        id="title-input"
        pInputText
        type="text"
        maxlength="30"
        placeholder="{{ 'newChapter.namePlaceholder' | translate }}" />
    </div>
  </form>
  <footer class="footer">
    <div class="checkbox-container">
      <p-checkbox
        class="custom-input-checkbox-sm"
        binary="true"
        [formControl]="createAnotherControl"
        inputId="another-event-input">
      </p-checkbox>
      <app-input-label
        labelType="switch-checkbox"
        for="another-event-input"
        text="newChapter.createAnotherChapter">
      </app-input-label>
    </div>

    <div class="buttons-container">
      <app-button
        buttonText="{{ 'newChapter.cancelBtn' | translate }}"
        buttonIconLeft="fa-regular fa-xmark"
        [buttonStyle]="buttonStyle.GHOST_PRIMARY"
        [buttonSize]="buttonSize.MEDIUM"
        (click)="onCloseDialog()" />
      <app-button
        class="c-ml-2"
        buttonText="{{ 'newChapter.confirmBtn' | translate }}"
        buttonIconLeft="far fa-check"
        [buttonSize]="buttonSize.MEDIUM"
        [loading]="isUpdating"
        [disabled]="!canUpdate"
        (click)="createChapter()" />
    </div>
  </footer>
</div>
