import * as moment from 'moment';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { from, NEVER, Subject } from 'rxjs';
import { catchError, switchMap, takeUntil, tap } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { IReactionDisposer, autorun } from 'mobx';
import { TranslateService } from '@ngx-translate/core';

import { ITicket, IEvent, IHub } from 'src/app/core/models';
import { TicketsService, OpenDialogService } from 'src/app/core/services';
import { EventsStore, HubsStore } from 'src/app/core/stores';
import { getTime, getTimeForEnglishLocale } from 'src/app/core/utils';
import { ButtonSize, ButtonStyle, ButtonType } from 'src/app/standalone';
import { IAdminTableColumn } from 'src/app/shared';
import { AttachSubscriptionsDialogComponent } from 'src/app/admin/dialogs';

@Component({
  selector: 'app-event-tickets',
  templateUrl: './event-tickets.component.html',
  styleUrls: ['./event-tickets.component.scss'],
})
export class EventTicketsComponent implements OnInit, OnDestroy {
  loading = true;
  searchValue: string = '';
  searchFilterFields: string[] = ['name', 'price'];
  syncingTickets = false;
  columns: IAdminTableColumn[] = [
    { field: 'name', titleTranslationKey: 'adminEventsTickets.tableCells.name' },
    {
      field: 'availability',
      titleTranslationKey: 'adminEventsTickets.tableCells.availability',
      template: 'availabilityCell',
    },
    {
      field: 'start',
      titleTranslationKey: 'adminEventsTickets.tableCells.start',
      template: 'startCell',
    },
    { field: 'end', titleTranslationKey: 'adminEventsTickets.tableCells.end', template: 'endCell' },
    {
      field: 'price',
      titleTranslationKey: 'adminEventsTickets.tableCells.price',
      template: 'priceCell',
    },
    {
      field: 'status',
      titleTranslationKey: 'adminEventsTickets.tableCells.status',
      template: 'statusCell',
    },
    { field: 'actions', template: 'actionsCell' },
  ];
  entries: ITicket[] = [];
  buttonType = ButtonType;
  buttonSize = ButtonSize;
  buttonStyle = ButtonStyle;

  private disposer: IReactionDisposer;
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private ticketsService: TicketsService,
    private eventsStore: EventsStore,
    private router: Router,
    private hubsStore: HubsStore,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private openDialogService: OpenDialogService,
    private translateService: TranslateService,
    private dialogService: DialogService,
  ) {}

  get isShowSyncEventBriteBtn(): boolean {
    return this.eventsStore.adminEvent?.eventbriteSync;
  }

  get event(): IEvent {
    return this.eventsStore.adminEvent;
  }

  get hub(): IHub {
    return this.hubsStore.hub;
  }

  async ngOnInit(): Promise<void> {
    this.disposer = autorun(async () => {
      if (this.event) {
        this.loading = true;
        await this.getAllEventTickets();
      }
    });
  }

  openAttachSubscriptionsDialog(): void {
    this.dialogService.open(AttachSubscriptionsDialogComponent, {
      width: '90%',
      height: '80%',
      closable: false,
      styleClass: 'attach-plans-dialog',
    });
  }

  textForAvailability(ticket: ITicket): string {
    const totalTickets: number = ticket.quantityTotal;
    const soldTickets: number = ticket.quantitySold || 0;

    if (totalTickets === soldTickets) {
      return this.translateService.instant('adminEventsTickets.soldOut');
    }

    return `${soldTickets}/${totalTickets}`;
  }

  getDateForTicket(ticket: ITicket, period: 'start' | 'end'): string {
    return `${moment(ticket[period].toDate()).format('DD.MM.YYYY')}`;
  }

  getTimeForTicket(ticket: ITicket, period: 'start' | 'end'): string {
    const lang = this.translateService.currentLang;
    switch (lang) {
      case 'en':
        return getTimeForEnglishLocale(ticket[period]);
      case 'de':
        return `${getTime(ticket[period])} Uhr`;
    }
  }

  async onSyncFromEventBrite(): Promise<void> {
    try {
      this.syncingTickets = true;
      const syncResult: boolean = await this.ticketsService.syncFromEventBrite(
        this.eventsStore.adminEvent.id,
      );

      if (syncResult) {
        this.showToastMessage('success', 'adminEventsTickets.ticketsSuccessfullySynced');
        this.getAllEventTickets();
      } else {
        this.showToastMessage('error', 'application.toasters.error');
      }
      this.syncingTickets = false;
    } catch (error) {
      console.warn(error);
      this.showToastMessage('error', 'application.toasters.error');
    }
  }

  onRowClicked(id: string): void {
    this.router.navigate([
      `/${this.hub ? this.hubsStore.useHubUrl : ''}/admin/event/${this.eventsStore.adminEvent.link}/registrations-tickets/tickets/${id}`,
    ]);
  }

  edit(event: Event, entry: ITicket): void {
    event.stopPropagation();
    this.router.navigate(
      [
        `/${this.hub ? this.hubsStore.useHubUrl : ''}/admin/event/${this.eventsStore.adminEvent.link}/registrations-tickets/tickets/${entry.id}`,
      ],
      {
        relativeTo: this.route,
        state: {
          entry: entry,
        },
      },
    );
  }

  remove(event: Event, ticket: ITicket): void {
    event.stopPropagation();
    let deleteDialogRef;
    if (ticket.quantitySold) {
      deleteDialogRef = this.openDialogService.openDeleteTicketDialog(
        'adminTicket.deletionNotPossible',
        'adminTicket.deletionNotPossibleSoldText',
        'adminTicket.confirmBtn',
        'adminTicket.deletionNotPossibleCancelBtn',
        false,
        true,
      );
    } else if (!ticket.quantitySold && !ticket.eventBriteId) {
      deleteDialogRef = this.openDialogService.openDeleteTicketDialog(
        'adminTicket.deleteTicket',
        'adminTicket.deleteTicketText',
        'adminTicket.confirmBtn',
        'adminTicket.cancelBtn',
        false,
      );
    } else if (!ticket.quantitySold && ticket.eventBriteId) {
      deleteDialogRef = this.openDialogService.openDeleteTicketDialog(
        'adminTicket.deletionNotPossible',
        'adminTicket.deletionNotPossibleEventbriteText',
        'adminTicket.confirmBtn',
        'adminTicket.deletionNotPossibleCancelBtn',
        true,
      );
    }

    deleteDialogRef.onClose
      .pipe(
        switchMap((res: 'cancel' | 'confirm') =>
          res === 'confirm' ? from(this.ticketsService.delete(ticket.id, this.event.id)) : NEVER,
        ),
        tap(() => {
          this.loading = true;
        }),
        tap(() => {
          this.getAllEventTickets();
          this.loading = false;
          this.showToastMessage('success', 'adminEventTickets.removeTicketSuccessMsg');
        }),
        catchError(() => {
          this.showToastMessage('error', 'application.toasters.error');
          return NEVER;
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.disposer();
  }

  private async getAllEventTickets(): Promise<void> {
    this.loading = true;
    const tickets: ITicket[] = await this.ticketsService.getEventTickets(
      this.eventsStore.adminEvent.id,
    );
    this.entries = [...tickets];
    this.loading = false;
  }

  private showToastMessage(severity: 'success' | 'error', detail: string): void {
    this.messageService.add({
      severity: severity,
      summary: this.translateService.instant(severity),
      detail: this.translateService.instant(detail),
      styleClass: 'custom-toast',
    });
  }
}
