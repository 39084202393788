import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { ThemesService } from 'src/app/core/services/themes/themes.service';

@Component({
  selector: 'app-default-covers',
  templateUrl: './default-covers.component.html',
  styleUrls: ['./default-covers.component.scss'],
})
export class DefaultCoversComponent implements OnInit {
  @Output() selectCover = new EventEmitter<string>();
  public defaultCoverImages: string[] = [];

  constructor(private themesService: ThemesService) {}

  ngOnInit(): void {
    this.defaultCoverImages = this.themesService.systemAppearanceSettings.coverImages;
  }

  setDefaultCover(imagePath: string) {
    this.selectCover.emit(imagePath);
  }
}
