import { Timestamp } from 'src/app/firebase';
import { IUser } from '../user/user.model';

export interface ChatConversation {
  id: string;
  conversationWithUser: IUser;
  lastMessage: ChatMessage;
  unreadMessages: number;
}

export interface ChatMessageAttachment {
  type: 'image' | 'pdf';
  name: string;
  url: string;
  size: number;
}

export interface ChatMessage {
  id: string;
  user: IUser;
  timestamp: Date;
  message: string;
  attachment?: ChatMessageAttachment;
  liked: boolean;
}

export interface ChatState {
  enabled: boolean;
  context?: {
    // the color context
    primaryColor: string;
    key: string;
  };
}

export interface ChatConversationDTO {
  id: string;
  usersIds: string[];
  context: {
    [key: string]: {
      lastMessage: ChatMessageDTO;
      totalMessages: number;
      usersReadMessages: { [key: string]: number };
    };
  };
}

export interface ChatMessageDTO {
  id: string;
  userId: string;
  timestamp: Timestamp;
  message: string;
  context: string;
  attachment?: ChatMessageAttachment;
  liked?: boolean;
}
