import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';

import { ICourse, IExportField } from 'src/app/core/models';
import { CourseInstructorsService, CourseSubscribersService } from 'src/app/core/services';
import { CoursesStore } from 'src/app/core/stores';
import { userFieldsForExportInCourse, userCourseFields } from 'src/app/core/utils';

@Component({
  selector: 'app-export-fields-in-course',
  templateUrl: './export-fields-in-course.component.html',
  styleUrls: ['./export-fields-in-course.component.scss'],
})
export class ExportFieldsInCourseComponent implements OnInit {
  @Input() userRole: 'subscriber' | 'instructor';

  userKeys: IExportField[] = userFieldsForExportInCourse;
  userCourseKeys: IExportField[] = userCourseFields;
  selectedUserKeys: IExportField[] = [];
  selectedUserCourseKeys: IExportField[] = userCourseFields;
  isLoading = false;

  private lang: string;

  constructor(
    private messageService: MessageService,
    private translateService: TranslateService,
    private courseInstructors: CourseInstructorsService,
    private coursesStore: CoursesStore,
    private courseSubscribers: CourseSubscribersService,
  ) {}

  get course(): ICourse {
    return this.coursesStore.adminCourse;
  }

  ngOnInit(): void {
    this.lang = this.translateService.currentLang;
    this.selectedUserKeys.push(
      { header: 'First Name', key: 'firstName', order: 2 },
      { header: 'Last Name', key: 'lastName', order: 3 },
      { header: 'Email Address', key: 'email', order: 4 },
      { header: 'Company', key: 'company', order: 5 },
      { header: 'Position', key: 'position', order: 6 },
      { header: 'Address', key: 'street', order: 7 },
      { header: 'City', key: 'city', order: 8 },
      { header: 'Postcode', key: 'postcode', order: 9 },
      { header: 'Country', key: 'country', order: 10 },
      { header: 'Phone', key: 'phone', order: 11 },
    );
  }

  isSelected(propertyName: 'selectedUserKeys' | 'selectedUserCourseKeys', key: string): string {
    return this[propertyName].some((selectedKey: IExportField) => selectedKey.key === key)
      ? 'selected'
      : '';
  }

  selectField(
    propertyName: 'selectedUserKeys' | 'selectedUserCourseKeys',
    field: IExportField,
  ): void {
    const condition: boolean = this[propertyName].some(
      (selectedUserKey: IExportField) => selectedUserKey.key === field.key,
    );

    if (condition) {
      this[propertyName] = this[propertyName].filter(
        (selectedUserKey: IExportField) => selectedUserKey.key !== field.key,
      );
    } else {
      this[propertyName].push(field);
    }
  }

  async export(): Promise<void> {
    try {
      this.isLoading = true;
      if (this.userRole === 'instructor') {
        await this.courseInstructors.exportInstructors(
          this.course.id,
          this.course.title,
          this.selectedUserKeys,
          this.selectedUserCourseKeys,
          this.lang,
        );
      } else {
        await this.courseSubscribers.exportSubscribers(
          this.course.id,
          this.course.title,
          this.selectedUserKeys,
          this.selectedUserCourseKeys,
          this.lang,
        );
      }
    } catch (error) {
      this.showToastMessage('error', 'application.toasters.error');
    } finally {
      this.isLoading = false;
    }
  }

  unselectAll(): void {
    this.selectedUserKeys = [];
    this.selectedUserCourseKeys = [];
  }

  private showToastMessage(severity: 'success' | 'error', detail: string): void {
    this.messageService.add({
      severity,
      summary: this.translateService.instant(severity),
      detail: this.translateService.instant(detail),
      styleClass: 'custom-toast',
    });
  }
}
