/* eslint-disable max-len */
import { environment } from 'src/environments/environment';
import { CollectionTypes } from 'src/app/shared/enums';

export const API_ROUTES = {
  appointmentAccept: (appointmentId: string) =>
    `${environment.apiUrl}appointment/${appointmentId}/accept`,
  appointmentCreate: `${environment.apiUrl}appointment/create`,
  appointmentDecline: (appointmentId: string) =>
    `${environment.apiUrl}appointment/${appointmentId}/cancel`,
  appointments: CollectionTypes.APPOINTMENTS,
  appointmentsUsers: CollectionTypes.APPOINTMENTS_USERS,
  awsEventBridgeEvents: CollectionTypes.AWS_EVENT_BRIDGE_EVENTS,
  bookmarkedBrands: (userId: string): string =>
    `${CollectionTypes.USERS}/${userId}/${CollectionTypes.BOOKMARKED_BRANDS}`,
  bookmarkedCourses: (userId: string): string =>
    `${CollectionTypes.USERS}/${userId}/${CollectionTypes.BOOKMARKED_COURSES}`,
  bookmarkedEvents: (userId: string): string =>
    `${CollectionTypes.USERS}/${userId}/${CollectionTypes.BOOKMARKED_EVENTS}`,
  bookmarkedSessions: (userEventId: string): string =>
    `${CollectionTypes.USER_EVENTS}/${userEventId}/${CollectionTypes.BOOKMARKED_SESSIONS}`,
  bookmarkedStageChatMessages: (userEventId: string, stageId: string) =>
    `${CollectionTypes.USER_EVENTS}/${userEventId}/${CollectionTypes.STAGE}/${stageId}/${CollectionTypes.BOOKMARKED_CHAT_MESSAGES}`,
  bookmarkedUsers: (userId: string): string =>
    `${CollectionTypes.USERS}/${userId}/${CollectionTypes.BOOKMARKED_USERS}`,
  brandCourses: CollectionTypes.BRAND_COURSES,
  brandMemberInvites: CollectionTypes.BRAND_MEMBER_INVITES,
  brandOwnerInvites: CollectionTypes.BRAND_OWNER_INVITES,
  brands: CollectionTypes.BRANDS,
  chapter: (courseId: string, chapterId: string) =>
    `${CollectionTypes.COURSES}/${courseId}/${CollectionTypes.CHAPTERS}/${chapterId}`,
  chapters: (courseId: string) =>
    `${CollectionTypes.COURSES}/${courseId}/${CollectionTypes.CHAPTERS}`,
  chatConversationMessages: (chatConversationId: string) =>
    `${CollectionTypes.CHATS}/${chatConversationId}/messages`,
  chats: CollectionTypes.CHATS,
  courseApi: (courseId: string) => `course/${courseId}`,
  courseAssets: CollectionTypes.COURSE_ASSETS,
  courses: CollectionTypes.COURSES,
  coursesMailTemplates: CollectionTypes.COURSES_MAIL_TEMPLATES,
  courseSubscriptions: CollectionTypes.COURSE_SUBSCRIPTIONS,
  courseTicket: (courseId: string, ticketId: string): string =>
    `${CollectionTypes.COURSES}/${courseId}/${CollectionTypes.TICKETS}/${ticketId}`,
  courseTickets: (courseId: string): string =>
    `${CollectionTypes.COURSES}/${courseId}/${CollectionTypes.TICKETS}`,
  courseCoupon: (courseId: string, couponId: string): string =>
    `${CollectionTypes.COURSES}/${courseId}/${CollectionTypes.COUPONS}/${couponId}`,
  courseCoupons: (courseId: string): string =>
    `${CollectionTypes.COURSES}/${courseId}/${CollectionTypes.COUPONS}`,
  dailyCo: {
    createToken: (dailyUrl: string) => `${dailyUrl}/meeting-tokens`,
    meetingsForSpecificRoom: (dailyUrl: string, roomName: string) =>
      `${dailyUrl}/meetings?room=${roomName}`,
    recordAccessLink: (dailyUrl: string, recordId: string) =>
      `${dailyUrl}/recordings/${recordId}/access-link`,
    recordListFromRoom: (dailyUrl: string, roomName: string) =>
      `${dailyUrl}/recordings?room_name=${roomName}`,
    room: (dailyUrl: string, roomName: string) => `${dailyUrl}/rooms/${roomName}`,
    rooms: (dailyUrl: string) => `${dailyUrl}/rooms`,
  },
  devices: CollectionTypes.DEVICES,
  emails: `${environment.apiUrl}emails`,
  exportInstructors: (courseId: string) =>
    `${environment.apiUrl}course/${courseId}/instructors/export`,
  exportSubscribers: (courseId: string) =>
    `${environment.apiUrl}course/${courseId}/subscribers/export`,
  eventBrands: CollectionTypes.EVENT_BRANDS,
  eventBriteSync: (eventId: string, sendEmail: boolean) =>
    `${environment.apiUrl}eventbrite/${eventId}/sync?sendEmail=${sendEmail}`,
  eventConsents: (eventId: string) =>
    `${CollectionTypes.EVENTS}/${eventId}/${CollectionTypes.SETTINGS}/${CollectionTypes.EVENT_REGISTRATION_FORM}/${CollectionTypes.CONSENTS}`,
  eventProducts: CollectionTypes.EVENT_PRODUCTS,
  eventRegistrationForm: CollectionTypes.EVENT_REGISTRATION_FORM,
  events: CollectionTypes.EVENTS,
  eventsMailTemplates: CollectionTypes.EVENTS_MAIL_TEMPLATES,
  eventSettings: (eventId: string): string =>
    `${CollectionTypes.EVENTS}/${eventId}/${CollectionTypes.SETTINGS}`,
  eventSubscriptions: CollectionTypes.EVENT_SUBSCRIPTIONS,
  eventTicket: (eventId: string, ticketId: string): string =>
    `${CollectionTypes.EVENTS}/${eventId}/${CollectionTypes.TICKETS}/${ticketId}`,
  eventTickets: (eventId: string): string =>
    `${CollectionTypes.EVENTS}/${eventId}/${CollectionTypes.TICKETS}`,
  failedLoginAttempts: CollectionTypes.FAILED_LOGIN_ATTEMPTS,
  fetchEventTickets: (eventId: string): string =>
    `${environment.apiUrl}${CollectionTypes.EVENTS}/${eventId}/${CollectionTypes.TICKETS}/fetch`,
  hubBrands: CollectionTypes.HUB_BRANDS,
  hubPages: CollectionTypes.HUB_PAGES,
  hubs: CollectionTypes.HUBS,
  library: CollectionTypes.LIBRARY,
  mailTemplates: CollectionTypes.MAIL_TEMPLATES,
  orders: CollectionTypes.ORDERS,
  sessions: (eventId: string): string =>
    `${CollectionTypes.EVENTS}/${eventId}/${CollectionTypes.SESSIONS}`,
  sessionAssets: (eventId: string): string =>
    `${CollectionTypes.EVENTS}/${eventId}/${CollectionTypes.SESSION_ASSETS}`,
  settings: `${CollectionTypes.SETTINGS}`,
  stages: (eventId: string): string =>
    `${CollectionTypes.EVENTS}/${eventId}/${CollectionTypes.STAGES}`,
  stageChatMessages: (eventId: string, stageId: string): string =>
    `${CollectionTypes.EVENTS}/${eventId}/${CollectionTypes.STAGES}/${stageId}/${CollectionTypes.CHAT_MESSAGES}`,
  subscriptions: CollectionTypes.SUBSCRIPTIONS,
  tickets: CollectionTypes.TICKETS,
  userBrands: CollectionTypes.USER_BRANDS,
  userCourseInvites: CollectionTypes.USER_COURSES_INVITES,
  userCourses: CollectionTypes.USER_COURSES,
  userCoursesTracking: CollectionTypes.USER_COURSES_TRACKING,
  userEvents: CollectionTypes.USER_EVENTS,
  userHubs: CollectionTypes.USER_HUBS,
  userInvites: CollectionTypes.USER_INVITES,
  userPaymentSessions: CollectionTypes.USER_PAYMENT_SESSIONS,
  users: CollectionTypes.USERS,
};
