<div class="image-component-container d-flex">
  <img
    *ngIf="src"
    alt="{{ this.text }}"
    src="{{ this.src | imageSize }}"
    class="image"
    [ngClass]="imageSize" />

  <div
    *ngIf="!src"
    class="backgound-color-primary border-radius-circle border-1-solid-bodyColor text-center"
    [ngClass]="imageSize">
    <span class="span-text"> {{ text }} </span>
  </div>
</div>
