import { Injectable } from '@angular/core';

import { UsersStore } from 'src/app/core/stores';
import {
  Firestore,
  IDocumentData,
  IFirestore,
  IQuerySnapshot,
  ServerTimestamp,
} from 'src/app/firebase';
import { IAsset, ICourse, ICourseAsset } from 'src/app/core/models';
import { CollectionTypes, API_ROUTES as apiRoutes } from 'src/app/shared';
import { StorageService } from './../storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class CourseAssetsService {
  private firestore: IFirestore;

  constructor(
    private storageService: StorageService,
    private userStore: UsersStore,
  ) {
    this.firestore = Firestore();
  }

  public async getOne(id: string): Promise<ICourseAsset> {
    try {
      const courseAsset = (
        await this.firestore.doc(`${CollectionTypes.COURSE_ASSETS}/${id}`).get()
      ).data() as ICourseAsset;
      return courseAsset;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async getAllCourseAssetsByCourseId(courseId: string): Promise<ICourseAsset[]> {
    const courseDoc = await this.firestore
      .collection(apiRoutes.courseAssets)
      .where('courseId', '==', courseId)
      .get();

    return courseDoc.docs.map((doc) => doc.data() as ICourseAsset);
  }

  public async updateCourseAssetsByIds(courseAssets: ICourseAsset[]): Promise<boolean> {
    courseAssets.forEach(async (courseAsset) => {
      await this.firestore
        .collection(CollectionTypes.COURSE_ASSETS)
        .doc(courseAsset.id)
        .update({ courseAsset });
    });

    return true;
  }

  async getAssetsByIds(assetIds: string[]): Promise<IAsset[]> {
    try {
      const events = [];
      let query: IQuerySnapshot<IDocumentData>;

      while (assetIds?.length > 0) {
        const chunk = assetIds.splice(0, 10);

        query = await this.firestore
          .collection(CollectionTypes.LIBRARY)
          .where('id', 'in', chunk)
          .orderBy('_title_', 'desc')
          .get();

        if (!query.empty) {
          query.forEach((doc) => {
            const ev = doc.data() as IAsset;
            if (!assetIds.includes(ev.id)) {
              events.push(ev);
            }
          });
        }
      }

      return events;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  public async createCourseAsset(course: ICourse, asset: IAsset): Promise<ICourseAsset> {
    try {
      const preAssetReqDocRef = this.firestore.collection(CollectionTypes.COURSE_ASSETS).doc();

      const newCourseAsset = {
        id: preAssetReqDocRef.id,
        _asset_title_: asset.title.toLowerCase(),
        _course_title: course.title.toLowerCase(),
        assetId: asset.id,
        courseId: course.id,
        createdAt: ServerTimestamp(),
        createdBy: this.userStore.userId,
        updatedAt: null,
        updatedBy: null,
      } as ICourseAsset;

      await preAssetReqDocRef.set({ ...newCourseAsset });

      return newCourseAsset;
    } catch (error) {
      return null;
    }
  }

  public async delete(assetId: string) {
    try {
      const assetDocRef = this.firestore
        .collection(CollectionTypes.COURSE_ASSETS)
        .where('assetId', '==', assetId);
      const currentAssetDoc = await assetDocRef.get();

      // delete image file if it exits
      const currentImageUrl = currentAssetDoc.docs[0].get('image');
      if (currentImageUrl) {
        await this.storageService.delete(currentImageUrl);
      }

      // delete asset file if it exits
      const currentFileUrl = currentAssetDoc.docs[0].get('file');
      if (currentFileUrl) {
        await this.storageService.delete(currentFileUrl);
      }

      await this.firestore
        .collection(CollectionTypes.COURSE_ASSETS)
        .doc(currentAssetDoc.docs[0].id)
        .delete();
      return true;
    } catch (error) {
      return null;
    }
  }
}
