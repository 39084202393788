<div
  *ngIf="!isModalMode"
  class="sign-in-view-navigation-container">
  <img
    *ngIf="logo"
    class="logo"
    [src]="logo | imageSize"
    (click)="onLogoClick()" />
  <form class="form">
    <p-dropdown
      class="custom-dropdown"
      panelStyleClass="custom-dropdown-panel"
      [(ngModel)]="selectedLanguage"
      [options]="selectLanguages"
      name="language"
      optionLabel="name"
      (onChange)="setLanguage($event.value)">
      <ng-template pTemplate="selectedItem">
        <span
          class="flag-icon flag-icon-squared flag-icon-circle flag-icon-{{
            selectedLanguage.code === 'en' ? 'gb' : selectedLanguage.code
          }}">
        </span>
        {{ 'languages.' + selectedLanguage.code | translate }}
      </ng-template>
      <ng-template
        let-lang
        pTemplate="item">
        <span
          class="flag-icon flag-icon-squared flag-icon-circle flag-icon-{{
            lang.code === 'en' ? 'gb' : lang.code
          }}">
        </span>
        {{ 'languages.' + lang.code | translate }}
      </ng-template>
    </p-dropdown>
  </form>
</div>

<p-toast>
  <ng-template
    let-message
    pTemplate="message">
    <app-toast
      [severity]="message.severity"
      [summary]="message.summary"
      [detail]="message.detail">
    </app-toast>
  </ng-template>
</p-toast>

<div
  *ngIf="isVerificationEmailSent"
  class="verification-email-sent-container">
  <div class="left">
    <span
      class="inline-flex border-circle justify-content-center align-items-center text-white bg-green-500 mr-4"
      style="width: 38px; height: 38px">
      <i class="pi pi-check-circle text-xl"></i>
    </span>
    <span>
      <h1>{{ 'signin.authVerificationTitle' | translate }}</h1>
      <p>{{ authVerificationDesc }}</p>
    </span>
  </div>
  <div class="right">
    <button
      class="signin-button"
      pButton
      (click)="resendVerifyEmail()"
      [disabled]="!resentVerificationBtnActive">
      <span *ngIf="sendingVerificationEmailLoading"><i class="pi pi-spin pi-spinner"></i></span>
      <div class="button-text">
        <span *ngIf="!resentVerificationBtnActive"
          >{{ 'signin.authVerificationButtonTryAgain' | translate }}
          {{ resentVerificationBtnTimeLeft }}</span
        >
        <span *ngIf="resentVerificationBtnActive">{{
          'signin.authVerificationButton' | translate
        }}</span>
      </div>
    </button>
  </div>
</div>

<div
  class="signin-view-container flex flex-row flex-wrap justify-content-center"
  [ngClass]="{
    'verification-email-sent-container--shown': isVerificationEmailSent,
    'signin-view-popup': isModalMode,
  }">
  <div class="signin-form-container">
    <p class="page-title">{{ 'signin.signinTitle' | translate }}</p>

    <form
      [formGroup]="form"
      class="form">
      <!-- Email input -->
      <div>
        <app-input-label
          labelType="required"
          for="email-input"
          text="signin.emailLabel">
        </app-input-label>
        <input
          class="custom-input-text custom-input-text-required"
          formControlName="email"
          id="email-input"
          pInputText
          type="text"
          placeholder="{{ 'signin.emailPlaceholder' | translate }}" />
        <app-input-error-message
          [showMessage]="form.controls.email.invalid && form.controls.email.touched"
          [errorMessage]="
            form.controls.email.hasError('email')
              ? ('signin.emailNotValid' | translate)
              : ('signin.emailRequired' | translate)
          ">
        </app-input-error-message>
      </div>

      <!-- Password input -->
      <div>
        <app-input-label
          labelType="required"
          for="password-input"
          text="signin.passwordLabel">
        </app-input-label>
        <div class="relative">
          <input
            class="custom-input-text custom-input-text-required"
            formControlName="password"
            id="password-input"
            pInputText
            [type]="showPassword ? 'text' : 'password'"
            placeholder="{{ 'signin.passwordLabel' | translate }}" />
          <i
            class="fa-regular eye-icon"
            [ngClass]="{ 'fa-eye': showPassword, 'fa-eye-slash': !showPassword }"
            (click)="togglePasswordVisibility()">
          </i>
        </div>
        <app-input-error-message
          [showMessage]="form.controls.password.invalid && form.controls.password.touched"
          [errorMessage]="passwordErrorMessage | translate">
        </app-input-error-message>
      </div>

      <!-- Normal Sign in -->
      <button
        class="signin-button p-0"
        pButton
        [disabled]="!form.valid"
        (click)="signin()">
        <div class="button-text">
          <span *ngIf="!signinLoading">{{ 'signin.signinBtn' | translate }}</span>
          <span *ngIf="signinLoading"><i class="pi pi-spin pi-spinner"></i></span>
        </div>
      </button>

      <div
        class="links-container c-pt-1"
        (click)="onResetPassword()">
        <p>
          {{ 'signin.or' | translate }}
          <span class="signup-text-style">{{ 'signin.resetPassBtn' | translate }}</span>
        </p>
      </div>

      <ng-container *ngIf="showSignUp">
        <p-divider styleClass="custom-divider-horizontal"></p-divider>

        <div
          class="links-container c-pt-1"
          *ngIf="generalSettings && !generalSettings.isPrivate"
          (click)="onSignup()">
          <p>
            {{ 'signin.signupBtnFirstLine' | translate }}
            <span class="signup-text-style">{{ 'signin.signupBtnSecondLine' | translate }}</span>
          </p>
        </div>
      </ng-container>
    </form>
  </div>
</div>
