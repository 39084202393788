import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable } from 'rxjs';

import { IAsset } from 'src/app/core/models';
import { RegisteredCourseStore } from 'src/app/core/stores';

@Component({
  selector: 'app-registered-course-asset-details',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './registered-course-asset-details.component.html',
  styleUrls: ['./registered-course-asset-details.component.scss'],
})
export class RegisteredCourseAssetDetailsComponent implements OnInit {
  asset: Observable<IAsset>;

  constructor(private registeredCourseStore: RegisteredCourseStore) {}

  ngOnInit(): void {
    this.asset = this.registeredCourseStore.activeAsset;
  }
}
