import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import { ClipboardService } from 'ngx-clipboard';

import { Timestamp } from 'src/app/firebase';
import {
  IEvent,
  IStage,
  IEventBrand,
  ITag,
  IUser,
  IClientConfiguration,
  IChannel,
} from 'src/app/core/models';
import {
  StagesService,
  TagsService,
  BrandsService,
  SpeakersService,
  AttendeesService,
  EventsService,
  IvsService,
  FormService,
} from 'src/app/core/services';
import { EventsStore, StagesStore, HubsStore, UsersStore } from 'src/app/core/stores';
import { ConfirmDialogComponent } from 'src/app/shared';
import { asyncDelay, capitalizeFirstLetter } from 'src/app/core/utils';
import { StreamProviders } from 'src/app/core/enums';
import { AppStore } from 'src/app/app.store';

@Component({
  selector: 'app-admin-stage-form',
  templateUrl: './admin-stage-form.component.html',
  styleUrls: ['./admin-stage-form.component.scss'],
})
export class AdminStageFormComponent implements OnInit, OnDestroy {
  loading = true;
  isUpdating = false;
  stageId: string = null;
  tags: ITag[] = [];
  eventBrands: IEventBrand[] = [];
  streamProviders = Object.values(StreamProviders);
  providersOfStream = StreamProviders;
  eventBrandPeople: (IUser & { name: string })[] = [];
  eventSpeakers: (IUser & { name: string })[] = [];
  eventAttendees: (IUser & { name: string })[] = [];
  form: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl(null, [Validators.required, Validators.maxLength(50)]),
    streamProvider: new UntypedFormControl(StreamProviders.NOT_SELECTED),
    streamLink: new UntypedFormControl(null),
    streamServer: new UntypedFormControl(null),
    streamKey: new UntypedFormControl(null),
    arn: new UntypedFormControl(null),
    recordingConfigurationArn: new UntypedFormControl(null),
    enableRecording: new UntypedFormControl(null),
    enableAutoReplay: new UntypedFormControl(null),
    streamRecording: new UntypedFormControl(null),
    isMainStage: new UntypedFormControl(null),
    featured: new UntypedFormControl(null),
    isChatVisible: new UntypedFormControl(true),
    isChatAnonymous: new UntypedFormControl(false),
    isChatAnonymousOneToOne: new UntypedFormControl(false),
    isAgendaVisible: new UntypedFormControl(true),
    isHideChatAtTheEndOfTheDay: new UntypedFormControl(true),
    description: new UntypedFormControl(null),
    tags: new UntypedFormControl(null),
    banner: new UntypedFormControl(null),
    ivsPreviewImage: new UntypedFormControl(null),
    sponsors: new UntypedFormControl(null),
    brandPeople: new UntypedFormControl(null),
    moderators: new UntypedFormControl(null),
    speakers: new UntypedFormControl(null),
  });

  private unsubscribe$: Subject<void> = new Subject<void>();
  private initialStreamProvider: StreamProviders = null;

  constructor(
    private router: Router,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private eventsStore: EventsStore,
    private stagesStore: StagesStore,
    public hubsStore: HubsStore,
    private usersStore: UsersStore,
    private tagService: TagsService,
    private brandsService: BrandsService,
    private stagesService: StagesService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private speakersService: SpeakersService,
    private attendeesService: AttendeesService,
    private eventsService: EventsService,
    private ivsService: IvsService,
    private formService: FormService,
    private clipboardService: ClipboardService,
    public appStore: AppStore,
  ) {
    this.stageId = this.route.snapshot.params.stageId || null;
  }

  get stage(): IStage {
    return this.stagesStore.adminStage;
  }

  get event(): IEvent {
    return this.eventsStore.adminEvent;
  }

  get streamProvider(): string {
    return this.form.get('streamProvider').value;
  }

  get selectedBrandLabel(): string {
    return `{0} ${this.translateService.instant('adminStage.brandsSelected')}`;
  }

  get shouldShowBrands(): boolean {
    return this.hubsStore.hub.brands;
  }

  get canUpdate(): boolean {
    return (
      !this.isUpdating && this.form.valid && this.form.dirty && this.formService.isValueChanged()
    );
  }

  async ngOnInit(): Promise<void> {
    const [
      tagsResponse,
      eventBrandsResponse,
      eventStagesResponse,
      eventSpeakersResponse,
      eventAttendeesResponse,
      eventBrandPeopleResponse,
    ] = await Promise.all([
      this.tagService.getByIdsOrderedByTitle(this.event.tags),
      this.brandsService.getBrandsFromEvent(this.event.id),
      this.stagesService.getAllEventStages(this.event.id),
      this.speakersService.getAllSpeakers(this.event.id),
      this.attendeesService.getAllAttendees(this.event.id),
      this.eventsService.getEventBrandPeople(this.event.id),
    ]);

    this.tags = tagsResponse;
    this.initialStreamProvider = eventStagesResponse.find((stage) => stage.id == this.stageId)
      ?.streamProvider as StreamProviders;

    this.eventBrands = eventBrandsResponse.map((e) => {
      e['name'] = capitalizeFirstLetter(e._brand_name_);
      return e;
    });

    this.eventSpeakers = eventSpeakersResponse.map((e) => ({
      ...e,
      name: `${e.firstName.trim()} ${e.lastName.trim()}`,
    }));
    this.eventAttendees = eventAttendeesResponse.map((e) => ({
      ...e,
      name: `${e.firstName.trim()} ${e.lastName.trim()}`,
    }));
    this.eventBrandPeople = eventBrandPeopleResponse.map((e) => ({
      ...e,
      name: `${e.firstName.trim()} ${e.lastName.trim()} (${e.company})`,
    }));

    this.updateForm();
    this.formService.setForm(this.form);
    this.form.updateValueAndValidity();
    this.form.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.form.markAsDirty());
    this.loading = false;
  }

  selectTags(tagIds: string[]): void {
    this.form.patchValue({ tags: tagIds });
  }

  setBannerValue(value: File): void {
    this.form.controls.banner.setValue(value);
  }

  setIvsPreviewImageValue(value: File): void {
    this.form.controls.ivsPreviewImage.setValue(value);
  }

  copyToClipboard(): void {
    this.clipboardService.copy(this.form.controls.streamKey.value);
  }

  async onSave(): Promise<void> {
    const stage = this.form.getRawValue() as IStage;

    if (stage.isMainStage && !this.stage?.isMainStage) {
      const allStages = await this.stagesService.getAll(this.event.id);

      if (allStages.filter((s) => s.isMainStage && s.id !== stage.id).length) {
        this.dialogService
          .open(ConfirmDialogComponent, {
            closable: false,
            styleClass: 'confirm-dialog',
            data: {
              titleKey: 'adminStage.changingMainStage',
              descriptionKey: 'adminStage.changingMainStageDesc',
              confirmBtnKey: 'adminStage.gotIt',
              hideCancelBtn: true,
            },
          })
          .onClose.pipe(
            filter((result: 'cancel' | 'confirm') => result === 'confirm'),
            takeUntil(this.unsubscribe$),
          )
          .subscribe(() => {
            this.save();
          });
      } else {
        await this.save();
      }
    } else {
      await this.save();
    }
  }

  onDiscard(): void {
    if (this.stage) {
      this.updateForm();
    } else {
      this.form.patchValue({
        name: null,
        streamProvider: StreamProviders.NOT_SELECTED,
        streamLink: null,
        streamServer: null,
        streamKey: null,
        arn: null,
        recordingConfigurationArn: null,
        enableRecording: null,
        enableAutoReplay: null,
        streamRecording: null,
        isMainStage: null,
        featured: null,
        isChatVisible: true,
        isChatAnonymous: false,
        isChatAnonymousOneToOne: false,
        isAgendaVisible: true,
        isHideChatAtTheEndOfTheDay: true,
        description: null,
        tags: null,
        banner: null,
        sponsors: null,
        brandPeople: null,
        moderators: null,
        speakers: null,
      });
    }
  }

  async onConfirm(): Promise<void> {
    try {
      this.isUpdating = true;
      await asyncDelay(1);
      await this.save();
      this.formService.setForm(this.form);
    } catch (error) {
      console.error(error);
    }
  }

  private updateForm(): void {
    this.form.patchValue({
      ...this.stage,

      // removes any user that was previous associated to this stage
      // BUT was removed from the event without being also removed from the stage relations
      brandPeople:
        this.stage?.brandPeople?.filter((uid) => this.eventBrandPeople.find((u) => u.id === uid)) ??
        [],
      moderators:
        this.stage?.moderators?.filter((uid) => this.eventAttendees.find((u) => u.id === uid)) ??
        [],
      speakers:
        this.stage?.speakers?.filter((uid) => this.eventSpeakers.find((u) => u.id === uid)) ?? [],
    });

    if (this.stage) {
      this.form.controls.streamServer.disable();
      this.form.controls.streamKey.disable();
    }
  }

  private async save(): Promise<void> {
    const stage = this.form.getRawValue() as IStage;
    this.isUpdating = true;

    try {
      let newStage = null;
      stage._name_ = stage.name.toLowerCase();

      if (this.stageId) {
        const ivsChannelResponse = await this.saveIvsStreamProvider({ ...this.stage, ...stage });

        stage.streamKey = ivsChannelResponse?.streamKey || null;
        stage.streamLink = ivsChannelResponse?.streamLink || stage?.streamLink;
        stage.streamServer = ivsChannelResponse?.streamServer || null;
        stage.arn = ivsChannelResponse?.arn || null;
        stage.recordingConfigurationArn = ivsChannelResponse?.recordingConfigurationArn || null;
        stage.ivsChannelName = ivsChannelResponse?.ivsChannelName || null;
        this.initialStreamProvider = StreamProviders.AMAZON;

        this.form.patchValue({
          streamProvider: stage.streamProvider,
          streamKey: stage.streamKey,
          streamLink: stage.streamLink,
          streamServer: stage.streamServer,
          arn: stage.arn,
          recordingConfigurationArn: stage.recordingConfigurationArn,
        });
        this.form.updateValueAndValidity();

        newStage = await this.stagesService.update(this.event.id, this.stage.id, stage);
      } else {
        stage.createdAt = Timestamp.now();
        stage.createdBy = this.usersStore.user.id;
        newStage = await this.stagesService.create(this.event.id, stage);

        const ivsChannelResponse = await this.saveIvsStreamProvider(newStage);

        if (newStage.streamProvider === StreamProviders.AMAZON) {
          newStage.streamKey = ivsChannelResponse.streamKey;
          newStage.streamLink = ivsChannelResponse.streamLink;
          newStage.streamServer = ivsChannelResponse.streamServer;
          newStage.arn = ivsChannelResponse.arn;
          newStage.recordingConfigurationArn = ivsChannelResponse.recordingConfigurationArn;
          newStage.ivsChannelName = ivsChannelResponse.ivsChannelName;

          newStage = await this.stagesService.update(this.event.id, newStage.id, newStage);
        }
      }

      this.stagesStore.setAdminStage({ ...newStage });

      this.messageService.add({
        severity: 'success',
        summary: this.translateService.instant('success'),
        detail: this.stageId
          ? this.translateService.instant('adminStage.stageSuccessfullyUpdated')
          : this.translateService.instant('adminStage.stageSuccessfullyCreated'),
        styleClass: 'custom-toast',
      });
      this.isUpdating = false;
      if (!this.stageId) {
        this.router.navigate([
          `/${this.hubsStore.useHubUrl}/admin/event/${this.event.link}/setup/stages/${newStage.id}`,
        ]);
      }
    } catch (error) {
      console.log(error);
      this.isUpdating = false;
      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('error'),
        detail: this.stageId
          ? this.translateService.instant('adminStage.errorUpdateStage')
          : this.translateService.instant('adminStage.errorCreatingStage'),
        styleClass: 'custom-toast',
      });
    }
  }

  private async saveIvsStreamProvider(stage: IStage): Promise<any> {
    let result = {};

    if (
      stage.streamProvider === StreamProviders.AMAZON &&
      this.initialStreamProvider !== StreamProviders.AMAZON
    ) {
      // Craete new channel
      const credentials: IClientConfiguration = {
        region: this.appStore.generalSystemSettings.ivsRegion,
        accessKeyId: this.appStore.generalSystemSettings.ivsKey,
        secretAccessKey: this.appStore.generalSystemSettings.ivsSecret,
      };
      const ivsChannel: IChannel = await this.ivsService.createChannelAsync(
        credentials,
        stage.id.toLowerCase(),
        stage?.enableRecording,
      );

      result = {
        streamProvider: stage.streamProvider,
        streamKey: ivsChannel.streamKey,
        streamLink: ivsChannel.streamLink,
        streamServer: ivsChannel.streamServer,
        arn: ivsChannel.arn,
        recordingConfigurationArn: ivsChannel.recordingConfigurationArn,
        enableAutoReplay: stage.enableAutoReplay,
        streamRecording: stage.enableRecording,
        ivsChannelName: ivsChannel.ivsChannelName,
      };
    }

    if (
      stage.streamProvider !== StreamProviders.AMAZON &&
      this.initialStreamProvider === StreamProviders.AMAZON
    ) {
      // Delte old channel
      const credentials = {
        region: this.appStore.generalSystemSettings.ivsRegion,
        accessKeyId: this.appStore.generalSystemSettings.ivsKey,
        secretAccessKey: this.appStore.generalSystemSettings.ivsSecret,
      };
      await this.ivsService.deleteChannelAsync(
        credentials,
        stage.arn,
        stage.recordingConfigurationArn,
        false,
      );

      result = {
        streamProvider: stage.streamProvider,
        streamKey: null,
        streamLink: null,
        streamServer: null,
        arn: null,
        recordingConfigurationArn: null,
        enableAutoReplay: null,
        streamRecording: null,
        ivsChannelName: null,
      };
    }

    if (
      stage.streamProvider === StreamProviders.AMAZON &&
      this.initialStreamProvider === StreamProviders.AMAZON
    ) {
      result = {
        streamProvider: stage.streamProvider,
        streamKey: stage.streamKey,
        streamLink: stage.streamLink,
        streamServer: stage.streamServer,
        arn: stage.arn,
        recordingConfigurationArn: stage.recordingConfigurationArn,
        enableAutoReplay: stage.enableAutoReplay,
        streamRecording: stage.enableRecording,
        ivsChannelName: stage.ivsChannelName,
      };
    }

    return result;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
