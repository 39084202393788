import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import {
  MainNavigationComponent,
  AsideNavigationComponent,
  FooterNavigationComponent,
  SidebarNavigationComponent,
  CHAT_COMPONENTS,
  UserPhotoComponent,
  MobileEventNavigationFooterComponent,
  MobileHeaderComponent,
  MobileNavigationFooterComponent,
  ImprintComponent,
  SearchComponent,
  PrivacyPageComponent,
  SwNewVersionComponent,
  SwInstallPromptComponent,
  HubPricingTableComponent,
  NewHubComponent,
} from './components';

import { ImageSizeModule } from './modules/image-size/image-size.module';
import { LoaderModule } from './modules/loader/loader.module';
import { SharedModule } from '../shared/shared.module';
import { TopicsSidebarComponent } from '../shared/components/topics-sidebar/topics-sidebar.component';
import {
  TranslationLoader,
  TranslationsService,
} from './services/translations/translations.service';
import { NewAppVersionComponent, UserAccountNavigationComponent } from '../standalone';
import { StripeCallbackComponent } from './components/stripe-callback/stripe-callback.component';
import { MaintenanceComponent } from './components/maintenance/maintenance.component';
import { HomeComponent } from './components/home/home.component';

export function HttpLoaderFactory(http: HttpClient, translatinsService: TranslationsService) {
  return new TranslationLoader(http, translatinsService);
}

@NgModule({
  declarations: [
    MainNavigationComponent,
    AsideNavigationComponent,
    FooterNavigationComponent,
    SidebarNavigationComponent,
    MobileEventNavigationFooterComponent,
    MobileNavigationFooterComponent,
    CHAT_COMPONENTS,
    UserPhotoComponent,
    MobileHeaderComponent,
    TopicsSidebarComponent,
    ImprintComponent,
    SearchComponent,
    PrivacyPageComponent,
    SwNewVersionComponent,
    SwInstallPromptComponent,
    HubPricingTableComponent,
    NewHubComponent,
    StripeCallbackComponent,
    MaintenanceComponent,
    HomeComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslationLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    LoaderModule,
    ImageSizeModule,
    NewAppVersionComponent,
    UserAccountNavigationComponent,
  ],
  exports: [
    MainNavigationComponent,
    AsideNavigationComponent,
    FooterNavigationComponent,
    SidebarNavigationComponent,
    MobileEventNavigationFooterComponent,
    CHAT_COMPONENTS,
    UserPhotoComponent,
    MobileHeaderComponent,
    MobileNavigationFooterComponent,
    TopicsSidebarComponent,
    ImprintComponent,
    HomeComponent,
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CoreModule {}
