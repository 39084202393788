import { Injectable } from '@angular/core';
import { AuthorizationService } from '../auth';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable()
export class HttpClientService {
  constructor(
    private http: HttpClient,
    private authService: AuthorizationService,
  ) {}

  public async post<T>(url: string, payload: any): Promise<T> {
    const headers = await this.authService.buildHeaders();

    try {
      const response: any = await this.http
        .post<T>(`${environment.apiUrl}${url}`, payload, { headers })
        .toPromise();
      return response.response ? (response.response as T) : response;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  public async get<T>(url: string): Promise<T> {
    const headers = await this.authService.buildHeaders();

    try {
      return this.http.get<T>(`${environment.apiUrl}${url}`, { headers }).toPromise();
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  public async delete<T>(url: string): Promise<T> {
    const headers = await this.authService.buildHeaders();

    try {
      return this.http.delete<T>(`${environment.apiUrl}${url}`, { headers }).toPromise();
    } catch (e) {
      console.error(e);
      return null;
    }
  }
}
