<div
  *ngIf="isSearchVisible"
  class="search">
  <input
    class="custom-input-text"
    pInputText
    placeholder="{{ 'mainNavigation.search_input_plaseholder' | translate }}"
    [(ngModel)]="currentSearchTerm"
    (input)="onSearchQueryUpdate($event)"
    type="text" />
  <i class="fa-regular fa-magnifying-glass search-icon"></i>
  <ng-container *ngIf="searchResults$ | async as searchResults">
    <i
      class="far fa-times-circle cross-icon"
      (click)="clearSearchAction()">
    </i>
    <div
      *ngIf="searchResults"
      class="search-results">
      <ng-container *ngFor="let searchResultGroup of searchResults">
        <div class="search-results__group-title">
          {{ searchResultGroup.description }}
        </div>
        <div
          *ngFor="let item of searchResultGroup.items"
          class="search-results__row"
          (click)="onSelectSearchResult(searchResultGroup, item)">
          {{ item.description }}
        </div>
      </ng-container>
      <ng-container *ngIf="searchResults.length === 0">
        {{ 'search.noSearchResultsMessage' | translate }}
      </ng-container>
    </div>
  </ng-container>
</div>
