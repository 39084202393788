import { Injectable } from '@angular/core';

import {
  Firestore,
  IDocumentData,
  IDocumentReference,
  IDocumentSnapshot,
  IFirestore,
} from 'src/app/firebase';
import { IHubPage, IPrivacySettingsPage } from 'src/app/core/models';
import { API_ROUTES as apiRoutes } from 'src/app/shared';

@Injectable({
  providedIn: 'root',
})
export class HubPagesService {
  private firestore: IFirestore;

  constructor() {
    this.firestore = Firestore();
  }

  async getHubPage(hubPageId: string): Promise<IHubPage> {
    try {
      const hubPageQuery: IDocumentSnapshot<IDocumentData> = await this.firestore
        .collection(apiRoutes.hubPages)
        .doc(hubPageId)
        .get();

      return hubPageQuery.data() as IHubPage;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async getHubPagesByHubId(hubId: string): Promise<IHubPage[]> {
    try {
      const hubPages: IHubPage[] = (
        await this.firestore.collection(apiRoutes.hubPages).where('hubId', '==', hubId).get()
      ).docs.map((doc) => doc.data() as IHubPage);

      return hubPages;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async createHubPages(globalPages: IPrivacySettingsPage[], hubId: string): Promise<IHubPage[]> {
    try {
      const hubPages = await Promise.all(
        globalPages.map(async (page: IPrivacySettingsPage) => {
          const hubPageDocRef: IDocumentReference<IDocumentData> = await this.firestore
            .collection(apiRoutes.hubPages)
            .doc();
          const newHubPage: IHubPage = {
            ...page,
            id: hubPageDocRef.id,
            hubId,
          };
          await this.firestore
            .collection(apiRoutes.hubPages)
            .doc(newHubPage.id)
            .set({ ...newHubPage });

          return newHubPage;
        }),
      );

      return hubPages;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async updateHubPage(hubPage: IHubPage): Promise<IHubPage> {
    try {
      await this.firestore.collection(apiRoutes.hubPages).doc(hubPage.id).update(hubPage);

      return hubPage;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }
}
