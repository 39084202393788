import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { DialogService } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { ClipboardService } from 'ngx-clipboard';
import { TranslateService } from '@ngx-translate/core';

import { SettingsService } from 'src/app/core/services';
import { IPrivacySettingsPage } from 'src/app/core/models';
import { HubsStore } from 'src/app/core/stores';
import { EventPagePreviewComponent } from '../event-page-preview/event-page-preview.component';

@Component({
  selector: 'app-system-page-html-editor',
  templateUrl: './system-page-html-editor.component.html',
  styleUrls: ['./system-page-html-editor.component.scss'],
})
export class SystemPageHtmlEditorComponent implements OnInit {
  @Input() page: IPrivacySettingsPage = null;

  public loading = true;
  public saving = false;
  public form: UntypedFormGroup;
  public languages: any[] = [];
  public isEditing: boolean = false;

  constructor(
    private translateService: TranslateService,
    private dialogService: DialogService,
    private messageService: MessageService,
    private settingsService: SettingsService,
    private clipboardService: ClipboardService,
    public hubsStore: HubsStore,
  ) {}

  ngOnInit(): void {
    this.languages = this.translateService.getLangs().map((l) => {
      return { name: this.translateService.instant('languages.' + l), code: l };
    });

    this.createForm();
    this.loading = false;
  }

  private createForm(): void {
    this.form = new UntypedFormGroup({
      title: new UntypedFormControl(this.page ? this.page.title : '', Validators.required),
      language: new UntypedFormControl(
        this.page ? this.languages.find((x) => x.code === this.page.language) : '',
        Validators.required,
      ),
      status: new UntypedFormControl(this.page ? this.page.status : ''),
      isMobile: new UntypedFormControl(this.page ? this.page.isMobile : false),
      url: new UntypedFormControl(this.page ? this.page.url : ''),
      html: new UntypedFormControl(this.page ? this.page.html : '', Validators.required),
    });
  }

  public preview() {
    this.dialogService.open(EventPagePreviewComponent, {
      width: '90%',
      height: '90%',
      dismissableMask: true,
      contentStyle: {
        overflow: 'auto',
        padding: '0',
        'border-radius': '20px',
      },
      data: {
        html: this.form.controls.html.value,
      },
    });
  }

  public copy() {
    const value = this.form.get('html').value ? this.form.get('html').value : '';
    this.clipboardService.copy(value);
  }

  public async save() {
    if (!this.form.valid) return;

    try {
      this.saving = true;
      const form = this.form.value;

      const newPage = {
        id: this.page ? this.page.id : null,
        title: form.title,
        _title_: form.title.toLowerCase(),
        language: form.language.code,
        status: form.status,
        url: form.url,
        html: form.html,
        isDeleted: false,
        isMobile: form.isMobile,
      } as IPrivacySettingsPage;

      await this.settingsService.update(newPage, this.page.id);

      this.messageService.add({
        severity: 'success',
        summary: this.translateService.instant('adminEventPageTemplate.success'),
        detail: this.translateService.instant('adminEventPageTemplate.succesSave'),
        styleClass: 'custom-toast',
      });

      this.saving = false;
    } catch (error) {
      console.log(error);
      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('error'),
        detail: this.translateService.instant('adminEventPageTemplate.errorSave'),
        styleClass: 'custom-toast',
      });
      this.saving = false;
    }
  }
}
