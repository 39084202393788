import { Injectable } from '@angular/core';
import {
  Route,
  UrlSegment,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';

import { UsersStore, HubsStore } from 'src/app/core/stores';
import {
  ThemesService,
  HubsService,
  UsersService,
  AuthenticationService,
} from 'src/app/core/services';
import { TenantsService } from 'src/app/core/services/tenants/tenants.service';
import { IHub } from 'src/app/core/models';

@Injectable({
  providedIn: 'root',
})
export class HubAccessGuard {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private hubsService: HubsService,
    private hubsStore: HubsStore,
    private usersStore: UsersStore,
    private themesService: ThemesService,
    private usersService: UsersService,
    private tenantsService: TenantsService,
  ) {}

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    const prevHubTenantId = this.hubsStore.hub ? this.hubsStore.hub.tenantId : null;
    const hub = await this.hubsService.getByUrl(next.params.hub);
    this.hubsStore.setHub(hub);
    if (!hub) {
      this.router.navigate(['/dashboard']);
      return false;
    } else {
      const currentTheme = localStorage.getItem('styleTheme');
      const currentThemeProps =
        this.themesService.systemAppearanceSettings[currentTheme + 'Theme'].properties;

      document.documentElement.style.setProperty(
        '--appPrimaryColor',
        hub.primaryColor !== null && hub.primaryColor !== undefined && hub.primaryColor !== ''
          ? hub.primaryColor
          : currentThemeProps['--appPrimaryColor'],
      );
    }

    if (this.usersStore.user) {
      //TODO: For now this not works for existing admins(and similar roles) who didn't migrated to tenants.
      // const freshUser = await this.usersService.getOne(this.usersStore.user?.id);
      // if ((freshUser?.role === 'superAdmin' || freshUser?.role === 'internalAdmin' || freshUser?.role === 'instanceOwner' || freshUser?.role === 'softwareDeveloper')) {
      //   return true;
      // }

      if (this.hubsStore.hub.tenantId) {
        const isExistResponse = await this.tenantsService.isEmailExist(this.usersStore.user.email);

        if (isExistResponse.exist && this.usersStore.user.id === isExistResponse.userId) {
          return this.checkHubAccess(state, hub);
        } else {
          if (state.url !== '/' + this.hubsStore.useHubUrl + '/switch-hub') {
            await this.authenticationService.signOut();
            this.router.navigate([this.hubsStore.useHubUrl + '/switch-hub']);
            this.usersStore.reset();
            return false;
          } else {
            return true;
          }
        }
      } else {
        if (state.url !== '/' + this.hubsStore.useHubUrl + '/switch-hub') {
          return this.checkHubAccess(state, hub);
        } else {
          return true;
        }
      }
    } else {
      if (state.url !== '/' + this.hubsStore.useHubUrl + '/switch-hub') {
        return this.checkHubAccess(state, hub);
      } else {
        //
      }
    }
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const currentTheme = localStorage.getItem('styleTheme');
    const systemAppearanceThemeProps =
      this.themesService.systemAppearanceSettings[currentTheme + 'Theme'].properties;

    document.documentElement.style.setProperty(
      '--appPrimaryColor',
      systemAppearanceThemeProps['--appPrimaryColor'],
    );

    this.hubsStore.resetHub();
    return true;
  }
  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }

  private async checkHubAccess(state, hub: IHub) {
    if (hub.isPrivate) {
      if (state.url == '/' + this.hubsStore.useHubUrl + '/signin') {
        return true;
      }
      if (
        state.url == `/${this.hubsStore.useHubUrl}/signup` ||
        state.url == `/${this.hubsStore.useHubUrl}/dashboard`
      ) {
        this.router.navigate([`/${this.hubsStore.useHubUrl}/signin`]);
        return false;
      }
      let userHub, freshUser;
      if (this.usersStore.user?.id) {
        userHub = await this.hubsService.hasAccess(hub.id, this.usersStore.user.id);
        freshUser = await this.usersService.getOne(this.usersStore.user.id);
      }

      if (!userHub && !freshUser) {
        this.router.navigate([`${this.hubsStore.useHubUrl}/signin`]);
        return true;
      }

      if (
        userHub ||
        freshUser?.role === 'superAdmin' ||
        freshUser?.role === 'internalAdmin' ||
        freshUser?.role === 'instanceOwner' ||
        freshUser?.role === 'softwareDeveloper'
      ) {
        if (hub.isMaintenance) {
          if (state.url.includes('admin')) {
            return true;
          } else {
            if (state.url !== '/' + this.hubsStore.useHubUrl + '/maintenance') {
              this.router.navigate([this.hubsStore.useHubUrl + '/maintenance']);
            }
          }
        }
        return true;
      } else {
        this.router.navigate([`${this.hubsStore.useHubUrl}/signin`]);
        return true;
      }
    } else {
      if (hub.isMaintenance) {
        if (state.url.includes('admin')) {
          return true;
        } else {
          if (state.url !== '/' + this.hubsStore.useHubUrl + '/maintenance') {
            this.router.navigate([this.hubsStore.useHubUrl + '/maintenance']);
          }
        }
      }
      return true;
    }
  }

  private setPrimaryTextColor(hexColor) {
    if (hexColor) {
      const hex = hexColor;
      const red = parseInt(hex[1] + hex[2], 16);
      const green = parseInt(hex[3] + hex[4], 16);
      const blue = parseInt(hex[5] + hex[6], 16);

      if (red * 0.299 + green * 0.587 + blue * 0.114 > 150) {
        document.documentElement.style.setProperty('--appPrimaryTextColor', 'black');
      } else {
        document.documentElement.style.setProperty('--appPrimaryTextColor', 'white');
      }
    }
  }
}
