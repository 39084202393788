<div class="sw-install-prompt-container">
  <h1>{{ 'SW.installAppDialogTitle' | translate }}</h1>

  <p *ngIf="platform === 'android'">{{ 'SW.addToHomeScreen' | translate }}</p>
  <p *ngIf="platform === 'ios'">
    <span>{{ 'SW.iosInstallMsgPartOne' | translate }}</span>
    <i class="fa-thin fa-arrow-up-from-square"></i>
    <span>{{ 'SW.iosInstallMsgPartTwo' | translate }}</span>
    <i class="fa-thin fa-plus"></i>
    <span>{{ 'SW.iosInstallMsgPartTwo' | translate }}</span>
  </p>

  <div class="actions-container">
    <button
      type="button"
      class="{{ platform === 'ios' ? 'cancel-btn' : 'confirm-btn' }}"
      pButton
      label="{{
        (platform === 'ios' ? 'SW.installAppDialogCancelBtn' : 'SW.installAppDialogInstallBtn')
          | translate
      }}"
      (click)="ref.close(platform === 'ios' ? false : true)"></button>
  </div>
</div>
