import { Firestore, IFirestore } from './../../../firebase';
import { IAppearanceSettings } from '../../models/system/appearance-settings.model';
import { Router } from '@angular/router';
import { darkTheme } from '../../models/theme/dark-theme.model';
import { lightTheme } from '../../models/theme/light-theme.model';
import { Theme } from '../../models/theme/theme.model';
import { Injectable } from '@angular/core';
import { from, BehaviorSubject } from 'rxjs';
import { isObjectEmpty } from '../../utils/utils';
import { action, makeObservable, observable } from 'mobx';
import { IGeneralSettings } from '../../models';
import { StorageService } from '../storage';

@Injectable({
  providedIn: 'root',
})
export class ThemesService {
  private isSettingsLoadingSubject$ = new BehaviorSubject<boolean>(false);
  public isSettingsLoading$ = this.isSettingsLoadingSubject$.asObservable();

  private systemAppearanceSettingsSubject$ = new BehaviorSubject<IAppearanceSettings>(null);
  public systemAppearanceSettings$ = this.systemAppearanceSettingsSubject$.asObservable();

  private settingsCollection: string = 'settings';
  private appearanceSettingsDoc: string = 'appearance';
  private generalSettingsDoc: string = 'general';

  public systemAppearanceSettings: IAppearanceSettings = null;
  public activeTheme: Theme = darkTheme;
  public availableThemes: Theme[] = [lightTheme, darkTheme];

  private readonly firestore: IFirestore;

  constructor(
    private router: Router,
    private storageService: StorageService,
  ) {
    this.firestore = Firestore();

    makeObservable(this, {
      activeTheme: observable,
      setActiveTheme: action,
    });

    from(this.firestore.collection('settings').doc('appearance').get()).subscribe((doc) => {
      this.systemAppearanceSettings = doc.data() as IAppearanceSettings;
      this.systemAppearanceSettingsSubject$.next(doc.data() as IAppearanceSettings);

      if (
        this.systemAppearanceSettings.darkTheme &&
        !isObjectEmpty(this.systemAppearanceSettings.darkTheme)
      ) {
        localStorage.setItem('darkTheme', JSON.stringify(this.systemAppearanceSettings.darkTheme));
      } else {
        localStorage.setItem('darkTheme', JSON.stringify(darkTheme));
      }

      if (
        this.systemAppearanceSettings.lightTheme &&
        !isObjectEmpty(this.systemAppearanceSettings.lightTheme)
      ) {
        localStorage.setItem(
          'lightTheme',
          JSON.stringify(this.systemAppearanceSettings.lightTheme),
        );
      } else {
        localStorage.setItem('lightTheme', JSON.stringify(lightTheme));
      }

      if (
        (this.systemAppearanceSettings.darkTheme &&
          !isObjectEmpty(this.systemAppearanceSettings.darkTheme)) ||
        (this.systemAppearanceSettings.lightTheme &&
          !isObjectEmpty(this.systemAppearanceSettings.lightTheme))
      ) {
        switch (localStorage.getItem('styleTheme')) {
          case 'light':
            this.setLightTheme();
            break;
          case 'dark':
          default:
            this.setDarkTheme();
        }
      }

      this.isSettingsLoadingSubject$.next(true);
    });
  }

  public setThemeByName(themeName: string): void {
    if (themeName === 'dark') {
      this.setDarkTheme();
    } else if (themeName === 'light') {
      this.setLightTheme();
    } else {
      this.setDarkTheme();
    }
  }

  public setActiveTheme(theme: Theme): void {
    this.activeTheme = theme;
    localStorage.setItem('styleTheme', this.activeTheme.name);

    Object.keys(this.activeTheme.properties).forEach((property) => {
      document.documentElement.style.setProperty(property, this.activeTheme.properties[property]);
    });
  }

  public async getSystemGeneralSettings(): Promise<IGeneralSettings> {
    const query = await this.firestore.collection('settings').doc('general').get();
    const result = query.data();

    return result as IGeneralSettings;
  }

  public async getSystemAppearanceSettings(): Promise<IAppearanceSettings> {
    const query = await this.firestore.collection('settings').doc('appearance').get();
    const result = query.data();

    return result as IAppearanceSettings;
  }

  public async updateGeneralSettings(generalSettings: IGeneralSettings): Promise<IGeneralSettings> {
    try {
      await this.firestore
        .collection(this.settingsCollection)
        .doc(this.generalSettingsDoc)
        .update({ ...generalSettings });
      return generalSettings;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  public async updateAppearanceSettings(
    appearanceSettings: IAppearanceSettings,
  ): Promise<IAppearanceSettings> {
    try {
      const settingsDocument = this.firestore
        .collection(this.settingsCollection)
        .doc(this.appearanceSettingsDoc);
      if (
        appearanceSettings.logo instanceof File ||
        appearanceSettings.logoLight instanceof File ||
        appearanceSettings.cover instanceof File
      ) {
        const settingsDocData = await settingsDocument.get();

        if (appearanceSettings.logo instanceof File) {
          const currentLogoImage = settingsDocData.get('logo');

          if (currentLogoImage) {
            await this.storageService.delete(currentLogoImage);
          }

          if (appearanceSettings.logo !== null) {
            const fileName = `file_${new Date().getTime()}`;
            const newLogoImage = await this.storageService.upload(
              appearanceSettings.logo,
              'settings',
              fileName,
            );
            appearanceSettings.logo = newLogoImage;
          } else {
            appearanceSettings.logo = null;
          }
        }

        if (appearanceSettings.logoLight instanceof File) {
          const currentLogoLightImage = settingsDocData.get('logoLight');

          if (currentLogoLightImage) {
            await this.storageService.delete(currentLogoLightImage);
          }

          if (appearanceSettings.logoLight !== null) {
            const fileName = `file_${new Date().getTime()}`;
            const newLogoLightImage = await this.storageService.upload(
              appearanceSettings.logoLight,
              'settings',
              fileName,
            );
            appearanceSettings.logoLight = newLogoLightImage;
          } else {
            appearanceSettings.logoLight = null;
          }
        }

        if (appearanceSettings.cover instanceof File) {
          const currentCoverImage = settingsDocData.get('cover');

          if (currentCoverImage) {
            await this.storageService.delete(currentCoverImage);
          }

          if (appearanceSettings.cover !== null) {
            const fileName = `file_${new Date().getTime()}`;
            const newCoverImage = await this.storageService.upload(
              appearanceSettings.cover,
              'settings',
              fileName,
            );
            appearanceSettings.cover = newCoverImage;
          } else {
            appearanceSettings.cover = null;
          }
        }
      }

      await settingsDocument.update({ ...appearanceSettings });

      return appearanceSettings;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  private setDarkTheme(): void {
    const themeColors = localStorage.getItem('darkTheme') || null;

    if (themeColors === null) {
      localStorage.setItem('darkTheme', JSON.stringify(darkTheme));
      this.setActiveTheme(darkTheme);
    } else {
      this.setActiveTheme(JSON.parse(themeColors));
    }
  }

  private setLightTheme(): void {
    const themeColors = localStorage.getItem('lightTheme') || null;

    if (themeColors === null) {
      localStorage.setItem('lightTheme', JSON.stringify(lightTheme));
      this.setActiveTheme(lightTheme);
    } else {
      this.setActiveTheme(JSON.parse(themeColors));
    }
  }
}
