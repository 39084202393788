@if (loading()) {
  <app-loader></app-loader>
} @else {
  <header class="header">
    {{ 'adminEventSessionSidebar.spaceSettingsNavItem' | translate }}
  </header>
  <p-divider styleClass="custom-divider-horizontal"></p-divider>
  <form [formGroup]="form">
    <!-- Space-Settings block -->
    <div class="form-block c-my-8 c-px-8">
      <div class="left-side">
        <h2 class="title">{{ 'adminSession.spaceSettingsTitle' | translate }}</h2>
        <p class="description">{{ 'adminSession.spaceSettingsDescription' | translate }}</p>
      </div>
      <div class="right-side">
        <!-- Space input -->
        <div class="form-field-input-switch-block">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="space"
            inputId="space-input">
          </p-inputSwitch>
          <app-input-label
            [labelType]="isSessionFinished ? 'switch-checkbox-tooltip' : 'switch-checkbox'"
            tooltipText="{{ 'adminSession.spaceSettingsTooltipText' | translate }}"
            for="space-input"
            text="adminSession.space">
          </app-input-label>
        </div>

        @if (form.controls.space.value) {
          <div class="c-mt-8">
            <p-divider styleClass="custom-divider-horizontal"></p-divider>

            <!-- Is live streaming input -->
            <div class="form-field-input-switch-block c-mt-8">
              <p-inputSwitch
                class="custom-input-switch"
                formControlName="isLiveStreaming"
                inputId="is-live-streaming-input">
              </p-inputSwitch>
              <app-input-label
                [labelType]="
                  isSessionFinished || isLiveStreamingDisabled
                    ? 'switch-checkbox-tooltip'
                    : 'switch-checkbox'
                "
                tooltipText="{{ liveStreamingTooltip | translate }}"
                for="is-live-streaming-input"
                text="adminSession.isLiveStreaming">
              </app-input-label>
            </div>
            <p class="switch-input-note">
              {{ 'adminSession.isLiveStreamingNote' | translate }}
            </p>

            <!-- Is allow only moderators input -->
            <div class="form-field-input-switch-block c-mt-4">
              <p-inputSwitch
                class="custom-input-switch"
                formControlName="isAllowOnlyModerators"
                inputId="is-allow-only-moderators-input">
              </p-inputSwitch>
              <app-input-label
                [labelType]="isSessionFinished ? 'switch-checkbox-tooltip' : 'switch-checkbox'"
                tooltipText="{{ 'adminSession.spaceSettingsTooltipText' | translate }}"
                for="is-allow-only-moderators-input"
                text="adminSession.isAllowOnlyModerators">
              </app-input-label>
            </div>
            <p class="switch-input-note">
              {{ 'adminSession.isAllowOnlyModeratorsNote' | translate }}
            </p>

            <!-- Moderators input -->
            <div class="form-field-block c-mt-4 c-mb-4">
              <app-input-label
                [labelType]="isSessionFinished ? 'tooltip' : 'required'"
                tooltipText="{{ 'adminSession.spaceSettingsTooltipText' | translate }}"
                for="moderators-input"
                text="adminSession.moderatorsLabel">
              </app-input-label>
              <p-multiSelect
                class="custom-multi-select"
                [class.custom-multi-select-required]="!isSessionFinished"
                [options]="moderators()"
                optionLabel="lastName"
                optionValue="id"
                formControlName="moderators"
                inputId="moderators-input"
                placeholder="{{ 'adminSession.moderatorsPlaceholder' | translate }}">
                <ng-template
                  let-moderator
                  pTemplate="item">
                  {{ moderator.firstName }} {{ moderator.lastName }}
                </ng-template>
              </p-multiSelect>
              <p class="input-note">
                {{ 'adminSession.moderatorsNote' | translate }}
              </p>
            </div>

            <!-- Maximum participants input -->
            <div class="form-field-block c-mb-4">
              <app-input-label
                [labelType]="isSessionFinished ? 'tooltip' : 'usual'"
                tooltipText="{{ 'adminSession.spaceSettingsTooltipText' | translate }}"
                for="max-participants-input"
                text="adminSession.participants">
              </app-input-label>
              <p-inputNumber
                class="custom-input-number custom-input-number-required"
                inputId="max-participants-input"
                formControlName="maxParticipants"
                placeholder="{{ 'adminSession.participantsPlaceholder' | translate }}">
              </p-inputNumber>
              <app-input-error-message
                [showMessage]="
                  form.controls.maxParticipants.touched &&
                  form.controls.maxParticipants.hasError('max')
                "
                errorMessage="{{ 'adminSession.maximum' | translate }} {{ maxParticipants }}">
              </app-input-error-message>
              <p class="input-note input-note-with-showing-error">
                {{ 'adminSession.participantsNote' | translate }}
              </p>
            </div>

            <p-divider styleClass="custom-divider-horizontal"></p-divider>

            <!-- Owner only broadcast switch input -->
            <div class="form-field-input-switch-block c-mt-8 c-mb-4">
              <p-inputSwitch
                class="custom-input-switch"
                formControlName="ownerOnlyBroadcast"
                inputId="owner-only-broadcast-input-switch">
              </p-inputSwitch>
              <app-input-label
                [labelType]="isSessionFinished ? 'switch-checkbox-tooltip' : 'switch-checkbox'"
                tooltipText="{{ 'adminSession.spaceSettingsTooltipText' | translate }}"
                for="owner-only-broadcast-input-switch"
                text="adminSession.broadcastLabel">
              </app-input-label>
            </div>
            <p class="switch-input-note c-mb-8">
              {{ 'adminSession.broadcastNote' | translate }}
            </p>

            <p-divider styleClass="custom-divider-horizontal"></p-divider>

            <!-- Camera switch input -->
            <div class="form-field-input-switch-block c-mt-8">
              <p-inputSwitch
                class="custom-input-switch"
                formControlName="camera"
                inputId="camera-input-switch">
              </p-inputSwitch>
              <app-input-label
                [labelType]="isSessionFinished ? 'switch-checkbox-tooltip' : 'switch-checkbox'"
                tooltipText="{{ 'adminSession.spaceSettingsTooltipText' | translate }}"
                for="camera-input-switch"
                text="adminSession.cameraLabel">
              </app-input-label>
            </div>
            <p class="switch-input-note">
              {{ 'adminSession.cameraNote' | translate }}
            </p>

            <!-- Microphone switch input -->
            <div class="form-field-input-switch-block c-mt-4">
              <p-inputSwitch
                class="custom-input-switch"
                formControlName="microphone"
                inputId="microphone-input-switch">
              </p-inputSwitch>
              <app-input-label
                [labelType]="isSessionFinished ? 'switch-checkbox-tooltip' : 'switch-checkbox'"
                tooltipText="{{ 'adminSession.spaceSettingsTooltipText' | translate }}"
                for="microphone-input-switch"
                text="adminSession.microphoneLabel">
              </app-input-label>
            </div>
            <p class="switch-input-note">
              {{ 'adminSession.microphoneNote' | translate }}
            </p>

            <!-- Screen sharing switch input -->
            <div class="form-field-input-switch-block c-mt-4">
              <p-inputSwitch
                class="custom-input-switch"
                formControlName="screenSharing"
                inputId="screen-sharing-input-switch">
              </p-inputSwitch>
              <app-input-label
                [labelType]="isSessionFinished ? 'switch-checkbox-tooltip' : 'switch-checkbox'"
                tooltipText="{{ 'adminSession.spaceSettingsTooltipText' | translate }}"
                for="screen-sharing-input-switch"
                text="adminSession.screenSharingLabel">
              </app-input-label>
            </div>
            <p class="switch-input-note">
              {{ 'adminSession.screenSharingNote' | translate }}
            </p>

            <!-- Text chat switch input -->
            <div class="form-field-input-switch-block c-mt-4">
              <p-inputSwitch
                class="custom-input-switch"
                formControlName="textChat"
                inputId="text-chat-input-switch">
              </p-inputSwitch>
              <app-input-label
                [labelType]="isSessionFinished ? 'switch-checkbox-tooltip' : 'switch-checkbox'"
                tooltipText="{{ 'adminSession.spaceSettingsTooltipText' | translate }}"
                for="text-chat-input-switch"
                text="adminSession.textChatLabel">
              </app-input-label>
            </div>
            <p class="switch-input-note">
              {{ 'adminSession.textChatNote' | translate }}
            </p>

            <!-- Show people listing switch input -->
            <div class="form-field-input-switch-block c-mt-4">
              <p-inputSwitch
                class="custom-input-switch"
                formControlName="isShowPeopleListing"
                inputId="text-chat-input-switch">
              </p-inputSwitch>
              <app-input-label
                [labelType]="isSessionFinished ? 'switch-checkbox-tooltip' : 'switch-checkbox'"
                tooltipText="{{ 'adminSession.spaceSettingsTooltipText' | translate }}"
                for="text-chat-input-switch"
                text="adminSession.showPeopleListing">
              </app-input-label>
            </div>
            <p class="switch-input-note">
              {{ 'adminSession.showPeopleListingNote' | translate }}
            </p>

            <!-- Picture in picture switch input -->
            <div class="form-field-input-switch-block c-mt-4">
              <p-inputSwitch
                class="custom-input-switch"
                formControlName="isPictureInPicture"
                inputId="text-chat-input-switch">
              </p-inputSwitch>
              <app-input-label
                [labelType]="isSessionFinished ? 'switch-checkbox-tooltip' : 'switch-checkbox'"
                tooltipText="{{ 'adminSession.spaceSettingsTooltipText' | translate }}"
                for="text-chat-input-switch"
                text="adminSession.pictureInPicture">
              </app-input-label>
            </div>
            <p class="switch-input-note">
              {{ 'adminSession.pictureInPictureNote' | translate }}
            </p>

            <!-- Hand raising switch input -->
            <div class="form-field-input-switch-block c-mt-4">
              <p-inputSwitch
                class="custom-input-switch"
                formControlName="isHandRaising"
                inputId="hand-raising-input-switch">
              </p-inputSwitch>
              <app-input-label
                [labelType]="isSessionFinished ? 'switch-checkbox-tooltip' : 'switch-checkbox'"
                tooltipText="{{ 'adminSession.spaceSettingsTooltipText' | translate }}"
                for="hand-raising-input-switch"
                text="adminSession.enableHandRaising">
              </app-input-label>
            </div>
            <p class="switch-input-note">
              {{ 'adminSession.handRisingDescription' | translate }}
            </p>

            <!-- Emoji reactions switch input -->
            <div class="form-field-input-switch-block c-mt-4">
              <p-inputSwitch
                class="custom-input-switch"
                formControlName="isEmojiReactions"
                inputId="emoji-reactions-input-switch">
              </p-inputSwitch>
              <app-input-label
                [labelType]="isSessionFinished ? 'switch-checkbox-tooltip' : 'switch-checkbox'"
                tooltipText="{{ 'adminSession.spaceSettingsTooltipText' | translate }}"
                for="emoji-reactions-input-switch"
                text="adminSession.enableEmojiReactions">
              </app-input-label>
            </div>
            <p class="switch-input-note">
              {{ 'adminSession.emojiReactionsDescription' | translate }}
            </p>

            <!-- Breakout rooms switch input -->
            <div class="form-field-input-switch-block c-mt-4">
              <p-inputSwitch
                class="custom-input-switch"
                formControlName="isBreakoutRooms"
                inputId="breakout-rooms-input-switch">
              </p-inputSwitch>
              <app-input-label
                [labelType]="isSessionFinished ? 'switch-checkbox-tooltip' : 'switch-checkbox'"
                tooltipText="{{ 'adminSession.spaceSettingsTooltipText' | translate }}"
                for="breakout-rooms-input-switch"
                text="adminSession.enableBreakoutsRooms">
              </app-input-label>
            </div>
            <p class="switch-input-note">
              {{ 'adminSession.breakoutRoomsDescription' | translate }}
            </p>

            <!-- Is recording switch input -->
            <div class="form-field-input-switch-block c-my-4">
              <p-inputSwitch
                class="custom-input-switch"
                formControlName="isRecording"
                inputId="is-recording-input-switch">
              </p-inputSwitch>
              <app-input-label
                [labelType]="isSessionFinished ? 'switch-checkbox-tooltip' : 'switch-checkbox'"
                tooltipText="{{ 'adminSession.spaceSettingsTooltipText' | translate }}"
                for="is-recording-input-switch"
                text="adminSession.localRecording">
              </app-input-label>
            </div>
            <p class="switch-input-note">
              {{ 'adminSession.localRecordingDescription' | translate }}
            </p>

            <!-- Recording type input -->
            @if (form.controls.isRecording.value) {
              <div class="form-field-block c-mt-4">
                <app-input-label
                  for="recording-type-input"
                  text="adminSession.recordingType">
                </app-input-label>
                <p-dropdown
                  class="custom-dropdown"
                  panelStyleClass="custom-dropdown-panel"
                  [options]="typesOfRecording()"
                  formControlName="recordingType"
                  inputId="recording-type-input"
                  placeholder="{{ 'adminSession.recordingTypePlaceholder' | translate }}">
                  <ng-template
                    pTemplate="item"
                    let-item>
                    {{ getRecordingTypeText(item) | translate }}
                  </ng-template>
                  <ng-template
                    pTemplate="selectedItem"
                    let-selectedItem>
                    {{ getRecordingTypeText(selectedItem) | translate }}
                  </ng-template>
                </p-dropdown>

                @if (form.controls.recordingType.value === recordingTypes.CLOUD) {
                  <div class="flex c-mt-4">
                    <app-button
                      buttonText="{{ 'adminSession.downloadRecordingButton' | translate }}"
                      buttonIconLeft="far fa-download"
                      [buttonSize]="buttonSize.MEDIUM"
                      [disabled]="!isSessionFinished"
                      (click)="downloadRecord()" />
                  </div>
                }
              </div>
            }
          </div>
        }
      </div>
    </div>
  </form>
}

<div class="fixed right-0 bottom-0 left-0">
  <app-save-discard-actions
    [isShow]="canUpdate"
    [isLoading]="isUpdating()"
    (discard)="onDiscard()"
    (confirm)="onConfirm()">
  </app-save-discard-actions>
</div>

<p-toast>
  <ng-template
    let-message
    pTemplate="message">
    <app-toast
      [severity]="message.severity"
      [summary]="message.summary"
      [detail]="message.detail">
    </app-toast>
  </ng-template>
</p-toast>
