@if (loading()) {
  <app-loader></app-loader>
} @else {
  <div class="admin-ticket-form-container">
    <form [formGroup]="form">
      <!-- Ticket name & Details block -->
      <div class="form-block c-mt-8 c-mb-9">
        <div class="left-side">
          <h2 class="title">{{ 'adminCourseTicketForm.nameDetailsTitle' | translate }}</h2>
          <p class="description">
            {{ 'adminCourseTicketForm.nameDetailsDescription' | translate }}
          </p>
        </div>
        <div class="right-side">
          <!-- Name input -->
          <div class="form-field-block">
            <app-input-label
              labelType="required"
              for="name-input"
              text="adminCourseTicketForm.nameLabel">
            </app-input-label>
            <input
              class="custom-input-text custom-input-text-required"
              formControlName="name"
              id="name-input"
              pInputText
              type="text"
              placeholder="{{ 'adminCourseTicketForm.nameLabel' | translate }}" />
            <app-input-error-message
              [showMessage]="form.controls.name.invalid && form.controls.name.touched"
              errorMessage="{{ 'application.forms.required' | translate }}">
            </app-input-error-message>
          </div>

          <!-- Info input -->
          <div class="form-field-block c-mb-3">
            <app-input-label
              for="info-input"
              text="adminCourseTicketForm.infoLabel">
            </app-input-label>
            <input
              class="custom-input-text"
              formControlName="info"
              id="info-input"
              pInputText
              type="text"
              placeholder="{{ 'adminCourseTicketForm.infoLabel' | translate }}" />
          </div>

          <!-- QuantityTotal input -->
          <div class="form-field-block c-mb-3">
            <app-input-label
              labelType="required"
              for="quantityTotal-input"
              text="adminCourseTicketForm.quantityTotalLabel">
            </app-input-label>
            <input
              class="custom-input-text custom-input-text-required"
              formControlName="quantityTotal"
              id="quantityTotal-input"
              pInputText
              type="text"
              placeholder="{{ 'adminCourseTicketForm.quantityTotalLabel' | translate }}" />
            <app-input-error-message
              [showMessage]="
                form.controls.quantityTotal.invalid && form.controls.quantityTotal.touched
              "
              [errorMessage]="
                form.controls.quantityTotal.hasError('pattern')
                  ? ('application.forms.validNumbers' | translate)
                  : ('application.forms.required' | translate)
              ">
            </app-input-error-message>
          </div>

          <p-divider styleClass="custom-divider-horizontal"></p-divider>

          <!-- Published input -->
          <div class="form-field-input-switch-block c-mt-8">
            <p-inputSwitch
              class="custom-input-switch"
              formControlName="isPublished"
              inputId="publishTicket-input">
            </p-inputSwitch>
            <app-input-label
              labelType="switch-checkbox"
              for="publishTicket-input"
              text="adminCourseTicketForm.publishTicketLabel">
            </app-input-label>
          </div>
        </div>
      </div>

      <p-divider styleClass="custom-divider-horizontal"></p-divider>

      <!-- Duration & Price block -->
      <div class="form-block c-my-9">
        <div class="left-side">
          <h2 class="title">{{ 'adminCourseTicketForm.durationPriceTitle' | translate }}</h2>
          <p class="description">
            {{ 'adminCourseTicketForm.durationPriceDescription' | translate }}
          </p>
        </div>
        <div class="right-side">
          <div class="two-elements-block">
            <!-- StartDate input -->
            <div>
              <app-input-label
                labelType="required"
                for="start-date-input"
                text="adminCourseTicketForm.startDateLabel">
              </app-input-label>
              <p-calendar
                class="custom-calendar custom-calendar-required"
                formControlName="startDate"
                [monthNavigator]="true"
                [yearNavigator]="true"
                [minDate]="minDate()"
                [showIcon]="true"
                dateFormat="dd/mm/yy"
                yearRange="1900:2050"
                inputId="start-date-input"
                icon="far fa-calendar-day"
                placeholder="{{ 'adminCourseTicketForm.startDateLabel' | translate }}">
              </p-calendar>
              <app-input-error-message
                [showMessage]="form.controls.startDate.invalid && form.controls.startDate.touched"
                errorMessage="{{ 'application.forms.required' | translate }}">
              </app-input-error-message>
            </div>

            <!-- StartTime input -->
            <div>
              <app-input-label
                for="start-time-input"
                text="adminCourseTicketForm.timeLabel">
              </app-input-label>
              <p-calendar
                class="custom-calendar custom-calendar-time"
                formControlName="startTime"
                placeholder="{{ 'adminCourseTicketForm.timeLabel' | translate }}"
                inputId="start-time-input"
                [timeOnly]="true"
                [showIcon]="true"
                icon="far fa-clock">
              </p-calendar>
            </div>
          </div>

          <div class="two-elements-block">
            <!-- EndDate input -->
            <div>
              <app-input-label
                labelType="required"
                for="end-date-input"
                text="adminCourseTicketForm.endDateLabel">
              </app-input-label>
              <p-calendar
                class="custom-calendar custom-calendar-required"
                formControlName="endDate"
                [monthNavigator]="true"
                [yearNavigator]="true"
                [minDate]="minDate()"
                [showIcon]="true"
                dateFormat="dd/mm/yy"
                yearRange="1900:2050"
                inputId="end-date-input"
                icon="far fa-calendar-day"
                placeholder="{{ 'adminCourseTicketForm.endDateLabel' | translate }}">
              </p-calendar>
              <app-input-error-message
                [showMessage]="form.controls.endDate.invalid && form.controls.endDate.touched"
                errorMessage="{{ 'application.forms.required' | translate }}">
              </app-input-error-message>
            </div>

            <!-- EndTime input -->
            <div>
              <app-input-label
                for="end-time-input"
                text="adminCourseTicketForm.timeLabel">
              </app-input-label>
              <p-calendar
                class="custom-calendar custom-calendar-time custom-calendar-required"
                formControlName="endTime"
                placeholder="{{ 'adminCourseTicketForm.timeLabel' | translate }}"
                inputId="end-time-input"
                [timeOnly]="true"
                [showIcon]="true"
                icon="far fa-clock">
              </p-calendar>
            </div>
          </div>

          <!-- Paid input -->
          <div class="form-field-input-switch-block c-mb-4">
            <p-inputSwitch
              class="custom-input-switch"
              formControlName="isPaid"
              inputId="paid-ticket-input">
            </p-inputSwitch>
            <app-input-label
              labelType="switch-checkbox"
              for="paid-ticket-input"
              text="adminCourseTicketForm.paidTicketLabel">
            </app-input-label>
          </div>

          @if (form.controls.isPaid.value) {
            <div class="form-field-block c-mt-4">
              <app-input-label
                [labelType]="hasValidator('price') ? 'required' : 'usual'"
                for="price-input"
                text="adminCourseTicketForm.priceLabel">
              </app-input-label>
              <div class="p-input-icon-right">
                <input
                  class="custom-input-text"
                  [class.custom-input-text-required]="hasValidator('price')"
                  formControlName="price"
                  id="price-input"
                  pInputText
                  type="text"
                  placeholder="{{ 'adminCourseTicketForm.priceLabel' | translate }}" />
                <i
                  class="fa-solid"
                  [ngClass]="{
                    'fa-euro-sign': courseCurrency() === 'EUR',
                    'fa-dollar-sign': courseCurrency() === 'USD',
                    'fa-chf-sign': courseCurrency() === 'CHF',
                  }">
                </i>
              </div>
              <app-input-error-message
                [showMessage]="form.controls.price.invalid && form.controls.price.touched"
                [errorMessage]="
                  form.controls.price.hasError('min')
                    ? ('adminCourseTicketForm.minAmountErrorMessage' | translate)
                    : ('application.forms.required' | translate)
                ">
              </app-input-error-message>
            </div>
          }
        </div>
      </div>

      <p-divider styleClass="custom-divider-horizontal"></p-divider>

      <!-- Course Access block -->
      <div class="form-block c-my-9">
        <div class="left-side">
          <h2 class="title">{{ 'adminCourseTicketForm.courseAccessTitle' | translate }}</h2>
          <p class="description">
            {{ 'adminCourseTicketForm.courseAccessDescription' | translate }}
          </p>
        </div>
        <div class="right-side">
          <!-- Is show live events input -->
          <div class="form-field-input-switch-block">
            <p-inputSwitch
              class="custom-input-switch"
              formControlName="isShowLiveEvents"
              inputId="is-show-live-events-input">
            </p-inputSwitch>
            <app-input-label
              labelType="switch-checkbox"
              for="is-show-live-events-input"
              text="adminCourseTicketForm.grantAccessTitle">
            </app-input-label>
          </div>
        </div>
      </div>

      <p-divider styleClass="custom-divider-horizontal"></p-divider>

      <!-- Advanced Settings block -->
      <div class="form-block c-mt-9 c-mb-4">
        <div class="left-side">
          <h2 class="title">{{ 'adminCourseTicketForm.advancedSettingsTitle' | translate }}</h2>
          <p class="description">
            {{ 'adminCourseTicketForm.advancedSettingsDescription' | translate }}
          </p>
        </div>
        <div class="right-side">
          <!-- Description textarea -->
          <div class="form-field-block">
            <app-input-label text="adminCourseTicketForm.description"></app-input-label>
            <p-editor
              styleClass="custom-editor"
              formControlName="description"
              placeholder="{{ 'adminCourseTicketForm.description' | translate }}">
            </p-editor>
          </div>
        </div>
      </div>
    </form>
  </div>
}

<div class="save-discard-actions-container">
  <app-save-discard-actions
    [isShow]="canUpdate"
    [isLoading]="isUpdating()"
    (discard)="onDiscard()"
    (confirm)="onConfirm()">
  </app-save-discard-actions>
</div>

<p-toast>
  <ng-template
    let-message
    pTemplate="message">
    <app-toast
      [severity]="message.severity"
      [summary]="message.summary"
      [detail]="message.detail">
    </app-toast>
  </ng-template>
</p-toast>
