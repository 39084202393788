@if (loading()) {
  <app-loader></app-loader>
} @else {
  <div class="admin-brands-table-container">
    <admin-table-header-navbar [title]="'adminBrands.title' | translate">
      <app-button-list
        [items]="btnListItems()"
        btnLabel="shared.add">
      </app-button-list>
    </admin-table-header-navbar>

    <div class="c-my-4">
      <p-divider styleClass="custom-divider-horizontal"></p-divider>
    </div>

    <admin-table-filter-navbar
      [filters]="tableFilters()"
      (onFiltersChange)="filtersChange($event)"
      (onSearchTermChange)="searchValue.set($event)">
    </admin-table-filter-navbar>

    <admin-table
      [loading]="loadingEntries()"
      [columns]="columns()"
      [entries]="entries()"
      [searchValue]="searchValue()"
      [searchFilterFields]="searchFilterFields()"
      [paginator]="true"
      [lazy]="false"
      emptyStateKey="adminBrands.emptyState"
      emptyStateIcon="fak fa-lox-crown"
      (rowClicked)="onRowClicked($event)">
      <ng-template
        let-entry
        epTemplateKey="nameCell">
        <div class="name-cell">
          <div>
            @if (entry.logo) {
              <img
                class="banner"
                [defaultImage]="imgPreloader()"
                [lazyLoad]="entry.logo | imageSize" />
            } @else {
              <div class="placeholder-container">
                <img
                  class="placeholder"
                  [src]="logoAvatar() | imageSize" />
              </div>
            }
          </div>
          <p class="brand-name">{{ entry.name }}</p>
        </div>
      </ng-template>

      <ng-template
        let-entry
        epTemplateKey="actionsCell">
        <div class="table-btn-actions-container">
          <app-button
            [buttonType]="buttonType().ROUND"
            [buttonSize]="buttonSize().MEDIUM"
            [buttonStyle]="buttonStyle().FILLED_SECONDARY"
            buttonRoundIcon="far fa-pen-to-square"
            (click)="edit($event, entry)" />
          <app-button
            [buttonType]="buttonType().ROUND"
            [buttonSize]="buttonSize().MEDIUM"
            [buttonStyle]="buttonStyle().FILLED_DANGER"
            buttonRoundIcon="far fa-trash-can"
            (click)="removeBrand($event, entry)" />
        </div>
      </ng-template>
    </admin-table>
  </div>
}
