import { Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IAsset } from 'src/app/core/models';
import { RecordingTypes } from 'src/app/core/enums';
import { DailyCoBaseRoomComponent } from '../daily-co-base-room/daily-co-base-room.component';

@Component({
  selector: 'app-daily-co-asset-room',
  standalone: true,
  imports: [CommonModule, DailyCoBaseRoomComponent],
  templateUrl: './daily-co-asset-room.component.html',
  styleUrls: ['./daily-co-asset-room.component.scss'],
})
export class DailyCoAssetRoomComponent extends DailyCoBaseRoomComponent implements OnInit {
  @ViewChild('iframe', { static: true, read: ElementRef }) iframe: ElementRef;

  asset: IAsset;

  constructor(injector: Injector) {
    super(injector);
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();
    this.asset = this.config?.data?.asset;
    if (!this.asset) {
      this.ref.close();
    }

    this.createDailyIframeWrap(this.iframe.nativeElement);
    this.setDailyTheme();
    this.startListeningEvents();

    const token: string = this.asset.instructors.includes(this.usersStore.userId)
      ? await this.getAdminToken(
          this.asset.eventAssetMeetingRoomName,
          this.asset.isRecording,
          RecordingTypes.CLOUD,
        )
      : await this.getParticipantToken(this.asset.eventAssetMeetingRoomName);

    this.openDaily(this.asset.eventAssetMeetingRoomUrl, token);
  }
}
