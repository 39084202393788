import { BehaviorSubject } from 'rxjs';
import { IBrand } from './../../models/brand/brand.model';
import { Injectable } from '@angular/core';
import { makeAutoObservable, observable } from 'mobx';

@Injectable()
export class BrandsStore {
  public brandsMap: { [key: string]: IBrand } = {};
  public bookmarkedIds: string[] = [];
  public adminBrand: IBrand = null;
  public brand: IBrand = null;

  private refreshBrandMembersSubject = new BehaviorSubject(null);
  refreshBrandMembers = this.refreshBrandMembersSubject.asObservable();

  constructor() {
    makeAutoObservable(this, {
      brandsMap: observable.shallow,
    });
  }

  public refreshMembers() {
    this.refreshBrandMembersSubject.next(true);
  }

  public setBrand(brand: IBrand): void {
    this.brand = brand;
  }

  public unsetBrand(): void {
    this.adminBrand = null;
  }

  public setAdminBrand(brand: IBrand): void {
    this.adminBrand = brand;
  }

  public unsetAdminBrand(): void {
    this.adminBrand = null;
  }

  public setBrands(brands: IBrand[]): void {
    for (const brand of brands) {
      this.brandsMap[brand.id] = brand;
    }
  }

  public get brands(): IBrand[] {
    return Object.keys(this.brandsMap).map((id) => this.brandsMap[id]);
  }

  public setBookmarkedIds(ids: string[]): void {
    this.bookmarkedIds = ids;
  }

  public pushBookmarkedId(id: string): void {
    if (!this.bookmarkedIds.includes(id)) {
      this.bookmarkedIds.push(id);
    }
  }

  public popBookmarkedId(id: string): void {
    this.bookmarkedIds = this.bookmarkedIds.filter((_id) => _id !== id);
  }

  public reset(): void {
    this.brandsMap = {};
    this.bookmarkedIds = [];
  }
}
