@if (loading()) {
  <app-loader></app-loader>
} @else {
  <div
    class="admin-panel-standard-container"
    [class.admin-panel-container-without-header-and-sidebar]="isHideHeaderAndSidebar">
    @if (!isHideHeaderAndSidebar) {
      <aside class="sidebar">
        @if (selectedHub) {
          <app-admin-sidebar-with-hub
            [logo]="logo()"
            [hub]="selectedHub"
            [user]="currentUser"
            [userHub]="currentUserHub()">
          </app-admin-sidebar-with-hub>
        } @else {
          <app-admin-sidebar-without-hub [logo]="logo()"></app-admin-sidebar-without-hub>
        }
      </aside>

      <header>
        <app-admin-header (createHub)="onCreateHub()"></app-admin-header>
      </header>
    }

    <section>
      <router-outlet></router-outlet>
    </section>
  </div>

  <div class="admin-panel-small-container">
    <h1>{{ 'adminPanel.cupTitle' | translate }}</h1>
    <p>{{ 'adminPanel.cupText' | translate }}</p>
  </div>
}

<p-toast>
  <ng-template
    let-message
    pTemplate="message">
    <app-toast
      [severity]="message.severity"
      [summary]="message.summary"
      [detail]="message.detail">
    </app-toast>
  </ng-template>
</p-toast>

<p-toast
  key="copyInvite"
  position="bottom-center">
  <ng-template
    let-message
    pTemplate="message">
    <app-toast
      [severity]="message.severity"
      [summary]="message.summary"
      [detail]="message.detail">
    </app-toast>
  </ng-template>
</p-toast>
