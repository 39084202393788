<div>
  <app-input-label
    labelType="required"
    for="name-input"
    text="Email">
  </app-input-label>
  <input
    class="custom-input-text custom-input-text-required"
    id="name-input"
    [formControl]="userEmail"
    pInputText
    type="text"
    placeholder="Email" />
  <app-input-error-message
    [showMessage]="userEmail.invalid && userEmail.touched"
    [errorMessage]="errorMessage">
  </app-input-error-message>
</div>
<div class="text-right">
  <button
    class="primary-text-color"
    pButton
    type="button"
    label="Submit"
    [icon]="isUpdating ? 'pi pi-spin pi-spinner' : null"
    [disabled]="isBtnDisabled"
    (click)="onDeleteUserData()"></button>
</div>

<p-toast>
  <ng-template
    let-message
    pTemplate="message">
    <app-toast
      [severity]="message.severity"
      [summary]="message.summary"
      [detail]="message.detail">
    </app-toast>
  </ng-template>
</p-toast>
