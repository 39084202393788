<div class="header">
  <div class="title">
    {{ 'adminEventBrands.attachBrands' | translate }}
  </div>
  <span
    *ngIf="!appStore.generalSystemSettings.enableEncryption"
    class="search-input p-input-icon-left"
    [ngClass]="{ 'p-input-icon-right': filterSearch.value != '' }">
    <i class="far fa-search"></i>
    <input
      class="custom-input-text"
      type="text"
      #filterSearch
      name="filterSearch"
      (input)="brandSearchChanged($event.target.value)"
      pInputText
      placeholder="{{ 'adminTable.inputSearchPlaceholder' | translate }}" />
    <i
      *ngIf="filterSearch.value != ''"
      class="reset-btn far fa-times-circle"
      (click)="filterSearch.value = ''"></i>
  </span>
  <button
    class="attach primary-text-color"
    pButton
    pRipple
    label="{{ 'adminEventBrands.attach' | translate }}"
    [icon]="attachLoading ? 'pi pi-spin pi-spinner' : null"
    [disabled]="!selectedEntries.length"
    (click)="attach()"></button>
</div>
<div class="container">
  <div class="filters">
    <div class="title">{{ 'adminUsers.tagsFilterLabel' | translate }}</div>
    <span
      class="search-input p-input-icon-left"
      [ngClass]="{ 'p-input-icon-right': filterSearch.value != '' }">
      <i class="far fa-search"></i>
      <input
        class="custom-input-text"
        type="text"
        #filterSearch
        name="filterSearch"
        (input)="tagSearchChanged($event.target.value)"
        pInputText
        placeholder="{{ 'adminTable.inputSearchPlaceholder' | translate }}" />
      <i
        *ngIf="filterSearch.value != ''"
        class="reset-btn far fa-times-circle"
        (click)="filterSearch.value = ''"></i>
    </span>
    <div class="tag-list">
      <p-checkbox
        *ngFor="let tag of filteredTags"
        class="custom-input-checkbox-sm c-my-2 c-ml-1"
        (ngModelChange)="tagSelectionChanged($event)"
        [value]="tag"
        [label]="tag.title"
        [(ngModel)]="selectedTags">
      </p-checkbox>
    </div>
  </div>
  <admin-table
    [lazyLoading]="loadingEntries"
    [columns]="columns"
    [entries]="entries"
    [pageSize]="pageSize"
    [(selectedEntries)]="selectedEntries"
    checkBoxSelection="true"
    [totalRecords]="totalRecords"
    [emptyStateKey]="'adminBrands.emptyState'"
    [emptyStateIcon]="'fak fa-lox-crown'"
    (lazyLoad)="fetchEntries($event)">
    <ng-template
      let-entry
      epTemplateKey="logoCell">
      <img
        *ngIf="entry.logo"
        style="height: 4rem; width: auto"
        [defaultImage]="imgPreloader"
        [lazyLoad]="entry.logo | imageSize" />
      <img
        *ngIf="!entry.logo"
        style="height: 4rem; width: auto"
        [src]="logoAvatar" />
    </ng-template>
  </admin-table>
</div>

<p-toast key="user">
  <ng-template
    let-message
    pTemplate="message">
    <app-toast
      [severity]="message.severity"
      [summary]="message.summary"
      [detail]="message.detail">
    </app-toast>
  </ng-template>
</p-toast>
