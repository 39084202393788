<div
  *ngIf="isShow || isLoading"
  class="container"
  [@fadeInOut]>
  <button
    *ngIf="!isLoading"
    class="discard"
    (click)="onDiscard()">
    {{ 'shared.discard' | translate }}
  </button>
  <button
    [@rotateAndReplace]="buttonState"
    [@buttonHover]="buttonHoverState"
    class="confirm"
    [class.spinner]="buttonState !== 'initial'"
    [disabled]="buttonState !== 'initial'"
    (mouseenter)="buttonHoverState = 'hovered'"
    (mouseleave)="buttonHoverState = 'initial'"
    (click)="onConfirm()">
    <ng-container *ngIf="buttonState === 'initial'">
      {{ 'shared.save' | translate }}
    </ng-container>
    <ng-container *ngIf="buttonState !== 'initial'">
      <i class="fa-regular fa-spinner fa-spin-pulse"></i>
    </ng-container>
  </button>
</div>
