export interface IEventSubscription {
  id: string;
  eventId: string;
  subscriptionId: string;
  subscriptionName: string;
  createdAt: any;
  createdBy: string;
  updatedAt: any;
  updatedBy: string;
  platform: string;
  price?: number;
  currency?: string;
  stripePriceId?: string;
  orderIndex: number;
  isDefault: boolean;
}
