<div (click)="menu.toggle($event)">
  <app-button
    buttonText="{{ btnLabel | translate }}"
    buttonIconLeft="far fa-plus"
    buttonIconRight="far fa-chevron-down"
    [buttonSize]="buttonSize.MEDIUM" />
</div>
<p-menu
  #menu
  [popup]="true"
  [model]="items"
  styleClass="p-custom-menu"
  appendTo="body">
</p-menu>
