import * as dayjs from 'dayjs';
import { Component, inject, OnInit, signal } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { noop } from 'rxjs';
import { Carousel, CarouselModule } from 'primeng/carousel';

import { MixpanelService, EventsService } from 'src/app/core/services';
import { SharedModule } from 'src/app/shared';
import { UsersStore, HubsStore } from 'src/app/core/stores';
import { IEvent } from 'src/app/core/models';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-events',
  standalone: true,
  imports: [RouterModule, TranslateModule, CarouselModule, SharedModule],
  templateUrl: './events.component.html',
  styleUrl: './events.component.scss',
})
export class EventsComponent implements OnInit {
  loading = signal(true);
  featuredEvents = signal<IEvent[]>([]);
  currentFeaturedEventsIndex = signal(0);

  #sort = signal<'asc' | 'desc'>('asc');
  #route = inject(ActivatedRoute);
  #router = inject(Router);
  #hubsStore = inject(HubsStore);
  #usersStore = inject(UsersStore);
  #eventsService = inject(EventsService);
  #mixpanelService = inject(MixpanelService);

  constructor() {
    //"fix" the vertical scroll problem on mobile
    Carousel.prototype.onTouchMove = noop;
  }

  get hubUrl(): string {
    return this.#hubsStore.useHubUrl;
  }

  async ngOnInit(): Promise<void> {
    this.#route.queryParams.subscribe(async (params) => {
      this.#sort.set(params.sort ? 'desc' : 'asc');
    });

    const featuredEvents = await this.#eventsService.getFeatured(this.#hubsStore.hub?.id);
    this.featuredEvents.set(featuredEvents);
    this.loading.set(false);
    this.sendDiscoveryEvent();
  }

  toggleSort(): void {
    this.#sort.update((sort: 'asc' | 'desc') => (sort === 'asc' ? 'desc' : 'asc'));
    if (this.#sort() === 'asc') {
      this.#router.navigate([], { relativeTo: this.#route, queryParams: {} });
    } else {
      this.#router.navigate([], { relativeTo: this.#route, queryParams: { sort: this.#sort() } });
    }
  }

  eventDateForShowing(event: IEvent): string {
    if (dayjs(event.start.toDate()).isSame(event.end.toDate(), 'day')) {
      return dayjs(event.start.toDate()).format('DD.MM.YYYY');
    } else {
      return `${dayjs(event.start.toDate()).format('DD.MM.YYYY')} - ${dayjs(event.end.toDate()).format('DD.MM.YYYY')}`;
    }
  }

  async onEvent(event: IEvent): Promise<void> {
    this.#router.navigate([`${this.#hubsStore.useHubUrl}/events/${event.id}`]);
  }

  setCurrentFeaturedEventsIndex({ page }: { page: number }): void {
    this.currentFeaturedEventsIndex.set(page);
  }

  private async sendDiscoveryEvent(): Promise<void> {
    if (!this.#usersStore.user) {
      return;
    }
    const response: any = await this.#eventsService.getDiscoveryEventsReport(
      this.#usersStore.user?.id,
    );
    this.#mixpanelService.eventDiscovery(
      response.data.allPublishEventsCount,
      response.data.invitedEventsForCurrentUser,
    );
  }
}
