import * as moment from 'moment';
import { Component } from '@angular/core';
import { IEvent, ISession, IStage } from 'src/app/core/models';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { merge, Subject } from 'rxjs';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';

import { EventsStore } from 'src/app/core/stores';
import { SessionsService, StagesService } from 'src/app/core/services';
import { debounceTime, filter, takeUntil, tap } from 'rxjs/operators';
import { updateTime } from 'src/app/core/utils';
import { Timestamp } from 'src/app/firebase';
import { ButtonSize, ButtonStyle } from 'src/app/standalone';

@Component({
  selector: 'app-new-session',
  templateUrl: './new-session.component.html',
  styleUrls: ['./new-session.component.scss'],
})
export class NewSessionComponent {
  loading = true;
  isUpdating = false;
  eventStages: IStage[] = [];
  minDate: Date = moment().toDate();
  minEndTime: Date = moment().add(1, 'hours').toDate();
  form: UntypedFormGroup;
  createAnotherControl = new UntypedFormControl(null);
  buttonStyle = ButtonStyle;
  buttonSize = ButtonSize;

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private messageService: MessageService,
    private eventsStore: EventsStore,
    private translateService: TranslateService,
    private stagesService: StagesService,
    private sessionsService: SessionsService,
    private ref: DynamicDialogRef,
  ) {}

  get event(): IEvent {
    return this.eventsStore.adminEvent;
  }

  get canUpdate(): boolean {
    return !this.isUpdating && this.form.valid && this.form.dirty;
  }

  async ngOnInit(): Promise<void> {
    this.createForm();
    const eventStagesResponse = await this.stagesService.getAllEventStages(this.event.id);

    this.eventStages = eventStagesResponse;

    this.form.updateValueAndValidity();
    this.form.valueChanges.subscribe(() => this.form.markAsDirty());

    this.loading = false;

    const startTimeValue$ = this.form.controls.start.valueChanges;
    const endTimeValue$ = this.form.controls.end.valueChanges;

    merge(startTimeValue$, endTimeValue$)
      .pipe(
        debounceTime(300),
        filter((value) => !!value),
        tap(() => {
          const start = updateTime(this.form.controls.date.value, this.form.controls.start.value);
          const end = updateTime(this.form.controls.date.value, this.form.controls.end.value);

          if (moment(start).isAfter(end)) {
            const validEnd = start.add(1, 'hours');
            this.form.controls.end.setValue(validEnd.toDate(), { emitEvent: false });
          }
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();
  }

  onCloseDialog(): void {
    this.ref.close();
  }

  async create(): Promise<void> {
    this.isUpdating = true;

    const session = this.form.getRawValue();
    const startDate = updateTime(session.date, session.start);
    const endDate = updateTime(session.date, session.end);

    session.start = Timestamp.fromMillis(startDate.toDate().getTime());
    session.end = Timestamp.fromMillis(endDate.toDate().getTime());
    try {
      const sessionPayload: ISession = {
        id: null,
        title: session.title,
        _title_: null,
        space: false,
        date: session.date,
        start: session.start,
        end: session.end,
        description: null,
        shortDescription: null,
        banner: null,
        stageId: session.stageId,
        speakers: [],
        brands: [],
        tags: [],
        videolink: null,
        featuredSession: false,
        updatedAt: null,
        updatedBy: null,
        createdAt: null,
        createdBy: null,
      };

      await this.sessionsService.create(this.event.id, sessionPayload);
      this.showToastMessage('success', 'adminSession.sessionSuccessfullyCreated');

      this.isUpdating = false;
      if (this.createAnotherControl.value) {
        this.form.reset({
          date: this.minDate,
          start: this.minDate,
          end: this.minEndTime,
        });
        return;
      }
      this.ref.close(sessionPayload);
    } catch (error) {
      console.log(error);
      this.showToastMessage('error', 'adminSession.errorCreatingSession');
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private createForm(): void {
    this.form = new UntypedFormGroup({
      title: new UntypedFormControl(null, Validators.required),
      date: new UntypedFormControl(this.minDate, Validators.required),
      start: new UntypedFormControl(this.minDate, Validators.required),
      end: new UntypedFormControl(this.minEndTime, Validators.required),
      stageId: new UntypedFormControl(null),
    });
  }

  private showToastMessage(severity: 'success' | 'error', detail: string): void {
    this.messageService.add({
      severity: severity,
      summary: this.translateService.instant(severity),
      detail: this.translateService.instant(detail),
      styleClass: 'custom-toast',
    });
  }
}
