import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

import { ChatMessageAttachment } from 'src/app/core/models/chat';
import { ChatService } from 'src/app/core/services/chat';

@Component({
  selector: 'app-chat-load-attachment',
  templateUrl: './chat-load-attachment.component.html',
  styleUrls: ['./chat-load-attachment.component.scss'],
})
export class ChatLoadAttachmentComponent {
  private static MAX_FILE_SIZE = 5000000;
  private static SUPPORTED_TYPES = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'];

  inError = false;
  isUploading = false;

  isDragOver = false;

  @ViewChild('input', { static: true }) input?: ElementRef<HTMLInputElement>;

  @Output() dismiss = new EventEmitter<ChatMessageAttachment | undefined>();

  @Input() conversationId?: string;

  constructor(private chatService: ChatService) {}

  async onSelectImageFile(event: any): Promise<void> {
    const file: File = event.target.files[0];
    this.handleFile(file);
  }

  selectFileAction(): void {
    this.input?.nativeElement.click();
  }

  onDropFileAction(event): void {
    event.preventDefault();
  }

  @HostListener('dragover', ['$event'])
  onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();

    this.isDragOver = true;
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();

    this.isDragOver = false;
  }

  @HostListener('drop', ['$event'])
  public ondrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = false;

    const files = event.dataTransfer.files;
    if (files.length > 0) {
      this.handleFile(files[0]);
    }
  }

  cancelAction(): void {
    this.dismiss.next(undefined);
  }

  private async handleFile(file: File): Promise<void> {
    this.inError = false;

    if (
      file.size > ChatLoadAttachmentComponent.MAX_FILE_SIZE ||
      !ChatLoadAttachmentComponent.SUPPORTED_TYPES.includes(file.type)
    ) {
      this.inError = true;
      return;
    }

    this.isUploading = true;
    const publicFilePath = await this.chatService.uploadAttachment('this.conversationId', file);
    this.dismiss.next({
      url: publicFilePath,
      name: file.name,
      type: file.type === 'application/pdf' ? 'pdf' : 'image',
      size: file.size,
    });

    this.isUploading = false;
  }
}
