@if (loading()) {
  <app-loader></app-loader>
} @else {
  <div class="page-container">
    <header class="c-mb-4">
      <h1 class="admin-page-title">{{ 'adminEvent.menuItemDetails' | translate }}</h1>
    </header>

    <p-divider styleClass="custom-divider-horizontal"></p-divider>

    <form [formGroup]="form">
      <!-- Event name & Details block -->
      <div class="form-block c-mt-8 c-mb-9">
        <div class="left-side">
          <h2 class="title">{{ 'adminEventSettings.nameDetailsTitle' | translate }}</h2>
          <p class="description">{{ 'adminEventSettings.nameDetailsDescription' | translate }}</p>
        </div>
        <div class="right-side">
          <!-- Title input -->
          <div class="form-field-block">
            <app-input-label
              labelType="required"
              for="title-input"
              text="adminEventSettings.titleLabel">
            </app-input-label>
            <input
              class="custom-input-text custom-input-text-required"
              formControlName="title"
              id="title-input"
              pInputText
              type="text"
              maxlength="{{ getMaxLengthValue('title') }}"
              placeholder="{{ 'adminEventSettings.titlePlaceholder' | translate }}" />
            <app-input-error-message
              [showMessage]="form.controls.title.invalid && form.controls.title.touched"
              errorMessage="{{ 'application.forms.required' | translate }}">
            </app-input-error-message>
          </div>

          <!-- Accounting number input -->
          <div class="form-field-block c-mb-4">
            <app-input-label
              for="accounting-number-input"
              text="adminEventSettings.accountingNumberLabel">
            </app-input-label>
            <input
              class="custom-input-text custom-input-text-required"
              formControlName="accountingNumber"
              id="accounting-number-input"
              pInputText
              type="text"
              placeholder="{{ 'adminEventSettings.accountingNumberPlaceholder' | translate }}" />
          </div>

          <!-- Tagline input -->
          <div class="form-field-block">
            <app-input-label
              for="tagline-input"
              text="adminEventSettings.taglineLabel">
            </app-input-label>
            <input
              class="custom-input-text"
              formControlName="tagline"
              id="tagline-input"
              pInputText
              type="text"
              placeholder="{{ 'adminEventSettings.taglinePlaceholder' | translate }}" />
            <app-input-error-message
              [showMessage]="form.controls.tagline.invalid && form.controls.tagline.touched"
              errorMessage="{{ 'application.forms.msxLengthErrorText' | translate }}">
            </app-input-error-message>
          </div>

          <!-- Link input -->
          <div class="form-field-block c-mb-4">
            <app-input-label
              labelType="required"
              for="link-input"
              text="adminEventSettings.linkSuffixLabel">
            </app-input-label>
            <div class="p-inputgroup custom-input-group">
              <span class="p-inputgroup-addon">{{ linkPrefix }}</span>
              <input
                formControlName="link"
                id="link-input"
                pInputText
                type="text"
                placeholder="{{ 'adminEventSettings.linkSuffixPlaceholder' | translate }}" />
              <i
                *ngIf="form.controls.link.value?.length"
                class="far fa-copy"
                [pTooltip]="!copyIsClicked() ? tooltipText : null"
                tooltipPosition="top"
                tooltipStyleClass="custom-tooltip"
                (mouseleave)="copyIsClicked.set(false)"
                (click)="copyToClipboard()">
              </i>
              <div
                *ngIf="form.controls.link.value?.length && copyIsClicked()"
                class="tooltip-copied">
                {{ tooltipText }}
              </div>
            </div>
            <app-input-error-message
              [showMessage]="form.controls.link.invalid && form.controls.link.dirty"
              [errorMessage]="linkErrorText">
            </app-input-error-message>
          </div>

          <!-- User input -->
          <div class="form-field-block">
            <app-input-label
              labelType="required"
              for="user-input"
              text="adminEventSettings.ownerLabel">
            </app-input-label>
            <p-dropdown
              class="custom-dropdown custom-dropdown-required"
              panelStyleClass="custom-dropdown-panel"
              [options]="usersForShowing()"
              [filter]="true"
              filterBy="name"
              optionValue="id"
              formControlName="user"
              inputId="user-input"
              appendTo="body"
              [virtualScroll]="true"
              [virtualScrollItemSize]="virtualScrollItemSize()"
              placeholder="{{ 'adminEventSettings.ownerLabel' | translate }}">
              <ng-template
                let-user
                pTemplate="selectedItem">
                {{ user?.firstName }} {{ user?.lastName }}
              </ng-template>
              <ng-template
                let-user
                pTemplate="item">
                {{ user?.firstName }} {{ user?.lastName }}
              </ng-template>
            </p-dropdown>
            <app-input-error-message
              [showMessage]="form.controls.user.invalid && form.controls.user.touched"
              errorMessage="{{ 'application.forms.required' | translate }}">
            </app-input-error-message>
          </div>
        </div>
      </div>

      <p-divider styleClass="custom-divider-horizontal"></p-divider>

      <!-- Duration & Location block -->
      <div class="form-block c-my-9">
        <div class="left-side">
          <h2 class="title">{{ 'adminEventSettings.durationLocationTitle' | translate }}</h2>
          <p class="description">{{ 'adminEventSettings.durationLocationDesc' | translate }}</p>
        </div>
        <div class="right-side">
          <div class="two-elements-block">
            <!-- Start Date input -->
            <div>
              <app-input-label
                labelType="required"
                for="start-date-input"
                text="adminEventSettings.startDateLabel">
              </app-input-label>
              <p-calendar
                class="custom-calendar custom-calendar-required"
                formControlName="start"
                [monthNavigator]="true"
                [yearNavigator]="true"
                [showIcon]="true"
                dateFormat="dd/mm/yy"
                yearRange="1900:2050"
                inputId="start-date-input"
                icon="far fa-calendar-day"
                placeholder="{{ 'adminEventSettings.startDateLabel' | translate }}">
              </p-calendar>
              <app-input-error-message
                [showMessage]="form.controls.start.invalid && form.controls.start.touched"
                errorMessage="{{ 'application.forms.required' | translate }}">
              </app-input-error-message>
            </div>

            <!-- Start Time input -->
            <div>
              <app-input-label
                labelType="required"
                for="start-time-input"
                text="adminTicket.timeLabel">
              </app-input-label>
              <p-calendar
                class="custom-calendar custom-calendar-required custom-calendar-time"
                formControlName="startTime"
                placeholder="{{ 'adminTicket.timeLabel' | translate }}"
                inputId="start-time-input"
                [timeOnly]="true"
                [showIcon]="true"
                icon="fak fa-lox-calendar"
                appDateMask>
              </p-calendar>
              <app-input-error-message
                [showMessage]="form.controls.startTime.invalid && form.controls.startTime.touched"
                errorMessage="{{ 'application.forms.required' | translate }}">
              </app-input-error-message>
            </div>
          </div>

          <div class="two-elements-block">
            <!-- End Date input -->
            <div>
              <app-input-label
                labelType="required"
                for="end-date-input"
                text="adminEventSettings.endDateLabel">
              </app-input-label>
              <p-calendar
                class="custom-calendar custom-calendar-required"
                formControlName="end"
                [monthNavigator]="true"
                [yearNavigator]="true"
                [showIcon]="true"
                [minDate]="endDateMin()"
                dateFormat="dd/mm/yy"
                yearRange="1900:2050"
                inputId="end-date-input"
                icon="far fa-calendar-day"
                placeholder="{{ 'adminEventSettings.endDateLabel' | translate }}">
              </p-calendar>
              <app-input-error-message
                [showMessage]="form.controls.end.invalid && form.controls.end.touched"
                errorMessage="{{ 'application.forms.required' | translate }}">
              </app-input-error-message>
            </div>

            <!-- End Time input -->
            <div>
              <app-input-label
                labelType="required"
                for="end-time-input"
                text="adminTicket.timeLabel">
              </app-input-label>
              <p-calendar
                class="custom-calendar custom-calendar-required custom-calendar-time"
                formControlName="endTime"
                placeholder="{{ 'adminTicket.timeLabel' | translate }}"
                inputId="end-time-input"
                [timeOnly]="true"
                [showIcon]="true"
                icon="far fa-clock">
              </p-calendar>
              <app-input-error-message
                [showMessage]="form.controls.endTime.invalid && form.controls.endTime.touched"
                errorMessage="{{ 'application.forms.required' | translate }}">
              </app-input-error-message>
            </div>
          </div>

          <div class="two-elements-block">
            <!-- Location input -->
            <div>
              <app-input-label
                for="location-input"
                text="adminEventSettings.locationLabel">
              </app-input-label>
              <input
                class="custom-input-text"
                formControlName="location"
                id="location-input"
                pInputText
                type="text"
                placeholder="{{ 'adminEventSettings.locationLabel' | translate }}" />
            </div>

            <!-- Timezone input -->
            <div>
              <app-input-label
                labelType="required"
                for="timezone-input"
                text="adminNewEvent.timeZoneLabel">
              </app-input-label>
              <p-dropdown
                class="custom-dropdown custom-dropdown-required"
                panelStyleClass="custom-dropdown-panel"
                [filter]="true"
                [options]="timezoneOptionItems()"
                optionLabel="name"
                formControlName="timezone"
                inputId="timezone-input"
                placeholder="{{ 'adminNewEvent.timeZoneLabel' | translate }}">
              </p-dropdown>
              <app-input-error-message
                [showMessage]="form.controls.timezone.invalid && form.controls.timezone.touched"
                errorMessage="{{ 'adminNewEvent.timeZoneReq' | translate }}">
              </app-input-error-message>
            </div>
          </div>
        </div>
      </div>

      <p-divider styleClass="custom-divider-horizontal"></p-divider>

      <!-- Description & tags block -->
      <div class="form-block c-my-9">
        <div class="left-side">
          <h2 class="title">{{ 'adminEventSettings.descTagsTitle' | translate }}</h2>
          <p class="description">{{ 'adminEventSettings.descTagsDescription' | translate }}</p>
        </div>
        <div class="right-side">
          <!-- Description textarea -->
          <div class="form-field-block c-mb-4">
            <app-input-label text="adminEventSettings.descriptionLabel"></app-input-label>
            <p-editor
              styleClass="custom-editor"
              formControlName="description"
              placeholder="{{ 'adminEventSettings.descriptionPlaceholder' | translate }}">
            </p-editor>
          </div>

          <!-- Short description textarea -->
          <div class="form-field-block c-mb-4">
            <app-input-label text="adminEventSettings.shortDescriptionTitle"></app-input-label>
            <textarea
              class="custom-input-textarea resize-none"
              [rows]="3"
              pInputTextarea
              formControlName="shortDescription"
              autoResize="autoResize"
              placeholder="{{ 'adminEventSettings.shortDescriptionPlaceholder' | translate }}">
            </textarea>
          </div>

          <!-- Tags multi select -->
          <div class="form-field-block">
            @if (tags()?.length) {
              <app-tags-multi-list
                [possibleTags]="tags()"
                [selectedTags]="event?.tags"
                [adminTheme]="true"
                (onSelected)="selectTags($event)">
              </app-tags-multi-list>
            } @else {
              <span>
                {{ 'adminEventSettings.noTagsAvailable' | translate }}
              </span>
            }
          </div>
        </div>
      </div>
    </form>
  </div>
}

<div class="save-discard-actions-container">
  <app-save-discard-actions
    [isShow]="canUpdate"
    [isLoading]="isUpdating()"
    (discard)="onDiscard()"
    (confirm)="onConfirm()">
  </app-save-discard-actions>
</div>

<p-toast>
  <ng-template
    let-message
    pTemplate="message">
    <app-toast
      [severity]="message.severity"
      [summary]="message.summary"
      [detail]="message.detail">
    </app-toast>
  </ng-template>
</p-toast>
