import { IEventRegistrationField } from './event-registration-field.model';

export interface IEventRegistrationForm {
  fields: IEventRegistrationField[];
  enableGroupCheckboxes: boolean;
  allowSkipRegistration: boolean;
  isGlobalSignup: boolean;
  isGlobalTags: boolean;
  isShowProfileAndTags: boolean;
  isShowEventTags: boolean;
  isShowProfileAppearance: boolean;
  isShowConsents: boolean;
  isShowAccommodationTransfer: boolean;
  isEventCode: boolean;
  eventCode: string;
  isRsvpRegistration: boolean;
  isEventTagsMandatory?: boolean;
}

export const defaultEventRegistrationForm = {
  fields: [
    {
      fieldName: 'company',
      isRequired: true,
      isVisible: true,
    },
    {
      fieldName: 'industry',
      isRequired: true,
      isVisible: true,
    },
    {
      fieldName: 'subIndustry',
      isRequired: true,
      isVisible: true,
    },
    {
      fieldName: 'areaOfActivity',
      isRequired: true,
      isVisible: true,
    },
    {
      fieldName: 'optionalPosition',
      isRequired: true,
      isVisible: true,
    },
    {
      fieldName: 'companySize',
      isRequired: true,
      isVisible: true,
    },
    {
      fieldName: 'position',
      isRequired: true,
      isVisible: true,
    },
    {
      fieldName: 'street',
      isRequired: true,
      isVisible: true,
    },
    {
      fieldName: 'department',
      isRequired: false,
      isVisible: true,
    },
    {
      fieldName: 'postcode',
      isRequired: true,
      isVisible: true,
    },
    {
      fieldName: 'city',
      isRequired: true,
      isVisible: true,
    },
    {
      fieldName: 'country',
      isRequired: true,
      isVisible: true,
    },
    {
      fieldName: 'phone',
      isRequired: true,
      isVisible: true,
    },
    {
      fieldName: 'mobile',
      isRequired: false,
      isVisible: false,
    },
    {
      fieldName: 'birthDate',
      isRequired: false,
      isVisible: false,
    },
    {
      fieldName: 'divider',
      isRequired: false,
      isVisible: false,
    },
    {
      fieldName: 'emergencyContactName',
      isRequired: false,
      isVisible: false,
    },
    {
      fieldName: 'emergencyContactPhone',
      isRequired: false,
      isVisible: false,
    },
    {
      fieldName: 'divider',
      isRequired: false,
      isVisible: false,
    },
    {
      fieldName: 'travelDocument',
      isRequired: false,
      isVisible: false,
    },
    {
      fieldName: 'passportNumber',
      isRequired: false,
      isVisible: false,
    },
    {
      fieldName: 'dateOfIssue',
      isRequired: false,
      isVisible: false,
    },
    {
      fieldName: 'dateOfExpiry',
      isRequired: false,
      isVisible: false,
    },
    {
      fieldName: 'divider',
      isRequired: false,
      isVisible: false,
    },
    {
      fieldName: 'nationality',
      isRequired: false,
      isVisible: false,
    },
    {
      fieldName: 'vegetarian',
      isRequired: false,
      isVisible: false,
    },
    {
      fieldName: 'salutation',
      isRequired: false,
      isVisible: false,
    },
    {
      fieldName: 'clothingSize',
      isRequired: false,
      isVisible: false,
    },
    {
      fieldName: 'shoeSize',
      isRequired: false,
      isVisible: false,
    },
  ],
};
