<div class="dialog-container flex flex-column align-items-center">
  <h1 class="title">{{ title | translate }}</h1>
  <p class="main-text">{{ text | translate }}</p>
  <div class="buttons">
    <button
      class="button"
      (click)="close()">
      {{ 'clarificationDialog.tickets.seeDetails' | translate }}
    </button>
    <button
      *ngIf="!event?.eventbriteSync"
      class="button"
      (click)="closeUsualDialog()"
      [ngStyle]="{ 'background-color': actionButtonColor ? actionButtonColor : 'transparent' }">
      {{ btnText | translate }}
    </button>
  </div>
  <app-eventbrite-ticket
    *ngIf="event?.eventbriteSync"
    [event]="event"
    [userEventsMap]="eventUsers"
    [className]="className"
    [buttonText]="btnText"
    (click)="close()">
  </app-eventbrite-ticket>
</div>
