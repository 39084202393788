<div class="booking-code-pdf">
  <div class="header">
    <div class="moonova-icon"></div>
    <div class="dates">
      <div class="code">{{ 'adminBookingCodePdf.orderNumber' | translate }} 89574843758793457</div>
      <div class="date">
        {{ 'adminBookingCodePdf.orderDate' | translate }} Do., 18. Aug. 2022 12:00 Uhr
      </div>
    </div>
  </div>
  <div class="ticket">
    <div class="name">Earlybird - 49,00€ zzgl. MwSt.</div>
    <div class="event-name">MOONOVA-Satellite Düsseldorf 2022</div>
    <div class="date">
      <div class="date-header">
        <i class="pi pi-calendar"></i>
        <span>Datum / Uhrzeit</span>
      </div>
      <div class="date-text">Do., 18. Aug. 2022 12:00 Uhr - Sa., 31. Dez. 2022 12:00 Uhr</div>
    </div>
    <div class="address">
      <div class="address-header">
        <i class="pi pi-map-marker"></i>
        <span>Veranstaltungsort</span>
      </div>
      <div class="address-text">Messe München</div>
    </div>
  </div>
  <div class="ticket booking-code">
    <div class="booking-code">
      <div class="code">
        <div class="code-text">{{ 'adminBookingCodePdf.bookingCode' | translate }}</div>
        <div class="code">2134 4758 2893 3748 3984</div>
      </div>
    </div>
    <div class="qr-code">
      <div class="content">
        <div class="header-text">{{ 'adminBookingCodePdf.ticketCreationTitle' | translate }}</div>
        <div class="btn">
          <span>{{ 'adminBookingCodePdf.ticketCreationBtnLabel' | translate }}</span>
          <i class="pi pi-arrow-right"></i>
        </div>
        <div class="link">www.lox.space/moonova/invite</div>
      </div>
      <div class="code">
        <img
          src="../../../../assets/images/qr.png"
          alt="" />
      </div>
    </div>
  </div>
  <div class="note">
    <div class="note-text">
      <span>{{ 'adminBookingCodePdf.note' | translate }}</span
      >{{ 'adminBookingCodePdf.bookingCodeNote' | translate }}
    </div>
    <div class="address">
      <div class="note-address-header">
        <div class="icon">
          <i class="pi pi-home"></i>
          <span>{{ 'adminBookingCodePdf.host' | translate }}</span>
        </div>
      </div>
      <div class="note-address-content">
        Ebner Media Group
        <br />
        Straße 12
        <br />
        322323 München
      </div>
    </div>
  </div>
  <div class="faq">
    <div class="faq-header-text">{{ 'adminBookingCodePdf.faq' | translate }}</div>
    <div class="faq-header-description">{{ 'adminBookingCodePdf.faqDescription' | translate }}</div>
    <div class="faq-content">
      <div class="faq-item">
        <div class="question">{{ 'adminBookingCodePdf.whyReceive' | translate }}</div>
        <div class="answer">{{ 'adminBookingCodePdf.whyReceiveAnswer' | translate }}</div>
      </div>
      <div class="faq-item">
        <div class="question">{{ 'adminBookingCodePdf.whatIsBookingCode' | translate }}</div>
        <div class="answer">{{ 'adminBookingCodePdf.whatIsBookingCodeAnswer' | translate }}</div>
      </div>
      <div class="faq-item">
        <div class="question">{{ 'adminBookingCodePdf.howRedeem' | translate }}</div>
        <div class="answer">{{ 'adminBookingCodePdf.howRedeemAnswer' | translate }}</div>
      </div>
      <div class="faq-item">
        <div class="question">{{ 'adminBookingCodePdf.howLongValid' | translate }}</div>
        <div class="answer">{{ 'adminBookingCodePdf.howLongValidAnswer' | translate }}</div>
      </div>
      <div class="faq-item">
        <div class="question">{{ 'adminBookingCodePdf.howContact' | translate }}</div>
        <div class="answer">{{ 'adminBookingCodePdf.howContactAnswer' | translate }}</div>
      </div>
    </div>
  </div>
</div>
