<div class="toast-container">
  @switch (severity()) {
    @case ('success') {
      <i class="fa-regular fa-check toast-icon success"></i>
    }
    @case ('error') {
      <i class="fa-regular fa-triangle-exclamation toast-icon error"></i>
    }
    @case ('info') {
      <i class="fa-regular fa-circle-info toast-icon info"></i>
    }
  }
  <div class="toast-content">
    <h4>{{ summary() }}</h4>
    <p>{{ detail() }}</p>
  </div>
</div>
