import * as JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Subject, merge, takeUntil, tap } from 'rxjs';
import { MessageService } from 'primeng/api';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';

import { RegisteredCourseStore } from 'src/app/core/stores';
import { IAsset, ICourse } from 'src/app/core/models';
import { trackByFn } from 'src/app/core/utils';
import { MixpanelService } from 'src/app/core/services';
import { AnalyticsEventTypes } from 'src/app/core/enums';
import { SharedModule } from 'src/app/shared';
import { FileAssetCardComponent } from '../file-asset-card/file-asset-card.component';

@Component({
  selector: 'app-files-sidebar-tab',
  standalone: true,
  imports: [CommonModule, FileAssetCardComponent, TranslateModule, SharedModule],
  templateUrl: './files-sidebar-tab.component.html',
  styleUrls: ['./files-sidebar-tab.component.scss'],
})
export class FilesSidebarTabComponent implements OnInit, OnDestroy {
  assets: IAsset[];
  trackByFn = trackByFn;
  course: ICourse;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private registeredCourseStore: RegisteredCourseStore,
    private mixpanelService: MixpanelService,
    private messageService: MessageService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    const assetsWithTypeFile$ = this.registeredCourseStore.assetsWithTypeFile.pipe(
      tap((assetsWithTypeFile: IAsset[]) => {
        this.assets = assetsWithTypeFile;
      }),
    );
    const course$ = this.registeredCourseStore.course.pipe(
      tap((course: ICourse) => {
        this.course = course;
      }),
    );
    merge(assetsWithTypeFile$, course$).pipe(takeUntil(this.unsubscribe$)).subscribe();
  }

  onDownloadAsset(asset: IAsset): void {
    saveAs(asset.file, asset.fileName);
    this.mixpanelService.courseAssetEvent(
      this.course,
      asset,
      AnalyticsEventTypes.COURSE_ASSET_FILE,
    );
    this.showToastMessage();
  }

  async onDownloadAll(): Promise<void> {
    if (!this.assets?.length) {
      return;
    }
    const zipFile: JSZip = new JSZip();
    const name = `${this.course.title}_assets.zip`;

    let assetCount = 0;

    this.assets.map(async (asset: IAsset) => {
      JSZipUtils.getBinaryContent(asset.file as string, (error, data) => {
        if (error) {
          console.warn(error);
          throw new Error(error);
        } else {
          zipFile.file(asset.fileName, data, { binary: true });

          assetCount += 1;
          if (assetCount === this.assets.length) {
            zipFile.generateAsync({ type: 'blob' }).then((content) => {
              if (content) {
                saveAs(content, name);
              }
            });
          }
        }
      });
    });
    this.mixpanelService.courseEvent(this.course, AnalyticsEventTypes.COURSE_ASSET_DOWNLOAD_ALL);
    this.showToastMessage();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private showToastMessage(): void {
    this.messageService.add({
      severity: 'info',
      detail: this.translateService.instant('courseRegisteredPage.downloadAlert'),
      styleClass: 'custom-toast',
    });
  }
}
