import { Timestamp } from 'src/app/firebase';

export interface IEmail {
  id: string;
  action: string;
  isHtmlMode: boolean;
  type: any[]; //Enum (manual, trigger);
  module: EmailModuleType;
  title: string;
  _title_: string;
  language: string;
  subject: string;
  from: string;
  status: boolean;
  audience: any[]; //Enum (Attendees, Speakers, BrandPeople)
  html: string;
  image: string;
  emailHeader: string;
  emailPreHeader: string;
  buttonText: string;
  buttonLink: string;
  color: string;
  description: string;
  addToCalendarActive: boolean;
  customUrl: string;
  default: boolean;
  isDeleted: boolean;
  createAt: Timestamp;
  createBy: string;
  updateAt: Timestamp;
  updateBy: string;
  actionTitle?: string;
  eventId?: string;
  courseId?: string;
  isUnlayer?: boolean;
  design?: string;
}

export enum EmailModuleType {
  EVENTS = 'events',
  COURSES = 'courses',
}
