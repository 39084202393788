import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TitleStrategy, RouterStateSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CoursesStore, EventsStore, HubsStore } from '../../stores';

@Injectable({
  providedIn: 'root',
})
export class PageTitleService extends TitleStrategy {
  constructor(
    private readonly title: Title,
    private translateService: TranslateService,
    private hubsStore: HubsStore,
    private eventsStore: EventsStore,
    private coursesStore: CoursesStore,
  ) {
    super();
  }

  override async updateTitle(routerState: RouterStateSnapshot): Promise<void> {
    const titleTranslationKey = this.buildTitle(routerState);
    const hubSuffix = this.hubsStore.hub ? ` | ${this.hubsStore.hub.title}` : '';
    const pageTitle =
      this.translateService.instant(`application.pageTitles.${titleTranslationKey}`) + hubSuffix;

    if (titleTranslationKey == 'eventName') {
      const eventName = this.eventsStore.event?.title;
      this.title.setTitle(`${eventName + hubSuffix}`);
    } else if (titleTranslationKey == 'courseName') {
      const courseName = this.coursesStore.course?.title;
      this.title.setTitle(`${courseName + hubSuffix}`);
    } else if (titleTranslationKey !== undefined) {
      this.title.setTitle(`${pageTitle}`);
    } else {
      const homePageTitle = this.hubsStore.hub?.title
        ? this.hubsStore.hub?.title
        : this.translateService.instant('application.pageTitles.homePageTitle');
      this.title.setTitle(`${homePageTitle}`);
    }
  }
}
