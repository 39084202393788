import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { makeAutoObservable } from 'mobx';

import { IBrand, IUserHub, IUserEvent, IUser, IUserCourse } from 'src/app/core/models';
import { userAvatar } from 'src/app/shared';
import {
  AdvertisersSubIndustries,
  AgencySubIndustries,
  GeneralSubIndustries,
  Industry,
  MediaAdvertisingSubIndustries,
  RetailSubIndustries,
  ServicesSuppliersSubIndustries,
  TechnologySubIndustries,
} from 'src/app/core/enums';
import { IDevice } from '../../models/device/device.model';
import { IndexedDbService } from '../../services/indexed-db/indexed-db.service';

@Injectable()
export class UsersStore {
  public user: IUser = null;
  public users: IUser[] = [];
  public userEventsMap: { [eventId: string]: IUserEvent } = {};
  public userCoursesMap: { [courseId: string]: IUserCourse } = {};
  public userBookmarkedEventsIds: any[] = [];
  public userBookmarkedCoursesIds: any[] = [];
  public userHubs: IUserHub[] = [];
  public adminUser: IUser = null;
  public unreadNotificatios: number = 0;
  public hideNotificationBar: Subject<void> = new Subject<void>();
  public userBrand: IBrand = null;

  public userDevice: IDevice = null;

  private selectedUser: BehaviorSubject<IUser> = new BehaviorSubject<IUser>(null);
  private selectedDevice: BehaviorSubject<IDevice> = new BehaviorSubject<IDevice>(null);

  public get currentUserDevice(): Observable<IDevice> {
    return this.selectedDevice.asObservable();
  }

  public get currentUser(): Observable<IUser> {
    return this.selectedUser.asObservable();
  }

  public get userEvents(): IUserEvent[] {
    return Object.keys(this.userEventsMap).map((id) => this.userEventsMap[id]);
  }

  public get userCourses(): IUserCourse[] {
    return Object.keys(this.userCoursesMap).map((id) => this.userCoursesMap[id]);
  }

  public get userId(): string {
    return this.user?.id || null;
  }

  public get userProfilePic(): string {
    return this.user?.profileImage ?? userAvatar;
  }

  public get hubs(): IUserHub[] {
    return this.userHubs;
  }

  constructor(private indexedDbService: IndexedDbService) {
    makeAutoObservable(this);
  }

  public setUserBrand(brand: IBrand): void {
    this.userBrand = brand;
  }

  public setUserDevice(device: IDevice): void {
    this.userDevice = device;
    this.selectedDevice.next(device);
    localStorage.setItem('userDevice', JSON.stringify(device));

    this.indexedDbService.isDone$.subscribe((ready) => {
      if (ready && device)
        this.indexedDbService.put('userDevice', { _userDevice: '_userDevice', ...device });
    });
  }

  public setUser(user: IUser): void {
    this.user = user;
    this.selectedUser.next(user);
    localStorage.setItem('user', JSON.stringify(user));

    this.indexedDbService.isDone$.subscribe((ready) => {
      if (ready && user) this.indexedDbService.put('user', { _user: '_user', ...user });
    });
  }

  public setAdminUser(user: IUser): void {
    if (user.id === this.user.id) {
      this.setUser(user);
    }
    this.adminUser = user;
  }

  public unsetAdminUser(): void {
    this.adminUser = null;
  }

  public setUsers(users: IUser[]): void {
    this.users = users;
  }

  public setBrandId(id: string): void {
    this.user.brandId = id;
    localStorage.setItem('user', JSON.stringify(this.user));
  }

  public setUserEvents(userEvents: IUserEvent[], reset: boolean = false) {
    if (reset) {
      this.userEventsMap = {};
    }

    for (const userEvent of userEvents) {
      this.userEventsMap[userEvent.eventId] = userEvent;
    }
  }

  public setUserCourses(userCourses: IUserCourse[], reset: boolean = false) {
    if (reset) {
      this.userCoursesMap = {};
    }

    for (const userCourse of userCourses) {
      this.userCoursesMap[userCourse.courseId] = userCourse;
    }
  }

  public getUserEvent(eventId: string): IUserEvent {
    return this.userEventsMap[eventId];
  }

  public getUserCourse(courseId: string): IUserCourse {
    return this.userCoursesMap[courseId];
  }

  public getUserSubIndustries(userIndustry: Industry = null): string[] {
    switch (userIndustry) {
      case Industry.AGENCY:
        return Object.values(AgencySubIndustries);
      case Industry.INDUSTRY_ADVERTISERS:
        return Object.values(AdvertisersSubIndustries);
      case Industry.MEDIA_ADVERTISING:
        return Object.values(MediaAdvertisingSubIndustries);
      case Industry.OTHER:
        return Object.values(GeneralSubIndustries);
      case Industry.RETAIL:
        return Object.values(RetailSubIndustries);
      case Industry.SERVICES_SUPPLIERS:
        return Object.values(ServicesSuppliersSubIndustries);
      case Industry.TECHNOLOGY:
        return Object.values(TechnologySubIndustries);
      default:
        return Object.values(AgencySubIndustries);
    }
  }

  public setBookmarkedEventsIds(eventsIds: any[]): void {
    this.userBookmarkedEventsIds = eventsIds;
  }

  public pushBookmarkedId(id: string): void {
    if (!this.userBookmarkedEventsIds.includes(id)) {
      this.userBookmarkedEventsIds.push(id);
    }
  }

  public popBookmarkedId(id: string): void {
    this.userBookmarkedEventsIds = this.userBookmarkedEventsIds.filter((_id) => _id !== id);
  }

  public setBookmarkedCoursesIds(coursesIds: any[]): void {
    this.userBookmarkedCoursesIds = coursesIds;
  }

  public pushBookmarkedCourseId(id: string): void {
    if (!this.userBookmarkedCoursesIds.includes(id)) {
      this.userBookmarkedCoursesIds.push(id);
    }
  }

  public popBookmarkedCourseId(id: string): void {
    this.userBookmarkedCoursesIds = this.userBookmarkedCoursesIds.filter((_id) => _id !== id);
  }

  public setUserHubs(userHubs: IUserHub[]): void {
    this.userHubs = userHubs;
  }

  public hasAccessToHubAdminPanel(hubId: string): boolean {
    for (const hub of this.userHubs) {
      if (
        hub.hubId === hubId &&
        hub.userId === this.userId &&
        (hub.role === 'owner' || hub.role === 'admin')
      ) {
        return true;
      }
    }

    return false;
  }

  public hideNotification(): void {
    this.hideNotificationBar.next();
  }

  public reset(): void {
    this.user = null;
    this.users = [];
    this.userEventsMap = {};
    this.userBookmarkedEventsIds = [];
    this.userCoursesMap = {};
    this.userBookmarkedCoursesIds = [];
    this.userHubs = [];
    this.userBrand = null;
  }
}
