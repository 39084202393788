import * as moment from 'moment';
import { Injectable, signal } from '@angular/core';

import { Firestore, IFirestore } from 'src/app/firebase';
import { API_ROUTES as apiRoutes } from 'src/app/shared';
import { AwsEventBridgeEventName } from 'src/app/standalone/shared/enums';
import { IAwsEventBridgeEvent } from 'src/app/standalone/shared/models';

@Injectable({
  providedIn: 'root',
})
export class AwsEventBridgeEventService {
  private firestore: IFirestore;
  private liveChannelListener: () => void;
  private isLiveChannelAvailableSignal = signal<boolean>(false);

  isLiveChannelAvailable = this.isLiveChannelAvailableSignal.asReadonly();

  constructor() {
    this.firestore = Firestore();
  }

  listenIfLiveChannelAvailable(channelName: string) {
    this.liveChannelListener = this.firestore
      .collection(apiRoutes.awsEventBridgeEvents)
      .where('channelName', '==', channelName)
      .onSnapshot((awsEventBridgeEventQuerySnapshot) => {
        const awsEventBridgeEvents: IAwsEventBridgeEvent[] =
          awsEventBridgeEventQuerySnapshot.docs.map(
            (awsEventBridgeEvent) => awsEventBridgeEvent.data() as IAwsEventBridgeEvent,
          );

        let awsStreamStartEvent = null;
        let awsStreamEndEvent = null;

        awsEventBridgeEvents.forEach((awsEvent: IAwsEventBridgeEvent) => {
          switch (awsEvent.eventName) {
            case AwsEventBridgeEventName.STREAM_START:
              awsStreamStartEvent = { ...awsEvent };
              break;
            case AwsEventBridgeEventName.STREAM_END:
              awsStreamEndEvent = { ...awsEvent };
              break;
          }
        });

        const condition =
          (awsStreamStartEvent && !awsStreamEndEvent) ||
          moment(awsStreamStartEvent?.time).isAfter(moment(awsStreamEndEvent?.time));

        this.isLiveChannelAvailableSignal.set(condition);
      });
  }

  removeLiveChannelListener() {
    if (this.liveChannelListener) {
      this.liveChannelListener();
    }
    this.liveChannelListener = null;
  }
}
