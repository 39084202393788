import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  IUserCourse,
  AlgoliaSearchResult,
  ICourse,
  IUser,
  ICourseBrand,
  ICourseTicket,
  ICourseCoupon,
} from 'src/app/core/models';
import {
  Firestore,
  IDocumentData,
  IDocumentReference,
  IDocumentSnapshot,
  IFirestore,
  IQuery,
  IQueryDocumentSnapshot,
  IQuerySnapshot,
  ServerTimestamp,
} from 'src/app/firebase';
import { API_ROUTES as apiRoutes } from 'src/app/shared';
import { ICourseSubscription, ISubscription } from 'src/app/core/models';
import { CoursesStore, HubsStore, UsersStore } from 'src/app/core/stores';
import { StorageService, AlgoliaService, HttpClientService } from 'src/app/core/services';

@Injectable({
  providedIn: 'root',
})
export class CoursesService {
  private firestore: IFirestore;

  constructor(
    private storageService: StorageService,
    private coursesStore: CoursesStore,
    private userStore: UsersStore,
    private usersStore: UsersStore,
    private algoliaService: AlgoliaService,
    private http: HttpClientService,
    private hubsStore: HubsStore,
  ) {
    this.firestore = Firestore();
  }

  async getAllWithoutLimitations(): Promise<ICourse[]> {
    const coursesQuerySnapshot: IQuerySnapshot<IDocumentData> = await this.firestore
      .collection(apiRoutes.courses)
      .get();
    const courses: ICourse[] = coursesQuerySnapshot.docs.map((doc) => doc.data() as ICourse);

    return courses;
  }

  async getOne(
    id: string,
    forceUpdate: boolean = false,
    setInStore: boolean = true,
  ): Promise<ICourse> {
    if (!forceUpdate && this.coursesStore.course) {
      return this.coursesStore.course;
    }

    try {
      const courseQuery: IDocumentSnapshot<IDocumentData> = await this.firestore
        .collection(apiRoutes.courses)
        .doc(id)
        .get();

      if (courseQuery.exists) {
        if (setInStore) {
          this.coursesStore.setCourse(courseQuery.data() as ICourse);
        }

        return courseQuery.data() as ICourse;
      } else {
        return null;
      }
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async create(course: ICourse): Promise<ICourse> {
    try {
      const preCourseReqDoc: IDocumentReference<IDocumentData> = this.firestore
        .collection(apiRoutes.courses)
        .doc();
      course.id = preCourseReqDoc.id;
      await preCourseReqDoc.set(course);
      await this.algoliaService.createCourse(course);

      return course;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async update(courseId: string, course: ICourse): Promise<ICourse> {
    try {
      const courseDocument: IDocumentReference<IDocumentData> = this.firestore
        .collection(apiRoutes.courses)
        .doc(courseId);
      const courseDocData: IDocumentSnapshot<IDocumentData> = await courseDocument.get();

      const timestamp = new Date().getTime();
      const hubId = this.hubsStore.hub?.id;

      if (course.banner instanceof File) {
        const currentBannerImage = courseDocData.get('banner');

        if (currentBannerImage) {
          await this.storageService.delete(currentBannerImage);
        }

        if (course.banner !== null) {
          const fileName = `file_${new Date().getTime()}`;
          const newCoverImage = await this.storageService.upload(
            course.banner,
            'courses',
            fileName,
          );
          course.banner = newCoverImage;
        } else {
          course.banner = null;
        }
      }

      if (course.featuredImage instanceof File) {
        const currentFeaturedImage = courseDocData.get('featuredImage');

        if (currentFeaturedImage) {
          await this.storageService.delete(currentFeaturedImage);
        }

        if (course.featuredImage !== null) {
          const fileName = `file_${new Date().getTime()}`;
          const newCoverImage = await this.storageService.upload(
            course.featuredImage,
            'courses',
            fileName,
          );
          course.featuredImage = newCoverImage;
        } else {
          course.featuredImage = null;
        }
      }

      if (course.internalVideo instanceof File) {
        const assetInternalVideoFileName = `course_${course.id}_internal-video_${timestamp}`;
        const newAssetInternalVideoPath = await this.storageService.upload(
          course.internalVideo,
          `hubs/${hubId}/library`,
          assetInternalVideoFileName,
        );
        course.internalVideo = newAssetInternalVideoPath;
      }
      await courseDocument.update({ ...course });
      await this.algoliaService.updateCourse(course);

      this.coursesStore.setAdminCourse({ ...course });

      return course;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async delete(courseDocId: string): Promise<boolean> {
    try {
      await this.firestore.collection(apiRoutes.courses).doc(courseDocId).delete();
      return true;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async deleteFromAPI(courseId: string): Promise<boolean> {
    try {
      await this.http.delete(apiRoutes.courseApi(courseId));
      return true;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getByUrl(link: string): Promise<ICourse> {
    try {
      const courseQuery: IQuerySnapshot<IDocumentData> = await this.firestore
        .collection(apiRoutes.courses)
        .where('link', '==', link)
        .get();

      if (!courseQuery.empty) {
        return courseQuery.docs[0].data() as ICourse;
      }

      return null;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getUserCourse(courseId: string, userId: string): Promise<IUserCourse> {
    if (!userId) {
      return;
    }

    try {
      const userCourseQuery: IQuerySnapshot<IDocumentData> = await this.firestore
        .collection(apiRoutes.userCourses)
        .where('courseId', '==', courseId)
        .where('userId', '==', userId)
        .get();

      if (!userCourseQuery.empty) {
        return userCourseQuery.docs[0].data() as IUserCourse;
      }

      return null;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async updateUserCourse(userCourse: IUserCourse): Promise<IUserCourse> {
    try {
      const userCourseDocument: IDocumentReference<IDocumentData> = this.firestore
        .collection(apiRoutes.userCourses)
        .doc(userCourse.id);
      await userCourseDocument.update({ ...userCourse });

      return userCourse;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  checkCourseByLinkExists(courseLink: string, courseId: string = null): Observable<boolean> {
    const courses: ICourse[] = [];
    const coursesQuery: IQuery<IDocumentData> = this.firestore
      .collection(apiRoutes.courses)
      .where('link', '==', courseLink);

    return from(coursesQuery.get()).pipe(
      map((response) => {
        response.forEach((doc) => {
          if (doc.data().id === courseId) {
            return;
          }
          courses.push(doc.data() as ICourse);
        });

        return courses.length > 0;
      }),
    );
  }

  async getAllByHubId(hubId: string = null): Promise<ICourse[]> {
    return (
      await this.firestore
        .collection(apiRoutes.courses)
        .where('hubId', '==', hubId)
        .orderBy('_title_')
        .get()
    ).docs.map((doc) => doc.data() as ICourse);
  }

  async publishCourse(courseId: string): Promise<void> {
    await this.firestore
      .collection(apiRoutes.courses)
      .doc(courseId)
      .update({ isPublished: true, publicationDate: ServerTimestamp() });

    const courseQuery: IDocumentSnapshot<IDocumentData> = await this.firestore
      .collection(apiRoutes.courses)
      .doc(courseId)
      .get();
    const courseDocument = courseQuery.data();
    await this.algoliaService.updateCourse(courseDocument);
  }

  async unpublishCourse(courseId: string): Promise<void> {
    await this.firestore
      .collection(apiRoutes.courses)
      .doc(courseId)
      .update({ publicationDate: null, isPublished: false });

    const courseQuery: IDocumentSnapshot<IDocumentData> = await this.firestore
      .collection(apiRoutes.courses)
      .doc(courseId)
      .get();
    const courseDocument = courseQuery.data();
    await this.algoliaService.updateCourse(courseDocument);
  }

  async unAttachUser(
    courseId: string,
    userId: string,
    role: 'subscriber' | 'instructor',
  ): Promise<void> {
    const userCourseId: string = (
      await this.firestore
        .collection(apiRoutes.userCourses)
        .where('courseId', '==', courseId)
        .where('userId', '==', userId)
        .where('role', '==', role)
        .get()
    ).docs[0].id;

    await this.firestore.collection(apiRoutes.userCourses).doc(userCourseId).delete();
    await this.algoliaService.removeUserCourse(userCourseId);
  }

  async addUserToCourse(
    course: ICourse,
    user: IUser,
    role: 'subscriber' | 'instructor',
    isFromAdminPart: boolean,
    ticketId?: string,
  ): Promise<IUserCourse> {
    try {
      const userCoursesQuery = await this.firestore
        .collection(apiRoutes.userCourses)
        .where('courseId', '==', course.id)
        .where('userId', '==', user.id)
        .get();

      if (userCoursesQuery.empty) {
        const userCourseDoc: IDocumentReference<IDocumentData> = this.firestore
          .collection(apiRoutes.userCourses)
          .doc();
        const newFirestoreUserCourse: IUserCourse = {
          id: userCourseDoc.id,
          courseId: course.id,
          userId: user.id,
          _firstName_: user.firstName.toLowerCase(),
          _lastName_: user.lastName.toLowerCase(),
          _company_: user?.company?.toLowerCase() || null,
          _position_: user.position ? user.position.toLowerCase() : null,
          _courseTitle_: course.title.toLowerCase(),
          role,
          isFromEventBrite: false,
          ticketId: ticketId || null,
          status: 'active',
          isWatched: false,
          completedChapters: [],
          wasAttachedFromAdminPart: isFromAdminPart,
          createdAt: ServerTimestamp(),
          createdBy: this.userStore.user.id,
          updatedAt: null,
          updatedBy: null,
          tags: user.tags,
        };
        await userCourseDoc.set({ ...newFirestoreUserCourse });
        await this.algoliaService.createUserCourse(newFirestoreUserCourse);
        this.usersStore.setUserCourses([{ ...newFirestoreUserCourse }]);
        return newFirestoreUserCourse;
      } else {
        throw new Error(`User ID: ${user.id} is already attached to the course ID: ${course.id}`);
      }
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getAll(
    hubId: string,
    pageIndex: number,
    pageSize: number,
    searchTerm: string,
  ): Promise<AlgoliaSearchResult<ICourse>> {
    return await this.algoliaService.search<ICourse>(
      apiRoutes.courses,
      `${hubId} ${searchTerm}`,
      pageSize,
      pageIndex * pageSize,
    );
  }

  async getFeatured(hubId: string = null): Promise<ICourse[]> {
    let coursesQuery: IQuery<IDocumentData> = this.firestore
      .collection(apiRoutes.courses)
      .orderBy('start')
      .orderBy('_title_')
      .orderBy('id')
      .where('featured', '==', true)
      .where('isPublished', '==', true);

    if (hubId) {
      coursesQuery = coursesQuery.where('hubId', '==', hubId);
    }
    coursesQuery = coursesQuery.limit(5);
    const courses: ICourse[] = (await coursesQuery.get()).docs.map((doc) => doc.data() as ICourse);

    return courses;
  }

  async getAllPaginated(
    hubId: string = null,
    course: ICourse = null,
    pageSize: number = 12,
    order: 'asc' | 'desc' = 'asc',
  ): Promise<ICourse[]> {
    const courses: ICourse[] = [];
    let query: IQuery<IDocumentData> = this.firestore
      .collection(apiRoutes.courses)
      .where('isPublished', '==', true)
      .orderBy('_title_', order)
      .orderBy('id');

    if (course) {
      query = query.startAfter(course._title_, course.id);
    }

    if (hubId) {
      query = query.where('hubId', '==', hubId);
    }

    query = query.limit(pageSize);

    const response = await query.get();
    response.forEach((doc) => courses.push(doc.data() as ICourse));

    return courses;
  }

  async getAllWithoutPaginated(
    hubId: string = null,
    order: 'asc' | 'desc' = 'asc',
  ): Promise<ICourse[]> {
    const courses: ICourse[] = [];
    let query: IQuery<IDocumentData> = this.firestore
      .collection(apiRoutes.courses)
      .where('isPublished', '==', true)
      .orderBy('_title_', order)
      .orderBy('id');

    if (hubId) {
      query = query.where('hubId', '==', hubId);
    }

    const response = await query.get();
    response.forEach((doc) => courses.push(doc.data() as ICourse));

    return courses;
  }

  async getBookmarked(
    hubId: string = null,
    entry: ICourse = null,
    userId: string,
    pageSize: number = 12,
    sort: 'asc' | 'desc' = 'asc',
  ): Promise<ICourse[]> {
    try {
      const courses: ICourse[] = [];
      let coursesIds: string[] = [];
      const bookmarkedCoursesQuery: IQuerySnapshot<IDocumentData> = await this.firestore
        .collection(apiRoutes.bookmarkedCourses(userId))
        .get();
      coursesIds.push(...bookmarkedCoursesQuery.docs.map((doc) => doc.id));

      if (entry) {
        coursesIds = coursesIds.slice(coursesIds.indexOf(entry.id));
      }

      let coursesQuery: IQuery<IDocumentData> | IQuerySnapshot<IDocumentData> = null;
      while (coursesIds.length > 0) {
        const chunk: string[] = coursesIds.splice(0, 10);
        coursesQuery = this.firestore
          .collection(apiRoutes.courses)
          .where('id', 'in', chunk)
          .where('isPublished', '==', true)
          .orderBy('start', sort)
          .orderBy('_title_', sort);

        if (entry) {
          coursesQuery = coursesQuery.startAfter(entry.start, entry._title_);
        }

        if (hubId) {
          coursesQuery = coursesQuery.where('hubId', '==', hubId);
        }

        coursesQuery = await coursesQuery.limit(pageSize).get();

        if (!coursesQuery.empty) {
          coursesQuery.forEach((doc) => {
            if (courses.length < pageSize) {
              courses.push(doc.data() as ICourse);
            }
          });
        }

        if (courses.length === pageSize) {
          coursesIds = [];
        }
      }

      return courses;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getBookmarkedWithoutPagination(
    hubId: string = null,
    userId: string,
    sort: 'asc' | 'desc' = 'asc',
  ): Promise<ICourse[]> {
    const courses: ICourse[] = [];

    try {
      const coursesIds: string[] = [];
      const bookmarkedCoursesQuery: IQuerySnapshot<IDocumentData> = await this.firestore
        .collection(apiRoutes.bookmarkedCourses(userId))
        .get();
      coursesIds.push(...bookmarkedCoursesQuery.docs.map((doc) => doc.id));

      let coursesQuery: IQuery<IDocumentData> | IQuerySnapshot<IDocumentData> = null;
      while (coursesIds.length > 0) {
        const chunk: string[] = coursesIds.splice(0, 10);

        coursesQuery = this.firestore
          .collection(apiRoutes.courses)
          .where('id', 'in', chunk)
          .where('isPublished', '==', true)
          .orderBy('start', sort)
          .orderBy('_title_', sort);

        if (hubId) {
          coursesQuery = coursesQuery.where('hubId', '==', hubId);
        }

        coursesQuery = await coursesQuery.get();
        coursesQuery.forEach((doc) => courses.push(doc.data() as ICourse));
      }

      return courses;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getByUser(
    userId: string,
    hubId: string = null,
    entry: ICourse = null,
    pageSize: number = 20,
    order: 'asc' | 'desc' = 'asc',
  ): Promise<ICourse[]> {
    const userCourses: { [key: string]: IUserCourse } = {};

    (
      await this.firestore
        .collection(apiRoutes.userCourses)
        .where('userId', '==', userId)
        .orderBy('_courseTitle_', order)
        .get()
    ).forEach((doc) => {
      const userCourse = doc.data() as IUserCourse;
      userCourses[userCourse.courseId] = doc.data() as IUserCourse;
    });

    const coursesIds: string[] = Object.keys(userCourses);
    const startFromIndex: number = entry ? coursesIds.indexOf(entry.id) + 1 : 0;
    const courseIdsToQuery: string[] = coursesIds.splice(startFromIndex, pageSize);
    const courses: ICourse[] = [];

    while (courseIdsToQuery.length) {
      const chunk: string[] = courseIdsToQuery.splice(0, 10);
      let query: IQuery<IDocumentData> = this.firestore
        .collection(apiRoutes.courses)
        .where('id', 'in', chunk)
        .where('isPublished', '==', true)
        .orderBy('_title_', order);

      if (hubId) {
        query = query.where('hubId', '==', hubId);
      }
      const queryCourses: IQuerySnapshot<IDocumentData> = await query.get();
      courses.push(
        ...queryCourses.docs.map((doc) =>
          Object.assign(doc.data() as ICourse, { type: userCourses[doc.id] }),
        ),
      );
    }

    return courses;
  }

  async getByUserWithoutPagination(
    userId: string,
    hubId: string = null,
    order: 'asc' | 'desc' = 'asc',
  ): Promise<ICourse[]> {
    const userCourses: { [key: string]: IUserCourse } = {};

    (
      await this.firestore
        .collection(apiRoutes.userCourses)
        .where('userId', '==', userId)
        .orderBy('_courseTitle_', order)
        .get()
    ).forEach((doc) => {
      const userCourse = doc.data() as IUserCourse;
      userCourses[userCourse.courseId] = doc.data() as IUserCourse;
    });
    const coursesIds: string[] = Object.keys(userCourses);
    const courses: ICourse[] = [];

    while (coursesIds.length) {
      const chunk: string[] = coursesIds.splice(0, 10);
      let query: IQuery<IDocumentData> = this.firestore
        .collection(apiRoutes.courses)
        .where('id', 'in', chunk)
        .where('isPublished', '==', true)
        .orderBy('_title_', order);

      if (hubId) {
        query = query.where('hubId', '==', hubId);
      }
      const queryCourses: IQuerySnapshot<IDocumentData> = await query.get();
      courses.push(
        ...queryCourses.docs.map((doc) =>
          Object.assign(doc.data() as ICourse, { type: userCourses[doc.id] }),
        ),
      );
    }

    return courses;
  }

  async getAllCoursesByUser(userId: string, hubId: string = null): Promise<ICourse[]> {
    try {
      const courseIds: string[] = [];
      const courses: ICourse[] = [];
      const userCoursesQuery: IQuerySnapshot<IDocumentData> = await this.firestore
        .collection(apiRoutes.userCourses)
        .where('userId', '==', userId)
        .get();
      userCoursesQuery.forEach((userCourseQuery: IQueryDocumentSnapshot<IDocumentData>) => {
        courseIds.push(userCourseQuery.data().courseId);
      });

      while (courseIds.length) {
        const batch: string[] = courseIds.splice(0, 10);
        let coursesQuery: IQuery<IDocumentData> = this.firestore
          .collection(apiRoutes.courses)
          .where('id', 'in', batch);
        if (hubId) {
          coursesQuery = coursesQuery.where('hubId', '==', hubId);
        }
        const coursesSnapshot: IQuerySnapshot<IDocumentData> = await coursesQuery.get();
        coursesSnapshot.forEach((courseQuery: IQueryDocumentSnapshot<IDocumentData>) => {
          const course: ICourse = courseQuery.data() as ICourse;
          courses.push(course);
        });
      }

      return courses;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async getByOwner(ownerId: string): Promise<ICourse[]> {
    try {
      const courses: ICourse[] = (
        await this.firestore.collection(apiRoutes.courses).where('ownerId', '==', ownerId).get()
      ).docs.map((doc) => doc.data() as ICourse);

      return courses;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getBasedOnTags(
    hubId: string = null,
    entry: ICourse = null,
    pageSize: number = 12,
    order: 'asc' | 'desc' = 'asc',
  ): Promise<ICourse[]> {
    try {
      const courses: ICourse[] = [];
      let tags: string[] = this.usersStore.user ? [...this.usersStore.user.tags] : [];
      let queryCoursesByTags: IQuery<IDocumentData> | IQuerySnapshot<IDocumentData> = null;

      while (tags.length > 0) {
        const chunk: string[] = tags.splice(0, 10);

        queryCoursesByTags = this.firestore
          .collection(apiRoutes.courses)
          .where('isPublished', '==', true)
          .where('tags', 'array-contains-any', chunk)
          .orderBy('start', order)
          .orderBy('_title_', order)
          .orderBy('id');

        if (entry) {
          queryCoursesByTags = queryCoursesByTags.startAfter(entry.start, entry._title_, entry.id);
        }

        if (hubId) {
          queryCoursesByTags = queryCoursesByTags.where('hubId', '==', hubId);
        }

        queryCoursesByTags = await queryCoursesByTags.limit(pageSize).get();

        if (!queryCoursesByTags.empty) {
          queryCoursesByTags.forEach((doc) => {
            if (courses.length < pageSize) {
              courses.push(doc.data() as ICourse);
            }
          });
        }

        if (courses.length === pageSize) {
          tags = [];
        }
      }

      return courses;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getBasedOnTagsWithoutPagination(
    hubId: string = null,
    order: 'asc' | 'desc' = 'asc',
  ): Promise<ICourse[]> {
    try {
      const courses: ICourse[] = [];
      const tags: string[] = this.usersStore.user ? [...this.usersStore.user.tags] : [];
      let queryCoursesByTags: IQuery<IDocumentData> | IQuerySnapshot<IDocumentData> = null;

      while (tags.length > 0) {
        const chunk: string[] = tags.splice(0, 10);
        queryCoursesByTags = this.firestore
          .collection(apiRoutes.courses)
          .where('isPublished', '==', true)
          .where('tags', 'array-contains-any', chunk)
          .orderBy('start', order)
          .orderBy('_title_', order)
          .orderBy('id');

        if (hubId) {
          queryCoursesByTags = queryCoursesByTags.where('hubId', '==', hubId);
        }

        queryCoursesByTags = await queryCoursesByTags.get();
        queryCoursesByTags.forEach((doc) => courses.push(doc.data() as ICourse));
      }

      return courses;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getInstructors(courseId: string): Promise<IUserCourse[]> {
    const courseInstructors: IQuerySnapshot<IDocumentData> = await this.firestore
      .collection(apiRoutes.userCourses)
      .where('courseId', '==', courseId)
      .where('role', '==', 'instructor')
      .get();

    return courseInstructors.docs.map((d) => d.data()) as IUserCourse[];
  }

  async getByBrandId(
    hubId: string,
    brandId: string,
    entry: ICourse = null,
    pageSize: number = 20,
    order: 'asc' | 'desc' = 'asc',
  ): Promise<ICourse[]> {
    const brandCourses: { [key: string]: ICourseBrand } = {};
    (
      await this.firestore.collection(apiRoutes.brandCourses).where('brandId', '==', brandId).get()
    ).forEach((doc) => {
      const brandCourse = doc.data() as ICourseBrand;
      brandCourses[brandCourse.courseId] = doc.data() as ICourseBrand;
    });

    const courseIds: string[] = Object.keys(brandCourses);
    const startFromIndex: number = entry ? courseIds.indexOf(entry.id) + 1 : 0;
    const eventIdsToQuery: string[] = courseIds.splice(startFromIndex, pageSize);
    const courses: ICourse[] = [];

    while (eventIdsToQuery.length) {
      const chunk: string[] = eventIdsToQuery.splice(0, 10);
      let query: IQuery<IDocumentData> = this.firestore
        .collection(apiRoutes.courses)
        .where('id', 'in', chunk)
        .orderBy('_title_', order);

      if (entry) {
        query = query.startAfter(entry._title_);
      }

      if (hubId) {
        query = query.where('hubId', '==', hubId);
      }

      const queryEvents: IQuerySnapshot<IDocumentData> = await query.get();
      courses.push(
        ...queryEvents.docs.map((doc) =>
          Object.assign(doc.data() as ICourse, { type: brandCourses[doc.id] }),
        ),
      );
    }

    return courses;
  }

  async getCourseSubscriptions(courseId: string): Promise<ICourseSubscription[]> {
    try {
      const courseSubscriptions: ICourseSubscription[] = (
        await this.firestore
          .collection(apiRoutes.courseSubscriptions)
          .where('courseId', '==', courseId)
          .orderBy('orderIndex')
          .get()
      ).docs.map((doc) => doc.data() as ICourseSubscription);

      return courseSubscriptions;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async addSubscriptionToCourse(courseId: string, subscription: ISubscription): Promise<void> {
    const exists: ICourseSubscription = (
      await this.firestore
        .collection(apiRoutes.courseSubscriptions)
        .where('courseId', '==', courseId)
        .where('subscriptionId', '==', subscription.id)
        .get()
    ).docs.map((doc) => doc.data() as ICourseSubscription)[0];

    if (exists) {
      return;
    }

    try {
      const courseSubscriptionDoc: IDocumentReference<IDocumentData> = this.firestore
        .collection(apiRoutes.courseSubscriptions)
        .doc();
      const newCourseSubscription: ICourseSubscription = {
        id: courseSubscriptionDoc.id,
        courseId,
        subscriptionId: subscription.id,
        createdAt: ServerTimestamp(),
        createdBy: this.usersStore.user.id,
        subscriptionName: subscription.name,
        updatedAt: null,
        updatedBy: null,
        platform: subscription.platform,
        price: Number(subscription.price),
        currency: subscription.currency ?? null,
        stripePriceId: subscription.stripePriceId ?? null,
        orderIndex: subscription.orderIndex,
        isDefault: subscription.isDefault,
      };

      await courseSubscriptionDoc.set({ ...newCourseSubscription });
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async removeSubscriptionToCourse(courseId: string, subscriptionId: string): Promise<void> {
    try {
      const courseSubscription = (
        await this.firestore
          .collection(apiRoutes.courseSubscriptions)
          .where('courseId', '==', courseId)
          .where('subscriptionId', '==', subscriptionId)
          .get()
      ).docs[0];

      if (courseSubscription) {
        await this.firestore
          .collection(apiRoutes.courseSubscriptions)
          .doc(courseSubscription.id)
          .delete();
      }
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getLatestCoursesByCreationDate(
    hubId: string = null,
    course: ICourse = null,
    pageSize: number = 12,
    order: 'asc' | 'desc' = 'desc',
  ): Promise<ICourse[]> {
    try {
      let query: IQuery<IDocumentData> = this.firestore
        .collection(apiRoutes.courses)
        .where('isPublished', '==', true)
        .orderBy('createdAt', order);

      if (hubId) {
        query = query.where('hubId', '==', hubId);
      }

      if (course) {
        query = query.startAfter(course.createdAt);
      }
      query = query.limit(pageSize);
      const courses: ICourse[] = (await query.get()).docs.map((d) => d.data() as ICourse);

      return courses;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async hasAnyTicketOrCoupon(courseId: string): Promise<boolean> {
    try {
      const tickets: ICourseTicket[] = (
        await this.firestore.collection(apiRoutes.courseTickets(courseId)).get()
      ).docs.map((doc) => doc.data() as ICourseTicket);
      const coupons: ICourseCoupon[] = (
        await this.firestore.collection(apiRoutes.courseCoupons(courseId)).get()
      ).docs.map((doc) => doc.data() as ICourseCoupon);

      return tickets.length > 0 || coupons.length > 0;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async isCourseFree(courseId: string): Promise<boolean> {
    try {
      const tickets = (
        await this.firestore
          .collection(apiRoutes.courseTickets(courseId))
          .where('isPaid', '==', true)
          .where('isPublished', '==', true)
          .get()
      ).docs;

      const subscriptions = (
        await this.firestore
          .collection(apiRoutes.courseSubscriptions)
          .where('courseId', '==', courseId)
          .get()
      ).docs;

      return tickets.length == 0 && subscriptions.length == 0;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async updateCourseCheckedNavigationItems(
    courseId: string,
    activeNavigationItems: string[],
  ): Promise<void> {
    try {
      const courseDocument: IDocumentReference<IDocumentData> = this.firestore
        .collection(apiRoutes.courses)
        .doc(courseId);
      await courseDocument.update({
        checkedNavigationItems: activeNavigationItems,
      });
      const course = (await courseDocument.get()).data() as ICourse;
      course.checkedNavigationItems = activeNavigationItems;
      this.coursesStore.setAdminCourse(course);
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }
}
