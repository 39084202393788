import { Component } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-subscription-expired-dialog',
  templateUrl: './subscription-expired-dialog.component.html',
  styleUrls: ['./subscription-expired-dialog.component.scss'],
})
export class SubscriptionExpiredDialogComponent {
  constructor(private ref: DynamicDialogRef) {}

  onClose(): void {
    this.ref.close(true);
  }
}
