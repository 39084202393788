export interface IBookmarkedSpeakerEvent {
  event: string;
  bookmarkedSpeakerId: string;
  bookmarkedSpeakerName: string;
  bookmarkedSpeakerSurname: string;
  bookmarkedSpeakerCompany: string;
  bookmarkedSpeakerPosition: string;
  bookmarkedSpeakerEvent: string;
  bookmarkedSpeakerTags: string[];
  currentPage: string;
}
