export interface IStripeSessionCheckout {
  // this interface can contain more properties according to the stripe documentation,
  // so please update and add new properties if necessary
  id: string;
  url: string;
  customer_details: {
    email: string;
    name: string;
  };
}
