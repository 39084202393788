<ng-container *ngIf="loading">
  <app-loader></app-loader>
</ng-container>

<div
  *ngIf="!loading"
  class="container">
  <form [formGroup]="form">
    <!-- Ticket name & Details block -->
    <div class="form-block c-mb-9">
      <div class="left-side">
        <h2 class="title">{{ 'adminHubProfileForm.nameAndUrlTitle' | translate }}</h2>
        <p class="description">{{ 'adminHubProfileForm.nameAndUrlSubtitle' | translate }}</p>
      </div>
      <div class="right-side">
        <!-- Title input -->
        <div class="form-field-block">
          <app-input-label
            labelType="required"
            for="title-input"
            text="adminHubProfileForm.titleLabel">
          </app-input-label>
          <input
            class="custom-input-text custom-input-text-required"
            formControlName="title"
            id="title-input"
            pInputText
            type="text"
            placeholder="{{ 'adminHubProfileForm.titleLabel' | translate }}" />
          <app-input-error-message
            [showMessage]="form.controls.title.invalid && form.controls.title.touched"
            errorMessage="{{ 'application.forms.required' | translate }}">
          </app-input-error-message>
        </div>

        <!-- Headline input -->
        <div class="form-field-block">
          <app-input-label
            labelType="required"
            for="headline-input"
            text="adminHubProfileForm.headlineLabel">
          </app-input-label>
          <input
            class="custom-input-text custom-input-text-required"
            formControlName="headline"
            id="headline-input"
            pInputText
            type="text"
            placeholder="{{ 'adminHubProfileForm.headlineLabel' | translate }}" />
          <app-input-error-message
            [showMessage]="form.controls.headline.invalid && form.controls.headline.touched"
            [errorMessage]="
              form.controls.headline.errors?.maxlength
                ? ('adminHubProfileForm.headlineMaxLengthError' | translate)
                : ('application.forms.required' | translate)
            ">
          </app-input-error-message>
        </div>

        <!-- Tagline input -->
        <div class="form-field-block c-mb-4">
          <app-input-label
            for="tagline-input"
            text="adminHubProfileForm.taglineLabel">
          </app-input-label>
          <input
            class="custom-input-text"
            formControlName="tagline"
            id="tagline-input"
            pInputText
            type="text"
            placeholder="{{ 'adminHubProfileForm.taglineLabel' | translate }}" />
          <p class="input-note">
            {{ 'adminHubProfileForm.taglineInputNote' | translate }}
          </p>
        </div>

        <!-- Link suffix input -->
        <div class="form-field-block">
          <app-input-label
            labelType="required"
            for="link-input"
            text="adminHubProfileForm.linkSuffixLabel">
          </app-input-label>
          <div class="p-inputgroup custom-input-group">
            <span class="p-inputgroup-addon">{{ linkPrefix }}</span>
            <input
              formControlName="url"
              id="link-input"
              pInputText
              type="text"
              placeholder="{{ 'adminHubProfileForm.linkSuffixLabel' | translate }}" />
            <i
              *ngIf="form.controls.url.value.length"
              class="far fa-copy"
              [pTooltip]="!copyIsClicked ? tooltipText : null"
              tooltipPosition="top"
              tooltipStyleClass="custom-tooltip"
              (mouseleave)="copyIsClicked = false"
              (click)="copyToClipboard()">
            </i>
            <div
              *ngIf="form.controls.url.value.length && copyIsClicked"
              class="tooltip-copied">
              {{ tooltipText }}
            </div>
          </div>
          <app-input-error-message
            [showMessage]="form.controls.url.invalid && form.controls.url.dirty"
            [errorMessage]="urlErrorText">
          </app-input-error-message>
        </div>
      </div>
    </div>

    <div
      *ngIf="
        freshUser?.role === 'instanceOwner' ||
        freshUser?.role === 'softwareDeveloper' ||
        isUserInHubTeam
      "
      class="form-block c-mb-8">
      <div class="right-side">
        <div class="c-px-8 btn-container">
          @if (!isStripeConnected) {
            <app-button
              buttonText="{{ 'adminHub.connectWithStripeBtn' | translate }}"
              [buttonSize]="buttonSize.MEDIUM"
              [loading]="stripeLoading"
              (click)="toggleStripeConnect()" />
          }

          @if (isStripeConnected && stripeConnectId) {
            <app-button
              buttonText="{{ stripeBtnLabel | translate }}"
              [buttonIconLeft]="icon"
              [buttonStyle]="buttonStyle.TERTIARY_PRIMARY"
              [buttonSize]="buttonSize.MEDIUM"
              (click)="toggleStripeConnect()" />
          }

          @if (isStripeConnected && stripeConnectId && isExpressConnect) {
            <app-button
              buttonText="{{ 'adminSystemSettings.loginLinkBtn' | translate }}"
              [buttonSize]="buttonSize.MEDIUM"
              (click)="onCreateLoginLink()" />
          }

          <div class="stripe-section-description">
            {{ 'adminHub.stripeDescription' | translate }}
          </div>
          <div class="powered-by-stripe"></div>
        </div>
      </div>
    </div>

    <p-divider styleClass="custom-divider-horizontal"></p-divider>

    <!-- Design block -->
    <div class="form-block c-my-9">
      <div class="left-side">
        <h2 class="title">{{ 'adminHubProfileForm.designTitle' | translate }}</h2>
        <p class="description">{{ 'adminHubProfileForm.designSubtitle' | translate }}</p>
      </div>
      <div class="right-side">
        <!-- Logo Dark Image -->
        <div class="form-field-block c-mb-4">
          <app-logo-media-form
            titleMediaForm="adminHubProfileForm.designLogoTitleDark"
            description="adminHubProfileForm.designLogoDesc"
            btnConfirm="upload"
            btnCancel="clear"
            [imgValue]="form.controls.logoDark.value"
            (changeValue)="setLogoDarkValue($event)">
          </app-logo-media-form>
        </div>

        <!-- Logo Light Image -->
        <div class="form-field-block c-mb-4">
          <app-logo-media-form
            titleMediaForm="adminHubProfileForm.designLogoTitleLight"
            description="adminHubProfileForm.designLogoDesc"
            btnConfirm="upload"
            btnCancel="clear"
            [imgValue]="form.controls.logoLight.value"
            (changeValue)="setLogoLightValue($event)">
          </app-logo-media-form>
        </div>

        <!-- Icon image -->
        <div class="form-field-block c-mb-4">
          <app-icon-media-form
            titleMediaForm="adminHubProfileForm.designIconTitle"
            description="adminHubProfileForm.designIconDesc"
            btnConfirm="upload"
            btnCancel="clear"
            [imgValue]="form.controls.icon.value"
            (changeValue)="setIconValue($event)">
          </app-icon-media-form>
        </div>

        <!-- Banner image -->
        <div class="form-field-block c-mb-4">
          <app-big-profile-picture-media-form
            titleMediaForm="adminHubProfileForm.designBannerTitle"
            description="adminHubProfileForm.designBannerDesc"
            btnConfirm="upload"
            btnCancel="clear"
            objectFit="contain"
            [imgValue]="form.controls.banner.value"
            (changeValue)="setBannerValue($event)">
          </app-big-profile-picture-media-form>
        </div>

        <!-- Color picker for primary color -->
        <div class="form-field-block">
          <app-color-picker
            label="adminHubProfileForm.primaryColorLabel"
            [colorPickerValue]="form.controls.primaryColor.value"
            (selectedColor)="onSelectPrimaryColor($event)"
            (selectedColorError)="onSetPrimaryColorError($event)">
          </app-color-picker>
        </div>
      </div>
    </div>

    <p-divider styleClass="custom-divider-horizontal"></p-divider>

    <!-- Hub Settings & Features block -->
    <div class="form-block c-mt-9">
      <div class="left-side">
        <h2 class="title">{{ 'adminHubProfileForm.settingsTitle' | translate }}</h2>
        <p class="description">{{ 'adminHubProfileForm.settingsSubtitle' | translate }}</p>
      </div>
      <div class="right-side">
        <!-- Private Hub (Invitation-Only) input -->
        <div class="form-field-input-switch-block c-mb-4">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="isPrivate"
            inputId="is-private-input">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="is-private-input"
            text="adminHubProfileForm.isPrivate">
          </app-input-label>
        </div>

        <!-- Maintenance Mode input -->
        <div class="form-field-input-switch-block c-mb-4">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="isMaintenance"
            inputId="is-maintenance-input">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="is-maintenance-input"
            text="adminHubProfileForm.isMaintenance">
          </app-input-label>
        </div>

        <!-- Events input -->
        <div class="form-field-input-switch-block c-mb-4">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="events"
            inputId="events-input">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="events-input"
            text="adminHubProfileForm.events">
          </app-input-label>
        </div>

        <!-- Academy input -->
        <div class="form-field-input-switch-block c-mb-4">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="academy"
            inputId="academy-input">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="academy-input"
            text="adminHubProfileForm.academy">
          </app-input-label>
        </div>

        <!-- Brands input -->
        <div class="form-field-input-switch-block c-mb-4">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="brands"
            inputId="brands-input">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="brands-input"
            text="adminHubProfileForm.brands">
          </app-input-label>
        </div>

        <!-- Is Salesforce SSO input -->
        <div class="form-field-input-switch-block">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="isSalesforceSSO"
            inputId="is-salesforce-sso-input">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="is-salesforce-sso-input"
            text="adminHubProfileForm.signUpWithW&V">
          </app-input-label>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="save-discard-actions-container">
  <app-save-discard-actions
    [isShow]="canUpdate"
    [isLoading]="isUpdating"
    (discard)="onDiscard()"
    (confirm)="onConfirm()">
  </app-save-discard-actions>
</div>
