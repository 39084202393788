import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { asyncDelay } from 'src/app/core/utils';

@Injectable({
  providedIn: 'root',
})
export class BrowserDefaultVideoPlayerService {
  private video: HTMLVideoElement;
  private _videoPlay = new Subject<void>();
  private _videoPause = new Subject<{ seconds: number; percent: number; duration: number }>();
  private _videoTimeUpdate = new Subject<{ seconds: number; percent: number; duration: number }>();
  private _videoEnd = new Subject<void>();

  get videoPlay(): Observable<void> {
    return this._videoPlay.asObservable();
  }

  get videoPause(): Observable<{ seconds: number; percent: number; duration: number }> {
    return this._videoPause.asObservable();
  }

  get videoTimeUpdate(): Observable<{ seconds: number; percent: number; duration: number }> {
    return this._videoTimeUpdate.asObservable();
  }

  get videoEnd(): Observable<void> {
    return this._videoEnd.asObservable();
  }

  startManagingVideoPlayer(elementId: string): HTMLVideoElement {
    this.video = document.getElementById(elementId) as HTMLVideoElement;
    if (this.video) {
      this.startListeners();
    }

    return this.video;
  }

  play(): void {
    this.video.play();
  }

  pause(): void {
    this.video.pause();
  }

  setCurrentTime(currentTime: number): void {
    this.video.currentTime = currentTime;
  }

  async destroy(): Promise<void> {
    if (this.video) {
      this.pause();
      await asyncDelay(100);
      this.video?.removeEventListener('play', this.playListener.bind(this));
      this.video?.removeEventListener('pause', this.pauseListener.bind(this));
      this.video?.removeEventListener('timeupdate', this.timeUpdateListener.bind(this));
      this.video?.removeEventListener('ended', this.endedListener.bind(this));
    }
    this.video = null;
  }

  private startListeners(): void {
    this.video.addEventListener('play', this.playListener.bind(this));
    this.video.addEventListener('pause', this.pauseListener.bind(this));
    this.video.addEventListener('timeupdate', this.timeUpdateListener.bind(this));
    this.video.addEventListener('ended', this.endedListener.bind(this));
  }

  private playListener(): void {
    this._videoPlay.next();
  }

  private pauseListener(): void {
    this._videoPause.next(this.createNewValueForListeners());
  }

  private timeUpdateListener(): void {
    this._videoTimeUpdate.next(this.createNewValueForListeners());
  }

  private endedListener(): void {
    this._videoEnd.next();
  }

  private createNewValueForListeners(): { seconds: number; percent: number; duration: number } {
    const newValue: { seconds: number; percent: number; duration: number } = {
      seconds: this.video.currentTime,
      percent: this.video.currentTime / this.video.duration,
      duration: this.video.duration,
    };

    return newValue;
  }
}
