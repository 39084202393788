<div class="default-covers grid">
  <div
    *ngFor="let img of defaultCoverImages"
    class="col-4 cover-image-container">
    <img
      src="{{ img | imageSize }}"
      alt=""
      class="cover-image"
      (click)="setDefaultCover(img)" />
  </div>
</div>
