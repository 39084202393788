<div class="speaker-view-container grid">
  <div
    class="user-container lg:col-8 md:col-8 sm:col-12 col-12"
    [ngClass]="getUserContainerClass">
    <app-user-details
      [userId]="userId"
      [userType]="userType"
      [showBackBtn]="false">
    </app-user-details>
  </div>

  <div
    *ngIf="isSpeaker"
    class="lg:col-4 md:col-4 sm:col-12 col-12 user-connected-data fullHeight">
    <div class="user-connected-data-inner fullHeight overflow-y-scroll">
      <ng-container *ngIf="loadingSessions">
        <session-card-skeleton
          class="flex mb-5"
          *ngFor="let i of [0, 1, 2, 3, 4]"></session-card-skeleton>
      </ng-container>
      <ng-container *ngIf="speakerSessions.length">
        <app-session-card
          *ngFor="let session of speakerSessions"
          [session]="session"
          (onClose)="ref.close()">
        </app-session-card>
      </ng-container>
      <div
        *ngIf="!speakerSessions.length && !loadingSessions"
        class="empty-list">
        <app-empty-state
          [icon]="'fak fa-lox-calendar'"
          text="{{ 'speakerDetails.emptySessions' | translate }}">
        </app-empty-state>
      </div>
    </div>
  </div>
</div>
