import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subject, from } from 'rxjs';
import { tap, map, switchMap, takeUntil, filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { ClipboardService } from 'ngx-clipboard';

import { IHubPage } from 'src/app/core/models';
import { HubPagesService } from 'src/app/core/services';
import { SharedModule } from 'src/app/shared';
import { EventPagePreviewComponent } from 'src/app/admin';
import { ToastComponent } from 'src/app/standalone/shared';

@Component({
  selector: 'app-hub-page',
  standalone: true,
  imports: [CommonModule, SharedModule, ToastComponent],
  templateUrl: './hub-page.component.html',
  styleUrls: ['./hub-page.component.scss'],
})
export class HubPageComponent implements OnInit, OnDestroy {
  hubPage: IHubPage;
  loading = true;
  saving = false;
  form: FormGroup;
  languages: { name: string; code: string }[] = [];
  isEditing: boolean = false;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private hubPageService: HubPagesService,
    private translateService: TranslateService,
    private dialogService: DialogService,
    private messageService: MessageService,
    private clipboardService: ClipboardService,
    private hubPagesService: HubPagesService,
  ) {}

  ngOnInit(): void {
    this.route.params
      .pipe(
        map(({ pageId }) => pageId),
        filter((pageId: string) => !!pageId),
        switchMap((pageId: string) => from(this.hubPageService.getHubPage(pageId))),
        tap((hubPage: IHubPage) => {
          this.hubPage = hubPage;
          this.updateForm();
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();

    this.languages = this.translateService.getLangs().map((lang: string) => {
      return { name: this.translateService.instant('languages.' + lang), code: lang };
    });

    this.createForm();
    this.loading = false;
  }

  private createForm(): void {
    this.form = new FormGroup({
      title: new FormControl('', Validators.required),
      language: new FormControl('', Validators.required),
      status: new FormControl(false),
      isMobile: new FormControl(false),
      url: new FormControl(''),
      html: new FormControl('', Validators.required),
    });
  }

  private updateForm(): void {
    this.form.patchValue({
      ...this.hubPage,
      language: this.languages.find(
        (lang: { name: string; code: string }) => lang.code === this.hubPage.language,
      ),
    });
  }

  public preview(): void {
    this.dialogService.open(EventPagePreviewComponent, {
      width: '90%',
      height: '90%',
      dismissableMask: true,
      contentStyle: {
        overflow: 'auto',
        padding: '0',
        'border-radius': '20px',
      },
      data: {
        html: this.form.controls.html.value,
      },
    });
  }

  copy(): void {
    const value = this.form.get('html').value ? this.form.get('html').value : '';
    this.clipboardService.copy(value);
  }

  async save(): Promise<void> {
    if (!this.form.valid) {
      return;
    }

    try {
      this.saving = true;
      const form = this.form.value;

      const newPage: IHubPage = {
        ...this.hubPage,
        ...form,
        _title_: form.title.toLowerCase(),
        language: form.language.code,
        isDeleted: false,
      };

      await this.hubPagesService.updateHubPage(newPage);

      this.showToastMessage('success', this.translateService.instant('hubPage.successSave'));
      this.saving = false;
    } catch (error) {
      console.log(error);
      this.showToastMessage('error', this.translateService.instant('hubPage.errorSave'));
      this.saving = false;
    }
  }

  private showToastMessage(severity: 'success' | 'error', detail: string): void {
    this.messageService.add({
      severity: severity,
      summary: this.translateService.instant(severity),
      detail: this.translateService.instant(detail),
      styleClass: 'custom-toast',
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
