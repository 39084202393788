import { Router } from '@angular/router';
import { UsersStore } from 'src/app/core/stores';
import { UsersService } from './../../core/services/users/users.service';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Route,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AdminUserAccessGuard {
  constructor(
    private router: Router,
    private usersService: UsersService,
    private usersStore: UsersStore,
  ) {}

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    let currentEntry = this.router.getCurrentNavigation().extras.state
      ? this.router.getCurrentNavigation().extras.state.entry
        ? this.router.getCurrentNavigation().extras.state.entry
        : null
      : null;

    if (!currentEntry) {
      currentEntry = await this.usersService.getOne(next.params.userId);
    }

    this.usersStore.setAdminUser(currentEntry);
    return currentEntry ? true : false;
  }
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.usersStore.unsetAdminUser();
    return true;
  }
  canLoad(
    route: Route,
    segments: UrlSegment[],
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
}
