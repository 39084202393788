import { Timestamp } from 'src/app/firebase';

export interface IOrder {
  id: string;
  eventId: string;
  ticketId: string;
  ticketName: string;
  _ticketName_: string;
  orderId: string; // generate it with uuid4 lib
  orderedBy: string; // user who created the order
  bookedBy: string; // user who use booking code to book the ticket
  bookedAt: Timestamp; // when code was booked
  bookingCode: number; // auto generated
  assignedEmail: string; // email for which booking code is reserved
  assignedAt: Timestamp;
  assignedBy: string;
  reserved: boolean;
  stripeCheckoutSessionId?: string;
  userEmail?: string;
  createdAt: Timestamp;
  createdBy: string;
  updatedAt: Timestamp;
  updatedBy: string;
  downloadingPdfFile?: boolean;
}
