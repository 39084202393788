import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FilterMatchMode, MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { autorun, IReactionDisposer } from 'mobx';
import { ClipboardService } from 'ngx-clipboard';

import {
  EventsService,
  BrandsService,
  UsersService,
  OpenDialogService,
  EmailsService,
} from 'src/app/core/services';
import { IAdminTableColumn } from 'src/app/shared';
import { IEmail, IEvent, ITag, IUser } from 'src/app/core/models';
import { EventsStore, HubsStore } from 'src/app/core/stores';
import { SelectEmailForEventUserComponent } from 'src/app/admin';
import { ButtonSize, ButtonStyle, ButtonType } from 'src/app/standalone';

export interface IBrandPerson {
  id: string;
  firstName: string;
  lastName: string;
  company: string;
  displayEmail: string;
  email: string;
  isActive: boolean;
  brandId: string;
  isOwner: boolean;
}

@Component({
  selector: 'app-event-brand-people',
  templateUrl: './event-brand-people.component.html',
  styleUrls: ['./event-brand-people.component.scss'],
})
export class EventBrandPeopleComponent implements OnInit, OnDestroy {
  loading = true;
  loadingEntries = true;
  searchValue = '';
  searchFilterFields: string[] = [
    'name',
    'firstName',
    'lastName',
    'company',
    'email',
    'eventStatus',
    'isOwner',
  ];
  columns: IAdminTableColumn[] = [
    { field: 'firstName', titleTranslationKey: 'adminEventBrandPeople.tableCells.firstName' },
    { field: 'lastName', titleTranslationKey: 'adminEventBrandPeople.tableCells.lastName' },
    { field: 'company', titleTranslationKey: 'adminEventBrandPeople.tableCells.company' },
    { field: 'email', titleTranslationKey: 'adminEventBrandPeople.tableCells.email' },
    {
      field: 'eventStatus',
      titleTranslationKey: 'adminEventBrandPeople.tableCells.status',
      template: 'statusCell',
      filterable: true,
      filterMatchMode: FilterMatchMode.IN,
      filterOptions: [
        { name: 'Pending', value: 'pending' },
        { name: 'Active', value: 'active' },
      ],
      filterType: 'text',
    },
    {
      field: 'isOwner',
      titleTranslationKey: 'adminEventBrandPeople.tableCells.brandOwner',
      template: 'ownerCell',
    },
    { field: 'actions', template: 'actionsCell' },
  ];
  entries: (IUser & { name: string })[] = [];
  selectedEntries: IUser[] = [];
  tags: ITag[] = [];
  sidebarVisible = false;
  userIdForSidebar: string;
  buttonType = ButtonType;
  buttonSize = ButtonSize;
  buttonStyle = ButtonStyle;

  private disposer: IReactionDisposer;

  constructor(
    public hubsStore: HubsStore,
    private router: Router,
    private route: ActivatedRoute,
    private brandService: BrandsService,
    private eventsService: EventsService,
    private eventStore: EventsStore,
    private usersService: UsersService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private clipboardService: ClipboardService,
    private openDialogService: OpenDialogService,
    private dialogService: DialogService,
    private emailsService: EmailsService,
  ) {}

  get event(): IEvent {
    return this.eventStore.adminEvent;
  }

  ngOnInit(): void {
    this.disposer = autorun(async () => {
      if (this.event) {
        this.loading = true;
        this.loadingEntries = true;
        await this.fetchBrandPeople();
        this.loadingEntries = false;
        this.loading = false;
      }
    });
  }

  async sendInvites(): Promise<void> {
    try {
      const selectEmailDialogRef: DynamicDialogRef = this.dialogService.open(
        SelectEmailForEventUserComponent,
        {
          width: '90%',
          height: '80%',
          dismissableMask: true,
          closable: false,
          contentStyle: {
            padding: '0',
            'border-radius': '20px',
          },
          data: {
            userType: 'brandpeople',
          },
        },
      );
      const email: IEmail = await firstValueFrom(selectEmailDialogRef.onClose.pipe(take(1)));

      if (!email) {
        return;
      }

      const deleteDialogRef: DynamicDialogRef = this.openDialogService.openConfirmDialog(
        'adminEventBrandPeople.sendUserInvitesDialog.header',
        this.translateService.instant('adminEventBrandPeople.sendUserInvitesDialog.message', {
          totalUsers: this.selectedEntries.length,
        }),
        'buttons.confirm',
        'buttons.cancel',
      );
      const result: 'cancel' | 'confirm' = await firstValueFrom(
        deleteDialogRef.onClose.pipe(take(1)),
      );

      if (result === 'cancel') {
        return;
      }

      await Promise.all(
        this.selectedEntries.map((brandPerson: IUser) =>
          this.emailsService.sendInviteWithEmailTemplate(
            this.event.id,
            brandPerson.id,
            email.id,
            'event',
          ),
        ),
      );

      this.showToastMessage('success', 'adminEventBrandPeople.sendUserInvitesSuccessMessage');
    } catch (error) {
      this.showToastMessage('error', 'adminEventBrandPeople.sendUserInvitesErrorMessage');
    }
  }

  sendOwnerInvites(): void {
    const deleteDialogRef: DynamicDialogRef = this.openDialogService.openConfirmDialog(
      'adminEventBrandPeople.sendUserInvitesDialog.header',
      this.translateService.instant('adminEventBrandPeople.sendUserInvitesDialog.message', {
        totalUsers: this.selectedEntries.length,
      }),
      'buttons.confirm',
      'buttons.cancel',
    );

    deleteDialogRef.onClose
      .pipe(filter((result: 'cancel' | 'confirm') => result === 'confirm'))
      .subscribe(() =>
        this.selectedEntries.forEach((user) =>
          this.brandService
            .addEventBrandOwner(this.event.id, user.id, user.email, user.brandId)
            .then(() =>
              this.messageService.add({
                severity: 'success',
                summary: this.translateService.instant('success'),
                detail: this.translateService.instant(
                  'adminEventBrandPeople.sendUserInvitesSuccessMessage',
                ),
                styleClass: 'custom-toast',
              }),
            )
            .catch(() =>
              this.messageService.add({
                severity: 'error',
                summary: this.translateService.instant('error'),
                detail: this.translateService.instant(
                  'adminEventBrandPeople.sendUserInvitesErrorMessage',
                ),
                styleClass: 'custom-toast',
              }),
            ),
        ),
      );
  }

  onCloseSidebar(): void {
    this.sidebarVisible = false;
  }

  onRowClicked(id: string): void {
    if (this.hubsStore.hub.isShowSidePanel) {
      this.sidebarVisible = true;
      this.userIdForSidebar = id;

      return;
    }

    this.router.navigate([id, 'event-details'], { relativeTo: this.route });
  }

  navigateToUser(event: Event, user: IUser): void {
    event.stopPropagation();
    if (this.hubsStore.hub.isShowSidePanel) {
      this.sidebarVisible = true;
      this.userIdForSidebar = user.id;

      return;
    }

    this.router.navigate([user.id, 'event-details'], { relativeTo: this.route });
  }

  async copyInvite(event: Event, user: IUser & { name: string }): Promise<void> {
    event.stopPropagation();
    let messageSeverity: 'success' | 'error';
    let messageSummary: string;
    this.entries[this.entries.indexOf(user)].isActionBtnShowingSpinner = true;

    try {
      // get user invite url
      const inviteUrl = await this.usersService.getUserInviteUrl(
        this.event.id,
        user.id,
        user?.isOwner,
      );
      // copy the link to the clipboard
      if (inviteUrl) {
        this.clipboardService.copy(inviteUrl);
      } else {
        this.clipboardService.copy(
          this.translateService.instant('adminEvents.copyInviteLinkEmpty'),
        );
      }
      messageSeverity = inviteUrl ? 'success' : 'error';
      messageSummary = inviteUrl
        ? this.translateService.instant('adminEvents.copyInviteLinkSuccess')
        : user.eventStatus === 'active'
          ? this.translateService.instant('adminEvents.copyInviteLinkWarnActiveUser')
          : this.translateService.instant('adminEvents.copyInviteLinkWarn');
    } catch (error) {
      messageSeverity = 'error';
      messageSummary = this.translateService.instant('adminEvents.copyInviteLinkError');
      console.warn(error);
    }

    this.messageService.add({
      key: 'copyInvite',
      severity: messageSeverity,
      summary: messageSummary,
      styleClass: 'custom-toast',
    });

    this.entries[this.entries.indexOf(user)].isActionBtnShowingSpinner = false;
  }

  ngOnDestroy(): void {
    this.disposer();
  }

  private async fetchBrandPeople(): Promise<void> {
    this.entries = (await this.eventsService.getEventBrandPeople(this.event.id)).map((e) => ({
      ...e,
      name: `${e.firstName.trim()} ${e.lastName.trim()}`,
    }));
  }

  private showToastMessage(severity: 'success' | 'error', message: string): void {
    this.messageService.add({
      severity,
      summary: this.translateService.instant(severity),
      detail: this.translateService.instant(message),
      styleClass: 'custom-toast',
    });
  }
}
