<ng-container *ngIf="loading">
  <app-loader></app-loader>
</ng-container>

<ng-container *ngIf="!loading">
  <div class="header">
    <div class="title">
      {{ 'adminEmailTemplates.attachEmailsTitles' | translate }}
    </div>
    <span
      class="search-input p-input-icon-left"
      [ngClass]="{ 'p-input-icon-right': filterSearch.value !== '' }">
      <i class="far fa-search search-icon"></i>
      <input
        class="custom-input-text"
        type="text"
        #filterSearch
        name="filterSearch"
        pInputText
        placeholder="{{ 'adminTable.inputSearchPlaceholder' | translate }}"
        (input)="searchChanged($event.target.value)" />
      <i
        *ngIf="filterSearch.value !== ''"
        class="reset-btn far fa-times-circle"
        (click)="filterSearch.value = ''">
      </i>
    </span>
    <div class="btn-container">
      <app-button
        buttonText="{{ 'adminEmailTemplates.attach' | translate }}"
        [buttonSize]="buttonSize.MEDIUM"
        [loading]="attachLoading"
        [disabled]="!selectedEntries.length"
        (click)="attach()" />
      <i
        class="fa-regular fa-xmark close-icon"
        (click)="onCloseDialog()"></i>
    </div>
  </div>

  <div class="container">
    <admin-table
      scssClass="table-for-attach show-row-border-bottom"
      checkBoxSelection="true"
      emptyStateKey="adminEmailTemplates.emptyState"
      [loading]="loadingEntries"
      [columns]="columns"
      [entries]="entries"
      [pageSize]="pageSize"
      [paginator]="true"
      [lazy]="false"
      [searchValue]="searchTerm"
      [searchFilterFields]="searchFilterFields"
      [(selectedEntries)]="selectedEntries">
    </admin-table>
  </div>

  <p-toast key="email">
    <ng-template
      let-message
      pTemplate="message">
      <app-toast
        [severity]="message.severity"
        [summary]="message.summary"
        [detail]="message.detail">
      </app-toast>
    </ng-template>
  </p-toast>
</ng-container>
