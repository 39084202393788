import { Component, OnInit, inject, signal } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateModule } from '@ngx-translate/core';

import { IEventMenuOption } from 'src/app/core/models';
import { AdminEventMenuItemsOrganizerComponent } from '../../components';

@Component({
  selector: 'app-event-menu-items-dialog',
  standalone: true,
  imports: [TranslateModule, AdminEventMenuItemsOrganizerComponent],
  templateUrl: './event-menu-items-dialog.component.html',
  styleUrl: './event-menu-items-dialog.component.scss',
})
export class EventMenuItemsDialogComponent implements OnInit {
  title = signal<string>('');
  items = signal<IEventMenuOption[]>(null);

  private ref = inject(DynamicDialogRef);
  private config = inject(DynamicDialogConfig);

  ngOnInit(): void {
    this.title.set(this.config.data.title);
    this.items.set(this.config.data.items);
  }

  closeDialog(): void {
    this.ref.close();
  }
}
