import { Component, Input } from '@angular/core';

@Component({
  selector: 'image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
})
export class ImageComponent {
  @Input() src: string;
  @Input() text: string;
  @Input() size: string;

  sizes: string[] = ['super-small', 'extra-small', 'small', 'medium', 'large'];
  imageSize: string;

  ngOnInit(): void {
    let index = this.sizes.indexOf(this.size);
    this.imageSize = this.sizes[index != -1 ? index : 1];
  }
}
