<div class="language-dialog-container">
  <header class="header">
    <h1>{{ pageTitle | translate }}</h1>
    <i
      class="fa-regular fa-xmark close-icon"
      (click)="onCloseDialog()">
    </i>
  </header>
  <form [formGroup]="form">
    <div class="grid">
      <!-- Translations input -->
      <div class="col-10">
        <app-input-label
          labelType="required"
          text="adminSystemTranslations.translations">
        </app-input-label>
        <textarea
          class="custom-input-textarea resize-none custom-input-textarea-required textarea"
          pInputTextarea
          [formControl]="form.controls.translations"
          placeholder="{{ 'adminSystemTranslations.translations' | translate }}">
        </textarea>
      </div>

      <div class="col-2">
        <!-- Title input -->
        <div>
          <app-input-label
            labelType="required"
            for="title-input"
            text="adminSystemTranslations.langFormTitleLabel">
          </app-input-label>
          <input
            class="custom-input-text custom-input-text-required"
            formControlName="title"
            id="title-input"
            pInputText
            type="text"
            placeholder="{{ 'adminSystemTranslations.langFormTitleLabel' | translate }}" />
          <app-input-error-message
            [showMessage]="form.controls.title.invalid && form.controls.title.touched"
            errorMessage="{{ 'adminSystemTranslations.langFormTitleReq' | translate }}">
          </app-input-error-message>
        </div>

        <!-- Code input -->
        <div>
          <app-input-label
            labelType="required"
            for="code-input"
            text="adminSystemTranslations.langFormCodeLabel">
          </app-input-label>
          <input
            class="custom-input-text custom-input-text-required"
            formControlName="code"
            id="code-input"
            pInputText
            type="text"
            placeholder="{{ 'adminSystemTranslations.langFormCodeLabel' | translate }}" />
          <app-input-error-message
            [showMessage]="form.controls.code.invalid && form.controls.code.touched"
            errorMessage="{{ 'adminSystemTranslations.langFormCodeReq' | translate }}">
          </app-input-error-message>
        </div>

        <div class="actions-container">
          <app-button
            buttonText="{{ 'adminSystemTranslations.langFormConfirm' | translate }}"
            [loading]="saving"
            [buttonSize]="buttonSize.MEDIUM"
            [disabled]="form.invalid"
            (click)="save()" />
        </div>
      </div>
    </div>
  </form>
</div>
