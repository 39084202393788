import * as moment from 'moment';
import { Component, computed, OnDestroy, OnInit, signal } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

import {
  ConfirmDialogComponent,
  imgPreloader,
  logoAvatar,
  IAdminTableColumn,
  parseToMoment,
} from 'src/app/shared';
import { EventsService, UserHubsService } from 'src/app/core/services';
import { IEvent, IHub, IUser, IUserHub, UserHubRoles } from 'src/app/core/models';
import { HubsStore, UsersStore } from 'src/app/core/stores';
import { Timestamp } from 'src/app/firebase';
import { AppStore } from 'src/app/app.store';
import { ButtonSize, ButtonStyle, ButtonType } from 'src/app/standalone/shared/enums';
import { NewEventComponent } from '../../../dialogs';

@Component({
  selector: 'app-events-list',
  templateUrl: './events-list.component.html',
  styleUrls: ['./events-list.component.scss'],
})
export class EventsListComponent implements OnInit, OnDestroy {
  loading = true;
  loadingEntries = true;
  columns: IAdminTableColumn[] = [
    {
      field: 'title',
      template: 'titleCell',
      titleTranslationKey: 'adminEvents.tableColTitle',
    },
    {
      field: 'location',
      titleTranslationKey: 'adminEvents.tableColLocation',
    },
    {
      field: 'status',
      template: 'statusCell',
      titleTranslationKey: 'adminEvents.tableColStatus',
    },
    {
      field: '',
      template: 'actionsCell',
    },
  ];
  entries: IEvent[] = [];
  totalRecords = 0;
  pageSize = 25;
  imgPreloader = imgPreloader;
  logoAvatar = logoAvatar;
  buttonType = ButtonType;
  buttonSize = ButtonSize;
  buttonStyle = ButtonStyle;
  currentUserHub = signal<IUserHub>(null);
  canCreateNewEvent = computed(() => {
    const userHub: IUserHub = this.currentUserHub();
    if (!userHub) {
      return null;
    }

    return userHub.role === UserHubRoles.ADMIN || userHub.role === UserHubRoles.OWNER;
  });

  private searchTerm = '';
  private subscriptions = new Subscription();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private messageService: MessageService,
    private dialogService: DialogService,
    private eventsService: EventsService,
    private translateService: TranslateService,
    public appStore: AppStore,
    public hubsStore: HubsStore,
    private usersStore: UsersStore,
    private userHubsService: UserHubsService,
  ) {}

  get user(): IUser {
    return this.usersStore.user;
  }

  get hub(): IHub {
    return this.hubsStore.hub;
  }

  async ngOnInit(): Promise<void> {
    const userHub: IUserHub = await this.userHubsService.getUserHub(this.hub.id, this.user.id);
    this.currentUserHub.set(userHub);
    await this.init();

    this.subscriptions.add(
      this.router.events
        .pipe(filter((e) => e instanceof NavigationEnd))
        .subscribe(async () => await this.init()),
    );
  }

  async onSearchTermChange(e: string): Promise<void> {
    this.loadingEntries = true;
    this.searchTerm = e;
    await this.fetchEvents(0);
    this.loadingEntries = false;
  }

  async fetchEntries(event: any): Promise<void> {
    this.loadingEntries = true;
    this.pageSize = event.rows;
    await this.fetchEvents(event.first / this.pageSize);
    this.loadingEntries = false;
  }

  getEventDate(event: IEvent): string {
    const lang: string = this.translateService.currentLang;
    const localLocaleStartDate = moment(parseToMoment(event.start));
    const localLocaleEndDate = moment(parseToMoment(event.end));
    localLocaleStartDate.locale(lang);
    localLocaleEndDate.locale(lang);
    if (moment(parseToMoment(event.start)).isSame(parseToMoment(event.end), 'day')) {
      return `${localLocaleStartDate.format('LLL')} - ${localLocaleEndDate.format('LT')}`;
    }

    return `${localLocaleStartDate.format('LL')} - ${localLocaleEndDate.format('LL')}`;
  }

  async openNewEventDialog(): Promise<void> {
    const newEventDialogRef: DynamicDialogRef = this.dialogService.open(NewEventComponent, {
      width: '80%',
      height: '85%',
      closable: false,
      styleClass: 'attach-new-event-dialog',
    });

    const payload: IEvent = await newEventDialogRef.onClose.pipe(take(1)).toPromise();
    if (payload) {
      this.router.navigate([
        `/${this.hubsStore.useHubUrl}/admin/event/${payload?.link}/setup/details`,
      ]);
    }
  }

  async onRowClicked(id: string): Promise<void> {
    const events: IEvent[] = await this.eventsService.getById(id);
    this.router.navigate([`/${this.hubsStore.useHubUrl}/admin/event/${events[0].link}`]);
  }

  edit(event: Event, entry: IEvent): void {
    event.stopPropagation();
    this.router.navigate([`/${this.hubsStore.useHubUrl}/admin/event/${entry.link}`], {
      relativeTo: this.activatedRoute,
      state: {
        event: entry,
      },
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private async fetchEvents(page: number): Promise<void> {
    try {
      const response = await this.eventsService.fetch(
        this.hubsStore.hub?.id ?? null,
        page,
        this.pageSize,
        this.searchTerm,
      );
      this.entries = response.results.map((event) => {
        event.start = event.start ? Timestamp.fromDate(new Date(event.start)) : undefined;
        event.end = event.end ? Timestamp.fromDate(new Date(event.end)) : undefined;
        return event;
      });
      this.totalRecords = response.total;
    } catch (error) {
      this.loadingEntries = false;
    }
  }

  private delete(entry: IEvent): void {
    const deleteDialogRef = this.dialogService.open(ConfirmDialogComponent, {
      closable: false,
      styleClass: 'confirm-dialog',
      data: {
        titleKey: 'adminEvents.deleteEvent',
        descriptionKey: 'adminEvents.deleteEventText',
        confirmBtnKey: 'adminEvents.confirmBtn',
        cancelBtnKey: 'adminEvents.cancelBtn',
        entry,
      },
    });

    deleteDialogRef.onClose.pipe(take(1)).subscribe(async (result: 'cancel' | 'confirm') => {
      if (result === 'confirm') {
        try {
          const deleteResult = await this.eventsService.delete(entry.id);

          if (deleteResult) {
            this.messageService.add({
              severity: 'success',
              summary: this.translateService.instant('success'),
              detail: this.translateService.instant('adminEvents.succesDeleteEvent'),
              styleClass: 'custom-toast',
            });
          }
        } catch (error) {
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('success'),
            detail: this.translateService.instant('adminEvents.errorDeleteEvent'),
            styleClass: 'custom-toast',
          });
        }
      }
    });
  }

  private async init(): Promise<void> {
    await this.fetchEvents(0);
    this.loadingEntries = false;
    this.loading = false;
  }
}
