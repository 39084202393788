import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

export class FormCustomValidator {
  static containSpace(control: AbstractControl): ValidationErrors | null {
    if ((control.value as string).indexOf(' ') >= 0) {
      return { containSpace: true };
    }
    return null;
  }

  static noPasswordMatch(control: AbstractControl): ValidationErrors | null {
    const password: string = control.get('password').value;
    const confirmPassword: string = control.get('confirmPassword').value;

    if (password !== confirmPassword) {
      control.get('confirmPassword').setErrors({ noPasswordMatch: true });
      return { noPasswordMatch: true };
    }
    return null;
  }
}

// check if the string is a hex color representation
export function validateStringAsHex(): ValidatorFn {
  return Validators.pattern('#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$');
}

// check if the string includes only numbers and the first char should be from 1 to 9
export function validNumbers(): ValidatorFn {
  return Validators.pattern('^[1-9][0-9]*$');
}
