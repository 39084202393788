<div class="container">
  <header class="header">
    <p-dropdown
      class="custom-dropdown"
      panelStyleClass="custom-dropdown-panel"
      [options]="templates()"
      [formControl]="currentTemplate"
      optionLabel="name"
      placeholder="Template">
    </p-dropdown>

    <div class="flex align-items-center gap-2">
      <app-button
        buttonText="{{ 'adminEmailTemplates.save' | translate }}"
        [buttonSize]="buttonSize().MEDIUM"
        [disabled]="!isUnlayerEditorReady()"
        (click)="onSaveTemplate()" />
      <app-cross-icon (click)="onCloseDialog()" />
    </div>
  </header>
  <main class="main">
    <div
      id="editor"
      style="width: 100%; height: 100%"></div>
  </main>
</div>
