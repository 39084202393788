<ng-container *ngIf="loading">
  <app-loader></app-loader>
</ng-container>

<div
  *ngIf="!loading"
  class="edit-email-container">
  <h1>{{ email ? email.title : ('adminEmailTemplate.editEmailTitle' | translate) }}</h1>

  <div class="c-mt-4 c-mb-8">
    <p-divider styleClass="custom-divider-horizontal"></p-divider>
  </div>
  <app-system-email-editor
    *ngIf="!email.isHtmlMode && !email.isUnlayer"
    [email]="email"></app-system-email-editor>
  <app-system-email-html-editor
    *ngIf="email.isHtmlMode"
    [email]="email"></app-system-email-html-editor>
  <app-system-email-unlayer-editor
    *ngIf="email.isUnlayer"
    [email]="email"
    (emailChange)="onEmailChanged($event)"></app-system-email-unlayer-editor>
</div>
