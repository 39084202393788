import * as moment from 'moment';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { merge, Subject } from 'rxjs';
import { debounceTime, filter, takeUntil, tap } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { InputNumber } from 'primeng/inputnumber';
import { TranslateService } from '@ngx-translate/core';

import {
  IGeneralSettings,
  ISession,
  IStage,
  ITicket,
  IUser,
  ProductPricePair,
  ticketTypes,
} from 'src/app/core/models';
import {
  FormService,
  SessionsService,
  SettingsService,
  StagesService,
  StripeService,
  TicketsService,
} from 'src/app/core/services';
import { EventsStore, TicketsStore, UsersStore, HubsStore } from 'src/app/core/stores';
import { updateTime, goToLink, validNumbers, asyncDelay } from 'src/app/core/utils';
import { Timestamp } from 'src/app/firebase';
import { parseToMoment } from 'src/app/shared';
import { StripeInformationDialogComponent } from '../../dialogs/stripe-information/stripe-information-dialog.component';
import { EventTypes, TicketTypes, CurrencyCode } from 'src/app/core/enums';

@Component({
  selector: 'app-admin-ticket-form',
  templateUrl: './admin-ticket-form.component.html',
  styleUrls: ['./admin-ticket-form.component.scss'],
})
export class AdminTicketFormComponent implements OnInit, OnDestroy {
  @ViewChild('inputNumberMin') inputNumberMin: InputNumber;
  @ViewChild('inputNumberMax') inputNumberMax: InputNumber;

  loading = true;
  form: UntypedFormGroup;
  currencies = Object.keys(CurrencyCode);
  isDescriptionDisabled = false;
  isUpdating = false;
  sessions: ISession[];
  stages: IStage[];
  minDate: Date = moment().toDate();
  goToLink = goToLink;
  stripeConnectId = null;
  ticketTypes = ticketTypes;
  isExistingTicket = false;

  private defaultEndDate: Date = moment().add(1, 'days').toDate();
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private fb: UntypedFormBuilder,
    private ticketsStore: TicketsStore,
    private eventsStore: EventsStore,
    private hubsStore: HubsStore,
    private sessionsService: SessionsService,
    private stagesService: StagesService,
    private ticketsService: TicketsService,
    private messageService: MessageService,
    private usersStore: UsersStore,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private stripeService: StripeService,
    private router: Router,
    private formService: FormService,
    private settingsService: SettingsService,
  ) {}

  get user(): IUser {
    return this.usersStore.user;
  }

  get canUpdate(): boolean {
    return (
      !this.isUpdating && this.form.valid && this.form.dirty && this.formService.isValueChanged()
    );
  }

  get ticket(): ITicket {
    return this.ticketsStore.adminTicket;
  }

  async ngOnInit(): Promise<void> {
    this.createForm();
    this.stages = await this.stagesService.getAllEventStages(this.eventsStore.adminEvent?.id);
    this.sessions = await this.sessionsService.getEventSessions(this.eventsStore?.adminEvent?.id);
    const generalSettings: IGeneralSettings = await this.settingsService.getSettings();
    this.stripeConnectId = generalSettings.stripeConnectId;
    if (this.ticket) {
      this.updateForm();
    }
    this.formService.setForm(this.form);
    this.loading = false;

    this.form.controls.completeEvent.valueChanges
      .pipe(
        tap((value: boolean) => {
          if (value) {
            this.form.controls.stages.disable();
            this.form.controls.sessions.disable();
          } else {
            this.form.controls.stages.enable();
            this.form.controls.sessions.enable();
          }
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();

    this.form.controls.isPaid.valueChanges
      .pipe(
        tap((val: boolean) => {
          if (val) {
            this.setPaidTicketRequiremenets();
          } else {
            this.removePaidTicketRequirements();
          }
          if (val && !this.stripeConnectId) {
            this.showStripeDialog();
          }
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();

    this.form.controls.stages.valueChanges
      .pipe(
        tap((ids: string[]) => {
          this.setSessions(ids);
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();

    const minimumQuantityChanges$ = this.form.controls.minimumQuantity.valueChanges.pipe(
      tap((min: number) => {
        const max = this.form.controls.maximumQuantity.value;
        if (max && min > max) {
          this.form.controls.minimumQuantity.setValue(max, { emitEvent: false });
          this.inputNumberMin.input.nativeElement.value = max;
        }
      }),
    );

    const maximumQuantityChanges$ = this.form.controls.maximumQuantity.valueChanges.pipe(
      tap((max: number) => {
        const min = this.form.controls.minimumQuantity.value;
        if (min && min > max) {
          this.form.controls.maximumQuantity.setValue(min, { emitEvent: false });
          this.inputNumberMax.input.nativeElement.value = min;
        }
      }),
    );

    merge(minimumQuantityChanges$, maximumQuantityChanges$)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe();

    if (this.eventsStore.adminEvent.eventType === EventTypes.DIGITAL) {
      this.form.controls.ticketType.setValue(TicketTypes.DIGITAL);
      this.form.controls.ticketType.disable();
    }

    const startDateValue$ = this.form.controls.startDate.valueChanges;
    const startTimeValue$ = this.form.controls.startTime.valueChanges;
    const endDateValue$ = this.form.controls.endDate.valueChanges;
    const endTimeValue$ = this.form.controls.endTime.valueChanges;

    merge(startDateValue$, startTimeValue$, endDateValue$, endTimeValue$)
      .pipe(
        debounceTime(300),
        filter((value) => !!value),
        tap(() => {
          const start = updateTime(
            this.form.controls.startDate.value,
            this.form.controls.startTime.value,
          );
          const end = updateTime(
            this.form.controls.endDate.value,
            this.form.controls.endTime.value,
          );

          if (moment(start).isAfter(end)) {
            const validEnd = start.add(1, 'hours');
            this.form.controls.endDate.setValue(validEnd.toDate(), { emitEvent: false });
            this.form.controls.endTime.setValue(validEnd.toDate(), { emitEvent: false });
          }
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();
  }

  showStripeDialog(): void {
    this.dialogService
      .open(StripeInformationDialogComponent, { width: '38.75rem', height: '31.975rem' })
      .onClose.subscribe(() => {
        this.form.patchValue({
          isPaid: false,
        });
      });
  }

  hasValidator(controlName: string): boolean {
    const control = this.form.get(controlName);
    if (!control.validator) {
      return false;
    } else {
      return true;
    }
  }

  async setSessions(stageIds: string[]): Promise<void> {
    if (stageIds.length) {
      this.sessions = await this.sessionsService.getSessionsWithStageIds(
        this.eventsStore?.adminEvent?.id,
        stageIds,
      );
    } else {
      this.sessions = await this.sessionsService.getEventSessions(this.eventsStore?.adminEvent?.id);
    }
    const sessionIds = this.sessions.map((session: ISession) => session.id);
    this.form.controls.sessions.setValue(
      this.form.controls.sessions.value.filter((id: string) => sessionIds.includes(id)),
    );
  }

  onDiscard(): void {
    this.updateForm();
  }

  async onConfirm(): Promise<void> {
    try {
      this.isUpdating = true;
      await asyncDelay(1);
      await this.onSaveTicket();
      this.formService.setForm(this.form);
    } catch (error) {
      console.error(error);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private createForm(): void {
    this.form = this.fb.group({
      name: [null, Validators.required],
      info: [null],
      quantityTotal: [null, [Validators.required, validNumbers()]],
      minimumQuantity: [null, Validators.required],
      maximumQuantity: [null, Validators.required],
      isIncludedBadge: [false],
      isPublished: [false],
      startDate: [this.minDate, Validators.required],
      startTime: [this.minDate],
      endDate: [this.defaultEndDate, Validators.required],
      endTime: [this.defaultEndDate],
      isPaid: [false],
      currency: [null],
      price: [null],
      completeEvent: [true],
      onDemand: [false],
      stages: [{ value: [], disabled: true }],
      sessions: [{ value: [], disabled: true }],
      description: [null],
      eventBriteId: [{ value: null, disabled: true }],
      ticketType: [null],
    });

    this.form.controls.currency.disable();
    this.form.controls.price.disable();
    this.form.controls.ticketType.disable();
  }

  private updateForm(): void {
    this.form.patchValue(
      {
        ...this.ticket,
        startDate: this.ticket?.start ? parseToMoment(this.ticket.start).toDate() : null,
        startTime: this.ticket?.start ? parseToMoment(this.ticket.start).toDate() : null,
        endDate: this.ticket?.end ? parseToMoment(this.ticket.end).toDate() : null,
        endTime: this.ticket?.end ? parseToMoment(this.ticket.end).toDate() : null,
        price: this.ticket?.price ? Number(this.ticket.price) : null,
      },
      { emitEvent: false },
    );

    if (this.ticket.isEventBrite) {
      this.disableFormControls();
    }

    if (this.ticket.completeEvent) {
      this.form.controls.stages.disable();
      this.form.controls.sessions.disable();
    }

    if (this.ticket.isPaid) {
      this.form.controls.currency.disable();
      this.form.controls.price.disable();
      this.form.controls.isPaid.disable();
    }

    if (!this.ticket.completeEvent) {
      this.form.controls.stages.enable();
      this.form.controls.sessions.enable();
    }

    if (this.ticket.stages) {
      this.setSessions(this.ticket.stages);
    }
  }

  private async onSaveTicket(): Promise<void> {
    if (this.form.invalid) {
      return;
    }
    const form = this.form.getRawValue();

    //TODO: This is a hot fix for eventbrite ticket update and needs to be removed after Moonova 2023 event
    if (form.eventBriteId) {
      this.createFreeTicket();
      return;
    }
    if (form.isPaid) {
      this.createStripeTicket();
    } else {
      this.createFreeTicket();
    }
  }

  private disableFormControls(): void {
    this.form.controls.name.disable();
    this.form.controls.info.disable();
    this.form.controls.quantityTotal.disable();
    this.form.controls.isIncludedBadge.disable();
    this.form.controls.isPublished.disable();
    this.form.controls.startDate.disable();
    this.form.controls.startTime.disable();
    this.form.controls.endDate.disable();
    this.form.controls.endTime.disable();
    this.form.controls.isPaid.disable();
    this.form.controls.currency.disable();
    this.form.controls.price.disable();
    this.form.controls.description.disable();
    this.isDescriptionDisabled = true;
  }

  private async createFreeTicket(): Promise<void> {
    try {
      this.isUpdating = true;
      const form = this.form.getRawValue();

      const { startDate, startTime, endDate, endTime, ...others } = form;
      const dateStart = updateTime(startDate, startTime);
      const dateEnd = updateTime(endDate, endTime);
      const updatedTicket: ITicket = {
        ...this.ticket,
        ...others,
        start: Timestamp.fromMillis(dateStart.toDate().getTime()),
        end: Timestamp.fromMillis(dateEnd.toDate().getTime()),
        price: '0',
      };

      if (this.ticket?.id) {
        await this.ticketsService.updateTicket(this.eventsStore.adminEvent.id, updatedTicket, true);
        this.showToastMessage('success', 'adminTicket.ticketSuccessfullyUpdated');
      } else {
        await this.ticketsService.createTicket(this.eventsStore.adminEvent.id, updatedTicket);
        this.showToastMessage('success', 'adminTicket.ticketSuccessfullyCreated');
        this.router.navigate([
          `/${this.hubsStore.useHubUrl}/admin/event/${this.eventsStore.adminEvent.link}/registrations-tickets/tickets`,
        ]);
      }

      this.isUpdating = false;
    } catch (error) {
      console.warn(error);
      this.isUpdating = false;
      this.showToastMessage('error', 'application.toasters.error');
    }
  }

  private async createStripeTicket(): Promise<void> {
    try {
      this.isUpdating = true;
      const form = this.form.getRawValue();
      const isDigital = form.ticketType === TicketTypes.DIGITAL;
      let stripePriceProductPair: ProductPricePair;
      if (!this.ticket || !this.ticket?.stripePriceId) {
        stripePriceProductPair = await this.stripeService.createProduct(
          form.name,
          form.price,
          form.currency,
          false,
          isDigital,
        );
      }

      const { startDate, startTime, endDate, endTime, price, ...others } = form;
      const dateStart = updateTime(startDate, startTime);
      const dateEnd = updateTime(endDate, endTime);
      const updatedTicket: ITicket = {
        ...this.ticket,
        ...others,
        stripePriceId: stripePriceProductPair?.priceId || this.ticket.stripePriceId,
        stripeProductId: stripePriceProductPair?.productId || this.ticket.stripeProductId,
        start: Timestamp.fromMillis(dateStart.toDate().getTime()),
        end: Timestamp.fromMillis(dateEnd.toDate().getTime()),
        price: String(price) || '0',
      };
      updatedTicket.stripePriceId =
        this.ticket && this.ticket?.stripePriceId
          ? this.ticket.stripePriceId
          : stripePriceProductPair.priceId;

      if (this.ticket?.id) {
        await this.ticketsService.updateTicket(this.eventsStore.adminEvent.id, updatedTicket, true);
        this.showToastMessage('success', 'adminTicket.ticketSuccessfullyUpdated');
      } else {
        await this.ticketsService.createTicket(this.eventsStore.adminEvent.id, updatedTicket);
        this.showToastMessage('success', 'adminTicket.ticketSuccessfullyCreated');
        this.router.navigate([
          `/${this.hubsStore.useHubUrl}/admin/event/${this.eventsStore.adminEvent.link}/registrations-tickets/tickets`,
        ]);
      }

      this.isUpdating = false;
    } catch (error) {
      console.warn(error);
      this.isUpdating = false;
      this.showToastMessage('error', `stripeErrorCodes.${error.message}`);
    }
  }

  private showToastMessage(severity: 'success' | 'error', detail: string): void {
    const fallbackTranslationKey = 'toasters.error';
    let message;
    this.translateService.get(detail).subscribe((translation) => {
      message =
        translation === detail
          ? this.translateService.instant(fallbackTranslationKey)
          : translation;
    });
    this.messageService.add({
      severity,
      summary: this.translateService.instant(severity),
      detail: this.translateService.instant(message),
      styleClass: 'custom-toast',
    });
  }

  private setPaidTicketRequiremenets(): void {
    this.form.controls.price.enable();
    this.form.controls.currency.enable();
    this.form.controls.ticketType.enable();
    this.form.controls.currency.setValue(CurrencyCode.EUR);
    this.form.get('currency').setValidators([Validators.required]);
    this.form.get('ticketType').setValidators([Validators.required]);
    this.form.get('price').setValidators([Validators.required, Validators.min(0.5)]);
    this.form.get('currency').updateValueAndValidity();
    this.form.get('ticketType').updateValueAndValidity();
    this.form.get('price').updateValueAndValidity();
  }

  private removePaidTicketRequirements(): void {
    this.form.controls.price.disable();
    this.form.controls.currency.disable();
    this.form.controls.ticketType.disable();
    this.form.get('price').reset();
    this.form.get('currency').reset();
    this.form.get('ticketType').reset();
    this.form.get('currency').removeValidators([Validators.required]);
    this.form.get('ticketType').removeValidators([Validators.required]);
    this.form.get('price').removeValidators([Validators.required, Validators.min(0.5)]);
    this.form.get('currency').updateValueAndValidity();
    this.form.get('ticketType').updateValueAndValidity();
    this.form.get('price').updateValueAndValidity();
  }
}
