import { InviteTypes } from 'src/app/core/enums';

export interface IBrandMemberInvite {
  displayEmail: string;
  email: string;
  eventId: string;
  id: string;
  isFromEventBriteSync: boolean;
  token: string;
  userId: string;
  brandId: string;
  type?: InviteTypes;
  tempPassword?: string;
}
