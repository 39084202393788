import { Injectable } from '@angular/core';
import { makeAutoObservable } from 'mobx';

@Injectable()
export class VideosStore {
  public loading = true;

  constructor() {
    makeAutoObservable(this);
  }
}
