@if (loading()) {
  <app-loader></app-loader>
} @else {
  <div class="user-events-container">
    <admin-table-filter-navbar (onSearchTermChange)="searchValue.set($event)">
    </admin-table-filter-navbar>

    <admin-table
      [loading]="loadingEntries()"
      [columns]="columns()"
      [entries]="entries()"
      [paginator]="true"
      [lazy]="false"
      [searchValue]="searchValue()"
      [searchFilterFields]="searchFilterFields()"
      emptyStateKey="adminUserEvents.emptyState"
      emptyStateIcon="far fa-screen-users"
      (rowClicked)="onRowClicked($event)">
      <ng-template
        let-entry
        epTemplateKey="titleCell">
        <div class="title-cell">
          <div>
            @if (entry.banner) {
              <img
                class="banner"
                [defaultImage]="imgPreloader()"
                [lazyLoad]="entry.banner | imageSize" />
            } @else {
              <div class="placeholder-container">
                <img
                  class="placeholder"
                  [src]="logoAvatar() | imageSize" />
              </div>
            }
          </div>
          <div>
            <p class="event-title">{{ entry.title }}</p>
            <p class="event-date">{{ getEventDate(entry) }}</p>
          </div>
        </div>
      </ng-template>
      <ng-template
        let-entry
        epTemplateKey="statusCell">
        <app-tag
          [iconClass]="entry.isPublished ? 'fa-regular fa-check' : 'fa-regular fa-xmark'"
          tagText="{{
            (entry.isPublished ? 'adminTable.statusActive' : 'adminTable.statusInactive')
              | translate
          }}"
          [bgColor]="entry.isPublished ? 'var(--appPrimaryColor)' : '#ff244c'">
        </app-tag>
      </ng-template>

      <ng-template
        let-entry
        epTemplateKey="actionsCell">
        <div class="table-btn-actions-container">
          <app-button
            [buttonType]="buttonType().ROUND"
            [buttonSize]="buttonSize().MEDIUM"
            [buttonStyle]="buttonStyle().FILLED_SECONDARY"
            buttonRoundIcon="far fa-pen-to-square"
            (click)="edit($event, entry)" />
        </div>
      </ng-template>
    </admin-table>
  </div>
}
