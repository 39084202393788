import { Component, EventEmitter, Input, Output, ChangeDetectionStrategy } from '@angular/core';

import { IUser } from 'src/app/core/models';
import { getUserNameAbbreviation } from 'src/app/core/utils';

@Component({
  selector: 'app-user-ticket-card',
  templateUrl: './user-ticket-card.component.html',
  styleUrls: ['./user-ticket-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserTicketCardComponent {
  @Input() user: IUser;
  @Input() ticketName: string;
  @Input() bookingCode: number;
  @Input() btnLabel: string;
  @Input() btnIconClass: string = 'fa-regular fa-print';
  @Output() userTicketCardAction: EventEmitter<void> = new EventEmitter();

  imageText = getUserNameAbbreviation;

  onClick(): void {
    this.userTicketCardAction.emit();
  }
}
