<label
  class="input-label"
  for="color-picker-input">
  {{ label | translate }}
</label>
<div class="color-picker-container">
  <p-colorPicker
    class="custom-color-picker"
    [formControl]="colorPicker">
  </p-colorPicker>
  <input
    class="custom-input-text custom-input-text-required"
    [formControl]="inputColor"
    id="color-picker-input"
    pInputText
    type="text" />
</div>
