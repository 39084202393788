import * as moment from 'moment';
import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

import { IAsset } from 'src/app/core/models';
import { parseToMoment } from 'src/app/shared';

@Component({
  selector: 'app-asset-event-banner',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './asset-event-banner.component.html',
  styleUrls: ['./asset-event-banner.component.scss'],
})
export class AssetEventBannerComponent {
  @Input() asset: IAsset;
  @Input() containerStyles: { [key: string]: string };
  @Input() monthStyles: { [key: string]: string };
  @Input() dateStyles: { [key: string]: string };

  constructor(private translateService: TranslateService) {}

  get assetEventStartMonth(): string {
    const currentLang: string = this.translateService.currentLang;

    return moment(parseToMoment(this.asset.eventAssetStartDate))
      .locale(currentLang)
      .format('MMM')
      .toUpperCase();
  }

  get assetEventStartDay(): number {
    return moment(parseToMoment(this.asset.eventAssetStartDate)).date();
  }
}
