import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

import { ServerTimestamp } from 'src/app/firebase';
import { HubsStore, UsersStore } from 'src/app/core/stores';
import { ITag, IHubTag } from 'src/app/core/models';
import { HubsService, TagsService } from 'src/app/core/services';

@Component({
  selector: 'app-hub-tags',
  templateUrl: './hub-tags.component.html',
  styleUrls: ['./hub-tags.component.scss'],
})
export class HubTagsComponent implements OnInit {
  loading = true;
  appTags: ITag[] = [];
  hubTags: IHubTag[] = [];
  selectedTags: string[] = [];
  saving: boolean = false;
  form: UntypedFormGroup = new UntypedFormGroup({
    tags: new UntypedFormControl([]),
  });

  constructor(
    private tagsService: TagsService,
    private hubsService: HubsService,
    public hubsStore: HubsStore,
    private usersStore: UsersStore,
    private messageService: MessageService,
    private translateService: TranslateService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.appTags = await this.tagsService.getAllOrderedByTitle();
    this.hubTags = await this.hubsService.getTagsRelation(this.hubsStore.hub.id);
    this.hubTags.map((t) => this.selectedTags.push(t.tagId));

    this.form.patchValue({
      tags: this.selectedTags,
    });
    this.form.updateValueAndValidity();
    this.form.valueChanges.subscribe(() => this.form.markAsDirty());
    this.loading = false;
  }

  selectTags(tagIds: string[]): void {
    this.form.patchValue({ tags: tagIds });
  }

  async save(): Promise<void> {
    this.saving = true;

    try {
      const payload = this.form.value.tags.map((t) => {
        const existingHubTag: IHubTag =
          this.hubTags.find((huTab: IHubTag) => huTab.tagId === t) || null;
        const appTagObject: ITag = this.appTags.find((appTab: ITag) => appTab.id === t) || null;

        return {
          id: existingHubTag ? existingHubTag.id : null,
          hubId: this.hubsStore.hub.id,
          tagId: t,
          _tagTitle_: appTagObject?.title.toLowerCase() ? appTagObject?.title.toLowerCase() : null,
          createdAt: existingHubTag
            ? existingHubTag.createdAt
              ? existingHubTag.createdAt
              : null
            : ServerTimestamp(),
          createdBy: existingHubTag
            ? existingHubTag.createdBy
              ? existingHubTag.createdBy
              : null
            : this.usersStore.user.id,
          updatedAt: existingHubTag ? ServerTimestamp() : null,
          updatedBy: existingHubTag ? this.usersStore.user.id : null,
        };
      });

      await this.hubsService.saveTags(this.hubsStore.hub.id, payload || []);
      this.messageService.add({
        severity: 'success',
        summary: this.translateService.instant('success'),
        detail: this.translateService.instant('adminSystemTags.succesUpdate'),
        styleClass: 'custom-toast',
      });
    } catch (error) {
      console.log(error);
      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('error'),
        detail: this.translateService.instant('adminSystemTags.errorUpdate'),
        styleClass: 'custom-toast',
      });
    }
    this.saving = false;
  }
}
