import { IHub } from '../../models';
import { Injectable } from '@angular/core';
import { makeAutoObservable } from 'mobx';
import { ChangeAuthTenant } from 'src/app/firebase';
import { environment } from 'src/environments/environment';

@Injectable()
export class HubsStore {
  public bindedWithDomain: boolean = false;
  public hub: IHub = null;
  public hubsMap: { [key: string]: IHub } = {};

  constructor() {
    makeAutoObservable(this);
  }

  public get hubs(): IHub[] {
    return Object.keys(this.hubsMap).map((id) => this.hubsMap[id]);
  }

  public get environmentBaseUrl(): string {
    return this.hub && this.bindedWithDomain ? window.location.origin : environment.baseUrl;
  }

  public get environmentBaseEventsUrl(): string {
    return this.hub && this.bindedWithDomain
      ? `${window.location.origin}/events/`
      : environment.envUrl;
  }

  public get useHubUrl(): string {
    return this.hub && !this.bindedWithDomain ? this.hub.url : '';
  }

  public setHub(hub: IHub): void {
    this.hub = hub;
    if (hub?.tenantId) {
      ChangeAuthTenant(hub.tenantId);
    }
  }

  public resetHub(): void {
    this.hub = null;
    ChangeAuthTenant(null);
  }

  public setHubDomainBinding(value: boolean): void {
    this.bindedWithDomain = value;
  }

  public setHubs(newHubs: IHub[]): void {
    newHubs.forEach((hub) => (this.hubsMap[hub.id] = hub));
  }

  public get hubId(): string {
    return this.hub?.id;
  }

  public getHub(id: string): IHub {
    return this.hubsMap[id];
  }

  public getHubByUrl(url: string): IHub {
    return this.hubs.find((hub) => hub.url === url);
  }
}
