<div class="container">
  <div class="details-inner-container">
    <div class="header">
      <app-update-default-covers
        *ngIf="product?.image && !product?.videoLink"
        [url]="product?.image"
        [color]="color">
      </app-update-default-covers>

      <ng-container *ngIf="product?.videoLink && safeVideoUrl">
        <div class="video_wrapper">
          <div class="iframe-container">
            <iframe
              class="iframe-positioning"
              [src]="safeVideoUrl"
              frameborder="0"
              webkitallowfullscreen
              mozallowfullscreen
              allowfullscreen>
            </iframe>
          </div>
          <script src="https://player.vimeo.com/api/player.js"></script>
        </div>
      </ng-container>

      <div
        *ngIf="!product?.videoLink"
        class="info-header">
        <img
          *ngIf="brand?.logo"
          class="logo"
          [src]="brand?.logo | imageSize"
          alt="{{ 'showroom.productLogo' | translate }}" />
      </div>
    </div>

    <div class="body grid">
      <div class="lg:col-4 md:col-12 sm:col-12 col-12 brand-name-col">
        <div>
          <h1>{{ product?.title }}</h1>
          <p
            class="brand-name"
            (click)="openBrand()">
            {{ product.brandName }}
          </p>
          <p>{{ product?.tagline }}</p>
        </div>

        <button
          *ngIf="product?.file"
          class="downloadFileBtn"
          pButton
          (click)="downloadFile()">
          <i class="far fa-download"></i>
          <span>{{ 'showroom.downloadFile' | translate }}</span>
        </button>
        <button
          *ngIf="product?.cta"
          class="openLinkBtn"
          pButton
          (click)="goToLink(product?.cta)">
          <i class="fak fa-lox-calendar"></i>
          <span>{{ 'showroom.openLink' | translate }}</span>
        </button>
        <button
          *ngIf="showBookmarkContent"
          class="bookmarkBtn"
          pButton
          (click)="bookmark()">
          <i [ngClass]="getBookmarkedIconClass()"></i>
          <span>{{ 'showroom.bookmark' | translate }}</span>
        </button>
      </div>
      <div class="lg:col-8 md:col-12 sm:col-12 col-12 brand-data-col">
        <div
          class="brand-bio"
          [innerHTML]="product?.description"></div>
      </div>
    </div>
  </div>
</div>
