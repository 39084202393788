import { ITag } from '../../models/tag/tag.model';
import { Injectable } from '@angular/core';
import { makeAutoObservable } from 'mobx';
import { IStage } from '../../models/stages/stage.model';

@Injectable()
export class TagsStore {
  public tagsMap: { [key: string]: ITag } = {};

  constructor() {
    makeAutoObservable(this);
  }

  public setTags(tags: ITag[]): void {
    for (const tag of tags) {
      this.tagsMap[tag.id] = tag;
    }
  }

  public get tags(): ITag[] {
    return Object.keys(this.tagsMap).map((key) => this.tagsMap[key]);
  }
}
