import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'confirm-dialog',
  styleUrls: ['./confirm-dialog.component.scss'],
  templateUrl: './confirm-dialog.component.html',
})
export class ConfirmDialogComponent {
  titleKey: string;
  descriptionKey: string;
  confirmBtnKey: string;
  cancelBtnKey: string;
  hideCancelBtn: boolean;
  hideConfirmBtn: boolean;
  className: string;

  constructor(
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
  ) {
    this.titleKey = config.data?.titleKey || '';
    this.descriptionKey = config.data?.descriptionKey || '';
    this.confirmBtnKey = config.data?.confirmBtnKey || 'buttons.confirm';
    this.cancelBtnKey = config.data?.cancelBtnKey || 'buttons.cancel';
    this.hideCancelBtn = config.data?.hideCancelBtn;
    this.hideConfirmBtn = config.data?.hideConfirmBtn;
    this.className = config.data?.className || 'fa-regular fa-check';
  }

  onCloseDialog(): void {
    this.ref.close();
  }
}
