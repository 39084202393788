<h1>{{ 'exportFields.title' | translate }}</h1>
<p>{{ 'exportFields.description' | translate }}</p>
<p-accordion
  [multiple]="true"
  styleClass="entities-keys-accordion">
  <p-accordionTab header="{{ 'exportFields.userFields' | translate }}">
    <div class="entity-keys-list-container">
      <div
        *ngFor="let key of userKeys"
        class="key-item clickable"
        [ngClass]="isSelected('selectedUserKeys', key.key)"
        (click)="selectField('selectedUserKeys', key)">
        {{ key.header | translate }}
      </div>
    </div>
  </p-accordionTab>

  <p-accordionTab header="{{ 'exportFields.userCourseFields' | translate }}">
    <div class="entity-keys-list-container">
      <div
        *ngFor="let key of userCourseKeys"
        class="key-item clickable"
        [ngClass]="isSelected('selectedUserCourseKeys', key.key)"
        (click)="selectField('selectedUserCourseKeys', key)">
        {{ key.header | translate }}
      </div>
    </div>
  </p-accordionTab>
</p-accordion>

<button
  class="export"
  pButton
  pRipple
  label="{{ 'exportFields.downloadBtnLabel' | translate }}"
  [icon]="isLoading ? 'pi pi-spin pi-spinner' : null"
  (click)="export()"></button>

<button
  class="unselectBtn"
  pButton
  pRipple
  label="{{ 'exportFields.unselectBtnLabel' | translate }}"
  (click)="unselectAll()"></button>
