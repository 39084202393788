<nav>
  <figure class="figure">
    <figcaption class="figcaption">
      {{ 'adminEventSessionSidebar.generalNavGroupTitle' | translate }}
    </figcaption>
    <ul class="list">
      <li class="list-item">
        <a
          class="item-link"
          routerLink="basic-info"
          routerLinkActive="active">
          <span>{{ 'adminEventSessionSidebar.basicInfoNavItem' | translate }}</span>
          <i class="fa-regular fa-chevron-right chevron-icon"></i>
        </a>
      </li>
      <li class="list-item">
        <a
          class="item-link"
          routerLink="design"
          routerLinkActive="active">
          <span>{{ 'adminEventSessionSidebar.designNavItem' | translate }}</span>
          <i class="fa-regular fa-chevron-right chevron-icon"></i>
        </a>
      </li>
      <li class="list-item">
        <a
          class="item-link"
          routerLink="space-settings"
          routerLinkActive="active">
          <span>{{ 'adminEventSessionSidebar.spaceSettingsNavItem' | translate }}</span>
          <i class="fa-regular fa-chevron-right chevron-icon"></i>
        </a>
      </li>
      <li class="list-item">
        <a
          class="item-link"
          routerLink="session-assets"
          routerLinkActive="active">
          <span>{{ 'adminEventSessionSidebar.assetsNavItem' | translate }}</span>
          <i class="fa-regular fa-chevron-right chevron-icon"></i>
        </a>
      </li>
    </ul>
  </figure>
  <figure class="figure">
    <figcaption class="figcaption">
      {{ 'adminEventSessionSidebar.sessionRegistrationGroupTitle' | translate }}
    </figcaption>
    <ul class="list">
      <li class="list-item">
        <a
          class="item-link"
          routerLink="registration-settings"
          routerLinkActive="active">
          <span>{{ 'adminEventSessionSidebar.registrationSettingsNavItem' | translate }}</span>
          <i class="fa-regular fa-chevron-right chevron-icon"></i>
        </a>
      </li>
      <li class="list-item">
        <a
          class="item-link"
          routerLink="registrations"
          routerLinkActive="active">
          <span>{{ 'adminEventSessionSidebar.registrationsNavItem' | translate }}</span>
          <i class="fa-regular fa-chevron-right chevron-icon"></i>
        </a>
      </li>
    </ul>
  </figure>
</nav>
