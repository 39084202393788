import { Component, inject, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ICourse, IHub, IUser } from 'src/app/core/models';
import { CoursesService } from 'src/app/core/services';
import { HubsStore, UsersStore } from 'src/app/core/stores';
import { IAdminTableColumn, SharedModule, imgPreloader, logoAvatar } from 'src/app/shared';
import {
  ButtonComponent,
  ButtonSize,
  ButtonStyle,
  ButtonType,
  TagComponent,
} from 'src/app/standalone/shared';

@Component({
  selector: 'app-user-courses',
  standalone: true,
  imports: [SharedModule, ButtonComponent, TagComponent],
  templateUrl: './user-courses.component.html',
  styleUrl: './user-courses.component.scss',
})
export class UserCoursesComponent {
  loading = signal(true);
  courses = signal<ICourse[]>(null);
  searchValue = signal('');
  columns = signal<IAdminTableColumn[]>([
    { field: 'title', template: 'titleCell', titleTranslationKey: 'adminCourseList.tableTitle' },
    { field: 'isPublished', titleTranslationKey: 'adminCourseList.status', template: 'statusCell' },
    { field: 'actions', template: 'actionsCell' },
  ]);
  imgPreloader = imgPreloader;
  logoAvatar = logoAvatar;
  buttonSize = ButtonSize;
  buttonType = ButtonType;
  buttonStyle = ButtonStyle;

  private usersStore = inject(UsersStore);
  private router = inject(Router);
  private hubsStore = inject(HubsStore);
  private route = inject(ActivatedRoute);
  private coursesService = inject(CoursesService);

  get user(): IUser {
    return this.usersStore.adminUser;
  }

  get hub(): IHub {
    return this.hubsStore.hub;
  }

  async ngOnInit(): Promise<void> {
    await this.fetchCourses();
    this.loading.set(false);
  }

  edit(event: Event, course: ICourse): void {
    event.stopPropagation();
    this.router.navigate([`/${this.hubsStore.useHubUrl}/admin/courses/${course.link}`], {
      relativeTo: this.route,
      state: { course },
    });
  }

  onRowClicked(id: string): void {
    const courseForNavigate: ICourse = this.courses().find((course: ICourse) => course.id === id);
    this.router.navigate([`/${this.hubsStore.useHubUrl}/admin/courses/${courseForNavigate.link}`]);
  }

  private async fetchCourses(): Promise<void> {
    const courses: ICourse[] = await this.coursesService.getAllCoursesByUser(
      this.user.id,
      this.hub.id,
    );
    this.courses.set(courses);
  }
}
