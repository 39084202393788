import * as moment from 'moment';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import {
  UsersService,
  OpenDialogService,
  MixpanelService,
  ThemesService,
} from 'src/app/core/services';
import { UsersStore, EventsStore, HubsStore } from 'src/app/core/stores';
import { IEvent, IUser, IUserEvent } from 'src/app/core/models';
import { parseToMoment, imgPreloader } from 'src/app/shared';
import { AnalyticsEventTypes } from 'src/app/core/enums';

@Component({
  selector: 'app-event-card',
  templateUrl: './event-card.component.html',
  styleUrls: ['./event-card.component.scss'],
})
export class EventCardComponent implements OnInit {
  @Input() event: IEvent;
  @Input() bookmarkBtnShow = true;
  @Input() onClickRedirectToDetails = true;
  @Output() onBookmark = new EventEmitter<string>();

  bookmarking = false;
  loggedUser: IUser;
  imgPreloader = imgPreloader;
  isCurrentUserRegisteredInEvent = false;

  constructor(
    private router: Router,
    private hubsStore: HubsStore,
    public eventsStore: EventsStore,
    private usersService: UsersService,
    private usersStore: UsersStore,
    private openDialogService: OpenDialogService,
    private mixpanelService: MixpanelService,
    private themesService: ThemesService,
    private translateService: TranslateService,
  ) {}

  get defaultCoversColor(): string {
    const currentTheme = localStorage.getItem('styleTheme');
    const currentThemeProps =
      this.themesService.systemAppearanceSettings[currentTheme + 'Theme'].properties;

    return this.hubsStore.hub?.primaryColor || currentThemeProps['--appPrimaryColor'];
  }

  get isSameDay(): boolean {
    return moment(parseToMoment(this.event.start)).isSame(parseToMoment(this.event.end), 'day');
  }

  get eventDateFirstLine(): string {
    const lang: string = this.translateService.currentLang;
    const localLocale = moment(parseToMoment(this.event.start));
    localLocale.locale(lang);
    if (this.isSameDay) {
      return localLocale.format('dd., LL');
    }
    return lang === 'de'
      ? `${localLocale.format('llll')} Uhr -`
      : `${localLocale.format('llll')} -`;
  }

  get eventDateSecondLine(): string {
    const lang: string = this.translateService.currentLang;
    const localLocale = moment(parseToMoment(this.event.end));
    localLocale.locale(lang);
    if (this.isSameDay) {
      const localLocaleStartTime = moment(parseToMoment(this.event.start));
      localLocaleStartTime.locale(lang);
      return lang === 'de'
        ? `${localLocaleStartTime.format('LT')} Uhr - ${localLocale.format('LT')} Uhr`
        : `${localLocaleStartTime.format('LT')} - ${localLocale.format('LT')}`;
    }
    return lang === 'de' ? `${localLocale.format('llll')} Uhr` : localLocale.format('llll');
  }

  get getBookmarkedBtnClass(): string {
    if (this.bookmarking) {
      return 'far fa-circle-notch fa-spin';
    }
    return this.usersStore.userBookmarkedEventsIds.includes(this.event.id)
      ? 'far fa-bookmark active'
      : 'far fa-bookmark';
  }

  ngOnInit(): void {
    this.loggedUser = this.usersStore.user;
    if (this.loggedUser) {
      this.usersService.getUserBookmarkedEventsIds(this.loggedUser.id);
    }
    this.checkingIfUserIsRegistered();
  }

  async onNavigateToEventPage(): Promise<void> {
    if (!this.onClickRedirectToDetails) {
      return;
    }
    const freshUser = await this.usersService.getOne(this.usersStore.user?.id);

    if (this.event.isOnMaintenance && freshUser.role === 'user') {
      this.openMaintenanceDialog();
      return;
    }
    this.openEvent();
  }

  openMaintenanceDialog(): void {
    this.openDialogService.openMaintenanceDynamicDialog(this.event);
  }

  async bookmark(event: MouseEvent): Promise<void> {
    event.stopPropagation();
    this.bookmarking = true;

    if (this.usersStore.userBookmarkedEventsIds.includes(this.event.id)) {
      await this.usersService.unBookmarkEvent(this.usersStore.userId, this.event.id);
      // send event to mixpanel analytics
      this.mixpanelService.eventBookmarkedOrUnBookmarked(
        this.event,
        AnalyticsEventTypes.EVENT_UNBOOKMARKED,
      );
    } else {
      await this.usersService.bookmarkEvent(this.usersStore.userId, this.event.id);
      // send event to mixpanel analytics
      this.mixpanelService.eventBookmarkedOrUnBookmarked(
        this.event,
        AnalyticsEventTypes.EVENT_BOOKMARKED,
      );
    }

    this.bookmarking = false;
    this.onBookmark.emit(this.event.id);
  }

  openEvent(): void {
    this.router.navigate([`${this.hubsStore.useHubUrl}/events/${this.event.id}`]);
  }

  isLive(): boolean {
    return moment().isBetween(parseToMoment(this.event.start), parseToMoment(this.event.end));
  }

  private async checkingIfUserIsRegistered(): Promise<void> {
    try {
      const userEventDataForCurrentUser: IUserEvent = await this.usersService.getUserEvent(
        this.event.id,
        this.usersStore.userId,
      );
      this.isCurrentUserRegisteredInEvent = !!userEventDataForCurrentUser?.status;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }
}
