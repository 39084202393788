import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { MixpanelService, StagesService, EventMenuService } from 'src/app/core/services';
import { HubsStore, StagesStore, EventsStore } from 'src/app/core/stores';
import { goToLink } from 'src/app/core/utils';
import { IEventMenuItem } from 'src/app/core/models';
import { AppStore } from 'src/app/app.store';

@Component({
  selector: 'app-aside-navigation',
  templateUrl: './aside-navigation.component.html',
  styleUrls: ['./aside-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AsideNavigationComponent {
  public goToLink = goToLink;
  public menuItems$: Observable<IEventMenuItem[]>;

  constructor(
    public eventsStore: EventsStore,
    public stagesService: StagesService,
    public stagesStore: StagesStore,
    public appStore: AppStore,
    private router: Router,
    public hubsStore: HubsStore,
    private mixpanelService: MixpanelService,
    private cdr: ChangeDetectorRef,
    eventMenuService: EventMenuService,
  ) {
    this.menuItems$ = eventMenuService.eventMenuItems();
  }

  public menuItemClick(menuItem: IEventMenuItem): void {
    if (menuItem.clickAction) {
      menuItem.clickAction();
      this.cdr.markForCheck();
    }
  }

  public onExitEventClick(): void {
    // TODO: remove or implement =>  this.eventStore.reset();
    this.router.navigate([`${this.hubsStore.useHubUrl}/events`]);
    // send event to mixpanel analytics
    this.mixpanelService.eventExit(this.eventsStore.event);
  }
}
