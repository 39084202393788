import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { atcb_action, atcb_init } from 'add-to-calendar-button';

import { IEvent } from 'src/app/core/models';

@Component({
  selector: 'app-add-event-to-calendar-button',
  templateUrl: './add-event-to-calendar-button.component.html',
  styleUrls: ['./add-event-to-calendar-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddEventToCalendarButtonComponent {
  @Input() type: 'full' | 'compact' = 'full';
  @Input() event?: IEvent;

  constructor(private translateService: TranslateService) {}

  ngAfterViewInit(): void {
    if (this.type && this.type === 'full' && this.event) {
      atcb_init();
    }
  }

  showAction(event: MouseEvent): void {
    if (this.type === 'full' || !this.event) {
      return;
    }

    const { title, shortDescription, start, end, location, timezone } = this.event;

    const [startDate, startTime]: [string, string] = start.toDate().toISOString().split('T');
    const [endDate, endTime]: [string, string] = end.toDate().toISOString().split('T');

    atcb_action({
      name: title,
      description: shortDescription,
      startDate,
      startTime: startTime.substring(0, 5),
      endDate,
      endTime: endTime.substring(0, 5),
      location: window.location.href,
      options: ['Apple', 'Google', 'iCal', 'Microsoft365', 'Outlook.com', 'MicrosoftTeams'],
      timeZoneOffset: '+00:00',
      iCalFileName: title.trim().toLocaleLowerCase().split(' ').join('_'),
      trigger: 'click',
    });
  }

  config(): string {
    if (!this.event) {
      return '';
    }

    const { title, shortDescription, start, end, timezone, location } = this.event;

    const [startDate, startTime]: [string, string] = start.toDate().toISOString().split('T');
    const [endDate, endTime]: [string, string] = end.toDate().toISOString().split('T');

    return JSON.stringify({
      name: title,
      description: shortDescription,
      startDate,
      startTime: startTime.substring(0, 5),
      endDate,
      endTime: endTime.substring(0, 5),
      location: window.location.href,
      label: this.translateService.instant('application.add-to-calendar.label'),
      options: ['Apple', 'Google', 'iCal', 'Microsoft365', 'Outlook.com', 'MicrosoftTeams'],
      timeZoneOffset: '+00:00',
      iCalFileName: title.trim().toLocaleLowerCase().split(' ').join('_'),
    });
  }
}
