import * as moment from 'moment';
import firebase from 'firebase/compat/app';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { finalize, map } from 'rxjs/operators';

import { Firestore, IDocumentData, IFirestore, IQuerySnapshot, Timestamp } from 'src/app/firebase';
import { API_ROUTES as apiRoutes, parseToMoment } from 'src/app/shared';
import { IUser } from '../../models/user';
import { UsersStore } from '../../stores/users/users.store';
import { UsersService } from '../users/users.service';

interface SaveChatMessageDTO {
  userId: string;
  message: string;
  timestamp: firebase.firestore.FieldValue;
  pinned: boolean;
}

interface ReadChatMessageDTO {
  id: string;
  userId: string;
  message: string;
  timestamp: Timestamp;
  type: 'added' | 'removed' | 'modified';
}

export type ChatMessageType = 'NEW' | 'UPDATED' | 'DELETED';

export interface ChatMessage {
  id: string;
  user?: IUser;
  userId: string;
  message: string;
  timestamp: Date;
  state: ChatMessageType;
}

@Injectable({
  providedIn: 'root',
})
export class StageChatService {
  private firestore: IFirestore = Firestore();
  private usersCache: { [key: string]: IUser } = {};

  constructor(
    private usersStore: UsersStore,
    private usersService: UsersService,
  ) {}

  async sendMessage(message: string, eventId: string, stageId: string): Promise<void> {
    const doc = this.firestore.collection(apiRoutes.stageChatMessages(eventId, stageId)).doc();

    const messageDTO: SaveChatMessageDTO = {
      userId: this.usersStore.user.id,
      message,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      pinned: false,
    };

    await doc.set(messageDTO);
  }

  messages(
    eventId: string,
    stageId: string,
    attacheUserToMessage = true,
    date: Timestamp = null,
  ): Observable<ChatMessage[]> {
    const subject = new Subject<ChatMessage[]>();
    const messagesQuery: IDocumentData = date
      ? this.firestore
          .collection(apiRoutes.stageChatMessages(eventId, stageId))
          .where('timestamp', '>=', date)
      : this.firestore.collection(apiRoutes.stageChatMessages(eventId, stageId));

    messagesQuery.orderBy('timestamp', 'asc').onSnapshot({
      next: async (querySnapshot: IQuerySnapshot<IDocumentData>) => {
        const chatMessagesDTOs = querySnapshot
          .docChanges()
          // .filter(d => d.type === 'added')
          .map((d) => ({ id: d.doc.id, ...d.doc.data(), type: d.type }) as ReadChatMessageDTO);

        let chatMessages: ChatMessage[];

        if (attacheUserToMessage) {
          // get a list of all the usersIds
          // repeated ids are removed
          const uniqueUsersIds = chatMessagesDTOs
            .map((dto) => dto.userId)
            .filter((value, index, self) => self.indexOf(value) === index);

          const usersMap = await this.getUsersMap(uniqueUsersIds);

          // create the ChatMessage with the user info
          chatMessages = chatMessagesDTOs.map((dto) => {
            const message = this.mapper(dto);
            message.user = usersMap[dto.userId] as IUser;
            return message;
          });
        } else {
          chatMessages = chatMessagesDTOs.map((dto) => this.mapper(dto));
        }

        subject.next(chatMessages);
      },
      error: (err) => console.error(err),
    });

    return subject.asObservable();
  }

  async deleteMessage(eventId: string, stageId: string, messageId: string): Promise<void> {
    await this.firestore
      .collection(apiRoutes.stageChatMessages(eventId, stageId))
      .doc(messageId)
      .delete();
  }

  bookmarkedMessages(
    eventId: string,
    stageId: string,
    date: Timestamp = null,
  ): Observable<ChatMessage[]> {
    const bookmarkedMessages$ = new BehaviorSubject<ChatMessage[]>([]);
    const bookmarkedMessagesQuery: IDocumentData = this.firestore.collection(
      apiRoutes.bookmarkedStageChatMessages(this.usersStore.getUserEvent(eventId).id, stageId),
    );

    const snapshotCancelFn = bookmarkedMessagesQuery.onSnapshot(
      async (querySnapshot: IQuerySnapshot<IDocumentData>) => {
        const bookmarkedMessages: ChatMessage[] = bookmarkedMessages$.getValue();
        const addedMessagesIds: string[] = querySnapshot
          .docChanges()
          .filter((d) => d.type === 'added')
          .map((d) => d.doc.id);
        const removedMessagesIds: string[] = querySnapshot
          .docChanges()
          .filter((d) => d.type === 'removed')
          .map((d) => d.doc.id);

        // remove un bookmarked messages
        for (const removedMessageId of removedMessagesIds) {
          const message: ChatMessage = bookmarkedMessages.find((m) => m.id === removedMessageId);
          const messageIndex: number = message ? bookmarkedMessages.indexOf(message) : -1;
          bookmarkedMessages.splice(messageIndex, 1);
        }

        // add new messages
        const chatMessagesDTOs = (
          await Promise.all(
            addedMessagesIds.map((id) =>
              this.firestore
                .collection(apiRoutes.stageChatMessages(eventId, stageId))
                .doc(id)
                .get(),
            ),
          )
        )
          .filter((d: IDocumentData) => d.exists)
          .map((d: IDocumentData) => ({ id: d.id, ...d.data() }) as ReadChatMessageDTO);

        const uniqueUsersIds = chatMessagesDTOs
          .map((dto) => dto.userId)
          .filter((value, index, self) => self.indexOf(value) === index);

        const usersMap = await this.getUsersMap(uniqueUsersIds);

        // create the ChatMessage with the user info
        const chatMessages = chatMessagesDTOs
          .map((dto) => {
            const message = this.mapper(dto);
            message.user = usersMap[dto.userId] as IUser;
            return message;
          })
          .filter((message: ChatMessage) =>
            moment(message.timestamp).isAfter(parseToMoment(date.toMillis())),
          );

        bookmarkedMessages.push(...chatMessages);

        // emit updated bookmarked list
        bookmarkedMessages$.next(bookmarkedMessages);
      },
    );

    return bookmarkedMessages$.pipe(
      finalize(() => {
        if (bookmarkedMessages$.observers.length === 0) {
          snapshotCancelFn();
        }
      }),
    );
  }

  async bookmarkMessage(eventId: string, stageId: string, messageId: string): Promise<boolean> {
    try {
      await this.firestore
        .collection(
          `userEvents/${this.usersStore.getUserEvent(eventId).id}/stage/${stageId}/bookmarkedChatMessages`,
        )
        .doc(messageId)
        .set({});

      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async removeBookmarkMessage(
    eventId: string,
    stageId: string,
    messageId: string,
  ): Promise<boolean> {
    try {
      await this.firestore
        .collection(
          `userEvents/${this.usersStore.getUserEvent(eventId).id}/stage/${stageId}/bookmarkedChatMessages`,
        )
        .doc(messageId)
        .delete();

      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  pinnedMessages(
    eventId: string,
    stageId: string,
    date: Timestamp = null,
  ): Observable<ChatMessage[]> {
    const pinnedMessages$ = new BehaviorSubject<ChatMessage[]>([]);
    const pinnedMessagesQuery: IDocumentData = date
      ? this.firestore
          .collection(apiRoutes.stageChatMessages(eventId, stageId))
          .where('pinned', '==', true)
          .where('timestamp', '>=', date)
      : this.firestore
          .collection(apiRoutes.stageChatMessages(eventId, stageId))
          .where('pinned', '==', true);

    const snapshotCancelFn = pinnedMessagesQuery.onSnapshot(
      async (querySnapshot: IQuerySnapshot<IDocumentData>) => {
        const pinnedMessages: ChatMessage[] = pinnedMessages$.getValue();
        const addedMessagesIds: string[] = querySnapshot
          .docChanges()
          .filter((d) => d.type === 'added')
          .map((d) => d.doc.id);
        const removedMessagesIds: string[] = querySnapshot
          .docChanges()
          .filter((d) => d.type === 'removed')
          .map((d) => d.doc.id);

        // remove unpinned messages
        for (const removedMessageId of removedMessagesIds) {
          const message: ChatMessage = pinnedMessages.find((m) => m.id === removedMessageId);
          const messageIndex: number = message ? pinnedMessages.indexOf(message) : -1;
          pinnedMessages.splice(messageIndex, 1);
        }

        // add new messages
        const chatMessagesDTOs = (
          await Promise.all(
            addedMessagesIds.map((id) =>
              this.firestore
                .collection(apiRoutes.stageChatMessages(eventId, stageId))
                .doc(id)
                .get(),
            ),
          )
        )
          .filter((d: IDocumentData) => d.exists)
          .map((d: IDocumentData) => ({ id: d.id, ...d.data() }) as ReadChatMessageDTO);

        const uniqueUsersIds = chatMessagesDTOs
          .map((dto) => dto.userId)
          .filter((value, index, self) => self.indexOf(value) === index);
        const usersMap = await this.getUsersMap(uniqueUsersIds);

        // create the ChatMessage with the user info
        const chatMessages = chatMessagesDTOs.map((dto) => {
          const message = this.mapper(dto);
          message.user = usersMap[dto.userId] as IUser;
          return message;
        });

        pinnedMessages.push(...chatMessages);

        // emit updated bookmarked list
        pinnedMessages$.next(pinnedMessages);
      },
    );

    return pinnedMessages$.pipe(
      map((messages: ChatMessage[]) => {
        return messages.sort((a, b) => {
          return moment(a.timestamp).isAfter(moment(b.timestamp)) ? 1 : -1;
        });
      }),
      finalize(() => {
        if (pinnedMessages$.observers.length === 0) {
          snapshotCancelFn();
        }
      }),
    );
  }

  async pinMessage(eventId: string, stageId: string, messageId: string): Promise<boolean> {
    try {
      await this.firestore
        .collection(apiRoutes.stageChatMessages(eventId, stageId))
        .doc(messageId)
        .update({ pinned: true });

      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async unpinMessage(eventId: string, stageId: string, messageId: string): Promise<boolean> {
    try {
      await this.firestore
        .collection(apiRoutes.stageChatMessages(eventId, stageId))
        .doc(messageId)
        .update({ pinned: false });

      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  private mapper(source: ReadChatMessageDTO): ChatMessage {
    return {
      id: source.id,
      message: source.message,
      timestamp: source.timestamp ? source.timestamp.toDate() : new Date(),
      userId: source.userId,
      user: undefined,
      state: (
        {
          added: 'NEW',
          removed: 'DELETED',
          modified: 'UPDATED',
        } as { [key: string]: ChatMessageType }
      )[source.type],
    };
  }

  private async getUsersMap(usersIds: string[]): Promise<Record<string, Partial<IUser>>> {
    // get all the users for the provided ids
    // if the user is cached, uses that user
    // otherwise, gets the user and caches it locally
    let res: Array<Partial<IUser>> = [];

    try {
      res = await Promise.all<IUser>(
        usersIds.map((id) => {
          if (this.usersCache[id]) {
            return Promise.resolve(this.usersCache[id]);
          } else {
            return new Promise(async (resolve) => {
              const user = await this.usersService.getOne(id);
              this.usersCache[id] = user;
              resolve(user);
            });
          }
        }),
      );
    } catch (err) {
      console.error(err);
    }

    const users: Record<string, Partial<IUser>> = {};
    // transforms the user array into a map
    // for faster querying
    // TODO: understand why the list returns null users
    res.filter((user: IUser) => !!user).forEach((user: IUser) => (users[user.id] = user));

    return users;
  }
}
