import { Injectable } from '@angular/core';
import {
  Route,
  UrlSegment,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';

import { UsersStore, HubsStore } from 'src/app/core/stores';
import {
  ThemesService,
  HubsService,
  UsersService,
  AuthenticationService,
  TenantsService,
} from 'src/app/core/services';
import { DOMAINS_MAP } from 'src/app/core/utils';
import { IHub } from 'src/app/core/models';

@Injectable({
  providedIn: 'root',
})
export class DomainHubAccessGuard {
  private HUB_URL: string = '';

  constructor(
    private router: Router,
    private hubsService: HubsService,
    private hubsStore: HubsStore,
    private usersStore: UsersStore,
    private themesService: ThemesService,
    private usersService: UsersService,
    private authenticationService: AuthenticationService,
    private tenantsService: TenantsService,
  ) {}

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    console.log(
      '----------------------------------- Domain Hub Access Guard -----------------------------------------',
    );

    this.HUB_URL = DOMAINS_MAP[window.location.host] || '';
    console.log('this.HUB_URL => ', this.HUB_URL);

    if (this.HUB_URL !== '') {
      const hub = await this.hubsService.getByUrl(this.HUB_URL);
      this.hubsStore.setHub(hub);
      console.log('HUB: ', hub);

      if (!hub) {
        // TODO: Redirect to page 404 or some page where it says no valid hub!
        // this.router.navigate(['/app-loader']);
        return false;
      } else {
        const currentTheme = localStorage.getItem('styleTheme');
        const currentThemeProps =
          this.themesService.systemAppearanceSettings[currentTheme + 'Theme'].properties;

        document.documentElement.style.setProperty(
          '--appPrimaryColor',
          hub.primaryColor !== null && hub.primaryColor !== undefined && hub.primaryColor !== ''
            ? hub.primaryColor
            : currentThemeProps['--appPrimaryColor'],
        );
      }

      if (this.usersStore.user) {
        //TODO: For now this not works for existing admins(and similar roles) who doesnwho didn't migrated to tenants.
        // const freshUser = await this.usersService.getOne(this.usersStore.user?.id);
        // if ((freshUser?.role === 'superAdmin' || freshUser?.role === 'internalAdmin' || freshUser?.role === 'instanceOwner' || freshUser?.role === 'softwareDeveloper')) {
        //   return true;
        // }

        if (this.hubsStore.hub.tenantId) {
          const isExistResponse = await this.tenantsService.isEmailExist(
            this.usersStore.user.email,
          );

          if (isExistResponse.exist && this.usersStore.user.id === isExistResponse.userId) {
            return this.checkHubAccess(state, hub);
          } else {
            if (state.url !== '/' + this.hubsStore.useHubUrl + '/switch-hub') {
              await this.authenticationService.signOut();
              this.router.navigate([this.hubsStore.useHubUrl + '/switch-hub']);
              this.usersStore.reset();
              return false;
            } else {
              return true;
            }
          }
        } else {
          if (state.url !== '/' + this.hubsStore.useHubUrl + '/switch-hub') {
            return this.checkHubAccess(state, hub);
          } else {
            return true;
          }
        }
      } else {
        if (state.url !== '/' + this.hubsStore.useHubUrl + '/switch-hub') {
          return this.checkHubAccess(state, hub);
        } else {
          //
        }
      }

      console.log('READY 1');
      return true;
    } else {
      // TODO: Redirect to page 404 or some page where it says no valid hub!
      // this.router.navigate(['/app-loader']);
      return false;
    }
  }
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canMatch(
    route: Route,
    segments: UrlSegment[],
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canLoad(
    route: Route,
    segments: UrlSegment[],
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }

  private async checkHubAccess(state, hub: IHub) {
    if (hub.isPrivate) {
      const userHub = await this.hubsService.hasAccess(hub.id, this.usersStore.user.id);
      const freshUser = await this.usersService.getOne(this.usersStore.user.id);

      if (
        userHub ||
        freshUser?.role === 'superAdmin' ||
        freshUser?.role === 'internalAdmin' ||
        freshUser?.role === 'instanceOwner' ||
        freshUser?.role === 'softwareDeveloper'
      ) {
        if (hub.isMaintenance) {
          if (state.url.includes('admin')) {
            return true;
          } else {
            if (state.url !== '/' + this.hubsStore.useHubUrl + '/maintenance') {
              this.router.navigate([this.hubsStore.useHubUrl + '/maintenance']);
            }
          }
        }
        return true;
      } else {
        this.router.navigate(['/dashboard']);
        return false;
      }
    } else {
      if (hub.isMaintenance) {
        if (state.url.includes('admin')) {
          return true;
        } else {
          if (state.url !== '/' + this.hubsStore.useHubUrl + '/maintenance') {
            this.router.navigate([this.hubsStore.useHubUrl + '/maintenance']);
          }
        }
      }
      return true;
    }
  }
}
