import { Component, input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-custom-radio-button',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './custom-radio-button.component.html',
  styleUrl: './custom-radio-button.component.scss',
})
export class CustomRadioButtonComponent {
  isActive = input<boolean>();
  iconClass = input<string>();
  title = input<string>();
  description = input<string>();
}
