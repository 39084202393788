import DailyIframe, { DailyCall, DailyLanguageSetting } from '@daily-co/daily-js';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { AppStore } from 'src/app/app.store';
import { API_ROUTES as apiRoutes } from 'src/app/shared';
import {
  IDailyRoomConfig,
  IDailyRoom,
  IDailyRoomToken,
  IDeletedRoom,
  IDailyRecordAccessLink,
  IDailyRecordingList,
  IDailyMeetings,
} from 'src/app/core/models';
import { HubsStore, EventsStore } from '../../stores';
import { ThemesService } from '../themes';
import { RecordingTypes } from '../../enums';

enum IDailyRoomPrivacy {
  private = 'private',
  public = 'public',
}

@Injectable({
  providedIn: 'root',
})
export class DailyCoService {
  private headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private eventsStore: EventsStore,
    private hubsStore: HubsStore,
    private themesService: ThemesService,
    public appStore: AppStore,
  ) {
    this.headers = new HttpHeaders()
      .set('Authorization', `Bearer ${this.appStore.environment.daily.dailyToken}`)
      .set('Accept', 'application/json');
  }

  createCallWrap(el: HTMLIFrameElement, lang: DailyLanguageSetting): DailyCall {
    return DailyIframe.wrap(el, {
      lang,
      showLeaveButton: true,
      showFullscreenButton: true,
      iframeStyle: {
        position: 'fixed',
        width: '100%',
        height: '100%',
      },
    });
  }

  createRoom(properties: IDailyRoomConfig): Observable<IDailyRoom> {
    const options = {
      privacy: IDailyRoomPrivacy.private,
      properties,
    };

    return this.http.post<IDailyRoom>(
      apiRoutes.dailyCo.rooms(this.appStore.environment.daily.dailyUrl),
      options,
      { headers: this.headers },
    );
  }

  updateRoom(roomName: string, properties: IDailyRoomConfig): Observable<IDailyRoom> {
    const options = { properties };
    return this.http.post<IDailyRoom>(
      apiRoutes.dailyCo.room(this.appStore.environment.daily.dailyUrl, roomName),
      options,
      { headers: this.headers },
    );
  }

  deleteRoom(roomName: string): Observable<IDeletedRoom> {
    return this.http
      .delete<IDeletedRoom>(
        apiRoutes.dailyCo.room(this.appStore.environment.daily.dailyUrl, roomName),
        { headers: this.headers },
      )
      .pipe(catchError((error) => (error.status === 404 ? of(null) : of(error))));
  }

  createToken(
    roomName: string,
    name: string,
    userId: string,
    isOwner: boolean = false,
    isRecording: boolean = false,
    recordingType: RecordingTypes = null,
  ): Observable<IDailyRoomToken> {
    const options = {
      properties: {
        is_owner: isOwner,
        user_name: name,
        user_id: userId,
        room_name: roomName,
        enable_recording: isRecording ? recordingType : '',
      },
    };

    return this.http.post<IDailyRoomToken>(
      apiRoutes.dailyCo.createToken(this.appStore.environment.daily.dailyUrl),
      options,
      { headers: this.headers },
    );
  }

  getFirstAccessRecordLinkByRoomName(roomName: string): Observable<IDailyRecordAccessLink> {
    return this.http
      .get<IDailyRecordingList>(
        apiRoutes.dailyCo.recordListFromRoom(this.appStore.environment.daily.dailyUrl, roomName),
        { headers: this.headers },
      )
      .pipe(
        switchMap((response: IDailyRecordingList) => {
          if (response.total_count > 0) {
            return this.getRecordAccessLink(response.data[0].id);
          }

          return of(null);
        }),
      );
  }

  getRecordAccessLink(recordId: string): Observable<IDailyRecordAccessLink> {
    return this.http.get<IDailyRecordAccessLink>(
      apiRoutes.dailyCo.recordAccessLink(this.appStore.environment.daily.dailyUrl, recordId),
      { headers: this.headers },
    );
  }

  getAllSessionsForSpecificDailyRoom(roomName: string): Observable<IDailyMeetings> {
    return this.http.get<IDailyMeetings>(
      apiRoutes.dailyCo.meetingsForSpecificRoom(this.appStore.environment.daily.dailyUrl, roomName),
      { headers: this.headers },
    );
  }

  setTheme(callFrame: DailyCall): void {
    const currentTheme = localStorage.getItem('styleTheme');
    const currentThemeProps =
      this.themesService.systemAppearanceSettings[currentTheme + 'Theme'].properties;
    const accentColor =
      this.eventsStore?.event?.primaryColor ||
      this.hubsStore?.hub?.primaryColor ||
      currentThemeProps['--appPrimaryColor'];

    callFrame.setTheme({
      colors: {
        accent: accentColor,
        accentText: '#FFFFFF',
        background: '#292929',
        backgroundAccent: '#3A3A3A',
        baseText: '#FFFFFF',
        border: '#31313A',
        mainAreaBg: '#181818',
        mainAreaBgAccent: '#292929',
        mainAreaText: '#FFFFFF',
        supportiveText: '#C5C5C5',
      },
    });
  }
}
