import { Component, EventEmitter, Output, input, signal } from '@angular/core';
import { OrderListModule } from 'primeng/orderlist';

import { IEventMenuOption, IEventMenuOptionGroup } from 'src/app/core/models';
import { ButtonComponent, ButtonSize, ButtonStyle, ButtonType } from 'src/app/standalone/shared';

@Component({
  selector: 'app-admin-event-menu-items-organizer',
  standalone: true,
  imports: [OrderListModule, ButtonComponent],
  templateUrl: './admin-event-menu-items-organizer.component.html',
  styleUrl: './admin-event-menu-items-organizer.component.scss',
})
export class AdminEventMenuItemsOrganizerComponent {
  items = input<IEventMenuOption[]>();
  @Output() drillDownGroup = new EventEmitter<IEventMenuOptionGroup>();

  buttonType = signal<typeof ButtonType>(ButtonType);
  buttonStyle = signal<typeof ButtonStyle>(ButtonStyle);
  buttonSize = signal<typeof ButtonSize>(ButtonSize);

  stylesForVisibleItem = signal<{ [key: string]: string }>({ color: 'var(--app-primary)' });

  drillDownAction(groupItem: IEventMenuOptionGroup): void {
    this.drillDownGroup.emit(groupItem);
  }
}
