import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { EventPage } from 'src/app/core/models';
import { EventPagesService } from 'src/app/core/services';
import { EventsStore } from 'src/app/core/stores';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
})
export class PageComponent implements OnInit {
  public loading = true;
  public page: EventPage = null;
  public pageId: string = null;
  public title: string = null;

  constructor(
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private pagesService: EventPagesService,
    private eventsStore: EventsStore,
  ) {}

  async ngOnInit(): Promise<void> {
    this.route.params.subscribe(async (params) => {
      this.pageId = params.pageId;
      this.title = this.translateService.instant('adminPageTemplate.editPageTitle');
      this.page = await this.pagesService.get(this.pageId, this.eventsStore.adminEvent.id);
      this.loading = false;
    });
  }
}
