import { Component, OnInit, inject, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom, take } from 'rxjs';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FilterMatchMode, MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

import { ConfirmDialogComponent, IAdminTableColumn, SharedModule } from 'src/app/shared';
import { IAudience, ICourse, IEmail } from 'src/app/core/models';
import { AttachEmailsComponent } from 'src/app/admin';
import { EmailsService } from 'src/app/core/services';
import { CoursesStore } from 'src/app/core/stores';
import { courseAudience } from 'src/app/core/utils';
import { ButtonSize, ButtonStyle, ButtonType } from 'src/app/standalone/shared';
import {
  ButtonComponent,
  TagComponent,
  ToastComponent,
} from 'src/app/standalone/shared/components';
import { UnlayerEmailEditorComponent } from 'src/app/admin/components';

@Component({
  selector: 'app-messages',
  standalone: true,
  imports: [CommonModule, SharedModule, ButtonComponent, TagComponent, ToastComponent],
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
})
export class MessagesComponent implements OnInit {
  loading = signal(true);
  loadingEntries = signal(true);
  searchValue = signal('');
  searchFilterFields = signal<string[]>(['title', 'language']);
  columns = signal<IAdminTableColumn[]>(null);
  courseEmails = signal<IEmail[]>([]);
  buttonSize = signal<typeof ButtonSize>(ButtonSize);
  buttonType = signal<typeof ButtonType>(ButtonType);
  buttonStyle = signal<typeof ButtonStyle>(ButtonStyle);

  private emailsService = inject(EmailsService);
  private translateService = inject(TranslateService);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private dialogService = inject(DialogService);
  private messageService = inject(MessageService);
  private coursesStore = inject(CoursesStore);

  get course(): ICourse {
    return this.coursesStore.adminCourse;
  }

  async ngOnInit(): Promise<void> {
    await this.fetchEmails();
    this.columns.set([
      { field: 'title', titleTranslationKey: 'messages.tableColumnTitle', sortable: true },
      {
        field: 'language',
        titleTranslationKey: 'messages.tableColumnLanguage',
        template: 'langCell',
        filterable: true,
        filterMatchMode: FilterMatchMode.IN,
        filterOptions: this.translateService.getLangs().map((lang: string) => ({
          name: this.translateService.instant('languages.' + lang),
          value: lang,
        })),
        filterType: 'text',
      },
      {
        field: 'audience',
        titleTranslationKey: 'messages.tableColumnAudience',
        template: 'audienceCell',
        filterable: true,
        filterMatchMode: FilterMatchMode.IN,
        filterOptions: courseAudience.map((item: IAudience) => ({
          name: item.label,
          value: item.value,
        })),
        filterType: 'text',
      },
      {
        field: 'actions',
        titleTranslationKey: 'messages.tableColumnActions',
        template: 'courseActionsCell',
      },
      { template: 'actionsCell' },
    ]);

    this.loadingEntries.set(false);
    this.loading.set(false);
  }

  async attach(): Promise<void> {
    const attachDialogRef: DynamicDialogRef = this.dialogService.open(AttachEmailsComponent, {
      width: '90%',
      height: '80%',
      dismissableMask: true,
      closable: false,
      contentStyle: {
        padding: '0',
        'border-radius': '20px',
      },
      data: { attachTo: 'courses' },
    });

    const attachedEmails: IEmail[] = await firstValueFrom(attachDialogRef.onClose);

    if (attachedEmails) {
      await this.fetchEmails();
    }
  }

  capitalizeFirstLetter(s: string): string {
    if (!s) {
      return s;
    }
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  async edit(event: Event, entry: IEmail): Promise<void> {
    event.stopPropagation();
    if (entry.isUnlayer) {
      const unlayerDialogRef: DynamicDialogRef = this.dialogService.open(
        UnlayerEmailEditorComponent,
        {
          closable: false,
          styleClass: 'unlayer-email-editor-dialog',
          data: {
            email: entry,
          },
        },
      );

      // const result: boolean = await firstValueFrom(unlayerDialogRef.onClose);
      const result: boolean = await unlayerDialogRef.onClose.pipe(take(1)).toPromise();
      console.log(result, 'line 129');
      // if (result) {
      await this.fetchEmails();
      // }
    } else {
      this.router.navigate([entry.id], {
        relativeTo: this.route,
        state: { entry },
      });
    }
  }

  async remove(event: Event, courseEmail: IEmail): Promise<void> {
    event.stopPropagation();
    const unAttachDialogRef: DynamicDialogRef = this.dialogService.open(ConfirmDialogComponent, {
      closable: false,
      styleClass: 'confirm-dialog',
      data: {
        titleKey: 'messages.unAttachTitle',
        descriptionKey: 'messages.unAttachText',
        confirmBtnKey: 'messages.confirmBtn',
        cancelBtnKey: 'messages.cancelBtn',
        className: 'fa-solid fa-trash-xmark',
        entry: courseEmail,
      },
    });

    const result: 'cancel' | 'confirm' = await firstValueFrom(unAttachDialogRef.onClose);
    if (result === 'confirm') {
      try {
        this.loadingEntries.set(true);
        const deleteResult = await this.emailsService.unAttach('courses', courseEmail.id);
        if (deleteResult) {
          this.showToastMessage('success', 'messages.successUnAttach');
        }
        await this.fetchEmails();
        this.loadingEntries.set(false);
      } catch (error) {
        this.showToastMessage('error', 'messages.errorUnAttach');
      }
    }
  }

  onRowClicked(id: string): void {
    const courseEmail: IEmail = this.courseEmails().find((cm: IEmail) => cm.id === id);
    if (courseEmail.isUnlayer) {
      this.dialogService.open(UnlayerEmailEditorComponent, {
        closable: false,
        styleClass: 'unlayer-email-editor-dialog',
        data: {
          email: courseEmail,
        },
      });
    } else {
      this.router.navigate([id], {
        relativeTo: this.route,
        state: { id },
      });
    }
  }

  private async fetchEmails(): Promise<void> {
    console.log('fetchemails');
    try {
      this.loadingEntries.set(true);
      this.loading.set(true);
      const courseEmails = await this.emailsService.getCourseEmails(this.course.id);
      this.courseEmails.set(courseEmails);
    } catch (error) {
      console.warn(error);
    } finally {
      this.loadingEntries.set(false);
      this.loading.set(false);
    }
  }

  private showToastMessage(severity: 'success' | 'error', detail: string): void {
    this.messageService.add({
      severity,
      summary: this.translateService.instant(severity),
      detail: this.translateService.instant(detail),
      styleClass: 'custom-toast',
    });
  }
}
