import { Component, inject, OnInit, Signal, signal } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';

import { IEventMenuOption, IEventMenuOptionGroup } from 'src/app/core/models';
import { EventMenuService } from 'src/app/core/services';
import { EventsStore, HubsStore } from 'src/app/core/stores';
import {
  AdminEventMenuItemsOrganizerComponent,
  EventMenuItemsDialogComponent,
} from 'src/app/standalone/admin';
import { SharedModule } from 'src/app/shared';

@Component({
  selector: 'app-event-menu',
  standalone: true,
  imports: [SharedModule, AdminEventMenuItemsOrganizerComponent],
  templateUrl: './event-menu.component.html',
  styleUrl: './event-menu.component.scss',
})
export class EventMenuComponent implements OnInit {
  menuItems = signal<IEventMenuOption[]>([]);
  eventMenus: Signal<string[]> = signal(['Regular Menu', 'On Demand Menu']);
  loading = signal(true);
  isUpdating = signal(false);

  #regularMenuItems = signal<IEventMenuOption[]>([]);
  #onDemandMenuItems = signal<IEventMenuOption[]>([]);
  #footerMenuItems = signal<IEventMenuOption[]>([]);
  #eventDetailsMenuItems = signal<IEventMenuOption[]>([]);
  #helpSupportMenuItems = signal<IEventMenuOption[]>([]);
  #messageService = inject(MessageService);
  #eventMenuService = inject(EventMenuService);
  #eventsStore = inject(EventsStore);
  #dialogService = inject(DialogService);
  #translateService = inject(TranslateService);
  #hubsStore = inject(HubsStore);

  async ngOnInit(): Promise<void> {
    const [
      regularMenuItems,
      onDemandMenuItems,
      footerMenuItems,
      eventDetailsMenuItems,
      helpSupportMenuItems,
    ] = await Promise.all([
      this.#eventMenuService.getEventMenuOptions(this.#eventsStore.adminEvent.id, 'regular'),
      this.#eventMenuService.getEventMenuOptions(this.#eventsStore.adminEvent.id, 'onDemand'),
      this.#eventMenuService.getEventMenuOptions(this.#eventsStore.adminEvent.id, 'footer'),
      this.#eventMenuService.getEventMenuOptions(this.#eventsStore.adminEvent.id, 'eventDetails'),
      this.#eventMenuService.getEventMenuOptions(this.#eventsStore.adminEvent.id, 'helpSupport'),
    ]);

    // add names to the items
    this.#regularMenuItems.set(
      regularMenuItems.map((menuOption: IEventMenuOption) => this.addNameToMenuOption(menuOption)),
    );
    this.#onDemandMenuItems.set(
      onDemandMenuItems.map((menuOption: IEventMenuOption) => this.addNameToMenuOption(menuOption)),
    );
    this.#footerMenuItems.set(
      footerMenuItems.map((menuOption: IEventMenuOption) => this.addNameToMenuOption(menuOption)),
    );
    this.#eventDetailsMenuItems.set(
      eventDetailsMenuItems.map((menuOption: IEventMenuOption) =>
        this.addNameToMenuOption(menuOption),
      ),
    );
    this.#helpSupportMenuItems.set(
      helpSupportMenuItems.map((menuOption: IEventMenuOption) =>
        this.addNameToMenuOption(menuOption),
      ),
    );

    this.onSelectMenuChange({ value: 'Regular Menu' });
    this.loading.set(false);
  }

  onEditGroupMenu(menuItem: IEventMenuOptionGroup): void {
    this.#dialogService.open(EventMenuItemsDialogComponent, {
      styleClass: 'event-menu-dialog',
      closable: false,
      data: {
        title: menuItem.name,
        items: menuItem.options,
      },
    });
  }

  async updateMenuSettings(): Promise<void> {
    this.isUpdating.set(true);

    await this.#eventMenuService.updateEventMenuOptions(
      this.#eventsStore.adminEvent.id,
      this.#regularMenuItems(),
      this.#onDemandMenuItems(),
      this.#footerMenuItems(),
      this.#eventDetailsMenuItems(),
      this.#helpSupportMenuItems(),
    );

    this.#messageService.add({
      severity: 'success',
      summary: this.#translateService.instant('success'),
      detail: this.#translateService.instant('adminEventMenu.saveSuccess'),
      styleClass: 'custom-toast',
    });

    this.isUpdating.set(false);
  }

  private addNameToMenuOption(item: IEventMenuOption): IEventMenuOption {
    let name: string;

    if (item.type === 'divider') {
      name = this.#translateService.instant(`adminEventMenu.sectionDividerLabel`);
    } else if (item.key === 'attendees') {
      const attendees = this.#translateService.instant(`asideNavigation.attendeesMenuItem`);
      const matchmaking = this.#translateService.instant(`asideNavigation.matchmakingMenuItem`);
      name = `${attendees} / ${matchmaking}`;
    } else {
      name = this.#translateService.instant(`asideNavigation.${item.key}MenuItem`);
    }

    return {
      name,
      ...item,
    };
  }

  onSelectMenuChange(event: { value: string }): void {
    switch (event.value) {
      case 'Regular Menu':
        this.menuItems.set(this.#regularMenuItems());
        this.filterMenuItems();
        break;
      case 'On Demand Menu':
        this.menuItems.set(this.#onDemandMenuItems());
        this.filterMenuItems();
        break;
      case 'Footer Menu':
        this.menuItems.set(this.#footerMenuItems());
        this.filterMenuItems();
        break;
      case 'Event Details':
        this.menuItems.set(this.#eventDetailsMenuItems());
        this.filterMenuItems();
        break;
      case 'Help & Support':
        this.menuItems.set(this.#helpSupportMenuItems());
        this.filterMenuItems();
        break;

      default:
        this.menuItems.set([]);
        break;
    }
  }

  filterMenuItems(): void {
    if (!this.#hubsStore.hub.brands) {
      this.menuItems.update((menuItems: IEventMenuOption[]) =>
        menuItems.filter((item) => item.id !== 'brands' && item.id !== 'mediaPartners'),
      );
    }
  }
}
