import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { makeAutoObservable } from 'mobx';

import { IGeneralSettings, IEnvironment, IAppearanceSettings } from './core/models';

@Injectable()
export class AppStore {
  public isSideBarVisible = false;
  public isAsideNavigationVisible = false;
  public isTopicsSidebarVisible = false;
  public isSearchVisible = false;
  public generalSystemSettings: IGeneralSettings;
  public appearanceSettings: IAppearanceSettings;
  public environment: IEnvironment;
  // public customDomainHubUrl: string = null;

  public readonly loaderState$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {
    makeAutoObservable(this);
  }

  // public setCustomDomainHubUrl(value: string): void {
  //   this.customDomainHubUrl = value;
  // }

  public setEnvironment(value: IEnvironment): void {
    this.environment = value;
  }

  public setGeneralSystemSettingse(value: IGeneralSettings): void {
    this.generalSystemSettings = value;
  }

  public setAppearanceSystemSettingse(value: IAppearanceSettings): void {
    this.appearanceSettings = value;
  }

  public setSidebarVisible(value: boolean): void {
    this.isSideBarVisible = value;
  }

  public setIsAsideNavigationVisible(value: boolean): void {
    this.isAsideNavigationVisible = value;
  }

  public toggleTopicsSidebarVisible(): void {
    this.isTopicsSidebarVisible = !this.isTopicsSidebarVisible;
  }

  public setIsSearchVisible(value: boolean): void {
    this.isSearchVisible = value;
  }
}
