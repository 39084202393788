import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { asyncDelay } from 'src/app/core/utils';

@Component({
  templateUrl: './progress-bar-dialog.component.html',
  styleUrls: ['./progress-bar-dialog.component.scss'],
})
export class ProgressBarDialogComponent implements OnInit {
  public secondsLeft: number;

  constructor(
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
  ) {}

  public async ngOnInit() {
    this.secondsLeft = this.config.data?.loadingDelay / 1000;
    const secondsLoading = this.secondsLeft;

    for (let i = 0; i < secondsLoading; i++) {
      await asyncDelay(1000);
      this.secondsLeft -= 1;
    }

    this.ref.close();
  }
}
