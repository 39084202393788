<div class="confirm-dialog-container">
  <i
    class="fa-regular fa-xmark close-icon"
    (click)="onCloseDialog()">
  </i>
  <div class="icon">
    <i [class]="className"></i>
  </div>
  <h1>{{ titleKey | translate }}</h1>
  <p>{{ descriptionKey | translate }}</p>
  <div class="actions-container">
    <button
      *ngIf="!hideCancelBtn"
      type="button"
      class="cancel-btn"
      pButton
      label="{{ cancelBtnKey | translate }}"
      (click)="ref.close('cancel')"></button>
    <button
      *ngIf="!hideConfirmBtn"
      type="button"
      class="confirm-btn"
      pButton
      label="{{ confirmBtnKey | translate }}"
      (click)="ref.close('confirm')"></button>
  </div>
</div>
