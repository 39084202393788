import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Firestore, IFirestore, IDocumentData, IQuerySnapshot } from 'src/app/firebase';
import { CollectionTypes } from 'src/app/shared';
import { environment } from 'src/environments/environment';
import {
  ISubscription,
  IUserPaymentSession,
  ICreateStripeSessionCheckout,
  IStripeSessionCheckout,
  IUserStripeAccount,
  ICourseCoupon,
  ProductPricePair,
  IStripeReport,
} from 'src/app/core/models';
import { AuthorizationService } from '../auth';
import { AppStore } from '../../../app.store';
import { BillingPeriod, RevenueAccounts, StripeTaxes } from 'src/app/core/enums';
import { API_ROUTES as apiRoutes, PaymentStatuses } from 'src/app/shared';
import { GoogleTagManagerAnalyticsService, AnalyticsService } from '../analytics';
import { CoursesStore, HubsStore, UsersStore } from '../../stores';
import { UsersService } from '../users';
import { SubscriptionsService } from '../subscriptions';

@Injectable({
  providedIn: 'root',
})
export class StripeService {
  private firestore: IFirestore;

  constructor(
    private httpClient: HttpClient,
    public appStore: AppStore,
    private hubStore: HubsStore,
    private authorizationService: AuthorizationService,
    private gtagService: GoogleTagManagerAnalyticsService,
    private analyticsService: AnalyticsService,
    private usersStore: UsersStore,
    private coursesStore: CoursesStore,
    private usersService: UsersService,
    private subscriptionsService: SubscriptionsService,
  ) {
    this.firestore = Firestore();
  }

  public async getStripeConnectStatusByHubId(hubId: string): Promise<boolean> {
    try {
      const settingsQuery = await this.firestore.collection(CollectionTypes.HUBS).doc(hubId).get();
      return settingsQuery.data().isStripeConnected;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  public async getStripeConnectId(): Promise<string> {
    try {
      const settingsQuery = await this.firestore
        .collection(CollectionTypes.SETTINGS)
        .doc('general')
        .get();
      return settingsQuery.data().stripeConnectId;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  public async getStripePlatformAccountId(): Promise<string> {
    try {
      const settingsQuery = await this.firestore
        .collection(CollectionTypes.SETTINGS)
        .doc('general')
        .get();
      return settingsQuery.data().stripePlatformId;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  public async getStripeConnectIdByHubId(hubId: string): Promise<string> {
    try {
      const settingsQuery = await this.firestore.collection(CollectionTypes.HUBS).doc(hubId).get();
      return settingsQuery.data().stripeConnectId;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  public async setStripeConnect(): Promise<void> {
    try {
      const userQuery = this.firestore.collection(CollectionTypes.SETTINGS).doc('general');
      await userQuery.set({ isStripeConnected: true }, { merge: true });
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  public async setExpressHubStatus(hubId: string, status: boolean): Promise<void> {
    try {
      const hubQuery = this.firestore.collection(CollectionTypes.HUBS).doc(hubId);
      await hubQuery.set({ isStripeExpress: status }, { merge: true });
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  public async setExpressStripeConnect(hubId: string): Promise<void> {
    try {
      const hubQuery = this.firestore.collection(CollectionTypes.HUBS).doc(hubId);
      await hubQuery.set({ isStripeConnected: true }, { merge: true });
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  public async getHubExpressStatus(hubId: string): Promise<boolean> {
    try {
      const settingsQuery = await this.firestore.collection(CollectionTypes.HUBS).doc(hubId).get();
      return settingsQuery.data().isStripeExpress;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  public async setStripeConnectForExistingAccount(code: string, hubId: string): Promise<string> {
    try {
      const headers = await this.authorizationService.buildHeaders();
      const payload = {
        code: code,
        hubId: hubId,
      };
      const response = await this.httpClient
        .post<any>(
          `${environment.apiUrl}stripe/set-stripe-connect-for-existing-account/`,
          payload,
          { headers },
        )
        .toPromise();
      return response.data;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  public async disonnectFromStripe(hubId: string): Promise<void> {
    try {
      const query = this.firestore.collection(CollectionTypes.HUBS).doc(hubId);
      await query.set(
        { isStripeConnected: false, stripeConnectId: null, isStripeExpress: false },
        { merge: true },
      );
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  public async createStripeConnectAccount(
    hubId: string,
    email: string,
    userId: string,
    refreshUrl: string,
    returnUrl: string,
  ): Promise<string> {
    try {
      const headers = await this.authorizationService.buildHeaders();

      const payload = {
        email: email,
        id: userId,
        refreshUrl: refreshUrl,
        returnUrl: returnUrl,
        hubId: hubId,
      };

      const response = await this.httpClient
        .post<any>(`${environment.apiUrl}stripe/create-stripe-connect-account/`, payload, {
          headers,
        })
        .toPromise();

      return response.data.url;
    } catch (error) {
      console.log(error);
      throw new Error(error);
    }
  }

  public async getOnboardingLink(
    stripeConnectId: string,
    refreshUrl: string,
    returnUrl: string,
  ): Promise<string> {
    try {
      const headers = await this.authorizationService.buildHeaders();

      const payload = {
        stripeConnectId: stripeConnectId,
        refreshUrl: refreshUrl,
        returnUrl: returnUrl,
      };

      const response = await this.httpClient
        .post<any>(`${environment.apiUrl}stripe/create-account-link/`, payload, { headers })
        .toPromise();
      return response.data.url;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  public async getLoginLink(stripeConnectId: string): Promise<string> {
    try {
      const headers = await this.authorizationService.buildHeaders();

      const payload = {
        stripeConnectId: stripeConnectId,
      };

      const response = await this.httpClient
        .post<any>(`${environment.apiUrl}stripe/create-login-link/`, payload, { headers })
        .toPromise();
      return response.data.url;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  public async createCustomer(email: string, userName: string): Promise<string> {
    try {
      const headers = await this.authorizationService.buildHeaders();

      const payload = {
        email: email,
        name: userName,
      };
      const response = await this.httpClient
        .post<any>(`${environment.apiUrl}stripe/create-customer/`, payload, { headers })
        .toPromise();
      return response.data.id;
    } catch (error) {
      console.warn(error);
      throw new Error(error.error.data.code);
    }
  }

  public async createCustomerForPlatformAccount(email: string, userName: string): Promise<string> {
    try {
      const headers = await this.authorizationService.buildHeaders();

      const payload = {
        email: email,
        name: userName,
      };
      const response = await this.httpClient
        .post<any>(`${environment.apiUrl}stripe/create-customer-for-platform-account/`, payload, {
          headers,
        })
        .toPromise();
      return response.data.id;
    } catch (error) {
      console.warn(error);
      throw new Error(error.error.data.code);
    }
  }

  async checkCustomer(email: string, customerId: string, name: string): Promise<string> {
    try {
      const headers = await this.authorizationService.buildHeaders();
      const payload = {
        email,
        customerId,
        name,
      };

      const customer = await this.httpClient
        .post<any>(`${environment.apiUrl}stripe/check-customer`, payload, { headers })
        .toPromise();
      return customer.data.id;
    } catch (error) {
      console.warn(error);
      throw new Error(error.error.data.code);
    }
  }

  async updateStripeId(userId: string, stripeId: string): Promise<void> {
    try {
      await this.firestore.collection(apiRoutes.users).doc(userId).update({ stripeId: stripeId });
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  public async createProduct(
    name: string,
    price: number,
    currency: string,
    isPlan: boolean,
    isDigital: boolean,
  ): Promise<ProductPricePair> {
    try {
      const headers = await this.authorizationService.buildHeaders();
      const revenueAccount = isDigital ? RevenueAccounts.DIGITAL : RevenueAccounts.HYBRID;
      const stripeTax = isDigital
        ? StripeTaxes.TRAINING_SERVICES
        : StripeTaxes.PROFESSIONAL_SERVICES;

      const payload = {
        name: name,
        price: price,
        currency: currency,
        isPlan: isPlan,
        revenueAccount,
        stripeTax,
      };

      const product = await this.httpClient
        .post<any>(`${environment.apiUrl}stripe/create-product/`, payload, { headers })
        .toPromise();
      return {
        productId: product.data.productId,
        priceId: product.data.priceId,
      };
    } catch (error) {
      console.warn(error);
      throw new Error(error.error.data.code);
    }
  }

  public async getCheckoutLink(checkoutData: ICreateStripeSessionCheckout): Promise<string> {
    try {
      const headers = await this.authorizationService.buildHeaders();
      const session = await this.httpClient
        .post<any>(`${environment.apiUrl}stripe/create-checkout-session/`, checkoutData, {
          headers,
        })
        .toPromise();
      // this.gtagService.sendCheckoutEvent({event: 'begin_checkout'});
      // this.analyticsService.logBeginCheckout();
      // eslint-disable-next-line @typescript-eslint/dot-notation
      window['dataLayer'].push({ event: 'Begin Checkout' });
      return session.data.url;
    } catch (error) {
      console.warn(error);
      if (error.error.data.code) {
        throw new Error(error.error.data.code);
      } else {
        throw new Error(error.error.data.raw.message);
      }
    }
  }

  public async getCheckoutLinkForTicketingFlow(
    stripeCheckoutData: ICreateStripeSessionCheckout,
  ): Promise<string> {
    try {
      const session = await this.httpClient
        .post<{
          msg: string;
          data: IStripeSessionCheckout;
        }>(`${environment.apiUrl}stripe/create-checkout-session-for-ticketing/`, stripeCheckoutData)
        .toPromise();

      return session.data.url;
    } catch (error) {
      console.warn(error);
      if (error.error.data.code) {
        throw new Error(error.error.data.code);
      } else {
        throw new Error(error.error.data.raw.message);
      }
    }
  }

  public async getStripeCheckoutSession(sessionId: string): Promise<IStripeSessionCheckout> {
    try {
      const session = await this.httpClient
        .get<{
          msg: string;
          data: IStripeSessionCheckout;
        }>(`${environment.apiUrl}stripe/retrieve-checkout-session/`, {
          params: new HttpParams().set('sessionId', sessionId),
        })
        .toPromise();

      return session.data;
    } catch (error) {
      console.warn(error);
      throw new Error(error.error.data.code);
    }
  }

  public async getSubscriptionLink(
    amount: number,
    name: string,
    currency: string,
    successUrl: string,
    cancelUrl: string,
    connectId: string,
    userId: string,
    ticketId: string,
    customerId: string,
    priceId: string,
  ): Promise<string> {
    try {
      const headers = await this.authorizationService.buildHeaders();

      const payload = {
        amount,
        name,
        currency,
        successUrl,
        cancelUrl,
        connectId,
        userId,
        ticketId,
        customerId,
        priceId,
      };

      const session = await this.httpClient
        .post<any>(`${environment.apiUrl}stripe/create-subscription-session/`, payload, { headers })
        .toPromise();
      return session.data.url;
    } catch (error) {
      console.warn(error);
      if (error.error.data.code) {
        throw new Error(error.error.data.code);
      } else {
        throw new Error(error.error.data.raw.message);
      }
    }
  }

  public async retrieveCheckoutSession(sessionId: string): Promise<string> {
    try {
      const headers = await this.authorizationService.buildHeaders();

      const payload = {
        sessionId,
      };

      const session = await this.httpClient
        .post<any>(`${environment.apiUrl}stripe/payment-confirmation/`, payload, { headers })
        .toPromise();

      return session.data.payment_status;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  public async getPaymentSessionIdByUserAndTicketId(
    userId: string,
    ticketId: string,
  ): Promise<string> {
    try {
      const paymentSession = (
        await this.firestore
          .collection(CollectionTypes.USER_PAYMENT_SESSIONS)
          .where('userId', '==', userId)
          .where('ticketId', '==', ticketId)
          .orderBy('createdAt', 'desc')
          .get()
      ).docs[0].data() as IUserPaymentSession;

      return paymentSession.sessionId;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  public async getPaymentSessionsIdByUserAndSubscriptionId(
    userId: string,
    subscriptionId: string,
  ): Promise<string> {
    try {
      const paymentSession = (
        await this.firestore
          .collection(CollectionTypes.USER_PAYMENT_SESSIONS)
          .where('userId', '==', userId)
          .where('subscriptionId', '==', subscriptionId)
          .orderBy('createdAt')
          .get()
      ).docs[0].data() as IUserPaymentSession;

      return paymentSession.sessionId;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  public async getDefaultSubscription(): Promise<ISubscription> {
    try {
      const defaultSubscriptionQuery = await this.firestore
        .collection(CollectionTypes.SUBSCRIPTIONS)
        .where('isDefault', '==', true)
        .get();

      let defaultSubscription;

      defaultSubscriptionQuery.docs.map(async (doc) => {
        defaultSubscription = doc.data();
      });

      return defaultSubscription;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  public async getCustomerPortalLink(
    customerId: string,
    returnUrl: string,
    isAdmin: boolean,
  ): Promise<string> {
    try {
      const headers = await this.authorizationService.buildHeaders();
      let subscriptions: ISubscription[];
      let products;
      if (!isAdmin) {
        subscriptions = await this.subscriptionsService.getByHubId(this.hubStore.hub.id);
        subscriptions = subscriptions.filter((s) => s.billingPeriod != BillingPeriod.Lifetime);
        products = this.transformSubscriptionsToProducts(subscriptions);
      } else {
        products = environment.stripe.adminProducts;
      }

      const payload = {
        customerId,
        returnUrl,
        products,
      };

      const response = await this.httpClient
        .post<any>(`${environment.apiUrl}stripe/create-customer-portal-link/`, payload, { headers })
        .toPromise();
      return response.data.url;
    } catch (error) {
      console.warn(error);
      throw new Error(error.error.data.code);
    }
  }

  public async getCustomerId(userId: string, stripeConnectId?: string): Promise<string> {
    try {
      return await (await this.firestore.collection(CollectionTypes.USERS).doc(userId).get()).data()
        .stripeId;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  public async createInvoice(
    customerId: string,
    priceId: string,
    stripeConnectId: string,
  ): Promise<string> {
    try {
      const headers = await this.authorizationService.buildHeaders();

      const payload = {
        customerId: customerId,
        priceId: priceId,
        stripeConnectId: stripeConnectId,
      };

      const response = await this.httpClient
        .post<any>(`${environment.apiUrl}stripe/create-invoice/`, payload, { headers })
        .toPromise();
      return response.data.id;
    } catch (error) {
      console.warn(error);
      throw new Error(error.error.data.code);
    }
  }

  public async finalizeInvoice(invoiceId: string, customerId: string): Promise<void> {
    try {
      const headers = await this.authorizationService.buildHeaders();

      const payload = {
        invoiceId: invoiceId,
        customerId: customerId,
      };

      await this.httpClient
        .post<any>(`${environment.apiUrl}stripe/finalize-invoice/`, payload, { headers })
        .toPromise();
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  public async createSubscription(customerId: string, priceId: string): Promise<void> {
    try {
      const headers = await this.authorizationService.buildHeaders();

      const payload = {
        customerId: customerId,
        priceId: priceId,
      };
      await this.httpClient
        .post<any>(`${environment.apiUrl}stripe/create-subscription/`, payload, { headers })
        .toPromise();
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async purchaseConfirmationEmail(orderId: string, userId: string = null): Promise<void> {
    try {
      const headers = await this.authorizationService.buildHeaders();

      const payload = { orderId, userId };
      await this.httpClient
        .post<any>(`${environment.apiUrl}stripe/orders/purchase-confirmation-email`, payload, {
          headers,
        })
        .toPromise();
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async purchaseConfirmationEmailForUnregisteredUser(
    orderId: string,
    email: string,
    language: string,
    fullName: string = null,
  ): Promise<void> {
    try {
      const payload = { orderId, email, language, fullName, tenantId: this.hubStore.hub.tenantId };
      await this.httpClient
        .post<any>(`${environment.apiUrl}stripe/orders/anonymous-purchase-confirmation-email`, {
          ...payload,
          tenantId: this.hubStore.hub.tenantId,
        })
        .toPromise();
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  getConnectUrlForExistingAccount(returnUrl: string): string {
    return `https://connect.stripe.com/oauth/v2/authorize?response_type=code&client_id=${this.appStore.environment.stripe.client_id}&scope=read_write&redirect_uri=${returnUrl}`;
  }

  getConnectUrlForExistingExpressAccount(returnUrl: string): string {
    return `https://connect.stripe.com/express/oauth/v2/authorize?response_type=code&client_id=${this.appStore.environment.stripe.client_id}&scope=read_write&redirect_uri=${returnUrl}`;
  }

  async createOrUpdateCustomer(email: string, name: string): Promise<string> {
    try {
      const payload = {
        email,
        name,
      };
      const response = await this.httpClient
        .post<any>(`${environment.apiUrl}stripe/create-or-update-customer/`, payload)
        .toPromise();
      return response.data.id;
    } catch (error) {
      console.warn(error);
      throw new Error(error.error.data.code);
    }
  }

  async getPendingInvitationUrlAndTenantIdWithCheckoutSessionId(
    sessionId: string,
  ): Promise<{ url: string; tenantId: string }> {
    try {
      const collectionName = apiRoutes.userInvites;
      let userInvite: IDocumentData;
      const query: IQuerySnapshot<IDocumentData> = await this.firestore
        .collection(collectionName)
        .where('checkoutSessionId', '==', sessionId)
        .get();

      if (query.size > 0) {
        query.forEach((doc) => (userInvite = doc.data()));
      } else {
        const session = await this.httpClient
          .get<any>(`${environment.apiUrl}stripe/retrieve-checkout-session/`, {
            params: new HttpParams().set('sessionId', sessionId),
          })
          .toPromise();

        const user = await this.usersService.getUserByEmail(session.data.customer_details.email);
        if (session.data.payment_status === PaymentStatuses.PAID) {
          return {
            url: `${this.hubStore.environmentBaseUrl}/signin?redirectLink=new-hub`,
            tenantId: user.tenantId,
          };
        } else {
          return { url: '', tenantId: user.tenantId };
        }
      }

      return userInvite
        ? {
            url: `${this.hubStore.environmentBaseUrl}/signup?invite=${userInvite.token}`,
            tenantId: userInvite.tenantId,
          }
        : { url: '', tenantId: '' };
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async checkStripeCustomer(): Promise<void> {
    const user = this.usersStore.user;
    if (user.stripeId) {
      const customerId = await this.checkCustomer(
        user.email,
        user.stripeId,
        `${user.firstName} ${user.lastName}`,
      );
      await this.usersService.update(user.id, { stripeId: customerId });
      user.stripeId = customerId;
      this.usersStore.setUser(user);
    }
  }

  async createWebhookEndpoint(connectId: string): Promise<void> {
    try {
      const headers = await this.authorizationService.buildHeaders();
      const apiUrl = new URL(environment.apiUrl);
      const baseUrl = apiUrl.protocol + '//' + apiUrl.hostname;
      const payload = {
        connectId: connectId,
        baseUrl: baseUrl,
      };
      const response = await this.httpClient
        .post<any>(`${environment.apiUrl}stripe/create-webhook-endpoint`, payload, { headers })
        .toPromise();
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async createAccountSessionForPaymentComponent(connectId: string): Promise<string> {
    try {
      const headers = await this.authorizationService.buildHeaders();
      const payload = {
        connectId,
      };
      const response = await this.httpClient
        .post<any>(
          `${environment.apiUrl}stripe/create-account-session-for-payment-component/`,
          payload,
          { headers },
        )
        .toPromise();
      return response.data.client_secret;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async createAccountSessionForPayoutsComponent(connectId: string): Promise<string> {
    try {
      const headers = await this.authorizationService.buildHeaders();
      const payload = {
        connectId,
      };
      const response = await this.httpClient
        .post<any>(
          `${environment.apiUrl}stripe/create-account-session-for-payouts-component/`,
          payload,
          { headers },
        )
        .toPromise();
      return response.data.client_secret;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async createCoupon(
    name: string,
    code: string,
    currency: string,
    amount_off: number,
    duration: string,
    percent_off: number,
    max_redemptions: number,
    applies_to: string[],
    expires_at: any,
  ): Promise<any> {
    try {
      const headers = await this.authorizationService.buildHeaders();
      const payload = {
        name,
        code,
        currency,
        duration,
        amount_off: amount_off * 100,
        percent_off,
        max_redemptions,
        applies_to,
        expires_at: expires_at.unix(),
      };

      const newCoupon = await this.httpClient
        .post<any>(`${environment.apiUrl}stripe/create-coupon/`, payload, { headers })
        .toPromise();
      return newCoupon.data;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async updateCoupon(id: string, name: string): Promise<any> {
    try {
      const headers = await this.authorizationService.buildHeaders();
      const payload = {
        id,
        name,
      };
      const updatedCoupon = await this.httpClient
        .post<any>(`${environment.apiUrl}stripe/update-coupon/`, payload, { headers })
        .toPromise();
      return updatedCoupon.data.coupon;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async deleteCoupon(id: string): Promise<void> {
    try {
      const headers = await this.authorizationService.buildHeaders();
      const payload = {
        id,
      };
      await this.httpClient
        .post<any>(`${environment.apiUrl}stripe/delete-coupon/`, payload, { headers })
        .toPromise();
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async retrieveCoupon(id: string): Promise<any> {
    try {
      const headers = await this.authorizationService.buildHeaders();
      const payload = {
        id,
      };
      const coupon = await this.httpClient
        .post<any>(`${environment.apiUrl}stripe/retrieve-coupon/`, payload, { headers })
        .toPromise();
      const timesRedeemed = coupon.data.times_redeemed;
      return { timesRedeemed };
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async searchPaymentIntent(courseId: string): Promise<IStripeReport> {
    try {
      const headers = await this.authorizationService.buildHeaders();
      const payload = {
        courseId,
      };
      const res = await this.httpClient
        .post<any>(`${environment.apiUrl}stripe/search-payment-intent`, payload, { headers })
        .toPromise();
      let stripeReport: IStripeReport;
      let totalAmount = 0;
      res.data.paymentIntents.data.forEach((pi) => {
        totalAmount += pi.amount / 100;
      });
      stripeReport = {
        totalSales: res.data.paymentIntents.data.length,
        totalRevenue: totalAmount,
      };

      return stripeReport;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  transformSubscriptionsToProducts(subscriptions: ISubscription[]) {
    return subscriptions.map((sub) => ({
      product: sub.stripeProductId,
      prices: [sub.stripePriceId],
    }));
  }
}
