import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IAsset, AlgoliaSearchResult, ICourseAsset, ISessionAsset } from 'src/app/core/models';
import { UsersStore, LibraryStore } from 'src/app/core/stores';
import { CollectionTypes, API_ROUTES as apiRoutes } from 'src/app/shared';
import { AlgoliaService, StorageService, AuthorizationService } from 'src/app/core/services';
import {
  Firestore,
  IFirestore,
  Timestamp,
  IFullMetadata,
  IQuerySnapshot,
  IDocumentData,
} from 'src/app/firebase';
import { AppStore } from 'src/app/app.store';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LibraryService {
  private firestore: IFirestore;

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private authorizationService: AuthorizationService,
    private libraryStore: LibraryStore,
    private usersStore: UsersStore,
    private algoliaService: AlgoliaService,
    public appStore: AppStore,
  ) {
    this.firestore = Firestore();
  }

  async getAllWithoutLimitations(): Promise<IAsset[]> {
    const assetsQuerySnapshot: IQuerySnapshot<IDocumentData> = await this.firestore
      .collection(apiRoutes.library)
      .get();
    const assets: IAsset[] = assetsQuerySnapshot.docs.map((doc) => doc.data() as IAsset);

    return assets;
  }

  async getOne(
    id: string,
    forceUpdate: boolean = false,
    setInStore: boolean = true,
  ): Promise<IAsset> {
    try {
      const assetQuery = await this.firestore.collection(CollectionTypes.LIBRARY).doc(id).get();
      if (assetQuery.exists) {
        if (setInStore) this.libraryStore.setAsset(assetQuery.data() as IAsset);
        return assetQuery.data() as IAsset;
      } else {
        return null;
      }
    } catch (error) {
      throw error;
    }
  }

  async create(hubId: string, asset: IAsset): Promise<IAsset> {
    try {
      const preAssetReqDocRef = this.firestore.collection(`${CollectionTypes.LIBRARY}`).doc();
      asset.id = preAssetReqDocRef.id;

      const timestamp = new Date().getTime();

      if (asset.image instanceof File) {
        const assetImageFileName = `asset_${asset.id}_image_${timestamp}`;
        const newAssetImagePath = await this.storageService.upload(
          asset.image,
          `hubs/${hubId}/library`,
          assetImageFileName,
        );
        asset.image = newAssetImagePath;
      }

      if (asset.file instanceof File) {
        const assetFileName = `asset_${asset.id}_${timestamp}.${asset.fileType}`;
        const newAssetFilePath = await this.storageService.upload(
          asset.file,
          `hubs/${hubId}/library`,
          assetFileName,
        );
        asset.file = newAssetFilePath;
      }

      if (asset.internalVideo instanceof File) {
        const assetInternalVideoFileName = `asset_${asset.id}_internal-video_${timestamp}`;
        const newAssetInternalVideoPath = await this.storageService.upload(
          asset.internalVideo,
          `hubs/${hubId}/library`,
          assetInternalVideoFileName,
        );
        asset.internalVideo = newAssetInternalVideoPath;
      }

      asset.createdAt = Timestamp.now();
      asset.createdBy = this.usersStore.user.id;
      asset.updatedAt = Timestamp.now();
      asset.updatedBy = this.usersStore.user.id;

      await preAssetReqDocRef.set({ ...asset });
      await this.algoliaService.createLibrary(asset);

      return asset;
    } catch (error) {
      throw error;
    }
  }

  async update(hubId: string, asset: IAsset): Promise<IAsset> {
    try {
      const assetDocRef = this.firestore.collection(CollectionTypes.LIBRARY).doc(asset.id);
      const currentAssetDoc = await assetDocRef.get();
      const timestamp = new Date().getTime();

      if (asset.image instanceof File) {
        // delete image file if it exits
        const currentImageUrl = currentAssetDoc.get('image');
        if (currentImageUrl) {
          await this.storageService.delete(currentImageUrl);
        }

        const assetImageFileName = `asset_${asset.id}_image_${timestamp}`;
        const newAssetImagePath = await this.storageService.upload(
          asset.image,
          `hubs/${hubId}/library`,
          assetImageFileName,
        );
        asset.image = newAssetImagePath;
      }

      if (asset.file instanceof File) {
        // delete asset file if it exits
        const currentFileUrl = currentAssetDoc.get('file');
        if (currentFileUrl) {
          await this.storageService.delete(currentFileUrl);
        }

        const assetFileName = `asset_${asset.id}_${timestamp}`;
        const newAssetFilePath = await this.storageService.upload(
          asset.file,
          `hubs/${hubId}/library`,
          assetFileName,
        );
        asset.file = newAssetFilePath;
      }

      if (asset.internalVideo instanceof File) {
        // delete internalVideo file if it exits
        const currentInternalVideoUrl = currentAssetDoc.get('internalVideo');
        if (currentInternalVideoUrl) {
          await this.storageService.delete(currentInternalVideoUrl);
        }

        const assetInternalVideoFileName = `asset_${asset.id}_internal-video_${timestamp}`;
        const newAssetInternalVideoPath = await this.storageService.upload(
          asset.internalVideo,
          `hubs/${hubId}/library`,
          assetInternalVideoFileName,
        );
        asset.internalVideo = newAssetInternalVideoPath;
      }

      asset.updatedAt = Timestamp.now();
      asset.updatedBy = this.usersStore.user.id;

      await assetDocRef.update(asset);
      await this.algoliaService.updateLibrary(asset);

      return asset;
    } catch (error) {
      return null;
    }
  }

  async delete(hubId: string, assetId: string): Promise<boolean> {
    const headers = await this.authorizationService.buildHeaders();

    try {
      await this.http.delete(`${environment.apiUrl}library/${assetId}`, { headers }).toPromise();
      await this.algoliaService.removeLibrary(assetId);

      return true;
    } catch (error) {
      return null;
    }
  }

  async getAll(
    hubId: string,
    pageIndex: number,
    pageSize: number,
    searchTerm: string,
    filters: string,
  ): Promise<AlgoliaSearchResult<IAsset>> {
    try {
      return await this.algoliaService.search<IAsset>(
        CollectionTypes.LIBRARY,
        `${hubId} ${searchTerm}`,
        pageSize,
        pageIndex * pageSize,
        filters,
      );
    } catch (error) {
      return {
        results: [],
        total: 0,
      };
    }
  }

  get(hubId: string): Observable<IAsset[]> {
    const events = [];
    let query = this.firestore
      .collection(CollectionTypes.LIBRARY)
      .where('hubId', '==', hubId)
      .orderBy('_title_');

    return from(query.get()).pipe(
      map((response) => {
        response.forEach((doc) => events.push(doc.data()));
        return events;
      }),
    );
  }

  async getByIds(
    assetIds: string[],
    paginate: boolean,
    entry: IAsset,
    order: 'asc' | 'desc' = 'desc',
    pageSize: number = 12,
  ): Promise<IAsset[]> {
    const assets: IAsset[] = [];
    let assetIdSearch: string[] = [];

    if (paginate) {
      const startFromIndex = entry ? assetIds.indexOf(entry.id) + 1 : 0;
      assetIdSearch = assetIds.splice(startFromIndex, pageSize);
    } else {
      assetIdSearch = [...assetIds];
    }

    while (assetIdSearch.length > 0) {
      const chunk = assetIdSearch.splice(0, 10);

      const query: IQuerySnapshot<IDocumentData> = await this.firestore
        .collection(apiRoutes.library)
        .where('id', 'in', chunk)
        .orderBy('_title_', order)
        .get();

      if (!query.empty) {
        query.forEach((doc) => {
          const currentAsset = doc.data() as IAsset;
          if (!assets.some((asset) => asset.id === currentAsset.id)) {
            assets.push(currentAsset);
          }
        });
      }
    }

    return assets;
  }

  async fetchUnattached(
    hubId: string,
    courseId: string,
    pageIndex: number,
    pageSize: number,
    searchTerm: string,
  ): Promise<AlgoliaSearchResult<IAsset>> {
    const attachedAssets: ICourseAsset[] = await this.getAssetsFromCourse(courseId);
    const facetFilters = attachedAssets
      .map((b: ICourseAsset) => b.assetId)
      .map((id) => `objectID:-${id}`)
      .join(',');

    const result: AlgoliaSearchResult<IAsset> = await this.algoliaService.search<IAsset>(
      CollectionTypes.LIBRARY,
      `${searchTerm} ${hubId}`,
      pageSize,
      pageIndex * pageSize,
      '',
      facetFilters,
    );

    return {
      total: result.total,
      results: result.results,
    };
  }

  async fetchUnattachedForSession(
    hubId: string,
    eventId: string,
    sessionId: string,
    pageIndex: number,
    pageSize: number,
    searchTerm: string,
  ): Promise<AlgoliaSearchResult<IAsset>> {
    const attachedAssets: ISessionAsset[] = await this.getAssetsFromSession(eventId, sessionId);
    const facetFilters = attachedAssets
      .map((b: ISessionAsset) => b.assetId)
      .map((id) => `objectID:-${id}`)
      .join(',');

    const result: AlgoliaSearchResult<IAsset> = await this.algoliaService.search<IAsset>(
      CollectionTypes.LIBRARY,
      `${searchTerm} ${hubId}`,
      pageSize,
      pageIndex * pageSize,
      'type:FILE',
      facetFilters,
    );

    return result;
  }

  async getAssetsFromCourse(courseId: string): Promise<ICourseAsset[]> {
    const courseAssets: ICourseAsset[] = [];
    const courseAssetsQuery = await this.firestore
      .collection(CollectionTypes.COURSE_ASSETS)
      .where('courseId', '==', courseId)
      .get();

    courseAssetsQuery.forEach((brandQuery) => {
      const assets: ICourseAsset = brandQuery.data() as ICourseAsset;
      courseAssets.push(assets);
    });

    return courseAssets;
  }

  async getAssetsFromSession(eventId: string, sessionId: string): Promise<ISessionAsset[]> {
    const sessionAssets: ISessionAsset[] = [];
    const sessionAssetsQuery = await this.firestore
      .collection(apiRoutes.sessionAssets(eventId))
      .where('sessionId', '==', sessionId)
      .get();

    sessionAssetsQuery.docs.map((doc) => {
      sessionAssets.push(doc.data() as ISessionAsset);
    });

    return sessionAssets;
  }

  checkIfAssetLinkExists(assetLink: string, assetId: string = null): Observable<boolean> {
    const courses = [];
    const coursesQuery = this.firestore
      .collection(CollectionTypes.LIBRARY)
      .where('link', '==', assetLink);

    return from(coursesQuery.get()).pipe(
      map((response) => {
        response.forEach((doc) => {
          if (doc.data().id === assetId) {
            return;
          }
          courses.push(doc.data());
        });

        return courses.length > 0;
      }),
    );
  }

  async getLibraryFileFromStorage(url: string): Promise<File> {
    try {
      const file: IFullMetadata = await this.storageService.getFileFormStorage(url);
      return file as undefined as File;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }
}
