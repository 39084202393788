<div
  class="event-card-container"
  (click)="onNavigateToEventPage()">
  <div
    *ngIf="bookmarkBtnShow && loggedUser"
    class="bookmark-icon-box"
    (click)="bookmark($event)">
    <i [ngClass]="getBookmarkedBtnClass"></i>
  </div>
  <div class="img-container">
    <img
      *ngIf="event.banner"
      class="banner img-preloader"
      [defaultImage]="imgPreloader"
      [lazyLoad]="event.banner | imageSize" />
    <div
      *ngIf="!event.banner"
      class="default-banner-container">
      <svg
        width="142"
        height="137"
        viewBox="0 0 142 137"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M141.037 16.8864C141.037 17.3611 141.017 17.8288 140.975 18.292C140.607 22.4768 138.618 26.3506 135.434 29.0838C132.25 31.8169 128.125 33.1921 123.942 32.9146C119.759 32.6372 115.85 30.7292 113.054 27.5994C110.258 24.4696 108.795 20.3668 108.981 16.1699C109.166 11.9729 110.984 8.01554 114.045 5.14534C117.106 2.27514 121.167 0.720376 125.359 0.814055C129.55 0.907734 133.538 2.64241 136.468 5.64654C139.399 8.65066 141.039 12.6853 141.037 16.8864Z"
          fill="#E5E5E5" />
        <path
          d="M127.025 18.1168C125.99 18.9233 124.544 20.5293 124.98 24.1031C124.495 25.4902 122.771 28.14 122.771 29.4072C122.771 30.6744 120.701 30.9054 120.471 30.5588C120.241 30.2122 120.127 25.8353 118.862 25.0288C117.597 24.2224 117.827 20.5357 117.827 20.5357C117.827 20.5357 118.287 19.1531 116.792 18.2315C115.298 17.3099 114.256 15.1209 115.583 13.8536C116.907 12.5863 113.343 11.7798 112.884 10.8582C112.688 10.4669 111.939 9.59091 111.143 8.7176C112.863 5.79832 115.457 3.49588 118.556 2.13688C119.731 3.36674 120.747 4.94106 120.472 6.59543C120.517 7.40188 124.01 8.66919 122.299 10.3968C120.587 12.125 118.262 14.5448 122.299 15.3508C126.335 16.1584 128.059 17.31 127.025 18.1168Z"
          [attr.fill]="defaultCoversColor" />
        <path
          d="M136.209 5.38638C135.349 7.20432 133.192 8.66975 131.969 8.66975C130.589 7.17157 127.6 9.24588 127.32 6.82642C127.156 5.41634 128.761 3.22731 130.129 1.64215C132.41 2.41764 134.488 3.6973 136.209 5.38638Z"
          [attr.fill]="defaultCoversColor" />
        <path
          d="M140.975 18.292C140.633 22.2702 138.821 25.9769 135.894 28.6861C135.363 28.1768 134.914 27.5801 135.073 27.1031C135.418 26.0662 136.82 23.8772 136.579 22.4948C136.354 21.2044 137.542 17.3012 140.975 18.292Z"
          [attr.fill]="defaultCoversColor" />
        <path
          d="M114.869 98.1591C114.355 101.333 113.505 104.443 112.333 107.437C104.697 127.039 84.2105 139.32 62.6512 135.79C50.7489 133.838 40.1073 127.229 33.0656 117.417C26.0238 107.605 23.1583 95.3922 25.0988 83.4636C25.1974 82.8584 25.3124 82.2482 25.4325 81.6543C30.2964 57.8076 53.1299 41.8731 77.316 45.832C84.5331 47.0012 91.3595 49.9069 97.2099 54.3C99.1323 55.7384 100.936 57.3296 102.603 59.0583C107.555 64.1618 111.243 70.3573 113.373 77.1477C115.503 83.938 116.015 91.1341 114.869 98.1587V98.1591Z"
          fill="#E5E5E5" />
        <path
          d="M112.333 107.437C103.672 113.024 93.4441 118.192 82.1683 122.466C70.6865 126.815 59.4115 129.754 49.0775 131.293C24.7937 134.921 5.72257 130.867 1.42552 119.477C-2.59863 108.838 7.29918 94.4284 25.4323 81.6542C25.3122 82.2481 25.1972 82.8584 25.0986 83.4636C24.7362 85.6789 24.5395 87.9182 24.5102 90.1629C14.0627 98.2464 8.62576 106.163 10.769 111.842C12.8528 117.363 21.7003 119.713 34.2867 119.047C46.5914 118.397 62.4695 114.846 79.0891 108.549C93.4633 103.097 106.068 96.4699 115.454 89.8128C128.019 80.9113 134.834 71.9539 132.482 65.7051C130.063 59.2902 118.523 57.1521 102.603 59.0587C100.936 57.33 99.132 55.7388 97.2096 54.3004C119.494 51.7196 136.67 55.9372 140.719 66.6652C145.056 78.1395 133.228 93.9806 112.333 107.437Z"
          [attr.fill]="defaultCoversColor" />
      </svg>
    </div>
  </div>
  <div class="main-content">
    <h1 class="event-title">{{ event.title }}</h1>
    <div class="status-date-container">
      <i
        *ngIf="isLive()"
        class="is-live-icon fa-solid fa-circle"></i>
      <div>
        <p class="event-date">{{ eventDateFirstLine }}</p>
        <p class="event-date">{{ eventDateSecondLine }}</p>
      </div>
    </div>
    <div class="button-container">
      <button
        *ngIf="!isCurrentUserRegisteredInEvent"
        class="join-btn"
        [class.join-btn--demand]="event.isOnDemandMode || event.isOnDemandComingSoon">
        <i
          *ngIf="event.isOnDemandMode || event.isOnDemandComingSoon"
          class="fa-light fa-play"></i>
        {{
          !event.isOnDemandMode && !event.isOnDemandComingSoon
            ? ('eventCard.detailsBtn' | translate)
            : ('eventCard.onDemandBtn' | translate)
        }}
      </button>
      <button
        *ngIf="isCurrentUserRegisteredInEvent"
        class="join-btn join-btn--attending">
        <i class="far fa-circle-check"></i>
        {{ 'eventCard.attending' | translate }}
      </button>
    </div>
  </div>
</div>
