import * as moment from 'moment';
import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Subject, debounceTime, filter, firstValueFrom, merge, takeUntil, tap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Moment } from 'moment';

import {
  AssetStatus,
  IAsset,
  ICourse,
  IDailyRoom,
  IDailyRoomConfig,
  ISession,
  IUser,
} from 'src/app/core/models';
import {
  CourseAssetsService,
  LibraryService,
  VimeoService,
  SessionAssetsService,
  CourseInstructorsService,
  UsersService,
  DailyCoService,
} from 'src/app/core/services';
import { CoursesStore, HubsStore, UsersStore, EventsStore } from 'src/app/core/stores';
import { Timestamp } from 'src/app/firebase';
import { AssetEventTypes, AssetFileTypes, AssetType } from 'src/app/core/enums';
import { SharedModule } from 'src/app/shared';
import { trackByFn, updateTime } from 'src/app/core/utils';
import { ButtonSize, ButtonStyle } from 'src/app/standalone';
import { ButtonComponent, CustomRadioButtonComponent } from 'src/app/standalone/shared/components';

interface ITypeAsset {
  iconClass: string;
  title: string;
  description: string;
  value: AssetType;
}

@Component({
  selector: 'app-new-asset',
  standalone: true,
  imports: [CommonModule, SharedModule, CustomRadioButtonComponent, ButtonComponent],
  templateUrl: './new-asset.component.html',
  styleUrls: ['./new-asset.component.scss'],
})
export class NewAssetComponent implements OnInit, OnDestroy {
  @ViewChild('internalVideoInput') internalVideoInput: ElementRef<HTMLInputElement>;

  loading = true;
  isUpdating = false;
  linkLoading = false;
  isFileUpload = false;
  form: UntypedFormGroup;
  createAnotherControl = new UntypedFormControl(null);
  assetType = AssetType;
  fileData: File;
  fileType: AssetFileTypes;
  eventType = AssetEventTypes;
  allowFileSize = 4_294_967_296; // 4GB
  course: ICourse = null;
  session: ISession = null;
  allowedFileTypes: string[] = [
    'image/png',
    'image/jpeg',
    'image/jpg',
    'application/pdf',
    'application/zip',
    'application/x-zip-compressed',
  ];
  allowedFormatsForInternalVideo: string[] = ['video/mp4', 'video/webm'];
  fileVideoPreview: SafeResourceUrl;
  trackByFn = trackByFn;
  typeAssets: ITypeAsset[];
  instructors: IUser[];
  datePlaceholder = moment().add(1, 'month').format('DD/MM/YY');
  buttonStyle = ButtonStyle;
  buttonSize = ButtonSize;

  readonly linkPrefix: string = '';

  private internalVideoMaxSize = 8_000_000_000; // 8GB
  private createdAssetIds: string[] = [];
  private unsubscribe$ = new Subject<void>();

  constructor(
    private translateService: TranslateService,
    private ref: DynamicDialogRef,
    private libraryService: LibraryService,
    private hubsStore: HubsStore,
    private usersStore: UsersStore,
    private messageService: MessageService,
    private vimeoService: VimeoService,
    private configDialog: DynamicDialogConfig,
    private courseAssetsService: CourseAssetsService,
    private coursesStore: CoursesStore,
    private eventsStore: EventsStore,
    private sessionAssetsService: SessionAssetsService,
    private sanitizer: DomSanitizer,
    private courseInstructorsService: CourseInstructorsService,
    private usersService: UsersService,
    private dailyCoService: DailyCoService,
  ) {
    this.linkPrefix = this.hubsStore.hub
      ? `${this.hubsStore.environmentBaseEventsUrl}${this.hubsStore.useHubUrl}/`
      : this.hubsStore.environmentBaseEventsUrl;
  }

  get canUpdate(): boolean {
    return !this.isUpdating && this.form.valid && this.form.dirty;
  }

  async ngOnInit(): Promise<void> {
    this.form = new UntypedFormGroup({
      title: new UntypedFormControl(null, Validators.required),
      type: new UntypedFormControl(AssetType.VIDEO),
      videoLink: new UntypedFormControl(null),
      file: new UntypedFormControl(null),
      internalVideo: new UntypedFormControl(null),
      date: new UntypedFormControl(null),
      start: new UntypedFormControl(null),
      end: new UntypedFormControl(null),
      instructors: new UntypedFormControl(null),
      eventType: new UntypedFormControl(null),
      isRecording: new UntypedFormControl(null),
      videoDuration: new UntypedFormControl(null),
    });
    this.course = this.configDialog.data?.course || null;
    this.session = this.configDialog.data?.session || null;

    this.initTypeAssets();

    if (this.session) {
      this.form.controls.type.setValidators([Validators.required]);
      this.form.controls.type.disable();
    }

    this.form.controls.type.valueChanges
      .pipe(
        tap((assetType: AssetType) => {
          if (assetType === AssetType.EVENT) {
            this.addValidatorsForAssetEventControls();
          } else {
            this.removeValidatorsForAssetEventControls();
          }
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();

    this.form.controls.title.valueChanges.subscribe((value) => {
      if (typeof value === 'string') {
        const trimmedValue = value.replace(/^\s+/, '');
        if (trimmedValue !== value) {
          this.form.get('title').setValue(trimmedValue, { emitEvent: false });
        }
      }
    });

    const startTimeValue$ = this.form.controls.start.valueChanges;
    const endTimeValue$ = this.form.controls.end.valueChanges;

    merge(startTimeValue$, endTimeValue$)
      .pipe(
        debounceTime(300),
        filter((value) => !!value),
        tap(() => {
          const start = updateTime(this.form.controls.date.value, this.form.controls.start.value);
          const end = updateTime(this.form.controls.date.value, this.form.controls.end.value);

          if (moment(start).isAfter(end)) {
            const validEnd = start.add(1, 'hours');
            this.form.controls.end.setValue(validEnd.toDate(), { emitEvent: false });
          }
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();

    if (this.course) {
      const userCoursesIds: string[] =
        await this.courseInstructorsService.getAllCourseInstructorsIds(this.course.id);
      this.instructors = await this.usersService.getUserByIds(userCoursesIds);
    }

    this.loading = false;
  }

  getFileTypeText(type: string): string {
    return this.translateService.instant(`adminLibraryAssetFrom.${type.toLowerCase()}`);
  }

  onUpload(data: { files: File[] }): void {
    this.fileData = data.files[0];
    this.form.controls.file.setValue(this.fileData);
    this.isFileUpload = true;

    // check if the selected image format is allowed
    if (!this.allowedFileTypes.includes(this.fileData.type)) {
      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('error'),
        detail: this.translateService.instant(
          'adminLibraryAssetFrom.fileTypeNotAllowedErrorMessage',
        ),
        styleClass: 'custom-toast',
      });

      this.form.controls.file.setValue(null);
      return;
    }

    if (this.fileData.size > this.allowFileSize) {
      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('error'),
        detail: this.translateService.instant(
          'adminLibraryAssetFrom.fileSizeNotAllowedErrorMessage',
          { size: (this.allowFileSize / (1024 * 1024)).toFixed(2) },
        ),
        styleClass: 'custom-toast',
      });

      this.form.controls.file.setValue(null);
      return;
    }

    switch (this.fileData.type) {
      case 'image/png':
        this.fileType = AssetFileTypes.PNG;
        break;
      case 'image/jpeg':
      case 'image/jpg':
        this.fileType = AssetFileTypes.JPG;
        break;
      case 'application/pdf':
        this.fileType = AssetFileTypes.PDF;
        break;
      case 'application/zip':
      case 'application/x-zip-compressed':
        this.fileType = AssetFileTypes.ZIP;
        break;
    }
  }

  onError(err: any): void {
    console.log(err);
  }

  customClear(fileUpload: FileUpload): void {
    this.fileData = null;
    fileUpload.clear();
  }

  async create(): Promise<void> {
    if (!this.hubsStore.hubId) {
      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('adminLibraryAssetFrom.pleaseSelectHub'),
        styleClass: 'custom-toast',
      });
      return;
    }
    this.isUpdating = true;

    try {
      const asset = this.form.getRawValue();
      let videoDuration: number = asset.videoDuration;
      if (asset.type === AssetType.VIDEO && asset.videoLink) {
        videoDuration = await this.getVideoDurationByUrl(asset.videoLink);
      }

      let startDate: Moment = null;
      let endDate: Moment = null;
      let eventAssetMeetingRoomUrl: string = null;
      let eventAssetMeetingRoomName: string = null;
      let eventAssetStartDate: Timestamp = null;
      let eventAssetEndDate: Timestamp = null;

      if (asset.type === AssetType.EVENT) {
        startDate = updateTime(asset.date, asset.start);
        endDate = updateTime(asset.date, asset.end);
        eventAssetStartDate = Timestamp.fromMillis(startDate.toDate().getTime());
        eventAssetEndDate = Timestamp.fromMillis(endDate.toDate().getTime());

        const configForDailyRoom: IDailyRoomConfig = {
          owner_only_broadcast: asset.eventType === AssetEventTypes.BROADCAST,
          exp: moment(endDate).add(20, 'minutes').unix(),
          eject_at_room_exp: true,
          enable_network_ui: true,
          enable_prejoin_ui: true,
          enable_video_processing_ui: true,
        };

        const room: IDailyRoom = await firstValueFrom(
          this.dailyCoService.createRoom(configForDailyRoom),
        );
        eventAssetMeetingRoomUrl = room.url;
        eventAssetMeetingRoomName = room.name;
      }

      const assetPayload: IAsset = {
        id: null,
        hubId: this.hubsStore.hubId,
        title: asset.title,
        _title_: asset.title.toLowerCase(),
        shortDescription: null,
        link: null,
        type: asset.type,
        file: asset.type === AssetType.FILE ? asset.file : null,
        fileType: asset.type === AssetType.FILE ? this.fileType : null,
        fileName: asset.type === AssetType.FILE ? this.fileData.name : null,
        fileSize: asset.type === AssetType.FILE ? this.fileData.size : null,
        videoLink: asset.type === AssetType.VIDEO ? asset.videoLink : null,
        videoDuration,
        internalVideo: asset.type === AssetType.VIDEO ? asset.internalVideo : null,
        isGlobal: null,
        brands: [],
        featured: null,
        isVisible: null,
        description: null,
        status: AssetStatus.DRAFT,
        tags: null,
        eventAssetStartDate,
        eventAssetEndDate,
        instructors: asset.instructors,
        eventType: asset.eventType,
        isRecording: asset.isRecording,
        breakoutRoomDailySetting: false,
        cameraDailySetting: false,
        microphoneDailySetting: false,
        screenSharingDailySetting: false,
        textChatDailySetting: false,
        peopleListingDailySetting: false,
        pictureInPictureDailySetting: false,
        handRisingDailySetting: false,
        emojiReactionDailySetting: false,
        eventAssetMeetingRoomUrl,
        eventAssetMeetingRoomName,
        createdAt: Timestamp.now(),
        createdBy: this.usersStore.userId,
      };

      const newAsset: IAsset = await this.libraryService.create(this.hubsStore.hubId, assetPayload);
      this.createdAssetIds.push(newAsset.id);

      if (this.course) {
        await this.courseAssetsService.createCourseAsset(this.coursesStore.adminCourse, newAsset);
      }

      if (this.session) {
        await this.sessionAssetsService.createSessionAsset(
          this.eventsStore.adminEvent.id,
          newAsset,
        );
      }

      if (this.createAnotherControl.value) {
        this.form.reset({ createAnotherControl: true, type: AssetType.VIDEO });
        this.clearInternalVideo();
      } else {
        this.ref.close(this.createdAssetIds);
      }

      this.isUpdating = false;
      this.messageService.add({
        severity: 'success',
        summary: this.translateService.instant('adminLibraryAssetFrom.successCreateAsset'),
        styleClass: 'custom-toast',
      });
    } catch (error) {
      console.log(error);
      this.isUpdating = false;
    }
  }

  async getVideoDurationByUrl(url: string): Promise<number> {
    try {
      const { duration } = await this.vimeoService.getVideoInfoByUrl(url).toPromise();
      return duration;
    } catch (error) {
      console.warn(error);
      if (error.status === 404) {
        this.form.controls.videoLink.setErrors({ invalidUrl: true });
      }
      throw new Error(error);
    }
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    this.uploadVideo(event.dataTransfer.files);
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
  }

  uploadInternalVideo(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (!input.files || input.files.length === 0) {
      return;
    }

    this.uploadVideo(input.files);
  }

  clearInternalVideo(): void {
    this.form.patchValue({ internalVideo: '', duration: null });
    this.internalVideoInput.nativeElement.value = '';
    this.fileVideoPreview = null;
  }

  onChangeAssetType(assetType: ITypeAsset): void {
    if (this.form.controls.type.value === assetType.value) {
      return;
    }

    this.form.patchValue({
      type: assetType.value,
    });
  }

  onCloseDialog(): void {
    this.ref.close(this.createdAssetIds);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private initTypeAssets(): void {
    if (this.course) {
      this.typeAssets = [
        {
          iconClass: 'fa-regular fa-video',
          title: 'adminLibraryAssetFrom.typeVideoLabel',
          description: 'adminLibraryAssetFrom.typeVideoDesc',
          value: AssetType.VIDEO,
        },
        {
          iconClass: 'fa-regular fa-calendar',
          title: 'adminLibraryAssetFrom.typeEventLabel',
          description: 'adminLibraryAssetFrom.typeEventDesc',
          value: AssetType.EVENT,
        },
        {
          iconClass: 'fa-regular fa-rectangle-history',
          title: 'adminLibraryAssetFrom.typeFileLabel',
          description: 'adminLibraryAssetFrom.typeFileDesc',
          value: AssetType.FILE,
        },
      ];
    } else if (this.session) {
      this.typeAssets = [
        {
          iconClass: 'fa-regular fa-rectangle-history',
          title: 'adminLibraryAssetFrom.typeFileLabel',
          description: 'adminLibraryAssetFrom.typeFileDesc',
          value: AssetType.FILE,
        },
      ];
      this.form.patchValue({ type: AssetType.FILE });
    } else {
      this.typeAssets = [
        {
          iconClass: 'fa-regular fa-video',
          title: 'adminLibraryAssetFrom.typeVideoLabel',
          description: 'adminLibraryAssetFrom.typeVideoDesc',
          value: AssetType.VIDEO,
        },
        {
          iconClass: 'fa-regular fa-rectangle-history',
          title: 'adminLibraryAssetFrom.typeFileLabel',
          description: 'adminLibraryAssetFrom.typeFileDesc',
          value: AssetType.FILE,
        },
      ];
    }
  }

  private async uploadVideo(files: FileList): Promise<void> {
    const file = files[0];

    if (file.size > this.internalVideoMaxSize) {
      this.messageService.add({
        severity: 'error',
        detail: this.translateService.instant('adminLibraryAssetFrom.maxSizeError'),
        styleClass: 'custom-toast',
      });
      this.clearInternalVideo();

      return;
    }

    if (!this.allowedFormatsForInternalVideo.includes(file.type)) {
      this.messageService.add({
        severity: 'error',
        detail: this.translateService.instant('adminLibraryAssetFrom.invalidVideoFormat'),
        styleClass: 'custom-toast',
      });
      this.clearInternalVideo();

      return;
    }

    const reader = new FileReader();
    const readFilePromise: Promise<string> = new Promise((resolve, reject) => {
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        resolve(reader.result as string);
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });

    try {
      const result: string = await readFilePromise;
      this.form.controls.internalVideo.setValue(file);
      this.fileVideoPreview = this.sanitizer.bypassSecurityTrustResourceUrl(result as string);
      this.setDurationForInternalVideo(file);
    } catch (error) {
      this.clearInternalVideo();
      this.messageService.add({
        severity: 'error',
        detail: error.currentTarget.error.message,
        styleClass: 'custom-toast',
      });
    }
  }

  private setDurationForInternalVideo(file: File): void {
    const videoElement = document.createElement('video');
    videoElement.preload = 'metadata';
    const objectURL = URL.createObjectURL(file);
    videoElement.src = objectURL;
    videoElement.addEventListener('loadedmetadata', () => {
      this.form.controls.videoDuration.setValue(Math.round(videoElement.duration));
      URL.revokeObjectURL(objectURL);
      videoElement.removeEventListener('loadedmetadata', () => {});
    });
  }

  private addValidatorsForAssetEventControls(): void {
    this.form.controls.date.addValidators(Validators.required);
    this.form.controls.date.markAsUntouched();
    this.form.controls.start.addValidators(Validators.required);
    this.form.controls.start.markAsUntouched();
    this.form.controls.end.addValidators(Validators.required);
    this.form.controls.end.markAsUntouched();
    this.form.controls.instructors.addValidators(Validators.required);
    this.form.controls.eventType.addValidators(Validators.required);
  }

  private removeValidatorsForAssetEventControls(): void {
    this.form.controls.date.removeValidators(Validators.required);
    this.form.controls.date.updateValueAndValidity();
    this.form.controls.start.removeValidators(Validators.required);
    this.form.controls.start.updateValueAndValidity();
    this.form.controls.end.removeValidators(Validators.required);
    this.form.controls.end.updateValueAndValidity();
    this.form.controls.instructors.removeValidators(Validators.required);
    this.form.controls.instructors.updateValueAndValidity();
    this.form.controls.eventType.removeValidators(Validators.required);
    this.form.controls.eventType.updateValueAndValidity();
  }
}
