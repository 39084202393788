<form [formGroup]="form">
  <ng-container *ngFor="let formField of formFieldsForShowing">
    <ng-container [ngSwitch]="formField.fieldName">
      <ng-container *ngSwitchCase="'country'">
        <div>
          <app-input-label
            [labelType]="getLabelType(formField)"
            for="country-input"
            [text]="getLabel(formField.fieldName)"
            [tooltipText]="getTooltip(formField.fieldName)"
            [tooltipEvent]="isMobile ? 'focus' : 'hover'"
            [tabIndex]="isMobile ? 0 : null">
          </app-input-label>
          <p-dropdown
            class="custom-dropdown"
            [class.custom-dropdown-required]="formField.isRequired"
            panelStyleClass="custom-dropdown-panel"
            [options]="countriesArray"
            [filter]="true"
            [virtualScroll]="true"
            [virtualScrollItemSize]="virtualScrollItemSize"
            formControlName="country"
            inputId="country-input"
            optionLabel="name"
            appendTo="body"
            [placeholder]="getPlaceholder(formField.fieldName)">
          </p-dropdown>
          <app-input-error-message
            [showMessage]="
              form.get(formField.fieldName).invalid && form.get(formField.fieldName).touched
            "
            errorMessage="{{ 'application.forms.required' | translate }}">
          </app-input-error-message>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'travelDocument'">
        <div>
          <app-input-label
            [labelType]="getLabelType(formField)"
            for="travel-document-input"
            [text]="getLabel(formField.fieldName)"
            [tooltipText]="getTooltip(formField.fieldName)"
            [tooltipEvent]="isMobile ? 'focus' : 'hover'"
            [tabIndex]="isMobile ? 0 : null">
          </app-input-label>
          <p-dropdown
            class="custom-dropdown"
            [class.custom-dropdown-required]="formField.isRequired"
            panelStyleClass="custom-dropdown-panel"
            [options]="travelDocumentTypes"
            formControlName="travelDocument"
            inputId="travel-document-input"
            [placeholder]="getPlaceholder(formField.fieldName)"
            appendTo="body">
            <ng-template
              pTemplate="item"
              let-item
              >{{ item | translate }}</ng-template
            >
            <ng-template
              pTemplate="selectedItem"
              let-selectedItem>
              {{ selectedItem | translate }}
            </ng-template>
          </p-dropdown>
          <app-input-error-message
            [showMessage]="
              form.get(formField.fieldName).invalid && form.get(formField.fieldName).touched
            "
            errorMessage="{{ 'application.forms.required' | translate }}">
          </app-input-error-message>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'dateOfIssue'">
        <div>
          <app-input-label
            [labelType]="getLabelType(formField)"
            for="date-of-issue-input"
            [text]="getLabel(formField.fieldName)"
            [tooltipText]="getTooltip(formField.fieldName)"
            [tooltipEvent]="isMobile ? 'focus' : 'hover'"
            [tabIndex]="isMobile ? 0 : null">
          </app-input-label>
          <p-calendar
            class="custom-calendar"
            [class.custom-calendar-required]="formField.isRequired"
            panelStyleClass="custom-calendar-panel-style-class"
            formControlName="dateOfIssue"
            [monthNavigator]="true"
            [yearNavigator]="true"
            [showIcon]="true"
            [maxDate]="dateForValidation"
            appendTo="body"
            dateFormat="dd/mm/yy"
            yearRange="1900:2050"
            inputId="date-of-issue-input"
            icon="fak fa-lox-calendar"
            [placeholder]="getPlaceholder(formField.fieldName)">
          </p-calendar>
          <app-input-error-message
            [showMessage]="
              form.get(formField.fieldName).invalid && form.get(formField.fieldName).touched
            "
            errorMessage="{{ 'application.forms.required' | translate }}">
          </app-input-error-message>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'dateOfExpiry'">
        <div>
          <app-input-label
            [labelType]="getLabelType(formField)"
            for="date-of-expiry-input"
            [text]="getLabel(formField.fieldName)"
            [tooltipText]="getTooltip(formField.fieldName)"
            [tooltipEvent]="isMobile ? 'focus' : 'hover'"
            [tabIndex]="isMobile ? 0 : null">
          </app-input-label>
          <p-calendar
            class="custom-calendar"
            [class.custom-calendar-required]="formField.isRequired"
            panelStyleClass="custom-calendar-panel-style-class"
            formControlName="dateOfExpiry"
            [monthNavigator]="true"
            [yearNavigator]="true"
            [showIcon]="true"
            appendTo="body"
            dateFormat="dd/mm/yy"
            yearRange="1900:2050"
            inputId="date-of-expiry-input"
            icon="fak fa-lox-calendar"
            [placeholder]="getPlaceholder(formField.fieldName)">
          </p-calendar>
          <app-input-error-message
            [showMessage]="
              form.get(formField.fieldName).invalid && form.get(formField.fieldName).touched
            "
            errorMessage="{{ 'application.forms.required' | translate }}">
          </app-input-error-message>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'birthDate'">
        <div>
          <app-input-label
            [labelType]="getLabelType(formField)"
            for="birth-date-input"
            [text]="getLabel(formField.fieldName)"
            [tooltipText]="getTooltip(formField.fieldName)"
            [tooltipEvent]="isMobile ? 'focus' : 'hover'"
            [tabIndex]="isMobile ? 0 : null">
          </app-input-label>
          <p-calendar
            class="custom-calendar"
            [class.custom-calendar-required]="formField.isRequired"
            panelStyleClass="custom-calendar-panel-style-class"
            formControlName="birthDate"
            [monthNavigator]="true"
            [yearNavigator]="true"
            [showIcon]="true"
            [maxDate]="dateForValidation"
            appendTo="body"
            dateFormat="dd/mm/yy"
            yearRange="1900:2050"
            inputId="birth-date-input"
            icon="fak fa-lox-calendar"
            [placeholder]="getPlaceholder(formField.fieldName)">
          </p-calendar>
          <app-input-error-message
            [showMessage]="
              form.get(formField.fieldName).invalid && form.get(formField.fieldName).touched
            "
            errorMessage="{{ 'application.forms.required' | translate }}">
          </app-input-error-message>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'nationality'">
        <div>
          <app-input-label
            [labelType]="getLabelType(formField)"
            for="nationality-input"
            [text]="getLabel(formField.fieldName)"
            [tooltipText]="getTooltip(formField.fieldName)"
            [tooltipEvent]="isMobile ? 'focus' : 'hover'"
            [tabIndex]="isMobile ? 0 : null">
          </app-input-label>
          <p-dropdown
            class="custom-dropdown"
            [class.custom-dropdown-required]="formField.isRequired"
            panelStyleClass="custom-dropdown-panel"
            [options]="countriesArray"
            [filter]="true"
            formControlName="nationality"
            inputId="nationality-input"
            optionLabel="name"
            [placeholder]="getPlaceholder(formField.fieldName)"
            appendTo="body">
          </p-dropdown>
          <app-input-error-message
            [showMessage]="
              form.get(formField.fieldName).invalid && form.get(formField.fieldName).touched
            "
            errorMessage="{{ 'application.forms.required' | translate }}">
          </app-input-error-message>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'vegetarian'">
        <div>
          <app-input-label
            [labelType]="getLabelType(formField)"
            for="vegetarian-input"
            [text]="getLabel(formField.fieldName)"
            [tooltipText]="getTooltip(formField.fieldName)"
            [tooltipEvent]="isMobile ? 'focus' : 'hover'"
            [tabIndex]="isMobile ? 0 : null">
          </app-input-label>
          <p-dropdown
            class="custom-dropdown"
            [class.custom-dropdown-required]="formField.isRequired"
            panelStyleClass="custom-dropdown-panel"
            [options]="vegetarianTypes"
            formControlName="vegetarian"
            inputId="vegetarian-input"
            [placeholder]="getPlaceholder(formField.fieldName)"
            appendTo="body">
            <ng-template
              pTemplate="item"
              let-item
              >{{ item | translate }}</ng-template
            >
            <ng-template
              pTemplate="selectedItem"
              let-selectedItem>
              {{ selectedItem | translate }}
            </ng-template>
          </p-dropdown>
          <app-input-error-message
            [showMessage]="
              form.get(formField.fieldName).invalid && form.get(formField.fieldName).touched
            "
            errorMessage="{{ 'application.forms.required' | translate }}">
          </app-input-error-message>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'divider'">
        <div class="c-mb-3">
          <p-divider styleClass="custom-divider-horizontal"></p-divider>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'salutation'">
        <div>
          <app-input-label
            [labelType]="getLabelType(formField)"
            for="salutation-input"
            [text]="getLabel(formField.fieldName)"
            [tooltipText]="getTooltip(formField.fieldName)"
            [tooltipEvent]="isMobile ? 'focus' : 'hover'"
            [tabIndex]="isMobile ? 0 : null">
          </app-input-label>
          <p-dropdown
            class="custom-dropdown"
            [class.custom-dropdown-required]="formField.isRequired"
            panelStyleClass="custom-dropdown-panel"
            [options]="salutationTypes"
            formControlName="salutation"
            inputId="salutation-input"
            appendTo="body"
            [placeholder]="getPlaceholder(formField.fieldName)">
            <ng-template
              pTemplate="item"
              let-item
              >{{ item | translate }}</ng-template
            >
            <ng-template
              pTemplate="selectedItem"
              let-selectedItem>
              {{ selectedItem | translate }}
            </ng-template>
          </p-dropdown>
          <app-input-error-message
            [showMessage]="
              form.get(formField.fieldName).invalid && form.get(formField.fieldName).touched
            "
            errorMessage="{{ 'application.forms.required' | translate }}">
          </app-input-error-message>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'clothingSize'">
        <div>
          <app-input-label
            [labelType]="getLabelType(formField)"
            for="clothing-size-input"
            [text]="getLabel(formField.fieldName)"
            [tooltipText]="getTooltip(formField.fieldName)"
            [tooltipEvent]="isMobile ? 'focus' : 'hover'"
            [tabIndex]="isMobile ? 0 : null">
          </app-input-label>
          <p-dropdown
            class="custom-dropdown"
            [class.custom-dropdown-required]="formField.isRequired"
            panelStyleClass="custom-dropdown-panel"
            [options]="clothingSizes"
            formControlName="clothingSize"
            inputId="clothing-size-input"
            [placeholder]="getPlaceholder(formField.fieldName)"
            appendTo="body">
            <ng-template
              pTemplate="item"
              let-item
              >{{ item | translate }}</ng-template
            >
            <ng-template
              pTemplate="selectedItem"
              let-selectedItem>
              {{ selectedItem | translate }}
            </ng-template>
          </p-dropdown>
          <app-input-error-message
            [showMessage]="
              form.get(formField.fieldName).invalid && form.get(formField.fieldName).touched
            "
            errorMessage="{{ 'application.forms.required' | translate }}">
          </app-input-error-message>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'industry'">
        <div>
          <app-input-label
            [labelType]="getLabelType(formField)"
            for="industry-input"
            [text]="getLabel(formField.fieldName)"
            [tooltipText]="getTooltip(formField.fieldName)"
            [tooltipEvent]="isMobile ? 'focus' : 'hover'"
            [tabIndex]="isMobile ? 0 : null">
          </app-input-label>
          <p-dropdown
            class="custom-dropdown"
            [class.custom-dropdown-required]="formField.isRequired"
            panelStyleClass="custom-dropdown-panel"
            [options]="industryTypes"
            formControlName="industry"
            inputId="industry-input"
            [placeholder]="getPlaceholder(formField.fieldName)"
            appendTo="body">
            <ng-template
              pTemplate="item"
              let-item
              >{{ item | translate }}</ng-template
            >
            <ng-template
              pTemplate="selectedItem"
              let-selectedItem>
              {{ selectedItem | translate }}
            </ng-template>
          </p-dropdown>
          <app-input-error-message
            [showMessage]="
              form.get(formField.fieldName).invalid && form.get(formField.fieldName).touched
            "
            errorMessage="{{ 'application.forms.required' | translate }}">
          </app-input-error-message>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'subIndustry'">
        <div>
          <app-input-label
            [labelType]="getLabelType(formField)"
            for="sub-industry-input"
            [text]="getLabel(formField.fieldName)"
            [tooltipText]="getTooltip(formField.fieldName)"
            [tooltipEvent]="isMobile ? 'focus' : 'hover'"
            [tabIndex]="isMobile ? 0 : null">
          </app-input-label>
          <p-dropdown
            class="custom-dropdown"
            [class.custom-dropdown-required]="formField.isRequired"
            panelStyleClass="custom-dropdown-panel"
            [options]="subIndustryTypes"
            formControlName="subIndustry"
            inputId="sub-industry-input"
            [placeholder]="getPlaceholder(formField.fieldName)"
            appendTo="body">
            <ng-template
              pTemplate="item"
              let-item
              >{{ item | translate }}</ng-template
            >
            <ng-template
              pTemplate="selectedItem"
              let-selectedItem>
              {{ selectedItem | translate }}
            </ng-template>
          </p-dropdown>
          <app-input-error-message
            [showMessage]="
              form.get(formField.fieldName).invalid && form.get(formField.fieldName).touched
            "
            errorMessage="{{ 'application.forms.required' | translate }}">
          </app-input-error-message>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'areaOfActivity'">
        <div>
          <app-input-label
            [labelType]="getLabelType(formField)"
            for="area-of-activity-input"
            [text]="getLabel(formField.fieldName)"
            [tooltipText]="getTooltip(formField.fieldName)"
            [tooltipEvent]="isMobile ? 'focus' : 'hover'"
            [tabIndex]="isMobile ? 0 : null">
          </app-input-label>
          <p-dropdown
            class="custom-dropdown"
            [class.custom-dropdown-required]="formField.isRequired"
            panelStyleClass="custom-dropdown-panel"
            [options]="areaOfActivityTypes"
            formControlName="areaOfActivity"
            inputId="area-of-activity-input"
            [placeholder]="getPlaceholder(formField.fieldName)"
            appendTo="body">
            <ng-template
              pTemplate="item"
              let-item
              >{{ item | translate }}</ng-template
            >
            <ng-template
              pTemplate="selectedItem"
              let-selectedItem>
              {{ selectedItem | translate }}
            </ng-template>
          </p-dropdown>
          <app-input-error-message
            [showMessage]="
              form.get(formField.fieldName).invalid && form.get(formField.fieldName).touched
            "
            errorMessage="{{ 'application.forms.required' | translate }}">
          </app-input-error-message>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'optionalPosition'">
        <div>
          <app-input-label
            [labelType]="getLabelType(formField)"
            for="optional-position-input"
            [text]="getLabel(formField.fieldName)"
            [tooltipText]="getTooltip(formField.fieldName)"
            [tooltipEvent]="isMobile ? 'focus' : 'hover'"
            [tabIndex]="isMobile ? 0 : null">
          </app-input-label>
          <p-dropdown
            class="custom-dropdown"
            [class.custom-dropdown-required]="formField.isRequired"
            panelStyleClass="custom-dropdown-panel"
            [options]="optionalPositionTypes"
            formControlName="optionalPosition"
            inputId="optional-position-input"
            [placeholder]="getPlaceholder(formField.fieldName)"
            appendTo="body">
            <ng-template
              pTemplate="item"
              let-item
              >{{ item | translate }}</ng-template
            >
            <ng-template
              pTemplate="selectedItem"
              let-selectedItem>
              {{ selectedItem | translate }}
            </ng-template>
          </p-dropdown>
          <app-input-error-message
            [showMessage]="
              form.get(formField.fieldName).invalid && form.get(formField.fieldName).touched
            "
            errorMessage="{{ 'application.forms.required' | translate }}">
          </app-input-error-message>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'companySize'">
        <div>
          <app-input-label
            [labelType]="getLabelType(formField)"
            for="company-size-input"
            [text]="getLabel(formField.fieldName)"
            [tooltipText]="getTooltip(formField.fieldName)"
            [tooltipEvent]="isMobile ? 'focus' : 'hover'"
            [tabIndex]="isMobile ? 0 : null">
          </app-input-label>
          <p-dropdown
            class="custom-dropdown"
            [class.custom-dropdown-required]="formField.isRequired"
            panelStyleClass="custom-dropdown-panel"
            [options]="companySizeTypes"
            formControlName="companySize"
            inputId="company-size-input"
            [placeholder]="getPlaceholder(formField.fieldName)"
            appendTo="body">
            <ng-template
              pTemplate="item"
              let-item
              >{{ item | translate }}</ng-template
            >
            <ng-template
              pTemplate="selectedItem"
              let-selectedItem>
              {{ selectedItem | translate }}
            </ng-template>
          </p-dropdown>
          <app-input-error-message
            [showMessage]="
              form.get(formField.fieldName).invalid && form.get(formField.fieldName).touched
            "
            errorMessage="{{ 'application.forms.required' | translate }}">
          </app-input-error-message>
        </div>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <div>
          <app-input-label
            [labelType]="getLabelType(formField)"
            for="{{ formField.fieldName }}-input"
            [text]="getLabel(formField.fieldName)"
            [tooltipText]="getTooltip(formField.fieldName)"
            [tooltipEvent]="isMobile ? 'focus' : 'hover'"
            [tabIndex]="isMobile ? 0 : null">
          </app-input-label>
          <input
            class="custom-input-text"
            [class.custom-input-text-required]="formField.isRequired"
            [formControl]="form.get(formField.fieldName)"
            id="{{ formField.fieldName }}-input"
            pInputText
            type="text"
            [maxlength]="getMaxLengthValue(formField.fieldName)"
            [placeholder]="getPlaceholder(formField.fieldName)" />
          <app-input-error-message
            [showMessage]="
              form.get(formField.fieldName).invalid && form.get(formField.fieldName).touched
            "
            errorMessage="{{ 'application.forms.required' | translate }}">
          </app-input-error-message>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</form>
