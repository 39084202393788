import { Component, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

import { IBrand, IEvent, ITag } from 'src/app/core/models';
import { BrandsService, TagsService, ThemesService, UsersService } from 'src/app/core/services';
import { EventsStore, HubsStore, UsersStore } from 'src/app/core/stores';
import { goToLink } from 'src/app/core/utils';
import { userAvatar } from '../../utils';

@Component({
  selector: 'app-default-brand',
  templateUrl: './default-brand.component.html',
  styleUrls: ['./default-brand.component.scss'],
})
export class DefaultBrandComponent implements OnInit {
  @Input() brandId: string = null;
  @Input() showBackBtn = true;

  loading = false;
  showBookmarkContent: boolean;
  brand: IBrand;
  tags: ITag[] = [];
  currentEvent: IEvent;
  userAvatar = userAvatar;
  showBookmarkSpinner = false;
  bookmarkedIds: string[] = [];

  get defaultCoversColor(): string {
    const currentTheme = localStorage.getItem('styleTheme');
    const currentThemeProps =
      this.themesService.systemAppearanceSettings[currentTheme + 'Theme'].properties;
    return (
      this.currentEvent?.accentColor ||
      this.hubsStore.hub?.primaryColor ||
      currentThemeProps['--appPrimaryColor']
    );
  }

  constructor(
    private route: ActivatedRoute,
    private eventsStore: EventsStore,
    private brandsService: BrandsService,
    private tagsService: TagsService,
    private location: Location,
    private hubsStore: HubsStore,
    private themesService: ThemesService,
    private usersStore: UsersStore,
    private usersService: UsersService,
  ) {
    this.brandId = this.route.snapshot.params.brandId;
  }

  async ngOnInit(): Promise<void> {
    this.showBookmarkContent = !!this.usersStore.user;
    this.currentEvent = this.eventsStore.event;
    this.brand = await this.brandsService.getOne(this.brandId);
    this.tags = await this.tagsService.getByIdsOrderedByTitle(this.brand?.tags);
    this.bookmarkedIds = await this.usersService.getUserBookmarkedBrandsIds(this.usersStore.userId);
    this.loading = false;
  }

  async onBookmark(): Promise<void> {
    this.showBookmarkSpinner = true;
    if (this.bookmarkedIds.includes(this.brand.id)) {
      const result: boolean = await this.usersService.unBookmarkBrand(
        this.usersStore.userId,
        this.brand.id,
      );
      if (result) {
        const elIndex = this.bookmarkedIds.indexOf(this.brand.id);
        if (elIndex > -1) this.bookmarkedIds.splice(elIndex, 1);
        this.showBookmarkSpinner = false;
      }
    } else {
      const result: boolean = await this.usersService.bookmarkBrand(
        this.usersStore.userId,
        this.brand.id,
      );
      if (result) {
        this.bookmarkedIds.push(this.brand.id);
        this.showBookmarkSpinner = false;
      }
    }
  }

  getBookmarkedIconClass(): string {
    if (this.showBookmarkSpinner) {
      return 'fa fa-circle-o-notch fa-spin';
    } else {
      return this.bookmarkedIds.includes(this.brand?.id)
        ? 'fak fa-lox-bookmark-1 active'
        : 'fak fa-lox-bookmark-1';
    }
  }

  onGoToLink(url: string): void {
    goToLink(url);
  }

  onBack(): void {
    this.location.back();
  }
}
