import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { autorun, IReactionDisposer } from 'mobx';
import { TranslateService } from '@ngx-translate/core';

import {
  ConfirmDialogComponent,
  IAdminTableFilter,
  imgPreloader,
  logoAvatar,
  IAdminTableColumn,
  jpgImg,
  pdfImg,
  pngImg,
  videoImg,
  zipImg,
} from 'src/app/shared';
import { IBrand, IAsset, ICourse } from 'src/app/core/models';
import { BrandsService, CourseAssetsService } from 'src/app/core/services';
import { HubsStore, CoursesStore } from 'src/app/core/stores';
import { AttachAssetsDialogComponent } from 'src/app/admin/dialogs/attach-assets/attach-assets-dialog.component';
import { AssetType, AssetFileTypes } from 'src/app/core/enums';
import { NewAssetComponent } from 'src/app/standalone/shared/components/asset';
import { ButtonSize, ButtonStyle, ButtonType } from 'src/app/standalone';

@Component({
  selector: 'app-course-media',
  templateUrl: './course-media.component.html',
  styleUrls: ['./course-media.component.scss'],
})
export class CourseMediaComponent implements OnInit, OnDestroy {
  loading = true;
  loadingEntries = true;
  columns: IAdminTableColumn[] = [
    {
      field: 'title',
      template: 'titleCell',
      titleTranslationKey: 'adminCourseMedia.tableTitle',
      sortable: true,
    },
    { field: 'type', titleTranslationKey: 'adminCourseMedia.type' },
    { field: 'actions', template: 'actionsCell' },
  ];
  allAssets: IAsset[] = [];
  entries: IAsset[] = [];
  totalRecords = 0;
  pageSize = 10;
  courseAssetIds: string[] = [];
  brands: IBrand[] = [];
  selectedBrands: string[] = [];
  assetBrands: IBrand[] = [];
  tableFilters: IAdminTableFilter[] = [];
  imgPreloader = imgPreloader;
  logoAvatar = logoAvatar;
  assetType = AssetType;
  assetEventBannerContainerStyles = {
    background: '#323232',
    'border-radius': '0.25rem',
  };
  assetEventBannerMonthStyles = {
    'font-size': '0.625rem',
    'line-height': '0.625rem',
  };
  assetEventBannerDateStyle = {
    'font-size': '1.125rem',
    'line-height': '1.125rem',
  };
  buttonStyle = ButtonStyle;
  buttonType = ButtonType;
  buttonSize = ButtonSize;

  private disposer: IReactionDisposer;

  constructor(
    public hubsStore: HubsStore,
    public coursesStore: CoursesStore,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private dialogService: DialogService,
    private brandsService: BrandsService,
    private courseAssetsService: CourseAssetsService,
    private translateService: TranslateService,
  ) {}

  get course(): ICourse {
    return this.coursesStore.adminCourse;
  }

  async ngOnInit(): Promise<void> {
    this.disposer = autorun(async () => {
      if (this.course) {
        this.loading = true;
        this.loadingEntries = true;

        await this.fetchAssets();

        this.loadingEntries = false;
        this.loading = false;
      }
    });
    this.brands = await this.brandsService.getAll();

    this.loading = false;
  }

  async onSearchTermChange(e): Promise<void> {
    this.loadingEntries = true;
    this.entries = this.allAssets.filter((x) => x._title_.includes(e.toLowerCase()));
    this.loadingEntries = false;
  }

  async fetchEntries(event: any): Promise<void> {
    this.loadingEntries = true;
    this.pageSize = event.rows;
    await this.fetchAssets();
    this.loadingEntries = false;
  }

  filterDuplicates(array: any[]): any[] {
    const newArray = [];

    array.forEach((x) => {
      if (!newArray.includes(x)) {
        newArray.push(x);
      }
    });

    return newArray;
  }

  removeEntry(entry: IAsset): void {
    this.entries.splice(this.entries.indexOf(entry), 1);
    this.totalRecords -= 1;
  }

  openAttachAssetsDialog(): void {
    this.dialogService
      .open(AttachAssetsDialogComponent, {
        width: '90%',
        height: '80%',
        closable: false,
        styleClass: 'attach-assets-dialog',
      })
      .onClose.subscribe(async () => {
        this.loadingEntries = true;
        this.entries = [];
        await this.fetchAssets();
        this.loadingEntries = false;
      });
  }

  async openNewCourseAssetDialog(): Promise<void> {
    const newAssetDialogRef: DynamicDialogRef = this.dialogService.open(NewAssetComponent, {
      styleClass: 'new-library-asset-dialog',
      closable: false,
      data: {
        course: this.course,
      },
    });

    const newAssetIds: string[] = await newAssetDialogRef.onClose.pipe(take(1)).toPromise();
    if (newAssetIds?.length) {
      this.router.navigate(
        [
          `/${this.hubsStore.useHubUrl}/admin/courses/${this.course.link}/setup/media/${newAssetIds.at(-1)}`,
        ],
        {
          queryParams: { 'action-before': 'create' },
        },
      );
    }
  }

  getImagePlaceholder(asset: IAsset): string {
    if (asset.type === AssetType.VIDEO) {
      return videoImg;
    }

    switch (asset.fileType) {
      case AssetFileTypes.PNG:
        return pngImg;
      case AssetFileTypes.JPG:
        return jpgImg;
      case AssetFileTypes.PDF:
        return pdfImg;
      case AssetFileTypes.ZIP:
        return zipImg;
      default:
        return pngImg;
    }
  }

  onRowClick(id: string) {
    this.router.navigate(
      [
        `/${this.hubsStore.useHubUrl}/admin/courses/${this.coursesStore.adminCourse.link}/setup/media/${id}`,
      ],
      {
        relativeTo: this.route,
        state: {
          assetId: id,
        },
      },
    );
  }

  edit(event: Event, entry: any): void {
    event.stopPropagation();
    this.router.navigate(
      [
        `/${this.hubsStore.useHubUrl}/admin/courses/${this.coursesStore.adminCourse.link}/setup/media/${entry.id}`,
      ],
      {
        relativeTo: this.route,
        state: {
          assetId: entry.id,
        },
      },
    );
  }

  remove(event: Event, entry: IAsset): void {
    event.stopPropagation();
    const deleteDialogRef = this.dialogService.open(ConfirmDialogComponent, {
      closable: false,
      styleClass: 'confirm-dialog',
      data: {
        titleKey: 'adminCourseMedia.deleteAsset',
        descriptionKey: 'adminCourseMedia.deleteAssetText',
        confirmBtnKey: 'adminCourseMedia.confirmBtn',
        cancelBtnKey: 'adminCourseMedia.cancelBtn',
        entry,
      },
    });

    deleteDialogRef.onClose.pipe(take(1)).subscribe(async (result: 'cancel' | 'confirm') => {
      if (result === 'confirm') {
        try {
          const deleteResult = this.courseAssetsService.delete(entry.id);
          if (deleteResult) {
            this.removeEntry(entry);

            this.messageService.add({
              severity: 'success',
              summary: this.translateService.instant('success'),
              detail: this.translateService.instant('adminCourseMedia.successDeleteAsset'),
              styleClass: 'custom-toast',
            });
          }
        } catch (error) {
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('error'),
            detail: this.translateService.instant('adminCourseMedia.errorDeleteAsset'),
            styleClass: 'custom-toast',
          });
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.disposer();
  }

  private async fetchAssets(): Promise<void> {
    let response = null;

    const courseAssets = await this.courseAssetsService.getAllCourseAssetsByCourseId(
      this.coursesStore.adminCourse.id,
    );
    const chaptersAssetIds = [];
    courseAssets.forEach((ch) => {
      chaptersAssetIds.push(ch.assetId);
    });

    this.courseAssetIds = this.filterDuplicates(chaptersAssetIds);
    response = await this.courseAssetsService.getAssetsByIds(this.courseAssetIds);
    this.allAssets = response;
    this.entries = this.allAssets;
    this.totalRecords = this.entries.length;
    const brandIds: string[] = [];

    this.entries.forEach((asset: IAsset) => {
      if (Array.isArray(asset?.brands) && asset?.brands?.length) {
        if (!brandIds.includes(asset?.brands[0])) {
          brandIds.push(asset.brands[0]);
        }
      }
    });

    this.brandsService.getByIds(brandIds).then((brands) => {
      this.assetBrands = brands;

      this.entries.forEach((entry) => {
        if (entry?.brands && entry?.brands?.length) {
          entry.brandName = this.assetBrands.find((x) => x.id === entry.brands[0])?.name;
        }
      });
    });
  }
}
