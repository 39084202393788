import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';

import { IMG_FORMATS } from 'src/app/core/utils';
import { logoAvatar } from 'src/app/shared/utils';
import { ButtonSize, ButtonStyle } from 'src/app/standalone';
import { ButtonComponent } from 'src/app/standalone/shared/components/';

@Component({
  selector: 'app-small-profile-picture-media-form',
  standalone: true,
  imports: [TranslateModule, ButtonComponent],
  templateUrl: './small-profile-picture-media-form.component.html',
  styleUrl: './small-profile-picture-media-form.component.scss',
})
export class SmallProfilePictureMediaFormComponent {
  @Input() titleMediaForm: string;
  @Input() description: string;
  @Input() btnConfirm: string;
  @Input() btnCancel: string;
  @Input() imgValue: string;
  @Output() changeValue: EventEmitter<File> = new EventEmitter<File>();
  @ViewChild('profileImageUpload') profileImageUploadInput: ElementRef<HTMLInputElement>;

  profileImageUploadProcess = false;
  logoAvatar = logoAvatar;
  buttonStyle = ButtonStyle;
  buttonSize = ButtonSize;

  private newProfileImagePreview: string;

  constructor(
    private messageService: MessageService,
    private translateService: TranslateService,
    private sanitizer: DomSanitizer,
  ) {}

  get profileImage(): string | ArrayBuffer | SafeUrl {
    if (this.profileImageUploadProcess) {
      return null;
    }

    return this.newProfileImagePreview && typeof this.newProfileImagePreview === 'string'
      ? this.sanitizer.bypassSecurityTrustUrl(this.newProfileImagePreview)
      : this.imgValue;
  }

  onProfileImageUpload(event: Event): void {
    this.profileImageUploadProcess = true;

    const onSuccess = (img: string) => {
      this.newProfileImagePreview = img;
      this.profileImageUploadProcess = false;
    };

    const onError = (e) => {
      if (e === 'type_size') {
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('error'),
          detail: this.translateService.instant(
            'application.toasters.imageTypeOrSizeRuleViolation_PNG_JPEG',
            { size: 3 },
          ),
          styleClass: 'custom-toast',
        });
        this.profileImageUploadInput.nativeElement.value = '';
      }
      this.profileImageUploadProcess = false;
    };

    this.uploadImg(event, onSuccess, onError);
  }

  clearProfileImage(): void {
    this.newProfileImagePreview = null;
    this.profileImageUploadInput.nativeElement.value = '';
    this.changeValue.emit(null);
  }

  private uploadImg(
    event: Event,
    onSuccess: (img: string | ArrayBuffer) => void,
    onError: (e) => void,
  ): void {
    const input = event.target as HTMLInputElement;

    if (!input.files || input.files.length === 0) {
      return;
    }

    const imageFile = input.files[0];

    if (!IMG_FORMATS.includes(imageFile.type) || imageFile.size > 3_194_304) {
      onError('type_size');
      return;
    }
    this.changeValue.emit(imageFile);

    const reader = new FileReader();
    reader.readAsDataURL(imageFile);

    reader.onloadend = () => onSuccess(reader.result);

    reader.onerror = (error) => {
      console.log(error);
      onError(error);
    };
  }
}
