export function formatBytes(fileSize, decimals = 2) {
  const bytes = fileSize;
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

// The function accepts any string, but returns only a string with latin letters and dashes.
// Spaces are replaced with dashes, umlauts are replaced with latin letters, all other characters are ignored.
export function preparedValueForLink(titleValue: string): string {
  const value = titleValue.trim().toLowerCase().replace(/\s+/g, ' ').split(' ').join('-');
  let result = '';
  for (let i = 0; i < value.length; i++) {
    if (value[i].match(/[a-z,0-9,-]/)) {
      result += value[i];
    }

    if (value[i].match(/[\u00df, \u00e4, \u00f6, \u00fc, \u00c4, \u00d6, \u00dc]/)) {
      result += replaceUmlauts(value[i]);
    }
  }
  return result;
}

export function replaceUmlauts(value: string): string {
  return value
    .replace(/\u00df/g, 'ss')
    .replace(/\u00e4/g, 'ae')
    .replace(/\u00f6/g, 'oe')
    .replace(/\u00fc/g, 'ue')
    .replace(/\u00c4/g, 'ae')
    .replace(/\u00d6/g, 'oe')
    .replace(/\u00dc/g, 'ue');
}
