import { Component, inject, signal } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject, takeUntil, tap } from 'rxjs';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import {
  brandMergeTags,
  capitalizeFirstLetter,
  courseMergeTags,
  hubMergeTags,
  systemEmailEventMergeTags,
  systemEmailUserMergeTags,
} from 'src/app/core/utils';
import { ButtonSize } from '../../../enums';

declare const unlayer: any;

@Component({
  standalone: true,
  imports: [],
  template: '',
})
export class BaseUnlayerEditorComponent {
  templates = signal(null);
  currentTemplate = new FormControl({ value: null, disabled: true });
  buttonSize = signal<typeof ButtonSize>(ButtonSize);
  isUnlayerEditorReady = signal(false);

  private unlayerHtml = signal('');
  private unlayerDesign = signal('');
  private unsubscribe$ = new Subject<void>();
  private config = inject(DynamicDialogConfig);
  private dialogRef = inject(DynamicDialogRef);

  ngOnInit(): void {
    this.templates.set(this.config.data.templates);

    if (this.config.data.currentTemplate) {
      const { design, html } = this.config.data.currentTemplate;
      this.unlayerDesign.set(design);
      this.unlayerHtml.set(html);
    }

    const mergeTags = [
      ...systemEmailUserMergeTags,
      ...courseMergeTags,
      ...hubMergeTags,
      ...systemEmailEventMergeTags,
      ...brandMergeTags,
    ].reduce((acc, tag) => {
      const [prefix, property] = tag.split('.');
      const mergeTagName = `${prefix}.${property}`;
      const key = `${property}${capitalizeFirstLetter(prefix)}`;
      acc[key] = {
        mergeTagName,
        value: `{{${mergeTagName}}}`,
        sample: `{{${mergeTagName}}}`,
      };
      return acc;
    }, {});

    unlayer.init({
      id: 'editor',
      projectId: 218240,
      appearance: {
        theme: 'modern_dark',
      },
      tools: {
        timer: {
          enabled: true,
        },
        video: {
          enabled: true,
        },
      },
      mergeTags,
      version: 'latest',
    });

    if (this.unlayerDesign() && this.unlayerHtml()) {
      unlayer.loadDesign(JSON.parse(this.unlayerDesign()));
    }

    unlayer.addEventListener('editor:ready', () => {
      this.isUnlayerEditorReady.set(true);
      this.currentTemplate.enable({ emitEvent: false });
    });

    this.currentTemplate.valueChanges
      .pipe(
        tap((template) => {
          unlayer.loadDesign(template.design);
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();
  }

  async onSaveTemplate(): Promise<void> {
    unlayer.exportHtml((data: unknown) => {
      this.dialogRef.close({ design: JSON.stringify(data['design']), html: data['html'] });
    });
  }

  onCloseDialog(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
