<div
  class="container"
  [class.active]="isActive()">
  <div class="header">
    <div class="icon-wrapper">
      <i [class]="iconClass()"></i>
    </div>
    <div class="checkbox"></div>
  </div>
  <h4 class="title">{{ title() | translate }}</h4>
  <p class="description">{{ description() | translate }}</p>
</div>
