<div>
  @if (chapters?.length) {
    <div class="c-mb-5">
      @if (isLiveAssetEvent) {
        <div class="cursor-pointer">
          <app-chapter-asset-card
            [asset]="isLiveAssetEvent"
            assetEventStatus="live"
            (click)="onChangeActiveAsset(isLiveAssetEvent)">
          </app-chapter-asset-card>
        </div>
      }
      @if (isUpcomingAssetEvent && !isLiveAssetEvent) {
        <div class="cursor-pointer">
          <app-chapter-asset-card
            [asset]="isUpcomingAssetEvent"
            assetEventStatus="upcoming"
            (click)="onChangeActiveAsset(isUpcomingAssetEvent)">
          </app-chapter-asset-card>
        </div>
      }
    </div>
    <p-accordion
      expandIcon="far fa-chevron-down"
      collapseIcon="far fa-chevron-up"
      [styleClass]="stylesClassForAccordion">
      @for (chapter of chapters; track chapter.id; let i = $index) {
        @if (chapter.assetsFullModel.length) {
          <p-accordionTab [(selected)]="activeState[i]">
            <ng-template pTemplate="header">
              <p class="chapter-title">{{ chapter.title }}</p>
            </ng-template>
            @for (asset of chapter.assetsFullModel; track asset.id) {
              <div
                class="chapter-asset-card-container"
                [class.selected]="asset.id === activeAsset.id">
                <app-chapter-asset-card
                  [asset]="asset"
                  (click)="onChangeActiveAsset(asset)">
                </app-chapter-asset-card>
              </div>
            }
          </p-accordionTab>
        }
      }
    </p-accordion>
  } @else {
    <div class="empty-list">
      <app-empty-state
        icon="far fa-graduation-cap"
        text="{{ 'courseRegisteredPage.noChapters' | translate }}">
      </app-empty-state>
    </div>
  }
</div>
