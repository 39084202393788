import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-course-coupon',
  templateUrl: './course-coupon.component.html',
  styleUrls: ['./course-coupon.component.scss'],
})
export class CourseCouponComponent implements OnInit {
  loading = true;
  title: string = '';

  constructor(
    private translateService: TranslateService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    let courseId;
    this.route.url.subscribe((urlPath) => {
      courseId = urlPath[urlPath.length - 1].toString();
    });
    if (courseId) {
      this.title = this.translateService.instant('adminCourseCouponForm.editCoupon');
    } else {
      this.title = this.translateService.instant('adminCourseCouponForm.createCoupon');
    }
    this.loading = false;
  }
}
