import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormControl,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { Observable, firstValueFrom, from } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';

import { ConfirmDialogComponent, SharedModule } from 'src/app/shared';
import { AuthenticationService, BrandsService, UsersService } from 'src/app/core/services';
import { HubsStore, UsersStore } from 'src/app/core/stores';
import { IBrand, IUser } from 'src/app/core/models';
import { ToastComponent } from 'src/app/standalone/shared';

@Component({
  selector: 'app-hub-privacy',
  standalone: true,
  imports: [CommonModule, SharedModule, ToastComponent],
  templateUrl: './hub-privacy.component.html',
  styleUrls: ['./hub-privacy.component.scss'],
})
export class HubPrivacyComponent {
  userEmail = new FormControl(
    '',
    [Validators.required, Validators.email],
    this.userExistsValidator(),
  );
  isUpdating = false;

  constructor(
    private usersService: UsersService,
    private brandsService: BrandsService,
    private authenticationService: AuthenticationService,
    private messageService: MessageService,
    private usersStore: UsersStore,
    private translateService: TranslateService,
    private dialogService: DialogService,
    private hubsStore: HubsStore,
  ) {}

  get isBtnDisabled(): boolean {
    return this.userEmail.invalid || this.isUpdating;
  }

  get errorMessage(): string {
    if (this.userEmail.hasError('notExist')) {
      return "This user doesn't exist";
    } else if (this.userEmail.hasError('email')) {
      return 'Email is not valid';
    }

    return 'Email is required';
  }

  async onDeleteUserData(): Promise<void> {
    try {
      const deleteDialogRef = this.dialogService.open(ConfirmDialogComponent, {
        closable: false,
        styleClass: 'confirm-dialog',
        data: {
          titleKey: 'hubPrivacy.dialogTitle',
          descriptionKey: 'hubPrivacy.dialogText',
          confirmBtnKey: 'hubPrivacy.dialogConfirmBtn',
          cancelBtnKey: 'hubPrivacy.dialogCancelBtn',
        },
      });
      const result: 'cancel' | 'confirm' = await firstValueFrom(deleteDialogRef.onClose);

      if (result !== 'confirm') {
        return;
      }

      const email: string = this.userEmail.value;
      this.userEmail.disable();
      if (!email) {
        return;
      }
      this.isUpdating = true;
      const { user } = await this.authenticationService.getAccountByEmail(
        email,
        this.hubsStore.hub.tenantId,
      );
      if (!user) {
        this.messageService.add({
          severity: 'error',
          summary: 'error',
          detail: 'User not found',
          styleClass: 'custom-toast',
        });

        return;
      }
      const currentUser: IUser = { ...this.usersStore.user };

      const brands: IBrand[] = await this.brandsService.getBrandsByUserId(user.id);
      if (brands.length) {
        const brandNames: string = brands.reduce((acc, curr, index) => {
          const separator = index === 0 ? ' ' : ', ';
          return `${acc} ${separator} "${curr.name}"`;
        }, '');
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('hubPrivacy.notAllowedNotificationTitle'),
          detail: this.translateService.instant('hubPrivacy.notAllowedNotificationText', {
            brandNames,
          }),
          styleClass: 'custom-toast',
        });

        return;
      }

      await this.usersService.deleteUserAndUserInformation(user.id, email);

      if (user.id === currentUser.id) {
        await this.authenticationService.signOut();
      }

      this.messageService.add({
        severity: 'success',
        summary: 'success',
        detail: 'User successfully removed',
        styleClass: 'custom-toast',
      });
    } catch (error) {
      console.warn(error);

      this.messageService.add({
        severity: 'error',
        summary: 'error',
        detail:
          error.error.data === 'not-allowed'
            ? "Current user can't delete any user"
            : "User couldn't be removed. Please contact support.",
        styleClass: 'custom-toast',
      });
    } finally {
      this.isUpdating = false;
      this.userEmail.reset();
      this.userEmail.enable();
    }
  }

  private userExistsValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> =>
      from(this.usersService.getUserByEmail(control.value)).pipe(
        debounceTime(500),
        distinctUntilChanged(),
        map((userExists) => (userExists ? null : { notExist: true })),
      );
  }
}
