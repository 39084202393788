import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';

import {
  Firestore,
  IDocumentData,
  IFirestore,
  IQueryDocumentSnapshot,
  IQuerySnapshot,
} from 'src/app/firebase';
import { environment } from 'src/environments/environment';
import { AuthorizationService } from 'src/app/core/services';
import { parseToMoment, API_ROUTES as apiRoutes, CollectionTypes } from 'src/app/shared';
import { EmailModuleType, IEmail, IHub } from 'src/app/core/models';
import { CoursesStore, EventsStore, HubsStore } from 'src/app/core/stores';
import { EmailCourseActionTypes } from '../../utils';

@Injectable({
  providedIn: 'root',
})
export class EmailsService {
  private firestore: IFirestore;

  constructor(
    private http: HttpClient,
    private authorizationService: AuthorizationService,
    private eventsStore: EventsStore,
    private coursesStore: CoursesStore,
    private hubsStore: HubsStore,
  ) {
    this.firestore = Firestore();
  }

  get hub(): IHub {
    return this.hubsStore.hub;
  }

  async create(email: IEmail): Promise<IEmail> {
    try {
      const preEmailReqDoc = this.firestore.collection(apiRoutes.mailTemplates).doc();
      email.id = preEmailReqDoc.id;
      await this.firestore
        .collection(apiRoutes.mailTemplates)
        .doc(email.id)
        .set({ ...email });

      return email as any as IEmail;
    } catch (error) {
      console.log(error);
    }
  }

  async update(id: string, email: IEmail): Promise<IEmail> {
    try {
      const emailRef = this.firestore.collection(apiRoutes.mailTemplates).doc(id);
      await emailRef.update(email);

      return email as any as IEmail;
    } catch (error) {
      console.log(error);
    }
  }

  async getAll(): Promise<IEmail[]> {
    try {
      const emails = await this.firestore
        .collection(apiRoutes.mailTemplates)
        .where('isDeleted', '==', false)
        .orderBy('_title_')
        .get();

      return emails.docs.map((doc) => {
        const email = doc.data();
        email.timeStamp = email.createdAt ? parseToMoment(email.createdAt).format('ll') : '-';
        email.updateTimeStamp = email.updatedAt ? parseToMoment(email.updatedAt).format('ll') : '-';

        return email as any as IEmail;
      });
    } catch (error) {
      console.log(error);
    }
  }

  async getAllForEvent(): Promise<IEmail[]> {
    try {
      const emails = await this.firestore
        .collection(apiRoutes.mailTemplates)
        .where('isDeleted', '==', false)
        .where('module', '==', EmailModuleType.EVENTS)
        .orderBy('_title_')
        .get();

      return emails.docs.map((doc) => {
        const email = doc.data();
        email.timeStamp = email.createdAt ? parseToMoment(email.createdAt).format('ll') : '-';
        email.updateTimeStamp = email.updatedAt ? parseToMoment(email.updatedAt).format('ll') : '-';

        return email as any as IEmail;
      });
    } catch (error) {
      console.log(error);
    }
  }

  async getAllForCourse(): Promise<IEmail[]> {
    try {
      const emails = await this.firestore
        .collection(apiRoutes.mailTemplates)
        .where('isDeleted', '==', false)
        .where('module', '==', EmailModuleType.COURSES)
        .orderBy('_title_')
        .get();

      return emails.docs.map((doc) => {
        const email = doc.data();
        email.timeStamp = email.createdAt ? parseToMoment(email.createdAt).format('ll') : '-';
        email.updateTimeStamp = email.updatedAt ? parseToMoment(email.updatedAt).format('ll') : '-';

        return email as any as IEmail;
      });
    } catch (error) {
      console.log(error);
    }
  }

  async get(id: string): Promise<IEmail> {
    try {
      const email = await this.firestore.collection(apiRoutes.mailTemplates).doc(id).get();

      return email.data() as any as IEmail;
    } catch (error) {
      console.log(error);
    }
  }

  async getDefaultEmails(module = EmailModuleType.EVENTS): Promise<IEmail[]> {
    try {
      const emails: IEmail[] = [];
      const emailsQuery: IQuerySnapshot<IDocumentData> = await this.firestore
        .collection(apiRoutes.mailTemplates)
        .where('isDeleted', '==', false)
        .where('default', '==', true)
        .where('module', '==', module)
        .orderBy('_title_')
        .get();

      emailsQuery.docs.forEach((doc: IQueryDocumentSnapshot<IDocumentData>) => {
        emails.push(doc.data() as IEmail);
      });

      return emails;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async updateEmailTemplate(id: string, email: IEmail): Promise<void> {
    try {
      const emailRef = this.firestore.collection(apiRoutes.mailTemplates).doc(id);
      await emailRef.update(email);
    } catch (error) {
      console.log(error);
    }
  }

  async softRemove(id: string): Promise<boolean> {
    try {
      const emailRef = this.firestore.collection(apiRoutes.mailTemplates).doc(id);
      await emailRef.update({
        isDeleted: true,
      });

      return true;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async sendTestEmail(email: string, emailId: string): Promise<boolean> {
    try {
      let collectionName: CollectionTypes = CollectionTypes.MAIL_TEMPLATES;

      if (this.eventsStore.adminEvent) {
        collectionName = CollectionTypes.EVENTS_MAIL_TEMPLATES;
      }

      if (this.coursesStore.adminCourse) {
        collectionName = CollectionTypes.COURSES_MAIL_TEMPLATES;
      }
      const headers = await this.authorizationService.buildHeaders();

      return this.http
        .post<boolean>(
          `${environment.apiUrl}emails/test`,
          { email, emailId, collectionName, hubId: this.hub.id },
          { headers },
        )
        .toPromise();
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async sendEventRegisterEmail(eventId, userId, type): Promise<boolean> {
    try {
      const headers = await this.authorizationService.buildHeaders();

      return this.http
        .post<boolean>(
          `${environment.apiUrl}events/${eventId}/register/${type}`,
          {
            userId,
          },
          { headers },
        )
        .toPromise();
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async sendCourseEmail(
    courseId: string,
    userId: string,
    emailActionType: EmailCourseActionTypes,
  ): Promise<boolean> {
    const headers = await this.authorizationService.buildHeaders();

    return firstValueFrom(
      this.http.post<boolean>(
        `${environment.apiUrl}emails/course-email`,
        { courseId, userId, emailActionType },
        { headers },
      ),
    );
  }
  // EVENT EMAILS:

  async attachEmailToEvent(eventId: string, email: IEmail): Promise<IEmail> {
    try {
      const preEmailReqDoc = this.firestore.collection(apiRoutes.eventsMailTemplates).doc();
      email.id = preEmailReqDoc.id;
      email.eventId = eventId;
      email.default = true;

      await this.firestore
        .collection(apiRoutes.eventsMailTemplates)
        .doc(email.id)
        .set({ ...email });

      return email as any as IEmail;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async getEventEmails(eventId: string): Promise<IEmail[]> {
    try {
      const emails = await this.firestore
        .collection(apiRoutes.eventsMailTemplates)
        .where('eventId', '==', eventId)
        .orderBy('_title_')
        .get();

      return emails.docs.map((doc) => {
        const email = doc.data();
        email.timeStamp = email.createdAt ? parseToMoment(email.createdAt).format('ll') : '-';
        email.updateTimeStamp = email.updatedAt ? parseToMoment(email.updatedAt).format('ll') : '-';

        return email as any as IEmail;
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async attachEmailToCourse(courseId: string, email: IEmail): Promise<IEmail> {
    try {
      const preEmailReqDoc = this.firestore.collection(apiRoutes.coursesMailTemplates).doc();
      email.id = preEmailReqDoc.id;
      email.courseId = courseId;
      email.default = true;
      email.actionTitle = email.actionTitle ? email.actionTitle : null;

      await this.firestore
        .collection(apiRoutes.coursesMailTemplates)
        .doc(email.id)
        .set({ ...email });

      return email as any as IEmail;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async getCourseEmails(courseId: string): Promise<IEmail[]> {
    try {
      const emails = await this.firestore
        .collection(apiRoutes.coursesMailTemplates)
        .where('courseId', '==', courseId)
        .orderBy('_title_')
        .get();

      return emails.docs.map((doc) => {
        const email = doc.data();
        email.timeStamp = email.createdAt ? parseToMoment(email.createdAt).format('ll') : '-';
        email.updateTimeStamp = email.updatedAt ? parseToMoment(email.updatedAt).format('ll') : '-';

        return email as any as IEmail;
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async getEmailsForSpecificAudience(
    entityId: string,
    audience: 'attendees' | 'brandpeople' | 'speakers' | 'instructor' | 'subscriber',
    collectionName: CollectionTypes.EVENTS_MAIL_TEMPLATES | CollectionTypes.COURSES_MAIL_TEMPLATES,
  ): Promise<IEmail[]> {
    try {
      const emailsCollection =
        collectionName === CollectionTypes.EVENTS_MAIL_TEMPLATES
          ? apiRoutes.eventsMailTemplates
          : apiRoutes.coursesMailTemplates;

      const propertyName: string =
        collectionName === CollectionTypes.EVENTS_MAIL_TEMPLATES ? 'eventId' : 'courseId';

      const emails = await this.firestore
        .collection(emailsCollection)
        .where('isDeleted', '==', false)
        .where(propertyName, '==', entityId)
        .where('audience', '==', audience)
        .orderBy('_title_')
        .get();

      return emails.docs.map((doc) => {
        const email = doc.data();
        email.timeStamp = email.createdAt ? parseToMoment(email.createdAt).format('ll') : '-';
        email.updateTimeStamp = email.updatedAt ? parseToMoment(email.updatedAt).format('ll') : '-';

        return email as any as IEmail;
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async unAttach(emailsCollection: 'events' | 'courses', docId: string): Promise<boolean> {
    try {
      const collection: CollectionTypes =
        emailsCollection === 'events'
          ? apiRoutes.eventsMailTemplates
          : apiRoutes.coursesMailTemplates;
      await this.firestore.collection(collection).doc(docId).delete();

      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async getEventEmail(eventId: string, emailId: string): Promise<IEmail> {
    try {
      const email = await this.firestore
        .collection(apiRoutes.eventsMailTemplates)
        .doc(emailId)
        .get();

      return email.data() as any as IEmail;
    } catch (error) {
      console.log(error);
    }
  }

  async getCourseEmail(emailId: string): Promise<IEmail> {
    try {
      const email = await this.firestore
        .collection(apiRoutes.coursesMailTemplates)
        .doc(emailId)
        .get();

      return email.data() as any as IEmail;
    } catch (error) {
      console.log(error);
    }
  }

  async updateEventEmail(id: string, email: IEmail): Promise<IEmail> {
    try {
      const emailRef = this.firestore.collection(apiRoutes.eventsMailTemplates).doc(id);
      await emailRef.update(email);

      return email as any as IEmail;
    } catch (error) {
      console.log(error);
    }
  }

  async updateCourseEmail(id: string, email: IEmail): Promise<IEmail> {
    try {
      const emailRef = this.firestore.collection(apiRoutes.coursesMailTemplates).doc(id);
      await emailRef.update(email);

      return email as any as IEmail;
    } catch (error) {
      console.log(error);
    }
  }

  async sendInviteWithEmailTemplate(
    id: string,
    userId: string,
    emailId: string,
    entityName: 'event' | 'course',
  ): Promise<void> {
    const headers: HttpHeaders = await this.authorizationService.buildHeaders();
    const urlFirstPart = `${apiRoutes.emails}/${id}/inviteWithEmailTemplate`;
    const urlSecondPart = `?userId=${userId}&emailId=${emailId}&tenantId=${this.hub?.tenantId}&entityName=${entityName}`;

    return await firstValueFrom(this.http.get<any>(`${urlFirstPart}${urlSecondPart}`, { headers }));
  }

  async isEmailSenderApiKeyValid(apiKey: string): Promise<any> {
    const headers: HttpHeaders = await this.authorizationService.buildHeaders();

    return await firstValueFrom(
      this.http.get<any>(`${apiRoutes.emails}/${apiKey}/isApiKeyValid`, { headers }),
    );
  }
}
