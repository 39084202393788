import { Component, OnInit } from '@angular/core';

import {
  appointmentMergeTags,
  asyncDelay,
  brandMergeTags,
  courseMergeTags,
  systemEmailEventMergeTags,
  hubMergeTags,
  systemEmailUserMergeTags,
} from 'src/app/core/utils';

@Component({
  selector: 'app-system-email-merge-tags',
  templateUrl: './system-email-merge-tags.component.html',
  styleUrls: ['./system-email-merge-tags.component.scss'],
})
export class SystemEmailMergeTagsComponent implements OnInit {
  public loading = true;
  public userKeys: string[] = [];
  public brandKeys: string[] = [];
  public eventKeys: string[] = [];
  public courseKeys: string[] = [];
  public hubKeys: string[] = [];
  public appointmentKeys: string[] = [];

  async ngOnInit(): Promise<void> {
    this.userKeys = systemEmailUserMergeTags;
    this.brandKeys = brandMergeTags;
    this.eventKeys = systemEmailEventMergeTags;
    this.courseKeys = courseMergeTags;
    this.hubKeys = hubMergeTags;
    this.appointmentKeys = appointmentMergeTags;

    await asyncDelay(200);
    this.loading = false;
  }
}
