import * as CryptoJS from 'crypto-js';
import { Injectable } from '@angular/core';

import { AppStore } from 'src/app/app.store';

@Injectable({
  providedIn: 'root',
})
export class CryptoJSService {
  constructor(private appStore: AppStore) {}

  public encrypt(value: any): string {
    if (value == null || typeof value === 'undefined') {
      return value;
    }

    const encrypted = CryptoJS.AES.encrypt(
      value,
      this.appStore.environment.encryptionSecret,
    ).toString();
    const hmac = CryptoJS.HmacSHA256(
      encrypted,
      CryptoJS.SHA256(this.appStore.environment.encryptionSecret),
    ).toString();
    return hmac + encrypted;
  }

  public decrypt(encryptedKey: string): string {
    if (encryptedKey == null || typeof encryptedKey === 'undefined') {
      return encryptedKey;
    }

    const transitEncrypted = encryptedKey.substring(64);
    return CryptoJS.AES.decrypt(
      transitEncrypted,
      this.appStore.environment.encryptionSecret,
    ).toString(CryptoJS.enc.Utf8);
  }

  public decryptWithCustomSecret(encryptedKey: string, secret: string): string {
    if (encryptedKey == null || typeof encryptedKey === 'undefined') {
      return encryptedKey;
    }

    const transitEncrypted = encryptedKey.substring(64);
    return CryptoJS.AES.decrypt(transitEncrypted, secret).toString(CryptoJS.enc.Utf8);
  }

  public generateCustomSecret(): string {
    return CryptoJS.lib.WordArray.random(512 / 32).toString();
  }
}
