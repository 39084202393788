import { Component } from '@angular/core';

@Component({
  selector: 'app-event-itself-report',
  standalone: true,
  imports: [],
  templateUrl: './event-itself-report.component.html',
  styleUrl: './event-itself-report.component.scss',
})
export class EventItselfReportComponent {}
