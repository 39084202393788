import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-course-registration',
  templateUrl: './course-registration.component.html',
  styleUrls: ['./course-registration.component.scss'],
})
export class CourseRegistrationComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
