<div class="appointment-container">
  <header class="header">
    <h1>{{ 'appointmentDialog.scheduleMeeting' | translate }}</h1>
    <button
      pButton
      [icon]="isUpdating ? 'pi pi-spin pi-spinner' : null"
      label="{{ 'appointmentDialog.sendRequest' | translate }}"
      [disabled]="!canUpdate"
      (click)="onSendRequest()"></button>
  </header>
  <form
    [formGroup]="form"
    class="form">
    <div class="left">
      <div class="left-column">
        <h2>{{ 'appointmentDialog.name' | translate }}</h2>

        <!-- Meeting name input -->
        <div>
          <app-input-label
            labelType="required"
            for="meeting-name-input"
            text="appointmentDialog.meetingNameLabel">
          </app-input-label>
          <input
            class="custom-input-text custom-input-text-required"
            formControlName="meetingName"
            id="meeting-name-input"
            pInputText
            type="text"
            placeholder="{{ 'appointmentDialog.meetingNameLabel' | translate }}" />
          <app-input-error-message
            [showMessage]="form.controls.meetingName.invalid && form.controls.meetingName.touched"
            errorMessage="{{ 'application.forms.required' | translate }}">
          </app-input-error-message>
        </div>

        <!-- Description input -->
        <div class="c-mb-4">
          <app-input-label text="appointmentDialog.descriptionLabel"></app-input-label>
          <textarea
            class="custom-input-textarea resize-none"
            pInputTextarea
            [formControl]="form.controls.description"
            placeholder="{{ 'appointmentDialog.descriptionLabel' | translate }}">
          </textarea>
        </div>

        <ng-container *ngIf="currentEvent.onlineVideoMeetingStatus">
          <div class="c-mb-4">
            <app-ticket-button
              ticketName="{{ 'appointmentDialog.onlineVideoMeetingLabel' | translate }}"
              [isSelected]="isSelectedOnlineVideoMeeting"
              (clicked)="onVideoMeetingToggle()">
            </app-ticket-button>
          </div>

          <div class="c-mb-4">
            <app-ticket-button
              ticketName="{{ 'appointmentDialog.externalMeetingLinkLabel' | translate }}"
              [isSelected]="isSelectedExternalMeeting"
              (clicked)="onExternalMeetingToggle()">
            </app-ticket-button>
          </div>
        </ng-container>

        <ng-container *ngIf="isSelectedExternalMeeting">
          <!-- Location input -->
          <div>
            <app-input-label
              labelType="required"
              for="location-input"
              text="appointmentDialog.locationLabel">
            </app-input-label>
            <input
              class="custom-input-text custom-input-text-required"
              formControlName="location"
              id="location-input"
              pInputText
              type="text"
              placeholder="{{ 'appointmentDialog.locationLabel' | translate }}" />
            <p class="input-note">{{ 'appointmentDialog.warnLocationText' | translate }}</p>
          </div>
        </ng-container>
      </div>

      <div class="right-column">
        <h2>{{ 'appointmentDialog.dateTime' | translate }}</h2>

        <div class="start-container">
          <!-- Start date input -->
          <div>
            <app-input-label
              labelType="required"
              for="start-date-input"
              text="appointmentDialog.startDateLabel">
            </app-input-label>
            <p-calendar
              class="custom-calendar custom-calendar-required"
              formControlName="startDate"
              [monthNavigator]="true"
              [yearNavigator]="true"
              [minDate]="minDate"
              [showIcon]="true"
              dateFormat="dd/mm/yy"
              yearRange="1900:2050"
              inputId="start-date-input"
              icon="far fa-calendar-day"
              placeholder="{{ 'appointmentDialog.startDateLabel' | translate }}">
            </p-calendar>
            <app-input-error-message
              [showMessage]="form.controls.startDate.invalid && form.controls.startDate.touched"
              errorMessage="{{ 'application.forms.required' | translate }}">
            </app-input-error-message>
          </div>

          <!-- Start time input -->
          <div>
            <app-input-label
              labelType="required"
              for="start-time-input"
              text="appointmentDialog.timeLabel">
            </app-input-label>
            <p-calendar
              class="custom-calendar custom-calendar-required custom-calendar-time"
              formControlName="startTime"
              inputId="start-time-input"
              [timeOnly]="true"
              [showIcon]="true"
              icon="far fa-clock"
              placeholder="{{ 'appointmentDialog.timeLabel' | translate }}"
              appDateMask>
            </p-calendar>
            <app-input-error-message
              [showMessage]="form.controls.startTime.invalid && form.controls.startTime.touched"
              errorMessage="{{ 'application.forms.required' | translate }}">
            </app-input-error-message>
          </div>
        </div>

        <div class="end-container">
          <!-- End date input -->
          <div>
            <app-input-label
              labelType="required"
              for="end-date-input"
              text="appointmentDialog.endDateLabel">
            </app-input-label>
            <p-calendar
              class="custom-calendar custom-calendar-required"
              formControlName="endDate"
              [monthNavigator]="true"
              [yearNavigator]="true"
              [minDate]="minDate"
              [showIcon]="true"
              dateFormat="dd/mm/yy"
              yearRange="1900:2050"
              inputId="end-date-input"
              icon="far fa-calendar-day"
              placeholder="{{ 'appointmentDialog.endDateLabel' | translate }}">
            </p-calendar>
            <app-input-error-message
              [showMessage]="form.controls.endDate.invalid && form.controls.endDate.touched"
              errorMessage="{{ 'application.forms.required' | translate }}">
            </app-input-error-message>
          </div>

          <!-- End time input -->
          <div>
            <app-input-label
              labelType="required"
              for="end-time-input"
              text="appointmentDialog.timeLabel">
            </app-input-label>
            <p-calendar
              class="custom-calendar custom-calendar-required custom-calendar-time"
              formControlName="endTime"
              inputId="end-time-input"
              [timeOnly]="true"
              [showIcon]="true"
              icon="far fa-clock"
              placeholder="{{ 'appointmentDialog.timeLabel' | translate }}"
              appDateMask>
            </p-calendar>
            <app-input-error-message
              [showMessage]="form.controls.endTime.invalid && form.controls.endTime.touched"
              errorMessage="{{ 'application.forms.required' | translate }}">
            </app-input-error-message>
          </div>
        </div>
        <div
          class="input-note note"
          [innerHTML]="textForNote"></div>
      </div>
    </div>

    <div class="right">
      <div class="top">
        <h2>{{ 'appointmentDialog.guests' | translate }}</h2>
        <div class="users-container">
          <i
            *ngIf="usersLoading"
            class="fal fa-spinner-third fa-spin"></i>

          <!-- Users multi select -->
          <div>
            <app-input-label
              for="users-input"
              text="appointmentDialog.addGuests">
            </app-input-label>
            <p-multiSelect
              class="custom-multi-select"
              [options]="users"
              [selectionLimit]="selectionLimit"
              [virtualScroll]="true"
              optionLabel="lastName"
              formControlName="users"
              inputId="users-input"
              itemSize="30"
              placeholder="{{ 'appointmentDialog.addGuests' | translate }}">
              <ng-template
                let-user
                pTemplate="item">
                <div class="event-item">{{ user.firstName }} {{ user.lastName }}</div>
              </ng-template>
            </p-multiSelect>
            <p class="input-note">
              {{ 'appointmentDialog.warnText1' | translate }}
              <span>{{ 'appointmentDialog.warnText2' | translate }}</span>
            </p>
          </div>
        </div>
      </div>

      <p-divider styleClass="custom-divider-horizontal"></p-divider>

      <div class="bottom">
        <p class="bottom-title">
          {{ 'appointmentDialog.addedGuests' | translate: { counter: counterOfUsers } }}
        </p>
        <ul class="list-users">
          <li class="host-user">
            <image
              [src]="currentUser.profileImage"
              [text]="imageText(currentUser)"
              size="super-small">
            </image>
            <p>
              {{ currentUser.firstName }} {{ currentUser.lastName }}
              <span>{{ 'appointmentDialog.host' | translate }}</span>
            </p>
          </li>
          <ng-container *ngIf="form.controls.users?.value">
            <li *ngFor="let user of form.controls.users?.value">
              <image
                [src]="user?.profileImage"
                [text]="imageText(user)"
                size="super-small">
              </image>
              <p>{{ user.firstName }} {{ user.lastName }}</p>
              <i
                class="fal fa-times"
                (click)="removeUser(user)"></i>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </form>
</div>

<p-toast>
  <ng-template
    let-message
    pTemplate="message">
    <app-toast
      [severity]="message.severity"
      [summary]="message.summary"
      [detail]="message.detail">
    </app-toast>
  </ng-template>
</p-toast>
