import { Auth, IAuth } from './../../../firebase';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { HubsStore } from 'src/app/core/stores';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  private firebaseAuth: IAuth;

  constructor(private hubsStore: HubsStore) {
    this.firebaseAuth = Auth();
  }

  public async buildHeaders() {
    const token = await this.firebaseAuth.currentUser.getIdToken();
    let httpHeaders;

    if (this.hubsStore.hub && this.hubsStore.hub.tenantId) {
      httpHeaders = new HttpHeaders({
        Authorization: 'Bearer ' + token,
        'Access-Control-Allow-Origin': '*',
        tenantId: this.hubsStore.hub.tenantId,
      });
    } else {
      httpHeaders = new HttpHeaders({
        Authorization: 'Bearer ' + token,
        'Access-Control-Allow-Origin': '*',
      });
    }

    return httpHeaders;
  }

  public buildHeaders_(): Observable<any> {
    return from(this.firebaseAuth.currentUser.getIdToken()).pipe(
      map((token) => new HttpHeaders({ Authorization: 'Bearer ' + token })),
    );
  }
}
