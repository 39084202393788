export enum AreaOfActivity {
  DISTRIBUTION_SALES = 'userAreaOfActivity.distributionSales',
  MARKETING_PR = 'userAreaOfActivity.marketingPr',
  PRODUCT = 'userAreaOfActivity.product',
  IT_INFRASTRUCTURE_DATA = 'userAreaOfActivity.itInfrastructureData',
  BUSINESS_DEVELOPMENT = 'userAreaOfActivity.businessDevelopment',
  PURCHASING_PROCUREMENT = 'userAreaOfActivity.purchasingProcurement',
  GRAPHICS = 'userAreaOfActivity.graphics',
  OTHER = 'userAreaOfActivity.other',
  FINANCE_INVESTMENT = 'userAreaOfActivity.financeInvestment',
  HR = 'userAreaOfActivity.hr',
  LOGISTICS = 'userAreaOfActivity.logistics',
  PRODUCTION = 'userAreaOfActivity.production',
  ACCOUNTING_CONTROLLING = 'userAreaOfActivity.accountingControlling',
  RESEARCH_DEVELOPMENT = 'userAreaOfActivity.researchDevelopment',
  CUSTOMER_SERVICE = 'userAreaOfActivity.customerService',
}
