import { Theme } from './theme.model';

export const lightTheme: Theme = {
  name: 'light',
  properties: {
    '--appFont': 'Inter, sans-serif',
    '--appPrimaryColor': '#0148FF',
    '--app-primary': '#0148FF',
    '--app-primary-hover': '#157FCC',
    '--app-secondary': '#424242',
    '--app-secondary-hover': '#757575',
    '--background': '#F5F5F5',
    '--background-sidebar': '#131313',
    '--layer-01': '#FFFFFF',
    '--layer-02': '#FAFAFA',
    '--layer-03': '#FFFFFF',
    '--layer-hover-01': '#EEEEEE',
    '--layer-hover-02': '#FFFFFF',
    '--layer-hover-03': '#F5F5F5',
    '--border-strong-01': '#EEEEEE',
    '--border-strong-02': '#E0E0E0',
    '--border-strong-03': '#BDBDBD',
    '--text-primary': '#0E0E0E',
    '--text-secondary': '#616161',
    '--icon-primary': '#0E0E0E',
    '--icon-secondary': '#616161',
    '--icon-disabled': '#BDBDBD',
    '--field-01': '#FFFFFF',
    '--field-02': '#F5F5F5',
  },
};
