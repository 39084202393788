export enum BrandLevelsTypes {
  PLATINUM = 'platinum',
  GOLD = 'gold',
  SILVER = 'silver',
  MEDIA = 'media',
  DEFAULT = 'default',
  NONE = 'none',
}

export const brandLevels: Array<{ key: string; value: string }> = [
  { key: BrandLevelsTypes.DEFAULT, value: 'brandLevels.default' },
  { key: BrandLevelsTypes.PLATINUM, value: 'brandLevels.platinum' },
  { key: BrandLevelsTypes.GOLD, value: 'brandLevels.gold' },
  { key: BrandLevelsTypes.SILVER, value: 'brandLevels.silver' },
  { key: BrandLevelsTypes.MEDIA, value: 'brandLevels.mediaPartner' },
  { key: BrandLevelsTypes.NONE, value: 'brandLevels.none' },
];
