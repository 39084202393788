<ng-container *ngIf="loading">
  <app-loader class="admin-user-loading-container"></app-loader>
</ng-container>

<div
  *ngIf="!loading"
  class="new-user-container">
  <div class="header c-p-5">
    <div class="title">
      {{ createUserType | translate }}
    </div>
    <i
      class="fa-regular fa-xmark close-icon"
      (click)="onCloseDialog()"></i>
  </div>

  <form [formGroup]="form">
    <div class="form-container c-p-5">
      <ng-container *ngIf="isShowLastFirstNameInputs">
        <!-- First name input -->
        <div class="form-field-block">
          <app-input-label
            labelType="required"
            for="first-name-input"
            text="adminUserProfile.firstNameLabel">
          </app-input-label>
          <input
            class="custom-input-text custom-input-text-required"
            formControlName="firstName"
            id="first-name-input"
            pInputText
            type="text"
            maxlength="{{ getMaxLengthValue('firstName') }}"
            placeholder="{{ 'adminUserProfile.firstNamePlaceholder' | translate }}" />
          <app-input-error-message
            [showMessage]="form.controls?.firstName?.invalid && form.controls?.firstName?.touched"
            errorMessage="{{ 'adminUserProfile.firstNameReq' | translate }}">
          </app-input-error-message>
        </div>

        <!-- Last name input -->
        <div class="form-field-block">
          <app-input-label
            labelType="required"
            for="last-name-input"
            text="adminUserProfile.lastNameLabel">
          </app-input-label>
          <input
            class="custom-input-text custom-input-text-required"
            formControlName="lastName"
            id="last-name-input"
            pInputText
            type="text"
            maxlength="{{ getMaxLengthValue('lastName') }}"
            placeholder="{{ 'adminUserProfile.lastNamePlaceholder' | translate }}" />
          <app-input-error-message
            [showMessage]="form.controls.lastName.invalid && form.controls.lastName.touched"
            errorMessage="{{ 'adminUserProfile.lastNameReq' | translate }}">
          </app-input-error-message>
        </div>
      </ng-container>

      <!-- Email input -->
      <div class="form-field-block">
        <app-input-label
          labelType="required"
          for="email-input"
          text="adminUserAccount.emailLabel">
        </app-input-label>
        <input
          class="custom-input-text custom-input-text-required"
          formControlName="email"
          id="email-input"
          pInputText
          type="email"
          placeholder="{{ 'adminUserProfile.emailPlaceholder' | translate }}" />
        <app-input-error-message
          [showMessage]="form.controls.email.invalid && form.controls.email.touched"
          [errorMessage]="
            form.controls.email.hasError('email')
              ? ('adminUserAccount.emailNotValid' | translate)
              : ('adminUserAccount.emailReq' | translate)
          ">
        </app-input-error-message>
      </div>

      @if (userType === 'attendee' || userType === 'subscriber') {
        <!-- Ticket input -->
        <div class="form-field-block c-mb-5">
          <app-input-label
            for="ticket-input"
            text="adminUserProfile.ticket">
          </app-input-label>
          <p-dropdown
            class="custom-dropdown"
            panelStyleClass="custom-dropdown-panel"
            appendTo="body"
            [options]="tickets"
            formControlName="ticket"
            optionLabel="name"
            inputId="ticket-input"
            [placeholder]="
              userType === 'subscriber'
                ? ('adminUserProfile.ticketPlaceholder' | translate)
                : ('adminNewUser.ticketPlaceholderForAttendee' | translate)
            ">
          </p-dropdown>
        </div>
      }

      <!-- Send invite input -->
      <div
        *ngIf="userType !== 'user' && userType !== 'hubAdmin' && userType !== 'userHub'"
        class="col text-left form-field-input-checkbox-block c-p-0 c-mt-1">
        <p-checkbox
          class="custom-input-checkbox-sm"
          binary="true"
          formControlName="sendInvite"
          inputId="send-invite-input">
        </p-checkbox>
        <app-input-label
          labelType="switch-checkbox"
          for="send-invite-input"
          text="adminUserProfile.sendInvite">
        </app-input-label>
      </div>
    </div>
  </form>

  <div class="footer c-p-2 c-pl-5">
    <div class="col text-left form-field-input-checkbox-block c-p-0">
      <p-checkbox
        class="custom-input-checkbox-sm"
        binary="true"
        [formControl]="createAnotherControl"
        inputId="another-user-input">
      </p-checkbox>
      <app-input-label
        labelType="switch-checkbox"
        for="another-user-input"
        [text]="textForCheckbox">
      </app-input-label>
    </div>
    <div class="col text-right flex justify-content-end align-items-center">
      <app-button
        buttonText="{{ 'adminUserAccount.cancelButton' | translate }}"
        buttonIconLeft="fa-regular fa-xmark"
        [buttonStyle]="buttonStyle.GHOST_PRIMARY"
        [buttonSize]="buttonSize.MEDIUM"
        (click)="onCloseDialog()" />
      <app-button
        class="c-ml-2"
        buttonText="{{ 'shared.save' | translate }}"
        buttonIconLeft="far fa-check"
        [buttonSize]="buttonSize.MEDIUM"
        [loading]="isUpdating"
        [disabled]="!canUpdate"
        (click)="create()" />
    </div>
  </div>
</div>
