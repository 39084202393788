<div class="container">
  <div class="title">
    <span class="stat-name">{{ statName | translate }}</span>
    <i
      class="fa-regular fa-circle-info info"
      pTooltip="{{ tooltipText | translate }}"
      [tooltipPosition]="tooltipPosition"
      [tooltipStyleClass]="tooltipStyleClass"
      [tooltipEvent]="tooltipEvent"
      [tabIndex]="tabIndex">
    </i>
  </div>
  <div class="progress-bar-title">
    <span class="progress-bar-value">{{ progressBarValue }}%</span>
    <span class="progress-bar-value-text">{{ progressBarText | translate }}</span>
  </div>
  <app-custom-progress-bar
    [height]="progressBarHeight"
    [className]="progressBarClassName"
    [borderRadius]="progressBarBorderRadius"
    [value]="progressBarValue">
  </app-custom-progress-bar>
  <ul class="list">
    <li
      *ngFor="let option of listOptions; trackBy: trackByFn"
      class="list-item">
      <span class="list-item-text">{{ option.text }}</span>
      <span class="list-item-value">{{ option.value }}</span>
    </li>
  </ul>
</div>
