<ng-container *ngIf="loading">
  <app-loader></app-loader>
</ng-container>

<div *ngIf="!loading">
  <form
    class="c-pl-8"
    [formGroup]="form">
    <!-- Ticket block -->
    <div class="form-block c-mt-8 c-mb-9">
      <div class="left-side">
        <h2 class="title">{{ 'adminUserEventDetails.ticketTitle' | translate }}</h2>
        <p class="description">{{ 'adminUserEventDetails.ticketTitleDescription' | translate }}</p>
      </div>
      <div class="right-side">
        <!-- Ticket input -->
        <div class="form-field-block c-mb-4">
          <app-input-label
            for="ticket-input"
            text="adminUserEventDetails.ticketTitle">
          </app-input-label>
          <p-dropdown
            class="custom-dropdown"
            panelStyleClass="custom-dropdown-panel"
            [options]="tickets"
            formControlName="ticket"
            optionLabel="name"
            inputId="ticket-input"
            emptyMessage="{{ 'adminUserEventDetails.emptyDefaultMessage' | translate }}"
            placeholder="{{ 'adminUserEventDetails.ticketTitle' | translate }}">
          </p-dropdown>
        </div>
      </div>
    </div>

    <p-divider styleClass="custom-divider-horizontal"></p-divider>

    <!-- Accommodation & Transfer block -->
    <div
      *ngIf="eventRegisterForm.isShowAccommodationTransfer"
      class="form-block c-my-9">
      <div class="left-side">
        <h2 class="title">{{ 'adminUserEventDetails.accommodationTransferTitle' | translate }}</h2>
        <p class="description">
          {{ 'adminUserEventDetails.accommodationTransferDescription' | translate }}
        </p>
      </div>
      <div class="right-side">
        <!-- Hotel input -->
        <div class="form-field-block c-mb-4">
          <app-input-label
            for="hotel-input"
            text="adminUserAccount.hotel">
          </app-input-label>
          <input
            class="custom-input-text"
            formControlName="hotel"
            id="hotel-input"
            pInputText
            type="text"
            placeholder="{{ 'adminUserAccount.hotel' | translate }}" />
        </div>

        <!-- Room type input -->
        <div class="form-field-block c-mb-4">
          <app-input-label
            for="room-type-input"
            text="adminUserEventDetails.roomType">
          </app-input-label>
          <p-dropdown
            class="custom-dropdown"
            panelStyleClass="custom-dropdown-panel"
            [options]="roomTypes"
            formControlName="roomType"
            inputId="room-type-input"
            placeholder="{{ 'adminUserEventDetails.roomType' | translate }}">
            <ng-template
              pTemplate="item"
              let-roomType>
              {{ roomType | translate }}
            </ng-template>
            <ng-template
              pTemplate="selectedItem"
              let-selectedRoomType>
              {{ selectedRoomType | translate }}
            </ng-template>
          </p-dropdown>
        </div>

        <!-- Room partner input -->
        <div
          *ngIf="isShowRoomPartner"
          class="form-field-block c-mb-4">
          <app-input-label
            for="room-partner-input"
            text="adminUserEventDetails.roomPartner">
          </app-input-label>
          <input
            class="custom-input-text"
            formControlName="roomPartner"
            id="room-partner-input"
            pInputText
            type="text"
            placeholder="{{ 'adminUserEventDetails.roomPartner' | translate }}" />
        </div>

        <!-- Preferred airport input -->
        <div class="form-field-block c-mb-4">
          <app-input-label
            for="preferred-airport-input"
            text="adminUserEventDetails.preferredAirport">
          </app-input-label>
          <p-dropdown
            class="custom-dropdown"
            panelStyleClass="custom-dropdown-panel"
            [options]="citiesForPreferredAirport"
            formControlName="preferredAirport"
            inputId="preferred-airport-input"
            placeholder="{{ 'adminUserEventDetails.preferredAirport' | translate }}"
            (onChange)="onPreferredChange($event.value)">
            <ng-template
              pTemplate="item"
              let-preferredAirport>
              {{ preferredAirport | translate }}
            </ng-template>
            <ng-template
              pTemplate="selectedItem"
              let-selectedPreferredAirport>
              {{ selectedPreferredAirport | translate }}
            </ng-template>
          </p-dropdown>
        </div>

        <!-- Alternative airport input -->
        <div class="form-field-block c-mb-4">
          <app-input-label
            for="alternative-airport-input"
            text="adminUserEventDetails.alternativeAirport">
          </app-input-label>
          <p-dropdown
            class="custom-dropdown"
            panelStyleClass="custom-dropdown-panel"
            [options]="citiesForAlternativeAirport"
            formControlName="alternativeAirport"
            inputId="alternative-airport-input"
            placeholder="{{ 'adminUserEventDetails.alternativeAirport' | translate }}"
            (onChange)="onAlternativeChange($event.value)">
            <ng-template
              pTemplate="item"
              let-alternativeAirport>
              {{ alternativeAirport | translate }}
            </ng-template>
            <ng-template
              pTemplate="selectedItem"
              let-selectedAlternativeAirport>
              {{ selectedAlternativeAirport | translate }}
            </ng-template>
          </p-dropdown>
        </div>

        <!-- Clothing size input -->
        <div class="form-field-block c-mb-4">
          <app-input-label
            for="clothing-size-input"
            text="adminUserEventDetails.clothingSize">
          </app-input-label>
          <p-dropdown
            class="custom-dropdown"
            panelStyleClass="custom-dropdown-panel"
            [options]="clothingSizes"
            formControlName="clothingSize"
            inputId="clothing-size-input"
            placeholder="{{ 'adminUserEventDetails.clothingSize' | translate }}">
            <ng-template
              pTemplate="item"
              let-item
              >{{ item | translate }}</ng-template
            >
            <ng-template
              pTemplate="selectedItem"
              let-selectedItem>
              {{ selectedItem | translate }}
            </ng-template>
          </p-dropdown>
        </div>

        <!-- Shoe size input -->
        <div class="form-field-block">
          <app-input-label
            for="shoe-size-input"
            text="adminUserEventDetails.shoeSize">
          </app-input-label>
          <input
            class="custom-input-text"
            formControlName="shoeSize"
            id="shoe-size-input"
            pInputText
            type="text"
            placeholder="{{ 'adminUserEventDetails.shoeSize' | translate }}" />
        </div>
      </div>
    </div>

    <div *ngIf="eventRegisterForm.isShowAccommodationTransfer">
      <p-divider styleClass="custom-divider-horizontal"></p-divider>
    </div>

    <!-- Tags block -->
    <div class="form-block c-mt-9 c-mb-4">
      <div class="left-side">
        <h2 class="title">{{ 'adminUserEventDetails.descTagsTitle' | translate }}</h2>
        <p class="description">
          {{ 'adminUserEventDetails.attendeeDescTagsDescription' | translate }}
        </p>
      </div>
      <div class="right-side">
        <!-- Tags multi select -->
        <div class="form-field-block">
          <app-tags-multi-list
            [possibleTags]="tags"
            [selectedTags]="form.controls.tags.value"
            [adminTheme]="true"
            (onSelected)="selectTags($event)">
          </app-tags-multi-list>
          <h3 *ngIf="!tags?.length">{{ 'adminUserEventDetails.noTagsAvailable' | translate }}</h3>
        </div>
      </div>
    </div>

    <div class="text-right c-mr-8 c-mb-8">
      <button
        class="primary-text-color"
        pButton
        type="button"
        [label]="'adminUserEventDetails.updateBtn' | translate"
        [icon]="isUpdating ? 'pi pi-spin pi-spinner' : null"
        [disabled]="!canUpdate"
        (click)="update()"></button>
    </div>
  </form>
</div>
