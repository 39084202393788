import * as moment from 'moment';
import { Timestamp } from 'src/app/firebase';

/*
  Returns time parsed as HH:mm
 */
export const getTime = (timestamp: Timestamp): string =>
  moment.unix(timestamp.seconds).format('HH:mm');

export const getTimeForEnglishLocale = (timestamp: Timestamp): string =>
  moment.unix(timestamp.seconds).format('hh:mm a');

/*
  Updates target's hours and minutes with source's ones and returns Moment object.
 */
export const updateTime = (target: Date, source: Date): moment.Moment => {
  const time: string[] = moment(source).format('HH:mm').split(':');

  return moment(new Date(target)).set({ hours: Number(time[0]), minutes: Number(time[1]) });
};
