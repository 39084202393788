@if (loading()) {
  <app-loader></app-loader>
} @else {
  <div class="admin-event-sessions-table-container">
    <admin-table-header-navbar [title]="'adminEventsSessions.title' | translate">
      <app-button
        buttonText="{{ 'adminEventsSessions.addBtn' | translate }}"
        buttonIconLeft="far fa-plus"
        [buttonSize]="buttonSize().MEDIUM"
        (click)="openNewSessionDialog()" />
    </admin-table-header-navbar>

    <div class="c-my-4">
      <p-divider styleClass="custom-divider-horizontal"></p-divider>
    </div>

    <admin-table-filter-navbar
      (onSearchTermChange)="searchValue.set($event)"></admin-table-filter-navbar>

    <admin-table
      [loading]="loadingEntries()"
      [columns]="columns()"
      [entries]="entries()"
      [searchValue]="searchValue()"
      [searchFilterFields]="searchFilterFields()"
      [paginator]="true"
      [lazy]="false"
      emptyStateKey="adminEventsSessions.emptyState"
      emptyStateIcon="far fa-video"
      (rowClicked)="onRowClicked($event)">
      <ng-template
        let-entry
        epTemplateKey="dateCell">
        {{ entry.date | date: 'dd.MMM.YYYY' }}
      </ng-template>

      <ng-template
        let-entry
        epTemplateKey="startCell">
        {{ entry.start | timePipe }}
      </ng-template>

      <ng-template
        let-entry
        epTemplateKey="endCell">
        {{ entry.end | timePipe }}
      </ng-template>

      <ng-template
        let-entry
        epTemplateKey="featuredCell">
        <app-tag
          [iconClass]="entry.featuredSession ? 'fa-regular fa-check' : 'fa-regular fa-xmark'"
          [bgColor]="entry.featuredSession ? 'var(--appPrimaryColor)' : '#ff244c'">
        </app-tag>
      </ng-template>

      <ng-template
        let-entry
        epTemplateKey="statusCell">
        <app-tag
          [iconClass]="entry.space ? 'fa-regular fa-check' : 'fa-regular fa-xmark'"
          [bgColor]="entry.space ? 'var(--appPrimaryColor)' : '#ff244c'">
        </app-tag>
      </ng-template>

      <ng-template
        let-entry
        epTemplateKey="availabilityCell">
        {{ textForAvailability(entry) }}
      </ng-template>

      <ng-template
        let-entry
        epTemplateKey="actionsCell">
        <div class="table-btn-actions-container">
          <app-button
            [buttonType]="buttonType().ROUND"
            [buttonSize]="buttonSize().MEDIUM"
            [buttonStyle]="buttonStyle().FILLED_SECONDARY"
            buttonRoundIcon="far fa-pen-to-square"
            (click)="edit($event, entry)" />
          <app-button
            [buttonType]="buttonType().ROUND"
            [buttonSize]="buttonSize().MEDIUM"
            [buttonStyle]="buttonStyle().FILLED_DANGER"
            buttonRoundIcon="far fa-trash-can"
            (click)="remove($event, entry)" />
        </div>
      </ng-template>
    </admin-table>
  </div>
}
