import { Injectable } from '@angular/core';

import { Firestore, IFirestore } from 'src/app/firebase';
import { IEventCustomPageMenuOptionGroup, EventPage } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class EventPagesService {
  private firestore: IFirestore;

  constructor() {
    this.firestore = Firestore();
  }

  async create(page: EventPage, eventId: string): Promise<EventPage> {
    try {
      //create & save page
      const preEmailReqDoc = this.firestore.collection(`events/${eventId}/pages`).doc();
      page.id = preEmailReqDoc.id;
      await this.firestore
        .collection(`events/${eventId}/pages`)
        .doc(page.id)
        .set({ ...page });
      //create menu item & save
      const pageMenuItem: IEventCustomPageMenuOptionGroup = {
        id: page._title_.replace(/\s/g, ''),
        name: page.title,
        customIconClass: page.customIconClass,
        visible: false,
        type: 'custom-page',
        key: page.title,
        pageId: page.id,
      };

      const menuConfigExists = await this.firestore
        .collection(`events/${eventId}/settings`)
        .doc('menuConfig')
        .get();

      if (menuConfigExists.exists) {
        const menuConfigData = menuConfigExists.data();
        menuConfigData.regularMenu.push(pageMenuItem);
        menuConfigData.onDemandMenu.push(pageMenuItem);
        menuConfigData.footerMenu?.push(pageMenuItem);
        menuConfigData.eventDetailsMenu?.push(pageMenuItem);
        menuConfigData.helpSupportMenu?.push(pageMenuItem);

        await this.firestore
          .collection(`events/${eventId}/settings`)
          .doc('menuConfig')
          .update(menuConfigData);
      } else {
        await this.firestore
          .collection(`events/${eventId}/settings`)
          .doc('menuConfig')
          .set(
            {
              regularMenu: [pageMenuItem],
              onDemandMenu: [pageMenuItem],
              footerMenu: [pageMenuItem],
              eventDetailsMenu: [pageMenuItem],
              helpSupportMenu: [pageMenuItem],
            },
            { merge: true },
          );
      }

      return page as any as EventPage;
    } catch (error) {
      console.log(error);
    }
  }

  async update(page: EventPage, eventId: string): Promise<EventPage> {
    try {
      //update page
      const pageRef = this.firestore.collection(`events/${eventId}/pages`).doc(page.id);
      await pageRef.update(page);

      // update menu items
      const defaultPageMenuItem: IEventCustomPageMenuOptionGroup = {
        id: page._title_.replace(/\s/g, ''),
        name: page.title,
        customIconClass: page.customIconClass,
        visible: false,
        type: 'custom-page',
        key: page.title,
        pageId: page.id,
      };

      const menuConfigExists = await this.firestore
        .collection(`events/${eventId}/settings`)
        .doc('menuConfig')
        .get();

      if (menuConfigExists.exists) {
        let menuConfigData = menuConfigExists.data();
        const regularMenuPageItem = menuConfigData.regularMenu.find((x) => x.pageId === page.id);
        const onDemandMenuPageItem = menuConfigData.onDemandMenu.find((x) => x.pageId === page.id);

        const newRegularPageMenuItem: IEventCustomPageMenuOptionGroup = {
          id: page._title_.replace(/\s/g, ''),
          name: page.title,
          customIconClass: page.customIconClass,
          visible: regularMenuPageItem.visible,
          type: 'custom-page',
          key: page.title,
          pageId: page.id,
        };

        const newOnDemanPageMenuItem: IEventCustomPageMenuOptionGroup = {
          id: page._title_.replace(/\s/g, ''),
          name: page.title,
          customIconClass: page.customIconClass,
          visible: onDemandMenuPageItem.visible,
          type: 'custom-page',
          key: page.title,
          pageId: page.id,
        };

        const regularIndex = menuConfigData.regularMenu.indexOf(regularMenuPageItem);
        regularIndex > -1
          ? (menuConfigData.regularMenu[regularIndex] = newRegularPageMenuItem)
          : '';

        const onDemandIndex = menuConfigData.onDemandMenu.indexOf(onDemandMenuPageItem);
        onDemandIndex > -1
          ? (menuConfigData.onDemandMenu[onDemandIndex] = newOnDemanPageMenuItem)
          : '';

        await this.firestore
          .collection(`events/${eventId}/settings`)
          .doc('menuConfig')
          .update(menuConfigData);
      } else {
        await this.firestore
          .collection(`events/${eventId}/settings`)
          .doc('menuConfig')
          .set(
            { regularMenu: [defaultPageMenuItem], onDemandMenu: [defaultPageMenuItem] },
            { merge: true },
          );
      }

      return page as any as EventPage;
    } catch (error) {
      console.log(error);
    }
  }

  async getAll(eventId: string): Promise<EventPage[]> {
    try {
      const pages = await this.firestore
        .collection(`events/${eventId}/pages`)
        .where('isDeleted', '==', false)
        .orderBy('createdAt', 'desc')
        .get();

      return pages.docs.map((doc) => {
        const page = doc.data();
        return page as EventPage;
      });
    } catch (error) {
      console.log(error);
    }
  }

  async getAllByLanguage(eventId: string, language: string): Promise<EventPage[]> {
    try {
      const pages = await this.firestore
        .collection(`events/${eventId}/pages`)
        .where('isDeleted', '==', false)
        .where('language', '==', language)
        .orderBy('createdAt', 'desc')
        .get();

      return pages.docs.map((doc) => {
        const page = doc.data();
        return page as EventPage;
      });
    } catch (error) {
      console.log(error);
    }
  }

  async get(id: string, eventId: string): Promise<EventPage> {
    try {
      const page = await this.firestore.collection(`events/${eventId}/pages`).doc(id).get();
      return page.data() as any as EventPage;
    } catch (error) {
      console.log(error);
    }
  }

  async updateEventPage(id: string, page: EventPage, eventId: string): Promise<void> {
    try {
      const emailRef = this.firestore.collection(`events/${eventId}/pages`).doc(id);
      await emailRef.update(page);
    } catch (error) {
      console.log(error);
    }
  }

  async softRemove(id: string, eventId: string): Promise<boolean> {
    try {
      const pageRef = this.firestore.collection(`events/${eventId}/pages`).doc(id);
      await pageRef.update({
        isDeleted: true,
      });

      this.removeMenuItem((await pageRef.get()).id, eventId);
      return true;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  private async removeMenuItem(pageId: string, eventId: string): Promise<boolean> {
    const menuConfigExists = await this.firestore
      .collection(`events/${eventId}/settings`)
      .doc('menuConfig')
      .get();

    if (menuConfigExists.exists) {
      const menuConfigData = menuConfigExists.data();
      const regularMenuPageItem = menuConfigData.regularMenu.find((x) => x.pageId === pageId);
      const onDemandMenuPageItem = menuConfigData.onDemandMenu.find((x) => x.pageId === pageId);

      menuConfigData.regularMenu = menuConfigData.regularMenu.filter(
        (x) => x.id != regularMenuPageItem.id,
      );
      menuConfigData.onDemandMenu = menuConfigData.onDemandMenu.filter(
        (x) => x.id != onDemandMenuPageItem.id,
      );

      await this.firestore
        .collection(`events/${eventId}/settings`)
        .doc('menuConfig')
        .update(menuConfigData);

      return true;
    } else {
      return false;
    }
  }
}
