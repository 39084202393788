<div class="container">
  <header>
    <span>{{ 'chapterDetails.title' | translate }}</span>
    <i
      class="fa-regular fa-xmark"
      (click)="onClose()"></i>
  </header>
  <form
    class="c-mb-4"
    [formGroup]="form">
    <!-- Banner Image -->
    <div class="c-mb-4">
      <app-logo-media-form
        titleMediaForm="chapterDetails.bannerTitle"
        description="chapterDetails.bannerDesc"
        btnConfirm="chapterDetails.btnUpload"
        btnCancel="chapterDetails.btnClear"
        [imgValue]="form.controls.banner.value"
        (changeValue)="setBannerValue($event)">
      </app-logo-media-form>
    </div>

    <!-- Title input -->
    <div>
      <app-input-label
        labelType="required"
        for="title-input"
        text="chapterDetails.titleLabel">
      </app-input-label>
      <input
        class="custom-input-text custom-input-text-required"
        formControlName="title"
        id="title-input"
        pInputText
        type="text"
        placeholder="{{ 'chapterDetails.titlePlaceholder' | translate }}" />
      <app-input-error-message
        [showMessage]="form.controls.title.invalid && form.controls.title.touched"
        errorMessage="{{ 'application.forms.required' | translate }}">
      </app-input-error-message>
    </div>

    <!-- Short description textarea -->
    <div class="c-mb-4">
      <app-input-label text="chapterDetails.shortDescriptionLabel"></app-input-label>
      <textarea
        class="custom-input-textarea resize-none"
        pInputTextarea
        formControlName="shortDescription"
        placeholder="{{ 'chapterDetails.shortDescriptionPlaceholder' | translate }}">
      </textarea>
    </div>

    <!-- Description textarea -->
    <div class="form-field-block">
      <app-input-label text="chapterDetails.descriptionLabel"></app-input-label>
      <p-editor
        styleClass="chapter-details-custom-editor"
        formControlName="description"
        placeholder="{{ 'chapterDetails.descriptionPlaceholder' | translate }}">
      </p-editor>
    </div>

    <!-- Is send email after chapter completed input -->
    <div class="c-mb-4">
      <p class="email-trigger-title">{{ 'chapterDetails.emailTriggerTitle' | translate }}</p>
      <div class="form-field-input-switch-block c-mt-4 c-p-0">
        <p-inputSwitch
          class="custom-input-switch"
          formControlName="isSendEmailAfterChapterCompleted"
          inputId="is-send-email-after-chapter-completed-input">
        </p-inputSwitch>
        <app-input-label
          labelType="switch-checkbox"
          for="is-send-email-after-chapter-completed-input"
          text="chapterDetails.isSendEmailAfterChapterCompleted">
        </app-input-label>
      </div>
    </div>

    <!-- Supported languages -->
    <div class="form-field-block c-mb-4">
      <app-input-label
        for="supported-languages-input"
        text="chapterDetails.supportedLanguagesTitle">
      </app-input-label>
      <p-multiSelect
        class="custom-multi-select"
        panelStyleClass="custom-multi-select-panel-style-class"
        [options]="supportedLangs()"
        formControlName="supportedLanguages"
        inputId="supported-languages-input"
        placeholder="{{ 'chapterDetails.supportedLanguagesPlaceholder' | translate }}">
        <ng-template
          let-selectedLangs
          pTemplate="selectedItems">
          @for (selectedLang of selectedLangs; track $index; let last = $last) {
            <span
              class="
                flag-icon flag-icon-squared flag-icon-circle
                flag-icon-{{ selectedLang === 'en' ? 'gb' : selectedLang }}
              ">
            </span>
            {{ 'languages.' + selectedLang | translate }}
            @if (!last) {
              ,
            }
          }
        </ng-template>
        <ng-template
          let-lang
          pTemplate="item">
          <span
            class="
              flag-icon flag-icon-squared flag-icon-circle
              flag-icon-{{ lang === 'en' ? 'gb' : lang }}">
          </span>
          {{ 'languages.' + lang | translate }}
        </ng-template>
      </p-multiSelect>
      <p class="input-note">
        {{ 'chapterDetails.supportedLanguagesInputNote' | translate }}
      </p>
    </div>
    <div class="flex">
      <app-button
        buttonText="{{ 'chapterDetails.reviewEditTranslationsBtn' | translate }}"
        [buttonStyle]="buttonStyle.TERTIARY_PRIMARY"
        (click)="onOpenManageTranslationsDialog()" />
    </div>
  </form>

  <div class="buttons-container">
    <app-button
      buttonText="{{ 'chapterDetails.cancelBtn' | translate }}"
      buttonIconLeft="fa-regular fa-xmark"
      [buttonStyle]="buttonStyle.GHOST_PRIMARY"
      [buttonSize]="buttonSize.MEDIUM"
      (click)="onClose()" />
    <app-button
      buttonText="{{ 'chapterDetails.saveBtn' | translate }}"
      buttonIconLeft="fa-regular fa-check"
      [buttonSize]="buttonSize.MEDIUM"
      [disabled]="form.invalid"
      (click)="onSave()" />
  </div>
</div>
