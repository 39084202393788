<ng-template #chatEmpty>
  <div class="empty-chat-conversation">
    <button
      class="close-chat-window"
      (click)="closeWindowAction()">
      <i class="far fa-times-circle"></i>
    </button>
    <div class="top-section">
      <app-user-photo
        class="user-photo"
        [user]="loggedUser"
        [isUnshowUserPresence]="'true'"></app-user-photo>
      <h2>
        {{ 'chat.chatWindow.emptyChat.greetingsLabelOne' | translate }}
        {{ loggedUser.firstName }} 👋<br />
        {{ 'chat.chatWindow.emptyChat.greetingsLabelTwo' | translate }}
      </h2>
      <h4>
        {{ 'chat.chatWindow.emptyChat.infoLabel' | translate }}
      </h4>
    </div>
    <div class="bottom-section">
      <div class="users">
        <img src="/assets/images/chat/users.png" />
      </div>
      <button
        class="p-button empty-chat-button"
        (click)="onNavigate()">
        {{ 'chat.chatWindow.emptyChat.discoverCommunityBtn' | translate }}
      </button>
    </div>
  </div>
</ng-template>

<ng-template #chatList>
  <div class="chat-nav-bar chat-nav-bar--chat-list">
    <div class="title">
      {{ 'chat.chatWindow.chatList.title' | translate }}
    </div>
    <button
      class="close-chat-window"
      (click)="closeWindowAction()">
      <i class="far fa-times-circle"></i>
    </button>
  </div>

  <div class="chat-content">
    <app-chat-conversations-list
      [chatConversations]="chatConversations$ | async"
      (chatConversationSelected)="onChatConversationSelected($event)"></app-chat-conversations-list>
  </div>
</ng-template>

<ng-template #chatConversation>
  <div
    class="chat-nav-bar chat-nav-bar--chat-conversation"
    [ngClass]="{ 'chat-nav-bar--chat-conversation-loading': loadingConversation }">
    <button
      *ngIf="!isHideChatArrowButton"
      class="back-arrow"
      (click)="returnToListAction()"></button>
    <div
      class="unread-counter"
      *ngIf="unreadMessages">
      {{ unreadMessages }}
    </div>
    <app-user-photo [user]="selectedChatConversation.conversationWithUser"></app-user-photo>
    <div class="user-name">
      {{ selectedChatConversation.conversationWithUser.firstName }}
      {{ selectedChatConversation.conversationWithUser.lastName }}
    </div>
    <button
      *ngIf="isShowOpenAppointmentBtn"
      class="meeting-btn"
      (click)="onOpenAppointmentDialog()">
      <i class="fak fa-lox-calendar"></i>
      {{ 'chat.chatConversation.meeting' | translate }}
    </button>

    <button
      class="close-chat-window"
      (click)="closeWindowAction()">
      <i class="far fa-times-circle"></i>
    </button>
  </div>

  <div class="chat-content">
    <app-chat-conversation
      [messages]="selectedChatConversationMessages"
      [chatConversationId]="selectedChatConversation.id"
      (sendMessage)="onSendMessage($event)"
      (toggleMessageLike)="onToggleMessageLike($event)">
    </app-chat-conversation>
  </div>
</ng-template>

<div
  class="chat-window"
  *ngIf="isOpen">
  <ng-container [ngSwitch]="state">
    <ng-container
      *ngSwitchCase="'EMPTY'"
      [ngTemplateOutlet]="chatEmpty"></ng-container>
    <ng-container
      *ngSwitchCase="'LIST'"
      [ngTemplateOutlet]="chatList"></ng-container>
    <ng-container
      *ngSwitchCase="'CONVERSATION'"
      [ngTemplateOutlet]="chatConversation"></ng-container>
  </ng-container>
</div>
