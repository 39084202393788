import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { trackByFn } from 'src/app/core/utils';
import { ICustomSidebarMenuItem } from 'src/app/core/models';
import { FeatureSidebarComponent } from '../feature-sidebar/feature-sidebar.component';

@Component({
  selector: 'app-feature-main-content',
  standalone: true,
  imports: [CommonModule, RouterModule, FeatureSidebarComponent],
  templateUrl: './feature-main-content.component.html',
  styleUrls: ['./feature-main-content.component.scss'],
})
export class FeatureMainContentComponent {
  @Input() activeMenuItem: ICustomSidebarMenuItem;
  @Input() checkedMenuItems: string[];
  @Output() checkClicked: EventEmitter<any> = new EventEmitter<any>();

  trackByFn = trackByFn;

  toggleActive(item: any) {
    this.checkClicked.emit(item);
  }
}
