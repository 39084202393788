import Player from '@vimeo/player';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { IVimeoPlayerOptions } from '../../models/vimeo/vimeo-player-options.model';
import { asyncDelay } from '../../utils';

@Injectable({
  providedIn: 'root',
})
export class VimeoPlayerService {
  player: Player;

  private _vimeoVideoPlay = new Subject<void>();
  private _vimeoVideoPause = new Subject<{ seconds: number; percent: number; duration: number }>();
  private _vimeoVideoTimeUpdate = new Subject<{
    seconds: number;
    percent: number;
    duration: number;
  }>();
  private _vimeoVideoEnd = new Subject<void>();

  get vimeoVideoPlay(): Observable<void> {
    return this._vimeoVideoPlay.asObservable();
  }

  get vimeoVideoPause(): Observable<{ seconds: number; percent: number; duration: number }> {
    return this._vimeoVideoPause.asObservable();
  }

  get vimeoVideoTimeUpdate(): Observable<{ seconds: number; percent: number; duration: number }> {
    return this._vimeoVideoTimeUpdate.asObservable();
  }

  get vimeoVideoEnd(): Observable<void> {
    return this._vimeoVideoEnd.asObservable();
  }

  createVimeoPlayer(el: HTMLIFrameElement, options: IVimeoPlayerOptions): Player {
    this.player = new Player(el, options);
    this.startListeners();

    return this.player;
  }

  async play(): Promise<void> {
    try {
      await this.player.play();

      return;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async pause(): Promise<void> {
    try {
      await this.player.pause();

      return;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async getPaused(): Promise<boolean> {
    try {
      const isPaused: boolean = await this.player.getPaused();

      return isPaused;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async setCurrentTime(seconds: number): Promise<number> {
    try {
      const currentTime = await this.player.setCurrentTime(seconds);

      return currentTime;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async loadVideo(player: Player, options: IVimeoPlayerOptions): Promise<any> {
    try {
      const loadedVideo = await player.loadVideo(options);
      return loadedVideo;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async unload(player: Player): Promise<void> {
    try {
      await player.unload();
      return;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async destroy(): Promise<boolean> {
    try {
      if (!this.player) {
        return;
      }

      await this.player.pause();
      await asyncDelay(100);
      this.player.off('pause', () => {});
      this.player.off('playing', () => {});
      this.player.off('timeupdate', () => {});
      await this.player.destroy();
      this.player = null;

      return true;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  private startListeners(): void {
    this.player.on('playing', () => {
      this._vimeoVideoPlay.next();
    });

    this.player.on('pause', (value: { seconds: number; percent: number; duration: number }) => {
      this._vimeoVideoPause.next(value);
    });

    this.player.on(
      'timeupdate',
      (value: { seconds: number; percent: number; duration: number }) => {
        this._vimeoVideoTimeUpdate.next(value);
      },
    );

    this.player.on('ended', () => {
      this._vimeoVideoEnd.next();
    });
  }
}
