import * as Sentry from '@sentry/angular-ivy';
import {
  APP_INITIALIZER,
  ErrorHandler,
  Injector,
  NgModule,
  ModuleWithProviders,
} from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Router, TitleStrategy } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { IntercomModule } from '@supy-io/ngx-intercom';
import { SidebarModule } from 'primeng/sidebar';
import { ClipboardModule } from 'ngx-clipboard';
import { CookieService } from 'ngx-cookie-service';
import { NgChartsModule } from 'ng2-charts';

import { StagesStore } from 'src/app/core/stores/stages/stages.store';
import { UsersStore } from 'src/app/core/stores/users/users.store';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppStore } from './app.store';
import { CoreModule } from './core/core.module';
import { LoaderModule } from './core/modules/loader/loader.module';
import { HttpClientService } from './core/services/http/http-client.service';
import { AttendeesStore } from './core/stores/attendees/attendees.store';
import { BrandsStore } from './core/stores/brands/brands.store';
import { EventsStore } from './core/stores/events/events.store';
import { HubsStore } from './core/stores/hubs/hubs.store';
import { SessionsStore } from './core/stores/sessions/sessions.store';
import { SpeakersStore } from './core/stores/speakers/speakers.store';
import { TagsStore } from './core/stores/tags/tags.store';
import { httpLoaderFactory, DOMAINS_MAP, DOMAINS_LIST } from './core/utils/utils';
import { ProductsStore } from './core/stores';
import { EnvironmentFactory } from './core/services/environment/environment.factory';
import { EnvironmentService } from './core/services/environment/environment.service';
import { PageTitleService } from './core/services/page-title/page-title.service';
import { LibraryStore } from './core/stores/library/library.store';
import { CoursesStore } from './core/stores/courses/courses.store';
import { ToastModule } from 'primeng/toast';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ThemesService } from 'src/app/core/services/themes/themes.service';
import { HubsService } from 'src/app/core/services/hubs/hubs.service';
import { UsersService } from 'src/app/core/services/users/users.service';
import { HubFactory } from './core/services/hubs/hub.factory';
import { ToastComponent } from './standalone/shared/components';

export let appInjector: Injector;

const SENTRY_PROVIDERS = [
  {
    provide: ErrorHandler,
    useValue: Sentry.createErrorHandler({
      showDialog: false,
    }),
  },
  {
    provide: Sentry.TraceService,
    deps: [Router],
  },
  {
    provide: APP_INITIALIZER,
    useFactory: () => () => {},
    deps: [Sentry.TraceService],
    multi: true,
  },
];

const providersArray = [
  {
    provide: APP_INITIALIZER,
    useFactory: EnvironmentFactory,
    deps: [EnvironmentService],
    multi: true,
  },
  // {
  //   provide: APP_INITIALIZER,
  //   useFactory: HubFactory,
  //   deps: [
  //     EnvironmentService,
  //     ThemesService,
  //     HubsService,
  //     UsersService,
  //     UsersStore,
  //     HubsStore
  //   ],
  //   multi: true
  // },
  ...SENTRY_PROVIDERS,
  HubsStore,
  TagsStore,
  EventsStore,
  StagesStore,
  UsersStore,
  SpeakersStore,
  AttendeesStore,
  SessionsStore,
  BrandsStore,
  HttpClientService,
  AppStore,
  CoursesStore,
  LibraryStore,
  ProductsStore,
  CookieService,
  {
    provide: TitleStrategy,
    useClass: PageTitleService,
  },
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    CoreModule,
    IntercomModule.forRoot({
      appId: null,
      updateOnRouterChange: true,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    LoaderModule,
    SidebarModule,
    GoogleTagManagerModule.forRoot({
      id: environment.googleTagManager.id,
    }),
    ClipboardModule,
    ToastModule,
    ServiceWorkerModule.register('service-worker.js', {
      enabled:
        (environment.production &&
          environment.enableSW &&
          !location.host.includes('127.0.0.1') &&
          !location.host.includes('localhost')) ||
        (environment.env === 'LOCAL' && environment.enableSW),
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    NgChartsModule,
    ToastComponent,
  ],
  providers: providersArray,
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    private injector: Injector,
    public appStore: AppStore,
    public hubsStore: HubsStore,
  ) {
    appInjector = this.injector;
    if (DOMAINS_LIST().includes(window.location.host)) {
      this.hubsStore.setHubDomainBinding(
        DOMAINS_MAP[window.location.host] && DOMAINS_MAP[window.location.host].length
          ? true
          : false,
      );
    }
  }
}
