<div class="mx-6">
  <div class="flex justify-content-center my-5">
    <h2>{{ 'progressBar.title' | translate }}</h2>
  </div>

  <p-progressBar
    [showValue]="false"
    [style]="{ height: '5px' }"
    mode="indeterminate"></p-progressBar>
  <div class="flex justify-content-between">
    <h5 class="mt-2">{{ 'progressBar.remainingTime' | translate }}</h5>
    <h5 class="mt-2">{{ 'progressBar.time' | translate: { secondsLeft: secondsLeft } }}</h5>
  </div>
  <p>{{ 'progressBar.description' | translate }}</p>
</div>
