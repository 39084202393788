import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { IEvent, IUserEvent } from 'src/app/core/models';
import { MixpanelService } from 'src/app/core/services';
import { goToLink } from 'src/app/core/utils';

@Component({
  selector: 'app-eventbrite-ticket',
  templateUrl: './eventbrite-ticket.component.html',
  styleUrls: ['./eventbrite-ticket.component.scss'],
})
export class EventbriteTicketComponent implements OnInit, AfterViewInit {
  @Input() event: IEvent;
  @Input() userEventsMap: { [eventId: string]: IUserEvent };
  @Input() className = 'p-button';
  @Input() buttonText = 'eventRegister.getTicket';
  @ViewChild('ticketContainer', { static: true, read: ElementRef })
  ticketContainer: ElementRef<HTMLDivElement>;
  private clickListener: () => void;

  constructor(
    private renderer: Renderer2,
    private translateService: TranslateService,
    private mixpanelService: MixpanelService,
  ) {}

  ngOnInit(): void {
    this.buttonText = this.translateService.instant(this.buttonText);
  }

  ngAfterViewInit(): void {
    if (this.event?.eventbriteButton) {
      // get tag "a"
      const el = this.ticketContainer.nativeElement.getElementsByTagName('a')[0];
      // get href attribute
      const href = el.getAttributeNode('href').value;
      const childElements = this.ticketContainer.nativeElement.childNodes;
      for (let i = 0; i < childElements.length; i++) {
        // clear parent element
        this.renderer.removeChild(this.ticketContainer.nativeElement, childElements[i]);
      }
      const button = this.renderer.createElement('button');
      const buttonText = this.renderer.createText(this.buttonText);
      this.renderer.addClass(button, this.className);
      this.renderer.setStyle(button, 'display', 'flex');
      this.renderer.setStyle(button, 'justify-content', 'center');
      this.renderer.appendChild(button, buttonText);
      this.renderer.appendChild(this.ticketContainer.nativeElement, button);
      this.clickListener = this.renderer.listen(button, 'click', () => {
        goToLink(href);
        // send event to mixpanel analytics
        this.mixpanelService.eventTicketBuy(this.event);
      });
    }
  }

  isEventRegistered(eventId: string): boolean {
    return Object.keys(this.userEventsMap).includes(eventId);
  }

  ngOnDestroy(): void {
    if (this.clickListener) {
      this.clickListener();
    }
  }
}
