<ng-container *ngIf="loading">
  <app-loader></app-loader>
</ng-container>

<div
  *ngIf="!loading"
  class="system-email-basic-editor-container">
  <div class="grid container">
    <div class="col-8 left-side">
      <div class="header-section grid">
        <div class="col txt-a-r button-container">
          <button
            class="p-button-rounded p-button-text"
            pButton
            pRipple
            icon="fa-light fa-copy"
            (click)="copy()"></button>
          <button
            class="p-button-text"
            pButton
            pRipple
            [label]="'adminEmailTemplate.mergeTagsBtn' | translate"
            icon="fa-regular fa-code"
            (click)="mergeTags()"></button>
          <button
            class="preview"
            pButton
            pRipple
            [label]="'adminEmailTemplate.previewBtn' | translate"
            (click)="preview()"></button>
        </div>
      </div>

      <div class="preview-section">
        <p-editor
          styleClass="custom-editor-height-forty-one"
          [formControl]="form.controls.description"></p-editor>
      </div>
    </div>

    <div class="col-4 right-side">
      <div
        *ngIf="email"
        class="send-test-email-section">
        <h3>{{ 'adminEmailTemplate.sendTestEmailTitle' | translate }}</h3>
        <form [formGroup]="testEmailForm">
          <!-- Email input -->
          <div>
            <app-input-label
              labelType="required"
              for="email-input"
              text="adminEmailTemplate.emailLabel">
            </app-input-label>
            <input
              class="custom-input-text custom-input-text-required"
              formControlName="email"
              id="email-input"
              pInputText
              type="text"
              placeholder="{{ 'adminEmailTemplate.emailLabel' | translate }}" />
            <app-input-error-message
              [showMessage]="
                testEmailForm.controls.email.invalid && testEmailForm.controls.email.touched
              "
              [errorMessage]="emailErrorMessage">
            </app-input-error-message>
          </div>

          <div class="actions-container txt-a-r">
            <button
              class="primary-text-color"
              type="button"
              pButton
              iconPos="right"
              label="{{ 'adminEmailTemplate.sendTestEmailBtn' | translate }}"
              [icon]="sendingTestEmail ? 'pi pi-spin pi-spinner' : 'far fa-arrow-right-long'"
              [disabled]="testEmailForm.invalid"
              (click)="sendTestEmail()"></button>
          </div>
        </form>
      </div>

      <div class="form-section">
        <h3>{{ 'adminEmailTemplate.emailFormTitle' | translate }}</h3>
        <div class="c-my-8">
          <p-divider styleClass="custom-divider-horizontal"></p-divider>
        </div>
        <form [formGroup]="form">
          <p-accordion
            [multiple]="true"
            expandIcon="far fa-chevron-down"
            collapseIcon="far fa-chevron-up"
            styleClass="custom-accordion">
            <p-accordionTab
              header="{{ 'adminEmailTemplate.tab1' | translate }}"
              [selected]="true">
              <!-- Module input -->
              <div class="c-mb-4">
                <app-input-label
                  for="module-input"
                  text="adminEmailTemplate.moduleLabel">
                </app-input-label>
                <p-dropdown
                  class="custom-dropdown"
                  panelStyleClass="custom-dropdown-panel"
                  [options]="moduleTypes"
                  formControlName="module"
                  inputId="module-input"
                  placeholder="{{ 'adminEmailTemplate.moduleLabel' | translate }}">
                  <ng-template
                    let-type
                    pTemplate="selectedItem">
                    {{ getModuleTypeText(type) }}
                  </ng-template>
                  <ng-template
                    let-type
                    pTemplate="item">
                    {{ getModuleTypeText(type) }}
                  </ng-template>
                </p-dropdown>
              </div>

              <!-- Title input -->
              <div>
                <app-input-label
                  labelType="required"
                  for="title-input"
                  text="adminEmailTemplate.titleLabel">
                </app-input-label>
                <input
                  class="custom-input-text custom-input-text-required"
                  formControlName="title"
                  id="title-input"
                  pInputText
                  type="text"
                  placeholder="{{ 'adminEmailTemplate.titleLabel' | translate }}" />
                <app-input-error-message
                  [showMessage]="form.controls.title.invalid && form.controls.title.touched"
                  errorMessage="{{ 'adminEmailTemplate.titleRequired' | translate }}">
                </app-input-error-message>
              </div>

              <!-- Language input -->
              <div>
                <app-input-label
                  labelType="required"
                  for="language-input"
                  text="adminEmailTemplate.languageLabel">
                </app-input-label>
                <p-dropdown
                  class="custom-dropdown custom-dropdown-required"
                  panelStyleClass="custom-dropdown-panel"
                  [options]="languages"
                  optionLabel="name"
                  formControlName="language"
                  inputId="language-input"
                  placeholder="{{ 'adminEmailTemplate.languageLabel' | translate }}">
                  <ng-template pTemplate="selectedItem">
                    <span
                      class="flag-icon flag-icon-squared flag-icon-circle flag-icon-{{
                        form.controls.language.value.code === 'en'
                          ? 'gb'
                          : form.controls.language.value.code
                      }}">
                    </span>
                    {{ 'languages.' + form.controls.language.value.code | translate }}
                  </ng-template>
                  <ng-template
                    let-lang
                    pTemplate="item">
                    <span
                      class="flag-icon flag-icon-squared flag-icon-circle flag-icon-{{
                        lang.code === 'en' ? 'gb' : lang.code
                      }}">
                    </span>
                    {{ 'languages.' + lang.code | translate }}
                  </ng-template>
                </p-dropdown>
                <app-input-error-message
                  [showMessage]="form.controls.language.invalid && form.controls.language.touched"
                  errorMessage="{{ 'adminEmailTemplate.languageRequired' | translate }}">
                </app-input-error-message>
              </div>

              <!-- Subject input -->
              <div>
                <app-input-label
                  labelType="required"
                  for="subject-input"
                  text="adminEmailTemplate.subjectLabel">
                </app-input-label>
                <input
                  class="custom-input-text custom-input-text-required"
                  formControlName="subject"
                  id="subject-input"
                  pInputText
                  type="text"
                  placeholder="{{ 'adminEmailTemplate.subjectLabel' | translate }}" />
                <app-input-error-message
                  [showMessage]="form.controls.subject.invalid && form.controls.subject.touched"
                  errorMessage="{{ 'adminEmailTemplate.subjectRequired' | translate }}">
                </app-input-error-message>
              </div>

              <!-- From input -->
              <div>
                <app-input-label
                  labelType="required"
                  for="from-input"
                  text="adminEmailTemplate.fromLabel">
                </app-input-label>
                <input
                  class="custom-input-text custom-input-text-required"
                  formControlName="from"
                  id="from-input"
                  pInputText
                  type="text"
                  placeholder="{{ 'adminEmailTemplate.fromLabel' | translate }}" />
                <app-input-error-message
                  [showMessage]="form.controls.from.invalid && form.controls.from.touched"
                  errorMessage="{{ 'adminEmailTemplate.fromRequired' | translate }}">
                </app-input-error-message>
              </div>
            </p-accordionTab>

            <p-accordionTab header="{{ 'adminEmailTemplate.tab2' | translate }}">
              <!-- Audience input -->
              <div class="c-mb-4">
                <app-input-label
                  for="audience-input"
                  text="adminEmailTemplate.audienceLabel">
                </app-input-label>
                <p-dropdown
                  class="custom-dropdown"
                  panelStyleClass="custom-dropdown-panel"
                  [options]="audience"
                  formControlName="audience"
                  inputId="audience-input"
                  placeholder="{{ 'adminEmailTemplate.audienceLabel' | translate }}">
                </p-dropdown>
              </div>

              <!-- Action input -->
              <div class="c-mb-4">
                <app-input-label
                  for="action-input"
                  text="adminEmailTemplate.actionLabel">
                </app-input-label>
                <p-dropdown
                  class="custom-dropdown"
                  panelStyleClass="custom-dropdown-panel"
                  [options]="actions"
                  formControlName="action"
                  inputId="action-input"
                  placeholder="{{ 'adminEmailTemplate.actionLabel' | translate }}">
                </p-dropdown>
              </div>

              <ng-container *ngIf="!this.course && !this.event">
                <!-- Default input -->
                <div class="form-field-input-switch-block c-mb-4 c-px-0">
                  <p-inputSwitch
                    class="custom-input-switch"
                    formControlName="default"
                    inputId="default-input">
                  </p-inputSwitch>
                  <app-input-label
                    labelType="switch-checkbox"
                    for="default-input"
                    text="adminEmailTemplate.defaultLabel">
                  </app-input-label>
                </div>

                <!-- Status input -->
                <div class="form-field-input-switch-block c-mb-4 c-px-0">
                  <p-inputSwitch
                    class="custom-input-switch"
                    formControlName="status"
                    inputId="status-input">
                  </p-inputSwitch>
                  <app-input-label
                    labelType="switch-checkbox"
                    for="status-input"
                    text="adminEmailTemplate.statusLabel">
                  </app-input-label>
                </div>
              </ng-container>
            </p-accordionTab>

            <p-accordionTab header="{{ 'adminEmailTemplate.tab3' | translate }}">
              <!-- Image input -->
              <div>
                <app-input-label
                  labelType="required"
                  for="image-input"
                  text="adminEmailTemplate.imageLabel">
                </app-input-label>
                <input
                  class="custom-input-text custom-input-text-required"
                  formControlName="image"
                  id="image-input"
                  pInputText
                  type="text"
                  placeholder="{{ 'adminEmailTemplate.imageLabel' | translate }}" />
                <app-input-error-message
                  [showMessage]="form.controls.image.invalid && form.controls.image.touched"
                  errorMessage="{{ 'adminEmailTemplate.imageRequired' | translate }}">
                </app-input-error-message>
              </div>

              <!-- Color input -->
              <div>
                <app-input-label
                  labelType="required"
                  for="color-input"
                  text="adminEmailTemplate.colorLabel">
                </app-input-label>
                <input
                  class="custom-input-text custom-input-text-required"
                  formControlName="color"
                  id="color-input"
                  pInputText
                  type="text"
                  placeholder="{{ 'adminEmailTemplate.colorLabel' | translate }}" />
                <app-input-error-message
                  [showMessage]="form.controls.color.invalid && form.controls.color.touched"
                  errorMessage="{{ 'adminEmailTemplate.colorRequired' | translate }}">
                </app-input-error-message>
              </div>

              <!-- Email header input -->
              <div>
                <app-input-label
                  labelType="required"
                  for="email-header-input"
                  text="adminEmailTemplate.emailHeaderLabel">
                </app-input-label>
                <input
                  class="custom-input-text custom-input-text-required"
                  formControlName="emailHeader"
                  id="email-header-input"
                  pInputText
                  type="text"
                  placeholder="{{ 'adminEmailTemplate.emailHeaderLabel' | translate }}" />
                <app-input-error-message
                  [showMessage]="
                    form.controls.emailHeader.invalid && form.controls.emailHeader.touched
                  "
                  errorMessage="{{ 'adminEmailTemplate.emailHeaderRequired' | translate }}">
                </app-input-error-message>
              </div>

              <!-- Button text input -->
              <div>
                <app-input-label
                  labelType="required"
                  for="button-text-input"
                  text="adminEmailTemplate.buttonTextLabel">
                </app-input-label>
                <input
                  class="custom-input-text custom-input-text-required"
                  formControlName="buttonText"
                  id="button-text-input"
                  pInputText
                  type="text"
                  placeholder="{{ 'adminEmailTemplate.buttonTextLabel' | translate }}" />
                <app-input-error-message
                  [showMessage]="
                    form.controls.buttonText.invalid && form.controls.buttonText.touched
                  "
                  errorMessage="{{ 'adminEmailTemplate.buttonTextRequired' | translate }}">
                </app-input-error-message>
              </div>

              <!-- Custom url input -->
              <div>
                <app-input-label
                  labelType="required"
                  for="custom-url-input"
                  text="adminEmailTemplate.customUrlLabel">
                </app-input-label>
                <input
                  class="custom-input-text custom-input-text-required"
                  formControlName="customUrl"
                  id="custom-url-input"
                  pInputText
                  type="text"
                  placeholder="{{ 'adminEmailTemplate.customUrlLabel' | translate }}" />
                <app-input-error-message
                  [showMessage]="form.controls.customUrl.invalid && form.controls.customUrl.touched"
                  errorMessage="{{ 'adminEmailTemplate.customUrlRequired' | translate }}">
                </app-input-error-message>
              </div>
            </p-accordionTab>
          </p-accordion>

          <div class="actions-container txt-a-r">
            <button
              class="primary-text-color"
              type="button"
              pButton
              iconPos="right"
              label="{{ 'adminEmailTemplate.saveEmailBtn' | translate }}"
              [icon]="saving ? 'pi pi-spin pi-spinner' : ''"
              [disabled]="form.invalid"
              (click)="save()"></button>
          </div>
        </form>
      </div>
    </div>
  </div>

  <p-sidebar
    [(visible)]="showMergeTags"
    [styleClass]="'email-merge-tags-sidebar'"
    [style]="{
      width: '40%',
      background: 'var(--background-sidebar)',
      color: 'var(--text-primary)',
      border: '0',
    }"
    position="right"
    baseZIndex="1">
    <app-system-email-merge-tags *ngIf="showMergeTags"></app-system-email-merge-tags>
  </p-sidebar>
</div>
