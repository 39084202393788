import { MenuItem } from 'primeng/api';

export interface ICustomSidebarMenuItem extends MenuItem {
  sidebarItems?: ISidebarItems;
}

export interface ISidebarItems {
  layout: SidebarItemsLayout;
  menuItems: ISidebarMenuItem[];
}

export enum SidebarItemsLayout {
  DEFAULT = 'default',
  SETUP = 'setup',
}

export interface ISidebarMenuItem {
  groupTitle?: string;
  items: MenuItem[];
}
