import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { IUserPresenceStylesConfig, IUser } from 'src/app/core/models';
import { userAvatar } from 'src/app/shared';

@Component({
  selector: 'app-user-photo',
  templateUrl: './user-photo.component.html',
  styleUrls: ['./user-photo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserPhotoComponent {
  @Input() user?: IUser;
  @Input() isUnshowUserPresence: boolean;

  defaultAvatar = userAvatar;

  configForPresenceStatus: IUserPresenceStylesConfig = {
    width: '0.5rem',
    height: '0.5rem',
    top: '1.7rem',
    right: '0rem',
    background: '#47D10F',
    border: '1px solid #292929',
  };
}
