import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';

import { ChatMessageAttachment } from 'src/app/core/models/chat';

@Component({
  selector: 'app-chat-reply',
  templateUrl: './chat-reply.component.html',
  styleUrls: ['./chat-reply.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ChatReplyComponent {
  @ViewChild('emojiPanel')
  emojiPanel?: OverlayPanel;

  @ViewChild('chatReplyArea')
  chatReplyArea?: ElementRef<HTMLDivElement>;

  @Input()
  placeholder: string;

  @Input()
  max?: number;

  @Input()
  attachment?: ChatMessageAttachment;

  @Output()
  sendMessage = new EventEmitter<{ text: string; attachment?: ChatMessageAttachment }>();

  @Output()
  loadAttachment = new EventEmitter<string>();

  @HostListener('keydown', ['$event'])
  keypressEvent(event: KeyboardEvent): boolean {
    if (event.key === 'Enter') {
      this.sendMessageAction();
      return false;
    }

    if (
      this.max &&
      (event.key.length === 1 || event.key === 'Dead') &&
      this.getMessageToSend()?.length >= this.max
    ) {
      return false;
    }

    return true;
  }

  @HostListener('paste', ['$event'])
  pastEvent(event: ClipboardEvent): boolean {
    // sanitize text and insert it at the cursor position
    let text = event.clipboardData.getData('text/plain');

    // get the remain available chars
    const currentLength = this.getMessageToSend()?.length ?? 0;
    const max = this.max ?? Number.MAX_SAFE_INTEGER;
    const remain = max - currentLength;

    // cut the text to fit the remain space
    text = text.substring(0, remain);

    document.execCommand('insertHTML', false, text);

    return false;
  }

  focus(): void {
    const range = document.createRange();
    range.selectNodeContents(this.chatReplyArea?.nativeElement);
    range.collapse(false);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
  }

  sendMessageAction(): void {
    const message = this.getMessageToSend();

    if (message || this.attachment) {
      this.sendMessage.next({
        text: message,
        attachment: this.attachment,
      });

      this.chatReplyArea.nativeElement.textContent = '';
      this.attachment = undefined;
    }
  }

  loadAttachmentAction(): void {
    this.loadAttachment.emit();
  }

  onEmojiSelected(emoji: string): void {
    this.chatReplyArea.nativeElement.textContent = `${this.chatReplyArea.nativeElement.textContent ?? ''}${emoji}`;
    this.emojiPanel?.hide();
    this.focus();
  }

  private getMessageToSend(): string | undefined {
    if (!this.chatReplyArea || !this.chatReplyArea.nativeElement) {
      return undefined;
    }

    let message = this.chatReplyArea.nativeElement.innerHTML;

    if (!message) {
      return undefined;
    }

    // remove head and tail white spaces
    message = message.replace(/&nbsp;/g, ' ').trim();

    // replaces <br> with break line
    message = message.replace(/<br>/g, '\n').trim();

    // replaces <br> with break line
    message = message.replace(/&amp;/g, '&');

    if (!message) {
      return undefined;
    }

    return message;
  }
}
