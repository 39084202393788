import { Injectable } from '@angular/core';
import { makeAutoObservable, observable } from 'mobx';

import { IBrandProduct } from '../../models';

@Injectable()
export class ProductsStore {
  public productsMap: { [key: string]: IBrandProduct } = {};
  public bookmarkedIds: string[] = [];
  constructor() {
    makeAutoObservable(this, {
      productsMap: observable.shallow,
    });
  }

  public setProducts(products: IBrandProduct[]): void {
    for (const product of products) {
      this.productsMap[product.id] = product;
    }
  }

  public get products(): IBrandProduct[] {
    return Object.keys(this.productsMap).map((id) => this.productsMap[id]);
  }

  public setBookmarkedIds(ids: string[]): void {
    this.bookmarkedIds = ids;
  }

  public pushBookmarkedId(id: string): void {
    if (!this.bookmarkedIds.includes(id)) {
      this.bookmarkedIds.push(id);
    }
  }

  public popBookmarkedId(id: string): void {
    this.bookmarkedIds = this.bookmarkedIds.filter((_id) => _id !== id);
  }

  public reset(): void {
    this.productsMap = {};
    this.bookmarkedIds = [];
  }
}
