import * as moment from 'moment';
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Router, NavigationExtras, ActivatedRoute, Params } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';

import { HubsStore, UsersStore } from 'src/app/core/stores';
import {
  AuthenticationService,
  ThemesService,
  UsersService,
  AnalyticsService,
  MixpanelService,
  IntercomService,
  TranslationsService,
  EventsService,
  StripeService,
  BrandsService,
  DevicesService,
  UserHubsService,
} from 'src/app/core/services';
import {
  IBrandMemberInvite,
  IBrandOwnerInvite,
  IHub,
  IUser,
  IUserEvent,
  IUserRegistrationInvite,
  IBrand,
  IUserHub,
  UserHubRoles,
} from 'src/app/core/models';
import { environment } from 'src/environments/environment';
import { goToLink } from 'src/app/core/utils';
import { Auth, FirebaseAuthNamespace, IAuth, Timestamp, ChangeAuthTenant } from 'src/app/firebase';
import { UsersMinLengthValidators, wVLogo } from 'src/app/shared';
import { InviteTypes } from 'src/app/core/enums';
import { AppStore } from 'src/app/app.store';

interface IUserWithInvite extends IUser {
  oldPassword: string;
  invite: Partial<IUserRegistrationInvite | IBrandMemberInvite | IBrandOwnerInvite>;
}

interface IUrlState {
  url: string;
}

interface IInvitedUser {
  invitedUser: IUserWithInvite;
  oldPassword: string;
  invite: Partial<IUserRegistrationInvite | IBrandMemberInvite | IBrandOwnerInvite>;
}

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
  providers: [MessageService],
})
export class SigninComponent implements OnInit, OnDestroy {
  @Input() isModalMode: false;
  @Output() signUp = new EventEmitter<void>();
  @Output() resetPassword = new EventEmitter<void>();

  form: UntypedFormGroup;
  signinLoading = false;
  isVerificationEmailSent = false;
  sendingVerificationEmailLoading = false;
  resentVerificationBtnInterval = null;
  resentVerificationBtnActive = true;
  resentVerificationBtnTimeLeft = 60;

  goToLink = goToLink;
  WBLogo = wVLogo;
  urlToNavigate: string;
  hub: IHub;
  hubLoading = true;
  generalSettings;
  isMicrosoftSSOShown = environment.microsoftSso.enabled;
  redirectLink = null;
  addAccountVerifiedAtTimestamp = false;
  languages: string[] = [];
  currentLanguage: string;
  selectLanguages: any[] = [];
  selectedLanguage: any = null;
  tenantId = null;
  showPassword = false;
  showSignUp = true;

  private userWithInvite: IUserWithInvite;
  private subscription = new Subscription();
  private firebaseAuth: IAuth;
  private firebaseAuthNamespace;
  private queryParams: Params;

  constructor(
    private router: Router,
    private messageService: MessageService,
    private themesService: ThemesService,
    private authenticationService: AuthenticationService,
    private usersService: UsersService,
    private analyticsService: AnalyticsService,
    private mixpanelService: MixpanelService,
    private intercomService: IntercomService,
    private dialogRef: DynamicDialogRef,
    private translateService: TranslateService,
    private hubsStore: HubsStore,
    private translationsService: TranslationsService,
    private route: ActivatedRoute,
    private location: Location,
    private eventsService: EventsService,
    private stripeService: StripeService,
    private brandsService: BrandsService,
    private devicesService: DevicesService,
    private userHubsService: UserHubsService,
    public usersStore: UsersStore,
    public appStore: AppStore,
  ) {
    this.tenantId = this.route.snapshot.queryParamMap.get('tenantId');
    this.firebaseAuth = Auth();
    this.firebaseAuthNamespace = FirebaseAuthNamespace;
    const navigation = this.router.getCurrentNavigation();
    const state = navigation?.extras.state as IUrlState | IInvitedUser;
    this.urlToNavigate = (state as IUrlState)?.url;
    this.userWithInvite = (state as IInvitedUser)?.invitedUser;

    this.languages = this.translationsService.getLanguages;
    this.currentLanguage = this.translationsService.getCurrentLanguage;

    this.selectLanguages = this.translationsService.getLanguages.map((l) => {
      const item = { name: this.translationsService.getTranslationSync('languages.' + l), code: l };
      if (this.currentLanguage === l) {
        this.selectedLanguage = item;
      }
      return item;
    });
  }

  get passwordErrorMessage(): string {
    return this.form.controls.password.value.length < 6 && !!this.form.controls.password.value
      ? 'signin.passwordNotValid'
      : 'signin.passwordRequired';
  }

  get logo(): string {
    if (this.themesService.activeTheme.name === 'dark') {
      return this.hubsStore.hub?.logoDark
        ? this.hubsStore.hub?.logoDark
        : this.themesService.systemAppearanceSettings?.logo;
    } else {
      return this.hubsStore.hub?.logoLight
        ? this.hubsStore.hub?.logoLight
        : this.themesService.systemAppearanceSettings?.logoLight;
    }
  }

  get authVerificationDesc(): string {
    return this.translateService.instant('signin.authVerificationDesc', {
      email: this.form.value.email,
    });
  }

  get authVerificationEmailSent(): string {
    return this.translateService.instant('signin.authVerificationEmailSent', {
      email: this.form.value.email,
    });
  }

  get signUpLink(): any[] {
    return this.hubsStore.hub ? [`/${this.hubsStore.useHubUrl}/signup`] : [`/signup`];
  }

  ngOnInit(): void {
    this.queryParams = this.route.snapshot.queryParams;
    if (this.hubsStore.hub?.isPrivate) {
      this.showSignUp = false;
    }
    this.initForm();
    if (this.userWithInvite) {
      this.updateForm();
    }

    this.getGeneralSystemSettings();

    this.route.queryParams.subscribe(
      (params: {
        verifiedEmail: string;
        redirectLink: string;
        preEmail: string;
        'book-code'?: string;
        'e-check-in'?: string;
      }) => {
        this.redirectLink = params.redirectLink || '';
        if (params['book-code']) {
          this.redirectLink += `?book-code=${params['book-code']}`;
        }
        if (params['e-check-in']) {
          this.redirectLink = `${this.hubsStore.useHubUrl}/event-check-in?book-code=${params['e-check-in']}`;
        }

        if (params && params.verifiedEmail && params.verifiedEmail.length) {
          this.addAccountVerifiedAtTimestamp = true;
          this.form.patchValue({ email: params.verifiedEmail });

          setTimeout(() => {
            this.messageService.add({
              severity: 'success',
              summary: this.translateService.instant('signin.authSuccessVerificationTitle'),
              detail: this.translateService.instant('signin.authSuccessVerificationDesc', {
                email: this.form.value.email,
              }),
              styleClass: 'custom-toast',
            });
          }, 500);
        } else if (params && params.preEmail && params.preEmail.length) {
          this.form.patchValue({ email: params.preEmail });
        }
      },
    );
  }

  enableResentVerificationButton(): void {
    this.resentVerificationBtnActive = true;
    this.resentVerificationBtnTimeLeft = 60;
  }

  enableResentVerificationButtonTimer(): void {
    this.resentVerificationBtnTimeLeft--;

    if (this.resentVerificationBtnTimeLeft < 0) {
      clearInterval(this.resentVerificationBtnInterval);
      this.resentVerificationBtnInterval = null;
      this.enableResentVerificationButton();
    }
  }

  async resendVerifyEmail(): Promise<void> {
    if (this.form.value.email) {
      await this.sendVerifyEmail(
        this.form.value.email,
        '',
        this.translationsService.getCurrentLanguage,
        // eslint-disable-next-line max-len
        `${this.hubsStore.environmentBaseUrl}${this.hubsStore.hub ? '/' + this.hubsStore.useHubUrl : ''}/signin?verifiedEmail=${this.form.value.email}&redirectLink=${
          this.queryParams['e-check-in']
            ? `event-check-in&book-code=${this.queryParams['e-check-in']}`
            : await this.generateRedirectLinkPage()
        }`,
      );
    }
  }

  async setLanguage(lang: any): Promise<void> {
    await this.translationsService.setLanguage(lang.code);
  }

  microsoftSSO(): void {
    const provider = new this.firebaseAuthNamespace.OAuthProvider('microsoft.com');
    provider.setCustomParameters({
      tenant: this.appStore.environment.microsoftSso.tenantId,
    });
    this.firebaseAuth.signInWithPopup(provider).then(async (data: any) => {
      const isAccountVerified = data.user.emailVerified;
      const firstName = data.additionalUserInfo.profile.givenName
        ? data.additionalUserInfo.profile.givenName
        : data.additionalUserInfo.profile.name.split(' ')[0];
      const lastName = data.additionalUserInfo.profile.surname
        ? data.additionalUserInfo.profile.surname
        : data.additionalUserInfo.profile.name.split(' ')[1];
      const userPayload = {
        id: data.user.uid,
        displayEmail: data.user.email,
        email: data.user.email.toLowerCase(),
        firstName,
        lastName,
        _firstName_: firstName.toLowerCase(),
        _lastName_: lastName.toLowerCase(),
        _company_: null,
        _position_: null,
        company: null,
        position: null,
        department: null,
        bio: null,
        title: null,
        addressSuffix: null,
        salutation: null,
        profileImage: null,
        coverImage: null,
        phone: null,
        country: null,
        city: null,
        street: null,
        postcode: null,
        website: null,
        facebook: null,
        instagram: null,
        youtube: null,
        linkedin: null,
        twitter: null,
        xing: null,
        calendly: null,
        acceptNews: false,
        allowChat: true,
        allowAppointment: true,
        isActive: true,
        brandId: null,
        hubId: null,
        role: 'user',
        tags: [],
        systemLanguage: this.translationsService.getCurrentLanguage,
        systemTheme: null,
        travelDocument: null,
        vegetarian: null,
        shoeSize: null,
        clothingSize: null,
        industry: null,
        subIndustry: null,
        areaOfActivity: null,
        optionalPosition: null,
        companySize: null,
        updatedAt: null,
        updatedBy: null,
        createdAt: Timestamp.now(),
        createdBy: data.user.uid,
        stripeId: null,
        hasEverSubscribed: false,
        createdHubsCount: 0,
      };

      let user = await this.usersService.getOne(userPayload.id);
      if (user) {
        if (!user.isActive) {
          await this.usersService.update(user.id, { isActive: true });
        }
      } else {
        user = await this.usersService.register(userPayload);
      }

      const token = localStorage.getItem('web_push_token') || null;

      if (token) {
        const device = await this.devicesService.getByWebPushToken(token);
        let deviceData = null;

        if (device) {
          deviceData = await this.devicesService.updateDevice(
            device.id,
            token,
            user.id,
            device.webPushPermission,
          );
        } else {
          deviceData = await this.devicesService.createDevice(token, user.id, true);
        }

        if (deviceData) {
          this.usersStore.setUserDevice({ ...device, ...deviceData });
        }
      }

      if (isAccountVerified) {
        this.usersStore.setUser(user);
        if (this.addAccountVerifiedAtTimestamp) {
          this.setUserVerificationTimestamp();
        }
        this.redirectOnPreviousPage();
      } else {
        this.sendVerifyEmail(
          data.user.email,
          firstName,
          userPayload.systemLanguage,
          // eslint-disable-next-line max-len
          `${this.hubsStore.environmentBaseUrl}/${this.hubsStore.hub ? '/' + this.hubsStore.useHubUrl : ''}/signin?verifiedEmail=${data.user.email}&redirectLink=${
            this.queryParams['e-check-in']
              ? `event-check-in&book-code=${this.queryParams['e-check-in']}`
              : await this.generateRedirectLinkPage()
          }`,
        );
        this.listenForUserActivation(data.user.email);
      }
    });
  }

  async signin(): Promise<void> {
    if (this.form.valid) {
      const form = this.form.value;
      this.signinLoading = true;
      if (this.generalSettings.isPrivate && !this.appStore.generalSystemSettings.enableEncryption) {
        const isEmailEXist = await this.usersService.getUserByEmail(form.email);
        if (!isEmailEXist) {
          const navigationExtras: NavigationExtras = {
            queryParams: { email: form.email },
            fragment: 'anchor',
          };
          this.router.navigate([this.hubsStore.useHubUrl, '/custom-screen'], navigationExtras);
          return;
        }
      }

      let numberOfAttempts = 0;
      let ip = null;

      try {
        ip = await this.authenticationService.getIpAddress();
      } catch (error) {
        console.log(error);
      }

      try {
        const attempts = await this.authenticationService.getFailedLoginAttempts(
          form.email,
          ip,
          moment(Date.now()).subtract(environment.lockoutTimeout.timeout, 'minutes').toDate(),
        );
        numberOfAttempts = attempts.docs.length;

        if ((attempts.docs as any[]).length >= 4) {
          this.signinLoading = false;
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('error'),
            detail: this.translateService.instant('signin.lockout'),
            styleClass: 'custom-toast',
          });
        } else {
          if (this.tenantId) {
            ChangeAuthTenant(this.tenantId);
          }
          const registerResponse = await this.authenticationService.signIn(
            form.email,
            form.password,
          );
          const userId = registerResponse.uid;
          const isAccountVerified = registerResponse.isVerified;
          const user = await this.usersService.getOne(userId);

          const token = localStorage.getItem('web_push_token') || null;

          if (token) {
            const device = await this.devicesService.getByWebPushToken(token);
            let deviceData = null;

            if (device) {
              deviceData = await this.devicesService.updateDevice(
                device.id,
                token,
                user.id,
                device.webPushPermission,
              );
            } else {
              deviceData = await this.devicesService.createDevice(token, user.id, true);
            }

            if (deviceData) {
              this.usersStore.setUserDevice({ ...device, ...deviceData });
            }
          }

          if (!isAccountVerified) {
            await this.sendVerifyEmail(
              form.email,
              '',
              this.translationsService.getCurrentLanguage,
              // eslint-disable-next-line max-len
              `${this.hubsStore.environmentBaseUrl}${this.hubsStore.hub ? '/' + this.hubsStore.useHubUrl : ''}/signin?verifiedEmail=${form.email}&redirectLink=${
                this.queryParams['e-check-in']
                  ? `event-check-in&book-code=${this.queryParams['e-check-in']}`
                  : await this.generateRedirectLinkPage()
              }`,
            );
            this.listenForUserActivation(form.email);
            this.signinLoading = false;
          } else {
            if (!user.isActive) {
              await this.usersService.update(userId, { isActive: true });
            }

            if (this.appStore.generalSystemSettings.isStripeConnected) {
              const stripeCustomerId = await this.stripeService.createOrUpdateCustomer(
                user.email,
                `${user.firstName} ${user.lastName}`,
              );
              this.usersService.update(user.id, { stripeId: stripeCustomerId });
              user.stripeId = stripeCustomerId;
            }

            this.usersStore.setUser(user);
            this.signinLoading = false;

            if (this.addAccountVerifiedAtTimestamp) {
              this.setUserVerificationTimestamp();
            }

            if (this.usersStore.user) {
              await this.usersService.getUserHubs(this.usersStore.user.id);
            }
            this.analyticsService.logLoginEvent();

            if (!this.isModalMode) {
              this.redirectOnPreviousPage();
            }

            // send event to mixpanel analytics
            this.mixpanelService.login(user, 'Manual Password');
            // send data to intercom
            this.intercomService.shutdown();
            this.intercomService.boot(this.appStore.environment.intercom.apiKey, user);

            // check if the user existing in current hub if not we need to add
            const currentHub = this.hubsStore.hub;
            const userHub: IUserHub = await this.userHubsService.getUserHub(currentHub.id, user.id);
            if (!userHub) {
              const newUserHub: IUserHub = {
                id: null,
                hubId: currentHub.id,
                userId: user.id,
                role: UserHubRoles.USER,
                _hub_title_: currentHub._title_,
                _firstName_: user._firstName_,
                _lastName_: user._lastName_,
                _company_: user._company_,
                _position_: user._position_,
                createdAt: Timestamp.now(),
                createdBy: user.id,
                updatedAt: null,
                updatedBy: null,
              };

              await this.userHubsService.addUserToHub(newUserHub);
            }
          }
        }
        this.dialogRef.close();
      } catch (error) {
        console.log(error);
        if (error.code.includes('wrong-password')) {
          await this.authenticationService.createFailedAttemptRec(form.email, ip);
        }

        this.signinLoading = false;

        if (error.code.includes('auth/too-many-requests')) {
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('error'),
            detail: this.translateService.instant('signin.lockout'),
            styleClass: 'custom-toast',
          });
        } else if (error.code.includes('auth/wrong-password')) {
          if (numberOfAttempts === 3) {
            this.messageService.add({
              severity: 'error',
              summary: this.translateService.instant('error'),
              detail: this.translateService.instant('signin.lockout'),
              styleClass: 'custom-toast',
            });
          } else {
            this.messageService.add({
              severity: 'error',
              summary: this.translateService.instant('error'),
              detail: this.translateService.instant('signin.attemptFailed', {
                count: numberOfAttempts === 0 ? 3 : 4 - (1 + numberOfAttempts),
              }),
              styleClass: 'custom-toast',
            });
          }
        } else {
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('error'),
            detail: this.translateService.instant('signin.' + error.code),
            styleClass: 'custom-toast',
          });
        }
      }
    }
  }

  async sendVerifyEmail(
    email: string,
    userName: string,
    userLang: string,
    link: string = null,
  ): Promise<void> {
    try {
      this.sendingVerificationEmailLoading = true;
      await this.authenticationService.sendVerifyEmail(
        email,
        userName,
        userLang,
        link
          ? link
          : `${this.hubsStore.environmentBaseUrl}${this.hubsStore.hub ? '/' + this.hubsStore.useHubUrl : ''}/signin?verifiedEmail=${email}`,
      );
      this.isVerificationEmailSent = true;
      setTimeout(() => {
        this.sendingVerificationEmailLoading = false;
        this.resentVerificationBtnActive = false;
        this.resentVerificationBtnInterval = setInterval(() => {
          this.enableResentVerificationButtonTimer();
        }, 1000);

        this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant('success'),
          detail: this.authVerificationEmailSent,
          styleClass: 'custom-toast',
        });
      }, 1000);
      this.authenticationService.signOut(false);
    } catch (error) {
      if (error.includes('TOO_MANY_ATTEMPTS_TRY_LATER')) {
        this.messageService.add({
          severity: 'error',
          sticky: true,
          summary: this.translateService.instant('error'),
          detail: this.translateService.instant('signin.TOO_MANY_ATTEMPTS_TRY_LATER'),
          styleClass: 'custom-toast',
        });
      }
      this.authenticationService.signOut(false);
      this.signinLoading = false;
    }
  }

  async navigateTo(): Promise<void> {
    const eventId: string = this.userWithInvite?.invite?.eventId;
    const type: InviteTypes = this.userWithInvite?.invite?.type;
    const brandId: string = this.userWithInvite?.brandId;

    if (eventId && InviteTypes.USER) {
      await this.updateUserEvent(eventId);
    }

    if (this.queryParams['e-check-in']) {
      this.router.navigate([this.hubsStore.useHubUrl, 'event-check-in'], {
        queryParams: { 'book-code': this.queryParams['e-check-in'] },
      });
      return;
    }

    switch (type) {
      case InviteTypes.BRAND_MEMBER:
        this.router.navigate([this.hubsStore.useHubUrl, `events`]);
        break;
      case InviteTypes.BRAND_OWNER:
        const brand: IBrand = await this.brandsService.getOne(brandId);
        this.router.navigate([`${this.hubsStore.useHubUrl}/mybrand/${brand.link}/dashboard`]);
        break;
      case InviteTypes.USER:
        this.router.navigate([`${this.hubsStore.useHubUrl}/events/${eventId}/register`]);
        break;
      default:
        this.router.navigate([`${this.hubsStore.useHubUrl}/events`]);
    }
  }

  onSignup(): void {
    if (this.isModalMode) {
      this.signUp.emit();
      return;
    }
    if (this.queryParams['e-check-in']) {
      this.router.navigate([`/${this.hubsStore.useHubUrl}/signup`], {
        queryParams: { 'e-check-in': this.queryParams['e-check-in'] },
      });
    } else {
      this.router.navigate([`/${this.hubsStore.useHubUrl}/signup`]);
    }
  }

  onBack(): void {
    this.location.back();
  }

  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private async listenForUserActivation(email: string): Promise<void> {
    if (!this.appStore.generalSystemSettings.enableEncryption && email) {
      this.usersService.listenForUserActivation(email).subscribe((isVerified) => {
        if (isVerified) {
          if (!this.queryParams['e-check-in']) {
            this.router.navigateByUrl(`/${this.hubsStore.useHubUrl}/dashboard`);
          } else {
            this.router.navigate([`/${this.hubsStore.useHubUrl}/event-check-in`], {
              queryParams: { 'book-code': this.queryParams['e-check-in'] },
            });
          }
        }
      });
    }
  }

  private initForm(): void {
    this.form = new UntypedFormGroup({
      email: new UntypedFormControl(null, [Validators.required, Validators.email]),
      password: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(UsersMinLengthValidators.password),
      ]),
      privacyPolicy: new UntypedFormControl(false, null),
      dataProtections: new UntypedFormControl(false, null),
    });
  }

  private updateForm(): void {
    this.form.patchValue({ ...this.userWithInvite });
  }

  private async getGeneralSystemSettings(): Promise<void> {
    this.generalSettings = await this.themesService.getSystemGeneralSettings();
  }

  // update user-event data for users with invite
  private async updateUserEvent(eventId: string): Promise<void> {
    const invitationUrlOpenedAt: Timestamp = await this.authenticationService.invitationUrlOpenedAt
      .pipe(take(1))
      .toPromise();
    const userEvent: IUserEvent = await this.usersService.getUserEvent(
      eventId,
      this.userWithInvite.id,
    );
    await this.eventsService.updateUserEvent({ ...userEvent, invitationUrlOpenedAt });
    this.authenticationService.updateInvitationOpenedDate(null);
  }

  // update user-event data for users with invite
  private async setUserVerificationTimestamp(): Promise<void> {
    await this.usersService.update(this.usersStore.user.id, { accountVerifiedAt: Timestamp.now() });
  }

  private redirectOnPreviousPage(): void {
    if (this.redirectLink) {
      if (this.redirectLink.includes('event-check-in')) {
        this.router.navigate([this.redirectLink.split('?')[0]], {
          queryParams: { 'book-code': this.redirectLink.split('=').at(-1) },
        });
      } else {
        this.router.navigate([this.redirectLink]);
      }
    } else if (!this.userWithInvite && !this.urlToNavigate) {
      this.router.navigate([`/${this.hubsStore.useHubUrl}/dashboard`]);
    } else if (this.urlToNavigate && this.urlToNavigate.includes('about')) {
      const hubUrl = this.urlToNavigate.split('/', 2).join('/');
      this.router.navigate([`${hubUrl}/dashboard`]);
    } else if (this.urlToNavigate) {
      this.router.navigate([this.urlToNavigate]);
    } else {
      this.navigateTo();
    }
  }

  private async generateRedirectLinkPage(): Promise<string> {
    if (!this.userWithInvite && !this.urlToNavigate) {
      return `${this.hubsStore.useHubUrl}/dashboard`;
    } else if (this.urlToNavigate && this.urlToNavigate.includes('about')) {
      const hubUrl = this.urlToNavigate.split('/', 2).join('/');
      return `${hubUrl}/dashboard`;
    } else if (this.urlToNavigate) {
      return this.urlToNavigate;
    } else {
      const eventId: string = this.userWithInvite?.invite?.eventId;
      const type: InviteTypes = this.userWithInvite?.invite?.type;
      const brandId: string = this.userWithInvite?.brandId;

      switch (type) {
        case InviteTypes.BRAND_MEMBER:
          return `${this.hubsStore.useHubUrl}/events`;
        case InviteTypes.BRAND_OWNER:
          const brand: IBrand = await this.brandsService.getOne(brandId);
          return `${this.hubsStore.useHubUrl}/mybrand/${brand.link}/dashboard`;
        case InviteTypes.USER:
          return `${this.hubsStore.useHubUrl}/events/${eventId}/register`;
        default:
          return `${this.hubsStore.useHubUrl}/events`;
      }
    }
  }

  onResetPassword() {
    if (this.isModalMode) {
      this.resetPassword.emit();
      return;
    } else {
      this.router.navigate([`/${this.hubsStore.useHubUrl}/resetpassword`]);
    }
  }

  onLogoClick() {
    if (this.hubsStore.hub?.isPrivate) {
      return;
    } else {
      this.router.navigate([`/${this.hubsStore.useHubUrl}/dashboard`]);
    }
  }
}
