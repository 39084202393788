<div class="create-course-coupon-container">
  <header>
    <h1 class="title">{{ title }}</h1>
  </header>

  <div class="c-mt-4 c-mb-8">
    <p-divider styleClass="custom-divider-horizontal"></p-divider>
  </div>

  <app-admin-coupon-form></app-admin-coupon-form>
</div>
