<ng-container *ngIf="loading">
  <app-loader></app-loader>
</ng-container>

<div
  *ngIf="!loading"
  class="event-page-html-editor-container">
  <div class="grid">
    <div class="col-8 left-side">
      <div class="header-section grid">
        <div class="col txt-a-r button-container">
          <button
            class="p-button-rounded p-button-text"
            pButton
            pRipple
            [icon]="'far fa-copy'"
            (click)="copy()"></button>
          <button
            class="p-button-text"
            pButton
            pRipple
            [label]="'adminEmailTemplate.mergeTagsBtn' | translate"
            [icon]="'far fa-brackets-curly'"
            (click)="mergeTags()"></button>
          <button
            class="preview"
            pButton
            pRipple
            [label]="'adminEventPageTemplate.previewBtn' | translate"
            (click)="preview()"></button>
        </div>
      </div>

      <div class="html-editor">
        <app-input-label
          labelType="required"
          text="adminEventPageTemplate.htmlPlaceholder">
        </app-input-label>
        <textarea
          class="custom-input-textarea resize-none custom-input-textarea-required textarea"
          pInputTextarea
          [formControl]="form.controls.html"
          placeholder="{{ 'adminEventPageTemplate.htmlPlaceholder' | translate }}">
        </textarea>
      </div>
    </div>

    <div class="col-4 right-side">
      <div class="form-section">
        <h3>
          {{
            (this.isEditing
              ? 'adminEventPageTemplate.editPageTitle'
              : 'adminEventPageTemplate.newPageTitle'
            ) | translate
          }}
        </h3>
        <form [formGroup]="form">
          <!-- Title input -->
          <div>
            <app-input-label
              labelType="required"
              for="title-input"
              text="adminEventPageTemplate.titleLabel">
            </app-input-label>
            <input
              class="custom-input-text custom-input-text-required"
              formControlName="title"
              id="title-input"
              pInputText
              type="text"
              placeholder="{{ 'adminEventPageTemplate.titleLabel' | translate }}" />
            <app-input-error-message
              [showMessage]="form.controls.title.invalid && form.controls.title.touched"
              errorMessage="{{ 'adminEventPageTemplate.titleRequired' | translate }}">
            </app-input-error-message>
          </div>

          <!-- Language input -->
          <div>
            <app-input-label
              labelType="required"
              for="language-input"
              text="adminEventPageTemplate.languageLabel">
            </app-input-label>
            <p-dropdown
              class="custom-dropdown custom-dropdown-required"
              panelStyleClass="custom-dropdown-panel"
              [options]="languages"
              optionLabel="name"
              formControlName="language"
              inputId="language-input"
              placeholder="{{ 'adminEventPageTemplate.languageLabel' | translate }}">
              <ng-template pTemplate="selectedItem">
                <span
                  class="flag-icon flag-icon-squared flag-icon-circle flag-icon-{{
                    form.controls.language.value.code === 'en'
                      ? 'gb'
                      : form.controls.language.value.code
                  }}">
                </span>
                {{ 'languages.' + form.controls.language.value?.code | translate }}
              </ng-template>
              <ng-template
                let-lang
                pTemplate="item">
                <span
                  class="flag-icon flag-icon-squared flag-icon-circle flag-icon-{{
                    lang.code === 'en' ? 'gb' : lang.code
                  }}">
                </span>
                {{ 'languages.' + lang.code | translate }}
              </ng-template>
            </p-dropdown>
            <app-input-error-message
              [showMessage]="form.controls.language.invalid && form.controls.language.touched"
              errorMessage="{{ 'adminEventPageTemplate.languageRequired' | translate }}">
            </app-input-error-message>
          </div>

          <!-- TODO: uncomment during adding logic for this property -->
          <!-- Audience input -->
          <!-- <div class="c-mb-4">
            <app-input-label
              for="audience-input"
              text="adminEventPageTemplate.audienceLabel">
            </app-input-label>
            <p-dropdown
              class="custom-dropdown"
              panelStyleClass="custom-dropdown-panel"
              [options]="audience"
              formControlName="audience"
              inputId="audience-input"
              placeholder="{{ 'adminEventPageTemplate.audienceLabel' | translate }}">
            </p-dropdown>
          </div> -->

          <!-- Custom icon class input -->
          <div class="c-mb-4">
            <app-input-label
              for="custom-icon-class-input"
              text="adminEventPageTemplate.customIconClassLabel">
            </app-input-label>
            <input
              class="custom-input-text"
              formControlName="customIconClass"
              id="custom-icon-class-input"
              pInputText
              type="text"
              placeholder="{{ 'adminEventPageTemplate.customIconClassLabel' | translate }}" />
          </div>

          <!-- Url input -->
          <!-- TODO: this is commented out for a while, and then we’ll decide what we’ll do -->
          <!-- <div class="c-mb-4">
            <app-input-label
              for="url-input"
              text="adminEventPageTemplate.urlLabel">
            </app-input-label>
            <div class="p-inputgroup custom-input-group">
              <span class="p-inputgroup-addon">URL</span>
              <input
                formControlName="url"
                id="url-input"
                pInputText
                type="text"
                placeholder="{{ 'adminEventPageTemplate.urlLabel' | translate }}">
              <i
                *ngIf="form.controls.url.value.length"
                class="far fa-copy"
                [pTooltip]="!copyIsClicked ? tooltipText : null"
                tooltipPosition="top"
                tooltipStyleClass="custom-tooltip"
                (mouseleave)="copyIsClicked = false"
                (click)="copyToClipboard()">
              </i>
              <div *ngIf="form.controls.url.value.length && copyIsClicked" class="tooltip-copied">
                {{ tooltipText }}
              </div>
            </div>
          </div> -->

          <!-- TODO: uncomment during adding logic for this property -->
          <!-- Status input -->
          <!-- <div class="form-field-input-switch-block px-0 c-mb-4">
            <p-inputSwitch
              class="custom-input-switch"
              formControlName="status"
              inputId="status-input">
            </p-inputSwitch>
            <app-input-label
              labelType="switch-checkbox"
              for="status-input"
              text="adminEventPageTemplate.statusLabel">
            </app-input-label>
          </div> -->

          <!-- TODO: uncomment during adding logic for this property -->
          <!-- Is mobile input -->
          <!-- <div class="form-field-input-switch-block px-0 c-mb-4">
            <p-inputSwitch
              class="custom-input-switch"
              formControlName="isMobile"
              inputId="is-mobile-input">
            </p-inputSwitch>
            <app-input-label
              labelType="switch-checkbox"
              for="is-mobile-input"
              text="adminEventPageTemplate.isMobileLabel">
            </app-input-label>
          </div> -->

          <div class="actions-container txt-a-r">
            <button
              class="primary-text-color"
              pButton
              type="button"
              iconPos="right"
              label="{{ 'adminEventPageTemplate.saveBtn' | translate }}"
              [icon]="saving ? 'pi pi-spin pi-spinner' : ''"
              [disabled]="form.invalid"
              (click)="save()"></button>
          </div>
        </form>
      </div>
    </div>
  </div>

  <p-sidebar
    [(visible)]="showMergeTags"
    [styleClass]="'email-merge-tags-sidebar'"
    [style]="{
      width: '40%',
      background: 'var(--background-sidebar)',
      color: 'var(--text-primary)',
      border: '0',
    }"
    position="right"
    baseZIndex="1">
    <app-event-page-merge-tags></app-event-page-merge-tags>
  </p-sidebar>
</div>
