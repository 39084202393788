import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

import { IBrandProduct } from 'src/app/core/models';
import { BrandProductsService } from 'src/app/core/services';

@Component({
  selector: 'app-brand-product',
  templateUrl: './brand-product.component.html',
  styleUrls: ['./brand-product.component.scss'],
})
export class BrandProductComponent implements OnInit {
  product: IBrandProduct;

  private readonly productId: string;

  constructor(
    private brandProductService: BrandProductsService,
    private location: Location,
    private route: ActivatedRoute,
  ) {
    this.productId = this.route.snapshot.params.productId;
  }

  async ngOnInit(): Promise<void> {
    this.product = await this.brandProductService.getOne(this.productId);
  }

  onConfirm(success: boolean) {
    if (success) {
      this.location.back();
    }
  }
}
