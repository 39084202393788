import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { IUserPresenceStatus, IUserPresenceStylesConfig } from 'src/app/core/models/user';
import { PresenceService } from 'src/app/core/services/presence/presence.service';

@Component({
  selector: 'app-presence',
  templateUrl: './presence.component.html',
  styleUrls: ['./presence.component.scss'],
})
export class PresenceComponent implements OnInit {
  @Input() userId: string;
  @Input() stylesConfig: IUserPresenceStylesConfig;

  presence$: Observable<IUserPresenceStatus>;

  constructor(private presence: PresenceService) {}

  ngOnInit(): void {
    this.presence$ = this.presence.getPresence(this.userId);
  }
}
