import { Component, OnInit, inject, signal } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

import { IHub, ITag, IUser } from 'src/app/core/models';
import { FormService, TagsService, UsersService } from 'src/app/core/services';
import { HubsStore, UsersStore } from 'src/app/core/stores';
import { SharedModule } from 'src/app/shared';
import {
  CoverPictureMediaFormComponent,
  SaveDiscardActionsComponent,
  SmallProfilePictureMediaFormComponent,
  ToastComponent,
} from 'src/app/standalone/shared';
import {
  CALENDLY_PROFILE_LINK_PATTERN,
  FACEBOOK_PROFILE_LINK_PATTERN,
  INSTAGRAM_PROFILE_LINK_PATTERN,
  LINKEDIN_PROFILE_LINK_PATTERN,
  TWITTER_PROFILE_LINK_PATTERN,
  VALID_LINK_PATTERN,
  XING_PROFILE_LINK_PATTERN,
  YOUTUBE_PROFILE_LINK_PATTERN,
  asyncDelay,
} from 'src/app/core/utils';

@Component({
  selector: 'app-user-appearance',
  standalone: true,
  imports: [
    SharedModule,
    SaveDiscardActionsComponent,
    SmallProfilePictureMediaFormComponent,
    CoverPictureMediaFormComponent,
    ToastComponent,
  ],
  templateUrl: './user-appearance.component.html',
  styleUrl: './user-appearance.component.scss',
})
export class UserAppearanceComponent implements OnInit {
  loading = signal(true);
  isUpdating = signal(false);
  form: FormGroup;
  tags = signal<ITag[]>(null);

  private usersStore = inject(UsersStore);
  private usersService = inject(UsersService);
  private fb = inject(FormBuilder);
  private formService = inject(FormService);
  private messageService = inject(MessageService);
  private translateService = inject(TranslateService);
  private tagsService = inject(TagsService);
  private hubsStore = inject(HubsStore);

  get user(): IUser {
    return this.usersStore.adminUser;
  }

  get hub(): IHub {
    return this.hubsStore.hub;
  }

  get canUpdate(): boolean {
    return (
      !this.isUpdating() && this.form.valid && this.form.dirty && this.formService.isValueChanged()
    );
  }

  async ngOnInit(): Promise<void> {
    this.createForm();
    this.updateForm();
    this.formService.setForm(this.form);
    const hubTagIds: string[] = await this.tagsService.getHubTagsIdsArray(this.hub.id);
    const tags: ITag[] = await this.tagsService.getByIdsOrderedByTitle(hubTagIds);
    this.tags.set(tags);
    this.loading.set(false);
  }

  setProfileImageValue(value: File): void {
    this.form.controls.profileImage.setValue(value);
    this.makeFormControlDirty('profileImage');
  }

  setCoverImageValue(value: File): void {
    this.form.controls.coverImage.setValue(value);
    this.makeFormControlDirty('coverImage');
  }

  selectTags(tagIds: string[]): void {
    this.form.patchValue({ tags: tagIds });
    this.makeFormControlDirty('tags');
  }

  onDiscard(): void {
    this.updateForm();
  }

  async onConfirm(): Promise<void> {
    try {
      this.isUpdating.set(true);
      await asyncDelay(1);
      await this.updateUser();
      this.formService.setForm(this.form);
    } catch (error) {
      console.error(error);
    }
  }

  private createForm(): void {
    this.form = this.fb.group({
      profileImage: null,
      coverImage: null,
      bio: null,
      tags: null,
      website: [null, Validators.pattern(VALID_LINK_PATTERN)],
      linkedin: [
        null,
        [Validators.pattern(VALID_LINK_PATTERN), Validators.pattern(LINKEDIN_PROFILE_LINK_PATTERN)],
      ],
      twitter: [
        null,
        [Validators.pattern(VALID_LINK_PATTERN), Validators.pattern(TWITTER_PROFILE_LINK_PATTERN)],
      ],
      facebook: [
        null,
        [Validators.pattern(VALID_LINK_PATTERN), Validators.pattern(FACEBOOK_PROFILE_LINK_PATTERN)],
      ],
      instagram: [
        null,
        [
          Validators.pattern(VALID_LINK_PATTERN),
          Validators.pattern(INSTAGRAM_PROFILE_LINK_PATTERN),
        ],
      ],
      youtube: [
        null,
        [Validators.pattern(VALID_LINK_PATTERN), Validators.pattern(YOUTUBE_PROFILE_LINK_PATTERN)],
      ],
      xing: [
        null,
        [Validators.pattern(VALID_LINK_PATTERN), Validators.pattern(XING_PROFILE_LINK_PATTERN)],
      ],
      calendly: [
        null,
        [Validators.pattern(VALID_LINK_PATTERN), Validators.pattern(CALENDLY_PROFILE_LINK_PATTERN)],
      ],
      allowChat: null,
      allowAppointment: null,
    });
  }

  private updateForm(): void {
    this.form.patchValue({ ...this.user });
  }

  private makeFormControlDirty(formControlName: string): void {
    this.form.controls[formControlName].markAsDirty();
  }

  private async updateUser(): Promise<void> {
    this.isUpdating.set(true);

    try {
      const formValues = this.form.getRawValue();
      const newUser: IUser = {
        ...this.user,
        ...formValues,
      };

      await this.usersService.update(this.user.id, newUser);
      this.usersStore.setAdminUser(newUser);
      this.showToastMessage('success', 'adminUserProfile.userSuccessfullyUpdated');
    } catch (error) {
      console.log(error);
      this.showToastMessage('error', 'adminUserProfile.errorUpdateUser');
    } finally {
      this.isUpdating.set(false);
    }
  }

  private showToastMessage(severity: 'success' | 'error', detail: string): void {
    this.messageService.add({
      severity,
      summary: this.translateService.instant(severity),
      detail: this.translateService.instant(detail),
      styleClass: 'custom-toast',
    });
  }
}
