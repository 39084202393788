import { Component, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject, merge, takeUntil, tap } from 'rxjs';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

import { IEvent, ISession } from 'src/app/core/models';
import { FormService, SessionsService } from 'src/app/core/services';
import { EventsStore, SessionsStore } from 'src/app/core/stores';
import { asyncDelay, updateTime } from 'src/app/core/utils';
import { Timestamp } from 'src/app/firebase';
import { SharedModule } from 'src/app/shared';
import { SaveDiscardActionsComponent, ToastComponent } from 'src/app/standalone/shared';

@Component({
  selector: 'app-event-session-registration-settings',
  standalone: true,
  imports: [SharedModule, SaveDiscardActionsComponent, ToastComponent],
  templateUrl: './event-session-registration-settings.component.html',
  styleUrl: './event-session-registration-settings.component.scss',
})
export class EventSessionRegistrationSettingsComponent implements OnInit, OnDestroy {
  loading = signal<boolean>(true);
  isUpdating = signal<boolean>(false);
  form: FormGroup;
  sessions = signal<ISession[]>(null);

  #unsubscribe$ = new Subject<void>();
  #fb = inject(FormBuilder);
  #formService = inject(FormService);
  #eventsStore = inject(EventsStore);
  #sessionsStore = inject(SessionsStore);
  #sessionsService = inject(SessionsService);
  #messageService = inject(MessageService);
  #translateService = inject(TranslateService);

  get event(): IEvent {
    return this.#eventsStore.adminEvent;
  }

  get session(): ISession {
    return this.#sessionsStore.adminSession;
  }

  get canUpdate(): boolean {
    return (
      !this.isUpdating() && this.form.valid && this.form.dirty && this.#formService.isValueChanged()
    );
  }

  async ngOnInit(): Promise<void> {
    this.createForm();
    this.updateForm();
    this.#formService.setForm(this.form);

    const sessions: ISession[] = await this.#sessionsService.getEventSessions(this.event.id);
    this.sessions.set(
      sessions.filter((sessionResponse: ISession) => sessionResponse.id !== this.session?.id),
    );

    this.loading.set(false);

    const sessionRegistrationChanges$ = this.form.controls.isSessionRegistration.valueChanges.pipe(
      tap((value: boolean) => {
        if (value) {
          this.form.controls.availability.enable();
          this.form.controls.isClosingSessionRegistration.enable();
          this.form.controls.sessionsRelation.enable();
          this.form.controls.isCancellingEnabled.enable();
        } else {
          this.form.controls.availability.disable();
          this.form.controls.isClosingSessionRegistration.disable();
          this.form.controls.sessionsRelation.disable();
          this.form.controls.closingDate.disable();
          this.form.controls.closingTime.disable();
          this.form.controls.isCancellingEnabled.disable();
        }

        if (
          value &&
          this.form.controls.isClosingSessionRegistration.enable &&
          this.form.controls.isClosingSessionRegistration.value
        ) {
          this.form.controls.closingDate.enable();
          this.form.controls.closingTime.enable();
        }
      }),
    );

    const closingRegistrationChanges$ =
      this.form.controls.isClosingSessionRegistration.valueChanges.pipe(
        tap((value: boolean) => {
          if (value) {
            this.form.controls.closingDate.enable();
            this.form.controls.closingTime.enable();
          } else {
            this.form.controls.closingDate.disable();
            this.form.controls.closingTime.disable();
          }
        }),
      );

    merge(sessionRegistrationChanges$, closingRegistrationChanges$)
      .pipe(takeUntil(this.#unsubscribe$))
      .subscribe();
  }

  onDiscard(): void {
    this.updateForm();
  }

  async onConfirm(): Promise<void> {
    try {
      this.isUpdating.set(true);
      await asyncDelay(1);
      await this.updateSession();
      this.#formService.setForm(this.form);
    } catch (error) {
      console.error(error);
    }
  }

  ngOnDestroy(): void {
    this.#unsubscribe$.next();
    this.#unsubscribe$.complete();
  }

  private createForm(): void {
    this.form = this.#fb.group({
      isSessionRegistration: [null],
      isCancellingEnabled: [null],
      availability: [{ value: null, disabled: !this.session?.isSessionRegistration }],
      isClosingSessionRegistration: [
        { value: null, disabled: !this.session?.isSessionRegistration },
      ],
      closingDate: [
        {
          value: null,
          disabled:
            !this.session?.isSessionRegistration || !this.session?.isClosingSessionRegistration,
        },
        Validators.required,
      ],
      closingTime: [
        {
          value: null,
          disabled:
            !this.session?.isSessionRegistration || !this.session?.isClosingSessionRegistration,
        },
        Validators.required,
      ],
      sessionsRelation: [{ value: null, disabled: !this.session?.isSessionRegistration }],
    });
  }

  private updateForm(): void {
    this.form.patchValue({
      ...this.session,
      closingDate: this.session?.closingDate ? this.session.closingDate.toDate() : null,
      closingTime: this.session?.closingDate ? this.session.closingDate.toDate() : null,
    });
  }

  private async updateSession(): Promise<void> {
    this.isUpdating.set(true);
    const { closingDate, closingTime, ...others } = this.form.getRawValue();

    const sessionForUpdate: ISession = {
      ...this.session,
      ...others,
      closingDate:
        !!closingDate && !!closingTime
          ? Timestamp.fromMillis(updateTime(closingDate, closingTime).toDate().getTime())
          : null,
    };

    try {
      await this.#sessionsService.update(this.event.id, sessionForUpdate.id, sessionForUpdate);
      this.#sessionsStore.setAdminSession({ ...sessionForUpdate });
      this.isUpdating.set(false);
      this.showToastMessage('success', 'adminSession.sessionSuccessfullyUpdated');
    } catch (error) {
      console.log(error);
      this.isUpdating.set(false);
      this.showToastMessage('error', 'adminSession.errorUpdateSession');
    }
  }

  private showToastMessage(severity: 'success' | 'error', detail: string): void {
    this.#messageService.add({
      severity: severity,
      summary: this.#translateService.instant(severity),
      detail: this.#translateService.instant(detail),
      styleClass: 'custom-toast',
    });
  }
}
