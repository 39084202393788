import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[epTemplateKey]',
})
export class EPTemplateKeyDirective {
  @Input('epTemplateKey') identifier: string;

  constructor(private templateRef: TemplateRef<any>) {}

  ngOnInit(): void {
    this.templateRef.elementRef.nativeElement['key'] = this.identifier;
  }
}
