<div class="chat-conversation">
  <div
    #messagesContainer
    class="messages-container">
    <div class="messages">
      <ng-container *ngFor="let m of messages$ | async; trackBy: trackByFn">
        <div
          class="message incoming-message"
          *ngIf="isIncomingMessage(m)">
          <div>
            <app-user-photo
              class="user"
              [user]="m.user"></app-user-photo>
            <div class="message-content">
              <ng-container *ngIf="m.attachment">
                <a
                  [href]="m.attachment.url"
                  target="_blank">
                  <ng-container [ngSwitch]="m.attachment.type">
                    <ng-container *ngSwitchCase="'image'">
                      <img
                        class="attachment-image"
                        [src]="m.attachment.url | imageSize" />
                    </ng-container>
                    <ng-container *ngSwitchCase="'pdf'">
                      <div class="attachment-pdf">
                        <i class="far fa-file-pdf"></i> {{ m.attachment.name }}
                      </div>
                    </ng-container>
                  </ng-container>
                </a>
              </ng-container>
              <div
                *ngIf="m.message"
                class="text"
                [innerHTML]="m.message | linkify"></div>
            </div>
            <div
              class="like"
              (click)="onToggleMessageLike(m)"
              [ngClass]="{ 'like--liked': m.liked }">
              <i class="far fa-thumbs-up"></i>
            </div>
          </div>
          <div class="date">{{ dateToString(m.timestamp) }}</div>
        </div>
        <div
          class="message outgoing-message"
          *ngIf="isOutgoingMessage(m)">
          <div class="outgoing-message__container">
            <div
              *ngIf="m.liked"
              class="outgoing-message__like">
              <i class="far fa-thumbs-up outgoing-message--size"></i>
            </div>
            <div class="message-content">
              <ng-container *ngIf="m.attachment">
                <a
                  [href]="m.attachment.url"
                  target="_blank">
                  <ng-container [ngSwitch]="m.attachment.type">
                    <ng-container *ngSwitchCase="'image'">
                      <img
                        class="attachment-image"
                        [src]="m.attachment.url | imageSize" />
                    </ng-container>
                    <ng-container *ngSwitchCase="'pdf'">
                      <div class="attachment-pdf">
                        <i class="far fa-file-pdf"></i> {{ m.attachment.name }}
                      </div>
                    </ng-container>
                  </ng-container>
                </a>
              </ng-container>
              <div
                *ngIf="m.message"
                class="text"
                [innerHTML]="m.message | linkify"></div>
            </div>
          </div>
          <div class="date">{{ dateToString(m.timestamp) }}</div>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="reply">
    <app-chat-reply
      [attachment]="attachment"
      [placeholder]="'chat.chatConversation.replyInputPlaceholder' | translate"
      (sendMessage)="onSendMessage($event)"
      (loadAttachment)="onLoadAttachment()"></app-chat-reply>
  </div>
</div>

<app-chat-load-attachment
  *ngIf="isUploadAttachmentOpen"
  (dismiss)="onDismissLoadAttachment($event)"
  [conversationId]="chatConversationId"></app-chat-load-attachment>
