<div class="order-dialog-container">
  <div class="px-4 py-8 md:px-6 lg:px-8">
    <span class="text-xl">
      {{ 'orderConfirmationDialog.orderConfirmation' | translate }}
    </span>
    <div class="font-bold text-4xl my-2">
      {{ 'orderConfirmationDialog.thankYouForYourOrder' | translate }} 🚀
    </div>
    <p class="text-xl mt-0 mb-4 p-0">
      {{ 'orderConfirmationDialog.bookingCodesWereSent' | translate }}
      {{ orders[0][0].userEmail }}
    </p>
    <div class="gradient"></div>

    <div class="py-5 mb-3 sm:mb-0">
      <span class="font-medium text-xl mr-2">
        {{ 'orderConfirmationDialog.orderNumber' | translate }}:
      </span>
      <span class="font-medium text-xl">{{ orders[0][0].orderId }}</span>
    </div>

    <p class="text-sm mt-0 mb-4 p-0">
      {{ 'orderConfirmationDialog.bookingCodeAvailableText' | translate }}
    </p>

    <p class="text-sm mt-0 mb-4 p-0">
      {{ 'orderConfirmationDialog.bookingCodesText' | translate }}
    </p>

    <p class="text-sm mt-0 mb-4 p-0">
      {{ 'orderConfirmationDialog.attention' | translate }}
    </p>

    <div
      *ngFor="let singleTicketOrders of orders; trackBy: trackByFn"
      class="w-full tickets-container mb-5">
      <div class="text-xl mb-3 text-left font-medium">
        {{ singleTicketOrders[0].ticketName }}
      </div>
      <div
        *ngFor="let singleTicketOrder of singleTicketOrders; trackBy: trackByFn"
        class="bg-gray-900 p-3 flex align-items-center cursor-pointer booking-code"
        [ngClass]="{ 'booking-code-active': singleTicketOrder.id === selectedOrder?.value?.id }"
        (click)="onSelectBookingCode(singleTicketOrder)">
        <p-radioButton
          class="custom-input-radio"
          name="groupOfBookingCodes"
          [value]="singleTicketOrder"
          [formControl]="selectedOrder"
          styleClass="mr-3">
        </p-radioButton>
        <div class="mr-4 md:mr-8">
          <div class="font-medium mb-1">
            {{ singleTicketOrder.bookingCode }}
          </div>
          <span class="text-sm">
            {{ 'orderConfirmationDialog.bookingCode' | translate }}
          </span>
        </div>
        <div
          *ngIf="usersStore.user || singleTicketOrder.orderedBy"
          class="p-1 ml-auto flex flex-nowrap">
          <button
            (click)="downloadPdf(singleTicketOrder)"
            class="pdf-button p-button-outlined"
            pButton
            pRipple
            label="{{ 'orderConfirmationDialog.pdf' | translate }}"
            icon="pi pi-print"></button>
        </div>
      </div>
    </div>

    <div
      *ngIf="selectedOrder.value"
      class="text-right">
      <button
        class="primary-text-color"
        pButton
        label="{{ 'orderConfirmationDialog.redeemCode' | translate }}"
        type="button"
        (click)="onRedeemCode()"></button>
    </div>
  </div>
</div>
