import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

import { IEvent } from 'src/app/core/models';
import { EventsService, FormService } from 'src/app/core/services';
import { EventsStore } from 'src/app/core/stores';
import { asyncDelay } from 'src/app/core/utils';
import { SharedModule } from 'src/app/shared';
import {
  BigProfilePictureMediaFormComponent,
  IconMediaFormComponent,
  LogoMediaFormComponent,
  SaveDiscardActionsComponent,
  ToastComponent,
} from 'src/app/standalone/shared';

@Component({
  selector: 'app-event-design',
  standalone: true,
  imports: [
    SharedModule,
    SaveDiscardActionsComponent,
    LogoMediaFormComponent,
    IconMediaFormComponent,
    BigProfilePictureMediaFormComponent,
    ToastComponent,
  ],
  templateUrl: './event-design.component.html',
  styleUrl: './event-design.component.scss',
})
export class EventDesignComponent implements OnInit {
  form: FormGroup;
  loading = true;
  isUpdating = false;

  constructor(
    private eventsStore: EventsStore,
    private fb: FormBuilder,
    private formService: FormService,
    private eventsService: EventsService,
    private messageService: MessageService,
    private translateService: TranslateService,
  ) {}

  get event(): IEvent {
    return this.eventsStore.adminEvent;
  }

  get canUpdate(): boolean {
    return (
      !this.isUpdating && this.form.valid && this.form.dirty && this.formService.isValueChanged()
    );
  }

  ngOnInit(): void {
    this.createForm();
    this.updateForm();
    this.formService.setForm(this.form);
    this.loading = false;
  }

  setLogoDarkValue(value: File): void {
    this.form.controls.logoDark.setValue(value);
    this.makeFormControlDirty('logoDark');
  }

  setLogoLightValue(value: File): void {
    this.form.controls.logoLight.setValue(value);
    this.makeFormControlDirty('logoLight');
  }

  setIconValue(value: File): void {
    this.form.controls.icon.setValue(value);
    this.makeFormControlDirty('icon');
  }

  setBannerValue(value: File): void {
    this.form.controls.banner.setValue(value);
    this.makeFormControlDirty('banner');
  }

  setFeaturedImageValue(value: File): void {
    this.form.controls.featuredImage.setValue(value);
    this.makeFormControlDirty('featuredImage');
  }

  onSelectPrimaryColor(value: string): void {
    this.form.controls.primaryColor.setValue(value);
    this.makeFormControlDirty('primaryColor');
  }

  onSelectAccentColor(value: string): void {
    this.form.controls.accentColor.setValue(value);
    this.makeFormControlDirty('accentColor');
  }

  onDiscard(): void {
    this.updateForm();
  }

  async onConfirm(): Promise<void> {
    try {
      this.isUpdating = true;
      await asyncDelay(1);
      await this.updateEvent();
      this.formService.setForm(this.form);
    } catch (error) {
      console.error(error);
    }
  }

  async updateEvent(): Promise<void> {
    try {
      this.isUpdating = true;
      const value = this.form.getRawValue();
      const eventForUpdate: IEvent = {
        ...this.event,
        ...value,
      };
      const updatedEvent: IEvent = await this.eventsService.update(this.event.id, eventForUpdate);
      const newEvent = { ...this.eventsStore.adminEvent, ...updatedEvent };
      this.eventsStore.setAdminEvent(newEvent);
      this.isUpdating = false;

      this.messageService.add({
        severity: 'success',
        summary: this.translateService.instant('success'),
        detail: this.translateService.instant('adminEventSettings.successUpdateEvent'),
        styleClass: 'custom-toast',
      });
    } catch (error) {
      console.error(error);
      this.isUpdating = false;
    }
  }

  private createForm(): void {
    this.form = this.fb.group({
      logoDark: [null],
      logoLight: [null],
      icon: [null],
      banner: [null],
      featuredImage: [null],
      primaryColor: [null],
      accentColor: [null],
    });
  }

  private updateForm(): void {
    this.form.patchValue({
      ...this.event,
    });
  }

  private makeFormControlDirty(formControlName: string): void {
    this.form.controls[formControlName].markAsDirty();
  }
}
