import { Injectable, Signal, signal } from '@angular/core';
import { makeAutoObservable } from 'mobx';

import { ICourseTicket, ITicket } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class TicketsStore {
  adminTicket: ITicket = null;
  adminCourseTicket: ICourseTicket = null;

  #courseTicket = signal<ICourseTicket>(null);
  courseTicket: Signal<ICourseTicket> = this.#courseTicket.asReadonly();

  constructor() {
    makeAutoObservable(this);
  }

  setAdminTicket(ticket: ITicket): void {
    this.adminTicket = ticket;
  }

  unsetAdminTicket() {
    this.adminTicket = null;
  }

  setAdminCourseTicket(ticket: ICourseTicket): void {
    this.adminCourseTicket = ticket;
  }

  setCourseTicket(ticket: ICourseTicket): void {
    this.#courseTicket.set(ticket);
  }

  unsetAdminCourseTicket() {
    this.adminCourseTicket = null;
  }

  unSetCourseTicket(): void {
    this.#courseTicket.set(null);
  }
}
