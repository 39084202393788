<ng-container *ngIf="loading">
  <app-loader></app-loader>
</ng-container>

<div
  *ngIf="!loading"
  class="page-container">
  <header class="c-mb-4">
    <h1 class="admin-page-title">{{ 'adminEvent.menuItemAddOns' | translate }}</h1>
  </header>

  <p-divider styleClass="custom-divider-horizontal"></p-divider>

  <form [formGroup]="form">
    <!-- Eventbrite integration block -->
    <div class="form-block c-my-9">
      <div class="left-side">
        <h2 class="title">{{ 'adminEventSettings.eventbriteTitle' | translate }}</h2>
        <p class="description">{{ 'adminEventSettings.eventbriteDescription' | translate }}</p>
      </div>
      <div class="right-side">
        <!-- Eventbrite sync input -->
        <div class="form-field-input-switch-block c-mb-4">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="eventbriteSync"
            inputId="eventbrite-sync-input">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="eventbrite-sync-input"
            text="adminEventSettings.eventbriteSync">
          </app-input-label>
        </div>

        <!-- Eventbrite event id input -->
        <div class="form-field-block c-mb-4">
          <app-input-label
            for="eventbrite-event-id-input"
            text="adminEventSettings.eventbriteEventId">
          </app-input-label>
          <input
            class="custom-input-text"
            formControlName="eventbriteEventId"
            id="eventbrite-event-id-input"
            pInputText
            type="text"
            epTrimSpaces
            placeholder="{{ 'adminEventSettings.eventbriteEventId' | translate }}" />
        </div>

        <!-- Eventbrite token input -->
        <div class="form-field-block c-mb-4">
          <app-input-label
            for="eventbrite-token-input"
            text="adminEventSettings.eventbriteToken">
          </app-input-label>
          <input
            class="custom-input-text"
            formControlName="eventbriteToken"
            id="eventbrite-token-input"
            pInputText
            type="text"
            epTrimSpaces
            placeholder="{{ 'adminEventSettings.eventbriteToken' | translate }}" />
        </div>

        <!-- Eventbrite button input -->
        <div class="form-field-block">
          <app-input-label text="adminEventSettings.eventbriteEmbedCode"></app-input-label>
          <textarea
            class="custom-input-textarea resize-none"
            [rows]="4"
            pInputTextarea
            formControlName="eventbriteButton"
            autoResize="autoResize"
            placeholder="{{ 'adminEventSettings.eventbriteEmbedCode' | translate }}">
          </textarea>
        </div>

        <div
          *ngIf="form.controls.eventbriteSync.value"
          class="text-right c-mt-4 c-px-8">
          <button
            class="primary-text-color"
            [class.p-button-success]="isSyncing"
            [icon]="isSyncing ? 'pi pi-spin pi-spinner' : 'far fa-rotate-right'"
            pButton
            pRipple
            type="button"
            [label]="'adminEventSettings.eventbriteSyncBtnLabel' | translate"
            [disabled]="isSyncBtnDisabled"
            (click)="onSyncEventBrite()"></button>
        </div>
      </div>
    </div>

    <p-divider styleClass="custom-divider-horizontal"></p-divider>

    <!-- Appointment scheduling block -->
    <div class="form-block c-mt-9 c-mb-4">
      <div class="left-side">
        <h2 class="title">{{ 'adminEventSettings.appointmentTitle' | translate }}</h2>
        <p class="description">{{ 'adminEventSettings.appointmentDescription' | translate }}</p>
      </div>
      <div class="right-side">
        <!-- Appointment feature input -->
        <div class="form-field-input-switch-block c-mb-8">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="appointmentFeature"
            inputId="appointment-feature-input">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="appointment-feature-input"
            text="adminEventSettings.appointment">
          </app-input-label>
        </div>

        <p-divider styleClass="custom-divider-horizontal"></p-divider>

        <h2 class="appointment-settings-title c-mt-8 c-px-8">
          {{ 'adminEventSettings.appointmentSettingsTitle' | translate }}
        </h2>

        <!-- Appointment attendees status input -->
        <div class="form-field-input-switch-block c-mb-4">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="appointmentAttendeesStatus"
            inputId="appointment-attendees-status-input">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="appointment-attendees-status-input"
            text="adminEventSettings.appointmentAttendeesLabel">
          </app-input-label>
        </div>

        <!-- Appointment speakers status input -->
        <div class="form-field-input-switch-block c-mb-4">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="appointmentSpeakersStatus"
            inputId="appointment-speakers-status-input">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="appointment-speakers-status-input"
            text="adminEventSettings.appointmentSpeakersLabel">
          </app-input-label>
        </div>

        <!-- Appointment brand people status input -->
        <div
          class="form-field-input-switch-block c-mb-8"
          *ngIf="shouldShowBrands">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="appointmentBrandPeopleStatus"
            inputId="appointment-brand-people-status-input">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="appointment-brand-people-status-input"
            text="adminEventSettings.appointmentBrandPeopleLabel">
          </app-input-label>
        </div>

        <p-divider styleClass="custom-divider-horizontal"></p-divider>

        <!-- Online video meeting status input -->
        <div class="form-field-input-switch-block c-mt-8 c-mb-4">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="onlineVideoMeetingStatus"
            inputId="online-video-meeting-status-status-input">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="online-video-meeting-status-status-input"
            text="adminEventSettings.onlineVideoMeetingLabel">
          </app-input-label>
        </div>

        <!-- Duration input -->
        <div class="form-field-block">
          <app-input-label
            [labelType]="form.controls.duration.hasError('required') ? 'required' : 'usual'"
            for="duration-input"
            text="adminEventSettings.durationLabel">
          </app-input-label>
          <p-inputNumber
            class="custom-input-number"
            [class.custom-input-number-required]="form.controls.duration.hasError('required')"
            inputId="duration-input"
            formControlName="duration"
            suffix="{{ 'shared.minutes' | translate }}"
            min="1"
            max="45"
            placeholder="{{ 'adminEventSettings.durationLabel' | translate }}">
          </p-inputNumber>
          <p class="input-note">
            {{ 'adminEventSettings.durationNote' | translate }}
          </p>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="save-discard-actions-container">
  <app-save-discard-actions
    [isShow]="canUpdate"
    [isLoading]="isUpdating"
    (discard)="onDiscard()"
    (confirm)="onConfirm()">
  </app-save-discard-actions>
</div>

<p-toast>
  <ng-template
    let-message
    pTemplate="message">
    <app-toast
      [severity]="message.severity"
      [summary]="message.summary"
      [detail]="message.detail">
    </app-toast>
  </ng-template>
</p-toast>
