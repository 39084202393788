<ng-container *ngIf="loading">
  <app-loader></app-loader>
</ng-container>

<div
  *ngIf="!loading"
  class="container">
  <form [formGroup]="form">
    <!-- Name block -->
    <div class="form-block c-mb-9">
      <div class="left-side">
        <h2 class="title">{{ 'adminSystemSettings.nameLabel' | translate }}</h2>
        <p class="description">{{ 'adminSystemSettings.nameSubtitle' | translate }}</p>
      </div>
      <div class="right-side">
        <!-- Name input -->
        <div class="form-field-block c-mb-4">
          <app-input-label
            for="name-input"
            text="adminSystemSettings.titleLabel">
          </app-input-label>
          <input
            class="custom-input-text"
            formControlName="name"
            id="name-input"
            pInputText
            type="text"
            placeholder="{{ 'adminSystemSettings.titleLabel' | translate }}" />
        </div>

        <!-- Slogan input -->
        <div class="form-field-block c-mb-4">
          <app-input-label
            for="slogan-input"
            text="adminSystemSettings.sloganLabel">
          </app-input-label>
          <input
            class="custom-input-text"
            formControlName="slogan"
            id="slogan-input"
            pInputText
            type="text"
            placeholder="{{ 'adminSystemSettings.sloganLabel' | translate }}" />
        </div>

        <!-- Copyright input -->
        <div class="form-field-block">
          <app-input-label
            for="copyright-input"
            text="adminSystemSettings.copyright">
          </app-input-label>
          <input
            class="custom-input-text"
            formControlName="copyright"
            id="copyright-input"
            pInputText
            type="text"
            placeholder="{{ 'adminSystemSettings.copyright' | translate }}" />
        </div>

        <!-- App owner input -->
        <div
          *ngIf="freshUser?.role === 'instanceOwner' || freshUser?.role === 'softwareDeveloper'"
          class="form-field-block c-mt-4">
          <app-input-label
            for="app-owner-input"
            text="adminSystemSettings.appOwnerLabel">
          </app-input-label>
          <input
            class="custom-input-text"
            formControlName="appOwner"
            id="app-owner-input"
            pInputText
            type="text"
            placeholder="{{ 'adminSystemSettings.appOwnerLabel' | translate }}" />
        </div>
      </div>
    </div>

    <p-divider styleClass="custom-divider-horizontal"></p-divider>

    <!-- Design block -->
    <div class="form-block c-my-9">
      <div class="left-side">
        <h2 class="title">{{ 'adminSystemSettings.designLabel' | translate }}</h2>
        <p class="description">{{ 'adminSystemSettings.designSubtitle' | translate }}</p>
      </div>
      <div class="right-side">
        <!-- Logo image -->
        <div class="form-field-block c-mb-4">
          <app-logo-media-form
            titleMediaForm="adminSystemSettings.designLogoTitleDark"
            description="adminSystemSettings.designLogoDesc"
            btnConfirm="upload"
            btnCancel="clear"
            [imgValue]="form.controls.logo.value"
            (changeValue)="setLogoValue($event)">
          </app-logo-media-form>
        </div>

        <!-- Logo light image -->
        <div class="form-field-block c-mb-4">
          <app-logo-media-form
            titleMediaForm="adminSystemSettings.designLogoTitleLight"
            description="adminSystemSettings.designLogoDesc"
            btnConfirm="upload"
            btnCancel="clear"
            [imgValue]="form.controls.logoLight.value"
            (changeValue)="setLogoLightValue($event)">
          </app-logo-media-form>
        </div>

        <!-- Cover image -->
        <div class="form-field-block c-mb-4">
          <app-logo-media-form
            titleMediaForm="adminSystemSettings.designCoverTitle"
            description="adminSystemSettings.designCoverDesc"
            btnConfirm="upload"
            btnCancel="clear"
            [imgValue]="form.controls.cover.value"
            (changeValue)="setCoverValue($event)">
          </app-logo-media-form>
        </div>

        <!-- Default email image -->
        <div class="form-field-block c-mb-4">
          <app-logo-media-form
            titleMediaForm="adminSystemSettings.designDefaultEmailLogoTitle"
            description="adminSystemSettings.designEmailLogoDesc"
            btnConfirm="upload"
            btnCancel="clear"
            [imgValue]="form.controls.defaultEmailLogo.value"
            (changeValue)="setDefaultEmailLogoValue($event)">
          </app-logo-media-form>
        </div>

        <!-- Color picker for primary color -->
        <div class="form-field-block">
          <app-color-picker
            label="adminEventSettings.primaryColorLabel"
            [colorPickerValue]="form.controls.primaryColor.value"
            (selectedColor)="onSelectPrimaryColor($event)"
            (selectedColorError)="onSetPrimaryColorError($event)">
          </app-color-picker>
        </div>
      </div>
    </div>

    <p-divider styleClass="custom-divider-horizontal"></p-divider>

    <!-- Features block -->
    <div class="form-block c-mt-9">
      <div class="left-side">
        <h2 class="title">{{ 'adminSystemSettings.featuresLabel' | translate }}</h2>
        <p class="description">{{ 'adminSystemSettings.featuresSubtitle' | translate }}</p>
      </div>
      <div class="right-side">
        <p class="c-px-8 c-m-0">{{ 'adminSystemSettings.inviteOnlyLabel' | translate }}</p>

        <!-- Is private input -->
        <div class="form-field-input-switch-block c-mt-4 c-mb-9">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="isPrivate"
            inputId="is-private-input">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="is-private-input"
            text="adminSystemSettings.grantEventAccessLabel">
          </app-input-label>
        </div>

        <p-divider styleClass="custom-divider-horizontal"></p-divider>

        <!-- Enable encryption input -->
        <div
          *ngIf="freshUser?.role === 'instanceOwner' || freshUser?.role === 'softwareDeveloper'"
          class="form-field-input-switch-block c-mt-9 c-mb-4">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="enableEncryption"
            inputId="enable-encryption-input">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="enable-encryption-input"
            text="adminSystemSettings.enableEncryption">
          </app-input-label>
        </div>

        <!-- Hide profile input -->
        <div
          class="form-field-input-switch-block c-mb-4"
          [class.c-mt-9]="
            freshUser?.role !== 'instanceOwner' || freshUser?.role === 'softwareDeveloper'
          ">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="hideProfile"
            inputId="hide-profile-input">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="hide-profile-input"
            text="adminSystemSettings.hideProfile">
          </app-input-label>
        </div>

        <!-- Hide help input -->
        <div class="form-field-input-switch-block c-mb-4">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="hideHelp"
            inputId="hide-help-input">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="hide-help-input"
            text="adminSystemSettings.hideHelp">
          </app-input-label>
        </div>

        <!-- Activate/Deactivate global single route input -->
        <div class="form-field-input-switch-block c-mb-4">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="globalSingleRoute"
            inputId="global-single-route-input">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="global-single-route-input"
            text="adminSystemSettings.activateGlobalSingleRoute">
          </app-input-label>
        </div>

        <p-divider styleClass="custom-divider-horizontal"></p-divider>

        <p class="c-px-8 c-m-0 c-mt-4">{{ 'adminSystemSettings.amazonIVS' | translate }}</p>

        <div class="form-field-block c-mb-4">
          <app-input-label
            for="ivs-region-input"
            text="adminSystemSettings.enterRegionPlaceholder">
          </app-input-label>
          <input
            class="custom-input-text"
            formControlName="ivsRegion"
            id="ivs-region-input"
            pInputText
            type="text"
            placeholder="{{ 'adminSystemSettings.enterRegionPlaceholder' | translate }}" />
        </div>

        <div class="form-field-block c-mb-4">
          <app-input-label
            for="ivs-key-input"
            text="adminSystemSettings.enterApiKeyPlaceholder">
          </app-input-label>
          <input
            class="custom-input-text"
            formControlName="ivsKey"
            id="ivs-key-input"
            pInputText
            type="text"
            placeholder="{{ 'adminSystemSettings.enterApiKeyPlaceholder' | translate }}" />
        </div>

        <div class="form-field-block c-mb-4">
          <app-input-label
            for="api-secret-input"
            text="adminSystemSettings.enterApiSecretPlaceholder">
          </app-input-label>
          <input
            class="custom-input-text"
            formControlName="ivsSecret"
            id="api-secret-input"
            pInputText
            type="text"
            placeholder="{{ 'adminSystemSettings.enterApiSecretPlaceholder' | translate }}" />
        </div>
      </div>
    </div>
  </form>
</div>

<div class="save-discard-actions-container">
  <app-save-discard-actions
    [isShow]="canUpdate"
    [isLoading]="isUpdating"
    (discard)="onDiscard()"
    (confirm)="onConfirm()">
  </app-save-discard-actions>
</div>
