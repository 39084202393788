import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'addSeparator',
})
export class AddSeparatorPipe implements PipeTransform {
  transform(value: string | number, characterNumber: number, separator: string = ' '): string {
    return String(value)
      .split('')
      .map((element: string, index: number) => {
        const condition =
          (index + 1) % characterNumber === 0 &&
          index + 1 >= characterNumber &&
          index + 1 !== String(value).length;
        return condition ? `${element}${separator}` : element;
      })
      .join('');
  }
}
