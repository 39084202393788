@switch (buttonType()) {
  @case (typeOfButton().DEFAULT) {
    @if (loading()) {
      <button
        [class]="classesForButtonLoading"
        disabled>
        <i class="fa-regular fa-loader fa-spin"></i>
      </button>
    } @else {
      <button
        [class]="classesForDefaultButton"
        tooltipStyleClass="custom-tooltip"
        [pTooltip]="buttonTooltip()"
        [tooltipPosition]="buttonTooltipPosition()"
        [disabled]="disabled()">
        @if (buttonIconLeft()) {
          <i [class]="buttonIconLeft()"></i>
        }

        @if (buttonText()) {
          {{ buttonText() }}
        }

        @if (buttonIconRight()) {
          <i [class]="buttonIconRight()"></i>
        }
      </button>
    }
  }

  @case (typeOfButton().ROUND) {
    @if (loading()) {
      <button
        [class]="classesForButtonLoading"
        disabled>
        <i class="fa-regular fa-loader fa-spin"></i>
      </button>
    } @else {
      <button
        [class]="classesForRoundButton"
        [disabled]="disabled()"
        [pTooltip]="buttonTooltip()"
        [ngStyle]="scssStyles()"
        tooltipStyleClass="custom-tooltip">
        <i [class]="buttonRoundIcon()"></i>
      </button>
    }
  }
}
