import { IEventMenuOption } from '../models';

export const DEFAULT_REGULAR_MENU_ITEMS: IEventMenuOption[] = [
  {
    type: 'link',
    key: 'mainStage',
    id: 'mainStage',
    visible: true,
  },
  {
    type: 'group',
    key: 'allStages',
    id: 'allStages',
    visible: true,
    options: [],
  },
  {
    type: 'divider',
    id: 'divider_1',
    visible: true,
  },
  {
    type: 'link',
    key: 'program',
    id: 'program',
    visible: true,
  },
  {
    type: 'link',
    key: 'brands',
    id: 'brands',
    visible: true,
  },
  {
    type: 'link',
    key: 'speakers',
    id: 'speakers',
    visible: true,
  },
  {
    type: 'link',
    key: 'spaces',
    id: 'spaces',
    visible: true,
  },
  {
    type: 'link',
    key: 'attendees',
    id: 'attendees',
    visible: true,
  },
  {
    type: 'link',
    key: 'topics',
    id: 'topics',
    visible: true,
  },
  {
    type: 'link',
    key: 'mediaPartners',
    id: 'mediaPartners',
    visible: true,
  },
  {
    type: 'link',
    key: 'showroom',
    id: 'showroom',
    visible: true,
  },
  {
    type: 'link',
    key: 'myBookings',
    id: 'myBookings',
    visible: true,
  },
];

export const DEFAULT_ON_DEMAND_EVENT_MENU_ITEMS: IEventMenuOption[] = [
  {
    type: 'link',
    key: 'videolisting',
    id: 'videolisting',
    visible: true,
  },
  {
    type: 'link',
    key: 'program',
    id: 'program',
    visible: true,
  },
  {
    type: 'link',
    key: 'brands',
    id: 'brands',
    visible: true,
  },
  {
    type: 'link',
    key: 'speakers',
    id: 'speakers',
    visible: true,
  },
  {
    type: 'link',
    key: 'attendees',
    id: 'attendees',
    visible: true,
  },
  {
    type: 'link',
    key: 'topics',
    id: 'topics',
    visible: true,
  },
  {
    type: 'link',
    key: 'mediaPartners',
    id: 'mediaPartners',
    visible: true,
  },
  {
    type: 'link',
    key: 'showroom',
    id: 'showroom',
    visible: true,
  },
  {
    type: 'link',
    key: 'myBookings',
    id: 'myBookings',
    visible: true,
  },
];

export const DEFAULT_FOOTER_MENU_ITEMS: IEventMenuOption[] = [
  {
    type: 'link',
    key: 'home',
    id: 'home',
    visible: false,
  },
  {
    type: 'link',
    key: 'program',
    id: 'program',
    visible: false,
  },
  {
    type: 'link',
    key: 'discover',
    id: 'discover',
    visible: false,
  },
  {
    type: 'link',
    key: 'profile',
    id: 'profile',
    visible: false,
  },
];
