<div
  class="container"
  [ngStyle]="containerStyles">
  <p
    class="month"
    [ngStyle]="monthStyles">
    {{ assetEventStartMonth }}
  </p>
  <p
    class="date"
    [ngStyle]="dateStyles">
    {{ assetEventStartDay }}
  </p>
</div>
