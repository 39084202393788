export enum AgencySubIndustries {
  EVENTS = 'userSubIndustries.agency.events',
  PHOTO = 'userSubIndustries.agency.photo',
  BRANDING = 'userSubIndustries.agency.branding',
  DIGITAL = 'userSubIndustries.agency.digital',
  DIRECT_MARKETING = 'userSubIndustries.agency.directMarketing',
  ILLUSTRATOR = 'userSubIndustries.agency.illustrator',
  FULL_SERVICE = 'userSubIndustries.agency.fullService',
  CREATIVE = 'userSubIndustries.agency.creative',
  MEDIA = 'userSubIndustries.agency.media',
  CONTENT = 'userSubIndustries.agency.content',
  RECRUITING = 'userSubIndustries.agency.recruiting',
  SPORTS = 'userSubIndustries.agency.sports',
  SOCIAL_MEDIA = 'userSubIndustries.agency.socialMedia',
  COMMUNICATION = 'userSubIndustries.agency.communication',
}

export enum RetailSubIndustries {
  RETAILER = 'userSubIndustries.retail.retailer',
  STORE_OPERATOR = 'userSubIndustries.retail.storeOperator',
  SERVICE_PROVIDERS = 'userSubIndustries.retail.serviceProviders',
  LOGISTICS_SHIPPING_TRANSPORT = 'userSubIndustries.retail.logisticsShippingTransport',
  MULTICHANNEL = 'userSubIndustries.retail.multichannel',
}

export enum AdvertisersSubIndustries {
  AUTOMOTIVE = 'userSubIndustries.advertisers.automotive',
  BANKS = 'userSubIndustries.advertisers.banks',
  REAL_ESTATE = 'userSubIndustries.advertisers.realEstate',
  CHEMICAL = 'userSubIndustries.advertisers.chemical',
  CONSUMER_ELECTRONICS = 'userSubIndustries.advertisers.consumerElectronics',
  ELECTRICAL_ENGINEERING = 'userSubIndustries.advertisers.electricalEngineering',
  ENERGY_WATER_ENVIRONMENT = 'userSubIndustries.advertisers.energyWaterEnvironment',
  LEISURE_TOURISM_GASTRONOMY = 'userSubIndustries.advertisers.leisureTourismGastronomy',
  CAPITAL_GOODS = 'userSubIndustries.advertisers.capitalGoods',
  HEALTH = 'userSubIndustries.advertisers.health',
  AGRICULTURE = 'userSubIndustries.advertisers.agriculture',
  BRANDED_GOODS = 'userSubIndustries.advertisers.brandedGoods',
  FURNITURE = 'userSubIndustries.advertisers.furniture',
  FOOD = 'userSubIndustries.advertisers.food',
  SOFTWARE = 'userSubIndustries.advertisers.software',
  OTHER_MANUFACTURERS = 'userSubIndustries.advertisers.otherManufacturers',
  TEXTILE_GOODS = 'userSubIndustries.advertisers.textileGoods',
}

export enum TechnologySubIndustries {
  BIG_DATA = 'userSubIndustries.technology.bigData',
  HARDWARE = 'userSubIndustries.technology.hardware',
  TECHNOLOGY_PROVIDERS = 'userSubIndustries.technology.technologyProviders',
  IT_CONSULTING = 'userSubIndustries.technology.itConsulting',
  IT_SERVICE_PROVIDER = 'userSubIndustries.technology.itServiceProvider',
  IT_SECURITY = 'userSubIndustries.technology.itSecurity',
  NETWORK = 'userSubIndustries.technology.network',
  TELECOMMUNICATIONS = 'userSubIndustries.technology.telecommunications',
}

export enum MediaAdvertisingSubIndustries {
  AUDIO = 'userSubIndustries.mediaAdvertising.audio',
  OUTDOOR_ADVERTISING = 'userSubIndustries.mediaAdvertising.outdoorAdvertising',
  CINEMA = 'userSubIndustries.mediaAdvertising.cinema',
  MEDIA = 'userSubIndustries.mediaAdvertising.media',
  MEDIA_PRODUCTION = 'userSubIndustries.mediaAdvertising.mediaProduction',
  BOOKS = 'userSubIndustries.mediaAdvertising.books',
  SPONSORING = 'userSubIndustries.mediaAdvertising.sponsoring',
  NEWSPAPERS = 'userSubIndustries.mediaAdvertising.newspapers',
  TV = 'userSubIndustries.mediaAdvertising.tv',
  MARKETERS = 'userSubIndustries.mediaAdvertising.marketers',
  ADVERTISING_MEDIA = 'userSubIndustries.mediaAdvertising.advertisingMedia',
}

export enum ServicesSuppliersSubIndustries {
  SERVICE_PROVIDERS = 'userSubIndustries.servicesSuppliers.serviceProviders',
  PRINTING = 'userSubIndustries.servicesSuppliers.printing',
  MARKET_RESEARCH = 'userSubIndustries.servicesSuppliers.marketResearch',
  ORGANIZATION = 'userSubIndustries.servicesSuppliers.organization',
  PACKAGING = 'userSubIndustries.servicesSuppliers.packaging',
  TYPE_INDUSTRY = 'userSubIndustries.servicesSuppliers.typeIndustry',
  TECHNOLOGY = 'userSubIndustries.servicesSuppliers.technology',
  CONSULTING = 'userSubIndustries.servicesSuppliers.consulting',
}

export enum GeneralSubIndustries {
  EDUCATION = 'userSubIndustries.other.education',
  NON_PROFIT_ORGANIZATION = 'userSubIndustries.other.nonProfitOrganization',
  HEALTH = 'userSubIndustries.other.health',
  INSTITUTES = 'userSubIndustries.other.institutes',
  PUBLIC_SERVICE = 'userSubIndustries.other.publicService',
  POLITICS = 'userSubIndustries.other.politics',
  OTHER = 'userSubIndustries.other.other',
  UNION = 'userSubIndustries.other.union',
}

export type SubIndustry =
  | AgencySubIndustries
  | RetailSubIndustries
  | AdvertisersSubIndustries
  | TechnologySubIndustries
  | MediaAdvertisingSubIndustries
  | ServicesSuppliersSubIndustries
  | GeneralSubIndustries;
