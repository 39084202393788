import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';

import { IEvent, ISession, ITag, IUser, IUserSession } from 'src/app/core/models';
import { SessionsService, UserSessionsService, UsersService } from 'src/app/core/services';
import { EventsStore, SessionsStore, TagsStore, UsersStore } from 'src/app/core/stores';
import { Timestamp } from 'src/app/firebase';
import { IAdminTableColumn } from 'src/app/shared';
import { ButtonSize } from 'src/app/standalone';

@Component({
  selector: 'app-attach-session-users',
  templateUrl: './attach-session-users.component.html',
  styleUrls: ['./attach-session-users.component.scss'],
})
export class AttachSessionUsersComponent implements OnInit {
  attachLoading = false;
  loadingUsers = true;
  users: (IUser & { availableToRegister: boolean })[] = [];
  selectedUsers: IUser[] = [];
  totalRecords = 0;
  pageSize = 10;
  tags: ITag[] = [];
  selectedTags: ITag[] = [];
  columns: IAdminTableColumn[] = [
    { field: 'firstName', titleTranslationKey: 'adminUsers.tableColFirstName' },
    { field: 'lastName', titleTranslationKey: 'adminUsers.tableColLastName' },
    { field: 'email', titleTranslationKey: 'adminUsers.tableColEmail' },
    { field: 'company', titleTranslationKey: 'adminUsers.tableColCompany' },
    { field: 'position', titleTranslationKey: 'adminUsers.tableColPosition' },
  ];
  availableSeats: number;
  buttonSize = ButtonSize;

  constructor(
    private eventStore: EventsStore,
    private tagStore: TagsStore,
    private messageService: MessageService,
    private translateService: TranslateService,
    private usersService: UsersService,
    private usersStore: UsersStore,
    private userSessionsService: UserSessionsService,
    private sessionsStore: SessionsStore,
    private sessionsService: SessionsService,
  ) {}

  get event(): IEvent {
    return this.eventStore.adminEvent;
  }

  get session(): ISession {
    return this.sessionsStore.adminSession;
  }

  async ngOnInit(): Promise<void> {
    await this.fetchUsers();
    this.tags = this.tagStore.tags.filter((tag) => this.event.tags.includes(tag.id));
    this.loadingUsers = false;
  }

  private async fetchUsers(): Promise<void> {
    try {
      const allUsersFromEvent: IUser[] = await this.usersService.getAllUsersFromEvent(
        this.event.id,
        this.usersStore.userId,
      );
      const allUsersFromEventWithCurrent: IUser[] = [...allUsersFromEvent, this.usersStore.user];
      const sessionRegisteredUsers: IUserSession[] =
        await this.userSessionsService.getUserSessionsBySessionId(this.event.id, this.session.id);
      this.availableSeats = this.session?.availability
        ? this.session.availability - sessionRegisteredUsers.length
        : null;
      const sessionRegisteredUserIds: string[] = sessionRegisteredUsers.map(
        (registerUser: IUserSession) => registerUser.userId,
      );
      const allUsersExceptRegistered: IUser[] = allUsersFromEventWithCurrent.filter(
        (user: IUser) => !sessionRegisteredUserIds.includes(user.id),
      );
      const preparedUsersForFiltering = await Promise.all(
        allUsersExceptRegistered.map(async (user: IUser) => {
          const isPossibleToRegister = await this.checkPossibilityToRegister(user);
          return { ...user, availableToRegister: isPossibleToRegister };
        }),
      );
      const usersForShowing = preparedUsersForFiltering.filter((u) => u.availableToRegister);
      this.users = usersForShowing;
      this.totalRecords = usersForShowing.length;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  private async checkPossibilityToRegister(user: IUser): Promise<boolean> {
    try {
      // get all user sessions for current user
      const allUserSessionsForCurrentUser: IUserSession[] =
        await this.userSessionsService.allUserSessionsByUserId(this.event.id, user.id);
      // get all full session models in which the current user is registered
      const allSessionsForCurrentUser: ISession[] = await Promise.all(
        allUserSessionsForCurrentUser.map((userSession: IUserSession) =>
          this.sessionsService.getOne(this.event.id, userSession.sessionId),
        ),
      );
      // get sessionIds for sessions in which the current user is registered
      const allSessionIdsForCurrentUser: string[] = allSessionsForCurrentUser.map(
        (registeredSession: ISession) => registeredSession?.id,
      );
      // get all relative session IDs from the sessions in which the current user is registered
      const allRelationsSessionsIds: string[] = allSessionsForCurrentUser
        .map((session: ISession) => session?.sessionsRelation || [])
        .flat();
      // remove duplicate IDs
      const uniqueRelationsSessionsIds: string[] = [...new Set(allRelationsSessionsIds)];
      // check if the current session exists in the relation sessions of registered sessions
      const isCurrentSessionExistingInRelationSessions = uniqueRelationsSessionsIds.includes(
        this.session.id,
      );
      // check if the relation sessions of the current session have IDs of sessions in which the user is already registered
      const isUserRegisteredIntoSessionsFromCurrentRelationSessions =
        this.session.sessionsRelation?.some((sessionRelationId: string) => {
          return allSessionIdsForCurrentUser.includes(sessionRelationId);
        });

      return (
        !isCurrentSessionExistingInRelationSessions &&
        !isUserRegisteredIntoSessionsFromCurrentRelationSessions
      );
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async fetchEntries(event: any): Promise<void> {
    this.loadingUsers = true;
    this.pageSize = event.rows;
    await this.fetchUsers();
    this.loadingUsers = false;
  }

  async attach(): Promise<void> {
    this.attachLoading = true;
    if (this.session?.availability && this.availableSeats - this.selectedUsers.length < 0) {
      this.showToastMessage('error', 'error', 'adminSession.noSeatsNotification');
      this.selectedUsers = [];
      this.attachLoading = false;
      return;
    }

    try {
      await Promise.all(
        this.selectedUsers.map((user: IUser) => {
          const newUserSession: IUserSession = {
            id: null,
            eventId: this.event.id,
            userId: user.id,
            sessionId: this.session.id,
            sessionTitle: this.session.title,
            createdAt: Timestamp.now(),
            createdBy: this.usersStore.userId,
            updatedAt: null,
            updatedBy: null,
          };
          return this.userSessionsService.create(this.event.id, newUserSession);
        }),
      );
      this.showToastMessage('success', 'success', 'adminSession.successRegisteredNotification', {
        count: this.selectedUsers.length,
      });
    } catch (error) {
      this.showToastMessage('error', 'error', 'application.toasters.error');
    }

    this.attachLoading = false;
    this.selectedUsers = [];
    this.fetchUsers();
  }

  async tagSelectionChanged(tags: ITag[]): Promise<void> {
    try {
      this.selectedTags = tags;
      await this.fetchUsers();
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  private showToastMessage(
    severity: 'success' | 'error',
    summary: string,
    detail: string,
    additionalDetail?: {},
  ): void {
    this.messageService.add({
      key: 'user',
      severity,
      summary: this.translateService.instant(summary),
      detail: this.translateService.instant(detail, additionalDetail),
      styleClass: 'custom-toast',
    });
  }
}
