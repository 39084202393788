import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'app-entity-keys',
  templateUrl: './entity-keys.component.html',
  styleUrls: ['./entity-keys.component.scss'],
})
export class EntityKeysComponent {
  @Input() entity: string;
  @Input() keys: string[];

  constructor(
    private clipboardService: ClipboardService,
    private translateService: TranslateService,
    private messageService: MessageService,
  ) {}

  copy(key: string) {
    this.clipboardService.copy(key === 'event.invitationURL' ? `{{invitationURL}}` : `{{${key}}}`);
    try {
      this.messageService.add({
        severity: 'success',
        summary: this.translateService.instant('success'),
        detail: this.translateService.instant('adminEmailTemplate.succesCopyEntryKey'),
        styleClass: 'custom-toast',
      });
    } catch (error) {
      console.log(error);
    }
  }
}
