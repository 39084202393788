<div class="tab-container">
  <div class="tabs">
    <div
      class="tab-item"
      [class.active]="activeTab() === tabTypes().ALL"
      (click)="changeTab(tabTypes().ALL)">
      {{ 'chaptersSidebarTab.all' | translate }}
    </div>
    <div
      class="tab-item"
      [class.active]="activeTab() === tabTypes().LESSIONS"
      (click)="changeTab(tabTypes().LESSIONS)">
      {{ 'chaptersSidebarTab.lessions' | translate }}
    </div>
    @if (!isHideEventAssets()) {
      <div
        class="tab-item"
        [class.active]="activeTab() === tabTypes().EVENTS"
        (click)="changeTab(tabTypes().EVENTS)">
        {{ 'chaptersSidebarTab.events' | translate }}
      </div>
    }
  </div>

  <div class="tabs-content c-mt-5">
    @switch (activeTab()) {
      @case (tabTypes().ALL) {
        <app-chapters-sidebar-tab-all-content></app-chapters-sidebar-tab-all-content>
      }
      @case (tabTypes().LESSIONS) {
        <app-chapters-sidebar-tab-lessions></app-chapters-sidebar-tab-lessions>
      }
      @case (tabTypes().EVENTS) {
        <app-chapters-sidebar-tab-events></app-chapters-sidebar-tab-events>
      }
      @default {
        <app-chapters-sidebar-tab-all-content></app-chapters-sidebar-tab-all-content>
      }
    }
  </div>
</div>
