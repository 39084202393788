import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

import { BrandsStore, UsersStore } from 'src/app/core/stores';
import { BrandProductsService, BrandsService } from 'src/app/core/services';
import { IBrandProduct } from 'src/app/core/models';
import { asyncDelay, defaultDialogConfig, VALID_LINK_PATTERN } from 'src/app/core/utils';
import { DeleteDialogComponent, logoAvatar, FileType } from 'src/app/shared';

// admin part of the application, the route starts with this value. Change value on route change
const ADMIN_PART_URL_START = 'admin';

@Component({
  selector: 'app-brand-product-details',
  templateUrl: './brand-product-details.component.html',
  styleUrls: ['./brand-product-details.component.scss'],
})
export class BrandProductDetailsComponent implements OnInit, OnDestroy {
  @Input() product: IBrandProduct;
  @Output() confirm = new EventEmitter<boolean>();
  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;

  fileTypes: string[] = Object.values(FileType);
  formGroup: UntypedFormGroup;
  defaultImage = logoAvatar;
  fileUploading: boolean;
  imageUploading: boolean;
  FileType = FileType;
  fileVideoPreview: SafeResourceUrl;
  filePdfPreview: SafeResourceUrl;
  imagePreview: string;
  filePreview: string;
  isUpdate: boolean;
  saveInProgress: boolean;
  deleteInProgress: boolean;

  private subscriptions = new Subscription();
  private readonly FILE_MAX_SIZE_MB = 5;

  get file(): string {
    return this.getImage(
      this.fileUploading,
      this.filePreview ?? this.formGroup?.controls.file?.value,
    );
  }

  get image(): string {
    return this.getImage(
      this.imageUploading,
      this.imagePreview ?? this.formGroup?.controls.image?.value,
    );
  }

  get fileType(): FileType {
    return this.formGroup.get('type').value;
  }

  get allowedFormats(): string {
    const type: FileType = this.formGroup.get('type').value;

    if (type === FileType.JPG) {
      return 'image/jpeg,image/jpg';
    }

    if (type === FileType.PNG) {
      return 'image/png';
    }

    if (type === FileType.Video) {
      return 'video/mp4,video/x-m4v,video/*';
    }

    if (type === FileType.PDF) {
      return 'application/pdf';
    }

    if (type === FileType.ZIP) {
      return 'application/x-zip-compressed';
    }
  }

  constructor(
    private fb: UntypedFormBuilder,
    private usersStore: UsersStore,
    public sanitizer: DomSanitizer,
    private brandProductsService: BrandProductsService,
    private brandsService: BrandsService,
    private dialogService: DialogService,
    private messageService: MessageService,
    private brandsStore: BrandsStore,
    private router: Router,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.isUpdate = !!this.product;
    this.buildFormGroup(this.product);
  }

  async saveChanges(): Promise<void> {
    if (this.formGroup.invalid) {
      return;
    }

    this.saveInProgress = true;

    const payload = this.formGroup.getRawValue() as IBrandProduct;
    const brand = this.router.url.includes(ADMIN_PART_URL_START)
      ? this.brandsStore.adminBrand
      : await this.brandsService.getOne(this.usersStore.user.brandId);

    try {
      if (!this.isUpdate) {
        await this.brandProductsService.create(brand, payload);
        this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant('success'),
          detail: this.translateService.instant('brandOwner.product.createSuccess'),
          styleClass: 'custom-toast',
        });
        await asyncDelay(2000);
        this.saveInProgress = false;
        this.confirm.emit(true);
      } else {
        await this.brandProductsService.update(payload);
        this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant('success'),
          detail: this.translateService.instant('brandOwner.product.updateSuccess'),
          styleClass: 'custom-toast',
        });
        await asyncDelay(2000);
        this.saveInProgress = false;
        this.confirm.emit(true);
      }
    } catch (error) {
      console.log(error);
      this.saveInProgress = false;
      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('error'),
        detail: this.translateService.instant('brandOwner.product.saveError'),
        styleClass: 'custom-toast',
      });
      await asyncDelay(2000);
      this.confirm.emit(false);
    }
  }

  async deleteProduct(): Promise<void> {
    const dialogRef: DynamicDialogRef = this.dialogService.open(
      DeleteDialogComponent,
      Object.assign(defaultDialogConfig(), {
        height: '35%',
        width: '35%',
        data: {
          title: 'brandOwner.product.deleteProduct',
          description: 'brandOwner.product.deleteProductDescription',
        },
      }),
    );
    const result = await dialogRef.onClose.pipe(take(1)).toPromise();
    if (result) {
      this.deleteInProgress = true;
      try {
        await this.brandProductsService.delete(this.formGroup.get('id').value);
        this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant('success'),
          detail: this.translateService.instant('brandOwner.product.removeSuccess'),
          styleClass: 'custom-toast',
        });
        this.deleteInProgress = false;
        await asyncDelay(2000);
        this.confirm.emit(true);
      } catch (error) {
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('error'),
          detail: this.translateService.instant('brandOwner.product.removeError'),
          styleClass: 'custom-toast',
        });
        this.deleteInProgress = false;
        await asyncDelay(2000);
        this.confirm.emit(false);
      }
    }
  }

  clearFile(): void {
    this.formGroup.patchValue({ file: '' });
    this.fileInput.nativeElement.value = '';
    this.fileVideoPreview = null;
    this.filePdfPreview = null;
    this.filePreview = null;
    this.fileUploading = false;
  }

  clearImage(): void {
    this.formGroup.patchValue({ image: '' });
    this.imagePreview = null;
    this.imageUploading = false;
  }

  getImage(uploadProcess: boolean, src: string): string {
    return uploadProcess ? '' : src;
  }

  uploadMediaImage(event: Event): void {
    this.imageUploading = true;

    const onSuccess = (image: string) => {
      this.imagePreview = image;

      this.imageUploading = false;
    };

    const onError = () => {
      this.imageUploading = false;
    };

    this.uploadImg(event, 'image', false, onSuccess, onError);
  }

  uploadFile(event: Event): void {
    this.fileUploading = true;

    const onSuccess = (file: string) => {
      if (this.fileType === FileType.Video) {
        this.fileVideoPreview = this.sanitizer.bypassSecurityTrustResourceUrl(file);
      } else if (this.fileType === FileType.PDF) {
        this.filePdfPreview = this.sanitizer.bypassSecurityTrustResourceUrl(file);
      } else if (this.fileType === FileType.JPG || this.fileType === FileType.PNG) {
        this.filePreview = file;
      }

      this.fileUploading = false;
    };

    const onError = () => {
      this.fileUploading = false;
      this.fileInput.nativeElement.value = '';
    };

    this.uploadImg(event, 'file', true, onSuccess, onError);
  }

  getFileTypeText(type: string): string {
    return this.translateService.instant(`brandOwner.mediaTypes.${type.toLowerCase()}`);
  }

  private uploadImg(
    event: Event,
    controlName: string,
    isFileUpload: boolean,
    onSuccess: (img: string | ArrayBuffer) => void,
    onError: () => void,
  ): void {
    const input = event.target as HTMLInputElement;

    if (!input.files || input.files.length === 0) {
      return;
    }

    const file = input.files[0];
    const fileSize = Number((file.size / 1024 / 1024).toFixed(4));

    if (controlName === 'image') {
      if (file.size > 3_194_304) {
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('error'),
          detail: this.translateService.instant(
            'application.toasters.imageTypeOrSizeRuleViolation_PNG_JPEG',
            { size: 3 },
          ),
          styleClass: 'custom-toast',
        });
        return;
      }
    } else {
      if (fileSize > this.FILE_MAX_SIZE_MB) {
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('error'),
          detail: this.translateService.instant('showroom.uploadFileSizeLimit'),
          styleClass: 'custom-toast',
        });
        return;
      }
    }

    if (isFileUpload) {
      if (this.fileType === FileType.JPG && !['image/jpeg', 'image/jpg'].includes(file.type)) {
        return;
      }

      if (this.fileType === FileType.PNG && file.type !== 'image/png') {
        return;
      }

      if (this.fileType === FileType.PDF && file.type !== 'application/pdf') {
        return;
      }

      if (this.fileType === FileType.Video && !file.type.includes('video')) {
        return;
      }

      if (
        this.fileType === FileType.ZIP &&
        !['application/x-zip-compressed', 'application/zip'].includes(file.type)
      ) {
        return;
      }
    } else {
      if (!['image/jpeg', 'image/jpg', 'image/png'].includes(file.type)) {
        return;
      }
    }

    this.formGroup.get(controlName).setValue(file);

    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = () => onSuccess(reader.result);

    reader.onerror = (error) => {
      console.log(error);
      onError();
    };
  }

  private buildFormGroup(product: IBrandProduct): void {
    this.formGroup = this.fb.group({
      id: new UntypedFormControl(product?.id ?? ''),
      title: new UntypedFormControl(product?.title ?? '', [
        Validators.required,
        Validators.maxLength(30),
      ]),
      description: new UntypedFormControl(product?.description ?? '', [Validators.maxLength(500)]),
      tagline: new UntypedFormControl(product?.tagline ?? '', [Validators.maxLength(50)]),
      cta: new UntypedFormControl(product?.cta ?? '', Validators.pattern(VALID_LINK_PATTERN)),
      type: new UntypedFormControl(product?.type ?? FileType.Video),
      file: new UntypedFormControl(product?.file ?? ''),
      videoLink: new UntypedFormControl(
        product?.videoLink ?? '',
        Validators.pattern(VALID_LINK_PATTERN),
      ),
      image: new UntypedFormControl(product?.image ?? ''),
    });

    if (this.fileType === FileType.Video) {
      this.fileVideoPreview = this.file.length
        ? this.sanitizer.bypassSecurityTrustResourceUrl(this.file)
        : null;
    }

    if (this.fileType === FileType.PDF) {
      this.filePdfPreview = this.file.length
        ? this.sanitizer.bypassSecurityTrustResourceUrl(this.file)
        : null;
    }

    this.subscriptions.add(
      this.formGroup.get('type').valueChanges.subscribe(() => this.clearFile()),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
