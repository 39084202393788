import { Timestamp } from 'src/app/firebase';

export interface IUserSession {
  id: string;
  eventId: string;
  userId: string;
  sessionId: string;
  sessionTitle: string;
  createdAt: Timestamp;
  createdBy: string;
  updatedAt: Timestamp;
  updatedBy: string;
}
