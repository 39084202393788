import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';

import { HubsService, UserHubsService } from 'src/app/core/services';
import { ConfirmDialogComponent, IAdminTableColumn } from 'src/app/shared';
import { HubsStore } from 'src/app/core/stores';
import { IUser, IHub } from 'src/app/core/models';
import { getUserNameAbbreviation } from 'src/app/core/utils';
import { NewUserComponent, AttachUsersToHubComponent } from 'src/app/admin/dialogs';
import { ButtonSize, ButtonStyle, ButtonType } from 'src/app/standalone';

@Component({
  selector: 'app-hub-admins-list',
  templateUrl: './hub-admins-list.component.html',
  styleUrls: ['./hub-admins-list.component.scss'],
})
export class HubAdminsListComponent implements OnInit {
  loading = true;
  loadingEntries = true;
  searchValue = '';
  searchFilterFields: string[] = ['firstName', 'lastName', 'email', 'isActive', 'memberRole'];
  columns: IAdminTableColumn[] = [
    { field: 'firstName', titleTranslationKey: 'adminBrandTeam.tableColFirstName', sortable: true },
    { field: 'lastName', titleTranslationKey: 'adminBrandTeam.tableColLastName', sortable: true },
    { field: 'email', titleTranslationKey: 'adminBrandTeam.tableColEmail', sortable: true },
    {
      field: 'isActive',
      titleTranslationKey: 'adminBrandTeam.tableColStatus',
      template: 'statusCell',
      sortable: true,
    },
    { field: 'hubRole', titleTranslationKey: 'adminBrandTeam.tableColTeamRole', sortable: true },
    { field: 'actions', template: 'actionsCell' },
  ];
  entries: IUser[] = [];
  allEntries: IUser[] = [];
  selectedEntries: IUser[] = [];
  imageText = getUserNameAbbreviation;
  buttonSize = ButtonSize;
  buttonType = ButtonType;
  buttonStyle = ButtonStyle;

  constructor(
    private messageService: MessageService,
    private dialogService: DialogService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public hubsStore: HubsStore,
    private hubsService: HubsService,
    private translateService: TranslateService,
    private userHubs: UserHubsService,
  ) {}

  get hub(): IHub {
    return this.hubsStore.hub;
  }

  async ngOnInit(): Promise<void> {
    if (this.hub) {
      await this.fetchHubTeam();
      this.loadingEntries = false;
      this.loading = false;
    }
  }

  async openAttachUserDialog(): Promise<void> {
    const dialogRef: DynamicDialogRef = this.dialogService.open(AttachUsersToHubComponent, {
      width: '90%',
      height: '80%',
      closable: false,
      data: { memberIds: this.entries.map((u) => u.id) },
    });

    await firstValueFrom(dialogRef.onClose);
    this.loadingEntries = true;
    this.entries = [];
    await this.fetchHubTeam();
    this.loadingEntries = false;
  }

  async sendInvites(): Promise<void> {
    const dialogRef: DynamicDialogRef = this.dialogService.open(ConfirmDialogComponent, {
      closable: false,
      styleClass: 'confirm-dialog',
      data: {
        titleKey: 'adminHubTeam.sendUserInvitesDialogHeader',
        descriptionKey: this.translateService.instant('adminHubTeam.sendUserInvitesDialogMessage', {
          totalUsers: this.selectedEntries.length,
        }),
      },
    });

    const result: 'cancel' | 'confirm' = await firstValueFrom(dialogRef.onClose);
    if (result === 'confirm') {
      await Promise.all(
        this.selectedEntries.map(async (user: IUser) => {
          try {
            await this.hubsService.sendInvite(this.hub, user.id);
            this.showToastMessage('success', 'adminHubTeam.sendUserInvitesSuccessMessage');
          } catch (error) {
            this.showToastMessage('error', 'adminHubTeam.sendUserInvitesErrorMessage');
          }
        }),
      );
    }
  }

  async onAddNewAdmin(): Promise<void> {
    const newAdminDialog: DynamicDialogRef = this.dialogService.open(NewUserComponent, {
      styleClass: 'attach-new-user-dialog',
      closable: false,
      data: {
        userType: 'hubAdmin',
        hub: this.hub,
      },
    });

    const id: string = await firstValueFrom(newAdminDialog.onClose);
    if (id) {
      this.router.navigate([
        `/${this.hub ? this.hubsStore.useHubUrl : ''}/admin/user/${id}/profile`,
      ]);
    } else {
      await this.fetchHubTeam();
    }
  }

  onRowClicked(id: string): void {
    this.router.navigate([`/${this.hubsStore.useHubUrl}/admin/user/${id}`]);
  }

  edit(event: Event, entry: IUser): void {
    event.stopPropagation();
    this.router.navigate([`/${this.hubsStore.useHubUrl}/admin/user/${entry.id}`], {
      relativeTo: this.activatedRoute,
      state: {
        user: entry,
      },
    });
  }

  async unAttachUser(event: Event, entry: IUser): Promise<void> {
    event.stopPropagation();
    const deleteDialogRef: DynamicDialogRef = this.dialogService.open(ConfirmDialogComponent, {
      closable: false,
      styleClass: 'confirm-dialog',
      data: {
        titleKey: 'adminHubTeam.deleteMember',
        descriptionKey: 'adminHubTeam.deleteMemberText',
        confirmBtnKey: 'adminHubTeam.confirmBtn',
        cancelBtnKey: 'adminHubTeam.cancelBtn',
        entry,
      },
    });

    const result: 'cancel' | 'confirm' = await firstValueFrom(deleteDialogRef.onClose);
    if (result === 'confirm') {
      try {
        this.loadingEntries = true;
        await this.userHubs.changeRoleToUserForHubUser(entry.userHubId);
        this.showToastMessage('success', 'adminHubTeam.succesDeleteMember');
        this.entries = [];
        this.allEntries = [];
        this.fetchHubTeam();
        this.loadingEntries = false;
      } catch (error) {
        this.showToastMessage('error', 'adminHubTeam.errorDeleteMember');
      }
    }
  }

  private async fetchHubTeam(): Promise<void> {
    this.allEntries = await this.hubsService.getAdminTeamMembers(this.hub.id);
    this.entries = this.allEntries;
  }

  private showToastMessage(severity: 'success' | 'error', message: string): void {
    this.messageService.add({
      severity,
      summary: this.translateService.instant(severity),
      detail: this.translateService.instant(message),
      styleClass: 'custom-toast',
    });
  }
}
