import { ChatBubbleComponent } from './chat-bubble/chat-bubble.component';
import { ChatComponent } from './chat/chat.component';
import { ChatConversationComponent } from './chat-conversation/chat-conversation.component';
import { ChatConversationsListComponent } from './chat-conversations-list/chat-conversations-list.component';
import { ChatLoadAttachmentComponent } from './chat-load-attachment/chat-load-attachment.component';
import { ChatReplyComponent } from './chat-reply/chat-reply.component';
import { ChatWindowComponent } from './chat-window/chat-window.component';

export * from './chat-bubble/chat-bubble.component';
export * from './chat-conversation/chat-conversation.component';
export * from './chat-conversations-list/chat-conversations-list.component';
export * from './chat-load-attachment/chat-load-attachment.component';
export * from './chat-reply/chat-reply.component';
export * from './chat-window/chat-window.component';
export * from './chat/chat.component';

export const CHAT_COMPONENTS = [
  ChatBubbleComponent,
  ChatComponent,
  ChatConversationComponent,
  ChatConversationsListComponent,
  ChatLoadAttachmentComponent,
  ChatReplyComponent,
  ChatWindowComponent,
];
