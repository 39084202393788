import {
  AwsEventBridgeEventDetailType,
  AwsEventBridgeEventName,
  AwsEventBridgeEventSource,
} from 'src/app/standalone/shared/enums';

export interface IAwsEventBridgeEvent {
  account: string;
  channelName: string;
  detailType: AwsEventBridgeEventDetailType;
  eventName: AwsEventBridgeEventName;
  id: string;
  region: string;
  resources: string[];
  source: AwsEventBridgeEventSource;
  streamId: string;
  time: string;
  version: string;
}
