import {
  ICourseSubscription,
  ICourseTicket,
  IEventSubscription,
  ITicket,
} from 'src/app/core/models';
import { StripeSessionCheckoutMode } from 'src/app/core/enums';

export interface ICreateStripeSessionCheckout {
  successUrl: string;
  cancelUrl: string;
  mode: StripeSessionCheckoutMode;
  lineItems?: IStripeSessionCheckoutLineItem[];
  customer?: string;
  amount?: number;
  connectId?: string;
  customerId?: string;
  tickets?: ITicket[] | ICourseTicket[] | ICourseSubscription[] | IEventSubscription[];
  orderId?: string;
  metadata: Record<string, string>;
  description: string;
  userId: string;
  email?: string;
}

export interface IStripeSessionCheckoutLineItem {
  // REQUIRED CONDITIONALLY. One of price or priceData is required.
  // If someone needs priceData property, please expand this interface
  price?: string;
  quantity?: number;
}
