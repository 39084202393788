@if (loading()) {
  <app-loader></app-loader>
} @else {
  <header class="header">
    {{ 'adminEventSessionSidebar.basicInfoNavItem' | translate }}
  </header>
  <p-divider styleClass="custom-divider-horizontal"></p-divider>
  <form [formGroup]="form">
    <!-- Session Name & Details block -->
    <div class="form-block c-my-8 c-px-8">
      <div class="left-side">
        <h2 class="title">{{ 'adminSession.nameDetailsTitle' | translate }}</h2>
        <p class="description">{{ 'adminSession.nameDetailsDescription' | translate }}</p>
      </div>
      <div class="right-side">
        <!-- Title input -->
        <div class="form-field-block">
          <app-input-label
            labelType="required"
            for="title-input"
            text="adminSession.titleLabel">
          </app-input-label>
          <input
            class="custom-input-text custom-input-text-required"
            formControlName="title"
            id="title-input"
            pInputText
            type="text"
            placeholder="Name" />
          <app-input-error-message
            [showMessage]="form.controls.title.invalid && form.controls.title.touched"
            errorMessage="{{ 'adminSession.titleReq' | translate }}">
          </app-input-error-message>
        </div>

        <!-- Date input -->
        <div class="form-field-block">
          <app-input-label
            [labelType]="isPossibleChangeDate ? 'required' : 'tooltip-required'"
            tooltipText="{{ 'adminSession.expiredSessionDateTooltip' | translate }}"
            for="date-input"
            text="adminSession.startDateLabel">
          </app-input-label>
          <p-calendar
            class="custom-calendar custom-calendar-required"
            formControlName="date"
            [monthNavigator]="true"
            [yearNavigator]="true"
            [showIcon]="true"
            dateFormat="dd/mm/yy"
            yearRange="1900:2050"
            inputId="date-input"
            icon="fak fa-lox-calendar"
            placeholder="23/01/2022">
          </p-calendar>
          <app-input-error-message
            [showMessage]="form.controls.date.invalid && form.controls.date.touched"
            errorMessage="{{ 'application.forms.required' | translate }}">
          </app-input-error-message>
        </div>

        <div class="two-elements-block">
          <!-- Start time input -->
          <div>
            <app-input-label
              [labelType]="isPossibleChangeDate ? 'required' : 'tooltip-required'"
              tooltipText="{{ 'adminSession.expiredSessionDateTooltip' | translate }}"
              for="start-time-input"
              text="adminSession.startTimeLabel">
            </app-input-label>
            <p-calendar
              class="custom-calendar custom-calendar-time custom-calendar-required"
              formControlName="start"
              placeholder="09:00"
              inputId="start-time-input"
              [timeOnly]="true"
              [showIcon]="true"
              icon="far fa-clock"
              appDateMask>
            </p-calendar>
            <app-input-error-message
              [showMessage]="form.controls.start.invalid && form.controls.start.touched"
              errorMessage="{{ 'application.forms.required' | translate }}">
            </app-input-error-message>
          </div>

          <!-- End time input -->
          <div>
            <app-input-label
              [labelType]="isPossibleChangeDate ? 'required' : 'tooltip-required'"
              tooltipText="{{ 'adminSession.expiredSessionDateTooltip' | translate }}"
              for="end-time-input"
              text="adminSession.endTimeLabel">
            </app-input-label>
            <p-calendar
              class="custom-calendar custom-calendar-time custom-calendar-required"
              formControlName="end"
              placeholder="09:15"
              inputId="end-time-input"
              [timeOnly]="true"
              [showIcon]="true"
              icon="far fa-clock"
              appDateMask>
            </p-calendar>
            <app-input-error-message
              [showMessage]="form.controls.end.invalid && form.controls.end.touched"
              errorMessage="{{ 'application.forms.required' | translate }}">
            </app-input-error-message>
          </div>
        </div>

        <!-- Stages dropdown -->
        <div class="form-field-block c-mb-4">
          <app-input-label
            for="stages-input"
            text="adminSession.stage">
          </app-input-label>
          <p-dropdown
            class="custom-dropdown"
            panelStyleClass="custom-dropdown-panel"
            [options]="eventStages()"
            optionLabel="name"
            optionValue="id"
            formControlName="stageId"
            [showClear]="true"
            inputId="stages-input"
            placeholder="{{ 'adminSession.stage' | translate }}">
          </p-dropdown>
        </div>

        <!-- Video link input -->
        <div class="form-field-block c-mb-8">
          <app-input-label
            for="videolink-input"
            text="adminSession.videolink">
          </app-input-label>
          <div class="p-inputgroup custom-input-group">
            <span class="p-inputgroup-addon">Embed-URL</span>
            <input
              formControlName="videolink"
              id="videolink-input"
              type="text"
              pInputText
              placeholder="{{ 'adminSession.videolink' | translate }}" />
          </div>
        </div>

        <p-divider styleClass="custom-divider-horizontal"></p-divider>

        <!-- Featured session switch input -->
        <div class="form-field-input-switch-block c-mt-8">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="featuredSession"
            inputId="featured-session-input-switch">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="featured-session-input-switch"
            text="adminSession.featuredSession">
          </app-input-label>
        </div>
      </div>
    </div>

    <p-divider styleClass="custom-divider-horizontal"></p-divider>

    <!-- Brands & Speakers block -->
    <div class="form-block c-my-8 c-px-8">
      <div class="left-side">
        <h2 class="title">{{ 'adminSession.brandsSpeakersTitle' | translate }}</h2>
        <p class="description">{{ 'adminSession.brandsSpeakersDescription' | translate }}</p>
      </div>
      <div class="right-side">
        <!-- Speaker input -->
        <div class="form-field-block c-mb-4">
          <app-input-label
            for="speakers-input"
            text="adminSession.speaker">
          </app-input-label>
          <p-multiSelect
            class="custom-multi-select"
            [options]="eventSpeakers()"
            optionLabel="lastName"
            optionValue="id"
            formControlName="speakers"
            inputId="speakers-input"
            placeholder="{{ 'adminSession.speakersLabel' | translate }}">
            <ng-template
              let-speaker
              pTemplate="item">
              {{ speaker.firstName }} {{ speaker.lastName }}
            </ng-template>
          </p-multiSelect>
        </div>

        <!-- Brands input -->
        @if (shouldShowBrands) {
          <div class="form-field-block">
            <app-input-label
              for="brands-input"
              text="adminSession.brandsTitle">
            </app-input-label>
            <p-multiSelect
              class="custom-multi-select"
              [options]="eventBrands()"
              optionLabel="name"
              optionValue="brandId"
              formControlName="brands"
              inputId="brands-input"
              placeholder="{{ 'adminSession.selectBrands' | translate }}">
            </p-multiSelect>
          </div>
        }
      </div>
    </div>
  </form>
}

<div class="fixed right-0 bottom-0 left-0">
  <app-save-discard-actions
    [isShow]="canUpdate"
    [isLoading]="isUpdating()"
    (discard)="onDiscard()"
    (confirm)="onConfirm()">
  </app-save-discard-actions>
</div>

<p-toast>
  <ng-template
    let-message
    pTemplate="message">
    <app-toast
      [severity]="message.severity"
      [summary]="message.summary"
      [detail]="message.detail">
    </app-toast>
  </ng-template>
</p-toast>
