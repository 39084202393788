import { Injectable } from '@angular/core';
import { IFirestore, Firestore } from 'src/app/firebase';
import { IEmailTemplate } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class EmailTemplatesService {
  private readonly mailTemplatesCollection = 'mailTemplates';

  private firestore: IFirestore;

  constructor() {
    this.firestore = Firestore();
  }

  async getAll(): Promise<IEmailTemplate[]> {
    const res = await this.firestore
      .collection(this.mailTemplatesCollection)
      .orderBy('action')
      .get();

    return res.docs.map((d) => {
      const action = d.get('action');
      const html = d.get('html');

      return {
        id: d.id,
        action,
        html,
      };
    });
  }

  async get(id: string): Promise<IEmailTemplate> {
    const doc = await this.firestore.collection(this.mailTemplatesCollection).doc(id).get();

    const action = doc.get('action');
    const html = doc.get('html');

    return {
      id: doc.id,
      action,
      html,
    };
  }

  async updateEmailTemplate(id: string, html: string): Promise<void> {
    const docRef = this.firestore.collection(this.mailTemplatesCollection).doc(id);

    await docRef.update({ html });
  }
}
