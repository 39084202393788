@if (loading()) {
  <app-loader></app-loader>
} @else {
  <div class="event-emails-container">
    <div class="admin-event-emails-table-container">
      <admin-table-header-navbar [title]="'adminEmailTemplates.eventTitle' | translate">
        <app-button
          buttonText="{{ 'adminEmailTemplates.attachBtnLabel' | translate }}"
          buttonIconLeft="fa-solid fa-envelope-circle-check"
          [buttonSize]="buttonSize().MEDIUM"
          (click)="attach()" />
      </admin-table-header-navbar>

      <div class="c-my-4">
        <p-divider styleClass="custom-divider-horizontal"></p-divider>
      </div>

      <admin-table-filter-navbar (onSearchTermChange)="searchValue.set($event)">
      </admin-table-filter-navbar>

      <admin-table
        [loading]="loadingEntries()"
        [columns]="columns()"
        [entries]="entries()"
        [paginator]="true"
        [lazy]="false"
        [searchValue]="searchValue()"
        [searchFilterFields]="searchFilterFields()"
        emptyStateKey="adminEmailTemplates.noEventEmailsText"
        emptyStateTitleKey="adminEmailTemplates.noEventEmailsTitle"
        (rowClicked)="onRowClicked($event)">
        <ng-template
          let-entry
          epTemplateKey="langCell">
          <span
            class="flag-icon flag-icon-squared flag-icon-circle flag-icon-{{
              entry.language === 'en' ? 'gb' : entry.language
            }}">
          </span>
        </ng-template>

        <ng-template
          let-entry
          epTemplateKey="htmlCell">
          <app-tag
            [iconClass]="entry.isHtmlMode ? 'fa-regular fa-check' : 'fa-regular fa-xmark'"
            [bgColor]="entry.isHtmlMode ? 'var(--appPrimaryColor)' : '#ff244c'">
          </app-tag>
        </ng-template>

        <ng-template
          let-entry
          epTemplateKey="defaultCell">
          <app-tag
            [iconClass]="entry.default ? 'fa-regular fa-check' : 'fa-regular fa-xmark'"
            [bgColor]="entry.default ? 'var(--appPrimaryColor)' : '#ff244c'">
          </app-tag>
        </ng-template>

        <ng-template
          let-entry
          epTemplateKey="statusCell">
          <app-tag
            [iconClass]="entry.status ? 'fa-regular fa-check' : 'fa-regular fa-xmark'"
            tagText="{{
              (entry.status ? 'adminTable.statusActive' : 'adminTable.statusInactive') | translate
            }}"
            [bgColor]="entry.status ? 'var(--appPrimaryColor)' : '#ff244c'">
          </app-tag>
        </ng-template>

        <ng-template
          let-entry
          epTemplateKey="actionsCell">
          <div class="table-btn-actions-container">
            <app-button
              [buttonType]="buttonType().ROUND"
              [buttonSize]="buttonSize().MEDIUM"
              [buttonStyle]="buttonStyle().FILLED_SECONDARY"
              buttonRoundIcon="far fa-pen-to-square"
              (click)="edit($event, entry)" />
            <app-button
              [buttonType]="buttonType().ROUND"
              [buttonSize]="buttonSize().MEDIUM"
              [buttonStyle]="buttonStyle().FILLED_DANGER"
              buttonRoundIcon="far fa-trash-can"
              (click)="remove($event, entry)" />
          </div>
        </ng-template>
      </admin-table>
    </div>
  </div>
}
