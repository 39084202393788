<div class="new-hub-container">
  <ng-container>
    <div class="new-hub-content grid c-p-0 c-m-0">
      <div class="lg:col-5 md:col-12 sm:col-12 col-12 left p-0">
        <div
          *ngIf="!loading"
          class="left-container">
          <div class="header">
            <h1>{{ 'newHub.title' | translate }}</h1>
            <h2>{{ 'newHub.description' | translate }}</h2>
          </div>

          <form [formGroup]="form">
            <div class="form-container">
              <!-- Title input-->
              <div>
                <app-input-label
                  labelType="required"
                  for="title-input"
                  text="newHub.name">
                </app-input-label>
                <input
                  class="custom-input-text custom-input-text-required"
                  formControlName="title"
                  id="title-input"
                  pInputText
                  type="text"
                  placeholder="{{ 'newHub.nameLabel' | translate }}" />
                <app-input-error-message
                  [showMessage]="form.controls.title.invalid && form.controls.title.touched"
                  errorMessage="{{ 'newHub.hubNameReq' | translate }}">
                </app-input-error-message>
              </div>

              <div class="form-field-block c-mb-4">
                <app-input-label
                  labelType="required"
                  for="link-input"
                  text="newHub.url">
                </app-input-label>
                <div class="p-inputgroup custom-input-group">
                  <span class="p-inputgroup-addon">{{ linkPrefix }}</span>
                  <input
                    formControlName="link"
                    id="link-input"
                    pInputText
                    type="text"
                    placeholder="{{ 'newHub.urlLabel' | translate }}" />
                  <div
                    *ngIf="form.controls.link.value?.length && copyIsClicked"
                    class="tooltip-copied">
                    {{ tooltipText }}
                  </div>
                </div>
                <app-input-error-message
                  [showMessage]="
                    form.controls.link.invalid &&
                    (form.controls.link.touched || form.controls.title.dirty)
                  "
                  [errorMessage]="linkErrorText">
                </app-input-error-message>
              </div>

              <div class="hub-type-wrapper c-mb-4">
                <div
                  class="hub-type-card"
                  (click)="onPublic()"
                  [ngClass]="{
                    selected: isPublicSelected,
                  }">
                  <div class="header">
                    <div class="icon-wrapper">
                      <i class="fa-regular fa-globe"></i>
                    </div>
                    <div class="circle"></div>
                  </div>
                  <div class="title">{{ 'newHub.public' | translate }}</div>
                  <div class="description">{{ 'newHub.publicDescription' | translate }}</div>
                </div>
                <div
                  class="hub-type-card"
                  (click)="onPrivate()"
                  [ngClass]="{
                    selected: !isPublicSelected && isPublicSelected != undefined,
                  }">
                  <div class="header">
                    <div class="icon-wrapper">
                      <i class="fa-regular fa-lock"></i>
                    </div>
                    <div class="circle"></div>
                  </div>
                  <div class="title">{{ 'newHub.private' | translate }}</div>
                  <div class="description">{{ 'newHub.privateDescription' | translate }}</div>
                </div>
              </div>

              <div
                class="form-field-block c-mb-4"
                (click)="fileInput.click()">
                <app-input-label
                  for="link-input"
                  text="Logo">
                </app-input-label>
                <div class="preview-block">
                  <input
                    #fileInput
                    type="file"
                    (change)="onLogoImageUpload($event)" />
                  <div *ngIf="!logoImage">
                    <i class="fa-regular fa-upload"></i>
                    <p>{{ 'newHub.uploadImageSize' | translate }}</p>
                  </div>
                  <div *ngIf="logoImage">
                    <img
                      [src]="logoImage ? logoImage : ''"
                      alt="" />
                  </div>
                </div>
              </div>

              <div class="form-field-block c-mb-4">
                <app-color-picker
                  label="newHub.color"
                  [colorPickerValue]="form.controls.primaryColor.value"
                  (selectedColor)="onSelectPrimaryColor($event)">
                </app-color-picker>
              </div>
            </div>
          </form>

          <div class="footer c-p-2 c-pl-5">
            <div class="col text-right flex justify-content-end align-items-center">
              <button
                class="primary-text-color button-styles c-ml-2"
                pButton
                type="button"
                [label]="'newHub.create' | translate"
                [icon]="isUpdating ? 'pi pi-spin pi-spinner' : null"
                [disabled]="!canCreate"
                (click)="create()"></button>
            </div>
          </div>
        </div>
      </div>
      <div class="lg:col-7 md:col-12 sm:col-12 col-12 hidden-md hidden-sm c-p-0 right">
        <img
          class="signup-cover img-preloader"
          [defaultImage]="imgPreloader"
          [lazyLoad]="(systemAppearanceSettings$ | async).cover | imageSize" />
      </div>
    </div>
  </ng-container>
</div>
