import { Component } from '@angular/core';
import {
  eventPageEventMergeTags,
  hubMergeTags,
  userEventMergeTags,
  eventPageUserMergeTags,
} from 'src/app/core/utils';

@Component({
  selector: 'app-event-page-merge-tags',
  templateUrl: './event-page-merge-tags.component.html',
  styleUrls: ['./event-page-merge-tags.component.scss'],
})
export class EventPageMergeTagsComponent {
  userKeys: string[] = eventPageUserMergeTags;
  userEventKeys: string[] = userEventMergeTags;
  eventKeys: string[] = eventPageEventMergeTags;
  hubKeys: string[] = hubMergeTags;
}
