import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { ClipboardService } from 'ngx-clipboard';
import { TranslateService } from '@ngx-translate/core';

import { ServerTimestamp } from 'src/app/firebase';
import { EventsStore, HubsStore, UsersStore } from 'src/app/core/stores';
import { EventPage, IAudience } from 'src/app/core/models';
import { EventPagesService } from 'src/app/core/services';
import { eventAudience } from 'src/app/core/utils';
import { EventPagePreviewComponent } from '../event-page-preview/event-page-preview.component';

@Component({
  selector: 'app-event-page-html-editor',
  templateUrl: './event-page-html-editor.component.html',
  styleUrls: ['./event-page-html-editor.component.scss'],
})
export class EventPageHtmlEditorComponent implements OnInit {
  @Input() page: EventPage = null;

  loading = true;
  saving = false;
  sendingTestEmail = false;
  showMergeTags = false;
  testEmailForm: UntypedFormGroup;
  form: UntypedFormGroup;
  languages: { name: string; code: string }[] = [];
  audience: IAudience[] = eventAudience;
  isEditing = false;
  copyIsClicked = false;

  readonly baseUrl: string = '';

  constructor(
    private router: Router,
    private translateService: TranslateService,
    private dialogService: DialogService,
    private messageService: MessageService,
    private pagesService: EventPagesService,
    private clipboardService: ClipboardService,
    private usersStore: UsersStore,
    public hubsStore: HubsStore,
    private eventsStore: EventsStore,
  ) {
    this.baseUrl = this.hubsStore.environmentBaseUrl;
  }

  get fullUrlForShowing(): string {
    return this.form.controls.url.value
      ? `${this.hubsStore.environmentBaseUrl}/${this.eventsStore.adminEvent?.link}/${this.form.controls.url.value}`
      : `${this.hubsStore.environmentBaseUrl}/${this.eventsStore.adminEvent?.link}`;
  }

  get tooltipText(): string {
    return !this.copyIsClicked
      ? this.translateService.instant('copyTooltip.hover')
      : this.translateService.instant('copyTooltip.action');
  }

  ngOnInit(): void {
    this.languages = this.translateService
      .getLangs()
      .map((l) => ({ name: this.translateService.instant('languages.' + l), code: l }));
    if (this.page) {
      this.isEditing = true;
    }

    this.createForm();
    this.loading = false;
  }

  preview(): void {
    this.dialogService.open(EventPagePreviewComponent, {
      width: '90%',
      height: '90%',
      dismissableMask: true,
      contentStyle: {
        overflow: 'auto',
        padding: '0',
        'border-radius': '20px',
      },
      data: {
        html: this.form.controls.html.value,
      },
    });
  }

  mergeTags(): void {
    this.showMergeTags = !this.showMergeTags;
  }

  copy(): void {
    const value = this.form.get('html').value ? this.form.get('html').value : '';
    this.clipboardService.copy(value);
  }

  getFullLink(): string {
    return `http://${this.form.controls.url.value}`;
  }

  async save(): Promise<void> {
    if (!this.form.valid) {
      return;
    }

    try {
      this.saving = true;
      const form = this.form.value;

      const newPage = {
        id: this.page ? this.page.id : null,
        title: form.title,
        _title_: form.title.toLowerCase(),
        language: form.language.code,
        status: this.isEditing ? form.status : true,
        audience: form.audience,
        url: form.url,
        html: form.html,
        customIconClass: form.customIconClass,
        isDeleted: false,
        isMobile: form.isMobile,
        updatedAt: ServerTimestamp(),
        updatedBy: this.usersStore.user.id,
        createdAt: this.page ? this.page.createdAt : ServerTimestamp(),
        createdBy: this.page ? this.page.createdBy : this.usersStore.user.id,
      } as EventPage;

      if (this.isEditing) {
        await this.pagesService.update(newPage, this.eventsStore.adminEvent.id);
      } else {
        const newPageResponse = await this.pagesService.create(
          newPage,
          this.eventsStore.adminEvent.id,
        );
        this.router.navigate([
          `/${this.hubsStore.useHubUrl}/admin/event/${this.eventsStore.adminEvent.link}/content/pages/${newPageResponse.id}`,
        ]);
      }

      this.messageService.add({
        severity: 'success',
        summary: this.translateService.instant('adminEventPageTemplate.success'),
        detail: this.translateService.instant('adminEventPageTemplate.succesSave'),
        styleClass: 'custom-toast',
      });
      this.saving = false;
    } catch (error) {
      console.log(error);
      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('error'),
        detail: this.translateService.instant('adminEventPageTemplate.errorSave'),
        styleClass: 'custom-toast',
      });
      this.saving = false;
    }
  }

  copyToClipboard(): void {
    this.clipboardService.copy(`${this.fullUrlForShowing}`);
    this.copyIsClicked = true;
  }

  private createForm(): void {
    this.form = new UntypedFormGroup({
      title: new UntypedFormControl(this.page ? this.page?.title : '', Validators.required),
      language: new UntypedFormControl(
        this.page
          ? this.languages.find(
              (lang: { name: string; code: string }) => lang.code === this.page?.language,
            )
          : null,
        Validators.required,
      ),
      status: new UntypedFormControl(this.page ? this.page?.status : ''),
      isMobile: new UntypedFormControl(this.page ? this.page?.isMobile : false),
      audience: new UntypedFormControl(this.page ? this.page?.audience : ''),
      customIconClass: new UntypedFormControl(this.page ? this.page?.customIconClass : ''),
      url: new UntypedFormControl(this.page ? this.page?.url : ''),
      html: new UntypedFormControl(this.page ? this.page?.html : '', Validators.required),
    });
  }
}
