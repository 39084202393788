import { Component, OnInit, signal } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

import { IEvent, ISession, ITag } from 'src/app/core/models';
import { FormService, SessionsService, TagsService } from 'src/app/core/services';
import { EventsStore, SessionsStore } from 'src/app/core/stores';
import { asyncDelay } from 'src/app/core/utils';
import { SharedModule } from 'src/app/shared';
import {
  CoverPictureMediaFormComponent,
  SaveDiscardActionsComponent,
  ToastComponent,
} from 'src/app/standalone/shared';

@Component({
  selector: 'app-event-session-design',
  standalone: true,
  imports: [
    SharedModule,
    SaveDiscardActionsComponent,
    CoverPictureMediaFormComponent,
    ToastComponent,
  ],
  templateUrl: './event-session-design.component.html',
  styleUrl: './event-session-design.component.scss',
})
export class EventSessionDesignComponent implements OnInit {
  loading = signal(true);
  isUpdating = signal(false);
  form: FormGroup;
  tags = signal<ITag[]>(null);

  constructor(
    private fb: FormBuilder,
    private formService: FormService,
    private tagsService: TagsService,
    private eventsStore: EventsStore,
    private sessionsStore: SessionsStore,
    private sessionsService: SessionsService,
    private messageService: MessageService,
    private translateService: TranslateService,
  ) {}

  get event(): IEvent {
    return this.eventsStore.adminEvent;
  }

  get session(): ISession {
    return this.sessionsStore.adminSession;
  }

  get canUpdate(): boolean {
    return (
      !this.isUpdating() && this.form.valid && this.form.dirty && this.formService.isValueChanged()
    );
  }

  async ngOnInit(): Promise<void> {
    this.createForm();
    this.updateForm();
    this.formService.setForm(this.form);

    const tagsResponse: ITag[] = await this.tagsService.getByIdsOrderedByTitle(this.event.tags);
    this.tags.set(tagsResponse);
    this.loading.set(false);
  }

  setBannerValue(value: File): void {
    this.form.controls.banner.setValue(value);
    this.form.controls.banner.markAsDirty();
  }

  selectTags(tagIds: string[]): void {
    this.form.controls.tags.markAsDirty();
    this.form.patchValue({ tags: tagIds });
  }

  onDiscard(): void {
    this.updateForm();
  }

  async onConfirm(): Promise<void> {
    try {
      this.isUpdating.set(true);
      await asyncDelay(1);
      await this.updateSession();
      this.formService.setForm(this.form);
    } catch (error) {
      console.error(error);
    }
  }

  private createForm(): void {
    this.form = this.fb.group({
      banner: [null],
      videolink: [null],
      shortDescription: [null],
      description: [null],
      tags: [null],
    });
  }

  private updateForm(): void {
    this.form.patchValue({
      ...this.session,
    });
  }

  private async updateSession(): Promise<void> {
    this.isUpdating.set(true);
    const formValues = this.form.getRawValue();

    const sessionForUpdate: ISession = {
      ...this.session,
      ...formValues,
    };

    try {
      await this.sessionsService.update(this.event.id, sessionForUpdate.id, sessionForUpdate);
      this.sessionsStore.setAdminSession({ ...sessionForUpdate });
      this.isUpdating.set(false);
      this.showToastMessage('success', 'adminSession.sessionSuccessfullyUpdated');
    } catch (error) {
      console.log(error);
      this.isUpdating.set(false);
      this.showToastMessage('error', 'adminSession.errorUpdateSession');
    }
  }

  private showToastMessage(severity: 'success' | 'error', detail: string): void {
    this.messageService.add({
      severity,
      summary: this.translateService.instant(severity),
      detail: this.translateService.instant(detail),
      styleClass: 'custom-toast',
    });
  }
}
