import { Injectable } from '@angular/core';
import { makeAutoObservable, observable } from 'mobx';
import { IStage } from '../../models';

@Injectable()
export class StagesStore {
  public stageMap: { [key: string]: IStage } = {};
  public adminStage: IStage = null;

  constructor() {
    makeAutoObservable(this, {
      stageMap: observable.shallow,
    });
  }

  public get mainStage(): IStage {
    return this.stages.find((s) => s.isMainStage);
  }

  public get nonMainStages(): IStage[] {
    return this.stages.filter((stage) => !stage.isMainStage);
  }

  public get stages(): IStage[] {
    return Object.keys(this.stageMap).map((key) => this.stageMap[key]);
  }

  public setAdminStage(stage: IStage): void {
    this.adminStage = stage;
  }

  public unsetAdminStage() {
    this.adminStage = null;
  }

  public getStageName(stage: IStage): string {
    return stage?.name ?? '';
  }

  public getStageById(id: string): IStage {
    return this.stages.find((s) => s.id === id);
  }

  public setStage(stage: IStage): void {
    this.stageMap[stage.id] = stage;
  }

  public setStages(stages: IStage[]): void {
    for (const stage of stages.sort((a, _) => (a.isMainStage ? -1 : +1))) {
      this.stageMap[stage.id] = stage;
    }
  }

  public reset(): void {
    this.stageMap = {};
  }
}
