import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import {
  defaultCoversNames,
  getDefaultCoverWithChangedBgColor,
} from 'src/app/shared/helpers/default-cover-banner-helper';

@Component({
  selector: 'app-update-default-covers',
  templateUrl: './update-default-covers.component.html',
  styleUrls: ['./update-default-covers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpdateDefaultCoversComponent implements OnInit, AfterViewInit {
  @Input() color: string;
  @Input() url: string;
  @ViewChild('svgBannerContainer', { static: false }) svgBannerContainer: ElementRef;
  safeHtml: SafeHtml;

  constructor(
    private sanitizer: DomSanitizer,
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    if (this.color) {
      for (let i = 0; i < defaultCoversNames.length; i++) {
        // check if the string contains the given substring and get the index of the beginning of the substring
        const startIndex = this.url?.indexOf(defaultCoversNames[i]);
        if (startIndex && startIndex !== -1) {
          const defaultCover = this.url.slice(
            startIndex,
            startIndex + defaultCoversNames[i].length,
          );
          this.safeHtml = this.sanitizer.bypassSecurityTrustHtml(
            getDefaultCoverWithChangedBgColor(defaultCover, this.color),
          );
          break;
        }
      }
    }
  }

  ngAfterViewInit() {
    if (!!this.svgBannerContainer) {
      const el = this.svgBannerContainer.nativeElement.children[0];
      this.renderer.setStyle(el, 'border-radius', '1.25rem');
    }
  }
}
