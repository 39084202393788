import { Component } from '@angular/core';

import { HubsStore } from 'src/app/core/stores';
import { useDevice } from 'src/app/core/utils';

@Component({
  selector: 'mobile-navigation',
  templateUrl: './mobile-navigation-footer.component.html',
  styleUrls: ['./mobile-navigation-footer.component.scss'],
})
export class MobileNavigationFooterComponent {
  deviceInfo = useDevice();

  constructor(public hubsStore: HubsStore) {}

  get isIosPWA(): boolean {
    const isInStandaloneMode =
      'standalone' in window.navigator && (window.navigator.standalone as any);
    return this.deviceInfo.mobileOS === 'ios' && isInStandaloneMode;
  }

  isVisibleSidebarItem(item: 'events' | 'academy'): boolean {
    if (!this.hubsStore.hub) {
      return true;
    }

    return this.hubsStore.hub?.[item];
  }
}
