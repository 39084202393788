export const emailsEventActions: { label: string; value: string }[] = [
  { label: 'Hub member invite', value: 'hub-member-invite' },
  { label: 'Event invite user', value: 'event-invite-user' },
  { label: 'Event Brand owner invite', value: 'event-brand-owner-invite' },
  { label: 'Event Brand member invite', value: 'event-brand-member-invite' },
  { label: 'Event Registration Confirmation', value: 'event-registration-confirmation' },
  { label: 'Event Invitation declined', value: 'event-invitation-declined' },
  { label: 'Appointment Invitation', value: 'appointment-invitation' },
  { label: 'Appointment Host declined', value: 'appointment-host-declined' },
  { label: 'Appointment Host accepted', value: 'appointment-host-accepted' },
  { label: 'Verify Email', value: 'verify-email' },
  { label: 'Appointment Guest accepted', value: 'appointment-guest-accepted' },
  { label: 'Purchase confirmation', value: 'purchase-confirmation' },
];

export enum EmailCourseActionTypes {
  // send this type of emails from listeners (users-course.listeners.js)
  COURSE_REGISTRATION = 'course-registration',
  COURSE_COMPLETION = 'course-completion',
  COURSE_CHAPTER_COMPLETION = 'course-chapter-completion',
  COURSE_INSTRUCTOR_INVITE = 'course-instructor-invite',
  COURSE_SUBSCRIBER_INVITE = 'course-subscribe-invite',
}

export const emailsCourseActions: { label: string; value: EmailCourseActionTypes }[] = [
  { label: 'Course Registration', value: EmailCourseActionTypes.COURSE_REGISTRATION },
  { label: 'Course Completion', value: EmailCourseActionTypes.COURSE_COMPLETION },
  { label: 'Course Chapter Completion', value: EmailCourseActionTypes.COURSE_CHAPTER_COMPLETION },
  { label: 'Course Instructor Invite', value: EmailCourseActionTypes.COURSE_INSTRUCTOR_INVITE },
  { label: 'Course Subscriber Invite', value: EmailCourseActionTypes.COURSE_SUBSCRIBER_INVITE },
];
