import { Injectable } from '@angular/core';

import { Firestore, IFirestore, ServerTimestamp } from 'src/app/firebase';
import { CollectionTypes } from 'src/app/shared';
import { StorageService } from '../storage';
import { UsersStore, ProductsStore } from '../../stores';
import { IBrand, IUser, IBrandProduct } from '../../models';
import { HttpClientService } from '../http';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BrandProductsService {
  public listenerForUpdatingView = new Subject<void>();

  private firestore: IFirestore;

  constructor(
    private http: HttpClientService,
    private storageService: StorageService,
    private productsStore: ProductsStore,
    private usersStore: UsersStore,
  ) {
    this.firestore = Firestore();
  }

  public async getEventBrandProducts(eventId: string, brandId: string): Promise<IBrandProduct[]> {
    const productIds: string[] = (
      await this.firestore
        .collection(CollectionTypes.EVENT_PRODUCTS)
        .where('eventId', '==', eventId)
        .where('brandId', '==', brandId)
        .orderBy('productTitle')
        .get()
    ).docs.map((doc) => doc.data().productId);

    const products: IBrandProduct[] = [];

    while (productIds.length) {
      const chunk = productIds.splice(0, 10);

      const query = await this.firestore
        .collection(CollectionTypes.BRAND_PRODUCTS)
        .where('id', 'in', chunk)
        .orderBy('title')
        .get();

      products.push(...query.docs.map((doc) => doc.data() as IBrandProduct));
    }

    this.productsStore.setProducts(products);
    return products;
  }

  public async getAll(brandId: string): Promise<IBrandProduct[]> {
    return (
      await this.firestore
        .collection(CollectionTypes.BRAND_PRODUCTS)
        .where('brandId', '==', brandId)
        .orderBy('title')
        .get()
    ).docs.map((doc) => doc.data() as IBrandProduct);
  }

  public async getOne(productId: string): Promise<IBrandProduct> {
    return (
      await this.firestore
        .collection(CollectionTypes.BRAND_PRODUCTS)
        .where('id', '==', productId)
        .get()
    ).docs[0].data() as IBrandProduct;
  }

  public async create(brand: IBrand, product: IBrandProduct): Promise<void> {
    try {
      const brandProductDocument = this.firestore.collection(CollectionTypes.BRAND_PRODUCTS).doc();

      const payload = {
        ...product,
        id: brandProductDocument.id,
        _title_: product.title.toLowerCase(),
        createdAt: ServerTimestamp(),
        brandId: brand.id,
        _brandName_: brand._name_,
        brandName: brand.name,
        createdBy: this.usersStore.userId,
      };

      if (payload.file instanceof File) {
        const fileName = `file_${new Date().getTime()}`;
        const newFile = await this.storageService.upload(payload.file, 'brands', fileName);
        payload.file = newFile;
      } else {
        payload.file = null;
      }

      if (payload.image instanceof File) {
        const fileName = `file_${new Date().getTime()}`;
        const newImage = await this.storageService.upload(payload.image, 'brands', fileName);
        payload.image = newImage;
      } else {
        payload.image = null;
      }

      await brandProductDocument.set({ ...payload });
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public async update(product: IBrandProduct): Promise<void> {
    const doc: IBrandProduct = (
      await this.firestore.collection(CollectionTypes.BRAND_PRODUCTS).doc(product.id).get()
    ).data() as IBrandProduct;

    const payload = {
      ...product,
      _title_: product.title.toLowerCase(),
      updatedAt: ServerTimestamp(),
      updatedBy: this.usersStore.userId,
    };

    if (payload.file instanceof File) {
      const fileName = `file_${new Date().getTime()}`;
      await this.storageService.delete(doc.file);
      const newFile = await this.storageService.upload(payload.file, 'brands', fileName);
      payload.file = newFile;
    } else if (!payload.file) {
      if (doc.file) {
        await this.storageService.delete(doc.file);
      }
      payload.file = null;
    }

    if (payload.image instanceof File) {
      const fileName = `file_${new Date().getTime()}`;
      await this.storageService.delete(doc.image);
      const newImage = await this.storageService.upload(payload.image, 'brands', fileName);
      payload.image = newImage;
    } else if (!payload.image) {
      if (doc.image) {
        await this.storageService.delete(doc.image);
      }
      payload.image = null;
    }

    await this.firestore
      .collection(CollectionTypes.BRAND_PRODUCTS)
      .doc(product.id)
      .update({ ...payload });
  }

  public delete(id: string): Promise<void> {
    return this.http.delete(`brands/product/${id}`);
  }

  public async getAllPaginated(
    eventId: string,
    order: 'asc' | 'desc',
    entry?: IBrandProduct,
    pageSize: number = 12,
  ): Promise<IBrandProduct[]> {
    const products: IBrandProduct[] = [];
    let productIds: string[] = [];

    (
      await this.firestore
        .collection(CollectionTypes.EVENT_PRODUCTS)
        .where('eventId', '==', eventId)
        .orderBy('productTitle', order)
        .get()
    ).forEach((doc) => {
      const productId = doc.data().productId;

      if (!productIds.includes(productId)) {
        productIds.push(productId);
      }
    });

    const startFromIndex = entry ? productIds.indexOf(entry.id) + 1 : 0;
    productIds = productIds.splice(startFromIndex, pageSize);

    while (productIds.length) {
      const chunk = productIds.splice(0, 10);

      const query = await this.firestore
        .collection(CollectionTypes.BRAND_PRODUCTS)
        .where('id', 'in', chunk)
        .orderBy('title', order)
        .get();

      products.push(...query.docs.map((doc) => doc.data() as IBrandProduct));
    }

    this.productsStore.setProducts(products);
    return products;
  }

  public async getAllBrandProductsByBrandIdPaginated(
    brandId: string,
    order: 'asc' | 'desc',
    entry?: IBrandProduct,
    pageSize: number = 12,
  ): Promise<IBrandProduct[]> {
    let products: IBrandProduct[] = [];
    let query = this.firestore
      .collection(CollectionTypes.BRAND_PRODUCTS)
      .where('brandId', '==', brandId)
      .orderBy('title', order)
      .orderBy('id');

    if (entry) {
      query = query.startAfter(entry.title, entry.id);
    }

    (await query.limit(pageSize).get()).forEach((doc) => {
      const product = doc.data() as IBrandProduct;

      products.push(product);
    });

    return products;
  }

  public async getAllBrandProductsByBrandIdOfTypeDOCUMENTPaginated(
    brandId: string,
    order: 'asc' | 'desc',
    entry?: IBrandProduct,
    pageSize: number = 12,
  ): Promise<IBrandProduct[]> {
    let products: IBrandProduct[] = [];
    let query = this.firestore
      .collection(CollectionTypes.BRAND_PRODUCTS)
      .where('brandId', '==', brandId)
      .where('type', '==', 'PDF')
      .orderBy('title', order)
      .orderBy('id');

    if (entry) {
      query = query.startAfter(entry.title, entry.id);
    }

    (await query.limit(pageSize).get()).forEach((doc) => {
      const product = doc.data() as IBrandProduct;

      products.push(product);
    });

    return products;
  }

  public async getAllBrandProductsByBrandIdOfTypeVIDEOPaginated(
    brandId: string,
    order: 'asc' | 'desc',
    entry?: IBrandProduct,
    pageSize: number = 12,
  ): Promise<IBrandProduct[]> {
    let products: IBrandProduct[] = [];
    let query = this.firestore
      .collection(CollectionTypes.BRAND_PRODUCTS)
      .where('brandId', '==', brandId)
      .where('type', '==', 'Video')
      .orderBy('title', order)
      .orderBy('id');

    if (entry) {
      query = query.startAfter(entry.title, entry.id);
    }

    (await query.limit(pageSize).get()).forEach((doc) => {
      const product = doc.data() as IBrandProduct;

      products.push(product);
    });

    return products;
  }

  public async getAllBrandProductsByBrandIdOfTypeIMAGEPaginated(
    brandId: string,
    order: 'asc' | 'desc',
    entry?: IBrandProduct,
    pageSize: number = 12,
  ): Promise<IBrandProduct[]> {
    let products: IBrandProduct[] = [];
    const imageTypes = ['JPG', 'PNG'];

    let query = this.firestore
      .collection(CollectionTypes.BRAND_PRODUCTS)
      .where('brandId', '==', brandId)
      .where('type', 'in', imageTypes)
      .orderBy('title', order)
      .orderBy('id');

    if (entry) {
      query = query.startAfter(entry.title, entry.id);
    }

    (await query.limit(pageSize).get()).forEach((doc) => {
      const product = doc.data() as IBrandProduct;

      products.push(product);
    });

    return products;
  }

  public async getBookmarked(
    eventId: string,
    order: 'asc' | 'desc',
    entry?: IBrandProduct,
    pageSize: number = 12,
  ): Promise<IBrandProduct[]> {
    const userEventId = this.usersStore.userEventsMap[eventId].id;
    let products: IBrandProduct[] = [];
    const productIds: string[] = [];

    (
      await this.firestore
        .collection(`${CollectionTypes.USER_EVENTS}/${userEventId}/bookmarkedProducts`)
        .get()
    ).forEach((doc) => {
      if (!productIds.includes(doc.id)) {
        productIds.push(doc.id);
      }
    });

    let queryUsersByIds = null;

    while (productIds.length > 0) {
      const chunk = productIds.splice(0, 10);

      queryUsersByIds = await this.firestore
        .collection(CollectionTypes.BRAND_PRODUCTS)
        .where('id', 'in', chunk)
        .orderBy('title', order)
        .get();

      products.push(...queryUsersByIds.docs.map((doc) => doc.data() as IUser));
    }

    const startFromIndex = entry ? products.map((u) => u.id).indexOf(entry.id) + 1 : 0;
    products = products.splice(startFromIndex, pageSize);
    this.productsStore.setProducts(products);

    return products;
  }
}
