<ng-container *ngIf="loading">
  <app-loader class="admin-session-loading-container"></app-loader>
</ng-container>

<div
  *ngIf="!loading"
  class="admin-session-form-container">
  <div class="header c-p-5">
    <div class="title">
      {{ 'adminSession.newSessionTitle' | translate }}
    </div>
    <i
      class="fa-regular fa-xmark close-icon"
      (click)="onCloseDialog()"></i>
  </div>

  <form [formGroup]="form">
    <div class="c-p-5">
      <!-- Title input -->
      <div class="form-field-block">
        <app-input-label
          labelType="required"
          for="title-input"
          text="adminSession.titleLabel">
        </app-input-label>
        <input
          class="custom-input-text custom-input-text-required"
          formControlName="title"
          id="title-input"
          pInputText
          type="text"
          placeholder="{{ 'adminSession.titleLabel' | translate }}" />
        <app-input-error-message
          [showMessage]="form.controls.title.invalid && form.controls.title.touched"
          errorMessage="{{ 'adminSession.titleReq' | translate }}">
        </app-input-error-message>
      </div>

      <!-- Date input -->
      <div class="form-field-block">
        <app-input-label
          labelType="required"
          for="date-input"
          text="adminSession.startDateLabel">
        </app-input-label>
        <p-calendar
          class="custom-calendar custom-calendar-required"
          panelStyleClass="custom-calendar-panel-style-class"
          appendTo="body"
          formControlName="date"
          [monthNavigator]="true"
          [yearNavigator]="true"
          [showIcon]="true"
          dateFormat="dd/mm/yy"
          yearRange="1900:2050"
          inputId="date-input"
          icon="fak fa-lox-calendar"
          placeholder="{{ 'adminSession.startDateLabel' | translate }}">
        </p-calendar>
        <app-input-error-message
          [showMessage]="form.controls.date.invalid && form.controls.date.touched"
          errorMessage="{{ 'application.forms.required' | translate }}">
        </app-input-error-message>
      </div>

      <div class="two-elements-block c-p-0">
        <!-- Time start input -->
        <div>
          <app-input-label
            labelType="required"
            for="start-time-input"
            text="adminSession.startTimeLabel">
          </app-input-label>
          <p-calendar
            class="custom-calendar custom-calendar-time custom-calendar-required"
            formControlName="start"
            placeholder="{{ 'adminSession.startTimeLabel' | translate }}"
            inputId="start-time-input"
            [timeOnly]="true"
            [showIcon]="true"
            icon="far fa-clock">
          </p-calendar>
          <app-input-error-message
            [showMessage]="form.controls.start.invalid && form.controls.start.touched"
            errorMessage="{{ 'application.forms.required' | translate }}">
          </app-input-error-message>
        </div>

        <!-- Time end input -->
        <div>
          <app-input-label
            labelType="required"
            for="end-time-input"
            text="adminSession.endTimeLabel">
          </app-input-label>
          <p-calendar
            class="custom-calendar custom-calendar-time custom-calendar-required"
            formControlName="end"
            placeholder="{{ 'adminSession.endTimeLabel' | translate }}"
            inputId="end-time-input"
            [timeOnly]="true"
            [showIcon]="true"
            icon="far fa-clock">
          </p-calendar>
          <app-input-error-message
            [showMessage]="form.controls.end.invalid && form.controls.end.touched"
            errorMessage="{{ 'application.forms.required' | translate }}">
          </app-input-error-message>
        </div>
      </div>

      <!-- Stage Id input -->
      <div class="form-field-block c-mb-4">
        <app-input-label
          for="stage-input"
          text="adminSession.stage">
        </app-input-label>
        <p-dropdown
          class="custom-dropdown"
          panelStyleClass="custom-dropdown-panel"
          [options]="eventStages"
          formControlName="stageId"
          optionLabel="name"
          optionValue="id"
          [showClear]="true"
          inputId="stage-input"
          appendTo="body"
          placeholder="{{ 'adminSession.stagePlaceholder' | translate }}">
        </p-dropdown>
      </div>
    </div>
  </form>

  <div class="footer c-p-2 c-pl-5">
    <div class="col text-left form-field-input-checkbox-block c-p-0">
      <p-checkbox
        class="custom-input-checkbox-sm"
        binary="true"
        [formControl]="createAnotherControl"
        inputId="another-course-input">
      </p-checkbox>
      <app-input-label
        labelType="switch-checkbox"
        for="another-course-input"
        text="adminSession.createAnother">
      </app-input-label>
    </div>
    <div class="col text-right flex justify-content-end align-items-center">
      <app-button
        buttonText="{{ 'adminSession.cancelButton' | translate }}"
        buttonIconLeft="fa-regular fa-xmark"
        [buttonStyle]="buttonStyle.GHOST_PRIMARY"
        [buttonSize]="buttonSize.MEDIUM"
        (click)="onCloseDialog()" />
      <app-button
        class="c-ml-2"
        buttonText="{{ 'adminSession.createBtn' | translate }}"
        buttonIconLeft="far fa-check"
        [buttonSize]="buttonSize.MEDIUM"
        [loading]="isUpdating"
        [disabled]="!canUpdate"
        (click)="create()" />
    </div>
  </div>
</div>
