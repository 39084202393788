import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import {
  ThemesService,
  AuthenticationService,
  TranslationsService,
  SettingsService,
  HubPagesService,
} from 'src/app/core/services';
import { goToLink } from 'src/app/core/utils';
import { IHub, IHubPage, IPrivacySettingsPage, Theme } from 'src/app/core/models';
import { PrivacyPageTypes } from 'src/app/shared/enums';
import { environment } from 'src/environments/environment';
import { HubsStore, UsersStore } from 'src/app/core//stores';

declare const UC_UI: any;

@Component({
  selector: 'app-footer-navigation',
  templateUrl: './footer-navigation.component.html',
  styleUrls: ['./footer-navigation.component.scss'],
})
export class FooterNavigationComponent implements OnInit {
  isAuthenticated$: Observable<boolean>;
  languages: string[] = [];
  currentLanguage: string;
  selectLanguages: any[] = [];
  selectedLanguage: any = null;
  isDarkThemeActive = true;
  availableThemes: Theme[] = [];
  activeTheme: Theme;
  copyright: string;
  privacySettingsPages: IPrivacySettingsPage[] | IHubPage[];
  privacyPageTypes = PrivacyPageTypes;
  environment = environment;
  currentYear: string;

  isCurrentEnvStagingOrLocal = environment.env === 'STAGING' || environment.env === 'LOCAL';

  get hub(): IHub {
    return this.hubsStore.hub;
  }

  constructor(
    private authenticationService: AuthenticationService,
    private translationsService: TranslationsService,
    private themesService: ThemesService,
    private settingsService: SettingsService,
    private hubsStore: HubsStore,
    private hubPagesService: HubPagesService,
    public usersStore: UsersStore,
  ) {
    this.isAuthenticated$ = this.authenticationService.isAuthenticated$;

    this.languages = this.translationsService.getLanguages;
    this.currentLanguage = this.translationsService.getCurrentLanguage;

    this.availableThemes = this.themesService.availableThemes;
    this.activeTheme = this.themesService.activeTheme;
    this.isDarkThemeActive = this.activeTheme.name === 'dark';

    this.selectLanguages = this.translationsService.getLanguages.map((l) => {
      const item = { name: this.translationsService.getTranslationSync('languages.' + l), code: l };
      if (this.currentLanguage === l) {
        this.selectedLanguage = item;
      }
      return item;
    });

    this.currentYear = `${new Date().getFullYear()}`;
  }

  async ngOnInit(): Promise<void> {
    this.copyright = await this.settingsService.getCopyright();
    this.hub ? this.getHubPages() : this.getPrivacySettings();
  }

  private async getHubPages(): Promise<void> {
    this.privacySettingsPages = await this.hubPagesService.getHubPagesByHubId(this.hub.id);
  }

  private async getPrivacySettings(): Promise<void> {
    this.privacySettingsPages = await this.settingsService.getAllPages();
  }

  isPrivacyPageEnabled(typeId: number): boolean {
    let page = this.privacySettingsPages.find((page) => page.type == typeId);
    if (page) {
      return page.status;
    } else {
      return false;
    }
  }

  hasExternalUrl(typeId: number): boolean {
    return this.privacySettingsPages.find((page) => page.type == typeId).url.length > 0;
  }

  public async setLanguage(lang: any): Promise<void> {
    await this.translationsService.setLanguage(lang.code);
  }

  public setTheme(isDarkThemeActive: any): void {
    this.themesService.setThemeByName(isDarkThemeActive ? 'dark' : 'light');
  }

  public onPrivacyPageClick(typeId: number): void {
    const externalUrl = this.privacySettingsPages.find((page) => page.type === typeId).url;
    goToLink(externalUrl);
  }

  openCookiesManager(): void {
    // open cookies manager pop-up
    UC_UI.showSecondLayer();
  }
}
