import * as moment from 'moment';
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { UsersService } from 'src/app/core/services';
import { UsersStore } from 'src/app/core/stores';

@Component({
  selector: 'app-language-switcher',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, DropdownModule, TranslateModule],
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.scss'],
})
export class LanguageSwitcherComponent implements OnInit {
  selectedLanguage = new FormControl(null);
  selectLanguages: { name: string; code: string }[] = [];
  currentLanguage: string;

  get selectedLanguageCode(): string {
    return this.selectedLanguage.value?.code;
  }

  constructor(
    private translateService: TranslateService,
    private usersStore: UsersStore,
    private usersService: UsersService,
  ) {}

  ngOnInit(): void {
    this.currentLanguage = this.translateService.currentLang;
    this.selectLanguages = this.translateService.getLangs().map((lang: string) => {
      const item = { name: this.translateService.instant('languages.' + lang), code: lang };
      if (this.currentLanguage === lang) {
        this.selectedLanguage.setValue(item);
      }

      return item;
    });
  }

  setLanguage(lang: { name: string; code: string }): void {
    if (lang?.code && this.usersStore?.user?.id) {
      this.usersService.update(this.usersStore.user.id, { systemLanguage: lang.code });
    }

    this.translateService.use(lang.code);
    localStorage.setItem('language', lang.code);
    moment.locale(lang.code);
  }
}
