<div [class.container-pb]="isSearchVisible">
  <div class="header-container">
    <div class="logo header-item">
      <img
        [src]="logo | imageSize"
        alt="{{ 'shared.logo' | translate }}"
        (click)="onLogoClick()" />
    </div>

    <i
      *ngIf="serviceWorkerService.isNewVersionAvailable$ | async"
      class="fa-light fa-code-compare header-item"
      (click)="serviceWorkerService.reloadApp()">
    </i>
    <i
      *ngIf="isSearchAvailable && !hideEventSearch && !searchLoading"
      class="fa-regular fa-magnifying-glass header-item"
      (click)="toggleSearch()">
    </i>
    <button
      *ngIf="(isAuthenticated$ | async) === false && hubsStore.hub?.isSalesforceSSO"
      class="w-and-v-btn header-item"
      (click)="openWVSigninDialog()">
      <img [src]="WVLogo | imageSize" />
      <span>{{ 'signin.signupWithWV' | translate }}</span>
    </button>

    @if ((isAuthenticated$ | async) === false && !isLogoutScreen() && hubsStore.hub) {
      <button
        class="auth-btn sign-up-btn"
        (click)="onSignUp()">
        {{ 'mainNavigation.signUpBtn' | translate }}
      </button>

      <button
        class="auth-btn sign-in-btn"
        [ngClass]="{
          'c-mr-2': usersStore.user,
          'c-mr-0': !usersStore.user,
          'c-p-0': !usersStore.user,
        }"
        (click)="onSignIn()">
        {{ 'mainNavigation.login_btn' | translate }}
      </button>
    }
    <img
      *ngIf="usersStore.user"
      class="user-profile header-item"
      src="{{ usersStore.userProfilePic | imageSize }}"
      (click)="profilePopUp.toggle($event)" />
    <p-overlayPanel
      #profilePopUp
      styleClass="default-popup-menu-panel">
      <ng-template pTemplate>
        <div class="profile-popup-menu-container">
          <ul>
            <li (click)="openView('/dashboard')">
              <i class="fak fa-lox-home"></i>
              <a>{{ 'mainNavigation.dashboardBtn' | translate }}</a>
            </li>
            <li
              (click)="openProfile('profile')"
              *ngIf="isGlobalHelpVisible">
              <i class="fa-regular fa-circle-user"></i>
              <a>{{ 'mainNavigation.userProfileBtn' | translate }}</a>
            </li>

            <li
              [icon]="'pi pi-spin pi-spinner'"
              (click)="openCustomerProfile()">
              <i class="fa-regular fa-money-check"></i>
              <a>{{ 'mainNavigation.billingBtn' | translate }}</a>
              <i
                *ngIf="billingPortalLoading"
                class="fas fa-circle-notch fa-spin billing-loader"></i>
            </li>

            <div class="divider-container-1">
              <p-divider styleClass="custom-divider-horizontal"></p-divider>
            </div>

            <li
              *ngIf="usersStore.user?.brandId"
              (click)="inBrandDashboard ? openView('/dashboard') : openView('/mybrand')">
              <i class="fak fa-lox-crown"></i>
              <a *ngIf="inBrandDashboard">{{ 'mainNavigation.switchUserModelBtn' | translate }}</a>
              <a *ngIf="!inBrandDashboard">{{
                'mainNavigation.switchBrandModelBtn' | translate
              }}</a>
            </li>

            <!-- TODO uncomment after implementation -->
            <!-- <li (click)="openProfile('plans')">
                    <i class="fak fa-lox-medal color-yellow"></i>
                    <a class="color-yellow">{{ 'mainNavigation.upgradePlanBtn' | translate }}</a>
                </li> -->

            <!-- TODO remove ngIf after implementation Upgrade Plan logic -->
            <div
              class="divider-container-1"
              *ngIf="usersStore.user?.brandId">
              <p-divider styleClass="custom-divider-horizontal"></p-divider>
            </div>

            <li
              (click)="onNavigateToHelp()"
              *ngIf="isGlobalHelpVisible">
              <i class="far fa-info-circle"></i>
              <a>{{ 'mainNavigation.helpBtn' | translate }}</a>
            </li>
            <li (click)="logout()">
              <i class="far fa-sign-out"></i>
              <a>{{ 'mainNavigation.logOutBtn' | translate }}</a>
            </li>

            <div class="divider-container-2">
              <p-divider styleClass="custom-divider-horizontal"></p-divider>
            </div>

            <form class="form">
              <p-dropdown
                class="custom-dropdown-without-border"
                panelStyleClass="custom-dropdown-panel"
                [(ngModel)]="selectedLanguage"
                [options]="selectLanguages"
                name="language"
                optionLabel="name"
                (onChange)="setLanguage($event.value)">
                <ng-template pTemplate="selectedItem">
                  <span
                    class="flag-icon flag-icon-squared flag-icon-circle flag-icon-{{
                      selectedLanguage.code === 'en' ? 'gb' : selectedLanguage.code
                    }}">
                  </span>
                  {{ 'languages.' + selectedLanguage.code | translate }}
                </ng-template>
                <ng-template
                  let-lang
                  pTemplate="item">
                  <span
                    class="flag-icon flag-icon-squared flag-icon-circle flag-icon-{{
                      lang.code === 'en' ? 'gb' : lang.code
                    }}">
                  </span>
                  {{ 'languages.' + lang.code | translate }}
                </ng-template>
              </p-dropdown>
            </form>
          </ul>
        </div>
      </ng-template>
    </p-overlayPanel>
  </div>
  <app-search
    *ngIf="isSearchVisible && isSearchAvailable && !hideEventSearch && !searchLoading"></app-search>
</div>
