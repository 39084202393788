import { Component } from '@angular/core';
import { SkeletonModule } from 'primeng/skeleton';

@Component({
  selector: 'app-course-card-skeleton',
  standalone: true,
  imports: [SkeletonModule],
  templateUrl: './course-card-skeleton.component.html',
  styleUrl: './course-card-skeleton.component.scss',
})
export class CourseCardSkeletonComponent {}
