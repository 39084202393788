import { EventsStore } from './../../core/stores/events/events.store';
import { Router } from '@angular/router';
import { StagesStore } from 'src/app/core/stores';
import { StagesService } from './../../core/services/stages/stages.service';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Route,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AdminStageAccessGuard {
  constructor(
    private router: Router,
    private stagesService: StagesService,
    private stagesStore: StagesStore,
    private eventsStore: EventsStore,
  ) {}

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    let currentEntry = this.router.getCurrentNavigation().extras.state
      ? this.router.getCurrentNavigation().extras.state.entry
        ? this.router.getCurrentNavigation().extras.state.entry
        : null
      : null;

    if (!currentEntry) {
      currentEntry = await this.stagesService.getOne(
        this.eventsStore.adminEvent.id,
        next.params.stageId,
        true,
      );
    }

    this.stagesStore.setAdminStage(currentEntry);
    return currentEntry ? true : false;
  }
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.stagesStore.unsetAdminStage();
    return true;
  }
  canLoad(
    route: Route,
    segments: UrlSegment[],
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
}
