<div class="notifications-container">
  <p-tabView
    styleClass="notification-tab-view"
    class="tab-view">
    <p-tabPanel header="{{ 'notifications.notifications' | translate }}">
      <div
        *ngIf="isLoading"
        class="empty-container">
        <app-loader></app-loader>
      </div>
      <div
        *ngIf="!isLoading && notifications.length"
        class="notifications-list"
        [ngClass]="{ 'no-notifications': notifications && !notifications.length }">
        <ng-container *ngFor="let notification of notifications; trackBy: trackByFn">
          <div
            *ngIf="notification.isLoading"
            class="notification-loading-box">
            <app-loader></app-loader>
          </div>
          <ng-container
            *ngIf="!notification.isLoading"
            [ngSwitch]="notification.type">
            <div class="notification-container">
              <app-appointment-new-invite-notification
                *ngSwitchCase="notificationsTypes.APPOINTMENT_NEW_INVITE"
                [notification]="notification"
                (accept)="onAccept($event)"
                (decline)="onDecline($event)">
              </app-appointment-new-invite-notification>
            </div>
            <div class="notification-container">
              <app-appointment-canceled-invite-notification
                *ngSwitchCase="notificationsTypes.APPOINTMENT_CANCELED_INVITE"
                [notification]="notification">
              </app-appointment-canceled-invite-notification>
            </div>
          </ng-container>
        </ng-container>
      </div>
      <div
        *ngIf="!isLoading && notifications.length"
        class="notification-actions">
        <button (click)="onGoToViewNotifications('notifications')">
          {{ 'notifications.allNotificationsBtn' | translate }}
        </button>
      </div>
      <div
        *ngIf="!isLoading && !notifications.length"
        class="flex justify-content-center">
        <app-empty-state
          [icon]="'far fa-bell'"
          title="{{ 'notifications.noNotificationFirstLine' | translate }}"
          text="{{ 'notifications.noNotificationsSecondLine' | translate }}"
          [classes]="'notifications'"
          [noBackground]="true">
        </app-empty-state>
      </div>
    </p-tabPanel>

    <p-tabPanel header="{{ 'notifications.myMeetings' | translate }}">
      <div
        *ngIf="isLoading"
        class="empty-container">
        <app-loader></app-loader>
      </div>
      <div
        *ngIf="!isLoading && myMeetingsNotifications.length"
        class="notifications-list"
        [ngClass]="{
          'no-notifications': myMeetingsNotifications && !myMeetingsNotifications.length,
        }">
        <ng-container *ngFor="let notification of myMeetingsNotifications; trackBy: trackByFn">
          <div
            *ngIf="notification.isLoading"
            class="notification-loading-box">
            <app-loader></app-loader>
          </div>
          <ng-container
            *ngIf="!notification.isLoading"
            [ngSwitch]="notification.type">
            <div class="notification-container">
              <app-appointment-accepted-invite-notification
                *ngSwitchCase="notificationsTypes.APPOINTMENT_ACCEPTED_INVITE"
                [notification]="notification"
                (joiningMeeting)="onJoiningMeeting($event)">
              </app-appointment-accepted-invite-notification>
            </div>
            <div class="notification-container">
              <app-appointment-accepted-notification
                *ngSwitchCase="notificationsTypes.APPOINTMENT_ACCEPTED"
                [notification]="notification"
                (joiningMeeting)="onJoiningMeeting($event)">
              </app-appointment-accepted-notification>
            </div>
          </ng-container>
        </ng-container>
      </div>
      <div
        *ngIf="!isLoading && myMeetingsNotifications.length"
        class="notification-actions">
        <button (click)="onGoToViewNotifications('meetings')">
          {{ 'notifications.viewAllMeetings' | translate }}
        </button>
      </div>
      <div
        *ngIf="!isLoading && !myMeetingsNotifications.length"
        class="flex justify-content-center">
        <app-empty-state
          [icon]="'far fa-bell'"
          title="{{ 'notifications.noNotificationFirstLine' | translate }}"
          text="{{ 'notifications.noNotificationsSecondLine' | translate }}"
          [classes]="'notifications'"
          [noBackground]="true">
        </app-empty-state>
      </div>
    </p-tabPanel>
  </p-tabView>
</div>
