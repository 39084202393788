import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

import { HubsStore } from 'src/app/core/stores';
import { FeatureHeaderComponent, FeatureMainContentComponent } from 'src/app/standalone/admin';

@Component({
  selector: 'app-hub-settings-container',
  standalone: true,
  imports: [CommonModule, FeatureHeaderComponent, FeatureMainContentComponent],
  templateUrl: './hub-settings-container.component.html',
  styleUrls: ['./hub-settings-container.component.scss'],
})
export class HubSettingsContainerComponent implements OnInit {
  items: MenuItem[];

  constructor(
    private translateService: TranslateService,
    private hubsStore: HubsStore,
  ) {}

  ngOnInit(): void {
    this.items = [
      {
        label: this.translateService.instant('adminHub.profile'),
        icon: 'far fa-display',
        routerLink: `/${this.hubsStore.useHubUrl}/admin/hub-settings/profile`,
      },
      {
        label: this.translateService.instant('adminHub.emailSender'),
        icon: 'fa-regular fa-envelope',
        routerLink: `/${this.hubsStore.useHubUrl}/admin/hub-settings/email-sender`,
      },
      {
        label: this.translateService.instant('adminHub.tags'),
        icon: 'far fa-tag item-icon',
        routerLink: `/${this.hubsStore.useHubUrl}/admin/hub-settings/hub-tags`,
      },
      {
        label: this.translateService.instant('adminHub.admins'),
        icon: 'fa-regular fa-user-group',
        routerLink: `/${this.hubsStore.useHubUrl}/admin/hub-settings/admins`,
      },
      // TODO: uncomment when fixing hub pages logic
      // {
      //   label: this.translateService.instant('adminHub.pages'),
      //   icon: 'fa-regular fa-file-circle-info',
      //   routerLink: `/${this.hubsStore.useHubUrl}/admin/hub-settings/hub-pages`
      // },
      {
        label: this.translateService.instant('adminHub.plans'),
        icon: 'far fa-address-card',
        routerLink: `/${this.hubsStore.useHubUrl}/admin/hub-settings/plans`,
      },
      {
        label: this.translateService.instant('adminHub.privacy'),
        icon: 'fa-regular fa-user-lock',
        routerLink: `/${this.hubsStore.useHubUrl}/admin/hub-settings/privacy`,
      },
    ];
  }
}
