<ng-container *ngIf="loading">
  <app-loader></app-loader>
</ng-container>

<div *ngIf="!loading">
  <form [formGroup]="form">
    <!-- Ticket name & Details block -->
    <div class="form-block c-mt-8 c-mb-9">
      <div class="left-side">
        <h2 class="title">{{ 'adminTicket.nameDetailsTitle' | translate }}</h2>
        <p class="description">{{ 'adminTicket.nameDetailsDescription' | translate }}</p>
      </div>
      <div class="right-side">
        <!-- Name input -->
        <div class="form-field-block">
          <app-input-label
            labelType="required"
            for="name-input"
            text="adminTicket.nameLabel">
          </app-input-label>
          <input
            class="custom-input-text custom-input-text-required"
            formControlName="name"
            id="name-input"
            pInputText
            type="text"
            placeholder="{{ 'adminTicket.nameLabel' | translate }}" />
          <app-input-error-message
            [showMessage]="form.controls.name.invalid && form.controls.name.touched"
            errorMessage="{{ 'application.forms.required' | translate }}">
          </app-input-error-message>
        </div>

        <!-- Info input -->
        <div class="form-field-block c-mb-4">
          <app-input-label
            for="info-input"
            text="adminTicket.infoLabel">
          </app-input-label>
          <input
            class="custom-input-text"
            formControlName="info"
            id="info-input"
            pInputText
            type="text"
            placeholder="{{ 'adminTicket.infoLabel' | translate }}" />
        </div>

        <!-- Quantity Total input -->
        <div class="form-field-block">
          <app-input-label
            labelType="required"
            for="quantity-total-input"
            text="adminTicket.quantityTotalLabel">
          </app-input-label>
          <input
            class="custom-input-text custom-input-text-required"
            formControlName="quantityTotal"
            id="quantity-total-input"
            pInputText
            type="text"
            placeholder="{{ 'adminTicket.quantityTotalLabel' | translate }}" />
          <app-input-error-message
            [showMessage]="
              form.controls.quantityTotal.invalid && form.controls.quantityTotal.touched
            "
            [errorMessage]="
              form.controls.quantityTotal.hasError('pattern')
                ? ('application.forms.validNumbers' | translate)
                : ('application.forms.required' | translate)
            ">
          </app-input-error-message>
        </div>

        <div class="two-elements-block mb-4">
          <!-- Minimum quantity input -->
          <div>
            <app-input-label
              labelType="required"
              for="min-quantity-input"
              text="adminTicket.minimumQuantity">
            </app-input-label>
            <p-inputNumber
              #inputNumberMin
              class="custom-input-number custom-input-number-required"
              inputId="min-quantity-input"
              formControlName="minimumQuantity"
              min="1"
              max="100">
            </p-inputNumber>
            <p class="input-note">
              {{ 'adminTicket.minimumQuantityNote' | translate }}
            </p>
          </div>

          <!-- Maximum quantity input -->
          <div>
            <app-input-label
              labelType="required"
              for="max-quantity-input"
              text="adminTicket.maximumQuantity">
            </app-input-label>
            <p-inputNumber
              #inputNumberMax
              class="custom-input-number custom-input-number-required"
              inputId="max-quantity-input"
              formControlName="maximumQuantity"
              min="1"
              max="100">
            </p-inputNumber>
            <p class="input-note">
              {{ 'adminTicket.maximumQuantityNote' | translate }}
            </p>
          </div>
        </div>

        <p-divider styleClass="custom-divider-horizontal"></p-divider>

        <!-- Include badge input -->
        <p class="ticket-pdf-title c-mb-0 c-ml-8">{{ 'adminTicket.ticketPDF' | translate }}</p>
        <div class="form-field-input-switch-block c-mt-2">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="isIncludedBadge"
            inputId="include-badge-input">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="include-badge-input"
            text="adminTicket.badge">
          </app-input-label>
        </div>

        <!-- Published input -->
        <div class="form-field-input-switch-block c-mt-4">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="isPublished"
            inputId="publish-ticket-input">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="publish-ticket-input"
            text="adminTicket.publishTicketLabel">
          </app-input-label>
        </div>
      </div>
    </div>

    <p-divider styleClass="custom-divider-horizontal"></p-divider>

    <!-- Duration & Price block -->
    <div class="form-block c-my-9">
      <div class="left-side">
        <h2 class="title">{{ 'adminTicket.durationPriceTitle' | translate }}</h2>
        <p class="description">{{ 'adminTicket.durationPriceDescription' | translate }}</p>
        <p
          *ngIf="isStripeConnected && !stripeConnectId"
          class="description">
          {{ 'adminTicket.durationPriceDescriptionStripe' | translate }}
        </p>
      </div>
      <div class="right-side">
        <div class="two-elements-block">
          <!-- Start Date input -->
          <div>
            <app-input-label
              labelType="required"
              for="start-date-input"
              text="adminTicket.startDateLabel">
            </app-input-label>
            <p-calendar
              class="custom-calendar custom-calendar-required"
              formControlName="startDate"
              [monthNavigator]="true"
              [yearNavigator]="true"
              [minDate]="minDate"
              [showIcon]="true"
              dateFormat="dd/mm/yy"
              yearRange="1900:2050"
              inputId="start-date-input"
              icon="far fa-calendar-day"
              placeholder="{{ 'adminTicket.startDateLabel' | translate }}">
            </p-calendar>
            <app-input-error-message
              [showMessage]="form.controls.startDate.invalid && form.controls.startDate.touched"
              errorMessage="{{ 'application.forms.required' | translate }}">
            </app-input-error-message>
          </div>

          <!-- Start Time input -->
          <div>
            <app-input-label
              for="start-time-input"
              text="adminTicket.timeLabel">
            </app-input-label>
            <p-calendar
              class="custom-calendar custom-calendar-time"
              formControlName="startTime"
              placeholder="{{ 'adminTicket.timeLabel' | translate }}"
              inputId="start-time-input"
              [timeOnly]="true"
              [showIcon]="true"
              icon="far fa-clock">
            </p-calendar>
          </div>
        </div>

        <div class="two-elements-block">
          <!-- End Date input -->
          <div>
            <app-input-label
              labelType="required"
              for="end-date-input"
              text="adminTicket.endDateLabel">
            </app-input-label>
            <p-calendar
              class="custom-calendar custom-calendar-required"
              formControlName="endDate"
              [monthNavigator]="true"
              [yearNavigator]="true"
              [minDate]="minDate"
              [showIcon]="true"
              dateFormat="dd/mm/yy"
              yearRange="1900:2050"
              inputId="end-date-input"
              icon="far fa-calendar-day"
              placeholder="{{ 'adminTicket.endDateLabel' | translate }}">
            </p-calendar>
            <app-input-error-message
              [showMessage]="form.controls.endDate.invalid && form.controls.endDate.touched"
              errorMessage="{{ 'application.forms.required' | translate }}">
            </app-input-error-message>
          </div>

          <!-- End Time input -->
          <div>
            <app-input-label
              for="end-time-input"
              text="adminTicket.timeLabel">
            </app-input-label>
            <p-calendar
              class="custom-calendar custom-calendar-time"
              formControlName="endTime"
              placeholder="{{ 'adminTicket.timeLabel' | translate }}"
              inputId="end-time-input"
              [timeOnly]="true"
              [showIcon]="true"
              icon="far fa-clock">
            </p-calendar>
          </div>
        </div>

        <!-- Paid input -->
        <div class="form-field-input-switch-block c-mb-4">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="isPaid"
            inputId="paid-ticket-input">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="paid-ticket-input"
            text="adminTicket.paidTicketLabel">
          </app-input-label>
        </div>

        <div
          *ngIf="form.controls.isPaid.value"
          class="two-elements-block">
          <!-- Currency input -->
          <div>
            <app-input-label
              [labelType]="hasValidator('currency') ? 'required' : 'usual'"
              for="currency-input"
              text="adminTicket.currency">
            </app-input-label>
            <p-dropdown
              class="custom-dropdown"
              [class.custom-dropdown-required]="hasValidator('currency')"
              panelStyleClass="custom-dropdown-panel"
              [options]="currencies"
              formControlName="currency"
              inputId="currency-input"
              placeholder="{{ 'adminTicket.currency' | translate }}">
            </p-dropdown>
            <app-input-error-message
              [showMessage]="form.controls.currency.invalid && form.controls.currency.touched"
              errorMessage="{{ 'application.forms.required' | translate }}">
            </app-input-error-message>
          </div>

          <!-- Price input -->
          <div>
            <app-input-label
              [labelType]="hasValidator('price') ? 'required' : 'usual'"
              for="price-input"
              text="adminTicket.priceLabel">
            </app-input-label>
            <input
              class="custom-input-text"
              [class.custom-input-text-required]="hasValidator('price')"
              formControlName="price"
              id="price-input"
              pInputText
              type="number"
              placeholder="{{ 'adminTicket.priceLabel' | translate }}" />
            <app-input-error-message
              [showMessage]="form.controls.price.invalid && form.controls.price.touched"
              [errorMessage]="
                form.controls.price.hasError('min')
                  ? ('adminTicket.minAmountErrorMessage' | translate)
                  : ('application.forms.required' | translate)
              ">
            </app-input-error-message>
          </div>

          <!-- Ticket Type -->
          <div>
            <app-input-label
              [labelType]="hasValidator('ticketType') ? 'required' : 'usual'"
              for="role-input"
              text="adminTicket.ticketTypeLabel">
            </app-input-label>
            <p-dropdown
              class="custom-dropdown"
              panelStyleClass="custom-dropdown-panel"
              [options]="ticketTypes"
              optionLabel="name"
              optionValue="code"
              formControlName="ticketType"
              inputId="ticket-type-input"
              placeholder="{{ 'adminTicket.ticketTypeLabel' | translate }}">
            </p-dropdown>
            <app-input-error-message
              [showMessage]="form.controls.ticketType.invalid && form.controls.ticketType.touched"
              errorMessage="{{ 'application.forms.required' | translate }}">
            </app-input-error-message>
          </div>
        </div>
      </div>
    </div>

    <p-divider styleClass="custom-divider-horizontal"></p-divider>

    <!-- Event Access block -->
    <div class="form-block c-my-9">
      <div class="left-side">
        <h2 class="title">{{ 'adminTicket.eventAccessTitle' | translate }}</h2>
        <p class="description">{{ 'adminTicket.eventAccessDescription' | translate }}</p>
      </div>
      <div class="right-side">
        <!-- Complete event input -->
        <div class="form-field-input-switch-block c-mb-4">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="completeEvent"
            inputId="complete-event-input">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="complete-event-input"
            text="adminTicket.completeEventLabel">
          </app-input-label>
        </div>

        <!-- On-Demand input -->
        <div class="form-field-input-switch-block c-mb-4">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="onDemand"
            inputId="on-demand-input">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="on-demand-input"
            text="adminTicket.onDemandLabel">
          </app-input-label>
        </div>

        <!-- Stages input -->
        <div class="form-field-block c-mb-4">
          <app-input-label
            for="stages-input"
            text="adminTicket.stagesLabel">
          </app-input-label>
          <p-multiSelect
            class="custom-multi-select"
            [options]="stages"
            optionLabel="name"
            optionValue="id"
            formControlName="stages"
            inputId="stages-input"
            placeholder="{{ 'adminTicket.stagesLabel' | translate }}">
          </p-multiSelect>
        </div>

        <!-- Sessions input -->
        <div class="form-field-block">
          <app-input-label
            for="sessions-input"
            text="adminTicket.sessionsLabel">
          </app-input-label>
          <p-multiSelect
            class="custom-multi-select"
            [options]="sessions"
            optionLabel="title"
            optionValue="id"
            formControlName="sessions"
            inputId="sessions-input"
            placeholder="{{ 'adminTicket.sessionsLabel' | translate }}">
          </p-multiSelect>
        </div>
      </div>
    </div>

    <p-divider styleClass="custom-divider-horizontal"></p-divider>

    <!-- Advanced Settings block -->
    <div class="form-block c-mt-9 c-mb-4">
      <div class="left-side">
        <h2 class="title">{{ 'adminTicket.advancedSettingsTitle' | translate }}</h2>
        <p class="description">{{ 'adminTicket.advancedSettingsDescription' | translate }}</p>
      </div>
      <div class="right-side">
        <!-- Description textarea -->
        <div class="form-field-block c-mb-8">
          <app-input-label text="adminTicket.description"></app-input-label>
          <p-editor
            styleClass="custom-editor"
            formControlName="description"
            [readonly]="isDescriptionDisabled"
            placeholder="{{ 'adminTicket.description' | translate }}">
          </p-editor>
        </div>

        <p-divider styleClass="custom-divider-horizontal"></p-divider>

        <!-- EventBrite Id input -->
        <div class="form-field-block c-mt-8">
          <app-input-label
            for="event-brite-id-input"
            text="adminTicket.eventBriteIdLabel">
          </app-input-label>
          <input
            class="custom-input-text"
            formControlName="eventBriteId"
            id="event-brite-id-input"
            pInputText
            type="text"
            placeholder="{{ 'adminTicket.eventBriteIdLabel' | translate }}" />
        </div>
      </div>
    </div>

    <div class="save-discard-actions-container">
      <app-save-discard-actions
        [isShow]="canUpdate"
        [isLoading]="isUpdating"
        (discard)="onDiscard()"
        (confirm)="onConfirm()">
      </app-save-discard-actions>
    </div>
  </form>
</div>

<p-toast>
  <ng-template
    let-message
    pTemplate="message">
    <app-toast
      [severity]="message.severity"
      [summary]="message.summary"
      [detail]="message.detail">
    </app-toast>
  </ng-template>
</p-toast>
