@if (loading()) {
  <app-loader></app-loader>
} @else {
  <form
    class="container"
    [formGroup]="form">
    <!-- User name & Details block -->
    <div class="form-block c-mb-9">
      <div class="left-side">
        <h2 class="title">{{ 'adminUserProfile.editTitle' | translate }}</h2>
        <p class="description">{{ 'adminUserProfile.editDesc' | translate }}</p>
      </div>
      <div class="right-side">
        <!-- Salutation input -->
        <div class="form-field-block c-mb-4">
          <app-input-label
            for="salutation-input"
            text="adminUserProfile.salutationLabel">
          </app-input-label>
          <p-dropdown
            class="custom-dropdown"
            panelStyleClass="custom-dropdown-panel"
            [options]="salutations()"
            formControlName="salutation"
            inputId="salutation-input"
            placeholder="{{ 'adminUserProfile.salutationPlaceholder' | translate }}">
            <ng-template
              pTemplate="item"
              let-item>
              {{ item | translate }}
            </ng-template>
            <ng-template
              pTemplate="selectedItem"
              let-selectedItem>
              {{ selectedItem | translate }}
            </ng-template>
          </p-dropdown>
        </div>

        <div class="two-elements-block">
          <!-- First name input -->
          <div>
            <app-input-label
              labelType="required"
              for="first-name-input"
              text="adminUserProfile.firstNameLabel">
            </app-input-label>
            <input
              class="custom-input-text custom-input-text-required"
              formControlName="firstName"
              id="first-name-input"
              pInputText
              type="text"
              maxlength="{{ getMaxLengthValue('firstName') }}"
              placeholder="{{ 'adminUserProfile.firstNamePlaceholder' | translate }}" />
            <app-input-error-message
              [showMessage]="form.controls.firstName.invalid && form.controls.firstName.touched"
              errorMessage="{{ 'adminUserProfile.firstNameReq' | translate }}">
            </app-input-error-message>
          </div>

          <!-- Last name input -->
          <div>
            <app-input-label
              labelType="required"
              for="last-name-input"
              text="adminUserProfile.lastNameLabel">
            </app-input-label>
            <input
              class="custom-input-text custom-input-text-required"
              formControlName="lastName"
              id="last-name-input"
              pInputText
              type="text"
              maxlength="{{ getMaxLengthValue('lastName') }}"
              placeholder="{{ 'adminUserProfile.lastNamePlaceholder' | translate }}" />
            <app-input-error-message
              [showMessage]="form.controls.lastName.invalid && form.controls.lastName.touched"
              errorMessage="{{ 'adminUserProfile.lastNameReq' | translate }}">
            </app-input-error-message>
          </div>
        </div>

        <!-- Company input -->
        <div class="form-field-block c-mb-4">
          <app-input-label
            for="company-input"
            text="adminUserProfile.companyLabel">
          </app-input-label>
          <input
            class="custom-input-text"
            formControlName="company"
            id="company-input"
            pInputText
            type="text"
            maxlength="{{ getMaxLengthValue('company') }}"
            placeholder="{{ 'adminUserProfile.companyPlaceholder' | translate }}" />
        </div>

        <div class="two-elements-block">
          <!-- Industry input -->
          <div class="c-mb-4">
            <app-input-label
              for="industry-input"
              text="adminUserProfile.industryLabel">
            </app-input-label>
            <p-dropdown
              class="custom-dropdown"
              panelStyleClass="custom-dropdown-panel"
              [options]="industryOptions()"
              [virtualScroll]="true"
              [virtualScrollItemSize]="virtualScrollItemSize()"
              formControlName="industry"
              inputId="industry-input"
              placeholder="{{ 'adminUserProfile.industryPlaceholder' | translate }}">
              <ng-template
                pTemplate="item"
                let-item>
                {{ item | translate }}
              </ng-template>
              <ng-template
                pTemplate="selectedItem"
                let-selectedItem>
                {{ selectedItem | translate }}
              </ng-template>
            </p-dropdown>
          </div>

          <!-- Sub-industry input -->
          <div>
            <app-input-label
              for="sub-industry-input"
              text="adminUserProfile.subIndustryLabel">
            </app-input-label>
            <p-dropdown
              class="custom-dropdown"
              panelStyleClass="custom-dropdown-panel"
              [options]="subIndustriesOptions()"
              [virtualScroll]="true"
              [virtualScrollItemSize]="virtualScrollItemSize()"
              formControlName="subIndustry"
              inputId="sub-industry-input"
              placeholder="{{ 'adminUserProfile.subIndustryPlaceholder' | translate }}">
              <ng-template
                pTemplate="item"
                let-item>
                {{ item | translate }}
              </ng-template>
              <ng-template
                pTemplate="selectedItem"
                let-selectedItem>
                {{ selectedItem | translate }}
              </ng-template>
            </p-dropdown>
          </div>
        </div>

        <!-- Area of activity input -->
        <div class="form-field-block c-mb-4">
          <app-input-label
            for="area-of-activity-input"
            text="adminUserProfile.areaOfActivityLabel">
          </app-input-label>
          <p-dropdown
            class="custom-dropdown"
            panelStyleClass="custom-dropdown-panel"
            [options]="areaOfActivityOptions()"
            [virtualScroll]="true"
            [virtualScrollItemSize]="virtualScrollItemSize()"
            formControlName="areaOfActivity"
            inputId="area-of-activity-input"
            placeholder="{{ 'adminUserProfile.areaOfActivityPlaceholder' | translate }}">
            <ng-template
              pTemplate="item"
              let-item>
              {{ item | translate }}
            </ng-template>
            <ng-template
              pTemplate="selectedItem"
              let-selectedItem>
              {{ selectedItem | translate }}
            </ng-template>
          </p-dropdown>
        </div>

        <!-- Optional position input -->
        <div class="form-field-block c-mb-4">
          <app-input-label
            for="optional-position-input"
            text="adminUserProfile.optionalPositionLabel">
          </app-input-label>
          <p-dropdown
            class="custom-dropdown"
            panelStyleClass="custom-dropdown-panel"
            [options]="optionalPositionOptions()"
            [virtualScroll]="true"
            [virtualScrollItemSize]="virtualScrollItemSize()"
            formControlName="optionalPosition"
            inputId="optional-position-input"
            placeholder="{{ 'adminUserProfile.optionalPositionPlaceholder' | translate }}">
            <ng-template
              pTemplate="item"
              let-item>
              {{ item | translate }}
            </ng-template>
            <ng-template
              pTemplate="selectedItem"
              let-selectedItem>
              {{ selectedItem | translate }}
            </ng-template>
          </p-dropdown>
        </div>

        <!-- Position input -->
        <div class="form-field-block c-mb-4">
          <app-input-label
            for="position-input"
            text="adminUserProfile.positionLabel">
          </app-input-label>
          <input
            class="custom-input-text"
            formControlName="position"
            id="position-input"
            pInputText
            type="text"
            placeholder="{{ 'adminUserProfile.positionPlaceholder' | translate }}" />
        </div>

        <!-- Department input -->
        <div class="form-field-block c-mb-4">
          <app-input-label
            for="department-input"
            text="adminUserProfile.departmentLabel">
          </app-input-label>
          <input
            class="custom-input-text"
            formControlName="department"
            id="department-input"
            pInputText
            type="text"
            maxlength="{{ getMaxLengthValue('department') }}"
            placeholder="{{ 'adminUserProfile.departmentPlaceholder' | translate }}" />
        </div>

        <!-- Company size input -->
        <div class="form-field-block c-mb-4">
          <app-input-label
            for="company-size-input"
            text="adminUserProfile.companySizeLabel">
          </app-input-label>
          <p-dropdown
            class="custom-dropdown"
            panelStyleClass="custom-dropdown-panel"
            [options]="companySizeOptions()"
            [virtualScroll]="true"
            [virtualScrollItemSize]="virtualScrollItemSize()"
            formControlName="companySize"
            inputId="company-size-input"
            placeholder="{{ 'adminUserProfile.companySizePlaceholder' | translate }}">
          </p-dropdown>
        </div>

        <!-- Mobile input -->
        <div class="form-field-block">
          <app-input-label
            for="mobile-input"
            text="adminUserProfile.mobileLabel">
          </app-input-label>
          <input
            class="custom-input-text"
            formControlName="mobile"
            id="mobile-input"
            pInputText
            type="text"
            placeholder="{{ 'adminUserProfile.mobilePlaceholder' | translate }}" />
        </div>
      </div>
    </div>

    <p-divider styleClass="custom-divider-horizontal"></p-divider>

    <!-- Change user address block -->
    <div class="form-block c-mt-9 c-mb-4">
      <div class="left-side">
        <h2 class="title">{{ 'adminUserAccount.addressTitle' | translate }}</h2>
        <p class="description">{{ 'adminUserAccount.addressDesc' | translate }}</p>
      </div>
      <div class="right-side">
        <!-- Street input -->
        <div class="form-field-block c-mb-4">
          <app-input-label
            for="street-input"
            text="adminUserProfile.streetLabel">
          </app-input-label>
          <input
            class="custom-input-text"
            formControlName="street"
            id="street-input"
            pInputText
            type="text"
            maxlength="{{ getMaxLengthValue('street') }}"
            placeholder="{{ 'adminUserProfile.streetPlaceholder' | translate }}" />
        </div>

        <!-- Postcode input -->
        <div class="form-field-block c-mb-4">
          <app-input-label
            for="postcode-input"
            text="adminUserProfile.postcodeLabel">
          </app-input-label>
          <input
            class="custom-input-text"
            formControlName="postcode"
            id="postcode-input"
            pInputText
            type="text"
            appInputOnlyPostalCodeNumberValues
            maxlength="{{ getMaxLengthValue('postcode') }}"
            placeholder="{{ 'adminUserProfile.postcodePlaceholder' | translate }}" />
        </div>

        <!-- City input -->
        <div class="form-field-block c-mb-4">
          <app-input-label
            for="city-input"
            text="adminUserProfile.cityLabel">
          </app-input-label>
          <input
            class="custom-input-text"
            formControlName="city"
            id="city-input"
            pInputText
            type="text"
            placeholder="{{ 'adminUserProfile.cityPlaceholder' | translate }}" />
        </div>

        <!-- Country input -->
        <div class="form-field-block c-mb-4">
          <app-input-label
            for="country-input"
            text="adminUserProfile.countryLabel">
          </app-input-label>
          <p-dropdown
            class="custom-dropdown"
            panelStyleClass="custom-dropdown-panel"
            [filter]="true"
            [options]="countriesTranslated()"
            [virtualScroll]="true"
            [virtualScrollItemSize]="virtualScrollItemSize()"
            optionLabel="name"
            optionValue="code"
            formControlName="country"
            inputId="country-input"
            placeholder="{{ 'adminUserProfile.countryPlaceholder' | translate }}">
          </p-dropdown>
        </div>

        <!-- Phone input -->
        <div class="form-field-block">
          <app-input-label
            for="phone-input"
            text="adminUserProfile.phoneLabel">
          </app-input-label>
          <input
            class="custom-input-text"
            formControlName="phone"
            id="phone-input"
            pInputText
            type="text"
            maxlength="{{ getMaxLengthValue('phone') }}"
            appInputOnlyPhoneNumberValues
            placeholder="{{ 'adminUserProfile.phonePlaceholder' | translate }}" />
        </div>
      </div>
    </div>
  </form>
}

<div class="fixed right-0 bottom-0 left-0">
  <app-save-discard-actions
    [isShow]="canUpdate"
    [isLoading]="isUpdating()"
    (discard)="onDiscard()"
    (confirm)="onConfirm()">
  </app-save-discard-actions>
</div>

<p-toast>
  <ng-template
    let-message
    pTemplate="message">
    <app-toast
      [severity]="message.severity"
      [summary]="message.summary"
      [detail]="message.detail">
    </app-toast>
  </ng-template>
</p-toast>
