<div class="container">
  <div class="title">{{ title | translate }}</div>
  <div class="description">{{ description | translate }}</div>
  <div class="buttons">
    <div class="input-container">
      <input
        class="custom-input-text"
        [(ngModel)]="input"
        id="usual-input"
        pInputText
        type="text"
        placeholder="{{ 'deleteDialog.typeDelete' | translate }}" />
    </div>

    <p-button
      [disabled]="input !== 'DELETE'"
      (click)="delete()"
      label="{{ 'deleteDialog.delete' | translate }}"></p-button>
  </div>
</div>
