import { Component, OnInit } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

import { IEvent, ITicket, IOrder } from 'src/app/core/models';
import { OrdersService, TicketsService } from 'src/app/core/services';
import { EventsStore, UsersStore } from 'src/app/core/stores';
import { trackByFn } from 'src/app/core/utils';
import { UntypedFormControl, Validators } from '@angular/forms';
import { TicketPossibleBooked } from 'src/app/core/enums';

@Component({
  selector: 'app-tickets-dialog',
  templateUrl: './tickets-dialog.component.html',
  styleUrls: ['./tickets-dialog.component.scss'],
  providers: [CurrencyPipe],
})
export class TicketsDialogComponent implements OnInit {
  loading = true;
  tickets: ITicket[] = [];
  trackByFn = trackByFn;
  ticketPossibleBooked = TicketPossibleBooked;
  email = new UntypedFormControl(null, [Validators.required, Validators.email]);
  selectedFreeTickets: Map<string, ITicket> = new Map<string, ITicket>();
  selectedPaidTickets: Map<string, ITicket> = new Map<string, ITicket>();

  get event(): IEvent {
    return this.eventsStore.event;
  }

  get totalAmount(): string {
    const result = this.tickets.reduce((acc: number, curr: ITicket) => {
      return acc + curr.numberOfPurchaseTickets * Number(curr.price);
    }, 0);
    if (this.tickets.length) {
      return this.currencyPipe.transform(result, this.tickets[0]?.currency);
    }
    return '0';
  }

  get isBtnDisabled(): boolean {
    return (
      !this.tickets.some((ticket: ITicket) => ticket.numberOfPurchaseTickets !== 0) ||
      (this.isShowEmailInput && this.email.invalid)
    );
  }

  get isShowEmailInput(): boolean {
    return (
      (this.selectedFreeTickets.size || this.selectedPaidTickets.size) && !this.usersStore.user
    );
  }

  constructor(
    private eventsStore: EventsStore,
    private ticketsService: TicketsService,
    private currencyPipe: CurrencyPipe,
    private ordersService: OrdersService,
    private ref: DynamicDialogRef,
    private usersStore: UsersStore,
  ) {}

  async ngOnInit(): Promise<void> {
    const tickets: ITicket[] = await this.ticketsService.getPublishEventTicketsWithoutEventBrite(
      this.event.id,
    );
    if (tickets.length) {
      this.tickets = await Promise.all(
        tickets.map(async (ticket: ITicket) => {
          return {
            ...ticket,
            numberOfPurchaseTickets: 0,
            isSoldOut:
              (ticket?.quantitySold || 0) + (ticket?.numberBookedTickets || 0) >=
              ticket.quantityTotal,
          };
        }),
      );
    }
    this.loading = false;
  }

  changePurchaseTickets(countOfTickets: number, ticket: ITicket): void {
    this.updateMapCollections(countOfTickets, ticket);
    this.tickets = this.tickets.map((item: ITicket) => {
      if (item.id === ticket.id) {
        return {
          ...item,
          numberOfPurchaseTickets: countOfTickets,
        };
      }
      return item;
    });
  }

  private updateMapCollections(countOfTickets: number, ticket: ITicket): void {
    ticket?.price && ticket?.price !== '0'
      ? this.updateTicketsPaidCollection(countOfTickets, ticket)
      : this.updateTicketFreeCollections(countOfTickets, ticket);
  }

  private updateTicketsPaidCollection(countOfTickets: number, ticket: ITicket): void {
    countOfTickets
      ? this.selectedPaidTickets.set(ticket.id, ticket)
      : this.selectedPaidTickets.delete(ticket.id);
  }

  private updateTicketFreeCollections(countOfTickets: number, ticket: ITicket): void {
    countOfTickets
      ? this.selectedFreeTickets.set(ticket.id, ticket)
      : this.selectedFreeTickets.delete(ticket.id);
  }

  async onCheckoutTickets(): Promise<void> {
    this.loading = true;

    // reset possible to booked tickets
    this.tickets = [
      ...this.tickets.map((ticket: ITicket) => ({
        ...ticket,
        isPossibleBooked: TicketPossibleBooked.POSSIBLE,
      })),
    ];
    const ticketsFromDb: ITicket[] = await this.ticketsService.getPublishedEventTickets(
      this.event.id,
    );
    this.tickets = this.tickets.map((ticket: ITicket) => {
      const ticketFromDb = ticketsFromDb.find((item: ITicket) => item.id === ticket.id);
      return { ...ticket, numberBookedTickets: ticketFromDb?.numberBookedTickets || 0 };
    });

    const checkedTickets: ITicket[] = await Promise.all(
      this.tickets.map(async (ticket: ITicket) => {
        if (ticket?.numberOfPurchaseTickets !== 0 && !ticket.isSoldOut) {
          return {
            ...ticket,
            isSoldOut:
              (ticket?.quantitySold || 0) + (ticket?.numberBookedTickets || 0) >=
              ticket.quantityTotal,
            isPossibleBooked:
              ticket.quantityTotal >=
              (ticket?.quantitySold || 0) +
                ticket.numberOfPurchaseTickets +
                (ticket?.numberBookedTickets || 0)
                ? TicketPossibleBooked.POSSIBLE
                : TicketPossibleBooked.NOT_POSSIBLE,
          };
        }

        return ticket;
      }),
    );
    const isPossibleBookedAllNeededTickets = checkedTickets.every((ticket: ITicket) => {
      if (
        ticket?.isPossibleBooked &&
        ticket?.isPossibleBooked === TicketPossibleBooked.NOT_POSSIBLE
      ) {
        return false;
      }

      return true;
    });

    if (!isPossibleBookedAllNeededTickets) {
      this.tickets = [
        ...checkedTickets.map((ticket: ITicket) => ({ ...ticket, numberOfPurchaseTickets: 0 })),
      ];
      this.loading = false;
      return;
    }

    this.createOrders();
  }

  private async createOrders(): Promise<void> {
    const ticketsForBooking: ITicket[] = this.tickets.filter(
      (ticket: ITicket) =>
        ticket.numberOfPurchaseTickets !== 0 &&
        ticket.isPossibleBooked === TicketPossibleBooked.POSSIBLE,
    );
    await this.ticketsService.updateBookedNumbersInEventTicket(this.event.id, ticketsForBooking[0]);
    const orders: IOrder[] = await this.ordersService.createOrders(
      this.event.id,
      ticketsForBooking,
    );
    this.ref.close({
      tickets: ticketsForBooking,
      orderId: orders[0].orderId,
      userEmail: this.email.value,
    });
  }
}
