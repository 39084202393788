<div
  class="container"
  cdkDrag>
  <div
    class="chapter-card"
    [ngClass]="{ 'chapter-card-only': !isExpandAccordion }"
    (click)="expandCollapseAccordion()">
    <div class="banner-container">
      <img
        *ngIf="chapter?.banner"
        class="banner"
        [defaultImage]="imgPreloader"
        [lazyLoad]="chapter.banner | imageSize" />
      <div
        *ngIf="!chapter?.banner"
        class="placeholder-container">
        <img
          class="placeholder"
          [src]="logoAvatar | imageSize" />
      </div>
      <div
        class="hover-block"
        cdkDragHandle>
        <i class="fa-regular fa-bars"></i>
      </div>
    </div>
    <div class="info-container">
      <p
        class="title"
        (click)="navigateToChapter($event)">
        {{ chapter.title }}
      </p>
      <p class="short-description">{{ chapter.shortDescription }}</p>
    </div>
    <div
      class="dropdown-container"
      (appClickOutside)="onCloseToggleDropdown()">
      <div
        class="dropdown"
        (click)="onToggleDropdown($event)">
        <div
          class="circle-mark"
          [ngClass]="getClassForCircleMark"></div>
        <span>{{ currentChapterStatus }}</span>
        <div
          [ngClass]="{ rotated: expandDropdown }"
          [@iconAnimation]="expandDropdown ? 'rotated' : 'initial'">
          <i class="fa-regular fa-chevron-down"></i>
        </div>
      </div>
      <ul
        *ngIf="expandDropdown"
        class="dropdown-list"
        [@dropDownMenu]>
        <li
          *ngFor="let status of chapterStatuses; trackBy: trackByFn"
          class="dropdown-list-item"
          (click)="onSelectChapterStatus(status)">
          <div
            class="circle-mark"
            [ngClass]="{
              green: chapterStatus[status.value] === chapterStatus.PUBLISHED,
              orange: chapterStatus[status.value] === chapterStatus.DRAFT,
            }"></div>
          {{ status.label }}
        </li>
      </ul>
    </div>
    <i
      class="fa-regular fa-ellipsis-vertical vertical-dots"
      (click)="onOpenMenu($event, menu)">
    </i>
    <p-menu
      #menu
      [model]="items"
      [popup]="true"
      appendTo="body"
      styleClass="p-custom-menu"></p-menu>
    <i
      class="fa-regular fa-chevron-down chevron-icon"
      [ngClass]="{ 'chevron-icon-rotated': isExpandAccordion }">
    </i>
  </div>
  <div
    *ngIf="isExpandAccordion"
    class="accordion">
    <div
      class="assets-container"
      cdkDropList
      (cdkDropListDropped)="drop($event)">
      <app-asset-card
        *ngFor="let chapterAsset of chapter.assetsFullModel; trackBy: trackByFn"
        [asset]="chapterAsset"
        (changeAssetStatus)="onChangeAssetStatus($event, chapterAsset)"
        (editAsset)="onEditAsset(chapterAsset)"
        (removeAsset)="onRemoveAsset(chapterAsset)">
      </app-asset-card>
    </div>
    <div class="buttons">
      <app-button
        buttonText="{{ 'chapterCard.createNew' | translate }}"
        buttonIconLeft="fa-regular fa-upload"
        [buttonStyle]="buttonStyle.TERTIARY_PRIMARY"
        [buttonSize]="buttonSize.SMALL"
        (click)="onCreateNewAsset()" />
      <app-button
        buttonText="{{ 'chapterCard.attach' | translate }}"
        buttonIconLeft="fa-regular fa-paperclip"
        [buttonStyle]="buttonStyle.TERTIARY_PRIMARY"
        [buttonSize]="buttonSize.SMALL"
        (click)="onAttachAsset()" />
    </div>
  </div>
</div>
