export enum TravelDocument {
  PASSPORT = 'travelDocument.passport',
  IDENTITY_CARD = 'travelDocument.identityCard',
}

export enum Agreement {
  YES = 'agreement.yes',
  NO = 'agreement.no',
}

export enum Salutations {
  MR = 'salutation.mr',
  MS = 'salutation.ms',
}

export enum ClothingSizes {
  XS = 'clothingSizes.xs',
  S = 'clothingSizes.s',
  M = 'clothingSizes.m',
  L = 'clothingSizes.l',
  XL = 'clothingSizes.xl',
  XXL = 'clothingSizes.xxl',
  XXXL = 'clothingSizes.xxxl',
}
