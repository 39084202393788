import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';

import {
  IUser,
  ITicket,
  IEvent,
  IHub,
  IUserHub,
  UserHubRoles,
  IUserEvent,
  ICourse,
  ICourseTicket,
} from 'src/app/core/models';
import {
  AuthenticationService,
  CoursesService,
  EventsService,
  TicketsService,
  UserHubsService,
  UsersService,
} from 'src/app/core/services';
import { HubsStore, UsersStore } from 'src/app/core/stores';
import { UsersMaxLengthValidators } from 'src/app/shared';
import { EmailCourseActionTypes, capitalizeFirstLetter } from 'src/app/core/utils';
import { Timestamp } from 'src/app/firebase';
import { ButtonSize, ButtonStyle } from 'src/app/standalone';

type UserType =
  | 'user'
  | 'userHub'
  | 'attendee'
  | 'speaker'
  | 'subscriber'
  | 'instructor'
  | 'hubAdmin';

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.scss'],
})
export class NewUserComponent implements OnInit {
  loading = true;
  isUpdating = false;
  tickets: ITicket[] | ICourseTicket[];
  createAnotherControl = new UntypedFormControl(null);
  form: UntypedFormGroup;
  userType: UserType;
  course: ICourse;
  event: IEvent;
  isCheckUserEmail: boolean;
  buttonStyle = ButtonStyle;
  buttonSize = ButtonSize;

  private hub: IHub;

  constructor(
    public hubsStore: HubsStore,
    private usersStore: UsersStore,
    private usersService: UsersService,
    private ref: DynamicDialogRef,
    private configDialog: DynamicDialogConfig,
    private ticketsService: TicketsService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private userHubsService: UserHubsService,
    private eventsService: EventsService,
    private courseService: CoursesService,
    private authService: AuthenticationService,
  ) {}

  get canUpdate(): boolean {
    return !this.isUpdating && this.form.valid && this.form.dirty;
  }

  get createUserType(): string {
    if (this.userType === 'userHub') {
      return `adminUserProfile.createUser`;
    }

    return `adminUserProfile.create${capitalizeFirstLetter(this.userType)}`;
  }

  get textForCheckbox(): string {
    if (['attendee', 'speaker', 'subscriber', 'instructor'].includes(this.userType)) {
      return this.translateService.instant(
        `adminUserProfile.addAnother${capitalizeFirstLetter(this.userType)}`,
      );
    }

    return this.translateService.instant('adminUserAccount.createAnother');
  }

  get isShowLastFirstNameInputs(): boolean {
    return (
      (this.userType !== 'user' && this.userType !== 'hubAdmin' && !this.isCheckUserEmail) ||
      this.userType === 'user' ||
      this.userType === 'hubAdmin'
    );
  }

  async ngOnInit(): Promise<void> {
    this.userType = this.configDialog.data.userType;
    this.course = this.configDialog.data.course || null;
    this.event = this.configDialog.data.event || null;
    this.hub = this.configDialog.data.hub || null;

    await this.setTicketsList();

    switch (this.userType) {
      case 'speaker':
      case 'instructor':
        this.createFormForSpeakerOrInstructor();
        break;
      case 'attendee':
      case 'subscriber':
        this.createFormForAttendeeAndSubscriber();
        break;
      default:
        this.createFormForUser();
    }

    if (['userHub', 'attendee', 'speaker', 'subscriber', 'instructor'].includes(this.userType)) {
      this.prepareFormForAttaching();
    }

    this.loading = false;
  }

  async setTicketsList(): Promise<void> {
    if (this.event) {
      this.tickets = await this.ticketsService.getEventTickets(this.event.id);
    } else {
      this.tickets = await this.ticketsService.getCourseTickets(this.course?.id);
    }
  }

  getMaxLengthValue(formControl: string): number {
    return Number(UsersMaxLengthValidators[formControl]);
  }

  async create(): Promise<void> {
    this.isUpdating = true;

    if (this.isCheckUserEmail) {
      await this.startAttachingFlow();
    } else {
      await this.startCreatingFlow();
    }

    this.isUpdating = false;
  }

  onCloseDialog(): void {
    this.ref.close();
  }

  private prepareFormForAttaching(): void {
    this.isCheckUserEmail = true;
    this.form.controls.firstName.removeValidators(Validators.required);
    this.form.controls.lastName.removeValidators(Validators.required);
  }

  private createFormForUser(): void {
    this.form = new UntypedFormGroup({
      firstName: new UntypedFormControl(null, Validators.required),
      lastName: new UntypedFormControl(null, Validators.required),
      email: new UntypedFormControl(null, [Validators.required, Validators.email]),
    });
  }

  private createFormForSpeakerOrInstructor(): void {
    this.form = new UntypedFormGroup({
      firstName: new UntypedFormControl(null, Validators.required),
      lastName: new UntypedFormControl(null, Validators.required),
      email: new UntypedFormControl(null, [Validators.required, Validators.email]),
      sendInvite: new UntypedFormControl(null),
    });
  }

  private createFormForAttendeeAndSubscriber(): void {
    this.form = new UntypedFormGroup({
      firstName: new UntypedFormControl(null, Validators.required),
      lastName: new UntypedFormControl(null, Validators.required),
      email: new UntypedFormControl(null, [Validators.required, Validators.email]),
      sendInvite: new UntypedFormControl(null),
      ticket: new UntypedFormControl(null),
    });
  }

  private async startAttachingFlow(): Promise<void> {
    const userFromTenant: IUser = await this.getUserFromTenant();

    if (!userFromTenant) {
      this.form.controls.firstName.addValidators(Validators.required);
      this.form.controls.lastName.addValidators(Validators.required);
      this.isCheckUserEmail = false;

      return;
    }

    const isUserFromTenantExistInCurrentHub: IUserHub = await this.userHubsService.getUserHub(
      this.hub.id,
      userFromTenant.id,
    );

    if (!isUserFromTenantExistInCurrentHub) {
      await this.attachUserToHub(userFromTenant);
      await this.attachToEventOrCourse(userFromTenant);
      this.closeDialog(userFromTenant.id);
    } else {
      this.messageService.add({
        severity: 'info',
        summary: this.translateService.instant('shared.alert'),
        detail: this.translateService.instant('adminNewUser.userExist'),
        styleClass: 'custom-toast',
      });
    }
  }

  private async startCreatingFlow(): Promise<void> {
    const newUser: IUser = await this.createNewUser();

    if (this.userType !== 'hubAdmin') {
      await this.attachUserToHub(newUser);
    }

    this.closeDialog(newUser.id);
  }

  private async attachUserToHub(user: IUser): Promise<void> {
    try {
      if (!this.hub) {
        return;
      }

      const userHub: IUserHub = await this.userHubsService.getUserHub(this.hub.id, user.id);

      if (userHub) {
        return;
      }

      const newUserHub: IUserHub = {
        id: null,
        hubId: this.hub.id,
        userId: user.id,
        role: UserHubRoles.USER,
        _hub_title_: this.hub._title_,
        _firstName_: user._firstName_,
        _lastName_: user._lastName_,
        _company_: user._company_,
        _position_: user._position_,
        createdAt: Timestamp.now(),
        createdBy: this.usersStore.userId,
        updatedAt: null,
        updatedBy: null,
      };

      await this.userHubsService.addUserToHub(newUserHub);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  private async attachToEventOrCourse(user: IUser) {
    if (this.userType === 'attendee' || this.userType === 'speaker') {
      await this.attachToEvent(user);
    }

    if (this.userType === 'subscriber' || this.userType === 'instructor') {
      const role: 'subscriber' | 'instructor' = this.userType;
      await this.attachToCourse(user, role);
    }
  }

  private async attachToEvent(user: IUser): Promise<void> {
    try {
      const newUserEvent: IUserEvent = {
        id: null,
        eventId: this.event.id,
        userId: user.id,
        _firstName_: user.firstName.toLowerCase(),
        _lastName_: user.lastName.toLowerCase(),
        _company_: user?.company?.toLowerCase() || null,
        _position_: user.position ? user.position.toLowerCase() : null,
        _event_title_: this.event.title.toLowerCase(),
        role: 'attendee',
        isBrandPerson: false,
        ticketId: null,
        ticketPrice: null,
        status: 'pending',
        createdAt: Timestamp.now(),
        createdBy: this.usersStore.user.id,
        updatedAt: null,
        updatedBy: null,
        tags: user.tags,
        joinAt: null,
        acceptedConsents: null,
        acceptedMultiConsents: null,
        popUpConsents: null,
        hotel: null,
        roomType: null,
        roomPartner: null,
        preferredAirport: null,
        alternativeAirport: null,
        checkedIn: false,
      };
      await this.eventsService.addUserToEvent(newUserEvent);
    } catch (error) {
      console.warn(error);
    }
  }

  private async attachToCourse(user: IUser, role: 'subscriber' | 'instructor'): Promise<void> {
    try {
      await this.courseService.addUserToCourse(this.course, user, role, true);
    } catch (error) {
      console.warn(error);
    }
  }

  private async getUserFromTenant(): Promise<IUser> {
    const email = this.form.controls.email.value;
    const { user } = await this.authService.getAccountByEmail(email, this.hub.tenantId);

    return user;
  }

  private async createNewUser(): Promise<IUser> {
    try {
      const user: IUser = this.form.getRawValue();

      const userPayload: Partial<IUser> = {
        id: null,
        displayEmail: user.email,
        email: user.email.toLowerCase(),
        firstName: user.firstName,
        lastName: user.lastName,
        company: null,
        position: null,
        _firstName_: user.firstName.toLowerCase(),
        _lastName_: user.lastName.toLowerCase(),
        _company_: null,
        _position_: null,
        department: null,
        bio: null,
        title: null,
        addressSuffix: null,
        salutation: null,
        profileImage: null,
        coverImage: null,
        phone: null,
        country: null,
        city: null,
        street: null,
        postcode: null,
        website: null,
        facebook: null,
        instagram: null,
        twitter: null,
        youtube: null,
        linkedin: null,
        xing: null,
        calendly: null,
        acceptNews: null,
        allowChat: true,
        allowAppointment: true,
        isActive: false,
        brandId: null,
        // hubId: this.hubsStore.hub ? this.hubsStore.hub.id : null,
        hubId: null,
        role: null,
        tags: null,
        tagsList: null,
        systemLanguage: this.translateService.currentLang,
        systemTheme: null,
        updatedAt: null,
        updatedBy: null,
        createdAt: Timestamp.now(),
        createdBy: this.usersStore.user.id,
        ticket: this.form.controls.ticket ? user.ticket : null,
      };

      const response: { userPayload: IUser } = await this.usersService.createAPI(
        userPayload,
        this.userType,
        this.event?.id,
        this.course?.id,
        this.hub,
      );

      if (this.form?.controls?.sendInvite?.value && this.event) {
        this.usersService.sendInvite(this.event.id, response?.userPayload?.id);
      }

      if (this.form?.controls?.sendInvite?.value && this.course) {
        this.usersService.sendInviteForUsersFromCourse(
          this.course.id,
          response?.userPayload?.id,
          this.userType === 'instructor'
            ? EmailCourseActionTypes.COURSE_INSTRUCTOR_INVITE
            : EmailCourseActionTypes.COURSE_SUBSCRIBER_INVITE,
          this.translateService.currentLang,
        );
      }

      return response.userPayload;
    } catch (error) {
      console.log(error);
      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('error'),
        detail: this.translateService.instant(`adminNewUser.${error.error}`),
        styleClass: 'custom-toast',
      });
    }
  }

  private closeDialog(userId: string): void {
    if (['userHub', 'attendee', 'speaker', 'subscriber', 'instructor'].includes(this.userType)) {
      this.prepareFormForAttaching();
    }

    if (this.createAnotherControl.value) {
      this.form.reset({ createAnotherControl: true });
    } else {
      this.ref.close(userId);
    }
  }
}
