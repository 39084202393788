import { AlgoliaService } from '../algolia/algolia.service';
import { UsersStore } from 'src/app/core/stores';
import { StorageService } from '../storage/storage.service';
import { HttpClientService } from '../http/http-client.service';
import { AuthorizationService } from '../auth/authorization.service';
import { HttpClient } from '@angular/common/http';
import { IFirestore, ServerTimestamp } from '../../../firebase';
import { Injectable } from '@angular/core';
import { Firestore } from 'src/app/firebase';
import { IBrand, ICourse, ICourseBrand } from '../../models';
import { CollectionTypes } from 'src/app/shared';

@Injectable({
  providedIn: 'root',
})
export class CourseBrandsService {
  private firestore: IFirestore;

  constructor(
    private http: HttpClient,
    private authorizationService: AuthorizationService,
    private httpService: HttpClientService,
    private storageService: StorageService,
    private userStore: UsersStore,
    private algoliaService: AlgoliaService,
  ) {
    this.firestore = Firestore();
  }

  public async getOne(id: string): Promise<ICourseBrand> {
    try {
      const courseAsset = (
        await this.firestore.doc(`${CollectionTypes.BRAND_COURSES}/${id}`).get()
      ).data() as ICourseBrand;
      return courseAsset;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  public async getCourseBrand(courseId: string, brandId: string): Promise<ICourseBrand> {
    try {
      const courseBrand = (
        await this.firestore
          .collection(CollectionTypes.BRAND_COURSES)
          .where('brandId', '==', brandId)
          .where('courseId', '==', courseId)
          .get()
      ).docs[0]?.data();

      if (courseBrand) {
        return courseBrand as ICourseBrand;
      }

      console.error('This brand has not signed for this course');
      return null;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  public async getAllCourseBrandsId(courseId: string): Promise<string[]> {
    try {
      const query = await this.firestore
        .collection(CollectionTypes.BRAND_COURSES)
        .where('courseId', '==', courseId)
        .get();

      const brandsId = query.docs.map((doc) => doc.data().brandId);

      return brandsId;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  public async getAllCourseBrandsByCourseId(courseId: string): Promise<ICourseBrand[]> {
    const courseDoc = await this.firestore
      .collection(CollectionTypes.BRAND_COURSES)
      .where('courseId', '==', courseId)
      .get();

    return courseDoc.docs.map((doc) => doc.data() as ICourseBrand);
  }

  public async updateCourseAssetsByIds(courseBrands: ICourseBrand[]): Promise<boolean> {
    courseBrands.forEach(async (courseBrand) => {
      await this.firestore
        .collection(CollectionTypes.BRAND_COURSES)
        .doc(courseBrand.id)
        .update({ courseBrand });
    });

    return true;
  }

  public async updateBrandCourse(
    brandCourseId: string,
    tags: string[],
    level: string,
  ): Promise<boolean> {
    try {
      await this.firestore
        .collection(CollectionTypes.BRAND_COURSES)
        .doc(brandCourseId)
        .update({ tags: tags, level: level });

      const courseBrand = (
        await this.firestore.doc(`${CollectionTypes.BRAND_COURSES}/${brandCourseId}`).get()
      ).data() as ICourseBrand;
      await this.algoliaService.updateBrandCourse(courseBrand);

      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  public async createCourseBrand(course: ICourse, brand: IBrand): Promise<ICourseBrand> {
    try {
      const preAssetReqDocRef = this.firestore.collection(CollectionTypes.BRAND_COURSES).doc();

      let newCourseBrand = {
        id: preAssetReqDocRef.id,
        courseId: course.id,
        brandId: brand.id,
        status: false,
        tags: [],
        level: '',
        _brandName_: brand._name_,
        _courseTitle_: course._title_,
        createdAt: ServerTimestamp(),
        createdBy: this.userStore.userId,
        updatedAt: null,
        updatedBy: null,
      } as ICourseBrand;

      await preAssetReqDocRef.set({ ...newCourseBrand });
      await this.algoliaService.createBrandCourse(newCourseBrand);

      return newCourseBrand;
    } catch (error) {
      return null;
    }
  }

  public async delete(courseBrandId: string) {
    try {
      await this.firestore.collection(CollectionTypes.BRAND_COURSES).doc(courseBrandId).delete();
      await this.algoliaService.removeBrandCourse(courseBrandId);

      return true;
    } catch (error) {
      return null;
    }
  }
}
