<div
  class="navigation-container"
  [ngClass]="{ 'is-ios-pwa': isIosPWA }">
  <a
    routerLink="/{{ hubsStore.useHubUrl }}/dashboard"
    routerLinkActive="active">
    <i
      class="fak fa-lox-home"
      aria-hidden="true">
    </i>
    <span>{{ 'userDashboard.menu.dashboard' | translate }}</span>
  </a>
  <a
    *ngIf="isVisibleSidebarItem('events')"
    routerLink="/{{ hubsStore.useHubUrl }}/events"
    routerLinkActive="active">
    <i
      class="far fa-screen-users"
      aria-hidden="true">
    </i>
    <span>{{ 'userDashboard.menu.events' | translate }}</span>
  </a>
  <a
    *ngIf="isVisibleSidebarItem('academy')"
    routerLink="/{{ hubsStore.useHubUrl }}/courses"
    routerLinkActive="active">
    <i
      class="fa-regular fa-graduation-cap"
      aria-hidden="true">
    </i>
    <span>{{ 'userDashboard.menu.courses' | translate }}</span>
  </a>
</div>
