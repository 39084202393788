import { Injectable } from '@angular/core';
import { ICourse, ICourseCoupon } from '../../models';
import { Firestore, IFirestore, Timestamp } from 'src/app/firebase';
import { API_ROUTES as apiRoutes } from 'src/app/shared';
import { UsersStore } from '../../stores';

@Injectable({
  providedIn: 'root',
})
export class CouponsService {
  private firestore: IFirestore;

  constructor(private usersStore: UsersStore) {
    this.firestore = Firestore();
  }

  async createCourseCoupon(courseId: string, coupon: ICourseCoupon) {
    try {
      const preCourseCouponReq = await this.firestore
        .collection(apiRoutes.courseCoupons(courseId))
        .doc();
      const newCoupon: ICourseCoupon = {
        id: preCourseCouponReq.id,
        ...coupon,
        _name_: coupon.name.toLowerCase(),
        createdAt: Timestamp.now(),
        createdBy: this.usersStore.user.id,
      };

      await this.firestore
        .collection(apiRoutes.courseCoupons(courseId))
        .doc(newCoupon.id)
        .set({ ...newCoupon });

      return newCoupon;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async updateCourseCoupon(courseId: string, coupon: ICourseCoupon): Promise<ICourseCoupon> {
    try {
      const couponDoc = this.firestore.collection(apiRoutes.courseCoupons(courseId)).doc(coupon.id);
      const newCoupon = {
        ...coupon,
        _name_: coupon.name.toLowerCase(),
        updatedAt: Timestamp.now(),
        updatedBy: this.usersStore.user?.id ? this.usersStore.user.id : null,
      };
      await couponDoc.update({ ...newCoupon });

      return newCoupon;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async getCourseCoupons(courseId: string): Promise<ICourseCoupon[]> {
    try {
      const result: ICourseCoupon[] = (
        await this.firestore.collection(apiRoutes.courseCoupons(courseId)).get()
      ).docs.map((doc) => doc.data() as ICourseCoupon);

      return result;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async getCourseCoupon(courseId: string, couponId: string): Promise<ICourseCoupon> {
    try {
      const coupon = (
        await this.firestore.doc(apiRoutes.courseCoupon(courseId, couponId)).get()
      ).data() as ICourseCoupon;
      return coupon;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async removeCouponFromCourse(courseId: string, couponId: string): Promise<boolean> {
    try {
      await this.firestore.collection(apiRoutes.courseCoupons(courseId)).doc(couponId).delete();
      return true;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }
}
