import { HubsStore } from './../../core/stores/hubs/hubs.store';
import { UsersStore } from 'src/app/core/stores';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Route,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { HubsService } from 'src/app/core/services';

@Injectable({
  providedIn: 'root',
})
export class AdminLibraryAccessGuard {
  constructor(
    private router: Router,
    private usersStore: UsersStore,
    private hubsStore: HubsStore,
    private hubsService: HubsService,
  ) {}

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    if (this.hubsStore.hub && this.usersStore.user) {
      return true;
    }

    this.router.navigate([`${this.hubsStore.useHubUrl}/admin`]);
    return false;
  }
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canLoad(
    route: Route,
    segments: UrlSegment[],
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
}
