import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';

import { emailsCourseActions, emailsEventActions } from 'src/app/core/utils';
import { EmailsService } from 'src/app/core/services';
import { EventsStore, CoursesStore } from 'src/app/core/stores';
import { IEmail } from 'src/app/core/models';
import { IAdminTableColumn } from 'src/app/shared';
import { ButtonSize } from 'src/app/standalone';

@Component({
  selector: 'app-attach-emails',
  templateUrl: './attach-emails.component.html',
  styleUrls: ['./attach-emails.component.scss'],
})
export class AttachEmailsComponent implements OnInit {
  loading = false;
  attachLoading = false;
  loadingEntries = true;
  entries: IEmail[] = [];
  selectedEntries: IEmail[] = [];
  searchTerm = '';
  searchFilterFields: string[] = ['title', 'language', 'actionTitle', 'audience'];
  columns: IAdminTableColumn[] = [
    { field: 'title', titleTranslationKey: 'adminEmailTemplates.tableColumnTitle', sortable: true },
    {
      field: 'language',
      titleTranslationKey: 'adminEmailTemplates.tableColumnLanguage',
      template: 'langCell',
      sortable: true,
    },
    {
      field: 'actionTitle',
      titleTranslationKey: 'adminEmailTemplates.tableColumnAction',
      sortable: true,
    },
    {
      field: 'audience',
      titleTranslationKey: 'adminEmailTemplates.tableColumnAudience',
      sortable: true,
    },
  ];
  totalRecords = 0;
  pageSize = 10;
  emailActions: { label: string; value: string }[];
  buttonSize = ButtonSize;

  private attachTo: 'events' | 'courses';

  constructor(
    private emailsService: EmailsService,
    private translateService: TranslateService,
    private eventsStore: EventsStore,
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private messageService: MessageService,
    private coursesStore: CoursesStore,
  ) {}

  async ngOnInit(): Promise<void> {
    this.attachTo = this.config.data.attachTo;
    this.emailActions = this.attachTo === 'events' ? emailsEventActions : emailsCourseActions;
    await this.fetchEmails();
    this.loadingEntries = false;
    this.loading = false;
  }

  searchChanged(value: string): void {
    if (typeof value === 'string' && value.length) {
      this.searchTerm = value;
    } else {
      this.searchTerm = ' ';
    }
  }

  async attach(): Promise<void> {
    this.attachLoading = true;
    const attachedEmails: IEmail[] = await Promise.all(
      this.selectedEntries.map((selectedEntry: IEmail) =>
        this.attachTo === 'events'
          ? this.emailsService.attachEmailToEvent(this.eventsStore.adminEvent.id, selectedEntry)
          : this.emailsService.attachEmailToCourse(this.coursesStore.adminCourse.id, selectedEntry),
      ),
    );
    this.messageService.add({
      key: 'email',
      severity: 'success',
      summary: this.translateService.instant('success'),
      detail: this.translateService.instant('adminEmailTemplates.emailSuccessfullyAttached', {
        count: this.selectedEntries.length,
      }),
      styleClass: 'custom-toast',
    });
    this.attachLoading = false;
    this.selectedEntries = [];

    setTimeout(() => {
      this.ref.close(attachedEmails);
    }, 2000);
  }

  onCloseDialog(): void {
    this.ref.close();
  }

  private async fetchEmails(): Promise<void> {
    const emails: IEmail[] =
      this.attachTo === 'events'
        ? await this.emailsService.getAllForEvent()
        : await this.emailsService.getAllForCourse();

    this.entries = emails.map((e) => {
      e.actionTitle = e.action ? this.emailActions.find((x) => x.value === e.action)?.label : '-';
      return e;
    });
    this.entries = this.entries.filter((e: IEmail) => e.action !== 'verify-email');
  }
}
