import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable, Subject, takeUntil, tap } from 'rxjs';
import { AccordionModule } from 'primeng/accordion';

import { SharedModule } from 'src/app/shared';
import { IAsset, IChapter } from 'src/app/core/models';
import { RegisteredCourseStore } from 'src/app/core/stores';
import { trackByFn } from 'src/app/core/utils';
import { ChapterAssetCardComponent } from '../chapter-asset-card/chapter-asset-card.component';

@Component({
  selector: 'app-chapters-sidebar-tab-lessions',
  standalone: true,
  imports: [CommonModule, AccordionModule, ChapterAssetCardComponent, SharedModule],
  templateUrl: './chapters-sidebar-tab-lessions.component.html',
  styleUrls: ['./chapters-sidebar-tab-lessions.component.scss'],
})
export class ChaptersSidebarTabLessionsComponent implements OnInit, OnDestroy {
  chapters: Observable<IChapter[]>;
  trackByFn = trackByFn;
  activeAsset: IAsset;

  private unsubscribe$ = new Subject<void>();

  constructor(private registeredCourseStore: RegisteredCourseStore) {}

  get stylesClassForAccordion(): string {
    return window.screen.width > 904 ? 'custom-accordion' : 'registered-course-accordion';
  }

  ngOnInit(): void {
    this.chapters = this.registeredCourseStore.courseChaptersWithoutAssetEvents;

    this.registeredCourseStore.activeAsset
      .pipe(
        tap((asset: IAsset) => {
          this.activeAsset = asset;
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();
  }

  async onChangeActiveAsset(asset: IAsset): Promise<void> {
    if (this.activeAsset.id === asset.id) {
      return;
    }
    this.registeredCourseStore.setActiveAsset(asset);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
