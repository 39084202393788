import { Firestore, IFirestore } from './../../../firebase';
import { ISession } from 'src/app/core/models/session/session.model';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SpacesService {
  private firestore: IFirestore;
  private readonly events: string = 'events';
  private readonly sessions: string = 'sessions';

  constructor() {
    this.firestore = Firestore();
  }

  public async getAll(eventId: string): Promise<ISession[]> {
    try {
      const spaceSessions = (
        await this.firestore
          .collection(`${this.events}/${eventId}/${this.sessions}`)
          .where('space', '==', true)
          .orderBy('start', 'asc')
          .get()
      ).docs.map((x) => x.data() as ISession);

      return spaceSessions;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
}
