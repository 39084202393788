<div
  *ngIf="targetDate"
  class="timer">
  <div class="item">
    <span class="value">{{ daysToTargetDay }}</span>
    <span class="value-text">{{ 'countDown.days' | translate }}</span>
  </div>
  <div class="item">
    <span class="value">{{ hoursToTargetDay }}</span>
    <span class="value-text">{{ 'countDown.hours' | translate }}</span>
  </div>
  <div class="item">
    <span class="value">{{ minutesToTargetDay }}</span>
    <span class="value-text">{{ 'countDown.minutes' | translate }}</span>
  </div>
  <div class="item">
    <span class="value">{{ secondsToTargetDay }}</span>
    <span class="value-text">{{ 'countDown.seconds' | translate }}</span>
  </div>
</div>
