import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { IOrder } from 'src/app/core/models';
import { HubsStore, UsersStore } from 'src/app/core/stores';
import { trackByFn } from 'src/app/core/utils';
import { environment } from 'src/environments/environment';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-order-confirmation-dialog',
  templateUrl: './order-confirmation-dialog.component.html',
  styleUrls: ['./order-confirmation-dialog.component.scss'],
})
export class OrderConfirmationDialogComponent implements OnInit {
  orders: IOrder[][];
  trackByFn = trackByFn;
  selectedOrder = new UntypedFormControl(null);

  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private usersStore: UsersStore,
    private hubsStore: HubsStore,
  ) {}

  ngOnInit(): void {
    this.orders = this.config.data;
  }

  async downloadPdf(order: IOrder): Promise<void> {
    try {
      const pdfUrl = `${environment.apiUrl}stripe/orders/download-booking-code-pdf?bookingCode=${order.bookingCode}&userId=${this.usersStore.user ? this.usersStore.user.id : order.orderedBy ? order.orderedBy : null}&orderId=${order.orderId}&hubId=${this.hubsStore.hub ? this.hubsStore.hub : null}`;
      saveAs(pdfUrl);
    } catch (error) {
      console.log(error);
    }
  }

  onSelectBookingCode(order: IOrder): void {
    this.selectedOrder.setValue(order);
  }

  onRedeemCode(): void {
    this.ref.close(this.selectedOrder.value);
  }
}
