import { Component } from '@angular/core';

import { ITag, IUserEvent } from 'src/app/core/models';
import { MixpanelService, TagsService, UsersService } from 'src/app/core/services';
import { EventsStore, UsersStore } from 'src/app/core/stores';
import { AppStore } from 'src/app/app.store';

@Component({
  selector: 'app-topics-sidebar',
  templateUrl: './topics-sidebar.component.html',
})
export class TopicsSidebarComponent {
  public loading = true;
  public tags: ITag[] = [];
  public userTagIds: string[];

  private userEventObject: IUserEvent;

  constructor(
    private tagsService: TagsService,
    private eventsStore: EventsStore,
    private usersService: UsersService,
    private usersStore: UsersStore,
    private eventStore: EventsStore,
    public appStore: AppStore,
    private mixpanelService: MixpanelService,
  ) {}

  public async onShow(e): Promise<void> {
    this.loading = true;
    this.tags = await this.tagsService.getByIdsOrderedByTitle(this.eventsStore.event?.tags);

    if (this.eventsStore.eventId && this.usersStore.userId) {
      this.userEventObject = await this.usersService.getUserEvent(
        this.eventsStore.eventId,
        this.usersStore.userId,
      );
      if (this.userEventObject) this.userTagIds = this.userEventObject.tags;
    }

    this.loading = false;
  }

  public async onSelectedTag(tagIds: string[]): Promise<void> {
    this.userTagIds = tagIds;

    // sent event to mixpanel analytics
    this.mixpanelService.topicUpdate(
      this.eventStore.event,
      tagIds,
      this.usersStore.userEventsMap[this.eventStore.eventId].tags,
    );

    this.usersStore.userEventsMap[this.eventStore.eventId].tags = tagIds;

    let currentLoggedUserTags = this.usersStore.user.tags;
    let tagsToSaveIntoDb = [];

    tagIds.map((userEventTag) => {
      if (!currentLoggedUserTags.includes(userEventTag)) {
        tagsToSaveIntoDb.push(userEventTag);
        currentLoggedUserTags.push(userEventTag);
      }
    });

    if (tagsToSaveIntoDb.length) {
      await this.usersService.update(this.usersStore.user.id, { tags: currentLoggedUserTags });
    }

    await this.usersService.updateUserEvent({
      ...this.userEventObject,
      tags: [...this.userTagIds],
    });
  }
}
