import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import { ICourseCoupon, ICourseTicket } from 'src/app/core/models';
import { CouponsService, StripeService, TicketsService } from 'src/app/core/services';
import { CoursesStore, HubsStore } from 'src/app/core/stores';
import { updateTime, goToLink, validNumbers } from 'src/app/core/utils';
import { CurrencyCode, CouponDiscountType, CouponDuration } from 'src/app/core/enums';
import { Timestamp } from '@firebase/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { parseToMoment } from 'src/app/shared';

@Component({
  selector: 'app-admin-coupon-form',
  templateUrl: './admin-coupon-form.component.html',
  styleUrls: ['./admin-coupon-form.component.scss'],
})
export class AdminCouponFormComponent implements OnInit, OnDestroy {
  loading = true;
  isUpdating = false;
  currencies = Object.keys(CurrencyCode);
  couponDiscountTypes = CouponDiscountType;
  coupon: ICourseCoupon;
  couponForm: UntypedFormGroup;
  minDate: Date = moment().toDate();
  courseTickets: Array<ICourseTicket>;
  courseCurrency = this.coursesStore.adminCourse.currency;
  couponId: string;
  private defaultEndDate: Date = moment().add(1, 'days').toDate();

  constructor(
    private fb: UntypedFormBuilder,
    private ticketsService: TicketsService,
    private coursesStore: CoursesStore,
    private stripeService: StripeService,
    private translateService: TranslateService,
    private messageService: MessageService,
    private couponsService: CouponsService,
    private router: Router,
    private route: ActivatedRoute,
    private hubsStore: HubsStore,
  ) {}

  get canUpdate(): boolean {
    return !this.isUpdating && this.couponForm.valid && this.couponForm.dirty;
  }

  get isFormDisabled(): boolean {
    return (
      (this.couponForm.invalid && !this.isUpdating) ||
      (!this.couponForm.dirty && !this.isUpdating) ||
      this.isUpdating
    );
  }

  async ngOnInit(): Promise<void> {
    this.route.url.subscribe((urlPath) => {
      this.couponId = urlPath[urlPath.length - 1].toString();
    });
    if (this.couponId) {
      this.coupon = await this.couponsService.getCourseCoupon(
        this.coursesStore.adminCourse.id,
        this.couponId,
      );
    }

    this.createForm();

    if (this.coupon) {
      this.updateForm();
      this.disableUpdateOptions();
    }

    this.courseTickets = await this.ticketsService.getCourseTickets(
      this.coursesStore.adminCourse.id,
    );
    this.courseTickets = this.courseTickets.filter((t) => t.stripeProductId);

    this.couponForm.get('limitUsage').valueChanges.subscribe((value) => {
      if (value) {
        this.couponForm.get('usageLimit').setValidators([Validators.required]);
        this.couponForm.get('usageLimit').updateValueAndValidity();
      } else {
        this.couponForm.get('usageLimit').clearValidators();
        this.couponForm.get('usageLimit').updateValueAndValidity();
      }
    });

    this.couponForm.get('addExpirationDate').valueChanges.subscribe((value) => {
      if (value) {
        this.couponForm.get('expirationDate').setValidators([Validators.required]);
        this.couponForm.get('expirationDate').updateValueAndValidity();
      } else {
        this.couponForm.get('expirationDate').clearValidators();
        this.couponForm.get('expirationDate').updateValueAndValidity();
      }
    });

    this.couponForm.get('type').valueChanges.subscribe((selectedType) => {
      if (selectedType === CouponDiscountType.PercentageDiscount) {
        this.couponForm.get('discountAmount').reset();
        this.couponForm.get('discountPercentage').setValidators([Validators.required]);
        this.couponForm.get('discountPercentage').updateValueAndValidity();
        this.couponForm.get('currency').clearValidators();
        this.couponForm.get('currency').updateValueAndValidity();
        this.couponForm.get('discountAmount').clearValidators();
        this.couponForm.get('discountAmount').updateValueAndValidity();
      } else {
        this.couponForm.get('discountPercentage').reset();
        this.couponForm.get('discountPercentage').clearValidators();
        this.couponForm.get('discountPercentage').updateValueAndValidity();
        this.couponForm.get('currency').setValidators([Validators.required]);
        this.couponForm.get('currency').updateValueAndValidity();
        this.couponForm.get('discountAmount').setValidators([Validators.required]);
        this.couponForm.get('discountAmount').updateValueAndValidity();
      }
    });

    this.couponForm.get('addExpirationDate').valueChanges.subscribe((checked: boolean) => {
      if (checked) {
        this.couponForm.get('endDate').setValue(this.defaultEndDate);
        this.couponForm.get('endTime').setValue(this.defaultEndDate);
      } else {
        this.couponForm.get('endDate').reset();
        this.couponForm.get('endTime').reset();
      }
    });
  }

  createForm(): void {
    this.couponForm = this.fb.group({
      name: [null, Validators.required],
      code: [null, Validators.required],
      type: [CouponDiscountType.PercentageDiscount],
      discountPercentage: [null, Validators.required],
      currency: [this.courseCurrency],
      discountAmount: [null, Validators.pattern('^[0-9]*$')],
      applyToSpecificTickets: [false],
      tickets: [[]],
      limitUsage: [false],
      usageLimit: [null, Validators.pattern('^[0-9]*$')],
      addExpirationDate: [false],
      expirationDate: [false],
      endDate: [null],
      endTime: [null],
    });

    this.loading = false;
  }

  updateForm(): void {
    this.couponForm.patchValue({
      ...this.coupon,
      endDate: this.coupon?.expires_at.seconds
        ? parseToMoment(this.coupon.expires_at).toDate()
        : null,
      endTime: this.coupon?.expires_at.seconds
        ? parseToMoment(this.coupon.expires_at).toDate()
        : null,
    });
  }

  hasValidator(controlName: string): boolean {
    const control = this.couponForm.get(controlName);
    if (!control.validator) {
      return false;
    } else {
      return true;
    }
  }

  private async onCreateCoupon(): Promise<void> {
    try {
      this.isUpdating = true;
      const form = this.couponForm.getRawValue();
      const expirationDate = updateTime(form.endDate, form.endTime);

      let stripePromotion = await this.stripeService.createCoupon(
        form.name,
        form.code,
        form.currency,
        form.discountAmount,
        CouponDuration.FOREVER,
        form.discountPercentage,
        form.usageLimit,
        form.tickets,
        expirationDate,
      );
      const { endDate, endTime, ...others } = form;
      const dateEnd = updateTime(endDate, endTime);
      const updatedCoupon: ICourseCoupon = {
        ...this.coupon,
        ...others,
        applies_to: form.tickets,
        expires_at: Timestamp.fromMillis(dateEnd.toDate().getTime()),
      };
      updatedCoupon.stripeCouponId = stripePromotion.coupon.id;
      updatedCoupon.stripePromotionCodeId = stripePromotion.promotionCode.id;
      await this.couponsService.createCourseCoupon(this.coursesStore.adminCourse.id, updatedCoupon);
      this.showToastMessage('success', 'adminCourseCouponForm.couponSuccesfullyCreated');
      this.isUpdating = false;
      this.router.navigate([
        `/${this.hubsStore.useHubUrl}/admin/courses/${this.coursesStore.adminCourse.link}/setup/promotions`,
      ]);
    } catch (error) {
      console.warn(error);
      if (error.error?.data?.raw?.message) {
        this.showToastMessage('error', error.error.data.raw.message);
      } else {
        this.showToastMessage('error', `application.toasters.error`);
      }
      this.isUpdating = false;
    }
  }

  private async onUpdateCoupon(): Promise<void> {
    try {
      this.isUpdating = true;
      const form = this.couponForm.getRawValue();
      let stripePromotion = await this.stripeService.updateCoupon(
        this.coupon.stripeCouponId,
        form.name,
      );
      this.coupon.name = stripePromotion.name;
      await this.couponsService.updateCourseCoupon(this.coursesStore.adminCourse.id, this.coupon);
      this.showToastMessage('success', 'adminCourseCouponForm.couponSuccesfullyUpdated');
      this.isUpdating = false;
      this.router.navigate([
        `/${this.hubsStore.useHubUrl}/admin/courses/${this.coursesStore.adminCourse.link}/setup/promotions`,
      ]);
    } catch (error) {
      console.warn(error);
      if (error.error?.data?.raw?.message) {
        this.showToastMessage('error', error.error.data.raw.message);
      } else {
        this.showToastMessage('error', `application.toasters.error`);
      }
      this.isUpdating = false;
    }
  }

  private showToastMessage(severity: 'success' | 'error', detail: string): void {
    if (severity === 'error') {
      let message;
      if (
        this.translateService.instant(`stripeErrorCodes.${detail}`) !== `stripeErrorCodes.${detail}`
      ) {
        message = this.translateService.instant(`stripeErrorCodes.${detail}`);
      } else {
        message = detail;
      }
      this.messageService.add({
        severity: severity,
        detail: message,
        styleClass: 'custom-toast',
      });
    } else {
      const fallbackTranslationKey = 'toasters.error';
      let message;
      this.translateService.get(detail).subscribe((translation) => {
        message =
          translation === detail
            ? this.translateService.instant(fallbackTranslationKey)
            : translation;
      });
      if (this.coupon) {
        this.messageService.add({
          severity,
          summary: this.translateService.instant('adminCourseCouponForm.couponSuccesfullyUpdated'),
          detail: this.translateService.instant(
            'adminCourseCouponForm.couponSuccesfullyUpdatedDescription',
          ),
          styleClass: 'custom-toast',
        });
      } else {
        this.messageService.add({
          severity,
          summary: this.translateService.instant('adminCourseCouponForm.couponSuccesfullyCreated'),
          detail: this.translateService.instant(
            'adminCourseCouponForm.couponSuccesfullyCreatedDescription',
          ),
          styleClass: 'custom-toast',
        });
      }
    }
  }

  onDiscard() {
    if (this.coupon) {
      this.couponForm.patchValue({
        ...this.coupon,
      });
    } else {
      this.couponForm.reset();
      this.couponForm.patchValue({
        type: CouponDiscountType.PercentageDiscount,
      });
    }
  }

  onSave() {
    if (this.coupon) {
      this.onUpdateCoupon();
    } else {
      this.onCreateCoupon();
    }
  }

  disableUpdateOptions(): void {
    this.couponForm.get('code').disable();
    this.couponForm.get('type').disable();
    this.couponForm.get('discountPercentage').disable();
    this.couponForm.get('currency').disable();
    this.couponForm.get('discountAmount').disable();
    this.couponForm.get('applyToSpecificTickets').disable();
    this.couponForm.get('tickets').disable();
    this.couponForm.get('limitUsage').disable();
    this.couponForm.get('usageLimit').disable();
    this.couponForm.get('addExpirationDate').disable();
    this.couponForm.get('expirationDate').disable();
    this.couponForm.get('endDate').disable();
    this.couponForm.get('endTime').disable();
  }

  ngOnDestroy(): void {}
}
