<ng-container *ngIf="loading">
  <app-loader></app-loader>
</ng-container>

<div *ngIf="!loading">
  <form
    class="c-pl-8"
    [formGroup]="form">
    <!-- Brand level block -->
    <div class="form-block c-mt-8 c-mb-9">
      <div class="left-side">
        <h2 class="title">{{ 'adminUserEventDetails.descBrandLevelTitle' | translate }}</h2>
        <p class="description">
          {{ 'adminUserEventDetails.brandDescBrandLevelDescription' | translate }}
        </p>
      </div>
      <div class="right-side">
        <!-- Level input -->
        <div class="form-field-block c-mb-4">
          <app-input-label
            for="level-input"
            text="adminUserEventDetails.descBrandLevelTitle">
          </app-input-label>
          <p-dropdown
            class="custom-dropdown"
            panelStyleClass="custom-dropdown-panel"
            [options]="levels"
            formControlName="level"
            optionLabel="value"
            optionValue="key"
            inputId="level-input"
            placeholder="{{ 'adminUserEventDetails.descBrandLevelTitle' | translate }}">
            <ng-template
              let-selectedLevel
              pTemplate="selectedItem">
              {{ selectedLevel.value | translate }}
            </ng-template>
            <ng-template
              let-level
              pTemplate="item">
              {{ level.value | translate }}
            </ng-template>
          </p-dropdown>
        </div>
      </div>
    </div>

    <p-divider styleClass="custom-divider-horizontal"></p-divider>

    <!-- Tags block -->
    <div class="form-block c-my-9">
      <div class="left-side">
        <h2 class="title">{{ 'adminUserEventDetails.descTagsTitle' | translate }}</h2>
        <p class="description">
          {{ 'adminUserEventDetails.brandDescTagsDescription' | translate }}
        </p>
      </div>
      <div class="right-side">
        <!-- Tags multi select -->
        <div class="form-field-block">
          <app-tags-multi-list
            *ngIf="tags"
            [possibleTags]="tags"
            [selectedTags]="form.controls.tags.value"
            [adminTheme]="true"
            (onSelected)="selectTags($event)">
          </app-tags-multi-list>
          <h3 *ngIf="!tags?.length">{{ 'adminUserEventDetails.noTagsAvailable' | translate }}</h3>
        </div>
      </div>
    </div>

    <p-divider styleClass="custom-divider-horizontal"></p-divider>

    <!-- Products block -->
    <div class="form-block c-my-9">
      <div class="left-side">
        <h2 class="title">{{ 'brandEventConfigurations.products' | translate }}</h2>
        <p class="description">{{ 'brandEventConfigurations.productsDesc' | translate }}</p>
      </div>
      <div class="right-side">
        <!-- Products multi select -->
        <div class="form-field-block">
          <app-products-multi-list
            [edit]="true"
            [possibleProducts]="products"
            [selectedProducts]="form.controls.products.value"
            (onSelected)="selectEventProducts($event)">
          </app-products-multi-list>
        </div>
      </div>
    </div>

    <p-divider styleClass="custom-divider-horizontal"></p-divider>

    <!-- Featured products block -->
    <div class="form-block c-mt-9 c-mb-4">
      <div class="left-side">
        <h2 class="title">{{ 'adminUserEventDetails.featuredProducts' | translate }}</h2>
        <p class="description">
          {{ 'adminUserEventDetails.featuredProductsDescription' | translate }}
        </p>
      </div>
      <div class="right-side">
        <!-- Featured products multi select -->
        <div class="form-field-block">
          <app-products-multi-list
            [edit]="true"
            [possibleProducts]="eventProductsListing"
            [selectedProducts]="form.controls.featuredProducts.value"
            (onSelected)="selectFeaturedEventProducts($event)">
          </app-products-multi-list>
        </div>
      </div>
    </div>

    <div class="text-right c-mr-8 c-mb-8">
      <button
        class="primary-text-color"
        pButton
        type="button"
        [label]="'adminUserEventDetails.updateBtn' | translate"
        [icon]="isUpdating ? 'pi pi-spin pi-spinner' : null"
        [disabled]="!canUpdate"
        (click)="update()"></button>
    </div>
  </form>
</div>
