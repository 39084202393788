import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { autorun, IReactionDisposer } from 'mobx';
import { TranslateService } from '@ngx-translate/core';

import { SessionsService, StagesService, IvsService } from 'src/app/core/services';
import {
  ConfirmDialogComponent,
  imgPreloader,
  logoAvatar,
  IAdminTableColumn,
} from 'src/app/shared';
import { IEvent, IStage } from 'src/app/core/models';
import { HubsStore, EventsStore } from 'src/app/core/stores';
import { StreamProviders } from 'src/app/core/enums';
import { ButtonSize, ButtonStyle, ButtonType } from 'src/app/standalone';
import { AppStore } from '../../../../app.store';

@Component({
  selector: 'app-event-stages',
  templateUrl: './event-stages.component.html',
  styleUrls: ['./event-stages.component.scss'],
})
export class EventStagesComponent implements OnInit, OnDestroy {
  imgPreloader = imgPreloader;
  logoAvatar = logoAvatar;
  loading = true;
  loadingEntries = true;
  searchValue = '';
  searchFilterFields: string[] = ['name', 'order'];
  columns: IAdminTableColumn[] = [
    { template: 'titleCell', titleTranslationKey: 'adminEventStages.tableCells.title' },
    { template: 'featuredCell', titleTranslationKey: 'adminEventStages.tableCells.isFeatured' },
    { template: 'statusCell', titleTranslationKey: 'adminEventStages.tableCells.isMainStage' },
    { field: 'actions', template: 'actionsCell' },
  ];
  entries: IStage[] = [];
  buttonType = ButtonType;
  buttonSize = ButtonSize;
  buttonStyle = ButtonStyle;

  private currentOrder: string;
  private disposer: IReactionDisposer;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public hubsStore: HubsStore,
    private eventStore: EventsStore,
    private dialogService: DialogService,
    private messageService: MessageService,
    private stagesService: StagesService,
    private sessionsService: SessionsService,
    private translateService: TranslateService,
    private ivsService: IvsService,
    public appStore: AppStore,
  ) {}

  get event(): IEvent {
    return this.eventStore.adminEvent;
  }

  ngOnInit(): void {
    this.disposer = autorun(async () => {
      if (this.event) {
        this.loading = true;
        await this.fetchStages();
        this.loadingEntries = false;
        this.loading = false;
      }
    });
  }

  async reorder(stages: IStage[]): Promise<void> {
    const mainStage = stages.find((s) => s.isMainStage);
    const normalStages = stages.filter((s) => !s.isMainStage);
    if (mainStage) {
      stages = [mainStage, ...normalStages];
    }
    const newOrder: string = normalStages.map((s) => s.order).join();

    if (this.currentOrder !== newOrder) {
      this.loadingEntries = true;
      await this.stagesService.reorder(
        this.event.id,
        normalStages.map((s) => s.id),
      );
      await this.fetchStages();
      this.loadingEntries = false;
    } else {
      this.entries = stages;
    }
  }

  edit(event: Event, entry: IStage): void {
    event.stopPropagation();
    this.router.navigate(
      [`/${this.hubsStore.useHubUrl}/admin/event/${this.event.link}/setup/stages/${entry.id}`],
      {
        relativeTo: this.route,
        state: {
          entry: entry,
        },
      },
    );
  }

  remove(event: Event, entry: IStage): void {
    event.stopPropagation();
    const deleteDialogRef = this.dialogService.open(ConfirmDialogComponent, {
      closable: false,
      styleClass: 'confirm-dialog',
      data: {
        titleKey: 'adminEventStages.removeStage',
        descriptionKey: 'adminEventStages.removeStageText',
        confirmBtnKey: 'adminEventStages.confirmBtn',
        cancelBtnKey: 'adminEventStages.cancelBtn',
        entry: entry,
      },
    });

    deleteDialogRef.onClose.pipe(take(1)).subscribe(async (result: 'cancel' | 'confirm') => {
      if (result === 'confirm') {
        try {
          let deleteResult = await this.stagesService.remove(entry.id, this.event.id);

          if (entry.streamProvider === StreamProviders.AMAZON) {
            // Delete old channel
            const credentials = {
              region: this.appStore.generalSystemSettings.ivsRegion,
              accessKeyId: this.appStore.generalSystemSettings.ivsKey,
              secretAccessKey: this.appStore.generalSystemSettings.ivsSecret,
            };
            await this.ivsService.deleteChannelAsync(
              credentials,
              entry.arn,
              entry.recordingConfigurationArn,
              false,
            );
          }

          await this.sessionsService.unassignStage(entry.id, this.event.id);
          this.loadingEntries = true;
          await this.fetchStages();
          this.loadingEntries = false;
          this.loading = false;
          if (deleteResult)
            this.messageService.add({
              severity: 'success',
              summary: this.translateService.instant('success'),
              detail: this.translateService.instant('adminEventStages.succesDeleteStage'),
              styleClass: 'custom-toast',
            });
        } catch (error) {
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('error'),
            detail: this.translateService.instant('adminEventStages.errorDeleteStage'),
            styleClass: 'custom-toast',
          });
        }
      }
    });
  }

  onRowClicked(id: string): void {
    this.router.navigate([
      `/${this.hubsStore.useHubUrl}/admin/event/${this.event.link}/setup/stages/${id}`,
    ]);
  }

  ngOnDestroy(): void {
    this.disposer();
  }

  private async fetchStages(): Promise<void> {
    this.entries = await this.stagesService.getAll(this.event.id);
    this.currentOrder = this.entries
      .filter((s) => !s.isMainStage)
      .map((s) => s.order)
      .join();
  }
}
