import { Timestamp } from 'src/app/firebase';
import { CurrencyCode } from 'src/app/core/enums';
import { IFee } from './fee.model';

export interface ICourseTicket {
  id: string;
  name: string;
  _name_: string;
  info: string;
  quantityTotal: number;
  quantitySold: number;
  quantity_sold: number;
  quantity_total: number;
  plan: string;
  start: Timestamp;
  end: Timestamp;
  isPaid: boolean;
  currency: CurrencyCode;
  price: string;
  ticketCost: IFee;
  ticketCostValue: number;
  ticketCostMajorValue: string;
  ticketCostDisplay: string;
  description: string;
  isPublished: boolean;
  fee: IFee;
  major_value: string;
  isShowLiveEvents: boolean;
  createdAt: Timestamp;
  createdBy: string;
  updatedAt: Timestamp;
  updatedBy: string;
  actualFee: IFee;
  value: number;
  stripePriceId?: string;
  stripeProductId?: string;
}
