import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { firstValueFrom, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UnlayerService {
  private http = inject(HttpClient);

  async getEmailTemplates(): Promise<any> {
    try {
      const templates = await this.http
        .get<any>(`${environment.apiUrl}unlayer/get-templates/`)
        .toPromise();
      return templates.data.filter((t) => t.displayMode === 'email');
    } catch (error) {
      console.warn(error);
      throw new Error(error.error.data.code);
    }
  }

  async getWebPageTemplates(): Promise<any> {
    const templates$ = this.http
      .get<any>(`${environment.apiUrl}unlayer/get-templates/`)
      .pipe(
        map(({ msg, data }) =>
          data.filter((template: unknown) => template['displayMode'] === 'web'),
        ),
      );
    const webPageTemplates = await firstValueFrom(templates$);

    return webPageTemplates;
  }
}
