import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { SessionsStore, EventsStore } from 'src/app/core/stores';
import { SessionsService } from './../../core/services/sessions/sessions.service';

@Injectable({
  providedIn: 'root',
})
export class AdminSessionAccessGuard {
  constructor(
    private router: Router,
    private sessionsService: SessionsService,
    private sessionsStore: SessionsStore,
    private eventsStore: EventsStore,
  ) {}

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    let currentEntry = this.router.getCurrentNavigation().extras.state
      ? this.router.getCurrentNavigation().extras.state.entry
        ? this.router.getCurrentNavigation().extras.state.entry
        : null
      : null;

    if (!currentEntry) {
      currentEntry = await this.sessionsService.getOne(
        this.eventsStore.adminEvent.id,
        next.params.sessionId,
      );
    }

    this.sessionsStore.setAdminSession(currentEntry);
    this.sessionsStore.setSessionTitle(currentEntry.title);
    return currentEntry ? true : false;
  }
  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.sessionsStore.unsetAdminSession();
    this.sessionsStore.setSessionTitle('');

    return true;
  }
}
