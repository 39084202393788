<ng-container *ngIf="loading">
  <app-loader></app-loader>
</ng-container>

<div
  *ngIf="!loading"
  class="page-container">
  <header class="c-mb-4">
    <h1 class="admin-page-title">{{ 'adminEvent.menuItemSettings' | translate }}</h1>
  </header>

  <p-divider styleClass="custom-divider-horizontal"></p-divider>

  <form [formGroup]="form">
    <!-- General block -->
    <div class="form-block c-my-9">
      <div class="left-side">
        <h2 class="title">{{ 'adminEventSettings.eventGeneralTitle' | translate }}</h2>
        <p class="description">{{ 'adminEventSettings.eventGeneralDescription' | translate }}</p>
      </div>
      <div class="right-side">
        <!-- Featured input -->
        <div class="form-field-input-switch-block c-mb-4">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="featured"
            inputId="featured-input">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="featured-input"
            text="adminEventSettings.eventStatusPrivacyFeatured">
          </app-input-label>
        </div>

        <!-- Is private event input -->
        <div class="form-field-input-switch-block c-mb-4">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="isPrivateEvent"
            inputId="is-private-event-input">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="is-private-event-input"
            text="adminEventSettings.eventStatusPrivateEvent">
          </app-input-label>
        </div>

        <!-- Is on demand mode input -->
        <div class="form-field-input-switch-block c-mb-4">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="isOnDemandMode"
            inputId="is-on-demand-mode-input">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="is-on-demand-mode-input"
            text="adminEventSettings.eventStatusPrivacyDemand">
          </app-input-label>
        </div>

        <!-- Is on demand coming soon input -->
        <div class="form-field-input-switch-block c-mb-4">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="isOnDemandComingSoon"
            inputId="is-on-demand-coming-soon-input">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="is-on-demand-coming-soon-input"
            text="adminEventSettings.eventStatusPrivacyDemandComingSoon">
          </app-input-label>
        </div>

        <!-- Is on maintenance input -->
        <div class="form-field-input-switch-block c-mb-4">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="isOnMaintenance"
            inputId="is-on-maintenance-input">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="is-on-maintenance-input"
            text="adminEventSettings.eventStatusPrivacyMaintenance">
          </app-input-label>
        </div>

        <!-- Is matchmaking input -->
        <div class="form-field-input-switch-block c-mb-4">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="isMatchmaking"
            inputId="is-matchmaking-input">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="is-matchmaking-input"
            text="adminEventSettings.eventStatusPrivacyMatchmaking">
          </app-input-label>
        </div>
      </div>
    </div>

    <!-- Tickets block -->
    <div class="form-block c-my-9">
      <div class="left-side">
        <h2 class="title">{{ 'adminEventSettings.eventTicketsTitle' | translate }}</h2>
        <p class="description">{{ 'adminEventSettings.eventTicketsDescription' | translate }}</p>
      </div>
      <div class="right-side">
        <!-- Is Ticket restrictions input -->
        <div class="form-field-input-switch-block c-mb-4">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="isTicketRestrictions"
            inputId="is-ticket-restrictions-input">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="is-ticket-restrictions-input"
            text="adminEventSettings.eventStatusTicketRestrictions">
          </app-input-label>
        </div>

        <!-- Is enable direct buy input -->
        <div class="form-field-input-switch-block c-mb-4">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="isEnableDirectBuy"
            inputId="is-enable-direct-buy-input">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="is-enable-direct-buy-input"
            text="adminEventSettings.enableDirectBuy">
          </app-input-label>
        </div>

        <!-- Schedule event check-in input -->
        <div class="form-field-input-switch-block c-mb-4">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="scheduleEventCheckin"
            inputId="schedule-event-check-in-input">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="schedule-event-check-in-input"
            text="adminEventSettings.scheduleEventCheckin">
          </app-input-label>
        </div>

        <div
          *ngIf="form.controls.scheduleEventCheckin.value"
          class="two-elements-block">
          <!-- Schedule date input -->
          <div>
            <app-input-label
              labelType="required"
              for="shcedule-event-date-input"
              text="adminEventSettings.scheduleEventCheckinDate">
            </app-input-label>
            <p-calendar
              class="custom-calendar custom-calendar-required"
              formControlName="scheduleEventDate"
              [monthNavigator]="true"
              [yearNavigator]="true"
              [showIcon]="true"
              dateFormat="dd/mm/yy"
              yearRange="1900:2050"
              inputId="schedule-event-date-input"
              icon="far fa-calendar-day"
              placeholder="{{ 'adminEventSettings.scheduleEventCheckinDate' | translate }}">
            </p-calendar>
            <app-input-error-message
              [showMessage]="
                form.controls.scheduleEventDate.invalid && form.controls.scheduleEventDate.touched
              "
              errorMessage="{{ 'application.forms.required' | translate }}">
            </app-input-error-message>
          </div>

          <!-- Schedule time input -->
          <div>
            <app-input-label
              labelType="required"
              for="schedule-event-time-input"
              text="adminEventSettings.scheduleEventCheckinTime">
            </app-input-label>
            <p-calendar
              class="custom-calendar custom-calendar-required custom-calendar-time"
              formControlName="scheduleEventTime"
              placeholder="{{ 'adminEventSettings.scheduleEventCheckinTime' | translate }}"
              inputId="schedule-event-time-input"
              [timeOnly]="true"
              [showIcon]="true"
              icon="far fa-clock">
            </p-calendar>
            <app-input-error-message
              [showMessage]="
                form.controls.scheduleEventTime.invalid && form.controls.scheduleEventTime.touched
              "
              errorMessage="{{ 'application.forms.required' | translate }}">
            </app-input-error-message>
          </div>
        </div>
      </div>
    </div>

    <!-- Emails block -->
    <div class="form-block c-my-9">
      <div class="left-side">
        <h2 class="title">{{ 'adminEventSettings.eventEmailsTitle' | translate }}</h2>
        <p class="description">{{ 'adminEventSettings.eventEmailsDescription' | translate }}</p>
      </div>
      <div class="right-side">
        <!-- Event registration confirmation email input -->
        <div class="form-field-input-switch-block c-mb-4">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="eventRegistrationConfirmationEmail"
            inputId="event-registration-confirmation-email-input">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="event-registration-confirmation-email-input"
            text="adminEventSettings.eventRegistrationConfirmationEmail">
          </app-input-label>
        </div>

        <!-- Event invitation declined email input -->
        <div class="form-field-input-switch-block c-mb-4">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="eventInvitationDeclinedEmail"
            inputId="event-invitation-declined-email-input">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="event-invitation-declined-email-input"
            text="adminEventSettings.eventInvitationDeclinedEmail">
          </app-input-label>
        </div>
      </div>
    </div>

    <!-- Display options block -->
    <div class="form-block c-my-9">
      <div class="left-side">
        <h2 class="title">{{ 'adminEventSettings.eventDisplayOptionsTitle' | translate }}</h2>
        <p class="description">
          {{ 'adminEventSettings.eventDisplayOptionsDescription' | translate }}
        </p>
      </div>
      <div class="right-side">
        <!-- Hide chat input -->
        <div class="form-field-input-switch-block c-mb-4">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="hideChat"
            inputId="hide-chat-input">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="hide-chat-input"
            text="adminEventSettings.hideChat">
          </app-input-label>
        </div>

        <!-- Hide chat preview screen input -->
        <div class="form-field-input-switch-block c-mb-4">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="hideChatPreviewScreen"
            inputId="hide-chat-preview-screen-input">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="hide-chat-preview-screen-input"
            text="adminEventSettings.hideChatPreviewScreen">
          </app-input-label>
        </div>

        <!-- Hide event search input -->
        <div class="form-field-input-switch-block c-mb-4">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="hideEventSearch"
            inputId="hide-event-search-input">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="hide-event-search-input"
            text="adminEventSettings.eventHideSearch">
          </app-input-label>
        </div>

        <!-- Hide recommended sessions input -->
        <div class="form-field-input-switch-block c-mb-4">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="hideRecommendedSessions"
            inputId="hide-recommended-sessions-input">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="hide-recommended-sessions-input"
            text="adminEventSettings.eventHideRecommendedSessions">
          </app-input-label>
        </div>

        <!-- Hide brand level names input -->
        @if (shouldShowBrands) {
          <div class="form-field-input-switch-block c-mb-4">
            <p-inputSwitch
              class="custom-input-switch"
              formControlName="hideBrandLevelNames"
              inputId="hide-brand-level-names-input">
            </p-inputSwitch>
            <app-input-label
              labelType="switch-checkbox"
              for="hide-brand-level-names-input"
              text="adminEventSettings.hideBrandLevelNames">
            </app-input-label>
          </div>
        }
      </div>
    </div>
  </form>
</div>

<div class="save-discard-actions-container">
  <app-save-discard-actions
    [isShow]="canUpdate"
    [isLoading]="isUpdating"
    (discard)="onDiscard()"
    (confirm)="onConfirm()">
  </app-save-discard-actions>
</div>

<p-toast>
  <ng-template
    let-message
    pTemplate="message">
    <app-toast
      [severity]="message.severity"
      [summary]="message.summary"
      [detail]="message.detail">
    </app-toast>
  </ng-template>
</p-toast>
