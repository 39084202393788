import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

import { asyncDelay } from 'src/app/core/utils';
import { brandLevels } from 'src/app/core/enums';
import { IBrand, ITag, ICourse, ICourseBrand } from 'src/app/core/models';
import { HubsStore, BrandsStore, CoursesStore } from 'src/app/core/stores';
import { TagsService, CourseBrandsService } from 'src/app/core/services';

@Component({
  selector: 'app-course-brand-details',
  templateUrl: './course-brand-details.component.html',
  styleUrls: ['./course-brand-details.component.scss'],
})
export class CourseBrandDetailsComponent implements OnInit, OnDestroy {
  private formValueChangeSubscribtion: Subscription;

  public loading = true;
  public isUpdating = false;
  public courseId: string = null;
  public tags: ITag[] = [];
  public levels: Array<{ key: string; value: string }> = brandLevels;
  public courseBrand: ICourseBrand = null;
  public form: UntypedFormGroup = new UntypedFormGroup({
    tags: new UntypedFormControl([]),
    level: new UntypedFormControl('none'),
  });

  public get brand(): IBrand {
    return this.brandsStore.adminBrand;
  }

  public get course(): ICourse {
    return this.courseStore.adminCourse;
  }

  public get canUpdate(): boolean {
    return !this.isUpdating && this.form.valid && this.form.dirty;
  }

  constructor(
    private messageService: MessageService,
    private route: ActivatedRoute,
    private tagService: TagsService,
    public hubsStore: HubsStore,
    private courseStore: CoursesStore,
    private brandsStore: BrandsStore,
    private brandCoursesService: CourseBrandsService,
    private translateService: TranslateService,
  ) {
    this.courseId = this.route.parent.snapshot.paramMap.get('courseId');
  }

  async ngOnInit(): Promise<void> {
    this.tags = await this.tagService.getByIdsOrderedByTitle(this.course.tags);
    this.courseBrand = await this.brandCoursesService.getCourseBrand(this.course.id, this.brand.id);

    this.form.patchValue({
      ...{
        tags: this.courseBrand.tags,
        level: this.courseBrand.level,
      },
    });
    this.form.updateValueAndValidity();
    this.formValueChangeSubscribtion = this.form.valueChanges.subscribe(() =>
      this.form.markAsDirty(),
    );
    this.loading = false;
  }

  public selectTags(tagIds: string[]): void {
    this.form.patchValue({ tags: tagIds });
  }

  public async update(): Promise<void> {
    this.isUpdating = true;

    try {
      const form: any = this.form.getRawValue();
      await this.brandCoursesService.updateBrandCourse(this.courseBrand.id, form.tags, form.level);

      this.isUpdating = false;
      this.messageService.add({
        severity: 'success',
        summary: this.translateService.instant('success'),
        detail: this.translateService.instant('adminBrandCourseDetails.successfullyUpdated'),
        styleClass: 'custom-toast',
      });
    } catch (error) {
      console.log(error);
      this.isUpdating = false;
      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('error'),
        detail: this.translateService.instant(`adminBrandCourseDetails.error}`),
        styleClass: 'custom-toast',
      });
    }
  }

  ngOnDestroy(): void {
    this.formValueChangeSubscribtion.unsubscribe();
  }
}
