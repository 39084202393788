import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { AppStore } from '../../../app.store';

export interface SearchResultItem {
  description: string;
  key: string;
  metadata?: any;
}

export interface SearchResultGroup {
  key: string;
  description: string;
  items: SearchResultItem[];
}

export interface SearchResultGroupAndProvider {
  key: string;
  description: string;
  items: SearchResultItem[];
  provider: SearchProvider;
}

export interface SearchProvider {
  search(searchTerm: string): Promise<SearchResultGroup[]>;
  searchResultSelected(groupKey: string, item: SearchResultItem): void;
}

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  private searchProviders: SearchProvider[] = [];

  constructor(private appStore: AppStore) {}

  async search(searchTerm: string): Promise<SearchResultGroupAndProvider[]> {
    if (!searchTerm) {
      return [];
    }

    searchTerm = searchTerm.trim();

    if (searchTerm === '') {
      return [];
    }

    const searchesOps = this.searchProviders.map(async (sp) => {
      const result = await sp.search(searchTerm);
      return result.map((sg) => ({ ...sg, provider: sp }));
    });

    const res = await Promise.all(searchesOps);

    return res.reduce((prev, next) => prev.concat(next), []);
  }

  addSearchProvider(provider: SearchProvider): void {
    if (!provider) {
      return;
    }

    if (this.searchProviders.indexOf(provider) >= 0) {
      console.log('The search provided was already added');
      return;
    }

    this.searchProviders.push(provider);
    this.updateIsSearchVisible();
  }

  removeSearchProvider(provider: SearchProvider): void {
    const index = this.searchProviders.indexOf(provider);

    if (index >= 0) {
      this.searchProviders.splice(index, 1);
      this.updateIsSearchVisible();
    }
  }

  searchItemSelected(groupAndProvider: SearchResultGroupAndProvider, item: SearchResultItem): void {
    groupAndProvider.provider.searchResultSelected(groupAndProvider.key, item);
  }

  private updateIsSearchVisible(): void {
    this.appStore.setIsSearchVisible(this.searchProviders.length > 0);
  }
}
