import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { AssetFileTypes } from 'src/app/core/enums';
import { IAsset } from 'src/app/core/models';
import { formatBytes, jpgImg, pdfImg, pngImg, zipImg } from 'src/app/shared';

@Component({
  selector: 'app-file-asset-card',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './file-asset-card.component.html',
  styleUrls: ['./file-asset-card.component.scss'],
})
export class FileAssetCardComponent {
  @Input() asset: IAsset;
  @Output() downloadAsset = new EventEmitter<void>();

  formatBytes = formatBytes;

  get imgUrl(): string {
    switch (this.asset.fileType) {
      case AssetFileTypes.JPG:
        return jpgImg;
      case AssetFileTypes.PDF:
        return pdfImg;
      case AssetFileTypes.PNG:
        return pngImg;
      case AssetFileTypes.ZIP:
        return zipImg;
    }
  }

  onDownload(): void {
    this.downloadAsset.emit();
  }
}
