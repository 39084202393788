export const userAvatar = '../../../assets/images/user-avatar.svg';
export const calendar = '../../../assets/images/calendar.svg';
export const jpgImg = '../../../assets/images/file-types/jpg-image.svg';
export const pdfImg = '../../../assets/images/file-types/pdf-image.svg';
export const pngImg = '../../../assets/images/file-types/png-image.svg';
export const zipImg = '../../../assets/images/file-types/zip-image.svg';
export const videoImg = '../../../assets/images/file-types/video-image.svg';
export const imgPreloader = '../../../assets/images/img-preloader.svg';
export const logoAvatar = '../../../assets/images/logo-avatar.svg';
export const wVLogo = '../../../assets/images/WV-logo.svg';
export const aboutPageBg = '../../../assets/images/about-page-bg.png';
export const aboutPageAside = '../../../assets/images/about-page-aside.png';
export const aboutPageLargeItemBg = '../../../assets/images/about-page-large-item.png';
export const aboutPageStep1 = '../../../assets/images/step-1.png';
export const aboutPageStep2 = '../../../assets/images/step-2.png';
export const aboutPageStep3 = '../../../assets/images/step-3.png';
export const macBookImg = '../../../assets/images/macbook.png';
export const programBanner = '../../../assets/images/program-banner.png';
export const chatAllowed = '../../../assets/images/chat/chat-allowed.svg';
