@if (loading()) {
  <app-loader></app-loader>
} @else {
  <div class="container">
    <div class="flex align-items-center justify-content-between">
      <h1 class="admin-page-title">{{ 'adminEventMenu.pageTitle' | translate }}</h1>
      <p-dropdown
        class="custom-dropdown custom-dropdown-required"
        panelStyleClass="custom-dropdown-panel"
        [options]="eventMenus()"
        id="menu-dropdown"
        (onChange)="onSelectMenuChange($event)">
      </p-dropdown>
    </div>

    <div class="c-mt-4 c-mb-8">
      <p-divider styleClass="custom-divider-horizontal"></p-divider>
    </div>

    <!-- Edit profile information block -->
    <div class="form-block">
      <div class="left-side">
        <h2 class="title">{{ 'adminEventMenu.howItWorksTitle' | translate }}</h2>
        <p class="description">{{ 'adminEventMenu.howItWorksDesc' | translate }}</p>
      </div>
      <div class="right-side bg-tr">
        <div class="c-my-8 organizer-container">
          <app-admin-event-menu-items-organizer
            [items]="menuItems()"
            (drillDownGroup)="onEditGroupMenu($event)">
          </app-admin-event-menu-items-organizer>
        </div>

        <div class="text-right">
          <button
            class="primary-text-color"
            pButton
            type="button"
            [label]="'adminEventMenu.update' | translate"
            [icon]="isUpdating() ? 'pi pi-spin pi-spinner' : null"
            (click)="updateMenuSettings()"></button>
        </div>
      </div>
    </div>
  </div>
}
