import { Injectable } from '@angular/core';

import { Firestore, IFirestore } from 'src/app/firebase';
import { API_ROUTES as apiRoutes } from 'src/app/shared';
import { IBrandOwnerInvite } from 'src/app/core/models';

@Injectable({
  providedIn: 'root',
})
export class BrandOwnerInviteService {
  private firestore: IFirestore;

  constructor() {
    this.firestore = Firestore();
  }

  async getBrandOwnerInvitesByUserId(userId: string): Promise<IBrandOwnerInvite[]> {
    try {
      const brandOwnerInvites: IBrandOwnerInvite[] = (
        await this.firestore
          .collection(apiRoutes.brandOwnerInvites)
          .where('userId', '==', userId)
          .get()
      ).docs.map((doc) => doc.data() as IBrandOwnerInvite);

      return brandOwnerInvites;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async deleteBrandOwnerInvite(brandOwnerInviteId: string): Promise<boolean> {
    try {
      await this.firestore.collection(apiRoutes.brandOwnerInvites).doc(brandOwnerInviteId).delete();

      return true;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }
}
