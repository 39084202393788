import { Timestamp } from 'src/app/firebase';

export interface IUserPaymentSession {
  id: string;
  sessionId: string;
  userId: string;
  ticketId: string;
  subscriptionId: string;
  createdAt: Timestamp;
  orderId?: string;
}
