import { Component } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { StripeConnectTypes } from '../../../core/enums';

@Component({
  selector: 'app-stripe-connect',
  templateUrl: './stripe-connect.component.html',
  styleUrls: ['./stripe-connect.component.scss'],
})
export class StripeConnectComponent {
  isExpressSelected = true;

  constructor(private ref: DynamicDialogRef) {}

  onExpress() {
    this.isExpressSelected = true;
  }

  onStandard() {
    this.isExpressSelected = false;
  }

  onConnect() {
    if (this.isExpressSelected) {
      this.ref.close(StripeConnectTypes.EXPRESS);
    } else {
      this.ref.close(StripeConnectTypes.STANDARD);
    }
  }
}
