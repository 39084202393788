import * as moment from 'moment';
import Player from '@vimeo/player';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import {
  Subject,
  debounceTime,
  firstValueFrom,
  from,
  merge,
  of,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { MessageService } from 'primeng/api';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { AssetEventTypes, AssetFileTypes, AssetType } from 'src/app/core/enums';
import {
  CourseInstructorsService,
  DailyCoService,
  FormService,
  HubsService,
  LibraryService,
  TagsService,
  UsersService,
  VimeoPlayerService,
  VimeoService,
} from 'src/app/core/services';
import {
  AssetStatus,
  IAsset,
  ICourse,
  IDailyRecordAccessLink,
  IEvent,
  IHub,
  IHubBrand,
  ITag,
  IUser,
  IVimeoPlayerOptions,
} from 'src/app/core/models';
import { SharedModule, logoAvatar, parseToMoment, zipImg } from 'src/app/shared';
import { JPEG_PNG_FORMATS, asyncDelay, trackByFn, updateTime } from 'src/app/core/utils';
import { CoursesStore, EventsStore, HubsStore, LibraryStore } from 'src/app/core/stores';
import { Timestamp } from 'src/app/firebase';
import { chevronIconAnimation, dropDownAnimation } from './edit-asset.animation';
import { SaveDiscardActionsComponent } from '../../save-discard-actions/save-discard-actions.component';
import { ClickOutsideDirective } from '../../../directives/click-outside/click-outside.directive';
import { AssetEventSettingComponent } from '../asset-event-setting/asset-event-setting.component';
import { ButtonComponent } from '../../button/button.component';
import { ButtonSize, ButtonType, ButtonStyle } from '../../../enums';
import { ToastComponent } from '../../toast/toast.component';

enum EventAssetSettingPropertyName {
  BREAKOUT_ROOM = 'breakoutRoomDailySetting',
  CAMERA = 'cameraDailySetting',
  MICROPHONE = 'microphoneDailySetting',
  SCREEN_SHARING = 'screenSharingDailySetting',
  TEXT_CHAT = 'textChatDailySetting',
  PEOPLE_LISTING = 'peopleListingDailySetting',
  PICTURE_IN_PICTURE = 'pictureInPictureDailySetting',
  HAND_RISING = 'handRisingDailySetting',
  EMOJI_REACTION = 'emojiReactionDailySetting',
}

@Component({
  selector: 'app-edit-asset',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    SharedModule,
    SaveDiscardActionsComponent,
    ClickOutsideDirective,
    AssetEventSettingComponent,
    ButtonComponent,
    ToastComponent,
  ],
  templateUrl: './edit-asset.component.html',
  styleUrls: ['./edit-asset.component.scss'],
  animations: [dropDownAnimation, chevronIconAnimation],
})
export class EditAssetComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('assetImageInput') assetImageInput: ElementRef<HTMLInputElement>;
  @ViewChild('filePreviewer') filePreviewerInput: ElementRef<HTMLInputElement>;
  @ViewChild('iframe', { static: false }) iframe: ElementRef<HTMLIFrameElement>;

  form: FormGroup;
  asset: IAsset;
  linkLoading = false;
  tags: ITag[] = [];
  brands: IHubBrand[] = [];
  copyIsClicked = false;
  fileTypes: string[] = Object.values(AssetType);
  assetType = AssetType;
  isUpdating = false;
  fileVideoPreview: SafeResourceUrl;
  player: Player;
  expandDropdown = false;
  assetStatus = AssetStatus;
  assetStatuses: { value: AssetStatus; label: string }[];
  newFilePreview: string | SafeUrl;
  logoAvatar = logoAvatar;
  assetFileTypes = AssetFileTypes;
  instructors: IUser[];
  eventType = AssetEventTypes;
  trackByFn = trackByFn;
  // eslint-disable-next-line max-len
  eventAssetSettings: {
    iconClass: string;
    settingUiName: string;
    settingTooltip: string;
    id: EventAssetSettingPropertyName;
    disabled: boolean;
  }[];
  buttonSize = ButtonSize;
  buttonType = ButtonType;
  buttonStyle = ButtonStyle;

  private isAssetEventPast: boolean;
  private assetImagePreview: SafeUrl;
  private internalVideoMaxSize = 8_000_000_000; // 8GB
  private assetImageMaxSize = 3_194_304;
  private fileMaxSize = 20_000_000;
  private videoAllowedFormats: string[] = ['video/mp4', 'video/webm'];
  private fileAllowedFormats: string[] = [
    'image/png',
    'image/jpeg',
    'image/jpg',
    'application/pdf',
    'application/zip',
    'application/x-zip-compressed',
  ];
  private assetImageSupportFormats = JPEG_PNG_FORMATS;
  private assetEventRecord: IDailyRecordAccessLink;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private libraryService: LibraryService,
    private hubsStore: HubsStore,
    private translateService: TranslateService,
    private vimeoService: VimeoService,
    private libraryStore: LibraryStore,
    private messageService: MessageService,
    private formService: FormService,
    private hubsService: HubsService,
    private tagsService: TagsService,
    private router: Router,
    private courseStore: CoursesStore,
    private sanitizer: DomSanitizer,
    private vimeoPlayerService: VimeoPlayerService,
    private route: ActivatedRoute,
    private location: Location,
    private courseInstructorsService: CourseInstructorsService,
    private usersService: UsersService,
    private dailyCoService: DailyCoService,
    private eventsStore: EventsStore,
  ) {}

  get canUpdate(): boolean {
    const isMainValidation: boolean =
      this.form.valid &&
      this.form.dirty &&
      !this.isUpdating &&
      this.formService.isValueChanged() &&
      !this.linkLoading;

    if (this.form.controls.type.value === AssetType.VIDEO) {
      return (
        isMainValidation &&
        (this.form.controls.videoLink.value || this.form.controls.internalVideo.value)
      );
    } else if (this.form.controls.type.value === AssetType.EVENT) {
      return isMainValidation;
    } else {
      return isMainValidation && this.form.controls.fileType.value;
    }
  }

  get titleErrorMessage(): string {
    if (
      this.form.controls.title.hasError('pattern') &&
      (this.form.controls.title.dirty || this.form.controls.title.touched)
    ) {
      return this.translateService.instant('adminLibraryAssetFrom.invalidUrl');
    }

    return this.translateService.instant('adminLibraryAssetFrom.fieldRequired');
  }

  get hub(): IHub {
    return this.hubsStore.hub;
  }

  get course(): ICourse {
    return this.courseStore.adminCourse;
  }

  get getClassForCircleMark(): string {
    if (this.form?.controls.status.value === AssetStatus.PUBLISHED) {
      return 'green';
    }

    return 'orange';
  }

  get currentAssetStatus(): string {
    return this.translateService.instant(`assetCard.statuses.${this.form?.controls.status.value}`);
  }

  get assetImage(): string | SafeUrl {
    return this.assetImagePreview ?? this.form?.controls.image.value;
  }

  get assetFile(): string | SafeUrl {
    if (this.form.controls.type.value === AssetType.VIDEO) {
      return this.assetVideoPath;
    }

    if (this.form.controls.type.value === AssetType.FILE) {
      return this.assetFilePath;
    }
  }

  get assetVideoPath(): string | SafeUrl {
    if (this.form.controls.videoLink.value) {
      return this.form.controls.videoLink.value;
    }

    return this.fileVideoPreview ?? this.form?.controls.internalVideo.value;
  }

  get assetFilePath(): string | SafeUrl {
    if (this.form.controls.fileType.value === AssetFileTypes.ZIP) {
      return zipImg;
    }

    if (!this.newFilePreview && this.form.controls.file.value) {
      return this.sanitizer.bypassSecurityTrustUrl(this.form.controls.file.value);
    }

    return this.newFilePreview;
  }

  get isShowFileImg(): boolean {
    return (
      this.assetFile &&
      this.form.controls.fileType.value !== AssetFileTypes.PDF &&
      this.form.controls.type.value === AssetType.FILE
    );
  }

  get isShowFilePdfViewer(): boolean {
    return (
      this.assetFile &&
      this.form.controls.fileType.value === AssetFileTypes.PDF &&
      this.form.controls.type.value === AssetType.FILE
    );
  }

  get isShowFileVideo(): boolean {
    return (
      this.assetFile &&
      this.form.controls.type.value === AssetType.VIDEO &&
      !this.form.controls.videoLink.value
    );
  }

  get isShowVimeoPlayer(): boolean {
    return !!(
      this.form.controls.type.value === AssetType.VIDEO && this.form.controls.videoLink.value
    );
  }

  get event(): IEvent {
    return this.eventsStore.adminEvent;
  }

  get isAssetEventFinished(): boolean {
    const condition: boolean =
      this.asset.type === AssetType.EVENT &&
      this.asset.isRecording &&
      moment().isAfter(parseToMoment(this.asset.eventAssetEndDate).add(20, 'minutes'));

    return condition;
  }

  async ngOnInit(): Promise<void> {
    this.initForm();
    const isSessionAsset = this.router.url.includes('session-assets');
    of(true)
      .pipe(
        switchMap(() => {
          if (isSessionAsset) {
            const assetId = this.route.snapshot.params.assetId;

            return from(this.libraryService.getOne(assetId));
          }

          return this.libraryStore.adminAsset$;
        }),
        tap((asset: IAsset) => {
          this.asset = asset;
          this.isAssetEventPast =
            this.asset.type === AssetType.EVENT &&
            moment().isAfter(moment(parseToMoment(this.asset?.eventAssetEndDate)));
          this.setInitialValueForForm();
          this.setEventAssetSettings();
          this.formService.setForm(this.form);
          if (this.form.controls.fileType.value === AssetFileTypes.PDF) {
            this.newFilePreview = this.sanitizer.bypassSecurityTrustResourceUrl(
              this.form.controls.file.value,
            );
          }
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();

    if (this.course) {
      const userCoursesIds: string[] =
        await this.courseInstructorsService.getAllCourseInstructorsIds(this.course.id);
      this.instructors = await this.usersService.getUserByIds(userCoursesIds);
    }

    this.assetStatuses = Object.keys(AssetStatus).map((status: AssetStatus) => ({
      value: status,
      label: this.translateService.instant(`assetCard.statuses.${AssetStatus[status]}`),
    }));

    const [hubTagIds, brands] = await Promise.all([
      this.tagsService.getHubTagsIdsArray(this.hub.id),
      this.hubsService.getBrandsFromHub(this.hub.id),
    ]);

    this.tags = await this.tagsService.getByIdsOrderedByTitle(hubTagIds);
    this.brands = brands;

    const queryParams = this.route.snapshot.queryParams;
    if (queryParams['action-before'] === 'create') {
      this.showToastMessage('success', 'adminLibraryAssetFrom.createAlert');
      this.location.replaceState(
        `/${this.hubsStore.useHubUrl}/admin/library/asset/${this.asset.id}`,
      );
    }

    const assetType$ = this.form.controls.type.valueChanges.pipe(
      tap(() => {
        this.clearNewFilePreview();
      }),
    );

    const videoLink$ = this.form.controls.videoLink.valueChanges.pipe(
      debounceTime(500),
      tap((videoLinkValue: string) => {
        this.form.controls.internalVideo.setValue(null);
        if (!videoLinkValue) {
          this.form.controls.videoDuration.setValue(null);
        } else if (!!this.player && videoLinkValue) {
          this.vimeoPlayerService.loadVideo(this.player, { url: videoLinkValue });
        } else {
          this.createVimeoPlayer();
        }
      }),
    );

    merge(assetType$, videoLink$).pipe(takeUntil(this.unsubscribe$)).subscribe();

    if (this.isAssetEventFinished) {
      this.assetEventRecord = await firstValueFrom(
        this.dailyCoService.getFirstAccessRecordLinkByRoomName(
          this.asset.eventAssetMeetingRoomName,
        ),
      );
    }
  }

  ngAfterViewInit(): void {
    if (this.isShowVimeoPlayer && !this.player) {
      this.createVimeoPlayer();
    }
  }

  getFileTypeText(type: string): string {
    return this.translateService.instant(`adminLibraryAssetFrom.${type.toLowerCase()}Label`);
  }

  selectTags(tagIds: string[]): void {
    this.form.patchValue({ tags: tagIds });
    this.makeFormControlDirty('tags');
  }

  async getVideoDurationByUrl(url: string): Promise<number> {
    try {
      const { duration } = await this.vimeoService.getVideoInfoByUrl(url).toPromise();
      return duration;
    } catch (error) {
      console.warn(error);
      if (error.status === 404) {
        this.form.controls.videoLink.setErrors({ invalidUrl: true });
      }
      this.isUpdating = false;
      throw new Error(error);
    }
  }

  async onDiscard(): Promise<void> {
    if (this.player) {
      this.vimeoPlayerService.destroy();
    }
    this.fileVideoPreview = null;
    this.setInitialValueForForm();
    await asyncDelay(1);
    if (this.form.controls.videoLink.value) {
      this.createVimeoPlayer();
    }
  }

  async onConfirm(): Promise<void> {
    try {
      this.isUpdating = true;
      await asyncDelay(1);
      await this.update();
      this.formService.setForm(this.form);
    } catch (error) {
      console.error(error);
    }
  }

  onBack(): void {
    if (this.course) {
      this.router.navigate([
        `/${this.hubsStore.useHubUrl}/admin/courses/${this.course.link}/setup/media`,
      ]);
    }
  }

  onDrop(event: DragEvent): void {
    if (!this.assetFile) {
      event.preventDefault();
      this.onUploadFile(event.dataTransfer.files);
    }
  }

  onDragOver(event: DragEvent): void {
    if (!this.assetFile) {
      event.preventDefault();
    }
  }

  onUploadAssetFile(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (!input.files || input.files.length === 0) {
      return;
    }

    this.onUploadFile(input.files);
  }

  clearNewFilePreview(): void {
    this.newFilePreview = null;
    this.fileVideoPreview = null;
    if (this.filePreviewerInput?.nativeElement) {
      this.filePreviewerInput.nativeElement.value = '';
    }
    this.form.patchValue({
      file: null,
      fileType: null,
      fileSize: null,
      videoLink: null,
      videoDuration: null,
      internalVideo: null,
    });
    this.makeFormControlDirty('file');
    this.makeFormControlDirty('fileType');
    this.makeFormControlDirty('fileSize');
    this.makeFormControlDirty('videoLink');
    this.makeFormControlDirty('videoDuration');
    this.makeFormControlDirty('internalVideo');
  }

  onCloseToggleDropdown(): void {
    this.expandDropdown = false;
  }

  onToggleDropdown(): void {
    this.expandDropdown = !this.expandDropdown;
  }

  onSelectAssetStatus(status: { value: AssetStatus; label: string }): void {
    this.form.controls.status.setValue(AssetStatus[status.value]);
    this.makeFormControlDirty('status');
    this.onCloseToggleDropdown();
  }

  onUploadAssetImage(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (!input.files || input.files.length === 0) {
      return;
    }

    const file = input.files[0];

    if (file.size > this.assetImageMaxSize) {
      this.showToastMessage('error', 'adminLibraryAssetFrom.maxSizeError');

      return;
    }

    if (!this.assetImageSupportFormats.includes(file.type)) {
      this.showToastMessage('error', 'adminLibraryAssetFrom.invalidVideoFormat');

      return;
    }
    this.form.controls.image.setValue(file);
    this.makeFormControlDirty('image');

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      this.assetImagePreview = this.sanitizer.bypassSecurityTrustUrl(reader.result as string);
    };

    reader.onerror = (error) => {
      console.log(error);
      this.assetImagePreview = null;
      this.assetImageInput.nativeElement.value = '';
    };
  }

  onClearAssetImage(): void {
    this.form.patchValue({ image: null });
    this.assetImagePreview = null;
    this.assetImageInput.nativeElement.value = '';
    this.makeFormControlDirty('image');
  }

  onChangeEventSettingValue(newValue: boolean, propertyName: string): void {
    this.form.controls[propertyName].setValue(newValue);
    this.form.controls[propertyName].markAsDirty();
  }

  downloadRecord(): void {
    if (!this.isAssetEventFinished || !this.assetEventRecord?.download_link) {
      return null;
    }
    window.location.href = this.assetEventRecord.download_link;
  }

  ngOnDestroy(): void {
    if (this.player) {
      this.vimeoPlayerService.destroy();
    }
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private initForm(): void {
    this.form = new FormGroup({
      title: new FormControl(null, [Validators.required]),
      shortDescription: new FormControl(null),
      type: new FormControl({ value: AssetType.FILE, disabled: true }),
      videoLink: new FormControl(null),
      file: new FormControl(null),
      fileType: new FormControl(null),
      fileSize: new FormControl(null),
      brands: new FormControl(null),
      featured: new FormControl(false),
      isVisible: new FormControl(false),
      description: new FormControl(null),
      tags: new FormControl(null),
      image: new FormControl(null),
      internalVideo: new FormControl(null),
      status: new FormControl(null),
      videoDuration: new FormControl(null),
      date: new FormControl(null),
      start: new FormControl(null),
      end: new FormControl(null),
      instructors: new FormControl(null),
      eventType: new FormControl(null),
      isRecording: new FormControl(null),
      breakoutRoomDailySetting: new FormControl(null),
      cameraDailySetting: new FormControl(null),
      microphoneDailySetting: new FormControl(null),
      screenSharingDailySetting: new FormControl(null),
      textChatDailySetting: new FormControl(null),
      peopleListingDailySetting: new FormControl(null),
      pictureInPictureDailySetting: new FormControl(null),
      handRisingDailySetting: new FormControl(null),
      emojiReactionDailySetting: new FormControl(null),
    });
  }

  private setEventAssetSettings(): void {
    this.eventAssetSettings = [
      {
        iconClass: 'fa-regular fa-door-open',
        settingUiName: 'adminLibraryAssetFrom.breakoutRoomSetting',
        settingTooltip: 'adminLibraryAssetFrom.breakoutRoomSettingPlaceholder',
        id: EventAssetSettingPropertyName.BREAKOUT_ROOM,
        disabled: this.isAssetEventPast,
      },
      {
        iconClass: 'fa-regular fa-video',
        settingUiName: 'adminLibraryAssetFrom.cameraSetting',
        settingTooltip: 'adminLibraryAssetFrom.cameraSettingPlaceholder',
        id: EventAssetSettingPropertyName.CAMERA,
        disabled: this.isAssetEventPast,
      },
      {
        iconClass: 'fa-regular fa-microphone',
        settingUiName: 'adminLibraryAssetFrom.microphoneSetting',
        settingTooltip: 'adminLibraryAssetFrom.microphoneSettingPlaceholder',
        id: EventAssetSettingPropertyName.MICROPHONE,
        disabled: this.isAssetEventPast,
      },
      {
        iconClass: 'fa-regular fa-screencast',
        settingUiName: 'adminLibraryAssetFrom.screenSharingSetting',
        settingTooltip: 'adminLibraryAssetFrom.screenSharingSettingPlaceholder',
        id: EventAssetSettingPropertyName.SCREEN_SHARING,
        disabled: this.isAssetEventPast,
      },
      {
        iconClass: 'fa-regular fa-comments',
        settingUiName: 'adminLibraryAssetFrom.textChatSetting',
        settingTooltip: 'adminLibraryAssetFrom.textChatSettingPlaceholder',
        id: EventAssetSettingPropertyName.TEXT_CHAT,
        disabled: this.isAssetEventPast,
      },
      {
        iconClass: 'fa-regular fa-list',
        settingUiName: 'adminLibraryAssetFrom.peopleListingSetting',
        settingTooltip: 'adminLibraryAssetFrom.peopleListingSettingPlaceholder',
        id: EventAssetSettingPropertyName.PEOPLE_LISTING,
        disabled: this.isAssetEventPast,
      },
      {
        iconClass: 'fa-regular fa-up-down-left-right',
        settingUiName: 'adminLibraryAssetFrom.pictureInPictureSetting',
        settingTooltip: 'adminLibraryAssetFrom.pictureInPictureSettingPlaceholder',
        id: EventAssetSettingPropertyName.PICTURE_IN_PICTURE,
        disabled: this.isAssetEventPast,
      },
      {
        iconClass: 'fa-regular fa-hand',
        settingUiName: 'adminLibraryAssetFrom.handRisingSetting',
        settingTooltip: 'adminLibraryAssetFrom.handRisingSettingPlaceholder',
        id: EventAssetSettingPropertyName.HAND_RISING,
        disabled: this.isAssetEventPast,
      },
      {
        iconClass: 'fa-regular fa-face-smile',
        settingUiName: 'adminLibraryAssetFrom.emojiReactionsSetting',
        settingTooltip: 'adminLibraryAssetFrom.emojiReactionsSettingPlaceholder',
        id: EventAssetSettingPropertyName.EMOJI_REACTION,
        disabled: this.isAssetEventPast,
      },
    ];
  }

  private setInitialValueForForm(): void {
    this.form.patchValue(
      {
        ...this.asset,
        brands: this.asset?.brands[0],
        date: this.asset?.eventAssetStartDate
          ? parseToMoment(this.asset.eventAssetStartDate).toDate()
          : this.asset?.eventAssetStartDate,
        start: this.asset?.eventAssetStartDate
          ? parseToMoment(this.asset.eventAssetStartDate).toDate()
          : this.asset?.eventAssetStartDate,
        end: this.asset?.eventAssetEndDate
          ? parseToMoment(this.asset.eventAssetEndDate).toDate()
          : this.asset?.eventAssetEndDate,
      },
      { emitEvent: false },
    );

    if (this.isAssetEventPast) {
      this.form.controls.date.disable();
      this.form.controls.start.disable();
      this.form.controls.end.disable();
      this.form.controls.isRecording.disable();
      this.form.controls.breakoutRoomDailySetting.disable();
      this.form.controls.cameraDailySetting.disable();
      this.form.controls.microphoneDailySetting.disable();
      this.form.controls.screenSharingDailySetting.disable();
      this.form.controls.textChatDailySetting.disable();
      this.form.controls.peopleListingDailySetting.disable();
      this.form.controls.pictureInPictureDailySetting.disable();
      this.form.controls.handRisingDailySetting.disable();
      this.form.controls.emojiReactionDailySetting.disable();
    }
  }

  private async createVimeoPlayer(): Promise<void> {
    const options: IVimeoPlayerOptions = { url: `${this.form.controls.videoLink.value}` };
    this.player = this.vimeoPlayerService.createVimeoPlayer(this.iframe?.nativeElement, options);
  }

  private makeFormControlDirty(formControlName: string): void {
    this.form.controls[formControlName].markAsDirty();
  }

  private onUploadFile(files: FileList): void {
    const file = files[0];
    const maxFileSize =
      this.form.controls.type.value === AssetType.VIDEO
        ? this.internalVideoMaxSize
        : this.fileMaxSize;

    if (file.size > maxFileSize) {
      this.showToastMessage('error', 'adminLibraryAssetFrom.maxSizeError');
      this.clearNewFilePreview();

      return;
    }

    const formats =
      this.form.controls.type.value === AssetType.VIDEO
        ? this.videoAllowedFormats
        : this.fileAllowedFormats;
    if (!formats.includes(file.type)) {
      this.showToastMessage('error', 'adminLibraryAssetFrom.invalidVideoFormat');
      this.clearNewFilePreview();

      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      if (this.form.controls.type.value === AssetType.VIDEO) {
        this.fileVideoPreview = this.sanitizer.bypassSecurityTrustResourceUrl(
          reader.result as string,
        );
        this.form.controls.internalVideo.setValue(file);
        this.makeFormControlDirty('internalVideo');
        this.setDurationForInternalVideo(file);
      } else {
        let fileType: AssetFileTypes;
        switch (file.type) {
          case 'image/png':
            fileType = AssetFileTypes.PNG;
            break;
          case 'image/jpeg':
          case 'image/jpg':
            fileType = AssetFileTypes.JPG;
            break;
          case 'application/pdf':
            fileType = AssetFileTypes.PDF;
            break;
          case 'application/zip':
          case 'application/x-zip-compressed':
            fileType = AssetFileTypes.ZIP;
            break;
        }
        if (fileType === AssetFileTypes.PDF) {
          this.newFilePreview = this.sanitizer.bypassSecurityTrustResourceUrl(
            reader.result as string,
          );
        } else {
          this.newFilePreview = this.sanitizer.bypassSecurityTrustUrl(reader.result as string);
        }
        this.form.controls.file.setValue(file || null);
        this.form.controls.fileType.setValue(fileType || null);
        this.form.controls.fileSize.setValue(file.size || null);
        this.makeFormControlDirty('file');
        this.makeFormControlDirty('fileType');
        this.makeFormControlDirty('fileSize');
      }
    };

    reader.onerror = (error) => {
      console.log(error);
      this.newFilePreview = null;
      this.filePreviewerInput.nativeElement.value = '';
    };
  }

  private setDurationForInternalVideo(file: File): void {
    const videoElement = document.createElement('video');
    videoElement.preload = 'metadata';
    const objectURL = URL.createObjectURL(file);
    videoElement.src = objectURL;
    videoElement.addEventListener('loadedmetadata', () => {
      this.form.controls.videoDuration.setValue(Math.round(videoElement.duration));
      this.makeFormControlDirty('videoDuration');
      URL.revokeObjectURL(objectURL);
      videoElement.removeEventListener('loadedmetadata', () => {});
    });
  }

  private async update(): Promise<void> {
    if (this.form.invalid) {
      return;
    }
    this.isUpdating = true;
    const { date, start, end, ...newAsset } = this.form.getRawValue();

    const isGetVideoDuration: boolean =
      newAsset.type === AssetType.VIDEO &&
      this.asset?.videoLink !== newAsset.videoLink &&
      !this.fileVideoPreview;

    const videoDuration: number = isGetVideoDuration
      ? await this.getVideoDurationByUrl(newAsset.videoLink)
      : this.form.controls?.videoDuration.value;

    let eventAssetStartDate = null;
    let eventAssetEndDate = null;
    if (newAsset.type === AssetType.EVENT) {
      await this.checkAndUpdateDailyRoom();
      eventAssetStartDate = Timestamp.fromMillis(updateTime(date, start).toDate().getTime());
      eventAssetEndDate = Timestamp.fromMillis(updateTime(date, end).toDate().getTime());
    }

    this.asset = {
      ...this.asset,
      ...newAsset,
      id: this.asset?.id || null,
      _title_: newAsset.title.toLowerCase(),
      file: newAsset.type === AssetType.FILE ? newAsset.file : null,
      fileType: newAsset.type === AssetType.FILE ? newAsset.fileType : null,
      fileName:
        newAsset.type === AssetType.FILE && newAsset.fileType
          ? `${newAsset.title}.${newAsset.fileType}`
          : null,
      fileSize: newAsset.type === AssetType.FILE ? newAsset.fileSize : null,
      videoLink: newAsset.type === AssetType.VIDEO ? newAsset.videoLink : null,
      videoDuration,
      isGlobal: true,
      hubId: this.hubsStore.hubId,
      brands: newAsset.brands?.length ? [newAsset.brands] : [],
      eventAssetStartDate,
      eventAssetEndDate,
    };
    const result: IAsset = await this.libraryService.update(this.hubsStore.hubId, this.asset);

    if (result) {
      this.libraryStore.setAdminAsset(this.asset);
      this.showToastMessage('success', 'adminLibraryAsset.assetSuccessfullyUpdated');
    } else {
      this.showToastMessage('error', 'adminLibraryAsset.assetErrorUpdated');
    }
    this.isUpdating = false;
  }

  private async checkAndUpdateDailyRoom(): Promise<void> {
    try {
      if (this.isAssetEventPast) {
        return;
      }
      const isSame = this.compareOldAndNewValues();
      if (isSame) {
        return;
      }
      const newAsset = this.form.getRawValue();
      const endDate = updateTime(newAsset.date, newAsset.end);
      await firstValueFrom(
        this.dailyCoService.updateRoom(this.asset.eventAssetMeetingRoomName, {
          exp: moment(endDate).add(20, 'minutes').unix(),
          start_video_off: !newAsset.cameraDailySetting,
          start_audio_off: !newAsset.microphoneDailySetting,
          enable_screenshare: newAsset.screenSharingDailySetting,
          enable_chat: newAsset.textChatDailySetting,
          enable_advanced_chat: newAsset.textChatDailySetting,
          enable_people_ui: newAsset.peopleListingDailySetting,
          enable_pip_ui: newAsset.pictureInPictureDailySetting,
          enable_hand_raising: newAsset.handRisingDailySetting,
          enable_emoji_reactions: newAsset.emojiReactionDailySetting,
          enable_breakout_rooms: newAsset.breakoutRoomDailySetting,
        }),
      );
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  private compareOldAndNewValues(): boolean {
    const newAsset = this.form.getRawValue();
    const oldValues = {
      eventAssetEndDate: this.asset.eventAssetEndDate,
      cameraDailySetting: this.asset.cameraDailySetting,
      microphoneDailySetting: this.asset.microphoneDailySetting,
      screenSharingDailySetting: this.asset.screenSharingDailySetting,
      textChatDailySetting: this.asset.textChatDailySetting,
      peopleListingDailySetting: this.asset.peopleListingDailySetting,
      pictureInPictureDailySetting: this.asset.pictureInPictureDailySetting,
      handRisingDailySetting: this.asset.handRisingDailySetting,
      emojiReactionDailySetting: this.asset.emojiReactionDailySetting,
      breakoutRoomDailySetting: this.asset.breakoutRoomDailySetting,
    };
    const endDate = updateTime(newAsset.date, newAsset.end);
    const newValues = {
      eventAssetEndDate: Timestamp.fromMillis(endDate.toDate().getTime()),
      cameraDailySetting: newAsset.cameraDailySetting,
      microphoneDailySetting: newAsset.microphoneDailySetting,
      screenSharingDailySetting: newAsset.screenSharingDailySetting,
      textChatDailySetting: newAsset.textChatDailySetting,
      peopleListingDailySetting: newAsset.peopleListingDailySetting,
      pictureInPictureDailySetting: newAsset.pictureInPictureDailySetting,
      handRisingDailySetting: newAsset.handRisingDailySetting,
      emojiReactionDailySetting: newAsset.emojiReactionDailySetting,
      breakoutRoomDailySetting: newAsset.breakoutRoomDailySetting,
    };

    return JSON.stringify(oldValues) === JSON.stringify(newValues);
  }

  private showToastMessage(severity: 'success' | 'error', detail: string): void {
    this.messageService.add({
      severity,
      summary: this.translateService.instant(severity),
      detail: this.translateService.instant(detail),
      styleClass: 'custom-toast',
    });
  }
}
