import { Timestamp } from 'src/app/firebase';

export interface IEventProduct {
  id: string;
  eventId: string;
  productId: string;
  brandId: string;
  eventTitle: string;
  productTitle: string;
  brandName: string;
  createdAt: Timestamp;
  createdBy: string;
  updatedAt: Timestamp;
  updatedBy: string;
  featured: boolean;
}
