export interface IHubTag {
  id: string;
  hubId: string;
  tagId: string;
  _tagTitle_: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
}
