import { Component } from '@angular/core';

@Component({
  selector: 'app-event-engagement-report',
  standalone: true,
  imports: [],
  templateUrl: './event-engagement-report.component.html',
  styleUrl: './event-engagement-report.component.scss',
})
export class EventEngagementReportComponent {}
