import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';

import { IBrand, ICourse } from 'src/app/core/models';
import { TranslationsService } from 'src/app/core/services';
import { BrandsStore, CoursesStore, HubsStore } from 'src/app/core/stores';

@Component({
  selector: 'app-course-brand',
  templateUrl: './course-brand.component.html',
  styleUrls: ['./course-brand.component.scss'],
})
export class CourseBrandComponent implements OnInit {
  loading = true;
  menuItems: MenuItem[] = [];

  constructor(
    private translationsService: TranslationsService,
    public hubsStore: HubsStore,
    private brandsStore: BrandsStore,
    private courseStore: CoursesStore,
  ) {}

  get brand(): IBrand {
    return this.brandsStore.adminBrand;
  }

  get adminCourse(): ICourse {
    return this.courseStore.adminCourse;
  }

  ngOnInit(): void {
    this.buildMenuItems();
    this.loading = false;
  }

  private buildMenuItems(): void {
    this.menuItems = [
      {
        label: this.translationsService.getTranslationSync('adminBrandCourse.brandTabProfile'),
        icon: 'far fa-address-card',
        routerLink: `/${this.hubsStore.useHubUrl}/admin/courses/${this.adminCourse.link}/setup/brands/${this.brand.id}/profile`,
      },
      {
        label: this.translationsService.getTranslationSync('adminBrandCourse.brandTabTeam'),
        icon: 'fa-regular fa-user-group',
        routerLink: `/${this.hubsStore.useHubUrl}/admin/courses/${this.adminCourse.link}/setup/brands/${this.brand.id}/team`,
      },
      {
        label: this.translationsService.getTranslationSync(
          'adminBrandCourse.brandTabCourseDetails',
        ),
        icon: 'far fa-compass',
        routerLink: `/${this.hubsStore.useHubUrl}/admin/courses/${this.adminCourse.link}/setup/brands/${this.brand.id}/course-details`,
      },
    ];
  }
}
