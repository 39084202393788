<div class="container">
  <div class="upload-block">
    <h5 class="title">{{ titleMediaForm | translate }}</h5>
    <p class="description">{{ description | translate }}</p>
    <div class="actions">
      <input
        class="input"
        type="file"
        #logoUpload
        (change)="onLogoImageUpload($event)" />
      <div class="btn-actions-container">
        <app-button
          buttonText="{{ btnConfirm | translate }}"
          [buttonSize]="buttonSize.SMALL"
          [loading]="logoUploadProcess"
          (click)="logoUpload.click()" />
        <app-button
          buttonText="{{ btnCancel | translate }}"
          [buttonStyle]="buttonStyle.TERTIARY_SECONDARY"
          [buttonSize]="buttonSize.SMALL"
          (click)="clearLogoImage()" />
      </div>
    </div>
  </div>
  <div class="preview-block">
    <img
      class="img"
      [class.uploaded-img]="!!logoImage"
      [src]="logoImage ? logoImage : logoAvatar" />
  </div>
</div>
