<div class="confirm-dialog-container">
  <i
    class="fa-regular fa-xmark close-icon"
    (click)="onCloseDialog()">
  </i>
  <div class="icon">
    <i class="far fa-envelope-circle-check"></i>
  </div>
  <h1>{{ 'adminEmailTemplates.addTitle' | translate }}</h1>
  <p>{{ 'adminEmailTemplates.addText' | translate }}</p>
  <div class="actions-container">
    <app-button
      buttonText="{{ 'adminEmailTemplates.confirmEmailEditorBtn' | translate }}"
      [buttonSize]="buttonSize.MEDIUM"
      (click)="ref.close('basic')" />
    <app-button
      buttonText="{{ 'adminEmailTemplates.confirmHtmlEditorBtn' | translate }}"
      [buttonSize]="buttonSize.MEDIUM"
      (click)="ref.close('html')" />
    <app-button
      class="app-button-last"
      buttonText="{{ 'adminEmailTemplates.useUnlayer' | translate }}"
      [buttonSize]="buttonSize.MEDIUM"
      (click)="ref.close('unlayer')" />
  </div>
</div>
