import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { CommonModule } from '@angular/common';
import { loadConnectAndInitialize } from '@stripe/connect-js';
import { TranslateService } from '@ngx-translate/core';

import { StripeService, ThemesService } from 'src/app/core/services';
import { HubsStore } from 'src/app/core/stores';
import { environment } from 'src/environments/environment';
import { LoaderModule } from 'src/app/core/modules/loader/loader.module';
import { SharedModule } from 'src/app/shared';
import {
  getStripeEmbedComponentsAppearanceConfig,
  getStripeEmbedComponentLocaleConfig,
} from 'src/app/standalone/shared';

@Component({
  selector: 'app-report-payments',
  standalone: true,
  imports: [CommonModule, LoaderModule, SharedModule],
  templateUrl: './report-payments.component.html',
  styleUrls: ['./report-payments.component.scss'],
})
export class ReportPaymentsComponent implements OnInit {
  loading = true;
  showEmptyState = false;
  appPrimaryColor: string;

  constructor(
    private stripeService: StripeService,
    private hubsStore: HubsStore,
    private elementRef: ElementRef,
    private translateService: TranslateService,
    private themesService: ThemesService,
    private renderer2: Renderer2,
  ) {}

  async ngOnInit(): Promise<void> {
    const currentTheme = localStorage.getItem('styleTheme');
    this.appPrimaryColor =
      this.themesService.systemAppearanceSettings[currentTheme + 'Theme'].properties[
        '--appPrimaryColor'
      ];
    this.showEmptyState = await this.isEmptyState();
    if (!this.showEmptyState) {
      await this.buildEmbedComponent();
    } else {
      this.loading = false;
    }
  }

  async isEmptyState(): Promise<boolean> {
    const hubId = this.hubsStore.hubId;
    let isExpress = false;
    const isStripeConnected = await this.stripeService.getStripeConnectStatusByHubId(hubId);
    if (isStripeConnected) {
      isExpress = await this.stripeService.getHubExpressStatus(hubId);
    } else {
      return true;
    }
    return !isExpress;
  }

  async buildEmbedComponent(): Promise<void> {
    const connectId = await this.stripeService.getStripeConnectIdByHubId(this.hubsStore.hubId);
    const locale = getStripeEmbedComponentLocaleConfig(this.translateService.currentLang);
    const appearance = getStripeEmbedComponentsAppearanceConfig(this.appPrimaryColor);
    const stripeConnectInstance = loadConnectAndInitialize({
      publishableKey: environment.stripe.publishableKey,
      locale,
      appearance: {
        ...appearance,
      },
      fetchClientSecret: () =>
        this.stripeService.createAccountSessionForPaymentComponent(connectId),
      fonts: [],
    });
    const paymentsContainer: HTMLDivElement = this.renderer2.createElement('div');
    const payments = stripeConnectInstance.create('payments');
    this.renderer2.setStyle(paymentsContainer, 'padding', '2rem');
    this.renderer2.setStyle(paymentsContainer, 'background', 'var(--layer-01)');
    this.renderer2.setStyle(paymentsContainer, 'border-radius', '1.25rem');
    this.renderer2.appendChild(paymentsContainer, payments);
    this.renderer2.appendChild(this.elementRef.nativeElement, paymentsContainer);
    this.loading = false;
  }
}
