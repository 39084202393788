import Grade from 'grade-js';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';

import { IMG_FORMATS, asyncDelay } from 'src/app/core/utils';
import { logoAvatar } from 'src/app/shared/utils';
import { ButtonSize, ButtonStyle } from 'src/app/standalone';
import { ButtonComponent } from 'src/app/standalone/shared/components';

@Component({
  selector: 'app-big-profile-picture-media-form',
  standalone: true,
  imports: [TranslateModule, ButtonComponent],
  templateUrl: './big-profile-picture-media-form.component.html',
  styleUrl: './big-profile-picture-media-form.component.scss',
})
export class BigProfilePictureMediaFormComponent implements OnChanges {
  @Input() titleMediaForm: string;
  @Input() description: string;
  @Input() btnConfirm: string;
  @Input() btnCancel: string;
  @Input() imgValue: string | File;
  @Input() isRequired = false;
  @Input() objectFit: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down' = 'cover';
  @Output() changeValue = new EventEmitter<File>();
  @Output() changeGradient = new EventEmitter<string>();
  @ViewChild('profileImageUpload') profileImageUploadInput: ElementRef<HTMLInputElement>;

  profileImageUploadProcess = false;
  logoAvatar = logoAvatar;
  buttonStyle = ButtonStyle;
  buttonSize = ButtonSize;

  private newProfileImagePreview: string;

  constructor(
    private sanitizer: DomSanitizer,
    private messageService: MessageService,
    private translateService: TranslateService,
    private renderer2: Renderer2,
    private el: ElementRef,
  ) {}

  get profileImage(): string | ArrayBuffer | SafeUrl {
    if (this.profileImageUploadProcess) {
      return null;
    }

    return this.newProfileImagePreview && typeof this.newProfileImagePreview === 'string'
      ? this.sanitizer.bypassSecurityTrustUrl(this.newProfileImagePreview)
      : this.imgValue;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.imgValue.previousValue !== changes.imgValue.currentValue &&
      !(this.imgValue instanceof File)
    ) {
      this.newProfileImagePreview = null;
    }
  }

  onProfileImageUpload(event: Event): void {
    const onSuccess = (img: string) => {
      this.newProfileImagePreview = img;
      this.profileImageUploadProcess = false;
    };

    const onError = (e) => {
      if (e === 'type_size') {
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('error'),
          detail: this.translateService.instant(
            'application.toasters.imageTypeOrSizeRuleViolation_PNG_JPEG',
            { size: 3 },
          ),
          styleClass: 'custom-toast',
        });
        this.profileImageUploadInput.nativeElement.value = '';
      }
    };

    this.uploadImg(event, onSuccess, onError);
  }

  clearProfileImage(): void {
    this.newProfileImagePreview = null;
    this.profileImageUploadInput.nativeElement.value = '';
    this.changeValue.emit(null);
    this.changeGradient.emit(null);
  }

  private uploadImg(
    event: Event,
    onSuccess: (img: string | ArrayBuffer) => void,
    onError: (e) => void,
  ): void {
    const input = event.target as HTMLInputElement;

    if (!input.files || input.files.length === 0) {
      return;
    }

    const imageFile = input.files[0];

    if (!IMG_FORMATS.includes(imageFile.type) || imageFile.size > 3_194_304) {
      onError('type_size');
      return;
    }

    this.changeValue.emit(imageFile);

    const reader = new FileReader();
    reader.readAsDataURL(imageFile);

    reader.onloadstart = () => {
      this.profileImageUploadProcess = true;
    };

    reader.onloadend = () => {
      this.profileImageUploadProcess = false;
      onSuccess(reader.result);
      this.getBannerGradient(reader.result as string);
    };

    reader.onerror = (error) => {
      this.profileImageUploadProcess = false;
      console.log(error);
      onError(error);
    };
  }

  private async getBannerGradient(pathUrl: string): Promise<void> {
    await asyncDelay(1);
    const gradientWrapElement = this.renderer2.createElement('div');
    this.renderer2.addClass(gradientWrapElement, 'gradient-wrap');
    this.renderer2.setAttribute(gradientWrapElement, 'id', 'gradient-wrap');
    this.renderer2.setStyle(gradientWrapElement, 'width', '0');
    this.renderer2.setStyle(gradientWrapElement, 'height', '0');

    const imgElement = this.renderer2.createElement('img');
    this.renderer2.setAttribute(imgElement, 'src', pathUrl);
    this.renderer2.setStyle(imgElement, 'display', 'none');

    this.renderer2.appendChild(gradientWrapElement, imgElement);
    this.renderer2.appendChild(this.el.nativeElement, gradientWrapElement);

    Grade(document.querySelectorAll('.gradient-wrap'));

    const el = document.getElementById('gradient-wrap');
    const backgroundImage = el.style.backgroundImage;
    this.renderer2.removeChild(this.el.nativeElement, gradientWrapElement);
    this.changeGradient.emit(backgroundImage);
  }
}
