<ng-container *ngIf="loading">
  <app-loader></app-loader>
</ng-container>

<div
  *ngIf="!loading"
  class="hub-page-container">
  <div class="col-8 left-side">
    <div class="header-section">
      <h1>{{ form.controls.title.value }}</h1>
      <div class="col txt-a-r">
        <button
          pButton
          pRipple
          [icon]="'far fa-copy'"
          class="p-button-rounded p-button-text"
          (click)="copy()"></button>
        <button
          pButton
          pRipple
          class="preview"
          (click)="preview()"
          [label]="'hubPage.previewBtn' | translate"></button>
      </div>
    </div>

    <div class="html-editor">
      <app-input-label
        labelType="required"
        text="hubPage.htmlPlaceholder">
      </app-input-label>
      <textarea
        class="custom-input-textarea resize-none custom-input-textarea-required textarea"
        pInputTextarea
        [formControl]="form.controls.html"
        placeholder="{{ 'hubPage.htmlPlaceholder' | translate }}">
      </textarea>
    </div>
  </div>

  <div class="col-4 right-side">
    <div class="form-section">
      <h3>{{ 'hubPage.editPageTitle' | translate }}</h3>

      <form [formGroup]="form">
        <!-- Title input -->
        <div>
          <app-input-label
            labelType="required"
            for="title-input"
            text="hubPage.titleLabel">
          </app-input-label>
          <input
            class="custom-input-text custom-input-text-required"
            formControlName="title"
            id="title-input"
            pInputText
            type="text"
            placeholder="{{ 'hubPage.titleLabel' | translate }}" />
          <app-input-error-message
            [showMessage]="form.controls.title.invalid && form.controls.title.touched"
            errorMessage="{{ 'hubPage.titleRequired' | translate }}">
          </app-input-error-message>
        </div>

        <!-- Language input -->
        <div>
          <app-input-label
            labelType="required"
            for="language-input"
            text="hubPage.languageLabel">
          </app-input-label>
          <p-dropdown
            class="custom-dropdown custom-dropdown-required"
            panelStyleClass="custom-dropdown-panel"
            [options]="languages"
            optionLabel="name"
            formControlName="language"
            inputId="language-input"
            placeholder="{{ 'hubPage.languageLabel' | translate }}">
            <ng-template pTemplate="selectedItem">
              <span
                class="flag-icon flag-icon-squared flag-icon-circle flag-icon-{{
                  form.controls.language.value.code === 'en'
                    ? 'gb'
                    : form.controls.language.value.code
                }}">
              </span>
              {{ 'languages.' + form.controls.language.value.code | translate }}
            </ng-template>
            <ng-template
              let-lang
              pTemplate="item">
              <span
                class="flag-icon flag-icon-squared flag-icon-circle flag-icon-{{
                  lang.code === 'en' ? 'gb' : lang.code
                }}">
              </span>
              {{ 'languages.' + lang.code | translate }}
            </ng-template>
          </p-dropdown>
          <app-input-error-message
            [showMessage]="form.controls.language.invalid && form.controls.language.touched"
            errorMessage="{{ 'hubPage.languageRequired' | translate }}">
          </app-input-error-message>
        </div>

        <!-- Url input -->
        <div class="c-mb-4">
          <app-input-label
            for="url-input"
            text="hubPage.urlLabel">
          </app-input-label>
          <div class="p-inputgroup custom-input-group">
            <span class="p-inputgroup-addon">URL</span>
            <input
              formControlName="url"
              id="url-input"
              pInputText
              type="text"
              placeholder="{{ 'hubPage.urlLabel' | translate }}" />
          </div>
        </div>

        <!-- Status input -->
        <div class="form-field-input-switch-block c-px-0 c-mb-4">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="status"
            inputId="status-input">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="status-input"
            text="hubPage.statusLabel">
          </app-input-label>
        </div>

        <div class="actions-container txt-a-r">
          <button
            class="primary-text-color"
            type="button"
            iconPos="right"
            pButton
            label="{{ 'adminSystemPage.saveBtn' | translate }}"
            [icon]="saving ? 'pi pi-spin pi-spinner' : ''"
            [disabled]="form.invalid"
            (click)="save()"></button>
        </div>
      </form>
    </div>
  </div>
</div>

<p-toast>
  <ng-template
    let-message
    pTemplate="message">
    <app-toast
      [severity]="message.severity"
      [summary]="message.summary"
      [detail]="message.detail">
    </app-toast>
  </ng-template>
</p-toast>
