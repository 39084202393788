import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { DividerModule } from 'primeng/divider';
import { TranslateModule } from '@ngx-translate/core';

import { logoAvatar } from 'src/app/shared';
import { trackByFn } from 'src/app/core/utils';
import { ICourse } from 'src/app/core/models';

@Component({
  selector: 'app-sidebar-user-info-courses',
  standalone: true,
  imports: [CommonModule, TranslateModule, NgOptimizedImage, DividerModule],
  templateUrl: './sidebar-user-info-courses.component.html',
  styleUrls: ['./sidebar-user-info-courses.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarUserInfoCoursesComponent {
  @Input() userCourses: ICourse[];

  defaultCourseImage = logoAvatar;
  trackByFn = trackByFn;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  onNavigateTo(course: ICourse): void {
    this.router.navigate([`../courses/${course.link}`], {
      relativeTo: this.route,
    });
  }
}
