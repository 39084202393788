export * from './search.service';
import algoliasearch from 'algoliasearch/lite';
import { appInjector } from 'src/app/app.module';
import { AppStore } from './../../../app.store';

export const algoliaSearchClient = () => {
  const appStore = appInjector.get(AppStore);

  return algoliasearch(
    appStore.environment.algolia.appId,
    appStore.environment.algolia.searchApiKey,
  );
};
