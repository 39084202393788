import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'primeng/tooltip';

import { isDesktop } from 'src/app/core/utils';

@Component({
  selector: 'app-ticket-button',
  standalone: true,
  imports: [CommonModule, TranslateModule, TooltipModule],
  templateUrl: './ticket-button.component.html',
  styleUrls: ['./ticket-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketButtonComponent {
  @Input() ticketName: string;
  @Input() price: number | string;
  @Input() currencyIcon: string;
  @Input() tooltipText: string;
  @Input() isSelected: boolean;
  @Input() isDisabled: boolean;
  @Output() clicked: EventEmitter<void> = new EventEmitter();

  isDesktop = isDesktop();

  get isFreeTicket(): boolean {
    return Number(this.price) === 0;
  }

  onClick(): void {
    if (!this.isDisabled) {
      this.clicked.emit();
    }
  }
}
