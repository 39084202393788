import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { useDevice } from '../../../utils/utils';

@Component({
  selector: 'app-chat-bubble',
  templateUrl: './chat-bubble.component.html',
  styleUrls: ['./chat-bubble.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatBubbleComponent {
  @Input() enabled?: boolean;
  @Input() isChatAllowed: boolean;
  @Input() unreadMessages?: number;
  @Input() tooltipText: string = '';
  @Input() tooltipStyleClass: string = 'custom-tooltip';

  deviceInfo = useDevice();

  public get isIosPWA(): boolean {
    const isInStandaloneMode =
      'standalone' in window.navigator && (<any>window.navigator)['standalone'];
    return this.deviceInfo.mobileOS === 'ios' && isInStandaloneMode;
  }
}
