import { IUser } from './../../models/user/user.model';
import { Injectable } from '@angular/core';
import { makeAutoObservable } from 'mobx';

@Injectable()
// Every field/method related to bookmark should be the same as SpeakerStore
export class AttendeesStore {
  public attendeesMap: { [key: string]: IUser } = {};
  public bookmarkedIds: string[] = [];
  public eventAttendeeId: string = null;

  constructor() {
    makeAutoObservable(this);
  }

  public setAttendees(attendees: IUser[]) {
    for (const attendee of attendees) {
      this.attendeesMap[attendee.id] = attendee;
    }
  }

  public get attendees(): IUser[] {
    return Object.keys(this.attendeesMap).map((id) => this.attendeesMap[id]);
  }

  public setBookmarkedIds(list: string[]) {
    this.bookmarkedIds = list;
  }

  public pushBookmarkedId(id: string): void {
    if (!this.bookmarkedIds.includes(id)) {
      this.bookmarkedIds.push(id);
    }
  }

  public popBookmarkedId(id: string): void {
    this.bookmarkedIds = this.bookmarkedIds.filter((_id) => _id !== id);
  }

  public setEventAttendeeId(value: string): void {
    this.eventAttendeeId = value;
  }

  public reset(): void {
    this.eventAttendeeId = null;
  }
}
