import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({ name: 'linkify' })
export class LinkifyPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(text: string): SafeHtml {
    // Basic regular expression to find URLs (can be improved upon)
    const urlRegex = /(https?:\/\/\S+)/g;

    return this.sanitizer.bypassSecurityTrustHtml(
      text.replace(urlRegex, '<a href="$1" target="_blank">$1</a>'),
    );
  }
}
