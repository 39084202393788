@if (loading()) {
  <app-loader></app-loader>
} @else {
  <header class="header">
    {{ 'adminEventSessionSidebar.designNavItem' | translate }}
  </header>
  <p-divider styleClass="custom-divider-horizontal"></p-divider>
  <form [formGroup]="form">
    <!-- Design block -->
    <div class="form-block c-my-8 c-px-8">
      <div class="left-side">
        <h2 class="title">{{ 'adminSession.designTitle' | translate }}</h2>
        <p class="description">{{ 'adminSession.designDescription' | translate }}</p>
      </div>
      <div class="right-side">
        <!-- Banner -->
        <div class="form-field-block c-mb-4">
          <app-cover-picture-media-form
            titleMediaForm="adminSession.bannerTitle"
            description="adminSession.bannerDesc"
            btnConfirm="upload"
            btnCancel="clear"
            [imgValue]="form.controls.banner.value"
            (changeValue)="setBannerValue($event)">
          </app-cover-picture-media-form>
        </div>

        <!-- Video link input -->
        <div class="form-field-block">
          <app-input-label
            for="videolink-input"
            text="adminSession.videolink">
          </app-input-label>
          <div class="p-inputgroup custom-input-group">
            <span class="p-inputgroup-addon">Embed-URL</span>
            <input
              formControlName="videolink"
              id="videolink-input"
              type="text"
              pInputText
              placeholder="{{ 'adminSession.videolink' | translate }}" />
          </div>
        </div>
      </div>
    </div>

    <p-divider styleClass="custom-divider-horizontal"></p-divider>

    <!-- Description & Tags block -->
    <div class="form-block c-my-8 c-px-8">
      <div class="left-side">
        <h2 class="title">{{ 'adminSession.descTagsTitle' | translate }}</h2>
        <p class="description">{{ 'adminSession.descTagsDescription' | translate }}</p>
      </div>
      <div class="right-side">
        <!-- Short description input -->
        <div class="form-field-block c-mb-4">
          <app-input-label text="adminSession.shortDescription"></app-input-label>
          <p-editor
            styleClass="custom-editor"
            formControlName="shortDescription"
            placeholder="{{ 'adminSession.shortDescPlaceholder' | translate }}">
          </p-editor>
        </div>

        <!-- Long description input -->
        <div class="form-field-block c-mb-8">
          <app-input-label text="adminSession.longDescriptionLabel"></app-input-label>
          <p-editor
            styleClass="custom-editor"
            formControlName="description"
            placeholder="{{ 'adminSession.longDescPlaceholder' | translate }}">
          </p-editor>
        </div>

        <!-- Tags -->
        <div class="form-field-block">
          @if (tags()?.length) {
            <app-input-label text="adminSession.tagsLabel"></app-input-label>
          }
          <app-tags-multi-list
            [possibleTags]="tags()"
            [selectedTags]="session?.tags"
            [adminTheme]="true"
            (onSelected)="selectTags($event)">
          </app-tags-multi-list>
          @if (!tags()?.length) {
            <h3>{{ 'adminSession.noTagsAvailable' | translate }}</h3>
          }
        </div>
      </div>
    </div>
  </form>
}

<div class="fixed right-0 bottom-0 left-0">
  <app-save-discard-actions
    [isShow]="canUpdate"
    [isLoading]="isUpdating()"
    (discard)="onDiscard()"
    (confirm)="onConfirm()">
  </app-save-discard-actions>
</div>

<p-toast>
  <ng-template
    let-message
    pTemplate="message">
    <app-toast
      [severity]="message.severity"
      [summary]="message.summary"
      [detail]="message.detail">
    </app-toast>
  </ng-template>
</p-toast>
