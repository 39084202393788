import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';

import { EventsStore } from 'src/app/core/stores/events/events.store';
import { defaultDialogConfig } from 'src/app/core/utils/utils';
import { CalendlyDialogComponent } from './../calendly-dialog/calendly-dialog.component';

@Component({
  selector: 'app-calendly-link',
  templateUrl: './calendly-link.component.html',
  styleUrls: ['./calendly-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendlyLinkComponent {
  @Input() link?: string;
  @Input() openInDialog: boolean = false;

  constructor(
    public eventsStore: EventsStore,
    private dialogService: DialogService,
  ) {}

  public open() {
    if (this.openInDialog) {
      this.dialogService.open(
        CalendlyDialogComponent,
        Object.assign(defaultDialogConfig(), {
          styleClass: 'calendly-dialog',
          data: {
            url: this.link,
          },
        }),
      );
    } else {
      window.open(this.link, '_blank');
    }
  }
}
