import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-brand-courses',
  templateUrl: './brand-courses.component.html',
  styleUrls: ['./brand-courses.component.scss'],
})
export class BrandCoursesComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
