import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { reaction } from 'mobx';
import { BehaviorSubject, Observable } from 'rxjs';

import { AppStore } from 'src/app/app.store';
import { IFirestore, Firestore } from 'src/app/firebase';
import {
  DEFAULT_REGULAR_MENU_ITEMS,
  DEFAULT_ON_DEMAND_EVENT_MENU_ITEMS,
  DEFAULT_FOOTER_MENU_ITEMS,
} from '../../configs';
import {
  IEvent,
  IEventMenuItem,
  IEventMenuOption,
  IEventMenuOptionGroup,
  IEventMenuOptionLink,
} from '../../models';
import { EventsStore, HubsStore } from '../../stores';
import { BrandsService } from '../brands/brands.service';
import { EventPagesService } from '../events';
import { SpacesService } from '../spaces/spaces.service';
import { StagesService } from '../stages/stages.service';

@Injectable({
  providedIn: 'root',
})
export class EventMenuService {
  private firestore: IFirestore;
  private eventMenuItems$ = new BehaviorSubject<IEventMenuItem[]>([]);

  constructor(
    private hubsStore: HubsStore,
    private stagesService: StagesService,
    private spacesService: SpacesService,
    private eventsStore: EventsStore,
    private brandsService: BrandsService,
    private appStore: AppStore,
    private pageService: EventPagesService,
    private translateService: TranslateService,
  ) {
    this.firestore = Firestore();

    // listen to event changes so the menu items can be updated accordingly
    reaction(
      () => this.eventsStore.event,
      async (event) => {
        if (event) {
          const menuItems = await this.updateMenuItems(event);
          this.eventMenuItems$.next(menuItems);
        } else {
          this.eventMenuItems$.next([]);
        }
      },
    );
  }

  eventMenuItems(): Observable<IEventMenuItem[]> {
    return this.eventMenuItems$;
  }

  private async updateMenuItems(event: IEvent): Promise<IEventMenuItem[]> {
    const [stages, spaces, mediaPartners, eventMenuOptions] = await Promise.all([
      this.stagesService.getEventStages(this.eventsStore.eventId, true),
      this.spacesService.getAll(this.eventsStore.eventId),
      this.brandsService.getCertainLevelBrandsFromEvent(this.eventsStore.eventId, 'asc', 'media'),
      this.getEventMenuOptions(event.id, event.isOnDemandMode ? 'onDemand' : 'regular'),
    ]);

    const menuItems: IEventMenuItem[] = [];

    for (const eventMenuOption of eventMenuOptions) {
      if (!eventMenuOption.visible) {
        continue;
      }

      if (eventMenuOption.type === 'divider') {
        menuItems.push({
          type: 'divider',
          name: '',
          iconClass: '',
        });
        continue;
      }

      switch (eventMenuOption.key) {
        case 'mainStage': {
          // check if main stage exists
          const mainStage = stages.find((s) => s.isMainStage);
          if (mainStage) {
            menuItems.push({
              iconClass: 'fak fa-lox-mainstage',
              name: mainStage.name,
              url: `/${this.hubsStore.useHubUrl}/events/${event.id}/stages/${mainStage.id}`,
              type: 'link',
              key: eventMenuOption.key,
            });
          }
          break;
        }
        case 'allStages': {
          const nonMainStage = stages.filter((s) => !s.isMainStage);
          if (nonMainStage.length > 0 && eventMenuOption.type === 'group') {
            // add links to the
            menuItems.push({
              iconClass: 'fak fa-lox-stages',
              name: 'asideNavigation.allStagesMenuItem',
              clickAction: () => {
                this.appStore.setSidebarVisible(true);
              },
              type: eventMenuOption.type,
              key: eventMenuOption.key,
              items: eventMenuOption.options
                .filter((o) => o.visible)
                .map((menuOptions) => ({
                  iconClass: '',
                  name: menuOptions.name,
                  url: `/${this.hubsStore.useHubUrl}/events/${event.id}/stages/${menuOptions.key}`,
                  clickAction: () => {
                    this.appStore.setSidebarVisible(false);
                  },
                  type: 'link',
                  key: menuOptions.key,
                })),
            });
          }
          break;
        }
        case 'program': {
          menuItems.push({
            iconClass: 'fak fa-lox-calendar',
            name: 'asideNavigation.programMenuItem',
            url: `/${this.hubsStore.useHubUrl}/events/${event.id}/program`,
            type: 'link',
            key: eventMenuOption.key,
          });
          break;
        }
        case 'brands': {
          menuItems.push({
            iconClass: 'fak fa-lox-crown',
            name: 'asideNavigation.brandsMenuItem',
            url: `/${this.hubsStore.useHubUrl}/events/${event.id}/brands`,
            type: 'link',
            key: eventMenuOption.key,
          });
          break;
        }
        case 'speakers': {
          menuItems.push({
            iconClass: 'far fa-bullhorn',
            name: 'asideNavigation.speakersMenuItem',
            url: `/${this.hubsStore.useHubUrl}/events/${event.id}/speakers`,
            type: 'link',
            key: eventMenuOption.key,
          });
          break;
        }
        case 'spaces': {
          if (spaces.length > 0) {
            menuItems.push({
              iconClass: 'fa-regular fa-graduation-cap',
              name: 'asideNavigation.spacesMenuItem',
              url: `/${this.hubsStore.useHubUrl}/events/${event.id}/spaces`,
              type: 'link',
              key: eventMenuOption.key,
            });
          }
          break;
        }
        case 'attendees': {
          if (event.isMatchmaking) {
            menuItems.push({
              iconClass: 'fak fa-lox-attendees',
              name: 'asideNavigation.matchmakingMenuItem',
              url: `/${this.hubsStore.useHubUrl}/events/${event.id}/matchmaking`,
              type: 'link',
              key: eventMenuOption.key,
            });
          } else {
            menuItems.push({
              iconClass: 'fak fa-lox-attendees',
              name: 'asideNavigation.attendeesMenuItem',
              url: `/${this.hubsStore.useHubUrl}/events/${event.id}/attendees`,
              type: 'link',
              key: eventMenuOption.key,
            });
          }
          break;
        }
        case 'topics': {
          if (event.tags && event.tags.length) {
            menuItems.push({
              iconClass: 'fa-regular fa-tags',
              name: 'asideNavigation.topicsMenuItem',
              clickAction: () => {
                this.appStore.toggleTopicsSidebarVisible();
              },
              type: 'link',
              key: eventMenuOption.key,
            });
          }
          break;
        }
        case 'mediaPartners': {
          if (mediaPartners && mediaPartners.length > 0) {
            menuItems.push({
              iconClass: 'fas fa-newspaper',
              name: 'asideNavigation.mediaPartnersMenuItem',
              url: `/${this.hubsStore.useHubUrl}/events/${event.id}/media-partners`,
              type: 'link',
              key: eventMenuOption.key,
            });
          }
          break;
        }
        case 'showroom': {
          menuItems.push({
            iconClass: 'fa-regular fa-people-roof',
            name: 'asideNavigation.showroomMenuItem',
            url: `/${this.hubsStore.useHubUrl}/events/${event.id}/showroom`,
            type: 'link',
            key: eventMenuOption.key,
          });
          break;
        }
        case 'videolisting': {
          menuItems.push({
            iconClass: 'fak fa-lox-masterclass',
            name: 'asideNavigation.videolistingMenuItem',
            url: `/${this.hubsStore.useHubUrl}/events/${event.id}/videolisting`,
            type: 'link',
            key: eventMenuOption.key,
          });
          break;
        }
        case 'myBookings': {
          menuItems.push({
            iconClass: 'fa-regular fa-ticket',
            name: 'asideNavigation.myBookingsMenuItem',
            url: `/${this.hubsStore.useHubUrl}/events/${event.id}/my-bookings`,
            type: 'link',
            key: eventMenuOption.key,
          });
          break;
        }

        default: {
          if (eventMenuOption.type == 'custom-page') {
            menuItems.push({
              iconClass: eventMenuOption.customIconClass || 'fa-regular fa-book-sparkles',
              name: eventMenuOption.name,
              url: `/${this.hubsStore.useHubUrl}/events/${event.id}/page/${eventMenuOption.pageId}`,
              type: 'custom-page',
              key: eventMenuOption.key,
              pageId: eventMenuOption.pageId,
            });
          }
        }
      }
    }

    return menuItems;
  }

  async getEventMenuOptions(
    eventId: string,
    menuType: 'regular' | 'onDemand' | 'footer' | 'eventDetails' | 'helpSupport',
  ): Promise<IEventMenuOption[]> {
    const [menuConfig, stages, pages] = await Promise.all([
      this.firestore.collection(`events/${eventId}/settings`).doc('menuConfig').get(),
      this.stagesService.getAll(eventId),
      this.pageService.getAllByLanguage(eventId, this.translateService.currentLang),
    ]);

    let pagesIds = [];
    if (pages) {
      pagesIds = pages.map((x) => x.id);
    }

    let defaultMenuItems;
    let regularDefaultItemsNotVisible: IEventMenuOption[] = [];

    DEFAULT_REGULAR_MENU_ITEMS.forEach((item) => {
      item.visible = false;
      regularDefaultItemsNotVisible.push(item);
    });

    switch (menuType) {
      case 'regular':
        defaultMenuItems = DEFAULT_REGULAR_MENU_ITEMS;
        break;
      case 'onDemand':
        defaultMenuItems = DEFAULT_ON_DEMAND_EVENT_MENU_ITEMS;
        break;
      case 'footer':
        defaultMenuItems = [...DEFAULT_FOOTER_MENU_ITEMS, ...regularDefaultItemsNotVisible];
        break;
      default:
        defaultMenuItems = regularDefaultItemsNotVisible;
        break;
    }

    let menuItems: IEventMenuOption[] = menuConfig.get(`${menuType}Menu`) ?? [];

    // use the stage id as the key
    if (menuConfig.exists && menuItems.length > 0) {
      // check if previously configured MenuItem still exist
      const previouslyConfiguredMenuItems: IEventMenuOption[] = [];
      for (const previouslyConfiguredMenuItem of menuItems) {
        const defaultMenuItem = defaultMenuItems.find(
          (item) => item.id === previouslyConfiguredMenuItem.id,
        );
        if (
          defaultMenuItem ||
          (previouslyConfiguredMenuItem.type === 'custom-page' &&
            pagesIds.includes(previouslyConfiguredMenuItem.pageId))
        ) {
          previouslyConfiguredMenuItems.push(previouslyConfiguredMenuItem);
        }
      }

      // add the missing MenuItems related to stages that were created after the last config update
      const newMenuItems: IEventMenuOption[] = [];
      for (const defaultMenuItem of defaultMenuItems) {
        const alreadyConfiguredMenuItem = menuItems.find((i) => i.id === defaultMenuItem.id);
        if (!alreadyConfiguredMenuItem) {
          newMenuItems.push(defaultMenuItem);
        }
      }

      menuItems = [...previouslyConfiguredMenuItems, ...newMenuItems];

      // Check "All Stages" data integrity
      const allStagesEventMenuGroup = menuItems.find(
        (i) => i.type === 'group' && i.key === 'allStages',
      ) as IEventMenuOptionGroup;
      if (allStagesEventMenuGroup) {
        // check if previously configured stages still exist and fill the name property
        const previouslyConfiguredStagesMenuItems: IEventMenuOptionLink[] = [];
        for (const previouslyConfiguredStagesMenuItem of allStagesEventMenuGroup.options) {
          const stage = stages.find((s) => s.id === previouslyConfiguredStagesMenuItem.key);

          if (stage) {
            previouslyConfiguredStagesMenuItem.name = stage.name;
            previouslyConfiguredStagesMenuItems.push(previouslyConfiguredStagesMenuItem);
          }
        }

        // add the missing MenuItems related to stages that were created after the last config update
        const newStagesMenuItems: IEventMenuOptionLink[] = [];
        for (const stage of stages) {
          const alreadyConfiguredStage = allStagesEventMenuGroup.options.find(
            (i) => i.key === stage.id,
          );
          if (!alreadyConfiguredStage) {
            newStagesMenuItems.push({
              name: stage.name,
              type: 'link',
              key: stage.id,
              id: `${allStagesEventMenuGroup.id}_${stage.id}`,
              visible: !stage.isMainStage,
            });
          }
        }

        allStagesEventMenuGroup.options = [
          ...previouslyConfiguredStagesMenuItems,
          ...newStagesMenuItems,
        ];
      }

      return menuItems;
    } else {
      // create a clone
      const menuItems: IEventMenuOption[] = JSON.parse(JSON.stringify(defaultMenuItems));

      // add all stages as sub items of the allStages entry
      const allStagesEventMenuGroup = menuItems.find(
        (i) => i.type === 'group' && i.key === 'allStages',
      ) as IEventMenuOptionGroup;
      if (allStagesEventMenuGroup) {
        allStagesEventMenuGroup.options = stages.map((stage) => ({
          name: stage.name,
          type: 'link',
          key: stage.id,
          id: `${allStagesEventMenuGroup.id}_${stage.id}`,
          visible: !stage.isMainStage,
        }));
      }

      return menuItems;
    }
  }

  async updateEventMenuOptions(
    eventId: string,
    regularMenuItems: IEventMenuOption[],
    onDemandMenuItems: IEventMenuOption[],
    footerMenuItems: IEventMenuOption[],
    eventDetailsMenuItems: IEventMenuOption[],
    helpSupport: IEventMenuOption[],
  ): Promise<void> {
    const regularMenuItemsToSave = JSON.parse(
      JSON.stringify(regularMenuItems),
    ) as IEventMenuOption[];
    const onDemandMenuItemsToSave = JSON.parse(
      JSON.stringify(onDemandMenuItems),
    ) as IEventMenuOption[];
    const footerMenuItemsToSave = JSON.parse(JSON.stringify(footerMenuItems)) as IEventMenuOption[];
    const eventDetailsMenuItemsToSave = JSON.parse(
      JSON.stringify(eventDetailsMenuItems),
    ) as IEventMenuOption[];
    const helpSupportMenuItemsToSave = JSON.parse(
      JSON.stringify(helpSupport),
    ) as IEventMenuOption[];

    const cleanExtraFields = (items: IEventMenuOption[]) => {
      items.forEach((item) => {
        if (item.type === 'link') {
          delete item.name;
        } else if (item.type === 'group') {
          delete item.name;
          cleanExtraFields(item.options);
        }
      });
    };

    // remove "name" field
    cleanExtraFields(regularMenuItemsToSave);
    cleanExtraFields(onDemandMenuItemsToSave);
    cleanExtraFields(footerMenuItemsToSave);
    cleanExtraFields(eventDetailsMenuItemsToSave);
    cleanExtraFields(helpSupportMenuItemsToSave);

    // save the data
    const dataToSave = {
      regularMenu: regularMenuItemsToSave,
      onDemandMenu: onDemandMenuItemsToSave,
      footerMenu: footerMenuItemsToSave,
      eventDetailsMenu: eventDetailsMenuItemsToSave,
      helpSupportMenu: helpSupportMenuItemsToSave,
    };
    await this.firestore
      .collection(`events/${eventId}/settings`)
      .doc('menuConfig')
      .set(dataToSave, { merge: true });
  }
}
