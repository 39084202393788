import { Component } from '@angular/core';

@Component({
  selector: 'app-event-sessions-report',
  standalone: true,
  imports: [],
  templateUrl: './event-sessions-report.component.html',
  styleUrl: './event-sessions-report.component.scss',
})
export class EventSessionsReportComponent {}
