<div class="container">
  <p-dropdown
    class="custom-dropdown-without-border"
    panelStyleClass="custom-dropdown-panel"
    [formControl]="selectedLanguage"
    [options]="selectLanguages"
    optionLabel="name"
    (onChange)="setLanguage($event.value)">
    <ng-template pTemplate="selectedItem">
      <span
        class="
          flag-icon flag-icon-squared flag-icon-circle 
          flag-icon-{{ selectedLanguageCode === 'en' ? 'gb' : selectedLanguageCode }}
        ">
      </span>
      {{ 'languages.' + selectedLanguageCode | translate }}
    </ng-template>
    <ng-template
      let-lang
      pTemplate="item">
      <span
        class="
          flag-icon flag-icon-squared flag-icon-circle 
          flag-icon-{{ lang.code === 'en' ? 'gb' : lang.code }}">
      </span>
      {{ 'languages.' + lang.code | translate }}
    </ng-template>
  </p-dropdown>
</div>
