import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { TranslationsService } from 'src/app/core/services';
import { UsersStore } from 'src/app/core/stores';
import { ILanguage } from 'src/app/core/models';
import { ServerTimestamp } from 'src/app/firebase';
import { ButtonSize } from 'src/app/standalone';

@Component({
  selector: 'app-system-translations-editor',
  templateUrl: './system-translations-editor.component.html',
  styleUrls: ['./system-translations-editor.component.scss'],
})
export class SystemTranslationsEditorComponent {
  saving = false;
  pageTitle: string = 'adminSystemTranslations.tagFormAddTitle';
  form: UntypedFormGroup = new UntypedFormGroup({
    title: new UntypedFormControl(null, [Validators.required]),
    code: new UntypedFormControl(null, [Validators.required]),
    translations: new UntypedFormControl(null, [Validators.required]),
  });
  buttonSize = ButtonSize;

  private language: ILanguage = null;

  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private translationsService: TranslationsService,
    private usersStore: UsersStore,
  ) {
    this.language = config.data?.language || null;
    this.pageTitle = this.language
      ? 'adminSystemTranslations.tagFormEditTitle'
      : 'adminSystemTranslations.tagFormAddTitle';

    if (this.language) {
      this.form.patchValue({
        title: this.language.title,
        code: this.language.code,
        translations: JSON.stringify(this.language.translations, undefined, 4),
      });
      this.form.updateValueAndValidity();
      this.form.valueChanges.subscribe(() => this.form.markAsDirty());
    }
  }

  onCloseDialog(): void {
    this.ref.close();
  }

  async save(): Promise<void> {
    this.saving = true;

    if (this.language) {
      const payload = {
        id: this.language.id,
        title: this.form.value.title,
        _title_: this.form.value.title.toLowerCase(),
        code: this.form.value.code,
        translations: JSON.parse(this.form.value.translations),
        updatedAt: ServerTimestamp(),
        updatedBy: this.usersStore.user.id,
        createdAt: this.language.createdAt,
        createdBy: this.language.createdBy,
      };

      await this.translationsService.update(this.language.id, payload);
      this.saving = false;
      this.ref.close(true);
    } else {
      try {
        const language = {
          id: null,
          title: this.form.value.title,
          _title_: this.form.value.title.toLowerCase(),
          code: this.form.value.code,
          translations: JSON.parse(this.form.value.translations),
          updatedAt: null,
          updatedBy: null,
          createdAt: ServerTimestamp(),
          createdBy: this.usersStore.user.id,
        } as ILanguage;

        await this.translationsService.create(language);
        this.saving = false;
        this.ref.close(true);
      } catch (error) {
        console.log(error);
        this.saving = false;
        this.ref.close(false);
      }
    }
  }
}
