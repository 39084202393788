import firebase from 'firebase/compat/app';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

import {
  Auth,
  Firestore,
  IAuth,
  IDocumentData,
  IDocumentReference,
  IFirestore,
  IQuerySnapshot,
  ServerTimestamp,
  Timestamp,
} from 'src/app/firebase';
import { UsersStore } from 'src/app/core/stores';
import { API_ROUTES as apiRoutes } from 'src/app/shared';
import {
  AnalyticsService,
  PresenceService,
  MixpanelService,
  AuthorizationService,
} from 'src/app/core/services';
import { IIpifyJsonObject, IFailedLoginAttempt, IUserAccount, IUser } from 'src/app/core/models';
import { environment } from 'src/environments/environment';
import { HubsStore } from 'src/app/core/stores/hubs/hubs.store';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService implements OnDestroy {
  private isAuthLoadingSubject$ = new BehaviorSubject<boolean>(false);
  private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(this.checkLoggedUserState());
  private _invitationUrlOpenedAt$ = new BehaviorSubject<Timestamp>(null);
  private firebaseAuth: IAuth;
  private IPIFY_LINK = 'https://api.ipify.org?format=json';
  private firestore: IFirestore;

  constructor(
    private router: Router,
    private usersStore: UsersStore,
    private presence: PresenceService,
    private analytics: AnalyticsService,
    private mixpanelService: MixpanelService,
    private authorizationService: AuthorizationService,
    private http: HttpClient,
    public hubsStore: HubsStore,
  ) {
    this.startListenLocalStorage();
    this.firestore = Firestore();
    this.firebaseAuth = Auth();

    this.firebaseAuth.onAuthStateChanged((user) => {
      this.usersStore.setUser(user ? JSON.parse(localStorage.getItem('user')) : null);
      this.isAuthenticatedSubject$.next(!!user);
      this.isAuthLoadingSubject$.next(true);
    });

    this.isAuthLoadingSubject$.subscribe((isAuthenticated) => {
      if (isAuthenticated) {
        const user = this.usersStore?.user;

        if (user) {
          this.analytics.setUserContext(
            user.id,
            user.firstName,
            user.lastName,
            user.email,
            user.company,
          );
        }
      } else {
        this.analytics.clearUserContext();
      }
    });
  }

  get isAuthLoading$(): Observable<boolean> {
    return this.isAuthLoadingSubject$.asObservable();
  }

  get isAuthenticated$(): Observable<boolean> {
    return this.isAuthenticatedSubject$.asObservable();
  }

  get invitationUrlOpenedAt(): Observable<Timestamp> {
    return this._invitationUrlOpenedAt$.asObservable();
  }

  async getUserToken(): Promise<string> {
    return await this.firebaseAuth.currentUser.getIdToken();
  }

  async sendVerifyEmail(
    email: string,
    userName: string,
    userLang: string,
    redirectLink: string,
    tenantId: string = null,
  ): Promise<void> {
    try {
      const response = await this.http
        .post(`${environment.apiUrl}auth/verifyAccount`, {
          email,
          userName,
          userLang,
          redirectLink,
          tenantId: this.hubsStore.hub ? this.hubsStore.hub.tenantId : tenantId ? tenantId : null,
          noreplyEmail: environment.noreplyEmail,
          applicationName: environment.applicationName,
          hubId: this.hubsStore.hub?.id,
        })
        .toPromise();
      console.log(response);
    } catch (error) {
      console.error(error);
      throw error.error.msg;
    }
  }

  async signOutAllUsers(): Promise<void> {
    try {
      const headers: HttpHeaders = await this.authorizationService.buildHeaders();
      const response = await this.http
        .post(
          `${environment.apiUrl}auth/signoutusers`,
          { tenantId: this.hubsStore.hub.tenantId || null },
          { headers },
        )
        .toPromise();
      console.log(response);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async signIn(
    email: string,
    password: string,
  ): Promise<{
    uid: string;
    isVerified: boolean;
  }> {
    try {
      const firebaseUser = await this.firebaseAuth.signInWithEmailAndPassword(email, password);

      if (firebaseUser.user.emailVerified) {
        return {
          uid: firebaseUser.user.uid,
          isVerified: true,
        };
      } else {
        return {
          uid: firebaseUser.user.uid,
          isVerified: false,
        };
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async signUp(
    email: string,
    password: string,
  ): Promise<{
    uid: string;
    isVerified: boolean;
  }> {
    try {
      const firebaseUser = await this.firebaseAuth.createUserWithEmailAndPassword(
        email.toLowerCase(),
        password,
      );

      if (firebaseUser.user.emailVerified) {
        return {
          uid: firebaseUser.user.uid,
          isVerified: false,
        };
      } else {
        return {
          uid: firebaseUser.user.uid,
          isVerified: false,
        };
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async resetPassword(email: string): Promise<void> {
    try {
      return await this.firebaseAuth.sendPasswordResetEmail(email.toLowerCase());
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async signOut(navigateOnDashboard: boolean = true): Promise<void> {
    await this.presence.signOut();
    await this.firebaseAuth.signOut();
    this.mixpanelService.logout();

    if (navigateOnDashboard) {
      this.router.navigate([`/${this.hubsStore.useHubUrl}/logout`]);
    }
  }

  async changeEmail(password: string, oldEmail: string, newEmail: string): Promise<void> {
    try {
      const credential = firebase.auth.EmailAuthProvider.credential(oldEmail, password);
      await this.firebaseAuth.currentUser.reauthenticateWithCredential(credential);

      return await this.firebaseAuth.currentUser.updateEmail(newEmail);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async changePassword(email: string, oldPassword: string, newPassword: string): Promise<void> {
    try {
      const credential = firebase.auth.EmailAuthProvider.credential(email, oldPassword);
      await this.firebaseAuth.currentUser.reauthenticateWithCredential(credential);

      return await this.firebaseAuth.currentUser.updatePassword(newPassword);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async registerChangePassword(
    email: string,
    oldPassword: string,
    newPassword: string,
  ): Promise<{
    uid: string;
    isVerified: boolean;
  }> {
    try {
      const registerResponse = await this.signIn(email, oldPassword);
      const userId = registerResponse.uid;
      await this.firebaseAuth.currentUser.updatePassword(newPassword);
      // add new data to realtime db
      await this.presence.addPresence(userId, 'online');
      return registerResponse;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getIpAddress(): Promise<string> {
    try {
      const ipifyJson = await this.http.get<IIpifyJsonObject>(this.IPIFY_LINK).toPromise();

      return ipifyJson.ip;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getFailedLoginAttempts(email: string, ip: string, date: Date) {
    const attempts = await this.firestore
      .collection(apiRoutes.failedLoginAttempts)
      .where('email', '==', email)
      .where('createdAt', '>=', date)
      .limit(4)
      .get();

    return attempts;
  }

  async getFailedLoginAttemptsByEmail(email: string): Promise<IFailedLoginAttempt[]> {
    try {
      const attempts: IFailedLoginAttempt[] = (
        await this.firestore
          .collection(apiRoutes.failedLoginAttempts)
          .where('email', '==', email)
          .get()
      ).docs.map((doc) => doc.data() as IFailedLoginAttempt);

      return attempts;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async createFailedAttemptRec(email: string, ip: string): Promise<void> {
    const attempt: IFailedLoginAttempt = { id: '', createdAt: '', email, ip };
    const preBrandReq: IDocumentReference<IDocumentData> = this.firestore
      .collection(apiRoutes.failedLoginAttempts)
      .doc();
    attempt.id = preBrandReq.id;
    attempt.email = email;
    attempt.createdAt = ServerTimestamp();
    await this.firestore.collection(apiRoutes.failedLoginAttempts).doc(attempt.id).set(attempt);
  }

  async deleteFailedAttemptRecs(email: string): Promise<boolean> {
    try {
      await this.firestore
        .collection(apiRoutes.failedLoginAttempts)
        .where('email', '==', email)
        .get()
        .then((querySnapshot) => {
          querySnapshot.docs.forEach((snapshot) => {
            snapshot.ref.delete();
          });
        });

      return true;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async hasAnyFailedAttempts(email: string): Promise<boolean> {
    try {
      const attempts: IQuerySnapshot<IDocumentData> = await this.firestore
        .collection(apiRoutes.failedLoginAttempts)
        .where('email', '==', email)
        .limit(1)
        .get();

      return attempts.docs.length >= 1;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  updateInvitationOpenedDate(date: Timestamp): void {
    this._invitationUrlOpenedAt$.next(date);
  }

  async deleteFailedLoginAttempt(id: string): Promise<boolean> {
    try {
      await this.firestore.collection(apiRoutes.failedLoginAttempts).doc(id).delete();

      return true;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async deleteUserByEmail(email: string): Promise<boolean> {
    try {
      const headers: HttpHeaders = await this.authorizationService.buildHeaders();
      await this.http
        .delete<boolean>(environment.apiUrl + 'users/delete-user-by-email', {
          headers,
          body: { userEmailForDeleting: email, tenantId: this.hubsStore.hub.tenantId },
        })
        .toPromise();

      return true;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getAccountByEmail(
    email: string,
    tenantId: string,
  ): Promise<{ account: IUserAccount; exist: boolean; user: IUser }> {
    try {
      const headers: HttpHeaders = await this.authorizationService.buildHeaders();
      const payload = { email, tenantId };
      const response = await this.http
        .post(environment.apiUrl + 'auth/get-account-by-email', payload, { headers })
        .toPromise();

      return response as { account: IUserAccount; exist: boolean; user: IUser };
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async setVerifiedUser(email: string): Promise<boolean> {
    try {
      const headers: HttpHeaders = await this.authorizationService.buildHeaders();
      const payload = {
        email,
        tenantId: this.hubsStore.hub.tenantId,
      };
      await this.http
        .post(environment.apiUrl + 'users/verify-email', payload, { headers })
        .toPromise();
      return true;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  ngOnDestroy(): void {
    this.stopListenLocalStorage();
  }

  private checkLoggedUserState(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));

    if (user) {
      this.usersStore.setUser(user);
      return true;
    }
    return false;
  }

  private startListenLocalStorage(): void {
    window.addEventListener('storage', this.storageEventListener.bind(this));
  }

  private storageEventListener(event: StorageEvent) {
    if (event.storageArea === localStorage) {
      if (event?.key && event.key === 'logout-event') {
        this.signOut();
      }
    }
  }

  private stopListenLocalStorage(): void {
    window.removeEventListener('storage', this.storageEventListener.bind(this));
  }
}
