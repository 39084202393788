import { Component, OnInit, inject, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

import { FeatureHeaderComponent, FeatureMainContentComponent } from 'src/app/standalone/admin';
import { HubsStore, UsersStore } from 'src/app/core/stores';
import { IHub, IUser } from 'src/app/core/models';

@Component({
  selector: 'app-user-container',
  standalone: true,
  imports: [CommonModule, FeatureHeaderComponent, FeatureMainContentComponent],
  templateUrl: './user-container.component.html',
  styleUrls: ['./user-container.component.scss'],
})
export class UserContainerComponent implements OnInit {
  items = signal<MenuItem[]>(null);

  hubsStore = inject(HubsStore);
  private usersStore = inject(UsersStore);
  private translateService = inject(TranslateService);

  get user(): IUser {
    return this.usersStore.adminUser;
  }

  get fullName(): string {
    return `${this.user?.firstName} ${this.user?.lastName}` ?? '';
  }

  get hub(): IHub {
    return this.hubsStore.hub;
  }

  ngOnInit(): void {
    this.items.set([
      // {
      //   label: this.translateService.instant('adminUser.tabOverview'),
      //   icon: 'fa-sharp fa-regular fa-user-magnifying-glass',
      //   routerLink: `/${this.hubsStore.useHubUrl}/admin/user/${this.user.id}/overview`
      // },
      {
        label: this.translateService.instant('adminUser.tabProfile'),
        icon: 'fa-regular fa-user',
        routerLink: `/${this.hubsStore.useHubUrl}/admin/user/${this.user.id}/profile`,
      },
      {
        label: this.translateService.instant('adminUser.tabAppearance'),
        icon: 'fa-regular fa-address-card',
        routerLink: `/${this.hubsStore.useHubUrl}/admin/user/${this.user.id}/appearance`,
      },
      {
        label: this.translateService.instant('adminUser.tabAccount'),
        icon: 'fa-regular fa-user-gear',
        routerLink: `/${this.hubsStore.useHubUrl}/admin/user/${this.user.id}/account`,
      },
      {
        label: this.translateService.instant('adminUser.tabEvents'),
        icon: 'far fa-screen-users',
        routerLink: `/${this.hubsStore.useHubUrl}/admin/user/${this.user.id}/events`,
      },
      {
        label: this.translateService.instant('adminUser.tabCourses'),
        icon: 'far fa-graduation-cap',
        routerLink: `/${this.hubsStore.useHubUrl}/admin/user/${this.user.id}/courses`,
      },
      {
        label: this.translateService.instant('adminUser.tabMore'),
        icon: 'fa-regular fa-ellipsis',
        routerLink: `/${this.hubsStore.useHubUrl}/admin/user/${this.user.id}/more`,
      },
    ]);
  }
}
