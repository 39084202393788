import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';

import { IEvent, IHub } from 'src/app/core/models';
import { ThemesService } from 'src/app/core/services';
import { EventsStore, HubsStore } from 'src/app/core/stores';
import { EMAIL_TEMPLATE_DEFAULT_COLOR } from 'src/app/standalone';

@Component({
  selector: 'app-system-email-preview',
  templateUrl: './system-email-preview.component.html',
  styleUrls: ['./system-email-preview.component.scss'],
})
export class SystemEmailPreviewComponent implements OnInit {
  html: SafeHtml = null;

  constructor(
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private sanitizer: DomSanitizer,
    public hubsStore: HubsStore,
    private themesService: ThemesService,
    private eventsStore: EventsStore,
  ) {}

  get event(): IEvent {
    return this.eventsStore.adminEvent;
  }

  get hub(): IHub {
    return this.hubsStore.hub;
  }

  ngOnInit(): void {
    const logo = this.hub
      ? this.themesService.activeTheme.name === 'dark'
        ? this.hub.logoDark
        : this.hub.logoLight
      : this.themesService.activeTheme.name === 'dark'
        ? this.themesService.systemAppearanceSettings?.logo
        : this.themesService.systemAppearanceSettings?.logoLight;

    let html = this.config.data?.html || null;
    const logoPlaceholders = [
      '{{event.logoDark}}',
      '{{event.logoLight}}',
      '{{event.banner}}',
      '{{user.profileImage}}',
      '{{user.coverImage}}',
      '{{brand.logo}}',
      '{{brand.banner}}',
      '{{course.banner}}',
      '{{course.featuredImage}}',
      '{{hub.banner}}',
      '{{hub.icon}}',
      '{{hub.logoDark}}',
      '{{hub.logoLight}}',
    ];
    const logoRegex = new RegExp(logoPlaceholders.join('|'), 'gi');
    html = html.replace(logoRegex, logo);

    const colorPlaceholders = [
      '{{event.primaryColor}}',
      '{{event.accentColor}}',
      '{{hub.primaryColor}}',
    ];
    const colorRegex = new RegExp(colorPlaceholders.join('|'), 'gi');
    html = html.replace(colorRegex, this.getColorForPreview());

    if (html) {
      this.html = this.sanitizer.bypassSecurityTrustHtml(html);
    }
  }

  private getColorForPreview(): string {
    const isColorFromMergeTags: boolean = [
      '{{event.primaryColor}}',
      '{{event.accentColor}}',
      '{{hub.primaryColor}}',
    ].some((colorMergeTag: string) => colorMergeTag === this.config.data.color);

    if (!isColorFromMergeTags) {
      return this.config.data?.color ?? EMAIL_TEMPLATE_DEFAULT_COLOR;
    }

    switch (this.config.data.color) {
      case '{{event.primaryColor}}':
        return this.event?.primaryColor ?? EMAIL_TEMPLATE_DEFAULT_COLOR;
      case '{{event.accentColor}}':
        return this.event?.accentColor ?? EMAIL_TEMPLATE_DEFAULT_COLOR;
      case '{{hub.primaryColor}}':
        return this.hub?.primaryColor ?? EMAIL_TEMPLATE_DEFAULT_COLOR;
    }
  }
}
