import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';

import {
  IUser,
  IEvent,
  ITag,
  ITicket,
  IEventRegistrationForm,
  IUserEvent,
} from 'src/app/core/models';
import { TicketsService, UsersService, TagsService, EventsService } from 'src/app/core/services';
import { EventsStore, UsersStore, HubsStore } from 'src/app/core/stores';
import { Cities, RoomType } from 'src/app/shared';

@Component({
  selector: 'app-event-brand-person-event-details',
  templateUrl: './event-brand-person-event-details.component.html',
  styleUrls: ['./event-brand-person-event-details.component.scss'],
})
export class EventBrandPersonEventDetailsComponent implements OnInit, OnDestroy {
  public tickets: ITicket[];
  public loading = true;
  public isUpdating = false;
  public tags: ITag[] = [];
  public userEvent: IUserEvent = null;
  public form: UntypedFormGroup = new UntypedFormGroup({
    tags: new UntypedFormControl([]),
    ticket: new UntypedFormControl(null),
    roomType: new UntypedFormControl(null),
    roomPartner: new UntypedFormControl(null),
    preferredAirport: new UntypedFormControl(null),
    alternativeAirport: new UntypedFormControl(null),
  });
  public roomTypes: string[] = Object.values(RoomType);
  public cities: string[] = Object.values(Cities);
  public eventRegisterForm: IEventRegistrationForm;

  private unsubscribe$: Subject<void> = new Subject<void>();

  public get user(): IUser {
    return this.usersStore.adminUser;
  }

  public get event(): IEvent {
    return this.eventsStore.adminEvent;
  }

  public get canUpdate(): boolean {
    return !this.isUpdating && this.form.valid && this.form.dirty;
  }

  public get citiesForPreferredAirport(): string[] {
    return this.cities.filter(
      (city: string) => city !== this.form.controls.alternativeAirport.value,
    );
  }

  public get citiesForAlternativeAirport(): string[] {
    const citiesForShowing: string[] = this.cities.filter(
      (city: string) => city !== this.form.controls.preferredAirport.value,
    );
    return [...citiesForShowing, 'cities.noAlternative'];
  }

  public get isShowRoomPartner(): boolean {
    return this.form.controls.roomType.value === RoomType.DOUBLE_ROOM;
  }

  constructor(
    private messageService: MessageService,
    private tagService: TagsService,
    public hubsStore: HubsStore,
    private usersStore: UsersStore,
    private eventsStore: EventsStore,
    private usersService: UsersService,
    private ticketsService: TicketsService,
    private translateService: TranslateService,
    private eventsService: EventsService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.tags = await this.tagService.getByIdsOrderedByTitle(this.event.tags);
    this.userEvent = await this.usersService.getUserEventByRole(
      this.event.id,
      this.user.id,
      'attendee',
    );
    this.eventRegisterForm = await this.eventsService.getEventRegisterSettings(
      this.eventsStore.adminEvent.id,
    );
    this.tickets = await this.ticketsService.getEventTickets(this.event.id);

    this.form.patchValue({
      ...this.userEvent,
      ticket: this.userEvent?.ticketId
        ? this.tickets.find((ticket: ITicket) => ticket.id === this.userEvent?.ticketId)
        : null,
    });
    this.form.updateValueAndValidity();
    this.form.valueChanges.subscribe(() => this.form.markAsDirty());
    this.loading = false;

    this.form.controls.roomType.valueChanges
      .pipe(
        tap((value: string) => {
          if (value === RoomType.SINGLE_ROOM) {
            this.form.controls.roomPartner.setValue(null);
          }
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();
  }

  public selectTags(tagIds: string[]): void {
    this.form.patchValue({ tags: tagIds });
  }

  public async update(): Promise<void> {
    this.isUpdating = true;

    try {
      const { ticket, roomPartner, ...others } = this.form.getRawValue();
      await this.usersService.updateUserEvent({
        ...this.userEvent,
        ...others,
        ticketId: !!ticket ? ticket.id : null,
        ticketPrice: !!ticket ? ticket.price : null,
        roomPartner,
      });

      this.isUpdating = false;
      this.messageService.add({
        severity: 'success',
        summary: this.translateService.instant('success'),
        detail: this.translateService.instant('adminUserEventDetails.successfullyUpdated'),
        styleClass: 'custom-toast',
      });
    } catch (error) {
      console.log(error);
      this.isUpdating = false;
      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('error'),
        detail: this.translateService.instant(`adminUserEventDetails.error}`),
        styleClass: 'custom-toast',
      });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
