import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-sw-new-version',
  templateUrl: './sw-new-version.component.html',
  styleUrls: ['./sw-new-version.component.scss'],
})
export class SwNewVersionComponent implements OnInit {
  constructor(
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
  ) {}

  ngOnInit(): void {}
}
