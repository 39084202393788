<div class="container">
  <ng-container *ngFor="let item of arrForShowing; trackBy: trackByFn">
    <p class="title">
      {{ item[0] | translate | uppercase }}
    </p>
    <p class="value">
      {{ item[1] | translate }}
    </p>
  </ng-container>
</div>
