import { Component, OnDestroy, OnInit, ElementRef, signal, inject, viewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Subject, firstValueFrom, takeUntil, tap } from 'rxjs';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';

import { SharedModule } from 'src/app/shared';
import { ICourse, ICourseLandingPageConfig } from 'src/app/core/models';
import { CoursesStore, UsersStore } from 'src/app/core/stores';
import { VALID_LINK_PATTERN, asyncDelay, goToLink } from 'src/app/core/utils';
import {
  ButtonSize,
  ButtonStyle,
  CourseLandingPageType,
  HELP_VIMEO,
  SaveDiscardActionsComponent,
} from 'src/app/standalone/shared';
import {
  BigProfilePictureMediaFormComponent,
  ButtonComponent,
  CustomRadioButtonComponent,
  ToastComponent,
} from 'src/app/standalone/shared/components';
import {
  CoursesService,
  FormService,
  OpenDialogService,
  UnlayerService,
} from 'src/app/core/services';
import { Timestamp } from 'src/app/firebase';

interface ILandingPageOptionType {
  iconClass: string;
  title: string;
  description: string;
  value: CourseLandingPageType;
}

@Component({
  selector: 'app-landing-page',
  standalone: true,
  imports: [
    SharedModule,
    SaveDiscardActionsComponent,
    BigProfilePictureMediaFormComponent,
    ButtonComponent,
    CustomRadioButtonComponent,
    ToastComponent,
  ],
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent implements OnInit, OnDestroy {
  internalVideoInput = viewChild.required<ElementRef<HTMLInputElement>>('internalVideoInput');

  loading = signal(true);
  isUpdating = signal(false);
  fileVideoPreview = signal<SafeResourceUrl>(null);
  allowedFormatsForInternalVideo = signal<string[]>(['video/mp4', 'video/webm']);
  form: FormGroup;
  buttonSize = signal<typeof ButtonSize>(ButtonSize);
  buttonStyle = signal<typeof ButtonStyle>(ButtonStyle);
  pageOptions = signal<ILandingPageOptionType[]>([
    {
      iconClass: 'fa-regular fa-text',
      title: 'adminCourseLandingPage.defaultPageOptionTitle',
      description: 'adminCourseLandingPage.defaultPageOptionDescription',
      value: CourseLandingPageType.DEFAULT,
    },
    {
      iconClass: 'fa-regular fa-paintbrush-fine',
      title: 'adminCourseLandingPage.fullCustomPageOptionTitle',
      description: 'adminCourseLandingPage.fullCustomPageOptionDescription',
      value: CourseLandingPageType.FULL_CUSTOMIZATION,
    },
  ]);
  courseLandingPageType = signal<typeof CourseLandingPageType>(CourseLandingPageType);

  private unsubscribe$ = new Subject<void>();
  private internalVideoMaxSize = 8_000_000_000; // 8GB
  private fb = inject(FormBuilder);
  private coursesStore = inject(CoursesStore);
  private formService = inject(FormService);
  private usersStore = inject(UsersStore);
  private coursesService = inject(CoursesService);
  private messageService = inject(MessageService);
  private translateService = inject(TranslateService);
  private sanitizer = inject(DomSanitizer);
  private openDialogService = inject(OpenDialogService);
  private unlayerService = inject(UnlayerService);

  get course(): ICourse {
    return this.coursesStore.adminCourse;
  }

  get canUpdate(): boolean {
    return (
      !this.isUpdating() && this.form.valid && this.form.dirty && this.formService.isValueChanged()
    );
  }

  get showInternalVideoPlayer(): boolean {
    if (this.course.internalVideo) {
      return true;
    } else {
      return false;
    }
  }

  get internalVideoPath(): string {
    return this.course.internalVideo.toString();
  }

  ngOnInit(): void {
    this.createForm();
    this.updateForm();
    this.formService.setForm(this.form);
    this.listenChanges();
    this.loading.set(false);
  }

  setBannerValue(value: File): void {
    this.form.controls.banner.setValue(value);
    this.makeFormControlDirty('banner');
  }

  setBannerGradient(value: string): void {
    this.form.controls.bannerGradient.setValue(value);
    this.makeFormControlDirty('bannerGradient');
  }

  onNavigateTo(): void {
    goToLink(HELP_VIMEO);
  }

  onDiscard(): void {
    this.fileVideoPreview.set(null);
    this.updateForm();
  }

  async onConfirm(): Promise<void> {
    try {
      this.isUpdating.set(true);
      await asyncDelay(1);
      await this.updateCourse();
      this.formService.setForm(this.form);
    } catch (error) {
      console.error(error);
    }
  }

  setFeaturedImageValue(value: File): void {
    this.form.controls.featuredImage.setValue(value);
    this.makeFormControlDirty('featuredImage');
  }

  clearVideo(): void {
    this.form.patchValue({
      internalVideo: null,
      videoDuration: null,
      video: null,
    });
    this.form.markAsDirty();
  }

  uploadInternalVideo(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (!input.files || input.files.length === 0) {
      return;
    }

    this.uploadVideo(input.files);
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    this.uploadVideo(event.dataTransfer.files);
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
  }

  clearInternalVideo(): void {
    this.form.patchValue({
      internalVideo: null,
      video: this.course.video,
    });
    this.fileVideoPreview.set(null);
    this.form.controls.video.enable();
    this.makeFormControlDirty('internalVideo');
  }

  async onOpenPageBuilder(): Promise<void> {
    const unlayerTemplates = await this.unlayerService.getWebPageTemplates();
    const dialogRef: DynamicDialogRef = this.openDialogService.openWebPageUnlayerEditorDialog(
      unlayerTemplates,
      this.form.controls.landingPage?.value ?? null,
    );
    const fullCustomLandingPageConfig: ICourseLandingPageConfig = await firstValueFrom(
      dialogRef.onClose,
    );
    if (fullCustomLandingPageConfig) {
      this.form.controls.landingPage.setValue({ ...fullCustomLandingPageConfig });
      this.form.controls.landingPage.markAsDirty();
    }
  }

  onChangeLandingPageType(landingPageType: ILandingPageOptionType): void {
    if (this.form.controls.landingPageType.value === landingPageType.value) {
      return;
    }

    this.form.controls.landingPageType.setValue(landingPageType.value);
    this.form.controls.landingPageType.markAsDirty();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private uploadVideo(files: FileList): void {
    const file = files[0];

    if (file.size > this.internalVideoMaxSize) {
      this.messageService.add({
        severity: 'error',
        detail: this.translateService.instant('adminLibraryAssetFrom.maxSizeError'),
        styleClass: 'custom-toast',
      });
      this.clearInternalVideo();

      return;
    }

    if (!this.allowedFormatsForInternalVideo().includes(file.type)) {
      this.messageService.add({
        severity: 'error',
        detail: this.translateService.instant('adminLibraryAssetFrom.invalidVideoFormat'),
        styleClass: 'custom-toast',
      });
      this.clearInternalVideo();

      return;
    }

    this.form.controls.internalVideo.setValue(file);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      this.fileVideoPreview.set(
        this.sanitizer.bypassSecurityTrustResourceUrl(reader.result as string),
      );
      this.makeFormControlDirty('internalVideo');
    };

    reader.onerror = (error) => {
      console.log(error);
    };
  }

  private makeFormControlDirty(formControlName: string): void {
    this.form.controls[formControlName].markAsDirty();
  }

  private createForm(): void {
    this.form = this.fb.group({
      landingPageType: CourseLandingPageType.DEFAULT,
      description: null,
      banner: null,
      bannerGradient: null,
      video: [null, [Validators.pattern(VALID_LINK_PATTERN)]],
      internalVideo: null,
      videoDuration: null,
      linkedin: [null, [Validators.pattern(VALID_LINK_PATTERN)]],
      facebook: [null, [Validators.pattern(VALID_LINK_PATTERN)]],
      xing: [null, [Validators.pattern(VALID_LINK_PATTERN)]],
      featuredImage: null,
      landingPage: null,
    });
  }

  private updateForm(): void {
    this.form.patchValue({
      ...this.course,
    });

    if (this.course.featured) {
      this.form.controls.featuredImage.addValidators([Validators.required]);
      this.form.controls.featuredImage.updateValueAndValidity();
    }

    if (this.course.internalVideo) {
      this.fileVideoPreview.set(
        this.sanitizer.bypassSecurityTrustUrl(this.course.internalVideo as string),
      );
      this.form.controls.video.disable();
    }

    if (this.course.video) {
      this.form.controls.internalVideo.disable();
    }
  }

  private async updateCourse(): Promise<void> {
    this.isUpdating.set(true);
    const updatedCourse: ICourse = {
      ...this.course,
      ...this.form.getRawValue(),
      updatedAt: Timestamp.now(),
      updatedBy: this.usersStore.userId,
    };
    try {
      await this.coursesService.update(this.course.id, updatedCourse);
      this.showToastMessage('success', 'adminCourseSettings.courseSuccessfullyUpdated');
    } catch (error) {
      console.warn(error);
      this.showToastMessage('error', 'application.toasters.error');
    } finally {
      this.isUpdating.set(false);
    }
  }

  private showToastMessage(severity: 'success' | 'error', detail: string): void {
    this.messageService.add({
      severity,
      summary: this.translateService.instant(severity),
      detail: this.translateService.instant(detail),
      styleClass: 'custom-toast',
    });
  }

  private listenChanges(): void {
    this.form.controls.internalVideo.valueChanges
      .pipe(
        tap((val: any) => {
          if (val && this.form.controls.video.enabled) {
            this.form.controls.video.disable();
          } else {
            this.form.controls.video.enable();
          }
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();

    this.form.controls.video.valueChanges
      .pipe(
        tap((val: string) => {
          if (val?.length && this.form.controls.internalVideo.enabled) {
            this.form.controls.internalVideo.disable();
          } else {
            this.form.controls.internalVideo.enable();
          }
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();
  }
}
