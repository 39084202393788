import { IAnalyticsEvent } from './analytics-event.model';

export interface IAnalyticsUserEvent extends IAnalyticsEvent {
  firstName: string;
  lastName: string;
  displayEmail: string;
  email: string;
  role: string;
  brandId: string;
  company: string;
  position: string;
  website: string;
  city: string;
  country: string;
  tags: string[];
  id: string;
}
