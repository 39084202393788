import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { Observable, catchError, debounceTime, distinctUntilChanged, from, map, of } from 'rxjs';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

import { EmailsService, FormService, HubsService } from 'src/app/core/services';
import { SaveDiscardActionsComponent } from 'src/app/standalone/shared';
import { asyncDelay } from 'src/app/core/utils';
import { SharedModule } from 'src/app/shared';
import { IEmailSenderSettings, IHub } from 'src/app/core/models';
import { HubsStore } from 'src/app/core/stores';

@Component({
  selector: 'app-hub-email-sender',
  standalone: true,
  imports: [CommonModule, SharedModule, SaveDiscardActionsComponent],
  templateUrl: './hub-email-sender.component.html',
  styleUrls: ['./hub-email-sender.component.scss'],
})
export class HubEmailSenderComponent implements OnInit {
  loading = true;
  form: FormGroup;
  isUpdating: boolean;

  constructor(
    private formService: FormService,
    private fb: FormBuilder,
    private hubsStore: HubsStore,
    private emailsService: EmailsService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private hubsService: HubsService,
  ) {}

  get canUpdate(): boolean {
    return (
      !this.isUpdating && this.form.valid && this.form.dirty && this.formService.isValueChanged()
    );
  }

  get hub(): IHub {
    return this.hubsStore.hub;
  }

  ngOnInit(): void {
    this.initForm();
    this.setInitialValueForForm();
    this.formService.setForm(this.form);
    this.loading = false;
  }

  onDiscard(): void {
    this.setInitialValueForForm();
  }

  async onConfirm(): Promise<void> {
    try {
      this.isUpdating = true;
      await asyncDelay(1);
      await this.update();
      this.formService.setForm(this.form);
    } catch (error) {
      console.error(error);
    }
  }

  private initForm(): void {
    this.form = this.fb.group({
      sender: [null, [Validators.required, Validators.email]],
      fromName: [null, Validators.required],
      replyToEmail: [null, [Validators.required, Validators.email]],
      apiAccessKey: [null, Validators.required, this.linkExistsValidator()],
    });
  }

  private setInitialValueForForm(): void {
    if (!this.hub?.emailSenderSettings) {
      return;
    }

    this.form.patchValue({ ...this.hub.emailSenderSettings });
  }

  private async update(): Promise<void> {
    const formValue: IEmailSenderSettings = this.form.getRawValue();

    try {
      const updatedHub: IHub = await this.hubsService.update(this.hub.id, {
        ...this.hub,
        emailSenderSettings: { ...formValue },
      });
      this.hubsStore.setHub({ ...updatedHub });
      this.isUpdating = false;
      this.showToastMessage('success', 'adminHubEmailSender.successUpdated');
    } catch (error) {
      console.error(error);
      this.isUpdating = false;
      throw error;
    }
  }

  private linkExistsValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      const { value } = control;

      return from(this.emailsService.isEmailSenderApiKeyValid(value)).pipe(
        debounceTime(500),
        distinctUntilChanged(),
        map(() => {
          control.setErrors(null);

          return null;
        }),
        catchError(() => {
          control.setErrors({ invalidApiKey: true });

          return of({ invalidApiKey: true });
        }),
      );
    };
  }

  private showToastMessage(severity: 'success' | 'error', detail: string): void {
    this.messageService.add({
      severity,
      summary: this.translateService.instant(severity),
      detail: this.translateService.instant(detail),
      styleClass: 'custom-toast',
    });
  }
}
