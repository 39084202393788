<div class="stripe-connect-dialog-container">
  <header class="header">{{ 'stripeConnectPopup.title' | translate }}</header>
  <p-divider styleClass="custom-divider-horizontal"></p-divider>
  <main class="main">
    <ng-container>
      <div
        class="item"
        [ngClass]="{ selected: isExpressSelected }"
        (click)="onExpress()">
        <div class="circle"></div>
        <div class="info">
          <div class="title">{{ 'stripeConnectPopup.express' | translate }}</div>
          <div class="description">{{ 'stripeConnectPopup.expressDescription' | translate }}</div>
        </div>
      </div>
      <div
        class="item"
        [ngClass]="{ selected: !isExpressSelected }"
        (click)="onStandard()">
        <div class="circle"></div>
        <div class="info">
          <div class="title">{{ 'stripeConnectPopup.standard' | translate }}</div>
          <div class="description">{{ 'stripeConnectPopup.standardDescription' | translate }}</div>
        </div>
      </div>
    </ng-container>
  </main>

  <footer class="footer">
    <button
      class="primary-text-color"
      pButton
      label="{{ 'stripeConnectPopup.connectToStripe' | translate }}"
      type="button"
      (click)="onConnect()"></button>
  </footer>
</div>
