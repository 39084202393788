import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { EventConsentType } from 'src/app/core/enums';
import { IEventConsent } from 'src/app/core/models';
import { trackByFn } from 'src/app/core/utils';

@Component({
  selector: 'app-register-terms-and-conditions',
  templateUrl: './register-terms-and-conditions.component.html',
  styleUrls: ['./register-terms-and-conditions.component.scss'],
})
export class RegisterTermsAndConditionsComponent implements OnInit {
  loading: boolean = true;
  formConsents: UntypedFormGroup;
  eventConsents: IEventConsent[];
  trackByFn = trackByFn;

  get isBtnDisabled(): boolean {
    return !this.formConsents.valid && !this.loading;
  }

  get consentControls(): AbstractControl[] {
    return (<UntypedFormArray>this.formConsents?.get('acceptedConsents')).controls;
  }

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
  ) {}

  async ngOnInit(): Promise<void> {
    this.formConsents = new UntypedFormGroup({
      acceptedConsents: new UntypedFormArray([]),
    });
    this.eventConsents = this.config.data.consents;
    this.config.data.values.forEach(
      (item: { type: EventConsentType; required: boolean; value: boolean | number }) => {
        (this.formConsents?.controls?.acceptedConsents as UntypedFormArray).push(
          new UntypedFormControl(
            item.value,
            item?.required
              ? Validators.requiredTrue
              : item.type === EventConsentType.MULTI
                ? Validators.required
                : null,
          ),
        );
      },
    );
    this.loading = false;
  }

  save(): void {
    this.ref.close(this.formConsents.controls.acceptedConsents.value);
  }
}
