@if (loading()) {
  <app-loader></app-loader>
} @else {
  <header class="header">
    {{ 'adminEventSessionSidebar.registrationSettingsNavItem' | translate }}
  </header>
  <p-divider styleClass="custom-divider-horizontal"></p-divider>
  <form [formGroup]="form">
    <!-- Registration Settings block -->
    <div class="form-block c-my-8 c-px-8">
      <div class="left-side">
        <h2 class="title">{{ 'adminSession.registrationSettingsTitle' | translate }}</h2>
        <p class="description">{{ 'adminSession.registrationSettingsDesc' | translate }}</p>
      </div>
      <div class="right-side">
        <!-- Session registration switch input -->
        <div class="form-field-input-switch-block c-mb-4">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="isSessionRegistration"
            inputId="session-registration-input-switch">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="session-registration-input-switch"
            text="adminSession.sessionRegistrationLabel">
          </app-input-label>
        </div>

        <!-- Session cancelling registration switch input -->
        <div class="form-field-input-switch-block c-mb-4">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="isCancellingEnabled"
            inputId="is-cancelling-enabled-input-switch">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="is-cancelling-enabled-input-switch"
            text="adminSession.cancellingEnabled">
          </app-input-label>
        </div>

        <!-- Availability input -->
        <div class="form-field-block c-mb-8">
          <app-input-label
            for="availability-input"
            text="adminSession.availabilityLabel">
          </app-input-label>
          <p-inputNumber
            class="custom-input-number w-full"
            inputId="availability-input"
            formControlName="availability"
            placeholder="{{ 'adminSession.availabilityPlaceholder' | translate }}">
          </p-inputNumber>
        </div>

        <p-divider styleClass="custom-divider-horizontal"></p-divider>

        <!-- Closing session registration switch input -->
        <div class="form-field-input-switch-block c-mt-8">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="isClosingSessionRegistration"
            inputId="closing-session-registration-input-switch">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="closing-session-registration-input-switch"
            text="adminSession.closingSessionRegistrationLabel">
          </app-input-label>
        </div>

        <div class="two-elements-block c-my-4">
          <!-- Closing date input -->
          <div>
            <app-input-label
              labelType="required"
              for="closing-date-input"
              text="adminSession.closingDateLabel">
            </app-input-label>
            <p-calendar
              class="custom-calendar custom-calendar-required"
              formControlName="closingDate"
              [monthNavigator]="true"
              [yearNavigator]="true"
              [showIcon]="true"
              dateFormat="dd/mm/yy"
              yearRange="1900:2050"
              placeholder="23/01/2022"
              inputId="closing-date-input"
              icon="fak fa-lox-calendar">
            </p-calendar>
            <app-input-error-message
              [showMessage]="form.controls.closingDate.invalid && form.controls.closingDate.touched"
              errorMessage="{{ 'application.forms.required' | translate }}">
            </app-input-error-message>
          </div>

          <!-- Closing time input -->
          <div>
            <app-input-label
              labelType="required"
              for="closing-time-input"
              text="adminSession.closingTimeLabel">
            </app-input-label>
            <p-calendar
              class="custom-calendar custom-calendar-time custom-calendar-required"
              formControlName="closingTime"
              placeholder="09:00"
              inputId="closing-time-input"
              [timeOnly]="true"
              [showIcon]="true"
              icon="far fa-clock"
              appDateMask>
            </p-calendar>
            <app-input-error-message
              [showMessage]="form.controls.closingTime.invalid && form.controls.closingTime.touched"
              errorMessage="{{ 'application.forms.required' | translate }}">
            </app-input-error-message>
          </div>
        </div>

        <p-divider styleClass="custom-divider-horizontal"></p-divider>

        <!-- Session relation input -->
        <div class="form-field-block c-mt-4">
          <app-input-label
            for="session-relation-input"
            text="adminSession.sessionRelationLabel">
          </app-input-label>
          <p-multiSelect
            class="custom-multi-select"
            [options]="sessions()"
            optionLabel="title"
            optionValue="id"
            formControlName="sessionsRelation"
            inputId="session-relation-input"
            placeholder="{{ 'adminSession.sessionRelationPlaceholder' | translate }}">
          </p-multiSelect>
        </div>
      </div>
    </div>
  </form>
}

<div class="fixed right-0 bottom-0 left-0">
  <app-save-discard-actions
    [isShow]="canUpdate"
    [isLoading]="isUpdating()"
    (discard)="onDiscard()"
    (confirm)="onConfirm()">
  </app-save-discard-actions>
</div>

<p-toast>
  <ng-template
    let-message
    pTemplate="message">
    <app-toast
      [severity]="message.severity"
      [summary]="message.summary"
      [detail]="message.detail">
    </app-toast>
  </ng-template>
</p-toast>
