<div class="chat-conversation-list">
  <div
    class="chat-conversation"
    [ngClass]="{ 'chat-conversation--highlighted': chatConversation.unreadMessages > 0 }"
    *ngFor="let chatConversation of chatConversations"
    pTooltip="{{ getTextForTooltip(chatConversation) | translate }}"
    tooltipPosition="bottom"
    (click)="chatConversationSelectedAction(chatConversation)">
    <div class="left-section">
      <app-user-photo [user]="chatConversation.conversationWithUser"></app-user-photo>
    </div>

    <div class="center-section">
      <div class="user-name">
        {{ chatConversation.conversationWithUser.firstName }}
        {{ chatConversation.conversationWithUser.lastName }}
      </div>
      <div class="message">
        {{ chatConversation.lastMessage.message }}
      </div>
    </div>

    <div class="right-section">
      <div class="time">{{ timeToString(chatConversation.lastMessage.timestamp) }}</div>
      <div
        *ngIf="chatConversation.unreadMessages > 0"
        class="unread-messages">
        {{ chatConversation.unreadMessages }}
      </div>
    </div>
  </div>
</div>
