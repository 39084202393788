import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-sw-install-prompt',
  templateUrl: './sw-install-prompt.component.html',
  styleUrls: ['./sw-install-prompt.component.scss'],
})
export class SwInstallPromptComponent implements OnInit {
  public platform: string = '';

  constructor(
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
  ) {
    this.platform = config.data?.platform || '';
  }

  ngOnInit(): void {}
}
