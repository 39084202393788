import * as moment from 'moment';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

import { ChatMessage, ChatMessageAttachment } from 'src/app/core/models/chat';
import { UsersStore } from 'src/app/core/stores/users/users.store';
import { compareDate, trackByFn } from 'src/app/core/utils/utils';

@Component({
  selector: 'app-chat-conversation',
  templateUrl: './chat-conversation.component.html',
  styleUrls: ['./chat-conversation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatConversationComponent implements OnInit {
  trackByFn = trackByFn;

  isUploadAttachmentOpen = false;

  messages$ = new BehaviorSubject<ChatMessage[]>([]);

  attachment?: ChatMessageAttachment;

  @ViewChild('messagesContainer')
  messagesContainer?: ElementRef<HTMLDivElement>;

  @Output() toggleMessageLike = new EventEmitter<ChatMessage>();

  @Input() set messages(messages: ChatMessage[]) {
    if (!messages) {
      return;
    }

    // check if the is the first time updating the chat messages
    const firstUpdateWithValue = this.messages$.getValue().length === 0;

    // sort the messages by time
    messages.sort((a, b) => (a.timestamp < b.timestamp ? -1 : 1));

    // update the messages
    this.messages$.next(messages);

    // scroll the list to the bottom
    setTimeout(() => {
      const elem = this.messagesContainer?.nativeElement;

      if (!elem) {
        return;
      }

      // the scroll will only be "smooth" after the first update
      elem.scroll({ top: elem.scrollHeight, behavior: firstUpdateWithValue ? 'auto' : 'smooth' });
    });
  }

  @Input() chatConversationId?: string;

  @Output() sendMessage = new EventEmitter<string>();

  constructor(
    private usersStore: UsersStore,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {}

  onSendMessage(message: string): void {
    this.sendMessage.emit(message);
  }

  onLoadAttachment(): void {
    this.isUploadAttachmentOpen = true;
  }

  onDismissLoadAttachment(attachment?: ChatMessageAttachment): void {
    this.isUploadAttachmentOpen = false;
    this.attachment = attachment;
  }

  isIncomingMessage(chatMessage: ChatMessage): boolean {
    return !this.isOutgoingMessage(chatMessage);
  }

  isOutgoingMessage(chatMessage: ChatMessage): boolean {
    return this.usersStore.user.id === chatMessage.user.id;
  }

  dateToString(date: Date): string {
    const now = new Date();
    const diff = (now.getTime() - date.getTime()) / 1000 / 60;
    const currentLang = this.translateService.currentLang;

    if (diff <= 1) {
      const result: string = this.translateService.instant('chat.chatWindow.chatList.currentTime');
      return result;
    } else if (diff < 60) {
      // less than an hour
      const min = Math.floor(diff);
      const result: string = this.translateService.instant('chat.chatWindow.chatList.minAgo', {
        min,
      });
      return result;
    } else if (diff > 60 && compareDate(now, date)) {
      // less than a day
      const dateResult =
        currentLang === 'en' ? moment(date).format('LT') : moment(date).format('HH:mm');
      const result = currentLang === 'en' ? dateResult : `${dateResult} Uhr`;
      return result;
    } else {
      const result =
        currentLang === 'en' ? moment(date).format('L') : moment(date).format('DD.MM.YYYY');
      return result;
    }
  }

  onToggleMessageLike(message: ChatMessage): void {
    this.toggleMessageLike.emit(message);
  }
}
