import * as moment from 'moment';

import { ISession } from '../../core/models';
import { parseToMoment } from './date-mapper';

export const sortSessionsByDateAndTitle = (a: ISession, b: ISession): number =>
  new Date(`${a.start}`).getTime() - new Date(`${b.start}`).getTime() ||
  a.title.localeCompare(b.title);

export const isSessionLive = (session: ISession): boolean => {
  const start = parseToMoment(session.start);
  const end = parseToMoment(session.end);

  return moment().isBetween(start, end);
};
