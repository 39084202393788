<div class="container">
  <image
    [src]="notification.user.profileImage"
    [text]="imageText(notification.user)"
    size="super-small">
  </image>
  <div class="main-block">
    <p
      class="text"
      [innerHTML]="notificationText"></p>
    <i
      class="fal fa-info-circle info-icon"
      [pTooltip]="notification.appointmentUser.declineReason"
      tooltipPosition="bottom">
    </i>
  </div>
</div>
