import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { EventsStore } from 'src/app/core/stores';
import { EmailsService } from 'src/app/core/services';
import { IEmail } from 'src/app/core/models';

@Component({
  selector: 'app-event-email',
  templateUrl: './event-email.component.html',
  styleUrls: ['./event-email.component.scss'],
})
export class EventEmailComponent implements OnInit {
  public loading = true;
  public email: IEmail = null;
  public emailId: string = null;
  public title: string = null;

  constructor(
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private emailsService: EmailsService,
    private eventsStore: EventsStore,
  ) {}

  async ngOnInit(): Promise<void> {
    this.route.params.subscribe(async (params) => {
      this.emailId = params.id;
      this.title = this.translateService.instant('adminEmailTemplates.editEventEmailTitle');
      this.email = await this.emailsService.getEventEmail(
        this.eventsStore.adminEvent.id,
        this.emailId,
      );
      this.loading = false;
    });
  }
}
