import { Timestamp } from 'src/app/firebase';

export interface IHubBrand {
  id: string;
  brandId: string;
  _brand_name_: string;
  _hub_title_: string;
  createdAt: Timestamp | any;
  createdBy: string;
  hubId: string;
  name: string;
  updatedAt: string;
  updatedBy: string;
}
