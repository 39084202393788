import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss'],
})
export class DeleteDialogComponent implements OnInit {
  public input: string;
  public title: string;
  public description: string;

  constructor(
    private dialogRef: DynamicDialogRef,
    private config: DynamicDialogConfig,
  ) {}

  ngOnInit(): void {
    this.title = this.config.data?.title;
    this.description = this.config.data?.description;
  }

  public delete(): void {
    if (this.input === 'DELETE') {
      this.dialogRef.close(true);
    }
  }
}
