export const environment = {
  production: true,
  env: 'STAGING',
  branch: 'staging',
  baseUrl: 'https://brandyoo-staging.web.app',
  apiUrl: 'https://us-central1-brandyoo-staging.cloudfunctions.net/api/v1/',
  envUrl: 'https://brandyoo-staging.web.app/events/',
  googleTagManager: {
    id: 'GTM-W7RSKGK',
  },
  lockoutTimeout: {
    timeout: 10,
  },
  sentry: {
    dsn: 'https://3a64da83f9b94ca7a7504518d187ea7d@o975625.ingest.sentry.io/5931816',
    tracingOrigins: [
      'https://brandyoo-staging.web.app/',
      'https://us-central1-brandyoo-staging.cloudfunctions.net/api/v1/',
      'https://myevents.site/',
    ],
    tracesSampleRate: 1.0,
  },
  microsoftSso: {
    enabled: false,
  },
  reCaptcha: {
    siteKey: '6LcOG4wdAAAAADv6frLUPxrLKk3Z5taOeSUj95F1',
  },
  showCookieManager: true,
  indexedDbName: 'INDEXED_DB_BRANDYOO_STAGING',
  indexedDbVersion: 1,
  enableSW: true,
  stripePricingTable: {
    stripeKey:
      'pk_test_51PJxHhFoVxHBUm7J6wdnnrbdcmxmct9tLCT7l1wRzh3or9IAR3BgnfuGYMzqvC2SB6rIt8iu9O7Yf7NW9BEKRYay00HUjq6YL4',
    stripeTable: 'prctbl_1PkODIFoVxHBUm7JFmYdOeAZ',
  },
  stripe: {
    publishableKey:
      'pk_test_51PJxHhFoVxHBUm7J6wdnnrbdcmxmct9tLCT7l1wRzh3or9IAR3BgnfuGYMzqvC2SB6rIt8iu9O7Yf7NW9BEKRYay00HUjq6YL4',
    adminProducts: [
      {
        product: 'prod_QbbH0ENMD3BoUO',
        prices: ['price_1PkO4qFoVxHBUm7JoFjLHIea'],
      },
      {
        product: 'prod_QbbI3zGwjVCh1g',
        prices: ['price_1PkO5YFoVxHBUm7J5dIXSCto'],
      },
      {
        product: 'prod_QbbIZAjBjZDwmf',
        prices: ['price_1PkO6FFoVxHBUm7JAtyacscb'],
      },
    ],
  },
  dailyMeetingSublink: 'lox.daily.co',
  defaultEmail: 'noreply@lox.events',
  helpLink: 'https://help.lox.events/',
  poweredByUrl: 'https://lox.com/',
  noreplyEmail: 'noreply@lox.events',
  applicationName: 'LO:X',
  termsOfUseLink: 'https://www.ebnermedia.de/ebv/nzb/',
  termsAndConditions: 'https://www.ebnermedia.de/ebvm/agb/',
  imprint: 'https://www.ebnermedia.de/impressum/',
};
