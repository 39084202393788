<p-orderList
  [value]="items()"
  [dragdrop]="true"
  styleClass="list-styles-drag">
  <ng-template
    let-item
    pTemplate="item">
    <div
      class="menu-items-organizer__menu-item"
      [ngClass]="{ 'menu-items-organizer__menu-item--divider': item.type === 'divider' }">
      <i class="fa-solid fa-grip-vertical menu-items-organizer__menu-item__grip-vertical"></i>
      <span>{{ item.name ?? item.key }}</span>
      @if (item.type === 'group' && item.options && item.options.length > 0) {
        <div class="menu-items-organizer__menu-item__icon-container">
          <app-button
            buttonRoundIcon="fa-regular fa-pen-to-square"
            [buttonType]="buttonType().ROUND"
            [buttonStyle]="buttonStyle().FILLED_SECONDARY"
            [buttonSize]="buttonSize().SMALL"
            (click)="drillDownAction(item)" />
        </div>
      }
      <div>
        @if (item.visible) {
          <app-button
            buttonRoundIcon="fa-regular fa-eye green"
            [buttonType]="buttonType().ROUND"
            [buttonStyle]="buttonStyle().FILLED_SECONDARY"
            [buttonSize]="buttonSize().SMALL"
            [scssStyles]="stylesForVisibleItem()"
            (click)="item.visible = !item.visible" />
        }

        @if (!item.visible) {
          <app-button
            buttonRoundIcon="fa-regular fa-eye-slash"
            [buttonType]="buttonType().ROUND"
            [buttonStyle]="buttonStyle().FILLED_SECONDARY"
            [buttonSize]="buttonSize().SMALL"
            (click)="item.visible = !item.visible" />
        }
      </div>
    </div>
  </ng-template>
</p-orderList>
