import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

import { Timestamp } from 'src/app/firebase';
import { EventsStore, HubsStore, UsersStore } from 'src/app/core/stores';
import { INotification } from 'src/app/core/models';
import { NotificationsService } from 'src/app/core/services';
import { EventGeneralNotificationTypes } from 'src/app/core/enums';

@Component({
  selector: 'app-event-notification-form',
  templateUrl: './event-notifications-form.component.html',
  styleUrls: ['./event-notifications-form.component.scss'],
})
export class EventNotificationsFormComponent implements OnInit {
  @Input() notification: INotification = null;

  public loading = true;
  public saving = false;
  public sendingTestEmail = false;
  public showMergeTags = false;
  public testEmailForm: UntypedFormGroup;
  public form: UntypedFormGroup;
  public languages: any[] = [];
  public notificationTypes = Object.keys(EventGeneralNotificationTypes);
  public isEditing: boolean = false;

  constructor(
    private router: Router,
    private translateService: TranslateService,
    private messageService: MessageService,
    private usersStore: UsersStore,
    public hubsStore: HubsStore,
    private eventsStore: EventsStore,
    private notificationsService: NotificationsService,
  ) {}

  ngOnInit(): void {
    this.languages = this.translateService.getLangs().map((l) => {
      return { name: this.translateService.instant('languages.' + l), code: l };
    });

    this.notification ? (this.isEditing = true) : null;

    this.createForm();
    this.loading = false;
  }

  private createForm(): void {
    this.form = new UntypedFormGroup({
      title: new UntypedFormControl(
        this.notification ? this.notification?.title : '',
        Validators.required,
      ),
      type: new UntypedFormControl(
        this.notification
          ? this.notification?.type
          : EventGeneralNotificationTypes.general.toString(),
        Validators.required,
      ),
      description: new UntypedFormControl(
        this.notification ? this.notification?.description : '',
        Validators.required,
      ),
    });
  }

  public async save(): Promise<void> {
    if (!this.form.valid) return;

    try {
      this.saving = true;
      const form = this.form.value;

      const newNotification = {
        id: this.notification ? this.notification.id : null,
        title: form.title,
        description: form.description,
        type: form.type,
        updatedAt: this.notification ? Timestamp.now() : null,
        updatedBy: this.notification ? this.usersStore.user.id : null,
        createdAt: this.notification ? this.notification.createdAt : Timestamp.now(),
        createdBy: this.notification ? this.notification.createdBy : this.usersStore.user.id,
        receiverId: null,
        receiversIds: null,
        data: null,
        hostId: null,
        isSeen: false,
      } as INotification;

      if (this.isEditing) {
        await this.notificationsService.updateEventNotification(
          newNotification,
          this.eventsStore.adminEvent.id,
        );
      } else {
        await this.notificationsService.createEventNotification(
          newNotification,
          this.eventsStore.adminEvent.id,
        );
        this.router.navigate([
          `/${this.hubsStore.useHubUrl}/admin/event/${this.eventsStore.adminEvent.link}/communication/notifications`,
        ]);
      }

      this.messageService.add({
        severity: 'success',
        summary: this.translateService.instant('adminEventNotificationForm.success'),
        detail: this.translateService.instant('adminEventNotificationForm.succesSave'),
        styleClass: 'custom-toast',
      });

      this.saving = false;
    } catch (error) {
      console.log(error);
      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('error'),
        detail: this.translateService.instant('adminEventNotificationForm.errorSave'),
        styleClass: 'custom-toast',
      });
      this.saving = false;
    }
  }
}
