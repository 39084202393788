export interface IVimeoPlayerOptions {
  id?: string; // Id or url should be required one of them
  url?: string; // Id or url should be required one of them
  autopause?: boolean;
  autoplay?: boolean;
  background?: boolean;
  byline?: boolean;
  color?: string;
  controls?: boolean;
  dnt?: boolean;
  height?: string; // The height of the video in pixels.
  interactive_params?: any;
  keyboard?: boolean;
  loop?: boolean;
  maxheight?: string;
  maxwidth?: string;
  muted?: boolean;
  pip?: boolean;
  playsinline?: boolean;
  portrait?: boolean;
  quality?: 'auto' | '360p' | '540p' | '720p' | '1080p' | '2k' | '4k';
  responsive?: boolean;
  speed?: boolean;
  texttrack?: string;
  title?: boolean;
  transparent?: boolean;
  width?: string;
}
