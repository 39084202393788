import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';

import { ITag } from 'src/app/core/models/tag/tag.model';
@Component({
  selector: 'app-tags-multi-list',
  templateUrl: './tags-multi-list.component.html',
  styleUrls: ['./tags-multi-list.component.scss'],
})
export class TagsMultiListComponent implements AfterViewInit {
  @Input() public possibleTags: ITag[] = [];
  @Input() public showAsSingleRow = false;
  @Input() public adminTheme = false;
  @Input() public edit = true;
  @Input() set selectedTags(ids: string[]) {
    this._selectedTags = ids ? ids.slice() : [];
  }
  @Output() public onSelected = new EventEmitter<string[]>();
  @ViewChild('tagsslider') tagsSlider: ElementRef;

  private _selectedTags: string[] = [];
  private slider: any;
  private isDown = false;
  private startX: number;
  private scrollLeft: number;

  ngAfterViewInit() {
    this.slider = this.tagsSlider.nativeElement;
  }

  public tagSelected(tag: any): void {
    if (!this.edit) {
      return;
    }
    const clickedTagIndex = this._selectedTags.indexOf(tag.id);

    if (clickedTagIndex > -1) {
      this._selectedTags.splice(clickedTagIndex, 1);
    } else {
      this._selectedTags.push(tag.id);
    }

    this.onSelected.emit(this._selectedTags);
  }

  public isTagSelected(tag): boolean {
    return this._selectedTags.includes(tag.id);
  }

  public onMouseDown(event: MouseEvent) {
    this.isDown = true;
    this.slider.classList.add('active');
    this.startX = event.pageX - this.slider.offsetLeft;
    this.scrollLeft = this.slider.scrollLeft;
  }

  public onMouseLeave() {
    this.isDown = false;
    this.slider.classList.remove('active');
  }

  public onMouseUp() {
    this.isDown = false;
    this.slider.classList.remove('active');
  }

  public onMouseMove(event: MouseEvent) {
    if (!this.isDown) {
      return;
    }
    event.preventDefault();
    const x = event.pageX - this.slider.offsetLeft;
    const walk = (x - this.startX) * 3; // scroll-fast
    this.slider.scrollLeft = this.scrollLeft - walk;
  }
}
