<ng-container *ngIf="loading">
  <app-loader></app-loader>
</ng-container>

<div
  *ngIf="!loading"
  class="brand-profile-container">
  <form [formGroup]="form">
    <!-- Brand name & Details block -->
    <div class="form-block c-mb-9">
      <div class="left-side">
        <h2 class="title">{{ 'adminBrandProfile.nameDetailsTitle' | translate }}</h2>
        <p class="description">{{ 'adminBrandProfile.nameDetailsDescription' | translate }}</p>
      </div>
      <div class="right-side">
        <!-- Name input -->
        <div class="form-field-block">
          <app-input-label
            labelType="required"
            for="name-input"
            text="adminBrandProfile.nameLabel">
          </app-input-label>
          <input
            class="custom-input-text custom-input-text-required"
            formControlName="name"
            id="name-input"
            pInputText
            type="text"
            placeholder="{{ 'adminBrandProfile.nameLabel' | translate }}" />
          <app-input-error-message
            [showMessage]="form.controls.name.invalid && form.controls.name.touched"
            errorMessage="{{ 'adminBrandProfile.nameReq' | translate }}">
          </app-input-error-message>
        </div>

        <!-- Slogan input -->
        <div class="form-field-block c-mb-4">
          <app-input-label
            for="slogan-input"
            text="adminBrandProfile.sloganLabel">
          </app-input-label>
          <input
            class="custom-input-text"
            formControlName="slogan"
            id="slogan-input"
            maxlength="{{ getMaxLengthValue('slogan') }}"
            pInputText
            type="text"
            placeholder="{{ 'adminBrandProfile.sloganLabel' | translate }}" />
        </div>

        <!-- Website input -->
        <div class="form-field-block">
          <app-input-label
            for="website-input"
            text="adminBrandProfile.websiteLabel">
          </app-input-label>
          <div class="p-inputgroup custom-input-group">
            <span class="p-inputgroup-addon">URL</span>
            <input
              formControlName="website"
              id="website-input"
              pInputText
              type="text"
              placeholder="{{ 'adminBrandProfile.websiteLabel' | translate }}" />
          </div>
          <app-input-error-message
            [showMessage]="form.controls.website.invalid && form.controls.website.touched"
            errorMessage="{{ 'adminBrandProfile.invalidWebsite' | translate }}">
          </app-input-error-message>
        </div>

        <!-- Email input -->
        <div class="form-field-block">
          <app-input-label
            for="email-input"
            text="adminBrandProfile.emailLabel">
          </app-input-label>
          <input
            class="custom-input-text custom-input-text-required"
            formControlName="email"
            id="email-input"
            pInputText
            type="text"
            placeholder="{{ 'adminBrandProfile.emailLabel' | translate }}" />
          <app-input-error-message
            [showMessage]="form.controls.email.invalid && form.controls.email.touched"
            errorMessage="{{ 'adminBrandProfile.invalidEmail' | translate }}">
          </app-input-error-message>
        </div>

        <!-- User input -->
        <div class="form-field-block c-mb-4">
          <app-input-label
            for="user-input"
            text="adminBrandProfile.userLabel">
          </app-input-label>
          <div class="spinner-and-element-block">
            <i
              *ngIf="usersLoading"
              class="pi pi-spin pi-spinner icon-spinner">
            </i>
            <p-dropdown
              class="custom-dropdown base-element"
              panelStyleClass="custom-dropdown-panel"
              [filter]="true"
              filterBy="name"
              [options]="users"
              [virtualScroll]="true"
              [virtualScrollItemSize]="virtualScrollItemSize"
              optionValue="id"
              formControlName="user"
              (onClick)="loadUsers()"
              inputId="user-input"
              placeholder="{{ 'adminBrandProfile.userLabel' | translate }}">
              <ng-template
                let-user
                pTemplate="item"
                >{{ user.firstName }} {{ user.lastName }}</ng-template
              >
              <ng-template
                let-user
                pTemplate="selectedItem"
                >{{ user.firstName }} {{ user.lastName }}</ng-template
              >
            </p-dropdown>
          </div>
        </div>

        <!-- Link input -->
        <div class="form-field-block c-mb-4">
          <app-input-label
            labelType="required"
            for="link-input"
            text="adminBrandProfile.linkSuffixLabel">
          </app-input-label>
          <div class="p-inputgroup custom-input-group">
            <span class="p-inputgroup-addon">{{ linkPrefixLabel }}</span>
            <input
              formControlName="link"
              id="link-input"
              pInputText
              type="text"
              placeholder="{{ 'adminBrandProfile.linkSuffixLabel' | translate }}" />
            <i
              *ngIf="form.controls.link.value.length"
              class="far fa-copy"
              [pTooltip]="!copyIsClicked ? tooltipText : null"
              tooltipPosition="top"
              tooltipStyleClass="custom-tooltip"
              (mouseleave)="copyIsClicked = false"
              (click)="copyToClipboard()">
            </i>
            <div
              *ngIf="copyIsClicked"
              class="tooltip-copied">
              {{ tooltipText }}
            </div>
          </div>
          <app-input-error-message
            [showMessage]="form.controls.link.invalid && form.controls.link.touched"
            errorMessage="{{ linkErrorText | translate }}">
          </app-input-error-message>
        </div>

        <!-- Accept terms input -->
        <div class="form-field-input-checkbox-block c-mb-4">
          <p-checkbox
            class="custom-input-checkbox-bg"
            binary="true"
            formControlName="acceptTerms"
            inputId="accept-terms-input">
          </p-checkbox>
          <app-input-label
            labelType="switch-checkbox"
            for="accept-terms-input"
            text="adminBrandProfile.termsLabel">
          </app-input-label>
        </div>

        <!-- Accept news input -->
        <div class="form-field-input-checkbox-block c-mb-4">
          <p-checkbox
            class="custom-input-checkbox-bg"
            binary="true"
            formControlName="acceptNews"
            inputId="accept-news-input">
          </p-checkbox>
          <app-input-label
            labelType="switch-checkbox"
            for="accept-news-input"
            text="adminBrandProfile.newsLabel">
          </app-input-label>
        </div>

        <!-- Featured brand input -->
        <div class="form-field-input-switch-block">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="featuredBrand"
            inputId="featured-brand-input">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="featured-brand-input"
            text="adminBrandProfile.featuredLabel">
          </app-input-label>
        </div>
      </div>
    </div>

    <p-divider styleClass="custom-divider-horizontal"></p-divider>

    <!-- Description & Tags block -->
    <div class="form-block c-my-9">
      <div class="left-side">
        <h2 class="title">{{ 'adminBrandProfile.descTagsTitle' | translate }}</h2>
        <p class="description">{{ 'adminBrandProfile.descTagsDescription' | translate }}</p>
      </div>
      <div class="right-side">
        <!-- Description textarea -->
        <div class="form-field-block c-mb-4">
          <app-input-label text="adminBrandProfile.descriptionLabel"></app-input-label>
          <p-editor
            styleClass="custom-editor"
            formControlName="description"
            placeholder="{{ 'adminBrandProfile.descriptionLabel' | translate }}">
          </p-editor>
        </div>

        <!-- Tags multi select -->
        <div class="form-field-block">
          <app-input-label text="adminBrandProfile.tagsLabel"></app-input-label>
          <app-loader
            *ngIf="tagsLoading"
            class="brand-tags-loading-container"></app-loader>
          <app-tags-multi-list
            *ngIf="tags"
            [possibleTags]="tags"
            [selectedTags]="form.controls.tags.value"
            [adminTheme]="true"
            (onSelected)="selectTags($event)">
          </app-tags-multi-list>
        </div>
      </div>
    </div>

    <p-divider styleClass="custom-divider-horizontal"></p-divider>

    <!-- Design block -->
    <div class="form-block c-my-9">
      <div class="left-side">
        <h2 class="title">{{ 'adminBrandProfile.designTitle' | translate }}</h2>
        <p class="description">{{ 'adminBrandProfile.designDescription' | translate }}</p>
      </div>
      <div class="right-side">
        <!-- Logo Image -->
        <div class="form-field-block c-mb-4">
          <app-logo-media-form
            titleMediaForm="adminBrandProfile.iconImageTitle"
            description="adminBrandProfile.iconImageDesc"
            btnConfirm="upload"
            btnCancel="clear"
            [imgValue]="form.controls.logo.value"
            (changeValue)="setLogoValue($event)">
          </app-logo-media-form>
        </div>

        <!-- Banner image -->
        <div class="form-field-block">
          <app-cover-picture-media-form
            titleMediaForm="adminBrandProfile.coverTitle"
            description="adminBrandProfile.coverDesc"
            btnConfirm="upload"
            btnCancel="clear"
            [imgValue]="form.controls.banner.value"
            (changeValue)="setBannerValue($event)">
          </app-cover-picture-media-form>
        </div>
      </div>
    </div>

    <p-divider styleClass="custom-divider-horizontal"></p-divider>

    <!-- Address block -->
    <div class="form-block c-mt-9 c-mb-9">
      <div class="left-side">
        <h2 class="title">{{ 'adminBrandProfile.addressTitle' | translate }}</h2>
        <p class="description">{{ 'adminBrandProfile.addressDescription' | translate }}</p>
      </div>
      <div class="right-side">
        <!-- Street input -->
        <div class="form-field-block c-mb-4">
          <app-input-label
            for="street-input"
            text="adminBrandProfile.street">
          </app-input-label>
          <input
            class="custom-input-text"
            formControlName="street"
            id="street-input"
            pInputText
            type="text"
            placeholder="{{ 'adminBrandProfile.streetPlaceholder' | translate }}" />
        </div>

        <!-- Address Line 2 input -->
        <div class="form-field-block c-mb-4">
          <app-input-label
            for="address-line-second-input"
            text="adminBrandProfile.secondLineAddress">
          </app-input-label>
          <input
            class="custom-input-text"
            formControlName="addressLineSecond"
            id="address-line-second-input"
            pInputText
            type="text"
            placeholder="{{ 'adminBrandProfile.secondLineAddressPlaceholder' | translate }}" />
        </div>

        <div class="two-elements-block c-mb-4">
          <!-- Zip code input -->
          <div>
            <app-input-label
              for="zip-code-input"
              text="adminBrandProfile.zipCode">
            </app-input-label>
            <input
              class="custom-input-text"
              formControlName="zipCode"
              id="zip-code-input"
              pInputText
              type="text"
              appInputOnlyPostalCodeNumberValues
              placeholder="{{ 'adminBrandProfile.zipCodePlaceholder' | translate }}" />
          </div>

          <!-- City input -->
          <div>
            <app-input-label
              for="city-input"
              text="adminBrandProfile.city">
            </app-input-label>
            <input
              class="custom-input-text"
              formControlName="city"
              id="city-input"
              pInputText
              type="text"
              placeholder="{{ 'adminBrandProfile.cityPlaceholder' | translate }}" />
          </div>
        </div>

        <!-- Country input -->
        <div class="form-field-block c-mb-4">
          <app-input-label
            for="country-input"
            text="adminBrandProfile.country">
          </app-input-label>
          <p-dropdown
            class="custom-dropdown"
            panelStyleClass="custom-dropdown-panel"
            [filter]="true"
            [options]="countriesTranslated"
            [virtualScroll]="true"
            [virtualScrollItemSize]="virtualScrollItemSize"
            optionLabel="name"
            optionValue="code"
            formControlName="country"
            inputId="country-input"
            placeholder="{{ 'adminBrandProfile.countryPlaceholder' | translate }}">
          </p-dropdown>
        </div>
      </div>
    </div>

    <p-divider styleClass="custom-divider-horizontal"></p-divider>

    <!-- Social Media Accounts block -->
    <div class="form-block c-mt-9 c-mb-4">
      <div class="left-side">
        <h2 class="title">{{ 'adminBrandProfile.socialTitle' | translate }}</h2>
        <p class="description">{{ 'adminBrandProfile.socialDescription' | translate }}</p>
      </div>
      <div class="right-side">
        <!-- Facebook input -->
        <div class="form-field-block">
          <app-input-label
            for="facebook-input"
            text="adminBrandProfile.facebook">
          </app-input-label>
          <div class="p-inputgroup custom-input-group">
            <span class="p-inputgroup-addon">URL</span>
            <input
              class="custom-input-text custom-input-text-required"
              formControlName="facebook"
              id="facebook-input"
              pInputText
              type="text"
              placeholder="{{ 'adminBrandProfile.facebook' | translate }}" />
          </div>
          <app-input-error-message
            [showMessage]="form.controls.facebook.invalid && form.controls.facebook.touched"
            errorMessage="{{ 'adminBrandProfile.facebookInvalidLink' | translate }}">
          </app-input-error-message>
        </div>

        <!-- Instagram input -->
        <div class="form-field-block">
          <app-input-label
            for="instagram-input"
            text="adminBrandProfile.instagram">
          </app-input-label>
          <div class="p-inputgroup custom-input-group">
            <span class="p-inputgroup-addon">URL</span>
            <input
              class="custom-input-text custom-input-text-required"
              formControlName="instagram"
              id="instagram-input"
              pInputText
              type="text"
              placeholder="{{ 'adminBrandProfile.instagram' | translate }}" />
          </div>
          <app-input-error-message
            [showMessage]="form.controls.instagram.invalid && form.controls.instagram.touched"
            errorMessage="{{ 'adminBrandProfile.instagramInvalidLink' | translate }}">
          </app-input-error-message>
        </div>

        <!-- Youtube input -->
        <div class="form-field-block">
          <app-input-label
            for="youtube-input"
            text="adminBrandProfile.youtube">
          </app-input-label>
          <div class="p-inputgroup custom-input-group">
            <span class="p-inputgroup-addon">URL</span>
            <input
              class="custom-input-text custom-input-text-required"
              formControlName="youtube"
              id="youtube-input"
              pInputText
              type="text"
              placeholder="{{ 'adminBrandProfile.youtube' | translate }}" />
          </div>
          <app-input-error-message
            [showMessage]="form.controls.youtube.invalid && form.controls.youtube.touched"
            errorMessage="{{ 'adminBrandProfile.youtubeInvalidLink' | translate }}">
          </app-input-error-message>
        </div>

        <!-- Linkedin input -->
        <div class="form-field-block">
          <app-input-label
            for="linkedin-input"
            text="adminBrandProfile.linkedin">
          </app-input-label>
          <div class="p-inputgroup custom-input-group">
            <span class="p-inputgroup-addon">URL</span>
            <input
              class="custom-input-text custom-input-text-required"
              formControlName="linkedin"
              id="linkedin-input"
              pInputText
              type="text"
              placeholder="{{ 'adminBrandProfile.linkedin' | translate }}" />
          </div>
          <app-input-error-message
            [showMessage]="form.controls.linkedin.invalid && form.controls.linkedin.touched"
            errorMessage="{{ 'adminBrandProfile.linkedinInvalidLink' | translate }}">
          </app-input-error-message>
        </div>

        <!-- Twitter input -->
        <div class="form-field-block">
          <app-input-label
            for="twitter-input"
            text="adminBrandProfile.twitter">
          </app-input-label>
          <div class="p-inputgroup custom-input-group">
            <span class="p-inputgroup-addon">URL</span>
            <input
              class="custom-input-text custom-input-text-required"
              formControlName="twitter"
              id="twitter-input"
              pInputText
              type="text"
              placeholder="{{ 'adminBrandProfile.twitter' | translate }}" />
          </div>

          <app-input-error-message
            [showMessage]="form.controls.twitter.invalid && form.controls.twitter.touched"
            errorMessage="{{ 'adminBrandProfile.twitterInvalidLink' | translate }}">
          </app-input-error-message>
        </div>

        <!-- Xing input -->
        <div class="form-field-block">
          <app-input-label
            for="xing-input"
            text="adminBrandProfile.xing">
          </app-input-label>
          <div class="p-inputgroup custom-input-group">
            <span class="p-inputgroup-addon">URL</span>
            <input
              class="custom-input-text custom-input-text-required"
              formControlName="xing"
              id="xing-input"
              pInputText
              type="text"
              placeholder="{{ 'adminBrandProfile.xing' | translate }}" />
          </div>
          <app-input-error-message
            [showMessage]="form.controls.xing.invalid && form.controls.xing.touched"
            errorMessage="{{ 'adminBrandProfile.xingInvalidLink' | translate }}">
          </app-input-error-message>
        </div>

        <!-- Calendly input -->
        <div class="form-field-block">
          <app-input-label
            for="calendly-input"
            text="adminBrandProfile.calendly">
          </app-input-label>
          <div class="p-inputgroup custom-input-group">
            <span class="p-inputgroup-addon">URL</span>
            <input
              class="custom-input-text custom-input-text-required"
              formControlName="calendly"
              id="calendly-input"
              pInputText
              type="text"
              placeholder="{{ 'adminBrandProfile.calendly' | translate }}" />
          </div>
          <app-input-error-message
            [showMessage]="form.controls.calendly.invalid && form.controls.calendly.touched"
            errorMessage="{{ 'adminBrandProfile.calendlyInvalidLink' | translate }}">
          </app-input-error-message>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="save-discard-actions-container">
  <app-save-discard-actions
    [isShow]="canUpdate"
    [isLoading]="isUpdating"
    (discard)="onDiscard()"
    (confirm)="onConfirm()">
  </app-save-discard-actions>
</div>
