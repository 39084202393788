import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-course-instructor-details',
  templateUrl: './course-instructor-details.component.html',
  styleUrls: ['./course-instructor-details.component.scss'],
})
export class CourseInstructorDetailsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
