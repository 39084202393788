<app-chat-window
  [isOpen]="isChatEnabled && isChatWindowOpen"
  [contextKey]="contextKey"
  [chatConversations]="chatConversations() | async"
  [selectedChatConversationMessages]="selectedChatConversationMessage() | async"
  [unreadMessages]="unreadMessages"
  (sendMessage)="onSendMessage($event)"
  (closeWindow)="onCloseWindow()"
  (chatConversationSelected)="onChatConversationSelected($event)"
  (toggleMessageLike)="onToggleMessageLike($event)"
  (navigateTo)="onNavigateTo($event)"
  (openAppointmentDialog)="onAppointmentDialog($event)">
</app-chat-window>
<app-chat-bubble
  [enabled]="(isChatEnabled && !isMobile) || (isChatEnabled && isMobile && !isChatWindowOpen)"
  [unreadMessages]="unreadMessages"
  [isChatAllowed]="isChatAllowed"
  [tooltipText]="chatBubbleTooltip"
  (click)="toggleWindowVisibilityAction()">
</app-chat-bubble>
