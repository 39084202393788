import { ICountry } from '../../core/models/country.model';

export const countries: ICountry[] = [
  { name: 'countries.afghanistan', code: 'AF' },
  { name: 'countries.albania', code: 'AL' },
  { name: 'countries.algeria', code: 'DZ' },
  { name: 'countries.аmericanSamoa', code: 'AS' },
  { name: 'countries.andorra', code: 'AD' },
  { name: 'countries.angola', code: 'AO' },
  { name: 'countries.anguilla', code: 'AI' },
  { name: 'countries.antarctica', code: 'AQ' },
  { name: 'countries.antiguaAndBarbuda', code: 'AG' },
  { name: 'countries.argentina', code: 'AR' },
  { name: 'countries.armenia', code: 'AM' },
  { name: 'countries.aruba', code: 'AW' },
  { name: 'countries.australia', code: 'AU' },
  { name: 'countries.austria', code: 'AT' },
  { name: 'countries.azerbaijan', code: 'AZ' },
  { name: 'countries.bahamas', code: 'BS' },
  { name: 'countries.bahrain', code: 'BH' },
  { name: 'countries.bangladesh', code: 'BD' },
  { name: 'countries.barbados', code: 'BB' },
  { name: 'countries.belarus', code: 'BY' },
  { name: 'countries.belgium', code: 'BE' },
  { name: 'countries.belize', code: 'BZ' },
  { name: 'countries.benin', code: 'BJ' },
  { name: 'countries.bermuda', code: 'BM' },
  { name: 'countries.bhutan', code: 'BT' },
  { name: 'countries.bolivia', code: 'BO' },
  { name: 'countries.bosniaAndHerzegovina', code: 'BA' },
  { name: 'countries.botswana', code: 'BW' },
  { name: 'countries.bouvetIsland', code: 'BV' },
  { name: 'countries.brazil', code: 'BR' },
  { name: 'countries.britishIndianOceanTerritory', code: 'IO' },
  { name: 'countries.bruneiDarussalam', code: 'BN' },
  { name: 'countries.bulgaria', code: 'BG' },
  { name: 'countries.burkinaFaso', code: 'BF' },
  { name: 'countries.burundi', code: 'BI' },
  { name: 'countries.cambodia', code: 'KH' },
  { name: 'countries.cameroon', code: 'CM' },
  { name: 'countries.canada', code: 'CA' },
  { name: 'countries.capeVerde', code: 'CV' },
  { name: 'countries.caymanIslands', code: 'KY' },
  { name: 'countries.centralAfricanRepublic', code: 'CF' },
  { name: 'countries.chad', code: 'TD' },
  { name: 'countries.chile', code: 'CL' },
  { name: 'countries.china', code: 'CN' },
  { name: 'countries.christmasIsland', code: 'CX' },
  { name: 'countries.cocosIslands', code: 'CC' },
  { name: 'countries.colombia', code: 'CO' },
  { name: 'countries.comoros', code: 'KM' },
  { name: 'countries.congo', code: 'CG' },
  { name: 'countries.cookIslands', code: 'CK' },
  { name: 'countries.costaRica', code: 'CR' },
  { name: `countries.coteD'Ivoire`, code: 'CI' },
  { name: 'countries.croatia', code: 'HR' },
  { name: 'countries.cuba', code: 'CU' },
  { name: 'countries.cyprus', code: 'CY' },
  { name: 'countries.czechRepublic', code: 'CZ' },
  { name: `countries.democraticPeople'sRepublicOfKorea`, code: 'KP' },
  { name: 'countries.democraticRepublicOfTheCongo', code: 'CD' },
  { name: 'countries.denmark', code: 'DK' },
  { name: 'countries.djibouti', code: 'DJ' },
  { name: 'countries.dominica', code: 'DM' },
  { name: 'countries.dominicanRepublic', code: 'DO' },
  { name: 'countries.ecuador', code: 'EC' },
  { name: 'countries.egypt', code: 'EG' },
  { name: 'countries.elSalvador', code: 'SV' },
  { name: 'countries.equatorialGuinea', code: 'GQ' },
  { name: 'countries.eritrea', code: 'ER' },
  { name: 'countries.estonia', code: 'EE' },
  { name: 'countries.ethiopia', code: 'ET' },
  { name: 'countries.falklandIslandsMalvinas', code: 'FK' },
  { name: 'countries.faroeIslands', code: 'FO' },
  { name: 'countries.federatedStatesOfMicronesia', code: 'FM' },
  { name: 'countries.fiji', code: 'FJ' },
  { name: 'countries.finland', code: 'FI' },
  { name: 'countries.france', code: 'FR' },
  { name: 'countries.frenchGuiana', code: 'GF' },
  { name: 'countries.frenchPolynesia', code: 'PF' },
  { name: 'countries.frenchSouthernTerritories', code: 'TF' },
  { name: 'countries.gabon', code: 'GA' },
  { name: 'countries.gambia', code: 'GM' },
  { name: 'countries.georgia', code: 'GE' },
  { name: 'countries.germany', code: 'DE' },
  { name: 'countries.ghana', code: 'GH' },
  { name: 'countries.gibraltar', code: 'GI' },
  { name: 'countries.greece', code: 'GR' },
  { name: 'countries.greenland', code: 'GL' },
  { name: 'countries.grenada', code: 'GD' },
  { name: 'countries.guadeloupe', code: 'GP' },
  { name: 'countries.guam', code: 'GU' },
  { name: 'countries.guatemala', code: 'GT' },
  { name: 'countries.guernsey', code: 'GG' },
  { name: 'countries.guinea', code: 'GN' },
  { name: 'countries.guineaBissau', code: 'GW' },
  { name: 'countries.guyana', code: 'GY' },
  { name: 'countries.haiti', code: 'HT' },
  { name: 'countries.heardIslandAndMcdonaldIslands', code: 'HM' },
  { name: 'countries.holySeeVaticanCityState', code: 'VA' },
  { name: 'countries.honduras', code: 'HN' },
  { name: 'countries.hongKong', code: 'HK' },
  { name: 'countries.hungary', code: 'HU' },
  { name: 'countries.iceland', code: 'IS' },
  { name: 'countries.india', code: 'IN' },
  { name: 'countries.indonesia', code: 'ID' },
  { name: 'countries.islamicRepublicOfIran', code: 'IR' },
  { name: 'countries.iraq', code: 'IQ' },
  { name: 'countries.ireland', code: 'IE' },
  { name: 'countries.IsleOfMan', code: 'IM' },
  { name: 'countries.israel', code: 'IL' },
  { name: 'countries.italy', code: 'IT' },
  { name: 'countries.jamaica', code: 'JM' },
  { name: 'countries.japan', code: 'JP' },
  { name: 'countries.jersey', code: 'JE' },
  { name: 'countries.jordan', code: 'JO' },
  { name: 'countries.kazakhstan', code: 'KZ' },
  { name: 'countries.kenya', code: 'KE' },
  { name: 'countries.kiribati', code: 'KI' },
  { name: 'countries.kuwait', code: 'KW' },
  { name: 'countries.kyrgyzstan', code: 'KG' },
  { name: `countries.laoPeople'sDemocraticRepublic`, code: 'LA' },
  { name: 'countries.latvia', code: 'LV' },
  { name: 'countries.lebanon', code: 'LB' },
  { name: 'countries.lesotho', code: 'LS' },
  { name: 'countries.liberia', code: 'LR' },
  { name: 'countries.libyanArabJamahiriya', code: 'LY' },
  { name: 'countries.liechtenstein', code: 'LI' },
  { name: 'countries.lithuania', code: 'LT' },
  { name: 'countries.luxembourg', code: 'LU' },
  { name: 'countries.macao', code: 'MO' },
  { name: 'countries.madagascar', code: 'MG' },
  { name: 'countries.malawi', code: 'MW' },
  { name: 'countries.malaysia', code: 'MY' },
  { name: 'countries.maldives', code: 'MV' },
  { name: 'countries.mali', code: 'ML' },
  { name: 'countries.malta', code: 'MT' },
  { name: 'countries.marshallIslands', code: 'MH' },
  { name: 'countries.martinique', code: 'MQ' },
  { name: 'countries.mauritania', code: 'MR' },
  { name: 'countries.mauritius', code: 'MU' },
  { name: 'countries.mayotte', code: 'YT' },
  { name: 'countries.mexico', code: 'MX' },
  { name: 'countries.monaco', code: 'MC' },
  { name: 'countries.mongolia', code: 'MN' },
  { name: 'countries.montserrat', code: 'MS' },
  { name: 'countries.morocco', code: 'MA' },
  { name: 'countries.mozambique', code: 'MZ' },
  { name: 'countries.myanmar', code: 'MM' },
  { name: 'countries.namibia', code: 'NA' },
  { name: 'countries.nauru', code: 'NR' },
  { name: 'countries.nepal', code: 'NP' },
  { name: 'countries.netherlands', code: 'NL' },
  { name: 'countries.netherlandsAntilles', code: 'AN' },
  { name: 'countries.newCaledonia', code: 'NC' },
  { name: 'countries.newZealand', code: 'NZ' },
  { name: 'countries.nicaragua', code: 'NI' },
  { name: 'countries.niger', code: 'NE' },
  { name: 'countries.nigeria', code: 'NG' },
  { name: 'countries.niue', code: 'NU' },
  { name: 'countries.norfolkIsland', code: 'NF' },
  { name: 'countries.northernMarianaIslands', code: 'MP' },
  { name: 'countries.norway', code: 'NO' },
  { name: 'countries.oman', code: 'OM' },
  { name: 'countries.pakistan', code: 'PK' },
  { name: 'countries.palau', code: 'PW' },
  { name: 'countries.palestinianTerritoryOccupied', code: 'PS' },
  { name: 'countries.panama', code: 'PA' },
  { name: 'countries.papuaNewGuinea', code: 'PG' },
  { name: 'countries.paraguay', code: 'PY' },
  { name: 'countries.peru', code: 'PE' },
  { name: 'countries.philippines', code: 'PH' },
  { name: 'countries.pitcairn', code: 'PN' },
  { name: 'countries.poland', code: 'PL' },
  { name: 'countries.portugal', code: 'PT' },
  { name: 'countries.puertoRico', code: 'PR' },
  { name: 'countries.qatar', code: 'QA' },
  { name: 'countries.republicOfKorea', code: 'KR' },
  { name: 'countries.republicOfMoldova', code: 'MD' },
  { name: 'countries.reunion', code: 'RE' },
  { name: 'countries.romania', code: 'RO' },
  { name: 'countries.russianFederation', code: 'RU' },
  { name: 'countries.rwanda', code: 'RW' },
  { name: 'countries.saintHelena', code: 'SH' },
  { name: 'countries.saintKittsAndNevis', code: 'KN' },
  { name: 'countries.saintLucia', code: 'LC' },
  { name: 'countries.saintPierreAndMiquelon', code: 'PM' },
  { name: 'countries.saintVincentAndTheGrenadines', code: 'VC' },
  { name: 'countries.samoa', code: 'WS' },
  { name: 'countries.sanMarino', code: 'SM' },
  { name: 'countries.saoTomeAndPrincipe', code: 'ST' },
  { name: 'countries.saudiArabia', code: 'SA' },
  { name: 'countries.senegal', code: 'SN' },
  { name: 'countries.serbiaAndMontenegro', code: 'CS' },
  { name: 'countries.seychelles', code: 'SC' },
  { name: 'countries.sierraLeone', code: 'SL' },
  { name: 'countries.singapore', code: 'SG' },
  { name: 'countries.slovakia', code: 'SK' },
  { name: 'countries.slovenia', code: 'SI' },
  { name: 'countries.solomonIslands', code: 'SB' },
  { name: 'countries.somalia', code: 'SO' },
  { name: 'countries.southAfrica', code: 'ZA' },
  { name: 'countries.southGeorgiaAndTheSouthSandwichIslands', code: 'GS' },
  { name: 'countries.spain', code: 'ES' },
  { name: 'countries.sriLanka', code: 'LK' },
  { name: 'countries.sudan', code: 'SD' },
  { name: 'countries.suriname', code: 'SR' },
  { name: 'countries.svalbardAndJanMayen', code: 'SJ' },
  { name: 'countries.swaziland', code: 'SZ' },
  { name: 'countries.sweden', code: 'SE' },
  { name: 'countries.switzerland', code: 'CH' },
  { name: 'countries.syrianArabRepublic', code: 'SY' },
  { name: 'countries.taiwanProvinceOfChina', code: 'TW' },
  { name: 'countries.tajikistan', code: 'TJ' },
  { name: 'countries.thailand', code: 'TH' },
  { name: 'countries.theFormerYugoslavRepublicOfMacedonia', code: 'MK' },
  { name: 'countries.timorLeste', code: 'TL' },
  { name: 'countries.togo', code: 'TG' },
  { name: 'countries.tokelau', code: 'TK' },
  { name: 'countries.tonga', code: 'TO' },
  { name: 'countries.trinidadAndTobago', code: 'TT' },
  { name: 'countries.tunisia', code: 'TN' },
  { name: 'countries.turkey', code: 'TR' },
  { name: 'countries.turkmenistan', code: 'TM' },
  { name: 'countries.turksAndCaicosIslands', code: 'TC' },
  { name: 'countries.tuvalu', code: 'TV' },
  { name: 'countries.uganda', code: 'UG' },
  { name: 'countries.ukraine', code: 'UA' },
  { name: 'countries.unitedArabEmirates', code: 'AE' },
  { name: 'countries.unitedKingdom', code: 'GB' },
  { name: 'countries.unitedRepublicOfTanzania', code: 'TZ' },
  { name: 'countries.unitedStates', code: 'US' },
  { name: 'countries.unitedStatesMinorOutlyingIslands', code: 'UM' },
  { name: 'countries.uruguay', code: 'UY' },
  { name: 'countries.uzbekistan', code: 'UZ' },
  { name: 'countries.vanuatu', code: 'VU' },
  { name: 'countries.venezuela', code: 'VE' },
  { name: 'countries.vietNam', code: 'VN' },
  { name: 'countries.virginIslandsBritish', code: 'VG' },
  { name: 'countries.virginIslandsUs', code: 'VI' },
  { name: 'countries.wallisAndFutuna', code: 'WF' },
  { name: 'countries.westernSahara', code: 'EH' },
  { name: 'countries.yemen', code: 'YE' },
  { name: 'countries.zambia', code: 'ZM' },
  { name: 'countries.zimbabwe', code: 'ZW' },
];
