import { Component, EventEmitter, Input, Output } from '@angular/core';

import { AssetFileTypes } from 'src/app/core/enums';
import { IAsset } from 'src/app/core/models';
import { formatBytes, jpgImg, pdfImg, pngImg, zipImg } from '../../utils';

@Component({
  selector: 'app-asset-file-card',
  templateUrl: './asset-file-card.component.html',
  styleUrls: ['./asset-file-card.component.scss'],
})
export class AssetFileCardComponent {
  @Input() asset: IAsset;
  @Input() fileType: string;
  @Input() fileName: string;
  @Input() fileSize: number;
  @Input() file: File;
  @Output() clickOnCard: EventEmitter<IAsset> = new EventEmitter<IAsset>();
  @Output() clickOnFile: EventEmitter<boolean> = new EventEmitter<boolean>();
  formatBytes = formatBytes;

  get imgUrl(): string {
    switch (this.asset.fileType) {
      case AssetFileTypes.JPG:
        return jpgImg;
      case AssetFileTypes.PDF:
        return pdfImg;
      case AssetFileTypes.PNG:
        return pngImg;
      case AssetFileTypes.ZIP:
        return zipImg;
    }
  }

  get imgUrlByFileType(): string {
    switch (this.fileType) {
      case AssetFileTypes.JPG:
        return jpgImg;
      case AssetFileTypes.PDF:
        return pdfImg;
      case AssetFileTypes.PNG:
        return pngImg;
      case AssetFileTypes.ZIP:
        return zipImg;
    }
  }

  onFileCardClick(): void {
    this.clickOnFile.emit(true);
  }
}
