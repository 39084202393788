<div class="header-container">
  <h1>{{ 'emailMergeTags.title' | translate }}</h1>
  <p>{{ 'emailMergeTags.description' | translate }}</p>
</div>

<div class="c-my-8">
  <p-divider styleClass="custom-divider-horizontal"></p-divider>
</div>

<ng-container *ngIf="loading">
  <app-loader></app-loader>
</ng-container>

<ng-container *ngIf="!loading">
  <div class="body-container">
    <p-accordion
      [multiple]="true"
      styleClass="entities-keys-accordion">
      <p-accordionTab header="{{ 'emailMergeTags.userTitle' | translate }}">
        <div class="fields-list-section">
          <app-entity-keys
            [entity]="'user'"
            [keys]="userKeys"></app-entity-keys>
        </div>
      </p-accordionTab>
      <p-accordionTab header="{{ 'emailMergeTags.brandTitle' | translate }}">
        <div class="fields-list-section">
          <app-entity-keys
            [entity]="'brand'"
            [keys]="brandKeys"></app-entity-keys>
        </div>
      </p-accordionTab>
      <p-accordionTab header="{{ 'emailMergeTags.eventTitle' | translate }}">
        <div class="fields-list-section">
          <app-entity-keys
            [entity]="'event'"
            [keys]="eventKeys"></app-entity-keys>
        </div>
      </p-accordionTab>
      <p-accordionTab header="{{ 'emailMergeTags.courseTitle' | translate }}">
        <div class="fields-list-section">
          <app-entity-keys
            [entity]="'course'"
            [keys]="courseKeys"></app-entity-keys>
        </div>
      </p-accordionTab>
      <p-accordionTab header="{{ 'emailMergeTags.hubTitle' | translate }}">
        <div class="fields-list-section">
          <app-entity-keys
            [entity]="'hub'"
            [keys]="hubKeys"></app-entity-keys>
        </div>
      </p-accordionTab>
      <p-accordionTab header="{{ 'emailMergeTags.appointmentTitle' | translate }}">
        <div class="fields-list-section">
          <app-entity-keys
            [entity]="'appointment'"
            [keys]="appointmentKeys"></app-entity-keys>
        </div>
      </p-accordionTab>
    </p-accordion>
  </div>

  <p-toast>
    <ng-template
      let-message
      pTemplate="message">
      <app-toast
        [severity]="message.severity"
        [summary]="message.summary"
        [detail]="message.detail">
      </app-toast>
    </ng-template>
  </p-toast>
</ng-container>
