import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { DialogService } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

import { SubscriptionsService } from 'src/app/core/services';
import { ConfirmDialogComponent, IAdminTableColumn } from 'src/app/shared';
import { HubsStore } from 'src/app/core/stores';
import { ISubscription } from 'src/app/core/models';
import { ButtonSize, ButtonStyle, ButtonType } from 'src/app/standalone';

@Component({
  selector: 'app-plans-list',
  templateUrl: './plans-list.component.html',
  styleUrls: ['./plans-list.component.scss'],
})
export class PlansListComponent implements OnInit {
  loading = true;
  loadingEntries = true;
  entries: ISubscription[] = [];
  allEntries: ISubscription[] = [];
  scssClassForTable = 'table-border-top-radius show-row-border-bottom';
  columns: IAdminTableColumn[] = [
    { field: 'name', titleTranslationKey: 'adminPlans.tableCells.appPlan', sortable: false },
    {
      field: 'description',
      titleTranslationKey: 'adminPlans.tableCells.description',
      sortable: false,
    },
    {
      field: 'active',
      titleTranslationKey: 'adminPlans.tableCells.status',
      template: 'statusCell',
      sortable: false,
    },
    { field: 'actions', template: 'actionsCell' },
  ];
  buttonSize = ButtonSize;
  buttonType = ButtonType;
  buttonStyle = ButtonStyle;

  constructor(
    private subscriptionService: SubscriptionsService,
    public hubStore: HubsStore,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private messageService: MessageService,
    private translateService: TranslateService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.fetchSubscriptions();
  }

  edit(event: Event, entry: ISubscription): void {
    event.stopPropagation();
    this.router.navigate(
      [`/${this.hubStore.hub ? this.hubStore.useHubUrl : ''}/admin/hub-settings/plans/${entry.id}`],
      {
        relativeTo: this.route,
        state: {
          entry: entry,
        },
      },
    );
  }

  remove(event: Event, entry: ISubscription): void {
    event.stopPropagation();
    const deleteDialogRef = this.dialogService.open(ConfirmDialogComponent, {
      closable: false,
      styleClass: 'confirm-dialog',
      data: {
        titleKey: 'adminPlans.deletePlan',
        confirmBtnKey: 'adminPlans.confirmBtn',
        cancelBtnKey: 'adminPlans.cancelBtn',
        entry: entry,
      },
    });

    deleteDialogRef.onClose.pipe(take(1)).subscribe(async (result: 'cancel' | 'confirm') => {
      if (result === 'confirm') {
        try {
          await this.subscriptionService.deleteSubscriptionById(entry.id);

          this.messageService.add({
            severity: 'success',
            summary: this.translateService.instant('success'),
            detail: this.translateService.instant('adminPlans.planSuccessfullyDeleted'),
            styleClass: 'custom-toast',
          });
          this.fetchSubscriptions();
        } catch (error) {
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('success'),
            detail: this.translateService.instant('adminPlans.planDeleteFailed'),
            styleClass: 'custom-toast',
          });
          this.fetchSubscriptions();
        }
      }
    });
  }

  onRowReorder(event): void {
    const updatedEntries = event;

    updatedEntries.forEach((entry, index) => {
      if (entry) {
        entry.orderIndex = index;
      }
    });

    this.entries = updatedEntries;

    this.updateFirestore();
  }

  onRowClicked(id: string): void {
    this.router.navigate([
      `/${this.hubStore.hub ? this.hubStore.useHubUrl : ''}/admin/hub-settings/plans/${id}`,
    ]);
  }

  private async updateFirestore(): Promise<void> {
    const promises = this.entries.map((entry) =>
      this.subscriptionService.updateOrderIndex(entry.id, entry.orderIndex),
    );
    await Promise.all(promises);
  }

  private async fetchSubscriptions(): Promise<void> {
    this.entries = await this.subscriptionService.getByHubId(this.hubStore.hubId);
    this.loading = false;
    this.loadingEntries = false;
  }
}
