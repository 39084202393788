<div class="footer-navigation-container">
  <div [ngClass]="{ 'authorised-user-style': !usersStore.user }">
    <div
      *ngIf="privacySettingsPages?.length"
      class="footer-menu-container">
      <!-- Imprint -->
      <ng-container *ngIf="isPrivacyPageEnabled(privacyPageTypes.IMPRINT)">
        <span
          *ngIf="!hasExternalUrl(privacyPageTypes.IMPRINT)"
          routerLink="/imprint">
          {{ 'footerNavigation.imprint_btn' | translate }}
        </span>
        <span
          *ngIf="hasExternalUrl(privacyPageTypes.IMPRINT)"
          (click)="onPrivacyPageClick(privacyPageTypes.IMPRINT)">
          {{ 'footerNavigation.imprint_btn' | translate }}
        </span>
      </ng-container>

      <!-- Conditions of participation -->
      <ng-container *ngIf="isPrivacyPageEnabled(privacyPageTypes.CONDITIONS_OF_PARTICIPATION)">
        <span
          *ngIf="!hasExternalUrl(privacyPageTypes.CONDITIONS_OF_PARTICIPATION)"
          routerLink="/conditions-of-participation">
          {{ 'footerNavigation.conditions_participation_btn' | translate }}
        </span>
        <span
          *ngIf="hasExternalUrl(privacyPageTypes.CONDITIONS_OF_PARTICIPATION)"
          (click)="onPrivacyPageClick(privacyPageTypes.CONDITIONS_OF_PARTICIPATION)">
          {{ 'footerNavigation.conditions_participation_btn' | translate }}
        </span>
      </ng-container>

      <!-- Data Protection -->
      <ng-container *ngIf="isPrivacyPageEnabled(privacyPageTypes.DATA_PROTECTION)">
        <span
          *ngIf="!hasExternalUrl(privacyPageTypes.DATA_PROTECTION)"
          routerLink="/data-protection">
          {{ 'footerNavigation.data_protect_btn' | translate }}
        </span>
        <span
          *ngIf="hasExternalUrl(privacyPageTypes.DATA_PROTECTION)"
          (click)="onPrivacyPageClick(privacyPageTypes.DATA_PROTECTION)">
          {{ 'footerNavigation.data_protect_btn' | translate }}
        </span>
      </ng-container>

      <!-- Terms of conditions -->
      <ng-container *ngIf="isPrivacyPageEnabled(privacyPageTypes.TERMS_AND_CONDITIONS)">
        <span
          *ngIf="!hasExternalUrl(privacyPageTypes.TERMS_AND_CONDITIONS)"
          routerLink="/terms-of-conditions">
          {{ 'footerNavigation.terms_conditions_btn' | translate }}
        </span>
        <span
          *ngIf="hasExternalUrl(privacyPageTypes.TERMS_AND_CONDITIONS)"
          (click)="onPrivacyPageClick(privacyPageTypes.TERMS_AND_CONDITIONS)">
          {{ 'footerNavigation.terms_conditions_btn' | translate }}
        </span>
      </ng-container>

      <!-- Terms of use -->
      <ng-container *ngIf="isPrivacyPageEnabled(privacyPageTypes.TERMS_OF_USE)">
        <span
          *ngIf="!hasExternalUrl(privacyPageTypes.TERMS_OF_USE)"
          routerLink="/terms-of-use">
          {{ 'footerNavigation.terms_use_btn' | translate }}
        </span>
        <span
          *ngIf="hasExternalUrl(privacyPageTypes.TERMS_OF_USE)"
          (click)="onPrivacyPageClick(privacyPageTypes.TERMS_OF_USE)">
          {{ 'footerNavigation.terms_use_btn' | translate }}
        </span>
      </ng-container>

      <ng-container *ngIf="environment.showCookieManager">
        <span (click)="openCookiesManager()">{{
          'footerNavigation.cookiesManager' | translate
        }}</span>
      </ng-container>
    </div>
    <div class="footer-info-container">
      <span>Copyright © {{ currentYear }} {{ copyright }}</span>
    </div>
  </div>
  <div
    class="lang-theme-selectors"
    [ngClass]="{ 'authorised-user-style': !usersStore.user }">
    <form class="app-form">
      <!-- TODO: theme switcher uncomment during implementation -->
      <!-- <span *ngIf="isCurrentEnvStagingOrLocal" class="theme-selector">
        <i class="pi pi-sun"></i>
        <p-inputSwitch
          (onChange)="setTheme($event.checked)"
          [(ngModel)]="isDarkThemeActive"
          name="theme">
        </p-inputSwitch>
      </span> -->
      <span *ngIf="(isAuthenticated$ | async) === false">
        <p-dropdown
          class="custom-dropdown"
          panelStyleClass="custom-dropdown-panel"
          [(ngModel)]="selectedLanguage"
          [options]="selectLanguages"
          name="language"
          optionLabel="name"
          appendTo="body"
          (onChange)="setLanguage($event.value)">
          <ng-template pTemplate="selectedItem">
            <span
              class="flag-icon flag-icon-squared flag-icon-circle flag-icon-{{
                selectedLanguage.code === 'en' ? 'gb' : selectedLanguage.code
              }}">
            </span>
            {{ 'languages.' + selectedLanguage.code | translate }}
          </ng-template>
          <ng-template
            let-lang
            pTemplate="item">
            <span
              class="flag-icon flag-icon-squared flag-icon-circle flag-icon-{{
                lang.code === 'en' ? 'gb' : lang.code
              }}">
            </span>
            {{ 'languages.' + lang.code | translate }}
          </ng-template>
        </p-dropdown>
      </span>
    </form>
  </div>
</div>
