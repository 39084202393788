import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { merge, Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { ICountry, IEventRegistrationField } from 'src/app/core/models';
import { UsersStore } from 'src/app/core/stores';
import {
  Agreement,
  TravelDocument,
  parseToMoment,
  UsersMaxLengthValidators,
  Salutations,
  ClothingSizes,
} from 'src/app/shared';
import { VIRTUAL_SCROLL_ITEM_SIZE, countries, isMobile } from 'src/app/core/utils';
import { AreaOfActivity, CompanySize, Industry, OptionalPosition } from 'src/app/core/enums';

@Component({
  selector: 'app-form-group',
  templateUrl: './form-group.component.html',
  styleUrls: ['./form-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormGroupComponent implements OnInit, OnDestroy {
  @Input() formFields: IEventRegistrationField[];
  @Output() currentForm: EventEmitter<UntypedFormGroup> = new EventEmitter<UntypedFormGroup>();

  form: UntypedFormGroup;
  countriesArray: ICountry[];
  travelDocumentTypes: string[] = Object.values(TravelDocument);
  dateForValidation: Date = new Date();
  vegetarianTypes: string[] = Object.values(Agreement);
  salutationTypes: string[] = Object.values(Salutations);
  clothingSizes: string[] = Object.values(ClothingSizes);
  industryTypes: string[] = Object.values(Industry);
  subIndustryTypes: string[] = [];
  areaOfActivityTypes: string[] = Object.values(AreaOfActivity);
  optionalPositionTypes: string[] = Object.values(OptionalPosition);
  companySizeTypes: string[] = Object.values(CompanySize);
  formFieldsForShowing: IEventRegistrationField[];
  arrayOfTooltipFields = [
    'street',
    'postcode',
    'city',
    'country',
    'emergencyContactName',
    'clothingSize',
    'shoeSize',
  ];
  isMobile = isMobile();
  virtualScrollItemSize: number = VIRTUAL_SCROLL_ITEM_SIZE;

  private unsubscribe$: Subject<void> = new Subject<void>();

  get formControls(): string[] {
    return Object.keys(this.form.controls);
  }

  constructor(
    private translateService: TranslateService,
    private usersStore: UsersStore,
  ) {}

  ngOnInit(): void {
    this.formFieldsForShowing = this.formFields.filter(
      (formField: IEventRegistrationField) => formField.isVisible,
    );
    this.form = new UntypedFormGroup({});
    this.createForm();
    this.countriesArray = [
      ...countries.map((country: ICountry) => {
        return {
          name: this.translateService.instant(country.name),
          code: country.code,
        };
      }),
    ];

    merge(this.form.valueChanges, this.form.statusChanges)
      .pipe(
        tap(() => {
          this.currentForm.emit(this.form);
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();

    this.translateService.onLangChange
      .pipe(
        tap(() => {
          const currentCountry = countries.find(
            (country: ICountry) => country.code === this.form.controls.country.value.code,
          );
          const currentNationality = countries.find(
            (country: ICountry) => country.code === this.form.controls.nationality.value.code,
          );
          this.form.controls.country.patchValue({
            name: this.translateService.instant(currentCountry.name),
            code: currentCountry.code,
          });
          this.form.controls.nationality.patchValue({
            name: this.translateService.instant(currentNationality.name),
            code: currentNationality.code,
          });
          this.countriesArray = [
            ...countries.map((country) => {
              return {
                name: this.translateService.instant(country.name),
                code: country.code,
              };
            }),
          ];
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();

    if (this.form.controls?.industry && this.form.controls?.subIndustry) {
      this.getSubIndustries(this.form.controls.industry.value);
      !this.form.controls.industry.value ? this.form.controls.subIndustry.disable() : null;
      this.form.controls.industry.valueChanges
        .pipe(
          debounceTime(300),
          tap((industry: Industry) => {
            this.getSubIndustries(industry);
            this.form.controls.subIndustry.enable();
          }),
          takeUntil(this.unsubscribe$),
        )
        .subscribe();
    }
  }

  private getSubIndustries(industry: Industry): void {
    this.subIndustryTypes = this.usersStore.getUserSubIndustries(industry);
  }

  private createForm(): void {
    this.formFields.forEach((field: IEventRegistrationField) => {
      if (field.isVisible && field.fieldName !== 'divider') {
        const validators = field.isRequired ? this.getValidators(field) : null;
        this.form.addControl(
          field.fieldName,
          new UntypedFormControl(this.getFormFieldValue(field.fieldName), validators),
        );
      }
    });
    this.currentForm.emit(this.form);
  }

  private getFormFieldValue(fieldName: string): ICountry | Date {
    switch (fieldName) {
      case 'country':
        const neededCountry: ICountry = countries.find(
          (country: ICountry) => country.name === this.usersStore.user?.country,
        );
        return !!neededCountry
          ? { name: this.translateService.instant(neededCountry.name), code: neededCountry.code }
          : null;
      case 'nationality':
        const neededNationality: ICountry = countries.find(
          (country: ICountry) => country.name === this.usersStore.user?.nationality,
        );
        return !!neededNationality
          ? {
              name: this.translateService.instant(neededNationality.name),
              code: neededNationality.code,
            }
          : null;
      case 'dateOfExpiry':
        return !!this.usersStore.user?.dateOfExpiry
          ? parseToMoment(this.usersStore.user.dateOfExpiry).toDate()
          : null;
      case 'dateOfIssue':
        return !!this.usersStore.user?.dateOfIssue
          ? parseToMoment(this.usersStore.user.dateOfIssue).toDate()
          : null;
      case 'birthDate':
        return !!this.usersStore.user?.birthDate
          ? parseToMoment(this.usersStore.user.birthDate).toDate()
          : null;
      default:
        return this.usersStore.user[fieldName];
    }
  }

  private getValidators(field: IEventRegistrationField): Validators | Validators[] {
    let validators = [];
    field.isRequired ? validators.push(Validators.required) : null;

    switch (field.fieldName) {
      case 'email':
        validators.push(Validators.required, Validators.email);
        return validators;
      case 'company':
        validators.push(Validators.maxLength(UsersMaxLengthValidators.company.valueOf()));
        return validators;
      case 'street':
        validators.push(Validators.maxLength(UsersMaxLengthValidators.street.valueOf()));
        return validators;
      case 'department':
        validators.push(Validators.maxLength(UsersMaxLengthValidators.department.valueOf()));
        return validators;
      case 'postcode':
        validators.push(Validators.maxLength(UsersMaxLengthValidators.postcode.valueOf()));
        return validators;
      default:
        return validators;
    }
  }

  getMaxLengthValue(formControl): number {
    return Number(UsersMaxLengthValidators[formControl]);
  }

  getLabel(formControlName: string): string {
    return this.translateService.instant(`formGroup.${formControlName}`);
  }

  getPlaceholder(formControlName: string): string {
    return this.translateService.instant(`formGroupPlaceholders.${formControlName}`);
  }

  getTooltip(formControlName: string): string {
    return this.translateService.instant(`formGroupTooltips.${formControlName}`);
  }

  isTooltipRequired(formControlName: string): boolean {
    return this.arrayOfTooltipFields.includes(formControlName);
  }

  getLabelType(formField: IEventRegistrationField): string {
    if (formField.isRequired && this.isTooltipRequired(formField.fieldName)) {
      return 'tooltip-required';
    } else if (formField.isRequired && !this.isTooltipRequired(formField.fieldName)) {
      return 'required';
    } else if (!formField.isRequired && this.isTooltipRequired(formField.fieldName)) {
      return 'tooltip';
    }
    return 'usual';
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
