<div class="user-ticket-card">
  <div class="top-row">
    <image
      [src]="user?.profileImage"
      [text]="imageText(user)"
      size="extra-small">
    </image>
    <div class="user-info">
      <p class="user-name">{{ user.firstName }} {{ user.lastName }}</p>
      <p class="user-email">{{ user.email }}</p>
    </div>
  </div>
  <div class="bottom-row">
    <div>
      <p class="ticket-name">{{ ticketName }}</p>
      <p class="code">{{ bookingCode | addSeparator: 4 }}</p>
      <p class="text">{{ 'myBookings.bookingCode' | translate }}</p>
    </div>
    <button
      pButton
      pRipple
      [label]="btnLabel"
      [icon]="btnIconClass"
      (click)="onClick()"></button>
  </div>
</div>
