export enum UsersMaxLengthValidators {
  firstName = 40,
  lastName = 50,
  department = 60,
  company = 70,
  jobTitle = 70,
  street = 60,
  postcode = 10,
  phone = 30,
}

export enum UsersMinLengthValidators {
  password = 6,
}

export enum BrandsMaxLengthValidators {
  name = 40,
  slogan = 50,
}

export enum EventsMaxLengthValidators {
  title = 100,
  tagline = 120,
  emailName = 100,
}
