import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-event-consent-type-pop-up-dialog',
  templateUrl: './event-consent-type-pop-up-dialog.component.html',
  styleUrls: ['./event-consent-type-pop-up-dialog.component.scss'],
})
export class EventConsentTypePopUpDialogComponent implements OnInit {
  titleKey: string;
  descriptionKey: string;
  confirmBtnKey: string;
  cancelBtnKey: string;

  constructor(
    private config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.titleKey = this.translateService.instant(this.config.data.titleKey);
    this.descriptionKey = this.translateService.instant(this.config.data?.descriptionKey ?? ' ');
    this.confirmBtnKey = this.translateService.instant(this.config.data.confirmBtnKey);
    this.cancelBtnKey = this.translateService.instant(this.config.data.cancelBtnKey);
  }
}
