import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Firestore, IFirestore } from 'src/app/firebase';
import { IAppointment, IAppointmentUser } from 'src/app/core/models';
import { API_ROUTES as apiRoutes } from 'src/app/shared';
import { AuthorizationService } from '../auth';

@Injectable({
  providedIn: 'root',
})
export class AppointmentService {
  private firestore: IFirestore;

  constructor(
    private http: HttpClient,
    private authorizationService: AuthorizationService,
  ) {
    this.firestore = Firestore();
  }

  async getOne(appointmentId: string): Promise<IAppointment> {
    try {
      const appointment = (
        await this.firestore.collection(apiRoutes.appointments).doc(appointmentId).get()
      ).data() as IAppointment;

      return appointment;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getAppointmentUser(id: string): Promise<IAppointmentUser> {
    try {
      const appointmentUser = (
        await this.firestore.collection(apiRoutes.appointmentsUsers).doc(id).get()
      ).data() as IAppointmentUser;

      return appointmentUser;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getAppointmentHostUser(appointmentId: string, userId: string): Promise<IAppointmentUser> {
    try {
      const appointmentUser = (
        await this.firestore
          .collection(apiRoutes.appointmentsUsers)
          .where('appointmentId', '==', appointmentId)
          .where('userId', '==', userId)
          .where('isHost', '==', true)
          .get()
      ).docs[0].data() as IAppointmentUser;

      return appointmentUser;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getAppointmentUsersByUserId(userId: string): Promise<IAppointmentUser[]> {
    try {
      const appointmentUsers: IAppointmentUser[] = (
        await this.firestore
          .collection(apiRoutes.appointmentsUsers)
          .where('userId', '==', userId)
          .get()
      ).docs.map((doc) => doc.data() as IAppointmentUser);

      return appointmentUsers;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async deleteAppointmentUser(appointmentUserId: string): Promise<boolean> {
    try {
      await this.firestore.collection(apiRoutes.appointmentsUsers).doc(appointmentUserId).delete();

      return true;
    } catch (err) {
      console.warn(err);
      throw new Error(err);
    }
  }

  async createAppointment(
    appointment: IAppointment,
    userTokens: { [userId: string]: string },
  ): Promise<IAppointment> {
    try {
      const headers = await this.authorizationService.buildHeaders();

      return this.http
        .post<IAppointment>(
          apiRoutes.appointmentCreate,
          { ...appointment, userTokens },
          { headers },
        )
        .toPromise();
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async acceptAppointment(notificationId: string, appointmentId: string, userId: string) {
    try {
      const headers = await this.authorizationService.buildHeaders();

      return this.http
        .post<boolean>(
          apiRoutes.appointmentAccept(appointmentId),
          { notificationId, appointmentId, userId },
          { headers },
        )
        .toPromise();
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async declineAppointment(
    appointmentId: string,
    payload: { notificationId: string; userId: string; reason: string },
  ) {
    try {
      const headers = await this.authorizationService.buildHeaders();
      return this.http
        .post<boolean>(apiRoutes.appointmentDecline(appointmentId), payload, { headers })
        .toPromise();
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getAppointmentsByHostId(hostId: string): Promise<IAppointment[]> {
    try {
      const appointments: IAppointment[] = (
        await this.firestore.collection(apiRoutes.appointments).where('hostId', '==', hostId).get()
      ).docs.map((doc) => doc.data() as IAppointment);

      return appointments;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async deleteAppointment(appointmentId: string): Promise<boolean> {
    try {
      await this.firestore.collection(apiRoutes.appointments).doc(appointmentId).delete();

      return true;
    } catch (err) {
      console.warn(err);
      throw new Error(err);
    }
  }
}
