import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HubsService } from '../../services';
import { HubsStore } from 'src/app/core/stores';

@Component({
  selector: 'app-stripe-callback',
  templateUrl: './stripe-callback.component.html',
  styleUrls: ['./stripe-callback.component.scss'],
})
export class StripeCallbackComponent implements OnInit, OnDestroy {
  private subscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private hubsService: HubsService,
    private hubsStore: HubsStore,
  ) {}

  ngOnInit(): void {
    this.subscription = this.route.queryParams
      .pipe(
        switchMap((params) => {
          const hubId = localStorage.getItem('connectHubId');
          if (hubId) {
            return this.hubsService.getOne(hubId);
          } else {
            return of(null);
          }
        }),
      )
      .subscribe((hub) => {
        if (hub && hub.url) {
          let redirectUrl =
            environment.env === 'LOCAL'
              ? `http://127.0.0.1:4200/${hub.url}/admin/hub-settings`
              : `${this.hubsStore.environmentBaseUrl}/${hub && hub.url && !hub.customDomain ? hub.url : ''}/admin/hub-settings`;

          const queryParams = Array.from(this.route.snapshot.queryParamMap.keys)
            .map((key) => `${key}=${this.route.snapshot.queryParamMap.get(key)}`)
            .join('&');

          if (queryParams) {
            redirectUrl += `?${queryParams}`;
          }
          window.location.href = redirectUrl;
          localStorage.removeItem('connectHubId');
        }
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
