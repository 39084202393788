import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable, Subject, takeUntil, tap } from 'rxjs';

import { IAsset, IChapter } from 'src/app/core/models';
import { RegisteredCourseStore } from 'src/app/core/stores';
import { trackByFn } from 'src/app/core/utils';
import { ChapterAssetCardComponent } from '../chapter-asset-card/chapter-asset-card.component';
import { SharedModule } from 'src/app/shared';

@Component({
  selector: 'app-chapters-sidebar-tab-events',
  standalone: true,
  imports: [CommonModule, ChapterAssetCardComponent, SharedModule],
  templateUrl: './chapters-sidebar-tab-events.component.html',
  styleUrls: ['./chapters-sidebar-tab-events.component.scss'],
})
export class ChaptersSidebarTabEventsComponent implements OnInit, OnDestroy {
  chapters: Observable<IChapter[]>;
  trackByFn = trackByFn;
  activeAsset: IAsset;

  private unsubscribe$ = new Subject<void>();

  constructor(private registeredCourseStore: RegisteredCourseStore) {}

  get stylesClassForAccordion(): string {
    return window.screen.width > 904 ? 'custom-accordion' : 'registered-course-accordion';
  }

  ngOnInit(): void {
    this.chapters = this.registeredCourseStore.courseChaptersOnlyAssetEvents;

    this.registeredCourseStore.activeAsset
      .pipe(
        tap((asset: IAsset) => {
          this.activeAsset = asset;
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();
  }

  async onChangeActiveAsset(asset: IAsset): Promise<void> {
    if (this.activeAsset.id === asset.id) {
      return;
    }
    this.registeredCourseStore.setActiveAsset(asset);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
