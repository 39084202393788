<div
  class="card-container"
  (click)="clickOnCard.emit(asset)"
  *ngIf="asset">
  <div class="image-container">
    <img [src]="imgUrl" />
  </div>
  <p class="asset-title">{{ asset.fileName }}</p>
  <div class="footer">
    <span>{{ 'assetCard.fileSize' | translate }}: </span>
    <span>{{ formatBytes(asset.fileSize, 2) }}</span>
  </div>
</div>

<div
  class="card-container"
  (click)="onFileCardClick()"
  *ngIf="!asset">
  <div class="image-container">
    <img [src]="imgUrlByFileType" />
  </div>
  <p class="asset-title">{{ fileName }}</p>
  <div class="footer">
    <span>{{ 'assetCard.fileSize' | translate }}: </span>
    <span>{{ formatBytes(fileSize, 2) }}</span>
  </div>
</div>
