/* eslint-disable max-len */
export const horizontalGradientFromDarkToLight = (
  imageUrl: string,
  withLinGrad: boolean = true,
): { [key: string]: string } => ({
  background: withLinGrad
    ? `linear-gradient(90deg, rgba(24, 24, 24, 0.97) 20.56%, rgba(41, 41, 41, 0.00) 80.44%), url(${imageUrl})`
    : `url(${imageUrl})`,
  'background-repeat': 'no-repeat',
  'background-size': 'cover',
  'background-position': 'center',
});

export const mainDefaultBanner = (color: string): string => `
  <svg viewBox="0 0 1376 277" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2515_48292)">
    <rect width="1376" height="277" fill="#202020"/>
    <rect width="1376" height="277" fill="${color}" fill-opacity="0.4"/>
    <rect width="1376" height="277" fill="url(#paint1_linear_2515_48292)"/>
    <g clip-path="url(#clip1_2515_48292)">
    <g style="mix-blend-mode:color-dodge" filter="url(#filter0_f_2515_48292)">
    <path d="M810.509 -23.9029C541.959 17.7237 399.189 111.685 361.372 153.463C297.136 241.844 210.468 382.59 377.69 238.526C586.717 58.4454 794.968 80.6161 1263.53 3.24486C1638.38 -58.6521 1534.2 -132.645 1435.26 -161.904H1220.79C1195.93 -133.248 1079.06 -65.5296 810.509 -23.9029Z" fill="url(#paint2_linear_2515_48292)"/>
    </g>
    <g style="mix-blend-mode:color-dodge" filter="url(#filter1_f_2515_48292)">
    <path d="M648.089 -113.247C319.5 -62.3346 144.811 52.5867 98.5401 103.683C19.9426 211.779 -86.1007 383.922 118.506 207.721C374.265 -12.5293 629.073 14.587 1202.39 -80.0432C1661.05 -155.747 1533.58 -246.246 1412.51 -282.032H1150.1C1119.67 -246.984 976.678 -164.159 648.089 -113.247Z" fill="url(#paint3_linear_2515_48292)"/>
    </g>
    <g style="mix-blend-mode:color-dodge" filter="url(#filter2_f_2515_48292)">
    <path d="M1608.73 -63.1642C1340.18 -21.5375 1197.41 72.424 1159.59 114.202C1095.36 202.583 1008.69 343.329 1175.91 199.265C1384.94 19.1842 1593.19 41.3549 2061.75 -36.0164C2436.6 -97.9134 2332.42 -171.906 2233.48 -201.166H2019.01C1994.15 -172.51 1877.28 -104.791 1608.73 -63.1642Z" fill="url(#paint4_linear_2515_48292)"/>
    </g>
    <g style="mix-blend-mode:color-dodge" filter="url(#filter3_f_2515_48292)">
    <path d="M1036.6 51.1021C862.663 78.0575 770.192 138.903 745.698 165.956C704.093 223.187 647.959 314.328 756.267 221.038C891.653 104.427 1026.54 118.784 1330.02 68.6817C1572.81 28.6002 1505.33 -19.3141 1441.25 -38.261H1302.34C1286.23 -19.7047 1210.54 24.1466 1036.6 51.1021Z" fill="url(#paint5_linear_2515_48292)"/>
    </g>
    </g>
    </g>
    <defs>
    <filter id="filter0_f_2515_48292" x="231.837" y="-210.904" width="1345.16" height="560.344" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
    <feGaussianBlur stdDeviation="24.5" result="effect1_foregroundBlur_2515_48292"/>
    </filter>
    <filter id="filter1_f_2515_48292" x="-49" y="-331.032" width="1623.98" height="663.478" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
    <feGaussianBlur stdDeviation="24.5" result="effect1_foregroundBlur_2515_48292"/>
    </filter>
    <filter id="filter2_f_2515_48292" x="1030.06" y="-250.166" width="1345.16" height="560.344" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
    <feGaussianBlur stdDeviation="24.5" result="effect1_foregroundBlur_2515_48292"/>
    </filter>
    <filter id="filter3_f_2515_48292" x="644.536" y="-87.261" width="905.775" height="397.392" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
    <feGaussianBlur stdDeviation="24.5" result="effect1_foregroundBlur_2515_48292"/>
    </filter>
    <linearGradient id="paint0_linear_2515_48292" x1="704.5" y1="633.5" x2="357" y2="-226.5" gradientUnits="userSpaceOnUse">
    <stop stop-color="${color}"/>
    <stop offset="1" stop-opacity="0"/>
    </linearGradient>
    <linearGradient id="paint1_linear_2515_48292" x1="282.844" y1="138.5" x2="1376" y2="138.5" gradientUnits="userSpaceOnUse">
    <stop stop-color="#181818" stop-opacity="0.97"/>
    <stop offset="0.753815" stop-color="#292929" stop-opacity="0"/>
    </linearGradient>
    <linearGradient id="paint2_linear_2515_48292" x1="1528.95" y1="-179.92" x2="1187.67" y2="657.911" gradientUnits="userSpaceOnUse">
    <stop stop-color="${color}"/>
    <stop offset="1" stop-color="${color}" stop-opacity="0"/>
    <stop offset="1" stop-color="white"/>
    </linearGradient>
    <linearGradient id="paint3_linear_2515_48292" x1="1527.15" y1="-304.066" x2="1109.87" y2="720.777" gradientUnits="userSpaceOnUse">
    <stop stop-color="${color}"/>
    <stop offset="1" stop-color="${color}" stop-opacity="0"/>
    <stop offset="1" stop-color="white"/>
    </linearGradient>
    <linearGradient id="paint4_linear_2515_48292" x1="2327.17" y1="-219.181" x2="1985.89" y2="618.65" gradientUnits="userSpaceOnUse">
    <stop stop-color="${color}"/>
    <stop offset="1" stop-color="${color}" stop-opacity="0"/>
    <stop offset="1" stop-color="white"/>
    </linearGradient>
    <linearGradient id="paint5_linear_2515_48292" x1="1501.93" y1="-49.9268" x2="1280.97" y2="492.646" gradientUnits="userSpaceOnUse">
    <stop stop-color="${color}"/>
    <stop offset="1" stop-color="${color}" stop-opacity="0"/>
    <stop offset="1" stop-color="white"/>
    </linearGradient>
    <clipPath id="clip0_2515_48292">
    <rect width="1376" height="277" fill="white"/>
    </clipPath>
    <clipPath id="clip1_2515_48292">
    <rect width="1376" height="276" fill="white" transform="translate(0 1)"/>
    </clipPath>
    </defs>
  </svg>
`;
