import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';

import { validateStringAsHex } from 'src/app/core/utils';

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
})
export class ColorPickerComponent implements OnChanges, OnInit, OnDestroy {
  @Input() colorPickerValue: string;
  @Input() label: string;
  @Output() selectedColor = new EventEmitter<string>();
  @Output() selectedColorError = new EventEmitter<string>();

  colorPicker: UntypedFormControl = new UntypedFormControl(null);
  inputColor: UntypedFormControl = new UntypedFormControl(null, [validateStringAsHex()]);

  private unsubscribe$: Subject<void> = new Subject<void>();

  ngOnChanges(): void {
    if (this.colorPickerValue !== this.colorPicker.value) {
      this.colorPicker.patchValue(this.colorPickerValue);
      this.inputColor.patchValue(this.colorPickerValue);
    }
  }

  ngOnInit(): void {
    this.colorPicker.patchValue(this.colorPickerValue);
    this.inputColor.patchValue(this.colorPickerValue);

    this.inputColor.valueChanges
      .pipe(
        debounceTime(300),
        tap((value: string) => {
          this.colorPicker.setValue(value, { emitEvent: false });
          this.colorPicker.markAsDirty();
          if (!this.inputColor.hasError('pattern')) {
            this.selectedColor.emit(value);
          } else {
            this.selectedColorError.emit('pattern');
          }
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();

    this.colorPicker.valueChanges
      .pipe(
        debounceTime(300),
        tap((value: string) => {
          this.inputColor.setValue(value, { emitEvent: false });
          this.colorPicker.markAsDirty();
          this.selectedColor.emit(value);
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
