import { IExportField } from '../models';

export const userFields: IExportField[] = [
  {
    header: 'Salutation',
    key: 'salutation',
    order: 1,
  },
  {
    header: 'First Name',
    key: 'firstName',
    order: 2,
  },
  {
    header: 'Last Name',
    key: 'lastName',
    order: 3,
  },
  {
    header: 'Email Address',
    key: 'email',
    order: 4,
  },
  {
    header: 'Company',
    key: 'company',
    order: 5,
  },
  {
    header: 'Position',
    key: 'position',
    order: 6,
  },
  {
    header: 'Address',
    key: 'street',
    order: 7,
  },
  {
    header: 'City',
    key: 'city',
    order: 8,
  },
  {
    header: 'Postcode',
    key: 'postcode',
    order: 9,
  },
  {
    header: 'Country',
    key: 'country',
    order: 10,
  },
  {
    header: 'Phone',
    key: 'phone',
    order: 11,
  },
  {
    header: 'Mobile',
    key: 'mobile',
    order: 12,
  },
  {
    header: 'Emergency Contact - Name',
    key: 'emergencyContactName',
    order: 13,
  },
  {
    header: 'Emergency Contact - Phone',
    key: 'emergencyContactPhone',
    order: 14,
  },
  {
    header: 'Travel Document',
    key: 'travelDocument',
    order: 15,
  },
  {
    header: 'Travel Document Number',
    key: 'passportNumber',
    order: 16,
  },
  {
    header: 'Date of Issue',
    key: 'dateOfIssue',
    order: 17,
  },
  {
    header: 'Date of Expiry',
    key: 'dateOfExpiry',
    order: 18,
  },
  {
    header: 'Dietary Restrictions',
    key: 'vegetarian',
    order: 19,
  },
  {
    header: 'Date of Birth',
    key: 'birthDate',
    order: 20,
  },
  {
    header: 'Nationality',
    key: 'nationality',
    order: 21,
  },
  {
    header: 'Date of User Registration',
    key: 'userCreatedAt',
    order: 22,
  },
  {
    header: 'Industry',
    key: 'industry',
    order: 23,
  },
  {
    header: 'Sub-industry',
    key: 'subIndustry',
    order: 24,
  },
  {
    header: 'Area of activity',
    key: 'areaOfActivity',
    order: 25,
  },
  {
    header: 'Position dropdown',
    key: 'optionalPosition',
    order: 26,
  },
  {
    header: 'Company size',
    key: 'companySize',
    order: 27,
  },
];

export const userFieldsForExportInCourse: IExportField[] = [
  {
    header: 'Salutation',
    key: 'salutation',
    order: 1,
  },
  {
    header: 'First Name',
    key: 'firstName',
    order: 2,
  },
  {
    header: 'Last Name',
    key: 'lastName',
    order: 3,
  },
  {
    header: 'Email Address',
    key: 'email',
    order: 4,
  },
  {
    header: 'Company',
    key: 'company',
    order: 5,
  },
  {
    header: 'Position',
    key: 'position',
    order: 6,
  },
  {
    header: 'Address',
    key: 'street',
    order: 7,
  },
  {
    header: 'City',
    key: 'city',
    order: 8,
  },
  {
    header: 'Postcode',
    key: 'postcode',
    order: 9,
  },
  {
    header: 'Country',
    key: 'country',
    order: 10,
  },
  {
    header: 'Phone',
    key: 'phone',
    order: 11,
  },
  {
    header: 'Mobile',
    key: 'mobile',
    order: 12,
  },
  {
    header: 'Emergency Contact - Name',
    key: 'emergencyContactName',
    order: 13,
  },
  {
    header: 'Emergency Contact - Phone',
    key: 'emergencyContactPhone',
    order: 14,
  },
  {
    header: 'Date of Birth',
    key: 'birthDate',
    order: 15,
  },
  {
    header: 'Nationality',
    key: 'nationality',
    order: 16,
  },
  {
    header: 'Date of User Registration',
    key: 'userCreatedAt',
    order: 17,
  },
  {
    header: 'Industry',
    key: 'industry',
    order: 18,
  },
  {
    header: 'Sub-industry',
    key: 'subIndustry',
    order: 19,
  },
  {
    header: 'Area of activity',
    key: 'areaOfActivity',
    order: 20,
  },
  {
    header: 'Position dropdown',
    key: 'optionalPosition',
    order: 21,
  },
  {
    header: 'Company size',
    key: 'companySize',
    order: 22,
  },
];

export const userEventFields: IExportField[] = [
  {
    header: 'Attendance',
    key: 'status',
    order: 1,
  },
  {
    header: 'Invitation Link',
    key: 'invitationLink',
    order: 2,
  },
  {
    header: 'Role',
    key: 'role',
    order: 3,
  },
  {
    header: 'Ticket',
    key: 'ticketId',
    order: 4,
  },
  {
    header: 'Ticket Price',
    key: 'ticketPrice',
    order: 5,
  },
  {
    header: 'Hotel',
    key: 'hotel',
    order: 6,
  },
  {
    header: 'Room Type',
    key: 'roomType',
    order: 7,
  },
  {
    header: 'Room Partner',
    key: 'roomPartner',
    order: 8,
  },
  {
    header: 'Preferred Airport',
    key: 'preferredAirport',
    order: 9,
  },
  {
    header: 'Alternative Airport',
    key: 'alternativeAirport',
    order: 10,
  },
  {
    header: 'Session Registrations',
    key: 'sessionRegistrations',
    order: 11,
  },
  {
    header: 'Clothing Size',
    key: 'clothingSize',
    order: 12,
  },
  {
    header: 'Shoe Size',
    key: 'shoeSize',
    order: 13,
  },
  {
    header: 'Date of Event Registration',
    key: 'createdAt',
    order: 14,
  },
  {
    header: 'Tags',
    key: 'tags',
    order: 15,
  },
];

export const sessionFields: IExportField[] = [
  {
    header: 'User Register At',
    key: 'registerAt', // userSessions.createdAt
    order: 1,
  },
  {
    header: 'Title',
    key: 'title',
    order: 2,
  },
  {
    header: 'Date',
    key: 'date',
    order: 3,
  },
  {
    header: 'Space',
    key: 'space',
    order: 4,
  },
  {
    header: 'Session Start',
    key: 'start',
    order: 5,
  },
  {
    header: 'Session End',
    key: 'end',
    order: 6,
  },
  {
    header: 'Description',
    key: 'description',
    order: 7,
  },
  {
    header: 'Short Description',
    key: 'shortDescription',
    order: 8,
  },
  {
    header: 'Stage id',
    key: 'stageId',
    order: 9,
  },
  {
    header: 'Featured Session',
    key: 'featuredSession',
    order: 10,
  },
  {
    header: 'Max Participants',
    key: 'maxParticipants',
    order: 11,
  },
  {
    header: 'Is Session Registration',
    key: 'isSessionRegistration',
    order: 12,
  },
  {
    header: 'Availability',
    key: 'availability',
    order: 13,
  },
  {
    header: 'Is Closing Session Registration',
    key: 'isClosingSessionRegistration',
    order: 14,
  },
  {
    header: 'Closing Date',
    key: 'closingDate',
    order: 15,
  },
  {
    header: 'Closing Time',
    key: 'closingTime',
    order: 16,
  },
  {
    header: 'Sessions Relation',
    key: 'sessionsRelation',
    order: 17,
  },
  {
    header: 'Is Cancelling Enabled',
    key: 'isCancellingEnabled',
    order: 18,
  },
];

export const userCourseFields: IExportField[] = [
  {
    header: 'Role',
    key: 'role',
    order: 1,
  },
  {
    header: 'Ticket',
    key: 'ticketId',
    order: 2,
  },
  {
    header: 'Ticket Price',
    key: 'ticketPrice',
    order: 3,
  },
  {
    header: 'Date of Course Registration',
    key: 'createdAt',
    order: 4,
  },
  {
    header: 'Tags',
    key: 'tags',
    order: 5,
  },
  {
    header: 'Invitation Link',
    key: 'invitationLink',
    order: 6,
  },
];
