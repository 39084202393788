<ng-container *ngIf="loading">
  <app-loader></app-loader>
</ng-container>

<ng-container *ngIf="!loading">
  <div class="hub-tags-container">
    <div class="hub-tags-inner-container">
      <form [formGroup]="form">
        <div class="grid data-section">
          <div class="col-12 data-context">
            <h1 class="title">{{ 'adminHubTags.formTitle' | translate }}</h1>
            <p class="description">{{ 'adminHubTags.formDescription' | translate }}</p>
          </div>
          <div class="col-12 form-container">
            <app-tags-multi-list
              [possibleTags]="appTags"
              [selectedTags]="selectedTags"
              (onSelected)="selectTags($event)">
            </app-tags-multi-list>
          </div>
        </div>
      </form>

      <div class="grid c-mt-4 p-5">
        <div class="col text-right">
          <button
            pButton
            type="button"
            class="primary-text-color"
            [icon]="saving ? 'pi pi-spin pi-spinner' : null"
            [disabled]="form.invalid"
            [label]="'adminHubTags.saveBtnLabel' | translate"
            (click)="save()"></button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
