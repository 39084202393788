import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { DialogService } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

import { emailsCourseActions, emailsEventActions } from 'src/app/core/utils';
import { HubsStore } from 'src/app/core/stores';
import {
  ConfirmDialogComponent,
  IAdminTableColumn,
  IAdminTableFilter,
  IAdminTableFilterChange,
} from 'src/app/shared';
import { EmailsService } from 'src/app/core/services';
import { EmailModuleType, IEmail } from 'src/app/core/models';
import { ButtonSize, ButtonStyle, ButtonType } from 'src/app/standalone';
import { SystemNewEmailDialogComponent, UnlayerEmailEditorComponent } from '../../../components';

@Component({
  selector: 'app-emails-list',
  templateUrl: './emails-list.component.html',
  styleUrls: ['./emails-list.component.scss'],
})
export class EmailsListComponent implements OnInit {
  loading = true;
  loadingEntries = true;
  searchValue = '';
  searchFilterFields: string[] = ['title', 'language'];
  columns: IAdminTableColumn[] = [
    { field: 'title', titleTranslationKey: 'adminEmailTemplates.tableColumnTitle', sortable: true },
    {
      field: 'language',
      titleTranslationKey: 'adminEmailTemplates.tableColumnLanguage',
      template: 'langCell',
      sortable: true,
    },
    {
      field: 'actionTitle',
      titleTranslationKey: 'adminEmailTemplates.tableColumnAction',
      sortable: true,
    },
    {
      field: 'isHtmlMode',
      titleTranslationKey: 'adminEmailTemplates.tableColumnIsHtmlMode',
      template: 'htmlCell',
      sortable: true,
    },
    {
      field: 'default',
      titleTranslationKey: 'adminEmailTemplates.tableColumnDefault',
      template: 'defaultCell',
      sortable: true,
    },
    {
      field: 'status',
      titleTranslationKey: 'adminEmailTemplates.tableColumnStatus',
      template: 'statusCell',
      sortable: true,
    },
    {
      field: 'updateTimeStamp',
      titleTranslationKey: 'adminSystemTags.tableColTimeStampUpdated',
      sortable: true,
    },
    { field: 'actions', template: 'actionsCell' },
  ];
  entries: IEmail[] = [];
  emailActions: { label: string; value: string }[] = emailsEventActions;
  emailCourseActions: { label: string; value: string }[] = emailsCourseActions;
  totalRecords = 0;
  pageSize = 25;
  buttonSize = ButtonSize;
  buttonType = ButtonType;
  buttonStyle = ButtonStyle;
  tableFilters: IAdminTableFilter[];

  private allEmails: IEmail[];

  constructor(
    private emailsService: EmailsService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
    private messageService: MessageService,
    public hubsStore: HubsStore,
  ) {}

  async ngOnInit(): Promise<void> {
    const availableLangs: string[] = this.translateService.langs;
    const emailModuleTypes: string[] = Object.values(EmailModuleType);
    await this.fetchEmails();
    this.tableFilters = [
      {
        key: 'language',
        label: 'adminEmailTemplates.langFilterLabel',
        options: availableLangs.map((lang: string) => ({
          label: this.translateService.instant(`languages.${lang}`),
          value: lang,
        })),
      },
      {
        key: 'module',
        label: 'adminEmailTemplates.moduleFilterLabel',
        options: emailModuleTypes.map((emailModuleType: string) => ({
          label: this.translateService.instant(`moduleType.${emailModuleType}`),
          value: emailModuleType,
        })),
      },
    ];
    this.loadingEntries = false;
    this.loading = false;
  }

  add(): void {
    const newDialogRef = this.dialogService.open(SystemNewEmailDialogComponent, {
      closable: false,
      styleClass: 'new-system-new-email-dialog',
    });

    newDialogRef.onClose.pipe(take(1)).subscribe(async (mode) => {
      if (!mode) {
        return;
      }

      if (mode === 'unlayer') {
        this.dialogService.open(UnlayerEmailEditorComponent, {
          closable: false,
          styleClass: 'unlayer-email-editor-dialog',
        });
      } else {
        this.router.navigate(
          [`/${this.hubsStore.useHubUrl}/admin/system-settings/emails/new-email`],
          { queryParams: { mode } },
        );
      }
    });
  }

  edit(event: Event, entry: IEmail): void {
    event.stopPropagation();
    this.router.navigate([entry.id], {
      relativeTo: this.route,
      state: { entry },
    });
  }

  remove(event: Event, entry: IEmail): void {
    event.stopPropagation();
    const deleteDialogRef = this.dialogService.open(ConfirmDialogComponent, {
      closable: false,
      styleClass: 'confirm-dialog',
      data: {
        titleKey: 'adminEmailTemplates.deleteTitle',
        descriptionKey: 'adminEmailTemplates.deleteText',
        confirmBtnKey: 'adminEmailTemplates.confirmBtn',
        cancelBtnKey: 'adminEmailTemplates.cancelBtn',
        className: 'fa-solid fa-trash-xmark',
        entry,
      },
    });

    deleteDialogRef.onClose.pipe(take(1)).subscribe(async (result: 'cancel' | 'confirm') => {
      if (result === 'confirm') {
        try {
          this.loadingEntries = true;
          const deleteResult = await this.emailsService.softRemove(entry.id);
          if (deleteResult) {
            this.messageService.add({
              severity: 'success',
              summary: this.translateService.instant('success'),
              detail: this.translateService.instant('adminEmailTemplates.succesDelete'),
              styleClass: 'custom-toast',
            });
          }
          await this.fetchEmails();
          this.loadingEntries = false;
        } catch (error) {
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('success'),
            detail: this.translateService.instant('adminEmailTemplates.errorDelete'),
            styleClass: 'custom-toast',
          });
        }
      }
    });
  }

  async filtersChange(filters: IAdminTableFilterChange[]): Promise<void> {
    this.loadingEntries = true;
    if (!filters.length) {
      this.entries = [...this.allEmails];
      this.loadingEntries = false;
      return;
    }

    const transformedObject: { [key: string]: string[] } = filters.reduce((acc, curr) => {
      if (!acc[curr.filterKey]) {
        acc[curr.filterKey] = [];
      }
      acc[curr.filterKey].push(curr.optionKey);
      return acc;
    }, {});

    const filteredEmails: IEmail[] = this.allEmails.filter((email: IEmail) =>
      Object.keys(transformedObject).every((key: string) =>
        transformedObject[key].includes(email[key]),
      ),
    );

    this.entries = [...filteredEmails];
    this.loadingEntries = false;
  }

  private async fetchEmails(): Promise<void> {
    const emails = await this.emailsService.getAll();
    this.allEmails = emails.map((email: IEmail) => {
      if (email.module !== 'courses') {
        email.actionTitle = email.action
          ? this.emailActions?.find(
              (emailAction: { label: string; value: string }) => emailAction.value === email.action,
            )?.label
          : '-';
      } else {
        email.actionTitle = email.action
          ? this.emailCourseActions?.find(
              (emailCourseAction: { label: string; value: string }) =>
                emailCourseAction.value === email.action,
            )?.label
          : '-';
      }
      return email;
    });
    this.entries = [...this.allEmails];
  }
}
