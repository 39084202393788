import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { preparedValueForLink } from 'src/app/shared';
import { Timestamp } from 'src/app/firebase';
import { UsersService, BrandsService, HubsService } from 'src/app/core/services';
import { IUser, IBrand, IEvent, ICourse, IHub } from 'src/app/core/models';
import { HubsStore, UsersStore } from 'src/app/core/stores';
import { VIRTUAL_SCROLL_ITEM_SIZE } from 'src/app/core/utils';
import { ButtonSize, ButtonStyle } from 'src/app/standalone';

interface IBrandOwnerUser extends IUser {
  fullName: string;
}

@Component({
  selector: 'app-new-brand',
  templateUrl: './new-brand.component.html',
  styleUrls: ['./new-brand.component.scss'],
})
export class NewBrandComponent implements OnInit, OnDestroy {
  loading = true;
  isUserLoading = true;
  isUpdating = false;
  users: IBrandOwnerUser[] = [];
  linkLoading = false;
  createAnotherControl = new UntypedFormControl(null);
  form: UntypedFormGroup;
  event: IEvent = null;
  course: ICourse = null;
  hub: IHub;
  virtualScrollItemSize: number = VIRTUAL_SCROLL_ITEM_SIZE;
  buttonStyle = ButtonStyle;
  buttonSize = ButtonSize;

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private messageService: MessageService,
    public hubsStore: HubsStore,
    private usersStore: UsersStore,
    private brandsService: BrandsService,
    private usersService: UsersService,
    private translateService: TranslateService,
    private ref: DynamicDialogRef,
    private configDialog: DynamicDialogConfig,
    private hubsService: HubsService,
  ) {}

  get canUpdate(): boolean {
    return !this.isUpdating && this.form.valid && this.form.dirty;
  }

  async ngOnInit(): Promise<void> {
    this.event = this.configDialog?.data?.event;
    this.course = this.configDialog?.data?.course;
    this.hub = this.configDialog?.data?.hub;

    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(null, Validators.required),
      link: new UntypedFormControl(null),
      user: new UntypedFormControl(null, Validators.required),
    });
    this.form.controls['user'].disable();
    this.usersService.getUsersByHub(this.hubsStore.hub.id).then((usersData) => {
      const users = usersData;
      this.users = users.map((user: IUser) => ({
        ...user,
        fullName: `${user.firstName} ${user.lastName}`,
      }));

      this.isUserLoading = false;
      this.form.controls['user'].enable();
    });

    this.loading = false;

    this.form.controls.name.valueChanges
      .pipe(
        debounceTime(600),
        tap((value: string) => {
          if (!value) {
            this.form.controls.link.setValue(null);
          } else {
            this.linkLoading = true;
            this.createLinkValue(value);
          }
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();
  }

  async create(): Promise<void> {
    this.isUpdating = true;

    try {
      const brand = this.form.getRawValue();

      const newBrand: IBrand = {
        id: null,
        name: brand.name,
        _name_: brand.name.toLowerCase(),
        user: brand.user,
        team: [],
        slogan: null,
        description: null,
        banner: null,
        street: null,
        addressLineSecond: null,
        zipCode: null,
        city: null,
        country: null,
        logo: null,
        website: null,
        linkedin: null,
        facebook: null,
        twitter: null,
        instagram: null,
        youtube: null,
        xing: null,
        calendly: null,
        featuredBrand: false,
        tags: [],
        email: null,
        link: brand.link,
        acceptTerms: false,
        acceptNews: false,
        pendingUsers: [],
        users: [],
        updatedAt: null,
        updatedBy: null,
        createdAt: Timestamp.now(),
        createdBy: this.usersStore.user.id,
      };

      const createdBrand: IBrand = await this.brandsService.create(newBrand);
      const brandOwner: IUser = await this.usersService.getOne(newBrand.user);
      await this.brandsService.attachOwner(createdBrand, brandOwner);

      if (this.event) {
        this.brandsService.addBrandToEvent(this.event, createdBrand.id, newBrand);
      }

      if (this.course) {
        this.brandsService.addBrandToCourse(this.course, createdBrand.id, newBrand);
      }

      if (this.hub) {
        await this.hubsService.addBrand(this.hub, createdBrand);
      }

      this.isUpdating = false;
      if (this.createAnotherControl.value) {
        this.form.reset({ createAnotherControl: true });
      } else {
        this.ref.close(createdBrand.id);
      }

      this.messageService.add({
        severity: 'success',
        summary: this.translateService.instant('success'),
        detail: this.translateService.instant('adminNewBrand.brandSuccessfullyCreated'),
        styleClass: 'custom-toast',
      });
    } catch (error) {
      console.log(error);
      this.isUpdating = false;
      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('error'),
        detail: this.translateService.instant('adminNewBrand.errorCreateBrand'),
        styleClass: 'custom-toast',
      });
    }
  }

  onCloseDialog(): void {
    this.ref.close();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private async createLinkValue(titleValue: string): Promise<void> {
    const linkValue = preparedValueForLink(titleValue);
    let linkExist = true;
    let counter = 0;
    while (linkExist) {
      if (counter === 0) {
        linkExist = await this.brandsService.checkBrandByLinkExists(linkValue).toPromise();
      } else {
        linkExist = await this.brandsService
          .checkBrandByLinkExists(`${linkValue}-${counter}`)
          .toPromise();
      }

      if (linkExist) {
        counter += 1;
      } else {
        if (counter === 0) {
          this.form.controls.link.setValue(linkValue);
        } else {
          this.form.controls.link.setValue(`${linkValue}-${counter}`);
        }
      }
    }
    this.linkLoading = false;
  }
}
