import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  CdkDragHandle,
  CdkDrag,
  CdkDropList,
  CdkDragDrop,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { MenuItem } from 'primeng/api';
import { Menu } from 'primeng/menu';
import { TranslateService } from '@ngx-translate/core';

import { SharedModule, imgPreloader, logoAvatar } from 'src/app/shared';
import { AssetStatus, ChapterStatus, IAsset, IChapter } from 'src/app/core/models';
import { chevronIconAnimation, dropDownAnimation } from './chapter-card.animations';
import {
  ButtonComponent,
  ButtonSize,
  ButtonStyle,
  ClickOutsideDirective,
} from 'src/app/standalone/shared';
import { trackByFn } from 'src/app/core/utils';
import { AssetCardComponent } from '../../library';

@Component({
  selector: 'app-chapter-card',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    ClickOutsideDirective,
    AssetCardComponent,
    CdkDragHandle,
    CdkDrag,
    CdkDropList,
    ButtonComponent,
  ],
  templateUrl: './chapter-card.component.html',
  styleUrls: ['./chapter-card.component.scss'],
  animations: [dropDownAnimation, chevronIconAnimation],
})
export class ChapterCardComponent implements OnInit {
  @Input() chapter: IChapter;
  @Output() changeChapterStatus = new EventEmitter<string>();
  @Output() editChapter = new EventEmitter<IChapter>();
  @Output() removeChapter = new EventEmitter<IChapter>();
  @Output() createAsset = new EventEmitter<IChapter>();
  @Output() attachAsset = new EventEmitter<IChapter>();
  @Output() editAsset = new EventEmitter<IAsset>();
  @Output() removeAsset = new EventEmitter<IAsset>();
  @Output() changeAssetStatus = new EventEmitter<{ newAssetStatus: AssetStatus; asset: IAsset }>();
  @Output() chapterAssetsReorder = new EventEmitter<string[]>();

  items: MenuItem[] = [
    {
      label: this.translateService.instant('chapterCard.edit'),
      icon: 'fa-regular fa-pen-to-square',
      command: () => {
        this.navigateToChapter();
      },
    },
    {
      label: this.translateService.instant('chapterCard.delete'),
      icon: 'fa-regular fa-trash',
      command: () => {
        this.deleteChapter();
      },
    },
  ];
  logoAvatar = logoAvatar;
  imgPreloader = imgPreloader;
  chapterStatus = ChapterStatus;
  chapterStatuses: { value: ChapterStatus; label: string }[];
  expandDropdown = false;
  trackByFn = trackByFn;
  isExpandAccordion = false;
  animationState = 'out';
  buttonStyle = ButtonStyle;
  buttonSize = ButtonSize;

  constructor(private translateService: TranslateService) {}

  get getClassForCircleMark(): string {
    if (this.chapter?.status === ChapterStatus.PUBLISHED) {
      return 'green';
    }

    return 'orange';
  }

  get currentChapterStatus(): string {
    return this.translateService.instant(`chapterCard.statuses.${this.chapter?.status}`);
  }

  ngOnInit(): void {
    this.chapterStatuses = Object.keys(ChapterStatus).map((status: ChapterStatus) => ({
      value: status,
      label: this.translateService.instant(`chapterCard.statuses.${ChapterStatus[status]}`),
    }));
  }

  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.chapter.assetsFullModel, event.previousIndex, event.currentIndex);
    this.chapterAssetsReorder.emit(
      this.chapter.assetsFullModel.map((assetFullModel: IAsset) => assetFullModel.id),
    );
  }

  onCloseToggleDropdown(): void {
    this.expandDropdown = false;
  }

  onToggleDropdown(event: Event): void {
    event.stopPropagation();
    this.expandDropdown = !this.expandDropdown;
  }

  expandCollapseAccordion(): void {
    this.isExpandAccordion = !this.isExpandAccordion;
    this.animationState = this.animationState === 'out' ? 'in' : 'out';
  }

  onSelectChapterStatus(status: { value: ChapterStatus; label: string }): void {
    if (this.chapter.status !== status.value) {
      this.changeChapterStatus.emit(status.value);
    }
    this.onCloseToggleDropdown();
  }

  navigateToChapter(event: Event = null): void {
    if (event) {
      event.stopPropagation();
    }
    this.editChapter.emit(this.chapter);
  }

  onOpenMenu(event: Event, menu: Menu): void {
    menu.toggle(event);
    event.stopPropagation();
  }

  deleteChapter(): void {
    this.removeChapter.emit(this.chapter);
  }

  onChangeAssetStatus(newAssetStatus: AssetStatus, asset: IAsset): void {
    this.changeAssetStatus.emit({ newAssetStatus, asset });
  }

  onEditAsset(asset: IAsset): void {
    this.editAsset.emit(asset);
  }

  onRemoveAsset(asset: IAsset): void {
    this.removeAsset.emit(asset);
  }

  onAttachAsset(): void {
    this.attachAsset.emit(this.chapter);
  }

  onCreateNewAsset(): void {
    this.createAsset.emit(this.chapter);
  }
}
