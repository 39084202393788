import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

import {
  ConfirmDialogComponent,
  imgPreloader,
  logoAvatar,
  IAdminTableColumn,
} from 'src/app/shared';
import { AlgoliaSearchResult, IBrand, IHub } from 'src/app/core/models';
import { BrandsService, HubsService } from 'src/app/core/services';
import { HubsStore } from 'src/app/core/stores';
import { AppStore } from 'src/app/app.store';
import { AttachBrandsToHubDialogComponent, NewBrandComponent } from 'src/app/admin';
import { asyncDelay } from 'src/app/core/utils';
import { ButtonSize, ButtonStyle, ButtonType } from 'src/app/standalone';

@Component({
  selector: 'app-brands-list',
  templateUrl: './brands-list.component.html',
  styleUrls: ['./brands-list.component.scss'],
})
export class BrandsListComponent implements OnInit {
  loading = true;
  loadingEntries = true;
  columns: IAdminTableColumn[] = [
    { field: 'name', template: 'nameCell', titleTranslationKey: 'adminBrands.tableColName' },
    { field: 'ownerName', titleTranslationKey: 'adminBrands.tableColBrandOwner' },
    { field: 'ownerEmail', titleTranslationKey: 'adminBrands.tableColOwnerEmail' },
    { field: 'actions', template: 'actionsCell' },
  ];
  entries: IBrand[] = [];
  totalRecords = 0;
  pageSize = 25;
  page = 0;
  imgPreloader = imgPreloader;
  logoAvatar = logoAvatar;
  buttonSize = ButtonSize;
  buttonType = ButtonType;
  buttonStyle = ButtonStyle;

  private searchTerm = '';
  private lastDeletedBrandId: string;

  constructor(
    private messageService: MessageService,
    private dialogService: DialogService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private brandsService: BrandsService,
    public hubsStore: HubsStore,
    public hubsService: HubsService,
    private translateService: TranslateService,
    public appStore: AppStore,
  ) {}

  get hub(): IHub {
    return this.hubsStore.hub;
  }

  async ngOnInit(): Promise<void> {
    await this.fetchBrands();
    this.loadingEntries = false;
    this.loading = false;
  }

  async onSearchTermChange(e: string): Promise<void> {
    this.loadingEntries = true;
    this.searchTerm = e;
    await this.fetchBrands();
    this.loadingEntries = false;
  }

  async fetchEntries(event: any): Promise<void> {
    this.loadingEntries = true;
    this.pageSize = event.rows;
    this.page = event.first / this.pageSize;
    await this.fetchBrands();
    this.loadingEntries = false;
  }

  openAttachBrandsDialog(): void {
    this.dialogService
      .open(AttachBrandsToHubDialogComponent, {
        width: '90%',
        height: '80%',
        data: {
          members: this.entries,
        },
      })
      .onClose.subscribe(async () => await this.fetchBrands());
  }

  async openNewBrandDialog(): Promise<void> {
    const newBrandDialogRef: DynamicDialogRef = this.dialogService.open(NewBrandComponent, {
      width: '40%',
      height: '50%',
      closable: false,
      styleClass: 'attach-new-brand-dialog',
      data: {
        hub: this.hub,
      },
    });

    const brandId: string = await newBrandDialogRef.onClose.pipe(take(1)).toPromise();
    if (brandId) {
      this.router.navigate([`/${this.hubsStore.useHubUrl}/admin/brand/${brandId}/profile`]);
    }
  }

  edit(event: Event, entry: IBrand): void {
    event.stopPropagation();
    this.router.navigate([`/${this.hubsStore.useHubUrl}/admin/brand/${entry.id}`], {
      relativeTo: this.activatedRoute,
      state: { entry },
    });
  }

  async remove(event: Event, entry: IBrand): Promise<void> {
    event.stopPropagation();
    const data = this.hubsStore.hub
      ? {
          titleKey: 'adminHubBrands.deleteBrand',
          descriptionKey: 'adminHubBrands.deleteBrandText',
          confirmBtnKey: 'adminHubBrands.confirmBtn',
          cancelBtnKey: 'adminHubBrands.cancelBtn',
        }
      : {
          titleKey: 'adminBrands.deleteBrand',
          descriptionKey: 'adminBrands.deleteBrandText',
          confirmBtnKey: 'adminBrands.confirmBtn',
          cancelBtnKey: 'adminBrands.cancelBtn',
        };

    const deleteDialogRef: DynamicDialogRef = this.dialogService.open(ConfirmDialogComponent, {
      closable: false,
      styleClass: 'confirm-dialog',
      data,
    });

    const result: 'cancel' | 'confirm' = await firstValueFrom(deleteDialogRef.onClose);
    if (result === 'confirm') {
      this.loading = true;
      try {
        let deleteResult: boolean;

        if (this.hubsStore.hub) {
          deleteResult = await this.hubsService.removeBrand(this.hubsStore.hub.id, entry.id);
        } else {
          deleteResult = await this.brandsService.remove(entry.id);
        }
        if (deleteResult) {
          this.lastDeletedBrandId = entry.id;
          this.messageService.add({
            severity: 'success',
            summary: this.translateService.instant('success'),
            detail: this.translateService.instant('adminBrands.succesDeleteBrand'),
            styleClass: 'custom-toast',
          });

          await asyncDelay(500);
          await this.fetchBrands();
        }
      } catch (error) {
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('success'),
          detail: this.translateService.instant('adminBrands.errorDeleteBrand'),
          styleClass: 'custom-toast',
        });
      }
      this.loading = false;
    }
  }

  onRowClicked(id: string): void {
    this.router.navigate([`/${this.hubsStore.useHubUrl}/admin/brand/${id}`]);
  }

  private async fetchBrands(): Promise<void> {
    let response: AlgoliaSearchResult<IBrand> = null;

    if (this.hubsStore.hub) {
      response = await this.brandsService.fetchByHub(
        this.hubsStore.hub ? this.hubsStore.hub.id : null,
        this.page,
        this.pageSize,
        this.searchTerm,
      );
    } else {
      response = await this.brandsService.fetch(this.page, this.pageSize, this.searchTerm);
    }

    this.entries = response.results
      .filter((brand: IBrand) => brand?.id !== this.lastDeletedBrandId)
      .map((brand: IBrand) => {
        if (brand.owner) {
          brand.ownerName = `${brand.owner.firstName} ${brand.owner.lastName}`;
          brand.ownerDisplayEmail = brand.owner.displayEmail
            ? brand.owner.displayEmail
            : brand.owner.email;
          brand.ownerEmail = brand.owner.email.toLowerCase();
        }

        return brand;
      });
    this.totalRecords = this.lastDeletedBrandId ? response.total - 1 : response.total;
    this.lastDeletedBrandId = null;
  }
}
