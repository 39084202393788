import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AppStore } from 'src/app/app.store';
import { ThemesService } from 'src/app/core/services';

@Injectable({
  providedIn: 'root',
})
export class GeneralSingleRouteConfigGuard {
  constructor(
    private themesService: ThemesService,
    public appStore: AppStore,
    private router: Router,
  ) {}
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    // const generalSettings = await this.themesService.getSystemGeneralSettings();
    const appearanceSettings = await this.themesService.getSystemAppearanceSettings();
    this.appStore.setAppearanceSystemSettingse(appearanceSettings);

    if (
      appearanceSettings.globalSingleRoute &&
      !(
        state.url.includes('signin') ||
        state.url.includes('signup') ||
        state.url.includes('resetpassword')
      )
    ) {
      this.router.navigate(['']);
      return false;
    }

    return true;
  }
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canMatch(
    route: Route,
    segments: UrlSegment[],
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canLoad(
    route: Route,
    segments: UrlSegment[],
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
}
