import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { IAdminTableFilterChange, IAdminTableFilter } from 'src/app/shared';

@Component({
  selector: 'admin-table-filter-navbar',
  templateUrl: './admin-table-filter-navbar.component.html',
  styleUrls: ['./admin-table-filter-navbar.component.scss'],
})
export class AdminTableFilterNavbarComponent implements OnInit {
  @Input() searchActive: boolean = true;
  @Input() filters: IAdminTableFilter[];
  @Input() public showResetSort: boolean = false;

  @Output() onSearchTermChange = new EventEmitter<string>();
  @Output() onFiltersChange = new EventEmitter<IAdminTableFilterChange[]>();
  @Output() onSortReset = new EventEmitter<boolean>();

  public search = new Subject<string>();
  public selectedOptions = [];

  private selectedFiltersSet: { [key: string]: string[] } = {};

  ngOnInit(): void {
    this.search
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((val) => this.onSearchTermChange.emit(val));
  }

  public onFilterChange(filterKey: string, event: any): void {
    this.selectedFiltersSet[filterKey] = event.value;
    this.generateSelectedFiltersOptions();
    this.applyFilters();
  }

  public removeFilterOption(filterKey: string, optionKey: string) {
    this.selectedFiltersSet[filterKey]?.splice(
      this.selectedFiltersSet[filterKey].indexOf(optionKey),
      1,
    );
    this.generateSelectedFiltersOptions();
    this.applyFilters();
  }

  private applyFilters() {
    const filterChangeValues: { filterKey: string; optionKey: string }[] = [];

    Object.keys(this.selectedFiltersSet).forEach((filterKey) => {
      filterChangeValues.push(
        ...this.selectedFiltersSet[filterKey].map((optionKey) => ({ filterKey, optionKey })),
      );
    });

    this.onFiltersChange.emit(filterChangeValues);
  }

  private generateSelectedFiltersOptions() {
    this.selectedOptions = [];
    Object.keys(this.selectedFiltersSet).forEach((filterKey) => {
      this.selectedOptions.push(
        ...this.selectedFiltersSet[filterKey].map((optionKey) => {
          const filter = this.filters.find((f) => f.key === filterKey);
          const option = filter.options.find((o) => o.value === optionKey);

          return { prefix: filter.label, label: option.label, filterKey, optionKey };
        }),
      );
    });

    this.selectedOptions.sort(this.sortBylabel);
  }

  private sortBylabel(a, b): number {
    return a.label.localeCompare(b.label);
  }
}
