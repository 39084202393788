import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';

import { imgPreloader, logoAvatar } from '../utils';

@Component({
  selector: 'app-pick-list',
  templateUrl: './pick-list.component.html',
  styleUrls: ['./pick-list.component.scss'],
})
export class PickListComponent implements OnInit {
  @Input() public source?: any[] = [];
  @Input() public target?: any[] = [];
  @Output() public onSave = new EventEmitter<any[]>();

  imgPreloader = imgPreloader;
  logoAvatar = logoAvatar;

  constructor(private primengConfig: PrimeNGConfig) {}

  ngOnInit(): void {
    this.primengConfig.ripple = true;
  }

  onMoveAllToSource(event): void {
    this.onSave.emit(this.target);
  }

  onMoveAllToTarget(event): void {
    this.onSave.emit(this.target);
  }

  onSourceReorder(event): void {}

  onTargetReorder(event): void {
    this.onSave.emit(this.target);
  }

  onMoveToSource(event): void {
    this.onSave.emit(this.target);
  }

  onMoveToTarget(event): void {
    this.onSave.emit(this.target);
  }
}
