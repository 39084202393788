<ng-container *ngIf="loading">
  <app-loader></app-loader>
</ng-container>
<div
  *ngIf="!loading"
  class="page-container">
  <header class="c-mb-4">
    <h1 class="admin-page-title">{{ 'adminCourseAudiencePage.pageTitle' | translate }}</h1>
  </header>

  <p-divider styleClass="custom-divider-horizontal"></p-divider>

  <form
    [formGroup]="form"
    class="c-mt-8">
    <!-- Objectives block -->
    <div class="form-block c-my-9">
      <div class="left-side">
        <h2 class="title">{{ 'adminCourseAudiencePage.objectivesTitle' | translate }}</h2>
        <p class="description">{{ 'adminCourseAudiencePage.objectivesDesc' | translate }}</p>
      </div>
      <div class="right-side">
        <!-- Features textarea -->
        <div class="form-field-block c-mb-8">
          <app-input-label text="adminCourseAudiencePage.featuresLabel"></app-input-label>
          <p-editor
            styleClass="custom-editor"
            formControlName="features"
            placeholder="{{ 'adminCourseAudiencePage.featuresPlaceholder' | translate }}">
          </p-editor>
        </div>
      </div>
    </div>

    <p-divider styleClass="custom-divider-horizontal"></p-divider>

    <!-- Requirements block -->
    <div class="form-block c-my-9">
      <div class="left-side">
        <h2 class="title">{{ 'adminCourseAudiencePage.requirementsTitle' | translate }}</h2>
        <p class="description">{{ 'adminCourseAudiencePage.requirementsDesc' | translate }}</p>
      </div>
      <div class="right-side">
        <!-- Requirements textarea -->
        <div class="form-field-block c-mb-8">
          <app-input-label text="adminCourseAudiencePage.requirementsLabel"></app-input-label>
          <p-editor
            styleClass="custom-editor"
            formControlName="requirements"
            placeholder="{{ 'adminCourseAudiencePage.requirementsPlaceholder' | translate }}">
          </p-editor>
        </div>
      </div>
    </div>

    <p-divider styleClass="custom-divider-horizontal"></p-divider>

    <!-- Target Group block -->
    <div class="form-block c-my-9">
      <div class="left-side">
        <h2 class="title">{{ 'adminCourseAudiencePage.targetGroupTitle' | translate }}</h2>
        <p class="description">{{ 'adminCourseAudiencePage.targetGroupDesc' | translate }}</p>
      </div>
      <div class="right-side">
        <!-- Target Group textarea -->
        <div class="form-field-block c-mb-8">
          <app-input-label text="adminCourseAudiencePage.targetGroupLabel"></app-input-label>
          <p-editor
            styleClass="custom-editor"
            formControlName="targetGroup"
            placeholder="{{ 'adminCourseAudiencePage.targetGroupPlaceholder' | translate }}">
          </p-editor>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="save-discard-actions-container">
  <app-save-discard-actions
    [isShow]="canUpdate"
    [isLoading]="isUpdating"
    (discard)="onDiscard()"
    (confirm)="onConfirm()">
  </app-save-discard-actions>
</div>

<p-toast>
  <ng-template
    let-message
    pTemplate="message">
    <app-toast
      [severity]="message.severity"
      [summary]="message.summary"
      [detail]="message.detail">
    </app-toast>
  </ng-template>
</p-toast>
