import { IEmailSenderSettings } from '../common';

export interface IGeneralSettings {
  name: string;
  ownerId: string;
  slogan: string;
  isPrivate: boolean;
  enableEncryption: boolean;
  hideProfile: boolean;
  hideHelp: boolean;
  copyright: string;
  ivsRegion: string;
  ivsKey: string;
  ivsSecret: string;
  stripeConnectId: string;
  defaultEmailSenderSettings: IEmailSenderSettings;
  isMaintenance?: boolean;
  isStripeConnected?: boolean;
}
