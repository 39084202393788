import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

import { environment } from 'src/environments/environment';
import { AppStore } from 'src/app/app.store';
import { CryptoJSService } from '../crypto-js/crypto-js.service';
import { IEnvironment } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  private isEnvironmentLoadingSubject$ = new BehaviorSubject<boolean>(false);
  public isEnvironmentLoading$ = this.isEnvironmentLoadingSubject$.asObservable();

  private isEnvironmentReadySubject$ = new BehaviorSubject<boolean>(false);
  public isEnvironmentReady$ = this.isEnvironmentReadySubject$.asObservable();

  private ENVIRONMENT: IEnvironment = null;

  public get environment(): IEnvironment {
    return { ...this.ENVIRONMENT };
  }

  constructor(
    private http: HttpClient,
    private cryptoJSService: CryptoJSService,
    private appStore: AppStore,
  ) {}

  public async ConfigureEnvironment(): Promise<void> {
    if (!this.ENVIRONMENT) {
      await this.getEnvironment();
      this.appStore.setEnvironment(this.environment);
      this.isEnvironmentLoadingSubject$.next(true);
    } else {
      console.warn('ENVIRONMENT is already configured!');
    }
  }

  private async getEnvironment(): Promise<void> {
    try {
      const secret = this.cryptoJSService.generateCustomSecret();
      const environmentConfigEncoded = await this.http
        .post(`${environment.apiUrl}environment/config`, { s: secret }, { responseType: 'text' })
        .toPromise();
      console.log('ENV ---------------------------------');
      console.log(
        JSON.parse(this.cryptoJSService.decryptWithCustomSecret(environmentConfigEncoded, secret)),
      );
      console.log('--------------------------------- END ENV');
      this.ENVIRONMENT =
        typeof environmentConfigEncoded === 'string'
          ? JSON.parse(
              this.cryptoJSService.decryptWithCustomSecret(environmentConfigEncoded, secret),
            )
          : environmentConfigEncoded;
      this.isEnvironmentReadySubject$.next(true);
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }
}
