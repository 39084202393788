import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

import { IAsset, IEvent, ITag } from 'src/app/core/models';
import { LibraryService, CourseAssetsService, SessionAssetsService } from 'src/app/core/services';
import { CoursesStore, EventsStore, SessionsStore, TagsStore } from 'src/app/core/stores';
import {
  IAdminTableColumn,
  imgPreloader,
  jpgImg,
  logoAvatar,
  pdfImg,
  pngImg,
  videoImg,
  zipImg,
} from 'src/app/shared';
import { AppStore } from 'src/app/app.store';
import { AssetType, AssetFileTypes } from 'src/app/core/enums';
import { ButtonSize } from 'src/app/standalone';

@Component({
  templateUrl: './attach-assets-dialog.component.html',
  styleUrls: ['./attach-assets-dialog.component.scss'],
})
export class AttachAssetsDialogComponent implements OnInit {
  attachLoading = false;
  loadingEntries = true;
  entries: IAsset[] = [];
  selectedEntries: IAsset[] = [];
  totalRecords = 0;
  pageSize = 10;
  tags: ITag[] = [];
  selectedTags: ITag[] = [];
  imgPreloader = imgPreloader;
  logoAvatar = logoAvatar;
  columns: IAdminTableColumn[] = [
    { field: 'title', template: 'titleCell', titleTranslationKey: 'adminCourseMedia.tableTitle' },
    { field: 'type', titleTranslationKey: 'adminCourseMedia.type' },
    { field: 'isGlobal', template: 'statusCell', titleTranslationKey: 'Global' },
  ];
  isForLibrary = false;
  assetType = AssetType;
  assetEventBannerContainerStyles = {
    background: '#323232',
    'border-radius': '0.25rem',
  };
  assetEventBannerMonthStyles = {
    'font-size': '0.625rem',
    'line-height': '0.625rem',
  };
  assetEventBannerDateStyle = {
    'font-size': '1.125rem',
    'line-height': '1.125rem',
  };
  buttonSize = ButtonSize;

  private assetSearchTerm = '';
  private tagSearchTerm = '';
  private page = 0;

  constructor(
    private libraryService: LibraryService,
    private eventStore: EventsStore,
    private tagStore: TagsStore,
    private messageService: MessageService,
    private translateService: TranslateService,
    private courseStore: CoursesStore,
    private courseAssetService: CourseAssetsService,
    public appStore: AppStore,
    private dynamicDialogConfig: DynamicDialogConfig,
    private sessionAssetsService: SessionAssetsService,
    private eventsStore: EventsStore,
    private sessionsStore: SessionsStore,
    private ref: DynamicDialogRef,
  ) {}

  get filteredTags(): ITag[] {
    return this.tags.filter((tag) =>
      tag.title.toLowerCase().includes(this.tagSearchTerm.toLowerCase()),
    );
  }

  get event(): IEvent {
    return this.eventStore.adminEvent;
  }

  async ngOnInit(): Promise<void> {
    // This variable is to detect if this dialog is for course assets or for session assets
    this.isForLibrary = this.dynamicDialogConfig.data?.isForLibrary;
    await Promise.all([this.fetchAssets()]);
    if (!this.isForLibrary) {
      this.tags = this.tagStore.tags.filter((tag) =>
        this.courseStore.adminCourse?.tags?.includes(tag.id),
      );
    } else if (this.sessionsStore.adminSession.tags) {
      this.tags = this.tagStore.tags.filter((tag) =>
        this.sessionsStore.adminSession.tags.includes(tag.id),
      );
    }
    this.loadingEntries = false;
  }

  tagSearchChanged(value: string): void {
    this.tagSearchTerm = value;
  }

  async assetSearchChanged(value: string): Promise<void> {
    this.loadingEntries = true;
    this.assetSearchTerm = value;
    await this.fetchAssets();
    this.loadingEntries = false;
  }

  async fetchEntries(event: any): Promise<void> {
    this.loadingEntries = true;
    this.pageSize = event.rows;
    this.page = event.first / this.pageSize;
    await this.fetchAssets();
    this.loadingEntries = false;
  }

  async attach(): Promise<void> {
    if (!this.isForLibrary) {
      this.attachAssetsToCourse();
    } else {
      this.attachAssetsToSession();
    }
  }

  async tagSelectionChanged(tags: ITag[]): Promise<void> {
    this.selectedTags = tags;
    await this.fetchAssets();
  }

  getImagePlaceholder(asset: IAsset): string {
    if (asset.type === AssetType.VIDEO) {
      return videoImg;
    }

    switch (asset.fileType) {
      case AssetFileTypes.PNG:
        return pngImg;
      case AssetFileTypes.JPG:
        return jpgImg;
      case AssetFileTypes.PDF:
        return pdfImg;
      case AssetFileTypes.ZIP:
        return zipImg;
      default:
        return pngImg;
    }
  }

  onCloseDialog(): void {
    this.ref.close();
  }

  private async attachAssetsToCourse(): Promise<void> {
    this.attachLoading = true;

    try {
      await Promise.all(
        this.selectedEntries.map((asset: IAsset) =>
          this.courseAssetService.createCourseAsset(this.courseStore.adminCourse, asset),
        ),
      );
      this.messageService.add({
        key: 'asset',
        severity: 'success',
        summary: this.translateService.instant('success'),
        detail: this.translateService.instant('attachAssetsDialog.assetSuccessfullyAttached', {
          count: this.selectedEntries.length,
        }),
        styleClass: 'custom-toast',
      });
    } catch (error) {
      this.messageService.add({
        key: 'asset',
        severity: 'error',
        summary: this.translateService.instant('error'),
        detail: this.translateService.instant('application.toasters.error'),
        styleClass: 'custom-toast',
      });
    }

    this.attachLoading = false;
    this.selectedEntries = [];
    await this.fetchAssets();
  }

  private async attachAssetsToSession(): Promise<void> {
    this.attachLoading = true;

    try {
      await Promise.all(
        this.selectedEntries.map((asset: IAsset) =>
          this.sessionAssetsService.createSessionAsset(this.eventsStore.adminEvent.id, asset),
        ),
      );
      this.messageService.add({
        key: 'asset',
        severity: 'success',
        summary: this.translateService.instant('success'),
        detail: this.translateService.instant('attachAssetsDialog.assetSuccessfullyAttached', {
          count: this.selectedEntries.length,
        }),
        styleClass: 'custom-toast',
      });
    } catch (error) {
      this.messageService.add({
        key: 'asset',
        severity: 'error',
        summary: this.translateService.instant('error'),
        detail: this.translateService.instant('application.toasters.error'),
        styleClass: 'custom-toast',
      });
    }

    this.attachLoading = false;
    this.selectedEntries = [];
    await this.fetchAssets();
  }

  private async fetchAssets(): Promise<void> {
    if (!this.isForLibrary) {
      this.fetchAssetsForCourse();
    } else {
      this.fetchAssetsForSession();
    }
  }

  private async fetchAssetsForCourse(): Promise<void> {
    const response = await this.libraryService.fetchUnattached(
      this.courseStore.adminCourse.hubId,
      this.courseStore.adminCourse.id,
      this.page,
      this.pageSize,
      [...this.selectedTags.map((t) => t.id), this.assetSearchTerm].join(' '),
    );

    this.totalRecords = response.total;
    this.entries = response.results;
  }

  private async fetchAssetsForSession(): Promise<void> {
    const response = await this.libraryService.fetchUnattachedForSession(
      this.eventsStore.adminEvent.hubId,
      this.eventsStore.adminEvent.id,
      this.sessionsStore.adminSession.id,
      this.page,
      this.pageSize,
      [...this.selectedTags.map((t) => t.id), this.assetSearchTerm].join(' '),
    );

    this.totalRecords = response.total;
    this.entries = response.results;
  }
}
