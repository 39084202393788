<div class="container">
  <image
    [src]="notification.user.profileImage"
    [text]="imageText(notification.user)"
    size="super-small"></image>
  <div class="main-block">
    <div>
      <p
        class="text"
        [innerHTML]="notificationText"></p>
      <i
        class="fal fa-info-circle info-icon"
        [pTooltip]="notification.appointment.description"
        tooltipPosition="bottom">
      </i>
    </div>
    <div class="actions">
      <button
        class="confirm"
        pButton
        label="{{ 'buttons.accept' | translate }}"
        (click)="onAccept()"></button>
      <button
        class="cancel"
        pButton
        label="{{ 'buttons.decline' | translate }}"
        (click)="onDecline()"></button>
    </div>
  </div>
</div>
