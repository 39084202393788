import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { DropdownModule } from 'primeng/dropdown';
import { DividerModule } from 'primeng/divider';

import { IHub, IUser, IUserHub } from 'src/app/core/models';
import { HubsService } from 'src/app/core/services';
import { goToLink, trackByFn } from 'src/app/core/utils';
import { ChevronIconAnimation, DropDownAnimation } from './admin-header.animations';
import { HubsStore, UsersStore } from 'src/app/core/stores';
import { SharedModule, UserAccountNavigationComponent } from 'src/app/shared';
import { ClickOutsideDirective, NewAppVersionComponent } from 'src/app/standalone/shared';

@Component({
  selector: 'app-admin-header',
  standalone: true,
  imports: [
    CommonModule,
    DropdownModule,
    ClickOutsideDirective,
    DividerModule,
    UserAccountNavigationComponent,
    SharedModule,
    NewAppVersionComponent,
  ],
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.scss'],
  animations: [DropDownAnimation, ChevronIconAnimation],
})
export class AdminHeaderComponent implements OnInit {
  @Output() createHub: EventEmitter<void> = new EventEmitter();

  hubs: IHub[] = [];
  hubsLoading = true;
  trackByFn = trackByFn;
  expandDropdown = false;
  isOpen = false;
  selectedHub: IHub;
  isShowGlobalItem = true;

  get user(): IUser {
    return this.usersStore.user;
  }

  constructor(
    private hubsService: HubsService,
    private router: Router,
    private usersStore: UsersStore,
    public hubsStore: HubsStore,
  ) {}

  async ngOnInit(): Promise<void> {
    if (
      ['superAdmin', 'internalAdmin', 'instanceOwner', 'softwareDeveloper'].includes(this.user.role)
    ) {
      this.hubs = await this.hubsService.getAll();
      this.selectedHub = this.hubsStore.hub;
      this.hubsLoading = false;
    } else {
      this.usersStore.userHubs.map(async (userHub: IUserHub) => {
        const hub = await this.hubsService.getOne(userHub.hubId);
        this.hubs.push(hub);

        if (this.usersStore.userHubs.length === this.hubs.length) {
          this.hubs.sort(this.sortByTitle);
          this.selectedHub = this.hubsStore.hub || this.hubs[0] || null;
        }
      });
      this.isShowGlobalItem = false;
      this.hubsLoading = false;
    }
  }

  private sortByTitle(firstHub: IHub, secondHub: IHub): number {
    return firstHub.title.localeCompare(secondHub.title);
  }

  onToggleDropdown(): void {
    this.expandDropdown = !this.expandDropdown;
  }

  onCloseToggleDropdown(): void {
    this.expandDropdown = false;
  }

  onSelectHub(hub: IHub): void {
    this.selectedHub = hub;

    if (hub?.url && !hub.customDomain) {
      this.router.navigate([`${hub?.url ?? ''}/admin`]);
    } else {
      this.hubsStore.resetHub();
      this.router.navigate([`/admin`]);
    }
  }

  onCreateHub(): void {
    this.createHub.emit();
    this.onCloseToggleDropdown();
  }

  onNavigateToDashboard(): void {
    goToLink(`/${this.hubsStore.useHubUrl}/dashboard`);
  }
}
