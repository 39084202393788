<div
  class="asset-card"
  cdkDrag>
  <div class="banner-container">
    <ng-container
      *ngIf="asset.type !== assetType.EVENT || (asset.type === assetType.EVENT && asset.image)">
      <img
        *ngIf="asset?.image"
        class="banner"
        [defaultImage]="imgPreloader"
        [lazyLoad]="asset.image | imageSize" />
      <div
        *ngIf="!asset?.image"
        class="placeholder-container">
        <img
          class="placeholder"
          [src]="getImagePlaceholder(asset) | imageSize" />
      </div>
    </ng-container>
    <ng-container *ngIf="asset.type === assetType.EVENT && !asset.image">
      <div class="banner">
        <app-asset-event-banner
          [asset]="asset"
          [containerStyles]="assetEventBannerContainerStyles"
          [monthStyles]="assetEventBannerMonthStyles"
          [dateStyles]="assetEventBannerDateStyle">
        </app-asset-event-banner>
      </div>
    </ng-container>
    <div
      class="hover-block"
      cdkDragHandle>
      <i class="fa-regular fa-bars"></i>
    </div>
  </div>
  <div class="info-container">
    <p
      class="title"
      (click)="navigateToAsset($event)">
      {{ asset.title }}
    </p>
    <p class="short-description">{{ asset.shortDescription }}</p>
  </div>
  <div
    class="dropdown-container"
    (appClickOutside)="onCloseToggleDropdown()">
    <div
      class="dropdown"
      (click)="onToggleDropdown()">
      <div
        class="circle-mark"
        [ngClass]="getClassForCircleMark"></div>
      <span>{{ currentAssetStatus }}</span>
      <div
        [ngClass]="{ rotated: expandDropdown }"
        [@iconAnimation]="expandDropdown ? 'rotated' : 'initial'">
        <i class="fa-regular fa-chevron-down"></i>
      </div>
    </div>
    <ul
      *ngIf="expandDropdown"
      class="dropdown-list"
      [@dropDownMenu]>
      <li
        *ngFor="let status of assetStatuses; trackBy: trackByFn"
        class="dropdown-list-item"
        (click)="onSelectAssetStatus(status)">
        <div
          class="circle-mark"
          [ngClass]="{
            green: assetStatus[status.value] === assetStatus.PUBLISHED,
            orange: assetStatus[status.value] === assetStatus.DRAFT,
          }"></div>
        {{ status.label }}
      </li>
    </ul>
  </div>
  <i
    class="fa-regular fa-ellipsis-vertical vertical-dots"
    (click)="menu.toggle($event)"></i>
  <p-menu
    #menu
    [model]="items"
    [popup]="true"
    appendTo="body"
    styleClass="p-custom-menu"></p-menu>
</div>
