import { Timestamp } from 'src/app/firebase';

export interface IStage {
  id: string;
  name: string;
  _name_: string;
  description: string;
  icon: string;
  banner: string;
  ivsPreviewImage: File | string;
  streamProvider: string;
  streamKey: string;
  enableRecording: boolean;
  streamServer: string;
  streamLink: string;
  ivsChannelName: string;
  arn?: string;
  recordingConfigurationArn?: string;
  enableAutoReplay?: string;
  streamRecording?: string;
  sponsors: string[];
  brandPeople?: string[];
  moderators?: string[];
  speakers?: string[];
  tags: string[];
  order: number;
  isMainStage: boolean;
  featured: boolean;
  isChatVisible: boolean;
  isChatAnonymous: boolean;
  isChatAnonymousOneToOne: boolean;
  isAgendaVisible: boolean;
  isHideChatAtTheEndOfTheDay: boolean;
  updatedAt: any;
  updatedBy: string;
  createdAt: Timestamp;
  createdBy: string;
  docRef?: any;
  videoThumbnail?: string;
  isLiveStream?: boolean;
}
