import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

import { IEvent } from 'src/app/core/models';

@Component({
  selector: 'app-maintenance-dialog',
  templateUrl: './maintenance-dialog.component.html',
  styleUrls: ['./maintenance-dialog.component.scss'],
})
export class MaintenanceDialogComponent implements OnInit {
  event: IEvent;

  constructor(private config: DynamicDialogConfig) {}

  ngOnInit(): void {
    this.event = this.config.data;
  }
}
