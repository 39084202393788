<ng-container *ngIf="loading">
  <app-loader></app-loader>
</ng-container>

<div
  *ngIf="!loading"
  class="new-email-container">
  <h1>{{ 'adminEmailTemplate.newEmailTitle' | translate }}</h1>

  <div class="c-mt-4 c-mb-8">
    <p-divider styleClass="custom-divider-horizontal"></p-divider>
  </div>

  <app-system-email-editor *ngIf="viewMode === 'basic'"></app-system-email-editor>
  <app-system-email-html-editor *ngIf="viewMode === 'html'"></app-system-email-html-editor>
  <app-system-email-unlayer-editor *ngIf="viewMode === 'unlayer'"></app-system-email-unlayer-editor>
</div>
