<div
  #tagsslider
  (mousedown)="onMouseDown($event)"
  (mouseleave)="onMouseLeave()"
  (mousemove)="onMouseMove($event)"
  (mouseup)="onMouseUp()"
  [ngClass]="{
    'single-row': showAsSingleRow,
    'admin-theme': adminTheme,
  }"
  class="tags-list-container flex">
  <div
    *ngFor="let tag of possibleTags"
    class="tag-item"
    [ngClass]="{ selected: isTagSelected(tag), clickable: edit }"
    (click)="tagSelected(tag)">
    {{ tag.title }}
  </div>
</div>
