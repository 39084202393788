<div class="container">
  <div
    class="img-container"
    [class.default-img-container]="!profileImage">
    <img
      [class.img]="profileImage"
      [class.default-img]="!profileImage"
      [style.objectFit]="objectFit"
      [src]="profileImage ? profileImage : logoAvatar" />
  </div>
  <div class="upload-block">
    <h5 class="title">
      {{ titleMediaForm | translate }}
      <span
        *ngIf="isRequired"
        class="star"
        >*</span
      >
    </h5>
    <p class="description">{{ description | translate }}</p>
    <div class="actions">
      <input
        class="input"
        type="file"
        #profileImageUpload
        (change)="onProfileImageUpload($event)" />
      <div>
        <div class="btn-actions-container">
          <app-button
            buttonText="{{ btnConfirm | translate }}"
            [buttonSize]="buttonSize.SMALL"
            [loading]="profileImageUploadProcess"
            (click)="profileImageUpload.click()" />
          <app-button
            buttonText="{{ btnCancel | translate }}"
            [buttonStyle]="buttonStyle.TERTIARY_SECONDARY"
            [buttonSize]="buttonSize.SMALL"
            (click)="clearProfileImage()" />
        </div>
      </div>
    </div>
  </div>
</div>
