import { ITimeZone } from './../models/timezone.model';

export const utcTimeZones: ITimeZone[] = [
  { name: 'UTC−12:00 International Date Line West', code: 'UTC−12:00' },
  { name: 'UTC−11:00 Coordinated Unversal Time-11', code: 'UTC−11:00' },
  { name: 'UTC−10:00 Aleutian Islands', code: 'UTC−10:00' },
  { name: 'UTC−10:00 Hawaii', code: 'UTC−10:00' },
  { name: 'UTC−09:30 Marquesas Islands', code: 'UTC−09:30' },
  { name: 'UTC−09:00 Alaska', code: 'UTC−09:00' },
  { name: 'UTC−09:00 Coordinated Universal Time-09', code: 'UTC−09:00' },
  { name: 'UTC−08:00 Baja California', code: 'UTC−09:00' },
  { name: 'UTC−08:00 Coordinated Universal Time-08', code: 'UTC−08:00' },
  { name: 'UTC−08:00 Pacific Time (US & Canada)', code: 'UTC−08:00' },
  { name: 'UTC−07:00 Arizona', code: 'UTC−07:00' },
  { name: 'UTC−07:00 Chihuahua, La Paz, Mazatlan', code: 'UTC−07:00' },
  { name: 'UTC−07:00 Yukon', code: 'UTC−07:00' },
  { name: 'UTC−06:00 Central Time (US and Canada)', code: 'UTC−06:00' },
  { name: 'UTC−06:00 Central America', code: 'UTC−06:00' },
  { name: 'UTC−06:00 Guadalajara, Mexico City, Monterey', code: 'UTC−06:00' },
  { name: 'UTC−06:00 Saskatchewan', code: 'UTC−06:00' },
  { name: 'UTC−05:00 Bogota, Lima, Quito, Rio Branco', code: 'UTC−05:00' },
  { name: 'UTC−05:00 Chetumal', code: 'UTC−05:00' },
  { name: 'UTC−05:00 Eastern Time (US and Canada)', code: 'UTC−05:00' },
  { name: 'UTC−05:00 Haiti', code: 'UTC−05:00' },
  { name: 'UTC−05:00 Havana', code: 'UTC−05:00' },
  { name: 'UTC−05:00 Indiana (East)', code: 'UTC−05:00' },
  { name: 'UTC−05:00 Turks and Caicos', code: 'UTC−05:00' },
  { name: 'UTC−04:00 Asuncion', code: 'UTC−04:00' },
  { name: 'UTC−04:00 Atlantic Time (Canada)', code: 'UTC−04:00' },
  { name: 'UTC-04:00 Caracas', code: 'UTC-04:00' },
  { name: 'UTC−04:00 Cuiaba', code: 'UTC−04:00' },
  { name: 'UTC−04:00 Georgetown, La Paz, Manaus, San Juan', code: 'UTC−04:00' },
  { name: 'UTC−04:00 Santiago', code: 'UTC−04:00' },
  { name: 'UTC−03:30 Newfoundland', code: 'UTC−03:30' },
  { name: 'UTC−03:00 Araguaina', code: 'UTC−03:00' },
  { name: 'UTC−03:00 Brasilia', code: 'UTC−03:00' },
  { name: 'UTC−03:00 Cayenne, Fortaleza', code: 'UTC−03:00' },
  { name: 'UTC−03:00 Buenos Aires', code: 'UTC−03:00' },
  { name: 'UTC−03:00 Montevideo', code: 'UTC−03:00' },
  { name: 'UTC−03:00 Punta Arenas', code: 'UTC−03:00' },
  { name: 'UTC−03:00 Saint-Pierre and Maquelon', code: 'UTC−03:00' },
  { name: 'UTC−03:00 Salvador', code: 'UTC−03:00' },
  { name: 'UTC−02:00 Coordinated Universal Time-02', code: 'UTC−02:00' },
  { name: 'UTC−01:00 Cape Verde Is.', code: 'UTC−01:00' },
  { name: 'UTC−01:00 Cazores', code: 'UTC−01:00' },
  { name: 'UTC±00:00 Coordinated Universal Time', code: 'UTC±00:00' },
  { name: 'UTC+00:00 Dublin, Edinburgh, Lisbon, London', code: 'UTC+00:00' },
  { name: 'UTC+00:00 Monrovia, Reykjavik', code: 'UTC+00:00' },
  { name: 'UTC+01:00 Casablanca', code: 'UTC+01:00' },
  { name: 'UTC+01:00 Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna', code: 'UTC+01:00' },
  { name: 'UTC+01:00 Belgrade, Bratislavam Budapest, Ljubljana, Prague', code: 'UTC+01:00' },
  { name: 'UTC+01:00 Brussels, Copenhagen, Madrid, Paris', code: 'UTC+01:00' },
  { name: 'UTC+01:00 Sarajevo, Skopje, Warsaw, Zagreb', code: 'UTC+01:00' },
  { name: 'UTC+01:00 West Central Africa', code: 'UTC+01:00' },
  { name: 'UTC+02:00 Amman', code: 'UTC+02:00' },
  { name: 'UTC+02:00 Athens, Bucharest,', code: 'UTC+02:00' },
  { name: 'UTC+02:00 Beirut', code: 'UTC+02:00' },
  { name: 'UTC+02:00 Cairo', code: 'UTC+02:00' },
  { name: 'UTC+02:00 Chisinau', code: 'UTC+02:00' },
  { name: 'UTC+02:00 Damascus', code: 'UTC+02:00' },
  { name: 'UTC+02:00 Gaza, Hebron', code: 'UTC+02:00' },
  { name: 'UTC+02:00 Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius', code: 'UTC+02:00' },
  { name: 'UTC+02:00 Jerusalem', code: 'UTC+02:00' },
  { name: 'UTC+02:00 Juba', code: 'UTC+02:00' },
  { name: 'UTC+02:00 Kaliningrad', code: 'UTC+02:00' },
  { name: 'UTC+02:00 Khartoum', code: 'UTC+02:00' },
  { name: 'UTC+02:00 Tripoli', code: 'UTC+02:00' },
  { name: 'UTC+02:00 Windhoek', code: 'UTC+02:00' },
  { name: 'UTC+03:00 Baghdad, Minsk, Kuwait, Riyadh, Nairobi', code: 'UTC+03:00' },
  { name: 'UTC+03:00 Istanbul', code: 'UTC+03:00' },
  { name: 'UTC+03:00 Kuwait, Riyadh', code: 'UTC+03:00' },
  { name: 'UTC+03:00 Minsk', code: 'UTC+03:00' },
  { name: 'UTC+03:00 Moscow, St. Petersburg', code: 'UTC+03:00' },
  { name: 'UTC+03:00 Nairobi', code: 'UTC+03:00' },
  { name: 'UTC+03:00 Volgograd', code: 'UTC+03:00' },
  { name: 'UTC+03:30 Tehran', code: 'UTC+03:30' },
  { name: 'UTC+04:00 Abu Dhabi, Muscat', code: 'UTC+04:00' },
  { name: 'UTC+04:00 Astrakanm, Ulyanovsk', code: 'UTC+04:00' },
  { name: 'UTC+04:00 Baku', code: 'UTC+04:00' },
  { name: 'UTC+04:00 Izhevsk, Samara', code: 'UTC+04:00' },
  { name: 'UTC+04:00 Port Louis', code: 'UTC+04:00' },
  { name: 'UTC+04:00 Saratov', code: 'UTC+04:00' },
  { name: 'UTC+04:00 Tbilisi', code: 'UTC+04:00' },
  { name: 'UTC+04:00 Yerevan', code: 'UTC+04:00' },
  { name: 'UTC+04:30 Kabul', code: 'UTC+04:30' },
  { name: 'UTC+05:00 Ashgabad, Tashkent', code: 'UTC+05:00' },
  { name: 'UTC+05:00 Ekaterinburg', code: 'UTC+05:00' },
  { name: 'UTC+05:00 Islamabad, Karachi', code: 'UTC+05:00' },
  { name: 'UTC+05:00 Qyzylorda', code: 'UTC+05:00' },
  { name: 'UTC+05:30 Chennai, Kolkata, Mumbai, New Delhi', code: 'UTC+05:30' },
  { name: 'UTC+05:30 Sri Jayawardenepura', code: 'UTC+05:30' },
  { name: 'UTC+05:45 Kathmandu', code: 'UTC+05:45' },
  { name: 'UTC+06:00 Astana', code: 'UTC+06:00' },
  { name: 'UTC+06:00 Dhaka', code: 'UTC+06:00' },
  { name: 'UTC+06:00 Omsk', code: 'UTC+06:00' },
  { name: 'UTC+06:30 Yangon (Rangoon)', code: 'UTC+06:30' },
  { name: 'UTC+07:00 Bangkok, Hanoi, Jakarta, ', code: 'UTC+07:00' },
  { name: 'UTC+07:00 Bernaul, Gorno-Altaysk', code: 'UTC+07:00' },
  { name: 'UTC+07:00 Hovd', code: 'UTC+07:00' },
  { name: 'UTC+07:00 Krasnoyarsk', code: 'UTC+07:00' },
  { name: 'UTC+07:00 Novosibirsk', code: 'UTC+07:00' },
  { name: 'UTC+07:00 Tomsk', code: 'UTC+07:00' },
  { name: 'UTC+08:00 Beijing, Chongqing, Hong Kong, Urumqi', code: 'UTC+08:00' },
  { name: 'UTC+08:00 Irkutsk', code: 'UTC+08:00' },
  { name: 'UTC+08:00 Kuala Lumpur, Singapore', code: 'UTC+08:00' },
  { name: 'UTC+08:00 Perth', code: 'UTC+08:00' },
  { name: 'UTC+08:00 Taipei', code: 'UTC+08:00' },
  { name: 'UTC+08:00 Ulaanbaatar', code: 'UTC+08:00' },
  { name: 'UTC+08:45 Eucla', code: 'UTC+08:45' },
  { name: 'UTC+09:00 Osaka, Sapporo, Tokyo,', code: 'UTC+09:00' },
  { name: 'UTC+09:00 Pyongyang', code: 'UTC+09:00' },
  { name: 'UTC+09:00 Seoul', code: 'UTC+09:00' },
  { name: 'UTC+09:00 Yakutsk, ', code: 'UTC+09:00' },
  { name: 'UTC+09:30 Adelaide', code: 'UTC+09:30' },
  { name: 'UTC+09:30 Darwin', code: 'UTC+09:30' },
  { name: 'UTC+10:00 Brisbane', code: 'UTC+10:00' },
  { name: 'UTC+10:00 Canberra, Melbourne, Sydney', code: 'UTC+10:00' },
  { name: 'UTC+10:00 Guam, Port Moresby', code: 'UTC+10:00' },
  { name: 'UTC+10:00 Hobart', code: 'UTC+10:00' },
  { name: 'UTC+10:00 Vladivostok', code: 'UTC+10:00' },
  { name: 'UTC+10:30 Lord Howe Island', code: 'UTC+10:30' },
  { name: 'UTC+11:00 Bougainville Island', code: 'UTC+11:00' },
  { name: 'UTC+11:00 Chokurdakh', code: 'UTC+11:00' },
  { name: 'UTC+11:00 Magadan', code: 'UTC+11:00' },
  { name: 'UTC+11:00 Norfold Island', code: 'UTC+11:00' },
  { name: 'UTC+11:00 Sakhalin', code: 'UTC+11:00' },
  { name: 'UTC+11:00 Solomon Is., New Caledonia', code: 'UTC+11:00' },
  { name: 'UTC+12:00 Anadyr, Petropavlovsk-Kamchatksky', code: 'UTC+12:00' },
  { name: 'UTC+12:00 Auckland, Wellington', code: 'UTC+12:00' },
  { name: 'UTC+12:00 Coordinated Universal Time+12', code: 'UTC+12:00' },
  { name: 'UTC+12:00 Fiji', code: 'UTC+12:00' },
  { name: 'UTC+12:45 Chatham Islands', code: 'UTC+12:45' },
  { name: 'UTC+13:00 Coordinated Universal Time+13', code: 'UTC+13:00' },
  { name: 'UTC+13:00 Nuku-alofa', code: 'UTC+13:00' },
  { name: 'UTC+13:00 Samoa', code: 'UTC+13:00' },
  { name: 'UTC+14:00 Kiritimati Island', code: 'UTC+14:00' },
];
