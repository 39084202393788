import { ILanguage } from './../../models/translations/language.model';
import { parseToMoment } from '../../../shared/helpers/date-mapper';
import { Injectable } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Observable, BehaviorSubject, from } from 'rxjs';
import { TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Firestore, IFirestore } from 'src/app/firebase';
import { CollectionTypes } from 'src/app/shared';
import { UsersStore } from 'src/app/core/stores';
import { environment } from 'src/environments/environment';

export class TranslationLoader implements TranslateLoader {
  constructor(
    private http: HttpClient,
    private translatinsService: TranslationsService,
  ) {}

  getTranslation(lang: string): Observable<any> {
    return from(this.translatinsService.getTranslationsByCode(lang));
  }
}
@Injectable({
  providedIn: 'root',
})
export class TranslationsService {
  private translationsLoadingSubject$ = new BehaviorSubject<boolean>(true);
  public translationsLoading$ = this.translationsLoadingSubject$.asObservable();

  private currentLangSubject$ = new BehaviorSubject<any>(null);
  public currentLang$ = this.currentLangSubject$.asObservable();

  private firestore: IFirestore;

  constructor(
    private translateService: TranslateService,
    private usersStore: UsersStore,
  ) {
    this.firestore = Firestore();

    if (environment.branch === 'eventshub') {
      translateService.addLangs(['en', 'de', 'bg']);
      translateService.setDefaultLang('bg');
    } else {
      translateService.addLangs(['en', 'de']);
      translateService.setDefaultLang('de');
    }

    const browserLang = translateService.getBrowserLang();
    const selectedLang: string = localStorage.getItem('language') || null;

    if (selectedLang) {
      translateService.use(selectedLang);
    } else {
      translateService.use(browserLang.match(/en|de|bg/) ? browserLang : 'de');
      localStorage.setItem('language', this.translateService.currentLang);
    }

    translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.translationsLoadingSubject$.next(true);
      this.getTranslationsByCode(this.getCurrentLanguage).then((translations) => {
        translateService.setTranslation(this.getCurrentLanguage, translations);
        this.currentLangSubject$.next(event);
        this.translationsLoadingSubject$.next(false);
      });
    });
  }

  public get getLanguages(): string[] {
    return this.translateService.getLangs();
  }

  public get getCurrentLanguage(): string {
    return this.translateService.currentLang;
  }

  public async setLanguage(language: string): Promise<void> {
    // update the total messages read by the logged user

    if (language && this.usersStore && this.usersStore.user && this.usersStore.user.id) {
      await this.firestore
        .collection('users')
        .doc(this.usersStore.user.id)
        .set({ systemLanguage: language }, { merge: true });
    }

    this.translateService.use(language);
    localStorage.setItem('language', language);
    moment.locale(language);
  }

  public getTranslationSync(key: string | Array<string>, interpolateParams?: any): string {
    return this.translateService.instant(key, interpolateParams);
  }

  public getTranslation(
    key: string | Array<string>,
    interpolateParams?: any,
  ): Observable<string | any> {
    return this.translateService.get(key);
  }

  async getTranslationsById(id: string): Promise<JSON> {
    try {
      const translations = (
        await this.firestore.collection(CollectionTypes.TRANSLATIONS).doc(id).get()
      ).data() as JSON;

      return translations;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getTranslationsByCode(code: string): Promise<JSON> {
    try {
      const translations = await this.firestore
        .collection(CollectionTypes.TRANSLATIONS)
        .where('code', '==', code)
        .get();
      console.log('----------------------------------');
      console.log('TRANSLATIONS: ', translations.docs[0].data().translations);
      console.log('----------------------------------');

      if (!translations.empty) return translations.docs[0].data().translations as JSON;
      return {} as JSON;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  public async getAllLanguagesOrderedByTitle(forceSync: boolean = false): Promise<ILanguage[]> {
    try {
      const languagesQuery = await this.firestore
        .collection(CollectionTypes.TRANSLATIONS)
        .orderBy('_title_')
        .get();
      const languages = [];

      if (languagesQuery.size > 0) {
        languagesQuery.forEach((doc) => {
          const lang = doc.data();

          lang.timeStamp = lang.createdAt ? parseToMoment(lang.createdAt).format('ll') : '-';
          lang.updateTimeStamp = lang.updatedAt ? parseToMoment(lang.updatedAt).format('ll') : '-';

          languages.push(lang);
        });
      }

      return languages as ILanguage[];
    } catch (error) {
      return [];
    }
  }

  public async remove(tagDocId: string): Promise<boolean> {
    try {
      await this.firestore.collection(CollectionTypes.TRANSLATIONS).doc(tagDocId).delete();
      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  public async create(language: ILanguage): Promise<ILanguage> {
    try {
      const preLangReq = await this.firestore.collection(CollectionTypes.TRANSLATIONS).doc();
      language.id = preLangReq.id;
      await this.firestore
        .collection(CollectionTypes.TRANSLATIONS)
        .doc(language.id)
        .set({ ...language });
      return language;
    } catch (error) {
      console.error(error);

      return null;
    }
  }

  public async update(id: string, payload: any) {
    try {
      const langDocument = this.firestore.collection(CollectionTypes.TRANSLATIONS).doc(id);
      await langDocument.update({ ...payload });
      return payload;
    } catch (error) {
      throw error;
    }
  }
}
