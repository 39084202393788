@if (loading()) {
  <app-loader></app-loader>
} @else {
  <form
    class="container"
    [formGroup]="form">
    <!-- User images & interests block -->
    <div class="form-block c-mb-9">
      <div class="left-side">
        <h2 class="title">{{ 'adminUserProfile.editDesignTitle' | translate }}</h2>
        <p class="description">{{ 'adminUserProfile.editDesignDesc' | translate }}</p>
      </div>
      <div class="right-side">
        <!-- Profile image -->
        <div class="form-field-block c-mb-4">
          <app-small-profile-picture-media-form
            titleMediaForm="adminUserProfile.stepProfileLookImageTitle"
            description="adminUserProfile.stepProfileLookImageDesc"
            btnConfirm="upload"
            btnCancel="clear"
            [imgValue]="form.controls.profileImage.value"
            (changeValue)="setProfileImageValue($event)">
          </app-small-profile-picture-media-form>
        </div>

        <!-- Cover image -->
        <div class="form-field-block c-mb-4">
          <app-cover-picture-media-form
            titleMediaForm="adminUserProfile.stepProfileLookCoverTitle"
            description="adminUserProfile.stepProfileLookCoverDesc"
            btnConfirm="upload"
            btnCancel="clear"
            [imgValue]="form.controls.coverImage.value"
            (changeValue)="setCoverImageValue($event)">
          </app-cover-picture-media-form>
        </div>

        <!-- Bio textarea -->
        <div class="form-field-block c-mb-4">
          <app-input-label text="adminUserAppearance.bioLabel"></app-input-label>
          <p-editor
            styleClass="custom-editor"
            formControlName="bio"
            placeholder="{{ 'adminUserAppearance.bioPlaceholder' | translate }}">
          </p-editor>
        </div>

        <!-- Tags -->
        <div class="form-field-block">
          <app-input-label text="adminUserProfile.tags"></app-input-label>
          @if (tags()) {
            <app-tags-multi-list
              [possibleTags]="tags()"
              [selectedTags]="form.controls.tags.value"
              [adminTheme]="true"
              (onSelected)="selectTags($event)">
            </app-tags-multi-list>
          }
        </div>
      </div>
    </div>

    <p-divider styleClass="custom-divider-horizontal"></p-divider>

    <!-- Social media accounts block -->
    <div class="form-block c-my-9">
      <div class="left-side">
        <h2 class="title">{{ 'adminUserProfile.socialTitle' | translate }}</h2>
        <p class="description">{{ 'adminUserProfile.socialDescription' | translate }}</p>
      </div>
      <div class="right-side">
        <!-- Website input -->
        <div class="form-field-block">
          <app-input-label
            for="website-input"
            text="adminUserAppearance.websiteLabel">
          </app-input-label>
          <div class="p-inputgroup custom-input-group">
            <span class="p-inputgroup-addon">URL</span>
            <input
              class="custom-input-text custom-input-text-required"
              formControlName="website"
              id="website-input"
              pInputText
              type="text"
              placeholder="{{ 'adminUserAppearance.websitePlaceholder' | translate }}" />
          </div>
          <app-input-error-message
            [showMessage]="form.controls.website.invalid && form.controls.website.touched"
            errorMessage="{{ 'adminUserProfile.invalidLink' | translate }}">
          </app-input-error-message>
        </div>

        <!-- Linkedin input -->
        <div class="form-field-block">
          <app-input-label
            for="linkedin-input"
            text="adminUserAppearance.linkedinLabel">
          </app-input-label>
          <div class="p-inputgroup custom-input-group">
            <span class="p-inputgroup-addon">URL</span>
            <input
              class="custom-input-text custom-input-text-required"
              formControlName="linkedin"
              id="linkedin-input"
              pInputText
              type="text"
              placeholder="{{ 'adminUserAppearance.linkedinPlaceholder' | translate }}" />
          </div>
          <app-input-error-message
            [showMessage]="form.controls.linkedin.invalid && form.controls.linkedin.touched"
            errorMessage="{{ 'adminUserProfile.invalidLink' | translate }}">
          </app-input-error-message>
        </div>

        <!-- Twitter input -->
        <div class="form-field-block">
          <app-input-label
            for="twitter-input"
            text="adminUserAppearance.twitterLabel">
          </app-input-label>
          <div class="p-inputgroup custom-input-group">
            <span class="p-inputgroup-addon">URL</span>
            <input
              class="custom-input-text custom-input-text-required"
              formControlName="twitter"
              id="twitter-input"
              pInputText
              type="text"
              placeholder="{{ 'adminUserAppearance.twitterPlaceholder' | translate }}" />
          </div>
          <app-input-error-message
            [showMessage]="form.controls.twitter.invalid && form.controls.twitter.touched"
            errorMessage="{{ 'adminUserProfile.invalidLink' | translate }}">
          </app-input-error-message>
        </div>

        <!-- Facebook input -->
        <div class="form-field-block">
          <app-input-label
            for="facebook-input"
            text="adminUserAppearance.facebookLabel">
          </app-input-label>
          <div class="p-inputgroup custom-input-group">
            <span class="p-inputgroup-addon">URL</span>
            <input
              class="custom-input-text custom-input-text-required"
              formControlName="facebook"
              id="facebook-input"
              pInputText
              type="text"
              placeholder="{{ 'adminUserAppearance.facebookPlaceholder' | translate }}" />
          </div>
          <app-input-error-message
            [showMessage]="form.controls.facebook.invalid && form.controls.facebook.touched"
            errorMessage="{{ 'adminUserProfile.invalidLink' | translate }}">
          </app-input-error-message>
        </div>

        <!-- Instagram input -->
        <div class="form-field-block">
          <app-input-label
            for="instagram-input"
            text="adminUserAppearance.instagramLabel">
          </app-input-label>
          <div class="p-inputgroup custom-input-group">
            <span class="p-inputgroup-addon">URL</span>
            <input
              class="custom-input-text custom-input-text-required"
              formControlName="instagram"
              id="instagram-input"
              pInputText
              type="text"
              placeholder="{{ 'adminUserAppearance.instagramPlaceholder' | translate }}" />
          </div>
          <app-input-error-message
            [showMessage]="form.controls.instagram.invalid && form.controls.instagram.touched"
            errorMessage="{{ 'adminUserProfile.invalidLink' | translate }}">
          </app-input-error-message>
        </div>

        <!-- Youtube input -->
        <div class="form-field-block">
          <app-input-label
            for="youtube-input"
            text="adminUserAppearance.youtubeLabel">
          </app-input-label>
          <div class="p-inputgroup custom-input-group">
            <span class="p-inputgroup-addon">URL</span>
            <input
              class="custom-input-text custom-input-text-required"
              formControlName="youtube"
              id="youtube-input"
              pInputText
              type="text"
              placeholder="{{ 'adminUserAppearance.youtubePlaceholder' | translate }}" />
          </div>
          <app-input-error-message
            [showMessage]="form.controls.youtube.invalid && form.controls.youtube.touched"
            errorMessage="{{ 'adminUserProfile.invalidLink' | translate }}">
          </app-input-error-message>
        </div>

        <!-- Xing input -->
        <div class="form-field-block">
          <app-input-label
            for="xing-input"
            text="adminUserAppearance.xingLabel">
          </app-input-label>
          <div class="p-inputgroup custom-input-group">
            <span class="p-inputgroup-addon">URL</span>
            <input
              class="custom-input-text custom-input-text-required"
              formControlName="xing"
              id="xing-input"
              pInputText
              type="text"
              placeholder="{{ 'adminUserAppearance.xingPlaceholder' | translate }}" />
          </div>
          <app-input-error-message
            [showMessage]="form.controls.xing.invalid && form.controls.xing.touched"
            errorMessage="{{ 'adminUserProfile.invalidLink' | translate }}">
          </app-input-error-message>
        </div>

        <!-- Calendly input -->
        <div class="form-field-block">
          <app-input-label
            for="calendly-input"
            text="adminUserAppearance.calendlyLabel">
          </app-input-label>
          <div class="p-inputgroup custom-input-group">
            <span class="p-inputgroup-addon">URL</span>
            <input
              class="custom-input-text custom-input-text-required"
              formControlName="calendly"
              id="calendly-input"
              pInputText
              type="text"
              placeholder="{{ 'adminUserAppearance.calendlyPlaceholder' | translate }}" />
          </div>
          <app-input-error-message
            [showMessage]="form.controls.calendly.invalid && form.controls.calendly.touched"
            errorMessage="{{ 'adminUserProfile.invalidLink' | translate }}">
          </app-input-error-message>
        </div>

        <!-- Allow chat input -->
        <div class="form-field-input-switch-block c-mb-4">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="allowChat"
            inputId="allow-chat-input">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="allow-chat-input"
            text="adminUserProfile.chatLabel">
          </app-input-label>
        </div>

        <!-- Allow appointment input -->
        <div class="form-field-input-switch-block">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="allowAppointment"
            inputId="allow-appointment-input">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="allow-appointment-input"
            text="adminUserProfile.allowAppointmentLabel">
          </app-input-label>
        </div>
      </div>
    </div>
  </form>
}

<div class="fixed right-0 bottom-0 left-0">
  <app-save-discard-actions
    [isShow]="canUpdate"
    [isLoading]="isUpdating()"
    (discard)="onDiscard()"
    (confirm)="onConfirm()">
  </app-save-discard-actions>
</div>

<p-toast>
  <ng-template
    let-message
    pTemplate="message">
    <app-toast
      [severity]="message.severity"
      [summary]="message.summary"
      [detail]="message.detail">
    </app-toast>
  </ng-template>
</p-toast>
