@if (loading()) {
  <app-loader></app-loader>
} @else {
  <div class="messages-container">
    <div class="messages-table-container">
      <admin-table-header-navbar title="{{ 'messages.title' | translate }}">
        <app-button
          buttonText="{{ 'messages.attachBtnLabel' | translate }}"
          buttonIconLeft="fa-solid fa-envelope-circle-check"
          [buttonSize]="buttonSize().MEDIUM"
          (click)="attach()" />
      </admin-table-header-navbar>

      <div class="c-my-4">
        <p-divider styleClass="custom-divider-horizontal"></p-divider>
      </div>

      <admin-table-filter-navbar (onSearchTermChange)="searchValue.set($event)">
      </admin-table-filter-navbar>

      <admin-table
        [loading]="loadingEntries()"
        [columns]="columns()"
        [entries]="courseEmails()"
        [paginator]="true"
        [lazy]="false"
        [searchValue]="searchValue()"
        [searchFilterFields]="searchFilterFields()"
        emptyStateKey="messages.noCourseEmailsText"
        emptyStateTitleKey="messages.noCourseEmailsTitle"
        (rowClicked)="onRowClicked($event)">
        <ng-template
          let-entry
          epTemplateKey="langCell">
          <span
            class="flag-icon flag-icon-squared flag-icon-circle flag-icon-{{
              entry.language === 'en' ? 'gb' : entry.language
            }}">
          </span>
        </ng-template>

        <ng-template
          let-entry
          epTemplateKey="statusCell">
          <app-tag
            [iconClass]="entry.status ? 'fa-regular fa-check' : 'fa-regular fa-xmark'"
            tagText="{{
              (entry.status ? 'adminTable.statusActive' : 'adminTable.statusInactive') | translate
            }}"
            [bgColor]="entry.status ? 'var(--appPrimaryColor)' : '#ff244c'">
          </app-tag>
        </ng-template>

        <ng-template
          let-entry
          epTemplateKey="audienceCell">
          <span>{{ capitalizeFirstLetter(entry.audience) }}</span>
        </ng-template>

        <ng-template
          let-entry
          epTemplateKey="courseActionsCell">
          <span>{{ entry.action }}</span>
        </ng-template>

        <ng-template
          let-entry
          epTemplateKey="actionsCell">
          <div class="table-btn-actions-container">
            <app-button
              [buttonType]="buttonType().ROUND"
              [buttonSize]="buttonSize().MEDIUM"
              [buttonStyle]="buttonStyle().FILLED_SECONDARY"
              buttonRoundIcon="far fa-pen-to-square"
              (click)="edit($event, entry)" />
            <app-button
              [buttonType]="buttonType().ROUND"
              [buttonSize]="buttonSize().MEDIUM"
              [buttonStyle]="buttonStyle().FILLED_DANGER"
              buttonRoundIcon="far fa-trash-can"
              (click)="remove($event, entry)" />
          </div>
        </ng-template>
      </admin-table>
    </div>
  </div>
}

<p-toast>
  <ng-template
    let-message
    pTemplate="message">
    <app-toast
      [severity]="message.severity"
      [summary]="message.summary"
      [detail]="message.detail">
    </app-toast>
  </ng-template>
</p-toast>
