<ng-container *ngIf="loading">
  <app-loader class="tickets-dialog-loading-container"></app-loader>
</ng-container>

<div
  *ngIf="!loading"
  class="tickets-dialog-container">
  <header class="header">{{ event.title }}</header>
  <p-divider styleClass="custom-divider-horizontal"></p-divider>
  <main class="main">
    <ng-container *ngIf="tickets.length">
      <div
        *ngFor="let ticket of tickets; trackBy: trackByFn"
        class="ticket-item">
        <app-single-ticket
          [ticket]="ticket"
          [isSelected]="ticket.id === selectedTicket?.id"
          (selectTicket)="onTicket(ticket)"></app-single-ticket>
      </div>
    </ng-container>
    <div
      *ngIf="!tickets.length"
      class="mt-8">
      <app-empty-state
        icon="far fa-ticket"
        text="{{ 'adminEventsTickets.emptyState' | translate }}">
      </app-empty-state>
    </div>
  </main>
  <footer
    *ngIf="tickets.length"
    class="footer">
    <button
      class="primary-text-color"
      pButton
      label="{{ 'multiTicketingTicket.checkout' | translate }}"
      type="button"
      [disabled]="isBtnDisabled"
      (click)="onCheckout()"></button>
  </footer>
</div>
