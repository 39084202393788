<div class="container">
  <div
    class="dropdown-container"
    (appClickOutside)="onCloseToggleDropdown()">
    <div
      class="dropdown"
      (click)="onToggleDropdown()">
      <div class="dropdown-left-part">
        <span class="first-line single-line">
          {{ !!selectedHub ? selectedHub.title : ('adminHeader.global' | translate) }}
        </span>
        <span class="second-line">
          {{
            !!selectedHub
              ? ('adminHeader.currentHub' | translate)
              : ('adminHeader.selectHub' | translate)
          }}
        </span>
      </div>
      <div
        [ngClass]="{ rotated: expandDropdown }"
        [@iconAnimation]="expandDropdown ? 'rotated' : 'initial'">
        <i class="fa-regular fa-chevron-down chevron-icon"></i>
      </div>
    </div>
    <ul
      *ngIf="expandDropdown"
      class="dropdown-list"
      [@dropDownMenu]>
      <li
        *ngIf="selectedHub && isShowGlobalItem"
        class="dropdown-list-item"
        (click)="onSelectHub(null)">
        <i class="fa-regular fa-globe icon-globe"></i>
        {{ 'adminHeader.global' | translate }}
      </li>
      <li
        *ngIf="selectedHub"
        class="dropdown-list-item divider">
        <p-divider styleClass="custom-divider-horizontal"></p-divider>
      </li>
      <li
        class="dropdown-list-item"
        (click)="onCreateHub()">
        <i class="fa-solid fa-plus"></i>
        {{ 'adminHeader.createHub' | translate }}
      </li>
      <li
        *ngIf="selectedHub"
        class="dropdown-list-item divider">
        <p-divider styleClass="custom-divider-horizontal"></p-divider>
      </li>
      <ng-container *ngIf="!hubsLoading">
        <li
          *ngFor="let hub of hubs; trackBy: trackByFn"
          class="dropdown-list-item"
          (click)="onSelectHub(hub)">
          {{ hub.title }}
        </li>
      </ng-container>
    </ul>
  </div>

  <button
    class="admin-header-btn"
    (click)="onNavigateToDashboard()">
    <i class="far fa-globe"></i>
    <span>{{ 'mainNavigation.viewSiteBtn' | translate }}</span>
  </button>

  <app-new-app-version></app-new-app-version>
  <app-notification-bell></app-notification-bell>
  <app-user-account-navigation></app-user-account-navigation>
</div>
