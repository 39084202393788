<div class="main-navigation-container grid align-items-center">
  <div class="lg:col-3 md:col-3 sm:col-12 com-12 left col flex align-items-center">
    <div
      *ngIf="eventsStore.isEventRegistered"
      class="flex align-items-center menu-bar-icon">
      <i
        class="far fa-bars"
        (click)="appStore.setSidebarVisible(true)">
      </i>
    </div>
    <img
      class="img-preloader"
      [defaultImage]="defaultImage"
      [lazyLoad]="logo | imageSize"
      (click)="onLogoClick()" />
  </div>

  <div
    class="lg:col-6 md:col-6 sm:col-12 col-12 center col flex align-items-center justify-content-center psi">
    <app-search *ngIf="!hideSearch && !searchLoading"></app-search>
  </div>

  <nav
    *ngIf="isShowMainNav"
    class="main-nav-container">
    <ul class="list">
      <li class="list-item">
        <a
          class="link"
          routerLink="{{ hubsStore.useHubUrl }}/dashboard"
          routerLinkActive="active">
          {{ 'userDashboard.menu.dashboard' | translate }}
        </a>
      </li>
      <li
        *ngIf="isVisibleSidebarItem('events')"
        class="list-item">
        <a
          class="link"
          routerLink="{{ hubsStore.useHubUrl }}/events"
          routerLinkActive="active">
          {{ 'userDashboard.menu.events' | translate }}
        </a>
      </li>
      <li
        *ngIf="isVisibleSidebarItem('academy')"
        class="list-item">
        <a
          class="link"
          routerLink="{{ hubsStore.useHubUrl }}/courses"
          routerLinkActive="active">
          {{ 'userDashboard.menu.courses' | translate }}
        </a>
      </li>
    </ul>
  </nav>

  <div class="lg:col-3 md:col-3 sm:col-12 col-12 right col flex align-items-center c-pr-8">
    <button
      *ngIf="
        (isAuthenticated$ | async) === false && hubsStore.hub?.isSalesforceSSO && !isLogoutScreen
      "
      class="w-and-v-btn"
      (click)="openWVSigninDialog()">
      <img [src]="WVLogo | imageSize" />
      <span>{{ 'signin.signupWithWV' | translate }}</span>
    </button>

    <button
      *ngIf="(isAuthenticated$ | async) === false && !isLogoutScreen && hubsStore.hub"
      class="auth-btn sign-up-btn"
      (click)="onSignUp()">
      {{ 'mainNavigation.signUpBtn' | translate }}
    </button>

    <button
      *ngIf="(isAuthenticated$ | async) === false && !isLogoutScreen && hubsStore.hub"
      class="auth-btn sign-in-btn"
      (click)="onSignIn()">
      {{ 'mainNavigation.login_btn' | translate }}
    </button>

    <ng-container *ngIf="hubsStore.hub || (!hubsStore.hub && isGlobalSingleRoute)">
      <button
        *ngIf="
          !isAdminPanel &&
          (isAuthenticated$ | async) &&
          ((freshUser &&
            (freshUser.role === 'superAdmin' ||
              freshUser.role === 'internalAdmin' ||
              freshUser.role === 'instanceOwner' ||
              freshUser?.role === 'softwareDeveloper')) ||
            (hubsStore.hub && canEditHub(hubsStore.hub.id))) &&
          !showUpgradeButton
        "
        class="admin-btn"
        routerLink="{{ hubsStore.useHubUrl }}/admin">
        <i class="far fa-cog"></i>
        <span>{{ 'mainNavigation.admin_btn' | translate }}</span>
      </button>
    </ng-container>

    <button
      *ngIf="showUpgradeButton"
      class="admin-btn"
      (click)="openMultiTicketingDialog()">
      <i class="far fa-rocket"></i>
      <span>{{ 'mainNavigation.upgradeBtn' | translate }}</span>
    </button>

    <div
      *ngIf="isAuthenticated$ | async"
      class="notifications">
      <app-new-app-version></app-new-app-version>
      <app-notification-bell></app-notification-bell>
    </div>

    <div *ngIf="(isAuthenticated$ | async) && usersStore.user">
      <app-user-account-navigation></app-user-account-navigation>
    </div>
  </div>
</div>
