import { Timestamp } from 'src/app/firebase';
import { EventConsentType } from 'src/app/core/enums';

export interface IEventConsent {
  id: string;
  title: string;
  _title_: string;
  description: string;
  options: { title: string; _title_: string; description: string }[];
  order: number;
  isDeleted: boolean;
  required: boolean;
  type: EventConsentType;
  isSetLive: boolean;
  createdAt: Timestamp;
  createdBy: string;
  updatedAt: Timestamp;
  updatedBy: string;
  isActionBtnShowingSpinner?: boolean;
}
