<div class="eventbrite-sync-dialog-container">
  <h1>{{ 'adminEventSettings.synchronizeEventbriteData' | translate }}</h1>
  <p>{{ 'adminEventSettings.synchronizeEventbriteDataText' | translate }}</p>

  <div class="actions-container">
    <button
      type="button"
      pButton
      label="{{ 'adminEventSettings.syncAndEmailBtn' | translate }}"
      (click)="ref.close('syncAndEmail')"></button>
    <button
      type="button"
      pButton
      label="{{ 'adminEventSettings.syncOnlyBtn' | translate }}"
      (click)="ref.close('sync')"></button>
    <button
      class="cancel-btn"
      type="button"
      pButton
      label="{{ 'adminEventSettings.cancelBtn' | translate }}"
      (click)="ref.close()"></button>
  </div>
</div>
