import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

import {
  ConfirmDialogComponent,
  IAdminTableFilterChange,
  IAdminTableFilter,
  IAdminTableColumn,
} from 'src/app/shared';
import { ITag, IUser, AlgoliaSearchResult } from 'src/app/core/models';
import { TagsService, UsersService, HubsService } from 'src/app/core/services';
import { HubsStore } from 'src/app/core/stores';
import { getUserNameAbbreviation } from 'src/app/core/utils';
import { AppStore } from 'src/app/app.store';
import { NewUserComponent } from 'src/app/admin/dialogs/new-user/new-user.component';
import { ButtonSize, ButtonStyle, ButtonType } from 'src/app/standalone';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss'],
})
export class UsersListComponent implements OnInit {
  loading = true;
  loadingEntries = true;
  columns: IAdminTableColumn[] = [
    {
      field: 'profileImage',
      template: 'logoCell',
      titleTranslationKey: 'adminUsers.tableColImage',
    },
    { field: 'firstName', titleTranslationKey: 'adminUsers.tableColFirstName' },
    { field: 'lastName', titleTranslationKey: 'adminUsers.tableColLastName' },
    { field: 'email', titleTranslationKey: 'adminUsers.tableColEmail' },
    { field: 'company', titleTranslationKey: 'adminUsers.tableColCompany' },
    { field: 'position', titleTranslationKey: 'adminUsers.tableColPosition' },
    { field: 'isActive', titleTranslationKey: 'adminUsers.tableColStatus', template: 'statusCell' },
    { field: 'actions', template: 'actionsCell' },
  ];
  entries: IUser[] = [];
  totalRecords = 0;
  pageSize = 25;
  tags: ITag[] = [];
  tableFilters: IAdminTableFilter[] = [];
  imageText = getUserNameAbbreviation;
  sidebarVisible = false;
  userIdForSidebar: string;
  buttonSize = ButtonSize;
  buttonType = ButtonType;
  buttonStyle = ButtonStyle;

  private searchTerm = '';
  private selectedTags: string[] = [];

  constructor(
    private messageService: MessageService,
    private dialogService: DialogService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private usersService: UsersService,
    private tagsService: TagsService,
    public hubsStore: HubsStore,
    private hubsService: HubsService,
    private translateService: TranslateService,
    public appStore: AppStore,
  ) {}

  async ngOnInit(): Promise<void> {
    if (this.hubsStore.hub) {
      const tagsIdsArray = await this.tagsService.getHubTagsIdsArray(this.hubsStore.hub.id);
      this.tags = await this.tagsService.getByIdsOrderedByTitle(tagsIdsArray);
    } else {
      this.tags = await this.tagsService.getAll();
    }

    this.tableFilters = [
      {
        key: 'TAGS',
        label: 'adminUsers.tagsFilterLabel',
        options: this.tags.map((t) => ({ label: t.title, value: t.id })),
      },
    ];

    await this.fetchUsers(0);
    this.loadingEntries = false;
    this.loading = false;
  }

  async filtersChange(e: IAdminTableFilterChange[]): Promise<void> {
    this.loadingEntries = true;
    this.selectedTags = e.filter((f) => f.filterKey === 'TAGS').map((f) => f.optionKey);
    await this.fetchUsers(0);
    this.loadingEntries = false;
  }

  async onSearchTermChange(e: string): Promise<void> {
    this.loadingEntries = true;
    this.searchTerm = e;
    await this.fetchUsers(0);
    this.loadingEntries = false;
  }

  async fetchEntries(event: any): Promise<void> {
    this.loadingEntries = true;
    this.pageSize = event.rows;
    await this.fetchUsers(event.first / this.pageSize);
    this.loadingEntries = false;
  }

  async openNewUserDialog(): Promise<void> {
    const newUserDialogRef: DynamicDialogRef = this.dialogService.open(NewUserComponent, {
      styleClass: 'attach-new-user-dialog',
      closable: false,
      data: {
        userType: this.hubsStore.hub ? 'userHub' : 'user',
        hub: this.hubsStore.hub ?? null,
      },
    });

    const id: string = await newUserDialogRef.onClose.pipe(take(1)).toPromise();
    if (id) {
      this.router.navigate([`/${this.hubsStore.useHubUrl}/admin/user/${id}/profile`]);
    } else {
      await this.fetchUsers(0);
    }
  }

  onCloseSidebar(): void {
    this.sidebarVisible = false;
  }

  edit(event: Event, entry: IUser): void {
    event.stopPropagation();
    if (this.hubsStore.hub.isShowSidePanel) {
      this.sidebarVisible = true;
      this.userIdForSidebar = entry.id;

      return;
    }

    this.router.navigate([`/${this.hubsStore.useHubUrl}/admin/user/${entry.id}`], {
      relativeTo: this.activatedRoute,
      state: { entry },
    });
  }

  async unAttachUser(event: Event, entry: IUser): Promise<void> {
    event.stopPropagation();
    const deleteDialogRef = this.dialogService.open(ConfirmDialogComponent, {
      closable: false,
      styleClass: 'confirm-dialog',
      data: {
        titleKey: 'adminHubTeam.deleteMember',
        descriptionKey: 'adminHubTeam.deleteMemberText',
        confirmBtnKey: 'adminHubTeam.confirmBtn',
        cancelBtnKey: 'adminHubTeam.cancelBtn',
        entry,
      },
    });

    const result: 'cancel' | 'confirm' = await firstValueFrom(deleteDialogRef.onClose);
    if (result === 'confirm') {
      this.loading = true;
      try {
        const removeMember = await this.hubsService.removeMember(entry.userHubId);
        if (removeMember) {
          this.removeEntry(entry);
          this.messageService.add({
            severity: 'success',
            summary: this.translateService.instant('success'),
            detail: this.translateService.instant('adminHubTeam.succesDeleteMember'),
            styleClass: 'custom-toast',
          });
        }
      } catch (error) {
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('success'),
          detail: this.translateService.instant('adminHubTeam.errorDeleteMember'),
          styleClass: 'custom-toast',
        });
      }
      this.loading = false;
    }
  }

  onRowClicked(id: string): void {
    this.router.navigate([`/${this.hubsStore.useHubUrl}/admin/user/${id}`]);
  }

  private async fetchUsers(page: number): Promise<void> {
    let response: AlgoliaSearchResult<IUser> = null;

    if (!!this.hubsStore.hub) {
      response = await this.usersService.fetchByHub(
        this.hubsStore.hub ? this.hubsStore.hub.id : null,
        page,
        this.pageSize,
        [...this.selectedTags, this.searchTerm].join(' '),
      );
    } else {
      response = await this.usersService.fetch(
        page,
        this.pageSize,
        [...this.selectedTags, this.searchTerm].join(' '),
      );
    }

    if (this.appStore.generalSystemSettings.enableEncryption) {
      console.log(
        `ALGOLIA users-list fetchUsers & fetchByHub: ${!!this.hubsStore.hub} response.results `,
        response.results,
      );
      response.results = response.results.map((b) => {
        console.log('res', b);
        return this.usersService.decryptUserData(b, ['_highlightResult', 'objectID']) as IUser;
      });
      console.log(`↑ ALGOLIA users-list fetchUsers & fetchByHub: ${!!this.hubsStore.hub}`);
    }

    this.entries = response.results;
    this.totalRecords = response.total;
  }

  private removeEntry(entry: IUser): void {
    this.entries.splice(this.entries.indexOf(entry), 1);
    this.totalRecords -= 1;
  }
}
