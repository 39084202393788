<ng-container
  *ngIf="
    ((isEnvironmentLoading$ | async) !== true &&
      (isAuthLoading$ | async) !== true &&
      (themesService.isSettingsLoading$ | async) !== true &&
      (isTranslationsLoading$ | async) !== true) ||
    (isShowLoader$ | async)
  ">
  <app-loader></app-loader>
</ng-container>

<ng-container
  *ngIf="
    (isEnvironmentLoading$ | async) &&
    (isAuthLoading$ | async) &&
    (isSystemSettingsLoading$ | async) !== true &&
    (isTranslationsLoading$ | async) !== true &&
    (isShowLoader$ | async) !== true
  ">
  <header
    *ngIf="isHeaderAndFooterVisible"
    [ngClass]="{
      'not-logged': (isAuthenticated$ | async) !== true,
      hidden: isAdminView,
    }">
    <app-main-navigation class="main-header"></app-main-navigation>
    <mobile-header class="mobile-header"></mobile-header>
  </header>
  <main
    [ngClass]="{
      'not-logged': (isAuthenticated$ | async) !== true,
      'header-hidden': !isHeaderAndFooterVisible || isAdminView,
      'footer-hidden': (footerHidden && isMobile) || isAdminView,
    }">
    <aside
      *ngIf="appStore.isAsideNavigationVisible"
      class="app-main-aside">
      <app-aside-navigation></app-aside-navigation>
    </aside>

    <section
      id="scrollableContent"
      [ngClass]="{
        'aside-nav-hidden':
          !appStore.isAsideNavigationVisible || (isAuthenticated$ | async) !== true,
        'mobile-view': isMobile,
      }">
      <div id="main">
        <router-outlet></router-outlet>
      </div>

      <footer
        *ngIf="isHeaderAndFooterVisible"
        [ngClass]="{
          'user-dashboard-active': userDashboardActive || brandDashboardActive,
          'full-footer': !hideFullFooter(),
          hidden: isAdminView,
        }">
        <app-footer-navigation></app-footer-navigation>
      </footer>
      <p-scrollTop
        *ngIf="isShowScrollTop"
        [threshold]="200"
        target="parent">
      </p-scrollTop>
    </section>
  </main>

  <p-sidebar
    class="desktop-block mobile-none"
    [modal]="false"
    [baseZIndex]="10000"
    [showCloseIcon]="false"
    [(visible)]="appStore.isSideBarVisible"
    styleClass="sidebar-navigation-dialog">
    <app-sidebar-navigation></app-sidebar-navigation>
  </p-sidebar>
  <app-chat></app-chat>
  <!-- class="mobile-none" eventsStore.isActive && !eventsStore.isOnDemandMode -->
  <mobile-event-navigation
    *ngIf="appStore.isAsideNavigationVisible"
    class="desktop-none mobile-block">
  </mobile-event-navigation>
  <app-topics-sidebar></app-topics-sidebar>
  <mobile-navigation
    *ngIf="isMobileNavigationVisible && showNavigation() && !isAdminView"
    class="mobile-navigation">
  </mobile-navigation>
</ng-container>

<div
  *ngIf="isShowSpecialFooterForLogoutUsers"
  class="special-footer">
  <div>
    <span class="title preview-title">{{ 'footerForUnregisteredUsers.title' | translate }}</span>
    <span class="text">{{ 'footerForUnregisteredUsers.text' | translate }}</span>
  </div>
  <button
    type="button"
    (click)="onSignUp()">
    <span>{{ 'footerForUnregisteredUsers.btnText' | translate }}</span>
    <i class="ml-2 pi pi-arrow-right"></i>
  </button>
</div>

<div
  #adBanner
  style="height: 1px; width: 1px; visibility: hidden"></div>

<p-toast>
  <ng-template
    let-message
    pTemplate="message">
    <app-toast
      [severity]="message.severity"
      [summary]="message.summary"
      [detail]="message.detail">
    </app-toast>
  </ng-template>
</p-toast>
