import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { Subject, filter, tap, takeUntil } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';

import { IHub, IUser, IUserHub, UserHubRoles } from 'src/app/core/models';
import { goToLink } from 'src/app/core/utils';
import { HELP_URL } from 'src/app/standalone/shared';
import { HubsStore } from 'src/app/core/stores';

@Component({
  selector: 'app-admin-sidebar-with-hub',
  standalone: true,
  imports: [CommonModule, TranslateModule, NgOptimizedImage, RouterModule],
  templateUrl: './admin-sidebar-with-hub.component.html',
  styleUrls: ['./admin-sidebar-with-hub.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminSidebarWithHubComponent implements OnInit, OnDestroy {
  @Input() logo: string;
  @Input() hub: IHub;
  @Input() user: IUser;
  @Input() userHub: IUserHub;

  private currentUrl: string;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private router: Router,
    private cd: ChangeDetectorRef,
    public hubsStore: HubsStore,
  ) {}

  get isShowGlobalItem(): boolean {
    return (
      this.user &&
      ['superAdmin', 'internalAdmin', 'instanceOwner', 'softwareDeveloper'].includes(this.user.role)
    );
  }

  get isShowHelpIcon(): boolean {
    return this.userHub
      ? this.userHub.role === UserHubRoles.ADMIN || this.userHub.role === UserHubRoles.OWNER
      : false;
  }

  ngOnInit(): void {
    this.currentUrl = this.router.url.split('/').slice(0, 4).join('/');
    this.router.events
      .pipe(
        filter((event: NavigationEnd) => event instanceof NavigationEnd),
        tap((event: NavigationEnd) => {
          this.currentUrl = event.url.split('/').slice(0, 4).join('/');
          this.cd.detectChanges();
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();
  }

  isActiveRoute(
    url:
      | 'event'
      | 'courses'
      | 'library'
      | 'user'
      | 'brand'
      | 'hub-settings'
      | 'system-settings'
      | 'system-settings'
      | 'reports',
  ): boolean {
    return this.currentUrl.includes(url);
  }

  onHelp(): void {
    goToLink(HELP_URL);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
