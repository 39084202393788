import { Timestamp } from 'src/app/firebase';

export interface IAppointmentUser {
  appointmentId: string;
  approved: boolean;
  eventId: string;
  id: string;
  isHost: boolean;
  userId: string;
  token?: string;
  declineReason?: string;
  createdAt?: Timestamp;
  createdBy?: string;
  updatedAt?: Timestamp;
  updatedBy?: string;
}
