import firebase from 'firebase/compat/app';
import { IBrand } from '../brand/brand.model';
import { IUser } from '../user';
import { IEvent } from './event.model';

export interface IEventBrand {
  id: string;
  eventId: string;
  brandId: string;
  _brand_name_: string;
  _event_title_: string;
  createdAt: firebase.firestore.FieldValue;
  createdBy: string;
  level: string;
  name: string;
  tags: Array<string>;
  updatedAt: firebase.firestore.FieldValue;
  updatedBy: string;
}

export class EventBrand {
  static create(event: IEvent, brand: IBrand, user: IUser, level = ''): Partial<IEventBrand> {
    return {
      _brand_name_: brand.name,
      _event_title_: event.title,
      brandId: brand.id,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      createdBy: user.id,
      eventId: event.id,
      level,
      name: null,
      tags: [],
      updatedAt: null,
      updatedBy: null,
    };
  }
}
