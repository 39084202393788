import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';

import { BrandsService } from 'src/app/core/services';
import {
  ConfirmDialogComponent,
  imgPreloader,
  logoAvatar,
  IAdminTableColumn,
} from 'src/app/shared';
import { IBrand, IUser } from 'src/app/core/models';
import { HubsStore, BrandsStore } from 'src/app/core/stores';
import { ButtonSize, ButtonStyle, ButtonType } from 'src/app/standalone';
import { AttachUsersToBrandDialogComponent } from '../../../dialogs/attach-users-to-brand/attach-users-to-brand-dialog.component';

@Component({
  selector: 'app-brand-team',
  templateUrl: './brand-team.component.html',
  styleUrls: ['./brand-team.component.scss'],
})
export class BrandTeamComponent implements OnInit {
  loading = true;
  loadingEntries = false;
  searchValue = '';
  searchFilterFields: string[] = [
    'firstName',
    'lastName',
    'email',
    'company',
    'position',
    'brandRole',
  ];
  columns: IAdminTableColumn[] = [
    {
      field: 'profileImage',
      template: 'logoCell',
      titleTranslationKey: 'adminBrandTeam.tableColImage',
    },
    { field: 'firstName', titleTranslationKey: 'adminBrandTeam.tableColFirstName', sortable: true },
    { field: 'lastName', titleTranslationKey: 'adminBrandTeam.tableColLastName', sortable: true },
    { field: 'email', titleTranslationKey: 'adminBrandTeam.tableColEmail', sortable: true },
    { field: 'company', titleTranslationKey: 'adminBrandTeam.tableColCompany', sortable: true },
    { field: 'position', titleTranslationKey: 'adminBrandTeam.tableColPosition', sortable: true },
    { field: 'brandRole', titleTranslationKey: 'adminBrandTeam.tableColTeamRole', sortable: true },
    { field: 'actions', template: 'actionsCell' },
  ];
  entries: IUser[] = [];
  brandId: string = null;
  imgPreloader = imgPreloader;
  logoAvatar = logoAvatar;
  sidebarVisible = false;
  userIdForSidebar: string;
  buttonSize = ButtonSize;
  buttonType = ButtonType;
  buttonStyle = ButtonStyle;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    public hubsStore: HubsStore,
    private brandsService: BrandsService,
    private brandsStore: BrandsStore,
    private messageService: MessageService,
    private translateService: TranslateService,
  ) {
    this.brandId = this.route.parent.snapshot.paramMap.get('brandId');
  }

  get brand(): IBrand {
    return this.brandsStore.adminBrand;
  }

  async ngOnInit(): Promise<void> {
    await this.fetchMembers();
    this.loadingEntries = false;
    this.loading = false;
  }

  async openAttachUserDialog(): Promise<void> {
    const dialogRef: DynamicDialogRef = this.dialogService.open(AttachUsersToBrandDialogComponent, {
      width: '90%',
      height: '80%',
      closable: false,
      data: {
        members: this.entries,
      },
    });

    await dialogRef.onClose.pipe(take(1)).toPromise();
    await this.fetchMembers();
  }

  onCloseSidebar(): void {
    this.sidebarVisible = false;
  }

  edit(event: Event, entry: IUser): void {
    event.stopPropagation();
    if (this.hubsStore.hub.isShowSidePanel) {
      this.sidebarVisible = true;
      this.userIdForSidebar = entry.id;

      return;
    }

    this.router.navigate([`/${this.hubsStore.useHubUrl}/admin/user/${entry.id}`], {
      relativeTo: this.route,
      state: {
        user: entry,
      },
    });
  }

  async remove(event: Event, entry): Promise<void> {
    event.stopPropagation();
    if (entry.brandRole === 'owner') {
      return;
    }

    const deleteDialogRef = this.dialogService.open(ConfirmDialogComponent, {
      closable: false,
      styleClass: 'confirm-dialog',
      data: {
        titleKey: 'adminBrandTeam.deleteMember',
        descriptionKey: 'adminBrandTeam.deleteMemberText',
        confirmBtnKey: 'adminBrandTeam.confirmBtn',
        cancelBtnKey: 'adminBrandTeam.cancelBtn',
        entry,
      },
    });

    deleteDialogRef.onClose.pipe(take(1)).subscribe(async (result: 'cancel' | 'confirm') => {
      if (result === 'confirm') {
        try {
          const deleteResult = await this.brandsService.removeMember(entry.brandUserId);
          this.entries = [];
          this.loadingEntries = true;
          if (deleteResult) {
            this.messageService.add({
              severity: 'success',
              summary: this.translateService.instant('success'),
              detail: this.translateService.instant('adminBrandTeam.succesDeleteMember'),
              styleClass: 'custom-toast',
            });
          }
          await this.fetchMembers();
          this.loadingEntries = false;
        } catch (error) {
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('success'),
            detail: this.translateService.instant('adminBrandTeam.errorDeleteMember'),
            styleClass: 'custom-toast',
          });
        }
      }
    });
  }

  onRowClicked(id: string): void {
    if (this.hubsStore.hub.isShowSidePanel) {
      this.sidebarVisible = true;
      this.userIdForSidebar = id;

      return;
    }

    this.router.navigate([`/${this.hubsStore.useHubUrl}/admin/user/${id}`]);
  }

  private async fetchMembers(): Promise<void> {
    this.entries = await this.brandsService.getBrandAllMembers(this.brandId);
  }
}
