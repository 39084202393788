@if (loading()) {
  <app-loader></app-loader>
} @else {
  <form
    class="container"
    [formGroup]="form">
    <!-- Identification block -->
    <div class="form-block c-mb-9">
      <div class="left-side">
        <h2 class="title">{{ 'adminUserProfile.identificationTitle' | translate }}</h2>
        <p class="description">{{ 'adminUserProfile.identificationDesc' | translate }}</p>
      </div>
      <div class="right-side">
        <!-- Travel document input -->
        <div class="form-field-block c-mb-4">
          <app-input-label
            for="travel-document-input"
            text="adminUserMore.travelDocumentLabel">
          </app-input-label>
          <p-dropdown
            class="custom-dropdown"
            panelStyleClass="custom-dropdown-panel"
            [options]="travelDocumentTypes()"
            formControlName="travelDocument"
            inputId="travel-document-input"
            placeholder="{{ 'adminUserMore.travelDocumentPlaceholder' | translate }}">
            <ng-template
              pTemplate="item"
              let-item>
              {{ item | translate }}
            </ng-template>
            <ng-template
              pTemplate="selectedItem"
              let-selectedItem>
              {{ selectedItem | translate }}
            </ng-template>
          </p-dropdown>
        </div>

        <!-- Passport number input -->
        <div class="form-field-block c-mb-4">
          <app-input-label
            for="passport-number-input"
            text="adminUserMore.passportNumberLabel">
          </app-input-label>
          <input
            class="custom-input-text"
            formControlName="passportNumber"
            id="passport-number-input"
            pInputText
            type="text"
            placeholder="{{ 'adminUserMore.passportNumberPlaceholder' | translate }}" />
        </div>

        <div class="two-elements-block c-mb-4">
          <!-- Date of issue input -->
          <div>
            <app-input-label
              for="date-of-issue-input"
              text="adminUserMore.dateOfIssueLabel">
            </app-input-label>
            <p-calendar
              class="custom-calendar"
              formControlName="dateOfIssue"
              [monthNavigator]="true"
              [yearNavigator]="true"
              [maxDate]="dateForValidation()"
              [showIcon]="true"
              dateFormat="dd/mm/yy"
              yearRange="1900:2050"
              inputId="date-of-issue-input"
              icon="fak fa-lox-calendar"
              placeholder="{{ 'adminUserMore.dateOfIssuePlaceholder' | translate }}">
            </p-calendar>
          </div>

          <!-- Date of expiry input -->
          <div>
            <app-input-label
              for="date-of-expiry-input"
              text="adminUserMore.dateOfExpiryLabel">
            </app-input-label>
            <p-calendar
              class="custom-calendar"
              formControlName="dateOfExpiry"
              [monthNavigator]="true"
              [yearNavigator]="true"
              [showIcon]="true"
              dateFormat="dd/mm/yy"
              yearRange="1900:2050"
              inputId="date-of-expiry-input"
              icon="fak fa-lox-calendar"
              placeholder="{{ 'adminUserMore.dateOfExpiryPlaceholder' | translate }}">
            </p-calendar>
          </div>
        </div>

        <!-- Nationality input -->
        <div class="form-field-block c-mb-9">
          <app-input-label
            for="nationality-input"
            text="adminUserMore.nationalityLabel">
          </app-input-label>
          <p-dropdown
            class="custom-dropdown"
            panelStyleClass="custom-dropdown-panel"
            [filter]="true"
            [options]="countriesArray()"
            [virtualScroll]="true"
            [virtualScrollItemSize]="virtualScrollItemSize()"
            formControlName="nationality"
            optionLabel="name"
            inputId="nationality-input"
            placeholder="{{ 'adminUserMore.nationalityPlaceholder' | translate }}">
            <ng-template
              pTemplate="item"
              let-item>
              {{ item.name | translate }}
            </ng-template>
            <ng-template
              pTemplate="selectedItem"
              let-selectedItem>
              {{ selectedItem.name | translate }}
            </ng-template>
          </p-dropdown>
        </div>

        <p-divider styleClass="custom-divider-horizontal"></p-divider>

        <!-- Date of birth input -->
        <div class="form-field-block c-mt-9">
          <app-input-label
            for="date-of-birth-input"
            text="adminUserMore.birthDateLabel">
          </app-input-label>
          <p-calendar
            class="custom-calendar"
            formControlName="birthDate"
            [monthNavigator]="true"
            [yearNavigator]="true"
            [showIcon]="true"
            dateFormat="dd/mm/yy"
            yearRange="1900:2050"
            inputId="date-of-birth-input"
            icon="fak fa-lox-calendar"
            placeholder="{{ 'adminUserMore.birthDatePlaceholder' | translate }}">
          </p-calendar>
        </div>
      </div>
    </div>

    <p-divider styleClass="custom-divider-horizontal"></p-divider>

    <!-- Other block -->
    <div class="form-block c-my-9">
      <div class="left-side">
        <h2 class="title">{{ 'adminUserMore.otherTitle' | translate }}</h2>
        <p class="description">{{ 'adminUserMore.otherDesc' | translate }}</p>
      </div>
      <div class="right-side">
        <!-- Emergency contact name input -->
        <div class="form-field-block c-mb-4">
          <app-input-label
            for="emergency-contact-name-input"
            text="adminUserMore.emergencyContactNameLabel">
          </app-input-label>
          <input
            class="custom-input-text"
            formControlName="emergencyContactName"
            id="emergency-contact-name-input"
            pInputText
            type="text"
            placeholder="{{ 'adminUserMore.emergencyContactNamePlaceholder' | translate }}" />
        </div>

        <!-- Emergency contact phone input -->
        <div class="form-field-block c-mb-4">
          <app-input-label
            for="emergency-contact-phone-input"
            text="adminUserMore.emergencyContactPhoneLabel">
          </app-input-label>
          <input
            class="custom-input-text"
            formControlName="emergencyContactPhone"
            id="emergency-contact-phone-input"
            pInputText
            type="text"
            placeholder="{{ 'adminUserMore.emergencyContactPhonePlaceholder' | translate }}" />
        </div>

        <!-- Vegetarian input -->
        <div class="form-field-block c-mb-4">
          <app-input-label
            for="vegetarian-input"
            text="adminUserMore.vegetarianLabel">
          </app-input-label>
          <p-dropdown
            class="custom-dropdown"
            panelStyleClass="custom-dropdown-panel"
            [options]="vegetarianTypes()"
            formControlName="vegetarian"
            inputId="vegetarian-input"
            placeholder="{{ 'adminUserMore.vegetarianPlaceholder' | translate }}">
            <ng-template
              pTemplate="item"
              let-item>
              {{ item | translate }}
            </ng-template>
            <ng-template
              pTemplate="selectedItem"
              let-selectedItem>
              {{ selectedItem | translate }}
            </ng-template>
          </p-dropdown>
        </div>

        <!-- Clothing size input -->
        <div class="form-field-block c-mb-4">
          <app-input-label
            for="clothing-size-input"
            text="adminUserMore.clothingSizeLabel">
          </app-input-label>
          <p-dropdown
            class="custom-dropdown"
            panelStyleClass="custom-dropdown-panel"
            [options]="clothingSizes()"
            [virtualScroll]="true"
            [virtualScrollItemSize]="virtualScrollItemSize()"
            formControlName="clothingSize"
            inputId="clothing-size-input"
            placeholder="{{ 'adminUserMore.clothingSizePlaceholder' | translate }}">
            <ng-template
              pTemplate="item"
              let-item>
              {{ item | translate }}
            </ng-template>
            <ng-template
              pTemplate="selectedItem"
              let-selectedItem>
              {{ selectedItem | translate }}
            </ng-template>
          </p-dropdown>
        </div>

        <!-- Shoe size input -->
        <div class="form-field-block">
          <app-input-label
            for="shoe-size-input"
            text="adminUserMore.shoeSizeLabel">
          </app-input-label>
          <input
            class="custom-input-text"
            formControlName="shoeSize"
            id="shoe-size-input"
            pInputText
            type="text"
            placeholder="{{ 'adminUserMore.shoeSizePlaceholder' | translate }}" />
        </div>
      </div>
    </div>
  </form>
}

<div class="fixed right-0 bottom-0 left-0">
  <app-save-discard-actions
    [isShow]="canUpdate"
    [isLoading]="isUpdating()"
    (discard)="onDiscard()"
    (confirm)="onConfirm()">
  </app-save-discard-actions>
</div>

<p-toast>
  <ng-template
    let-message
    pTemplate="message">
    <app-toast
      [severity]="message.severity"
      [summary]="message.summary"
      [detail]="message.detail">
    </app-toast>
  </ng-template>
</p-toast>
