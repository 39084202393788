import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';

import { AssetFileTypes } from 'src/app/core/enums';
import { logoAvatar } from 'src/app/shared/utils';
import { ButtonSize, ButtonStyle } from 'src/app/standalone';
import { ButtonComponent } from 'src/app/standalone/shared/components';

@Component({
  selector: 'app-file-media-form',
  standalone: true,
  imports: [TranslateModule, ButtonComponent],
  templateUrl: './file-media-form.component.html',
  styleUrl: './file-media-form.component.scss',
})
export class FileMediaFormComponent {
  @Input() titleMediaForm: string;
  @Input() description: string;
  @Input() btnConfirm: string;
  @Input() btnCancel: string;
  @Input() fileValue: string;
  @Input() fileType: AssetFileTypes;
  @Input() allowedFileTypes: string[] = [
    'image/png',
    'image/jpeg',
    'image/jpg',
    'application/pdf',
    'application/zip',
    'application/x-zip-compressed',
  ];
  @Input() maxFileSize = 20_000_000; // 20MB
  @Output() changeValue = new EventEmitter<{
    file: File;
    fileType: AssetFileTypes;
    fileSize: number;
  }>();
  @ViewChild('fileUpload') fileUploadInput: ElementRef<HTMLInputElement>;

  assetFileTypes = AssetFileTypes;
  fileUploadProcess = false;
  logoAvatar = logoAvatar;
  buttonStyle = ButtonStyle;
  buttonSize = ButtonSize;

  private newFilePreview: string;

  constructor(
    private messageService: MessageService,
    private translateService: TranslateService,
    private sanitizer: DomSanitizer,
  ) {}

  get fileImage(): string | ArrayBuffer | SafeUrl {
    if (this.fileUploadProcess) {
      return null;
    }

    return this.newFilePreview && typeof this.newFilePreview === 'string'
      ? this.sanitizer.bypassSecurityTrustUrl(this.newFilePreview)
      : this.fileValue;
  }

  async onFileUpload(event: Event): Promise<void> {
    const selectedFile: File = (event.target as HTMLInputElement).files[0];

    // check if the selected image format is allowed
    if (!this.allowedFileTypes.includes(selectedFile.type)) {
      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('error'),
        detail: this.translateService.instant(
          'adminLibraryAssetFrom.fileTypeNotAllowedErrorMessage',
        ),
        styleClass: 'custom-toast',
      });
      this.fileUploadInput.nativeElement.value = '';
      return;
    }

    if (selectedFile.size > this.maxFileSize) {
      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('error'),
        detail: this.translateService.instant(
          'adminLibraryAssetFrom.fileSizeNotAllowedErrorMessage',
          { size: this.maxFileSize / (1024 * 1024) },
        ),
        styleClass: 'custom-toast',
      });
      return;
    }

    let fileType: AssetFileTypes;
    let loadImagePreview = false;
    switch (selectedFile.type) {
      case 'image/png':
        fileType = AssetFileTypes.PNG;
        loadImagePreview = true;
        break;
      case 'image/jpeg':
      case 'image/jpg':
        fileType = AssetFileTypes.JPG;
        loadImagePreview = true;
        break;
      case 'application/pdf':
        fileType = AssetFileTypes.PDF;
        break;
      case 'application/zip':
      case 'application/x-zip-compressed':
        fileType = AssetFileTypes.ZIP;
        break;
    }

    if (loadImagePreview) {
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onloadend = () => {
        this.newFilePreview = reader.result as string;
      };
    }
    this.changeValue.emit({ file: selectedFile, fileType, fileSize: selectedFile.size });
  }

  clearFile(): void {
    this.newFilePreview = null;
    this.fileUploadInput.nativeElement.value = '';
    this.changeValue.emit(null);
  }
}
