import { Component, OnInit } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { Router } from '@angular/router';

import { trackByFn } from 'src/app/core/utils';
import {
  StatCardComponent,
  CustomProgressBarComponent,
  AssetEventBannerComponent,
} from 'src/app/standalone/shared';
import {
  IAdminTableColumn,
  SharedModule,
  imgPreloader,
  logoAvatar,
  videoImg,
  jpgImg,
  pdfImg,
  pngImg,
  zipImg,
} from 'src/app/shared';
import {
  CourseAssetsService,
  CourseSubscribersService,
  CourseTrackingService,
  StripeService,
} from 'src/app/core/services';
import { CoursesStore, HubsStore } from 'src/app/core/stores';
import { AssetFileTypes, AssetType } from 'src/app/core/enums';
import { IAsset, IStripeReport } from 'src/app/core/models';

@Component({
  selector: 'app-reports',
  standalone: true,
  imports: [
    CommonModule,
    StatCardComponent,
    SharedModule,
    CustomProgressBarComponent,
    AssetEventBannerComponent,
  ],
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
  providers: [CurrencyPipe],
})
export class ReportsComponent implements OnInit {
  loading = true;
  loadingEntries = true;
  trackByFn = trackByFn;
  statCards: { iconClass: string; text: string; value: string }[];
  columns: IAdminTableColumn[] = [];
  allEntries: any = [];
  entries: any = [];
  totalRecords = 0;
  pageSize = 10;
  imgPreloader = imgPreloader;
  logoAvatar = logoAvatar;
  zipImg = zipImg;
  assetType = AssetType;
  assets: IAsset[] = [];
  courseId: string;
  searchFilterFields: string[] = [];
  searchTerm = '';
  assetEventBannerContainerStyles = {
    background: '#323232',
    'border-radius': '0.25rem',
  };
  assetEventBannerMonthStyles = {
    'font-size': '0.625rem',
    'line-height': '0.625rem',
  };
  assetEventBannerDateStyle = {
    'font-size': '1.125rem',
    'line-height': '1.125rem',
  };

  constructor(
    private courseTrackingService: CourseTrackingService,
    private stripeService: StripeService,
    private coursesStore: CoursesStore,
    private courseSubscribersService: CourseSubscribersService,
    private courseAssetsService: CourseAssetsService,
    private router: Router,
    private hubsStore: HubsStore,
    private currencyPipe: CurrencyPipe,
  ) {}

  ngOnInit(): void {
    this.initializeStatCards();
    this.setSearchFilterFields();
    this.courseId = this.coursesStore.adminCourse.id;
    this.fetchStatisticsData();
    this.fetchAssetsData();
  }

  getCardsContainerWidth(): string {
    const maxWidthPercentage = 100;
    const minItemWidthPercentage = 10;
    const items = this.statCards.length > 0 ? this.statCards.length : 1;
    const calculatedWidth = Math.min(maxWidthPercentage, minItemWidthPercentage * items);
    return `${calculatedWidth}%`;
  }

  getPercentageString(percentage: any): string {
    if (percentage === 0) {
      return `0 %`;
    } else {
      return `${percentage.toFixed(1)} %`;
    }
  }

  getImagePlaceholder(asset: IAsset): string {
    if (asset.type === AssetType.VIDEO) {
      return videoImg;
    }

    switch (asset.fileType) {
      case AssetFileTypes.PNG:
        return pngImg;
      case AssetFileTypes.JPG:
        return jpgImg;
      case AssetFileTypes.PDF:
        return pdfImg;
      case AssetFileTypes.ZIP:
        return zipImg;
      default:
        return pngImg;
    }
  }

  async fetchAssetsData(): Promise<void> {
    const courseAssets = await this.courseAssetsService.getAllCourseAssetsByCourseId(this.courseId);
    const courseAssetsIds: string[] = courseAssets.map((ca) => ca.assetId);
    this.assets = await this.courseAssetsService.getAssetsByIds(courseAssetsIds);
    this.allEntries = await this.getInfoForAssets(this.assets);
    this.entries = this.allEntries;
    this.totalRecords = this.entries.length;
    this.loadingEntries = false;
  }

  async getInfoForAssets(assets: IAsset[]): Promise<any[]> {
    const infoPromises = assets.map(async (asset) => {
      const info = await this.courseTrackingService.getAllByAssetId(asset.id, this.courseId);
      return { ...asset, ...info };
    });

    return Promise.all(infoPromises);
  }

  async onSearchTermChange(keyword: string): Promise<void> {
    this.searchTerm = keyword;
  }

  onRowClick(assetId: string) {
    this.router.navigate([
      `${this.hubsStore.useHubUrl}/admin/courses/${this.coursesStore.adminCourse.link}/asset-reports/${assetId}`,
    ]);
  }

  private async fetchStatisticsData(): Promise<void> {
    const [subscribers, totalSales, viewers] = await Promise.all([
      this.courseSubscribersService.getCountOfCourseSubscribers(this.courseId),
      this.stripeService.searchPaymentIntent(this.courseId),
      this.courseTrackingService.getViewersCountByCourseId(this.courseId),
    ]);

    this.updateStatCards(subscribers, totalSales, viewers);
    this.loading = false;
  }

  private updateStatCards(subscribers: number, stripeReport: IStripeReport, viewers: number): void {
    this.statCards[0].value = subscribers.toString();
    this.statCards[1].value = stripeReport.totalSales.toString();
    this.statCards[2].value = this.currencyPipe.transform(
      stripeReport.totalRevenue.toString(),
      this.coursesStore.adminCourse.currency,
    );
    this.statCards[3].value = viewers.toString();
  }

  private initializeStatCards(): void {
    this.statCards = [
      {
        iconClass: 'fa-regular fa-users',
        text: 'courseReports.subscribers',
        value: '0',
      },
      {
        iconClass: 'fa-regular fa-money-bill',
        text: 'courseReports.totalSales',
        value: '0',
      },
      {
        iconClass: 'fa-regular fa-money-bill-trend-up',
        text: 'courseReports.totalRevenue',
        value: '0',
      },
      {
        iconClass: 'fa-regular fa-play',
        text: 'courseReports.viewers',
        value: '0',
      },
    ];

    this.columns = [
      {
        field: 'title',
        template: 'titleCell',
        titleTranslationKey: 'courseReports.assetTitle',
        sortable: true,
      },
      {
        field: 'viewersCount',
        template: 'viewersCountCell',
        titleTranslationKey: 'courseReports.assetViewers',
        sortable: true,
      },
      // { field: 'views', template: 'viewsCountCell', titleTranslationKey: 'courseReports.assetViews', sortable: true},
      {
        field: 'averagePercentWatched',
        template: 'progressCell',
        titleTranslationKey: 'courseReports.assetAveragePercentWatched',
        sortable: true,
      },
    ];
  }

  private setSearchFilterFields(): void {
    this.searchFilterFields = ['title', 'viewersCount', 'viewsCount', 'averagePercentWatched'];
  }
}
