import { animate, style, transition, trigger } from '@angular/animations';

export const NotificationAnimation = trigger('notifications', [
  transition(':enter', [style({ height: 0, overflow: 'hidden' }), animate('500ms ease')]),

  transition(':leave', [
    style({ height: '*', overflow: 'hidden' }),
    animate('500ms ease', style({ height: 0 })),
  ]),
]);
