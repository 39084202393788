export const getStripeEmbedComponentsAppearanceConfig = (colorPrimary: string) => {
  const defaultColorPrimary = '#3fbe4e';

  return {
    variables: {
      colorPrimary: colorPrimary || defaultColorPrimary,
      colorText: '#ffffff',
      colorBackground: 'var(--layer-01)',
      buttonSecondaryColorBackground: '#181818',
      buttonSecondaryColorBorder: '#909090',
      buttonSecondaryColorText: '#ffffff',
      colorSecondaryText: '#909090',
      colorBorder: '#31313A',
      formHighlightColorBorder: '#323232',
      colorDanger: '#ff244c',
      badgeNeutralColorBackground: '#181818',
      badgeNeutralColorBorder: '#909090',
      badgeNeutralColorText: '#ffffff',
      badgeSuccessColorBackground: '#181818',
      badgeSuccessColorBorder: colorPrimary || defaultColorPrimary,
      badgeSuccessColorText: '#ffffff',
      badgeWarningColorBackground: '#181818',
      badgeWarningColorBorder: '#ff8f23',
      badgeWarningColorText: '#ffffff',
      badgeDangerColorBackground: '#181818',
      badgeDangerColorBorder: '#ff244c',
      badgeDangerColorText: '#ffffff',
      offsetBackgroundColor: '#292929',
      formBackgroundColor: '#181818',
      borderRadius: '8px',
      buttonBorderRadius: '16px',
      formBorderRadius: '12px',
      badgeBorderRadius: '20px',
      overlayBorderRadius: '20px',
      spacingUnit: '12px',
      fontFamily: 'var(--appFont)',
      headingXsFontWeight: '400',
      bodySmFontWeight: '400',
      labelSmFontSize: '12px',
      labelSmFontWeight: '200',
    },
  };
};

export const getStripeEmbedComponentLocaleConfig = (currentLang: string) => {
  let preferredLocale: string;

  if (currentLang === 'en') {
    preferredLocale = 'en-US';
  } else {
    preferredLocale = 'de';
  }

  return preferredLocale;
};
