import { Component } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-new-brand-product',
  templateUrl: './new-brand-product.component.html',
  styleUrls: ['./new-brand-product.component.scss'],
})
export class NewBrandProductComponent {
  constructor(private location: Location) {}

  public onConfirm(success: boolean) {
    if (success) {
      this.location.back();
    }
  }
}
