import { Component, OnDestroy, OnInit, signal } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { autorun } from 'mobx';
import { TranslateService } from '@ngx-translate/core';

import {
  IEventConsent,
  IEventRegistrationField,
  IEventRegistrationForm,
} from 'src/app/core/models';
import { EventsService, OpenDialogService } from 'src/app/core/services';
import { EventsStore } from 'src/app/core/stores';
import { IAdminTableColumn } from 'src/app/shared';
import { EventConsentType } from 'src/app/core/enums';
import { ButtonSize, ButtonType, ButtonStyle } from 'src/app/standalone';
import { EventConsentDialogComponent } from 'src/app/standalone/admin/dialogs';

@Component({
  selector: 'app-event-registration',
  templateUrl: './event-registration.component.html',
  styleUrls: ['./event-registration.component.scss'],
})
export class EventRegistrationComponent implements OnInit, OnDestroy {
  loading = true;
  isUpdating = false;
  eventRegistrationFields: IEventRegistrationField[];
  isAllowSkipReg: UntypedFormControl = new UntypedFormControl(false);
  isGroupCheckboxes: UntypedFormControl = new UntypedFormControl(false);
  loadingEventConsents = false;
  columns: IAdminTableColumn[] = [
    { field: 'title', titleTranslationKey: 'adminEventRegistration.tableCells.title' },
    {
      field: 'type',
      titleTranslationKey: 'adminEventRegistration.tableCells.type',
      template: 'type',
    },
    {
      field: 'required',
      titleTranslationKey: 'adminEventRegistration.tableCells.required',
      template: 'required',
    },
    { field: 'actions', template: 'actionsCell' },
  ];
  eventConsents: IEventConsent[] = [];
  form: UntypedFormGroup;
  eventConsentType = EventConsentType;
  showIsEventTagsMandatory = false;
  buttonSize = ButtonSize;
  buttonType = ButtonType;
  buttonStyle = ButtonStyle;
  stylesForVisibleAndRequiredField = signal<{ [key: string]: string }>({
    color: 'var(--app-primary)',
  });

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private messageService: MessageService,
    private eventsService: EventsService,
    private eventsStore: EventsStore,
    private translateService: TranslateService,
    private openDialogService: OpenDialogService,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private dialogService: DialogService,
  ) {}

  get isBtnDisable(): boolean {
    const {
      isShowProfileAndTags,
      isShowEventTags,
      isShowProfileAppearance,
      isShowConsents,
      isShowAccommodationTransfer,
    } = this.form?.value;
    const condition =
      isShowProfileAndTags ||
      isShowEventTags ||
      isShowProfileAppearance ||
      isShowConsents ||
      isShowAccommodationTransfer;

    return !condition || this.form.invalid;
  }

  async ngOnInit(): Promise<void> {
    this.route.parent.params
      .pipe(
        tap(() => {
          this.unsubscribe$.next();
          this.init();
        }),
      )
      .subscribe();
    this.showIsEventTagsMandatory = this.eventsStore.adminEvent.tags.length > 0;
  }

  private init(): void {
    autorun(async () => {
      if (this.eventsStore.adminEvent) {
        this.loading = true;
        this.createForm();
        const eventRegistrationSetting: IEventRegistrationForm =
          await this.eventsService.getEventRegisterSettings(this.eventsStore.adminEvent.id);
        this.eventRegistrationFields = eventRegistrationSetting.fields.filter(
          (field: IEventRegistrationField) =>
            field.fieldName !== 'clothingSize' && field.fieldName !== 'shoeSize',
        );
        this.isAllowSkipReg.setValue(eventRegistrationSetting.allowSkipRegistration);
        this.isGroupCheckboxes.setValue(eventRegistrationSetting.enableGroupCheckboxes);
        this.updateForm(eventRegistrationSetting);
        await this.getEventConsents();
        this.loading = false;
      }
    });

    this.form.controls.isShowProfileAndTags.valueChanges
      .pipe(
        tap((value: boolean) => {
          if (value) {
            this.form.controls.isShowEventTags.enable();
          } else {
            this.form.controls.isShowEventTags.setValue(false);
            this.form.controls.isShowEventTags.disable();
          }
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();

    this.form.controls.isEventCode.valueChanges
      .pipe(
        tap((value: boolean) => {
          if (value) {
            this.form.controls.eventCode.enable();
            this.form.controls.eventCode.setValidators(Validators.required);
          } else {
            this.form.controls.eventCode.setValue(null);
            this.form.controls.eventCode.disable();
          }
          this.form.controls.eventCode.updateValueAndValidity();
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();
  }

  private createForm(): void {
    this.form = this.fb.group({
      isGlobalSignup: [{ value: true, disabled: true }],
      isGlobalTags: [false],
      isShowProfileAndTags: [false],
      isShowEventTags: [{ value: false, disabled: true }],
      isShowProfileAppearance: [false],
      isShowConsents: [false],
      isShowAccommodationTransfer: [false],
      isEventCode: [false],
      eventCode: [{ value: null, disabled: true }],
      isRsvpRegistration: [false],
      isEventTagsMandatory: [false],
    });
  }

  private updateForm(regForm: IEventRegistrationForm): void {
    this.form.patchValue({ ...regForm });
  }

  private async getEventConsents(): Promise<void> {
    this.loadingEventConsents = true;
    const consents: IEventConsent[] = await this.eventsService.getAllConsentsFromEvent(
      this.eventsStore.adminEvent.id,
    );
    this.eventConsents = [...consents];
    this.loadingEventConsents = false;
  }

  async onCreateEventConsent(): Promise<void> {
    const dialogRef: DynamicDialogRef = this.dialogService.open(EventConsentDialogComponent, {
      closable: false,
      styleClass: 'event-consent-dialog',
    });
    const result: 'success' = await dialogRef.onClose.pipe(take(1)).toPromise();
    if (result === 'success') {
      this.showToastMessage('success', 'adminEventRegistration.consentCreatedSuccessfully');
      await this.getEventConsents();
    }
  }

  async downloadConsentReport(event: Event, consent: IEventConsent): Promise<void> {
    event.stopPropagation();
    try {
      await this.eventsService.downloadConsentReport(this.eventsStore.adminEvent.id, consent);
    } catch (e) {
      this.showToastMessage('error', 'adminEventRegistration.consentCreatedOrUpdatedError');
    }
  }

  async editConsent(event: Event, consent: IEventConsent): Promise<void> {
    event.stopPropagation();
    const dialogRef: DynamicDialogRef = this.dialogService.open(EventConsentDialogComponent, {
      closable: false,
      styleClass: 'event-consent-dialog',
      data: { consent },
    });
    const result: 'success' = await dialogRef.onClose.pipe(take(1)).toPromise();
    if (result === 'success') {
      this.showToastMessage('success', 'adminEventRegistration.consentUpdatedSuccessfully');
      await this.getEventConsents();
    }
  }

  async deleteConsent(event: Event, consent: IEventConsent): Promise<void> {
    event.stopPropagation();
    const dialogRef: DynamicDialogRef = this.openDialogService.openConfirmDialog(
      'adminEventRegistration.removeCheckbox',
      'adminEventRegistration.removeCheckboxDesc',
      'buttons.confirm',
      'buttons.cancel',
    );
    const result: 'cancel' | 'confirm' = await dialogRef.onClose.pipe(take(1)).toPromise();
    if (result === 'confirm') {
      try {
        this.eventConsents[this.eventConsents.indexOf(consent)].isActionBtnShowingSpinner = true;
        await this.eventsService.updateEventConsent(this.eventsStore.adminEvent.id, {
          ...consent,
          isDeleted: true,
          isActionBtnShowingSpinner: false,
        });
        this.showToastMessage('success', 'adminEventRegistration.consentDeleteSuccessfully');
        await this.getEventConsents();
      } catch (error) {
        this.showToastMessage('error', 'adminEventRegistration.consentCreatedOrUpdatedError');
        this.eventConsents[this.eventConsents.indexOf(consent)].isActionBtnShowingSpinner = false;
      }
    }
  }

  getCorrectTranslationForField(field: IEventRegistrationField): string {
    return this.translateService.instant(`adminEventRegistration.${field.fieldName}`);
  }

  async updateRegistrationSettings(): Promise<void> {
    this.isUpdating = true;
    const formValue = this.form.getRawValue();
    await this.eventsService.updateEventRegisterSettings(this.eventsStore.adminEvent.id, {
      ...formValue,
      fields: this.eventRegistrationFields,
      enableGroupCheckboxes: this.isGroupCheckboxes.value ? true : false,
      allowSkipRegistration: this.isAllowSkipReg.value,
    });

    this.messageService.add({
      severity: 'success',
      summary: this.translateService.instant('success'),
      detail: this.translateService.instant('adminEventRegistration.saveSuccess'),
      styleClass: 'custom-toast',
    });
    this.isUpdating = false;
  }

  async onRowReorder(orderedEventConsents: IEventConsent[]): Promise<void> {
    await this.eventsService.reorderEventConsents(
      this.eventsStore.adminEvent.id,
      orderedEventConsents.map((eventConsent: IEventConsent) => eventConsent.id),
    );
    await this.getEventConsents();
  }

  private showToastMessage(severity: 'success' | 'error', detail: string): void {
    this.messageService.add({
      severity: severity,
      summary: this.translateService.instant(severity),
      detail: this.translateService.instant(detail),
      styleClass: 'custom-toast',
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
