import { Component, OnInit, Output, EventEmitter, input, inject, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { HubsStore, UsersStore } from 'src/app/core/stores';
import {
  ICourse,
  IUser,
  IUserCourse,
  IChapter,
  IAsset,
  AssetStatus,
  ChapterStatus,
} from 'src/app/core/models';
import {
  UsersService,
  ThemesService,
  MixpanelService,
  UserCoursesService,
  CourseChaptersService,
  LibraryService,
} from 'src/app/core/services';
import { AnalyticsEventTypes } from 'src/app/core/enums';
import { imgPreloader } from 'src/app/shared/utils';

@Component({
  selector: 'app-course-card',
  standalone: true,
  imports: [CommonModule, TranslateModule, LazyLoadImageModule],
  templateUrl: './course-card.component.html',
  styleUrl: './course-card.component.scss',
})
export class CourseCardComponent implements OnInit {
  course = input.required<ICourse>();
  bookmarkBtnShow = input<boolean>(true);

  @Output() bookmarkUnBookmark = new EventEmitter<string>();

  imgPreloader = signal<string>(imgPreloader);
  isCurrentUserRegisteredInCourse = signal(false);

  #numberOfAssets = signal<number>(null);
  #courseDurationInSeconds = signal<number>(null);
  #bookmarking = signal(false);
  #usersService = inject(UsersService);
  #usersStore = inject(UsersStore);
  #hubsStore = inject(HubsStore);
  #themesService = inject(ThemesService);
  #mixpanelService = inject(MixpanelService);
  #router = inject(Router);
  #userCoursesService = inject(UserCoursesService);
  #courseChaptersService = inject(CourseChaptersService);
  #libraryService = inject(LibraryService);
  #translateService = inject(TranslateService);

  get user(): IUser {
    return this.#usersStore.user;
  }

  get defaultCoversColor(): string {
    const currentTheme = localStorage.getItem('styleTheme');
    const currentThemeProps =
      this.#themesService.systemAppearanceSettings[currentTheme + 'Theme'].properties;

    return this.#hubsStore.hub?.primaryColor || currentThemeProps['--appPrimaryColor'];
  }

  get getBookmarkedBtnClass(): string {
    if (this.#bookmarking()) {
      return 'far fa-circle-notch fa-spin';
    }

    return this.#usersStore.userBookmarkedCoursesIds.includes(this.course()?.id)
      ? 'far fa-bookmark active'
      : 'far fa-bookmark';
  }

  get courseAssetsText(): string {
    if (this.#numberOfAssets() === 1) {
      return `${this.#numberOfAssets()} ${this.#translateService.instant('courseCard.oneAsset')}`;
    }

    return `${this.#numberOfAssets()} ${this.#translateService.instant('courseCard.manyAssets')}`;
  }

  get allAssetsDuration(): string {
    if (!this.#courseDurationInSeconds()) {
      return '0m';
    }

    return `${Math.ceil(this.#courseDurationInSeconds() / 60)}m`;
  }

  get coursePrice(): string {
    return `${new Intl.NumberFormat('de', { style: 'currency', currency: this.course().currency })
      .format(Number(this.course().price))
      .split(/\s+/)
      .join('')}`;
  }

  get courseStrikePrice(): string {
    return `
    ${new Intl.NumberFormat('de', { style: 'currency', currency: this.course().currency })
      .format(Number(this.course().strikePrice))
      .split(/\s+/)
      .join('')}`;
  }

  async ngOnInit(): Promise<void> {
    if (this.user) {
      this.#usersService.getUserBookmarkedCoursesIds(this.user.id);
      this.checkingIfUserIsRegistered();
    }

    const chapters: IChapter[] = await this.#courseChaptersService.getAllPublishedByCourseId(
      this.course().id,
    );
    const publishedChapters: IChapter[] = chapters.filter(
      (chapter: IChapter) => chapter.status === ChapterStatus.PUBLISHED,
    );
    const chaptersWithFullAssetsModel: IChapter[] = await Promise.all(
      publishedChapters.map(async (chapter: IChapter) => {
        const assetsFullModel: IAsset[] = await Promise.all(
          chapter.assets.map(async (assetId: string) => {
            const asset: IAsset = await this.#libraryService.getOne(assetId);
            return asset;
          }),
        );
        const onlyPublishedAssetsFullModel: IAsset[] = assetsFullModel.filter(
          (asset: IAsset) => asset.status === AssetStatus.PUBLISHED,
        );

        return { ...chapter, assetsFullModel: onlyPublishedAssetsFullModel };
      }),
    );
    this.#numberOfAssets.set(
      chaptersWithFullAssetsModel.reduce(
        (acc, curr) => (acc += curr?.assetsFullModel?.length ?? 0),
        0,
      ),
    );
    this.#courseDurationInSeconds.set(
      chaptersWithFullAssetsModel
        .flatMap((chapter: IChapter) => chapter?.assetsFullModel)
        .map((asset: IAsset) => asset?.videoDuration ?? 0)
        .reduce((acc: number, curr: number) => (acc += curr), 0),
    );
  }

  async toggleBookmark(event: MouseEvent): Promise<void> {
    event.stopPropagation();
    this.#bookmarking.set(true);
    try {
      if (this.#usersStore.userBookmarkedCoursesIds.includes(this.course()?.id)) {
        await this.#usersService.unBookmarkCourse(this.#usersStore.userId, this.course().id);
        this.#mixpanelService.courseEvent(this.course(), AnalyticsEventTypes.COURSE_UNBOOKMARKED);
      } else {
        await this.#usersService.bookmarkCourse(this.#usersStore.userId, this.course().id);
        this.#mixpanelService.courseEvent(this.course(), AnalyticsEventTypes.COURSE_BOOKMARKED);
      }
    } catch (error) {
      console.warn(error);
      throw error;
    }

    this.#bookmarking.set(false);
    this.bookmarkUnBookmark.emit(this.course().id);
  }

  onNavigateToCoursePage(): void {
    this.#router.navigate([`/${this.#hubsStore.useHubUrl}/courses/${this.course().link}`]);
  }

  private async checkingIfUserIsRegistered(): Promise<void> {
    try {
      const userCourse: IUserCourse = await this.#userCoursesService.getUserCourse(
        this.course().id,
        this.#usersStore.userId,
      );
      this.isCurrentUserRegisteredInCourse.set(!!userCourse);
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }
}
