export interface IUserCourse {
  id: string;
  courseId: string;
  userId: string;
  ticketId: string;
  role: 'subscriber' | 'instructor';
  status: string;
  isFromEventBrite: boolean;
  tags: string[];
  _firstName_: string;
  _lastName_: string;
  _company_: string;
  _position_: string;
  _courseTitle_: string;
  isWatched: boolean;
  completedChapters: string[];
  wasAttachedFromAdminPart: boolean;
  createdAt: any;
  createdBy: string;
  updatedAt: any;
  updatedBy: string;
  createdAtString?: string;
  updatedAtString?: string;
}
