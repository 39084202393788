import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  Validators,
  UntypedFormGroup,
  AsyncValidatorFn,
  ValidationErrors,
  AbstractControl,
  FormGroup,
  FormControl,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription, from } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, take, filter, switchMap } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { ClipboardService } from 'ngx-clipboard';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';

import { HubsStore, UsersStore } from 'src/app/core/stores';
import { IHub, IUser } from 'src/app/core/models';
import {
  HubsService,
  UsersService,
  StripeService,
  TicketsService,
  OpenDialogService,
  FormService,
} from 'src/app/core/services';
import { environment } from 'src/environments/environment';
import { goToLink, asyncDelay } from 'src/app/core/utils';
import { ButtonSize, ButtonStyle } from 'src/app/standalone';
import { StripeConnectComponent } from '../../dialogs';
import { StripeConnectTypes } from '../../../core/enums';

@Component({
  selector: 'app-admin-hub-profile-form',
  templateUrl: './admin-hub-profile-form.component.html',
  styleUrls: ['./admin-hub-profile-form.component.scss'],
})
export class AdminHubProfileFormComponent implements OnInit, OnDestroy {
  loading = true;
  isUpdating: boolean;
  copyIsClicked = false;
  form: UntypedFormGroup;
  freshUser: IUser;
  isUserInHubTeam = false;
  stripeLoading = false;
  isStripeConnected = false;
  stripeConnectId = null;
  icon: string;
  stripeBtnLabel: string;
  isExpressConnect: boolean;
  buttonStyle = ButtonStyle;
  buttonSize = ButtonSize;

  readonly linkPrefix: string = ``;

  private subscription = new Subscription();

  constructor(
    private translateService: TranslateService,
    private messageService: MessageService,
    private hubsService: HubsService,
    private hubsStore: HubsStore,
    private clipboardService: ClipboardService,
    private usersService: UsersService,
    private usersStore: UsersStore,
    private route: ActivatedRoute,
    private stripeService: StripeService,
    private ticketsService: TicketsService,
    private openDialogService: OpenDialogService,
    private formService: FormService,
    private dialogService: DialogService,
  ) {
    this.linkPrefix = this.hubsStore.environmentBaseUrl;
  }

  get hub(): IHub {
    return this.hubsStore.hub;
  }

  get canUpdate(): boolean {
    return (
      !this.isUpdating && this.form.valid && this.form.dirty && this.formService.isValueChanged()
    );
  }

  get urlErrorText(): string {
    if (this.form?.controls?.url?.errors?.pattern) {
      return this.translateService.instant('adminHubProfileForm.eventLinkInvalidPattern');
    }

    if (this.form?.controls?.url?.errors?.exists) {
      return this.translateService.instant('adminHubProfileForm.linkExists');
    }

    return this.translateService.instant('adminHubProfileForm.eventLinkReq');
  }

  get tooltipText(): string {
    return !this.copyIsClicked
      ? this.translateService.instant('copyTooltip.hover')
      : this.translateService.instant('copyTooltip.action');
  }

  async ngOnInit(): Promise<void> {
    this.subscription.add(
      this.route.queryParams
        .pipe(
          filter((params) => params.stripeConnect && params.stripeConnect === 'true'),
          switchMap((params) => {
            if (!!params.code) {
              return from(
                this.stripeService.setStripeConnectForExistingAccount(
                  params.code,
                  this.hubsStore.hub.id,
                ),
              );
            } else {
              return from(this.stripeService.setExpressStripeConnect(this.hubsStore.hub.id));
            }
          }),
          filter((response) => !!response),
          map((response) => {
            this.stripeConnectId = response;
            this.isStripeConnected = true;
          }),
        )
        .subscribe(),
    );

    this.initForm();
    this.setInitialValueForForm();
    this.formService.setForm(this.form);
    this.isStripeConnected = await this.stripeService.getStripeConnectStatusByHubId(this.hub.id);
    this.stripeConnectId = await this.stripeService.getStripeConnectIdByHubId(this.hub.id);
    this.isExpressConnect = await this.stripeService.getHubExpressStatus(this.hub.id);
    this.updateStripeButtonProperties();

    if (this.form.controls.url.value.startsWith(this.linkPrefix)) {
      this.form.controls.url.setValue(this.form.controls.url.value.slice(this.linkPrefix.length));
    }

    this.freshUser = await this.usersService.getOne(this.usersStore.user.id);
    this.isHubOwnerOrAdmin();
    this.loading = false;
  }

  async isHubOwnerOrAdmin(): Promise<void> {
    const teamMembers = await this.hubsService.getAdminTeamMembers(this.hub.id);
    this.isUserInHubTeam =
      teamMembers.filter((member: IUser) => member.id === this.freshUser.id).length > 0;
  }

  copyToClipboard(): void {
    this.clipboardService.copy(`${this.linkPrefix}${this.form.controls.url.value}`);
    this.copyIsClicked = true;
  }

  setLogoDarkValue(value: File): void {
    this.form.controls.logoDark.setValue(value);
    this.makeFormControlDirty('logoDark');
  }

  setLogoLightValue(value: File): void {
    this.form.controls.logoLight.setValue(value);
    this.makeFormControlDirty('logoLight');
  }

  setIconValue(value: File): void {
    this.form.controls.icon.setValue(value);
    this.makeFormControlDirty('icon');
  }

  setBannerValue(value: File): void {
    this.form.controls.banner.setValue(value);
    this.makeFormControlDirty('banner');
  }

  onSelectPrimaryColor(value: string): void {
    this.form.controls.primaryColor.setValue(value);
    this.makeFormControlDirty('primaryColor');
  }

  onSetPrimaryColorError(value: string): void {
    this.form.controls.primaryColor.setErrors({ [value]: true });
    this.makeFormControlDirty('primaryColor');
  }

  onDiscard(): void {
    this.setInitialValueForForm();
  }

  async onConfirm(): Promise<void> {
    try {
      this.isUpdating = true;
      await asyncDelay(1);
      await this.update();
      this.formService.setForm(this.form);
    } catch (error) {
      console.error(error);
    }
  }

  updateStripeButtonProperties(): void {
    this.stripeBtnLabel = this.isStripeConnected
      ? 'adminHub.stripeConnected'
      : 'adminHub.disconnectWithStripeBtn';
    this.icon = this.isStripeConnected ? 'pi pi-check' : 'pi pi-times';
  }

  getStripeRedirectUrls(): { refreshUrl: string; returnUrl: string } {
    let stripeRedirectUrls: { refreshUrl: string; returnUrl: string };
    if (environment.env === 'LOCAL') {
      stripeRedirectUrls = {
        refreshUrl: `http://127.0.0.1:4200/stripe-callback?stripeConnect=false`,
        returnUrl: `http://127.0.0.1:4200/stripe-callback?stripeConnect=true`,
      };
    } else {
      stripeRedirectUrls = {
        refreshUrl: `${this.hubsStore.environmentBaseUrl}/stripe-callback?stripeConnect=false`,
        returnUrl: `${this.hubsStore.environmentBaseUrl}/stripe-callback?stripeConnect=true`,
      };
    }
    return stripeRedirectUrls;
  }

  async toggleStripeConnect(): Promise<void> {
    if (this.isStripeConnected) {
      this.openDisconnectDialog();
    } else {
      // commented part is for new standard accounts
      this.onOpenStripeDialog();
    }
  }

  async openDisconnectDialog(): Promise<void> {
    const disconnectDialogRef = this.openDialogService.openDisconnectStripeDialog(
      'adminSystemSettings.stripeDisconnectDialogTitle',
      '',
    );
    const result: 'cancel' | 'confirm' = await disconnectDialogRef.onClose
      .pipe(take(1))
      .toPromise();
    if (result === 'confirm') {
      await this.stripeService.disonnectFromStripe(this.hub.id);
      this.stripeLoading = true;
      await this.ticketsService.unpublishAllPaidTickets();
      this.isStripeConnected = false;
      this.stripeConnectId = null;
      this.stripeLoading = false;
      this.updateStripeButtonProperties();
    }
  }

  onConnectExistingAccount(): void {
    this.stripeLoading = true;
    const redirectUrls = this.getStripeRedirectUrls();
    localStorage.setItem('connectHubId', this.hubsStore.hub?.id);
    const url: string = this.stripeService.getConnectUrlForExistingAccount(redirectUrls.returnUrl);
    window.location.href = url;
  }

  async onConnectExpressAccount(): Promise<void> {
    this.stripeLoading = true;
    const redirectUrls = this.getStripeRedirectUrls();
    localStorage.setItem('connectHubId', this.hubsStore.hub?.id);
    await this.stripeService.setExpressHubStatus(this.hubsStore.hub?.id, true);
    const url: string = await this.stripeService.createStripeConnectAccount(
      this.hubsStore.hubId,
      this.freshUser.email,
      this.freshUser.id,
      redirectUrls.refreshUrl,
      redirectUrls.returnUrl,
    );
    window.location.href = url;
  }

  async onConnectStripe(): Promise<void> {
    this.stripeLoading = true;

    try {
      const redirectUrls = this.getStripeRedirectUrls();
      const url: string = await this.stripeService.createStripeConnectAccount(
        this.hubsStore.hubId,
        this.freshUser.email,
        this.freshUser.id,
        redirectUrls.refreshUrl,
        redirectUrls.returnUrl,
      );
      window.location.href = url;
    } catch (error) {
      this.stripeLoading = false;
      throw error;
    }
  }

  async onFinishOnboarding(): Promise<void> {
    this.stripeLoading = true;

    try {
      const redirectUrls = this.getStripeRedirectUrls();
      const url = await this.stripeService.getOnboardingLink(
        this.stripeConnectId,
        redirectUrls.refreshUrl,
        redirectUrls.returnUrl,
      );
      window.location.href = url;
    } catch (error) {
      this.stripeLoading = false;
      throw error;
    }
  }

  async onCreateLoginLink(): Promise<void> {
    this.stripeLoading = true;

    try {
      const url = await this.stripeService.getLoginLink(this.stripeConnectId);
      goToLink(url);
      this.stripeLoading = false;
    } catch (error) {
      this.stripeLoading = false;
      throw error;
    }
  }

  async onOpenStripeDialog(): Promise<void> {
    const dialogRef: DynamicDialogRef = this.dialogService.open(StripeConnectComponent, {
      width: '80rem',
      height: '80vh',
      styleClass: 'stripe-connect-dialog',
    });
    const response: StripeConnectTypes = await dialogRef.onClose.pipe(take(1)).toPromise();
    if (response === StripeConnectTypes.EXPRESS) {
      this.onConnectExpressAccount();
    } else if (response === StripeConnectTypes.STANDARD) {
      this.onConnectExistingAccount();
    }
  }

  async createWebhookEndpoint(): Promise<void> {
    await this.stripeService.createWebhookEndpoint(this.stripeConnectId);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private initForm(): void {
    this.form = new FormGroup({
      id: new FormControl(''),
      title: new FormControl('', [Validators.required, Validators.maxLength(80)]),
      headline: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      tagline: new FormControl('', Validators.maxLength(200)),
      url: new FormControl(
        '',
        [
          Validators.required,
          Validators.pattern(/^\S+$/),
          Validators.pattern(/^((?!http:|www\.|https:|\/|\\).)*$/),
        ],
        [this.linkExistsValidator()],
      ),
      logoDark: new FormControl(null),
      logoLight: new FormControl(null),
      icon: new FormControl(null),
      banner: new FormControl(null),
      primaryColor: new FormControl(''),
      isPrivate: new FormControl(false),
      isMaintenance: new FormControl(false),
      events: new FormControl(false),
      academy: new FormControl(false),
      brands: new FormControl(false),
      isSalesforceSSO: new FormControl(false),
    });
  }

  private setInitialValueForForm(): void {
    this.form.patchValue({
      ...this.hub,
    });
  }

  private linkExistsValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> =>
      this.hubsService.checkHubByLinkExists(control.value, this.form.controls.id.value).pipe(
        debounceTime(500),
        distinctUntilChanged(),
        map((linkExists) => (linkExists ? { exists: linkExists } : null)),
      );
  }

  private makeFormControlDirty(formControlName: string): void {
    this.form.controls[formControlName].markAsDirty();
  }

  private async update(): Promise<void> {
    const hub: IHub = this.form.getRawValue();

    try {
      await this.hubsService.update(hub.id, hub);
      const newHub = { ...this.hubsStore.hub, ...hub };
      this.hubsStore.setHub(newHub);
      this.isUpdating = false;

      this.messageService.add({
        severity: 'success',
        summary: this.translateService.instant('success'),
        detail: this.translateService.instant('adminHubProfileForm.successUpdateHub'),
        styleClass: 'custom-toast',
      });
    } catch (error) {
      console.error(error);
      this.isUpdating = false;
      throw error;
    }
  }
}
