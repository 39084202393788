import * as dayjs from 'dayjs';
import { Component, inject, OnInit, signal, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { merge, firstValueFrom, Subject } from 'rxjs';
import { debounceTime, filter, takeUntil, tap } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';

import { Timestamp } from 'src/app/firebase';
import { CurrencyCode } from 'src/app/core/enums';
import { ICourseTicket, IGeneralSettings, IUser, ProductPricePair } from 'src/app/core/models';
import { SharedModule } from 'src/app/shared';
import { CoursesStore, TicketsStore, HubsStore, UsersStore } from 'src/app/core/stores';
import { TicketsService, StripeService, FormService, SettingsService } from 'src/app/core/services';
import { updateTime, validNumbers, asyncDelay } from 'src/app/core/utils';
import { StripeInformationDialogComponent } from 'src/app/admin/dialogs/stripe-information/stripe-information-dialog.component';
import { combineDateAndTime, SaveDiscardActionsComponent } from 'src/app/standalone/shared';

@Component({
  selector: 'app-admin-course-ticket-form',
  standalone: true,
  imports: [SharedModule, SaveDiscardActionsComponent],
  templateUrl: './admin-course-ticket-form.component.html',
  styleUrl: './admin-course-ticket-form.component.scss',
})
export class AdminCourseTicketFormComponent implements OnInit, OnDestroy {
  loading = signal(true);
  form: FormGroup;
  ticket = signal<ICourseTicket>(null);
  isUpdating = signal(false);
  minDate = signal<Date>(new Date());
  courseCurrency = signal<CurrencyCode>(null);

  #defaultEndDate = signal<Date>(dayjs().add(1, 'days').toDate());
  #unsubscribe$: Subject<void> = new Subject<void>();
  #stripeConnectId = signal<string>(null);
  #coursesStore = inject(CoursesStore);
  #fb = inject(FormBuilder);
  #ticketsStore = inject(TicketsStore);
  #ticketsService = inject(TicketsService);
  #messageService = inject(MessageService);
  #usersStore = inject(UsersStore);
  #dialogService = inject(DialogService);
  #router = inject(Router);
  #hubsStore = inject(HubsStore);
  #translateService = inject(TranslateService);
  #stripeService = inject(StripeService);
  #formService = inject(FormService);
  #settingsService = inject(SettingsService);

  get user(): IUser {
    return this.#usersStore.user;
  }

  get canUpdate(): boolean {
    return (
      !this.isUpdating() && this.form.valid && this.form.dirty && this.#formService.isValueChanged()
    );
  }

  async ngOnInit(): Promise<void> {
    this.courseCurrency.set(this.#coursesStore.adminCourse.currency);
    this.createForm();
    this.ticket.set(this.#ticketsStore.adminCourseTicket);
    const generalSettings: IGeneralSettings = await this.#settingsService.getSettings();
    this.#stripeConnectId.set(generalSettings.stripeConnectId);

    if (this.ticket()) {
      this.updateForm();
    }
    this.#formService.setForm(this.form);

    const startDateValue$ = this.form.controls.startDate.valueChanges;
    const startTimeValue$ = this.form.controls.startTime.valueChanges;
    const endDateValue$ = this.form.controls.endDate.valueChanges;
    const endTimeValue$ = this.form.controls.endTime.valueChanges;

    merge(startDateValue$, startTimeValue$, endDateValue$, endTimeValue$)
      .pipe(
        debounceTime(300),
        filter((value) => !!value),
        tap(() => {
          const start = combineDateAndTime(
            this.form.controls.startDate.value,
            this.form.controls.startTime.value,
          );
          const end = combineDateAndTime(
            this.form.controls.endDate.value,
            this.form.controls.endTime.value,
          );

          if (dayjs(start).isAfter(end)) {
            const validEnd = dayjs(start).add(1, 'hours');
            this.form.controls.endDate.setValue(validEnd.toDate(), { emitEvent: false });
            this.form.controls.endTime.setValue(validEnd.toDate(), { emitEvent: false });
          }
        }),
        takeUntil(this.#unsubscribe$),
      )
      .subscribe();

    this.form.controls.isPaid.valueChanges
      .pipe(
        tap((val: boolean) => {
          if (val) {
            this.setPaidTicketRequirements();
          } else {
            this.removePaidTicketRequirements();
          }
          if (val && !this.#stripeConnectId()) {
            this.showStripeDialog();
          }
        }),
        takeUntil(this.#unsubscribe$),
      )
      .subscribe();

    this.loading.set(false);
  }

  hasValidator(controlName: string): boolean {
    const control = this.form.get(controlName);
    if (!control.validator) {
      return false;
    } else {
      return true;
    }
  }

  async showStripeDialog(): Promise<void> {
    const dialogRef: DynamicDialogRef = this.#dialogService.open(StripeInformationDialogComponent, {
      width: '38.75rem',
      height: '31.975rem',
    });

    await firstValueFrom(dialogRef.onClose);

    this.form.patchValue({
      isPaid: false,
    });
  }

  onDiscard(): void {
    this.updateForm();
  }

  async onConfirm(): Promise<void> {
    try {
      this.isUpdating.set(true);
      await asyncDelay(1);
      await this.onSaveTicket();
      this.#formService.setForm(this.form);
    } catch (error) {
      console.error(error);
    }
  }

  ngOnDestroy(): void {
    this.#unsubscribe$.next();
    this.#unsubscribe$.complete();
  }

  private createForm(): void {
    this.form = this.#fb.group({
      name: [null, Validators.required],
      info: null,
      quantityTotal: [null, [Validators.required, validNumbers()]],
      isPublished: false,
      startDate: [this.minDate(), Validators.required],
      startTime: this.minDate(),
      endDate: [this.#defaultEndDate(), Validators.required],
      endTime: this.#defaultEndDate(),
      isPaid: false,
      currency: this.courseCurrency(),
      price: null,
      isShowLiveEvents: true,
      description: null,
    });
  }

  private updateForm(): void {
    this.form.patchValue(
      {
        ...this.ticket(),
        startDate: this.ticket()?.start ? this.ticket().start.toDate() : null,
        startTime: this.ticket()?.start ? this.ticket().start.toDate() : null,
        endDate: this.ticket()?.end ? this.ticket().end.toDate() : null,
        endTime: this.ticket()?.end ? this.ticket().end.toDate() : null,
      },
      { emitEvent: false },
    );

    if (this.ticket().isPaid) {
      this.form.controls.currency.disable();
      this.form.controls.price.disable();
      this.form.controls.isPaid.disable();
    }
  }

  private async onSaveTicket(): Promise<void> {
    if (this.form.invalid) {
      return;
    }
    const form = this.form.getRawValue();
    if (form.isPaid) {
      await this.createStripeTicket();
    } else {
      await this.createFreeTicket();
    }
    this.#router.navigate([
      `/${this.#hubsStore.useHubUrl}/admin/courses/${this.#coursesStore.adminCourse.link}/setup/tickets`,
    ]);
  }

  private setPaidTicketRequirements(): void {
    this.form.controls.currency.setValue(CurrencyCode.EUR);
    this.form.get('currency').setValidators([Validators.required]);
    this.form.get('price').setValidators([Validators.required, Validators.min(0.5)]);
    this.form.get('currency').updateValueAndValidity();
    this.form.get('price').updateValueAndValidity();
  }

  private removePaidTicketRequirements(): void {
    this.form.controls.currency.setValue(null);
    this.form.get('currency').removeValidators([Validators.required]);
    this.form.get('price').removeValidators([Validators.required, Validators.min(0.5)]);
    this.form.get('currency').updateValueAndValidity();
    this.form.get('price').updateValueAndValidity();
  }

  private async createFreeTicket(): Promise<void> {
    try {
      this.isUpdating.set(true);

      const { startDate, startTime, endDate, endTime, ...others } = this.form.getRawValue();
      const dateStart = updateTime(startDate, startTime);
      const dateEnd = updateTime(endDate, endTime);
      const updatedTicket: ICourseTicket = {
        ...this.ticket(),
        ...others,
        start: Timestamp.fromMillis(dateStart.toDate().getTime()),
        end: Timestamp.fromMillis(dateEnd.toDate().getTime()),
      };

      if (this.ticket()?.id) {
        await this.#ticketsService.updateCourseTicket(
          this.#coursesStore.adminCourse.id,
          this.ticket().id,
          updatedTicket,
        );
        this.showToastMessage('success', 'adminCourseTicketForm.ticketSuccessfullyUpdated');
      } else {
        updatedTicket.quantitySold = 0;

        await this.#ticketsService.createCourseTicket(
          this.#coursesStore.adminCourse.id,
          updatedTicket,
        );
        this.showToastMessage('success', 'adminCourseTicketForm.ticketSuccessfullyCreated');
      }
    } catch (error) {
      console.warn(error);
      this.showToastMessage('error', 'application.toasters.error');
    } finally {
      this.isUpdating.set(false);
    }
  }

  private async createStripeTicket(): Promise<void> {
    try {
      this.isUpdating.set(true);
      const form = this.form.getRawValue();
      let stripePriceProductPair: ProductPricePair;
      if (!this.ticket() || !this.ticket()?.stripePriceId) {
        stripePriceProductPair = await this.#stripeService.createProduct(
          form.name,
          form.price,
          this.courseCurrency(),
          false,
          true,
        );
      } else {
        stripePriceProductPair = {
          priceId: this.ticket().stripePriceId,
          productId: this.ticket().stripeProductId,
        };
      }
      const { startDate, startTime, endDate, endTime, ...others } = form;
      const dateStart = updateTime(startDate, startTime);
      const dateEnd = updateTime(endDate, endTime);
      const updatedTicket: ICourseTicket = {
        ...this.ticket(),
        ...others,
        currency: this.courseCurrency(),
        start: Timestamp.fromMillis(dateStart.toDate().getTime()),
        end: Timestamp.fromMillis(dateEnd.toDate().getTime()),
      };
      updatedTicket.stripePriceId = stripePriceProductPair.priceId;
      updatedTicket.stripeProductId = stripePriceProductPair.productId;

      if (this.ticket()?.id) {
        await this.#ticketsService.updateCourseTicket(
          this.#coursesStore.adminCourse.id,
          this.ticket().id,
          updatedTicket,
        );
        this.showToastMessage('success', 'adminCourseTicketForm.ticketSuccessfullyUpdated');
      } else {
        await this.#ticketsService.createCourseTicket(
          this.#coursesStore.adminCourse.id,
          updatedTicket,
        );
        this.showToastMessage('success', 'adminTicket.ticketSuccessfullyCreated');
      }
    } catch (error) {
      console.warn(error);
      this.showToastMessage('error', `stripeErrorCodes.${error.message}`);
    } finally {
      this.isUpdating.set(false);
    }
  }

  private showToastMessage(severity: 'success' | 'error', detail: string): void {
    const fallbackTranslationKey = 'toasters.error';
    let message: string;
    this.#translateService
      .get(detail)
      .pipe(
        tap((translation: string) => {
          message =
            translation === detail
              ? this.#translateService.instant(fallbackTranslationKey)
              : translation;
        }),
        takeUntil(this.#unsubscribe$),
      )
      .subscribe();

    this.#messageService.add({
      severity,
      summary: this.#translateService.instant(severity),
      detail: this.#translateService.instant(message),
      styleClass: 'custom-toast',
    });
  }
}
