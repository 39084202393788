import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { IPrivacySettingsPage } from 'src/app/core/models';
import { SettingsService } from 'src/app/core/services';

@Component({
  selector: 'app-system-page',
  templateUrl: './system-page.component.html',
  styleUrls: ['./system-page.component.scss'],
})
export class SystemPageComponent implements OnInit, OnDestroy {
  loading = true;
  page: IPrivacySettingsPage = null;

  private subscriptions = new Subscription();

  constructor(
    private settingsService: SettingsService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.route.params.subscribe(async (params) => {
        if (params.pageId) {
          this.page = await this.settingsService.getPageById(params.pageId);
          this.loading = false;
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
