import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { IEmail } from 'src/app/core/models';
import { EmailsService } from 'src/app/core/services';

@Component({
  selector: 'app-course-message',
  templateUrl: './course-message.component.html',
  styleUrls: ['./course-message.component.scss'],
})
export class CourseMessageComponent implements OnInit {
  loading = true;
  email: IEmail;

  constructor(
    private route: ActivatedRoute,
    private emailsService: EmailsService,
  ) {}

  async ngOnInit(): Promise<void> {
    const emailId = this.route.snapshot.params.id;
    this.email = await this.emailsService.getCourseEmail(emailId);
    this.loading = false;
  }
}
