import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ClipboardService } from 'ngx-clipboard';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { IAppearanceSettings } from 'src/app/core/models';
import { ThemesService } from 'src/app/core/services';
import { HubsStore } from 'src/app/core/stores';
import { EventPagePreviewComponent } from '../event-page-preview/event-page-preview.component';

@Component({
  selector: 'app-system-home-page-editor',
  templateUrl: './system-home-page-editor.component.html',
  styleUrls: ['./system-home-page-editor.component.scss'],
})
export class SystemHomePageEditorComponent {
  public entry: any = null;
  public appearanceSettings: IAppearanceSettings;

  public loading = true;
  public saving = false;
  public form: UntypedFormGroup;
  public languages: any[] = [];
  public isEditing: boolean = false;

  constructor(
    private translateService: TranslateService,
    private dialogService: DialogService,
    private messageService: MessageService,
    private clipboardService: ClipboardService,
    public hubsStore: HubsStore,
    private themeService: ThemesService,
    public ref: DynamicDialogRef,
    private dynamicDialogConfig: DynamicDialogConfig,
  ) {}

  ngOnInit(): void {
    this.entry = this.dynamicDialogConfig.data?.entry;
    this.appearanceSettings = this.dynamicDialogConfig.data?.appearanceSettings;

    this.createForm();
    this.loading = false;
  }

  private createForm(): void {
    this.form = new UntypedFormGroup({
      code: new UntypedFormControl(this.entry ? this.entry.code.toLowerCase() : ''),
      html: new UntypedFormControl(
        this.entry
          ? this.appearanceSettings.globalSingleRouteHtml[this.entry.code.toLowerCase()]
          : '',
      ),
    });
  }

  public preview() {
    this.dialogService.open(EventPagePreviewComponent, {
      width: '90%',
      height: '90%',
      dismissableMask: true,
      contentStyle: {
        overflow: 'auto',
        padding: '0',
        'border-radius': '20px',
      },
      data: {
        html: this.form.controls.html.value,
      },
    });
  }

  public copy() {
    const value = this.form.get('html').value ? this.form.get('html').value : '';
    this.clipboardService.copy(value);
  }

  public async save() {
    if (!this.form.valid) return;

    try {
      this.saving = true;
      const form = this.form.value;

      const newEntry = {
        language: form.code,
        html: form.html,
      };

      console.log(newEntry);
      const appearanceSettingsPayload = { ...this.appearanceSettings };
      console.log(appearanceSettingsPayload);

      appearanceSettingsPayload.globalSingleRouteHtml[newEntry.language] = newEntry.html;
      console.log(appearanceSettingsPayload);

      await this.themeService.updateAppearanceSettings({
        globalSingleRouteHtml: appearanceSettingsPayload.globalSingleRouteHtml,
      } as IAppearanceSettings);

      this.saving = false;
      this.ref.close(true);
    } catch (error) {
      console.log(error);
      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('error'),
        detail: this.translateService.instant('adminSystemHomePages.errorSave'),
        styleClass: 'custom-toast',
      });
      this.saving = false;
    }
  }
}
