import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BrandDialogComponent } from '../../../event/dialogs/brand-dialog/brand-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class BrandsDetailsService {
  private currentDialogRef?: DynamicDialogRef;

  constructor(
    private dialogService: DialogService,
    private router: Router,
  ) {
    /// close modal on navigation
    this.router.events.subscribe((event) => {
      if (this.currentDialogRef && event instanceof NavigationStart) {
        this.currentDialogRef.close();
        this.currentDialogRef = undefined;
      }
    });
  }

  showBrandDetailsDialog(brandId: string): void {
    this.currentDialogRef = this.dialogService.open(BrandDialogComponent, {
      height: '80%',
      width: '80%',
      dismissableMask: true,
      contentStyle: {
        overflow: 'auto',
        padding: '0',
        'border-radius': '20px',
      },
      data: { brandId },
    });
  }
}
