import { EventsStore } from 'src/app/core/stores';
import { EventsService } from 'src/app/core/services';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Route,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AdminEventAccessGuard {
  constructor(
    private router: Router,
    private eventsService: EventsService,
    private eventsStore: EventsStore,
  ) {}

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    let currentEvent = this.router.getCurrentNavigation().extras.state
      ? this.router.getCurrentNavigation().extras.state.event
        ? this.router.getCurrentNavigation().extras.state.event
        : null
      : null;

    if (!currentEvent) {
      currentEvent = await this.eventsService.getByUrl(next.params.eventUrl);
    }

    this.eventsStore.setAdminEvent(currentEvent);
    return currentEvent ? true : false;
  }
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.eventsStore.unsetAdminEvent();
    return true;
  }
  canLoad(
    route: Route,
    segments: UrlSegment[],
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
}
