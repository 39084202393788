<div class="container">
  <div class="header">
    <span>{{ 'sidebarUser.profile' | translate }}</span>
    <i
      class="fa-regular fa-xmark"
      (click)="onClose()"></i>
  </div>
  <image
    [src]="user?.profileImage"
    [text]="imageText(user)"
    size="small">
  </image>
  <p class="email">{{ user?.email }}</p>
  <ul class="tabs">
    <li
      class="item"
      [class.active]="selectedTab === 1"
      (click)="selectedTab = 1">
      <i class="far fa-display item-icon"></i>
      <span class="item-text">
        {{ 'sidebarUser.info' | translate }}
      </span>
    </li>
    <li
      class="item"
      [class.active]="selectedTab === 2"
      (click)="selectedTab = 2">
      <i class="far fa-screen-users item-icon"></i>
      <span class="item-text">
        {{ 'sidebarUser.events' | translate }}
      </span>
    </li>
    <li
      class="item"
      [class.active]="selectedTab === 3"
      (click)="selectedTab = 3">
      <i class="far fa-graduation-cap item-icon"></i>
      <span class="item-text">
        {{ 'sidebarUser.courses' | translate }}
      </span>
    </li>
  </ul>

  <ng-container [ngSwitch]="selectedTab">
    <ng-container *ngSwitchCase="1">
      <div class="w-full">
        <app-sidebar-user-info [user]="user"></app-sidebar-user-info>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="2">
      <div class="w-full">
        <app-sidebar-user-info-events [userEvents]="userEvents"></app-sidebar-user-info-events>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="3">
      <div class="w-full">
        <app-sidebar-user-info-courses [userCourses]="userCourses"></app-sidebar-user-info-courses>
      </div>
    </ng-container>
  </ng-container>
</div>
