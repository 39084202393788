<ng-container *ngIf="loading">
  <app-loader></app-loader>
</ng-container>

<div *ngIf="!loading">
  <!-- Select Platform block -->
  <div class="form-block c-mt-8">
    <div class="left-side">
      <h2 class="title">{{ 'adminPlans.selectPlatform' | translate }}</h2>
    </div>
    <div class="right-side">
      <div class="form-field-block">
        <app-input-label
          for="platform-input"
          text="adminPlans.selectPlatform">
        </app-input-label>
        <p-dropdown
          class="custom-dropdown"
          panelStyleClass="custom-dropdown-panel"
          [options]="platforms"
          [(ngModel)]="selectedPlatform"
          [disabled]="isPlatformSwitcherDisabled"
          inputId="platform-input"
          placeholder="{{ 'adminPlans.platformlabel' | translate }}"
          (onChange)="onPlatformChange($event)">
        </p-dropdown>
      </div>
    </div>
  </div>

  <!-- SALESFORCE form -->
  <form
    *ngIf="selectedPlatform == platformCode.SALESFORCE"
    [formGroup]="salesforceForm">
    <!-- Plan Name & Details block -->
    <div class="form-block c-mt-8">
      <div class="left-side">
        <h2 class="title">{{ 'adminPlans.nameTitle' | translate }}</h2>
      </div>
      <div class="right-side">
        <!-- Name input -->
        <div class="form-field-block">
          <app-input-label
            labelType="required"
            for="name-input"
            text="adminPlans.planNameLabel">
          </app-input-label>
          <input
            class="custom-input-text custom-input-text-required"
            formControlName="name"
            id="name-input"
            pInputText
            type="text"
            placeholder="{{ 'adminPlans.planNameLabel' | translate }}" />
          <app-input-error-message
            [showMessage]="
              salesforceForm.controls.name.invalid && salesforceForm.controls.name.touched
            "
            errorMessage="{{ 'application.forms.required' | translate }}">
          </app-input-error-message>
        </div>

        <!-- App Name input -->
        <div class="form-field-block c-mb-4">
          <app-input-label
            for="app-name-input"
            text="adminPlans.appNameLabel">
          </app-input-label>
          <input
            class="custom-input-text"
            formControlName="appName"
            id="app-name-input"
            pInputText
            type="text"
            placeholder="{{ 'adminPlans.appNameLabel' | translate }}" />
        </div>

        <!-- Aboobject input -->
        <div class="form-field-block c-mb-6">
          <app-input-label
            labelType="required"
            for="aboobject-input"
            text="adminPlans.aboobjectLabel">
          </app-input-label>
          <input
            class="custom-input-text custom-input-text-required"
            formControlName="aboobject"
            id="aboobject-input"
            pInputText
            type="text"
            placeholder="{{ 'adminPlans.aboobjectLabel' | translate }}" />
          <app-input-error-message
            [showMessage]="
              salesforceForm.controls.aboobject.invalid && salesforceForm.controls.aboobject.touched
            "
            errorMessage="{{ 'application.forms.required' | translate }}">
          </app-input-error-message>
        </div>

        <p-divider styleClass="custom-divider-horizontal"></p-divider>

        <!-- Enabled input -->
        <div class="form-field-input-switch-block c-mt-9">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="active"
            inputId="enabled-input">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="enabled-input"
            text="adminPlans.enablePlan">
          </app-input-label>
        </div>
      </div>
    </div>

    <div class="text-right c-mt-4">
      <button
        class="save-btn primary-text-color"
        pButton
        type="button"
        label="{{ 'adminPlans.saveBtnLabel' | translate }}"
        [loading]="isUpdating"
        [icon]="isUpdating ? 'pi pi-spin pi-spinner' : null"
        [disabled]="isFormDisabled"
        (click)="onSaveSalesforcePlan()"></button>
    </div>
  </form>

  <!-- STRIPE form -->
  <form
    *ngIf="selectedPlatform == platformCode.STRIPE"
    [formGroup]="stripeForm">
    <!-- Plan Name & Details block -->
    <div class="form-block c-mt-8">
      <div class="left-side">
        <h2 class="title">{{ 'adminPlans.nameTitle' | translate }}</h2>
        <p class="description">{{ 'adminPlans.nameDescription' | translate }}</p>
        <p class="description">{{ 'adminPlans.nameSecondDescription' | translate }}</p>
      </div>
      <div class="right-side">
        <!-- Name input -->
        <div class="form-field-block">
          <app-input-label
            labelType="required"
            for="name-input"
            text="adminPlans.planNameLabel">
          </app-input-label>
          <input
            class="custom-input-text custom-input-text-required"
            formControlName="name"
            id="name-input"
            pInputText
            type="text"
            placeholder="{{ 'adminPlans.planNameLabel' | translate }}" />
          <app-input-error-message
            [showMessage]="stripeForm.controls.name.invalid && stripeForm.controls.name.touched"
            [errorMessage]="titleErrorMessage">
          </app-input-error-message>
        </div>

        <!-- Description input -->
        <div class="form-field-block c-mb-4">
          <app-input-label
            for="description-input"
            text="adminPlans.descriptionLabel">
          </app-input-label>
          <input
            class="custom-input-text"
            formControlName="description"
            id="description-input"
            pInputText
            type="text"
            placeholder="{{ 'adminPlans.descriptionLabel' | translate }}" />
        </div>

        <!-- Billing Period input -->
        <div class="form-field-block">
          <app-input-label
            labelType="required"
            for="billing-period-input"
            text="adminPlans.billingPeriodLabel">
          </app-input-label>
          <p-dropdown
            class="custom-dropdown custom-dropdown-required"
            panelStyleClass="custom-dropdown-panel"
            [options]="billingPeriods"
            formControlName="billingPeriod"
            inputId="billing-period-input"
            placeholder="{{ 'adminPlans.billingPeriodLabel' | translate }}">
          </p-dropdown>
          <app-input-error-message
            [showMessage]="
              stripeForm.controls.billingPeriod.invalid && stripeForm.controls.billingPeriod.touched
            "
            errorMessage="{{ 'application.forms.required' | translate }}">
          </app-input-error-message>
        </div>

        <!-- Billing information input -->
        <div class="form-field-block c-mb-4">
          <app-input-label
            for="billing-information-input"
            text="adminPlans.billingInformationLabel">
          </app-input-label>
          <input
            class="custom-input-text"
            formControlName="billingInformation"
            id="billing-information-input"
            pInputText
            type="text"
            placeholder="{{ 'adminPlans.billingInformationLabel' | translate }}" />
        </div>

        <div class="two-elements-block c-mb-6">
          <!-- Currency input -->
          <div>
            <app-input-label
              labelType="required"
              for="currency-input"
              text="adminPlans.currency">
            </app-input-label>
            <p-dropdown
              class="custom-dropdown custom-dropdown-required"
              panelStyleClass="custom-dropdown-panel"
              [options]="currencies"
              formControlName="currency"
              inputId="currency-input"
              placeholder="{{ 'adminPlans.currency' | translate }}">
            </p-dropdown>
            <app-input-error-message
              [showMessage]="
                stripeForm.controls.currency.invalid && stripeForm.controls.currency.touched
              "
              errorMessage="{{ 'application.forms.required' | translate }}">
            </app-input-error-message>
          </div>

          <!-- Price input -->
          <div>
            <app-input-label
              labelType="required"
              for="price-input"
              text="adminTicket.priceLabel">
            </app-input-label>
            <input
              class="custom-input-text custom-input-text-required"
              formControlName="price"
              id="price-input"
              pInputText
              type="text"
              placeholder="{{ 'adminTicket.priceLabel' | translate }}" />
            <app-input-error-message
              [showMessage]="stripeForm.controls.price.invalid && stripeForm.controls.price.touched"
              errorMessage="{{ 'application.forms.required' | translate }}">
            </app-input-error-message>
          </div>
        </div>

        <p-divider styleClass="custom-divider-horizontal"></p-divider>

        <!-- Enabled input -->
        <div class="form-field-input-switch-block c-mt-9 c-mb-4">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="active"
            inputId="active-input">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="active-input"
            text="adminPlans.enablePlan">
          </app-input-label>
        </div>

        <!-- Is Default input -->
        <div class="form-field-input-switch-block c-mb-4">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="isDefault"
            inputId="is-default-input">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="is-default-input"
            text="adminPlans.makeDefault">
          </app-input-label>
        </div>
      </div>
    </div>

    <div class="flex justify-content-end c-mt-4">
      <app-button
        buttonText="{{ 'adminPlans.saveBtnLabel' | translate }}"
        [buttonSize]="buttonSize.MEDIUM"
        [loading]="isUpdating"
        [disabled]="isFormDisabled"
        (click)="onSaveStripePlan()" />
    </div>
  </form>
</div>

<!-- Plenigo form -->
<form
  *ngIf="selectedPlatform == platformCode.PLENIGO"
  [formGroup]="plenigoForm">
  <!-- Plan Name & Details block -->
  <div class="form-block c-mt-8">
    <div class="left-side">
      <h2 class="title">{{ 'adminPlans.nameTitle' | translate }}</h2>
    </div>
    <div class="right-side">
      <!-- Name input -->
      <div class="form-field-block">
        <app-input-label
          labelType="required"
          for="name-input"
          text="adminPlans.planNameLabel">
        </app-input-label>
        <input
          class="custom-input-text custom-input-text-required"
          formControlName="name"
          id="name-input"
          pInputText
          type="text"
          placeholder="{{ 'adminPlans.planNameLabel' | translate }}" />
        <app-input-error-message
          [showMessage]="plenigoForm.controls.name.invalid && plenigoForm.controls.name.touched"
          errorMessage="{{ 'application.forms.required' | translate }}">
        </app-input-error-message>
      </div>

      <!-- App Name input -->
      <div class="form-field-block c-mb-4">
        <app-input-label
          for="app-name-input"
          text="adminPlans.appNameLabel">
        </app-input-label>
        <input
          class="custom-input-text"
          formControlName="appName"
          id="app-name-input"
          pInputText
          type="text"
          placeholder="{{ 'adminPlans.appNameLabel' | translate }}" />
      </div>

      <!-- Id input -->
      <div class="form-field-block c-mb-6">
        <app-input-label
          labelType="required"
          for="plan-id-input"
          text="adminPlans.idLabel">
        </app-input-label>
        <input
          class="custom-input-text custom-input-text-required"
          formControlName="planId"
          id="plan-id-input"
          pInputText
          type="text"
          placeholder="{{ 'adminPlans.idLabel' | translate }}" />
        <app-input-error-message
          [showMessage]="plenigoForm.controls.planId.invalid && plenigoForm.controls.planId.touched"
          errorMessage="{{ 'application.forms.required' | translate }}">
        </app-input-error-message>
      </div>

      <p-divider styleClass="custom-divider-horizontal"></p-divider>

      <!-- Enabled input -->
      <div class="form-field-input-switch-block c-mt-9">
        <p-inputSwitch
          class="custom-input-switch"
          formControlName="active"
          inputId="enabled-input">
        </p-inputSwitch>
        <app-input-label
          labelType="switch-checkbox"
          for="enabled-input"
          text="adminPlans.enablePlan">
        </app-input-label>
      </div>
    </div>
  </div>

  <div class="text-right c-mt-4">
    <button
      class="save-btn primary-text-color"
      pButton
      type="button"
      label="{{ 'adminPlans.saveBtnLabel' | translate }}"
      [loading]="isUpdating"
      [icon]="isUpdating ? 'pi pi-spin pi-spinner' : null"
      [disabled]="isFormDisabled"
      (click)="onSavePlenigoPlan()"></button>
  </div>
</form>
