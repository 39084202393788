import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

import { FeatureHeaderComponent, FeatureMainContentComponent } from 'src/app/standalone/admin';
import { IBrand, IHub } from 'src/app/core/models';
import { BrandsStore, HubsStore } from 'src/app/core/stores';

@Component({
  selector: 'app-brand-container',
  standalone: true,
  imports: [CommonModule, FeatureHeaderComponent, FeatureMainContentComponent],
  templateUrl: './brand-container.component.html',
  styleUrls: ['./brand-container.component.scss'],
})
export class BrandContainerComponent implements OnInit {
  items: MenuItem[];

  constructor(
    private brandsStore: BrandsStore,
    private translateService: TranslateService,
    public hubsStore: HubsStore,
  ) {}

  get brand(): IBrand {
    return this.brandsStore.adminBrand;
  }

  get hub(): IHub {
    return this.hubsStore.hub;
  }

  ngOnInit(): void {
    this.items = [
      {
        label: this.translateService.instant('adminBrand.brandTabProfile'),
        icon: 'far fa-display',
        routerLink: `/${this.hubsStore.useHubUrl}/admin/brand/${this.brand.id}/profile`,
      },
      {
        label: this.translateService.instant('adminBrand.brandTabTeam'),
        icon: 'fa-regular fa-user-group',
        routerLink: `/${this.hubsStore.useHubUrl}/admin/brand/${this.brand.id}/team`,
      },
      {
        label: this.translateService.instant('adminBrand.brandTabEvents'),
        icon: 'far fa-screen-users',
        routerLink: `/${this.hubsStore.useHubUrl}/admin/brand/${this.brand.id}/events`,
      },
      {
        label: this.translateService.instant('adminBrand.products'),
        icon: 'fak fa-lox-products',
        routerLink: `/${this.hubsStore.useHubUrl}/admin/brand/${this.brand.id}/products`,
      },
    ];
  }
}
