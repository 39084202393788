import { Injectable } from '@angular/core';

import { Firestore, IDocumentData, IDocumentReference, IFirestore } from 'src/app/firebase';
import { API_ROUTES as apiRoutes } from 'src/app/shared';
import { IUser, IUserCourse } from 'src/app/core/models';

@Injectable({
  providedIn: 'root',
})
export class UserCoursesService {
  private firestore: IFirestore;

  constructor() {
    this.firestore = Firestore();
  }

  async getUserCourseById(userCourseId: string): Promise<IUserCourse> {
    try {
      const userCourse = (
        await this.firestore.collection(apiRoutes.userCourses).doc(userCourseId).get()
      ).data() as IUserCourse;
      return userCourse;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async getUserCourse(courseId: string, userId: string): Promise<IUserCourse> {
    try {
      const userCourse: IUserCourse = (
        await this.firestore
          .collection(apiRoutes.userCourses)
          .where('courseId', '==', courseId)
          .where('userId', '==', userId)
          .get()
      ).docs[0]?.data() as IUserCourse;

      return userCourse;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async getUserCoursesByUserId(userId: string): Promise<IUserCourse[]> {
    try {
      const userCourses: IUserCourse[] = (
        await this.firestore.collection(apiRoutes.userCourses).where('userId', '==', userId).get()
      ).docs.map((doc) => doc.data() as IUserCourse);

      return userCourses;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async updateUserCourse(userCourse: IUserCourse): Promise<IUserCourse> {
    try {
      const userCourseDocument: IDocumentReference<IDocumentData> = this.firestore
        .collection(apiRoutes.userCourses)
        .doc(userCourse.id);
      await userCourseDocument.update({ ...userCourse });

      return userCourse;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async deleteUserCourse(userCourseId: string): Promise<boolean> {
    try {
      await this.firestore.collection(apiRoutes.userCourses).doc(userCourseId).delete();

      return true;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }
}
