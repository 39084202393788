<p-overlayPanel
  styleClass="chat-emoji-picker-overlay-panel"
  #emojiPanel
  [appendTo]="'body'"
  showTransitionOptions="0s"
  hideTransitionOptions="0s"
  [showCloseIcon]="true">
  <emoji-mart
    title="Pick your emoji…"
    emoji="point_up"
    (emojiClick)="onEmojiSelected($event.emoji.native)"></emoji-mart>
</p-overlayPanel>

<div class="chat-reply">
  <div class="chat-reply__container">
    <div
      #chatReplyArea
      class="chat-reply-input"
      autocomplete="off"
      autocorrect="off"
      spellcheck="true"
      aria-expanded="false"
      aria-autocomplete="list"
      aria-multiline="true"
      aria-label="Message"
      dir="auto"
      contenteditable="true"
      role="textbox"
      [attr.placeholder]="placeholder"></div>
    <div class="chat-reply-actions">
      <button (click)="emojiPanel.toggle($event)">
        <i class="far fa-smile"></i>
      </button>
      <button (click)="loadAttachmentAction()">
        <i class="far fa-paperclip"></i>
      </button>
      <button (click)="sendMessageAction()">
        <i class="far fa-paper-plane"></i>
      </button>
    </div>
  </div>
  <div
    *ngIf="attachment"
    class="attachment-preview">
    <i
      *ngIf="attachment.type === 'image'"
      class="fas fa-image"></i>
    <i
      *ngIf="attachment.type === 'pdf'"
      class="fas fa-file-pdf"></i>
    {{ attachment.name | truncate: 10 }}
  </div>
</div>
