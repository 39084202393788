import { Component, input } from '@angular/core';

@Component({
  selector: 'app-toast',
  standalone: true,
  templateUrl: './toast.component.html',
  styleUrl: './toast.component.scss',
})
export class ToastComponent {
  severity = input<'success' | 'error' | 'info'>();
  summary = input<string>();
  detail = input<string>();
}
