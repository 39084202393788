export interface ITag {
  id: string;
  title: string;
  _title_: string;
  updatedAt: any;
  updatedBy: string;
  createdAt: any;
  createdBy: string;
  docRef?: any;
  itemsLeft?: number; // TODO check if needed;
  timeStamp?;
  updateTimeStamp?;
}
