export interface EventPage {
  id?: string;
  title?: string;
  _title_?: string;
  url?: string;
  customIconClass: string;
  html?: string;
  language?: string;
  isMobile: boolean;
  status: boolean;
  audience?: string;
  isDeleted: boolean;
  updatedAt?: any;
  updatedBy?: string;
  createdAt?: any;
  createdBy?: string;
}
