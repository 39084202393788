import { Component } from '@angular/core';
import { take } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';

import { IAppearanceSettings } from 'src/app/core/models';
import { ThemesService } from 'src/app/core/services';
import { IAdminTableColumn } from 'src/app/shared';
import { ButtonSize, ButtonType, ButtonStyle } from 'src/app/standalone';
import { SystemHomePageEditorComponent } from '../../components/system-home-page-editor/system-home-page-editor.component';

@Component({
  selector: 'app-system-home-screen',
  templateUrl: './system-home-screen.component.html',
  styleUrls: ['./system-home-screen.component.scss'],
})
export class SystemHomeScreenComponent {
  loading = true;
  loadingEntries = true;
  searchValue = '';
  columns: IAdminTableColumn[] = [
    { field: 'code', titleTranslationKey: 'adminSystemHomePages.tableColCode' },
    { field: 'html', titleTranslationKey: 'adminSystemHomePages.tableColHtml' },
    { field: 'actions', template: 'actionsCell' },
  ];
  searchFilterFields: string[] = [];
  entries: any[] = [];
  totalRecords = 0;
  pageSize = 10;
  appearanceSettings: IAppearanceSettings;
  buttonSize = ButtonSize;
  buttonType = ButtonType;
  buttonStyle = ButtonStyle;

  constructor(
    private messageService: MessageService,
    private dialogService: DialogService,
    private themesService: ThemesService,
    private translateService: TranslateService,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.fetchEntries();
    this.loadingEntries = false;
    this.loading = false;
  }

  edit(event: Event, entry: any): void {
    event.stopPropagation();
    const deleteDialogRef = this.dialogService.open(SystemHomePageEditorComponent, {
      width: '90%',
      height: '80%',
      dismissableMask: true,
      contentStyle: {
        overflow: 'auto',
        padding: '0',
        'border-radius': '20px',
      },
      data: {
        entry: entry,
        appearanceSettings: this.appearanceSettings,
      },
    });

    deleteDialogRef.onClose.pipe(take(1)).subscribe(async (res) => {
      if (res) {
        try {
          this.messageService.add({
            severity: 'success',
            summary: this.translateService.instant('success'),
            detail: this.translateService.instant('adminSystemHomePages.succesUpdate'),
            styleClass: 'custom-toast',
          });
          this.loadingEntries = true;
          await this.fetchEntries();
          this.loadingEntries = false;
        } catch (error) {
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('success'),
            detail: this.translateService.instant('adminSystemHomePages.errorUpdate'),
            styleClass: 'custom-toast',
          });
        }
      }
    });
  }

  private async fetchEntries(): Promise<void> {
    this.entries = [];
    this.appearanceSettings = await this.themesService.getSystemAppearanceSettings();

    for (const key in this.appearanceSettings.globalSingleRouteHtml) {
      if (
        Object.prototype.hasOwnProperty.call(this.appearanceSettings.globalSingleRouteHtml, key)
      ) {
        const page = this.appearanceSettings.globalSingleRouteHtml[key];

        this.entries.push({
          html: page.substring(0, 100) + '...',
          code: key.toUpperCase(),
        });
      }
    }
  }
}
