import { Injectable } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

import { BrandsService, SpeakersService, StagesService, UsersService } from 'src/app/core/services';
import { AnalyticsEventTypes, SessionEventTypes } from 'src/app/core/enums';
import {
  IBrand,
  IBookmarkedBrandEvent,
  IBookmarkedSpeakerEvent,
  IBrandEvent,
  IAnalyticsEvent,
  ISpeakerEvent,
  IAnalyticsUserEvent,
  IUser,
  IEventViewEvent,
  IStageViewEvent,
  ISession,
  IEvent,
  ISessionEvent,
} from '../../models';
import { EventsStore, UsersStore } from '../../stores';
import { getTime } from '../../utils/date-util';

@Injectable({
  providedIn: 'root',
})
export class GoogleTagManagerAnalyticsService {
  private currentEvent: NavigationEnd;

  constructor(
    private usersStore: UsersStore,
    private translateService: TranslateService,
    private gtmService: GoogleTagManagerService,
    private eventsStore: EventsStore,
    private speakersService: SpeakersService,
    private brandsService: BrandsService,
    private usersService: UsersService,
    private stagesService: StagesService,
  ) {}

  async createAndPushEvent(event: NavigationEnd): Promise<void> {
    try {
      this.currentEvent = { ...event };
      const currentUser = this.usersStore.user;
      const routes = event.url.split('/').filter((route: string) => !!route);
      const lang = this.translateService.currentLang;
      const speakerCondition = routes[routes.length - 2] === 'speakers';
      const brandCondition = routes[routes.length - 2] === 'brands';
      const eventCondition = routes.length === 2 && routes[0] === 'events';
      const stageCondition = routes[routes.length - 2] === 'stages';

      if (!currentUser) {
        const eventGtm: IAnalyticsEvent = {
          event: AnalyticsEventTypes.USER,
          pageName: event.url,
          lang,
        };
        await this.pushGtmTag(eventGtm);
        return;
      }

      if (
        currentUser &&
        !speakerCondition &&
        !brandCondition &&
        !eventCondition &&
        !stageCondition
      ) {
        const userEvent = this.createGtmUserEvent(event);
        await this.pushGtmTag(userEvent);
        return;
      }

      if (
        currentUser &&
        speakerCondition &&
        routes[routes.length - 1] &&
        routes[routes.length - 1] !== 'speakers'
      ) {
        const userEvent = this.createGtmUserEvent(event);
        const speakerEvent = await this.createGtmSpeakerEvent(routes[routes.length - 1]);
        await Promise.all([this.pushGtmTag(speakerEvent), this.pushGtmTag(userEvent)]);
        return;
      }

      if (currentUser && brandCondition && !routes.includes('admin')) {
        const userEvent = this.createGtmUserEvent(event);
        const partnerEvent = await this.createGtmBrandEvent(routes[routes.length - 1]);
        await Promise.all([this.pushGtmTag(userEvent), this.pushGtmTag(partnerEvent)]);
        return;
      }

      if (currentUser && eventCondition && !routes.includes('admin')) {
        const userEvent = this.createGtmUserEvent(event);
        const eventViewEvent = this.createGtmEventViewEvent();
        await Promise.all([this.pushGtmTag(userEvent), this.pushGtmTag(eventViewEvent)]);
        return;
      }

      if (currentUser && stageCondition && !routes.includes('admin')) {
        const userEvent = this.createGtmUserEvent(event);
        const stageViewEvent = await this.createGtmStageViewEvent(routes[routes.length - 1]);
        await Promise.all([this.pushGtmTag(userEvent), this.pushGtmTag(stageViewEvent)]);
        return;
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  private async createGtmStageViewEvent(stageId: string): Promise<IStageViewEvent> {
    try {
      const { id } = this.eventsStore.event;
      const stage = await this.stagesService.getOne(id, stageId);
      return {
        event: AnalyticsEventTypes.STAGE_VIEW,
        stageId: stageId,
        stageName: stage.name,
      };
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  private createGtmEventViewEvent(): IEventViewEvent {
    const { title, id, tags, location } = this.eventsStore.event;
    return {
      event: AnalyticsEventTypes.EVENT_VIEW,
      eventName: title,
      eventId: id,
      eventTags: tags,
      eventLocation: location,
    };
  }

  private createGtmUserEvent(event: NavigationEnd): IAnalyticsEvent | IAnalyticsUserEvent {
    const lang = this.translateService.currentLang;
    const {
      firstName,
      lastName,
      displayEmail,
      email,
      role,
      brandId,
      company,
      position,
      website,
      city,
      country,
      tags,
      id,
    } = this.usersStore.user;
    return {
      event: AnalyticsEventTypes.USER,
      pageName: event.url,
      firstName,
      lastName,
      displayEmail,
      email,
      role,
      brandId,
      company,
      position,
      website,
      city,
      country,
      tags,
      lang,
      id,
    };
  }

  async createGtmSpeakerEvent(speakerId: string): Promise<ISpeakerEvent> {
    try {
      const { id, firstName, lastName, company, tags, position } =
        await this.speakersService.getOne(speakerId);
      const event = this.eventsStore.event
        ? this.eventsStore.event.title
        : this.eventsStore.adminEvent.title;
      return {
        event: AnalyticsEventTypes.SPEAKER_VIEW,
        speakerId: id,
        speakerFirstName: firstName,
        speakerLastName: lastName,
        speakerCompany: company,
        speakerPosition: position,
        speakerTags: tags,
        speakerEvent: event,
      };
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async createGtmBrandEvent(brandId: string): Promise<IBrandEvent> {
    try {
      const { id, name } = await this.brandsService.getOne(brandId);
      const event = this.eventsStore.event.title;
      return {
        event: AnalyticsEventTypes.PARTNER_VIEW,
        brandId: id,
        brandName: name,
        brandEvent: event,
      };
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async gtmSpeakerBookmarkEventWithSpeaker(speaker: IUser): Promise<void> {
    try {
      const event = this.eventsStore.event.title;
      const speakerBookmarkedEvent: IBookmarkedSpeakerEvent = {
        event: AnalyticsEventTypes.SPEAKER_BOOKMARK,
        bookmarkedSpeakerId: speaker?.id,
        bookmarkedSpeakerName: speaker?.firstName,
        bookmarkedSpeakerSurname: speaker?.lastName,
        bookmarkedSpeakerCompany: speaker?.company,
        bookmarkedSpeakerPosition: speaker?.position,
        bookmarkedSpeakerEvent: event,
        bookmarkedSpeakerTags: speaker?.tags,
        currentPage: this.currentEvent.url,
      };
      const userEvent = this.createGtmUserEvent(this.currentEvent);

      await Promise.all([this.pushGtmTag(speakerBookmarkedEvent), this.pushGtmTag(userEvent)]);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async gtmBrandBookmarkEventWithBrand(brand: IBrand): Promise<void> {
    try {
      const event = this.eventsStore.event.title;
      const brandBookmarkedEvent: IBookmarkedBrandEvent = {
        event: AnalyticsEventTypes.PARTNER_BOOKMARK,
        bookmarkedBrandId: brand?.id,
        bookmarkedBrandName: brand?.name,
        bookmarkedBrandEvent: event,
        currentPage: this.currentEvent.url,
      };
      const userEvent = this.createGtmUserEvent(this.currentEvent);

      await Promise.all([this.pushGtmTag(brandBookmarkedEvent), this.pushGtmTag(userEvent)]);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async gtmSessionEvent(
    event: IEvent,
    session: ISession,
    sessionEvent: SessionEventTypes,
  ): Promise<void> {
    try {
      const sessionSpeakersName = session?.speakers
        ? await Promise.all([
            ...session.speakers.map((speakerId: string) => this.getSpeakersFullName(speakerId)),
          ])
        : [];
      const sessionBrandsName = session?.brands
        ? await Promise.all([...session.brands.map((brandId) => this.getBrandName(brandId))])
        : [];
      const sessionViewEvent: ISessionEvent = {
        event: sessionEvent,
        eventName: event.title,
        eventId: event.id,
        sessionId: session.id,
        sessionName: session.title,
        sessionStartTime: getTime(session.start),
        sessionEndTime: getTime(session.end),
        sessionSpeakerIds: session?.speakers,
        sessionSpeakersName,
        sessionBrandIds: session?.brands,
        sessionBrandsName,
        sessionTags: session?.tags,
      };
      const userEvent = this.createGtmUserEvent(this.currentEvent);
      await Promise.all([this.pushGtmTag(sessionViewEvent), this.pushGtmTag(userEvent)]);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  private async getSpeakersFullName(speakerId: string): Promise<string> {
    try {
      const { firstName, lastName } = await this.usersService.getOne(speakerId);

      return `${firstName} ${lastName}`;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  private async getBrandName(brandId: string): Promise<string> {
    try {
      const { name } = await this.brandsService.getOne(brandId);

      return name;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  private async pushGtmTag(gtmTag): Promise<void> {
    try {
      await this.gtmService.pushTag(gtmTag);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public async sendCheckoutEvent(gtmTag): Promise<void> {
    try {
      await this.gtmService.pushTag(gtmTag);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}
