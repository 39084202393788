import { Injectable } from '@angular/core';

import { Firestore, IFirestore } from 'src/app/firebase';
import { IUserPaymentSession } from 'src/app/core/models';
import { API_ROUTES as apiRoutes } from 'src/app/shared';

@Injectable({
  providedIn: 'root',
})
export class UserPaymentSessionsService {
  private firestore: IFirestore;

  constructor() {
    this.firestore = Firestore();
  }

  async getFirstPaymentSessionsByOrderId(orderId: string): Promise<IUserPaymentSession> {
    try {
      const userPaymentSessions: IUserPaymentSession[] = (
        await this.firestore
          .collection(apiRoutes.userPaymentSessions)
          .where('orderId', '==', orderId)
          .limit(1)
          .get()
      ).docs.map((doc) => doc.data() as IUserPaymentSession);

      if (!userPaymentSessions.length) {
        return null;
      }

      return userPaymentSessions[0];
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getUserPaymentSessionsByUserId(userId: string): Promise<IUserPaymentSession[]> {
    try {
      const userPaymentSessions: IUserPaymentSession[] = (
        await this.firestore
          .collection(apiRoutes.userPaymentSessions)
          .where('userId', '==', userId)
          .get()
      ).docs.map((doc) => doc.data() as IUserPaymentSession);

      return userPaymentSessions;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async deleteUserPaymentSession(userPaymentSessionId: string): Promise<boolean> {
    try {
      await this.firestore
        .collection(apiRoutes.userPaymentSessions)
        .doc(userPaymentSessionId)
        .delete();

      return true;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }
}
