import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { delay } from 'rxjs/operators';

import { Timestamp } from './../../../firebase';
import { Firestore, IFirestore } from 'src/app/firebase';
import { CollectionTypes } from 'src/app/shared';
import { INotification } from '../../models';
import { AuthenticationService } from '../auth';
import { UsersStore } from './../../stores/users/users.store';
import { NotificationTypes } from 'src/app/core/enums';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  private firestore: IFirestore;

  private totalUnreadNotifications$ = new BehaviorSubject<number>(0);

  private notificationsListener?: () => void;

  constructor(
    private usersStore: UsersStore,
    private authenticationService: AuthenticationService,
  ) {
    this.firestore = Firestore();

    // listen to notification totals
    this.authenticationService.isAuthenticated$
      .pipe(
        delay(500), // Needs to wait this time otherwise the "usersStore.user.id" is undefined 😧
      )
      .subscribe((isAuthenticated) => {
        if (isAuthenticated && this.usersStore.user && this.usersStore.user.id) {
          if (!this.notificationsListener) {
            this.notificationsListener = this.firestore
              .collection(`users/${this.usersStore.user.id}/notifications`)
              .where('isSeen', '==', false)
              .onSnapshot((snapshot) => {
                this.totalUnreadNotifications$.next(snapshot.size);
                this.usersStore.unreadNotificatios = snapshot.size;
              });
          }
        } else {
          if (this.notificationsListener) {
            this.notificationsListener();
            this.notificationsListener = null;
            this.totalUnreadNotifications$.next(0);
            this.usersStore.unreadNotificatios = 0;
          }
        }
      });
  }

  async getLatestNotifications(userId: string): Promise<INotification[]> {
    try {
      const notifications: INotification[] = (
        await this.firestore
          .collection(`${CollectionTypes.USERS}/${userId}/${CollectionTypes.NOTIFICATIONS}`)
          .orderBy('createdAt', 'desc')
          .orderBy('isSeen')
          .limit(20)
          .get()
      ).docs.map((doc) => doc.data() as INotification);

      return notifications;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getLatestWithoutMeetingNotifications(userId: string): Promise<INotification[]> {
    try {
      const notifications: INotification[] = (
        await this.firestore
          .collection(`${CollectionTypes.USERS}/${userId}/${CollectionTypes.NOTIFICATIONS}`)
          .where('type', 'not-in', [
            NotificationTypes.APPOINTMENT_ACCEPTED_INVITE,
            NotificationTypes.APPOINTMENT_ACCEPTED,
          ])
          .orderBy('type')
          .orderBy('createdAt', 'desc')
          .orderBy('isSeen')
          .limit(20)
          .get()
      ).docs.map((doc) => doc.data() as INotification);

      return notifications;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getLatestMeetingNotifications(userId: string): Promise<INotification[]> {
    try {
      const notifications: INotification[] = (
        await this.firestore
          .collection(`${CollectionTypes.USERS}/${userId}/${CollectionTypes.NOTIFICATIONS}`)
          .where('type', 'in', [
            NotificationTypes.APPOINTMENT_ACCEPTED_INVITE,
            NotificationTypes.APPOINTMENT_ACCEPTED,
          ])
          .orderBy('createdAt', 'desc')
          .orderBy('isSeen')
          .limit(20)
          .get()
      ).docs.map((doc) => doc.data() as INotification);

      return notifications;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getAllNotifications(userId: string): Promise<INotification[]> {
    try {
      const notifications: INotification[] = (
        await this.firestore
          .collection(`${CollectionTypes.USERS}/${userId}/${CollectionTypes.NOTIFICATIONS}`)
          .orderBy('createdAt', 'desc')
          .orderBy('isSeen')
          .get()
      ).docs.map((doc) => doc.data() as INotification);

      return notifications;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  totalUnreadNotifications(): Observable<number> {
    return this.totalUnreadNotifications$.asObservable();
  }

  async markAllNotificationsAsRead(): Promise<void> {
    const userId = this.usersStore.user.id;
    const notifications = await this.getAllNotifications(userId);

    await Promise.all(
      notifications.map((n) =>
        this.firestore
          .collection(`${CollectionTypes.USERS}/${userId}/${CollectionTypes.NOTIFICATIONS}`)
          .doc(n.id)
          .update({
            isSeen: true,
          }),
      ),
    );
  }

  async getAllEventNotifications(eventId: string): Promise<INotification[]> {
    try {
      const pages = await this.firestore
        .collection(`${CollectionTypes.EVENTS}/${eventId}/${CollectionTypes.NOTIFICATIONS}`)
        .orderBy('createdAt', 'desc')
        .get();
      if (!pages.empty) {
        return pages.docs.map((doc) => {
          const page = doc.data();
          return page as INotification;
        });
      } else {
        return [];
      }
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  async createEventNotification(
    notification: INotification,
    eventId: string,
  ): Promise<INotification> {
    try {
      const preNotificationReqDoc = this.firestore
        .collection(`${CollectionTypes.EVENTS}/${eventId}/${CollectionTypes.NOTIFICATIONS}`)
        .doc();
      notification.id = preNotificationReqDoc.id;
      notification.createdAt = Timestamp.now();
      notification.createdBy = this.usersStore.user.id;
      notification.updatedAt = null;
      notification.updatedBy = null;

      await this.firestore
        .collection(`${CollectionTypes.EVENTS}/${eventId}/${CollectionTypes.NOTIFICATIONS}`)
        .doc(notification.id)
        .set({ ...notification });

      return notification;
    } catch (error) {
      console.log(error);
    }
  }

  async updateEventNotification(notification: INotification, eventId: string) {
    try {
      const pageRef = this.firestore
        .collection(`${CollectionTypes.EVENTS}/${eventId}/${CollectionTypes.NOTIFICATIONS}`)
        .doc(notification.id);
      await pageRef.update(notification);
    } catch (error) {
      console.log(error);
    }
  }

  async getEventNotificationById(id: string, eventId: string): Promise<INotification> {
    try {
      const page = await this.firestore
        .collection(`${CollectionTypes.EVENTS}/${eventId}/${CollectionTypes.NOTIFICATIONS}`)
        .doc(id)
        .get();
      return page.data() as INotification;
    } catch (error) {
      console.log(error);
    }
  }

  async removeEventNotification(id: string, eventId: string): Promise<boolean> {
    try {
      await this.firestore
        .collection(`${CollectionTypes.EVENTS}/${eventId}/${CollectionTypes.NOTIFICATIONS}`)
        .doc(id)
        .delete();

      return true;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
}
