export interface ISubscription {
  id: string;
  aboobject: string;
  active: boolean;
  appName: string;
  hubId: string;
  loxAcademy: boolean;
  platform: string;
  name: string;
  createdAt: any;
  createdBy: string;
  udpatedAt: any;
  updatedBy: string;
  stripePriceId?: string;
  stripeProductId?: string;
  currency?: string;
  price?: number;
  isDefault?: boolean;
  planId?: string;
  orderIndex: number;
  billingPeriod: string;
}
