import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject, merge, take, takeUntil, tap } from 'rxjs';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';

import { IEvent } from 'src/app/core/models';
import { EventsService, FormService } from 'src/app/core/services';
import { EventsStore } from 'src/app/core/stores';
import { asyncDelay } from 'src/app/core/utils';
import { SharedModule } from 'src/app/shared';
import { SaveDiscardActionsComponent, ToastComponent } from 'src/app/standalone/shared';
import { EventSyncEventbriteComponent } from 'src/app/admin/dialogs/event-sync-eventbrite/event-sync-eventbrite.component';

@Component({
  selector: 'app-event-add-ons',
  standalone: true,
  imports: [SharedModule, SaveDiscardActionsComponent, ToastComponent],
  templateUrl: './event-add-ons.component.html',
  styleUrl: './event-add-ons.component.scss',
})
export class EventAddOnsComponent implements OnInit, OnDestroy {
  form: FormGroup;
  loading = true;
  isUpdating = false;
  isSyncing: boolean;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private eventsStore: EventsStore,
    private fb: FormBuilder,
    private formService: FormService,
    private eventsService: EventsService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private dialogService: DialogService,
  ) {}

  get event(): IEvent {
    return this.eventsStore.adminEvent;
  }

  get canUpdate(): boolean {
    return (
      !this.isUpdating && this.form.valid && this.form.dirty && this.formService.isValueChanged()
    );
  }

  get isSyncBtnDisabled(): boolean {
    const form = this.form?.value;
    return (
      this.isSyncing ||
      !form?.eventbriteEventId ||
      !form?.eventbriteToken ||
      !form?.eventbriteButton
    );
  }

  ngOnInit(): void {
    this.createForm();
    this.updateForm();
    this.formService.setForm(this.form);
    this.loading = false;

    if (this.form.controls.appointmentFeature.value) {
      this.form.controls.appointmentAttendeesStatus.enable();
      this.form.controls.appointmentSpeakersStatus.enable();
      this.form.controls.appointmentBrandPeopleStatus.enable();
    }

    const appointmentFeature$ = this.form.controls.appointmentFeature.valueChanges.pipe(
      tap((isActive: boolean) => {
        if (isActive) {
          this.form.controls.appointmentAttendeesStatus.enable();
          this.form.controls.appointmentSpeakersStatus.enable();
          this.form.controls.appointmentBrandPeopleStatus.enable();
          this.form.controls.onlineVideoMeetingStatus.enable();
        } else {
          this.form.controls.appointmentAttendeesStatus.patchValue(false);
          this.form.controls.appointmentSpeakersStatus.patchValue(false);
          this.form.controls.appointmentBrandPeopleStatus.patchValue(false);
          this.form.controls.onlineVideoMeetingStatus.patchValue(false);
          this.form.controls.appointmentAttendeesStatus.disable();
          this.form.controls.appointmentSpeakersStatus.disable();
          this.form.controls.appointmentBrandPeopleStatus.disable();
          this.form.controls.onlineVideoMeetingStatus.disable();
        }
      }),
    );

    const onlineVideoMeetingStatus$ = this.form.controls.onlineVideoMeetingStatus.valueChanges.pipe(
      tap((value: boolean) => {
        if (value) {
          this.form.controls.duration.enable();
        } else {
          this.form.controls.duration.setValue(null);
          this.form.controls.duration.disable();
        }
      }),
    );

    const eventbriteEventId$ = this.form.controls.eventbriteEventId.valueChanges.pipe(
      tap((eventId: string) => {
        if (eventId) {
          this.form.get('eventbriteButton').patchValue(this.createEventBriteEmbedCode(eventId));
        } else {
          this.form.get('eventbriteButton').patchValue(null);
        }
      }),
    );

    merge(appointmentFeature$, onlineVideoMeetingStatus$, eventbriteEventId$)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe();
  }

  async onSyncEventBrite(): Promise<void> {
    const syncDialogRef: DynamicDialogRef = this.dialogService.open(EventSyncEventbriteComponent, {
      width: '60%',
      height: '35%',
      dismissableMask: true,
      contentStyle: {
        overflow: 'auto',
        padding: '0',
        'border-radius': '20px',
      },
    });

    const result: 'syncAndEmail' | 'sync' = await syncDialogRef.onClose.pipe(take(1)).toPromise();
    if (!!result) {
      await this.syncEventBrite(result === 'syncAndEmail');
    }
  }

  onDiscard(): void {
    this.updateForm();
  }

  async onConfirm(): Promise<void> {
    try {
      this.isUpdating = true;
      await asyncDelay(1);
      await this.updateEvent();
      this.formService.setForm(this.form);
    } catch (error) {
      console.error(error);
    }
  }

  async updateEvent(): Promise<void> {
    try {
      this.isUpdating = true;
      const values = this.form.getRawValue();

      const eventForUpdate: IEvent = {
        ...this.event,
        ...values,
        duration: Number(values.duration),
      };
      const updatedEvent: IEvent = await this.eventsService.update(this.event.id, eventForUpdate);
      const newEvent = { ...this.eventsStore.adminEvent, ...updatedEvent };
      this.eventsStore.setAdminEvent(newEvent);
      this.isUpdating = false;

      this.messageService.add({
        severity: 'success',
        summary: this.translateService.instant('success'),
        detail: this.translateService.instant('adminEventSettings.successUpdateEvent'),
        styleClass: 'custom-toast',
      });
    } catch (error) {
      console.error(error);
      this.isUpdating = false;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private createForm(): void {
    this.form = this.fb.group({
      eventbriteSync: [null],
      eventbriteEventId: [null],
      eventbriteToken: [null],
      eventbriteButton: [null],
      appointmentFeature: [null],
      appointmentAttendeesStatus: [{ value: null, disabled: true }],
      appointmentSpeakersStatus: [{ value: null, disabled: true }],
      appointmentBrandPeopleStatus: [{ value: null, disabled: true }],
      onlineVideoMeetingStatus: [null],
      duration: [{ value: null, disabled: true }, Validators.required],
    });
  }

  private updateForm(): void {
    this.form.patchValue({ ...this.event }, { emitEvent: false });
  }

  private async syncEventBrite(sendEmail: boolean = false): Promise<void> {
    try {
      this.isSyncing = true;

      await this.eventsService.eventBriteSync(this.event, sendEmail);

      this.isSyncing = false;
      this.messageService.add({
        severity: 'success',
        summary: this.translateService.instant('success'),
        detail: this.translateService.instant('adminEventSettings.eventbriteSyncSuccess'),
        styleClass: 'custom-toast',
      });
    } catch (err) {
      this.isSyncing = false;
      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('error'),
        detail: this.translateService.instant('adminEventSettings.eventbriteSyncError'),
        styleClass: 'custom-toast',
      });
    }
  }

  private createEventBriteEmbedCode(eventBriteId: string): string {
    // eslint-disable-next-line max-len
    return `<noscript><a href="https://www.eventbrite.com/e/nodejs-event-tickets-${eventBriteId}" rel="noopener noreferrer" target="_blank"></noscript>
          <button id="eventbrite-widget-modal-trigger-${eventBriteId}" type="button">Buy Tickets</button>
          <noscript></a>Buy Tickets on Eventbrite</noscript>
          <script src="https://www.eventbrite.com/static/widgets/eb_widgets.js"></script>
          <script type="text/javascript">
              var exampleCallback = function() {
                  console.log('Order complete!');
              };
              window.EBWidgets.createWidget({
                  widgetType: 'checkout',
                  eventId: '${eventBriteId}',
                  modal: true,
                  modalTriggerElementId: 'eventbrite-widget-modal-trigger-${eventBriteId}',
                  onOrderComplete: exampleCallback
              });
          </script>`;
  }
}
