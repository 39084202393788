import { UsersStore } from 'src/app/core/stores/users/users.store';
import { Injectable } from '@angular/core';
import {
  Route,
  UrlSegment,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { UsersService } from 'src/app/core/services';

@Injectable({
  providedIn: 'root',
})
export class SystemSettingsGuard {
  constructor(
    private router: Router,
    private usersStore: UsersStore,
    private usersService: UsersService,
  ) {}

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    const user = await this.usersService.getOne(this.usersStore.user.id);

    return user.role === 'internalAdmin' ||
      user.role === 'superAdmin' ||
      user.role === 'instanceOwner' ||
      user.role === 'softwareDeveloper'
      ? true
      : this.router.navigate(['admin/dashboard']);
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }
}
