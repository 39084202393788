<div class="container">
  <header class="header">
    @if (!editingEmail) {
      <p-dropdown
        class="custom-dropdown"
        panelStyleClass="custom-dropdown-panel"
        [options]="templates"
        [formControl]="currentTemplate"
        optionLabel="name"
        placeholder="Template">
      </p-dropdown>
    } @else {
      <div class="subject-container">
        <p-dropdown
          class="custom-dropdown"
          panelStyleClass="custom-dropdown-panel"
          [options]="options"
          [formControl]="currentOption">
        </p-dropdown>
        <div class="separator"></div>
        <div
          *ngIf="dropdownLabel == 'Title'"
          class="title-container"
          (mouseenter)="hovering = true"
          (mouseleave)="hovering = false">
          <span *ngIf="!editMode; else editTemplate">{{ emailTitle.value }}</span>
          <ng-template #editTemplate>
            <input
              class="custom-input-text title-input"
              [formControl]="emailTitle"
              pInputText
              type="text"
              autofocus
              (blur)="editMode = false" />
          </ng-template>
          <i
            *ngIf="hovering"
            class="pi pi-pencil"
            (click)="toggleEdit()">
          </i>
        </div>
        <div
          *ngIf="dropdownLabel == 'Subject'"
          class="title-container"
          (mouseenter)="hovering = true"
          (mouseleave)="hovering = false">
          <span *ngIf="!editMode; else editTemplate">{{ subject.value }}</span>
          <ng-template #editTemplate>
            <input
              class="custom-input-text title-input"
              [formControl]="subject"
              pInputText
              type="text"
              autofocus
              (blur)="editMode = false" />
          </ng-template>
          <i
            *ngIf="hovering"
            class="pi pi-pencil"
            (click)="toggleEdit()">
          </i>
        </div>
      </div>
    }

    <div class="btn-container">
      <button
        class="send-to-btn"
        pButton
        pRipple
        label="{{ 'adminEmailTemplates.sendTo' | translate }}"
        icon="fa-regular fa-envelope"
        [disabled]="!isUnlayerEditorReady"
        (click)="onSendTo($event)"></button>
      <button
        class="save primary-text-color"
        pButton
        pRipple
        label="{{ 'adminEmailTemplates.save' | translate }}"
        [disabled]="!isUnlayerEditorReady"
        (click)="onSave()"></button>
      <i
        class="fa-regular fa-xmark close-icon"
        (click)="onCloseDialog()"></i>
    </div>
  </header>
  <main class="main">
    <div
      id="editor"
      style="width: 100%; height: 100%"></div>
  </main>
</div>

@if (showSendTo) {
  <div
    class="send-to-container"
    (appClickOutside)="onCloseSendTestEmailContainer()"
    #sendToContainer>
    <div class="send-to-content">
      <div class="title">{{ 'adminEmailTemplates.sendTo' | translate }}</div>
      <div class="flex">
        <div class="p-input-icon-left">
          <input
            class="custom-input-text"
            [(ngModel)]="sendToInputValue"
            pInputText
            type="text"
            placeholder="{{ 'adminEmailTemplate.sendToLabel' | translate }}" />
          <i class="fa-regular fa-envelope"></i>
        </div>
        <button
          class="send primary-text-color"
          pButton
          pRipple
          label="{{ 'adminEmailTemplate.sendTestEmailBtn' | translate }}"
          [disabled]="!isValidEmail(sendToInputValue)"
          (click)="onSendTestEmail()"></button>
      </div>
      <div
        class="error-content"
        *ngIf="!isValidEmail(sendToInputValue) && sendToInputValue?.length > 0">
        <app-input-error-message
          [showMessage]="!isValidEmail(sendToInputValue) && sendToInputValue?.length > 0"
          [errorMessage]="emailErrorMessage">
        </app-input-error-message>
      </div>
      <div class="description">{{ 'adminEmailTemplate.beforeSendingDescription' | translate }}</div>
    </div>
  </div>
}
