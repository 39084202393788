<ng-container *ngIf="loading">
  <app-loader></app-loader>
</ng-container>

<div
  *ngIf="!loading"
  class="brand-events-container">
  <admin-table-filter-navbar
    (onSearchTermChange)="searchValue = $event"></admin-table-filter-navbar>

  <admin-table
    [loading]="loadingEntries"
    [columns]="columns"
    [entries]="entries"
    [paginator]="true"
    [lazy]="false"
    [emptyStateKey]="'adminBrandEvents.emptyState'"
    [emptyStateIcon]="'far fa-screen-users'"
    [searchValue]="searchValue"
    [searchFilterFields]="searchFilterFields"
    (rowClicked)="onRowClicked($event)">
    <ng-template
      let-entry
      epTemplateKey="logoCell">
      <img
        *ngIf="entry.icon"
        style="height: 4rem; width: auto"
        [defaultImage]="imgPreloader"
        [lazyLoad]="entry.icon | imageSize" />
      <img
        *ngIf="!entry.icon"
        style="height: 4rem; width: auto"
        [src]="logoAvatar | imageSize" />
    </ng-template>
    <ng-template
      let-entry
      epTemplateKey="startDateCell">
      {{ entry.start | datePipe }}
    </ng-template>
    <ng-template
      let-entry
      epTemplateKey="endDateCell">
      {{ entry.end | datePipe }}
    </ng-template>
    <ng-template
      let-entry
      epTemplateKey="statusCell">
      <app-tag
        [iconClass]="entry.isPublished ? 'fa-regular fa-check' : 'fa-regular fa-xmark'"
        tagText="{{
          (entry.isPublished ? 'adminTable.statusActive' : 'adminTable.statusInactive') | translate
        }}"
        [bgColor]="entry.isPublished ? 'var(--appPrimaryColor)' : '#ff244c'">
      </app-tag>
    </ng-template>

    <ng-template
      let-entry
      epTemplateKey="actionsCell">
      <div class="table-btn-actions-container">
        <app-button
          [buttonType]="buttonType.ROUND"
          [buttonSize]="buttonSize.MEDIUM"
          [buttonStyle]="buttonStyle.FILLED_SECONDARY"
          buttonRoundIcon="far fa-pen-to-square"
          (click)="edit($event, entry)" />
      </div>
    </ng-template>
  </admin-table>
</div>
