<div class="container">
  <div
    class="img-container"
    [class.default-img-container]="!coverImage">
    <img
      [class.img]="coverImage"
      [class.default-img]="!coverImage"
      [src]="coverImage ? coverImage : logoAvatar" />
  </div>
  <div class="upload-block">
    <h5 class="title">{{ titleMediaForm | translate }}</h5>
    <p class="description">{{ description | translate }}</p>
    <app-default-covers (selectCover)="onSetDefaultCover($event)"></app-default-covers>
    <div class="actions">
      <input
        class="input"
        type="file"
        #coverImageUpload
        (change)="onCoverImageUpload($event)" />
      <div class="btn-actions-container">
        <app-button
          buttonText="{{ btnConfirm | translate }}"
          [buttonSize]="buttonSize.SMALL"
          [loading]="coverImageUploadProcess"
          (click)="coverImageUpload.click()" />
        <app-button
          buttonText="{{ btnCancel | translate }}"
          [buttonStyle]="buttonStyle.TERTIARY_SECONDARY"
          [buttonSize]="buttonSize.SMALL"
          (click)="clearCoverImage()" />
      </div>
    </div>
  </div>
</div>
