export interface ISpeakerEvent {
  event: string;
  speakerId: string;
  speakerFirstName: string;
  speakerLastName: string;
  speakerCompany: string;
  speakerPosition: string;
  speakerEvent: string;
  speakerTags: string[];
}
