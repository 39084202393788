<div *ngIf="loading">
  <app-loader></app-loader>
</div>

<div
  *ngIf="!loading"
  class="event-notifications-list-table-container">
  <admin-table-header-navbar [title]="'adminEventNotificationsList.title' | translate">
    <app-button
      buttonText="{{ 'adminEventNotificationsList.addBtnLabel' | translate }}"
      buttonIconLeft="far fa-plus"
      [buttonSize]="buttonSize.MEDIUM"
      (click)="add()" />
  </admin-table-header-navbar>

  <div class="c-my-4">
    <p-divider styleClass="custom-divider-horizontal"></p-divider>
  </div>

  <admin-table-filter-navbar
    (onSearchTermChange)="searchValue = $event"></admin-table-filter-navbar>

  <admin-table
    [loading]="loadingEntries"
    [columns]="columns"
    [entries]="entries"
    [paginator]="true"
    [lazy]="false"
    emptyStateKey="adminEventNotificationsList.emptyState"
    [searchValue]="searchValue"
    [searchFilterFields]="searchFilterFields"
    (rowClicked)="onRowClicked($event)">
    <ng-template
      let-entry
      epTemplateKey="typeCell">
      <p>
        {{ entry.type | titlecase }}
      </p>
    </ng-template>

    <ng-template
      let-entry
      epTemplateKey="createdOnCell">
      <p>
        {{ entry.createdAt | datePipe }}
      </p>
    </ng-template>

    <ng-template
      let-entry
      epTemplateKey="actionsCell">
      <div class="table-btn-actions-container">
        <app-button
          [buttonType]="buttonType.ROUND"
          [buttonSize]="buttonSize.MEDIUM"
          [buttonStyle]="buttonStyle.FILLED_SECONDARY"
          buttonRoundIcon="far fa-pen-to-square"
          (click)="edit($event, entry)" />
        <app-button
          [buttonType]="buttonType.ROUND"
          [buttonSize]="buttonSize.MEDIUM"
          [buttonStyle]="buttonStyle.FILLED_DANGER"
          buttonRoundIcon="far fa-trash-can"
          (click)="remove($event, entry)" />
      </div>
    </ng-template>
  </admin-table>
</div>
