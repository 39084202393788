import { IUser } from '../../models/user/user.model';
import { Injectable } from '@angular/core';
import { makeAutoObservable, observable } from 'mobx';

@Injectable()
// Every field/method related to bookmark should be the same as AttendeeStore
export class SpeakersStore {
  public speakersMap: { [key: string]: IUser } = {};
  public stageSpeakersMap: { [stageId: string]: IUser[] } = {};
  public bookmarkedIds: string[] = [];

  constructor() {
    makeAutoObservable(this, {
      speakersMap: observable.shallow,
    });
  }

  public setSpeakers(speakers: IUser[]): void {
    for (const speaker of speakers) {
      this.speakersMap[speaker.id] = speaker;
    }
  }

  public get speakers(): IUser[] {
    return Object.keys(this.speakersMap).map((id) => this.speakersMap[id]);
  }

  public setStageSpeakers(stageId: string, speakers: IUser[]): void {
    this.stageSpeakersMap[stageId] = speakers;

    for (const speaker of speakers) {
      this.speakersMap[speaker.id] = speaker;
    }
  }

  public setBookmarkedIds(ids: string[]): void {
    this.bookmarkedIds = ids;
  }

  public pushBookmarkedId(id: string): void {
    if (!this.bookmarkedIds.includes(id)) {
      this.bookmarkedIds.push(id);
    }
  }

  public popBookmarkedId(id: string): void {
    this.bookmarkedIds = this.bookmarkedIds.filter((_id) => _id !== id);
  }

  public reset(): void {
    this.speakersMap = {};
    this.stageSpeakersMap = {};
    this.bookmarkedIds = [];
  }
}
