import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { StripeService, UsersService } from '../../services';
import { UsersStore } from '../../stores';
import { timer } from 'rxjs';
import { switchMap, filter, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ChangeAuthTenant } from 'src/app/firebase';

@Component({
  selector: 'app-hub-pricing-table',
  templateUrl: './hub-pricing-table.component.html',
  styleUrls: ['./hub-pricing-table.component.scss'],
})
export class HubPricingTableComponent implements OnInit {
  loading = false;
  stripeEmbedCode: SafeHtml;
  stripe_key: string;
  stripe_table: string;
  stripeCustomerId: string;

  @ViewChild('stripeTable', { static: true }) stripeTable: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private stripeService: StripeService,
    public usersStore: UsersStore,
    private usersService: UsersService,
    private router: Router,
  ) {}

  async ngOnInit(): Promise<void> {
    if (environment) {
      this.stripe_key = environment.stripePricingTable.stripeKey;
      this.stripe_table = environment.stripePricingTable.stripeTable;
    }
    if (this.usersStore.user) {
      const platformStripeId = await this.stripeService.getStripePlatformAccountId();
      this.stripeCustomerId = await this.stripeService.getCustomerId(
        this.usersStore.user.id,
        platformStripeId,
      );
    }

    this.route.queryParams.subscribe(async (params) => {
      if (params.session_id) {
        this.loading = true;
        if (!this.usersStore.user) {
          const poll = timer(0, 5000).pipe(
            take(4),
            switchMap(() =>
              this.stripeService.getPendingInvitationUrlAndTenantIdWithCheckoutSessionId(
                params.session_id,
              ),
            ),
            filter((res) => res != null),
          );

          const subscription = poll.subscribe(
            (res) => {
              console.log('@url and tenantId', res);
              ChangeAuthTenant(res.tenantId);
              window.location.href = res.url;
              subscription.unsubscribe();
            },
            (error) => {
              console.error('Error fetching URL:', error);
            },
            () => {
              console.error('Failed to fetch URL after 4 attempts');
            },
          );
        } else {
          const updatedUser = await this.usersService.getOne(this.usersStore.user.id);
          this.usersStore.setUser(updatedUser);
          this.router.navigate(['/new-hub']);
        }
      }
    });
  }
}
