<ng-container *ngIf="loading">
  <app-loader></app-loader>
</ng-container>

<div
  *ngIf="!loading"
  class="admin-event-tickets-table-container">
  <admin-table-header-navbar [title]="'adminEventsTickets.title' | translate">
    <div class="flex align-items-center gap-2">
      @if (isShowSyncEventBriteBtn) {
        <app-button
          buttonText="{{ 'adminEventsTickets.syncFromEventBrite' | translate }}"
          [loading]="syncingTickets"
          [buttonStyle]="buttonStyle.FILLED_SECONDARY"
          [buttonSize]="buttonSize.MEDIUM"
          (click)="onSyncFromEventBrite()" />
      }

      <app-button
        buttonText="{{ 'adminSubscriptions.attachPlans' | translate }}"
        buttonIconLeft="fa-regular fa-money-check-pen"
        [buttonStyle]="buttonStyle.FILLED_SECONDARY"
        [buttonSize]="buttonSize.MEDIUM"
        (click)="openAttachSubscriptionsDialog()" />
      <app-button
        routerLink="/{{ hub ? hubsStore.useHubUrl : '' }}/admin/event/{{
          event?.link
        }}/registrations-tickets/tickets/new-ticket"
        buttonText="{{ 'adminEventsTickets.addBtn' | translate }}"
        buttonIconLeft="far fa-plus"
        [buttonSize]="buttonSize.MEDIUM" />
    </div>
  </admin-table-header-navbar>

  <div class="c-my-4">
    <p-divider styleClass="custom-divider-horizontal"></p-divider>
  </div>

  <admin-table-filter-navbar
    (onSearchTermChange)="searchValue = $event"></admin-table-filter-navbar>
  <admin-table
    [loading]="loading"
    [paginator]="true"
    [lazy]="false"
    [columns]="columns"
    [entries]="entries"
    [emptyStateKey]="'adminEventsTickets.emptyState'"
    [emptyStateIcon]="'fa-regular fa-ticket'"
    [searchValue]="searchValue"
    [searchFilterFields]="searchFilterFields"
    [sortField]="'createdAt'"
    (rowClicked)="onRowClicked($event)">
    <ng-template
      let-entry
      epTemplateKey="availabilityCell">
      {{ textForAvailability(entry) }}
    </ng-template>

    <ng-template
      let-entry
      epTemplateKey="startCell">
      <p class="m-0">{{ getDateForTicket(entry, 'start') }}</p>
      <p class="m-0">{{ getTimeForTicket(entry, 'start') }}</p>
    </ng-template>

    <ng-template
      let-entry
      epTemplateKey="endCell">
      <p class="m-0">{{ getDateForTicket(entry, 'end') }}</p>
      <p class="m-0">{{ getTimeForTicket(entry, 'end') }}</p>
    </ng-template>

    <ng-template
      let-entry
      epTemplateKey="priceCell">
      {{
        entry.price === '0' || !entry?.price ? ('adminEventsTickets.free' | translate) : entry.price
      }}
    </ng-template>

    <ng-template
      let-entry
      epTemplateKey="statusCell">
      <app-tag
        [iconClass]="entry.isPublished ? 'fa-regular fa-check' : 'fa-regular fa-xmark'"
        [bgColor]="entry.isPublished ? 'var(--appPrimaryColor)' : '#ff244c'">
      </app-tag>
    </ng-template>

    <ng-template
      let-entry
      epTemplateKey="actionsCell">
      <div class="table-btn-actions-container">
        <app-button
          [buttonType]="buttonType.ROUND"
          [buttonSize]="buttonSize.MEDIUM"
          [buttonStyle]="buttonStyle.FILLED_SECONDARY"
          buttonRoundIcon="far fa-pen-to-square"
          (click)="edit($event, entry)" />
        <app-button
          [buttonType]="buttonType.ROUND"
          [buttonSize]="buttonSize.MEDIUM"
          [buttonStyle]="buttonStyle.FILLED_DANGER"
          buttonRoundIcon="far fa-trash-can"
          (click)="remove($event, entry)" />
      </div>
    </ng-template>
  </admin-table>
</div>
