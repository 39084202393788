export interface IFinishUserRegistration {
  token: string;
  displayEmail: string;
  email: string;
  firstName: string;
  lastName: string;
  company: string;
  tags: string[];
  password: string;
}
