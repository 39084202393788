import { computed, Injectable, Signal } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import {
  AssetStatus,
  IAsset,
  IChapter,
  ICourse,
  ICourseTicket,
  IUser,
  IUserCourseTracking,
} from '../../models';
import {
  CourseChaptersService,
  CourseInstructorsService,
  CourseTrackingService,
  LibraryService,
  UsersService,
} from '../../services';
import { UsersStore } from '../users/users.store';
import { AssetFileTypes, AssetType } from '../../enums';
import { TicketsStore } from '../tickets/tickets.store';

@Injectable({
  providedIn: 'root',
})
export class RegisteredCourseStore {
  private _course = new BehaviorSubject<ICourse>(null);
  private _courseInstructors = new BehaviorSubject<IUser[]>(null);
  private _courseChapters = new BehaviorSubject<IChapter[]>(null);
  private _courseChaptersWithoutAssetEvents = new BehaviorSubject<IChapter[]>(null);
  private _courseChaptersOnlyAssetEvents = new BehaviorSubject<IChapter[]>(null);
  private _activeAsset = new BehaviorSubject<IAsset>(null);
  private _assetsWithTypeFile = new BehaviorSubject<IAsset[]>(null);
  private _activeAssetFileType = new BehaviorSubject<
    'img' | 'zip' | 'pdf' | 'vimeo' | 'internalVideo' | 'event'
  >(null);
  private isHideEventAssets: Signal<boolean> = computed(() => {
    const ticket: ICourseTicket = this.ticketsStore.courseTicket();

    return !!ticket && !ticket?.isShowLiveEvents;
  });

  constructor(
    private courseInstructorsService: CourseInstructorsService,
    private usersService: UsersService,
    private courseChaptersService: CourseChaptersService,
    private libraryService: LibraryService,
    private courseTrackingService: CourseTrackingService,
    private usersStore: UsersStore,
    private ticketsStore: TicketsStore,
  ) {}

  get course(): Observable<ICourse> {
    return this._course.asObservable();
  }

  get courseInstructors(): Observable<IUser[]> {
    return this._courseInstructors.asObservable();
  }

  get courseChapters(): Observable<IChapter[]> {
    return this._courseChapters.asObservable();
  }

  get courseChaptersWithoutAssetEvents(): Observable<IChapter[]> {
    return this._courseChaptersWithoutAssetEvents.asObservable();
  }

  get courseChaptersOnlyAssetEvents(): Observable<IChapter[]> {
    return this._courseChaptersOnlyAssetEvents.asObservable();
  }

  get activeAsset(): Observable<IAsset> {
    return this._activeAsset.asObservable();
  }

  get assetsWithTypeFile(): Observable<IAsset[]> {
    return this._assetsWithTypeFile.asObservable();
  }

  get activeAssetFileType(): Observable<
    'img' | 'zip' | 'pdf' | 'vimeo' | 'internalVideo' | 'event'
  > {
    return this._activeAssetFileType.asObservable();
  }

  async setCourse(course: ICourse): Promise<void> {
    this._course.next(course);
    if (course) {
      await this.getChapters();
      const activeAsset: IAsset = this.getActiveAsset();
      this.setActiveAsset(activeAsset);
      this.getAssetsWithFileType();
      await this.getCourseInstructors();
      this.getChaptersWithoutAssetEvents();
      this.getChaptersOnlyAssetEvents();
    }
  }

  async setActiveAsset(asset: IAsset): Promise<void> {
    if (!asset) {
      return;
    }
    let updatedAsset: IAsset;
    if (!asset?.userCourseTracking.isWatched && asset.type === AssetType.FILE) {
      const assetForUpdate: IAsset = {
        ...asset,
        userCourseTracking: {
          ...asset.userCourseTracking,
          percentageViewed: 100,
          isWatched: true,
        },
      };

      updatedAsset = await this.updateUserCourseTracking(assetForUpdate);
    }
    this._activeAsset.next(updatedAsset ?? asset);
    this.setActiveAssetFileType();
  }

  async updateUserCourseTracking(assetForUpdate: IAsset): Promise<IAsset> {
    await this.courseTrackingService.update(assetForUpdate.userCourseTracking);
    this.updateChapters(assetForUpdate);

    return assetForUpdate;
  }

  reset(): void {
    this._course.next(null);
    this._courseInstructors.next(null);
    this._courseChapters.next(null);
    this._activeAsset.next(null);
    this._assetsWithTypeFile.next(null);
    this._courseChaptersWithoutAssetEvents.next(null);
    this._courseChaptersOnlyAssetEvents.next(null);
    this._activeAssetFileType.next(null);
  }

  private async getChapters(): Promise<void> {
    const chapters: IChapter[] = await this.courseChaptersService.getAllPublishedByCourseId(
      this._course.getValue().id,
    );
    const chapTersWithAssetsFullModel: IChapter[] = await Promise.all(
      chapters.map(async (chapter: IChapter) => {
        const assetsFullModel: IAsset[] = await Promise.all(
          chapter.assets.map(async (assetId: string) => {
            const asset: IAsset = await this.libraryService.getOne(assetId);
            const userCourseTracking: IUserCourseTracking = await this.getUserCourseTracking(asset);
            return { ...asset, userCourseTracking };
          }),
        );
        const onlyPublishedAssetsFullModel: IAsset[] = assetsFullModel.filter(
          (asset: IAsset) => asset.status === AssetStatus.PUBLISHED,
        );

        return { ...chapter, assetsFullModel: onlyPublishedAssetsFullModel };
      }),
    );

    console.log(this.isHideEventAssets(), 'hide event assets');
    if (this.isHideEventAssets()) {
      console.log(1);
      this._courseChapters.next(this.filterChaptersWithoutEventAssets(chapTersWithAssetsFullModel));
    } else {
      console.log(2);
      this._courseChapters.next(chapTersWithAssetsFullModel);
    }
  }

  private getActiveAsset(): IAsset {
    const firstNotWatchedAsset: IAsset = this._courseChapters
      .getValue()
      .map((chapter: IChapter) => chapter.assetsFullModel)
      .flat()
      .find((asset: IAsset) => !asset.userCourseTracking.isWatched);

    return firstNotWatchedAsset || this._courseChapters.getValue()[0]?.assetsFullModel[0];
  }

  private updateChapters(asset: IAsset): void {
    const updatedChapters: IChapter[] = this._courseChapters.getValue().map((chapter: IChapter) => {
      const updatedAssetsFullModel: IAsset[] = chapter.assetsFullModel.map(
        (assetFullModel: IAsset) => {
          if (assetFullModel.id === asset.id) {
            return asset;
          }

          return assetFullModel;
        },
      );
      return { ...chapter, assetsFullModel: [...updatedAssetsFullModel] };
    });
    this._courseChapters.next(updatedChapters);
  }

  private getAssetsWithFileType(): void {
    const chapters: IChapter[] = this._courseChapters.getValue();
    const assetsWithTypeFile: IAsset[] = chapters
      .map((chapter: IChapter) => chapter.assetsFullModel)
      .flat()
      .filter((asset: IAsset) => asset.type === AssetType.FILE);
    this._assetsWithTypeFile.next(assetsWithTypeFile);
  }

  private async getUserCourseTracking(asset: IAsset): Promise<IUserCourseTracking> {
    let userCourseTracking: IUserCourseTracking;
    userCourseTracking = await this.courseTrackingService.getOneByIds(
      this._course.getValue().id,
      asset.id,
      this.usersStore.userId,
    );
    if (!userCourseTracking) {
      const newUserCourseTracking = {
        id: null,
        courseId: this._course.getValue().id,
        assetId: asset.id,
        userId: this.usersStore.userId,
        percentageViewed: 0,
        secondsViewed: 0,
        isWatched: false,
        videoLink: asset.videoLink,
      };
      userCourseTracking = await this.courseTrackingService.create(newUserCourseTracking);
    }

    return userCourseTracking;
  }

  private async getCourseInstructors(): Promise<void> {
    const courseInstructorsId: string[] =
      await this.courseInstructorsService.getAllCourseInstructorsIds(this._course.getValue().id);
    const courseInstructors = await Promise.all(
      courseInstructorsId.map((instructorId: string) => this.usersService.getOne(instructorId)),
    );
    this._courseInstructors.next(courseInstructors);
  }

  private filterChaptersWithoutEventAssets(chapters: IChapter[]): IChapter[] {
    const chaptersWithoutEventAssets: IChapter[] = [];
    chapters.forEach((courseChapter: IChapter) => {
      const fileVideoAssets: IAsset[] = courseChapter.assetsFullModel.filter(
        (asset: IAsset) => asset.type !== AssetType.EVENT,
      );
      if (fileVideoAssets?.length) {
        chaptersWithoutEventAssets.push({ ...courseChapter, assetsFullModel: fileVideoAssets });
      }
    });

    return chaptersWithoutEventAssets;
  }

  private getChaptersWithoutAssetEvents(): void {
    this._courseChaptersWithoutAssetEvents.next(
      this.filterChaptersWithoutEventAssets(this._courseChapters.getValue()),
    );
  }

  private getChaptersOnlyAssetEvents(): void {
    const courseChaptersOnlyAssetEvents: IChapter[] = this._courseChapters
      .getValue()
      .map((courseChapter: IChapter) => {
        const assetEvents: IAsset[] = courseChapter.assetsFullModel.filter(
          (asset: IAsset) => asset.type === AssetType.EVENT,
        );

        return { ...courseChapter, assetsFullModel: assetEvents };
      });

    this._courseChaptersOnlyAssetEvents.next(courseChaptersOnlyAssetEvents);
  }

  private setActiveAssetFileType(): void {
    const asset: IAsset = this._activeAsset.getValue();
    if (
      asset.type === AssetType.FILE &&
      (asset.fileType === AssetFileTypes.JPG || asset.fileType === AssetFileTypes.PNG)
    ) {
      this._activeAssetFileType.next('img');
    } else if (asset.type === AssetType.FILE && asset.fileType === AssetFileTypes.PDF) {
      this._activeAssetFileType.next('pdf');
    } else if (asset.type === AssetType.FILE && asset.fileType === AssetFileTypes.ZIP) {
      this._activeAssetFileType.next('zip');
    } else if (asset.type === AssetType.VIDEO && !!asset.internalVideo) {
      this._activeAssetFileType.next('internalVideo');
    } else if (asset.type === AssetType.EVENT) {
      this._activeAssetFileType.next('event');
    } else {
      this._activeAssetFileType.next('vimeo');
    }
  }
}
