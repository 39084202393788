<p-sidebar
  [modal]="false"
  [baseZIndex]="10000"
  position="right"
  [showCloseIcon]="false"
  [(visible)]="appStore.isSideBarVisible"
  styleClass="sidebar-navigation-dialog mobile">
  <app-sidebar-navigation></app-sidebar-navigation>
</p-sidebar>

<div
  class="navigation-container"
  *ngIf="!hideNavigation"
  [ngClass]="{ 'is-ios-pwa': isIosPWA }">
  <a
    routerLink="/{{ hubsStore.useHubUrl }}/events/{{ eventsStore.eventId }}/stages/{{
      mainStage?.id
    }}"
    routerLinkActive="active"
    *ngIf="!hideMainStage">
    <i
      class="fak fa-lox-mainstage"
      aria-hidden="true"></i>
    <span>
      <!-- {{'asideNavigation.mainStageMenuItem' | translate }} -->
      {{ mainStage?.name }}
    </span>
  </a>
  <a
    *ngIf="nonMainStages && nonMainStages.length > 0 && !hideNonMainStages"
    routerLink="/{{ hubsStore.useHubUrl }}/events/{{ eventsStore.eventId }}/stages/all"
    routerLinkActive="active">
    <i
      class="fak fa-lox-stages"
      aria-hidden="true"></i>
    <span>{{ 'asideNavigation.allStagesMenuItem' | translate }}</span>
  </a>
  <a
    routerLink="/{{ hubsStore.useHubUrl }}/events/{{ eventsStore.eventId }}/program"
    routerLinkActive="active"
    *ngIf="!hideProgram">
    <i
      class="fak fa-lox-calendar"
      aria-hidden="true"></i>
    <span>{{ 'asideNavigation.programMenuItem' | translate }}</span>
  </a>
  <!-- <a routerLink="/{{hubsStore.useHubUrl}}/events/{{eventsStore.eventId}}/chat" routerLinkActive="active">
    <i class="fak fa-lox-community" aria-hidden="true"></i>
    <span>{{'chat.chat' | translate }}</span>
  </a> -->
  <a (click)="toggleMenu()">
    <i
      class="far fa-bars"
      aria-hidden="true"></i>
    <span>{{ 'mobileNavigation.menu' | translate }}</span>
  </a>
</div>
