import { Component } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';

import { ThemesService } from 'src/app/core/services';
import { HubsStore } from 'src/app/core/stores';

@Component({
  selector: 'app-event-page-preview',
  templateUrl: './event-page-preview.component.html',
  styleUrls: ['./event-page-preview.component.scss'],
})
export class EventPagePreviewComponent {
  public html: SafeHtml = null;

  constructor(
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private sanitizer: DomSanitizer,
    public hubsStore: HubsStore,
    private themesService: ThemesService,
  ) {
    const logo = this.hubsStore.hub
      ? this.themesService.activeTheme.name === 'dark'
        ? this.hubsStore.hub.logoDark
        : this.hubsStore.hub.logoLight
      : this.themesService.activeTheme.name === 'dark'
        ? this.themesService.systemAppearanceSettings?.logo
        : this.themesService.systemAppearanceSettings?.logoLight;

    let html = config.data?.html || null;
    html = html?.replace(
      /{{event.logoDark}}|{{event.logoLight}}|{{event.banner}}|{{user.profileImage}}|{{user.coverImage}}|{{brand.logo}}|{{brand.banner}}|{{course.banner}}|{{course.featuredImage}}|{{hub.banner}}|{{hub.icon}}|{{hub.logoDark}}|{{hub.logoLight}}/gi,
      logo,
    );

    if (html) {
      this.html = this.sanitizer.bypassSecurityTrustHtml(html);
    }
  }
}
