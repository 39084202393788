import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/database';
import 'firebase/compat/auth';
import 'firebase/compat/storage';
import 'firebase/compat/app-check';
import 'firebase/compat/messaging';
import { environment } from '../environments/environment';
import { AppStore } from './app.store';
import { appInjector } from './app.module';

export type IMessaging = firebase.messaging.Messaging;
export type IAuth = firebase.auth.Auth;
export type IFirestore = firebase.firestore.Firestore;
export type IDatabase = firebase.database.Database;
export type IStorage = firebase.storage.Storage;
export type IFunctions = firebase.functions.Functions;
export type IDocumentData = firebase.firestore.DocumentData;
export type IDocumentReference<T = IDocumentData> = firebase.firestore.DocumentReference<T>;
export type IQuery<T = IDocumentData> = firebase.firestore.Query<T>;
export type IQuerySnapshot<T = IDocumentData> = firebase.firestore.QuerySnapshot<T>;
export type IDocumentSnapshot<T = IDocumentData> = firebase.firestore.DocumentSnapshot<T>;
export type IQueryDocumentSnapshot<T = IDocumentData> = firebase.firestore.QueryDocumentSnapshot<T>;
export type IFirebaseUser = firebase.User;
export type IReference = firebase.storage.Reference;
export type IFullMetadata = firebase.storage.FullMetadata;

export class Timestamp extends firebase.firestore.Timestamp {}
export class FieldPath extends firebase.firestore.FieldPath {}

export const ServerTimestamp = () => firebase.firestore.FieldValue.serverTimestamp();
export const ArrayRemove = (...elements: any[]) =>
  firebase.firestore.FieldValue.arrayRemove(...elements);

let auth: IAuth = null;
// const initializeFirebaseApp = (): firebase.app.App => {
//   const firebaseApp = firebase.initializeApp(appStore.environment.firebase);
//   activateAppCheck();
//   return firebaseApp;
// };

// const activateAppCheck = () => {
//   if (appStore.environment.reCaptcha.siteKey) {
//     const appCheck = firebase.appCheck();
//     const shouldAutoRefreshTokens = true;
//     appCheck.activate(appStore.environment.reCaptcha.siteKey, shouldAutoRefreshTokens);
//   }
// };

// export const FirebaseApp = () => !firebase.apps.length ? initializeFirebaseApp() : firebase.app();

export const FirebaseAuthNamespace = firebase.auth;

export const FirebaseApp = () => {
  const appStore = appInjector.get(AppStore);
  return !firebase.apps.length
    ? firebase.initializeApp(appStore.environment.firebase)
    : firebase.app();
};

export const ChangeAuthTenant = (tenantId = null): void => {
  console.log('======================= CHANGE AUTH TENANT ======================');
  console.log('TENANT ID => ' + tenantId);

  auth = FirebaseApp().auth() as IAuth;
  if (tenantId) auth.tenantId = tenantId;
};

export const Auth = (): IAuth => {
  if (auth) {
    return auth;
  } else {
    auth = FirebaseApp().auth() as IAuth;
    useEmulator();
    return auth;
  }
};

const useEmulator = () => {
  if (environment.env === 'LOCAL') {
    auth.useEmulator('http://localhost:9099');
  }
};

export const Messaging = (): IMessaging => {
  try {
    return FirebaseApp().messaging() as IMessaging;
  } catch (error) {
    console.log('===================================================');
    console.log('Firebase Messaging is not supported on your device!');
    console.log('===================================================');
    console.log(error);
    return null;
  }
};

export const Firestore = (): IFirestore => {
  const firestore = FirebaseApp().firestore() as IFirestore;
  if (
    environment.env === 'LOCAL' &&
    firestore['_delegate']['_settings']['host'] !== 'localhost:8080'
  )
    firestore.useEmulator('localhost', 8080);
  return firestore;
};

export const Database = (): IDatabase => {
  const database = FirebaseApp().database() as IDatabase;
  if (environment.env === 'LOCAL') database.useEmulator('localhost', 9000);
  return database;
};

export const Storage = (): IStorage => {
  const storage = FirebaseApp().storage() as IStorage;
  if (environment.env === 'LOCAL') storage.useEmulator('localhost', 9199);
  return storage;
};

export const Functions = (): IFunctions => {
  const functions = FirebaseApp().functions() as IFunctions;
  if (environment.env === 'LOCAL') functions.useEmulator('localhost', 5001);
  return functions;
};
