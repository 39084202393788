<div class="container">
  <div class="assets-container">
    @for (asset of assets; track asset.id) {
      <app-file-asset-card
        [asset]="asset"
        (downloadAsset)="onDownloadAsset(asset)">
      </app-file-asset-card>
    } @empty {
      <div class="empty-list">
        <app-empty-state
          icon="fa-regular fa-copy"
          text="{{ 'courseRegisteredPage.noAssetFiles' | translate }}">
        </app-empty-state>
      </div>
    }
  </div>
  <div
    class="download"
    [class.disabled]="!assets?.length"
    (click)="onDownloadAll()">
    <i class="fa-regular fa-cloud-arrow-down"></i>
    <span>{{ 'courseRegisteredPage.downloadAll' | translate }}</span>
  </div>
</div>
