import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

import { IUser } from 'src/app/core/models';

@Component({
  selector: 'app-course-user-dialog',
  templateUrl: './course-user-dialog.component.html',
  styleUrls: ['./course-user-dialog.component.scss'],
})
export class CourseUserDialogComponent implements OnInit {
  user: IUser;

  constructor(private config: DynamicDialogConfig) {}

  ngOnInit(): void {
    this.user = this.config.data.user;
  }
}
