import { Component, OnDestroy, OnInit, inject, signal } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject, debounceTime, takeUntil, tap } from 'rxjs';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

import { ICountry, IUser } from 'src/app/core/models';
import { FormService, UsersService } from 'src/app/core/services';
import { UsersStore } from 'src/app/core/stores';
import { Salutations, SharedModule, UsersMaxLengthValidators } from 'src/app/shared';
import { SaveDiscardActionsComponent, ToastComponent } from 'src/app/standalone/shared';
import {
  VALID_PHONE_PATTERN,
  VIRTUAL_SCROLL_ITEM_SIZE,
  asyncDelay,
  countries,
} from 'src/app/core/utils';
import { AreaOfActivity, CompanySize, Industry, OptionalPosition } from 'src/app/core/enums';

@Component({
  selector: 'app-user-profile',
  standalone: true,
  imports: [SharedModule, SaveDiscardActionsComponent, ToastComponent],
  templateUrl: './user-profile.component.html',
  styleUrl: './user-profile.component.scss',
})
export class UserProfileComponent implements OnInit, OnDestroy {
  loading = signal(true);
  isUpdating = signal(false);
  form: FormGroup;
  subIndustriesOptions = signal<string[]>(null);
  countriesTranslated = signal<ICountry[]>(null);

  virtualScrollItemSize = signal<number>(VIRTUAL_SCROLL_ITEM_SIZE).asReadonly();
  salutations = signal<string[]>(Object.values(Salutations)).asReadonly();
  industryOptions = signal<string[]>(Object.values(Industry)).asReadonly();
  areaOfActivityOptions = signal<string[]>(Object.values(AreaOfActivity)).asReadonly();
  optionalPositionOptions = signal<string[]>(Object.values(OptionalPosition)).asReadonly();
  companySizeOptions = signal<string[]>(Object.values(CompanySize)).asReadonly();

  private unsubscribe$ = new Subject<void>();
  private usersStore = inject(UsersStore);
  private usersService = inject(UsersService);
  private fb = inject(FormBuilder);
  private formService = inject(FormService);
  private messageService = inject(MessageService);
  private translateService = inject(TranslateService);

  get user(): IUser {
    return this.usersStore.adminUser;
  }

  get canUpdate(): boolean {
    return (
      !this.isUpdating() && this.form.valid && this.form.dirty && this.formService.isValueChanged()
    );
  }

  ngOnInit(): void {
    this.createForm();
    this.updateForm();
    this.formService.setForm(this.form);
    this.getSubIndustries(this.user?.industry);
    this.countriesTranslated.set(
      countries.map((country: ICountry) => ({
        name: this.translateService.instant(country.name),
        code: country.name,
      })),
    );
    this.loading.set(false);

    if (!this.user?.industry) {
      this.form.controls.subIndustry.disable();
    }

    this.form.controls.industry.valueChanges
      .pipe(
        debounceTime(300),
        tap((industry: Industry) => {
          this.getSubIndustries(industry);
          this.form.controls.subIndustry.enable();
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();
  }

  getMaxLengthValue(formControl: string): number {
    return Number(UsersMaxLengthValidators[formControl]);
  }

  onDiscard(): void {
    this.updateForm();
  }

  async onConfirm(): Promise<void> {
    try {
      this.isUpdating.set(true);
      await asyncDelay(1);
      await this.updateUser();
      this.formService.setForm(this.form);
    } catch (error) {
      console.error(error);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private createForm(): void {
    this.form = this.fb.group({
      salutation: [null],
      firstName: [
        null,
        [Validators.required, Validators.maxLength(UsersMaxLengthValidators.firstName.valueOf())],
      ],
      lastName: [
        null,
        [Validators.required, Validators.maxLength(UsersMaxLengthValidators.lastName.valueOf())],
      ],
      company: [null, [Validators.maxLength(UsersMaxLengthValidators.company.valueOf())]],
      industry: [null],
      subIndustry: [null],
      areaOfActivity: [null],
      optionalPosition: [null],
      position: [null],
      department: [null, [Validators.maxLength(UsersMaxLengthValidators.department.valueOf())]],
      companySize: [null],
      mobile: [null],
      street: [null, [Validators.maxLength(UsersMaxLengthValidators.street)]],
      postcode: [null, [Validators.maxLength(UsersMaxLengthValidators.postcode)]],
      city: [null],
      country: [null],
      phone: [
        null,
        [
          Validators.maxLength(UsersMaxLengthValidators.phone),
          Validators.pattern(VALID_PHONE_PATTERN),
        ],
      ],
    });
  }

  private updateForm(): void {
    this.form.patchValue({ ...this.user });
  }

  private getSubIndustries(industry: Industry): void {
    this.subIndustriesOptions.set(this.usersStore.getUserSubIndustries(industry));
  }

  private async updateUser(): Promise<void> {
    this.isUpdating.set(true);

    try {
      const formValues = this.form.getRawValue();
      const newUser: IUser = {
        ...this.user,
        ...formValues,
      };

      await this.usersService.update(this.user.id, newUser);
      this.usersStore.setAdminUser(newUser);
      this.showToastMessage('success', 'adminUserProfile.userSuccessfullyUpdated');
    } catch (error) {
      console.log(error);
      this.showToastMessage('error', 'adminUserProfile.errorUpdateUser');
    } finally {
      this.isUpdating.set(false);
    }
  }

  private showToastMessage(severity: 'success' | 'error', detail: string): void {
    this.messageService.add({
      severity,
      summary: this.translateService.instant(severity),
      detail: this.translateService.instant(detail),
      styleClass: 'custom-toast',
    });
  }
}
