<div class="container">
  <div class="stat-chart">
    <app-stat-chart
      statName="Event Activity Score"
      tooltipText="Event Activity Score Summary"
      [chartType]="lineChartType"
      [chartData]="lineChartData"
      [chartOptions]="lineChartOptions"
      [chartLegend]="false">
    </app-stat-chart>
  </div>
  <div class="stat-single-progress-bar">
    <app-stat-single-progress-bar
      statName="Event Activity Score"
      tooltipText="Event Activity Score Summary"
      progressBarValue="90"
      progressBarText="Event Activity Score"
      [listOptions]="statSingleProgressBarOptions">
    </app-stat-single-progress-bar>
  </div>
  <div class="stat-cards-container">
    <ng-container *ngFor="let stat of statCards; let last = last; trackBy: trackByFn">
      <div class="card-container">
        <app-stat-card
          [iconClass]="stat.iconClass"
          [text]="stat.text"
          [value]="stat.value">
        </app-stat-card>
      </div>
      <ng-container *ngIf="!last">
        <p-divider
          styleClass="custom-divider-vertical-2"
          layout="vertical"></p-divider>
      </ng-container>
    </ng-container>
  </div>
</div>
