import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { autorun } from 'mobx';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';

import {
  UsersService,
  ThemesService,
  AuthenticationService,
  EventsService,
  StripeService,
  TicketsService,
} from 'src/app/core/services';
import { UsersStore, EventsStore, HubsStore } from 'src/app/core/stores';
import { AppStore } from 'src/app/app.store';
import { environment } from 'src/environments/environment';
import { imgPreloader, wVLogo, parseToMoment } from 'src/app/shared';
import {
  ICreateStripeSessionCheckout,
  IEvent,
  IHub,
  ITicket,
  IUser,
  IUserEvent,
  UserHubRoles,
} from 'src/app/core/models';
import { TicketsSingleSelectDialogComponent } from 'src/app/event/dialogs';
import { StripeSessionCheckoutMode } from 'src/app/core/enums';
import { SigninComponent } from '../../../auth/views/signin/signin.component';

@Component({
  selector: 'app-main-navigation',
  templateUrl: './main-navigation.component.html',
  styleUrls: ['./main-navigation.component.scss'],
})
export class MainNavigationComponent implements OnInit {
  isAuthenticated$: Observable<boolean>;
  logo: string;
  isAdminPanel = false;
  isLogoutScreen = false;
  defaultImage = imgPreloader;
  WVLogo = wVLogo;
  searchLoading = true;
  hideSearch = false;
  freshUser: IUser;
  showUpgradeButton = false;

  constructor(
    private router: Router,
    public appStore: AppStore,
    private authenticationService: AuthenticationService,
    private themesService: ThemesService,
    public hubsStore: HubsStore,
    public eventsStore: EventsStore,
    public eventsService: EventsService,
    private usersService: UsersService,
    public usersStore: UsersStore,
    private dialogService: DialogService,
    private stripeService: StripeService,
    private ticketsService: TicketsService,
    private messageService: MessageService,
    private translateService: TranslateService,
  ) {
    this.isAuthenticated$ = this.authenticationService.isAuthenticated$;

    this.isAdminPanel = this.router.url.indexOf('admin/') > -1;
    this.isLogoutScreen = this.router.url.indexOf('logout') > -1;

    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.isAdminPanel = val.urlAfterRedirects.includes('admin/');
        this.isLogoutScreen = val.urlAfterRedirects.includes('logout');
        this.setLogoAccordingToRoute();
        this.checkEventSettings();
      }
    });

    autorun(() => {
      this.setLogoAccordingToRoute();
    });
  }

  get isGlobalSingleRoute(): boolean {
    return this.themesService.systemAppearanceSettings.globalSingleRoute;
  }

  get hub(): IHub {
    return this.hubsStore.hub;
  }

  get isShowMainNav(): boolean {
    return !this.isLogoutScreen && !this.eventsStore.isEventRegistered;
  }

  async ngOnInit(): Promise<void> {
    if (this.usersStore.user) {
      await this.usersService.getUserHubs(this.usersStore.user?.id, true);
      this.freshUser = await this.usersService.getOne(this.usersStore.user?.id);
      this.checkEventSettings();
    }
  }

  setLogoAccordingToRoute(): void {
    if (this.isAdminPanel) {
      this.setLogoFromSystemSettings();
    } else if (
      this.eventsStore.event &&
      this.hasEventLogo() &&
      this.eventsStore.isEventRegistered
    ) {
      this.logo =
        this.themesService.activeTheme.name === 'dark'
          ? this.eventsStore.event.logoDark
          : this.eventsStore.event.logoLight;
    } else if (this.hubsStore.hub && this.hasHubLogo()) {
      this.logo =
        this.themesService.activeTheme.name === 'dark'
          ? this.hubsStore.hub.logoDark
          : this.hubsStore.hub.logoLight;
    } else {
      this.setLogoFromSystemSettings();
    }
  }

  setLogoFromSystemSettings(): void {
    if (this.themesService.activeTheme.name === 'dark') {
      this.logo = this.hubsStore.hub?.logoDark
        ? this.hubsStore.hub?.logoDark
        : this.themesService.systemAppearanceSettings?.logo;
    } else {
      this.logo = this.hubsStore.hub?.logoLight
        ? this.hubsStore.hub?.logoLight
        : this.themesService.systemAppearanceSettings?.logoLight;
    }
  }

  onLogoClick(): void {
    if (this.eventsStore.eventId && this.eventsStore.isEventRegistered) {
      this.router.navigate([`${this.hubsStore.useHubUrl}/events/${this.eventsStore.event.id}`]);
    } else {
      this.router.navigate([`${this.hubsStore.useHubUrl}/dashboard`]);
    }
  }

  canEditHub(hubId: string): boolean {
    return (
      this.usersStore.userHubs.map((hub) => hub.hubId).includes(hubId) &&
      (this.usersStore.userHubs.map((hub) => hub.role).includes(UserHubRoles.ADMIN) ||
        this.usersStore.userHubs.map((hub) => hub.role).includes(UserHubRoles.OWNER))
    );
  }

  openWVSigninDialog(): void {
    this.dialogService.open(SigninComponent, {
      width: '30%',
      height: '60%',
      dismissableMask: true,
      contentStyle: {
        overflow: 'hidden',
        padding: '0',
        'border-radius': '20px',
      },
      data: { isSSO: true },
    });
  }

  onSignIn(): void {
    this.router.navigate([`${this.hubsStore.useHubUrl}/signin`], {
      state: { url: this.router.url },
    });
  }

  onSignUp(): void {
    this.router.navigate([`${this.hubsStore.useHubUrl}/signup`], {
      state: { url: this.router.url },
    });
  }

  async openMultiTicketingDialog() {
    const event = this.eventsStore.event;
    const dialogRef: DynamicDialogRef = this.dialogService.open(
      TicketsSingleSelectDialogComponent,
      {
        width: '80vw',
        height: '80vh',
        styleClass: 'multi-ticketing-tickets-dialog',
        data: { event },
      },
    );

    const ticketForPurchase: ITicket = await dialogRef.onClose.pipe(take(1)).toPromise();
    if (!ticketForPurchase) {
      return false;
    }
    if (ticketForPurchase.price !== '0') {
      this.buyTicket(ticketForPurchase, event);
    } else {
      const userEvent: IUserEvent = this.usersStore.getUserEvent(event.id);
      userEvent.ticketId = ticketForPurchase.id;
      userEvent.ticketPrice = Number(ticketForPurchase.price);
      const newUserEvent = await this.eventsService.updateUserEvent(userEvent);
      this.usersStore.setUserEvents([newUserEvent]);
      this.router.navigate([`${this.hubsStore.useHubUrl}/events/${event.id}`]);
    }
  }

  async buyTicket(ticket: ITicket, event: IEvent): Promise<void> {
    try {
      const stripeConnectId = await this.stripeService.getStripeConnectIdByHubId(
        this.hubsStore.hub.id,
      );
      await this.stripeService.checkStripeCustomer();
      let stripeRedirectUrls;

      if (environment.env === 'LOCAL') {
        const successUrlFirstPart = `http://127.0.0.1:4200/${this.hubsStore.useHubUrl}/events/${event.id}`;
        stripeRedirectUrls = {
          successUrl: `${successUrlFirstPart}?action=ticket_purchased_on_demand&ticket_id=${ticket.id}&price_id=${ticket.stripePriceId}`,
          cancelUrl: `http://127.0.0.1:4200/${this.hubsStore.useHubUrl}/events/${event.id}?action=payment_failed_on_demand`,
        };
      } else {
        const successUrlFirstPart = `${this.hubsStore.environmentBaseUrl}/${this.hubsStore.useHubUrl}/events/${event.id}`;
        stripeRedirectUrls = {
          successUrl: `${successUrlFirstPart}?action=ticket_purchased_on_demand&ticket_id=${ticket.id}&price_id=${ticket.stripePriceId}`,
          cancelUrl: `${this.hubsStore.environmentBaseUrl}/${this.hubsStore.useHubUrl}/events/${event.id}?action=payment_failed_on_demand`,
        };
      }

      const paymentMetadata: Record<string, string> = {
        accounting_number: this.eventsStore.event.accountingNumber,
        event_id: this.eventsStore.eventId,
        event_start: parseToMoment(this.eventsStore.event.start).format('YYYY-MM-DD'),
        event_end: parseToMoment(this.eventsStore.event.end).format('YYYY-MM-DD'),
      };

      const checkoutSessionData: ICreateStripeSessionCheckout = {
        successUrl: stripeRedirectUrls.successUrl,
        cancelUrl: stripeRedirectUrls.cancelUrl,
        mode: StripeSessionCheckoutMode.PAYMENT,
        amount: Number(ticket.price),
        connectId: stripeConnectId,
        customerId: this.usersStore.user.stripeId,
        tickets: [ticket],
        metadata: paymentMetadata,
        description: this.eventsStore.event.title,
        userId: this.usersStore.user.id,
      };

      const sessionUrl = await this.stripeService.getCheckoutLink(checkoutSessionData);
      window.location.href = sessionUrl;
    } catch (error) {
      this.showToastMessage('error', `stripeErrorCodes.${error.message}`);
    }
  }

  isVisibleSidebarItem(item: 'events' | 'academy' | 'brands'): boolean {
    if (!this.hubsStore.hub) {
      return true;
    }

    return this.hubsStore.hub?.[item];
  }

  private hasEventLogo(): boolean {
    const logo =
      this.themesService.activeTheme.name === 'dark'
        ? this.eventsStore.event.logoDark
        : this.eventsStore.event.logoLight;
    return !!logo;
  }

  private hasHubLogo(): boolean {
    const logo =
      this.themesService.activeTheme.name === 'dark'
        ? this.hubsStore.hub.logoDark
        : this.hubsStore.hub.logoLight;
    return !!logo;
  }

  private async checkEventSettings(): Promise<void> {
    const eventId = this.eventsStore.eventId;
    if (eventId) {
      const event: IEvent[] = await this.eventsService.getById(eventId);
      if (event.length && event[0].hideEventSearch) {
        this.hideSearch = true;
        this.searchLoading = false;
      } else {
        this.hideSearch = false;
        this.searchLoading = false;
      }

      if (!this.isAdminPanel && this.router.url.includes(eventId)) {
        const onDemandTickets: ITicket[] = await this.ticketsService.getOnDemandTickets(eventId);
        const userEvent: IUserEvent = this.usersStore.userEvents.filter(
          (userEventItem: IUserEvent) => userEventItem.eventId === eventId,
        )[0];
        let userTicket: ITicket;
        if (userEvent) {
          userTicket = await this.ticketsService.getOneTicket(eventId, userEvent.ticketId);
        }

        const isRegistered = this.usersStore.userEvents
          .map((userEventItem: IUserEvent) => userEventItem.eventId)
          .includes(eventId);
        this.showUpgradeButton =
          !userTicket?.onDemand && onDemandTickets.length && this.freshUser && isRegistered;
      }
    } else {
      this.showUpgradeButton = false;
    }
    this.searchLoading = false;
  }

  private showToastMessage(severity: 'success' | 'error', detail: string): void {
    const fallbackTranslationKey = 'toasters.error';
    let message;
    this.translateService.get(detail).subscribe((translation) => {
      message =
        translation === detail
          ? this.translateService.instant(fallbackTranslationKey)
          : translation;
    });
    this.messageService.add({
      severity,
      summary: this.translateService.instant(severity),
      detail: this.translateService.instant(message),
      styleClass: 'custom-toast',
    });
  }
}
