import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { CdkDragDrop, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { firstValueFrom } from 'rxjs';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';

import { ConfirmDialogComponent, SharedModule } from 'src/app/shared';
import {
  AssetsService,
  CourseAssetsService,
  CourseChaptersService,
  LibraryService,
} from 'src/app/core/services';
import { CoursesStore, HubsStore } from 'src/app/core/stores';
import {
  AssetStatus,
  ChapterStatus,
  IAsset,
  IChapter,
  ICourse,
  ICourseAsset,
} from 'src/app/core/models';
import { trackByFn } from 'src/app/core/utils';
import { NewAssetComponent } from 'src/app/standalone/shared/components/asset';
import { ButtonComponent, ButtonSize, ToastComponent } from 'src/app/standalone/shared';
import { ChapterCardComponent } from '../chapter-card/chapter-card.component';
import { ChapterDetailsComponent } from '../chapter-details/chapter-details.component';
import { AttachAssetToChapterComponent, NewChapterComponent } from '../../../dialogs';

@Component({
  selector: 'app-chapters',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    ChapterCardComponent,
    CdkDropList,
    ChapterDetailsComponent,
    ButtonComponent,
    ToastComponent,
  ],
  templateUrl: './chapters.component.html',
  styleUrls: ['./chapters.component.scss'],
})
export class ChaptersComponent implements OnInit {
  loading = false;
  chapters: IChapter[] = [];
  trackByFn = trackByFn;
  sidebarVisible = false;
  selectedChapterForEditing: IChapter;
  buttonSize = ButtonSize;

  constructor(
    private dialogService: DialogService,
    private messageService: MessageService,
    private chapterService: CourseChaptersService,
    private coursesStore: CoursesStore,
    private translateService: TranslateService,
    private assetsService: AssetsService,
    private router: Router,
    private hubsStore: HubsStore,
    private route: ActivatedRoute,
    private courseAssetsService: CourseAssetsService,
    private libraryService: LibraryService,
  ) {}

  get course(): ICourse {
    return this.coursesStore.adminCourse;
  }

  ngOnInit(): void {
    this.fetchChapters();
  }

  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.chapters, event.previousIndex, event.currentIndex);
    this.onChapterReorder(this.chapters);
  }

  async onChapterReorder(orderedChapters: IChapter[]): Promise<void> {
    await this.chapterService.reorderChapters(
      this.course.id,
      orderedChapters.map((chapter: IChapter) => chapter.id),
    );
    this.chapters = [...orderedChapters];
  }

  async onChapterAssetsReorder(assetIds: string[], chapter: IChapter): Promise<void> {
    await this.chapterService.updateChapter(this.course.id, { ...chapter, assets: assetIds });
  }

  async openNewChapterDialog(): Promise<void> {
    const newChapterDialogRef: DynamicDialogRef = this.dialogService.open(NewChapterComponent, {
      width: '90%',
      height: '90%',
      closable: false,
      styleClass: 'new-chapter-dialog',
    });

    await firstValueFrom(newChapterDialogRef.onClose);
    this.fetchChapters();
  }

  async onChangeChapterStatus(newChapterStatus: ChapterStatus, chapter: IChapter): Promise<void> {
    this.onUpdateChapter({ ...chapter, status: ChapterStatus[newChapterStatus] });
  }

  async onChangeAssetStatus({ newAssetStatus, asset }, chapter: IChapter): Promise<void> {
    try {
      const updatedAsset: IAsset = await this.libraryService.update(this.hubsStore.hubId, {
        ...asset,
        status: AssetStatus[newAssetStatus],
      });
      const chapterForUpdate: IChapter = this.chapters.find(
        (chapterItem: IChapter) => chapterItem.id === chapter.id,
      );
      const updatedAssetsFullModel: IAsset[] = chapterForUpdate.assetsFullModel.map(
        (assetItem: IAsset) => {
          if (assetItem.id === updatedAsset.id) {
            return updatedAsset;
          }

          return assetItem;
        },
      );
      this.chapters = this.chapters.map((chapterItem: IChapter) => {
        if (chapterItem.id === chapterForUpdate.id) {
          return { ...chapterForUpdate, assetsFullModel: updatedAssetsFullModel };
        }

        return chapterItem;
      });

      this.messageService.add({
        severity: 'success',
        summary: this.translateService.instant('success'),
        detail: this.translateService.instant('adminLibraryAsset.assetSuccessfullyUpdated'),
        styleClass: 'custom-toast',
      });
    } catch (error) {
      console.error(error);
      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('error'),
        detail: this.translateService.instant('adminLibraryAsset.assetErrorUpdated'),
        styleClass: 'custom-toast',
      });
    }
  }

  async onUpdateChapter(chapter: IChapter): Promise<void> {
    try {
      this.loading = true;
      await this.chapterService.updateChapter(this.course.id, chapter);
      await this.fetchChapters();
      this.showToastMessage(
        'success',
        this.translateService.instant('chapters.chapterSuccessfullyUpdated'),
      );
    } catch (error) {
      this.showToastMessage('error', this.translateService.instant('chapters.chapterUpdatedError'));
    } finally {
      this.loading = false;
    }
  }

  onCloseSidebar(): void {
    this.selectedChapterForEditing = null;
    this.sidebarVisible = false;
  }

  onEditChapter(chapter: IChapter) {
    this.selectedChapterForEditing = { ...chapter };
    this.sidebarVisible = true;
  }

  async onRemoveChapter(chapter: IChapter): Promise<void> {
    const deleteDialogRef: DynamicDialogRef = this.dialogService.open(ConfirmDialogComponent, {
      closable: false,
      styleClass: 'confirm-dialog',
      data: {
        titleKey: 'chapters.removeChapterTitle',
        descriptionKey: 'chapters.removeChapterText',
        confirmBtnKey: 'chapters.confirmBtn',
        cancelBtnKey: 'chapters.cancelBtn',
      },
    });

    const result: 'cancel' | 'confirm' = await firstValueFrom(deleteDialogRef.onClose);

    if (result !== 'confirm') {
      return;
    }

    try {
      this.loading = true;
      await this.chapterService.delete(this.course.id, chapter.id);
      await this.fetchChapters();
      this.loading = false;
      this.showToastMessage(
        'success',
        this.translateService.instant('chapters.chapterSuccessfullyDeleted'),
      );
    } catch (error) {
      this.loading = false;
      this.showToastMessage('error', this.translateService.instant('application.toasters.error'));
    }
  }

  async onCreateNewAsset(chapter: IChapter): Promise<void> {
    const newAssetDialogRef: DynamicDialogRef = this.dialogService.open(NewAssetComponent, {
      styleClass: 'new-library-asset-dialog',
      closable: false,
      data: {
        course: this.course,
      },
    });

    const newAssetIds: string[] = await firstValueFrom(newAssetDialogRef.onClose);
    if (newAssetIds?.length) {
      this.onUpdateChapter({ ...chapter, assets: [...chapter.assets, ...newAssetIds] });
    }
  }

  async onAttachAsset(chapter: IChapter): Promise<void> {
    const courseAssets: ICourseAsset[] =
      await this.courseAssetsService.getAllCourseAssetsByCourseId(this.course.id);
    const listOfAssetsForAttaching: ICourseAsset[] = courseAssets.filter(
      (courseAsset: ICourseAsset) => !chapter.assets.includes(courseAsset.assetId),
    );
    const assets: IAsset[] = await this.assetsService.getAssetsByIds(
      listOfAssetsForAttaching.map((i) => i.assetId),
    );
    const attachAssetDialogRef: DynamicDialogRef = this.dialogService.open(
      AttachAssetToChapterComponent,
      {
        width: '90%',
        height: '80%',
        closable: false,
        styleClass: 'attach-assets-dialog',
        data: { assets },
      },
    );

    const selectedAssets: IAsset[] = await firstValueFrom(attachAssetDialogRef.onClose);
    if (selectedAssets) {
      this.onUpdateChapter({
        ...chapter,
        assets: chapter.assets.concat(
          selectedAssets.map((selectedAsset: IAsset) => selectedAsset.id),
        ),
      });
    }
  }

  onEditAsset(asset: IAsset): void {
    this.router.navigate(
      [
        `/${this.hubsStore.useHubUrl}/admin/courses/${this.coursesStore.adminCourse.link}/setup/media/${asset.id}`,
      ],
      {
        relativeTo: this.route,
        state: {
          assetId: asset.id,
        },
      },
    );
  }

  async onRemoveAsset(asset: IAsset, chapter: IChapter): Promise<void> {
    try {
      const deleteDialogRef: DynamicDialogRef = this.dialogService.open(ConfirmDialogComponent, {
        closable: false,
        styleClass: 'confirm-dialog',
        data: {
          titleKey: 'adminCourseMedia.deleteAsset',
          descriptionKey: 'adminCourseMedia.deleteAssetText',
          confirmBtnKey: 'adminCourseMedia.confirmBtn',
          cancelBtnKey: 'adminCourseMedia.cancelBtn',
          entry: asset,
        },
      });

      const result: 'cancel' | 'confirm' = await firstValueFrom(deleteDialogRef.onClose);
      if (result === 'confirm') {
        this.onUpdateChapter({
          ...chapter,
          assets: chapter.assets.filter((chapterAsset: string) => chapterAsset !== asset.id),
        });
      }
    } catch (error) {
      console.error(error);
      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('error'),
        detail: this.translateService.instant('adminCourseMedia.errorDeleteAsset'),
        styleClass: 'custom-toast',
      });
    }
  }

  private async fetchChapters(): Promise<void> {
    this.loading = true;
    const chapters: IChapter[] = await this.chapterService.getAllByCourseId(this.course.id);
    this.chapters = await Promise.all(
      chapters.map(async (chapter: IChapter) => {
        const assetsFullModel: IAsset[] = await this.getChapterAssetsFullModel(chapter);

        return { ...chapter, assetsFullModel };
      }),
    );
    this.loading = false;
  }

  private async getChapterAssetsFullModel(chapter: IChapter): Promise<IAsset[]> {
    const assetsFullModel: IAsset[] = await Promise.all(
      chapter.assets.map((assetId: string) => this.assetsService.getOne(assetId)),
    );

    return assetsFullModel;
  }

  private showToastMessage(severity: 'success' | 'error', detail: string): void {
    this.messageService.add({
      severity,
      summary: this.translateService.instant(severity),
      detail: this.translateService.instant(detail),
      styleClass: 'custom-toast',
    });
  }
}
