import { Injectable } from '@angular/core';
import { Firestore, IFirestore, Timestamp } from 'src/app/firebase';
import { ISubscription } from '../../models';
import { CollectionTypes } from 'src/app/shared';
import { UsersStore } from '../../stores';
import { API_ROUTES as apiRoutes } from 'src/app/shared';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionsService {
  private firestore: IFirestore;

  constructor(private usersStore: UsersStore) {
    this.firestore = Firestore();
  }

  public async getAll(): Promise<ISubscription[]> {
    try {
      const result: ISubscription[] = (
        await this.firestore.collection(CollectionTypes.SUBSCRIPTIONS).get()
      ).docs.map((doc) => doc.data() as ISubscription);

      return result;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  public async getById(subscriptionId: string): Promise<ISubscription> {
    try {
      const subscriptionQuery = await this.firestore
        .collection(CollectionTypes.SUBSCRIPTIONS)
        .doc(subscriptionId)
        .get();
      if (subscriptionQuery.exists) {
        return subscriptionQuery.data() as ISubscription;
      } else {
        return null;
      }
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  public async getByIds(subscriptionsIds: string[]): Promise<ISubscription[]> {
    try {
      let subscriptions: ISubscription[] = [];
      while (subscriptionsIds.length) {
        const chunk = subscriptionsIds.splice(0, 10);

        (
          await this.firestore
            .collection(CollectionTypes.SUBSCRIPTIONS)
            .where('id', 'in', chunk)
            .get()
        ).forEach((doc) => {
          subscriptions.push(doc.data() as ISubscription);
        });
      }

      return subscriptions;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  public async getSubscriptionIdsByNames(subscriptionNames: string[]): Promise<string[]> {
    try {
      let subscriptionIds: string[] = [];
      while (subscriptionNames.length) {
        const chunk = subscriptionNames.splice(0, 10);

        (
          await this.firestore
            .collection(CollectionTypes.SUBSCRIPTIONS)
            .where('aboobject', 'in', chunk)
            .get()
        ).forEach((doc) => {
          subscriptionIds.push(doc.data().id);
        });
      }

      return subscriptionIds;
    } catch (err) {
      console.warn(err);
      throw new Error(err);
    }
  }

  public async getByHubId(hubId: string): Promise<ISubscription[]> {
    try {
      let subscriptions: ISubscription[] = [];
      subscriptions = (
        await this.firestore
          .collection(apiRoutes.subscriptions)
          .where('hubId', '==', hubId)
          .orderBy('orderIndex')
          .get()
      ).docs.map((doc) => doc.data() as ISubscription);

      return subscriptions;
    } catch (error) {
      console.warn(error);
      throw error(error);
    }
  }

  public async createSubscription(subscription: ISubscription): Promise<void> {
    try {
      const highestOrderSubscription = await this.firestore
        .collection(apiRoutes.subscriptions)
        .where('hubId', '==', subscription.hubId)
        .orderBy('orderIndex', 'desc')
        .limit(1)
        .get();
      let nextOrderIndex = 0;
      if (!highestOrderSubscription.empty) {
        const highestData = highestOrderSubscription.docs[0].data();
        nextOrderIndex = highestData.orderIndex + 1;
      }
      subscription.orderIndex = nextOrderIndex;
      const preSubscriptionDoc = await this.firestore
        .collection(CollectionTypes.SUBSCRIPTIONS)
        .doc();
      const newSubscription: ISubscription = {
        ...subscription,
        id: preSubscriptionDoc.id,
        createdAt: Timestamp.now(),
        createdBy: this.usersStore.user.id,
      };
      if (subscription.isDefault) {
        (
          await this.firestore
            .collection(CollectionTypes.SUBSCRIPTIONS)
            .where('isDefault', '==', true)
            .where('hubId', '==', subscription.hubId)
            .get()
        ).forEach((s) => {
          s.ref.set({ isDefault: false }, { merge: true });
        });
      }

      await this.firestore
        .collection(CollectionTypes.SUBSCRIPTIONS)
        .doc(newSubscription.id)
        .set({ ...newSubscription });
    } catch (err) {
      console.warn(err);
      throw new Error(err);
    }
  }

  async updateOrderIndex(subscriptionId: string, newOrderIndex: number): Promise<void> {
    await this.firestore
      .collection(apiRoutes.subscriptions)
      .doc(subscriptionId)
      .update({ orderIndex: newOrderIndex });
  }

  public async updateSubscription(subscriptionId: string, subscription): Promise<void> {
    try {
      const oldSubscriptionDoc = this.firestore
        .collection(CollectionTypes.SUBSCRIPTIONS)
        .doc(subscriptionId);
      const newSubscription = {
        ...subscription,
        updatedAd: Timestamp.now(),
        updatedBy: this.usersStore.user.id,
      };
      if (subscription.isDefault) {
        (
          await this.firestore
            .collection(CollectionTypes.SUBSCRIPTIONS)
            .where('isDefault', '==', true)
            .where('hubId', '==', subscription.hubId)
            .get()
        ).forEach((s) => {
          s.ref.set({ isDefault: false }, { merge: true });
        });
      }

      await oldSubscriptionDoc.update({ ...newSubscription });
    } catch (err) {
      console.warn(err);
      throw new Error(err);
    }
  }

  public async deleteSubscriptionById(subscriptionId: string): Promise<void> {
    try {
      await this.firestore.collection(CollectionTypes.SUBSCRIPTIONS).doc(subscriptionId).delete();
    } catch (err) {
      console.warn(err);
      throw new Error(err);
    }
  }

  public async getStripeSubscriotionByPriceId(priceId: string): Promise<ISubscription> {
    try {
      let subscription;
      (
        await this.firestore
          .collection(CollectionTypes.SUBSCRIPTIONS)
          .where('stripePriceId', '==', priceId)
          .get()
      ).forEach((s) => {
        subscription = s;
      });

      return subscription;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  public async getPlenigoSubscriptionById(planId: string): Promise<ISubscription> {
    try {
      let subscription;
      (
        await this.firestore.collection(apiRoutes.subscriptions).where('planId', '==', planId).get()
      ).forEach((s) => {
        subscription = s;
      });

      return subscription;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }
}
