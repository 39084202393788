import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Subject, takeUntil, tap } from 'rxjs';

import { ServiceWorkerService } from 'src/app/core/services';

@Component({
  selector: 'app-new-app-version',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './new-app-version.component.html',
  styleUrls: ['./new-app-version.component.scss'],
})
export class NewAppVersionComponent implements OnInit, OnDestroy {
  @HostBinding('style.display') display = 'none';

  unsubscribe$ = new Subject<void>();

  constructor(private serviceWorkerService: ServiceWorkerService) {}

  ngOnInit(): void {
    this.serviceWorkerService.isNewVersionAvailable$
      .pipe(
        tap((value: boolean) => {
          this.display = value ? 'block' : 'none';
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();
  }

  onReloadApp(): void {
    this.serviceWorkerService.reloadApp();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
