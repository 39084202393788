import { Injectable } from '@angular/core';

import { Firestore, IDocumentData, IFirestore, IQuerySnapshot } from 'src/app/firebase';
import { IAsset } from 'src/app/core/models';
import { AssetType } from 'src/app/core/enums';
import { API_ROUTES as apiRoutes } from 'src/app/shared';

@Injectable({
  providedIn: 'root',
})
export class AssetsService {
  private firestore: IFirestore;

  constructor() {
    this.firestore = Firestore();
  }

  async getOne(id: string): Promise<IAsset> {
    try {
      const asset = (
        await this.firestore.collection(apiRoutes.library).doc(id).get()
      ).data() as IAsset;

      return asset;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getAssetsByIds(assetsIds: string[]): Promise<IAsset[]> {
    try {
      const assets: IAsset[] = [];
      const ids: string[] = [...assetsIds];
      while (ids.length) {
        const chunk = ids.splice(0, 10);
        const assetsQuery: IQuerySnapshot<IDocumentData> = await this.firestore
          .collection(apiRoutes.library)
          .where('id', 'in', chunk)
          .get();
        assets.push(...assetsQuery.docs.map((doc) => doc.data() as IAsset));
      }

      return assets;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getVideoAssetsByIds(assetsIds: string[]): Promise<IAsset[]> {
    try {
      const assets: IAsset[] = [];
      const ids: string[] = [...assetsIds];
      while (ids.length) {
        const chunk = ids.splice(0, 10);
        const assetsQuery: IQuerySnapshot<IDocumentData> = await this.firestore
          .collection(apiRoutes.library)
          .where('id', 'in', chunk)
          .where('type', '==', AssetType.VIDEO)
          .get();
        assets.push(...assetsQuery.docs.map((doc) => doc.data() as IAsset));
      }

      return assets;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}
