import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { autorun, IReactionDisposer } from 'mobx';

import { IEventMenuItem } from '../../models';
import { EventsStore, HubsStore } from '../../stores';
import { eventIsPassed, isMobile } from '../../utils/utils';
import { AppStore } from '../../../app.store';
import { EventMenuService, ThemesService } from '../../services';

type SidebarEventMenuItem = IEventMenuItem & { expanded?: boolean };
@Component({
  selector: 'app-sidebar-navigation',
  templateUrl: './sidebar-navigation.component.html',
  styleUrls: ['./sidebar-navigation.component.scss'],
})
export class SidebarNavigationComponent implements OnInit, OnDestroy {
  public logo: string;
  public eventIsPassed = eventIsPassed;
  public menuItems: SidebarEventMenuItem[] = [];

  private readonly autorunDisposer: IReactionDisposer;
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    public appStore: AppStore,
    public hubsStore: HubsStore,
    public eventsStore: EventsStore,
    private themesService: ThemesService,
    private eventMenuService: EventMenuService,
  ) {
    this.autorunDisposer = autorun(() => {
      if (this.eventsStore.event && this.hasEventLogo()) {
        this.logo =
          this.themesService.activeTheme.name === 'dark'
            ? this.eventsStore.event.logoDark
            : this.eventsStore.event.logoLight;
      } else if (this.hubsStore.hub && this.hasHubLogo()) {
        this.logo =
          this.themesService.activeTheme.name === 'dark'
            ? this.hubsStore.hub.logoDark
            : this.hubsStore.hub.logoLight;
      } else {
        this.logo =
          this.themesService.activeTheme.name === 'dark'
            ? this.themesService.systemAppearanceSettings?.logo
            : this.themesService.systemAppearanceSettings?.logoLight;
      }
    });
  }

  ngOnInit(): void {
    this.eventMenuService
      .eventMenuItems()
      .pipe(
        map((items: SidebarEventMenuItem[]) => {
          // clone the items to the objects can be changed without interference on other subscribers
          const clonedItems: SidebarEventMenuItem[] = [...items];
          clonedItems.forEach((item: SidebarEventMenuItem) => {
            if (item.type === 'group') {
              item.expanded = true;
              const oldClickActionFn = item.clickAction;

              item.clickAction = () => {
                item.expanded = !item.expanded;
                if (oldClickActionFn) {
                  oldClickActionFn();
                }
              };
            }
          });
          return clonedItems;
        }),
        tap((value: SidebarEventMenuItem[]) => {
          this.menuItems = value;
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();
  }

  private hasEventLogo(): boolean {
    return this.themesService.activeTheme.name === 'dark'
      ? !!this.eventsStore.event.logoDark
      : !!this.eventsStore.event.logoLight;
  }

  private hasHubLogo(): boolean {
    return this.themesService.activeTheme.name === 'dark'
      ? !!this.hubsStore.hub.logoDark
      : !!this.hubsStore.hub.logoLight;
  }

  menuItemClick(menuItem: IEventMenuItem): void {
    if (menuItem.clickAction) {
      menuItem.clickAction();
    }
    if (isMobile()) {
      this.appStore.setSidebarVisible(false);
    }
  }

  public onExitEventClick(): void {
    this.appStore.setSidebarVisible(!this.appStore.isSideBarVisible);
    this.router.navigate([`${this.hubsStore.useHubUrl}/events`]);
  }

  public onCloseShowDropDown(): void {
    this.menuItems = [
      ...this.menuItems.map((menuItem: SidebarEventMenuItem) => {
        if (menuItem.type === 'group') {
          menuItem.expanded = !menuItem.expanded;
        }
        return menuItem;
      }),
    ];
  }

  public ngOnDestroy(): void {
    this.autorunDisposer();
    this.appStore.setSidebarVisible(false);
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
