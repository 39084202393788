export enum ButtonStyle {
  FILLED_PRIMARY = 'filled-primary',
  TERTIARY_PRIMARY = 'tertiary-primary',
  GHOST_PRIMARY = 'ghost-primary',
  FILLED_SECONDARY = 'filled-secondary',
  TERTIARY_SECONDARY = 'tertiary-secondary',
  GHOST_SECONDARY = 'ghost-secondary',
  FILLED_SUCCESS = 'filled-success',
  TERTIARY_SUCCESS = 'tertiary-success',
  FILLED_DANGER = 'filled-danger',
  TERTIARY_DANGER = 'tertiary-danger',
}
