import { Component } from '@angular/core';

import { SharedModule } from 'src/app/shared';
import { AdminCourseTicketFormComponent } from 'src/app/standalone/admin/components';

@Component({
  selector: 'app-course-ticket',
  standalone: true,
  imports: [SharedModule, AdminCourseTicketFormComponent],
  templateUrl: './course-ticket.component.html',
  styleUrl: './course-ticket.component.scss',
})
export class CourseTicketComponent {}
