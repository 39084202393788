<div
  class="empty-state-container"
  [class]="classes"
  [ngClass]="{ 'empty-state-container-background': !noBackground }">
  <div class="empty-state-inner">
    <div class="empty-state-icon">
      <i class="{{ icon }}"></i>
    </div>
    <ng-container *ngIf="title">
      <h1>{{ title }}</h1>
      <p>{{ text }}</p>
    </ng-container>
    <ng-container *ngIf="!title">
      <h1>{{ text }}</h1>
    </ng-container>
  </div>
</div>
