import { Injectable } from '@angular/core';

import { Firestore, IFirestore } from 'src/app/firebase';
import { API_ROUTES as apiRoutes } from 'src/app/shared';

@Injectable({
  providedIn: 'root',
})
export class UserCourseInvitesService {
  private firestore: IFirestore;

  constructor() {
    this.firestore = Firestore();
  }

  async removeUserCourseInvite(inviteId: string): Promise<boolean> {
    try {
      await this.firestore.collection(apiRoutes.userCourseInvites).doc(inviteId).delete();

      return true;
    } catch (err) {
      console.warn(err);
      throw err;
    }
  }
}
