import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';

import { NotificationTypes } from 'src/app/core/enums';
import { INotification } from 'src/app/core/models';
import {
  AppointmentService,
  MixpanelService,
  NotificationsService,
  OpenDialogService,
  UsersService,
} from 'src/app/core/services';
import { UsersStore } from 'src/app/core/stores';
import { goToLink, trackByFn } from 'src/app/core/utils';
import { DailyMeetingComponent } from '../../daily-meeting/daily-meeting.component';
import { environment } from 'src/environments/environment';

const MEETING_SUBLINK = environment.dailyMeetingSublink;
@Component({
  selector: 'app-notifications',
  templateUrl: './notifications-container.component.html',
  styleUrls: ['./notifications-container.component.scss'],
})
export class NotificationsComponent implements OnInit {
  isLoading = true;
  notifications: INotification[];
  notificationsTypes = NotificationTypes;
  myMeetingsNotifications: INotification[];
  trackByFn = trackByFn;

  constructor(
    private notificationsService: NotificationsService,
    private usersStore: UsersStore,
    private appointmentService: AppointmentService,
    private openDialogService: OpenDialogService,
    private usersService: UsersService,
    private mixpanelService: MixpanelService,
    private dialogService: DialogService,
    private router: Router,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.getAllNotifications();
  }

  private async getAllNotifications(): Promise<void> {
    this.isLoading = true;
    const notifications: INotification[] =
      await this.notificationsService.getLatestWithoutMeetingNotifications(this.usersStore.userId);
    const meetingNotifications: INotification[] =
      await this.notificationsService.getLatestMeetingNotifications(this.usersStore.userId);
    const supplementedNotifications: INotification[] =
      await this.getNotificationsWithUserAndAppointment(notifications);
    const supplementedMeetingsNotifications: INotification[] =
      await this.getNotificationsWithUserAndAppointment(meetingNotifications);
    this.notifications = [...supplementedNotifications];
    this.myMeetingsNotifications = [...supplementedMeetingsNotifications];

    this.isLoading = false;
  }

  private async getNotificationsWithUserAndAppointment(
    notifications: INotification[],
  ): Promise<INotification[]> {
    const supplementedNotifications: INotification[] = await Promise.all(
      notifications.map(async (notification) => {
        const [host, user, appointment, appointmentUser] = await Promise.all([
          this.usersService.getOne(notification.data.hostId),
          this.usersService.getOne(notification.data.userId),
          this.appointmentService.getOne(notification.data.appointmentId),
          this.appointmentService.getAppointmentUser(notification.data.appointmentsUsersId),
        ]);

        return { ...notification, user, host, appointment, appointmentUser };
      }),
    );

    return supplementedNotifications;
  }

  async onJoiningMeeting(notification: INotification): Promise<void> {
    this.usersStore.hideNotification();
    const { appointment } = notification;
    if (appointment.location.includes(MEETING_SUBLINK)) {
      this.joinOnlineMeeting(notification);
    } else {
      this.joinExternalMeeting(appointment.location);
    }
  }

  private async joinOnlineMeeting(notification: INotification): Promise<void> {
    const { appointmentUser, appointment } = notification;
    let currentUser = appointmentUser;
    if (this.usersStore.userId === notification.data.hostId) {
      currentUser = await this.appointmentService.getAppointmentHostUser(
        notification.data.appointmentId,
        notification.data.hostId,
      );
    }

    this.dialogService.open(DailyMeetingComponent, {
      width: '100vw',
      height: '100vh',
      style: {
        maxHeight: '100vh',
      },
      contentStyle: {
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        padding: 0,
        borderRadius: 0,
        border: 'none',
      },
      closable: false,
      showHeader: false,
      modal: true,
      data: {
        notification: {
          url: appointment.location,
          token: currentUser.token,
        },
      },
    });
  }

  private joinExternalMeeting(url: string): void {
    goToLink(url);
  }

  async onAccept(notification: INotification): Promise<void> {
    notification.isLoading = true;
    await this.appointmentService.acceptAppointment(
      notification.id,
      notification.data.appointmentId,
      this.usersStore.userId,
    );
    notification.isLoading = false;
    this.isLoading = true;
    await this.getAllNotifications();
    this.mixpanelService.acceptMeeting(notification.appointment);
  }

  onDecline(notification: INotification): void {
    notification.isLoading = true;
    const dialogRef: DynamicDialogRef = this.openDialogService.openDeclineMeetingDialog();
    dialogRef.onClose.subscribe(async (reason: string) => {
      if (reason) {
        const payload = {
          notificationId: notification.id,
          userId: this.usersStore.userId,
          reason,
        };
        await this.appointmentService.declineAppointment(notification.data.appointmentId, payload);

        notification.isLoading = false;
        this.isLoading = true;
        this.getAllNotifications();
        this.mixpanelService.declineMeeting(notification.appointment, reason);
      } else {
        console.warn('No reason provided .... ');
      }
    });
  }

  onGoToViewNotifications(activeTab: 'notifications' | 'meetings'): void {
    this.router.navigate(['/user/notifications'], { state: { activeTab } });
    this.usersStore.hideNotification();
  }
}
