import { Component } from '@angular/core';

@Component({
  selector: 'app-attendee-check-in',
  standalone: true,
  imports: [],
  templateUrl: './attendee-check-in.component.html',
  styleUrl: './attendee-check-in.component.scss',
})
export class AttendeeCheckInComponent {}
