import { Injectable } from '@angular/core';
import QRCode from 'qrcode';

@Injectable({
  providedIn: 'root',
})
export class QrCodeService {
  constructor() {}

  public async generateQrCode(userId: string, eventId: string): Promise<string> {
    try {
      const qrCodeText = `{ user_id: ${userId}; event_id: ${eventId} }`;
      const qrCode = await QRCode.toDataURL(qrCodeText);
      return qrCode;
    } catch (error) {
      console.error(error);
    }
  }
}
