<div class="confirm-dialog-container">
  <div class="icon">
    <i class="far fa-check"></i>
  </div>
  <h1>{{ titleKey | translate }}</h1>
  <p [innerHtml]="descriptionKey"></p>
  <div class="actions-container">
    <button
      type="button"
      class="cancel-btn"
      pButton
      label="{{ cancelBtnKey | translate }}"
      (click)="ref.close('cancel')"></button>
    <button
      type="button"
      class="confirm-btn"
      pButton
      label="{{ confirmBtnKey | translate }}"
      (click)="ref.close('confirm')"></button>
  </div>
</div>
