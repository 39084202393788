@if (loading()) {
  <app-loader></app-loader>
} @else {
  <div>
    @if (featuredEvents().length) {
      <section class="event-featured-banner-container">
        <div
          class="banner-container-shadow"
          [style.background]="
            'url(' +
            featuredEvents()[currentFeaturedEventsIndex()]?.featuredImage +
            '), lightgray 50% / cover no-repeat'
          "></div>
        <p-carousel
          [value]="featuredEvents()"
          [autoplayInterval]="7000"
          styleClass="events-carousel"
          [indicatorsContentClass]="featuredEvents().length > 1 ? null : 'carousel-dots-hide'"
          (onPage)="setCurrentFeaturedEventsIndex($event)">
          <ng-template
            let-event
            pTemplate="item">
            <ng-container>
              <div class="desktop-view">
                <div class="event-banner-container">
                  @if (event?.featuredImage) {
                    <img
                      class="event-banner"
                      [src]="event.featuredImage"
                      alt="Event featured banner" />
                  }
                  <div class="banner-text-container">
                    <ng-container
                      *ngTemplateOutlet="
                        bannerContent;
                        context: { $implicit: event }
                      "></ng-container>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container>
              <div class="mobile-view">
                <div class="carousel-item-container">
                  <div class="event-banner-container">
                    @if (event?.featuredImage) {
                      <img
                        class="event-banner"
                        [src]="event.featuredImage"
                        alt="Event featured banner" />
                    }
                  </div>
                  <div class="event-content">
                    <ng-container
                      *ngTemplateOutlet="
                        bannerContent;
                        context: { $implicit: event }
                      "></ng-container>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-template>
        </p-carousel>
      </section>
    }

    <!-- Nav section for desktop view -->
    <div class="desktop-view">
      <section class="event-navigation-container">
        <div class="title-sort-wrapper display-wrapper">
          <h2 class="page-title">{{ 'events.events' | translate }}</h2>
          <i
            class="far fa-arrow-down-arrow-up"
            (click)="toggleSort()"></i>
        </div>
        <nav class="nav-container display-wrapper">
          <ul class="list display-wrapper">
            <li class="list-item">
              <a
                class="link"
                routerLink="/{{ hubUrl }}/events/filter/live&upcoming"
                routerLinkActive="active">
                {{ 'userDashboard.events.menu.live' | translate }}
              </a>
            </li>
            <li class="list-item">
              <a
                class="link"
                routerLink="/{{ hubUrl }}/events/filter/onDemand"
                routerLinkActive="active">
                {{ 'userDashboard.events.menu.onDemand' | translate }}
              </a>
            </li>
            <li class="list-item">
              <a
                class="link"
                routerLink="/{{ hubUrl }}/events/filter/past"
                routerLinkActive="active">
                {{ 'userDashboard.events.menu.past' | translate }}
              </a>
            </li>
            <li class="list-item ml-auto">
              <a
                class="link display-wrapper link-with-icon"
                routerLink="/{{ hubUrl }}/events/filter/mylist"
                routerLinkActive="active">
                <i class="fa-regular fa-user-check"></i>
                <span>{{ 'userDashboard.events.menu.my' | translate }}</span>
              </a>
            </li>
            <li class="list-item">
              <a
                class="link display-wrapper link-with-icon"
                routerLink="/{{ hubUrl }}/events/filter/bookmarked"
                routerLinkActive="active">
                <i class="fa-regular fa-bookmark"></i>
                <span>{{ 'userDashboard.events.menu.bookmarked' | translate }}</span>
              </a>
            </li>
          </ul>
        </nav>
      </section>
    </div>

    <!-- Nav section for mobile view -->
    <div class="mobile-view">
      <section class="event-navigation-container">
        <div class="nav-first-row">
          <div class="title-sort-wrapper display-wrapper">
            <h2 class="page-title">{{ 'events.events' | translate }}</h2>
            <i
              class="far fa-arrow-down-arrow-up"
              (click)="toggleSort()"></i>
          </div>
          <nav class="nav-container display-wrapper">
            <ul class="list display-wrapper">
              <li class="list-item ml-auto">
                <a
                  class="link display-wrapper link-with-icon link-mobile-view"
                  routerLink="/{{ hubUrl }}/events/filter/mylist"
                  routerLinkActive="active">
                  <i class="fa-regular fa-user-check"></i>
                  <span>{{ 'userDashboard.events.menu.my' | translate }}</span>
                </a>
              </li>
              <li class="list-item">
                <a
                  class="link display-wrapper link-with-icon link-mobile-view"
                  routerLink="/{{ hubUrl }}/events/filter/bookmarked"
                  routerLinkActive="active">
                  <i class="fa-regular fa-bookmark"></i>
                  <span>{{ 'userDashboard.events.menu.bookmarked' | translate }}</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <nav class="nav-second-row">
          <ul class="list display-wrapper">
            <li class="list-item">
              <a
                class="link"
                routerLink="/{{ hubUrl }}/events/filter/live&upcoming"
                routerLinkActive="active">
                {{ 'userDashboard.events.menu.live' | translate }}
              </a>
            </li>
            <li class="list-item">
              <a
                class="link"
                routerLink="/{{ hubUrl }}/events/filter/onDemand"
                routerLinkActive="active">
                {{ 'userDashboard.events.menu.onDemand' | translate }}
              </a>
            </li>
            <li class="list-item">
              <a
                class="link"
                routerLink="/{{ hubUrl }}/events/filter/past"
                routerLinkActive="active">
                {{ 'userDashboard.events.menu.past' | translate }}
              </a>
            </li>
          </ul>
        </nav>
      </section>
    </div>

    <section class="main-section-container">
      <router-outlet></router-outlet>
    </section>
  </div>
}

<ng-template
  #bannerContent
  let-event>
  <h1 class="event-title">{{ event.title }}</h1>
  <div class="event-date-container">
    <i class="fa-regular fa-calendar"></i>
    <span>{{ eventDateForShowing(event) }}</span>
  </div>
  <h3 class="event-tagline">{{ event.tagline }}</h3>
  <button
    class="event-details"
    (click)="onEvent(event)">
    {{ 'shared.detailsText' | translate }}
  </button>
</ng-template>
