import algoliasearch from 'algoliasearch';
import { Injectable } from '@angular/core';
import { SearchClient } from 'algoliasearch/lite';

import { AppStore } from 'src/app/app.store';
import { AlgoliaSearchResult, ICourseBrand } from 'src/app/core/models';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthorizationService } from 'src/app/core/services';

@Injectable({
  providedIn: 'root',
})
export class AlgoliaService {
  constructor(
    private http: HttpClient,
    private httpClient: HttpClient,
    private authorizationService: AuthorizationService,
    public appStore: AppStore,
  ) {}

  public get algoliaSearch(): SearchClient {
    return algoliasearch(
      this.appStore.environment.algolia.appId,
      this.appStore.environment.algolia.searchApiKey,
    );
  }

  async search<T>(
    index: string,
    query: string,
    length: number,
    offset: number,
    filters: string = '',
    facetFilters: string = '',
  ): Promise<AlgoliaSearchResult<T>> {
    const indexX = this.algoliaSearch.initIndex(
      this.appStore.environment.algolia.indexPrefix + index,
    );
    const res = await indexX.search<T>(query, { filters, facetFilters, length, offset });
    console.log('algolia search res.hits', res.hits);

    return {
      total: res.nbHits,
      results: res.hits,
    };
  }

  public async reindex(): Promise<void> {
    try {
      const response = await this.http
        .get(`${environment.apiUrl}algolia/init`, { responseType: 'text' })
        .toPromise();
      console.log(response);
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }

  public async createHubBrand(payload: any): Promise<any> {
    try {
      const headers: HttpHeaders = await this.authorizationService.buildHeaders();
      const response = await this.httpClient
        .post<any>(environment.apiUrl + 'algolia/hub-brand', { payload }, { headers })
        .toPromise();
      return response;
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }

  public async createUserBrand(payload: any): Promise<any> {
    try {
      const headers: HttpHeaders = await this.authorizationService.buildHeaders();
      const response = await this.httpClient
        .post<any>(environment.apiUrl + 'algolia/user-brand', { payload }, { headers })
        .toPromise();
      return response;
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }

  public async removeHubBrand(id: string): Promise<any> {
    try {
      const headers: HttpHeaders = await this.authorizationService.buildHeaders();
      const response = await this.httpClient
        .delete<any>(environment.apiUrl + 'algolia/hub-brand/delete/' + id, { headers })
        .toPromise();
      return response;
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }

  public async createHub(payload: any): Promise<any> {
    try {
      const headers: HttpHeaders = await this.authorizationService.buildHeaders();
      const response = await this.httpClient
        .post<any>(environment.apiUrl + 'algolia/hub', { payload }, { headers })
        .toPromise();
      return response;
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }

  public async createEvent(payload: any): Promise<any> {
    try {
      const headers: HttpHeaders = await this.authorizationService.buildHeaders();
      const response = await this.httpClient
        .post<any>(environment.apiUrl + 'algolia/event', { payload }, { headers })
        .toPromise();
      return response;
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }

  public async updateEvent(payload: any): Promise<any> {
    try {
      const headers: HttpHeaders = await this.authorizationService.buildHeaders();
      const response = await this.httpClient
        .put<any>(environment.apiUrl + 'algolia/event', { payload }, { headers })
        .toPromise();
      return response;
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }

  public async removeEvent(id: string): Promise<any> {
    try {
      const headers: HttpHeaders = await this.authorizationService.buildHeaders();
      const response = await this.httpClient
        .delete<any>(environment.apiUrl + 'algolia/event/delete/' + id, { headers })
        .toPromise();
      return response;
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }

  public async createBrand(payload: any): Promise<any> {
    try {
      const headers: HttpHeaders = await this.authorizationService.buildHeaders();
      const response = await this.httpClient
        .post<any>(environment.apiUrl + 'algolia/brand', { payload }, { headers })
        .toPromise();
      return response;
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }

  public async updateBrand(payload: any): Promise<any> {
    try {
      const headers: HttpHeaders = await this.authorizationService.buildHeaders();
      const response = await this.httpClient
        .put<any>(environment.apiUrl + 'algolia/brand', { payload }, { headers })
        .toPromise();
      return response;
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }

  public async removeBrand(id: string): Promise<any> {
    try {
      const headers: HttpHeaders = await this.authorizationService.buildHeaders();
      const response = await this.httpClient
        .delete<any>(environment.apiUrl + 'algolia/brand/delete/' + id, { headers })
        .toPromise();
      return response;
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }

  public async createBrandCourse(payload: any): Promise<any> {
    try {
      const headers: HttpHeaders = await this.authorizationService.buildHeaders();
      const response = await this.httpClient
        .post<any>(environment.apiUrl + 'algolia/brand-course', { payload }, { headers })
        .toPromise();
      return response;
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }

  public async updateBrandCourse(payload: ICourseBrand): Promise<ICourseBrand> {
    try {
      const headers: HttpHeaders = await this.authorizationService.buildHeaders();
      const response = await this.httpClient
        .put<any>(environment.apiUrl + 'algolia/brand-course', { payload }, { headers })
        .toPromise();
      return response;
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }

  public async removeBrandCourse(id: string): Promise<any> {
    try {
      const headers: HttpHeaders = await this.authorizationService.buildHeaders();
      const response = await this.httpClient
        .delete<any>(environment.apiUrl + 'algolia/brand-course/delete/' + id, { headers })
        .toPromise();
      return response;
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }

  public async createEventBrand(payload: any): Promise<any> {
    try {
      const headers: HttpHeaders = await this.authorizationService.buildHeaders();
      const response = await this.httpClient
        .post<any>(environment.apiUrl + 'algolia/event-brand', { payload }, { headers })
        .toPromise();
      return response;
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }

  public async updateEventBrand(payload: any): Promise<any> {
    try {
      const headers: HttpHeaders = await this.authorizationService.buildHeaders();
      const response = await this.httpClient
        .put<any>(environment.apiUrl + 'algolia/event-brand', { payload }, { headers })
        .toPromise();
      return response;
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }

  public async removeEventBrand(id: string): Promise<any> {
    try {
      const headers: HttpHeaders = await this.authorizationService.buildHeaders();
      const response = await this.httpClient
        .delete<any>(environment.apiUrl + 'algolia/event-brand/delete/' + id, { headers })
        .toPromise();
      return response;
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }

  public async createCourse(payload: any): Promise<any> {
    try {
      const headers: HttpHeaders = await this.authorizationService.buildHeaders();
      const response = await this.httpClient
        .post<any>(environment.apiUrl + 'algolia/course', { payload }, { headers })
        .toPromise();
      return response;
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }

  public async updateCourse(payload: any): Promise<any> {
    try {
      const headers: HttpHeaders = await this.authorizationService.buildHeaders();
      const response = await this.httpClient
        .put<any>(environment.apiUrl + 'algolia/course', { payload }, { headers })
        .toPromise();
      return response;
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }

  public async removeCourse(id: string): Promise<any> {
    try {
      const headers: HttpHeaders = await this.authorizationService.buildHeaders();
      const response = await this.httpClient
        .delete<any>(environment.apiUrl + 'algolia/course/delete/' + id, { headers })
        .toPromise();
      return response;
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }

  public async createUserEvent(payload: any): Promise<any> {
    try {
      const headers: HttpHeaders = await this.authorizationService.buildHeaders();
      const response = await this.httpClient
        .post<any>(environment.apiUrl + 'algolia/user-event', { payload }, { headers })
        .toPromise();
      return response;
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }

  public async updateUserEvent(payload: any): Promise<any> {
    try {
      const headers: HttpHeaders = await this.authorizationService.buildHeaders();
      const response = await this.httpClient
        .put<any>(environment.apiUrl + 'algolia/user-event', { payload }, { headers })
        .toPromise();
      return response;
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }

  public async removeUserEvent(id: string): Promise<any> {
    try {
      const headers: HttpHeaders = await this.authorizationService.buildHeaders();
      const response = await this.httpClient
        .delete<any>(environment.apiUrl + 'algolia/user-event/delete/' + id, { headers })
        .toPromise();
      return response;
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }

  public async createUserCourse(payload: any): Promise<any> {
    try {
      const headers: HttpHeaders = await this.authorizationService.buildHeaders();
      const response = await this.httpClient
        .post<any>(environment.apiUrl + 'algolia/user-course', { payload }, { headers })
        .toPromise();
      return response;
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }

  public async removeUserCourse(id: string): Promise<any> {
    try {
      const headers: HttpHeaders = await this.authorizationService.buildHeaders();
      const response = await this.httpClient
        .delete<any>(environment.apiUrl + 'algolia/user-course/delete/' + id, { headers })
        .toPromise();
      return response;
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }

  public async createLibrary(payload: any): Promise<any> {
    try {
      const headers: HttpHeaders = await this.authorizationService.buildHeaders();
      const response = await this.httpClient
        .post<any>(environment.apiUrl + 'algolia/library', { payload }, { headers })
        .toPromise();
      return response;
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }

  public async updateLibrary(payload: any): Promise<any> {
    try {
      const headers: HttpHeaders = await this.authorizationService.buildHeaders();
      const response = await this.httpClient
        .put<any>(environment.apiUrl + 'algolia/library', { payload }, { headers })
        .toPromise();
      return response;
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }

  public async removeLibrary(id: string): Promise<any> {
    try {
      const headers: HttpHeaders = await this.authorizationService.buildHeaders();
      const response = await this.httpClient
        .delete<any>(environment.apiUrl + 'algolia/library/delete/' + id, { headers })
        .toPromise();
      return response;
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }

  public async createUser(payload: any): Promise<any> {
    try {
      const headers: HttpHeaders = await this.authorizationService.buildHeaders();
      const response = await this.httpClient
        .post<any>(environment.apiUrl + 'algolia/user', { payload }, { headers })
        .toPromise();
      return response;
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }

  public async updateUser(payload: any, userId: string = null): Promise<any> {
    try {
      const headers: HttpHeaders = await this.authorizationService.buildHeaders();
      const response = await this.httpClient
        .put<any>(environment.apiUrl + 'algolia/user', { userId, payload }, { headers })
        .toPromise();
      return response;
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }

  public async removeUser(id: string): Promise<any> {
    try {
      const headers: HttpHeaders = await this.authorizationService.buildHeaders();
      const response = await this.httpClient
        .delete<any>(environment.apiUrl + 'algolia/user/delete/' + id, { headers })
        .toPromise();
      return response;
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }

  public async createUserHub(payload: any): Promise<any> {
    try {
      const headers: HttpHeaders = await this.authorizationService.buildHeaders();
      const response = await this.httpClient
        .post<any>(environment.apiUrl + 'algolia/user-hub', { payload }, { headers })
        .toPromise();
      return response;
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }

  public async updateUserHub(payload: any): Promise<any> {
    try {
      const headers: HttpHeaders = await this.authorizationService.buildHeaders();
      const response = await this.httpClient
        .put<any>(environment.apiUrl + 'algolia/user-hub', { payload }, { headers })
        .toPromise();
      return response;
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }

  public async removeUserHub(id: string): Promise<any> {
    try {
      const headers: HttpHeaders = await this.authorizationService.buildHeaders();
      const response = await this.httpClient
        .delete<any>(environment.apiUrl + 'algolia/user-hub/delete/' + id, { headers })
        .toPromise();
      return response;
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }
}
