import * as moment from 'moment';
import { makeAutoObservable } from 'mobx';
import { Injectable } from '@angular/core';

import { parseToMoment } from '../../../shared';
import { IBrand, ITag, IEvent } from '../../models';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class EventsStore {
  public event: IEvent = null;
  public adminEvent: IEvent = null;
  public isEventRegistered = false;
  public eventMediaPartners?: IBrand[];
  public event$ = new BehaviorSubject<IEvent>(null);

  public get eventId(): string {
    return this.event?.id;
  }

  public get isOnDemandMode(): boolean {
    return this.event?.isOnDemandMode || false;
  }

  public get isOnDemandComingSoonMode(): boolean {
    return this.event?.isOnDemandComingSoon;
  }

  constructor() {
    makeAutoObservable(this);
  }

  public setEvent(event: IEvent): void {
    this.event = event;
    this.event$.next(event);
  }

  public setAdminEvent(event: IEvent): void {
    this.adminEvent = event;
  }

  public unsetAdminEvent() {
    this.adminEvent = null;
  }

  public setEventRegisterStatus(registered: boolean): void {
    this.isEventRegistered = registered;
  }

  public setEventTags(tags: ITag[]): void {
    const tagIds = tags.map((t) => t.id);
    if (this.event.tags.every((id) => tagIds.includes(id))) {
      this.event.tagsList = tags;
    }
  }

  public setEventMediaPartners(brands: IBrand[]): void {
    this.eventMediaPartners = brands;
  }

  public get eventStart(): moment.Moment {
    return parseToMoment(this.event?.start);
  }

  public get eventEnd(): moment.Moment {
    return parseToMoment(this.event?.end);
  }

  public get isActive(): boolean {
    return moment().isBetween(parseToMoment(this.event?.start), parseToMoment(this.event?.end));
  }

  public get isOver(): boolean {
    return moment().isAfter(parseToMoment(this.event?.end));
  }

  public get isUpcoming(): boolean {
    return moment().isBefore(parseToMoment(this.event?.start));
  }

  public get daysLeft(): number {
    return parseToMoment(this.event?.start)?.diff(moment(), 'days');
  }

  public get hoursLeft(): number {
    return parseToMoment(this.event?.start)?.diff(moment(), 'hours');
  }

  public get minutesLeft(): number {
    return parseToMoment(this.event?.start)?.diff(moment(), 'minutes');
  }

  public get minutesToStartFromPublish(): number {
    if (this.event?.publicationDate) {
      return parseToMoment(this.event?.start)?.diff(
        parseToMoment(this.event?.publicationDate),
        'minutes',
      );
    } else {
      return 0;
    }
  }

  public get daysToStartFromPublish(): number {
    if (this.event.publicationDate) {
      return parseToMoment(this.event?.start)?.diff(
        parseToMoment(this.event?.publicationDate),
        'days',
      );
    } else {
      return 0;
    }
  }

  public get eventDateFormatted(): string {
    const start = parseToMoment(this.event.start).format('DD. MMM');
    const end = parseToMoment(this.event.end).format('DD. MMM YYYY');
    // I dont think we ever enter this if.
    if (start === end) {
      return start;
    } else {
      return `${start} - ${end}`;
    }
  }

  public reset(): void {
    this.event = null;
    this.event$.next(null);
    this.isEventRegistered = false;
  }
}
