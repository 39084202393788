import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { IEvent, IUserEvent } from 'src/app/core/models';

@Component({
  selector: 'app-clarification-dialog',
  templateUrl: './clarification-dialog.component.html',
  styleUrls: ['./clarification-dialog.component.scss'],
})
export class ClarificationDialogComponent implements OnInit {
  title: string;
  text: string;
  btnText: string;
  event: IEvent;
  eventUsers: { [eventId: string]: IUserEvent };
  className: string;
  actionButtonColor: string;

  constructor(
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
  ) {}

  ngOnInit(): void {
    const { event, eventUsers, className } = this.config.data;
    this.event = event;
    if (this.event.primaryColor) {
      this.actionButtonColor = this.event.primaryColor;
    }
    this.eventUsers = eventUsers;
    this.className = className;

    if (event.eventbriteSync) {
      this.title = 'clarificationDialog.tickets.title';
      this.text = 'clarificationDialog.tickets.onDemandText';
      this.btnText = 'clarificationDialog.tickets.onDemandBtn';
    } else {
      this.title = 'clarificationDialog.tickets.title';
      this.text = 'clarificationDialog.tickets.usualText';
      this.btnText = 'clarificationDialog.tickets.onDemandBtn';
    }
  }

  close(): void {
    this.ref.close();
  }

  closeUsualDialog(): void {
    this.ref.close(true);
  }
}
