import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-event-sync-eventbrite',
  templateUrl: './event-sync-eventbrite.component.html',
  styleUrls: ['./event-sync-eventbrite.component.scss'],
})
export class EventSyncEventbriteComponent {
  constructor(
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
  ) {}
}
