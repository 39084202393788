import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { TranslateModule } from '@ngx-translate/core';

import { HubsStore } from 'src/app/core/stores';
import { ICustomSidebarMenuItem, IHub } from 'src/app/core/models';
import { ButtonComponent, ButtonSize, ButtonStyle, ButtonType } from 'src/app/standalone/shared';

@Component({
  selector: 'app-feature-header',
  standalone: true,
  imports: [CommonModule, TranslateModule, MenuModule, MenubarModule, ButtonComponent],
  templateUrl: './feature-header.component.html',
  styleUrls: ['./feature-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeatureHeaderComponent {
  @Input() headerTitle: string;
  @Input() menuItems: ICustomSidebarMenuItem[];
  @Input() backUrl: string;
  @Input() actionItems: MenuItem[];
  @Output() activeMenuItem = new EventEmitter<ICustomSidebarMenuItem>();

  buttonSize = ButtonSize;
  buttonType = ButtonType;
  buttonStyle = ButtonStyle;

  constructor(
    private router: Router,
    private hubsStore: HubsStore,
  ) {}

  get hub(): IHub {
    return this.hubsStore.hub;
  }

  onActiveItemChange(event: ICustomSidebarMenuItem): void {
    this.activeMenuItem.emit(event);
  }

  onBack(): void {
    this.router.navigate([this.backUrl]);
  }
}
