import { Timestamp } from 'src/app/firebase';

export interface IAppointment {
  id: string;
  eventId: string;
  meetingName: string;
  description: string;
  startDate: Timestamp;
  endDate: Timestamp;
  hostId: string;
  users: string[];
  location?: string;
  createdAt?: Timestamp;
  createdBy?: string;
  updatedAt?: Timestamp;
  updatedBy?: string;
}

export class Appointment implements IAppointment {
  id: string;

  eventId: string;
  meetingName: string;
  description: string;
  startDate: Timestamp;
  endDate: Timestamp;
  hostId: string;
  users: string[];

  updatedAt: any;
  updatedBy: string;
  createdAt: any;
  createdBy: string;

  constructor(
    id: string = null,

    eventId = null,
    meetingName = null,
    description = null,
    startDate = null,
    endDate = null,
    hostId = null,
    users = null,

    updatedAt: any = null,
    updatedBy: string = null,
    createdAt: any = null,
    createdBy: string = null,
  ) {
    this.id = id;

    this.eventId = eventId;
    this.meetingName = meetingName;
    this.description = description;
    this.startDate = startDate;
    this.endDate = endDate;
    this.hostId = hostId;
    this.users = users;

    this.updatedAt = updatedAt;
    this.updatedBy = updatedBy;
    this.createdAt = createdAt;
    this.createdBy = createdBy;
  }
}
