import {
  Component,
  Input,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  TemplateRef,
  ContentChildren,
  QueryList,
} from '@angular/core';
import { Table } from 'primeng/table';
import { TranslateService } from '@ngx-translate/core';

import { trackByFn } from 'src/app/core/utils';
import { IAdminTableColumn } from 'src/app/shared';

@Component({
  selector: 'admin-table',
  templateUrl: './admin-table.component.html',
  styleUrls: ['./admin-table.component.scss'],
})
export class AdminTableComponent<T> implements OnInit {
  @Input() emptyStateKey = 'adminTable.empty';
  @Input() emptyStateTitleKey: string = null;
  @Input() loading = false;
  @Input() lazyLoading = false;
  @Input() columns: IAdminTableColumn[] = [];
  @Input() entries: T[] = [];
  @Input() lazy = true;
  @Input() lazyLoadOnInit = false;
  @Input() enableReorder = false;
  @Input() searchFilterFields = [];
  @Input() paginator = true;
  @Input() pageSize = 25;
  @Input() pageSizeOptions: number[] = [5, 10, 25, 50, 100];
  @Input() totalRecords?: number;
  @Input() pageReportTemplate = 'adminTable.pageReportTemplate';
  @Input() first = 0;
  @Input() showCurrentPageReport = true;
  @Input() dataKey: string;
  @Input() selectedEntries: object[] = [];
  @Input() checkBoxSelection: boolean;
  @Input() isShowTotalEntries = true;
  @Input() emptyStateIcon = '';
  @Input() scssClass = 'show-row-border-bottom';
  @Input() sortField: string;

  @Output() lazyLoad = new EventEmitter<any>();
  @Output() pageLoad = new EventEmitter<any>();
  @Output() selectedEntriesChange = new EventEmitter<object[]>();
  @Output() rowReorder = new EventEmitter<any[]>();
  @Output() rowClicked = new EventEmitter<string>();

  @ViewChild('adminTable') adminTable: Table;
  @ContentChildren(TemplateRef) templateRef: QueryList<TemplateRef<any>>;

  trackByFn = trackByFn;
  footerReportTemplateParameters;

  private templatesByKey: { [tablekey: string]: TemplateRef<any> } = {};

  constructor(private translateService: TranslateService) {}

  @Input() set searchValue(value: string) {
    if (value.length) {
      this.adminTable.filterGlobal(value, 'contains');
    } else {
      if (this.adminTable) {
        this.adminTable.clear();
      }
    }
  }

  ngOnInit(): void {
    this.footerReportTemplateParameters = {
      totalRecords: this.entries ? this.entries.length : 0,
    };
  }

  templateForKey(templateKey: string): TemplateRef<any> {
    if (!templateKey) {
      return null;
    }

    let template = this.templatesByKey[templateKey];

    if (!template) {
      template =
        this.templateRef
          .toArray()
          .find(
            (templateElement) => templateElement.elementRef.nativeElement?.key === templateKey,
          ) || null;
      this.templatesByKey[templateKey] = template;
    }

    return template;
  }

  customSort(event: { data: T[]; field: string; mode: string; order: number }): void {
    if (event.field === 'eventTimeStamp' || event.field === 'createdAtString') {
      const propertyName: 'eventTimeStamp' | 'createdAtString' =
        event.field === 'eventTimeStamp' ? 'eventTimeStamp' : 'createdAtString';
      event.data.sort((a, b) => {
        const dateA = new Date(a[propertyName]);
        const dateB = new Date(b[propertyName]);
        if (dateA < dateB) {
          return event.order === 1 ? -1 : 1;
        } else if (dateA > dateB) {
          return event.order === 1 ? 1 : -1;
        } else {
          return 0;
        }
      });
    } else {
      event.data.sort((a, b) => {
        let valueA = a[event.field];
        let valueB = b[event.field];
        // In case there are strings to sort, it should be not case sensitive
        if (typeof valueA === 'string' && typeof valueB === 'string') {
          valueA = valueA.toLowerCase();
          valueB = valueB.toLowerCase();
        }
        if (valueA < valueB) {
          return event.order === 1 ? -1 : 1;
        } else if (valueA > valueB) {
          return event.order === 1 ? 1 : -1;
        } else {
          return 0;
        }
      });
    }
  }

  getPlaceholder(field: string): string {
    return this.translateService.instant(`adminTable.${field}`);
  }

  onRowClick(id: string): void {
    this.rowClicked.emit(id);
  }
}
