import { Timestamp } from 'src/app/firebase';
import { Cities, RoomType } from 'src/app/shared';

export interface IUserEvent {
  id: string;
  eventId: string;
  userId: string;
  _firstName_: string;
  _lastName_: string;
  _company_: string;
  _position_: string;
  _event_title_: string;
  role: string;
  tags: string[];
  ticketId: any;
  ticketPrice: number;
  isBrandPerson: boolean;
  status: 'active' | 'inactive' | 'pending';
  createdAt: Timestamp;
  createdBy: string;
  updatedAt: Timestamp;
  updatedBy: string;
  joinAt: Timestamp;
  acceptedConsents: string[];
  acceptedMultiConsents: any;
  popUpConsents: IUserEventPopUpConsent[];
  hotel: string;
  roomType: RoomType;
  roomPartner: string;
  preferredAirport: Cities;
  alternativeAirport: Cities;
  isAttendTheEvent?: boolean;
  clothingSize?: string;
  shoeSize?: string;
  invitationUrlOpenedAt?: Timestamp;
  checkedIn: boolean;
}

export interface IUserEventPopUpConsent {
  consentId: string;
  response: boolean;
  dateOfResponse: Timestamp;
}
