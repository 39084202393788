@if (loading()) {
  <app-loader></app-loader>
} @else {
  <div class="admin-session-relation-table-container">
    <admin-table-header-navbar
      title="{{ 'adminEventSessionSidebar.registrationsNavItem' | translate }}">
      <div class="flex align-items-center gap-2">
        <app-button
          buttonText="{{ 'adminSession.attachUsers' | translate }}"
          buttonIconLeft="fa-regular fa-user-plus"
          [buttonStyle]="buttonStyle.FILLED_SECONDARY"
          [buttonSize]="buttonSize.MEDIUM"
          [disabled]="!session?.isSessionRegistration"
          (click)="onAttachUsers()" />
        <app-button
          buttonText="{{ 'exportFields.exportBtnLabel' | translate }}"
          buttonIconLeft="fa-solid fa-up-from-bracket"
          [buttonStyle]="buttonStyle.FILLED_SECONDARY"
          [buttonSize]="buttonSize.MEDIUM"
          (click)="toggleExportSidebar()" />
      </div>
    </admin-table-header-navbar>

    <div class="c-my-4">
      <p-divider styleClass="custom-divider-horizontal"></p-divider>
    </div>

    <admin-table-filter-navbar
      (onFiltersChange)="filtersChange($event)"
      (onSearchTermChange)="searchValue = $event">
    </admin-table-filter-navbar>

    <admin-table
      [loading]="loadingEntries()"
      [columns]="columns()"
      [entries]="entries()"
      [paginator]="true"
      [lazy]="false"
      [emptyStateKey]="'adminUsers.emptyState'"
      [emptyStateIcon]="'fa-regular fa-user-group'"
      [searchValue]="searchValue()"
      [searchFilterFields]="searchFilterFields()"
      checkBoxSelection="true"
      [(selectedEntries)]="selectedEntries">
      <ng-template
        let-entry
        epTemplateKey="timeCell">
        {{ entry.userSessionCreatedAt | datePipe: 'DD-MMMM-YYYY, HH:mm' }}
      </ng-template>

      <ng-template
        let-entry
        epTemplateKey="actionsCell">
        <div class="table-btn-actions-container">
          <app-button
            [buttonType]="buttonType.ROUND"
            [buttonSize]="buttonSize.MEDIUM"
            [buttonStyle]="buttonStyle.FILLED_SECONDARY"
            [loading]="entry?.isActionBtnShowingSpinner"
            buttonRoundIcon="far fa-pen-to-square"
            (click)="edit($event, entry)" />
          <app-button
            [buttonType]="buttonType.ROUND"
            [buttonSize]="buttonSize.MEDIUM"
            [buttonStyle]="buttonStyle.FILLED_DANGER"
            [loading]="entry?.isActionBtnShowingSpinner"
            buttonRoundIcon="far fa-trash-can"
            (click)="remove($event, entry)" />
        </div>
      </ng-template>
    </admin-table>

    <p-sidebar
      [(visible)]="showExportSidebar"
      styleClass="email-merge-tags-sidebar"
      [style]="{
        width: '40%',
        background: 'var(--background-sidebar)',
        color: 'var(--text-primary)',
        border: '0',
      }"
      position="right"
      baseZIndex="1">
      <app-export-fields-selector [isSessionRegistrationPage]="true"></app-export-fields-selector>
    </p-sidebar>
  </div>
}

<p-toast>
  <ng-template
    let-message
    pTemplate="message">
    <app-toast
      [severity]="message.severity"
      [summary]="message.summary"
      [detail]="message.detail">
    </app-toast>
  </ng-template>
</p-toast>
