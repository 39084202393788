<div class="header">
  <div class="title">
    {{ 'adminSession.attachUsers' | translate }}
  </div>
  <app-button
    buttonText="{{ 'buttons.attach' | translate }}"
    [buttonSize]="buttonSize.MEDIUM"
    [loading]="attachLoading"
    [disabled]="!selectedUsers.length"
    (click)="attach()" />
</div>
<div class="container">
  <admin-table
    [lazyLoading]="loadingUsers"
    [columns]="columns"
    [entries]="users"
    [pageSize]="pageSize"
    [(selectedEntries)]="selectedUsers"
    checkBoxSelection="true"
    [paginator]="false"
    [emptyStateKey]="'adminUsers.emptyState'"
    [emptyStateIcon]="'fa-regular fa-user-group'"
    [isShowTotalEntries]="false"
    (lazyLoad)="fetchEntries($event)">
  </admin-table>
</div>

<p-toast key="user">
  <ng-template
    let-message
    pTemplate="message">
    <app-toast
      [severity]="message.severity"
      [summary]="message.summary"
      [detail]="message.detail">
    </app-toast>
  </ng-template>
</p-toast>
