import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { parseToMoment, API_ROUTES as apiRoutes } from 'src/app/shared';
import { capitalizeFirstLetter } from 'src/app/core/utils';
import {
  ServerTimestamp,
  FieldPath,
  Firestore,
  IFirestore,
  IQuery,
  IDocumentData,
  IQuerySnapshot,
  IDocumentSnapshot,
  IDocumentReference,
} from 'src/app/firebase';
import {
  IBrand,
  IBrandMember,
  IEventBrand,
  IUser,
  IEvent,
  AlgoliaSearchResult,
  ICourse,
  ICourseBrand,
  IHubBrand,
  IUserHub,
} from 'src/app/core/models';
import { environment } from 'src/environments/environment';
import { AppStore } from 'src/app/app.store';
import {
  AlgoliaService,
  AuthorizationService,
  StorageService,
  UsersService,
  HttpClientService,
  ThemesService,
  SessionsService,
  StagesService,
} from 'src/app/core/services';
import { UsersStore, BrandsStore, EventsStore, HubsStore } from 'src/app/core/stores';
import { IBrandPerson } from '../../../admin/views/events/event-brand-people/event-brand-people.component';

@Injectable({
  providedIn: 'root',
})
export class BrandsService {
  private firestore: IFirestore;
  private readonly userEventsCollection: string = 'userEvents';

  constructor(
    private http: HttpClient,
    private httpService: HttpClientService,
    private storageService: StorageService,
    private usersService: UsersService,
    private usersStore: UsersStore,
    private eventsStore: EventsStore,
    private brandsStore: BrandsStore,
    private authorizationService: AuthorizationService,
    private algoliaService: AlgoliaService,
    private themesService: ThemesService,
    private sessionsService: SessionsService,
    private stagesService: StagesService,
    public appStore: AppStore,
    private hubsStore: HubsStore,
  ) {
    this.firestore = Firestore();
  }

  checkBrandByLinkExists(brandLink: string, brandId: string = null): Observable<boolean> {
    const brands: IBrand[] = [];
    const brandQuery: IQuery<IDocumentData> = this.firestore
      .collection(apiRoutes.brands)
      .where('link', '==', brandLink);

    return from(brandQuery.get()).pipe(
      map((response: IQuerySnapshot<IDocumentData>) => {
        response.forEach((doc) => {
          if (doc.data().id === brandId) {
            return;
          }
          brands.push(doc.data() as IBrand);
        });

        return brands.length > 0;
      }),
    );
  }

  async getBrandMemberByUserId(userId: string): Promise<IBrandMember[]> {
    try {
      const brandMembers: IBrandMember[] = (
        await this.firestore.collection(apiRoutes.userBrands).where('userId', '==', userId).get()
      ).docs.map((doc) => doc.data() as IBrandMember);

      return brandMembers;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getBrandAllMembers(brandId: string): Promise<IUser[]> {
    if (!brandId) {
      return null;
    }

    try {
      const users: IUser[] = [];
      const brandMembersQuery: IQuerySnapshot<IDocumentData> = await this.firestore
        .collection(apiRoutes.userBrands)
        .where('brandId', '==', brandId)
        .get();

      await Promise.all(
        brandMembersQuery.docs.map(async (brandMemberDoc) => {
          const brandMember: IBrandMember = brandMemberDoc.data() as IBrandMember;

          if (brandMember.userId) {
            const userDoc: IDocumentSnapshot<IDocumentData> = await this.firestore
              .collection(apiRoutes.users)
              .doc(brandMember.userId)
              .get();

            if (userDoc.exists) {
              let user: IUser = userDoc.data() as IUser;

              if (this.appStore.generalSystemSettings.enableEncryption) {
                user = this.usersService.decryptUserData(user) as IUser;
              }

              user.brandStatus = brandMember?.active;
              user.brandTimeStamp = parseToMoment(brandMember.createdAt).format('ll');
              user.brandRole = brandMember.role;
              user.brandUserId = brandMember.id;

              users.push(user);
            }
          } else {
            let user = {
              displayEmail: brandMember.displayEmail ? brandMember.displayEmail : brandMember.email,
              email: brandMember.email,
              brandRole: brandMember.role,
              brandUserId: brandMember.id,
            } as IUser;

            if (this.appStore.generalSystemSettings.enableEncryption) {
              user = this.usersService.decryptUserData(user) as IUser;
            }

            user.firstName = '-';
            user.lastName = '-';
            user.company = '-';
            user.position = '-';
            user.email = user.email.toLowerCase();

            users.push(user);
          }
        }),
      );

      return users;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getBrandAllEvents(brandId: string): Promise<IEvent[]> {
    if (!brandId) {
      return null;
    }

    try {
      const events: IEvent[] = [];
      const brandEventsQuery: IQuerySnapshot<IDocumentData> = await this.firestore
        .collection(apiRoutes.eventBrands)
        .where('brandId', '==', brandId)
        .get();

      await Promise.all(
        brandEventsQuery.docs.map(async (brandEventDoc) => {
          const brandEvent: IEventBrand = brandEventDoc.data() as IEventBrand;
          const eventDoc = await this.firestore
            .collection(apiRoutes.events)
            .doc(brandEvent.eventId)
            .get();
          if (eventDoc.exists) {
            const event: IEvent = eventDoc.data() as IEvent;
            events.push(event);
          }
        }),
      );

      return events;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async search(searchTerm: string): Promise<any[]> {
    const rawSearchResults = await this.algoliaService.algoliaSearch.search<any>([
      {
        query: `${searchTerm}`,
        indexName: `${this.appStore.environment.algolia.indexPrefix}brands`,
      },
    ]);

    return (rawSearchResults.results[0] as MySearchResponse).hits.map((d) => ({
      key: d.id,
      description: d.name,
    }));
  }

  async fetchUnattached(
    hubId: string,
    eventId: string,
    pageIndex: number,
    pageSize: number,
    searchTerm: string,
  ): Promise<AlgoliaSearchResult<IBrand>> {
    const attachedBrands = await this.getBrandsFromEvent(eventId);
    const facetFilters = attachedBrands
      .map((b: IEventBrand) => b.brandId)
      .map((id) => `brandId:-${id}`)
      .join(',');

    const resultFromAlgolia = await this.algoliaService.search<IHubBrand>(
      apiRoutes.hubBrands,
      `${searchTerm} ${hubId}`,
      pageSize,
      pageIndex * pageSize,
      '',
      facetFilters,
    );

    const result: AlgoliaSearchResult<IBrand> = {
      total: resultFromAlgolia.total,
      results: resultFromAlgolia.results.map((item: any) => item.brand),
    };

    return result;
  }

  async fetchUnattachedCourseBrands(
    hubId: string,
    courseId: string,
    pageIndex: number,
    pageSize: number,
    searchTerm: string,
  ): Promise<AlgoliaSearchResult<IBrand>> {
    const attachedBrands = await this.getBrandCoursesByCourseId(courseId);
    const facetFilters = attachedBrands
      .map((b: ICourseBrand) => b.brandId)
      .map((id) => `brandId:-${id}`)
      .join(',');

    const resultFromAlgolia: AlgoliaSearchResult<IHubBrand> =
      await this.algoliaService.search<IHubBrand>(
        apiRoutes.hubBrands,
        `${searchTerm} ${hubId}`,
        pageSize,
        pageIndex * pageSize,
        '',
        facetFilters,
      );

    const result: AlgoliaSearchResult<IBrand> = {
      total: resultFromAlgolia.total,
      results: resultFromAlgolia.results.map((item: any) => item.brand),
    };

    return result;
  }

  async fetchUnattachedUsers(
    hubId: string,
    brandMembers: string[],
    pageIndex: number,
    pageSize: number,
    searchTerm: string,
  ): Promise<AlgoliaSearchResult<IUser>> {
    let facetFilters = '';

    if (brandMembers.length) {
      facetFilters = brandMembers.map((id) => `userId:-${id}`).join(',');
    }

    const resultFromAlgolia: AlgoliaSearchResult<IUserHub> =
      await this.algoliaService.search<IUserHub>(
        apiRoutes.userHubs,
        `${searchTerm} ${hubId}`,
        pageSize,
        pageIndex * pageSize,
        '',
        facetFilters,
      );

    const result: AlgoliaSearchResult<IUser> = {
      total: resultFromAlgolia.total,
      results: resultFromAlgolia.results.map((item: any) => item.user),
    };

    if (this.appStore.generalSystemSettings.enableEncryption) {
      result.results = result.results.map((b) => {
        return this.usersService.decryptUserData(b, ['_highlightResult', 'objectID']) as IUser;
      });
    }

    return result;
  }

  async fetchEventBrands(
    eventId: string,
    pageIndex: number,
    pageSize: number,
    searchTerm: string,
  ): Promise<AlgoliaSearchResult<any>> {
    return await this.algoliaService.search<any>(
      'eventBrands',
      `${searchTerm} ${eventId}`,
      pageSize,
      pageIndex * pageSize,
    );
  }

  async fetch(
    pageIndex: number,
    pageSize: number,
    searchTerm: string,
  ): Promise<AlgoliaSearchResult<IBrand>> {
    return await this.algoliaService.search<IBrand>(
      'brands',
      searchTerm,
      pageSize,
      pageIndex * pageSize,
    );
  }

  async fetchByHub(
    hubId: string = null,
    pageIndex: number,
    pageSize: number,
    searchTerm: string,
  ): Promise<AlgoliaSearchResult<IBrand>> {
    const response = await this.algoliaService.search<IUser>(
      'hubBrands',
      `${hubId} ${searchTerm}`,
      pageSize,
      pageIndex * pageSize,
    );

    return {
      total: response.total,
      results: response.results.map((r: any) => r.brand) as IBrand[],
    };
  }

  async fetchBrandPeople(
    eventId: string,
    pageIndex: number,
    pageSize: number,
    searchTerm: string,
  ): Promise<AlgoliaSearchResult<IBrandPerson>> {
    const attachedBrands = await this.algoliaService.search<any>(
      'eventBrands',
      `${eventId}`,
      undefined,
      undefined,
    );

    if (!attachedBrands.total) {
      return {
        total: 0,
        results: [],
      };
    }

    const filters = attachedBrands.results
      .map((b: IEventBrand) => b.brandId)
      .map((id) => `brandId:${id}`)
      .join(' OR ');
    const response = await this.algoliaService.search<any>(
      'userBrands',
      `${searchTerm}`,
      pageSize,
      pageIndex * pageSize,
      filters,
    );

    if (this.appStore.generalSystemSettings.enableEncryption) {
      response.results = response.results.map((b) => {
        return this.usersService.decryptUserData(b, ['_highlightResult', 'objectID']) as IUser;
      });
    }

    return {
      total: response.total,
      results: response.results.map(
        (r) =>
          ({
            id: r.userId,
            displayEmail: r.user ? r.user.email : r.email,
            email: r.user ? r.user.email.toLowerCase() : r.email.toLowerCase(),
            company: r.user ? r.user.company : '-',
            firstName: r.user ? r.user.firstName : '-',
            lastName: r.user ? r.user.lastName : '-',
            isActive: r.active,
            brandId: r.brandId,
            isOwner: r.user ? r.brandId === r.user.brandId : false,
          }) as IBrandPerson,
      ),
    };
  }

  async deleteEventBrand(eventBrandId: string): Promise<void> {
    const eventBrand = await this.getEventBrandById(eventBrandId);

    if (!eventBrand) {
      return;
    }

    const { brandId, eventId } = eventBrand;
    const [sessions, stages] = await Promise.all([
      this.sessionsService.getAll(eventId),
      this.stagesService.getEventStages(eventId, true),
    ]);

    // remove association to sessions
    await Promise.all(
      sessions.map(async (session) => {
        try {
          const associatedSessionBrandIndex: number = session.brands?.indexOf(brandId);
          if (associatedSessionBrandIndex >= 0) {
            session.brands.splice(associatedSessionBrandIndex, 1);
            await this.sessionsService.update(eventId, session.id, session);
          }
        } catch (error) {
          console.warn(error);
          throw new Error(error);
        }
      }),
    );

    // remove association to stages
    await Promise.all(
      stages.map(async (stage) => {
        try {
          const associatedStageBrandIndex: number = stage.sponsors?.indexOf(brandId);
          if (associatedStageBrandIndex >= 0) {
            stage.sponsors.splice(associatedStageBrandIndex, 1);
            await this.stagesService.update(eventId, stage.id, stage);
          }
        } catch (error) {
          console.warn(error);
          throw new Error(error);
        }
      }),
    );

    // delete event brand
    await this.firestore.collection(apiRoutes.eventBrands).doc(eventBrandId).delete();
    await this.algoliaService.removeEventBrand(eventBrandId);
  }

  async getTeamMembers(
    brandId: string,
    pageIndex: number,
    pageSize: number,
    searchTerm: string,
  ): Promise<AlgoliaSearchResult<IUser>> {
    const res = await this.algoliaService.search(
      'userBrands',
      `${searchTerm}`,
      pageSize,
      pageIndex * pageSize,
      `brandId:${brandId}`,
    );

    if (this.appStore.generalSystemSettings.enableEncryption) {
      res.results = res.results.map((b) => {
        return this.usersService.decryptUserData(b, ['_highlightResult', 'objectID']) as IUser;
      });
    }

    return {
      total: res.total,
      results: res.results.map((r: any) =>
        Object.assign({}, r.user, {
          memberRole: r.role ? capitalizeFirstLetter(r.role) : 'Admin',
          brandUserId: r.id,
        }),
      ) as IUser[],
    };
  }

  async getBrandEvents(
    brandId: string,
    pageIndex: number,
    pageSize: number,
    searchTerm: string,
  ): Promise<AlgoliaSearchResult<IEvent>> {
    const res = await this.algoliaService.search(
      'eventBrands',
      `${searchTerm}`,
      pageSize,
      pageIndex * pageSize,
      `brandId:${brandId}`,
    );

    return {
      total: res.total,
      results: res.results.map((r: any) => r.event) as IEvent[],
    };
  }

  async getBrandEventsByBrandId(
    brandId: string,
    order: 'asc' | 'desc' = 'desc',
  ): Promise<IEventBrand[]> {
    try {
      const eventBrands: IEventBrand[] = (
        await this.firestore
          .collection(apiRoutes.eventBrands)
          .where('brandId', '==', brandId)
          .orderBy('_event_title_', order)
          .get()
      ).docs.map((doc) => doc.data() as IEventBrand);

      return eventBrands;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async addBrandToEvent(
    event: IEvent,
    brandId: string,
    brand: IBrand = null,
    eventTags: string[] = [],
    brandLevel: string = null,
  ): Promise<void> {
    if (!brand) {
      brand = await this.getOne(brandId);
    }
    const eventBrandDoc: IDocumentReference<IDocumentData> = this.firestore
      .collection(apiRoutes.eventBrands)
      .doc();
    const eventBrand: IEventBrand = {
      id: eventBrandDoc.id,
      _brand_name_: brand.name.toLowerCase(),
      _event_title_: event.title ? event.title.toLowerCase() : null,
      brandId,
      eventId: event.id,
      level: brandLevel ? brandLevel : 'default',
      tags: eventTags ? eventTags : brand.tags,
      createdAt: ServerTimestamp(),
      createdBy: this.usersStore.user.id,
      updatedAt: null,
      updatedBy: null,
    } as IEventBrand;

    await eventBrandDoc.set({ ...eventBrand });
    await this.algoliaService.createEventBrand(eventBrand);
  }

  async addBrandToCourse(
    course: ICourse,
    brandId: string,
    brand: IBrand = null,
    eventTags: string[] = [],
    brandLevel: string = null,
  ): Promise<void> {
    if (!brand) {
      brand = await this.getOne(brandId);
    }
    const brandCourseDoc: IDocumentReference<IDocumentData> = this.firestore
      .collection(apiRoutes.brandCourses)
      .doc();
    const courseBrand: ICourseBrand = {
      id: brandCourseDoc.id,
      courseId: course.id,
      brandId,
      status: false,
      tags: eventTags ? eventTags : brand.tags,
      level: brandLevel ? brandLevel : 'default',
      _brandName_: brand.name.toLowerCase(),
      _courseTitle_: course.title ? course.title.toLowerCase() : null,
      createdAt: ServerTimestamp(),
      createdBy: this.usersStore.user.id,
      updatedAt: null,
      updatedBy: null,
    } as ICourseBrand;

    await brandCourseDoc.set({ ...courseBrand });
    await this.algoliaService.createBrandCourse(courseBrand);
  }

  async getAllPaginated(
    eventId: string = null,
    order: 'asc' | 'desc',
    pageSize: number,
    entry?: IBrand,
    excludeMediaPartners: boolean = false,
  ): Promise<IBrand[]> {
    const brandIds: string[] = [];
    const eventBrandsArray: IEventBrand[] = [];
    let query = this.firestore.collection(apiRoutes.eventBrands).where('eventId', '==', eventId);

    if (excludeMediaPartners) {
      query = query
        .where('level', 'not-in', ['media'])
        .orderBy('level', order)
        .orderBy('_brand_name_', order);
    } else {
      query = query.orderBy('_brand_name_', order);
    }

    try {
      (await query.get()).forEach((doc) => {
        const brandId = doc.data().brandId;
        eventBrandsArray.push(doc.data() as IEventBrand);
        if (!brandIds.includes(brandId)) {
          brandIds.push(brandId);
        }
      });

      const startFromIndex: number = entry ? brandIds.indexOf(entry.id) + 1 : 0;
      const brandIdsToQuery: string[] = brandIds.splice(startFromIndex, pageSize);
      const brands: IBrand[] = [];

      while (brandIdsToQuery.length) {
        const chunk = brandIdsToQuery.splice(0, 10);
        (
          await this.firestore
            .collection(apiRoutes.brands)
            .orderBy('_name_')
            .where('id', 'in', chunk)
            .get()
        ).forEach((doc) => {
          const brand = doc.data() as IBrand;
          brand.level = eventBrandsArray.find((b) => b.brandId === brand.id)?.level;
          brands.push(brand);
        });
      }
      this.brandsStore.setBrands(brands);

      return brands;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getByTags(
    eventId: string,
    tagIds: string[],
    order: 'asc' | 'desc',
    pageSize: number,
    entry?: IBrand,
    excludeMediaPartners: boolean = false,
  ): Promise<IBrand[]> {
    const tagsIds: string[] = [...tagIds];
    let brandIds: string[] = [];
    const eventBrandsArray: IEventBrand[] = [];

    while (tagsIds.length) {
      const chunk = tagsIds.splice(0, 10);
      (
        await this.firestore
          .collection(apiRoutes.eventBrands)
          .where('eventId', '==', eventId)
          .where('tags', 'array-contains-any', chunk)
          .orderBy('_brand_name_')
          .get()
      ).forEach((doc) => {
        const brandId = doc.data().brandId;
        eventBrandsArray.push(doc.data() as IEventBrand);
        if (excludeMediaPartners && doc.data().level === 'media') {
          return;
        }

        if (!brandIds.includes(brandId)) {
          brandIds.push(brandId);
        }
      });
    }

    const startFromIndex: number = entry ? brandIds.indexOf(entry.id) + 1 : 0;
    brandIds = brandIds.splice(startFromIndex, pageSize);
    const brands: IBrand[] = [];

    while (brandIds.length) {
      const chunk = brandIds.splice(0, 10);
      (await this.firestore.collection(apiRoutes.brands).where('id', 'in', chunk).get()).forEach(
        (doc) => {
          const brand = doc.data() as IBrand;
          brand.level = eventBrandsArray.find((b) => b.brandId === brand.id)?.level;
          brands.push(brand);
        },
      );
    }
    this.brandsStore.setBrands(brands);

    return brands.sort((a, b) => this.sortBrandsByName(a, b, order));
  }

  async getBookmarked(
    eventId: string,
    order: 'asc' | 'desc',
    pageSize: number,
    entry?: IBrand,
  ): Promise<IBrand[]> {
    const userEventId: string = this.usersStore.userEventsMap[eventId].id;
    const brandIds: string[] = [];

    (
      await this.firestore
        .collection(`${this.userEventsCollection}/${userEventId}/bookmarkedBrands`)
        .get()
    ).forEach((doc) => {
      if (!brandIds.includes(doc.id)) {
        brandIds.push(doc.id);
      }
    });

    let brands: IBrand[] = [];

    while (brandIds.length) {
      const chunk = brandIds.splice(0, 10);
      (await this.firestore.collection(apiRoutes.brands).where('id', 'in', chunk).get()).forEach(
        (doc) => brands.push(doc.data() as IBrand),
      );
    }

    brands.sort((a, b) => this.sortBrandsByName(a, b, order));

    const startFromIndex: number = entry ? brands.map((u) => u.id).indexOf(entry.id) + 1 : 0;
    brands = brands.splice(startFromIndex, pageSize);
    this.brandsStore.setBrands(brands);

    return brands;
  }

  async getCertainLevelBrandsFromEvent(
    eventId: string = null,
    order: 'asc' | 'desc',
    level: string,
    entry?: IBrand,
    pageSize: number = null,
  ): Promise<IBrand[]> {
    const brands: IBrand[] = [];
    let brandsIds: string[] = [];
    const eventBrandsArray: IEventBrand[] = [];
    let eventBrandsQuery: IQuery<IDocumentData> | IQuerySnapshot<IDocumentData>;

    eventBrandsQuery = await this.firestore
      .collection(apiRoutes.eventBrands)
      .where('eventId', '==', eventId)
      .orderBy('_brand_name_');

    if (level) {
      eventBrandsQuery = eventBrandsQuery.where('level', '==', level);
    }

    eventBrandsQuery = await eventBrandsQuery.get();

    if (!eventBrandsQuery.empty) {
      eventBrandsQuery.forEach((doc) => {
        eventBrandsArray.push(doc.data() as IEventBrand);
        const brandId = doc.data().brandId;

        if (!brandsIds.includes(brandId)) {
          brandsIds.push(doc.data().brandId);
        }
      });

      if (brandsIds.length) {
        let queryBrands = null;

        while (brandsIds.length > 0) {
          const chunk = brandsIds.splice(0, 10);

          queryBrands = this.firestore
            .collection(apiRoutes.brands)
            .where('id', 'in', chunk)
            .orderBy('_name_', order)
            .orderBy(FieldPath.documentId());

          if (entry) {
            queryBrands = queryBrands.startAfter(entry.name, entry.id);
          }

          if (pageSize !== null) {
            queryBrands = await queryBrands.limit(pageSize).get();
          } else {
            queryBrands = await queryBrands.get();
          }

          if (!queryBrands.empty) {
            queryBrands.forEach((doc) => {
              const brand = doc.data();
              brand.level = eventBrandsArray.find((b) => b.brandId === brand.id)?.level;

              if (pageSize !== null && brands.length < pageSize) {
                brands.push(brand);
              } else if (pageSize === null) {
                brands.push(brand);
              }
            });
          }

          if (pageSize !== null && brands.length === pageSize) {
            brandsIds = [];
          }
        }
      }
    }

    // cache media partners when they're requested
    if (this.eventsStore.event?.id === eventId && level === 'media') {
      this.eventsStore.setEventMediaPartners(brands);
    }

    return brands;
  }

  async getOne(id: string, eventId?: string): Promise<IBrand> {
    if (this.brandsStore.brandsMap[id]) {
      return this.brandsStore.brandsMap[id];
    }
    try {
      const query: IDocumentSnapshot<IDocumentData> = await this.firestore
        .collection(apiRoutes.brands)
        .doc(id)
        .get();

      if (!eventId) {
        return query.data() as IBrand;
      }

      const eventBrandQuery = await this.firestore
        .collection(apiRoutes.eventBrands)
        .where('eventId', '==', eventId)
        .where('brandId', '==', id)
        .get();

      if (eventBrandQuery.size !== 1) {
        return null;
      }

      if (query.exists) {
        const result = query.data() as IBrand;
        result.tags = eventBrandQuery.docs[0].data().tags;

        return result;
      } else {
        return null;
      }
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getBrandByLink(link: string): Promise<IBrand> {
    const brandsQuery: IQuerySnapshot<IDocumentData> = await this.firestore
      .collection(apiRoutes.brands)
      .where('link', '==', link)
      .get();

    if (brandsQuery.size !== 1) {
      return null;
    }
    const result = brandsQuery.docs[0].data() as IBrand;

    return result;
  }

  async getAll(): Promise<any[]> {
    try {
      const brands: IBrand[] = [];
      const query: IQuerySnapshot<IDocumentData> = await this.firestore
        .collection(apiRoutes.brands)
        .get();
      if (query) {
        query.forEach((x) => brands.push(x.data() as IBrand));

        return brands;
      } else {
        return null;
      }
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getFeaturedBookmarkedByHubPaginated(
    hubId: string,
    order: 'asc' | 'desc',
    pageSize: number,
    entry?: IBrand,
  ): Promise<IBrand[]> {
    try {
      const bookmarkedBrandIds = await this.usersService.getUserBookmarkedBrandsIds(
        this.usersStore.userId,
      );
      const brandIds: string[] = [];
      const hubBrandsArray: IHubBrand[] = [];
      let query: IQuery<IDocumentData> = this.firestore
        .collection(apiRoutes.hubBrands)
        .where('hubId', '==', hubId)
        .orderBy('_brand_name_', order);

      (await query.get()).forEach((doc) => {
        const brandId = doc.data().brandId;
        hubBrandsArray.push(doc.data() as IHubBrand);
        if (!brandIds.includes(brandId) && bookmarkedBrandIds.includes(brandId)) {
          brandIds.push(brandId);
        }
      });

      const startFromIndex: number = entry ? brandIds.indexOf(entry.id) + 1 : 0;
      const brandIdsToQuery: string[] = brandIds.splice(startFromIndex, pageSize);
      const brands: IBrand[] = [];

      while (brandIdsToQuery.length) {
        const chunk = brandIdsToQuery.splice(0, 10);
        (
          await this.firestore
            .collection(apiRoutes.brands)
            .orderBy('_name_')
            .where('id', 'in', chunk)
            .where('featuredBrand', '==', true)
            .get()
        ).forEach((doc) => {
          const brand: IBrand = doc.data() as IBrand;
          brand.hub = hubBrandsArray.find((hubBrand: IHubBrand) => hubBrand.brandId === brand.id);
          brands.push(brand);
        });
      }

      return brands;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getFeaturedByHubPaginated(
    hubId: string,
    order: 'asc' | 'desc',
    pageSize: number,
    entry?: IBrand,
  ): Promise<IBrand[]> {
    try {
      const brandIds: string[] = [];
      const hubBrandsArray: IHubBrand[] = [];
      let query: IQuery<IDocumentData> = this.firestore
        .collection(apiRoutes.hubBrands)
        .where('hubId', '==', hubId)
        .orderBy('_brand_name_', order);

      (await query.get()).forEach((doc) => {
        const brandId: string = doc.data().brandId;
        hubBrandsArray.push(doc.data() as IHubBrand);
        if (!brandIds.includes(brandId)) {
          brandIds.push(brandId);
        }
      });

      const startFromIndex: number = entry ? brandIds.indexOf(entry.id) + 1 : 0;
      const brandIdsToQuery: string[] = brandIds.splice(startFromIndex, pageSize);
      const brands: IBrand[] = [];

      while (brandIdsToQuery.length) {
        const chunk: string[] = brandIdsToQuery.splice(0, 10);
        (
          await this.firestore
            .collection(apiRoutes.brands)
            .orderBy('_name_')
            .where('id', 'in', chunk)
            .where('featuredBrand', '==', true)
            .get()
        ).forEach((doc) => {
          const brand = doc.data() as IBrand;
          brand.hub = hubBrandsArray.find((b) => b.brandId === brand.id);
          brands.push(brand);
        });
      }

      return brands;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getFeaturedBookmarkedPaginated(
    order: 'asc' | 'desc',
    pageSize: number,
    entry?: IBrand,
  ): Promise<IBrand[]> {
    try {
      const brandIds: string[] = await this.usersService.getUserBookmarkedBrandsIds(
        this.usersStore.userId,
      );
      let brands: IBrand[] = [];

      while (brandIds.length) {
        const chunk: string[] = brandIds.splice(0, 10);
        (
          await this.firestore
            .collection(apiRoutes.brands)
            .where('id', 'in', chunk)
            .where('featuredBrand', '==', true)
            .orderBy('_name_', order)
            .get()
        ).forEach((doc) => {
          brands.push(doc.data() as IBrand);
        });
      }

      const startFromIndex: number = entry ? brands.map((u) => u.id).indexOf(entry.id) + 1 : 0;
      brands = brands.splice(startFromIndex, pageSize);
      this.brandsStore.setBrands(brands);

      return brands;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getFeaturedPaginated(
    order: 'asc' | 'desc',
    pageSize: number,
    entry?: IBrand,
  ): Promise<IBrand[]> {
    try {
      const brands: IBrand[] = [];
      let query: IQuery<IDocumentData> = this.firestore
        .collection(apiRoutes.brands)
        .orderBy('_name_', order)
        .where('featuredBrand', '==', true);

      if (entry) {
        query = query.startAfter(entry ? entry._name_ : 0);
      }
      query = query.limit(pageSize);
      const queryResponse: IQuerySnapshot<IDocumentData> = await query.get();
      if (!queryResponse.empty) {
        queryResponse.forEach((x) => brands.push(x.data() as IBrand));
      }

      return brands;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getByIds(brandIds: string[]): Promise<IBrand[]> {
    const cached: IBrand[] = [];
    const missing: string[] = [];

    if (!!brandIds) {
      brandIds.forEach((brandId) => {
        const brand = this.brandsStore.brandsMap[brandId];
        if (brand) {
          cached.push(brand);
        } else {
          missing.push(brandId);
        }
      });
    }
    const brands: IBrand[] = [];

    while (missing.length) {
      const chunk: string[] = missing.splice(0, 10);
      (await this.firestore.collection(apiRoutes.brands).where('id', 'in', chunk).get()).forEach(
        (doc) => brands.push(doc.data() as IBrand),
      );
    }
    this.brandsStore.setBrands(brands);

    return cached.concat(brands);
  }

  async getPage(brand: IBrand = null, pageSize: number = 12): Promise<IBrand[]> {
    const brands: IBrand[] = [];
    const query: IQuerySnapshot<IDocumentData> = await this.firestore
      .collection(apiRoutes.brands)
      .orderBy('name')
      .startAfter(brand ? brand.name : 0)
      .limit(pageSize)
      .get();

    if (!query.empty) {
      query.forEach((x) => {
        brands.push(x.data() as IBrand);
      });

      return brands;
    } else {
      return null;
    }
  }

  async getBrandsFromEvent(eventId: string): Promise<IEventBrand[]> {
    const brands: IEventBrand[] = [];
    const eventBrandsQuery: IQuerySnapshot<IDocumentData> = await this.firestore
      .collection(apiRoutes.eventBrands)
      .where('eventId', '==', eventId)
      .get();

    eventBrandsQuery.forEach((brandQuery) => {
      const brand: IEventBrand = brandQuery.data() as IEventBrand;
      brands.push(brand);
    });

    return brands;
  }

  async getBrandCoursesByCourseId(courseId: string): Promise<ICourseBrand[]> {
    const brands: ICourseBrand[] = [];
    const eventBrandsQuery: IQuerySnapshot<IDocumentData> = await this.firestore
      .collection(apiRoutes.brandCourses)
      .where('courseId', '==', courseId)
      .get();

    eventBrandsQuery.forEach((brandQuery) => {
      const brand: ICourseBrand = brandQuery.data() as ICourseBrand;
      brands.push(brand);
    });

    return brands;
  }

  async getMembers(
    brandId: string,
    status: 'all' | 'accepted' | 'pending' = 'all',
    order: 'asc' | 'desc' = 'asc',
  ): Promise<IBrandMember[]> {
    const members: IBrandMember[] = [];
    try {
      let membersQuery: IQuery<IDocumentData> = this.firestore
        .collection(apiRoutes.userBrands)
        .where('brandId', '==', brandId);

      if (status === 'accepted') {
        membersQuery = membersQuery.where('active', '==', true);
      } else if (status === 'pending') {
        membersQuery = membersQuery.where('active', '==', false);
      }

      const snapshot: IQuerySnapshot<IDocumentData> = await membersQuery
        .orderBy('email', order)
        .get();
      if (snapshot.size > 0) {
        snapshot.forEach((doc) => {
          let member = doc.data() as IBrandMember;

          if (this.appStore.generalSystemSettings.enableEncryption) {
            member = this.usersService.decryptUserData(member) as IBrandMember;
          }

          members.push(member);
        });
      }

      return members;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async attachOwner(brand: IBrand, user: IUser): Promise<IBrandMember> {
    const brandMemberPreReq: IDocumentReference<IDocumentData> = await this.firestore
      .collection(apiRoutes.userBrands)
      .doc();
    let newMemberObject: IBrandMember = {
      id: brandMemberPreReq.id,
      brandId: brand.id,
      userId: user ? user.id : null,
      displayEmail: user ? user.email : null,
      email: user ? user.email.toLowerCase() : null,
      role: 'owner',
      active: user ? true : false,
      _firstName_: user ? user._firstName_ : null,
      _lastName_: user ? user._lastName_ : null,
      _company_: user ? user._company_ : null,
      _position_: user ? user._position_ : null,
      _brand_name_: brand._name_,
      createdAt: ServerTimestamp(),
      createdBy: this.usersStore.user.id,
      updatedAt: null,
      updatedBy: null,
    };

    if (this.appStore.generalSystemSettings.enableEncryption) {
      newMemberObject = this.usersService.encryptUserData(
        newMemberObject,
      ) as unknown as IBrandMember;
    }
    await this.firestore
      .collection(apiRoutes.userBrands)
      .doc(newMemberObject.id)
      .set({ ...newMemberObject });
    await this.algoliaService.createUserBrand(newMemberObject);

    if (this.appStore.generalSystemSettings.enableEncryption) {
      newMemberObject = this.usersService.decryptUserData(newMemberObject) as IBrandMember;
    }

    return newMemberObject as IBrandMember;
  }

  async addBrandMemberFromAdminPart(brandId: string, email: string, userId: string) {
    const reqPayload: { email: string; userId: string; tenantId: string; hubId: string } = {
      email,
      userId,
      tenantId: this.hubsStore.hub.tenantId,
      hubId: this.hubsStore.hubId,
    };
    const headers: HttpHeaders = await this.authorizationService.buildHeaders();

    return this.http
      .post<any>(environment.apiUrl + 'brands/' + brandId + '/invite-from-admin', reqPayload, {
        headers,
      })
      .toPromise();
  }

  async addMember(brandId: string, email: string): Promise<any> {
    const reqPayload: { email: string; userId: string; tenantId: string; hubId: string } = {
      email,
      userId: this.usersStore.user.id,
      tenantId: this.hubsStore.hub.tenantId,
      hubId: this.hubsStore.hub.id || null,
    };
    const headers: HttpHeaders = await this.authorizationService.buildHeaders();

    return this.http
      .post<any>(environment.apiUrl + 'brands/' + brandId + '/invite', reqPayload, { headers })
      .toPromise();
  }

  async resendInvite(brandId: string, email: string): Promise<any> {
    try {
      const reqPayload: { email: string; userId: string; tenantId: string; hubId: string } = {
        email,
        userId: this.usersStore.user.id,
        tenantId: this.hubsStore.hub.tenantId,
        hubId: this.hubsStore.hub.id,
      };

      return this.httpService.post<any>(`brands/${brandId}/resendinvite`, reqPayload);
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async addEventBrandOwner(
    eventId: string,
    userId: string,
    email: string,
    brandId: string,
  ): Promise<any> {
    try {
      const reqPayload: { email: string; userId: string; eventId: string; tenantId: string } = {
        email,
        eventId,
        userId,
        tenantId: this.hubsStore.hub.tenantId,
      };
      const headers: HttpHeaders = await this.authorizationService.buildHeaders();

      return this.http
        .post<any>(environment.apiUrl + 'brands/' + brandId + '/ownerinvite', reqPayload, {
          headers,
        })
        .toPromise();
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async removeMember(id: string): Promise<boolean> {
    try {
      await this.firestore.collection(apiRoutes.userBrands).doc(id).delete();

      return true;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async deattachOwner(brandId: string, userId: string): Promise<boolean[]> {
    try {
      const userBrandsQuery: IQuerySnapshot<IDocumentData> = await this.firestore
        .collection(apiRoutes.userBrands)
        .where('brandId', '==', brandId)
        .where('userId', '==', userId)
        .get();

      return await Promise.all(
        userBrandsQuery.docs.map(async (d) => await this.removeMember(d.id)),
      );
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async create(brand: any): Promise<IBrand> {
    try {
      const preBrandReq: IDocumentReference<IDocumentData> = await this.firestore
        .collection(apiRoutes.brands)
        .doc();
      brand.id = preBrandReq.id;
      const brandDocument: IDocumentReference<IDocumentData> = this.firestore
        .collection(apiRoutes.brands)
        .doc(brand.id);

      if (brand.logo instanceof File || brand.banner instanceof File) {
        const brandDocData = await brandDocument.get();

        if (brand.logo instanceof File) {
          const currentLogoImage = brandDocData.get('logo');

          if (currentLogoImage) {
            await this.storageService.delete(currentLogoImage);
          }

          if (brand.logo !== null) {
            const fileName = `file_${new Date().getTime()}`;
            const newLogoImage = await this.storageService.upload(brand.logo, 'brands', fileName);
            brand.logo = newLogoImage;
          } else {
            brand.logo = null;
          }
        }

        if (brand.banner instanceof File) {
          const currentBannerImage = brandDocData.get('banner');

          if (currentBannerImage) {
            await this.storageService.delete(currentBannerImage);
          }

          if (brand.banner !== null) {
            const fileName = `file_${new Date().getTime()}`;
            const newCoverImage = await this.storageService.upload(
              brand.banner,
              'brands',
              fileName,
            );
            brand.banner = newCoverImage;
          } else {
            brand.banner = null;
          }
        }
      }

      await Promise.all([
        brandDocument.set({ ...brand }),
        this.usersService.assignBrand(brand.user, brand.id),
      ]);
      await this.algoliaService.createBrand(brand);

      return brand as IBrand;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async update(id: string, brandPayload: any): Promise<IBrand> {
    try {
      const brandDocument: IDocumentReference<IDocumentData> = this.firestore
        .collection(apiRoutes.brands)
        .doc(id);

      if (brandPayload.logo instanceof File || brandPayload.banner instanceof File) {
        const brandDocData: IDocumentSnapshot<IDocumentData> = await brandDocument.get();

        if (brandPayload.logo instanceof File) {
          const currentLogoImage = brandDocData.get('logo');

          if (currentLogoImage) {
            await this.storageService.delete(currentLogoImage);
          }

          if (brandPayload.logo !== null) {
            const fileName = `file_${new Date().getTime()}`;
            const newLogoImage = await this.storageService.upload(
              brandPayload.logo,
              'brands',
              fileName,
            );
            brandPayload.logo = newLogoImage;
          } else {
            brandPayload.logo = null;
          }
        }

        if (brandPayload.banner instanceof File) {
          const { coverImages } = await this.themesService.getSystemAppearanceSettings();
          const currentBannerImage = brandDocData.get('banner');

          if (currentBannerImage && !coverImages.includes(currentBannerImage)) {
            await this.storageService.delete(currentBannerImage);
          }

          if (brandPayload.banner !== null) {
            const fileName = `file_${new Date().getTime()}`;
            const newCoverImage = await this.storageService.upload(
              brandPayload.banner,
              'brands',
              fileName,
            );
            brandPayload.banner = newCoverImage;
          } else {
            brandPayload.banner = null;
          }
        }
      }
      await brandDocument.update({ ...brandPayload });
      await this.algoliaService.updateBrand(brandPayload);

      return brandPayload;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async remove(id: string): Promise<boolean> {
    try {
      await this.firestore.collection(apiRoutes.brands).doc(id).delete();
      await this.algoliaService.removeBrand(id);

      return true;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getBrandEvent(eventId: string, brandId: string): Promise<IEventBrand> {
    try {
      const brandEvent: IEventBrand = (
        await this.firestore
          .collection(apiRoutes.eventBrands)
          .where('brandId', '==', brandId)
          .where('eventId', '==', eventId)
          .get()
      ).docs[0]?.data() as IEventBrand;

      return brandEvent;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getEventBrandById(eventBrandId: string): Promise<IEventBrand | undefined> {
    const eventBrandDoc: IDocumentSnapshot<IDocumentData> = await this.firestore
      .collection(apiRoutes.eventBrands)
      .doc(eventBrandId)
      .get();

    if (eventBrandDoc.exists) {
      return eventBrandDoc.data() as IEventBrand;
    }
  }

  async updateBrandEventEntry(
    brandEventId: string,
    tags: string[],
    level: string,
  ): Promise<boolean> {
    try {
      await this.firestore
        .collection(apiRoutes.eventBrands)
        .doc(brandEventId)
        .update({ tags, level });

      return true;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  updateBrandEventTags(brandEventId: string, tags: string[]): Promise<void> {
    return this.firestore.collection(apiRoutes.eventBrands).doc(brandEventId).update({ tags });
  }

  private sortBrandsByName(a: IBrand, b: IBrand, order: 'asc' | 'desc'): number {
    const nameA = order === 'asc' ? a._name_ : b._name_;
    const nameB = order === 'asc' ? b._name_ : a._name_;

    return nameA.localeCompare(nameB);
  }

  async getBrandsByUserId(userId: string): Promise<IBrand[]> {
    try {
      const brands: IBrand[] = (
        await this.firestore.collection(apiRoutes.brands).where('user', '==', userId).get()
      ).docs.map((doc) => doc.data() as IBrand);

      return brands;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }
}

type MySearchResponse = {
  hits: Array<{
    id: string;
    name: string;
  }>;
};
