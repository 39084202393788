import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable, Subject, takeUntil, tap } from 'rxjs';
import { DialogService } from 'primeng/dynamicdialog';

import { ICourse, IUser } from 'src/app/core/models';
import { SharedModule } from 'src/app/shared';
import { CourseUserDialogComponent } from 'src/app/course/dialogs/course-user-dialog/course-user-dialog.component';
import { defaultDialogConfig } from 'src/app/core/utils';
import { RegisteredCourseStore } from 'src/app/core/stores';

@Component({
  selector: 'app-registered-course-overview',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './registered-course-overview.component.html',
  styleUrls: ['./registered-course-overview.component.scss'],
})
export class RegisteredCourseOverviewComponent implements OnInit, OnDestroy {
  responsiveOptions = [
    {
      breakpoint: '1920px',
      numVisible: 3,
      numScroll: 1,
    },
    {
      breakpoint: '460px',
      numVisible: 2,
      numScroll: 1,
    },
  ];
  course: Observable<ICourse>;
  instructors: IUser[];

  private unsubscribe$ = new Subject<void>();

  constructor(
    private dialogService: DialogService,
    private registeredCourseStore: RegisteredCourseStore,
  ) {}

  ngOnInit(): void {
    this.course = this.registeredCourseStore.course;
    this.registeredCourseStore.courseInstructors
      .pipe(
        tap((courseInstructors: IUser[]) => {
          this.instructors = courseInstructors;
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();
  }

  onOpenInstructor(user: IUser): void {
    this.dialogService.open(
      CourseUserDialogComponent,
      Object.assign(defaultDialogConfig(), {
        data: { user },
      }),
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
