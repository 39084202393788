export type IEventMenuOption =
  | IEventMenuOptionDivider
  | IEventMenuOptionLink
  | IEventMenuOptionGroup
  | IEventCustomPageMenuOptionGroup;

interface IEventMenuOptionBase {
  id: string;
  name?: string;
  visible: boolean;
  type: 'link' | 'divider' | 'group' | 'custom-page';
}

export interface IEventMenuOptionDivider extends IEventMenuOptionBase {
  type: 'divider';
}

export interface IEventMenuOptionLink extends IEventMenuOptionBase {
  type: 'link';
  key: string;
}

export interface IEventMenuOptionGroup extends IEventMenuOptionBase {
  type: 'group';
  key: string;
  options: IEventMenuOptionLink[];
}

export interface IEventCustomPageMenuOptionGroup extends IEventMenuOptionBase {
  type: 'custom-page';
  customIconClass: string;
  key: string;
  pageId: string;
}

export type IEventMenuItem =
  | IEventMenuItemDivider
  | IEventMenuItemLink
  | IEventMenuItemGroup
  | IEventCustomPagetMenuItemGroup;

interface IEventMenuItemBase {
  name: string;
  url?: string;
  iconClass: string;
  clickAction?: () => void;
}

export interface IEventMenuItemDivider extends IEventMenuItemBase {
  type: 'divider';
}

export interface IEventMenuItemLink extends IEventMenuItemBase {
  type: 'link';
  key: string;
}

export interface IEventMenuItemGroup extends IEventMenuItemBase {
  type: 'group';
  key: string;
  items: IEventMenuItemLink[];
}

export interface IEventCustomPagetMenuItemGroup extends IEventMenuItemBase {
  type: 'custom-page';
  key: string;
  pageId?: string;
}
