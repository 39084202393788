import firebase from 'firebase/compat/app';
import { IUser } from '../user/user.model';
import { IBrand } from './brand.model';

export interface IBrandMember {
  id: string;
  active: boolean;
  brandId: string;
  userId: string;
  displayEmail: string;
  email: string;
  role: string;
  _firstName_: string;
  _lastName_: string;
  _company_: string;
  _position_: string;
  _brand_name_: string;
  createdAt: any;
  createdBy: string;
  updatedAt: any;
  updatedBy: string;
  brandRef?: IBrand;
  userRef?: IBrand;
}

export class BrandMember {
  static create(brand: IBrand, member: IUser, sessionUser: IUser): Partial<IBrandMember> {
    return {
      active: true,
      brandId: brand.id,
      userId: member.id,
      displayEmail: member.email || '-',
      email: member.email.toLowerCase() || '-',
      _firstName_: member.firstName || '-',
      _lastName_: member.lastName || '-',
      _company_: member.company || '-',
      _position_: member.position || '-',
      _brand_name_: brand.name,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      createdBy: sessionUser.id,
      updatedAt: null,
      updatedBy: null,
    };
  }
}
