import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import { autorun, IReactionDisposer } from 'mobx';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FilterMatchMode, MenuItem, MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

import {
  ConfirmDialogComponent,
  IAdminTableFilter,
  IAdminTableFilterChange,
  IAdminTableColumn,
} from 'src/app/shared';
import {
  ICourse,
  ICourseTicket,
  IEmail,
  IHub,
  ITag,
  IUser,
  IUserCourse,
} from 'src/app/core/models';
import { CoursesStore, HubsStore } from 'src/app/core/stores';
import {
  CoursesService,
  TagsService,
  UsersService,
  CourseSubscribersService,
  HubsService,
  UserCoursesService,
  TicketsService,
  OpenDialogService,
  EmailsService,
  CourseTrackingService,
} from 'src/app/core/services';
import { asyncDelay, capitalizeFirstLetter, getUserNameAbbreviation } from 'src/app/core/utils';
import { ButtonSize, ButtonStyle, ButtonType } from 'src/app/standalone';
import {
  AttachSubscribersDialogComponent,
  NewUserComponent,
  SelectEmailForEventUserComponent,
} from '../../../dialogs';

interface IUserCourseSubscriber extends IUserCourse {
  email: string;
  ticketName?: string;
}

@Component({
  selector: 'app-course-subscribers',
  templateUrl: './course-subscribers.component.html',
  styleUrls: ['./course-subscribers.component.scss'],
})
export class CourseSubscribersComponent implements OnInit, OnDestroy {
  loading = true;
  loadingEntries = true;
  columns: IAdminTableColumn[] = [
    {
      field: '_firstName_',
      titleTranslationKey: 'adminEventAttendees.tableCells.firstName',
      sortable: true,
    },
    {
      field: '_lastName_',
      titleTranslationKey: 'adminEventAttendees.tableCells.lastName',
      sortable: true,
    },
    { field: 'email', titleTranslationKey: 'adminEventAttendees.tableCells.email', sortable: true },
    // TODO hide for now, uncomment later when we can show the correct company name
    // { field: '_company_', titleTranslationKey: 'adminEventAttendees.tableCells.company', sortable: true },
    {
      field: 'ticketName',
      titleTranslationKey: 'adminEventAttendees.tableCells.ticket',
      filterable: true,
      filterMatchMode: FilterMatchMode.IN,
      filterOptions: null,
      filterType: 'text',
    },
    {
      field: 'status',
      titleTranslationKey: 'adminEventAttendees.tableCells.status',
      template: 'statusCell',
      filterable: true,
      filterMatchMode: FilterMatchMode.IN,
      filterOptions: [
        { name: 'Active', value: 'active' },
        { name: 'Inactive', value: 'inactive' },
      ],
      filterType: 'text',
    },
    {
      field: 'createdAtString',
      titleTranslationKey: 'adminEventAttendees.tableCells.timeStamp',
      sortable: true,
    },
    { field: 'actions', template: 'actionsCell' },
  ];
  entries: IUserCourseSubscriber[] = [];
  allEntries: IUserCourseSubscriber[];
  selectedEntries: IUserCourseSubscriber[] = [];
  totalRecords = 0;
  pageSize = 10;
  tags: ITag[] = [];
  tableFilters: IAdminTableFilter[] = [];
  searchTerm = '';
  searchFilterFields: string[] = ['name', '_firstName_', '_lastName_', 'email', '_company_'];
  imageText = getUserNameAbbreviation;
  selectedTags: string[] = [];
  sidebarVisible = false;
  userIdForSidebar: string;
  courseTickets: ICourseTicket[];
  btnListItems: MenuItem[] = [
    {
      label: this.translateService.instant('adminSubscribers.addBtn'),
      icon: 'far fa-plus',
      command: () => {
        this.openNewSubscriberDialog();
      },
    },
    {
      label: this.translateService.instant('adminSubscribers.attachSubscribers'),
      icon: 'fa-regular fa-user-plus',
      command: () => {
        this.openAttachSubscribersDialog();
      },
    },
  ];
  showExportSidebar = false;
  buttonSize = ButtonSize;
  buttonType = ButtonType;
  buttonStyle = ButtonStyle;

  private currentPage = 0;
  private disposer: IReactionDisposer;

  constructor(
    public hubStore: HubsStore,
    private router: Router,
    private route: ActivatedRoute,
    private subscribersService: CourseSubscribersService,
    private tagService: TagsService,
    private dialogService: DialogService,
    private usersService: UsersService,
    private courseService: CoursesService,
    private courseStore: CoursesStore,
    private messageService: MessageService,
    private translateService: TranslateService,
    private hubsStore: HubsStore,
    private hubsService: HubsService,
    private userCoursesService: UserCoursesService,
    private ticketsService: TicketsService,
    private openDialogService: OpenDialogService,
    private emailsService: EmailsService,
    private courseTrackingService: CourseTrackingService,
  ) {}

  get course(): ICourse {
    return this.courseStore.adminCourse;
  }

  async ngOnInit(): Promise<void> {
    this.disposer = autorun(async () => {
      if (this.course) {
        this.loadingEntries = true;
        this.loading = true;
        await Promise.all([this.fetchSubscribers(), this.getTags()]);
        this.loadingEntries = false;
        this.loading = false;

        this.tableFilters = [
          {
            key: 'TAGS',
            label: 'adminUsers.tagsFilterLabel',
            options: this.tags.map((t) => ({ label: t.title, value: t.id })),
          },
        ];
        this.updateTicketNameFilter();
      }
    });
  }

  async filtersChange(e: IAdminTableFilterChange[]): Promise<void> {
    this.loadingEntries = true;
    this.selectedTags = e.filter((f) => f.filterKey === 'TAGS').map((f) => f.optionKey);

    if (this.selectedTags.length) {
      const filteredEntries = this.allEntries.filter((b) =>
        b.tags?.some((r) => this.selectedTags.indexOf(r) >= 0),
      );
      this.entries = filteredEntries;
    } else {
      this.entries = this.allEntries;
    }

    await asyncDelay(100);
    this.loadingEntries = false;
  }

  openAttachSubscribersDialog(): void {
    this.dialogService
      .open(AttachSubscribersDialogComponent, {
        width: '90%',
        height: '80%',
        closable: false,
      })
      .onClose.subscribe(async () => await this.fetchSubscribers());
  }

  async onSearchTermChange(e: string): Promise<void> {
    this.loadingEntries = true;
    this.searchTerm = e;
    await this.fetchSubscribers();
    this.loadingEntries = false;
  }

  async fetchEntries(event: any): Promise<void> {
    this.loadingEntries = true;
    this.pageSize = event.rows;
    this.currentPage = event.first / this.pageSize;
    await this.fetchSubscribers();
    this.loadingEntries = false;
  }

  async sendInvites(): Promise<void> {
    try {
      const selectEmailDialogRef: DynamicDialogRef = this.dialogService.open(
        SelectEmailForEventUserComponent,
        {
          width: '90%',
          height: '80%',
          dismissableMask: true,
          closable: false,
          contentStyle: {
            padding: '0',
            'border-radius': '20px',
          },
          data: {
            userType: 'subscriber',
          },
        },
      );
      const email: IEmail = await firstValueFrom(selectEmailDialogRef.onClose.pipe(take(1)));

      if (!email) {
        return;
      }

      const deleteDialogRef: DynamicDialogRef = this.openDialogService.openConfirmDialog(
        'adminEventAttendees.sendUserInvitesDialog.header',
        this.translateService.instant('adminEventAttendees.sendUserInvitesDialog.message', {
          totalUsers: this.selectedEntries.length,
        }),
        'buttons.confirm',
        'buttons.cancel',
      );
      const result: 'cancel' | 'confirm' = await firstValueFrom(
        deleteDialogRef.onClose.pipe(take(1)),
      );

      if (result !== 'confirm') {
        return;
      }

      await Promise.all(
        this.selectedEntries.map((subscriber: IUserCourseSubscriber) =>
          this.emailsService.sendInviteWithEmailTemplate(
            this.course.id,
            subscriber.userId,
            email.id,
            'course',
          ),
        ),
      );

      this.showToastMessage('success', 'adminEventAttendees.sendUserInvitesSuccessMessage');
    } catch (error) {
      this.showToastMessage('error', 'adminEventAttendees.sendUserInvitesErrorMessage');
    }
  }

  async openNewSubscriberDialog(): Promise<void> {
    let hub: IHub = this.hubsStore.hub;

    if (!hub) {
      hub = await this.hubsService.getOne(this.course.hubId);
    }
    const newSubscriberDialog: DynamicDialogRef = this.dialogService.open(NewUserComponent, {
      styleClass: 'attach-new-attendee-subscriber-dialog',
      closable: false,
      data: {
        userType: 'subscriber',
        course: this.course,
        hub,
      },
    });

    const id: string = await newSubscriberDialog.onClose.pipe(take(1)).toPromise();
    if (id) {
      this.router.navigate([
        `/${this.hubStore.useHubUrl}/admin/courses/${this.course.link}/subscribers/${id}/profile`,
      ]);
    } else {
      await this.fetchSubscribers();
    }
  }

  onCloseSidebar(): void {
    this.sidebarVisible = false;
  }

  async onRowClick(id: string): Promise<void> {
    const userCourse = await this.userCoursesService.getUserCourseById(id);
    if (this.hubsStore.hub.isShowSidePanel) {
      this.sidebarVisible = true;
      this.userIdForSidebar = userCourse.userId;

      return;
    }

    this.router.navigate([`${userCourse.userId}`], { relativeTo: this.route });
  }

  toggleExportSidebar(): void {
    this.showExportSidebar = !this.showExportSidebar;
  }

  navigateToSubscriber(event: Event, uc: IUserCourseSubscriber): void {
    event.stopPropagation();
    if (this.hubsStore.hub.isShowSidePanel) {
      this.sidebarVisible = true;
      this.userIdForSidebar = uc.userId;

      return;
    }

    this.router.navigate([`${uc.userId}`], { relativeTo: this.route });
  }

  async unAttachSubscriber(event: Event, entry: IUserCourseSubscriber): Promise<void> {
    event.stopPropagation();
    const deleteDialogRef: DynamicDialogRef = this.dialogService.open(ConfirmDialogComponent, {
      closable: false,
      styleClass: 'confirm-dialog',
      data: {
        titleKey: 'adminUsers.deleteUser',
        descriptionKey: 'adminUsers.deleteUserText',
        confirmBtnKey: 'adminUsers.confirmBtn',
        cancelBtnKey: 'adminUsers.cancelBtn',
        entry,
      },
    });
    const result: 'cancel' | 'confirm' = await firstValueFrom(deleteDialogRef.onClose);
    if (result === 'confirm') {
      await this.courseService.unAttachUser(this.course.id, entry.userId, 'subscriber');
      await this.courseTrackingService.deleteByCourseAndUserInfo(this.course.id, entry.userId);
      this.loadingEntries = true;
      await this.fetchSubscribers();
      this.loadingEntries = false;
    }
  }

  ngOnDestroy(): void {
    if (this.disposer) {
      this.disposer();
    }
  }

  private async fetchSubscribers(): Promise<void> {
    const userCourses: IUserCourse[] = await this.subscribersService.getAllCourseSubscribers(
      this.course.id,
    );
    const users: IUser[] = await Promise.all(
      userCourses.map((userCourse: IUserCourse) => this.usersService.getOne(userCourse.userId)),
    );
    const courseSubscribers: IUserCourseSubscriber[] = userCourses.map(
      (courseSubscriber: IUserCourse) => {
        const currentUser: IUser = users.find((user: IUser) => courseSubscriber.userId === user.id);
        return {
          ...courseSubscriber,
          _firstName_: capitalizeFirstLetter(courseSubscriber._firstName_),
          _lastName_: capitalizeFirstLetter(courseSubscriber._lastName_),
          email: currentUser.email,
          ticketName: currentUser?.ticket?.name,
          name: `${capitalizeFirstLetter(courseSubscriber._firstName_).trim()} ${capitalizeFirstLetter(courseSubscriber._lastName_).trim()}`,
        };
      },
    );

    this.allEntries = courseSubscribers;
    this.updateTicketNames();
    this.entries = this.allEntries;
    this.totalRecords = courseSubscribers.length;
  }

  private updateColumns(): void {
    this.columns = this.columns.map((column: IAdminTableColumn) => {
      if (column.field === 'ticketName') {
        const ticketNames = this.allEntries
          .filter((entry: IUserCourseSubscriber) => entry?.ticketName)
          .reduce((names: string[], entry: IUserCourseSubscriber) => {
            if (!names.includes(entry.ticketName)) {
              names.push(entry.ticketName);
            }
            return names;
          }, []);

        return ticketNames.length
          ? {
              ...column,
              filterOptions: ticketNames.map((ticketName: string) => ({
                name: ticketName,
                value: ticketName,
              })),
            }
          : column;
      }

      return column;
    });
  }

  private async updateTicketNames(): Promise<void> {
    for (const entry of this.allEntries) {
      if (entry.ticketId) {
        try {
          const ticketInfo = await this.ticketsService.getOneCourseTicket(
            entry.courseId,
            entry.ticketId,
          );
          // Replace 'name' with the actual property from your ticketInfo object that holds the ticket name
          entry.ticketName = ticketInfo.name;
        } catch (error) {
          console.error('Error fetching ticket info:', error);
        }
      }
    }
  }

  private async getTags(): Promise<void> {
    this.tags = await this.tagService.getByIdsOrderedByTitle(this.course.tags);
  }

  private async updateTicketNameFilter(): Promise<void> {
    this.courseTickets = await this.ticketsService.getCourseTickets(this.course.id);
    const ticketNameFilterOptions = this.courseTickets.map((ticket) => ({
      name: ticket.name,
      value: ticket.name,
    }));
    const ticketNameColumn = this.columns.filter((c) => c.field === 'ticketName')[0];
    ticketNameColumn.filterOptions = ticketNameFilterOptions;
  }

  private showToastMessage(severity: 'success' | 'error', message: string): void {
    this.messageService.add({
      severity,
      summary: this.translateService.instant(severity),
      detail: this.translateService.instant(message),
      styleClass: 'custom-toast',
    });
  }
}
