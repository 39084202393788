import { parseToMoment } from '../../../shared/helpers/date-mapper';
import { UsersStore } from 'src/app/core/stores/users/users.store';
import { ServerTimestamp } from './../../../firebase';
import { AlgoliaSearchResult } from './../../models/algolia/algolia-search-result.model';
import { AlgoliaService } from './../algolia/algolia.service';
import { Firestore, IFirestore } from '../../../firebase';
import { ITag } from 'src/app/core/models/tag/tag.model';
import { TagsStore } from '../../stores';
import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class TagsService {
  private firestore: IFirestore;
  private tagsCollection = 'tags';
  private hubTagsCollection = 'hubTags';

  constructor(
    private tagsStore: TagsStore,
    private algoliaService: AlgoliaService,
    private usersStore: UsersStore,
  ) {
    this.firestore = Firestore();
  }

  public async fetch(
    pageIndex: number,
    pageSize: number,
    searchTerm: string,
  ): Promise<AlgoliaSearchResult<ITag>> {
    const res = await this.algoliaService.search<ITag>(
      'tags',
      searchTerm,
      pageSize,
      pageIndex * pageSize,
    );

    return {
      total: res.total,
      results: res.results.map((r) => ({
        timeStamp: r.createdAt ? moment(r.createdAt).format('ll') : '-',
        updateTimeStamp: r.updatedAt ? moment(r.updatedAt).format('ll') : '-',
        ...r,
      })),
    };
  }

  public async get(tag: ITag = null, pageSize: number = 20): Promise<ITag[]> {
    const tags: ITag[] = [];

    const query = await this.firestore
      .collection(this.tagsCollection)
      .orderBy('title')
      .startAfter(tag ? tag.title : 0)
      .limit(pageSize)
      .get();

    if (!query.empty) {
      query.forEach((x) => {
        tags.push(x.data() as ITag);
      });

      return tags;
    } else {
      return null;
    }
  }

  public async getByIdsOrderedByTitle(ids: string[], forceSync: boolean = false): Promise<ITag[]> {
    if (!ids || ids.length === 0) {
      return [];
    }

    const passedTags = [...ids];

    const storeTags = Object.keys(this.tagsStore.tagsMap);
    if (passedTags.length > 0 && passedTags.every((id) => storeTags.includes(id))) {
      return this.tagsStore.tags.filter((tag) => ids.includes(tag.id));
    }

    try {
      // const tags = await this.httpService.post<ITag[]>("tags", { tagIds: ids });
      const tags = [];
      let query;

      while (passedTags.length > 0) {
        const chunk = passedTags.splice(0, 10);

        query = await this.firestore
          .collection(this.tagsCollection)
          .where('id', 'in', chunk)
          .orderBy('_title_')
          .get();

        if (!query.empty) {
          query.forEach((doc) => tags.push(doc.data()));
        }
      }

      this.tagsStore.setTags(tags);
      return tags;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  public async getHubTagsIdsArray(hubId: string = null): Promise<string[]> {
    return (
      await this.firestore
        .collection(this.hubTagsCollection)
        .where('hubId', '==', hubId)
        .orderBy('_tagTitle_')
        .get()
    ).docs.map((doc) => doc.data().tagId);
  }

  public async getOne(id: string): Promise<ITag> {
    return null;
  }

  public async getAll(forceSync: boolean = false): Promise<ITag[]> {
    // if (!forceSync && this.tagsStore.tags.length) {return this.tagsStore.tags;}

    try {
      const tagsQuery = await this.firestore
        .collection(this.tagsCollection)
        .orderBy('_title_')
        .get();
      const tags = [];

      if (tagsQuery.size > 0) {
        tagsQuery.forEach((doc) => {
          const tag = doc.data();

          tag.timeStamp = tag.createdAt ? parseToMoment(tag.createdAt).format('ll') : '-';
          tag.updateTimeStamp = tag.updatedAt ? parseToMoment(tag.updatedAt).format('ll') : '-';

          tags.push(tag);
        });
      }

      if (!this.tagsStore.tags.length || forceSync) {
        this.tagsStore.setTags(tags);
      }

      return tags as ITag[];
    } catch (error) {
      return [];
    }
  }

  public async getAllOrderedByTitle(forceSync: boolean = false): Promise<ITag[]> {
    // if (!forceSync && this.tagsStore.tags.length) {return this.tagsStore.tags;}

    try {
      const tagsQuery = await this.firestore
        .collection(this.tagsCollection)
        .orderBy('_title_')
        .get();
      const tags = [];

      if (tagsQuery.size > 0) {
        tagsQuery.forEach((doc) => {
          const tag = doc.data();

          tag.timeStamp = tag.createdAt ? parseToMoment(tag.createdAt).format('ll') : '-';
          tag.updateTimeStamp = tag.updatedAt ? parseToMoment(tag.updatedAt).format('ll') : '-';

          tags.push(tag);
        });
      }

      if (!this.tagsStore.tags.length || forceSync) {
        this.tagsStore.setTags(tags);
      }

      return tags as ITag[];
    } catch (error) {
      return [];
    }
  }

  public async create(tagTitle: string): Promise<ITag> {
    try {
      const preTagReq = await this.firestore.collection(this.tagsCollection).doc();
      const newTag = {
        id: preTagReq.id,
        title: tagTitle,
        _title_: tagTitle.toLowerCase(),
        updatedAt: null,
        updatedBy: null,
        createdAt: ServerTimestamp(),
        createdBy: this.usersStore.user.id,
      };
      await this.firestore
        .collection(this.tagsCollection)
        .doc(newTag.id)
        .set({ ...newTag });
      return newTag;
    } catch (error) {
      console.error(error);

      return null;
    }
  }

  public async update(id: string, payload: any) {
    try {
      const tagDocument = this.firestore.collection(this.tagsCollection).doc(id);
      await tagDocument.update({ ...payload });
      return payload;
    } catch (error) {
      throw error;
    }
  }

  public async remove(tagDocId: string): Promise<boolean> {
    try {
      await this.firestore.collection(this.tagsCollection).doc(tagDocId).delete();
      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }
}
