import { IUser, IHubBrand, IHub } from 'src/app/core/models';
import { Timestamp } from 'src/app/firebase';

export interface IBrand {
  id: string;
  name: string;
  level?: string;
  _name_: string;
  user: string;
  team: string[];
  slogan: string;
  description: string;
  banner: string;
  street: string;
  addressLineSecond: string;
  zipCode: string;
  city: string;
  country: string;
  logo: string;
  website: string;
  linkedin: string;
  facebook: string;
  twitter: string;
  instagram: string;
  youtube: string;
  xing: string;
  calendly: string;
  featuredBrand: boolean;
  tags: string[];
  email: string;
  acceptTerms: boolean;
  acceptNews: boolean;
  pendingUsers: string[];
  users: string[];
  updatedAt: Timestamp;
  updatedBy: string;
  createdAt: Timestamp;
  createdBy: string;
  itemsLeft?: number; // TODO check if really needed;
  link?: string;
  docRef?: any;
  owner?: IUser;
  eventBrandId?: string;
  hub?: IHub | IHubBrand;
  ownerName?: string;
  ownerDisplayEmail?: string;
  ownerEmail?: string;
}
