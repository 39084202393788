import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartConfiguration, ChartOptions, ChartType } from 'chart.js';

import {
  StatCardComponent,
  StatChartComponent,
  StatSingleProgressBarComponent,
} from 'src/app/standalone/shared';
import { trackByFn } from 'src/app/core/utils';
import { SharedModule } from 'src/app/shared';

@Component({
  selector: 'app-event-reports',
  standalone: true,
  imports: [
    CommonModule,
    StatChartComponent,
    StatCardComponent,
    StatSingleProgressBarComponent,
    SharedModule,
  ],
  templateUrl: './event-reports.component.html',
  styleUrls: ['./event-reports.component.scss'],
})
export class EventReportsComponent implements OnInit {
  trackByFn = trackByFn;
  statCards: { iconClass: string; text: string; value: string }[];
  statSingleProgressBarOptions: { text: string; value: string }[];
  lineChartData: ChartConfiguration<'line'>['data'];
  lineChartOptions: ChartOptions<'line'>;
  lineChartType: ChartType = 'line';

  ngOnInit(): void {
    this.statCards = [
      {
        iconClass: 'fa-regular fa-cart-shopping',
        text: 'Live Attendees',
        value: '15.200',
      },
      {
        iconClass: 'fa-regular fa-play',
        text: 'On-Demand',
        value: '15.200',
      },
      {
        iconClass: 'fa-regular fa-users',
        text: 'Registrants',
        value: '18.770',
      },
      {
        iconClass: 'fa-regular fa-timer',
        text: 'Pending Invites',
        value: '2.510',
      },
      {
        iconClass: 'fa-regular fa-eye',
        text: 'Views',
        value: '720.000',
      },
      {
        iconClass: 'fa-regular fa-arrow-pointer',
        text: 'Clicks',
        value: '520.000',
      },
    ];

    this.statSingleProgressBarOptions = [
      { text: 'Total Attendee Interactions', value: '1.000' },
      { text: 'Total Session Attendance', value: '1.000' },
      { text: 'Total Networking Interactions', value: '1.000' },
      { text: 'Total Leads Generated', value: '1.000' },
      { text: 'Total Virtual Booth Visits', value: '1.000' },
      { text: 'Content Downloads', value: '1.000' },
    ];

    const documentStyle = getComputedStyle(document.documentElement);
    this.lineChartData = {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      datasets: [
        {
          data: [65, 59, 80, 81, 56, 55, 40],
          label: 'Series A',
          fill: true,
          tension: 0.5,
          borderColor: documentStyle.getPropertyValue('--appPrimaryColor'),
          backgroundColor: 'rgba(255,0,0,0.3)',
        },
      ],
    };

    this.lineChartOptions = {
      maintainAspectRatio: false,
    };
  }
}
