import { InviteTypes } from 'src/app/core/enums';

export interface IUserRegistrationInvite {
  id: string;
  token: string;
  userId: string;
  displayEmail: string;
  email: string;
  firstName: string;
  lastName: string;
  company: string;
  eventId?: string;
  courseId?: string;
  tempPassword?: string;
  type?: InviteTypes;
  checkoutSessionId?: string;
  tenantId?: string;
}
