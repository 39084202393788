<div
  #productsslider
  (mousedown)="onMouseDown($event)"
  (mouseleave)="onMouseLeave()"
  (mousemove)="onMouseMove($event)"
  (mouseup)="onMouseUp()"
  [ngClass]="{
    'single-row': showAsSingleRow,
    'admin-theme': adminTheme,
  }"
  class="products-list-container flex">
  <div
    [id]="'item-' + product.id"
    *ngFor="let product of possibleProducts"
    [ngClass]="{ selected: isProductSelected(product), clickable: edit }"
    class="product-item">
    {{ product.title }}
  </div>
</div>
