import { Timestamp } from 'src/app/firebase';

export interface IUserCourseTracking {
  id: string;
  courseId: string;
  assetId: string;
  userId: string;
  percentageViewed: number;
  secondsViewed: number;
  isWatched: boolean;
  videoLink: string;
  createdAt?: Timestamp;
  createdBy?: string;
  updatedAt?: Timestamp;
  updatedBy?: string;
}
