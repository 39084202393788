<div class="stripe-information-dialog-container">
  <h1>{{ 'connectStripeDialog.title' | translate }}</h1>
  <button
    *ngIf="isInstanceOwner"
    class="primary-text-color p-button"
    pButton
    type="button"
    [label]="'connectStripeDialog.connectStripeBtn' | translate"
    (click)="onConnectStripe()"></button>
  <div *ngIf="!isInstanceOwner">
    <button
      class="primary-text-color p-button"
      pButton
      type="button"
      [label]="'connectStripeDialog.connectStripeBtn' | translate"
      [disabled]="true"
      pTooltip="{{ 'adminHub.stripeTooltip' | translate }}"
      tooltipPosition="right"
      tooltipStyleClass="custom-tooltip"
      (click)="onConnectStripe()"></button>
  </div>
  <div class="description">{{ 'connectStripeDialog.description' | translate }}</div>
  <h2>{{ 'connectStripeDialog.whyCreateAccount' | translate }}</h2>
  <div class="reasons-container">
    <div class="reason-item">
      <i class="far fa-check"></i
      ><span class="c-ml-4">{{ 'connectStripeDialog.easyToSetup' | translate }}</span>
    </div>
    <div class="reason-item">
      <i class="far fa-check"></i
      ><span class="c-ml-4">{{ 'connectStripeDialog.noSetupCost' | translate }}</span>
    </div>
    <div class="reason-item">
      <i class="far fa-check"></i
      ><span class="c-ml-4">{{ 'connectStripeDialog.noMinimumCharges' | translate }}</span>
    </div>
  </div>
</div>
