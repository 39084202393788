import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgChartsModule } from 'ng2-charts';
import { ChartConfiguration, ChartType, DefaultDataPoint } from 'chart.js';

import { SharedModule } from 'src/app/shared';

@Component({
  selector: 'app-stat-chart',
  standalone: true,
  imports: [CommonModule, SharedModule, NgChartsModule],
  templateUrl: './stat-chart.component.html',
  styleUrls: ['./stat-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatChartComponent<
  TType extends ChartType = ChartType,
  TData = DefaultDataPoint<TType>,
  TLabel = unknown,
> {
  @Input() statName: string;
  @Input() tooltipText: string;
  @Input() tooltipPosition: 'right' | 'left' | 'top' | 'bottom' = 'right';
  @Input() tooltipStyleClass: string = 'custom-tooltip';
  @Input() tooltipEvent: 'hover' | 'focus' = 'hover';
  @Input() tabIndex: number;
  @Input() chartType: ChartConfiguration<TType, TData, TLabel>['type'];
  @Input() chartData: ChartConfiguration<TType, TData, TLabel>['data'];
  @Input() chartOptions: ChartConfiguration<TType, TData, TLabel>['options'];
  @Input() chartLegend: boolean;
}
