import { Injectable } from '@angular/core';

import { Firestore, IDocumentData, IFirestore, IQuerySnapshot } from 'src/app/firebase';
import { API_ROUTES as apiRoutes } from 'src/app/shared';
import { IUserEvent } from 'src/app/core/models';

@Injectable({
  providedIn: 'root',
})
export class UserEventsService {
  private firestore: IFirestore;

  constructor() {
    this.firestore = Firestore();
  }

  async getUserEventsByUserId(userId: string): Promise<IUserEvent[]> {
    try {
      const userEvents: IUserEvent[] = (
        await this.firestore.collection(apiRoutes.userEvents).where('userId', '==', userId).get()
      ).docs.map((doc) => doc.data() as IUserEvent);

      return userEvents;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async isAttendee(eventId: string, userId: string): Promise<boolean> {
    const eventAttendeeQuery: IQuerySnapshot<IDocumentData> = await this.firestore
      .collection(apiRoutes.userEvents)
      .where('eventId', '==', eventId)
      .where('userId', '==', userId)
      .where('role', '==', 'attendee')
      .get();

    return !eventAttendeeQuery.empty;
  }

  async deleteUserEvent(userEventId: string): Promise<boolean> {
    try {
      await this.firestore.collection(apiRoutes.userEvents).doc(userEventId).delete();

      return true;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }
}
