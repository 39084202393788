import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

import { IBrand, ICourse, ITag } from 'src/app/core/models';
import { BrandsService } from 'src/app/core/services';
import { CoursesStore, TagsStore } from 'src/app/core/stores';
import { IAdminTableColumn, imgPreloader, logoAvatar } from 'src/app/shared';
import { AppStore } from 'src/app/app.store';
import { ButtonSize } from 'src/app/standalone';

@Component({
  templateUrl: './attach-course-brands-dialog.component.html',
  styleUrls: ['./attach-course-brands-dialog.component.scss'],
})
export class AttachCourseBrandsDialogComponent implements OnInit {
  @ViewChild('filterSearch') filterSearch: ElementRef<HTMLInputElement>;

  attachLoading = false;
  loadingEntries = true;
  entries: IBrand[] = [];
  selectedEntries: IBrand[] = [];
  totalRecords = 0;
  pageSize = 10;
  tags: ITag[] = [];
  selectedTags: ITag[] = [];
  columns: IAdminTableColumn[] = [
    {
      field: 'name',
      template: 'nameCell',
      titleTranslationKey: 'adminAttachCourseBrand.tableColName',
    },
    { field: 'ownerName', titleTranslationKey: 'adminAttachCourseBrand.tableColBrandOwner' },
    { field: 'ownerEmail', titleTranslationKey: 'adminAttachCourseBrand.tableColOwnerEmail' },
  ];
  imgPreloader = imgPreloader;
  logoAvatar = logoAvatar;
  buttonSize = ButtonSize;

  private brandSearchTerm = '';
  private tagSearchTerm = '';
  private page = 0;

  constructor(
    private brandService: BrandsService,
    private courseStore: CoursesStore,
    private tagStore: TagsStore,
    private messageService: MessageService,
    private translateService: TranslateService,
    public appStore: AppStore,
    private ref: DynamicDialogRef,
  ) {}

  get filteredTags(): ITag[] {
    return this.tags.filter((tag) =>
      tag.title.toLowerCase().includes(this.tagSearchTerm.toLowerCase()),
    );
  }

  get course(): ICourse {
    return this.courseStore.adminCourse;
  }

  async ngOnInit(): Promise<void> {
    await Promise.all([this.fetchBrands()]);
    this.tags = this.tagStore.tags.filter((tag) => this.course?.tags?.includes(tag.id));
    this.loadingEntries = false;
  }

  tagSearchChanged(value: string): void {
    this.tagSearchTerm = value;
  }

  async brandSearchChanged(value: string): Promise<void> {
    this.loadingEntries = true;
    this.brandSearchTerm = value;
    await this.fetchBrands();
    this.loadingEntries = false;
  }

  async fetchEntries(event: any): Promise<void> {
    this.loadingEntries = true;
    this.pageSize = event.rows;
    this.page = event.first / this.pageSize;
    await this.fetchBrands();
    this.loadingEntries = false;
  }

  async attach(): Promise<void> {
    this.attachLoading = true;
    await Promise.all(
      this.selectedEntries.map((brand: IBrand) =>
        this.brandService.addBrandToCourse(this.course, brand.id),
      ),
    );
    this.messageService.add({
      key: 'user',
      severity: 'success',
      summary: this.translateService.instant('success'),
      detail: this.translateService.instant('adminAttachCourseBrand.brandSuccessfullyAttached', {
        count: this.selectedEntries.length,
      }),
      styleClass: 'custom-toast',
    });

    this.attachLoading = false;
    this.selectedEntries = [];
    await this.fetchBrands();
  }

  async tagSelectionChanged(tags: ITag[]): Promise<void> {
    this.selectedTags = tags;
    await this.fetchBrands();
  }

  onClearBrandsSearch(): void {
    this.filterSearch.nativeElement.value = '';
    this.brandSearchChanged('');
  }

  onCloseDialog(): void {
    this.ref.close();
  }

  private async fetchBrands(): Promise<void> {
    const response = await this.brandService.fetchUnattachedCourseBrands(
      this.course.hubId,
      this.course.id,
      this.page,
      this.pageSize,
      [...this.selectedTags.map((t) => t.id), this.brandSearchTerm].join(' '),
    );

    this.totalRecords = response.total;
    this.entries = response.results.map((brand: IBrand) => {
      if (brand.owner) {
        brand.ownerName = `${brand.owner.firstName} ${brand.owner.lastName}`;
        brand.ownerDisplayEmail = brand.owner.displayEmail
          ? brand.owner.displayEmail
          : brand.owner.email;
        brand.ownerEmail = brand.owner.email.toLowerCase();
      }

      return brand;
    });
  }
}
