import { Timestamp } from 'src/app/firebase';
import { ITag, IStage, IUser } from 'src/app/core/models';
import { RecordingTypes } from '../../enums';
import { AssetFileTypes } from 'src/app/core/enums';

export interface ISession {
  id: string;
  title: string;
  _title_: string;
  space: boolean;
  date: Timestamp;
  start: Timestamp;
  end: Timestamp;
  description: string;
  shortDescription: string;
  banner: File | string;
  stageId: string;
  speakers: string[];
  brands: string[];
  tags: string[];
  videolink: string;
  featuredSession: boolean;
  updatedAt: Timestamp | Date;
  updatedBy: string;
  createdAt: Timestamp | Date;
  createdBy: string;
  moderators?: string[];
  camera?: boolean;
  microphone?: boolean;
  screenSharing?: boolean;
  textChat?: boolean;
  isRecording?: boolean;
  recordingType?: RecordingTypes;
  isShowPeopleListing?: boolean;
  isPictureInPicture?: boolean;
  isHandRaising?: boolean;
  isEmojiReactions?: boolean;
  isBreakoutRooms?: boolean;
  maxParticipants?: number;
  ownerOnlyBroadcast?: boolean;
  meetingRoomUrl?: string;
  meetingRoomName?: string;
  isLiveStreaming?: boolean;
  isAllowOnlyModerators?: boolean;

  tagsList?: ITag[];
  speakersList?: IUser[];
  stages?: IStage[];
  stage?: IStage;
  stageName?: string;
  sessionDate?: Timestamp | Date;
  isSessionRegistration?: boolean;
  availability?: number;
  isClosingSessionRegistration?: boolean;
  closingDate?: Timestamp;
  sessionsRelation?: string[];
  isCancellingEnabled?: boolean;
}
