import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { EventsService, ThemesService, UsersService } from '../services';
import { UsersStore } from '../stores';
import { IEvent, IUserEvent } from '../models';
import { HubsStore } from 'src/app/core/stores';

@Injectable({
  providedIn: 'root',
})
export class PrivateSystemGuard {
  constructor(
    private usersStore: UsersStore,
    private usersService: UsersService,
    private themeService: ThemesService,
    private router: Router,
    private eventsService: EventsService,
    private hubsStore: HubsStore,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    const generalSystemSettings = await this.themeService.getSystemGeneralSettings();
    const eventId = route.params?.eventId;
    const eventUrl = route.params?.eventUrl;

    if (!generalSystemSettings.isPrivate) {
      return true;
    }

    let event: IEvent;

    if (eventUrl) {
      event = await this.eventsService.getByUrl(eventUrl);
      const eventRegistrationSettings = await this.eventsService.getEventRegisterSettings(event.id);

      if (eventRegistrationSettings.isEventCode) {
        return true;
      } else {
        this.router.navigate([`${this.hubsStore.useHubUrl}/signin`]);
        return false;
      }
    }
    if (!this.usersStore.user) {
      if (eventId) {
        this.router.navigate([`${this.hubsStore.useHubUrl}/signin`], { state: { url: state.url } });
        return true;
      } else {
        this.router.navigate([`${this.hubsStore.useHubUrl}/signin`]);
        return false;
      }
    }

    let userEvent: IUserEvent;
    if (eventId) {
      userEvent = await this.usersService.getUserEvent(eventId, this.usersStore.user.id);
    }

    const freshUser = await this.usersService.getOne(this.usersStore.user.id);

    if (freshUser.role === 'user') {
      if (userEvent) {
        return true;
      }

      if (state.url.includes('dashboard')) {
        return true;
      } else {
        this.router.navigate(['/dashboard']);
        return true;
      }
    }

    return true;
  }
}
