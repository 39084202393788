import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { firstValueFrom } from 'rxjs';
import { DailyCall, DailyLanguageSetting } from '@daily-co/daily-js';
import { TranslateService } from '@ngx-translate/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { AppStore } from 'src/app/app.store';
import { DailyCoService, ChatService, IntercomService } from 'src/app/core/services';
import { UsersStore } from 'src/app/core/stores';
import { RecordingTypes } from 'src/app/core/enums';

@Component({
  selector: 'app-daily-co-base-room',
  standalone: true,
  imports: [CommonModule],
  template: '',
  styles: [''],
})
export class DailyCoBaseRoomComponent implements OnInit, OnDestroy {
  lang: DailyLanguageSetting;
  config: DynamicDialogConfig;
  ref: DynamicDialogRef;
  usersStore: UsersStore;
  daily: DailyCall;

  private dailyCoService: DailyCoService;
  private translateService: TranslateService;
  private chatService: ChatService;
  private intercomService: IntercomService;
  private appStore: AppStore;

  constructor(private injector: Injector) {
    this.config = injector.get(DynamicDialogConfig);
    this.dailyCoService = injector.get(DailyCoService);
    this.translateService = injector.get(TranslateService);
    this.ref = injector.get(DynamicDialogRef);
    this.chatService = injector.get(ChatService);
    this.intercomService = injector.get(IntercomService);
    this.usersStore = injector.get(UsersStore);
    this.appStore = injector.get(AppStore);
  }

  get userName(): string {
    return `${this.usersStore.user.firstName} ${this.usersStore.user.lastName}`;
  }

  ngOnInit(): void {
    this.intercomService.shutdown();
    this.lang = (this.translateService.currentLang as DailyLanguageSetting) || 'en';
  }

  createDailyIframeWrap(el: HTMLIFrameElement): void {
    this.daily = this.dailyCoService.createCallWrap(el, this.lang);
  }

  setDailyTheme(): void {
    this.dailyCoService.setTheme(this.daily);
  }

  startListeningEvents(): void {
    this.daily.on('left-meeting', () => {
      this.ref.close();
      this.chatService.updateChatState();
      this.intercomService.boot(this.appStore.environment.intercom.apiKey, this.usersStore.user);
    });
  }

  async getParticipantToken(roomName: string): Promise<string> {
    const { token } = await firstValueFrom(
      this.dailyCoService.createToken(roomName, this.userName, this.usersStore.userId),
    );

    return token;
  }

  async getAdminToken(roomName: string, isRecording: boolean, recordingType: RecordingTypes) {
    const { token } = await firstValueFrom(
      this.dailyCoService.createToken(
        roomName,
        this.userName,
        this.usersStore.userId,
        true,
        isRecording,
        recordingType,
      ),
    );

    return token;
  }

  openDaily(url: string, token: string): void {
    this.daily.join({ url, token });
    this.chatService.setChatState(false);
  }

  ngOnDestroy(): void {
    this.daily?.destroy();
    this.chatService.updateChatState();
    this.intercomService.boot(this.appStore.environment.intercom.apiKey, this.usersStore.user);
  }
}
