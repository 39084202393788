<div class="container">
  <div class="upload-block">
    <h5 class="title">{{ titleMediaForm | translate }}</h5>
    <p class="description">{{ description | translate }}</p>
    <div class="actions">
      <input
        class="input"
        type="file"
        #fileUpload
        (change)="onFileUpload($event)" />
      <div class="btn-actions-container">
        <app-button
          buttonText="{{ btnConfirm | translate }}"
          [buttonSize]="buttonSize.SMALL"
          [loading]="fileUploadProcess"
          (click)="fileUpload.click()" />
        <app-button
          buttonText="{{ btnCancel | translate }}"
          [buttonStyle]="buttonStyle.TERTIARY_SECONDARY"
          [buttonSize]="buttonSize.SMALL"
          (click)="clearFile()" />
      </div>
    </div>
  </div>
  <div class="preview-block">
    <ng-container *ngIf="!!fileType">
      <ng-container [ngSwitch]="fileType">
        <ng-container *ngSwitchCase="assetFileTypes.PDF">
          <i class="far fa-file-pdf default-icon"></i>
        </ng-container>
        <ng-container *ngSwitchCase="assetFileTypes.ZIP">
          <i class="far fa-file-archive default-icon"></i>
        </ng-container>
        <ng-container *ngSwitchCase="assetFileTypes.PNG">
          <img
            class="img uploaded-img"
            [src]="fileImage" />
        </ng-container>
        <ng-container *ngSwitchCase="assetFileTypes.JPG">
          <img
            class="img uploaded-img"
            [src]="fileImage" />
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="!fileType">
      <img
        class="img"
        [class.uploaded-img]="!!fileImage"
        [src]="fileImage ? fileImage : logoAvatar" />
    </ng-container>
  </div>
</div>
