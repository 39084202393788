<ng-container *ngIf="loading">
  <app-loader></app-loader>
</ng-container>

<div
  *ngIf="!loading"
  class="chapters-container">
  <header>
    <span class="page-title">{{ 'chapters.chapters' | translate }}</span>
    <app-button
      buttonText="{{ 'chapters.newChapter' | translate }}"
      buttonIconLeft="far fa-plus"
      [buttonSize]="buttonSize.MEDIUM"
      (click)="openNewChapterDialog()" />
  </header>

  <div class="c-my-4">
    <p-divider styleClass="custom-divider-horizontal"></p-divider>
  </div>

  <div
    *ngIf="chapters.length"
    class="chapters-list"
    cdkDropList
    (cdkDropListDropped)="drop($event)">
    <app-chapter-card
      *ngFor="let chapterItem of chapters; trackBy: trackByFn"
      [chapter]="chapterItem"
      (changeChapterStatus)="onChangeChapterStatus($event, chapterItem)"
      (editChapter)="onEditChapter($event)"
      (createAsset)="onCreateNewAsset($event)"
      (attachAsset)="onAttachAsset($event)"
      (removeChapter)="onRemoveChapter($event)"
      (changeAssetStatus)="onChangeAssetStatus($event, chapterItem)"
      (editAsset)="onEditAsset($event)"
      (removeAsset)="onRemoveAsset($event, chapterItem)"
      (chapterAssetsReorder)="onChapterAssetsReorder($event, chapterItem)">
    </app-chapter-card>
  </div>

  <ng-container *ngIf="!chapters.length">
    <app-empty-state
      icon="fa-regular fa-book-blank"
      title="{{ 'chapters.noChapters' | translate }}"
      text="{{ 'chapters.noChaptersSubTitle' | translate }}">
    </app-empty-state>
  </ng-container>
</div>

<p-toast>
  <ng-template
    let-message
    pTemplate="message">
    <app-toast
      [severity]="message.severity"
      [summary]="message.summary"
      [detail]="message.detail">
    </app-toast>
  </ng-template>
</p-toast>

<p-sidebar
  styleClass="chapter-details-custom-sidebar"
  [(visible)]="sidebarVisible"
  position="right"
  [showCloseIcon]="false">
  <app-chapter-details
    [chapter]="selectedChapterForEditing"
    (updateChapter)="onUpdateChapter($event)"
    (closeSidebar)="onCloseSidebar()">
  </app-chapter-details>
</p-sidebar>
