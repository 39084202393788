@if (loading()) {
  <app-loader></app-loader>
} @else {
  <div class="page-container">
    <header class="flex align-items-center justify-content-between c-mb-4">
      <h1 class="admin-page-title">{{ 'adminCourseLandingPage.pageTitle' | translate }}</h1>
      <app-button
        buttonText="{{ 'adminCourseLandingPage.pageBuilderBtn' | translate }}"
        buttonIconLeft="fa-regular fa-pen-to-square"
        [buttonStyle]="buttonStyle().FILLED_PRIMARY"
        [buttonSize]="buttonSize().MEDIUM"
        [disabled]="form.controls.landingPageType.value === courseLandingPageType().DEFAULT"
        (click)="onOpenPageBuilder()" />
    </header>

    <p-divider styleClass="custom-divider-horizontal"></p-divider>

    <form
      [formGroup]="form"
      class="c-mt-8">
      <!-- Page options block -->
      <div class="form-block c-my-9">
        <div class="left-side">
          <h2 class="title">{{ 'adminCourseLandingPage.pageOptionsTitle' | translate }}</h2>
          <p class="description">{{ 'adminCourseLandingPage.pageOptionsDesc' | translate }}</p>
        </div>
        <div class="right-side page-options-right-side">
          @for (pageOption of pageOptions(); track $index) {
            <app-custom-radio-button
              [isActive]="pageOption.value === form.controls.landingPageType.value"
              [iconClass]="pageOption.iconClass"
              [title]="pageOption.title"
              [description]="pageOption.description"
              (click)="onChangeLandingPageType(pageOption)" />
          }
        </div>
      </div>

      <p-divider styleClass="custom-divider-horizontal"></p-divider>

      <!-- Description block -->
      <div class="form-block c-my-9">
        <div class="left-side">
          <h2 class="title">{{ 'adminCourseLandingPage.descriptionTitle' | translate }}</h2>
          <p class="description">{{ 'adminCourseLandingPage.descriptionDesc' | translate }}</p>
        </div>
        <div class="right-side">
          <!-- Description textarea -->
          <div class="form-field-block c-mb-8">
            <app-input-label text="adminCourseLandingPage.descriptionLabel"></app-input-label>
            <p-editor
              styleClass="custom-editor"
              formControlName="description"
              placeholder="{{ 'adminCourseLandingPage.descriptionPlaceholder' | translate }}">
            </p-editor>
          </div>
        </div>
      </div>

      <p-divider styleClass="custom-divider-horizontal"></p-divider>

      <!-- Course image block -->
      <div class="form-block c-my-9">
        <div class="left-side">
          <h2 class="title">{{ 'adminCourseLandingPage.courseImageTitle' | translate }}</h2>
          <p class="description">{{ 'adminCourseLandingPage.courseImageDesc' | translate }}</p>
        </div>
        <div class="right-side">
          <!-- Course image -->
          <div class="form-field-block">
            <app-big-profile-picture-media-form
              titleMediaForm="adminCourseLandingPage.imageUpload"
              description="adminCourseLandingPage.recommendedFormat"
              btnConfirm="upload"
              btnCancel="delete"
              [imgValue]="form.controls.banner.value"
              (changeValue)="setBannerValue($event)"
              (changeGradient)="setBannerGradient($event)">
            </app-big-profile-picture-media-form>
          </div>

          <!-- Featured image -->
          <div
            *ngIf="course.featured"
            class="form-field-block c-mt-4">
            <app-big-profile-picture-media-form
              titleMediaForm="adminCourseSettings.featuredImageTitle"
              description="adminCourseSettings.featuredImageDesc"
              btnConfirm="upload"
              btnCancel="delete"
              [imgValue]="form.controls.featuredImage.value"
              [isRequired]="true"
              (changeValue)="setFeaturedImageValue($event)">
            </app-big-profile-picture-media-form>
          </div>
        </div>
      </div>

      <p-divider styleClass="custom-divider-horizontal"></p-divider>

      <!-- Promo video block -->
      <div class="form-block c-my-9">
        <div class="left-side">
          <h2 class="title">{{ 'adminCourseLandingPage.promoVideoTitle' | translate }}</h2>
          <p class="description">{{ 'adminCourseLandingPage.promoVideoDesc' | translate }}</p>
        </div>
        <div class="right-side">
          <!-- Video input -->
          <div class="form-field-block">
            <div
              class="internal-video-wrapper c-mb-4"
              (drop)="onDrop($event)"
              (dragover)="onDragOver($event)">
              <input
                class="input"
                type="file"
                #internalVideoInput
                [accept]="allowedFormatsForInternalVideo()"
                (change)="uploadInternalVideo($event)"
                [disabled]="form.controls.video.value" />
              <div
                *ngIf="!form.controls.internalVideo.value"
                class="upload-icon">
                <i class="fa-regular fa-video"></i>
                <span>
                  <span
                    class="choose-file"
                    (click)="internalVideoInput.click()">
                    {{ 'adminLibraryAssetFrom.chooseFile' | translate }}
                  </span>
                  {{ 'adminLibraryAssetFrom.orDragAndDrop' | translate }}
                </span>
                <span>{{ 'adminLibraryAssetFrom.videoFileLimit' | translate }}</span>
              </div>
              @if (fileVideoPreview()) {
                <video
                  [src]="fileVideoPreview()"
                  controls></video>
              }
              <button
                *ngIf="form.controls.internalVideo.value"
                class="p-button-rounded p-button-outlined clear-btn"
                pButton
                pRipple
                type="button"
                icon="far fa-circle-xmark"
                (click)="clearInternalVideo()"></button>
            </div>
          </div>
          <div class="separator c-mt-5 c-mb-5">
            <div></div>
            <span>{{ 'adminLibraryAssetFrom.videoSeparatorText' | translate }}</span>
            <div></div>
          </div>
          <div class="form-field-block">
            <app-input-label
              for="video-input"
              text="adminCourseLandingPage.videoLabel">
            </app-input-label>
            <div class="p-inputgroup custom-input-group">
              <span class="p-inputgroup-addon">Embed-URL</span>
              <input
                formControlName="video"
                id="video-input"
                pInputText
                type="text"
                placeholder="{{ 'adminCourseLandingPage.videoPlaceholder' | translate }}" />
            </div>
            <p class="input-note">
              {{ 'adminCourseLandingPage.videoNote' | translate }}
              <a
                class="video-note-link"
                (click)="onNavigateTo()">
                {{ 'adminCourseLandingPage.videoNoteLink' | translate }}
              </a>
            </p>
          </div>
        </div>
      </div>

      <p-divider styleClass="custom-divider-horizontal"></p-divider>

      <!-- Social links block -->
      <div class="form-block c-mt-9 c-mb-4">
        <div class="left-side">
          <h2 class="title">{{ 'adminCourseLandingPage.socialLinksTitle' | translate }}</h2>
          <p class="description">{{ 'adminCourseLandingPage.socialLinksDesc' | translate }}</p>
        </div>
        <div class="right-side">
          <!-- Linkedin input -->
          <div class="form-field-block c-mb-4">
            <app-input-label
              for="linkedin-input"
              text="adminCourseLandingPage.linkedinLabel">
            </app-input-label>
            <div class="p-inputgroup custom-input-group">
              <span class="p-inputgroup-addon">URL</span>
              <input
                class="custom-input-text"
                formControlName="linkedin"
                id="linkedin-input"
                pInputText
                type="text"
                placeholder="{{ 'adminCourseLandingPage.linkedinPlaceholder' | translate }}" />
            </div>
          </div>

          <!-- Facebook input -->
          <div class="form-field-block c-mb-4">
            <app-input-label
              for="facebook-input"
              text="adminCourseLandingPage.facebookLabel">
            </app-input-label>
            <div class="p-inputgroup custom-input-group">
              <span class="p-inputgroup-addon">URL</span>
              <input
                class="custom-input-text"
                formControlName="facebook"
                id="facebook-input"
                pInputText
                type="text"
                placeholder="{{ 'adminCourseLandingPage.facebookPlaceholder' | translate }}" />
            </div>
          </div>

          <!-- Xing input -->
          <div class="form-field-block">
            <app-input-label
              for="xing-input"
              text="adminCourseLandingPage.xingLabel">
            </app-input-label>
            <div class="p-inputgroup custom-input-group">
              <span class="p-inputgroup-addon">URL</span>
              <input
                class="custom-input-text"
                formControlName="xing"
                id="xing-input"
                pInputText
                type="text"
                placeholder="{{ 'adminCourseLandingPage.xingPlaceholder' | translate }}" />
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
}

<div class="save-discard-actions-container">
  <app-save-discard-actions
    [isShow]="canUpdate"
    [isLoading]="isUpdating()"
    (discard)="onDiscard()"
    (confirm)="onConfirm()">
  </app-save-discard-actions>
</div>

<p-toast>
  <ng-template
    let-message
    pTemplate="message">
    <app-toast
      [severity]="message.severity"
      [summary]="message.summary"
      [detail]="message.detail">
    </app-toast>
  </ng-template>
</p-toast>
