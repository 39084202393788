<div class="dialog-container flex flex-column align-items-center">
  <h1 class="title">{{ 'subscriptionDialog.title' | translate }}</h1>
  <p class="main-text">{{ 'subscriptionDialog.text' | translate }}</p>
  <div class="buttons">
    <button
      class="button"
      (click)="onClose()">
      {{ 'subscriptionDialog.btnText' | translate }}
    </button>
  </div>
</div>
