import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ButtonComponent, ButtonSize, ButtonStyle, ButtonType } from 'src/app/standalone/shared';
import { SharedModule } from 'src/app/shared';
import { IBrand } from 'src/app/core/models';
import { BrandsStore } from 'src/app/core/stores';
import { EventBrandSidebarComponent } from './event-brand-sidebar/event-brand-sidebar.component';

@Component({
  selector: 'app-event-brand',
  standalone: true,
  imports: [SharedModule, ButtonComponent, EventBrandSidebarComponent],
  templateUrl: './event-brand.component.html',
  styleUrl: './event-brand.component.scss',
})
export class EventBrandComponent {
  buttonType = ButtonType;
  buttonSize = ButtonSize;
  buttonStyle = ButtonStyle;

  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private brandsStore = inject(BrandsStore);

  get brand(): IBrand {
    return this.brandsStore.adminBrand;
  }

  onNavigateToBrandsList(): void {
    this.router.navigate([`../`], { relativeTo: this.route });
  }
}
