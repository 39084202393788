import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { MenuModule } from 'primeng/menu';
import { TranslateModule } from '@ngx-translate/core';

import { ButtonComponent } from '../button/button.component';
import { ButtonSize } from '../../enums';

@Component({
  selector: 'app-button-list',
  standalone: true,
  imports: [CommonModule, TranslateModule, MenuModule, ButtonComponent],
  templateUrl: './button-list.component.html',
  styleUrl: './button-list.component.scss',
})
export class ButtonListComponent {
  @Input() items: MenuItem[] = [];
  @Input() btnLabel: string;

  buttonSize = ButtonSize;
}
