<div class="container">
  <p class="title">
    {{ 'sidebarUser.courses' | translate | uppercase }}
  </p>
  <ul class="list">
    <ng-container *ngFor="let course of userCourses; last as isLast; trackBy: trackByFn">
      <li
        class="list-item"
        [class.list-item-last]="isLast"
        (click)="onNavigateTo(course)">
        <img
          [ngSrc]="course.banner || defaultCourseImage"
          class="icon"
          width="39"
          height="39"
          priority />
        <div class="text-container">
          <p class="course-name">{{ course.title }}</p>
          <span class="duration">{{ course.duration }}</span>
        </div>
        <i class="fa-regular fa-chevron-right chevron-icon"></i>
      </li>
      <div
        *ngIf="!isLast"
        class="divider-container">
        <p-divider styleClass="custom-divider-horizontal"></p-divider>
      </div>
    </ng-container>
  </ul>
</div>
