<div class="header">
  <div class="title">
    {{ 'adminInstructors.attachInstructors' | translate }}
  </div>
  <span
    *ngIf="!appStore.generalSystemSettings.enableEncryption"
    class="search-input p-input-icon-left"
    [ngClass]="{ 'p-input-icon-right': filterSearch.value != '' }">
    <i class="far fa-search search-icon"></i>
    <input
      class="custom-input-text main-search-input"
      type="text"
      #filterSearch
      name="filterSearch"
      (input)="instructorSearchChanged($event.target.value)"
      pInputText
      placeholder="{{ 'adminTable.inputSearchPlaceholder' | translate }}" />
    <i
      *ngIf="filterSearch.value"
      class="reset-btn far fa-times-circle"
      (click)="onClearInstructorsSearch()">
    </i>
  </span>
  <div class="btn-container">
    <app-button
      buttonText="{{ 'adminInstructors.attach' | translate }}"
      [buttonSize]="buttonSize.MEDIUM"
      [loading]="attachLoading"
      [disabled]="!selectedEntries.length"
      (click)="attach()" />
    <i
      class="fa-regular fa-xmark close-icon"
      (click)="onCloseDialog()"></i>
  </div>
</div>
<div class="container">
  <div class="filters">
    <div class="title">{{ 'adminUsers.tagsFilterLabel' | translate }}</div>
    <span
      class="search-input p-input-icon-left"
      [ngClass]="{ 'p-input-icon-right': filterSearch.value != '' }">
      <i class="far fa-search search-icon"></i>
      <input
        class="custom-input-text"
        type="text"
        #filterSearch
        name="filterSearch"
        (input)="tagSearchChanged($event.target.value)"
        pInputText
        placeholder="{{ 'adminTable.inputSearchPlaceholder' | translate }}" />
      <i
        *ngIf="filterSearch.value != ''"
        class="reset-btn far fa-times-circle"
        (click)="filterSearch.value = ''">
      </i>
    </span>
    <div class="tag-list">
      <p-checkbox
        *ngFor="let tag of filteredTags"
        class="custom-input-checkbox-sm c-my-2 c-ml-1"
        (ngModelChange)="tagSelectionChanged($event)"
        [value]="tag"
        [label]="tag.title"
        [(ngModel)]="selectedTags">
      </p-checkbox>
    </div>
  </div>
  <admin-table
    checkBoxSelection="true"
    emptyStateKey="adminUsers.emptyState"
    emptyStateIcon="far fa-person-chalkboard"
    scssClass="table-for-attach paginator-border-bottom-right-radius show-row-border-bottom"
    [lazyLoading]="loadingEntries"
    [columns]="columns"
    [entries]="entries"
    [pageSize]="pageSize"
    [totalRecords]="totalRecords"
    [(selectedEntries)]="selectedEntries"
    (lazyLoad)="fetchEntries($event)">
  </admin-table>
</div>

<p-toast key="user">
  <ng-template
    let-message
    pTemplate="message">
    <app-toast
      [severity]="message.severity"
      [summary]="message.summary"
      [detail]="message.detail">
    </app-toast>
  </ng-template>
</p-toast>
