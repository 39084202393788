<div class="sw-new-version-container">
  <h1>{{ 'SW.newVersionTitle' | translate }}</h1>

  <p>{{ 'SW.newVersionDersc' | translate }}</p>

  <div class="actions-container">
    <button
      type="button"
      class="cancel-btn"
      pButton
      label=""
      (click)="ref.close(false)">
      {{ 'SW.newVersionLaterBtn' | translate }}
    </button>
    <button
      type="button"
      class="confirm-btn"
      pButton
      label=""
      (click)="ref.close(true)">
      {{ 'SW.newVersionReloadBtn' | translate }}
    </button>
  </div>
</div>
