import { Injectable } from '@angular/core';

import { parseToMoment, CollectionTypes } from 'src/app/shared';
import { AlgoliaSearchResult, IUser, IUserHub } from 'src/app/core/models';
import { Firestore, IFirestore } from 'src/app/firebase';
import { AppStore } from 'src/app/app.store';
import { AlgoliaService } from './../algolia/algolia.service';
import { SpeakersStore } from '../../stores/speakers/speakers.store';
import { UsersStore } from '../../stores/users/users.store';
import { INavbarFilter } from '../../../event/components/app-actions-navbar/navbar-filter.model';
import { UsersService } from '../users';

@Injectable({
  providedIn: 'root',
})
export class SpeakersService {
  private firestore: IFirestore;

  private readonly users: string = 'users';
  private readonly userEventsCollection: string = 'userEvents';

  constructor(
    private userStore: UsersStore,
    private speakersStore: SpeakersStore,
    private algoliaService: AlgoliaService,
    public appStore: AppStore,
    private usersService: UsersService,
  ) {
    this.firestore = Firestore();
  }

  public async getAllSpeakers(eventId: string): Promise<IUser[]> {
    try {
      const users: IUser[] = [];
      const eventSpeakersQuery = await this.firestore
        .collection(CollectionTypes.USER_EVENTS)
        .where('eventId', '==', eventId)
        .where('role', '==', 'speaker')
        .get();

      const promises = [];
      const speakersIds = [];
      const speakersObjects = {};

      eventSpeakersQuery.docs.map(async (eventSpeakerDoc) => {
        const eventSpeaker = eventSpeakerDoc.data();
        speakersIds.push(eventSpeaker.userId);
        speakersObjects[eventSpeaker.userId] = eventSpeaker;
      });

      while (speakersIds.length) {
        const chunk = speakersIds.splice(0, 10);
        promises.push(
          this.firestore
            .collection(this.users)
            .where('id', 'in', chunk)
            .orderBy('_firstName_')
            .orderBy('_lastName_')
            .get(),
        );
      }

      const queryAllUsers = await Promise.all(promises);
      queryAllUsers.map((queryUsersChunk) => {
        queryUsersChunk.docs.map((doc) => {
          let user = doc.data() as IUser;
          if (this.appStore.generalSystemSettings.enableEncryption) {
            user = this.usersService.decryptUserData(user) as IUser;
            console.log('↑ getAllSpeakers decrypt');
          }

          users.push(
            Object.assign(user, {
              eventStatus: speakersObjects[doc.id]?.status,
              eventTimeStamp: parseToMoment(speakersObjects[doc.id].createdAt).format('ll'),
              eventRole: speakersObjects[doc.id].role,
            }),
          );
        });
      });

      return users;
    } catch (error) {
      console.warn(error);
      return [];
    }
  }

  async fetchUnattached(
    hubId: string,
    eventId: string,
    pageIndex: number,
    pageSize: number,
    searchTerm: string,
  ): Promise<AlgoliaSearchResult<IUser>> {
    const attachedUsers = await this.getAllEventSpeakers(eventId, true);
    const facetFilters = attachedUsers.map((id) => `userId:-${id}`).join(',');

    const resultFromAlgolia: AlgoliaSearchResult<IUserHub> =
      await this.algoliaService.search<IUserHub>(
        CollectionTypes.USER_HUBS,
        `${searchTerm} ${hubId}`,
        pageSize,
        pageIndex * pageSize,
        '',
        facetFilters,
      );

    const result: AlgoliaSearchResult<IUser> = {
      total: resultFromAlgolia.total,
      results: resultFromAlgolia.results.map((item: any) => item.user),
    };

    return result;
  }

  public async fetch(
    eventId: string = null,
    pageIndex: number,
    pageSize: number,
    searchTerm: string,
  ): Promise<AlgoliaSearchResult<IUser>> {
    const res = await this.algoliaService.search<{
      role: string;
      status?: 'active' | 'inactive' | 'pending';
      createdAt: any;
      user: IUser;
    }>('userEvents', `${eventId} ${'speaker'} ${searchTerm}`, pageSize, pageIndex * pageSize);

    return {
      total: res.total,
      results: res.results.map((r) => ({
        status: r?.status ?? 'inactive',
        timeStamp: new Date(r.createdAt),
        role: r.role,
        ...r.user,
      })),
    };
  }

  public async get(
    eventId: string,
    filter?: INavbarFilter,
    entry?: IUser,
    pageSize: number = 20,
    idsArray: any[] = [],
  ): Promise<IUser[]> {
    const speakers = [];
    let speakersIds = [];

    const eventSpeakersQuery = await this.firestore
      .collection(this.userEventsCollection)
      .where('eventId', '==', eventId)
      .where('role', '==', 'speaker')
      .orderBy('_firstName_', filter.order)
      .orderBy('_lastName_', filter.order)
      .get();

    if (!eventSpeakersQuery.empty) {
      eventSpeakersQuery.forEach((doc) => {
        if (!idsArray.includes(doc.data().userId)) {
          speakersIds.push(doc.data().userId);
        }
      });

      if (speakersIds.length) {
        let queryUsers = null;

        while (speakersIds.length > 0) {
          const chunk = speakersIds.splice(0, 10);

          queryUsers = this.firestore
            .collection(this.users)
            .where('id', 'in', chunk)
            .orderBy('_firstName_', filter.order)
            .orderBy('_lastName_', filter.order);

          queryUsers = await queryUsers.limit(pageSize).get();
          if (!queryUsers.empty) {
            queryUsers.forEach((doc) => {
              let speaker = doc.data();

              if (speakers.length < pageSize) {
                if (this.appStore.generalSystemSettings.enableEncryption) {
                  speaker = this.usersService.decryptUserData(speaker) as IUser;
                  console.log('speakers get decrypted speaker');
                }
                speakers.push(speaker);
              }
            });
          }

          if (speakers.length === pageSize) {
            speakersIds = [];
          }
        }
      }
    }

    return speakers;
  }

  public async getAllPaginated(
    eventId: string = null,
    order: 'asc' | 'desc',
    entry?: IUser,
    pageSize: number = 12,
  ): Promise<IUser[]> {
    const speakers: IUser[] = [];
    let speakerIds: string[] = [];

    (
      await this.firestore
        .collection(this.userEventsCollection)
        .where('eventId', '==', eventId)
        .where('role', '==', 'speaker')
        .orderBy('_firstName_', order)
        .orderBy('_lastName_', order)
        .get()
    ).forEach((doc) => {
      const userId = doc.data().userId;

      if (!speakerIds.includes(userId)) {
        speakerIds.push(userId);
      }
    });

    const startFromIndex = entry ? speakerIds.indexOf(entry.id) + 1 : 0;
    speakerIds = speakerIds.splice(startFromIndex, pageSize);

    while (speakerIds.length) {
      const chunk = speakerIds.splice(0, 10);

      const queryUsers = await this.firestore
        .collection(this.users)
        .where('id', 'in', chunk)
        .orderBy('_firstName_', order)
        .orderBy('_lastName_', order)
        .get();

      queryUsers.docs.map((doc) => {
        let speaker = doc.data() as IUser;

        if (this.appStore.generalSystemSettings.enableEncryption) {
          speaker = this.usersService.decryptUserData(speaker) as IUser;
          console.log('↑ getAllPaginated speaker decrypted');
        }
        speakers.push(speaker);
      });
    }

    this.speakersStore.setSpeakers(speakers);

    return speakers;
  }

  public async getAllByTagIds(
    eventId: string,
    tagIds: string[],
    order: 'asc' | 'desc',
    entry?: IUser,
    pageSize: number = 12,
  ): Promise<IUser[]> {
    const speakers: IUser[] = [];
    let speakerIds: string[] = [];
    // will break navbar tags without it - those ids are mobx observables from eventStore;
    const tagsIds = [...tagIds];

    while (tagsIds.length) {
      const chunk = tagsIds.splice(0, 10);

      (
        await this.firestore
          .collection(this.userEventsCollection)
          .where('tags', 'array-contains-any', chunk)
          .where('eventId', '==', eventId)
          .where('role', '==', 'speaker')
          .orderBy('_firstName_', order)
          .orderBy('_lastName_', order)
          .get()
      ).forEach((doc) => {
        const userId = doc.data().userId;

        if (!speakerIds.includes(userId)) {
          speakerIds.push(userId);
        }
      });
    }

    const startAfterIndex = entry ? speakerIds.indexOf(entry.id) + 1 : 0;
    speakerIds = speakerIds.splice(startAfterIndex, pageSize);

    let queryUsersByTagIds = null;

    while (speakerIds.length) {
      const chunk = speakerIds.splice(0, 10);

      queryUsersByTagIds = await this.firestore
        .collection(this.users)
        .where('id', 'in', chunk)
        .orderBy('_firstName_', order)
        .orderBy('_lastName_', order)
        .get();

      queryUsersByTagIds.docs.map((doc) => {
        let speaker = doc.data() as IUser;

        if (this.appStore.generalSystemSettings.enableEncryption) {
          speaker = this.usersService.decryptUserData(speaker) as IUser;
          console.log('↑  getAllByTagIds speaker decrypted', speaker);
        }
        speakers.push(speaker);
      });
    }

    this.speakersStore.setSpeakers(speakers);

    return speakers;
  }

  public async getBookmarked(
    eventId: string,
    order: 'asc' | 'desc',
    entry?: IUser,
    pageSize: number = 12,
  ): Promise<IUser[]> {
    const userEventId = this.userStore.userEventsMap[eventId].id;
    let speakers: IUser[] = [];
    const speakerIds: string[] = [];

    (
      await this.firestore
        .collection(`${this.userEventsCollection}/${userEventId}/bookmarkedSpeakers`)
        .get()
    ).forEach((doc) => {
      if (!speakerIds.includes(doc.id)) {
        speakerIds.push(doc.id);
      }
    });

    let queryUsersByIds = null;

    while (speakerIds.length > 0) {
      const chunk = speakerIds.splice(0, 10);

      queryUsersByIds = await this.firestore
        .collection(this.users)
        .where('id', 'in', chunk)
        .orderBy('_firstName_', order)
        .orderBy('_lastName_', order)
        .get();

      queryUsersByIds.docs.map((doc) => {
        let user = doc.data() as IUser;

        if (this.appStore.generalSystemSettings.enableEncryption) {
          user = this.usersService.decryptUserData(user) as IUser;
          console.log('↑ getBookmarked speaker decrypted');
        }
        speakers.push(user);
      });
    }

    const startFromIndex = entry ? speakers.map((u) => u.id).indexOf(entry.id) + 1 : 0;
    speakers = speakers.splice(startFromIndex, pageSize);
    this.speakersStore.setSpeakers(speakers);

    return speakers;
  }

  public async getOne(speakerId: string): Promise<IUser> {
    if (Object.keys(this.speakersStore.speakersMap).includes(speakerId)) {
      return this.speakersStore.speakersMap[speakerId];
    }

    try {
      let speaker = (await this.firestore.doc(`${this.users}/${speakerId}`).get()).data() as IUser;
      if (this.appStore.generalSystemSettings.enableEncryption) {
        speaker = this.usersService.decryptUserData(speaker) as IUser;
        console.log('↑ speakers getOne decrypted');
      }
      this.speakersStore.setSpeakers([speaker]);
      return speaker;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  public async getByIds(ids: string[], forceSync: boolean = false): Promise<IUser[]> {
    if (ids?.length === 0) {
      return [];
    }

    const speakerIds = !!ids ? [...ids] : null;

    const storeSpeakers = Object.keys(this.speakersStore.speakersMap);
    if (speakerIds?.length > 0 && speakerIds.every((id) => storeSpeakers.includes(id))) {
      return this.speakersStore.speakers.filter((speaker) => speakerIds.includes(speaker.id));
    }

    try {
      const speakers = [];
      let query;

      while (speakerIds?.length > 0) {
        const chunk = speakerIds.splice(0, 10);

        query = await this.firestore
          .collection(this.users)
          .where('id', 'in', chunk)
          .orderBy('_firstName_')
          .orderBy('_lastName_')
          .get();

        if (!query.empty) {
          query.forEach((doc) => {
            let speaker = doc.data() as IUser;

            if (this.appStore.generalSystemSettings.enableEncryption) {
              speaker = this.usersService.decryptUserData(speaker) as IUser;
              console.log('↑ getAllPaginated speaker decrypted', speaker);
            }
            speakers.push(speaker);
          });
        }
      }

      this.speakersStore.setSpeakers(speakers);
      return speakers;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  public async getByBrand(eventId: string, brandId: string): Promise<IUser[]> {
    return [];
  }

  public async getAllEventSpeakers(
    eventId: string,
    returnOnlyIds: boolean = false,
  ): Promise<IUser[] | string[]> {
    return (
      await this.firestore
        .collection('userEvents')
        .where('eventId', '==', eventId)
        .where('role', '==', 'speaker')
        .get()
    ).docs.map((doc) => (returnOnlyIds ? doc.data().userId : doc.data()));
  }

  public async remove(eventId: string, id: string): Promise<boolean> {
    return true;
  }
}
