import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Subject, takeUntil, tap } from 'rxjs';

import { FeatureHeaderComponent, FeatureMainContentComponent } from 'src/app/standalone/admin';
import { EditAssetComponent } from 'src/app/standalone/shared';
import { IAsset, IHub } from 'src/app/core/models';
import { HubsStore, LibraryStore } from 'src/app/core/stores';

@Component({
  selector: 'app-library-asset-container',
  standalone: true,
  imports: [CommonModule, FeatureHeaderComponent, FeatureMainContentComponent, EditAssetComponent],
  templateUrl: './library-asset-container.component.html',
  styleUrls: ['./library-asset-container.component.scss'],
})
export class LibraryAssetContainerComponent implements OnInit, OnDestroy {
  asset: IAsset;

  private unsubscribe$ = new Subject<void>();

  constructor(
    public hubsStore: HubsStore,
    private libraryStore: LibraryStore,
  ) {}

  get hub(): IHub {
    return this.hubsStore.hub;
  }

  ngOnInit(): void {
    this.libraryStore.adminAsset$
      .pipe(
        tap((asset: IAsset) => {
          this.asset = asset;
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
