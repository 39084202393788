import { Component } from '@angular/core';

@Component({
  selector: 'app-event-registration-report',
  standalone: true,
  imports: [],
  templateUrl: './event-registration-report.component.html',
  styleUrl: './event-registration-report.component.scss',
})
export class EventRegistrationReportComponent {}
