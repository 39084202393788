<div
  class="course-card-container"
  (click)="onNavigateToCoursePage()">
  @if (bookmarkBtnShow && user) {
    <div
      class="bookmark-icon-box"
      (click)="toggleBookmark($event)">
      <i [ngClass]="getBookmarkedBtnClass"></i>
    </div>
  }
  <div class="img-container">
    @if (course()?.banner) {
      <img
        class="banner img-preloader"
        [defaultImage]="imgPreloader()"
        [lazyLoad]="course().banner" />
    } @else {
      <div class="default-banner-container">
        <svg
          width="106"
          height="117"
          viewBox="0 0 106 117"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M2.58522 99.9917C3.06847 99.7145 8.5227 97.401 35.6565 110.075C35.5774 110.183 35.5439 110.234 35.5439 110.234C35.5439 110.234 7.33561 119.752 1.80875 108.027C0.158325 104.536 0.631246 102.132 2.53809 100.019L2.58522 99.9917Z"
            fill="#181818" />
          <path
            d="M93.782 104.787C87.8869 109.752 78.6421 113.357 69.7217 108.564C71.1313 107.661 72.5873 106.732 74.0722 105.77C83.0306 100.003 90.9214 103.862 93.782 104.787Z"
            fill="#181818" />
          <path
            d="M104.344 43.7152C110.367 93.9658 96.0538 103.086 94.4604 104.471C93.9991 104.872 93.8121 104.843 93.1146 104.663L93.1027 104.67C90.1507 103.886 83.0336 100.003 74.0749 105.769C72.59 106.731 71.1336 107.661 69.7244 108.563C60.5856 114.37 53.2057 118.463 47.8889 116.088C43.3791 113.782 39.3129 111.788 35.6572 110.074C8.52341 97.3997 3.06919 99.7132 2.58594 99.9904C3.14666 99.6845 8.29045 96.8276 15.2165 91.8038C15.24 91.7902 15.2636 91.7767 15.2687 91.7582C24.5263 85.036 36.9241 74.4426 45.8356 60.8155C51.9022 51.5491 57.8371 28.0577 69.2882 23.4484C92.8223 14.6519 103.367 33.494 104.344 43.7152Z"
            [attr.fill]="defaultCoversColor" />
          <path
            d="M78.3185 42.8329C79.049 40.9001 78.9778 39.0816 78.1595 38.7711C77.3411 38.4605 76.0855 39.7756 75.355 41.7084C74.6244 43.6412 74.6956 45.4597 75.514 45.7702C76.3324 46.0808 77.588 44.7657 78.3185 42.8329Z"
            fill="white" />
          <path
            d="M93.3077 45.3001C93.8254 43.2995 93.5584 41.4993 92.7114 41.2792C91.8643 41.0592 90.7579 42.5026 90.2402 44.5031C89.7224 46.5037 89.9894 48.3039 90.8365 48.524C91.6836 48.7441 92.79 47.3007 93.3077 45.3001Z"
            fill="white" />
          <path
            d="M80.6011 51.1035C80.7226 51.0862 80.8464 51.1111 80.9519 51.174C81.0573 51.2369 81.1382 51.3341 81.181 51.4493C81.3374 51.8665 81.9417 53.0805 82.6386 53.174C83.3354 53.2674 84.5444 52.3437 85.0208 51.8817C85.1234 51.7823 85.2612 51.7278 85.4039 51.7302C85.5466 51.7325 85.6825 51.7916 85.7817 51.8944C85.8809 51.9971 85.9353 52.1352 85.933 52.2782C85.9306 52.4211 85.8716 52.5573 85.7691 52.6567C85.5812 52.8387 83.8937 54.43 82.496 54.2427C81.0382 54.0473 80.2568 52.0524 80.1727 51.8255C80.1446 51.7499 80.1338 51.6691 80.141 51.5888C80.1482 51.5085 80.1733 51.4309 80.2144 51.3616C80.2556 51.2924 80.3117 51.2332 80.3786 51.1886C80.4455 51.1439 80.5216 51.1148 80.6013 51.1035L80.6011 51.1035Z"
            fill="white" />
          <path
            d="M95.4348 6.82852L75.7064 4.60948C74.2517 4.43409 72.8586 4.6527 71.527 5.26529L53.4202 13.4212C52.0256 14.1109 51.2469 15.3768 51.4624 16.7557C51.6677 18.069 52.7954 19.0357 54.3337 19.2653L56.0187 19.4718C55.9233 19.8231 55.828 20.1745 55.8163 20.58C55.061 21.0349 54.6606 21.8377 54.7941 22.6913C54.8967 23.348 55.3557 23.8814 56.0039 24.1834L55.3511 32.0224C55.307 32.702 55.9127 33.2125 56.6459 33.0974L60.8254 32.4416C61.5586 32.3265 61.9795 31.655 61.7302 31.0214L58.7168 23.7577C59.2418 23.2716 59.5894 22.6116 59.4868 21.955C59.3739 21.2327 58.8416 20.7107 58.1934 20.4088C58.2359 20.2003 58.3415 19.9146 58.4574 19.6946L62.6647 20.1781L62.8082 27.8249C63.1777 30.1888 69.6976 31.0494 77.5432 29.8183C85.3155 28.5988 91.334 25.7707 90.9645 23.4068L88.6946 16.0937L96.3482 12.6726C97.7429 11.9829 98.5949 10.7055 98.3897 9.39223C98.1741 8.01328 96.9731 7.05806 95.4348 6.82852Z"
            [attr.fill]="defaultCoversColor" />
        </svg>
      </div>
    }
  </div>
  <div class="main">
    <h1 class="course-title">{{ course().title }}</h1>
    <p class="course-duration">
      <i class="fa-regular fa-book-blank"></i>
      <span>{{ courseAssetsText }}</span>
      <i class="fa-regular fa-circle-play"></i>
      <span>{{ allAssetsDuration }}</span>
    </p>
    <div class="button-price-container">
      <div>
        @if (isCurrentUserRegisteredInCourse()) {
          <button class="join-btn join-btn--attending">
            <i class="far fa-circle-check"></i>
            {{ 'eventCard.attending' | translate }}
          </button>
        } @else {
          <button class="join-btn">
            {{ 'courseCard.startCourseBtn' | translate }}
          </button>
        }
      </div>

      @if (course().price) {
        <div class="price-container">
          <span class="price">{{ coursePrice }}</span>

          @if (course().strikePrice) {
            <span class="strike-price">{{ courseStrikePrice }}</span>
          }
        </div>
      }
    </div>
  </div>
</div>
