export enum AnalyticsEventTypes {
  ACCEPT_MEETING = 'Accept a meeting',
  ACCOUNT_UPDATE = 'Account Update',
  AGENDA_FILTER = 'Agenda Filter',
  AGENDA_VIEW = 'Agenda View',
  ATTENDEE_BOOKMARK = 'Attendee Bookmark',
  ATTENDEE_CLICK = 'Attendee Click',
  ATTENDEE_DISCOVERY = 'Attendee Discovery',
  ATTENDEE_FILTER = 'Attendee Filter',
  ATTENDEE_UNBOOKMARK = 'Attendee UnBookmark',
  ATTENDEE_VIEW = 'Attendee View',
  BRAND_BOOKMARK = 'Brand Bookmark',
  BRAND_CLICK = 'Brand Click',
  BRAND_DISCOVERY = 'Brand Discovery',
  BRAND_FILTER = 'Brand Filter',
  BRAND_UNBOOKMARK = 'Brand UnBookmark',
  BRAND_VIEW = 'Brand View',
  CHAT_UPDATE = 'Chat Update',
  COURSE_ASSET_FILE = 'Course Asset - File',
  COURSE_ASSET_DOWNLOAD_ALL = 'Course Asset - Download All',
  COURSE_ASSET_VIDEO = 'Course Asset - Video',
  COURSE_BOOKMARKED = 'Course Bookmarked',
  COURSE_BUY_A_COURSE = 'Course Buy a course',
  COURSE_REG_VIEW = 'Course Reg View',
  COURSE_UNBOOKMARKED = 'Course UnBookmarked',
  COURSE_VIEW = 'Course View',
  DECLINE_MEETING = 'Decline a meeting',
  DM_SENT = 'DM Sent',
  EVENT_BOOKMARKED = 'Event Bookmarked',
  EVENT_DISCOVERY = 'Event Discovery',
  EVENT_EXIT = 'Event Exit',
  EVENT_TICKET_BUY = 'Event Ticket Buy',
  EVENT_UNBOOKMARKED = 'Event Unbookmarked',
  EVENT_VIEW = 'Event View',
  HELP_CENTER_CLICK = 'Help Center Click',
  LOGIN = 'Login',
  MEDIA_PARTNER_VIEW = 'Media Partner View',
  PAGE_VISIT = 'Page Visit',
  PARTNER_BOOKMARK = 'Partner Bookmark',
  PARTNER_VIEW = 'Partner View',
  PASSWORD_RESET_REQUESTED = 'Password Reset Requested',
  PROFILE_COVER_REMOVE = 'Profile Cover Remove',
  PROFILE_COVER_UPLOAD = 'Profile Cover Upload',
  PROFILE_PICTURE_REMOVE = 'Profile Picture Remove',
  PROFILE_PICTURE_UPLOAD = 'Profile Picture Upload',
  PROFILE_UPDATE = 'Profile Update',
  PROFILE_VISIT = 'Profile Visit',
  SCHEDULE_MEETING = 'Schedule a Meeting',
  SESSION_BOOKMARK = 'Session Bookmark',
  SESSION_DOWNLOAD = 'Session Download',
  SESSION_UNBOOKMARK = 'Session UnBookmark',
  SESSION_VIEW = 'Session View',
  SIGNUP = 'Signup',
  SOCIAL_ICON_CLICK = 'Social Icon Click',
  SPEAKER_BOOKMARK = 'Speaker Bookmark',
  SPEAKER_CLICK = 'Speaker Click',
  SPEAKER_DISCOVERY = 'Speaker Discovery',
  SPEAKER_FILTER = 'Speaker Filter',
  SPEAKER_UNBOOKMARK = 'Speaker UnBookmark',
  SPEAKER_VIEW = 'Speaker View',
  STAGE_CHAT_SENT = 'Stage Chat Sent',
  STAGE_VIEW = 'Stage View',
  STREAM_TRACKING = 'Stream Tracking',
  TOPIC_UPDATE = 'Topic Update',
  USER = 'User',
  USER_REGISTERED_IN_COURSE = 'Course Registered Subscribers',
  USER_REGISTERED_IN_EVENT = 'Event Registered Attendees',
  VIDEO_DISCOVERY = 'Video Discovery',
  VIDEO_FILTER = 'Video Filter',
}
