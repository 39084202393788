import * as randomize from 'randomatic';
import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

import {
  Firestore,
  IDocumentData,
  IFirestore,
  Timestamp,
  IDocumentReference,
} from 'src/app/firebase';
import { ITicket, IOrder } from 'src/app/core/models';
import { UsersStore } from 'src/app/core/stores';
import { API_ROUTES as apiRoutes } from 'src/app/shared';

@Injectable({
  providedIn: 'root',
})
export class OrdersService {
  private firestore: IFirestore;

  constructor(private usersStore: UsersStore) {
    this.firestore = Firestore();
  }

  async numberOfOrdersForSpecificTicketInEvent(eventId: string, ticketId: string): Promise<number> {
    try {
      const numberOrders: number = (
        await this.firestore
          .collection(apiRoutes.orders)
          .where('eventId', '==', eventId)
          .where('ticketId', '==', ticketId)
          .get()
      ).docs.length;

      return numberOrders;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async createOrders(eventId: string, tickets: ITicket[]): Promise<IOrder[]> {
    try {
      const preparedOrders = await this.prepareOrders(eventId, tickets);
      const bookedOrders = await Promise.all(
        preparedOrders.map(async (order: IOrder) => {
          const preBookedOrderReq = await this.firestore.collection(apiRoutes.orders).doc();
          const newBookedOrder: IOrder = {
            ...order,
            id: preBookedOrderReq.id,
            createdAt: Timestamp.now(),
            createdBy: this.usersStore?.user?.id || null,
          };
          await this.firestore
            .collection(apiRoutes.orders)
            .doc(newBookedOrder.id)
            .set({ ...newBookedOrder });
          return newBookedOrder;
        }),
      );
      return bookedOrders;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  private async prepareOrders(eventId: string, tickets: ITicket[]): Promise<IOrder[]> {
    const orderId: string = uuidv4();
    let ordersForBooking: IOrder[] = [];
    tickets.forEach((ticket: ITicket) => {
      const ordersFromOneTicket: IOrder[] = Array(ticket.numberOfPurchaseTickets).fill({
        id: null,
        eventId,
        ticketId: ticket.id,
        ticketName: ticket.name,
        _ticketName_: ticket.name.toLowerCase(),
        orderId,
        orderedBy: this.usersStore?.user?.id || null,
        bookedBy: null,
        bookedAt: null,
        bookingCode: null,
        assignedEmail: null,
        assignedAt: null,
        assignedBy: null,
        reserved: true,
        createdAt: null,
        createdBy: null,
        updatedAt: null,
        updatedBy: null,
      });
      ordersForBooking = ordersForBooking.concat(ordersFromOneTicket);
    });
    const preparedOrders = await Promise.all(
      ordersForBooking.map(async (order: IOrder) => {
        const bookingCode = await this.createBookingCode();
        return { ...order, bookingCode };
      }),
    );

    return preparedOrders;
  }

  private async createBookingCode(): Promise<number> {
    const bookingCode = randomize('0', 20);
    const isExistBookingCode = await this.isExistBookingCode(bookingCode);
    if (isExistBookingCode) {
      return this.createBookingCode();
    }

    return bookingCode;
  }

  async isExistBookingCode(code: number): Promise<boolean> {
    try {
      const isExistBookingCode = !!(
        await this.firestore.collection(apiRoutes.orders).where('bookingCode', '==', code).get()
      ).docs.length;

      return isExistBookingCode;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getOrderByOrderId(orderId: string): Promise<IOrder[]> {
    try {
      const orders: IOrder[] = (
        await this.firestore.collection(apiRoutes.orders).where('orderId', '==', orderId).get()
      ).docs.map((doc) => doc.data() as IOrder);

      return orders;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getOrdersByEventIdAndUserId(eventId: string, userId: string): Promise<IOrder[]> {
    try {
      const orders: IOrder[] = (
        await this.firestore
          .collection(apiRoutes.orders)
          .where('eventId', '==', eventId)
          .where('orderedBy', '==', userId)
          .get()
      ).docs.map((doc) => doc.data() as IOrder);

      return orders;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getOrderByBookingCode(bookingCode: string): Promise<IOrder> {
    try {
      const [order] = (
        await this.firestore
          .collection(apiRoutes.orders)
          .where('bookingCode', '==', bookingCode)
          .get()
      ).docs.map((doc) => doc.data() as IOrder);

      return order;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getOrderByBookedByAndTicketId(bookedBy: string, ticketId: string): Promise<IOrder> {
    try {
      const [order] = (
        await this.firestore
          .collection(apiRoutes.orders)
          .where('bookedBy', '==', bookedBy)
          .where('ticketId', '==', ticketId)
          .get()
      ).docs.map((doc) => doc.data() as IOrder);

      return order;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async updateOrder(order: IOrder): Promise<IOrder> {
    try {
      const orderDocument: IDocumentReference<IDocumentData> = this.firestore
        .collection(apiRoutes.orders)
        .doc(order.id);
      const newOrder: IOrder = {
        ...order,
        updatedAt: Timestamp.now(),
        updatedBy: this.usersStore.user?.id ? this.usersStore.user.id : null,
      };
      await orderDocument.update({ ...newOrder });

      return newOrder;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async deleteOrder(order: IOrder): Promise<boolean> {
    try {
      await this.firestore.collection(apiRoutes.orders).doc(order.id).delete();
      return true;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }
}
