@if (loading()) {
  <app-loader></app-loader>
} @else {
  <div class="create-course-ticket-container">
    <div class="title">{{ 'adminCourseNewTicket.createTicketTitle' | translate }}</div>

    <div class="c-mt-4 c-mb-8">
      <p-divider styleClass="custom-divider-horizontal"></p-divider>
    </div>

    <app-admin-course-ticket-form></app-admin-course-ticket-form>
  </div>
}
