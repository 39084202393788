<div
  *ngIf="!!activeMenuItem?.sidebarItems; else oneColumn"
  class="two-column-layout">
  <div class="left-side">
    <app-feature-sidebar
      [currentMenuItem]="activeMenuItem"
      [checkedMenuItems]="checkedMenuItems"
      (checkClicked)="toggleActive($event)"></app-feature-sidebar>
  </div>
  <div class="right-side">
    <router-outlet></router-outlet>
  </div>
</div>

<ng-template #oneColumn>
  <div class="one-column-layout">
    <router-outlet></router-outlet>
  </div>
</ng-template>
