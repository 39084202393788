<div class="header-container">
  <h1>{{ 'eventPageMergeTags.title' | translate }}</h1>
  <p>{{ 'eventPageMergeTags.description' | translate }}</p>
</div>

<div class="c-my-8">
  <p-divider styleClass="custom-divider-horizontal"></p-divider>
</div>

<div class="body-container">
  <p-accordion
    [multiple]="true"
    styleClass="entities-keys-accordion">
    <p-accordionTab header="{{ 'eventPageMergeTags.userTitle' | translate }}">
      <div class="fields-list-section">
        <app-entity-keys [keys]="userKeys"></app-entity-keys>
      </div>
    </p-accordionTab>
    <p-accordionTab header="{{ 'eventPageMergeTags.userEventTitle' | translate }}">
      <div class="fields-list-section">
        <app-entity-keys [keys]="userEventKeys"></app-entity-keys>
      </div>
    </p-accordionTab>
    <p-accordionTab header="{{ 'eventPageMergeTags.eventTitle' | translate }}">
      <div class="fields-list-section">
        <app-entity-keys [keys]="eventKeys"></app-entity-keys>
      </div>
    </p-accordionTab>
    <p-accordionTab header="{{ 'eventPageMergeTags.hubTitle' | translate }}">
      <div class="fields-list-section">
        <app-entity-keys [keys]="hubKeys"></app-entity-keys>
      </div>
    </p-accordionTab>
  </p-accordion>
</div>

<p-toast>
  <ng-template
    let-message
    pTemplate="message">
    <app-toast
      [severity]="message.severity"
      [summary]="message.summary"
      [detail]="message.detail">
    </app-toast>
  </ng-template>
</p-toast>
