import { Component, OnInit, Signal } from '@angular/core';
import { Router, RouterModule } from '@angular/router';

import { IEvent } from 'src/app/core/models';
import { EventsStore, HubsStore, SessionsStore } from 'src/app/core/stores';
import { ButtonComponent, ButtonSize, ButtonStyle, ButtonType } from 'src/app/standalone/shared';
import { EventSessionSidebarComponent } from '../event-session-sidebar/event-session-sidebar.component';

@Component({
  selector: 'app-event-session-container',
  standalone: true,
  imports: [ButtonComponent, RouterModule, EventSessionSidebarComponent],
  templateUrl: './event-session-container.component.html',
  styleUrl: './event-session-container.component.scss',
})
export class EventSessionContainerComponent implements OnInit {
  buttonType = ButtonType;
  buttonStyle = ButtonStyle;
  buttonSize = ButtonSize;
  sessionTitle: Signal<string>;

  constructor(
    private router: Router,
    private hubsStore: HubsStore,
    private eventsStore: EventsStore,
    private sessionsStore: SessionsStore,
  ) {}

  get event(): IEvent {
    return this.eventsStore.adminEvent;
  }

  ngOnInit(): void {
    this.sessionTitle = this.sessionsStore.sessionTitle;
  }

  onNavigateToSessionsList(): void {
    this.router.navigate([
      `/${this.hubsStore.useHubUrl}/admin/event/${this.event.link}/content/sessions`,
    ]);
  }
}
