<div class="aside-navigation-container">
  <div class="menu-container">
    <ul class="list">
      <li *ngFor="let menuItem of menuItems$ | async">
        <ng-container [ngSwitch]="menuItem.type">
          <ng-container *ngSwitchCase="'divider'">
            <div class="divider"></div>
          </ng-container>
          <ng-container *ngSwitchDefault="">
            <a
              [routerLink]="menuItem.url"
              [routerLinkActive]="menuItem.url ? 'active' : ''"
              (click)="menuItemClick(menuItem)">
              <i [attr.class]="menuItem.iconClass"></i>
              <span class="manu-item-label">
                {{ menuItem.name | translate }}
              </span>
            </a>
          </ng-container>
        </ng-container>
      </li>
    </ul>
  </div>

  <div class="footer-container">
    <div
      *ngIf="eventsStore.event"
      class="social-links">
      <div
        *ngIf="eventsStore.event.facebook"
        class="link"
        (click)="goToLink(eventsStore.event.facebook)">
        <i class="fak fa-lox-social-facebook"></i>
      </div>
      <div
        *ngIf="eventsStore.event.linkedin"
        class="link"
        (click)="goToLink(eventsStore.event.linkedin)">
        <i class="fak fa-lox-social-linkedin"></i>
      </div>
      <div
        *ngIf="eventsStore.event.xing"
        class="link"
        (click)="goToLink(eventsStore.event.xing)">
        <i class="fak fa-lox-social-xing"></i>
      </div>
    </div>

    <div
      class="exit-event-btn"
      pTooltip="{{ 'asideNavigation.exitEventMenuItem' | translate }}"
      tooltipPosition="right"
      tooltipStyleClass="custom-tooltip"
      (click)="onExitEventClick()">
      <i class="far fa-arrow-right-from-bracket"></i>
    </div>
  </div>
</div>
