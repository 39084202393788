import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { ButtonSize } from 'src/app/standalone';

@Component({
  selector: 'app-system-new-email-dialog',
  templateUrl: './system-new-email-dialog.component.html',
  styleUrls: ['./system-new-email-dialog.component.scss'],
})
export class SystemNewEmailDialogComponent {
  buttonSize = ButtonSize;

  constructor(
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
  ) {}

  onCloseDialog(): void {
    this.ref.close();
  }
}
