<ng-container *ngIf="loading">
  <app-loader class="tickets-dialog-loading-container"></app-loader>
</ng-container>

<div
  *ngIf="!loading"
  class="tickets-dialog-container">
  <header class="header">{{ event.title }}</header>
  <p-divider styleClass="custom-divider-horizontal"></p-divider>
  <main class="main">
    <ng-container *ngIf="tickets.length">
      <div
        *ngFor="let ticket of tickets; trackBy: trackByFn"
        class="ticket-item">
        <app-ticket
          [ticket]="ticket"
          (currentNumberOfTickets)="changePurchaseTickets($event, ticket)">
        </app-ticket>
        <div
          *ngIf="ticket.isPossibleBooked === ticketPossibleBooked.NOT_POSSIBLE"
          class="error-block">
          <i class="fa-regular fa-circle-xmark"></i>
          {{ 'multiTicketingTicket.ticketErrorMessage' | translate }}
        </div>
      </div>

      <!-- Email input -->
      <div
        *ngIf="isShowEmailInput"
        class="c-mt-8 w-9">
        <app-input-label
          labelType="required"
          for="email-input"
          text="multiTicketingTicket.email">
        </app-input-label>
        <input
          class="custom-input-text custom-input-text-required"
          [formControl]="email"
          id="email-input"
          pInputText
          type="text"
          placeholder="{{ 'multiTicketingTicket.emailPlaceholder' | translate }}" />
        <app-input-error-message
          [showMessage]="email.invalid && email.touched"
          [errorMessage]="
            email.hasError('email')
              ? ('multiTicketingTicket.emailNotValid' | translate)
              : ('multiTicketingTicket.emailRequired' | translate)
          ">
        </app-input-error-message>
      </div>
    </ng-container>
    <div
      *ngIf="!tickets.length"
      class="mt-8">
      <app-empty-state
        icon="far fa-ticket"
        text="{{ 'adminEventsTickets.emptyState' | translate }}">
      </app-empty-state>
    </div>
  </main>
  <footer
    *ngIf="tickets.length"
    class="footer">
    <div class="footer-top-block">
      <p class="total-amount">
        {{ 'multiTicketingTicket.total' | translate }}
        <!-- TODO: uncomment it during future implementation -->
        <!-- <span>incl. VAT</span> -->
      </p>
      <p class="price">{{ totalAmount }}</p>
    </div>
    <button
      class="primary-text-color"
      pButton
      label="{{ 'multiTicketingTicket.checkout' | translate }}"
      type="button"
      [disabled]="isBtnDisabled"
      (click)="onCheckoutTickets()"></button>
  </footer>
</div>
