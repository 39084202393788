<ng-container *ngIf="loading">
  <app-loader></app-loader>
</ng-container>

<ng-container *ngIf="!loading">
  <div class="header">
    <div class="title">
      {{ 'selectEmailForInvite.selectEmail' | translate }}
    </div>
    <span
      class="search-input p-input-icon-left"
      [ngClass]="{ 'p-input-icon-right': search.value !== '' }">
      <i class="far fa-search search-icon"></i>
      <input
        class="custom-input-text"
        type="text"
        pInputText
        [formControl]="search"
        placeholder="{{ 'adminTable.inputSearchPlaceholder' | translate }}" />
      <i
        *ngIf="search.value !== ''"
        class="reset-btn far fa-times-circle"
        (click)="onClear()">
      </i>
    </span>
    <i
      class="fa-regular fa-xmark close-icon"
      (click)="onCloseDialog()"></i>
  </div>

  <div class="container">
    <admin-table
      emptyStateKey="adminEmailTemplates.emptyState"
      scssClass="table-for-attach show-row-border-bottom"
      [lazyLoading]="loadingEntries"
      [columns]="columns"
      [entries]="entries"
      [lazy]="false"
      [searchValue]="search.value"
      [searchFilterFields]="searchFilterFields"
      [isShowTotalEntries]="false"
      [paginator]="false">
      <ng-template
        let-entry
        epTemplateKey="actionsCell">
        <div class="table-btn-actions-container">
          <app-button
            [buttonType]="buttonType.ROUND"
            [buttonSize]="buttonSize.MEDIUM"
            [buttonStyle]="buttonStyle.FILLED_SECONDARY"
            buttonRoundIcon="fa-regular fa-paper-plane-top"
            (click)="onSelect(entry)" />
        </div>
      </ng-template>
    </admin-table>
  </div>
</ng-container>
