export enum CollectionTypes {
  APPOINTMENTS = 'appointments',
  APPOINTMENTS_USERS = 'appointmentsUsers',
  AWS_EVENT_BRIDGE_EVENTS = 'awsEventBridgeEvents',
  BOOKMARKED_BRANDS = 'bookmarkedBrands',
  BOOKMARKED_CHAT_MESSAGES = 'bookmarkedChatMessages',
  BOOKMARKED_COURSES = 'bookmarkedCourses',
  BOOKMARKED_EVENTS = 'bookmarkedEvents',
  BOOKMARKED_SESSIONS = 'bookmarkedSessions',
  BOOKMARKED_USERS = 'bookmarkedUsers',
  BRAND_COURSES = 'brandCourses',
  BRAND_MEMBER_INVITES = 'brandMemberInvites',
  BRAND_OWNER_INVITES = 'brandOwnerInvites',
  BRAND_PRODUCTS = 'brandProducts',
  BRANDS = 'brands',
  CHAPTERS = 'chapters',
  CHATS = 'chats',
  CHAT_MESSAGES = 'chat-messages',
  CONSENTS = 'consents',
  COURSE_ASSETS = 'courseAssets',
  COURSE_SUBSCRIPTIONS = 'courseSubscriptions',
  COURSES = 'courses',
  COURSES_MAIL_TEMPLATES = 'coursesMailTemplates',
  EVENT_BRANDS = 'eventBrands',
  EVENT_PAGES = 'events/${eventId}/pages',
  EVENT_PRODUCTS = 'eventProducts',
  EVENT_REGISTRATION_FORM = 'eventRegistrationForm',
  EVENT_SUBSCRIPTIONS = 'eventSubscriptions',
  EVENTS = 'events',
  EVENTS_MAIL_TEMPLATES = 'eventsMailTemplates',
  EXPORT = 'export',
  FAILED_LOGIN_ATTEMPTS = 'failedLoginAttempts',
  HUB_BRANDS = 'hubBrands',
  HUB_PAGES = 'hubPages',
  HUB_TAGS = 'hubTags',
  HUBS = 'hubs',
  LIBRARY = 'library',
  MAIL_TEMPLATES = 'mailTemplates',
  NOTIFICATIONS = 'notifications',
  ORDERS = 'orders',
  SESSION = 'session',
  SESSIONS = 'sessions',
  SESSION_ASSETS = 'sessionAssets',
  SETTINGS = 'settings',
  STAGE = 'stage',
  STAGES = 'stages',
  SUBSCRIPTIONS = 'subscriptions',
  SYSTEM_PAGES = 'settings/general/pages',
  TAGS = 'tags',
  TICKETS = 'tickets',
  TRANSLATIONS = 'translations',
  USER_BRANDS = 'userBrands',
  USER_COURSES = 'userCourses',
  USER_COURSES_INVITES = 'userCourseInvites',
  USER_COURSES_TRACKING = 'userCoursesTracking',
  USER_EVENTS = 'userEvents',
  USER_HUBS = 'userHubs',
  USER_INVITES = 'userInvites',
  USER_PAYMENT_SESSIONS = 'userPaymentSessions',
  USER_SESSIONS = 'userSessions',
  USERS = 'users',
  DEVICES = 'devices',
  COUPONS = 'coupons',
}
