<div
  class="card-container"
  (click)="onDownload()">
  <div class="image-container">
    <img [src]="imgUrl" />
  </div>
  <p class="asset-title">{{ asset.fileName }}</p>
  <div class="footer">
    <span>{{ 'assetCard.fileSize' | translate }}: </span>
    <span>{{ formatBytes(asset.fileSize, 2) }}</span>
  </div>
</div>
