import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-input-error-message',
  templateUrl: './input-error-message.component.html',
  styleUrls: ['./input-error-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputErrorMessageComponent {
  @Input() errorMessage: string;
  @Input() showMessage: boolean;
}
