<app-feature-header
  [headerTitle]="event?.title"
  [menuItems]="items"
  backUrl="{{ hubsStore.useHubUrl }}/admin/events">
  <app-button
    class="c-mr-2"
    buttonText="{{ 'adminEvent.viewEvent' | translate }}"
    buttonIconLeft="fa-regular fa-arrow-up-right-from-square"
    [buttonStyle]="buttonStyle.GHOST_PRIMARY"
    [buttonSize]="buttonSize.MEDIUM"
    (click)="navigateToEvent()" />
  <app-button
    [buttonText]="btnLabel | translate"
    [buttonIconLeft]="icon"
    [buttonStyle]="buttonStyle.TERTIARY_PRIMARY"
    [buttonSize]="buttonSize.MEDIUM"
    (click)="toggleEventStatus()" />
</app-feature-header>
<app-feature-main-content></app-feature-main-content>
