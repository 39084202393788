import { Timestamp } from 'src/app/firebase';

export interface IUserHub {
  id: string;
  hubId: string;
  userId: string;
  role: UserHubRoles;
  _hub_title_: string;
  _firstName_: string;
  _lastName_: string;
  _company_: string;
  _position_: string;
  createdAt: Timestamp;
  createdBy: string;
  updatedAt: Timestamp;
  updatedBy: string;
}

export enum UserHubRoles {
  OWNER = 'owner',
  ADMIN = 'admin',
  USER = 'user',
}
