import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

import { brandLevels } from 'src/app/core/enums';
import { IBrand, ITag, IEvent, IBrandProduct, IEventProduct } from 'src/app/core/models';
import { HubsStore, EventsStore, BrandsStore, UsersStore } from 'src/app/core/stores';
import {
  TagsService,
  BrandsService,
  BrandProductsService,
  EventsService,
} from 'src/app/core/services';
import { ServerTimestamp } from 'src/app/firebase';

@Component({
  selector: 'app-event-brand-event-details',
  templateUrl: './event-brand-event-details.component.html',
  styleUrls: ['./event-brand-event-details.component.scss'],
})
export class EventBrandEventDetailsComponent implements OnInit {
  public loading = true;
  public isUpdating = false;
  public tags: ITag[] = [];
  public levels: Array<{ key: string; value: string }> = brandLevels;
  public brandEvent = null;
  public eventProducts: IEventProduct[];
  public products: IBrandProduct[];
  public eventBrandProducts: IBrandProduct[];
  public form: UntypedFormGroup = new UntypedFormGroup({
    tags: new UntypedFormControl([]),
    level: new UntypedFormControl('none'),
    products: new UntypedFormControl([]),
    featuredProducts: new UntypedFormControl([]),
  });

  public get brand(): IBrand {
    return this.brandsStore.adminBrand;
  }

  public get event(): IEvent {
    return this.eventsStore.adminEvent;
  }

  public get canUpdate(): boolean {
    return !this.isUpdating && this.form.valid && this.form.dirty;
  }

  public get eventProductsListing(): { id: string; title: string }[] {
    return this.eventProducts.map((ep) => ({
      id: ep.id,
      title: this.products.find((p) => p.id === ep.productId).title,
    }));
  }

  constructor(
    private messageService: MessageService,
    private tagService: TagsService,
    public hubsStore: HubsStore,
    private eventsStore: EventsStore,
    private brandsStore: BrandsStore,
    private brandsService: BrandsService,
    private usersStore: UsersStore,
    private brandProductsService: BrandProductsService,
    private eventsService: EventsService,
    private translateService: TranslateService,
  ) {}

  public selectTags(tagIds: string[]): void {
    this.form.patchValue({ tags: tagIds });
  }

  public selectEventProducts(products: string[]): void {
    this.form.patchValue({ products });
  }

  public selectFeaturedEventProducts(featuredProducts: string[]) {
    this.form.patchValue({ featuredProducts });
  }

  async ngOnInit(): Promise<void> {
    const [tags, brandEvent, products, eventProducts, eventBrandProducts] = await Promise.all([
      this.tagService.getByIdsOrderedByTitle(this.event.tags),
      this.brandsService.getBrandEvent(this.event.id, this.brand.id),
      this.brandProductsService.getAll(this.brand.id),
      this.eventsService.getAllEventProducts(this.brand.id, this.event.id),
      this.brandProductsService.getEventBrandProducts(this.event.id, this.brand.id),
    ]);

    this.tags = tags;
    this.brandEvent = brandEvent;
    this.products = products;
    this.eventProducts = eventProducts;
    this.eventBrandProducts = eventBrandProducts;

    this.form.patchValue({
      ...{
        tags: this.brandEvent.tags,
        level: this.brandEvent.level,
        products: this.eventBrandProducts.map((x) => x.id),
        featuredProducts: this.eventProducts.filter((x) => x.featured).map((x) => x.id),
      },
    });
    this.form.updateValueAndValidity();
    this.form.valueChanges.subscribe(() => this.form.markAsDirty());
    this.loading = false;
  }

  public async update(): Promise<void> {
    this.isUpdating = true;

    try {
      const form: any = this.form.getRawValue();
      const products: IEventProduct[] = form.products.map((productId: string) => {
        const brandProduct = this.products.find((x) => x.id === productId);
        const existingEventProduct = this.eventProducts.find((x) => x.productId === productId);

        return {
          id: existingEventProduct ? existingEventProduct.id : null,
          eventId: this.event.id,
          productId: brandProduct?.id ?? '',
          brandId: brandProduct?.brandId ?? '',
          eventTitle: this.event.title.toLowerCase(),
          productTitle: brandProduct?.title?.toLowerCase() ?? '',
          brandName: brandProduct?._brandName_ ?? '',
          createdAt: ServerTimestamp(),
          createdBy: this.usersStore.userId,
          updatedAt: existingEventProduct ? ServerTimestamp() : null,
          updatedBy: existingEventProduct ? this.usersStore.userId : null,
          featured:
            !!existingEventProduct && form.featuredProducts.includes(existingEventProduct.id),
        } as IEventProduct;
      });

      await this.brandsService.updateBrandEventEntry(this.brandEvent.id, form.tags, form.level);
      await this.eventsService.saveEventProducts(this.event.id, this.brand.id, products);

      this.isUpdating = false;
      this.messageService.add({
        severity: 'success',
        summary: this.translateService.instant('success'),
        detail: this.translateService.instant('adminUserEventDetails.successfullyUpdated'),
        styleClass: 'custom-toast',
      });

      await this.ngOnInit();
    } catch (error) {
      console.log(error);
      this.isUpdating = false;
      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('error'),
        detail: this.translateService.instant(`adminUserEventDetails.error}`),
        styleClass: 'custom-toast',
      });
    }
  }
}
