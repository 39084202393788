import * as moment from 'moment';
import { Component, Input, EventEmitter, Output } from '@angular/core';

import { getUserNameAbbreviation, getTimeForEnglishLocale, getTime } from 'src/app/core/utils';
import { IAppointment, IUser, INotification } from 'src/app/core/models';
import { parseToMoment } from 'src/app/shared';
import { TranslationsService } from 'src/app/core/services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-appointment-accepted-notification',
  templateUrl: './appointment-accepted-notification.component.html',
  styleUrls: ['./appointment-accepted-notification.component.scss'],
})
export class AppointmentAcceptedNotificationComponent {
  @Input() notification: INotification;
  @Output() joiningMeeting: EventEmitter<INotification> = new EventEmitter<INotification>();

  public dailyMeetingSublink = environment.dailyMeetingSublink;
  imageText = getUserNameAbbreviation;

  get user(): IUser {
    return this.notification?.user;
  }

  get host(): IUser {
    return this.notification?.host;
  }

  get appointment(): IAppointment {
    return this.notification?.appointment;
  }

  get isShowJoinBtn(): boolean {
    return moment().isBetween(
      parseToMoment(this.appointment.startDate),
      parseToMoment(this.appointment.endDate),
    );
  }

  get textForTooltip(): string {
    if (moment().isBefore(parseToMoment(this.appointment.startDate))) {
      return this.translationsService.getTranslationSync(
        'notifications.joinBtnTooltipBeforeStartMeeting',
      );
    }

    return this.translationsService.getTranslationSync(
      'notifications.joinBtnTooltipAfterStartMeeting',
    );
  }

  get notificationText(): string {
    const lang: string = this.translationsService.getCurrentLanguage;
    const startDate = `<span>${parseToMoment(this.appointment.startDate).format('DD.MM.YYYY')}</span>`;
    const endDate = `<span>${parseToMoment(this.appointment.endDate).format('DD.MM.YYYY')}</span>`;
    let startTime = '';
    let endTime = '';

    switch (lang) {
      case 'en':
        startTime = `<span>${getTimeForEnglishLocale(this.appointment.startDate)}</span>`;
        endTime = `<span>${getTimeForEnglishLocale(this.appointment.endDate)}</span>`;
        break;
      case 'de':
        startTime = `<span>${getTime(this.appointment.startDate)}</span>`;
        endTime = `<span>${getTime(this.appointment.endDate)}</span>`;
    }

    if (
      parseToMoment(this.appointment.startDate).isSame(
        parseToMoment(this.appointment.endDate).toDate(),
        'day',
      )
    ) {
      return `
      ${this.translationsService.getTranslationSync('notifications.acceptedSameDate', {
        host: `${this.host.firstName} ${this.host.lastName}`,
        title: this.appointment.meetingName,
        date: startDate,
        startTime,
        endTime,
      })}
    `;
    }
    return `
      ${this.translationsService.getTranslationSync('notifications.acceptedDifferentDate', {
        host: `${this.host.firstName} ${this.host.lastName}`,
        title: this.appointment.meetingName,
        start: `${startDate} - ${startTime}`,
        end: `${endDate} - ${endTime}`,
      })}
    `;
  }

  constructor(private translationsService: TranslationsService) {}

  onJoiningMeeting(): void {
    this.joiningMeeting.emit(this.notification);
  }
}
