<div class="container">
  <header class="header">
    <h1>{{ 'eventConsentDialog.eventRegistration' | translate }}</h1>
    <i
      class="fa-regular fa-xmark close-icon"
      (click)="closeDialog()"></i>
  </header>
  <div class="left-column">
    <h2>{{ 'eventConsentDialog.eventConsent' | translate }}</h2>
    <p>{{ 'eventConsentDialog.eventConsentDescription' | translate }}</p>
  </div>
  <form
    [formGroup]="form"
    class="right-column">
    <p-dropdown
      class="custom-dropdown"
      panelStyleClass="custom-dropdown-panel"
      [options]="consentTypes"
      formControlName="type"
      placeholder="{{ 'eventConsentDialog.typeLabel' | translate }}">
    </p-dropdown>

    <ng-container *ngIf="typeOfConsent === 'single'">
      <!-- Consent required input -->
      <div class="form-field-input-switch-block c-my-4 c-px-0">
        <p-inputSwitch
          class="custom-input-switch"
          formControlName="required"
          inputId="required-input">
        </p-inputSwitch>
        <app-input-label
          labelType="switch-checkbox"
          for="required-input"
          text="eventConsentDialog.checkboxRequiredToggle">
        </app-input-label>
      </div>

      <!-- Consent title input -->
      <div>
        <app-input-label
          labelType="required"
          for="consent-title-input"
          text="eventConsentDialog.consentTitleLabel">
        </app-input-label>
        <input
          class="custom-input-text custom-input-text-required"
          formControlName="title"
          id="consent-title-input"
          pInputText
          type="text"
          placeholder="{{ 'eventConsentDialog.consentTitleLabel' | translate }}" />
        <app-input-error-message
          [showMessage]="form.controls.title.invalid && form.controls.title.touched"
          errorMessage="{{ 'application.forms.required' | translate }}">
        </app-input-error-message>
      </div>

      <!-- Description textarea -->
      <div class="c-mb-6">
        <app-input-label text="eventConsentDialog.consentDescription"></app-input-label>
        <p-editor
          styleClass="custom-editor"
          formControlName="description"
          placeholder="{{ 'eventConsentDialog.consentDescription' | translate }}">
        </p-editor>
      </div>
    </ng-container>

    <ng-container *ngIf="typeOfConsent === 'multi'">
      <ng-container
        formArrayName="options"
        *ngFor="let option of optionsArray['controls']; let i = index">
        <div [formGroupName]="i">
          <!-- Consent title input -->
          <div class="c-mt-4">
            <app-input-label
              labelType="required"
              for="consent-title-input"
              [text]="
                i === 0
                  ? 'eventConsentDialog.consentDefaultTitleLabel'
                  : 'eventConsentDialog.consentAlternativeTitleLabel'
              ">
            </app-input-label>
            <input
              class="custom-input-text custom-input-text-required"
              formControlName="title"
              id="consent-title-input"
              pInputText
              type="text"
              [placeholder]="
                i === 0
                  ? ('eventConsentDialog.consentDefaultTitleLabel' | translate)
                  : ('eventConsentDialog.consentAlternativeTitleLabel' | translate)
              " />
            <app-input-error-message
              [showMessage]="option.controls.title.invalid && option.controls.title.touched"
              errorMessage="{{ 'application.forms.required' | translate }}">
            </app-input-error-message>
          </div>

          <!-- Description textarea -->
          <div class="c-mb-6">
            <app-input-label
              [text]="
                i === 0
                  ? 'eventConsentDialog.consentDefaultDescription'
                  : 'eventConsentDialog.consentAlternativeDescription'
              ">
            </app-input-label>
            <p-editor
              styleClass="custom-editor"
              formControlName="description"
              [placeholder]="
                i === 0
                  ? ('eventConsentDialog.consentDefaultDescription' | translate)
                  : ('eventConsentDialog.consentAlternativeDescription' | translate)
              ">
            </p-editor>
          </div>
        </div>

        <div
          *ngIf="i === 0"
          class="c-my-9">
          <p-divider styleClass="custom-divider-horizontal"></p-divider>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="typeOfConsent === 'pop-up'">
      <!-- Consent title input -->
      <div class="c-mt-4">
        <app-input-label
          labelType="required"
          for="consent-title-input"
          text="eventConsentDialog.consentTitleLabel">
        </app-input-label>
        <input
          class="custom-input-text custom-input-text-required"
          formControlName="title"
          id="consent-title-input"
          pInputText
          type="text"
          placeholder="{{ 'eventConsentDialog.consentTitleLabel' | translate }}" />
        <app-input-error-message
          [showMessage]="form.controls.title.invalid && form.controls.title.touched"
          errorMessage="{{ 'application.forms.required' | translate }}">
        </app-input-error-message>
      </div>

      <!-- Description textarea -->
      <div class="c-mb-4">
        <app-input-label text="eventConsentDialog.consentDescription"></app-input-label>
        <p-editor
          styleClass="custom-editor"
          formControlName="description"
          placeholder="{{ 'eventConsentDialog.consentDescription' | translate }}">
        </p-editor>
      </div>

      <!-- Is set live input -->
      <div class="form-field-input-switch-block c-px-0 c-mb-6">
        <p-inputSwitch
          class="custom-input-switch"
          formControlName="isSetLive"
          inputId="is-set-live-input">
        </p-inputSwitch>
        <app-input-label
          labelType="switch-checkbox"
          for="is-set-live-input"
          text="eventConsentDialog.setLive">
        </app-input-label>
      </div>
    </ng-container>

    <div class="flex justify-content-end">
      <app-button
        [buttonText]="textForButton | translate"
        [buttonSize]="buttonSize.MEDIUM"
        [loading]="isCreatingOrUpdating"
        [disabled]="isBtnDisable"
        (click)="save()" />
    </div>
  </form>
</div>

<p-toast>
  <ng-template
    let-message
    pTemplate="message">
    <app-toast
      [severity]="message.severity"
      [summary]="message.summary"
      [detail]="message.detail">
    </app-toast>
  </ng-template>
</p-toast>
