<ng-container *ngIf="loading">
  <app-loader></app-loader>
</ng-container>

<div
  *ngIf="!loading"
  class="event-notification-form-container">
  <div class="grid">
    <div class="col-8 left-side">
      <div class="header-section grid">
        <div class="col">
          <h1>{{ form.controls.title.value }}</h1>
        </div>
      </div>

      <!-- Description textarea -->
      <div class="description">
        <app-input-label
          labelType="required"
          text="adminEventNotificationForm.descriptionLabel">
        </app-input-label>
        <p-editor
          styleClass="custom-editor"
          [formControl]="form.controls.description"
          placeholder="{{ 'description' | translate }}">
        </p-editor>
      </div>
    </div>

    <div class="col-4 right-side">
      <div class="form-section">
        <h3>
          {{
            (this.isEditing
              ? 'adminEventNotificationForm.editNotificationTitle'
              : 'adminEventNotificationForm.newNotificationTitle'
            ) | translate
          }}
        </h3>
        <form [formGroup]="form">
          <!-- Title input -->
          <div>
            <app-input-label
              labelType="required"
              for="title-input"
              text="adminEventNotificationForm.titleLabel">
            </app-input-label>
            <input
              class="custom-input-text custom-input-text-required"
              formControlName="title"
              id="title-input"
              pInputText
              type="text"
              placeholder="{{ 'adminEventNotificationForm.titleLabel' | translate }}" />
            <app-input-error-message
              [showMessage]="form.controls.title.invalid && form.controls.title.touched"
              errorMessage="{{ 'adminEventNotificationForm.titleRequired' | translate }}">
            </app-input-error-message>
          </div>

          <!-- Type input -->
          <div class="c-mb-4">
            <app-input-label
              for="type-input"
              text="adminEventNotificationForm.typeLabel">
            </app-input-label>
            <p-dropdown
              class="custom-dropdown"
              panelStyleClass="custom-dropdown-panel"
              [options]="notificationTypes"
              formControlName="type"
              inputId="type-input"
              placeholder="{{ 'adminEventNotificationForm.typeLabel' | translate }}">
            </p-dropdown>
          </div>

          <div class="actions-container txt-a-r">
            <button
              class="primary-text-color"
              pButton
              type="button"
              iconPos="right"
              label="{{ 'adminEventNotificationForm.saveBtn' | translate }}"
              [icon]="saving ? 'pi pi-spin pi-spinner' : ''"
              [disabled]="form.invalid"
              (click)="save()"></button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
