import { Component, input, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipModule } from 'primeng/tooltip';

import { ButtonSize, ButtonStyle, ButtonType } from '../../enums';

@Component({
  selector: 'app-button',
  standalone: true,
  imports: [CommonModule, TooltipModule],
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss',
})
export class ButtonComponent {
  buttonText = input<string>();
  buttonType = input<ButtonType>(ButtonType.DEFAULT);
  buttonStyle = input<ButtonStyle>(ButtonStyle.FILLED_PRIMARY);
  buttonSize = input<ButtonSize>(ButtonSize.LARGE);
  buttonIconLeft = input<string>();
  buttonIconRight = input<string>();
  buttonRoundIcon = input<string>();
  loading = input<boolean>(false);
  disabled = input<boolean>(false);
  buttonTooltip = input<string>();
  buttonTooltipPosition = input<'top' | 'bottom' | 'right' | 'left'>('right');
  scssStyles = input<{ [key: string]: string }>();

  typeOfButton = signal<typeof ButtonType>(ButtonType);

  get classesForDefaultButton(): string {
    return `btn default-btn ${this.buttonStyle()} ${this.buttonSize()}`;
  }

  get classesForRoundButton(): string {
    return `btn round-btn ${this.buttonStyle()} ${this.buttonSize()}`;
  }

  get classesForButtonLoading(): string {
    switch (this.buttonType()) {
      case ButtonType.DEFAULT:
        return `${this.classesForDefaultButton} loading`;
      case ButtonType.ROUND:
        return `${this.classesForRoundButton} loading`;
      default:
        return `${this.classesForDefaultButton} loading`;
    }
  }
}
