import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent {
  @Input() tooltipText: string;
  @Input() tooltipPosition: string = 'right';
  @Input() tooltipId: string;
  @Input() tooltipStyleClass: string;
  @Input() isMobile: boolean;
}
