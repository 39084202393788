import { Injectable } from '@angular/core';
import { IAsset, ISessionAsset } from 'src/app/core/models';
import { Firestore, IFirestore } from 'src/app/firebase';
import { CollectionTypes } from 'src/app/shared';
import { API_ROUTES as apiRoutes } from 'src/app/shared';
import { SessionsStore, UsersStore } from '../../stores';
import { Timestamp } from 'src/app/firebase';

@Injectable({
  providedIn: 'root',
})
export class SessionAssetsService {
  private fireStore: IFirestore;

  constructor(
    private sessionsStore: SessionsStore,
    private usersStore: UsersStore,
  ) {
    this.fireStore = Firestore();
  }

  async getOne(id: string, eventId: string): Promise<ISessionAsset> {
    try {
      const sessionAsset = (
        await this.fireStore.collection(apiRoutes.sessionAssets(eventId)).doc(id).get()
      ).data() as ISessionAsset;
      return sessionAsset;
    } catch (error) {
      console.error(error);
    }
  }

  async getAllSessionAssetsBySessionAndEventId(
    sessionId: string,
    eventId: string,
  ): Promise<ISessionAsset[]> {
    const sessionDoc = await this.fireStore
      .collection(apiRoutes.sessionAssets(eventId))
      .where('sessionId', '==', sessionId)
      .get();

    return sessionDoc.docs.map((doc) => doc.data() as ISessionAsset);
  }

  async createSessionAsset(eventId: string, asset: IAsset): Promise<ISessionAsset> {
    try {
      const preAssetReqDocRef = this.fireStore.collection(apiRoutes.sessionAssets(eventId)).doc();
      const newSessionAsset: ISessionAsset = {
        id: preAssetReqDocRef.id,
        _asset_title_: asset.title.toLowerCase(),
        _session_title_: this.sessionsStore.adminSession.title.toLowerCase(),
        assetId: asset.id,
        sessionId: this.sessionsStore.adminSession.id,
        createdAt: Timestamp.now(),
        createdBy: this.usersStore.user.id,
        updatedAt: null,
        updatedBy: null,
      };

      await preAssetReqDocRef.set({ ...newSessionAsset });

      return newSessionAsset;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  async getAssetsByIds(
    assetIds: string[],
    entry?: IAsset,
    order: 'asc' | 'desc' = 'desc',
    pageSize: number = 12,
  ): Promise<IAsset[]> {
    let assetIdSearch: string[] = [];
    try {
      const assets = [];
      let query;

      const startFromIndex = entry ? assetIds.indexOf(entry.id) + 1 : 0;
      assetIdSearch = assetIds.splice(startFromIndex, pageSize);

      while (assetIdSearch?.length > 0) {
        const chunk = assetIdSearch.splice(0, 10);

        query = await this.fireStore
          .collection(apiRoutes.library)
          .where('id', 'in', chunk)
          .orderBy('_title_', order)
          .get();

        if (!query.empty) {
          query.forEach((doc) => {
            const ev = doc.data() as IAsset;
            if (!assetIds.includes(ev.id)) {
              assets.push(ev);
            }
          });
        }
      }

      return assets;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  async delete(eventId: string, sessionId: string, assetId: string): Promise<void> {
    try {
      const assetDoc = await this.fireStore
        .collection(apiRoutes.sessionAssets(eventId))
        .where('sessionId', '==', sessionId)
        .where('assetId', '==', assetId)
        .get();

      await this.fireStore
        .collection(apiRoutes.sessionAssets(eventId))
        .doc(assetDoc.docs[0].id)
        .delete();
    } catch (error) {
      console.error(error);
    }
  }
}
