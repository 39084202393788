import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class IndexedDbService {
  // =====================================================================================================================
  // Documentation: https://developer.mozilla.org/en-US/docs/Web/API/IDBObjectStore
  // =====================================================================================================================

  private isDoneSubject$ = new BehaviorSubject<boolean>(false);
  public isDone$ = this.isDoneSubject$;

  private readonly databaseName: string = environment.indexedDbName;
  private readonly databaseVersion: number = environment.indexedDbVersion;

  private DB_CONNECTION = null;
  private DB: IDBDatabase = null;

  private USER_DEVICE_TABLE: IDBObjectStore = null;
  private USER_TABLE: IDBObjectStore = null;
  private USERS_TABLE: IDBObjectStore = null;
  private EVENTS_TABLE: IDBObjectStore = null;
  private BRANDS_TABLE: IDBObjectStore = null;
  private TAGS_TABLE: IDBObjectStore = null;

  private enableLogs: boolean = true;

  public get database(): IDBDatabase {
    return this.DB;
  }

  constructor() {
    this.log('========================================================================');
    this.log('============================== INDEXED DB ==============================');
    this.log('========================================================================');

    if (!('indexedDB' in window)) {
      this.log(
        "========================= This browser doesn't support IndexedDB =======================",
      );
    } else {
      this.log('========================= This browser support IndexedDB =======================');
      this.DB_CONNECTION = indexedDB.open(this.databaseName, this.databaseVersion);

      this.DB_CONNECTION.onupgradeneeded = (event) => {
        // triggers if the client had no database
        this.log('========================= PERFORM INITIALIZATION =======================');
        this.DB = event.target.result;
        this.createDatabaseObjectStores();
      };

      this.DB_CONNECTION.onerror = () => {
        console.error('Error', this.DB_CONNECTION.error);
      };

      this.DB_CONNECTION.onsuccess = () => {
        // continue working with database using db object
        this.log('====================== INDEXED DB IS READY TO USE ======================');

        this.DB = this.DB_CONNECTION.result;
        this.getDatabaseObjectStoresReference();
        this.isDoneSubject$.next(true);
      };
    }
  }

  private createDatabaseObjectStores() {
    // Create tables:
    if (!this.DB.objectStoreNames.contains('userDevice'))
      this.USER_DEVICE_TABLE = this.DB.createObjectStore('userDevice', { keyPath: '_userDevice' });
    if (!this.DB.objectStoreNames.contains('user'))
      this.USER_TABLE = this.DB.createObjectStore('user', { keyPath: '_user' });
    // if (!this.DB.objectStoreNames.contains("users")) this.USERS_TABLE = this.DB.createObjectStore("users", { keyPath: "_id" });
    // if (!this.DB.objectStoreNames.contains("events")) this.EVENTS_TABLE = this.DB.createObjectStore("events", { keyPath: "_id" });
    // if (!this.DB.objectStoreNames.contains("brands")) this.BRANDS_TABLE = this.DB.createObjectStore("brands", { keyPath: "_id" });
    // if (!this.DB.objectStoreNames.contains("tags")) this.TAGS_TABLE = this.DB.createObjectStore("tags", { keyPath: "_id" });
  }

  private getDatabaseObjectStoresReference() {
    // Get reference for userDevice table:
    if (this.DB.objectStoreNames.contains('userDevice'))
      this.USER_DEVICE_TABLE = this.DB.transaction('userDevice', 'readwrite').objectStore(
        'userDevice',
      );
    // Get reference for user table:
    if (this.DB.objectStoreNames.contains('user'))
      this.USER_TABLE = this.DB.transaction('user', 'readwrite').objectStore('user');
    // Get reference for users table:
    // if (this.DB.objectStoreNames.contains("users")) this.USERS_TABLE = this.DB.transaction("users", 'readwrite').objectStore("users");
    // Get reference for events table:
    // if (this.DB.objectStoreNames.contains("events")) this.EVENTS_TABLE = this.DB.transaction("events", 'readwrite').objectStore("events");
    // Get reference for brands table:
    // if (this.DB.objectStoreNames.contains("brands")) this.BRANDS_TABLE = this.DB.transaction("brands", 'readwrite').objectStore("brands");
    // Get reference for tags table:
    // if (this.DB.objectStoreNames.contains("tags")) this.TAGS_TABLE = this.DB.transaction("tags", 'readwrite').objectStore("tags");
  }

  public async get(tableName: string, key: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.log('========================================================================');
      this.log('======================== GET OPERATION STARTED... ======================');
      this.log('========================================================================');

      // open a read/write db transaction, ready for retrieving the data
      const transaction = this.DB.transaction([tableName], 'readwrite');

      // report on the success of the transaction completing, when everything is done
      transaction.oncomplete = (event) => {
        this.log('Transaction completed.');
      };

      transaction.onerror = (event) => {
        this.log(`Transaction not opened due to error: ${transaction.error}`);
        reject(transaction.error);
      };

      // create an object store on the transaction
      const objectStore = transaction.objectStore(tableName);

      // Make a request to get a record by key from the object store
      const objectStoreRequest = objectStore.get(key);

      objectStoreRequest.onsuccess = (event) => {
        // report the success of our request
        this.log('Request successful.');
        resolve(objectStoreRequest.result);
      };
    });
  }

  public async getAll(tableName: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.log('========================================================================');
      this.log('====================== GET ALL OPERATION STARTED... ====================');
      this.log('========================================================================');

      const transaction = this.DB.transaction(tableName, 'readonly');

      // report on the success of the transaction completing, when everything is done
      transaction.oncomplete = (event) => {
        this.log('Transaction completed.');
      };

      transaction.onerror = (event) => {
        this.log(`Transaction not opened due to error: ${transaction.error}`);
        reject(transaction.error);
      };

      const objectStore = transaction.objectStore(tableName);

      const objectStoreRequest = objectStore.getAll();

      objectStoreRequest.onsuccess = (event) => {
        // report the success of our request
        this.log('Request successful.', event);
        resolve(event.target['result']);
      };
    });
  }

  public async add(tableName: string, entry: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.log('========================================================================');
      this.log('======================== ADD OPERATION STARTED... ======================');
      this.log('========================================================================');
      // open a read/write db transaction, ready for adding the data
      const transaction = this.DB.transaction([tableName], 'readwrite');

      // report on the success of the transaction completing, when everything is done
      transaction.oncomplete = (event) => {
        this.log('Transaction completed.');
        resolve(event);
      };

      transaction.onerror = (event) => {
        this.log('Transaction not opened due to error.', event);
        reject(event);
      };

      // create an object store on the transaction
      const objectStore = transaction.objectStore(tableName);

      // Make a request to add our newItem object to the object store
      const objectStoreRequest = objectStore.add(entry);
      objectStoreRequest.onsuccess = (event) => this.log('Request successful.', event); // report the success of our request
    });
  }

  public async put(tableName: string, entry: any, key: IDBValidKey = undefined): Promise<any> {
    return new Promise((resolve, reject) => {
      this.log('========================================================================');
      this.log('======================== ADD OPERATION STARTED... ======================');
      this.log('========================================================================');
      // open a read/write db transaction, ready for adding the data
      const transaction = this.DB.transaction([tableName], 'readwrite');

      // report on the success of the transaction completing, when everything is done
      transaction.oncomplete = (event) => {
        this.log('Transaction completed.');
        resolve(event);
      };

      transaction.onerror = (event) => {
        this.log('Transaction not opened due to error.', event);
        reject(event);
      };

      // create an object store on the transaction
      const objectStore = transaction.objectStore(tableName);

      // Make a request to add our newItem object to the object store
      const objectStoreRequest = objectStore.put(entry, key);
      objectStoreRequest.onsuccess = (event) => this.log('Request successful.', event); // report the success of our request
    });
  }

  public async delete(tableName: string, key: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.log('========================================================================');
      this.log('======================= DELETE OPERATION STARTED... ====================');
      this.log('========================================================================');

      // open a database transaction and delete the task, finding it by the name we retrieved above
      let transaction = this.DB.transaction([tableName], 'readwrite');
      let request = transaction.objectStore(tableName).delete(key);

      request.onsuccess = (event) => this.log('Request successful.', event); // report the success of our request

      // report that the data item has been deleted
      transaction.oncomplete = (e) => {
        this.log(`Task "${key}" deleted.`);
        resolve(e);
      };

      transaction.onerror = (event) => {
        this.log('Transaction not opened due to error.', event);
        reject(event);
      };
    });
  }

  public async countEntries(tableName: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.log('========================================================================');
      this.log('======================= COUNT OPERATION STARTED... =====================');
      this.log('========================================================================');

      const transaction = this.DB.transaction([tableName], 'readonly');
      const objectStore = transaction.objectStore(tableName);

      const countRequest = objectStore.count();
      countRequest.onsuccess = () => resolve(countRequest.result);

      transaction.oncomplete = (e) => this.log(`Transaction completed.`);

      transaction.onerror = (event) => {
        this.log('Transaction not opened due to error.', event);
        reject(event);
      };
    });
  }

  public async clearAllEntries(tableName: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.log('========================================================================');
      this.log('======================= CLEAR OPERATION STARTED... =====================');
      this.log('========================================================================');

      // open a read/write db transaction, ready for clearing the data
      const transaction = this.DB.transaction([tableName], 'readwrite');

      // report on the success of the transaction completing, when everything is done
      transaction.oncomplete = (event) => {
        this.log('Transaction completed.');
        resolve(event);
      };

      transaction.onerror = (event) => {
        this.log(`Transaction not opened due to error: ${transaction.error}`);
        reject(event);
      };

      // create an object store on the transaction
      const objectStore = transaction.objectStore(tableName);

      // Make a request to clear all the data out of the object store
      const objectStoreRequest = objectStore.clear();
      objectStoreRequest.onsuccess = (event) => this.log('Request successful.'); // report the success of our request
    });
  }

  public async deleteDatabase(): Promise<any> {
    return new Promise((resolve, reject) => {
      const deleteRequest = indexedDB.deleteDatabase(this.databaseName);

      deleteRequest.onerror = (error) => {
        console.error('Error deleting database.', error);
        reject(error);
      };

      deleteRequest.onsuccess = (event) => {
        console.error('Database deleted successfully', event);
        resolve(event);
      };
    });
  }

  private log(msg, param = null) {
    if (this.enableLogs) console.log(msg, param);
  }
}
