<div class="event-speaker-container">
  <header class="header">
    <app-button
      [buttonType]="buttonType.ROUND"
      [buttonSize]="buttonSize.MEDIUM"
      [buttonStyle]="buttonStyle.GHOST_PRIMARY"
      buttonRoundIcon="fa-regular fa-chevron-left"
      (click)="onNavigateToSpeakersOrAttendeesList()" />
    <h2 class="user-name">{{ user?.firstName }} {{ user?.lastName }}</h2>
  </header>
  <aside class="aside">
    <app-event-attendee-speaker-sidebar></app-event-attendee-speaker-sidebar>
  </aside>
  <main class="main">
    <router-outlet></router-outlet>
  </main>
</div>
