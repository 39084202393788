import { Pipe, PipeTransform } from '@angular/core';

import { AppStore } from 'src/app/app.store';

@Pipe({
  name: 'imageSize',
})
export class ImageSizePipe implements PipeTransform {
  constructor(public appStore: AppStore) {}

  transform(image: any, size: 'small' | 'medium' | 'large' | 'normal' = null): string {
    // ==================
    // Temporary solution
    return image;
    // ==================

    // ======================================================================
    // use size “S“ - 320px — 480px: Mobile devices
    // use size “S“ - 481px — 768px: iPads, Tablets
    // use size “S“ - 769px — 1024px: Small screens, laptops
    // use size “S“ - HD: 720p image resolution (1,280 x 720 pixels – approx. 1 million total)
    // use size “S“ - Full HD (FHD): 1080p image resolution (1,920 x 1,080 pixels – approx. 2 million total)
    // use size “M“ - Quad HD (QHD): 1440p image resolution (2,560 x 1,440 pixels – approx. 4 million total)
    // use size “L“ - Ultra HD (UHD)/4K: 2160p image resolution (3,840 x 2,160 pixels – approx. 8 million total)
    // ======================================================================
    let url = '';
    let urlToReturn = '';

    if (typeof image === 'string') {
      url = image;
    } else if (typeof image === 'object') {
      url = image.changingThisBreaksApplicationSecurity;
    }

    // console.log('>>>>>>>');
    // console.log(url);
    // console.log(!url, url.indexOf(`/v0/b/${environment.firebase.storageBucket}/o`) === -1, url.includes('/assets/images/'));

    if (
      !url ||
      url.indexOf(`/v0/b/${this.appStore.environment.firebase.storageBucket}/o`) === -1 ||
      url.includes('/assets/images/')
    ) {
      return url;
    }

    // extract url components
    const [uri, queryParams] = url.split('?');
    const width = window.screen.width;
    const height = window.screen.height;
    const screenSize = width < 1281 ? 'small' : width < 2561 ? 'medium' : 'large';

    if (size) {
      if (size !== 'normal' && ['small', 'medium', 'large'].includes(size)) {
        // rebuild the url following the resized image pattern
        urlToReturn = [uri, '_', size, '?alt=media'].join('');
      } else {
        urlToReturn = url;
      }
    } else {
      urlToReturn = [uri, '_', screenSize, '?alt=media'].join('');
    }

    // console.log(urlToReturn);
    return urlToReturn;
  }
}
