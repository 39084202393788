import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { wVLogo } from 'src/app/shared';
import { HubsStore } from 'src/app/core/stores';

@Component({
  selector: 'app-create-account-dialog',
  templateUrl: './create-account-dialog.component.html',
  styleUrls: ['./create-account-dialog.component.scss'],
})
export class CreateAccountDialogComponent implements OnInit {
  isSalesforceSSO = false;
  WVLogo = wVLogo;

  private urlForNavigate: string;

  constructor(
    private ref: DynamicDialogRef,
    private router: Router,
    private config: DynamicDialogConfig,
    private hubsStore: HubsStore,
  ) {}

  ngOnInit(): void {
    this.urlForNavigate = this.config.data.url;
    this.isSalesforceSSO = this.config.data.isSalesforceSSO;
  }

  onCreateAccount(): void {
    this.ref.close();
    this.router.navigate([`${this.hubsStore.useHubUrl}/signup`], {
      state: { url: this.urlForNavigate },
    });
  }

  onLogIn(): void {
    this.ref.close();
    this.router.navigate([`${this.hubsStore.useHubUrl}/signin`], {
      state: { url: this.urlForNavigate },
    });
  }

  onSignupWithSalesforce(): void {
    this.ref.close(true);
  }
}
