@if (loading()) {
  <app-loader></app-loader>
} @else {
  <div class="user-courses-container">
    <admin-table-filter-navbar (onSearchTermChange)="searchValue = $event">
    </admin-table-filter-navbar>
    <admin-table
      [columns]="columns()"
      [entries]="courses()"
      [paginator]="true"
      [lazy]="false"
      emptyStateKey="adminCourseList.emptyState"
      emptyStateIcon="far fa-graduation-cap"
      [searchValue]="searchValue"
      (rowClicked)="onRowClicked($event)">
      <ng-template
        let-entry
        epTemplateKey="titleCell">
        <div class="title-cell">
          <div>
            <img
              *ngIf="entry.banner"
              class="banner"
              [defaultImage]="imgPreloader"
              [lazyLoad]="entry.banner | imageSize" />
            <div
              *ngIf="!entry.banner"
              class="placeholder-container">
              <img
                class="placeholder"
                [src]="logoAvatar | imageSize" />
            </div>
          </div>
          <p class="course-title">{{ entry.title }}</p>
        </div>
      </ng-template>

      <ng-template
        let-entry
        epTemplateKey="statusCell">
        <app-tag
          tagText="{{
            (entry.isPublished ? 'adminCourseList.published' : 'adminCourseList.unpublished')
              | translate
          }}"
          [bgColor]="entry.isPublished ? 'var(--appPrimaryColor)' : '#ff244c'">
        </app-tag>
      </ng-template>
      <ng-template
        let-entry
        epTemplateKey="actionsCell">
        <div class="table-btn-actions-container">
          <app-button
            [buttonType]="buttonType.ROUND"
            [buttonSize]="buttonSize.MEDIUM"
            [buttonStyle]="buttonStyle.FILLED_SECONDARY"
            [loading]="entry?.isActionBtnShowingSpinner"
            buttonRoundIcon="far fa-pen-to-square"
            (click)="edit($event, entry)" />
        </div>
      </ng-template>
    </admin-table>
  </div>
}
