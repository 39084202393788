import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

import { HubsStore, UsersStore } from 'src/app/core/stores';
import { UsersService } from 'src/app/core/services';

@Component({
  templateUrl: './stripe-information-dialog.component.html',
  styleUrls: ['./stripe-information-dialog.component.scss'],
})
export class StripeInformationDialogComponent implements OnInit {
  public isInstanceOwner = false;

  constructor(
    private router: Router,
    private hubsStore: HubsStore,
    public ref: DynamicDialogRef,
    private usersStore: UsersStore,
    private usersService: UsersService,
  ) {}

  async ngOnInit(): Promise<void> {
    const user = await this.usersService.getOne(this.usersStore.user.id);

    this.isInstanceOwner = user.role === 'instanceOwner' || user.role === 'softwareDeveloper';
  }

  public onConnectStripe(): void {
    this.router.navigate([`/${this.hubsStore.useHubUrl}/admin/hub-settings/profile`]);
    this.ref.close();
  }
}
