import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { tap, takeUntil } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';

import { CurrencyCode, SubscriptionPlatformCode, BillingPeriod } from 'src/app/core/enums';
import { StripeService, SubscriptionsService } from 'src/app/core/services';
import { ISubscription, ProductPricePair } from 'src/app/core/models';
import { HubsStore } from 'src/app/core/stores';
import { ButtonSize } from 'src/app/standalone/shared/enums';
import { StripeInformationDialogComponent } from '../../dialogs/stripe-information/stripe-information-dialog.component';

@Component({
  selector: 'app-admin-plan-form',
  templateUrl: './admin-plan-form.component.html',
  styleUrls: ['./admin-plan-form.component.scss'],
})
export class AdminPlanFormComponent implements OnInit, OnDestroy {
  loading = false;
  salesforceForm: UntypedFormGroup;
  stripeForm: UntypedFormGroup;
  plenigoForm: UntypedFormGroup;
  currencies = Object.keys(CurrencyCode);
  platforms = Object.values(SubscriptionPlatformCode);
  platformCode = SubscriptionPlatformCode;
  isUpdating = false;
  selectedPlatform = this.platforms[0];
  isPlatformSwitcherDisabled = false;
  isPriceDisabled = false;
  subscription: ISubscription;
  subscriptionId: string;
  billingPeriods: any[];
  buttonSize = ButtonSize;

  private unsubscribe$: Subject<void> = new Subject<void>();

  get isFormDisabled(): boolean {
    if (this.selectedPlatform === this.platformCode.SALESFORCE) {
      return (
        (this.salesforceForm.invalid && !this.isUpdating) ||
        (!this.salesforceForm.dirty && !this.isUpdating) ||
        this.isUpdating
      );
    } else if (this.selectedPlatform === this.platformCode.STRIPE) {
      return (
        (this.stripeForm.invalid && !this.isUpdating) ||
        (!this.stripeForm.dirty && !this.isUpdating) ||
        this.isUpdating
      );
    } else if (this.selectedPlatform === this.platformCode.PLENIGO) {
      return (
        (this.plenigoForm.invalid && !this.isUpdating) ||
        (!this.plenigoForm.dirty && !this.isUpdating) ||
        this.isUpdating
      );
    }
  }

  get titleErrorMessage(): string {
    if (this.stripeForm.controls.name.errors?.maxlength) {
      return this.translateService.instant('application.forms.msxLengthErrorText');
    }

    return this.translateService.instant('application.forms.required');
  }

  constructor(
    private fb: UntypedFormBuilder,
    private subscriptionService: SubscriptionsService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    private hubsStore: HubsStore,
    private translateService: TranslateService,
    private stripeService: StripeService,
    private dialogService: DialogService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.billingPeriods = Object.keys(BillingPeriod).map((key) => {
      return {
        label: this.translateService.instant('adminPlans.billingPeriod.' + BillingPeriod[key]),
        value: BillingPeriod[key],
      };
    });
    this.createStripeForm();

    this.subscriptionId = this.route.snapshot.paramMap.get('id');

    if (this.subscriptionId) {
      this.subscription = await this.subscriptionService.getById(this.subscriptionId);
      this.isPlatformSwitcherDisabled = true;
      if (this.subscription.platform == SubscriptionPlatformCode.SALESFORCE) {
        this.selectedPlatform = this.platforms[1];
        this.createSalesforceForm();
        this.updateSalesforceForm();
      } else if (this.subscription.platform == SubscriptionPlatformCode.STRIPE) {
        this.selectedPlatform = this.platforms[0];
        this.createStripeForm();
        this.updateStripeForm();
      } else if (this.subscription.platform == SubscriptionPlatformCode.PLENIGO) {
        this.selectedPlatform = this.platforms[2];
        this.createPlenigoForm();
        this.updatePlenigoForm();
      }
    }

    this.stripeForm.controls.price.valueChanges
      .pipe(
        tap((val: number | string) => {
          if (Number(val) !== 0) {
            this.stripeForm.controls.isDefault.disable();
            this.isPriceDisabled = true;
          } else {
            this.stripeForm.controls.isDefault.enable();
            this.isPriceDisabled = false;
          }
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();
  }

  private createSalesforceForm(): void {
    this.salesforceForm = this.fb.group({
      name: [null, Validators.required],
      appName: [null],
      aboobject: [null, Validators.required],
      active: [false],
    });
    this.loading = false;
  }

  private createStripeForm(): void {
    this.stripeForm = this.fb.group({
      name: [null, [Validators.required, Validators.maxLength(250)]],
      description: [null],
      currency: [null, Validators.required],
      price: [null, Validators.required],
      billingPeriod: [null, Validators.required],
      billingInformation: [null],
      active: [false],
      isDefault: [false],
    });
    this.loading = false;
  }

  private createPlenigoForm(): void {
    this.plenigoForm = this.fb.group({
      name: [null, Validators.required],
      appName: [null],
      planId: [null, Validators.required],
      active: [false],
    });
    this.loading = false;
  }

  async onSaveSalesforcePlan(): Promise<void> {
    if (this.salesforceForm.invalid) {
      return;
    }
    try {
      this.isUpdating = true;
      const form = this.salesforceForm.getRawValue();
      let subscription = {
        ...form,
        platform: SubscriptionPlatformCode.SALESFORCE,
        hubId: this.hubsStore.hubId,
      };

      if (this.subscriptionId) {
        await this.subscriptionService.updateSubscription(this.subscriptionId, subscription);
      } else {
        await this.subscriptionService.createSubscription(subscription);
      }
      this.showToastMessage('success', 'adminPlans.planSuccessfullySaved');
      this.isUpdating = false;
      this.router.navigate([`/${this.hubsStore.useHubUrl}/admin/hub-settings/plans`]);
    } catch (err) {
      console.warn(err);
      this.isUpdating = false;
      this.showToastMessage('error', 'adminPlans.planSaveFailed');
    }
  }

  async onSavePlenigoPlan(): Promise<void> {
    if (this.plenigoForm.invalid) {
      return;
    }

    try {
      this.isUpdating = true;
      const form = this.plenigoForm.getRawValue();
      let subscription = {
        ...form,
        platform: SubscriptionPlatformCode.PLENIGO,
        hubId: this.hubsStore.hubId,
      };
      if (this.subscriptionId) {
        await this.subscriptionService.updateSubscription(this.subscriptionId, subscription);
      } else {
        await this.subscriptionService.createSubscription(subscription);
      }
      this.showToastMessage('success', 'adminPlans.planSuccessfullySaved');
      this.isUpdating = false;
      this.router.navigate([`/${this.hubsStore.useHubUrl}/admin/hub-settings/plans`]);
    } catch (error) {
      console.warn(error);
      this.isUpdating = false;
      this.showToastMessage('error', 'adminPlans.planSaveFailed');
    }
  }

  async onSaveStripePlan(): Promise<void> {
    if (this.stripeForm.invalid) {
      return;
    }
    try {
      this.isUpdating = true;
      const form = this.stripeForm.getRawValue();
      let stripePriceProductPair: ProductPricePair;
      if (this.subscriptionId) {
        const stripeSubscription = await this.subscriptionService.getById(this.subscriptionId);
        stripePriceProductPair = {
          priceId: stripeSubscription.stripePriceId,
          productId: stripeSubscription.stripeProductId,
        };
      } else {
        if (form.billingPeriod == BillingPeriod.Lifetime) {
          stripePriceProductPair = await this.stripeService.createProduct(
            form.name,
            form.price,
            form.currency,
            false,
            false,
          );
        } else {
          stripePriceProductPair = await this.stripeService.createProduct(
            form.name,
            form.price,
            form.currency,
            true,
            false,
          );
        }
      }
      let subscription: ISubscription = {
        ...form,
        price: Math.round(form.price * 100) / 100,
        stripePriceId: stripePriceProductPair.priceId,
        stripeProductId: stripePriceProductPair.productId,
        platform: SubscriptionPlatformCode.STRIPE,
        hubId: this.hubsStore.hubId,
      };
      if (this.subscriptionId) {
        await this.subscriptionService.updateSubscription(this.subscriptionId, subscription);
      } else {
        await this.subscriptionService.createSubscription(subscription);
      }

      this.showToastMessage('success', 'adminPlans.planSuccessfullySaved');
      this.isUpdating = false;
      this.router.navigate([`/${this.hubsStore.useHubUrl}/admin/hub-settings/plans`]);
    } catch (error) {
      console.warn(error);
      this.isUpdating = false;
      this.showToastMessage('error', `stripeErrorCodes.${error.message}`);
    }
  }

  private showToastMessage(severity: 'success' | 'error', detail: string): void {
    const fallbackTranslationKey = 'toasters.error';
    let message;
    this.translateService.get(detail).subscribe((translation) => {
      message =
        translation === detail
          ? this.translateService.instant(fallbackTranslationKey)
          : translation;
    });
    this.messageService.add({
      severity: severity,
      summary: this.translateService.instant(severity),
      detail: this.translateService.instant(message),
      styleClass: 'custom-toast',
    });
  }

  private updateSalesforceForm(): void {
    this.salesforceForm.patchValue({
      ...this.subscription,
    });
  }

  private updateStripeForm(): void {
    this.stripeForm.patchValue({
      ...this.subscription,
    });
    if (this.subscriptionId) {
      this.stripeForm.controls.currency.disable();
      this.stripeForm.controls.price.disable();
    }
  }

  private updatePlenigoForm(): void {
    this.plenigoForm.patchValue({
      ...this.subscription,
    });
  }

  onPlatformChange($event): void {
    if ($event.value === this.platformCode.SALESFORCE) {
      this.createSalesforceForm();
    } else if ($event.value === this.platformCode.STRIPE) {
      this.createStripeForm();
    } else if ($event.value === this.platformCode.PLENIGO) {
      this.createPlenigoForm();
    }
  }

  private showStripeDialog(): void {
    this.dialogService.open(StripeInformationDialogComponent, {
      width: '38.75rem',
      height: '31.975rem',
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
