import { Timestamp } from 'src/app/firebase';
import { CurrencyCode, CouponDuration } from 'src/app/core/enums';

export interface ICourseCoupon {
  id: string;
  name: string;
  _name_: string;
  code: string;
  object: string;
  amount_off: string;
  percent_off: number;
  createdAt: Timestamp;
  createdBy: string;
  updatedAt: Timestamp;
  updatedBy: string;
  currency: CurrencyCode;
  max_redemptions: number;
  duration: CouponDuration;
  duration_in_months: number;
  times_redeemed: number;
  valid: boolean;
  stripeCouponId: string;
  expires_at: Timestamp;
  applies_to: string[];
  stripePromotionCodeId: string;
  discountPercentage?: string;
  discountAmount?: number;
}
