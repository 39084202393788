import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

type LabelType =
  | 'usual'
  | 'required'
  | 'tooltip'
  | 'tooltip-required'
  | 'switch-checkbox'
  | 'switch-checkbox-tooltip';

@Component({
  selector: 'app-input-label',
  templateUrl: './input-label.component.html',
  styleUrls: ['./input-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputLabelComponent {
  @Input() labelType: LabelType = 'usual';
  @Input() for: string;
  @Input() text: string;
  @Input() tooltipText: string;
  @Input() tooltipPosition: 'right' | 'left' | 'top' | 'bottom' = 'right';
  @Input() tooltipStyleClass = 'custom-tooltip';
  @Input() tooltipEvent: 'hover' | 'focus' = 'hover';
  @Input() tabIndex: number;
}
