<div class="admin-table-filter-navbar">
  <div class="admin-table-filter-navbar-inner">
    <div class="filter-fields-container">
      <span
        *ngIf="searchActive"
        class="search-input p-input-icon-left"
        [ngClass]="{ 'p-input-icon-right': filterSearch.value !== '' }">
        <i class="far fa-search"></i>
        <input
          class="custom-input-text"
          type="text"
          #filterSearch
          name="filterSearch"
          (input)="search.next($event.target.value)"
          pInputText
          placeholder="{{ 'adminTable.inputSearchPlaceholder' | translate }}" />
        <i
          *ngIf="filterSearch.value !== ''"
          class="reset-btn far fa-times-circle"
          (click)="filterSearch.value = ''; search.next('')">
        </i>
      </span>
      <div
        *ngFor="let filter of filters"
        class="filter-container">
        <p-multiSelect
          class="custom-multi-select-table-filter"
          panelStyleClass="custom-multi-select-panel-style-class width-22"
          [showToggleAll]="false"
          [placeholder]="filter.label"
          [options]="filter.options"
          (onChange)="onFilterChange(filter.key, $event)">
          <ng-template
            let-value
            pTemplate="selectedItems"
            >{{ filter.label | translate }}</ng-template
          >
        </p-multiSelect>
      </div>
      <ng-container *ngIf="showResetSort">
        <p-button
          class="reset-orders-btn"
          label="{{ 'adminTable.resetSortBtn' | translate }}"
          icon="far fa-rotate-left"
          iconPos="left"
          (click)="onSortReset.emit(true)">
        </p-button>
      </ng-container>
    </div>

    <div
      *ngIf="selectedOptions.length > 0"
      class="selected-filters-options-container">
      <div
        class="selected-filter-option"
        *ngFor="let fo of selectedOptions">
        <span
          ><b>{{ fo.prefix | translate }}:</b> {{ fo.label }}</span
        >
        <i
          (click)="removeFilterOption(fo.filterKey, fo.optionKey)"
          class="fal fa-trash-alt"></i>
      </div>
    </div>
  </div>
</div>
