import { Pipe, PipeTransform } from '@angular/core';

import { parseToMoment } from '../../helpers/date-mapper';

@Pipe({
  name: 'datePipe',
})
export class DatePipe implements PipeTransform {
  transform(value: any, format = 'DD.MMM.YYYY', ...args: any[]) {
    if (args.length) {
      return parseToMoment(value).format(args[0]);
    }

    return parseToMoment(value).format(format);
  }
}
