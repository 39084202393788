import { Component, inject, signal } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ICourse } from 'src/app/core/models';

import { CoursesStore } from 'src/app/core/stores';
import { SharedModule } from 'src/app/shared';
import { AdminCourseTicketFormComponent } from 'src/app/standalone/admin/components';

@Component({
  selector: 'app-course-new-ticket',
  standalone: true,
  imports: [SharedModule, AdminCourseTicketFormComponent],
  templateUrl: './course-new-ticket.component.html',
  styleUrl: './course-new-ticket.component.scss',
})
export class CourseNewTicketComponent {
  loading = signal(true);
  title = signal('');

  #courseStore = inject(CoursesStore);
  #translateService = inject(TranslateService);

  get event(): ICourse {
    return this.#courseStore.adminCourse;
  }

  ngOnInit(): void {
    this.title.set(this.#translateService.instant('adminCourseNewTicket.createTicketTitle'));
    this.loading.set(false);
  }
}
