import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, firstValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FilterMatchMode, MenuItem, MessageService } from 'primeng/api';
import { autorun, IReactionDisposer } from 'mobx';
import { TranslateService } from '@ngx-translate/core';
import { ClipboardService } from 'ngx-clipboard';

import { IAdminTableFilter, IAdminTableFilterChange, IAdminTableColumn } from 'src/app/shared';
import { IEvent, IHub, ITag, IUser, IEmail } from 'src/app/core/models';
import { EventsStore, HubsStore } from 'src/app/core/stores';
import {
  EventsService,
  OpenDialogService,
  SpeakersService,
  TagsService,
  UsersService,
  SessionsService,
  StagesService,
  HubsService,
  EmailsService,
} from 'src/app/core/services';
import { asyncDelay, getUserNameAbbreviation } from 'src/app/core/utils';
import { ButtonSize, ButtonStyle, ButtonType } from 'src/app/standalone';
import {
  AttachSpeakersDialogComponent,
  NewUserComponent,
  SelectEmailForEventUserComponent,
} from '../../../dialogs';

@Component({
  selector: 'app-event-speakers',
  templateUrl: './event-speakers.component.html',
  styleUrls: ['./event-speakers.component.scss'],
})
export class EventSpeakersComponent implements OnInit, OnDestroy {
  loading = true;
  loadingEntries = true;
  searchValue = '';
  searchFilterFields: string[] = [
    'name',
    'firstName',
    'lastName',
    'email',
    'company',
    'eventStatus',
    'eventTimeStamp',
  ];
  columns: IAdminTableColumn[] = [
    {
      field: 'firstName',
      titleTranslationKey: 'adminEventSpeakers.tableCells.firstName',
      sortable: true,
    },
    {
      field: 'lastName',
      titleTranslationKey: 'adminEventSpeakers.tableCells.lastName',
      sortable: true,
    },
    { field: 'email', titleTranslationKey: 'adminEventSpeakers.tableCells.email', sortable: true },
    {
      field: 'company',
      titleTranslationKey: 'adminEventSpeakers.tableCells.company',
      sortable: true,
    },
    {
      field: 'eventStatus',
      titleTranslationKey: 'adminEventSpeakers.tableCells.status',
      template: 'statusCell',
      filterable: true,
      filterMatchMode: FilterMatchMode.IN,
      filterOptions: [
        { name: 'Pending', value: 'pending' },
        { name: 'Active', value: 'active' },
      ],
      filterType: 'text',
    },
    {
      field: 'eventTimeStamp',
      titleTranslationKey: 'adminEventSpeakers.tableCells.timeStamp',
      sortable: true,
    },
    { field: 'actions', template: 'actionsCell' },
  ];
  entries: (IUser & { name: string })[] = [];
  allEntries: (IUser & { name: string })[] = [];
  selectedEntries: (IUser & { name: string })[] = [];
  totalRecords = 0;
  pageSize = 10;
  tags: ITag[] = [];
  tableFilters: IAdminTableFilter[] = [];
  imageText = getUserNameAbbreviation;
  btnListItems: MenuItem[] = [
    {
      label: this.translateService.instant('adminEventSpeakers.addBtn'),
      icon: 'far fa-plus',
      command: () => {
        this.openNewSpeakerDialog();
      },
    },
    {
      label: this.translateService.instant('adminEventSpeakers.attachSpeakers'),
      icon: 'fa-regular fa-user-plus',
      command: () => {
        this.openAttachSpeakersDialog();
      },
    },
    // TODO: uncomment during future implementation
    // {
    //   label: this.translateService.instant('shared.import'),
    //   icon: 'fa-regular fa-file-import'
    // },
  ];
  sidebarVisible = false;
  userIdForSidebar: string;
  buttonType = ButtonType;
  buttonSize = ButtonSize;
  buttonStyle = ButtonStyle;

  private selectedTags: string[] = [];
  private disposer: IReactionDisposer;
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    public hubStore: HubsStore,
    private router: Router,
    private route: ActivatedRoute,
    private speakerService: SpeakersService,
    private eventStore: EventsStore,
    private tagService: TagsService,
    private dialogService: DialogService,
    private eventService: EventsService,
    private usersService: UsersService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private clipboardService: ClipboardService,
    private openDialogService: OpenDialogService,
    private sessionsService: SessionsService,
    private stagesService: StagesService,
    private hubsStore: HubsStore,
    private hubsService: HubsService,
    private emailsService: EmailsService,
  ) {}

  get event(): IEvent {
    return this.eventStore.adminEvent;
  }

  ngOnInit(): void {
    this.disposer = autorun(async () => {
      if (this.event) {
        this.loading = true;
        this.loadingEntries = true;
        await Promise.all([this.fetchSpeakers(), this.getTags()]);
        this.tableFilters = [
          {
            key: 'TAGS',
            label: 'adminUsers.tagsFilterLabel',
            options: this.tags.map((t) => ({ label: t.title, value: t.id })),
          },
        ];
        this.loadingEntries = false;
        this.loading = false;
      }
    });
  }

  async filtersChange(e: IAdminTableFilterChange[]): Promise<void> {
    this.loadingEntries = true;
    this.selectedTags = e.filter((f) => f.filterKey === 'TAGS').map((f) => f.optionKey);

    if (this.selectedTags.length) {
      const entries = this.allEntries.filter((b) =>
        b.tags.some((r) => this.selectedTags.indexOf(r) >= 0),
      );
      this.entries = entries;
    } else {
      this.entries = this.allEntries;
    }

    await asyncDelay(100);
    this.loadingEntries = false;
  }

  openAttachSpeakersDialog(): void {
    this.dialogService
      .open(AttachSpeakersDialogComponent, {
        width: '90%',
        height: '80%',
        closable: false,
      })
      .onClose.subscribe(async () => await this.fetchSpeakers());
  }

  async sendInvites(): Promise<void> {
    try {
      const selectEmailDialogRef: DynamicDialogRef = this.dialogService.open(
        SelectEmailForEventUserComponent,
        {
          width: '90%',
          height: '80%',
          dismissableMask: true,
          closable: false,
          contentStyle: {
            padding: '0',
            'border-radius': '20px',
          },
          data: {
            userType: 'speakers',
          },
        },
      );
      const email: IEmail = await firstValueFrom(selectEmailDialogRef.onClose.pipe(take(1)));

      if (!email) {
        return;
      }

      const deleteDialogRef: DynamicDialogRef = this.openDialogService.openConfirmDialog(
        'adminEventSpeakers.sendUserInvitesDialog.header',
        this.translateService.instant('adminEventSpeakers.sendUserInvitesDialog.message', {
          totalUsers: this.selectedEntries.length,
        }),
        'buttons.confirm',
        'buttons.cancel',
      );
      const result: 'cancel' | 'confirm' = await firstValueFrom(
        deleteDialogRef.onClose.pipe(take(1)),
      );

      if (result !== 'confirm') {
        return;
      }

      await Promise.all(
        this.selectedEntries.map((speaker: IUser & { name: string }) =>
          this.emailsService.sendInviteWithEmailTemplate(
            this.event.id,
            speaker.id,
            email.id,
            'event',
          ),
        ),
      );

      this.showToastMessage('success', 'adminEventSpeakers.sendUserInvitesSuccessMessage');
    } catch (error) {
      this.showToastMessage('error', 'adminEventSpeakers.sendUserInvitesErrorMessage');
    }
  }

  async openNewSpeakerDialog(): Promise<void> {
    let hub: IHub = this.hubsStore.hub;

    if (!hub) {
      hub = await this.hubsService.getOne(this.event.hubId);
    }
    const newSpeakerDialog: DynamicDialogRef = this.dialogService.open(NewUserComponent, {
      styleClass: 'attach-new-speaker-instructor-dialog',
      closable: false,
      data: {
        userType: 'speaker',
        event: this.event,
        hub,
      },
    });

    const id: string = await newSpeakerDialog.onClose.pipe(take(1)).toPromise();
    if (id) {
      this.router.navigate([
        `/${this.hubStore.hub ? this.hubStore.useHubUrl : ''}/admin/event/${this.event.link}/participants/speakers/${id}/event-details`,
      ]);
    } else {
      await this.fetchSpeakers();
    }
  }

  onCloseSidebar(): void {
    this.sidebarVisible = false;
  }

  onRowClicked(id: string): void {
    this.router.navigate([id, 'event-details'], { relativeTo: this.route });
  }

  navigateToSpeaker(event: Event, user: IUser): void {
    event.stopPropagation();
    if (this.hubsStore.hub.isShowSidePanel) {
      this.sidebarVisible = true;
      this.userIdForSidebar = user.id;

      return;
    }

    this.router.navigate([user.id, 'event-details'], { relativeTo: this.route });
  }

  async removeSpeaker(event: Event, entry: IUser & { name: string }): Promise<void> {
    event.stopPropagation();
    const deleteDialogRef: DynamicDialogRef = this.openDialogService.openConfirmDialog(
      'adminUsers.deleteUser',
      'adminUsers.deleteUserText',
      'adminUsers.confirmBtn',
      'adminUsers.cancelBtn',
    );

    try {
      const result: 'cancel' | 'confirm' = await deleteDialogRef.onClose.pipe(take(1)).toPromise();
      if (result === 'confirm') {
        this.entries[this.entries.indexOf(entry)].isActionBtnShowingSpinner = true;
        await this.eventService.unattachUser(this.event.id, entry.id);
        await this.sessionsService.deleteModeratorFromAllSessionsInEvent(this.event.id, entry.id);
        await this.sessionsService.deleteSpeakerFromAllSessionsInEvent(this.event.id, entry.id);
        await this.stagesService.deleteSpeakerFromAllStagesInEvent(this.event.id, entry.id);
        await this.stagesService.deleteModeratorFromAllStagesInEvent(this.event.id, entry.id);
        this.loadingEntries = true;
        await this.fetchSpeakers();
        this.showToastMessage('success', 'adminEventSpeakers.removeSpeakerSuccessMsg');
      }
    } catch (error) {
      console.warn(error);
      this.showToastMessage('error', 'application.toasters.error');
      this.entries[this.entries.indexOf(entry)].isActionBtnShowingSpinner = false;
    }
    this.loadingEntries = false;
  }

  async copyInvite(event: Event, user: IUser & { name: string }): Promise<void> {
    event.stopPropagation();
    let messageSeverity: 'success' | 'error';
    let messageSummary: string;
    this.entries[this.entries.indexOf(user)].isActionBtnShowingSpinner = true;

    try {
      // get user invite url
      const inviteUrl = await this.usersService.getUserInviteUrl(this.event.id, user.id, false);
      // copy the link to the clipboard
      if (inviteUrl) {
        this.clipboardService.copy(inviteUrl);
      } else {
        this.clipboardService.copy(
          this.translateService.instant('adminEvents.copyInviteLinkEmpty'),
        );
      }
      messageSeverity = inviteUrl ? 'success' : 'error';
      messageSummary = inviteUrl
        ? this.translateService.instant('adminEvents.copyInviteLinkSuccess')
        : user.eventStatus === 'active'
          ? this.translateService.instant('adminEvents.copyInviteLinkWarnActiveUser')
          : this.translateService.instant('adminEvents.copyInviteLinkWarn');
    } catch (error) {
      messageSeverity = 'error';
      messageSummary = this.translateService.instant('adminEvents.copyInviteLinkError');
      console.warn(error);
    }

    this.messageService.add({
      key: 'copyInvite',
      severity: messageSeverity,
      summary: messageSummary,
      styleClass: 'custom-toast',
    });

    this.entries[this.entries.indexOf(user)].isActionBtnShowingSpinner = false;
  }

  ngOnDestroy(): void {
    this.disposer();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private async fetchSpeakers(): Promise<void> {
    this.allEntries = (await this.speakerService.getAllSpeakers(this.event.id)).map((e) => ({
      ...e,
      name: `${e.firstName.trim()} ${e.lastName.trim()}`,
    }));
    this.entries = this.allEntries;
  }

  private async getTags(): Promise<void> {
    this.tags = await this.tagService.getByIdsOrderedByTitle(this.event.tags);
  }

  private showToastMessage(severity: 'success' | 'error', message: string): void {
    this.messageService.add({
      severity,
      summary: this.translateService.instant(severity),
      detail: this.translateService.instant(message),
      styleClass: 'custom-toast',
    });
  }
}
