import { Component } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-susbcription-dialog',
  templateUrl: './susbcription-dialog.component.html',
  styleUrls: ['./susbcription-dialog.component.scss'],
})
export class SusbcriptionDialogComponent {
  constructor(private ref: DynamicDialogRef) {}

  onClose(): void {
    this.ref.close(true);
  }
}
