<div class="imprint-container">
  <div class="inner-container grid p-0 m-0">
    <div class="imprint-data lg:col-5 md:col-12 sm:col-12 col-12">
      <div class="grid imprint-header">
        <div class="lg:col-6 md:col-6 sm:col-6 col-6">
          <img
            [routerLink]="['/']"
            alt=""
            routerLinkActive="router-link-active"
            src="{{ logo | imageSize }}" />
        </div>
        <div class="lg:col-6 md:col-6 sm:col-6 col-6 text-right">
          <button
            pButton
            icon="pi"
            label="{{ 'signup.loginBtn' | translate }}"
            [routerLink]="['/signin']"
            routerLinkActive="router-link-active"></button>
        </div>
      </div>

      <div class="grid imprint-body">
        <div class="col-12">
          <h1>Impressum</h1>
          <br />

          <p>LO:X ist ein Angebot der:</p>
          <p>Ebner Media Group GmbH & Co. KG</p>
          <br />

          <p>vertretungsberechtigter Geschäftsführer:</p>
          <p>Marco Parrillo</p>
          <br />

          <p>Adresse</p>
          <p>Karlstr. 3, 89073 Ulm</p>
          <p>E-Mail: <a href="mailto: info@ebnermedia.de">info&#64;ebnermedia.de</a></p>
          <p>Website: <a (click)="goToLink('https://www.ebnermedia.de')">www.ebnermedia.de</a></p>
          <br />

          <p>Amtsgericht Ulm, HRA 1900 USt-IdNr.: DE 147041097</p>
        </div>
      </div>
    </div>
    <div
      class="lg:col-7 md:col-12 sm:col-12 col-12 hidden-md hidden-sm c-p-0 imprint-cover-container">
      <img
        class="imprint-cover img-preloader"
        [defaultImage]="imgPreloader"
        [lazyLoad]="
          (systemAppearanceSettings$ | async) && (systemAppearanceSettings$ | async).cover
            | imageSize
        " />
    </div>
  </div>
</div>
