import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';

import { CoursesStore, UsersStore } from 'src/app/core/stores';
import { SharedModule } from 'src/app/shared';
import { ChapterStatus, IChapter, ICourse } from 'src/app/core/models';
import { CourseChaptersService } from 'src/app/core/services';
import { Timestamp } from 'src/app/firebase';
import { ButtonComponent, ButtonSize, ButtonStyle } from 'src/app/standalone/shared';

@Component({
  selector: 'app-new-chapter',
  standalone: true,
  imports: [CommonModule, SharedModule, ButtonComponent],
  templateUrl: './new-chapter.component.html',
  styleUrls: ['./new-chapter.component.scss'],
})
export class NewChapterComponent implements OnInit {
  loading = true;
  isUpdating = false;
  form: FormGroup;
  createAnotherControl = new FormControl(null);
  buttonStyle = ButtonStyle;
  buttonSize = ButtonSize;

  constructor(
    private ref: DynamicDialogRef,
    private fb: FormBuilder,
    private courseChaptersService: CourseChaptersService,
    private coursesStore: CoursesStore,
    private usersStore: UsersStore,
    private messageService: MessageService,
    private translateService: TranslateService,
  ) {}

  get canUpdate(): boolean {
    return !this.isUpdating && this.form.valid && this.form.dirty;
  }

  get course(): ICourse {
    return this.coursesStore.adminCourse;
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      title: [null, Validators.required],
    });
    this.loading = false;
  }

  onCloseDialog(): void {
    this.ref.close();
  }

  async createChapter(): Promise<void> {
    try {
      if (!this.canUpdate) {
        return;
      }

      this.isUpdating = true;
      const { title } = this.form.getRawValue();
      const lastChapterFromDb = await this.courseChaptersService.getLastOneByCourseId(
        this.course.id,
      );
      const newChapter: IChapter = {
        id: null,
        banner: null,
        title,
        _title_: title.toLocaleLowerCase(),
        order: lastChapterFromDb?.order ? lastChapterFromDb.order + 1 : 1,
        assets: [],
        status: ChapterStatus.DRAFT,
        description: null,
        shortDescription: null,
        isSendEmailAfterChapterCompleted: false,
        createdAt: Timestamp.now(),
        createdBy: this.usersStore.userId,
        updatedAt: null,
        updatedBy: null,
      };
      await this.courseChaptersService.addNewChapterToCourse(this.course.id, newChapter);

      this.isUpdating = false;
      this.messageService.add({
        severity: 'success',
        summary: this.translateService.instant('success'),
        detail: this.translateService.instant('newChapter.successMessage'),
        styleClass: 'custom-toast',
      });

      if (this.createAnotherControl.value) {
        this.form.reset();

        return;
      }
      this.ref.close();
    } catch (error) {
      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('error'),
        detail: this.translateService.instant('newChapter.errorMessage'),
        styleClass: 'custom-toast',
      });
    }
  }
}
