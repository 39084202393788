<ng-container *ngIf="loading">
  <app-loader></app-loader>
</ng-container>

<form
  *ngIf="!loading"
  [formGroup]="form"
  class="container">
  <!-- Connect email sender block -->
  <div class="form-block c-mb-9">
    <div class="left-side">
      <h2 class="title">{{ 'adminHubEmailSender.connectEmailSenderTitle' | translate }}</h2>
      <p class="description">{{ 'adminHubEmailSender.connectEmailSenderSubtitle' | translate }}</p>
    </div>
    <div class="right-side">
      <!-- Sender input -->
      <div class="form-field-block">
        <app-input-label
          labelType="required"
          for="sender-input"
          text="adminHubEmailSender.senderLabel">
        </app-input-label>
        <input
          class="custom-input-text custom-input-text-required"
          formControlName="sender"
          id="sender-input"
          pInputText
          type="email"
          placeholder="{{ 'adminHubEmailSender.senderPlaceholder' | translate }}" />
        <app-input-error-message
          [showMessage]="form.controls.sender.invalid && form.controls.sender.touched"
          [errorMessage]="
            form.controls.sender.hasError('email')
              ? ('application.forms.email' | translate)
              : ('application.forms.required' | translate)
          ">
        </app-input-error-message>
      </div>

      <!-- From name input -->
      <div class="form-field-block">
        <app-input-label
          labelType="required"
          for="from-name-input"
          text="adminHubEmailSender.fromNameLabel">
        </app-input-label>
        <input
          class="custom-input-text custom-input-text-required"
          formControlName="fromName"
          id="from-name-input"
          pInputText
          type="text"
          placeholder="{{ 'adminHubEmailSender.fromNamePlaceholder' | translate }}" />
        <app-input-error-message
          [showMessage]="form.controls.fromName.invalid && form.controls.fromName.touched"
          errorMessage="{{ 'application.forms.required' | translate }}">
        </app-input-error-message>
      </div>

      <!-- Reply to email input -->
      <div class="form-field-block">
        <app-input-label
          labelType="required"
          for="reply-to-email-input"
          text="adminHubEmailSender.replyToEmailLabel">
        </app-input-label>
        <input
          class="custom-input-text custom-input-text-required"
          formControlName="replyToEmail"
          id="reply-to-email-input"
          pInputText
          type="email"
          placeholder="{{ 'adminHubEmailSender.replyToEmailPlaceholder' | translate }}" />
        <app-input-error-message
          [showMessage]="form.controls.replyToEmail.invalid && form.controls.replyToEmail.touched"
          [errorMessage]="
            form.controls.sender.hasError('email')
              ? ('application.forms.email' | translate)
              : ('application.forms.required' | translate)
          ">
        </app-input-error-message>
      </div>

      <!-- Api access key input -->
      <div class="form-field-block">
        <app-input-label
          labelType="required"
          for="api-access-key-input"
          text="adminHubEmailSender.apiAccessKeyLabel">
        </app-input-label>
        <input
          class="custom-input-text custom-input-text-required"
          formControlName="apiAccessKey"
          id="api-access-key-input"
          pInputText
          type="text"
          placeholder="{{ 'adminHubEmailSender.apiAccessKeyPlaceholder' | translate }}" />
        <app-input-error-message
          [showMessage]="form.controls.apiAccessKey.invalid && form.controls.apiAccessKey.dirty"
          [errorMessage]="
            form.controls.apiAccessKey.hasError('invalidApiKey')
              ? ('adminHubEmailSender.apiKeyInvalid' | translate)
              : ('application.forms.required' | translate)
          ">
        </app-input-error-message>
      </div>
    </div>
  </div>
</form>

<div class="save-discard-actions-container">
  <app-save-discard-actions
    [isShow]="canUpdate"
    [isLoading]="isUpdating"
    (discard)="onDiscard()"
    (confirm)="onConfirm()">
  </app-save-discard-actions>
</div>

<p-toast>
  <ng-template
    let-message
    pTemplate="message">
    <app-toast
      [severity]="message.severity"
      [summary]="message.summary"
      [detail]="message.detail">
    </app-toast>
  </ng-template>
</p-toast>
