import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { IEmail } from 'src/app/core/models';
import { EmailsService } from 'src/app/core/services';
import { CoursesStore, EventsStore } from 'src/app/core/stores';
import {
  EmailCourseActionTypes,
  emailsCourseActions,
  emailsEventActions,
} from 'src/app/core/utils';
import { CollectionTypes, IAdminTableColumn } from 'src/app/shared';
import { ButtonSize, ButtonType, ButtonStyle } from 'src/app/standalone';

type UserType = 'attendees' | 'speakers' | 'brandpeople' | 'instructor' | 'subscriber';

@Component({
  selector: 'app-select-email-for-event-user',
  templateUrl: './select-email-for-event-user.component.html',
  styleUrls: ['./select-email-for-event-user.component.scss'],
})
export class SelectEmailForEventUserComponent implements OnInit {
  loading = false;
  loadingEntries = true;
  entries: IEmail[] = [];
  searchFilterFields: string[] = ['title', 'language', 'actionTitle', 'isHtmlMode', 'status'];
  columns: IAdminTableColumn[] = [
    { field: 'title', titleTranslationKey: 'adminEmailTemplates.tableColumnTitle', sortable: true },
    {
      field: 'language',
      titleTranslationKey: 'adminEmailTemplates.tableColumnLanguage',
      template: 'langCell',
      sortable: true,
    },
    {
      field: 'actionTitle',
      titleTranslationKey: 'adminEmailTemplates.tableColumnAction',
      sortable: true,
    },
    {
      field: 'isHtmlMode',
      titleTranslationKey: 'adminEmailTemplates.tableColumnIsHtmlMode',
      template: 'htmlCell',
      sortable: true,
    },
    {
      field: 'status',
      titleTranslationKey: 'adminEmailTemplates.tableColumnStatus',
      template: 'statusCell',
      sortable: true,
    },
    { template: 'actionsCell' },
  ];
  emailActions: { label: string; value: string | EmailCourseActionTypes }[] = [
    ...emailsEventActions,
    ...emailsCourseActions,
  ];
  search = new FormControl('');
  buttonSize = ButtonSize;
  buttonType = ButtonType;
  buttonStyle = ButtonStyle;

  constructor(
    private emailsService: EmailsService,
    private eventsStore: EventsStore,
    private ref: DynamicDialogRef,
    private configDialog: DynamicDialogConfig,
    private coursesStore: CoursesStore,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.fetchEmails();
    this.loadingEntries = false;
    this.loading = false;
  }

  onSelect(entry: IEmail): void {
    this.ref.close(entry);
  }

  onClear(): void {
    this.search.setValue('');
  }

  onCloseDialog(): void {
    this.ref.close();
  }

  private async fetchEmails(): Promise<void> {
    const userType: UserType = this.configDialog.data.userType;
    let emails: IEmail[] = [];
    if (this.eventsStore.adminEvent?.id) {
      emails = await this.emailsService.getEmailsForSpecificAudience(
        this.eventsStore.adminEvent.id,
        userType,
        CollectionTypes.EVENTS_MAIL_TEMPLATES,
      );
    } else {
      emails = await this.emailsService.getEmailsForSpecificAudience(
        this.coursesStore.adminCourse.id,
        userType,
        CollectionTypes.COURSES_MAIL_TEMPLATES,
      );
    }
    this.entries = emails.map((email: IEmail) => {
      email.actionTitle = email.action
        ? this.emailActions.find(
            (emailAction: { label: string; value: string }) => emailAction.value === email.action,
          ).label
        : '-';

      return email;
    });
    this.entries = this.entries.filter((email: IEmail) => email.action !== 'verify-email');
  }
}
