import { Component, inject, OnInit, signal } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MenuItem, MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

import {
  EventsService,
  OpenDialogService,
  BrandsService,
  TagsService,
  HubsService,
} from 'src/app/core/services';
import {
  IAdminTableFilter,
  IAdminTableFilterChange,
  imgPreloader,
  logoAvatar,
  IAdminTableColumn,
  SharedModule,
} from 'src/app/shared';
import { IBrand, IEvent, IHub, ITag } from 'src/app/core/models';
import { HubsStore, EventsStore } from 'src/app/core/stores';
import { asyncDelay } from 'src/app/core/utils';
import { AttachBrandsDialogComponent, NewBrandComponent } from 'src/app/admin';
import { ButtonSize, ButtonStyle, ButtonType } from 'src/app/standalone';
import { ButtonListComponent } from 'src/app/standalone/shared/components/button-list/button-list.component';
import { ButtonComponent } from 'src/app/standalone/shared/components/button/button.component';

@Component({
  selector: 'app-event-brands',
  standalone: true,
  imports: [SharedModule, ButtonListComponent, ButtonComponent],
  templateUrl: './event-brands.component.html',
  styleUrl: './event-brands.component.scss',
})
export class EventBrandsComponent implements OnInit {
  loading = signal(true);
  loadingEntries = signal(true);
  searchValue = signal('');
  searchFilterFields = signal<string[]>(['name', 'ownerName', 'ownerEmail']);
  columns = signal<IAdminTableColumn[]>([
    { field: 'name', template: 'nameCell', titleTranslationKey: 'adminBrands.tableColName' },
    { field: 'ownerName', titleTranslationKey: 'adminBrands.tableColBrandOwner' },
    { field: 'ownerEmail', titleTranslationKey: 'adminBrands.tableColOwnerEmail' },
    { field: 'actions', template: 'actionsCell' },
  ]);
  tableFilters = signal<IAdminTableFilter[]>([]);
  entries = signal<IBrand[]>([]);
  btnListItems = signal<MenuItem[]>(null);
  imgPreloader = signal<string>(imgPreloader);
  logoAvatar = signal<string>(logoAvatar);
  buttonType = signal<typeof ButtonType>(ButtonType);
  buttonSize = signal<typeof ButtonSize>(ButtonSize);
  buttonStyle = signal<typeof ButtonStyle>(ButtonStyle);

  #allEntries = signal<IBrand[]>([]);
  #tags = signal<ITag[]>([]);
  #selectedTags = signal<string[]>([]);
  #messageService = inject(MessageService);
  #dialogService = inject(DialogService);
  #router = inject(Router);
  #activatedRoute = inject(ActivatedRoute);
  #brandsService = inject(BrandsService);
  #eventsService = inject(EventsService);
  #eventStore = inject(EventsStore);
  #tagService = inject(TagsService);
  #hubsStore = inject(HubsStore);
  #translateService = inject(TranslateService);
  #openDialogService = inject(OpenDialogService);
  #hubsService = inject(HubsService);

  get event(): IEvent {
    return this.#eventStore.adminEvent;
  }

  async ngOnInit(): Promise<void> {
    await Promise.all([this.fetchBrands(), this.getTags()]);
    this.tableFilters.set([
      {
        key: 'TAGS',
        label: 'adminUsers.tagsFilterLabel',
        options: this.#tags().map((t) => ({ label: t.title, value: t.id })),
      },
    ]);

    this.btnListItems.set([
      {
        label: this.#translateService.instant('adminEventBrands.addBtn'),
        icon: 'far fa-plus',
        command: () => {
          this.openNewBrandDialog();
        },
      },
      {
        label: this.#translateService.instant('adminEventBrands.attachBrands'),
        icon: 'fa-regular fa-grid-2-plus',
        command: () => {
          this.openAttachBrandsDialog();
        },
      },
    ]);
    this.loadingEntries.set(false);
    this.loading.set(false);
  }

  async openAttachBrandsDialog(): Promise<void> {
    const dialogRef: DynamicDialogRef = this.#dialogService.open(AttachBrandsDialogComponent, {
      width: '90%',
      height: '80%',
      closable: false,
    });

    await firstValueFrom(dialogRef.onClose);
    this.loadingEntries.set(true);
    this.entries.set([]);
    await this.fetchBrands();
    this.loadingEntries.set(false);
  }

  async filtersChange(e: IAdminTableFilterChange[]): Promise<void> {
    this.loadingEntries.set(true);
    this.#selectedTags.set(e.filter((f) => f.filterKey === 'TAGS').map((f) => f.optionKey));

    if (this.#selectedTags().length) {
      const entries = this.#allEntries().filter((b) =>
        b.tags.some((r) => this.#selectedTags().indexOf(r) >= 0),
      );
      this.entries.set(entries);
    } else {
      this.entries.set(this.#allEntries());
    }

    await asyncDelay(100);
    this.loadingEntries.set(false);
  }

  async openNewBrandDialog(): Promise<void> {
    let hub: IHub = this.#hubsStore.hub;

    if (!hub) {
      hub = await this.#hubsService.getOne(this.event.hubId);
    }
    const newBrandDialogRef: DynamicDialogRef = this.#dialogService.open(NewBrandComponent, {
      width: '40%',
      height: '40%',
      closable: false,
      styleClass: 'attach-new-brand-dialog',
      data: {
        event: this.event,
        hub,
      },
    });

    const brandId: string = await firstValueFrom(newBrandDialogRef.onClose);
    if (brandId) {
      this.#router.navigate([
        `/${this.#hubsStore.useHubUrl}/admin/event/${this.event.link}/participants/brands/${brandId}/profile`,
      ]);
    }
  }

  edit(event: Event, entry: IBrand): void {
    event.stopPropagation();
    this.#router.navigate([`${entry.id}/event-details`], { relativeTo: this.#activatedRoute });
  }

  async removeBrand(event: Event, entry: IBrand): Promise<void> {
    event.stopPropagation();
    const deleteDialogRef: DynamicDialogRef = this.#openDialogService.openConfirmDialog(
      'adminEventBrands.removeBrandTitle',
      'adminEventBrands.removeBrandText',
      'adminEventBrands.confirmBtn',
      'adminEventBrands.cancelBtn',
    );

    const result: 'cancel' | 'confirm' = await firstValueFrom(deleteDialogRef.onClose);
    if (result === 'confirm') {
      try {
        this.loading.set(true);
        await this.#brandsService.deleteEventBrand(entry.eventBrandId);
        this.showToastMessage('success', 'adminBrands.succesDeleteBrand');
        await this.fetchBrands();
        await asyncDelay(100);
      } catch (error) {
        this.showToastMessage('error', 'adminBrands.errorDeleteBrand');
      } finally {
        this.loading.set(false);
      }
    }
  }

  onRowClicked(id: string): void {
    this.#router.navigate([`${id}/event-details`], { relativeTo: this.#activatedRoute });
  }

  private async getTags(): Promise<void> {
    const tags: ITag[] = await this.#tagService.getByIdsOrderedByTitle(this.event.tags);
    this.#tags.set(tags);
  }

  private async fetchBrands(): Promise<void> {
    const allEntries = await this.#eventsService.getEventBrands(this.event.id);
    this.#allEntries.set(allEntries);
    this.entries.set(this.#allEntries());
  }

  private showToastMessage(severity: 'success' | 'error', detail: string): void {
    this.#messageService.add({
      severity,
      summary: this.#translateService.instant(severity),
      detail: this.#translateService.instant(detail),
      styleClass: 'custom-toast',
    });
  }
}
