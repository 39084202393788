import * as moment from 'moment';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Subject, merge, takeUntil, tap } from 'rxjs';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { IAsset } from 'src/app/core/models';
import { AssetType, AssetFileTypes } from 'src/app/core/enums';
import {
  videoImg,
  pngImg,
  jpgImg,
  pdfImg,
  zipImg,
  parseToMoment,
  SharedModule,
} from 'src/app/shared';
import {
  CustomProgressBarComponent,
  AssetEventBannerComponent,
} from 'src/app/standalone/shared/components';
import { BrowserDefaultVideoPlayerService, VimeoPlayerService } from 'src/app/core/services';
import { RegisteredCourseStore } from 'src/app/core/stores';
import { asyncDelay } from 'src/app/core/utils';

@Component({
  selector: 'app-chapter-asset-card',
  standalone: true,
  imports: [
    CommonModule,
    CustomProgressBarComponent,
    TranslateModule,
    AssetEventBannerComponent,
    SharedModule,
  ],
  templateUrl: './chapter-asset-card.component.html',
  styleUrls: ['./chapter-asset-card.component.scss'],
})
export class ChapterAssetCardComponent implements OnInit, OnDestroy {
  @Input() asset: IAsset;
  @Input() assetEventStatus: 'live' | 'upcoming';
  @Input() isHideProgressBar: boolean;
  @Input() isShowAssetDuration = false;

  assetType = AssetType;
  iconForVideoState: string;
  assetEventBannerMonthStyles = {
    'font-size': '0.625rem',
    'line-height': '0.625rem',
  };
  assetEventBannerDateStyle = {
    'font-size': '1.125rem',
    'line-height': '1.125rem',
  };
  customProgressBarHeight = '0.125rem';
  customProgressBarBorderRadius = '0 0 0.5rem 0.5rem';

  private chapterCurrentActiveAsset: IAsset;
  private activeAssetFileType: 'img' | 'zip' | 'pdf' | 'vimeo' | 'internalVideo' | 'event';
  private isVideoPlaying = false;
  private unsubscribe$ = new Subject();

  constructor(
    private translateService: TranslateService,
    private vimeoPlayerService: VimeoPlayerService,
    private registeredCourseStore: RegisteredCourseStore,
    private browserDefaultVideoPlayerService: BrowserDefaultVideoPlayerService,
  ) {}

  get assetUpcomingEventSubtitle(): string {
    const today = moment();
    const assetEventStartDate = moment(parseToMoment(this.asset.eventAssetStartDate));
    const daysUntilEvent = assetEventStartDate.diff(today, 'days');
    const subTitle: string =
      daysUntilEvent !== 1
        ? this.translateService.instant('chapterAssetCard.remainingTimeForManyDays', {
            numberDays: daysUntilEvent,
          })
        : this.translateService.instant('chapterAssetCard.remainingTimeForOneDay');

    return subTitle;
  }

  get assetEventSubtitle(): string {
    const formattedStartDate = moment(parseToMoment(this.asset.eventAssetStartDate)).format(
      'MMMM DD, YYYY, hh:mm A',
    );
    const formattedEndDate = moment(parseToMoment(this.asset.eventAssetEndDate)).format('hh:mm A');
    const formattedDateTimeRange = `${formattedStartDate} - ${formattedEndDate}`;

    return formattedDateTimeRange;
  }

  get imagePlaceholder(): string {
    if (this.asset.type === AssetType.VIDEO) {
      return videoImg;
    }

    switch (this.asset.fileType) {
      case AssetFileTypes.PNG:
        return pngImg;
      case AssetFileTypes.JPG:
        return jpgImg;
      case AssetFileTypes.PDF:
        return pdfImg;
      case AssetFileTypes.ZIP:
        return zipImg;
      default:
        return pngImg;
    }
  }

  get percentageWatched(): number {
    return this.asset?.userCourseTracking?.percentageViewed ?? 0;
  }

  get isAssetWatched(): boolean {
    return this.asset?.userCourseTracking?.isWatched;
  }

  ngOnInit(): void {
    this.iconForVideoState =
      (this.asset.internalVideo || this.asset.videoLink) && this.asset.userCourseTracking?.isWatched
        ? 'fa-regular fa-check icon-check'
        : 'fa-regular fa-play';

    const chapterCurrentActiveAsset$ = this.registeredCourseStore.activeAsset.pipe(
      tap((asset: IAsset) => {
        this.iconForVideoState = 'fa-regular fa-play';
        this.isVideoPlaying = false;
        this.chapterCurrentActiveAsset = asset;
      }),
    );

    const activeAssetFileType$ = this.registeredCourseStore.activeAssetFileType.pipe(
      tap((assetFileType: 'img' | 'zip' | 'pdf' | 'vimeo' | 'internalVideo' | 'event') => {
        this.activeAssetFileType = assetFileType;
      }),
    );

    const videoPlay$ = merge(
      this.vimeoPlayerService.vimeoVideoPlay,
      this.browserDefaultVideoPlayerService.videoPlay,
    ).pipe(
      tap(() => {
        if (this.asset.id === this.chapterCurrentActiveAsset.id) {
          this.iconForVideoState = 'fak fa-lox-pause icon-pause';
          this.isVideoPlaying = true;
        }
      }),
    );

    const videoPause$ = merge(
      this.vimeoPlayerService.vimeoVideoPause,
      this.browserDefaultVideoPlayerService.videoPause,
    ).pipe(
      tap(() => {
        if (this.asset.id === this.chapterCurrentActiveAsset.id) {
          const isVideo: boolean = ['vimeo', 'internalVideo'].includes(this.activeAssetFileType);
          this.iconForVideoState =
            isVideo && this.asset.userCourseTracking.isWatched
              ? 'fa-regular fa-check icon-check'
              : 'fa-regular fa-play';
          this.isVideoPlaying = false;
        }
      }),
    );

    const videoTimeUpdate$ = merge(
      this.vimeoPlayerService.vimeoVideoTimeUpdate,
      this.browserDefaultVideoPlayerService.videoTimeUpdate,
    ).pipe(
      tap(({ seconds, percent, duration }) => {
        if (this.asset.id === this.chapterCurrentActiveAsset.id) {
          this.asset = {
            ...this.asset,
            userCourseTracking: {
              ...this.asset.userCourseTracking,
              percentageViewed: percent * 100,
            },
          };
        }
      }),
    );

    const videoEnded$ = merge(
      this.vimeoPlayerService.vimeoVideoEnd,
      this.browserDefaultVideoPlayerService.videoEnd,
    ).pipe(
      tap(() => {
        console.log('video end');
        if (this.asset.id === this.chapterCurrentActiveAsset.id) {
          this.iconForVideoState = 'fa-regular fa-check icon-check';
          this.isVideoPlaying = false;
        }
      }),
    );

    merge(
      chapterCurrentActiveAsset$,
      activeAssetFileType$,
      videoPlay$,
      videoPause$,
      videoTimeUpdate$,
      videoEnded$,
    )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe();
  }

  async onVideoIconClick(e: Event): Promise<void> {
    e.stopPropagation();
    if (this.chapterCurrentActiveAsset.id !== this.asset.id) {
      await this.registeredCourseStore.setActiveAsset(this.asset);
      await asyncDelay(100);
    }

    if (this.activeAssetFileType === 'vimeo') {
      this.manageVimeoPlayer();
    } else {
      this.manageCustomPlayer();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  private manageVimeoPlayer(): void {
    if (this.isVideoPlaying) {
      this.vimeoPlayerService.pause();
    } else {
      this.vimeoPlayerService.play();
    }
  }

  private manageCustomPlayer(): void {
    if (this.isVideoPlaying) {
      this.browserDefaultVideoPlayerService.pause();
    } else {
      this.browserDefaultVideoPlayerService.play();
    }
  }
}
