import { Component, OnInit, OnDestroy } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { merge, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

import { asyncDelay, roles } from 'src/app/core/utils';
import { Timestamp } from 'src/app/firebase';
import { AuthenticationService, FormService, UsersService } from 'src/app/core/services';
import { IRole, IUser } from 'src/app/core/models';
import { UsersStore } from 'src/app/core/stores';
import { ButtonSize } from 'src/app/standalone';

@Component({
  selector: 'app-user-account',
  templateUrl: './user-account.component.html',
  styleUrls: ['./user-account.component.scss'],
  providers: [MessageService],
})
export class UserAccountComponent implements OnInit, OnDestroy {
  loading = true;
  isUpdating = false;
  roles: IRole[] = roles;
  form: FormGroup;
  isVerifying = false;
  canVerify = true;
  isResetting = false;
  canReset = true;
  buttonSize = ButtonSize;

  private unsubscribe$ = new Subject<void>();
  private isChangingEmail: boolean = false;
  private isChangingPassword: boolean = false;

  constructor(
    private messageService: MessageService,
    private translateService: TranslateService,
    private authenticationService: AuthenticationService,
    private usersService: UsersService,
    private usersStore: UsersStore,
    private formService: FormService,
  ) {}

  get user(): IUser {
    return this.usersStore.adminUser;
  }

  get canUpdate(): boolean {
    return (
      !this.isUpdating && this.form.valid && this.form.dirty && this.formService.isValueChanged()
    );
  }

  async ngOnInit(): Promise<void> {
    this.initForm();
    this.setInitialValueForForm();
    this.formService.setForm(this.form);
    this.canVerify = !this.user.hasOwnProperty('accountVerifiedAt');
    this.canReset = await this.authenticationService.hasAnyFailedAttempts(this.user.email);

    const email$ = this.form.controls.email.valueChanges.pipe(
      tap((value: string) => {
        this.isChangingEmail = value && this.user && this.user.email !== value;
        this.updateOldPasswordValidators();
      }),
    );
    const password$ = this.form.controls.password.valueChanges.pipe(
      tap((value: string) => {
        this.isChangingPassword = !!value;
        this.updateOldPasswordValidators();
      }),
    );

    merge(email$, password$).pipe(takeUntil(this.unsubscribe$)).subscribe();

    this.loading = false;
  }

  private initForm(): void {
    this.form = new FormGroup({
      email: new FormControl(null, [Validators.email]),
      oldPassword: new FormControl(''),
      password: new FormControl('', [Validators.minLength(6)]),
      accept: new FormControl(false),
      role: new FormControl(null),
      isActive: new FormControl(false),
    });
  }

  private setInitialValueForForm(): void {
    this.form.patchValue({ ...this.user });
  }

  async update(): Promise<void> {
    this.isUpdating = true;

    try {
      const user = this.form.getRawValue();

      if (user.email !== this.user.email && user.oldPassword.trim() !== '') {
        await this.authenticationService.changeEmail(user.oldPassword, this.user.email, user.email);
      }

      if (user.password && user.password.trim() !== '' && user.oldPassword.trim() !== '') {
        await this.authenticationService.changePassword(
          this.user.email,
          user.oldPassword,
          user.password,
        );
      }

      user['updatedAt'] = Timestamp.now();
      user['updatedBy'] = this.usersStore.user.id;

      delete user['oldPassword'];
      delete user['password'];

      await this.usersService.update(this.user.id, user);

      const newUser = { ...this.usersStore.adminUser, ...user };
      this.usersStore.setAdminUser(newUser);

      this.form.markAsPristine();
      this.messageService.add({
        severity: 'success',
        summary: this.translateService.instant('success'),
        detail: this.translateService.instant('adminUserAccount.userSuccessfullyUpdated'),
        styleClass: 'custom-toast',
      });
      this.isUpdating = false;
    } catch (error) {
      console.log(error);
      this.isUpdating = false;
      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('error'),
        detail: this.translateService.instant('adminUserAccount.errorUpdateUser'),
        styleClass: 'custom-toast',
      });
    }
  }

  private updateOldPasswordValidators(): void {
    if (this.isChangingEmail || this.isChangingPassword) {
      this.form.controls.oldPassword.setValidators([Validators.required, Validators.minLength(6)]);
    } else {
      this.form.controls.oldPassword.clearValidators();
    }

    this.form.controls.oldPassword.updateValueAndValidity();
  }

  async verifyEmail(): Promise<void> {
    this.isVerifying = true;
    const result = await this.authenticationService.setVerifiedUser(this.user.email);
    if (result) {
      this.canVerify = false;
      this.messageService.add({
        severity: 'success',
        summary: this.translateService.instant('success'),
        detail: this.translateService.instant('adminUser.emailVerified'),
        styleClass: 'custom-toast',
      });
    } else {
      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('error'),
        detail: this.translateService.instant('adminUser.emailVerificationFailed'),
        styleClass: 'custom-toast',
      });
    }
    this.isVerifying = false;
  }

  async resetLoginAttempts(): Promise<void> {
    this.isResetting = true;
    const result = await this.authenticationService.deleteFailedAttemptRecs(this.user.email);

    if (result) {
      this.canReset = false;

      this.messageService.add({
        severity: 'success',
        summary: this.translateService.instant('success'),
        detail: this.translateService.instant('adminUser.deleteFailedAttemptsSuccess'),
        styleClass: 'custom-toast',
      });
    } else {
      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('error'),
        detail: this.translateService.instant('adminUser.deleteFailedAttemptsError'),
        styleClass: 'custom-toast',
      });
    }
    this.isResetting = false;
  }

  onDiscard(): void {
    this.setInitialValueForForm();
  }

  async onConfirm(): Promise<void> {
    try {
      this.isUpdating = true;
      await asyncDelay(1);
      await this.update();
      this.formService.setForm(this.form);
    } catch (error) {
      console.error(error);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
