import { Injectable } from '@angular/core';

import { Firestore, IFirestore } from 'src/app/firebase';
import { API_ROUTES as apiRoutes } from 'src/app/shared';
import { IBrandMemberInvite } from 'src/app/core/models';

@Injectable({
  providedIn: 'root',
})
export class BrandMemberInviteService {
  private firestore: IFirestore;

  constructor() {
    this.firestore = Firestore();
  }

  async getBrandMemberInvitesByUserId(userId: string): Promise<IBrandMemberInvite[]> {
    try {
      const brandMemberInvites: IBrandMemberInvite[] = (
        await this.firestore
          .collection(apiRoutes.brandMemberInvites)
          .where('userId', '==', userId)
          .get()
      ).docs.map((doc) => doc.data() as IBrandMemberInvite);

      return brandMemberInvites;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async deleteBrandMemberInvite(brandMemberInviteId: string): Promise<boolean> {
    try {
      await this.firestore
        .collection(apiRoutes.brandMemberInvites)
        .doc(brandMemberInviteId)
        .delete();

      return true;
    } catch (err) {
      console.warn(err);
      throw new Error(err);
    }
  }
}
