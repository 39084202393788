import { Component, EventEmitter, Input, Output } from '@angular/core';

import { EventsStore } from 'src/app/core/stores/events/events.store';
import { IUser, IUserPresenceStylesConfig } from 'src/app/core/models';
import { userAvatar } from '../../utils';

@Component({
  selector: 'user-little-card',
  templateUrl: './user-little-card.component.html',
  styleUrls: ['./user-little-card.component.scss'],
})
export class UserLittleCardComponent {
  @Input() user: IUser;
  @Input() showPosition: boolean;
  @Input() isShowChatIcon: boolean = false;
  @Output() openChat: EventEmitter<Event> = new EventEmitter<Event>();

  configForPresenceStatus: IUserPresenceStylesConfig = {
    width: '1rem',
    height: '1rem',
    top: '68%',
    right: '0rem',
  };

  get fullName(): string {
    if (this.user?.firstName && this.user?.lastName) {
      return `${this.user.firstName} ${this.user.lastName}`;
    }

    return '';
  }

  get company(): string {
    return this.user?.company || '';
  }

  get position(): string {
    return this.user?.position || '';
  }

  get userAvatar(): string {
    return this.user?.profileImage ? this.user.profileImage : userAvatar;
  }

  constructor(public eventsStore: EventsStore) {}

  onOpenChat(e: Event): void {
    this.openChat.emit(e);
  }
}
