import { Database, IDatabase, IAuth, Auth } from './../../../firebase';
import firebase from 'firebase/compat/app';
import { Injectable } from '@angular/core';
import { tap, switchMap, first } from 'rxjs/operators';
import { fromEvent, Observable, of } from 'rxjs';
import { IUserPresenceStatus } from '../../models/user/user-presence-status.model';
import { UsersStore } from '../../stores/users/users.store';
import { IUser } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class PresenceService {
  private database: IDatabase;
  private auth: IAuth;
  private statusesCollectionRef;

  constructor(private usersStore: UsersStore) {
    this.database = Database();
    this.auth = Auth();

    this.statusesCollectionRef = this.database.ref('/status');
    this.updateOnUser().subscribe();
    this.updateOnDisconnect().subscribe();
    this.updateOnAway();
  }

  get timestamp(): {} {
    return firebase.database.ServerValue.TIMESTAMP;
  }

  public getPresence(userId: string): Observable<IUserPresenceStatus | null> {
    return fromEvent(this.database.ref(`status/${userId}`), 'value', function (snapshot) {
      return snapshot.val();
    }) as Observable<IUserPresenceStatus | null>;
  }

  public async addPresence(id, status: string): Promise<void> {
    await this.database.ref('/status/' + id).set({ status: status, timestamp: this.timestamp });
  }

  public async setPresence(status: string): Promise<void> {
    const user = await this.getUser();
    if (user) {
      await this.database.ref(`status/${user.id}`).update({ status, timestamp: this.timestamp });
    }
  }

  public async removePresence(key: string): Promise<void> {
    await this.statusesCollectionRef.remove(key);
  }

  public async signOut() {
    await this.setPresence('offline');
  }

  public getUser(): Promise<IUser | null> {
    return this.usersStore.currentUser.pipe(first()).toPromise();
  }

  private updateOnAway() {
    document.onvisibilitychange = (e) => {
      if (document.visibilityState === 'hidden') {
        this.setPresence('away');
      } else {
        this.setPresence('online');
      }
    };
  }

  private updateOnUser() {
    const connection = fromEvent(this.database.ref('.info/connected'), 'value', (snapshot) =>
      snapshot.val() ? 'online' : 'offline',
    );

    return of(this.auth.onAuthStateChanged).pipe(
      switchMap((user) => (user ? connection : of('offline'))),
      tap((status) => this.setPresence(status)),
    );
  }

  private updateOnDisconnect() {
    return of(
      this.auth.onAuthStateChanged((user) => {
        if (user) {
          this.database.ref(`status/${user.uid}`).onDisconnect().update({
            status: 'offline',
            timestamp: this.timestamp,
          });
        }
      }),
    );
  }
}
