import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';

export const connectedButton = trigger('connected', [
  state('start', style({ background: '#FBC02D', border: '1px solid #FBC02D', color: '#181818' })),
  state('end', style({ background: 'transparent', color: '#ffffff', border: '1px solid #61AE7F' })),
  transition(
    'start => end',
    animate(
      '1s',
      keyframes([
        style({ background: '#61AE7F', offset: 0.2 }),
        style({
          background: 'transparent',
          color: '#ffffff',
          border: '1px solid #61AE7F',
          offset: 0.67,
        }),
      ]),
    ),
  ),
  transition('end => start', animate('1s')),
]);

export const hoverButton = trigger('hover', [
  state(
    'hoverStart',
    style({ background: 'transparent', border: '1px solid #61AE7F', color: '#ffffff' }),
  ),
  state(
    'hoverEnd',
    style({ background: '#C94B4D', border: '1px solid #C94B4D', color: '#ffffff' }),
  ),
  transition('hoverStart => hoverEnd', animate('1s')),
]);

export const iconButton = trigger('icon', [
  state('start', style({ opacity: 1 })),
  state('end', style({ opacity: 1 })),
  transition(
    'start => end',
    animate(
      '1s',
      keyframes([
        style({ opacity: 0.5, offset: 0.25 }),
        style({ opacity: 0, offset: 0.5 }),
        style({ opacity: 0.5, offset: 0.75 }),
        style({ opacity: 1, offset: 1 }),
      ]),
    ),
  ),
]);
