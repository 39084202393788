import { Theme } from '../theme/theme.model';

export interface IAppearanceSettings {
  cover: any;
  coverImages: string[];
  logo: any;
  logoLight: any;
  defaultEmailLogo: any;
  darkTheme: Theme;
  lightTheme: Theme;
  primaryColor?: string;
  globalSingleRoute?: boolean;
  globalSingleRouteHtml?: any;
}
