export interface ICourseAsset {
  id: string;
  _asset_title_: string;
  _course_title: string;
  courseId: string;
  assetId: string;
  updatedAt: any;
  updatedBy: string;
  createdAt: any;
  createdBy: string;
}
