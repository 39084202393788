import { HubsStore } from 'src/app/core/stores';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  public loading = true;
  public test: string;

  constructor(public hubsStore: HubsStore) {}

  ngOnInit(): void {
    if (this.hubsStore.hub) {
      this.test = `${this.hubsStore.hub.title} Dashboard is coming soon.`;
    } else {
      this.test = `General Dashboard is coming soon.`;
    }
    this.loading = false;
  }
}
