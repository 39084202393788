import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  UntypedFormArray,
  UntypedFormControl,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { IEventConsent } from 'src/app/core/models';
import { EventsService } from 'src/app/core/services';
import { EventsStore, UsersStore } from 'src/app/core/stores';
import { consentTypes } from 'src/app/core/utils/event/consent-types';
import { Timestamp } from 'src/app/firebase';
import { EventConsentType } from 'src/app/core/enums';
import { ButtonComponent, ButtonSize } from 'src/app/standalone/shared';
import { SharedModule } from 'src/app/shared';
import { ToastComponent } from 'src/app/standalone/shared/components';

@Component({
  selector: 'app-event-consent-dialog',
  standalone: true,
  imports: [SharedModule, ButtonComponent, ToastComponent],
  templateUrl: './event-consent-dialog.component.html',
  styleUrl: './event-consent-dialog.component.scss',
})
export class EventConsentDialogComponent implements OnInit, OnDestroy {
  isCreatingOrUpdating = false;
  form: UntypedFormGroup;
  eventConsent: IEventConsent = null;
  consentTypes: { label: string; value: string }[] = consentTypes;
  buttonSize = ButtonSize;

  private subscription = new Subscription();

  constructor(
    private fb: UntypedFormBuilder,
    private usersStore: UsersStore,
    private eventsService: EventsService,
    private eventsStore: EventsStore,
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private messageService: MessageService,
    private translateService: TranslateService,
    private formBuilder: UntypedFormBuilder,
  ) {}

  get textForButton(): string {
    return !!this.eventConsent
      ? 'eventConsentDialog.updateConsent'
      : 'eventConsentDialog.createConsent';
  }

  get isBtnDisable(): boolean {
    return this.form.invalid || this.form.pristine || this.isCreatingOrUpdating;
  }

  get optionsArray(): UntypedFormArray {
    return this.form.get('options') as UntypedFormArray;
  }

  get typeOfConsent(): 'multi' | 'single' | 'pop-up' {
    return this.form.controls.type.value;
  }

  ngOnInit(): void {
    this.eventConsent = this.config.data?.consent ?? null;
    this.initForm();
  }

  async save(): Promise<void> {
    try {
      if (this.form.invalid) return;
      this.isCreatingOrUpdating = true;
      const formValue: {
        title: string;
        required: boolean;
        description: string;
        type: EventConsentType;
        options: any[];
        isSetLive: boolean;
      } = this.form.value;

      if (this.eventConsent) {
        const eventConsent: IEventConsent = {
          ...this.eventConsent,
          ...formValue,
          _title_: formValue.title ? formValue.title.toLowerCase() : null,
          options:
            formValue.type === 'multi'
              ? formValue.options.map((o) => ({ ...o, _title_: o.title.toLowerCase() }))
              : [],
          updatedAt: Timestamp.now(),
          updatedBy: this.usersStore.userId,
        };
        await this.eventsService.updateEventConsent(this.eventsStore.adminEvent.id, eventConsent);
      } else {
        const lastConsentFromDb = await this.eventsService.getLatestConsentByEventId(
          this.eventsStore.adminEvent.id,
        );
        const eventConsent: IEventConsent = {
          ...formValue,
          id: null,
          title: formValue.title ? formValue.title : '-',
          _title_: formValue.title ? formValue.title.toLowerCase() : null,
          options:
            formValue.type === 'multi'
              ? formValue.options.map((o) => ({ ...o, _title_: o.title.toLowerCase() }))
              : [],
          order: lastConsentFromDb?.order ? lastConsentFromDb.order + 1 : 1,
          isDeleted: false,
          createdAt: Timestamp.now(),
          createdBy: this.usersStore.userId,
          updatedAt: null,
          updatedBy: null,
        };
        await this.eventsService.addNewConsentToEvent(this.eventsStore.adminEvent.id, eventConsent);
      }

      this.ref.close('success');
      this.isCreatingOrUpdating = false;
    } catch (error) {
      console.log(error);
      this.showToastMessage('error', 'adminEventRegistration.consentCreatedOrUpdatedError');
      this.isCreatingOrUpdating = false;
    }
  }

  closeDialog(): void {
    this.ref.close();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private initForm(): void {
    this.form = this.fb.group({
      type: [
        {
          value: this.eventConsent ? this.eventConsent.type : 'single',
          disabled: !!this.eventConsent,
        },
        Validators.required,
      ],
      title: [this.eventConsent ? this.eventConsent.title : null, Validators.required],
      description: [this.eventConsent ? this.eventConsent.description : null],
      options: new UntypedFormArray([
        this.formBuilder.group({
          title: new UntypedFormControl(
            this.eventConsent && this.eventConsent.options.length
              ? this.eventConsent.options[0].title
              : null,
            null,
          ),
          description: new UntypedFormControl(
            this.eventConsent && this.eventConsent.options.length
              ? this.eventConsent.options[0].description
              : null,
            null,
          ),
        }),
        this.formBuilder.group({
          title: new UntypedFormControl(
            this.eventConsent && this.eventConsent.options.length
              ? this.eventConsent.options[1].title
              : null,
            null,
          ),
          description: new UntypedFormControl(
            this.eventConsent && this.eventConsent.options.length
              ? this.eventConsent.options[1].description
              : null,
            null,
          ),
        }),
      ]),
      required: [this.eventConsent ? this.eventConsent.required : false],
      isSetLive: [this.eventConsent ? this.eventConsent.isSetLive : false],
    });

    if (this.typeOfConsent === 'multi') {
      this.form.controls.title.setValidators([]);
      this.form.controls.title.updateValueAndValidity();
    }

    this.subscription.add(
      this.form.get('type').valueChanges.subscribe((value: string) => {
        if (value === 'multi') {
          this.form.controls.title.setValidators([]);
          this.form.controls.title.updateValueAndValidity();
        }
      }),
    );
  }

  private showToastMessage(severity: 'success' | 'error', detail: string): void {
    this.messageService.add({
      severity: severity,
      summary: this.translateService.instant(severity),
      detail: this.translateService.instant(detail),
      styleClass: 'custom-toast',
    });
  }
}
