import * as moment from 'moment';
import { Injectable } from '@angular/core';

import { ArrayRemove, Firestore, IFirestore, Timestamp } from 'src/app/firebase';
import { API_ROUTES as apiRoutes } from 'src/app/shared';
import { AlgoliaSearchResult, ISession, IProgramSessionFilter } from 'src/app/core/models';
import { ThemesService } from 'src/app/core/services';
import { AlgoliaService } from './../algolia/algolia.service';
import { StorageService } from '../storage/storage.service';
import { UsersStore } from '../../stores/users/users.store';

@Injectable({
  providedIn: 'root',
})
export class SessionsService {
  private firestore: IFirestore;

  constructor(
    private storageService: StorageService,
    private usersStore: UsersStore,
    private algoliaService: AlgoliaService,
    private themesService: ThemesService,
  ) {
    this.firestore = Firestore();
  }

  async fetch(
    eventId: string = null,
    pageIndex: number,
    pageSize: number,
    searchTerm: string,
  ): Promise<AlgoliaSearchResult<ISession>> {
    return await this.algoliaService.search<ISession>(
      'eventSessions',
      [eventId, searchTerm].join(' '),
      pageSize,
      pageIndex * pageSize,
    );
  }

  async getAll(eventId: string): Promise<ISession[]> {
    let sessions: ISession[] = [];
    try {
      const sessionsQuery = await this.firestore
        .collection(apiRoutes.sessions(eventId))
        .orderBy('start')
        .orderBy('_title_')
        .get();

      const stages = {};

      if (!sessionsQuery.empty) {
        sessions = await Promise.all(
          sessionsQuery.docs.map(async (doc) => {
            const session = doc.data() as ISession;

            if (session.stageId) {
              const stageDoc = await this.firestore
                .collection(apiRoutes.stages(eventId))
                .doc(session.stageId)
                .get();
              stages[session.stageId] = stageDoc.exists ? stageDoc.data() : null;
            }

            return {
              ...session,
              stage: stages[session.stageId] || null,
              stageName: stages[session.stageId] ? stages[session.stageId].name : '-',
              createdAt: session.createdAt ? (session.createdAt as Timestamp).toDate() : null,
              updatedAt: session.updatedAt ? (session.updatedAt as Timestamp).toDate() : null,
              sessionDate: session.date ? session.date.toDate() : null,
            } as ISession;
          }),
        );
      }

      return sessions;
    } catch (error) {
      console.warn(error);
      return sessions;
    }
  }

  async get(
    eventId: string = null,
    entry: ISession = null,
    pageSize: number = 12,
  ): Promise<ISession[]> {
    const sessions: ISession[] = [];
    let query = this.firestore
      .collection(apiRoutes.sessions(eventId))
      .orderBy('start')
      .orderBy('id');

    if (entry) {
      query = query.startAfter(entry.start, entry.id);
    }

    query = query.limit(pageSize);

    sessions.push(...(await query.get()).docs.map((doc) => doc.data() as ISession));

    return sessions;
  }

  async getOne(eventId: string, id: string): Promise<ISession> {
    try {
      const session = (
        await this.firestore.collection(apiRoutes.sessions(eventId)).doc(id).get()
      ).data() as ISession;

      return session;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async getBasedOnTags(
    eventId: string,
    tags: string[],
    entry: ISession = null,
    pageSize: number = 12,
  ): Promise<ISession[]> {
    const sessions = [];
    let tagIds = [...tags];

    try {
      let queryEventsByTags = null;

      while (tagIds.length > 0) {
        const chunk = tagIds.splice(0, 10);

        queryEventsByTags = this.firestore
          .collection(apiRoutes.sessions(eventId))
          .where('tags', 'array-contains-any', chunk)
          .orderBy('start')
          .orderBy('_title_')
          .orderBy('id');

        if (entry) {
          queryEventsByTags = queryEventsByTags.startAfter(entry.start, entry._title_, entry.id);
        }

        queryEventsByTags = await queryEventsByTags.limit(pageSize).get();

        if (!queryEventsByTags.empty) {
          queryEventsByTags.forEach((doc) => {
            sessions.push(doc.data());
          });
        }

        if (sessions.length === pageSize) {
          tagIds = [];
        }
      }

      return sessions;
    } catch (error) {
      console.log(error);
      return sessions;
    }
  }

  async getEventSessionsCertainStage(eventId: string, stageId: string): Promise<ISession[]> {
    const sessions: ISession[] = [];
    try {
      const sessionsQuery = await this.firestore
        .collection(apiRoutes.sessions(eventId))
        .where('stageId', '==', stageId)
        .get();

      if (!sessionsQuery.empty) {
        sessionsQuery.forEach((doc) => {
          sessions.push(doc.data() as ISession);
        });
      }

      return sessions;
    } catch (error) {
      console.warn(error);
      return sessions;
    }
  }

  async getEventSessions(eventId: string): Promise<ISession[]> {
    const sessions: ISession[] = [];
    try {
      const sessionsQuery = await this.firestore.collection(apiRoutes.sessions(eventId)).get();

      if (!sessionsQuery.empty) {
        sessionsQuery.forEach((doc) => {
          sessions.push(doc.data() as ISession);
        });
      }

      return sessions;
    } catch (error) {
      console.warn(error);
      return sessions;
    }
  }

  async getSessionsWithStageIds(eventId: string, stageIds: string[]): Promise<ISession[]> {
    const sessions: ISession[] = [];
    try {
      const sessionsQuery = await this.firestore
        .collection(apiRoutes.sessions(eventId))
        .where('stageId', 'in', stageIds)
        .get();

      if (!sessionsQuery.empty) {
        sessionsQuery.forEach((doc) => {
          sessions.push(doc.data() as ISession);
        });
      }
      return sessions;
    } catch (error) {
      console.warn(error);
      return sessions;
    }
  }

  async getAgendaSessions(
    eventId: string,
    userId: string,
    filter: IProgramSessionFilter,
  ): Promise<ISession[]> {
    try {
      const sessions: ISession[] = [];

      const startOfDay = filter.day;
      const endOfDay = moment(filter.day)
        .set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
        .toDate();

      let query = this.firestore
        .collection(apiRoutes.sessions(eventId))
        .where('start', '>=', startOfDay)
        .where('start', '<=', endOfDay)
        .orderBy('start')
        .orderBy('_title_');

      if (filter.stageId) {
        query = query.where('stageId', '==', filter.stageId);
      }

      if (filter.tagsIds.length) {
        const tagIds = [...filter.tagsIds];

        while (tagIds.length) {
          const chunk = tagIds.splice(0, 10);
          sessions.push(
            ...(await query.where('tags', 'array-contains-any', chunk).get()).docs.map(
              (doc) => doc.data() as ISession,
            ),
          );
        }
      } else if (filter.showBookmarked) {
        const userEventId = this.usersStore.userEventsMap[eventId].id;
        const bookmarkedSpeakersQuery = await this.firestore
          .collection(apiRoutes.bookmarkedSessions(userEventId))
          .get();

        const bookmarkedSessionIds = bookmarkedSpeakersQuery.docs.map((doc) => doc.id);

        while (bookmarkedSessionIds.length) {
          const chunk = bookmarkedSessionIds.splice(0, 10);

          sessions.push(
            ...(await query.where('id', 'in', chunk).get()).docs.map(
              (doc) => doc.data() as ISession,
            ),
          );
        }

        return sessions.sort((a, b) => a.start.toDate().valueOf() - b.start.toDate().valueOf());
      } else if (filter.speakersIds.length) {
        const speakerIds = [...filter.speakersIds];

        while (speakerIds.length) {
          const chunk = speakerIds.splice(0, 10);

          sessions.push(
            ...(await query.where('speakers', 'array-contains-any', chunk).get()).docs.map(
              (doc) => doc.data() as ISession,
            ),
          );
        }
      } else {
        sessions.push(...(await query.get()).docs.map((doc) => doc.data() as ISession));
      }

      return sessions.sort((a, b) => a.start.toDate().valueOf() - b.start.toDate().valueOf());
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  async getByBrand(eventId: string, brandId: string): Promise<ISession[]> {
    const sessions = [];
    const query = this.firestore
      .collection(apiRoutes.sessions(eventId))
      .where('brands', 'array-contains-any', [brandId])
      .orderBy('start')
      .orderBy('id');

    const queryResponse = await query.get();
    if (!queryResponse.empty) {
      queryResponse.forEach((doc) => sessions.push(doc.data()));
    }

    return sessions;
  }

  async getBySpeaker(eventId: string, speakerId: string): Promise<ISession[]> {
    try {
      const sessions = (
        await this.firestore
          .collection(apiRoutes.sessions(eventId))
          .where('speakers', 'array-contains', speakerId)
          .get()
      ).docs.map((doc) => doc.data() as ISession);

      return sessions;
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  async create(eventId: string, session: ISession): Promise<ISession> {
    try {
      const preSessionReq = await this.firestore.collection(apiRoutes.sessions(eventId)).doc();
      session.id = preSessionReq.id;
      const sessionDocument = this.firestore
        .collection(apiRoutes.sessions(eventId))
        .doc(session.id);

      if (session.banner instanceof File) {
        const sessionDocData = await sessionDocument.get();
        const currentBannerImage = sessionDocData.get('banner');
        const { coverImages } = await this.themesService.getSystemAppearanceSettings();

        if (currentBannerImage && !coverImages.includes(currentBannerImage)) {
          await this.storageService.delete(currentBannerImage);
        }

        if (session.banner !== null) {
          const fileName = `file_${new Date().getTime()}`;
          const newCoverImage = await this.storageService.upload(
            session.banner,
            'sessions',
            fileName,
          );
          session.banner = newCoverImage;
        } else {
          session.banner = null;
        }
      }

      await sessionDocument.set({
        ...session,
        _title_: session.title.toLowerCase(),
        createdAt: Timestamp.now(),
        createdBy: this.usersStore.user.id,
      });

      return session as ISession;
    } catch (error) {
      throw error;
    }
  }

  async update(eventId: string, sessionId: string, session: ISession): Promise<ISession> {
    try {
      const sessionDocument = this.firestore.collection(apiRoutes.sessions(eventId)).doc(sessionId);

      if (session.banner instanceof File) {
        const sessionDocData = await sessionDocument.get();
        const currentBannerImage = sessionDocData.get('banner');
        const { coverImages } = await this.themesService.getSystemAppearanceSettings();

        if (currentBannerImage && !coverImages.includes(currentBannerImage)) {
          await this.storageService.delete(currentBannerImage);
        }

        if (session.banner !== null) {
          const fileName = `file_${new Date().getTime()}`;
          const newCoverImage = await this.storageService.upload(
            session.banner,
            'sessions',
            fileName,
          );
          session.banner = newCoverImage;
        } else {
          session.banner = null;
        }
      }

      await sessionDocument.update({
        ...session,
        updatedAt: Timestamp.now(),
        updatedBy: this.usersStore.user.id,
      });

      return session as ISession;
    } catch (error) {
      throw error;
    }
  }

  async remove(docId: string, eventId: string): Promise<boolean> {
    try {
      await this.firestore.collection(apiRoutes.sessions(eventId)).doc(docId).delete();
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async unassignStage(stageId: string, eventId: string): Promise<boolean> {
    try {
      const sessionsRef = await this.firestore.collection(apiRoutes.sessions(eventId)).get();
      await sessionsRef.forEach(async (doc) => {
        const data: ISession = doc.data() as ISession;
        if (data.stageId === stageId) {
          await this.firestore.collection(apiRoutes.sessions(eventId)).doc(doc.id).update({
            stageId: null,
          });
        }
      });
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async removeSpeakerFromSession(
    eventId: string,
    sessionId: string,
    speakerId: string,
  ): Promise<boolean> {
    try {
      await this.firestore
        .collection(apiRoutes.sessions(eventId))
        .doc(sessionId)
        .update({
          speakers: ArrayRemove(speakerId),
        });

      return true;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async deleteSpeakerFromAllSessionsInEvent(eventId: string, speakerId: string): Promise<boolean> {
    try {
      const sessions: ISession[] = await this.getAll(eventId);
      await Promise.all(
        sessions.map((session: ISession) =>
          this.removeSpeakerFromSession(eventId, session.id, speakerId),
        ),
      );

      return true;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async removeModeratorFromSession(
    eventId: string,
    sessionId: string,
    moderatorId: string,
  ): Promise<boolean> {
    try {
      await this.firestore
        .collection(apiRoutes.sessions(eventId))
        .doc(sessionId)
        .update({
          moderators: ArrayRemove(moderatorId),
        });

      return true;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async deleteModeratorFromAllSessionsInEvent(
    eventId: string,
    moderatorId: string,
  ): Promise<boolean> {
    try {
      const sessions: ISession[] = await this.getAll(eventId);
      await Promise.all(
        sessions.map((session: ISession) =>
          this.removeModeratorFromSession(eventId, session.id, moderatorId),
        ),
      );

      return true;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}
