import { Timestamp } from 'src/app/firebase';
import { NotificationTypes } from 'src/app/core/enums';
import { IAppointmentUser } from '../appointment';
import { IAppointment } from '../appointment/appointment.model';
import { IUser } from '../user';
import { INotificationData } from './notification-data.model';

export interface INotification {
  id: string;
  title: string;
  description: string;
  type: NotificationTypes;
  isSeen: boolean;
  data: INotificationData;
  createdAt: Timestamp;
  createdBy: string;
  updatedAt: Timestamp;
  updatedBy: string;
  user?: IUser;
  hostId: string;
  host?: IUser;
  appointment?: IAppointment;
  appointmentUser?: IAppointmentUser;
  isLoading?: boolean;
}
