import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { INotification } from 'src/app/core/models';
import { NotificationsService } from 'src/app/core/services';
import { EventsStore } from 'src/app/core/stores';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
  public loading = true;
  public notificationId;
  public title: string = null;
  public notification: INotification;

  constructor(
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private notificationsService: NotificationsService,
    private eventsStore: EventsStore,
  ) {}

  async ngOnInit(): Promise<void> {
    this.route.params.subscribe(async (params) => {
      this.title = this.translateService.instant(
        params.notificationId
          ? 'adminEventNotificationForm.editNotificationTitle'
          : 'adminEventNotificationForm.newNotificationTitle',
      );

      this.notificationId = params.notificationId;
      this.notification = await this.notificationsService.getEventNotificationById(
        this.notificationId,
        this.eventsStore.adminEvent.id,
      );
      this.loading = false;
    });
  }
}
