import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

import { IBrand, IEvent, IHub, ITag, IUser } from 'src/app/core/models';
import { BrandsService, TagsService } from 'src/app/core/services';
import { BrandsStore, EventsStore, HubsStore } from 'src/app/core/stores';
import { AppStore } from 'src/app/app.store';
import { IAdminTableColumn } from 'src/app/shared';
import { ButtonSize } from 'src/app/standalone';

@Component({
  templateUrl: './attach-users-to-brand-dialog.component.html',
  styleUrls: ['./attach-users-to-brand-dialog.component.scss'],
})
export class AttachUsersToBrandDialogComponent implements OnInit {
  attachLoading = false;
  loadingEntries = true;
  entries: IUser[] = [];
  selectedEntries: IUser[] = [];
  totalRecords = 0;
  pageSize = 10;
  tags: ITag[] = [];
  selectedTags: ITag[] = [];
  columns: IAdminTableColumn[] = [
    { field: 'firstName', titleTranslationKey: 'adminUsers.tableColFirstName' },
    { field: 'lastName', titleTranslationKey: 'adminUsers.tableColLastName' },
    { field: 'email', titleTranslationKey: 'adminUsers.tableColEmail' },
    { field: 'company', titleTranslationKey: 'adminUsers.tableColCompany' },
    { field: 'position', titleTranslationKey: 'adminUsers.tableColPosition' },
  ];
  buttonSize = ButtonSize;

  private userSearchTerm = '';
  private tagSearchTerm = '';
  private page = 0;
  private brandMembers = [];

  constructor(
    private eventStore: EventsStore,
    private brandsStore: BrandsStore,
    private messageService: MessageService,
    private translateService: TranslateService,
    private tagsService: TagsService,
    private brandService: BrandsService,
    private hubsStore: HubsStore,
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    public appStore: AppStore,
  ) {
    this.brandMembers = config.data?.members.map((m) => m.id) || [];
  }

  get event(): IEvent {
    return this.eventStore.adminEvent;
  }

  get brand(): IBrand {
    return this.brandsStore.adminBrand;
  }

  get filteredTags(): ITag[] {
    return this.tags.filter((tag) =>
      tag.title.toLowerCase().includes(this.tagSearchTerm.toLowerCase()),
    );
  }

  get hub(): IHub {
    return this.hubsStore.hub;
  }

  async ngOnInit(): Promise<void> {
    await Promise.all([this.fetchUsers(), this.fetchTags()]);
    this.loadingEntries = false;
  }

  tagSearchChanged(value: string): void {
    this.tagSearchTerm = value;
  }

  async userSearchChanged(value: string): Promise<void> {
    this.loadingEntries = true;
    this.userSearchTerm = value;
    await this.fetchUsers();
    this.loadingEntries = false;
  }

  async fetchEntries(event: any): Promise<void> {
    this.loadingEntries = true;
    this.pageSize = event.rows;
    this.page = event.first / this.pageSize;
    await this.fetchUsers();
    this.loadingEntries = false;
  }

  async attach(): Promise<void> {
    this.attachLoading = true;
    await Promise.all(
      this.selectedEntries.map((user) =>
        this.brandService.addBrandMemberFromAdminPart(this.brand.id, user.email, user.id),
      ),
    );
    this.messageService.add({
      key: 'user',
      severity: 'success',
      summary: this.translateService.instant('success'),
      detail: this.translateService.instant('adminBrandTeam.brandSuccessfullyAttached', {
        count: this.selectedEntries.length,
      }),
      styleClass: 'custom-toast',
    });
    this.attachLoading = false;

    this.selectedEntries.map((e) => this.brandMembers.push(e.id));
    this.selectedEntries = [];

    await this.fetchUsers();
  }

  async tagSelectionChanged(tags: ITag[]): Promise<void> {
    this.selectedTags = tags;
    await this.fetchUsers();
  }

  onCloseDialog(): void {
    this.ref.close();
  }

  private async fetchUsers(): Promise<void> {
    const response = await this.brandService.fetchUnattachedUsers(
      this.hub.id,
      this.brandMembers,
      this.page,
      this.pageSize,
      [...this.selectedTags.map((t) => t.id), this.userSearchTerm].join(' '),
    );

    this.entries = response.results;
    this.totalRecords = response.total;
  }

  private async fetchTags(): Promise<void> {
    this.tags = await this.tagsService.getByIdsOrderedByTitle(this.brand.tags);
  }
}
