import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appInputOnlyPostalCodeNumberValues]',
})
export class InputOnlyPostalCodeNumberValuesDirective {
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (
      isNaN(parseInt(event.key)) &&
      event.key !== '-' &&
      event.code !== 'ArrowLeft' &&
      event.code !== 'ArrowRight' &&
      event.code !== 'Backspace' &&
      event.code !== 'Delete'
    ) {
      event.preventDefault();
    }
  }
}
