<div
  *ngIf="!showOnlyLogo && !showOnlyTitle && !caseWithLogoWithoutBrowserEvent"
  class="brand-card-container flex"
  [ngClass]="[isMedia ? 'blocked-click-event' : '']"
  [class.default-cursor]="!showDetailsOnClick"
  (click)="onClick($event)">
  <app-update-default-covers
    *ngIf="brand.banner"
    [url]="brand.banner"
    [color]="currentEvent.accentColor">
  </app-update-default-covers>
  <div class="data">
    <div
      *ngIf="showBookmarkContent"
      class="bookmark-icon-box">
      <p-progressSpinner *ngIf="showBookmarkSpinner"></p-progressSpinner>
      <i
        *ngIf="!showBookmarkSpinner"
        [ngClass]="getBookmarkedIconClass()"></i>
    </div>
    <img
      *ngIf="brand.logo !== null && brand.logo !== ''"
      class="brand-logo"
      src="{{ brand.logo | imageSize }}" />
    <div class="brand-name">
      <p class="name">{{ brand.name }}</p>
      <small class="brand-level">{{ getBrandLevelLabel(brand?.level) }}</small>
      <p class="slogan">{{ brand.slogan }}</p>
    </div>
  </div>
</div>

<div
  *ngIf="showOnlyLogo"
  class="brand-logo-only"
  [ngClass]="[imageSize, centered ? 'center' : '', isMedia ? 'blocked-click-event' : '']"
  [class.default-cursor]="!showDetailsOnClick"
  (click)="onClick($event)">
  <img
    [defaultImage]="imgPreloader"
    [lazyLoad]="brand?.logo | imageSize"
    class="img-preloader"
    *ngIf="brand?.logo" />
</div>

<div
  *ngIf="showOnlyTitle"
  class="brand-title-only"
  (click)="onClick($event)"
  [ngClass]="[isMedia ? 'blocked-click-event' : '']">
  {{ brand.name }}
</div>

<!-- this option is the same as "showOnlyLogo", only without the click event on the div element -->
<div
  *ngIf="caseWithLogoWithoutBrowserEvent"
  class="brand-logo-only"
  [ngClass]="[imageSize, centered ? 'center' : '', isMedia ? 'blocked-click-event' : '']"
  [class.default-cursor]="!showDetailsOnClick">
  <img
    *ngIf="brand?.logo"
    class="img-preloader"
    [defaultImage]="imgPreloader"
    [lazyLoad]="brand?.logo | imageSize" />
</div>
