<ng-container *ngIf="loading">
  <app-loader class="new-library-asset-container"></app-loader>
</ng-container>

<div
  *ngIf="!loading"
  class="new-asset-container">
  <div class="header c-p-5">
    <div class="title">
      {{ 'adminLibraryAssetFrom.createAsset' | translate }}
    </div>
    <i
      class="fa-regular fa-xmark close-icon"
      (click)="onCloseDialog()"></i>
  </div>

  <form
    class="c-p-5"
    [formGroup]="form">
    <!-- File type input -->
    <div class="type-wrapper c-mb-4">
      <app-custom-radio-button
        *ngFor="let typeAsset of typeAssets; trackBy: trackByFn"
        [isActive]="typeAsset.value === form.controls.type.value"
        [iconClass]="typeAsset.iconClass"
        [title]="typeAsset.title"
        [description]="typeAsset.description"
        (click)="onChangeAssetType(typeAsset)" />
    </div>

    <!-- Title input -->
    <div class="form-field-block">
      <app-input-label
        labelType="required"
        for="title-input"
        text="adminLibraryAssetFrom.title">
      </app-input-label>
      <input
        class="custom-input-text custom-input-text-required"
        formControlName="title"
        id="title-input"
        pInputText
        type="text"
        placeholder="{{ 'adminLibraryAssetFrom.titlePlaceholder' | translate }}" />
      <app-input-error-message
        [showMessage]="form.controls.title.invalid && form.controls.title.touched"
        errorMessage="{{ 'application.forms.required' | translate }}">
      </app-input-error-message>
    </div>

    <!-- Internal video input -->
    <div
      *ngIf="form.controls.type.value === assetType.VIDEO"
      class="internal-video-wrapper c-mb-4"
      (drop)="onDrop($event)"
      (dragover)="onDragOver($event)">
      <input
        class="input"
        type="file"
        #internalVideoInput
        [accept]="allowedFormatsForInternalVideo"
        (change)="uploadInternalVideo($event)" />
      <div
        *ngIf="!form.controls.internalVideo.value"
        class="upload-icon">
        <i class="fa-regular fa-video"></i>
        <span>
          <span
            class="choose-file"
            (click)="internalVideoInput.click()">
            {{ 'adminLibraryAssetFrom.chooseFile' | translate }}
          </span>
          {{ 'adminLibraryAssetFrom.orDragAndDrop' | translate }}
        </span>
        <span>{{ 'adminLibraryAssetFrom.videoFileLimit' | translate }}</span>
      </div>
      <video
        *ngIf="fileVideoPreview"
        [src]="fileVideoPreview"
        controls></video>
      <button
        *ngIf="form.controls.internalVideo.value"
        class="p-button-rounded p-button-outlined clear-btn"
        pButton
        pRipple
        type="button"
        icon="far fa-circle-xmark"
        (click)="clearInternalVideo()"></button>
    </div>

    <div
      *ngIf="form.controls.type.value === assetType.VIDEO"
      class="separator">
      {{ 'adminLibraryAssetFrom.videoSeparatorText' | translate }}
    </div>

    <!-- Video link input -->
    <div
      *ngIf="form.controls.type.value === assetType.VIDEO"
      class="form-field-block">
      <app-input-label
        for="video-link-input"
        text="adminLibraryAssetFrom.url">
      </app-input-label>
      <div class="p-inputgroup custom-input-group">
        <span class="p-inputgroup-addon">Embed-URL</span>
        <input
          formControlName="videoLink"
          id="video-link-input"
          pInputText
          type="text"
          placeholder="{{ 'adminLibraryAssetFrom.urlPlaceholder' | translate }}" />
      </div>
      <p class="tooltip-text c-m-0">
        {{ 'adminLibraryAssetFrom.urlTooltip' | translate }}
      </p>
    </div>

    <!-- File input -->
    <div
      *ngIf="form.controls.type.value === assetType.FILE"
      class="form-field-block">
      <app-input-label
        labelType="required"
        for="file-input"
        text="adminLibraryAssetFrom.uploadFile">
      </app-input-label>
      <p-fileUpload
        #fileUpload
        class="custom-file-upload"
        styleClass="c-mt-2"
        [customUpload]="true"
        [auto]="true"
        [showUploadButton]="false"
        [showCancelButton]="false"
        chooseLabel="{{ 'adminLibraryAssetFrom.addFile' | translate }}"
        (click)="customClear(fileUpload)"
        (uploadHandler)="onUpload($event)"
        (onError)="onError($event)">
      </p-fileUpload>
    </div>
    <p
      *ngIf="form.controls.type.value === assetType.FILE"
      class="tooltip-text c-m-0">
      {{ 'adminLibraryAssetFrom.typeDescription' | translate }}
    </p>

    <ng-container *ngIf="form.controls.type.value === assetType.EVENT">
      <!-- Date input -->
      <div class="form-field-block">
        <app-input-label
          labelType="required"
          for="date-input"
          text="adminLibraryAssetFrom.dateLabel">
        </app-input-label>
        <p-calendar
          class="custom-calendar custom-calendar-required"
          panelStyleClass="custom-calendar-panel-style-class"
          formControlName="date"
          [monthNavigator]="true"
          [yearNavigator]="true"
          [showIcon]="true"
          dateFormat="dd/mm/yy"
          yearRange="1900:2050"
          inputId="date-input"
          icon="fak fa-lox-calendar"
          [placeholder]="datePlaceholder"
          appendTo="body">
        </p-calendar>
        <app-input-error-message
          [showMessage]="form.controls.date.invalid && form.controls.date.touched"
          errorMessage="{{ 'application.forms.required' | translate }}">
        </app-input-error-message>
      </div>

      <div class="two-elements-block c-pr-0 c-pl-0">
        <!-- Start time input -->
        <div>
          <app-input-label
            labelType="required"
            for="start-time-input"
            text="adminLibraryAssetFrom.startTimeLabel">
          </app-input-label>
          <p-calendar
            class="custom-calendar custom-calendar-time custom-calendar-required"
            formControlName="start"
            placeholder="09:00"
            inputId="start-time-input"
            [timeOnly]="true"
            [showIcon]="true"
            icon="far fa-clock"
            appDateMask>
          </p-calendar>
          <app-input-error-message
            [showMessage]="form.controls.start.invalid && form.controls.start.touched"
            errorMessage="{{ 'application.forms.required' | translate }}">
          </app-input-error-message>
        </div>

        <!-- End time input -->
        <div>
          <app-input-label
            labelType="required"
            for="end-time-input"
            text="adminLibraryAssetFrom.endTimeLabel">
          </app-input-label>
          <p-calendar
            class="custom-calendar custom-calendar-time custom-calendar-required"
            formControlName="end"
            placeholder="09:00"
            inputId="end-time-input"
            [timeOnly]="true"
            [showIcon]="true"
            icon="far fa-clock"
            appDateMask>
          </p-calendar>
          <app-input-error-message
            [showMessage]="form.controls.end.invalid && form.controls.end.touched"
            errorMessage="{{ 'application.forms.required' | translate }}">
          </app-input-error-message>
        </div>
      </div>

      <!-- Instructors input -->
      <div class="form-field-block c-mb-4">
        <app-input-label
          labelType="tooltip-required"
          tooltipText="adminLibraryAssetFrom.instructorsTooltip"
          for="instructors-input"
          text="adminLibraryAssetFrom.instructorsLabel">
        </app-input-label>
        <p-multiSelect
          class="custom-multi-select custom-multi-select-required"
          panelStyleClass="custom-multi-select-panel-style-class"
          [options]="instructors"
          optionLabel="lastName"
          optionValue="id"
          formControlName="instructors"
          inputId="instructors-input"
          appendTo="body"
          placeholder="{{ 'adminLibraryAssetFrom.instructorsLabel' | translate }}">
          <ng-template
            let-instructor
            pTemplate="item">
            {{ instructor.firstName }} {{ instructor.lastName }}
          </ng-template>
        </p-multiSelect>
      </div>

      <!-- Asset Event Type -->
      <div class="form-field-block c-mb-4">
        <p class="form-field-title">
          {{ 'adminLibraryAssetFrom.eventType' | translate }}
          <i
            class="fa-regular fa-circle-info"
            pTooltip="{{ 'adminLibraryAssetFrom.eventTypeTooltip' | translate }}"
            tooltipStyleClass="custom-tooltip">
          </i>
          <span class="star">*</span>
        </p>
        <div class="form-field-input-radio-block c-mb-4 c-mt-4 c-p-0">
          <p-radioButton
            class="custom-input-radio"
            [value]="eventType.BROADCAST"
            formControlName="eventType"
            inputId="broadcast-input">
          </p-radioButton>
          <app-input-label
            for="broadcast-input"
            text="adminLibraryAssetFrom.broadcastLabel"
            labelType="switch-checkbox">
          </app-input-label>
        </div>
        <div class="form-field-input-radio-block c-p-0">
          <p-radioButton
            class="custom-input-radio"
            [value]="eventType.MEETING"
            formControlName="eventType"
            inputId="meeting-input">
          </p-radioButton>
          <app-input-label
            for="meeting-input"
            text="adminLibraryAssetFrom.meetingLabel"
            labelType="switch-checkbox">
          </app-input-label>
        </div>
      </div>

      <!-- Recording -->
      <p class="form-field-title">
        {{ 'adminLibraryAssetFrom.recording' | translate }}
      </p>
      <div class="form-field-input-switch-block c-p-0">
        <p-inputSwitch
          class="custom-input-switch"
          formControlName="isRecording"
          inputId="is-recording-input">
        </p-inputSwitch>
        <app-input-label
          labelType="switch-checkbox"
          tooltipText="Need to add"
          for="is-recording-input"
          text="adminLibraryAssetFrom.enableRecording">
        </app-input-label>
      </div>
    </ng-container>
  </form>

  <div class="footer c-p-2 c-pl-5">
    <div class="col text-left form-field-input-checkbox-block c-p-0">
      <p-checkbox
        class="custom-input-checkbox-sm"
        binary="true"
        [formControl]="createAnotherControl"
        inputId="another-asset-input">
      </p-checkbox>
      <app-input-label
        labelType="switch-checkbox"
        for="another-asset-input"
        text="adminLibraryAssetFrom.createAnother">
      </app-input-label>
    </div>
    <div class="col text-right flex justify-content-end align-items-center">
      <app-button
        buttonText="{{ 'adminLibraryAssetFrom.cancel' | translate }}"
        buttonIconLeft="fa-regular fa-xmark"
        [buttonStyle]="buttonStyle.GHOST_PRIMARY"
        [buttonSize]="buttonSize.MEDIUM"
        (click)="onCloseDialog()" />
      <app-button
        class="c-ml-2"
        buttonText="{{ 'adminLibraryAssetFrom.create' | translate }}"
        buttonIconLeft="far fa-check"
        [buttonSize]="buttonSize.MEDIUM"
        [loading]="isUpdating"
        [disabled]="!canUpdate"
        (click)="create()" />
    </div>
  </div>
</div>
