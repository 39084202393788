import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

import {
  IAdminTableFilter,
  IAdminTableFilterChange,
  ConfirmDialogComponent,
  imgPreloader,
  logoAvatar,
  IAdminTableColumn,
  pngImg,
  jpgImg,
  pdfImg,
  zipImg,
  videoImg,
} from 'src/app/shared';
import { IBrand, IAsset, ITag, AlgoliaSearchResult, IHubBrand } from 'src/app/core/models';
import { BrandsService, HubsService, LibraryService, TagsService } from 'src/app/core/services';
import { HubsStore, CoursesStore } from 'src/app/core/stores';
import { AppStore } from 'src/app/app.store';
import { AssetFileTypes, AssetType } from 'src/app/core/enums';
import { NewAssetComponent } from 'src/app/standalone/shared/components/asset';
import { ButtonSize, ButtonStyle, ButtonType } from 'src/app/standalone';

@Component({
  selector: 'app-library',
  templateUrl: './library.component.html',
  styleUrls: ['./library.component.scss'],
})
export class LibraryComponent implements OnInit {
  loading = true;
  loadingEntries = true;
  columns: IAdminTableColumn[] = [
    { field: 'title', template: 'titleCell', titleTranslationKey: 'adminLibraryList.tableTitle' },
    { field: 'brandName', titleTranslationKey: 'adminLibraryList.brand' },
    { field: 'type', titleTranslationKey: 'adminLibraryList.type' },
    { field: 'actions', template: 'actionsCell' },
  ];
  entries: IAsset[] = [];
  totalRecords = 0;
  pageSize = 25;
  tableFilters: IAdminTableFilter[] = [];
  brands: IBrand[] = [];
  selectedBrands: string[] = [];
  assetBrands: IBrand[] = [];
  tags: ITag[] = [];
  imgPreloader = imgPreloader;
  logoAvatar = logoAvatar;
  assetType = AssetType;
  assetEventBannerContainerStyles = {
    background: '#323232',
    'border-radius': '0.25rem',
  };
  assetEventBannerMonthStyles = {
    'font-size': '0.625rem',
    'line-height': '0.625rem',
  };
  assetEventBannerDateStyle = {
    'font-size': '1.125rem',
    'line-height': '1.125rem',
  };
  buttonSize = ButtonSize;
  buttonType = ButtonType;
  buttonStyle = ButtonStyle;

  private selectedTags: string[] = [];
  private selectedTypes: string[] = [];
  private searchTerm = '';
  private hubBrands: IHubBrand[];

  constructor(
    public hubsStore: HubsStore,
    public courseStore: CoursesStore,
    public appStore: AppStore,
    private libraryService: LibraryService,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private dialogService: DialogService,
    private brandsService: BrandsService,
    private tagsService: TagsService,
    private translateService: TranslateService,
    private hubsService: HubsService,
  ) {}

  async ngOnInit(): Promise<void> {
    [, this.tags, this.hubBrands] = await Promise.all([
      this.fetchAssets(0),
      this.tagsService.getAll(),
      this.hubsService.getBrandsFromHub(this.hubsStore.hubId),
    ]);
    const brandIds: string[] = this.hubBrands.map((hubBrand: IHubBrand) => hubBrand.brandId);
    this.brands = await this.brandsService.getByIds(brandIds);
    this.tableFilters = [
      {
        key: 'TAGS',
        label: 'adminLibraryList.tags',
        options: this.tags.map((t) => ({ label: t.title, value: t.id })),
      },
      {
        key: 'BRANDS',
        label: 'adminLibraryList.brand',
        options: this.brands.map((t) => ({ label: t.name, value: t.id })),
      },
      {
        key: 'TYPE',
        label: 'adminLibraryList.type',
        options: Object.values(AssetType).map((type) => ({ label: type, value: type })),
      },
    ];

    if (!this.hubsStore.hub.brands) {
      this.tableFilters = this.tableFilters.filter((filter) => filter.key !== 'BRANDS');
      this.columns = this.columns.filter((column) => column.field !== 'brandName');
    }

    this.loadingEntries = false;
    this.loading = false;
  }

  async onSearchTermChange(e: string): Promise<void> {
    this.loadingEntries = true;
    this.searchTerm = e;
    await this.fetchAssets(0);
    this.loadingEntries = false;
  }

  async fetchEntries(event: any): Promise<void> {
    this.loadingEntries = true;
    this.pageSize = event.rows;
    await this.fetchAssets(event.first / this.pageSize);
    this.loadingEntries = false;
  }

  async openNewLibraryAssetDialog(): Promise<void> {
    const newAssetDialogRef: DynamicDialogRef = this.dialogService.open(NewAssetComponent, {
      styleClass: 'new-library-asset-dialog',
      closable: false,
    });

    const newAssetIds: string[] = await newAssetDialogRef.onClose.pipe(take(1)).toPromise();
    if (newAssetIds?.length) {
      this.router.navigate(
        [`/${this.hubsStore.useHubUrl}/admin/library/asset/${newAssetIds.at(-1)}`],
        {
          queryParams: { 'action-before': 'create' },
        },
      );
    }
  }

  async filtersChange(e: IAdminTableFilterChange[]): Promise<void> {
    this.loadingEntries = true;
    this.selectedTags = e.filter((f) => f.filterKey === 'TAGS').map((f) => f.optionKey);
    this.selectedBrands = e.filter((f) => f.filterKey === 'BRANDS').map((f) => f.optionKey);
    this.selectedTypes = e.filter((f) => f.filterKey === 'TYPE').map((f) => f.optionKey);

    await this.fetchAssets(0);
    this.loadingEntries = false;
  }

  getImagePlaceholder(asset: IAsset): string {
    if (asset.type === AssetType.VIDEO) {
      return videoImg;
    }

    switch (asset.fileType) {
      case AssetFileTypes.PNG:
        return pngImg;
      case AssetFileTypes.JPG:
        return jpgImg;
      case AssetFileTypes.PDF:
        return pdfImg;
      case AssetFileTypes.ZIP:
        return zipImg;
      default:
        return pngImg;
    }
  }

  onRowClicked(id: string): void {
    this.router.navigate([`/${this.hubsStore.useHubUrl}/admin/library/asset/${id}`], {
      relativeTo: this.route,
      state: {
        assetId: id,
      },
    });
  }

  edit(event: Event, entry: IAsset): void {
    event.stopPropagation();
    this.router.navigate([`/${this.hubsStore.useHubUrl}/admin/library/asset/${entry.id}`], {
      relativeTo: this.route,
      state: {
        assetId: entry.id,
      },
    });
  }

  remove(event: Event, entry: IAsset): void {
    event.stopPropagation();
    const deleteDialogRef = this.dialogService.open(ConfirmDialogComponent, {
      closable: false,
      styleClass: 'confirm-dialog',
      data: {
        titleKey: 'adminLibraryList.deleteAsset',
        descriptionKey: 'adminLibraryList.deleteAssetText',
        confirmBtnKey: 'adminLibraryList.confirmBtn',
        cancelBtnKey: 'adminLibraryList.cancelBtn',
        entry,
      },
    });

    deleteDialogRef.onClose.pipe(take(1)).subscribe(async (result: 'cancel' | 'confirm') => {
      if (result === 'confirm') {
        try {
          this.entries[this.entries.indexOf(entry)].isActionBtnShowingSpinner = true;
          const deleteResult = await this.libraryService.delete(this.hubsStore.hubId, entry.id);
          if (deleteResult) {
            this.removeEntry(entry);
            this.messageService.add({
              severity: 'success',
              summary: this.translateService.instant('success'),
              detail: this.translateService.instant('adminLibraryList.succesDeleteAsset'),
              styleClass: 'custom-toast',
            });
          }
        } catch (error) {
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('success'),
            detail: this.translateService.instant('adminLibraryList.errorDeleteAsset'),
            styleClass: 'custom-toast',
          });
        }
      }
    });
  }

  private async fetchAssets(page: number): Promise<void> {
    const filters: string = this.selectedTypes.reduce(
      (filters: string, selectedType: string, index: number) =>
        index === 0 ? `type:${selectedType}` : `${filters} OR type:${selectedType}`,
      '',
    );
    const response: AlgoliaSearchResult<IAsset> = await this.libraryService.getAll(
      this.hubsStore.hubId,
      page,
      this.pageSize,
      [...this.selectedTags, ...this.selectedBrands, this.searchTerm].join(' '),
      filters,
    );

    this.entries = response.results;
    this.totalRecords = response.total;
    const brandIds: string[] = [];

    this.entries.forEach((entry: IAsset) => {
      if (!!entry.brands && entry.brands?.length !== 0) {
        if (!brandIds.includes(entry.brands[0])) {
          brandIds.push(entry.brands[0]);
        }
      }
    });
    this.brandsService.getByIds(brandIds).then((brands: IBrand[]) => {
      this.assetBrands = brands;

      this.entries.forEach((entry: IAsset) => {
        if (!!entry.brands && entry.brands?.length !== 0) {
          entry.brandName = this.assetBrands.find((x) => x.id === entry.brands[0])?.name;
        }
      });
    });
  }

  private removeEntry(entry: IAsset): void {
    this.entries.splice(this.entries.indexOf(entry), 1);
    this.totalRecords -= 1;
  }
}
