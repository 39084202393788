<div class="outer">
  <p-skeleton
    class="container"
    width="100%"
    height="16rem"></p-skeleton>
  <div class="inner">
    <p-skeleton
      class="logo"
      borderRadius="1rem"
      height="5.5rem"
      width="7rem"
      styleClass="mb-2"></p-skeleton>
    <div>
      <p-skeleton
        height="1.5rem"
        width="8rem"
        styleClass="mb-2"></p-skeleton>
      <p-skeleton
        height="1rem"
        width="10rem"></p-skeleton>
    </div>
  </div>
</div>
