import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';

import { ServerTimestamp } from 'src/app/firebase';
import { UsersStore } from 'src/app/core/stores';
import { ITag } from 'src/app/core/models';
import { TagsService } from 'src/app/core/services';
import { ButtonSize } from 'src/app/standalone';

@Component({
  selector: 'app-admin-tag-form',
  templateUrl: './admin-tag-form.component.html',
  styleUrls: ['./admin-tag-form.component.scss'],
})
export class AdminTagFormComponent implements OnInit {
  saving = false;
  pageTitle: string = 'adminSystemTags.tagFormAddTitle';
  form: UntypedFormGroup = new UntypedFormGroup({
    title: new UntypedFormControl(null, [Validators.required]),
  });
  buttonSize = ButtonSize;

  private tag: ITag = null;

  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private tagsService: TagsService,
    private usersStore: UsersStore,
  ) {}

  ngOnInit(): void {
    this.tag = this.config.data?.tag || null;
    this.pageTitle = this.tag
      ? 'adminSystemTags.tagFormEditTitle'
      : 'adminSystemTags.tagFormAddTitle';

    if (this.tag) {
      this.form.patchValue({
        title: this.tag.title,
      });
      this.form.updateValueAndValidity();
      this.form.valueChanges.subscribe(() => this.form.markAsDirty());
    }
  }

  onCloseDialog(): void {
    this.ref.close();
  }

  async save(): Promise<void> {
    this.saving = true;

    if (this.tag) {
      const payload = {
        id: this.tag.id,
        title: this.form.value.title,
        _title_: this.form.value.title.toLowerCase(),
        updatedAt: ServerTimestamp(),
        updatedBy: this.usersStore.user.id,
        createdAt: this.tag.createdAt,
        createdBy: this.tag.createdBy,
      };

      await this.tagsService.update(this.tag.id, payload);
      this.saving = false;
      this.ref.close(true);
    } else {
      try {
        await this.tagsService.create(this.form.value.title);
        this.saving = false;
        this.ref.close(true);
      } catch (error) {
        console.log(error);
        this.saving = false;
        this.ref.close(false);
      }
    }
  }
}
