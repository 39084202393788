import * as moment from 'moment';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject, filter, merge, takeUntil, tap } from 'rxjs';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

import { IEvent, IEventMenuOption } from 'src/app/core/models';
import { EventMenuService, EventsService, FormService } from 'src/app/core/services';
import { EventsStore, HubsStore } from 'src/app/core/stores';
import { asyncDelay, updateTime } from 'src/app/core/utils';
import { SharedModule, parseToMoment } from 'src/app/shared';
import { SaveDiscardActionsComponent, ToastComponent } from 'src/app/standalone/shared';
import { Timestamp } from 'src/app/firebase';

@Component({
  selector: 'app-event-settings',
  standalone: true,
  imports: [SharedModule, SaveDiscardActionsComponent, ToastComponent],
  templateUrl: './event-settings.component.html',
  styleUrl: './event-settings.component.scss',
})
export class EventSettingsComponent implements OnInit, OnDestroy {
  form: FormGroup;
  loading = true;
  isUpdating = false;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private eventsStore: EventsStore,
    private fb: FormBuilder,
    private formService: FormService,
    private eventsService: EventsService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private hubsStore: HubsStore,
    private eventMenuService: EventMenuService,
  ) {}

  get event(): IEvent {
    return this.eventsStore.adminEvent;
  }

  get canUpdate(): boolean {
    return (
      !this.isUpdating && this.form.valid && this.form.dirty && this.formService.isValueChanged()
    );
  }

  get shouldShowBrands(): boolean {
    return this.hubsStore.hub.brands;
  }

  ngOnInit(): void {
    this.createForm();
    this.updateForm();
    this.formService.setForm(this.form);
    this.loading = false;

    const scheduleEventCheckin$ = this.form.controls.scheduleEventCheckin.valueChanges.pipe(
      tap((value: boolean) => {
        if (value) {
          const date = this.event?.scheduleEventDate
            ? parseToMoment(this.event.scheduleEventDate)
            : null;
          const defaultDate = moment()
            .subtract(1, 'week')
            .hours(9)
            .minutes(0)
            .seconds(0)
            .milliseconds(0);
          this.form.controls.scheduleEventDate.setValidators(Validators.required);
          this.form.controls.scheduleEventTime.setValidators(Validators.required);
          this.form.controls.scheduleEventDate.setValue(
            !!date ? date.toDate() : defaultDate.toDate(),
          );
          this.form.controls.scheduleEventTime.setValue(
            !!date ? date.toDate() : defaultDate.toDate(),
          );
        } else {
          this.form.controls.scheduleEventDate.removeValidators(Validators.required);
          this.form.controls.scheduleEventTime.removeValidators(Validators.required);
          this.form.controls.scheduleEventDate.setValue(null);
          this.form.controls.scheduleEventTime.setValue(null);
        }
      }),
    );

    const isOnDemandMode$ = this.form.controls.isOnDemandMode.valueChanges.pipe(
      filter((value: boolean) => !!value),
      tap(() => {
        this.form.controls.isOnDemandComingSoon.setValue(false);
      }),
    );

    const isOnDemandComingSoon$ = this.form.controls.isOnDemandComingSoon.valueChanges.pipe(
      filter((value: boolean) => !!value),
      tap(() => {
        this.form.controls.isOnDemandMode.setValue(false);
      }),
    );

    const hideChat$ = this.form.controls.hideChat.valueChanges.pipe(
      tap((value: boolean) => {
        if (value) {
          this.form.controls.hideChatPreviewScreen.setValue(false);
          this.form.controls.hideChatPreviewScreen.disable();
        } else {
          this.form.controls.hideChatPreviewScreen.enable();
        }
      }),
    );

    merge(scheduleEventCheckin$, isOnDemandMode$, isOnDemandComingSoon$, hideChat$)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe();

    if (this.event.hideChat) {
      this.form.controls.hideChatPreviewScreen.setValue(false);
      this.form.controls.hideChatPreviewScreen.disable();
    }
  }

  onDiscard(): void {
    this.updateForm();
  }

  async onConfirm(): Promise<void> {
    try {
      this.isUpdating = true;
      await asyncDelay(1);
      await this.updateEvent();
      this.formService.setForm(this.form);
    } catch (error) {
      console.error(error);
    }
  }

  async updateEvent(): Promise<void> {
    try {
      this.isUpdating = true;
      const { scheduleEventDate, scheduleEventTime, ...others } = this.form.getRawValue();
      const scheduleDate =
        scheduleEventDate && scheduleEventTime
          ? updateTime(scheduleEventDate, scheduleEventTime)
          : null;

      const eventForUpdate: IEvent = {
        ...this.event,
        ...others,
        scheduleEventDate: !!scheduleDate
          ? Timestamp.fromMillis(scheduleDate.toDate().getTime())
          : null,
      };
      const updatedEvent: IEvent = await this.eventsService.update(this.event.id, eventForUpdate);
      const newEvent = { ...this.eventsStore.adminEvent, ...updatedEvent };
      if (this.event.isEnableDirectBuy !== newEvent.isEnableDirectBuy) {
        const isShowMyBooking = !newEvent.isEnableDirectBuy;
        await this.updateEventMenuSettings(isShowMyBooking);
      }
      this.eventsStore.setAdminEvent(newEvent);
      this.isUpdating = false;

      this.messageService.add({
        severity: 'success',
        summary: this.translateService.instant('success'),
        detail: this.translateService.instant('adminEventSettings.successUpdateEvent'),
        styleClass: 'custom-toast',
      });
    } catch (error) {
      console.error(error);
      this.isUpdating = false;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private createForm(): void {
    this.form = this.fb.group({
      featured: [null],
      isPrivateEvent: [null],
      isOnDemandMode: [null],
      isOnDemandComingSoon: [null],
      isOnMaintenance: [null],
      isMatchmaking: [null],
      isTicketRestrictions: [null],
      isEnableDirectBuy: [null],
      scheduleEventCheckin: [null],
      scheduleEventDate: [null],
      scheduleEventTime: [null],
      eventRegistrationConfirmationEmail: [null],
      eventInvitationDeclinedEmail: [null],
      hideChat: [null],
      hideChatPreviewScreen: [null],
      hideEventSearch: [null],
      hideRecommendedSessions: [null],
      hideBrandLevelNames: [null],
    });
  }

  private updateForm(): void {
    this.form.patchValue({
      ...this.event,
    });
  }

  private async updateEventMenuSettings(isShowMyBooking: boolean): Promise<void> {
    try {
      const [
        regularMenuItems,
        onDemandMenuItems,
        footerMenuItems,
        eventDetailsMenuItems,
        helpSupportMenuItems,
      ] = await Promise.all([
        this.eventMenuService.getEventMenuOptions(this.event.id, 'regular'),
        this.eventMenuService.getEventMenuOptions(this.event.id, 'onDemand'),
        this.eventMenuService.getEventMenuOptions(this.event.id, 'footer'),
        this.eventMenuService.getEventMenuOptions(this.event.id, 'eventDetails'),
        this.eventMenuService.getEventMenuOptions(this.event.id, 'helpSupport'),
      ]);

      const myBookingsMenuItem: IEventMenuOption = {
        id: 'myBookings',
        key: 'myBookings',
        type: 'link',
        visible: isShowMyBooking,
      };

      await this.eventMenuService.updateEventMenuOptions(
        this.event.id,
        [
          ...regularMenuItems.filter((menuItem: IEventMenuOption) => menuItem.id !== 'myBookings'),
          myBookingsMenuItem,
        ],
        [
          ...onDemandMenuItems.filter((menuItem: IEventMenuOption) => menuItem.id !== 'myBookings'),
          myBookingsMenuItem,
        ],
        [...footerMenuItems],
        [...eventDetailsMenuItems],
        [...helpSupportMenuItems],
      );
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}
