import { Injectable } from '@angular/core';
import { AppStore } from 'src/app/app.store';
import { IMessaging } from 'src/app/firebase';
import { Messaging } from '../../../firebase';
import { AuthorizationService } from '../auth';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { WebPushNotificationDialogComponent } from '../../../shared/dialogs/web-push-notification-dialog/web-push-notification-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class WebPushService {
  public isMessagingSupported: boolean = false;

  private messaging: IMessaging;
  private foregroundMessagesListener = null;
  private backgroundMessagesListener = null;

  constructor(
    private http: HttpClient,
    private authorizationService: AuthorizationService,
    public appStore: AppStore,
    private messageService: MessageService,
    private dialogService: DialogService,
  ) {
    this.messaging = Messaging();
    this.isMessagingSupported = this.messaging ? true : false;
  }

  public subscribeForegroundMessages() {
    console.log('Subscribe for Foreground Messages...');

    if (!this.foregroundMessagesListener) {
      this.foregroundMessagesListener = this.messaging.onMessage((payload) => {
        console.log('Foreground Message received. ', payload);

        const notificationBody = payload.notification.body.replace(
          /(\b(https?|ftp|file):\/\/[\-A-Z0-9+&@#\/%?=~_|!:,.;]*[\-A-Z09+&@#\/%=~_|])/gim,
          '<a href="$1">$1</a>',
        );

        this.dialogService.open(WebPushNotificationDialogComponent, {
          width: '30%',
          // height: '30%',
          dismissableMask: true,
          position: 'top-right',
          styleClass: 'web-push-notification-dialog-wrapper',
          contentStyle: {
            overflow: 'auto',
            padding: '0',
            'border-radius': '20px',
          },
          data: {
            title: payload.notification.title,
            description: notificationBody,
          },
        } as DynamicDialogConfig);

        // this.messageService.add({
        //   severity: 'custom',
        //   summary: payload.notification.title,
        //   detail: notificationBody,
        //   icon: 'pi-bell',
        //   styleClass: 'custom-toast',
        //   life: 5000
        // });
      });
    }
  }

  public unsubscribeForegroundMessages() {
    if (this.foregroundMessagesListener) {
      this.foregroundMessagesListener();
    }
    this.foregroundMessagesListener = null;
  }

  public subscribeBackgroundMessages() {
    if (!this.backgroundMessagesListener) {
      this.backgroundMessagesListener = this.messaging.onBackgroundMessage((payload) => {
        console.log('Background Message received. ', payload);
      });
    }
  }

  public unsubscribeBackgroundMessages() {
    if (this.backgroundMessagesListener) {
      this.backgroundMessagesListener();
    }
    this.backgroundMessagesListener = null;
  }

  public async requestPermission(): Promise<boolean> {
    try {
      const permission = await Notification.requestPermission();
      console.log('WP permission => ', permission);

      return permission === 'granted';
    } catch (error) {
      console.log(error);
    }
  }

  public async getToken(): Promise<string> {
    try {
      // const token = await this.messaging.getToken({ vapidKey: "BLeo_siyvsRIRRWU6HpuExUn0Tuaw4CoIg-wlm-OzpCbP-WwV-gNxyRK_MDcWhfDdI10sq4SVCmhvi4GV_2nrEM" });
      const token = await this.messaging.getToken({
        vapidKey: this.appStore.environment.webpush.public_key,
      });
      console.log('WP token => ', token);

      return token;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async subscribeTokenToTopic(topic: string, token: string): Promise<void> {
    try {
      const headers = await this.authorizationService.buildHeaders();
      return this.http
        .post<any>(`${environment.apiUrl}test/test`, { topic: topic, token: token }, { headers })
        .toPromise();
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async unsubscribeTokenFromTopic(topic: string, token: string): Promise<void> {
    try {
      const headers = await this.authorizationService.buildHeaders();
      return this.http
        .post<any>(`${environment.apiUrl}test/test`, { topic: topic, token: token }, { headers })
        .toPromise();
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }
}
