import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { IBrandProduct } from 'src/app/core/models';

@Component({
  selector: 'app-brand-product.dialog',
  templateUrl: './brand-product-dialog.component.html',
  styleUrls: ['./brand-product-dialog.component.scss'],
})
export class BrandProductDialogComponent implements OnInit {
  public product: IBrandProduct = null;

  constructor(
    private dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
  ) {}

  ngOnInit(): void {
    this.product = this.config.data?.product;
  }

  public onConfirm(success: boolean): void {
    this.dialogRef.close(success);
  }
}
