import { Component, OnInit, inject, signal } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';

import { ICountry, IUser } from 'src/app/core/models';
import { FormService, UsersService } from 'src/app/core/services';
import { UsersStore } from 'src/app/core/stores';
import { VIRTUAL_SCROLL_ITEM_SIZE, asyncDelay, countries } from 'src/app/core/utils';
import { Timestamp } from 'src/app/firebase';
import {
  Agreement,
  ClothingSizes,
  SharedModule,
  TravelDocument,
  parseToMoment,
} from 'src/app/shared';
import { SaveDiscardActionsComponent, ToastComponent } from 'src/app/standalone/shared';

@Component({
  selector: 'app-user-more',
  standalone: true,
  imports: [SharedModule, SaveDiscardActionsComponent, ToastComponent],
  templateUrl: './user-more.component.html',
  styleUrl: './user-more.component.scss',
})
export class UserMoreComponent implements OnInit {
  loading = signal(true);
  isUpdating = signal(false);
  form: FormGroup;
  travelDocumentTypes = signal<string[]>(Object.values(TravelDocument));
  dateForValidation = signal<Date>(new Date());
  countriesArray = signal<ICountry[]>(countries);
  vegetarianTypes = signal<string[]>(Object.values(Agreement));
  clothingSizes = signal<string[]>(Object.values(ClothingSizes));

  virtualScrollItemSize = signal<number>(VIRTUAL_SCROLL_ITEM_SIZE).asReadonly();

  private usersStore = inject(UsersStore);
  private formService = inject(FormService);
  private fb = inject(FormBuilder);
  private usersService = inject(UsersService);
  private messageService = inject(MessageService);
  private translateService = inject(TranslateService);

  get user(): IUser {
    return this.usersStore.adminUser;
  }

  get canUpdate(): boolean {
    return (
      !this.isUpdating() && this.form.valid && this.form.dirty && this.formService.isValueChanged()
    );
  }

  ngOnInit(): void {
    this.createForm();
    this.updateForm();
    this.formService.setForm(this.form);
    this.loading.set(false);
  }

  onDiscard(): void {
    this.updateForm();
  }

  async onConfirm(): Promise<void> {
    try {
      this.isUpdating.set(true);
      await asyncDelay(1);
      await this.updateUser();
      this.formService.setForm(this.form);
    } catch (error) {
      console.error(error);
    }
  }

  private createForm(): void {
    this.form = this.fb.group({
      travelDocument: null,
      passportNumber: null,
      dateOfIssue: null,
      dateOfExpiry: null,
      nationality: null,
      birthDate: null,
      emergencyContactName: null,
      emergencyContactPhone: null,
      vegetarian: null,
      clothingSize: null,
      shoeSize: null,
    });
  }

  private updateForm(): void {
    this.form.patchValue({
      ...this.user,
      dateOfIssue: !!this.user?.dateOfIssue ? parseToMoment(this.user.dateOfIssue).toDate() : null,
      dateOfExpiry: !!this.user?.dateOfExpiry
        ? parseToMoment(this.user.dateOfExpiry).toDate()
        : null,
      birthDate: !!this.user?.birthDate ? parseToMoment(this.user.birthDate).toDate() : null,
      nationality: countries.find((country: ICountry) => country.name === this.user?.nationality),
    });
  }

  private async updateUser(): Promise<void> {
    this.isUpdating.set(true);

    try {
      const formValues = this.form.getRawValue();
      const newUser: IUser = {
        ...this.user,
        ...formValues,
        dateOfIssue: formValues?.dateOfIssue
          ? Timestamp.fromMillis(formValues.dateOfIssue.getTime())
          : null,
        dateOfExpiry: formValues?.dateOfExpiry
          ? Timestamp.fromMillis(formValues.dateOfExpiry.getTime())
          : null,
        birthDate: formValues?.birthDate
          ? Timestamp.fromMillis(formValues.birthDate.getTime())
          : null,
        nationality: formValues?.nationality
          ? countries.find((country: ICountry) => country.code === formValues?.nationality?.code)
              ?.name
          : (this.user.nationality ?? ''),
      };

      await this.usersService.update(this.user.id, newUser);
      this.usersStore.setAdminUser(newUser);
      this.showToastMessage('success', 'adminUserProfile.userSuccessfullyUpdated');
    } catch (error) {
      console.log(error);
      this.showToastMessage('error', 'adminUserProfile.errorUpdateUser');
    } finally {
      this.isUpdating.set(false);
    }
  }

  private showToastMessage(severity: 'success' | 'error', detail: string): void {
    this.messageService.add({
      severity,
      summary: this.translateService.instant(severity),
      detail: this.translateService.instant(detail),
      styleClass: 'custom-toast',
    });
  }
}
