import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import firebase from 'firebase/compat/app';

import {
  IUserCourse,
  IEvent,
  AlgoliaSearchResult,
  IHub,
  IUserHub,
  IUser,
  IUserEvent,
  IUserRegistrationInvite,
  IBrandMember,
  IBrandMemberInvite,
  IBrandOwnerInvite,
  IBrand,
  IPlenigoUserResponse,
} from 'src/app/core/models';
import {
  AuthorizationService,
  AlgoliaService,
  PresenceService,
  StorageService,
  HttpClientService,
  ThemesService,
  CryptoJSService,
} from 'src/app/core/services';
import { InviteTypes } from 'src/app/core/enums';
import {
  Auth,
  Firestore,
  IFirestore,
  IAuth,
  ServerTimestamp,
  IDocumentData,
  IQuerySnapshot,
  IDocumentSnapshot,
  IDocumentReference,
  IQueryDocumentSnapshot,
  IQuery,
  Timestamp,
} from 'src/app/firebase';
import { environment } from 'src/environments/environment';
import { CollectionTypes, API_ROUTES as apiRoutes } from 'src/app/shared';
import { AppStore } from 'src/app/app.store';
import { HubsStore, UsersStore } from 'src/app/core/stores';
import { EmailCourseActionTypes } from '../../utils';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  listenForUserActivationObservable$ = null;

  private firestore: IFirestore;
  private firebaseAuth: IAuth;
  private excludedEncryptionList: string[] = [
    'id',
    'uid',
    'role',
    'status',
    'hubId',
    'userId',
    'type',
    'eventId',
    'eventStatus',
    'eventRole',
    'brandId',
    'userHubId',
    'brandUserId',
    'brandRole',
    'hubRole',
    'brandStatus',
    'salesforceId',
    'subscriptionIds',
    'stripeId',
    'ticketId',
    'active',
    'isBrandPerson',
    'allowAppointment',
    '_event_title_',
    'tags',
    'tagsList',
    'updatedAt',
    'createdAt',
    'joinAt',
    'userCreatedAt',
    'publicationDate',
    'start',
    'end',
    'acceptedConsents',
    'acceptedMultiConsents',
    'popUpConsents',
    'consentId',
    'consentTitle',
    'consentDescription',
  ];
  // some db records are not in Timestamp data type
  private userTimeStampFields: string[] = [
    // 'updatedAt',
    // 'createdAt',
    // 'joinAt',
    // 'userCreatedAt',
    // 'publicationDate',
    // 'start',
    // 'end',
    'birthDate',
    'dateOfExpiry',
    'dateOfIssue',
    'eventTimeStamp',
    'brandTimeStamp',
  ];

  constructor(
    private storageService: StorageService,
    private usersStore: UsersStore,
    private hubsStore: HubsStore,
    private presence: PresenceService,
    private algoliaService: AlgoliaService,
    private http: HttpClientService,
    private httpClient: HttpClient,
    private authorizationService: AuthorizationService,
    private themesService: ThemesService,
    private cryptoJSService: CryptoJSService,
    public appStore: AppStore,
  ) {
    this.firestore = Firestore();
    this.firebaseAuth = Auth();
  }

  async getLoggedUserBrandData(brandId: string, forceUpdate: boolean = false): Promise<IBrand> {
    if (!brandId) {
      return null;
    }

    if (forceUpdate || !this.usersStore.userBrand) {
      try {
        const userBrand = (
          await this.firestore.collection(apiRoutes.brands).doc(brandId).get()
        ).data() as IBrand;
        this.usersStore.setUserBrand(userBrand);

        return this.usersStore.userBrand;
      } catch (error) {
        console.warn(error);
        throw new Error(error);
      }
    } else {
      return this.usersStore.userBrand;
    }
  }

  async getUserAllEvents(userId: string, hubId: string = null): Promise<IEvent[]> {
    if (!userId) {
      return null;
    }

    try {
      const eventIds: string[] = [];
      const events: IEvent[] = [];
      const userEventsQuery: IQuerySnapshot<IDocumentData> = await this.firestore
        .collection(apiRoutes.userEvents)
        .where('userId', '==', userId)
        .get();
      userEventsQuery.forEach((userEventQuery: IQueryDocumentSnapshot<IDocumentData>) => {
        eventIds.push(userEventQuery.data().eventId);
      });

      while (eventIds.length) {
        const batch: string[] = eventIds.splice(0, 10);
        let eventsQuery: IQuery<IDocumentData> = this.firestore
          .collection(apiRoutes.events)
          .where('id', 'in', batch);
        if (hubId) {
          eventsQuery = eventsQuery.where('hubId', '==', hubId);
        }
        const eventsSnapshot: IQuerySnapshot<IDocumentData> = await eventsQuery.get();
        eventsSnapshot.forEach((eventQuery: IQueryDocumentSnapshot<IDocumentData>) => {
          let event: IEvent = eventQuery.data() as IEvent;
          if (this.appStore.generalSystemSettings.enableEncryption) {
            event = this.decryptUserData(event) as IEvent;
          }
          events.push(event);
        });
      }

      return events;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async search(searchTerm: string): Promise<any[]> {
    const rawSearchResults = await this.algoliaService.algoliaSearch.search<any>([
      {
        query: `${searchTerm}`,
        indexName: `${this.appStore.environment.algolia.indexPrefix}users`,
      },
    ]);

    return (rawSearchResults.results[0] as MySearchResponse).hits.map((doc) => ({
      key: doc.id,
      description: `${doc.firstName} ${doc.lastName}`,
    }));
  }

  async fetch(
    pageIndex: number,
    pageSize: number,
    searchTerm: string,
  ): Promise<AlgoliaSearchResult<IUser>> {
    return await this.algoliaService.search<IUser>(
      'users',
      searchTerm,
      pageSize,
      pageIndex * pageSize,
    );
  }

  async fetchByHub(
    hubId: string = null,
    pageIndex: number,
    pageSize: number,
    searchTerm: string,
  ): Promise<AlgoliaSearchResult<IUser>> {
    const response = await this.algoliaService.search<IUser>(
      'userHubs',
      `${hubId} ${searchTerm}`,
      pageSize,
      pageIndex * pageSize,
    );
    return {
      total: response.total,
      results: response.results.map((r: any) => {
        const user: IUser = r.user;
        user.userHubId = r.id;
        return user;
      }) as IUser[],
    };
  }

  async sendInvite(eventId: string, userId: string): Promise<void> {
    return await this.http.get<any>(
      `${apiRoutes.events}/${eventId}/invite?userId=${userId}&tenantId=${this.hubsStore.hub.tenantId}`,
    );
  }

  async sendInviteForUsersFromCourse(
    courseId: string,
    userId: string,
    templateName: EmailCourseActionTypes,
    lang: string,
  ): Promise<void> {
    const urlFirstPart = `${apiRoutes.courseApi(courseId)}/invite?userId=${userId}&tenantId=`;
    const urlSecondPart = `${this.hubsStore.hub.tenantId}&template=${templateName}&lang=${lang}`;

    return await this.http.get<any>(`${urlFirstPart}${urlSecondPart}`);
  }

  async getAll(): Promise<IUser[]> {
    const result: IUser[] = [];
    (await this.firestore.collection(apiRoutes.users).orderBy('firstName').get()).docs.map(
      (doc) => {
        let user = doc.data() as IUser;
        if (this.appStore.generalSystemSettings.enableEncryption) {
          user = this.decryptUserData(user) as IUser;
        }
        result.push(user);
      },
    );
    this.usersStore.setUsers(result);

    return result;
  }

  async getAllByIds(userIds: string[]): Promise<IUser[]> {
    try {
      const users: IUser[] = [];
      const ids: string[] = [...userIds];
      while (ids.length) {
        const chunk = ids.splice(0, 10);
        const usersQuery: IQuerySnapshot<IDocumentData> = await this.firestore
          .collection(apiRoutes.users)
          .where('id', 'in', chunk)
          .get();
        users.push(...usersQuery.docs.map((doc) => doc.data() as IUser));
      }

      return users;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async getAllSuperAndInternalAdmins(): Promise<IUser[]> {
    try {
      const result: IUser[] = [];

      (
        await this.firestore
          .collection(apiRoutes.users)
          .where('role', 'in', [
            'internalAdmin',
            'superAdmin',
            'instanceOwner',
            'softwareDeveloper',
          ])
          .orderBy('firstName')
          .get()
      ).docs.map((doc) => {
        let user = doc.data() as IUser;

        if (this.appStore.generalSystemSettings.enableEncryption) {
          user = this.decryptUserData(user) as IUser;
        }
        result.push(user);
      });

      return result;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async get(user: IUser = null, pageSize: number = 20): Promise<IUser[]> {
    const users: IUser[] = [];

    const query: IQuerySnapshot<IDocumentData> = await this.firestore
      .collection(apiRoutes.users)
      .orderBy('firstName')
      .startAfter(user ? user.firstName : 0)
      .limit(pageSize)
      .get();

    if (!query.empty) {
      query.forEach((x) => {
        let userData = x.data() as IUser;
        if (this.appStore.generalSystemSettings.enableEncryption) {
          userData = this.decryptUserData(userData) as IUser;
        }
        users.push(userData);
      });

      return users;
    } else {
      return null;
    }
  }

  encryptUserData(
    user: IUser | IUserEvent | IHub | Partial<IUser & { uid: string }>,
    excludedEncryptionFields = [],
  ): IUser {
    try {
      const encryptedUserData = Object.assign({}, user);
      Object.entries(encryptedUserData).forEach(([key, value]) => {
        if (
          value !== null &&
          !this.excludedEncryptionList.includes(key) &&
          !excludedEncryptionFields.includes(key)
        ) {
          if (typeof value === 'object' && !Array.isArray(value)) {
            let encryptedValue;

            const condition =
              (value instanceof firebase.firestore.Timestamp ||
                this.userTimeStampFields.includes(key)) &&
              value.hasOwnProperty('seconds');
            if (condition) {
              value = new Date(value.seconds * 1000).toString();
              encryptedValue = this.cryptoJSService.encrypt(value);
            } else {
              encryptedValue = Object.keys(value).reduce(
                (prev, curr, index) => ({
                  ...prev,
                  [this.cryptoJSService.encrypt(String(curr))]: this.cryptoJSService.encrypt(
                    String(value[curr]),
                  ),
                }),
                {},
              );
            }
            encryptedUserData[key] = encryptedValue;
          } else if (Array.isArray(value)) {
            const encryptedArray = [];
            value.forEach(async (v) => {
              encryptedArray.push(this.cryptoJSService.encrypt(v));
            });
            encryptedUserData[key] = encryptedArray;
          } else {
            value = String(value);
            const encryptedValue = this.cryptoJSService.encrypt(value);
            encryptedUserData[key] = encryptedValue;
          }
        }
      });

      return encryptedUserData as IUser;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  decryptUserData(
    user: IUser | IUserEvent | IHub | IBrandMember | Partial<IUser & { uid: string }>,
    excludedDecryptionFields = [],
  ): IUser | IUserEvent | IHub | IBrandMember | Partial<IUser & { uid: string }> {
    try {
      const decryptedUserData = Object.assign({}, user);
      Object.entries(decryptedUserData).forEach(([key, value]) => {
        if (
          value !== null &&
          !this.excludedEncryptionList.includes(key) &&
          !excludedDecryptionFields.includes(key)
        ) {
          if (typeof value === 'object' && !Array.isArray(value)) {
            const decryptedValue = Object.keys(value).reduce(
              (prev, curr, index) => ({
                ...prev,
                [this.cryptoJSService.decrypt(String(curr))]: this.cryptoJSService.decrypt(
                  String(value[curr]),
                ),
              }),
              {},
            );
            decryptedUserData[key] = decryptedValue;
          } else if (Array.isArray(value)) {
            const decryptedArray = [];
            value.forEach(async (v) => {
              decryptedArray.push(this.cryptoJSService.decrypt(v));
            });
            decryptedUserData[key] = decryptedArray;
          } else {
            const decryptedValue = this.cryptoJSService.decrypt(String(value));
            if (this.userTimeStampFields.includes(key)) {
              const decryptedTimeStamp = firebase.firestore.Timestamp.fromDate(
                new Date(decryptedValue),
              );
              decryptedUserData[key] = decryptedTimeStamp;
            } else {
              decryptedUserData[key] = decryptedValue;
            }
          }
        }
      });

      return decryptedUserData;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getOne(id: string): Promise<IUser> {
    if (!id) {
      return null;
    }

    try {
      let user = null;
      const userQuery: IQuerySnapshot<IDocumentData> = await this.firestore
        .collection(apiRoutes.users)
        .where('id', '==', id)
        .get();
      if (userQuery.size > 0) {
        userQuery.forEach((doc) => (user = doc.data()));
      }

      if (user && this.appStore.generalSystemSettings.enableEncryption) {
        user = this.decryptUserData(user) as IUser;
      }

      return user;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getUserByEmail(email: string, authUserExists: boolean = false): Promise<IUser> {
    try {
      let user: IUser = null;
      if (this.appStore.generalSystemSettings.enableEncryption) {
        if (authUserExists) {
          user = { email } as IUser;
        }
        return user;
      }

      const userQuery: IQuerySnapshot<IDocumentData> = await this.firestore
        .collection(apiRoutes.users)
        .where('email', '==', email.toLowerCase())
        .get();
      userQuery.forEach((doc) => (user = doc.data() as IUser));

      return user;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async register(userPayload: IUser): Promise<IUser> {
    try {
      if (this.appStore.generalSystemSettings.enableEncryption) {
        userPayload = this.encryptUserData(userPayload) as IUser;
      }
      await this.firestore
        .collection(apiRoutes.users)
        .doc(userPayload.id)
        .set({ ...userPayload });
      await this.presence.addPresence(userPayload.id, 'online');

      if (this.appStore.generalSystemSettings.enableEncryption) {
        userPayload = this.decryptUserData(userPayload) as IUser;
      }
      this.usersStore.setUsers([userPayload]);

      return userPayload;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async create(
    userPayload: Partial<IUser & { uid: string }>,
    password: string,
  ): Promise<Partial<IUser>> {
    try {
      if (password) {
        const firebaseUser: firebase.auth.UserCredential =
          await this.firebaseAuth.createUserWithEmailAndPassword(
            userPayload.email.toLowerCase(),
            password,
          );
        userPayload.uid = firebaseUser.user.uid;
        userPayload.id = userPayload.uid;
      } else {
        throw new Error('Password required');
      }

      if (this.appStore.generalSystemSettings.enableEncryption) {
        userPayload = this.encryptUserData(userPayload) as IUser;
      }

      await this.firestore
        .collection(apiRoutes.users)
        .doc(userPayload.id)
        .set({ ...userPayload });
      await this.presence.addPresence(userPayload.id, 'offline');

      if (this.appStore.generalSystemSettings.enableEncryption) {
        userPayload = this.decryptUserData(userPayload) as IUser;
      }
      this.usersStore.setUsers([userPayload] as Array<IUser>);

      return userPayload;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async createAPI(
    userPayload: any,
    assignAs: string = null,
    eventId: string = null,
    courseId: string = null,
    hub: IHub = null,
  ): Promise<any> {
    try {
      const reqPayload: any = {
        userPayload,
        assignAs,
        eventId,
        courseId,
        hub,
      };
      const headers: HttpHeaders = await this.authorizationService.buildHeaders();

      return this.httpClient
        .post<any>(environment.apiUrl + 'users/create', reqPayload, { headers })
        .toPromise();
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async update(id: string, userPayload: any): Promise<IUser> {
    try {
      const userDocument: IDocumentReference<IDocumentData> = this.firestore
        .collection(apiRoutes.users)
        .doc(id);

      if (userPayload.profileImage instanceof File || userPayload.coverImage instanceof File) {
        const userDocData: IDocumentSnapshot<IDocumentData> = await userDocument.get();

        if (userPayload.profileImage instanceof File) {
          const currentProfileImage = userDocData.get('profileImage');

          if (currentProfileImage) {
            await this.storageService.delete(currentProfileImage);
          }

          if (userPayload.profileImage !== null) {
            const fileName = `file_${new Date().getTime()}`;
            const newProfileImage: string = await this.storageService.upload(
              userPayload.profileImage,
              'users',
              fileName,
            );
            userPayload.profileImage = newProfileImage;
          } else {
            userPayload.profileImage = null;
          }
        }

        if (userPayload.coverImage instanceof File) {
          const { coverImages } = await this.themesService.getSystemAppearanceSettings();
          const currentCoverImage = userDocData.get('coverImage');

          if (currentCoverImage && !coverImages.includes(currentCoverImage)) {
            await this.storageService.delete(currentCoverImage);
          }

          if (userPayload.coverImage !== null) {
            const fileName = `file_${new Date().getTime()}`;
            const newCoverImage: string = await this.storageService.upload(
              userPayload.coverImage,
              'users',
              fileName,
            );
            userPayload.coverImage = newCoverImage;
          } else {
            userPayload.coverImage = null;
          }
        }
      }

      const userForUpdate: IUser = {
        ...userPayload,
        id,
        updatedAt: Timestamp.now(),
        updatedBy: (this.usersStore?.user ? this.usersStore?.user.id : id) || null,
      };

      if (this.appStore.generalSystemSettings.enableEncryption) {
        userPayload = this.encryptUserData(userForUpdate) as IUser;
      }

      await userDocument.update({ ...userForUpdate });
      await this.algoliaService.updateUser(userForUpdate, userForUpdate.id);

      if (this.appStore.generalSystemSettings.enableEncryption) {
        userPayload = this.decryptUserData(userForUpdate) as IUser;
      }
      return userForUpdate;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async remove(id: string): Promise<boolean> {
    try {
      await this.firestore.collection(apiRoutes.users).doc(id).delete();
      // await this.presence.removePresence(id);

      return true;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async deleteUserAndUserInformation(userId: string, userEmail: string): Promise<boolean> {
    try {
      const headers: HttpHeaders = await this.authorizationService.buildHeaders();
      await this.httpClient
        .delete<boolean>(environment.apiUrl + 'users/fully-delete-user', {
          headers,
          body: { userId, email: userEmail, tenantId: this.hubsStore.hub.tenantId },
        })
        .toPromise();

      return true;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async approveAllPendingBrandInvitations(user: IUser): Promise<void> {
    try {
      const query: IQuerySnapshot<IDocumentData> = await this.firestore
        .collection(apiRoutes.userBrands)
        .where('email', '==', user.email)
        .where('active', '==', false)
        .get();

      if (query.size > 0) {
        await Promise.all(
          query.docs.map(async (doc) => {
            await this.firestore
              .collection(apiRoutes.userBrands)
              .doc(doc.id)
              .update({
                userId: user.id,
                _firstName_: user.firstName.toLowerCase(),
                _lastName_: user.lastName.toLowerCase(),
                _company_: user.company ? user.company.toLowerCase() : '',
                _position_: user.position ? user.position.toLowerCase() : null,
                active: true,
              });
          }),
        );
      }
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async attachUserToEvents(user: IUser): Promise<void> {
    try {
      const eventsIds: string[] = [];
      const userBrandsQuery: IQuerySnapshot<IDocumentData> = await this.firestore
        .collection(apiRoutes.userBrands)
        .where('email', '==', user.email)
        .where('active', '==', true)
        .get();

      await Promise.all(
        userBrandsQuery.docs.map(async (userBrandDoc) => {
          const userBrand: IDocumentData = userBrandDoc.data();
          const brandEventsQuery: IQuerySnapshot<IDocumentData> = await this.firestore
            .collection(apiRoutes.eventBrands)
            .where('brandId', '==', userBrand.brandId)
            .get();

          await Promise.all(
            brandEventsQuery.docs.map(async (brandEventDoc) => {
              const brandEvent = brandEventDoc.data();

              if (!eventsIds.includes(brandEvent.eventId)) {
                const userEvent = (
                  await this.firestore
                    .collection(apiRoutes.userEvents)
                    .where('userId', '==', userBrand.userId)
                    .where('eventId', '==', brandEvent.eventId)
                    .get()
                ).docs[0]?.data() as IUserEvent;

                const preUserEventReq: IDocumentReference<IDocumentData> = await this.firestore
                  .collection(apiRoutes.userEvents)
                  .doc();
                let newFirestoreUserEvent = {
                  id: userEvent
                    ? userEvent.id
                    : (await this.firestore.collection(apiRoutes.userEvents).doc()).id,
                  eventId: brandEvent.eventId,
                  userId: userBrand.userId,
                  _firstName_: userBrand._firstName_.toLowerCase(),
                  _lastName_: userBrand._lastName_.toLowerCase(),
                  _company_: userBrand._company_ ? userBrand._company_.toLowerCase() : '',
                  _position_: userBrand._position_ ? userBrand._position_.toLowerCase() : null,
                  _event_title_: brandEvent._event_title_.toLowerCase(),
                  role: 'attendee',
                  isBrandPerson: true,
                  status: 'pending',
                  ticketId: null,
                  ticketPrice: null,
                  createdAt: ServerTimestamp(),
                  createdBy: null,
                  updatedAt: null,
                  updatedBy: null,
                  tags: [],
                } as IUserEvent;

                if (this.appStore.generalSystemSettings.enableEncryption) {
                  newFirestoreUserEvent = this.encryptUserData(
                    newFirestoreUserEvent,
                  ) as unknown as IUserEvent;
                }

                if (userEvent) {
                  await this.firestore
                    .collection(apiRoutes.userEvents)
                    .doc(userEvent.id)
                    .update(newFirestoreUserEvent);
                } else {
                  await this.firestore
                    .collection(apiRoutes.userEvents)
                    .doc(newFirestoreUserEvent.id)
                    .set({ ...newFirestoreUserEvent });
                }

                eventsIds.push(brandEvent.eventId);
              }
            }),
          );
        }),
      );
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async getUserBrandByBrandId(brandId: string): Promise<IBrandMember[]> {
    const result: IBrandMember[] = [];

    try {
      const query: IQuerySnapshot<IDocumentData> = await this.firestore
        .collection(apiRoutes.userBrands)
        .where('brandId', '==', brandId)
        .get();

      if (!query.empty) {
        query.forEach((doc) => {
          let userBrand: IBrandMember = doc.data() as IBrandMember;
          if (this.appStore.generalSystemSettings.enableEncryption) {
            userBrand = this.decryptUserData(userBrand) as IBrandMember;
          }
          result.push(userBrand);
        });
      }

      return result;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getUserBrandsByBrandIds(brandIds: string[]): Promise<IBrandMember[]> {
    if (brandIds?.length === 0) {
      return [];
    }
    const speakerIds: string[] = !!brandIds ? [...brandIds] : null;

    try {
      const userBrands: IBrandMember[] = [];
      let query: IQuerySnapshot<IDocumentData>;

      while (speakerIds?.length > 0) {
        const chunk: string[] = speakerIds.splice(0, 10);

        query = await this.firestore
          .collection(apiRoutes.userBrands)
          .where('brandId', 'in', chunk)
          .where('role', '==', 'owner')
          .get();

        if (!query.empty) {
          query.forEach((doc) => {
            let brandMember: IBrandMember = doc.data() as IBrandMember;
            if (this.appStore.generalSystemSettings.enableEncryption) {
              brandMember = this.decryptUserData(brandMember) as IBrandMember;
            }
            userBrands.push(brandMember);
          });
        }
      }

      return userBrands;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getPendingInvitation(
    token: string,
    collectionName: CollectionTypes,
  ): Promise<
    IUser & {
      oldPassword: string;
      invite: Partial<IUserRegistrationInvite | IBrandMemberInvite | IBrandOwnerInvite>;
    }
  > {
    try {
      let user: IUser = null;
      let oldPassword: string = null;
      const query: IQuerySnapshot<IDocumentData> = await this.firestore
        .collection(collectionName)
        .where('token', '==', token)
        .get();

      const inviteWithoutType: Partial<
        IUserRegistrationInvite | IBrandMemberInvite | IBrandOwnerInvite
      > = query.docs[0].data();
      const invite: Partial<IUserRegistrationInvite | IBrandMemberInvite | IBrandOwnerInvite> =
        this.addTypeToInvite(inviteWithoutType, collectionName);

      if (invite.userId) {
        const userQuery: IDocumentSnapshot<IDocumentData> = await this.firestore
          .collection(CollectionTypes.USERS)
          .doc(invite.userId)
          .get();
        user = userQuery.data() as IUser;
        if (this.appStore.generalSystemSettings.enableEncryption) {
          user = this.decryptUserData(user) as IUser;
        }
        oldPassword = invite?.tempPassword;

        return { ...user, oldPassword, invite };
      }

      if (!invite.userId) {
        return { ...user, oldPassword, invite, email: invite.email };
      }
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async removePendingInvitation(
    userId: string,
    eventId: string,
  ): Promise<Partial<IUserRegistrationInvite>> {
    try {
      let invite: Partial<IUserRegistrationInvite>;
      const query: IQuerySnapshot<IDocumentData> = await this.firestore
        .collection(apiRoutes.userInvites)
        .where('userId', '==', userId)
        .where('eventId', '==', eventId)
        .get();

      if (!query.empty) {
        invite = query.docs[0].data() as unknown as IUserRegistrationInvite;
        await query.forEach(
          async (doc) =>
            await this.firestore.collection(apiRoutes.userInvites).doc(doc.id).delete(),
        );
      }

      return invite;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async assignBrand(userId: string, brandId: string) {
    try {
      await this.firestore.collection(apiRoutes.users).doc(userId).update({ brandId });

      return true;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getUserEvent(eventId: string, userId: string): Promise<IUserEvent> {
    try {
      let userEvent: IUserEvent = (
        await this.firestore
          .collection(apiRoutes.userEvents)
          .where('userId', '==', userId)
          .where('eventId', '==', eventId)
          .get()
      ).docs[0]?.data() as IUserEvent;

      if (userEvent) {
        if (this.appStore.generalSystemSettings.enableEncryption) {
          userEvent = this.decryptUserData(userEvent) as IUserEvent;
        }
        return userEvent;
      }

      console.error('This user has not signed for this event');
      return null;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getUserEventByRole(eventId: string, userId: string, role: string): Promise<IUserEvent> {
    try {
      let userEvent: IUserEvent = (
        await this.firestore
          .collection(apiRoutes.userEvents)
          .where('userId', '==', userId)
          .where('eventId', '==', eventId)
          .where('role', '==', role)
          .get()
      ).docs[0]?.data() as IUserEvent;

      if (userEvent) {
        if (this.appStore.generalSystemSettings.enableEncryption) {
          userEvent = this.decryptUserData(userEvent) as IUserEvent;
        }

        return userEvent;
      }

      console.error('This user has not signed for this event');
      return null;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getUsersFromEvent(eventId: string, userId: string): Promise<IUserEvent[]> {
    try {
      const userEvents: IUserEvent[] = [];

      (
        await this.firestore
          .collection(apiRoutes.userEvents)
          .where('eventId', '==', eventId)
          .where('userId', '==', userId)
          .get()
      ).docs.map((doc) => {
        let userEvent = doc.data() as IUserEvent;
        if (this.appStore.generalSystemSettings.enableEncryption) {
          userEvent = this.decryptUserData(userEvent) as IUserEvent;
        }
        userEvents.push(userEvent);
      });

      return userEvents;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getSpeakersFromEvent(eventId: string): Promise<IUserEvent[]> {
    return this.getSpeakersOrAttendeesFromEvent(eventId, 'speaker');
  }

  async getAttendeesFromEvent(eventId: string): Promise<IUserEvent[]> {
    return this.getSpeakersOrAttendeesFromEvent(eventId, 'attendee');
  }

  async getAllEventUsersFromEvent(eventId: string): Promise<IUserEvent[]> {
    try {
      const userEvents: IUserEvent[] = [];
      (
        await this.firestore.collection(apiRoutes.userEvents).where('eventId', '==', eventId).get()
      ).docs.map((doc) => {
        let userEvent = doc.data() as IUserEvent;
        if (this.appStore.generalSystemSettings.enableEncryption) {
          userEvent = this.decryptUserData(userEvent) as IUserEvent;
        }
        userEvents.push(userEvent);
      });

      return userEvents;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getAllActiveUsersFromEvent(eventId: string): Promise<IUserEvent[]> {
    try {
      const userEvents: IUserEvent[] = [];
      (
        await this.firestore
          .collection(apiRoutes.userEvents)
          .where('eventId', '==', eventId)
          .where('status', '==', 'active')
          .get()
      ).docs.map((doc) => {
        let userEvent = doc.data() as IUserEvent;
        if (this.appStore.generalSystemSettings.enableEncryption) {
          userEvent = this.decryptUserData(userEvent) as IUserEvent;
        }
        userEvents.push(userEvent);
      });

      return userEvents;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getAllUserEventsByEventIdIfIsBrandPerson(eventId: string): Promise<IUserEvent[]> {
    try {
      const userEvents: IUserEvent[] = [];
      (
        await this.firestore
          .collection(apiRoutes.userEvents)
          .where('eventId', '==', eventId)
          .where('isBrandPerson', '==', true)
          .get()
      ).docs.map((doc) => {
        let userEvent = doc.data() as IUserEvent;
        if (this.appStore.generalSystemSettings.enableEncryption) {
          userEvent = this.decryptUserData(userEvent) as IUserEvent;
        }
        userEvents.push(userEvent);
      });
      return userEvents;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getUserEvents(userId: string, forceUpdate: boolean = false): Promise<IUserEvent[]> {
    if (!userId) {
      return null;
    }

    if (forceUpdate || !this.usersStore.userEvents.length) {
      try {
        const userEvents: IUserEvent[] = [];
        (
          await this.firestore.collection(apiRoutes.userEvents).where('userId', '==', userId).get()
        ).docs.map((doc) => {
          let userEvent = doc.data() as IUserEvent;
          if (this.appStore.generalSystemSettings.enableEncryption) {
            userEvent = this.decryptUserData(userEvent) as IUserEvent;
          }
          userEvents.push(userEvent);
        });
        this.usersStore.setUserEvents(userEvents, true);

        return this.usersStore.userEvents;
      } catch (error) {
        console.warn(error);
        throw new Error(error);
      }
    } else {
      return this.usersStore.userEvents;
    }
  }

  async getUserCourses(userId: string, forceUpdate: boolean = false): Promise<IUserCourse[]> {
    if (!userId) {
      return null;
    }

    if (forceUpdate || !this.usersStore.userCourses.length) {
      try {
        const userCourses: IUserCourse[] = (
          await this.firestore.collection(apiRoutes.userCourses).where('userId', '==', userId).get()
        ).docs.map((doc) => doc.data() as IUserCourse);
        this.usersStore.setUserCourses(userCourses);

        return this.usersStore.userCourses;
      } catch (error) {
        console.warn(error);
        throw new Error(error);
      }
    } else {
      return this.usersStore.userCourses;
    }
  }

  async getUserHubs(userId: string, forceSync: boolean = false): Promise<any[]> {
    const hubs: IHub[] = [];
    const userHubsRelations: IUserHub[] = [];

    if (!forceSync && this.usersStore.userHubs.length) {
      return this.usersStore.userHubs;
    }

    try {
      const userHubsQuery: IQuerySnapshot<IDocumentData> = await this.firestore
        .collection(apiRoutes.userHubs)
        .where('userId', '==', userId)
        .get();

      if (!userHubsQuery.empty) {
        const hubsIds: string[] = [];
        userHubsQuery.forEach((doc) => {
          let userHub = doc.data() as IUserHub;
          if (this.appStore.generalSystemSettings.enableEncryption) {
            userHub = this.decryptUserData(userHub) as IUserHub;
          }
          userHubsRelations.push(userHub);
          hubsIds.push(doc.data().hubId);
        });

        while (hubsIds.length) {
          const batch: string[] = hubsIds.splice(0, 10);
          const hubsQuery: IQuerySnapshot<IDocumentData> = await this.firestore
            .collection(apiRoutes.hubs)
            .where('id', 'in', batch)
            .get();
          hubsQuery.forEach((doc) => hubs.push(doc.data() as IHub));
        }
      }
      this.usersStore.setUserHubs(userHubsRelations);
      this.hubsStore.setHubs(hubs);

      return hubs;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getUsersByHub(hubId: string): Promise<IUser[]> {
    const usersIds: string[] = [];
    let users: IUser[] = [];

    try {
      (
        await this.firestore.collection(apiRoutes.userHubs).where('hubId', '==', hubId).get()
      ).docs.map((doc) => usersIds.push(doc.data().userId));
      while (usersIds.length) {
        const batch: string[] = usersIds.splice(0, 10);
        const usersQuery: IQuerySnapshot<IDocumentData> = await this.firestore
          .collection(apiRoutes.users)
          .where('id', 'in', batch)
          .get();
        usersQuery.forEach((doc) => {
          let user = doc.data() as IUser;
          if (this.appStore.generalSystemSettings.enableEncryption) {
            user = this.decryptUserData(user) as IUser;
          }
          users.push(user);
        });
      }
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }

    if (usersIds.length) {
      users = await this.getUserByIds(usersIds);
    }

    return users;
  }

  async getUserByIds(
    userIds: string[],
    order: 'asc' | 'desc' = 'asc',
    pageSize: number = 10,
  ): Promise<IUser[]> {
    if (userIds.length === 0) {
      return [];
    }
    const users: IUser[] = [];
    const chunk: string[] = userIds.splice(0, 10);

    const queryUsers: IQuerySnapshot<IDocumentData> = await this.firestore
      .collection(apiRoutes.users)
      .where('id', 'in', chunk)
      .orderBy('firstName', order)
      .orderBy('lastName', order)
      .limit(pageSize)
      .get();

    if (!queryUsers.empty) {
      queryUsers.forEach((doc) => {
        let user = doc.data() as IUser;
        if (this.appStore.generalSystemSettings.enableEncryption) {
          user = this.decryptUserData(user) as IUser;
        }
        users.push(user);
      });
    }

    return users;
  }

  async updateUserEvent(userEvent: IUserEvent): Promise<void> {
    if (this.appStore.generalSystemSettings.enableEncryption) {
      userEvent = this.encryptUserData(userEvent) as unknown as IUserEvent;
    }

    await this.firestore
      .collection(apiRoutes.userEvents)
      .doc(userEvent.id)
      .update({ ...userEvent });
    await this.algoliaService.updateUserEvent(userEvent);
  }

  async getUserBookmarkedEventsIds(
    userId: string,
    forceUpdate: boolean = false,
  ): Promise<string[]> {
    if (!forceUpdate && this.usersStore.userBookmarkedEventsIds.length) {
      return this.usersStore.userBookmarkedEventsIds;
    }

    try {
      const list: string[] = [];
      const query: IQuerySnapshot<IDocumentData> = await this.firestore
        .collection(apiRoutes.bookmarkedEvents(userId))
        .get();
      query.forEach((doc) => list.push(doc.id));
      this.usersStore.setBookmarkedEventsIds(list);

      return list;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getUserBookmarkedCoursesIds(
    userId: string,
    forceUpdate: boolean = false,
  ): Promise<string[]> {
    if (!forceUpdate && this.usersStore.userBookmarkedCoursesIds.length) {
      return this.usersStore.userBookmarkedCoursesIds;
    }

    try {
      const list: string[] = [];
      const query: IQuerySnapshot<IDocumentData> = await this.firestore
        .collection(apiRoutes.bookmarkedCourses(userId))
        .get();
      query.forEach((doc) => list.push(doc.id));
      this.usersStore.setBookmarkedCoursesIds(list);

      return list;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getUserBookmarkedBrandsIds(userId: string): Promise<string[]> {
    try {
      const list: string[] = [];
      const query: IQuerySnapshot<IDocumentData> = await this.firestore
        .collection(apiRoutes.bookmarkedBrands(userId))
        .get();
      query.forEach((doc) => list.push(doc.id));

      return list;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getUserBookmarkedUserIds(userId: string): Promise<string[]> {
    try {
      const list: string[] = [];
      const query: IQuerySnapshot<IDocumentData> = await this.firestore
        .collection(apiRoutes.bookmarkedUsers(userId))
        .get();
      query.forEach((doc) => list.push(doc.id));

      return list;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async bookmarkBrand(userId: string, brandId: string): Promise<boolean> {
    try {
      await this.firestore.collection(apiRoutes.bookmarkedBrands(userId)).doc(brandId).set({});

      return true;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async unBookmarkBrand(userId: string, brandId: string): Promise<boolean> {
    try {
      await this.firestore.collection(apiRoutes.bookmarkedBrands(userId)).doc(brandId).delete();

      return true;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async bookmarkUser(userId: string, bookmarkUserId: string): Promise<boolean> {
    try {
      await this.firestore
        .collection(apiRoutes.bookmarkedUsers(userId))
        .doc(bookmarkUserId)
        .set({});

      return true;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async unBookmarkUser(userId: string, unBookmarkUserId: string): Promise<boolean> {
    try {
      await this.firestore
        .collection(apiRoutes.bookmarkedUsers(userId))
        .doc(unBookmarkUserId)
        .delete();

      return true;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async bookmarkCourse(userId: string, courseId: string): Promise<boolean> {
    try {
      await this.firestore.collection(apiRoutes.bookmarkedCourses(userId)).doc(courseId).set({});
      this.usersStore.pushBookmarkedCourseId(courseId);

      return true;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async unBookmarkCourse(userId: string, courseId: string): Promise<boolean> {
    try {
      await this.firestore.collection(apiRoutes.bookmarkedCourses(userId)).doc(courseId).delete();
      this.usersStore.popBookmarkedCourseId(courseId);

      return true;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async bookmarkEvent(userId: string, eventId: string): Promise<boolean> {
    try {
      await this.firestore.collection(apiRoutes.bookmarkedEvents(userId)).doc(eventId).set({});
      this.usersStore.pushBookmarkedId(eventId);

      return true;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async unBookmarkEvent(userId: string, eventId: string): Promise<boolean> {
    try {
      await this.firestore.collection(apiRoutes.bookmarkedEvents(userId)).doc(eventId).delete();
      this.usersStore.popBookmarkedId(eventId);

      return true;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getAllUsersForAppointment(eventId: string, userId: string): Promise<IUser[]> {
    try {
      const [usersDocs, eventUsers] = await Promise.all([
        this.firestore
          .collection(apiRoutes.users)
          .where('id', '!=', userId)
          .where('allowAppointment', '==', true)
          .get(),
        this.getAllActiveUsersFromEvent(eventId),
      ]);
      const eventUsersIds: string[] = eventUsers.map((userEvent: IUserEvent) => userEvent.userId);
      const users: IUser[] = [];

      usersDocs.docs.map((doc) => {
        let user = doc.data() as IUser;
        if (this.appStore.generalSystemSettings.enableEncryption) {
          user = this.decryptUserData(user) as IUser;
        }
        users.push(user);
      });

      return users.filter((user: IUser) => eventUsersIds.includes(user.id));
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getAllUsersFromEvent(eventId: string, userId: string): Promise<IUser[]> {
    try {
      const [usersDocs, eventUsers] = await Promise.all([
        this.firestore.collection(apiRoutes.users).where('id', '!=', userId).get(),
        this.getAllEventUsersFromEvent(eventId),
      ]);
      const eventUsersIds: string[] = eventUsers.map((userEvent: IUserEvent) => userEvent.userId);
      const users: IUser[] = [];

      usersDocs.docs.map((d) => {
        let user = d.data() as IUser;
        if (this.appStore.generalSystemSettings.enableEncryption) {
          user = this.decryptUserData(user) as IUser;
        }
        users.push(user);
      });

      return users.filter((user: IUser) => eventUsersIds.includes(user.id));
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async verifySalesforceCustomer(email: string, password: string): Promise<any> {
    try {
      const payload = { email, password };
      return await this.httpClient
        .post<any>(`${environment.apiUrl}salesforce/verify-customer/`, payload)
        .toPromise();
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getSalesforceSubscriptionNames(email: string): Promise<string[]> {
    try {
      const params: HttpParams = new HttpParams().append('email', email);
      const response = await this.httpClient
        .get<any>(`${environment.apiUrl}salesforce/get-subscriptions/`, { params })
        .toPromise();
      let salesforceObjectNames: string[] = [];

      if (response.data.length) {
        salesforceObjectNames = response.data.map((s) => s.aboobject);
      }

      return salesforceObjectNames;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getUserSubscriptions(userId: string): Promise<string[]> {
    try {
      const subscriptionIds = (
        await this.firestore.collection(apiRoutes.users).doc(userId).get()
      ).data()?.subscriptionIds;

      if (subscriptionIds) {
        return subscriptionIds;
      } else {
        return [];
      }
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async setSalesforceId(userId: string, salesforceId: string): Promise<void> {
    try {
      this.firestore.collection(apiRoutes.users).doc(userId).update({ salesforceId });
      this.usersStore.user.salesforceId = salesforceId;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async setPlenigoStatus(userId: string, connected: boolean): Promise<void> {
    try {
      this.firestore
        .collection(apiRoutes.users)
        .doc(userId)
        .update({ isPlenigoConnected: connected });
      this.usersStore.user.isPlenigoConnected = connected;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async setStripeCustomerId(userId: string, stripeId: string): Promise<void> {
    try {
      this.firestore.collection(apiRoutes.users).doc(userId).set({ stripeId }, { merge: true });
      this.usersStore.user.stripeId = stripeId;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async setSubscriptions(userId: string, subscriptions: string[]): Promise<void> {
    try {
      await this.firestore
        .collection(apiRoutes.users)
        .doc(userId)
        .set({ subscriptionIds: subscriptions }, { merge: true });
      this.usersStore.user.subscriptionIds = subscriptions;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getUserInviteUrl(eventId: string, userId: string, isBrandOwner: boolean): Promise<string> {
    try {
      const collectionName = isBrandOwner ? apiRoutes.brandOwnerInvites : apiRoutes.userInvites;
      let userInvite: IDocumentData;
      const query: IQuerySnapshot<IDocumentData> = await this.firestore
        .collection(collectionName)
        .where('eventId', '==', eventId)
        .where('userId', '==', userId)
        .get();

      if (query.size > 0) {
        query.forEach((doc) => (userInvite = doc.data()));
      }

      return userInvite
        ? `${this.hubsStore.environmentBaseUrl}/${this.hubsStore.useHubUrl}/signup?invite=${userInvite.token}`
        : '';
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async getUserStripeId(userId: string): Promise<string> {
    try {
      const subscriptionIds: string = (
        await this.firestore.collection(apiRoutes.users).doc(userId).get()
      ).data().stripeId;

      return subscriptionIds;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  listenForUserActivation(email: string): Observable<boolean> {
    const subject = new Subject<boolean>();
    this.listenForUserActivationObservable$ = this.firestore
      .collection(apiRoutes.users)
      .where('email', '==', email)
      .onSnapshot((querySnapshot) => {
        const users: IUser[] = [];
        querySnapshot.forEach((doc) => users.push(doc.data() as IUser));
        subject.next(users[0]?.accountVerifiedAt ? true : false);
        if (users[0] && users[0]?.accountVerifiedAt && this.listenForUserActivationObservable$) {
          this.listenForUserActivationObservable$();
        }
      });

    return subject.asObservable();
  }

  async getPlenigoUserData(email: string, password: string): Promise<IPlenigoUserResponse> {
    try {
      const payload = { email, password };
      const response = await this.httpClient
        .post<any>(`${environment.apiUrl}plenigo/getUserData`, payload)
        .toPromise();

      return response.data;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  private async getSpeakersOrAttendeesFromEvent(
    eventId: string,
    role: 'attendee' | 'speaker',
  ): Promise<IUserEvent[]> {
    try {
      const userEvents: IUserEvent[] = [];
      (
        await this.firestore
          .collection(apiRoutes.userEvents)
          .where('eventId', '==', eventId)
          .where('role', '==', role)
          .get()
      ).docs.map((doc) => {
        let userEvent = doc.data() as IUserEvent;
        if (this.appStore.generalSystemSettings.enableEncryption) {
          userEvent = this.decryptUserData(userEvent) as IUserEvent;
        }
        userEvents.push(userEvent);
      });

      return userEvents;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  private addTypeToInvite(
    invite: Partial<IUserRegistrationInvite | IBrandMemberInvite | IBrandOwnerInvite>,
    collectionName: CollectionTypes,
  ): Partial<IUserRegistrationInvite | IBrandMemberInvite | IBrandOwnerInvite> {
    switch (collectionName) {
      case CollectionTypes.USER_INVITES:
        return { ...invite, type: InviteTypes.USER };
      case CollectionTypes.BRAND_MEMBER_INVITES:
        return { ...invite, type: InviteTypes.BRAND_MEMBER };
      case CollectionTypes.BRAND_OWNER_INVITES:
        return { ...invite, type: InviteTypes.BRAND_OWNER };
      case CollectionTypes.USER_COURSES_INVITES:
        return { ...invite, type: InviteTypes.USER_FROM_COURSE };
    }
  }
}

type MySearchResponse = {
  hits: Array<{
    id: string;
    firstName: string;
    lastName: string;
  }>;
};
