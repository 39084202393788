import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

import { CoursesStore, HubsStore, UsersStore } from 'src/app/core/stores';
import { ICourse } from 'src/app/core/models';

@Component({
  selector: 'app-course-subscriber',
  templateUrl: './course-subscriber.component.html',
  styleUrls: ['./course-subscriber.component.scss'],
})
export class CourseSubscriberComponent implements OnInit {
  loading = true;
  subscriberId: string = null;
  menuItems: MenuItem[] = [];

  constructor(
    private route: ActivatedRoute,
    private translateService: TranslateService,
    public hubsStore: HubsStore,
    public courseStore: CoursesStore,
    public userStore: UsersStore,
  ) {
    this.subscriberId = this.route.snapshot.params.userId;
  }

  get course(): ICourse {
    return this.courseStore.adminCourse;
  }

  ngOnInit(): void {
    this.buildMenuItems();
    this.loading = false;
  }

  private buildMenuItems(): void {
    this.menuItems = [
      {
        label: this.translateService.instant('adminUser.tabProfile'),
        icon: 'far fa-address-card',
        routerLink: `/${this.hubsStore.useHubUrl}/admin/courses/${this.course.link}/subscribers/${this.subscriberId}/profile`,
      },
      {
        label: this.translateService.instant('adminUser.tabAccount'),
        icon: 'fa-regular fa-user-group',
        routerLink: `/${this.hubsStore.useHubUrl}/admin/courses/${this.course.link}/subscribers/${this.subscriberId}/account`,
      },
      // TODO: Implement user courses component(It's blank now). Before I will comment this.
      // {
      //   label: this.translateService.instant('adminSubscribers.courseDetails'),
      //   icon: 'far fa-compass',
      //   routerLink: `/${this.hubsStore.useHubUrl}/admin/courses/${this.course.link}/subscribers/${this.subscriberId}/courses`
      // }
    ];
  }
}
