import { Component } from '@angular/core';
import { ThemesService } from 'src/app/core/services';
import { IAppearanceSettings } from 'src/app/core/models';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  public loading = true;
  public htmlContent: string = '';
  public safeHtml: SafeHtml;

  public appearanceSettings: IAppearanceSettings;
  public currentLanguage: string;

  constructor(
    private domSanitizer: DomSanitizer,
    private translateService: TranslateService,
    private themesService: ThemesService,
  ) {
    this.currentLanguage = this.translateService.currentLang;
  }

  async ngOnInit(): Promise<void> {
    this.appearanceSettings = await this.themesService.getSystemAppearanceSettings();
    this.htmlContent = this.appearanceSettings.globalSingleRouteHtml[this.currentLanguage] || '';

    this.safeHtml = this.domSanitizer.bypassSecurityTrustHtml(this.htmlContent);
    this.loading = false;
  }
}
