import { Component, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { Router } from '@angular/router';
import { firstValueFrom, Subject, takeUntil, tap } from 'rxjs';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';

import {
  HubsService,
  OpenDialogService,
  StripeService,
  ThemesService,
  UserHubsService,
  UsersService,
} from 'src/app/core/services';
import { IAsset, IHub, IUser, IUserHub } from 'src/app/core/models';
import {
  BrandsStore,
  CoursesStore,
  EventsStore,
  HubsStore,
  LibraryStore,
  UsersStore,
} from 'src/app/core/stores';
import { environment } from 'src/environments/environment';
import { SharedModule } from 'src/app/shared';
import { AdminSidebarWithHubComponent, AdminSidebarWithoutHubComponent } from '../admin-sidebar';
import { AdminHeaderComponent } from '../admin-header/admin-header.component';

@Component({
  selector: 'app-admin-panel',
  standalone: true,
  imports: [
    SharedModule,
    AdminSidebarWithHubComponent,
    AdminSidebarWithoutHubComponent,
    AdminHeaderComponent,
  ],
  templateUrl: './admin-panel.component.html',
  styleUrl: './admin-panel.component.scss',
})
export class AdminPanelComponent implements OnInit, OnDestroy {
  loading = signal(true);
  logo = signal<string>(null);
  currentUserHub = signal<IUserHub>(null);

  #onSubscriptionDialogRef = signal<DynamicDialogRef>(null);
  #adminAsset: IAsset;
  #unsubscribe$ = new Subject<void>();
  #hubsStore = inject(HubsStore);
  #themesService = inject(ThemesService);
  #router = inject(Router);
  #usersStore = inject(UsersStore);
  #usersService = inject(UsersService);
  #stripeService = inject(StripeService);
  #userHubsService = inject(UserHubsService);
  #hubsService = inject(HubsService);
  #translateService = inject(TranslateService);
  #messageService = inject(MessageService);
  #openDialogService = inject(OpenDialogService);
  #eventsStore = inject(EventsStore);
  #coursesStore = inject(CoursesStore);
  #libraryStore = inject(LibraryStore);
  #brandsStore = inject(BrandsStore);

  get selectedHub(): IHub {
    return this.#hubsStore.hub;
  }

  get currentUser(): IUser {
    return this.#usersStore.user;
  }

  get isHideHeaderAndSidebar(): boolean {
    const condition: boolean =
      !!this.#eventsStore.adminEvent ||
      !!this.#coursesStore.adminCourse ||
      !!this.#adminAsset ||
      !!this.#usersStore.adminUser ||
      !!this.#brandsStore.adminBrand;

    return condition;
  }

  async ngOnInit(): Promise<void> {
    this.logo.set(
      this.#themesService.activeTheme.name === 'dark'
        ? this.#themesService.systemAppearanceSettings?.logo
        : this.#themesService.systemAppearanceSettings?.logoLight,
    );
    const currentUserHub = await this.#userHubsService.getUserHub(
      this.selectedHub.id,
      this.currentUser.id,
    );
    this.currentUserHub.set(currentUserHub);
    this.#libraryStore.adminAsset$
      .pipe(
        tap((asset: IAsset) => {
          this.#adminAsset = asset;
        }),
        takeUntil(this.#unsubscribe$),
      )
      .subscribe();

    this.loading.set(false);
  }

  async onCreateHub(): Promise<void> {
    const doesAdminHaveSubscription = await this.#hubsService.doesAdminHaveActiveSubscription(
      this.currentUser.id,
    );
    const canCreateHub = await this.#hubsService.canAdminCreateNewHub(this.#usersStore.user.id);
    if (canCreateHub) {
      this.#router.navigate(['/new-hub']);
    } else {
      if (doesAdminHaveSubscription) {
        this.#onSubscriptionDialogRef.set(this.#openDialogService.openSubscriptionDialog());
      } else {
        this.#onSubscriptionDialogRef.set(this.#openDialogService.openSubscriptionExpiredDialog());
      }
      const isUpdate = await firstValueFrom(this.#onSubscriptionDialogRef().onClose);
      if (isUpdate) {
        this.openCustomerProfile();
      }
    }
  }

  async openCustomerProfile(): Promise<void> {
    try {
      let returnUrl: string;
      if (environment.env === 'LOCAL') {
        returnUrl = `http://127.0.0.1:4200/${this.#hubsStore.useHubUrl}/profile`;
      } else {
        returnUrl = `${this.#hubsStore.environmentBaseUrl}/${this.#hubsStore.useHubUrl}/profile`;
      }

      await this.#stripeService.checkStripeCustomer();
      const isAdmin = (await this.#usersService.getOne(this.#usersStore.user.id)).hasEverSubscribed;
      const url: string = await this.#stripeService.getCustomerPortalLink(
        this.#usersStore.user.stripeId,
        returnUrl,
        isAdmin,
      );
      window.location.href = url;
    } catch (error) {
      this.showToastMessage('error', `stripeErrorCodes.${error.message}`);
    }
  }

  ngOnDestroy(): void {
    this.#unsubscribe$.next();
    this.#unsubscribe$.complete();
  }

  private showToastMessage(severity: 'success' | 'error', detail: string): void {
    const fallbackTranslationKey = 'toasters.error';
    let message: string;
    this.#translateService
      .get(detail)
      .pipe(
        tap((translation: string) => {
          message =
            translation === detail
              ? this.#translateService.instant(fallbackTranslationKey)
              : translation;
        }),
        takeUntil(this.#unsubscribe$),
      )
      .subscribe();

    this.#messageService.add({
      severity,
      summary: this.#translateService.instant(severity),
      detail: this.#translateService.instant(message),
      styleClass: 'custom-toast',
    });
  }
}
