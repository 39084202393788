<ng-container *ngIf="loading">
  <app-loader class="new-brand-loading-container"></app-loader>
</ng-container>

<div
  *ngIf="!loading"
  class="new-brand-container">
  <div class="header c-p-5">
    <div class="title">
      {{ 'adminBrandProfile.createBrand' | translate }}
    </div>
    <i
      class="fa-regular fa-xmark close-icon"
      (click)="onCloseDialog()"></i>
  </div>

  <form [formGroup]="form">
    <div class="form-container c-p-5">
      <!-- Name input -->
      <div class="form-field-block">
        <app-input-label
          labelType="required"
          for="name-input"
          text="adminBrandProfile.nameLabel">
        </app-input-label>
        <input
          class="custom-input-text custom-input-text-required"
          formControlName="name"
          id="name-input"
          pInputText
          type="text"
          placeholder="{{ 'adminBrandProfile.namePlaceholder' | translate }}" />
        <app-input-error-message
          [showMessage]="form.controls.name.invalid && form.controls.name.touched"
          errorMessage="{{ 'adminBrandProfile.nameReq' | translate }}">
        </app-input-error-message>
      </div>

      <!-- User input -->
      <div class="form-field-block">
        <app-input-label
          labelType="required"
          for="user-input"
          text="adminBrandProfile.userLabel">
        </app-input-label>
        <div class="spinner-and-element-block">
          <i
            *ngIf="isUserLoading"
            class="pi pi-spin pi-spinner icon-spinner">
          </i>
          <p-dropdown
            class="custom-dropdown custom-dropdown-required"
            panelStyleClass="custom-dropdown-panel"
            [disabled]="isUserLoading"
            [filter]="true"
            filterBy="fullName"
            [options]="users"
            [virtualScroll]="true"
            [virtualScrollItemSize]="virtualScrollItemSize"
            optionLabel="fullName"
            optionValue="id"
            formControlName="user"
            inputId="user-input"
            appendTo="body"
            placeholder="{{ 'adminBrandProfile.ownerPlaceholder' | translate }}">
          </p-dropdown>
        </div>
        <app-input-error-message
          [showMessage]="form.controls.user.invalid && form.controls.user.touched"
          errorMessage="{{ 'adminBrandProfile.userReq' | translate }}">
        </app-input-error-message>
      </div>
    </div>
  </form>

  <div class="footer c-p-2 c-pl-5">
    <div class="col text-left form-field-input-checkbox-block c-p-0">
      <p-checkbox
        class="custom-input-checkbox-sm"
        binary="true"
        [formControl]="createAnotherControl"
        inputId="another-course-input">
      </p-checkbox>
      <app-input-label
        labelType="switch-checkbox"
        for="another-course-input"
        text="adminBrandProfile.createAnother">
      </app-input-label>
    </div>
    <div class="col text-right flex justify-content-end align-items-center">
      <app-button
        buttonText="{{ 'adminBrandProfile.cancelBtn' | translate }}"
        buttonIconLeft="fa-regular fa-xmark"
        [buttonStyle]="buttonStyle.GHOST_PRIMARY"
        [buttonSize]="buttonSize.MEDIUM"
        (click)="onCloseDialog()" />
      <app-button
        class="c-ml-2"
        buttonText="{{ 'adminBrandProfile.createBtn' | translate }}"
        buttonIconLeft="far fa-check"
        [buttonSize]="buttonSize.MEDIUM"
        [loading]="isUpdating"
        [disabled]="!canUpdate"
        (click)="create()" />
    </div>
  </div>
</div>
