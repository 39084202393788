<ng-container [ngSwitch]="labelType">
  <ng-container *ngSwitchCase="'usual'">
    <label
      class="label"
      [for]="for">
      <span>{{ text | translate }}</span>
    </label>
  </ng-container>

  <ng-container *ngSwitchCase="'required'">
    <label
      class="label"
      [for]="for">
      <span>{{ text | translate }}</span>
      <span class="star">*</span>
    </label>
  </ng-container>

  <ng-container *ngSwitchCase="'tooltip'">
    <label
      class="label"
      [for]="for">
      <span>{{ text | translate }}</span>
      <i
        class="fa-regular fa-circle-info info"
        pTooltip="{{ tooltipText | translate }}"
        [tooltipPosition]="tooltipPosition"
        [tooltipStyleClass]="tooltipStyleClass"
        [tooltipEvent]="tooltipEvent"
        [tabIndex]="tabIndex">
      </i>
    </label>
  </ng-container>

  <ng-container *ngSwitchCase="'tooltip-required'">
    <label
      class="label"
      [for]="for">
      <span>{{ text | translate }}</span>
      <i
        class="fa-regular fa-circle-info info"
        pTooltip="{{ tooltipText | translate }}"
        [tooltipPosition]="tooltipPosition"
        [tooltipStyleClass]="tooltipStyleClass"
        [tooltipEvent]="tooltipEvent"
        [tabIndex]="tabIndex">
      </i>
      <span class="star">*</span>
    </label>
  </ng-container>

  <ng-container *ngSwitchCase="'switch-checkbox'">
    <label
      class="label switch-checkbox"
      [for]="for">
      <span>{{ text | translate }}</span>
    </label>
  </ng-container>

  <ng-container *ngSwitchCase="'switch-checkbox-tooltip'">
    <label
      class="label switch-checkbox"
      [for]="for">
      <span>{{ text | translate }}</span>
      <i
        class="fa-regular fa-circle-info info"
        pTooltip="{{ tooltipText | translate }}"
        [tooltipPosition]="tooltipPosition"
        [tooltipStyleClass]="tooltipStyleClass"
        [tooltipEvent]="tooltipEvent"
        [tabIndex]="tabIndex">
      </i>
    </label>
  </ng-container>
</ng-container>
