import * as moment from 'moment';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { IAppointment, INotification, IUser } from 'src/app/core/models';
import { getTime, getTimeForEnglishLocale, getUserNameAbbreviation } from 'src/app/core/utils';
import { TranslationsService } from 'src/app/core/services';
import { parseToMoment } from 'src/app/shared';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-appointment-accepted-invite-notification',
  templateUrl: './appointment-accepted-invite-notification.component.html',
  styleUrls: ['./appointment-accepted-invite-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppointmentAcceptedInviteNotificationComponent {
  @Input() notification: INotification;
  @Output() joiningMeeting: EventEmitter<INotification> = new EventEmitter<INotification>();

  public dailyMeetingSublink = environment.dailyMeetingSublink;
  imageText = getUserNameAbbreviation;

  get user(): IUser {
    return this.notification?.user;
  }

  get host(): IUser {
    return this.notification?.host;
  }

  get appointment(): IAppointment {
    return this.notification?.appointment;
  }

  get isShowJoinBtn(): boolean {
    return moment().isBetween(
      parseToMoment(this.appointment.startDate),
      parseToMoment(this.appointment.endDate),
    );
  }

  get textForTooltip(): string {
    if (moment().isBefore(parseToMoment(this.appointment.startDate))) {
      return this.translationsService.getTranslationSync(
        'notifications.joinBtnTooltipBeforeStartMeeting',
      );
    }

    return this.translationsService.getTranslationSync(
      'notifications.joinBtnTooltipAfterStartMeeting',
    );
  }

  get notificationText(): string {
    const lang: string = this.translationsService.getCurrentLanguage;
    const startDate = `<span>${parseToMoment(this.appointment.startDate).format('DD.MM.YYYY')}</span>`;
    const endDate = `<span>${parseToMoment(this.appointment.endDate).format('DD.MM.YYYY')}</span>`;
    let startTime = '';
    let endTime = '';

    switch (lang) {
      case 'en':
        startTime = `<span>${getTimeForEnglishLocale(this.appointment.startDate)}</span>`;
        endTime = `<span>${getTimeForEnglishLocale(this.appointment.endDate)}</span>`;
        break;
      case 'de':
        startTime = `<span>${getTime(this.appointment.startDate)}</span>`;
        endTime = `<span>${getTime(this.appointment.endDate)}</span>`;
    }
    if (
      parseToMoment(this.appointment.startDate).isSame(
        parseToMoment(this.appointment.endDate).toDate(),
        'day',
      )
    ) {
      return `
        ${this.user.firstName} ${this.user.lastName} 
        ${this.translationsService.getTranslationSync('notifications.acceptedInviteSameDate', { title: this.appointment.meetingName, date: startDate, startTime, endTime })}
    `;
    }
    return `
      ${this.user.firstName} ${this.user.lastName} 
      ${this.translationsService.getTranslationSync('notifications.acceptedInviteDifferentDate', {
        title: this.appointment.meetingName,
        start: `${startDate} - ${startTime}`,
        end: `${endDate} - ${endTime}`,
      })}
    `;
  }

  constructor(private translationsService: TranslationsService) {}

  onJoiningMeeting(): void {
    this.joiningMeeting.emit(this.notification);
  }
}
