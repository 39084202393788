<app-loader *ngIf="loadingView; else calendlyTemplate"></app-loader>

<ng-template #calendlyTemplate>
  <div class="calendly-box-container">
    <iframe
      width="100%"
      height="100%"
      [src]="url"
      frameborder="0"
      webkitallowfullscreen
      mozallowfullscreen
      allowfullscreen></iframe>
  </div>
</ng-template>
