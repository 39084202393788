<ng-container *ngIf="loading">
  <app-loader class="register-loading-container"></app-loader>
</ng-container>

<div
  *ngIf="!loading"
  class="register-terms-and-conditionals-container">
  <div class="header c-p-5">
    <div class="title">
      {{ 'eventRegister.termsAndConditions' | translate }}
    </div>
  </div>

  <form [formGroup]="formConsents">
    <ng-container formArrayName="acceptedConsents">
      <ng-container *ngFor="let consent of consentControls; let i = index; trackBy: trackByFn">
        <div
          *ngIf="eventConsents[i]?.type === 'single'"
          class="checkbox-container c-mx-5 c-my-8">
          <p-checkbox
            class="custom-input-checkbox-sm"
            binary="true"
            inputId="consent{{ i }}"
            [formControlName]="i">
          </p-checkbox>
          <label
            class="checkbox-label"
            for="consent{{ i }}">
            <p class="consent consent-title">
              {{ eventConsents[i]?.title }}
              <span
                *ngIf="eventConsents[i]?.required"
                class="consent-required">
                *
              </span>
            </p>
            <p
              class="consent consent-description prime-ng-editor-reset"
              [innerHTML]="eventConsents[i]?.description"></p>
          </label>
        </div>

        <div *ngIf="eventConsents[i]?.type === 'multi'">
          <div
            *ngFor="let option of eventConsents[i]?.options; let k = index; trackBy: trackByFn"
            class="consent-option-container c-mx-5 c-my-8">
            <div class="radio-btn-container">
              <p-radioButton
                class="custom-input-radio"
                [value]="k"
                [formControlName]="i"
                inputId="consent-option-{{ option?._title_ }}{{ i }}">
              </p-radioButton>
              <label
                class="checkbox-label"
                for="consent-option-{{ option?._title_ }}{{ i }}">
                <p class="consent consent-title">{{ option?.title }}</p>
                <p
                  class="consent consent-description prime-ng-editor-reset"
                  [innerHTML]="option?.description"></p>
              </label>
              <br />
            </div>
          </div>
        </div>

        <div
          *ngIf="!(consentControls?.length - 1 === i)"
          class="c-mx-5 c-my-8">
          <p-divider styleClass="custom-divider-horizontal"></p-divider>
        </div>
      </ng-container>
    </ng-container>
  </form>
  <div class="footer c-p-2 c-pl-5">
    <div class="col text-right flex justify-content-end align-items-center">
      <button
        class="p-button-text button-styles"
        pButton
        type="button"
        [label]="'eventRegister.cancel' | translate"
        icon="fa-regular fa-xmark"
        (click)="ref.close()"></button>
      <button
        class="primary-text-color button-styles c-ml-2"
        pButton
        type="button"
        [label]="'eventRegister.save' | translate"
        [icon]="isUpdating ? 'pi pi-spin pi-spinner' : 'far fa-check'"
        [disabled]="isBtnDisabled"
        (click)="save()"></button>
    </div>
  </div>
</div>
