import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';

import { TicketsService } from 'src/app/core/services';
import { CoursesStore, EventsStore, TicketsStore } from 'src/app/core/stores';

@Injectable({
  providedIn: 'root',
})
export class AdminCourseTicketAccessGuard {
  constructor(
    private router: Router,
    private ticketsService: TicketsService,
    private eventsStore: EventsStore,
    private ticketsStore: TicketsStore,
    private courseStore: CoursesStore,
  ) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean | UrlTree> {
    let currentEntry = this.router.getCurrentNavigation().extras.state?.entry
      ? this.router.getCurrentNavigation().extras.state.entry
      : null;
    if (!currentEntry) {
      currentEntry = await this.ticketsService.getOneCourseTicket(
        this.courseStore.adminCourse.id,
        route.params.ticketId,
      );
    }

    this.ticketsStore.setAdminCourseTicket(currentEntry);
    return currentEntry ? true : false;
  }
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.ticketsStore.unsetAdminCourseTicket();
    return true;
  }
  canLoad(
    route: Route,
    segments: UrlSegment[],
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
}
