import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { PrivacyPageTypes } from 'src/app/shared/enums';
import { SettingsService } from '../../services';

@Component({
  selector: 'app-privacy-page',
  templateUrl: './privacy-page.component.html',
  styleUrls: ['./privacy-page.component.scss'],
})
export class PrivacyPageComponent implements OnInit {
  public html: string;

  constructor(
    private router: Router,
    private settingsService: SettingsService,
  ) {}

  async ngOnInit(): Promise<void> {
    switch (this.router.url) {
      case '/imprint':
        this.html = await this.settingsService.getPageHtml(PrivacyPageTypes.IMPRINT);
        break;

      case '/conditions-of-participation':
        this.html = await this.settingsService.getPageHtml(
          PrivacyPageTypes.CONDITIONS_OF_PARTICIPATION,
        );
        break;

      case '/data-protection':
        this.html = await this.settingsService.getPageHtml(PrivacyPageTypes.DATA_PROTECTION);
        break;

      case '/terms-of-conditions':
        this.html = await this.settingsService.getPageHtml(PrivacyPageTypes.TERMS_AND_CONDITIONS);
        break;

      case '/terms-of-use':
        this.html = await this.settingsService.getPageHtml(PrivacyPageTypes.TERMS_OF_USE);
        break;

      default:
        break;
    }
  }
}
