import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-new-email',
  templateUrl: './new-email.component.html',
  styleUrls: ['./new-email.component.scss'],
})
export class NewEmailComponent implements OnInit {
  public loading = true;
  public viewMode = 'unlayer';

  constructor(private route: ActivatedRoute) {}

  async ngOnInit(): Promise<void> {
    this.route.queryParams.subscribe(async (params) => {
      this.viewMode = params.mode;
      this.loading = false;
    });
  }
}
