<div class="container">
  <div class="title-wrapper">
    @if (backUrl) {
      <app-button
        class="c-mr-2"
        [buttonType]="buttonType.ROUND"
        [buttonSize]="buttonSize.MEDIUM"
        [buttonStyle]="buttonStyle.GHOST_PRIMARY"
        buttonRoundIcon="fa-regular fa-chevron-left"
        (click)="onBack()" />
    }
    <h1>{{ headerTitle | translate }}</h1>
    <div *ngIf="actionItems">
      <button
        class="btn"
        (click)="menu.toggle($event)">
        <i class="fa-regular fa-ellipsis"></i>
      </button>
      <p-menu
        #menu
        [popup]="true"
        [model]="actionItems"
        styleClass="p-custom-menu"></p-menu>
    </div>
    <div class="content-container">
      <ng-content></ng-content>
    </div>
  </div>
  <div
    *ngIf="menuItems"
    class="tab-menu">
    <p-menubar
      [model]="menuItems"
      autoDisplay="true"
      autoHide="true"
      styleClass="custom-menubar">
    </p-menubar>
  </div>
</div>
