import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { TranslateModule } from '@ngx-translate/core';

import { ButtonComponent } from '../../button/button.component';
import { BaseUnlayerEditorComponent } from '../base-unlayer-editor/base-unlayer-editor.component';
import { CrossIconComponent } from '../../cross-icon/cross-icon.component';

@Component({
  selector: 'app-web-page-unlayer-editor',
  standalone: true,
  imports: [
    DropdownModule,
    ReactiveFormsModule,
    ButtonComponent,
    TranslateModule,
    CrossIconComponent,
  ],
  templateUrl: './web-page-unlayer-editor.component.html',
  styleUrl: './web-page-unlayer-editor.component.scss',
})
export class WebPageUnlayerEditorComponent
  extends BaseUnlayerEditorComponent
  implements OnInit, OnDestroy {}
