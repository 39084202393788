import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SystemAdminPanelGuard } from './admin/guards/system-admin-panel.guard';
import { HubAccessGuard } from './hub/guards/hub-access.guard';
import { LoaderComponent } from './core/modules/loader/loader/loader.component';
import { PrivateSystemGuard } from 'src/app/core/guards/private-system.guard';
import { PrivacyPageComponent } from './core/components/privacy-page/privacy-page.component';
import {
  HubPricingTableComponent,
  NewHubComponent,
  StripeCallbackComponent,
  MaintenanceComponent,
} from './core/components';
import { NewHubGuard } from './core/guards/new-hub.guard';
import { SystemMaintenanceGuard } from './core/guards/system-maintenance.guard';
import { DomainHubAccessGuard } from './hub/guards/domain-hub-access.guard';
import { DOMAINS_LIST } from './core/utils';
import { GeneralSingleRouteConfigGuard } from './core/guards/general-single-route-config.guard';
import { HomeComponent } from './core/components/home/home.component';
import { GeneralMultiRouteConfigGuard } from './core/guards/general-multi-route-config.guard';

const routes: Routes = [
  // {
  //   path: '',
  //   pathMatch: 'full',
  //   redirectTo: ''
  // },
  {
    path: 'maintenance',
    component: MaintenanceComponent,
  },
  {
    path: 'imprint',
    component: PrivacyPageComponent,
    canActivate: [SystemMaintenanceGuard],
  },
  {
    path: 'conditions-of-participation',
    component: PrivacyPageComponent,
    canActivate: [SystemMaintenanceGuard],
  },
  {
    path: 'data-protection',
    component: PrivacyPageComponent,
    canActivate: [SystemMaintenanceGuard],
  },
  {
    path: 'terms-of-conditions',
    component: PrivacyPageComponent,
    canActivate: [SystemMaintenanceGuard],
  },
  {
    path: 'terms-of-use',
    component: PrivacyPageComponent,
    canActivate: [SystemMaintenanceGuard],
  },
  {
    path: '',
    component: HomeComponent,
    canActivate: [SystemMaintenanceGuard, GeneralMultiRouteConfigGuard],
  },
  {
    path: '',
    loadChildren: () =>
      import('./user-dashboard/user-dashboard.module').then((m) => m.UserDashboardModule),
    canActivate: [SystemMaintenanceGuard, GeneralSingleRouteConfigGuard],
  },
  {
    path: 'pricing-table',
    component: HubPricingTableComponent,
    canActivate: [SystemMaintenanceGuard],
  },
  {
    path: 'new-hub',
    component: NewHubComponent,
    canActivate: [SystemMaintenanceGuard, NewHubGuard],
  },
  {
    path: 'stripe-callback',
    component: StripeCallbackComponent,
    canActivate: [SystemMaintenanceGuard],
  },
  // {
  //   path: 'mybrand',
  //   loadChildren: () => import('./brand-dashboard/brand-dashboard.module').then(m => m.BrandDashboardModule),
  //   canActivate: [SystemMaintenanceGuard, PrivateSystemGuard]
  // },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
    canActivate: [SystemMaintenanceGuard, SystemAdminPanelGuard],
  },
  // TODO remove this route after combine course and stripe logic
  {
    path: 'app-loader',
    component: LoaderComponent,
  },
  {
    path: '',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
    canActivate: [SystemMaintenanceGuard],
  },
  {
    path: ':hub',
    loadChildren: () => import('./hub/hub.module').then((m) => m.HubModule),
    canActivate: [SystemMaintenanceGuard, HubAccessGuard, PrivateSystemGuard],
    canDeactivate: [SystemMaintenanceGuard, HubAccessGuard],
  },
  {
    path: '**',
    redirectTo: '',
  },
];

const hubRoutes: Routes = [
  // {
  //   path: '',
  //   pathMatch: 'full',
  //   redirectTo: 'dashboard'
  // },
  {
    path: 'maintenance',
    component: MaintenanceComponent,
    canActivate: [DomainHubAccessGuard, PrivateSystemGuard],
  },
  {
    path: 'imprint',
    component: PrivacyPageComponent,
    canActivate: [SystemMaintenanceGuard, DomainHubAccessGuard, PrivateSystemGuard],
  },
  {
    path: 'conditions-of-participation',
    component: PrivacyPageComponent,
    canActivate: [SystemMaintenanceGuard, DomainHubAccessGuard, PrivateSystemGuard],
  },
  {
    path: 'data-protection',
    component: PrivacyPageComponent,
    canActivate: [SystemMaintenanceGuard, DomainHubAccessGuard, PrivateSystemGuard],
  },
  {
    path: 'terms-of-conditions',
    component: PrivacyPageComponent,
    canActivate: [SystemMaintenanceGuard, DomainHubAccessGuard, PrivateSystemGuard],
  },
  {
    path: 'terms-of-use',
    component: PrivacyPageComponent,
    canActivate: [SystemMaintenanceGuard, DomainHubAccessGuard, PrivateSystemGuard],
  },
  {
    path: 'pricing-table',
    component: HubPricingTableComponent,
    canActivate: [SystemMaintenanceGuard, DomainHubAccessGuard, SystemMaintenanceGuard],
  },
  {
    path: 'new-hub',
    component: NewHubComponent,
    canActivate: [
      SystemMaintenanceGuard,
      DomainHubAccessGuard,
      SystemMaintenanceGuard,
      NewHubGuard,
    ],
  },
  {
    path: 'stripe-callback',
    component: StripeCallbackComponent,
    canActivate: [SystemMaintenanceGuard, DomainHubAccessGuard, SystemMaintenanceGuard],
  },
  {
    path: '',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
    canActivate: [SystemMaintenanceGuard, DomainHubAccessGuard, PrivateSystemGuard],
  },
  {
    path: '',
    loadChildren: () => import('./hub/hub.module').then((m) => m.HubModule),
    canActivate: [SystemMaintenanceGuard, DomainHubAccessGuard, PrivateSystemGuard],
  },
  // TODO remove this route after combine course and stripe logic
  {
    path: 'app-loader',
    component: LoaderComponent,
  },
  {
    path: '**',
    redirectTo: '',
  },
];

const routesBasedOnDomain: Routes = DOMAINS_LIST().includes(window.location.host)
  ? hubRoutes
  : routes;

@NgModule({
  imports: [RouterModule.forRoot(routesBasedOnDomain, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
