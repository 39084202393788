<div
  class="brand-product-container flex"
  (click)="disableDefaultOnClick ? '' : onClick($event)">
  <img
    *ngIf="product.image"
    class="img-banner-container"
    [src]="product.image | imageSize"
    alt="" />
  <div
    class="data"
    #data>
    <div
      *ngIf="showBookmarkContent"
      class="bookmark-icon-box">
      <p-progressSpinner *ngIf="showBookmarkSpinner"></p-progressSpinner>
      <i
        *ngIf="!showBookmarkSpinner"
        [ngClass]="getBookmarkedIconClass()"></i>
    </div>
    <div class="product-name">
      <p class="name">{{ product.title }}</p>
      <p class="tagline">{{ product.tagline }}</p>
    </div>
  </div>
</div>
