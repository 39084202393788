import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-web-push-notification-dialog',
  templateUrl: './web-push-notification-dialog.component.html',
  styleUrls: ['./web-push-notification-dialog.component.scss'],
})
export class WebPushNotificationDialogComponent implements OnInit {
  public title: string;
  public description: string;

  public className: string;

  constructor(
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
  ) {
    this.title = config.data?.title || '';
    this.description = config.data?.description || '';

    this.className = config.data?.className || 'fa-regular fa-bell';
  }

  ngOnInit(): void {}
}
