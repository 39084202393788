import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { makeAutoObservable } from 'mobx';

import { IAsset, ITag } from 'src/app/core/models';

@Injectable()
export class LibraryStore {
  asset: IAsset = null;

  private adminAsset = new BehaviorSubject<IAsset>(null);

  get assetId(): string {
    return this.asset?.id;
  }

  get adminAsset$(): Observable<IAsset> {
    return this.adminAsset.asObservable();
  }

  constructor() {
    makeAutoObservable(this);
  }

  setAsset(asset: IAsset): void {
    this.asset = asset;
  }

  setAdminAsset(asset: IAsset): void {
    this.adminAsset.next(asset);
  }

  unsetAdminAsset(): void {
    this.adminAsset.next(null);
  }

  setAssetTags(tags: ITag[]): void {
    const tagIds = tags.map((tag: ITag) => tag.id);
    if (this.asset.tags.every((id) => tagIds.includes(id))) {
      this.asset.tagsList = tags;
    }
  }

  reset(): void {
    this.asset = null;
  }
}
