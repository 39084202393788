import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { NotificationsService } from 'src/app/core/services';
import { NotificationAnimation } from './notification-bell.animations';

@Component({
  selector: 'app-notification-bell',
  templateUrl: './notification-bell.component.html',
  styleUrls: ['./notification-bell.component.scss'],
  animations: [NotificationAnimation],
})
export class NotificationBellComponent implements OnInit {
  expandDropdown = false;
  totalUnreadNotifications$: Observable<number>;

  constructor(private notificationsService: NotificationsService) {}

  ngOnInit(): void {
    this.totalUnreadNotifications$ = this.notificationsService.totalUnreadNotifications();
  }

  private onOpenNotifications(): void {
    this.notificationsService.markAllNotificationsAsRead();
  }

  onToggleDropdown(): void {
    this.expandDropdown = !this.expandDropdown;
    this.onOpenNotifications();
  }

  onCloseNotifications(): void {
    this.expandDropdown = false;
  }
}
