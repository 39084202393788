<div class="c-p-5">
  <div class="flex align-items-center justify-content-between c-mb-7">
    <div class="title">
      {{ title() }}
    </div>
    <i
      class="fa-regular fa-xmark close-icon"
      (click)="closeDialog()">
    </i>
  </div>
  <div class="scrollable-content">
    <app-admin-event-menu-items-organizer [items]="items()"> </app-admin-event-menu-items-organizer>
  </div>
</div>
