import { Component } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-decline-meeting-dialog',
  templateUrl: './decline-meeting-dialog.component.html',
  styleUrls: ['./decline-meeting-dialog.component.scss'],
})
export class DeclineMeetingDialogComponent {
  reason = new UntypedFormControl('', Validators.required);

  constructor(private ref: DynamicDialogRef) {}

  onSendReason(): void {
    this.ref.close(this.reason.value);
  }
}
