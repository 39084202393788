import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-new-page',
  templateUrl: './new-page.component.html',
  styleUrls: ['./new-page.component.scss'],
})
export class NewPageComponent implements OnInit {
  public loading = true;
  public emailIsHtmlMode = false;
  public title: string = null;

  constructor(
    private route: ActivatedRoute,
    private translateService: TranslateService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.route.queryParams.subscribe(async (params) => {
      this.emailIsHtmlMode = params.mode
        ? params.mode === 'basic'
          ? false
          : params.mode === 'html'
            ? true
            : false
        : false;
      this.title = this.translateService.instant('adminEventNewPageTemplate.newPageTitle');
      this.loading = false;
    });
  }
}
