import * as moment from 'moment';
import { Component, OnDestroy, OnInit, signal } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject, firstValueFrom, merge, takeUntil, tap } from 'rxjs';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

import {
  IDailyRecordAccessLink,
  IDailyRoom,
  IDailyRoomConfig,
  IEvent,
  ISession,
  IStage,
  IUser,
} from 'src/app/core/models';
import {
  DailyCoService,
  EventsService,
  FormService,
  SessionsService,
  StagesService,
} from 'src/app/core/services';
import { EventsStore, SessionsStore } from 'src/app/core/stores';
import { asyncDelay } from 'src/app/core/utils';
import { SharedModule, parseToMoment } from 'src/app/shared';
import {
  ButtonComponent,
  ButtonSize,
  SaveDiscardActionsComponent,
  ToastComponent,
} from 'src/app/standalone/shared';
import { RecordingTypes } from 'src/app/core/enums';

@Component({
  selector: 'app-event-session-space-settings',
  standalone: true,
  imports: [SharedModule, SaveDiscardActionsComponent, ButtonComponent, ToastComponent],
  templateUrl: './event-session-space-settings.component.html',
  styleUrl: './event-session-space-settings.component.scss',
})
export class EventSessionSpaceSettingsComponent implements OnInit, OnDestroy {
  loading = signal<boolean>(true);
  isUpdating = signal<boolean>(false);
  form: FormGroup;
  moderators = signal<IUser[]>(null);
  maxParticipants = signal<number>(1000);
  typesOfRecording = signal<string[]>(Object.values(RecordingTypes));
  recordingTypes = RecordingTypes;
  buttonSize = ButtonSize;

  private stage = signal<IStage>(null);
  private sessionRecord = signal<IDailyRecordAccessLink>(null);
  private unsubscribe$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private formService: FormService,
    private eventsStore: EventsStore,
    private sessionsStore: SessionsStore,
    private sessionsService: SessionsService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private stagesService: StagesService,
    private eventsService: EventsService,
    private dailyCoService: DailyCoService,
  ) {}

  get event(): IEvent {
    return this.eventsStore.adminEvent;
  }

  get session(): ISession {
    return this.sessionsStore.adminSession;
  }

  get isSessionFinished(): boolean {
    return this.session.space
      ? moment().isAfter(parseToMoment(this.session.end).add(20, 'minutes'))
      : moment().isAfter(parseToMoment(this.session.end));
  }

  get isLiveStreamingDisabled(): boolean {
    return !(!!this.stage() && this.stage()?.streamProvider === 'Amazon IVS');
  }

  get liveStreamingTooltip(): string {
    if (this.isLiveStreamingDisabled) {
      return 'adminSession.liveStreamingTooltip';
    }

    return 'adminSession.spaceSettingsTooltipText';
  }

  get canUpdate(): boolean {
    return (
      !this.isUpdating() && this.form.valid && this.form.dirty && this.formService.isValueChanged()
    );
  }

  async ngOnInit(): Promise<void> {
    this.createForm();
    this.updateForm();
    this.formService.setForm(this.form);

    if (this.session?.stageId) {
      const stage: IStage = await this.stagesService.getOne(
        this.event.id,
        this.session.stageId,
        true,
      );
      this.stage.set(stage);
    }

    const [speakersResponse, attendeesResponse] = await Promise.all([
      this.eventsService.getAllEventUsersByRole(this.event.id, 'speaker'),
      this.eventsService.getAllEventUsersByRole(this.event.id, 'attendee'),
    ]);
    this.moderators.set(speakersResponse.concat(attendeesResponse));

    this.loading.set(false);

    if (this.isSessionFinished || this.isLiveStreamingDisabled) {
      this.form.controls.isLiveStreaming.disable();
    }

    if (this.session.isRecording) {
      this.form.controls.recordingType.setValidators(Validators.required);
    }

    if (this.isSessionFinished) {
      const recordOfSession: IDailyRecordAccessLink = await firstValueFrom(
        this.dailyCoService.getFirstAccessRecordLinkByRoomName(this.session.meetingRoomName),
      );
      this.sessionRecord.set(recordOfSession);
    }

    if (this.session.space) {
      this.form.controls.moderators.addValidators(Validators.required);
    }

    const spaceChanges$ = this.form.controls.space.valueChanges.pipe(
      tap((value: boolean) => {
        if (value) {
          this.form.controls.moderators.addValidators(Validators.required);
        } else {
          this.form.controls.moderators.removeValidators(Validators.required);
        }
      }),
    );

    const recordingChanges$ = this.form.controls.isRecording.valueChanges.pipe(
      tap((value: boolean) => {
        if (value) {
          this.form.controls.recordingType.setValidators(Validators.required);
        } else {
          this.form.controls.recordingType.removeValidators(Validators.required);
        }
      }),
    );

    merge(spaceChanges$, recordingChanges$).pipe(takeUntil(this.unsubscribe$)).subscribe();
  }

  getRecordingTypeText(value: string): string {
    return `adminSession.${value}`;
  }

  downloadRecord(): void {
    if (!this.isSessionFinished || !this.sessionRecord()?.download_link) {
      return null;
    }
    window.location.href = this.sessionRecord().download_link;
  }

  onDiscard(): void {
    this.updateForm();
  }

  async onConfirm(): Promise<void> {
    try {
      this.isUpdating.set(true);
      await asyncDelay(1);
      await this.updateSession();
      this.formService.setForm(this.form);
    } catch (error) {
      console.error(error);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private createForm(): void {
    this.form = this.fb.group({
      space: [{ value: null, disabled: this.isSessionFinished }],
      isLiveStreaming: null,
      isAllowOnlyModerators: [{ value: null, disabled: this.isSessionFinished }],
      moderators: [{ value: null, disabled: this.isSessionFinished }],
      maxParticipants: [
        { value: 1000, disabled: this.isSessionFinished },
        Validators.max(this.maxParticipants()),
      ],
      ownerOnlyBroadcast: [{ value: false, disabled: this.isSessionFinished }],
      camera: [{ value: false, disabled: this.isSessionFinished }],
      microphone: [{ value: false, disabled: this.isSessionFinished }],
      screenSharing: [{ value: false, disabled: this.isSessionFinished }],
      textChat: [{ value: false, disabled: this.isSessionFinished }],
      isShowPeopleListing: [{ value: false, disabled: this.isSessionFinished }],
      isPictureInPicture: [{ value: false, disabled: this.isSessionFinished }],
      isHandRaising: [{ value: false, disabled: this.isSessionFinished }],
      isEmojiReactions: [{ value: false, disabled: this.isSessionFinished }],
      isBreakoutRooms: [{ value: false, disabled: this.isSessionFinished }],
      isRecording: [{ value: false, disabled: this.isSessionFinished }],
      recordingType: [{ value: null, disabled: this.isSessionFinished }],
    });
  }

  private updateForm(): void {
    this.form.patchValue({
      ...this.session,
    });
  }

  private async updateSession(): Promise<void> {
    this.isUpdating.set(true);
    const formValues = this.form.getRawValue();

    if (!this.session.space && formValues.space) {
      await this.createDailyRoom();
    } else if (this.session.space && formValues.space && this.isSpaceSettingsChanged()) {
      await this.updateDailyRoom();
    } else if (this.session.space && !formValues.space) {
      await this.deleteDailyRoom();
    }

    const sessionForUpdate: ISession = {
      ...this.session,
      ...formValues,
    };

    try {
      await this.sessionsService.update(this.event.id, sessionForUpdate.id, sessionForUpdate);
      this.sessionsStore.setAdminSession({ ...sessionForUpdate });
      this.isUpdating.set(false);
      this.showToastMessage('success', 'adminSession.sessionSuccessfullyUpdated');
    } catch (error) {
      console.log(error);
      this.isUpdating.set(false);
      this.showToastMessage('error', 'adminSession.errorUpdateSession');
    }
  }

  private async createDailyRoom(): Promise<void> {
    try {
      const configForDailyRoom: IDailyRoomConfig = this.createDailyConfig();
      const room: IDailyRoom = await this.dailyCoService.createRoom(configForDailyRoom).toPromise();
      this.session.meetingRoomUrl = room.url;
      this.session.meetingRoomName = room.name;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  private createDailyConfig(): IDailyRoomConfig {
    const newSession = this.form.getRawValue();
    return {
      owner_only_broadcast: newSession.ownerOnlyBroadcast,
      start_video_off: !newSession.camera,
      start_audio_off: !newSession.microphone,
      enable_screenshare: newSession.screenSharing,
      enable_chat: newSession.textChat,
      enable_advanced_chat: newSession.textChat,
      enable_people_ui: newSession.isShowPeopleListing,
      enable_pip_ui: newSession.isPictureInPicture,
      enable_hand_raising: newSession.isHandRaising,
      enable_emoji_reactions: newSession.isEmojiReactions,
      enable_breakout_rooms: newSession.isBreakoutRooms,
      exp: moment(parseToMoment(this.session.end)).add(20, 'minutes').unix(),
      max_participants: newSession.maxParticipants,
      eject_at_room_exp: true,
      enable_network_ui: true,
      enable_prejoin_ui: true,
      enable_video_processing_ui: true,
    };
  }

  private isSpaceSettingsChanged(): boolean {
    const {
      isSessionRegistration,
      isCancellingEnabled,
      availability,
      isClosingSessionRegistration,
      closingDate,
      closingTime,
      sessionsRelation,
      ...newSession
    } = this.form.getRawValue();
    const keys = Object.keys(newSession);

    for (let key of keys) {
      if (JSON.stringify(newSession[key]) !== JSON.stringify(this.session[key])) {
        return true;
      }
    }
    return false;
  }

  private async updateDailyRoom(): Promise<void> {
    try {
      const newSession = this.form.getRawValue();
      await this.dailyCoService
        .updateRoom(this.session.meetingRoomName, {
          owner_only_broadcast: newSession.ownerOnlyBroadcast,
          start_video_off: !newSession.camera,
          start_audio_off: !newSession.microphone,
          enable_screenshare: newSession.screenSharing,
          enable_chat: newSession.textChat,
          enable_advanced_chat: newSession.textChat,
          enable_people_ui: newSession.isShowPeopleListing,
          enable_pip_ui: newSession.isPictureInPicture,
          max_participants: newSession.maxParticipants,
          enable_hand_raising: newSession.isHandRaising,
          enable_emoji_reactions: newSession.isEmojiReactions,
          enable_breakout_rooms: newSession.isBreakoutRooms,
        })
        .toPromise();
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  private async deleteDailyRoom(): Promise<void> {
    try {
      await this.dailyCoService.deleteRoom(this.session.meetingRoomName).toPromise();
      this.form.patchValue({
        ownerOnlyBroadcast: false,
        camera: false,
        microphone: false,
        screenSharing: false,
        textChat: false,
        isRecording: false,
        isShowPeopleListing: false,
        isPictureInPicture: false,
        isHandRaising: false,
        isEmojiReactions: false,
        isBreakoutRooms: false,
        maxParticipants: null,
        moderators: null,
      });
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  private showToastMessage(severity: 'success' | 'error', detail: string): void {
    this.messageService.add({
      severity,
      summary: this.translateService.instant(severity),
      detail: this.translateService.instant(detail),
      styleClass: 'custom-toast',
    });
  }
}
