<ng-container *ngIf="loading">
  <app-loader></app-loader>
</ng-container>

<div
  *ngIf="!loading"
  class="admin-brand-course-inner-container">
  <div class="header-container">
    <div class="title">{{ brand?.name }}</div>
  </div>

  <p-divider styleClass="custom-divider-horizontal"></p-divider>

  <div class="navigation-container c-mt-4">
    <p-menubar
      [model]="menuItems"
      styleClass="brand-menubar"></p-menubar>
  </div>

  <div class="child-container">
    <router-outlet></router-outlet>
  </div>
</div>
