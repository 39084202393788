import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { HubsStore } from 'src/app/core/stores';
import { FeatureHeaderComponent, FeatureMainContentComponent } from '../../../components';

@Component({
  selector: 'app-reports-container',
  standalone: true,
  imports: [CommonModule, FeatureHeaderComponent, FeatureMainContentComponent],
  templateUrl: './reports-container.component.html',
  styleUrls: ['./reports-container.component.scss'],
})
export class ReportsContainerComponent implements OnInit {
  items: MenuItem[];

  constructor(
    private translateService: TranslateService,
    private hubsStore: HubsStore,
  ) {}

  ngOnInit(): void {
    this.items = [
      {
        label: this.translateService.instant('adminReports.payments'),
        icon: 'fa-regular fa-money-bill-trend-up',
        routerLink: `/${this.hubsStore.useHubUrl}/admin/reports/payments`,
      },
      {
        label: this.translateService.instant('adminReports.payouts'),
        icon: 'fa-regular fa-money-bill-transfer',
        routerLink: `/${this.hubsStore.useHubUrl}/admin/reports/payouts`,
      },
    ];
  }
}
