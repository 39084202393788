import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { Subject, filter, takeUntil, tap } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';

import { IUser } from 'src/app/core/models';
import { UsersStore } from 'src/app/core/stores';

@Component({
  selector: 'app-admin-sidebar-without-hub',
  standalone: true,
  imports: [CommonModule, TranslateModule, NgOptimizedImage, RouterModule],
  templateUrl: './admin-sidebar-without-hub.component.html',
  styleUrls: ['./admin-sidebar-without-hub.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminSidebarWithoutHubComponent implements OnInit, OnDestroy {
  @Input() logo: string;

  currentUrl: string;

  private unsubscribe$ = new Subject<void>();

  get user(): IUser {
    return this.usersStore.user;
  }

  get isShowGlobalItem(): boolean {
    return (
      this.user &&
      ['superAdmin', 'internalAdmin', 'instanceOwner', 'softwareDeveloper'].includes(this.user.role)
    );
  }

  constructor(
    private router: Router,
    private cd: ChangeDetectorRef,
    private usersStore: UsersStore,
  ) {}

  ngOnInit(): void {
    this.currentUrl = this.router.url.split('/').slice(0, 4).join('/');
    this.router.events
      .pipe(
        filter((event: NavigationEnd) => event instanceof NavigationEnd),
        tap((event: NavigationEnd) => {
          this.currentUrl = event.url.split('/').slice(0, 4).join('/');
          this.cd.detectChanges();
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
