export interface IPrivacySettingsPage {
  id: string;
  title: string;
  _title_: string;
  type: number;
  url?: string;
  html: string;
  language: string;
  isMobile: boolean;
  status: boolean;
  audience?: string;
  isDeleted: boolean;
  updatedAt?: any;
  updatedBy?: string;
  createdAt?: any;
  createdBy?: string;
}
