import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { from } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

import { EventsStore, BrandsStore } from 'src/app/core/stores';
import {
  GoogleTagManagerAnalyticsService,
  BookmarkService,
  TranslationsService,
  MixpanelService,
  BrandsDetailsService,
} from 'src/app/core/services';
import { IBrand, IEvent } from 'src/app/core/models';
import { imgPreloader } from '../../utils';
import { AnalyticsEventTypes, BookmarkableCollection } from 'src/app/core/enums';

@Component({
  selector: 'app-brand-card',
  templateUrl: './brand-card.component.html',
  styleUrls: ['./brand-card.component.scss'],
})
export class BrandCardComponent implements OnInit, OnDestroy {
  @Input() public brand: IBrand = null;
  @Input() public showOnlyLogo: boolean;
  @Input() public showOnlyTitle: boolean;
  @Input() public showDetailsOnClick: boolean = true;
  @Input() size: string;
  @Input() centered: boolean;
  @Input() caseWithLogoWithoutBrowserEvent: boolean;

  public sizes: string[] = ['small', 'medium', 'large'];
  public imageSize: string;
  public showBookmarkSpinner = true;
  public showBookmarkContent: boolean;
  public currentEvent: IEvent;
  public imgPreloader = imgPreloader;

  private readonly bookmarkableCollection: BookmarkableCollection;
  private brandDialogRef: DynamicDialogRef;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private eventStore: EventsStore,
    private bookmarkService: BookmarkService,
    private translationsService: TranslationsService,
    private brandsStore: BrandsStore,
    private gtmAnalyticsService: GoogleTagManagerAnalyticsService,
    private mixpanelService: MixpanelService,
    private brandsDetailsService: BrandsDetailsService,
  ) {
    this.bookmarkableCollection = BookmarkableCollection.Brands;
    this.showBookmarkContent = this.eventStore.isEventRegistered;
  }

  ngOnInit(): void {
    this.currentEvent = this.eventStore.event;
    if (this.showBookmarkContent) {
      from(this.bookmarkService.fetchIds(this.bookmarkableCollection))
        .pipe(filter((s) => s))
        .subscribe(() => (this.showBookmarkSpinner = false));
    }

    let index = this.sizes.indexOf(this.size);
    this.imageSize = this.sizes[index != -1 ? index : 2];
  }

  public get isMedia(): boolean {
    return this.brand.level === 'media';
  }

  public getBrandLevelLabel(level: string): string {
    let brandTraKey = '';

    switch (level) {
      case 'platinum':
        brandTraKey = 'platinumBrandLabel';
        break;
      case 'gold':
        brandTraKey = 'goldBrandLabel';
        break;
      case 'silver':
        brandTraKey = 'silverBrandLabel';
        break;
      case 'media':
        brandTraKey = 'mediaBrandLabel';
        break;
    }

    return brandTraKey !== '' ? this.translationsService.getTranslationSync(brandTraKey) : '';
  }

  public getBookmarkedIconClass(): string {
    return this.brandsStore.bookmarkedIds.includes(this.brand.id)
      ? 'fak fa-lox-bookmark-1 active'
      : 'fak fa-lox-bookmark-1';
  }

  public onClick(event): void {
    const target: HTMLElement = event.target;

    if (target.className.includes('bookmark')) {
      this.bookmark();
    } else if (this.router.url.includes(`${this.eventStore.event.id}/brands`)) {
      this.router.navigate([this.brand.id], { relativeTo: this.route });
    } else if (this.showDetailsOnClick) {
      this.openDetails();
    }
  }

  private async bookmark(): Promise<void> {
    this.showBookmarkSpinner = true;

    if (this.brandsStore.bookmarkedIds.includes(this.brand.id)) {
      const result: boolean = await this.bookmarkService.removeBookmark(
        this.brand.id,
        this.bookmarkableCollection,
      );
      if (result) {
        this.showBookmarkSpinner = false;
      }
      // send event to mixpanel analytics
      this.mixpanelService.brandBookmarkOrUnbookmark(
        this.currentEvent,
        this.brand,
        AnalyticsEventTypes.BRAND_UNBOOKMARK,
      );
    } else {
      const result: boolean = await this.bookmarkService.bookmark(
        this.brand.id,
        this.bookmarkableCollection,
      );
      if (result) {
        this.showBookmarkSpinner = false;
      }
      // send event to google tag manager and mixpanel analytics
      this.gtmAnalyticsService.gtmBrandBookmarkEventWithBrand(this.brand);
      this.mixpanelService.brandBookmarkOrUnbookmark(
        this.currentEvent,
        this.brand,
        AnalyticsEventTypes.BRAND_BOOKMARK,
      );
    }
  }

  private openDetails(): void {
    this.brandsDetailsService.showBrandDetailsDialog(this.brand.id);
  }

  public ngOnDestroy(): void {
    this.brandDialogRef?.close();
  }
}
