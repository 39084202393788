<button
  *ngIf="enabled"
  [pTooltip]="tooltipText"
  [tooltipStyleClass]="tooltipStyleClass"
  class="chat-bubble"
  [ngClass]="{ 'chat-not-allowed': !isChatAllowed, 'chat-is-ios-pwa': isIosPWA }">
  <i class="far fa-comments"></i>
  <div
    [ngClass]="{ 'unread-messages--hidden': !unreadMessages }"
    class="unread-messages">
    {{ unreadMessages }}
  </div>
</button>
