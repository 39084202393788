import { Component, Input } from '@angular/core';

import { IEvent } from 'src/app/core/models';
import { MixpanelService, UsersService } from 'src/app/core/services';
import { UsersStore } from 'src/app/core/stores';
import { AnalyticsEventTypes } from 'src/app/core/enums';

@Component({
  selector: 'app-event-bookmark-button',
  templateUrl: './event-bookmark-button.component.html',
  styleUrls: ['./event-bookmark-button.component.scss'],
})
export class EventBookmarkButtonComponent {
  @Input() event?: IEvent;

  private bookmarking: boolean;

  public get isBookmarked(): boolean {
    return this.usersStore.userBookmarkedEventsIds.includes(this.event?.id);
  }

  public get loggedUser(): boolean {
    return !!this.usersStore.user;
  }

  constructor(
    private usersStore: UsersStore,
    private mixpanelService: MixpanelService,
    private usersService: UsersService,
  ) {}

  public async bookmark(): Promise<void> {
    if (this.bookmarking) {
      return;
    }

    this.bookmarking = true;

    if (this.usersStore.userBookmarkedEventsIds.includes(this.event.id)) {
      await this.usersService.unBookmarkEvent(this.usersStore.userId, this.event.id);

      // send event to mixpanel analytics
      await this.mixpanelService.eventBookmarkedOrUnBookmarked(
        this.event,
        AnalyticsEventTypes.EVENT_UNBOOKMARKED,
      );
    } else {
      await this.usersService.bookmarkEvent(this.usersStore.userId, this.event.id);

      // send event to mixpanel analytics
      await this.mixpanelService.eventBookmarkedOrUnBookmarked(
        this.event,
        AnalyticsEventTypes.EVENT_BOOKMARKED,
      );
    }

    this.bookmarking = false;
  }
}
