import { Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { TranslationsService } from 'src/app/core/services';
import { SwNewVersionComponent } from '../../components/sw-new-version/sw-new-version.component';
import { SwInstallPromptComponent } from '../../components/sw-install-prompt/sw-install-prompt.component';
import { useDevice } from '../../utils/utils';
import { WebPushService } from '../webpush/webpush.service';
import { UsersStore } from 'src/app/core/stores';
import { DevicesService } from '../devices/devices.service';

@Injectable({
  providedIn: 'root',
})
export class ServiceWorkerService {
  private isNewVersionAvailableSubject$ = new BehaviorSubject<boolean>(false);
  isNewVersionAvailable$ = this.isNewVersionAvailableSubject$.asObservable();

  private isOnlineSubject$ = new BehaviorSubject<boolean>(true);
  isOnline$ = this.isOnlineSubject$.asObservable();

  private modalPwaNetworkSubject$ = new BehaviorSubject<boolean>(false);
  modalPwaNetwork$ = this.modalPwaNetworkSubject$.asObservable();

  isOnline: boolean = true;

  private modalPwaEvent: any;
  private initFinished = false;
  private deviceInfo = useDevice();

  constructor(
    private swUpdate: SwUpdate,
    private translateService: TranslateService,
    private translationsService: TranslationsService,
    private dialogService: DialogService,
    private messageService: MessageService,
    private webPushService: WebPushService,
    private usersStore: UsersStore,
    private devicesService: DevicesService,
  ) {
    this.translationsService.translationsLoading$.subscribe({
      next: async (loaded) => {
        if (!loaded && !this.initFinished) {
          console.log('START SERVICE WORKER >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>');
          console.log(`SW_ENABLED=[${this.swUpdate.isEnabled}]`);

          if (this.swUpdate.isEnabled) {
            this.swUpdate.versionUpdates.subscribe((event: VersionReadyEvent) => {
              if (event.type === 'VERSION_READY') {
                console.log(
                  '@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ SW_NEW_VERSION @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@',
                  event,
                );
                console.log(`SW_NEW_VERSION=[TRUE]`);
                this.showAppNewVersionPopUp();
              }
            });
          }

          this.updateOnlineStatus();
          window.addEventListener('online', this.updateOnlineStatus.bind(this));
          window.addEventListener('offline', this.updateOnlineStatus.bind(this));

          this.loadModalPwa();
          this.initWebPushNotifications();

          this.initFinished = true;
          console.log('SERVICE WORKER END <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<');
        }
      },
    });
  }

  private async initWebPushNotifications() {
    if (this.webPushService.isMessagingSupported) {
      const permission = await this.webPushService.requestPermission();
      console.log('permission => ', permission);

      if (permission) {
        const token = await this.webPushService.getToken();
        const device = await this.devicesService.getByWebPushToken(token);
        let devicePayload = null;

        localStorage.setItem('web_push_token', token);

        if (this.usersStore.user) {
          const deviceByUserId = await this.devicesService.getByUserId(this.usersStore.user.id);

          if (device) {
            devicePayload = await this.devicesService.updateDevice(
              device.id,
              token,
              this.usersStore.user.id,
              device.webPushPermission,
            );
            this.usersStore.setUserDevice({ ...device, ...devicePayload });
          } else if (deviceByUserId) {
            devicePayload = await this.devicesService.updateDevice(
              deviceByUserId.id,
              token,
              this.usersStore.user.id,
              deviceByUserId.webPushPermission,
            );
            this.usersStore.setUserDevice({ ...device, ...devicePayload });
          } else {
            devicePayload = await this.devicesService.createDevice(
              token,
              this.usersStore.user.id,
              true,
            );
          }
        } else {
          if (device) {
            devicePayload = await this.devicesService.updateDevice(
              device.id,
              token,
              null,
              device.webPushPermission,
            );
          } else {
            devicePayload = await this.devicesService.createDevice(token, null, true);
          }
        }

        console.log(devicePayload);
        if (devicePayload.webPushPermission) this.webPushService.subscribeForegroundMessages();
      } else {
        if (this.usersStore.user) {
          const device = await this.devicesService.getByUserId(this.usersStore.user.id);
          let devicePayload = null;

          if (device) {
            devicePayload = await this.devicesService.updateDevice(
              device.id,
              null,
              this.usersStore.user.id,
              device.webPushPermission,
            );
            this.usersStore.setUserDevice({ ...device, ...devicePayload });
          } else {
            this.devicesService.createDevice(null, this.usersStore.user.id);
          }
        } else {
          console.log('User is not logged...');
        }
      }
    } else {
      // Skip init web push notifications
      console.log('Skip init web push notifications...');
      // alert('Skip init web push notifications...');
    }
  }

  public async showPWAInstallMsg(): Promise<void> {
    this.loadModalPwa();
  }

  public async reloadApp(): Promise<void> {
    this.isNewVersionAvailableSubject$.next(false);
    window.location.reload();
  }

  public async showAppNewVersionPopUp(): Promise<void> {
    const ref = this.dialogService.open(SwNewVersionComponent, {
      width: '80vw',
      style: {
        position: 'absolute',
        top: '4rem',
        'max-width': '18.75rem',
      },
      closable: false,
      styleClass: 'ignore-default-styles',
      contentStyle: {
        'flex-grow': 1,
      },
      data: {},
    });

    ref.onClose.subscribe((res: boolean = false) => {
      if (res) {
        this.reloadApp();
      } else {
        this.isNewVersionAvailableSubject$.next(true);
      }
    });
  }

  private async updateOnlineStatus(): Promise<void> {
    console.info(`ONLINE=[${window.navigator.onLine}]`);
    this.isOnlineSubject$.next(window.navigator.onLine);

    if (this.initFinished) {
      this.messageService.add({
        severity: window.navigator.onLine ? 'success' : 'error',
        summary: this.translateService.instant('SW.onlineStatusTitle'),
        detail:
          !this.initFinished && window.navigator.onLine
            ? this.translateService.instant('SW.onlineMsg')
            : window.navigator.onLine
              ? this.translateService.instant('SW.onlineBackMsg')
              : this.translateService.instant('SW.offlineMsg'),
        styleClass: 'custom-toast',
        life: 3000,
      });
    }
  }

  private async loadModalPwa(): Promise<void> {
    console.log(`PLATFORMS:`, this.deviceInfo);

    if (this.deviceInfo.isDesktopPlatform) {
      // Desktop Platform
    } else if (this.deviceInfo.isMobilePlatform) {
      if (this.deviceInfo.mobileOS === 'android') {
        window.addEventListener('beforeinstallprompt', (event: any) => {
          event.preventDefault();
          this.modalPwaEvent = event;
          // this.showInstallAlert('android');
        });
      } else if (this.deviceInfo.mobileOS === 'ios') {
        const isInStandaloneMode =
          'standalone' in window.navigator && (<any>window.navigator)['standalone'];
        if (!isInStandaloneMode) {
          // this.showInstallAlert('ios');
        }
      } else {
        // OTHER PLATFORM
      }
    } else {
      // OTHER PLATFORM
    }
  }

  private async showInstallAlert(platform: 'android' | 'ios'): Promise<void> {
    const ref = this.dialogService.open(SwInstallPromptComponent, {
      width: '90vw',
      style: {
        position: 'absolute',
        top: '4rem',
      },
      styleClass: 'ignore-default-styles',
      closable: false,
      contentStyle: {
        'flex-grow': 1,
      },
      data: {
        platform: platform,
      },
    });

    ref.onClose.subscribe((res: boolean = false) => {
      if (res && platform === 'android' && this.modalPwaEvent) {
        this.modalPwaEvent.prompt();
      }
    });
  }
}
