<div class="attach-subscriptions-dialog">
  <div class="header">
    <div class="title">
      {{ 'adminSubscriptions.attachPlans' | translate }}
    </div>
    <app-button
      buttonText="{{ 'adminSubscriptions.savePlans' | translate }}"
      [buttonSize]="buttonSize.MEDIUM"
      [loading]="attachLoading"
      [disabled]="attachLoading"
      (click)="attach()" />
    <i
      class="fa-regular fa-xmark close-icon"
      (click)="onCloseDialog()"></i>
  </div>
  <div class="container">
    <admin-table
      checkBoxSelection="true"
      emptyStateKey="adminSubscriptions.tableEmpty"
      emptyStateIcon="fak fa-lox-attendees"
      scssClass="table-for-attach show-row-border-bottom"
      [lazyLoading]="loadingEntries"
      [loading]="loadingEntries"
      [columns]="columns"
      [entries]="entries"
      [pageSize]="pageSize"
      [totalRecords]="totalRecords"
      [paginator]="false"
      [isShowTotalEntries]="false"
      [(selectedEntries)]="selectedEntries"
      (lazyLoad)="fetchSubscibtions()">
    </admin-table>
  </div>
</div>
