import { Component, OnInit } from '@angular/core';
import { from } from 'rxjs';

import { EventsStore, HubsStore } from 'src/app/core/stores';
import { StagesService, EventMenuService } from 'src/app/core/services';
import { IStage } from '../../models';
import { AppStore } from '../../../app.store';
import { useDevice } from '../../utils/utils';

@Component({
  selector: 'mobile-event-navigation',
  templateUrl: './mobile-event-navigation-footer.component.html',
  styleUrls: ['./mobile-event-navigation-footer.component.scss'],
})
export class MobileEventNavigationFooterComponent implements OnInit {
  public mainStage: IStage;
  public nonMainStages: IStage[];
  public hideNonMainStages: boolean = false;
  public hideProgram: boolean = false;
  public hideNavigation: boolean = false;
  public hideMainStage: boolean = false;

  deviceInfo = useDevice();

  public get isIosPWA(): boolean {
    const isInStandaloneMode =
      'standalone' in window.navigator && (<any>window.navigator)['standalone'];
    return this.deviceInfo.mobileOS === 'ios' && isInStandaloneMode;
  }

  constructor(
    public appStore: AppStore,
    public eventsStore: EventsStore,
    public hubsStore: HubsStore,
    private stagesService: StagesService,
    private eventMenuService: EventMenuService,
  ) {}

  public ngOnInit(): void {
    from(this.stagesService.getEventStages(this.eventsStore.eventId)).subscribe((stages) => {
      this.nonMainStages = stages.filter((s) => !s.isMainStage);
      this.mainStage = stages.find((s) => s.isMainStage);
    });

    this.eventMenuService.eventMenuItems().subscribe((res) => {
      !res.length ? (this.hideNavigation = true) : (this.hideNavigation = false);
      const allStages = res.filter((menuItem: any) => menuItem.key == 'allStages');
      const program = res.filter((menuItem: any) => menuItem.key == 'program');
      const main = res.filter((menuItem: any) => menuItem.key == 'mainStage');

      if (!allStages.length) {
        this.hideNonMainStages = true;
      } else {
        this.hideNonMainStages = false;
      }

      if (!program.length) {
        this.hideProgram = true;
      } else {
        this.hideProgram = false;
      }

      if (!main.length) {
        this.hideMainStage = true;
      } else {
        this.hideMainStage = false;
      }
    });
  }

  public toggleMenu(): void {
    this.appStore.isSideBarVisible = !this.appStore.isSideBarVisible;
  }
}
