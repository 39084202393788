import { Pipe, PipeTransform } from '@angular/core';

import { parseToMoment } from '../../helpers/date-mapper';

@Pipe({
  name: 'timePipe',
})
export class TimePipe implements PipeTransform {
  transform(value: any, ...args: any[]) {
    if (args.length) {
      return parseToMoment(value).format(args[0]);
    }

    return parseToMoment(value).format('HH:mm');
  }
}
