import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

import { SharedModule } from 'src/app/shared';
import { ICourse } from 'src/app/core/models';
import { CoursesStore, UsersStore } from 'src/app/core/stores';
import { asyncDelay } from 'src/app/core/utils';
import { SaveDiscardActionsComponent, ToastComponent } from 'src/app/standalone/shared';
import { CoursesService, FormService } from 'src/app/core/services';
import { Timestamp } from 'src/app/firebase';

@Component({
  selector: 'app-audience',
  standalone: true,
  imports: [CommonModule, SharedModule, SaveDiscardActionsComponent, ToastComponent],
  templateUrl: './audience.component.html',
  styleUrls: ['./audience.component.scss'],
})
export class AudienceComponent implements OnInit {
  loading = true;
  isUpdating: boolean;
  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private coursesStore: CoursesStore,
    private formService: FormService,
    private usersStore: UsersStore,
    private coursesService: CoursesService,
    private messageService: MessageService,
    private translateService: TranslateService,
  ) {}

  get course(): ICourse {
    return this.coursesStore.adminCourse;
  }

  get canUpdate(): boolean {
    return (
      !this.isUpdating && this.form.valid && this.form.dirty && this.formService.isValueChanged()
    );
  }

  ngOnInit(): void {
    this.createForm();
    this.updateForm();
    this.formService.setForm(this.form);
    this.loading = false;
  }

  onDiscard(): void {
    this.updateForm();
  }

  async onConfirm(): Promise<void> {
    try {
      this.isUpdating = true;
      await asyncDelay(1);
      await this.updateCourse();
      this.formService.setForm(this.form);
    } catch (error) {
      console.error(error);
    }
  }

  private createForm(): void {
    this.form = this.fb.group({
      features: [null],
      requirements: [null],
      targetGroup: [null],
    });
  }

  private updateForm(): void {
    this.form.patchValue({
      ...this.course,
    });
  }

  private async updateCourse(): Promise<void> {
    this.isUpdating = true;
    const updatedCourse: ICourse = {
      ...this.course,
      ...this.form.getRawValue(),
      updatedAt: Timestamp.now(),
      updatedBy: this.usersStore.userId,
    };

    try {
      await this.coursesService.update(this.course.id, updatedCourse);
      this.showToastMessage('success', 'adminCourseSettings.courseSuccessfullyUpdated');
      this.isUpdating = false;
    } catch (error) {
      console.warn(error);
      this.isUpdating = false;
      this.showToastMessage('error', 'application.toasters.error');
    }
  }

  private showToastMessage(severity: 'success' | 'error', detail: string): void {
    this.messageService.add({
      severity,
      summary: this.translateService.instant(severity),
      detail: this.translateService.instant(detail),
      styleClass: 'custom-toast',
    });
  }
}
