<ng-container *ngIf="loading">
  <app-loader></app-loader>
</ng-container>

<div *ngIf="!loading">
  <form [formGroup]="form">
    <!-- Stage name & Details block -->
    <div class="form-block c-mb-8">
      <div class="left-side">
        <h2 class="title">{{ 'adminStage.nameDetailsTitle' | translate }}</h2>
        <p class="description">
          {{ 'adminStage.nameDetailsDescription' | translate }}
        </p>
      </div>
      <div class="right-side">
        <!-- Name input -->
        <div class="form-field-block">
          <app-input-label
            labelType="required"
            for="name-input"
            text="adminStage.nameLabel">
          </app-input-label>
          <input
            class="custom-input-text custom-input-text-required"
            formControlName="name"
            id="name-input"
            pInputText
            type="text"
            placeholder="{{ 'adminStage.nameLabel' | translate }}" />
          <app-input-error-message
            [showMessage]="form.controls.name.touched && form.controls.name.hasError('required')"
            errorMessage="{{ 'adminStage.nameReq' | translate }}">
          </app-input-error-message>
        </div>

        <!-- Stream provider dropdown-->
        <div class="c-ml-8 c-mr-8">
          <app-input-label
            for="stream-provider-input"
            text="adminStage.streamProvider">
          </app-input-label>

          <p-dropdown
            class="custom-dropdown"
            panelStyleClass="custom-dropdown-panel"
            [options]="streamProviders"
            formControlName="streamProvider"
            inputId="stream-provider-input"
            placeholder="{{ 'adminStage.streamProvider' | translate }}">
          </p-dropdown>
        </div>

        <div
          class="c-mb-9"
          [ngSwitch]="streamProvider">
          <div *ngSwitchCase="'Not Selected'"></div>
          <div *ngSwitchCase="'Amazon IVS'">
            @if (stage) {
              <!-- Stream Server -->
              <div class="form-field-block c-mt-4">
                <app-input-label
                  for="stream-server-input"
                  text="adminStage.streamServer">
                </app-input-label>
                <input
                  class="custom-input-text"
                  formControlName="streamServer"
                  id="stream-server-input"
                  pInputText
                  type="text"
                  placeholder="{{ 'adminStage.streamServer' | translate }}" />
                <app-input-error-message
                  [showMessage]="
                    form.controls.name.touched && form.controls.name.hasError('required')
                  "
                  errorMessage="{{ 'adminStage.nameReq' | translate }}">
                </app-input-error-message>
              </div>

              <!-- Stream key -->
              <div class="form-field-block">
                <app-input-label
                  for="stream-key-input"
                  text="adminStage.streamKey">
                </app-input-label>
                <div class="stream-key-input">
                  <input
                    class="custom-input-text custom-input-text-required"
                    formControlName="streamKey"
                    id="stream-key-input"
                    pInputText
                    type="text"
                    placeholder="{{ 'adminStage.streamKey' | translate }}" />
                  @if (form.controls.streamKey.value) {
                    <i
                      class="far fa-copy copy-icon"
                      (click)="copyToClipboard()">
                    </i>
                  }
                </div>
                <p class="input-note">
                  {{ 'adminStage.streamNote' | translate }}
                </p>
              </div>
            }

            <!-- Stream link input -->
            <!-- <div class="form-field-block c-mb-1">
              <app-input-label
                labelType="required"
                for="stream-link-input"
                text="adminStage.streamLink">
              </app-input-label>
              <input
                class="custom-input-text custom-input-text-required"
                formControlName="streamLink"
                id="stream-link-input"
                pInputText
                type="text"
                placeholder="{{ 'adminStage.streamLink' | translate }}">
            </div> -->

            <!-- Enable stream recording -->
            <!-- <div class="form-field-input-switch-block c-mt-9 c-mb-4">
              <p-inputSwitch
                class="custom-input-switch"
                formControlName="enableRecording"
                inputId="enable-recording-input">
              </p-inputSwitch>
              <app-input-label
                labelType="switch-checkbox"
                for="enable-recording-input"
                text="adminStage.enableRecording">
              </app-input-label>
            </div> -->

            <!-- Stream Recording URL -->
            <!-- <div class="form-field-block c-mb-1 c-mt-4">
              <app-input-label
                for="stream-recording-input"
                text="adminStage.streamRecording">
              </app-input-label>
              <input
                class="custom-input-text"
                formControlName="streamRecording"
                id="stream-recording-input"
                pInputText
                type="text"
                placeholder="{{ 'adminStage.streamRecording' | translate }}">
              <p class="input-note">
                {{ 'adminStage.recordingNote' | translate }}
              </p>
            </div> -->

            <!-- Enable stream auto replay -->
            <!-- <div class="form-field-input-switch-block c-mt-9 c-mb-4">
              <p-inputSwitch
                class="custom-input-switch"
                formControlName="enableAutoReplay"
                inputId="enable-replay-input">
              </p-inputSwitch>
              <app-input-label
                labelType="switch-checkbox"
                for="enable-replay-input"
                text="adminStage.enableAutoReplay">
              </app-input-label>
            </div> -->
          </div>

          <div *ngSwitchCase="'Vimeo'">
            <!-- Stream link input -->
            <div class="form-field-block c-mb-9">
              <app-input-label
                for="stream-link-input"
                text="adminStage.streamLink">
              </app-input-label>
              <div class="p-inputgroup custom-input-group">
                <span class="p-inputgroup-addon">Embed-URL</span>
                <input
                  formControlName="streamLink"
                  id="stream-link-input"
                  pInputText
                  type="text"
                  placeholder="{{ 'adminStage.streamLink' | translate }}" />
              </div>
            </div>
          </div>

          <div *ngSwitchCase="'Youtube'">
            <!-- Stream link input -->
            <div class="form-field-block c-mb-9">
              <app-input-label
                for="stream-link-input"
                text="adminStage.streamLink">
              </app-input-label>
              <div class="p-inputgroup custom-input-group">
                <span class="p-inputgroup-addon">Embed-URL</span>
                <input
                  formControlName="streamLink"
                  id="stream-link-input"
                  pInputText
                  type="text"
                  placeholder="{{ 'adminStage.streamLink' | translate }}" />
              </div>
            </div>
          </div>

          <div *ngSwitchCase="'Wistia'">
            <!-- Wistia stream information -->
          </div>
        </div>

        <p-divider styleClass="custom-divider-horizontal"></p-divider>

        <!-- Is main stage input -->
        <div class="form-field-input-switch-block c-mt-9 c-mb-4">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="isMainStage"
            inputId="is-main-stage-input">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="is-main-stage-input"
            text="adminStage.isMainStage">
          </app-input-label>
        </div>

        <!-- Featured input -->
        <div class="form-field-input-switch-block c-mb-4">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="featured"
            inputId="featured-input">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="featured-input"
            text="adminStage.featuredLabel">
          </app-input-label>
        </div>

        <!-- Is chat visible input -->
        <div class="form-field-input-switch-block c-mb-4">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="isChatVisible"
            inputId="is-chat-visible-input">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="is-chat-visible-input"
            text="adminStage.chatLabel">
          </app-input-label>
        </div>

        <!-- Is chat anonymous input -->
        <div class="form-field-input-switch-block c-mb-4">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="isChatAnonymous"
            inputId="is-chat-anonymous-input">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="is-chat-anonymous-input"
            text="adminStage.chatAnonymousLabel">
          </app-input-label>
        </div>

        <!-- Is chat anonymous one2one input -->
        <div
          class="form-field-input-switch-block mb-4"
          *ngIf="form.value.isChatVisible">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="isChatAnonymousOneToOne"
            inputId="is-chat-anonymous-one-to-one-input">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="is-chat-anonymous-one-to-one-input"
            text="adminStage.chatAnonymousOne2OneLabel">
          </app-input-label>
        </div>

        <!-- Is agenda visible input -->
        <div class="form-field-input-switch-block c-mb-4">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="isAgendaVisible"
            inputId="is-agenda-visible-input">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="is-agenda-visible-input"
            text="adminStage.agendaLabel">
          </app-input-label>
        </div>

        <!-- Is hide chat at the end of the day input -->
        <div class="form-field-input-switch-block c-mb-4">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="isHideChatAtTheEndOfTheDay"
            inputId="is-hide-chat-at-the-end-of-the-day-input">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="is-hide-chat-at-the-end-of-the-day-input"
            text="adminStage.hideChatAtTheEndOfTheDayLabel">
          </app-input-label>
        </div>
      </div>
    </div>

    <p-divider styleClass="custom-divider-horizontal"></p-divider>

    <!-- Description & Tags block -->
    <div class="form-block c-my-8">
      <div class="left-side">
        <h2 class="title">{{ 'adminStage.descTagsTitle' | translate }}</h2>
        <p class="description">
          {{ 'adminStage.descTagsDescription' | translate }}
        </p>
      </div>
      <div class="right-side">
        <!-- Description textarea -->
        <div class="form-field-block c-mb-4">
          <app-input-label text="adminStage.description"></app-input-label>
          <p-editor
            styleClass="custom-editor"
            formControlName="description"
            placeholder="{{ 'adminStage.description' | translate }}">
          </p-editor>
        </div>

        <!-- Tags multi select -->
        <div class="form-field-block">
          <app-input-label
            *ngIf="tags?.length"
            text="adminStage.tags">
          </app-input-label>
          <app-tags-multi-list
            [possibleTags]="tags"
            [selectedTags]="form.controls.tags.value"
            [adminTheme]="true"
            (onSelected)="selectTags($event)">
          </app-tags-multi-list>
          <h3 *ngIf="!tags?.length">
            {{ 'adminStage.noTagsAvailable' | translate }}
          </h3>
        </div>
      </div>
    </div>

    <p-divider styleClass="custom-divider-horizontal"></p-divider>

    <!-- Design block -->
    <div class="form-block c-my-8">
      <div class="left-side">
        <h2 class="title">{{ 'adminStage.designTitle' | translate }}</h2>
        <p class="description">
          {{ 'adminStage.designDescription' | translate }}
        </p>
      </div>
      <div class="right-side">
        <!-- Banner image -->
        <div class="form-field-block c-mb-4">
          <app-big-profile-picture-media-form
            titleMediaForm="adminStage.bannerTitle"
            description="adminStage.bannerDesc"
            btnConfirm="upload"
            btnCancel="clear"
            [imgValue]="form.controls.banner.value"
            (changeValue)="setBannerValue($event)">
          </app-big-profile-picture-media-form>
        </div>

        <!-- IVS preview image -->
        @if (form.controls.streamProvider.value === providersOfStream.AMAZON) {
          <div class="form-field-block c-mb-4">
            <app-big-profile-picture-media-form
              titleMediaForm="adminStage.ivsPreviewImageTitle"
              description="adminStage.ivsPreviewImageDesc"
              btnConfirm="upload"
              btnCancel="clear"
              [imgValue]="form.controls.ivsPreviewImage.value"
              (changeValue)="setIvsPreviewImageValue($event)">
            </app-big-profile-picture-media-form>
          </div>
        }
      </div>
    </div>

    <p-divider styleClass="custom-divider-horizontal"></p-divider>

    <!-- Brands and Contacts  -->
    <div class="form-block mt-8 mb-4">
      <div class="left-side">
        <h2 class="title">
          {{ 'adminStage.brandsAndContactsTitle' | translate }}
        </h2>
        <p class="description">
          {{ 'adminStage.brandsAndContactsDescription' | translate }}
        </p>
      </div>
      <div class="right-side">
        <!-- Brand People -->
        <div
          class="form-field-block mb-5"
          *ngIf="shouldShowBrands">
          <app-input-label
            for="moderators-brand-people"
            text="adminStage.selectBrandPeople">
          </app-input-label>
          <p-multiSelect
            class="custom-multi-select"
            [options]="eventBrandPeople"
            formControlName="brandPeople"
            optionLabel="name"
            optionValue="id"
            inputId="moderators-brand-people"
            placeholder="{{ 'adminStage.selectBrandPeoplePlaceholder' | translate }}">
          </p-multiSelect>
        </div>

        <p-divider
          styleClass="custom-divider-horizontal"
          *ngIf="shouldShowBrands"></p-divider>

        <!-- Moderators -->
        <div
          class="form-field-block mb-5"
          [ngClass]="{ 'mt-5': shouldShowBrands }">
          <app-input-label
            for="moderators-input"
            text="adminStage.selectModerators">
          </app-input-label>
          <p-multiSelect
            class="custom-multi-select"
            [options]="eventAttendees"
            formControlName="moderators"
            optionLabel="name"
            optionValue="id"
            inputId="moderators-input"
            placeholder="{{ 'adminStage.selectModeratorsPlaceholder' | translate }}">
          </p-multiSelect>
        </div>

        <p-divider styleClass="custom-divider-horizontal"></p-divider>

        <!-- Speaker -->
        <div class="form-field-block mt-5">
          <app-input-label
            for="speakers-input"
            text="adminStage.selectSpeakers">
          </app-input-label>
          <p-multiSelect
            class="custom-multi-select"
            [options]="eventSpeakers"
            formControlName="speakers"
            optionLabel="name"
            optionValue="id"
            inputId="speakers-input"
            placeholder="{{ 'adminStage.selectSpeakersPlaceholder' | translate }}">
          </p-multiSelect>
        </div>
      </div>
    </div>

    <!-- Brands block -->
    <div
      class="form-block c-mt-8 c-mb-4"
      *ngIf="shouldShowBrands">
      <div class="left-side">
        <h2 class="title">{{ 'adminStage.brandsTitle' | translate }}</h2>
        <p class="description">
          {{ 'adminStage.brandsDescription' | translate }}
        </p>
      </div>
      <div class="right-side">
        <!-- Brands input -->
        <div class="form-field-block">
          <app-input-label
            for="brands-input"
            text="adminStage.selectBrands">
          </app-input-label>
          <p-multiSelect
            class="custom-multi-select"
            [options]="eventBrands"
            optionLabel="name"
            optionValue="brandId"
            formControlName="sponsors"
            inputId="brands-input"
            [maxSelectedLabels]="3"
            [selectedItemsLabel]="selectedBrandLabel"
            placeholder="{{ 'adminStage.selectBrands' | translate }}">
          </p-multiSelect>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="save-discard-actions-container">
  <app-save-discard-actions
    [isShow]="canUpdate"
    [isLoading]="isUpdating"
    (discard)="onDiscard()"
    (confirm)="onConfirm()">
  </app-save-discard-actions>
</div>
