import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { ThemesService } from '../services';
import { HubsStore } from 'src/app/core/stores';

@Injectable({
  providedIn: 'root',
})
export class SystemMaintenanceGuard {
  constructor(
    private router: Router,
    private themeService: ThemesService,
    private hubsStore: HubsStore,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    const generalSystemSettings = await this.themeService.getSystemGeneralSettings();

    if (generalSystemSettings.isMaintenance && !state.url.includes('maintenance')) {
      const hubUrl = route.params.hub;
      const hub = this.hubsStore.hub;

      const url = hubUrl ? `/${hubUrl}/maintenance` : `/maintenance`;
      this.router.navigate([url]);
      return false;
    } else {
      return true;
    }
  }
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canMatch(
    route: Route,
    segments: UrlSegment[],
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canLoad(
    route: Route,
    segments: UrlSegment[],
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
}
