import { Component, Input } from '@angular/core';

@Component({
  selector: 'admin-table-header-navbar',
  templateUrl: './admin-table-header-navbar.component.html',
  styleUrls: ['./admin-table-header-navbar.component.scss'],
})
export class AdminTableHeaderNavbarComponent {
  @Input() title: string = '';
}
