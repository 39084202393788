import { Component, computed, inject, signal, Signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { ChaptersSidebarTabAllContentComponent } from '../chapters-sidebar-tab-all-content/chapters-sidebar-tab-all-content.component';
import { ChaptersSidebarTabLessionsComponent } from '../chapters-sidebar-tab-lessions/chapters-sidebar-tab-lessions.component';
import { ChaptersSidebarTabEventsComponent } from '../chapters-sidebar-tab-events/chapters-sidebar-tab-events.component';
import { CoursesStore, TicketsStore } from 'src/app/core/stores';
import { ICourse, ICourseTicket } from 'src/app/core/models';

enum TabTypes {
  ALL = 'all',
  LESSIONS = 'lessions',
  EVENTS = 'events',
}

@Component({
  selector: 'app-chapters-sidebar-tab',
  standalone: true,
  imports: [
    CommonModule,
    ChaptersSidebarTabAllContentComponent,
    TranslateModule,
    ChaptersSidebarTabLessionsComponent,
    ChaptersSidebarTabEventsComponent,
  ],
  templateUrl: './chapters-sidebar-tab.component.html',
  styleUrls: ['./chapters-sidebar-tab.component.scss'],
})
export class ChaptersSidebarTabComponent {
  tabTypes = signal<typeof TabTypes>(TabTypes);
  activeTab = signal<TabTypes>(TabTypes.ALL);
  isHideEventAssets: Signal<boolean> = computed(() => {
    const ticket: ICourseTicket = this.#ticketsStore.courseTicket();

    return !!ticket && !ticket?.isShowLiveEvents;
  });

  #coursesStore = inject(CoursesStore);
  #ticketsStore = inject(TicketsStore);

  get course(): ICourse {
    return this.#coursesStore.course;
  }

  changeTab(tab: TabTypes): void {
    this.activeTab.set(tab);
  }
}
