import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { IUser } from 'src/app/core/models';
import { UsersStore } from 'src/app/core/stores';
import { SharedModule } from 'src/app/shared';
import { ButtonComponent, ButtonSize, ButtonStyle, ButtonType } from 'src/app/standalone/shared';
import { EventAttendeeSpeakerSidebarComponent } from './event-attendee-speaker-sidebar/event-attendee-speaker-sidebar.component';

@Component({
  selector: 'app-event-attendee-speaker',
  standalone: true,
  imports: [SharedModule, ButtonComponent, EventAttendeeSpeakerSidebarComponent],
  templateUrl: './event-attendee-speaker.component.html',
  styleUrl: './event-attendee-speaker.component.scss',
})
export class EventAttendeeSpeakerComponent {
  buttonType = ButtonType;
  buttonSize = ButtonSize;
  buttonStyle = ButtonStyle;

  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private usersStore = inject(UsersStore);

  get user(): IUser {
    return this.usersStore.adminUser;
  }

  onNavigateToSpeakersOrAttendeesList(): void {
    this.router.navigate([`../`], { relativeTo: this.route });
  }
}
