<div
  *ngIf="!isMobile"
  [pTooltip]="tooltipText"
  [tooltipPosition]="tooltipPosition"
  [tooltipStyleClass]="tooltipStyleClass">
  <img
    src="../../../../assets/images/info.svg"
    alt="" />
</div>
<div
  *ngIf="isMobile"
  [pTooltip]="tooltipText"
  [tooltipPosition]="tooltipPosition"
  [tooltipStyleClass]="tooltipStyleClass"
  [tooltipEvent]="'focus'"
  [tabindex]="0">
  <img
    src="../../../../assets/images/info.svg"
    alt="" />
</div>
