<ng-container *ngIf="loading">
  <app-loader></app-loader>
</ng-container>

<div
  *ngIf="!loading"
  class="admin-user-container">
  <div class="admin-user-inner-container">
    <div class="header-container">
      <div class="title">
        {{ userStore.adminUser?.firstName }} {{ userStore.adminUser?.lastName }}
      </div>
    </div>

    <div class="navigation-container">
      <p-menubar
        [model]="menuItems"
        styleClass="user-menubar"></p-menubar>
    </div>

    <div class="child-container">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
