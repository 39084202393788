import * as dayjs from 'dayjs';

export const combineDateAndTime = (date: Date, time: Date): Date => {
  const dayjsDate = dayjs(date);
  const dayjsTime = dayjs(time);
  const combinedDateTime = dayjsDate
    .hour(dayjsTime.hour())
    .minute(dayjsTime.minute())
    .second(dayjsTime.second())
    .millisecond(dayjsTime.millisecond());

  return combinedDateTime.toDate();
};
