import { Component, OnDestroy, OnInit } from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { from, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { saveAs } from 'file-saver';

import { goToLink } from 'src/app/core/utils/utils';
import { IBrand, IBrandProduct, IEvent } from 'src/app/core/models';
import {
  BookmarkService,
  BrandsDetailsService,
  BrandsService,
  ThemesService,
  StorageService,
} from 'src/app/core/services';
import { EventsStore, ProductsStore, TagsStore, HubsStore } from 'src/app/core/stores';
import { BookmarkableCollection } from 'src/app/core/enums';

@Component({
  selector: 'app-product-dialog',
  templateUrl: './product-dialog.component.html',
  styleUrls: ['./product-dialog.component.scss'],
})
export class ProductDialogComponent implements OnInit, OnDestroy {
  loading = true;
  showBookmarkSpinner = true;
  showBookmarkContent: boolean = true;
  brand: IBrand = null;
  product: IBrandProduct;
  currentEvent: IEvent;
  safeVideoUrl: SafeResourceUrl;
  color;
  goToLink = goToLink;

  private readonly bookmarkableCollection: BookmarkableCollection = BookmarkableCollection.Products;
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private brandsService: BrandsService,
    private bookmarkService: BookmarkService,
    private productsStore: ProductsStore,
    private eventsStore: EventsStore,
    public tagsStore: TagsStore,
    public config: DynamicDialogConfig,
    private sanitizer: DomSanitizer,
    private brandsDetailsService: BrandsDetailsService,
    public themesService: ThemesService,
    public storageService: StorageService,
    public hubsStore: HubsStore,
  ) {
    this.product = this.config.data.product;

    if (this.eventsStore.event) {
      this.showBookmarkContent = this.eventsStore.isEventRegistered;
    } else {
      this.showBookmarkContent = this.config.data.showBookmarkContent;
    }
  }

  async ngOnInit(): Promise<void> {
    this.currentEvent = this.eventsStore.event;

    if (!this.currentEvent) {
      if (this.hubsStore.hub) {
        this.color = this.hubsStore.hub.primaryColor;
      } else {
        this.color = this.themesService.activeTheme.properties['--appPrimaryColor'];
      }
    } else {
      this.color = this.currentEvent.accentColor;
    }

    this.brand = await this.brandsService.getOne(this.product.brandId);

    if (this.showBookmarkContent) {
      from(this.bookmarkService.fetchIds(this.bookmarkableCollection))
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(() => (this.showBookmarkSpinner = false));
    }

    this.safeVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.product.videoLink);
    this.loading = false;
  }

  async bookmark(): Promise<void> {
    this.showBookmarkSpinner = true;

    if (this.productsStore.bookmarkedIds.includes(this.product.id)) {
      const result: boolean = await this.bookmarkService.removeBookmark(
        this.product.id,
        this.bookmarkableCollection,
      );
      if (result) {
        this.showBookmarkSpinner = false;
      }
    } else {
      const result: boolean = await this.bookmarkService.bookmark(
        this.product.id,
        this.bookmarkableCollection,
      );
      if (result) {
        this.showBookmarkSpinner = false;
      }
    }
  }

  getBookmarkedIconClass(): string {
    if (this.showBookmarkSpinner) {
      return 'far fa-circle-o-notch fa-spin';
    } else {
      return this.productsStore.bookmarkedIds.includes(this.product?.id)
        ? 'fak fa-lox-bookmark-1 active'
        : 'fak fa-lox-bookmark-1';
    }
  }

  async downloadFile(): Promise<void> {
    const url = String(this.product.file);

    try {
      const decodedPath = this.getBucketFileChildPath(url);
      const data = await this.storageService.getChildFileFromStorage(decodedPath);
      const file = data.file;
      const downloadURL = data.downloadURL;

      const mime = require('mime');
      let fileExt = 'pdf'; // default ext

      if (file.contentType && file.contentType != 'application/octet-stream') {
        fileExt = mime.getExtension(file.contentType);
      }

      if (downloadURL) {
        saveAs(downloadURL, file.name + '.' + fileExt);
      } else {
        saveAs(url);
      }
    } catch (e) {
      console.error(e);
      saveAs(url);
    }
  }

  openBrand(): void {
    this.brandsDetailsService.showBrandDetailsDialog(this.brand.id);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getBucketFileChildPath(url) {
    const parts = url.split('/');
    const childPath = parts[parts.length - 1].split('?')[0];

    console.log('getBucketFileChildPath url', url);
    console.log('parts', parts);
    console.log('childPath', childPath);

    return decodeURIComponent(childPath);
  }
}
