import * as moment from 'moment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';

import { environment } from 'src/environments/environment';
import { parseToMoment, CollectionTypes, IUserType } from 'src/app/shared';
import { UsersStore, AttendeesStore, SpeakersStore } from 'src/app/core/stores';
import { AlgoliaSearchResult, IUser, IUserEvent, ITicket, IUserHub } from 'src/app/core/models';
import { Firestore, IFirestore } from 'src/app/firebase';
import { UsersService } from '../users/users.service';
import { AlgoliaService } from './../algolia/algolia.service';
import { AuthorizationService } from '../auth';
import { AppStore } from '../../../app.store';
import { mapRoleToUserType } from 'src/app/core/utils';

@Injectable({
  providedIn: 'root',
})
export class AttendeesService {
  private firestore: IFirestore;

  constructor(
    private attendeesStore: AttendeesStore,
    private usersService: UsersService,
    private userStore: UsersStore,
    private speakersStore: SpeakersStore,
    private algoliaService: AlgoliaService,
    private http: HttpClient,
    private authorizationService: AuthorizationService,
    public appStore: AppStore,
  ) {
    this.firestore = Firestore();
  }

  async fetchUnattached(
    hubId: string,
    eventId: string,
    pageIndex: number,
    pageSize: number,
    searchTerm: string,
  ): Promise<AlgoliaSearchResult<IUser>> {
    const attachedUsers = await this.getAllEventAttendees(eventId, true);
    const facetFilters = attachedUsers.map((id) => `userId:-${id}`).join(',');
    const resultFromAlgolia: AlgoliaSearchResult<IUserHub> =
      await this.algoliaService.search<IUserHub>(
        CollectionTypes.USER_HUBS,
        `${searchTerm} ${hubId}`,
        pageSize,
        pageIndex * pageSize,
        '',
        facetFilters,
      );

    const result: AlgoliaSearchResult<IUser> = {
      total: resultFromAlgolia.total,
      results: resultFromAlgolia.results.map((item: any) => item.user),
    };

    if (this.appStore.generalSystemSettings.enableEncryption) {
      console.log('ALGOLIA attendees fetchUnattached res.results ', result.results);
      result.results = result.results.map((b) => {
        console.log('res', b);
        return this.usersService.decryptUserData(b, ['_highlightResult', 'objectID']) as IUser;
      });
      console.log('↑ ALGOLIA attendees fetchUnattached decrypted');
    }
    return result;
  }

  public async fetch(
    indexName: string = 'userEvents',
    eventId: string = null,
    pageIndex: number,
    pageSize: number,
    searchTerm: string,
  ): Promise<AlgoliaSearchResult<IUser>> {
    const res = await this.algoliaService.search<{
      role: string;
      status?: 'active' | 'inactive' | 'pending';
      createdAt: any;
      user: IUser;
    }>(indexName, `${eventId} attendee ${searchTerm}`, pageSize, pageIndex * pageSize);

    return {
      total: res.total,
      results: res.results.map((r) => ({
        status: r?.status ?? 'inactive',
        timeStamp: moment(r.createdAt).format('ll'),
        role: r.role,
        ...r.user,
      })),
    };
  }

  public async getAllAttendeesAPI(eventId: string): Promise<IUser[]> {
    const headers = await this.authorizationService.buildHeaders();
    return this.http
      .get<IUser[]>(`${environment.apiUrl}events/${eventId}/attendees/all`, { headers })
      .toPromise();
  }

  public async getAllAttendees(eventId: string): Promise<IUser[]> {
    try {
      const users: IUser[] = [];
      const tickets: ITicket[] = [];
      const eventAttendeesQuery = await this.firestore
        .collection(CollectionTypes.USER_EVENTS)
        .where('eventId', '==', eventId)
        .where('role', '==', 'attendee')
        .where('isBrandPerson', '==', false)
        .orderBy('_firstName_')
        .orderBy('_lastName_')
        .get();

      const promises = [];
      const attendeeIds = [];
      const attendeeObjects = {};

      eventAttendeesQuery.docs.map(async (eventAttendeeDoc) => {
        const eventAttendee = eventAttendeeDoc.data();
        attendeeIds.push(eventAttendee.userId);
        attendeeObjects[eventAttendee.userId] = eventAttendee;
      });

      promises.push(
        this.firestore
          .collection(`${CollectionTypes.EVENTS}/${eventId}/${CollectionTypes.TICKETS}`)
          .get(),
      );

      while (attendeeIds.length) {
        const chunk = attendeeIds.splice(0, 10);
        promises.push(
          this.firestore
            .collection(CollectionTypes.USERS)
            .where('id', 'in', chunk)
            .orderBy('_firstName_')
            .orderBy('_lastName_')
            .get(),
        );
      }

      const [queryAllTickets, ...queryAllUsers] = await Promise.all(promises);

      queryAllTickets.docs.map((doc) => {
        tickets.push(doc.data());
      });

      queryAllUsers.map((queryUsers) => {
        queryUsers.docs.map((doc) => {
          let ticket;
          if (attendeeObjects[doc.id]['ticketId']) {
            ticket = tickets.filter(
              (ticket: ITicket) => ticket.id == attendeeObjects[doc.id]['ticketId'],
            )[0];
          }

          let user = doc.data() as IUser;
          if (this.appStore.generalSystemSettings.enableEncryption) {
            user = this.usersService.decryptUserData(user) as IUser;
            console.log('↑ getAllAttendees user decrypted');
          }

          users.push(
            Object.assign(user, {
              eventStatus: attendeeObjects[doc.id]?.status,
              checkedIn: attendeeObjects[doc.id]?.checkedIn,
              eventTimeStamp: parseToMoment(attendeeObjects[doc.id].createdAt).format('ll'),
              eventRole: attendeeObjects[doc.id].role,
              ticket,
            }),
          );
        });
      });

      return users;
    } catch (error) {
      console.warn(error);
      return [];
    }
  }
  public async getAllPaginated(
    eventId: string = null,
    order: 'asc' | 'desc',
    entry?: IUser,
    pageSize: number = 12,
  ): Promise<IUser[]> {
    const userTypeMap: { [key: string]: IUserType } = {};

    (
      await this.firestore
        .collection(CollectionTypes.USER_EVENTS)
        .where('eventId', '==', eventId)
        .where('role', 'in', ['speaker', 'attendee'])
        .orderBy('_firstName_', order)
        .orderBy('_lastName_', order)
        .get()
    ).forEach((doc) => {
      const userEvent = doc.data() as IUserEvent;

      if (!userTypeMap[userEvent.userId]) {
        userTypeMap[userEvent.userId] = mapRoleToUserType(userEvent.role);
      }
    });

    const attendeeIds = Object.keys(userTypeMap);
    const startFromIndex = entry ? attendeeIds.indexOf(entry.id) + 1 : 0;
    const attendeeIdsToQuery = attendeeIds.splice(startFromIndex, pageSize);

    const attendees: IUser[] = [];

    while (attendeeIdsToQuery.length) {
      const chunk = attendeeIdsToQuery.splice(0, 10);

      const queryUsers = await this.firestore
        .collection(CollectionTypes.USERS)
        .where('id', 'in', chunk)
        .orderBy('_firstName_', order)
        .orderBy('_lastName_', order)
        .get();

      queryUsers.docs.map((doc) => {
        let user = doc.data() as IUser;

        if (this.appStore.generalSystemSettings.enableEncryption) {
          user = this.usersService.decryptUserData(user) as IUser;
          console.log('↑ getAllPaginated user decrypted');
        }

        attendees.push(Object.assign(user, { type: userTypeMap[doc.id] }));
      });
    }

    // attendees.sort((a, b) => sortByFirstAndLastName(a, b, order));
    this.cache(attendees);

    return attendees;
  }

  public async getAllByTagIds(
    eventId: string,
    tagIds: string[],
    order: 'asc' | 'desc',
    entry?: IUser,
    pageSize: number = 12,
  ): Promise<IUser[]> {
    const attendees: IUser[] = [];
    const userTypeMap: { [key: string]: IUserType } = {};
    const userEvents: IUserEvent[] = [];
    // will break navbar tags without it - those ids are mobx observables from eventStore;
    const tagsIds = [...tagIds];

    while (tagsIds.length) {
      const chunk = tagsIds.splice(0, 10);

      const speakersQuery = await this.firestore
        .collection(CollectionTypes.USER_EVENTS)
        .where('tags', 'array-contains-any', chunk)
        .where('eventId', '==', eventId)
        .where('role', '==', 'speaker')
        .get();

      userEvents.push(...speakersQuery.docs.map((doc) => doc.data() as IUserEvent));

      const userAttendeesQuery = await this.firestore
        .collection(CollectionTypes.USER_EVENTS)
        .where('tags', 'array-contains-any', chunk)
        .where('eventId', '==', eventId)
        .where('role', '==', 'attendee')
        .get();

      userEvents.push(...userAttendeesQuery.docs.map((doc) => doc.data() as IUserEvent));
    }

    // userEvents.sort((a, b) => sortByFirstAndLastName(a, b, order));

    let attendeeIds = userEvents
      .map((ue) => ue.userId)
      .filter((value, index, self) => self.indexOf(value) === index);

    const startAfterIndex = entry ? attendeeIds.indexOf(entry.id) + 1 : 0;
    attendeeIds = attendeeIds.splice(startAfterIndex, pageSize);

    for (const attendeeId of attendeeIds) {
      userTypeMap[attendeeId] = mapRoleToUserType(
        userEvents.find((ue) => ue.userId === attendeeId).role,
      );
    }

    let queryUsersByTagIds = null;

    while (attendeeIds.length) {
      const chunk = attendeeIds.splice(0, 10);

      queryUsersByTagIds = await this.firestore
        .collection(CollectionTypes.USERS)
        .where('id', 'in', chunk)
        .get();

      queryUsersByTagIds.docs.map((doc) => {
        let user = doc.data() as IUser;

        if (this.appStore.generalSystemSettings.enableEncryption) {
          user = this.usersService.decryptUserData(user) as IUser;
          console.log('↑ getAllByTagIds user decrypted');
        }

        attendees.push(Object.assign(user, { type: userTypeMap[doc.id] }));
      });
    }

    // attendees.sort((a, b) => sortByFirstAndLastName(a, b, order));
    this.cache(attendees);

    return attendees;
  }

  public async getBookmarked(
    eventId: string,
    order: 'asc' | 'desc',
    entry?: IUser,
    pageSize: number = 12,
  ): Promise<IUser[]> {
    const userEventId = this.userStore.userEventsMap[eventId].id;
    let attendees: IUser[] = [];
    let attendeeIds: string[] = [];
    const userTypeMap: { [key: string]: IUserType } = {};

    const bookmarkedAttendeesQuery = await this.firestore
      .collection(`${CollectionTypes.USER_EVENTS}/${userEventId}/bookmarkedAttendees`)
      .get();
    bookmarkedAttendeesQuery.forEach((doc) => {
      attendeeIds.push(doc.id);
      userTypeMap[doc.id] = IUserType.Attendee;
    });

    const bookmarkedSpeakersQuery = await this.firestore
      .collection(`${CollectionTypes.USER_EVENTS}/${userEventId}/bookmarkedSpeakers`)
      .get();
    bookmarkedSpeakersQuery.forEach((doc) => {
      attendeeIds.push(doc.id);
      userTypeMap[doc.id] = IUserType.Speaker;
    });

    attendeeIds = attendeeIds.filter((value, index, self) => self.indexOf(value) === index);
    let queryUsersByIds = null;

    while (attendeeIds.length > 0) {
      const chunk = attendeeIds.splice(0, 10);

      queryUsersByIds = await this.firestore
        .collection(CollectionTypes.USERS)
        .where('id', 'in', chunk)
        .get();

      queryUsersByIds.docs.map((doc) => {
        let user = doc.data() as IUser;

        if (this.appStore.generalSystemSettings.enableEncryption) {
          user = this.usersService.decryptUserData(user) as IUser;
          console.log('↑ getBookmarked user decrypted');
        }
        Object.assign(user, { type: userTypeMap[doc.id] });
        attendees.push(user);
      });
    }

    // attendees.sort((a, b) => sortByFirstAndLastName(a, b, order));
    const startFromIndex = entry ? attendees.map((u) => u.id).indexOf(entry.id) + 1 : 0;
    attendees = attendees.splice(startFromIndex, pageSize);
    this.cache(attendees);

    return attendees;
  }

  public async getSimilar(eventId: string, attendeeId: string): Promise<IUser[]> {
    const attendees = [];
    let attendeesIds = [];
    const tagsPayload = (await this.usersService.getUserEvent(eventId, attendeeId)).tags;

    if (tagsPayload.length) {
      while (tagsPayload.length) {
        const chunk = tagsPayload.splice(0, 10);

        const userAttendeesQuery = await this.firestore
          .collection(CollectionTypes.USER_EVENTS)
          .where('tags', 'array-contains-any', chunk)
          .where('eventId', '==', eventId)
          .orderBy('_firstName_')
          .orderBy('_lastName_')
          .get();

        if (!userAttendeesQuery.empty) {
          userAttendeesQuery.forEach((doc) => {
            const userId = doc.data().userId;
            if (userId) {
              if (!attendeesIds.includes(userId)) {
                attendeesIds.push(doc.data().userId);
              }
            }
          });
        }
      }

      if (attendeesIds.length) {
        let queryUsersByTagIds = null;

        while (attendeesIds.length) {
          const chunk = attendeesIds.splice(0, 10);

          queryUsersByTagIds = this.firestore
            .collection(CollectionTypes.USERS)
            .where('id', 'in', chunk)
            .orderBy('_firstName_')
            .orderBy('_lastName_');

          queryUsersByTagIds = await queryUsersByTagIds.limit(6).get();

          if (!queryUsersByTagIds.empty) {
            queryUsersByTagIds.forEach((doc) => {
              let data = doc.data();

              if (this.appStore.generalSystemSettings.enableEncryption) {
                data = this.usersService.decryptUserData(data) as IUser;
                console.log('↑ getSimilar user decrypted');
              }
              if (attendees.length < 6) {
                attendees.push(data);
              }
            });
          }

          if (attendees.length === 6) {
            attendeesIds = [];
          }
        }
      }
    }

    this.attendeesStore.setAttendees(attendees);

    return attendees;
  }

  public async getOne(
    attendeeId: string,
    eventId: string,
    forceSync: boolean = false,
  ): Promise<IUser> {
    if (!forceSync && Object.keys(this.attendeesStore.attendeesMap).includes(attendeeId)) {
      return this.attendeesStore.attendeesMap[attendeeId];
    }

    try {
      let attendee = (
        await this.firestore.doc(`${CollectionTypes.USERS}/${attendeeId}`).get()
      ).data() as IUser;
      if (this.appStore.generalSystemSettings.enableEncryption) {
        attendee = this.usersService.decryptUserData(attendee) as IUser;
        console.log('↑ attendees getOne decrypted');
      }
      this.attendeesStore.setAttendees([attendee]);
      return attendee;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  public async remove(eventId: string, id: string): Promise<boolean> {
    return true;
  }

  public async getAllEventAttendees(
    eventId: string,
    returnOnlyIds: boolean = false,
  ): Promise<IUser[] | string[]> {
    const eventAttendeesQueary = await this.firestore
      .collection('userEvents')
      .where('eventId', '==', eventId)
      .where('role', '==', 'attendee')
      .get();
    const eventAttendees = [];

    if (!eventAttendeesQueary.empty) {
      eventAttendeesQueary.forEach((doc) => {
        let attendee = doc.data();
        if (returnOnlyIds) {
          eventAttendees.push(attendee.userId);
        } else {
          if (this.appStore.generalSystemSettings.enableEncryption) {
            attendee = this.usersService.decryptUserData(attendee) as IUser;
            console.log('↑ attendees getAllEventAttendees decrypted');
          }
          eventAttendees.push(attendee);
        }
      });
    }

    return eventAttendees;
  }

  public async exportAttendees(
    eventId: string,
    eventTitle: string,
    lang: string,
    userFields: Array<any>,
    userEventFields: Array<any>,
    consents: Array<any>,
  ): Promise<void> {
    try {
      const responseType = 'blob';
      const headers = await this.authorizationService.buildHeaders();
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      await this.http
        .post(
          `${environment.apiUrl}events/${eventId}/attendees/export`,
          { lang, userFields, userEventFields, consents, timezone },
          { headers, responseType },
        )
        .toPromise()
        .then((blob) => {
          const date = new Date();
          const reportName =
            eventTitle +
            '-' +
            date.getUTCDate().toString() +
            '_' +
            (date.getMonth() + 1) +
            '_' +
            date.getFullYear() +
            '.xlsx';
          saveAs(blob, reportName);
        });
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  private cache(users: IUser[]): void {
    this.attendeesStore.setAttendees(users.filter((u) => u.type === IUserType.Attendee));
    this.speakersStore.setSpeakers(users.filter((u) => u.type === IUserType.Speaker));
  }
}
