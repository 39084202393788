import * as moment from 'moment';
import { Component, computed, inject, OnDestroy, OnInit, Signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable, Subject, filter, merge, switchMap, take, takeUntil, tap } from 'rxjs';
import { AccordionModule } from 'primeng/accordion';

import { IAsset, IChapter, ICourseTicket } from 'src/app/core/models';
import { RegisteredCourseStore, TicketsStore } from 'src/app/core/stores';
import { SharedModule, parseToMoment } from 'src/app/shared';
import { ChapterAssetCardComponent } from '../chapter-asset-card/chapter-asset-card.component';
import { AssetType } from 'src/app/core/enums';

@Component({
  selector: 'app-chapters-sidebar-tab-all-content',
  standalone: true,
  imports: [CommonModule, AccordionModule, ChapterAssetCardComponent, SharedModule],
  templateUrl: './chapters-sidebar-tab-all-content.component.html',
  styleUrls: ['./chapters-sidebar-tab-all-content.component.scss'],
})
export class ChaptersSidebarTabAllContentComponent implements OnInit, OnDestroy {
  chapters: IChapter[];
  activeState: boolean[] = [];
  activeAsset: IAsset;

  #isHideEventAssets: Signal<boolean> = computed(() => {
    const ticket: ICourseTicket = this.#ticketsStore.courseTicket();

    return !!ticket && !ticket?.isShowLiveEvents;
  });
  #allAssetsEventsFromChapters: IAsset[];
  #unsubscribe$ = new Subject<void>();
  #ticketsStore = inject(TicketsStore);
  #registeredCourseStore = inject(RegisteredCourseStore);

  get isLiveAssetEvent(): IAsset {
    const now = moment();
    const liveAssetEvent = this.#allAssetsEventsFromChapters?.find((asset: IAsset) => {
      const startTime = moment(parseToMoment(asset.eventAssetStartDate));
      const endTime = moment(parseToMoment(asset.eventAssetEndDate));
      return now.isBetween(startTime, endTime);
    });

    return liveAssetEvent ?? null;
  }

  get isUpcomingAssetEvent(): IAsset {
    const today = moment();
    const endDate = moment().add(30, 'days');
    const upcomingAssetEvent = this.#allAssetsEventsFromChapters?.find((asset: IAsset) => {
      const assetEventDate = moment(parseToMoment(asset.eventAssetStartDate));
      return assetEventDate.isBetween(today, endDate);
    });

    return upcomingAssetEvent ?? null;
  }

  get stylesClassForAccordion(): string {
    return window.screen.width > 904 ? 'custom-accordion' : 'registered-course-accordion';
  }

  ngOnInit(): void {
    let courseChapters$: Observable<IChapter[]>;
    if (this.#isHideEventAssets()) {
      courseChapters$ = this.#registeredCourseStore.courseChaptersWithoutAssetEvents.pipe(
        tap((courseChapters: IChapter[]) => {
          this.chapters = courseChapters;
        }),
      );
    } else {
      courseChapters$ = this.#registeredCourseStore.courseChapters.pipe(
        tap((courseChapters: IChapter[]) => {
          this.chapters = courseChapters;
          if (!!courseChapters) {
            this.#allAssetsEventsFromChapters = courseChapters
              .map((courseChapter: IChapter) => courseChapter.assetsFullModel)
              .flat(1)
              .filter((asset: IAsset) => asset.type === AssetType.EVENT)
              .sort(
                (first: IAsset, second: IAsset) =>
                  first.eventAssetStartDate.toMillis() - second.eventAssetStartDate.toMillis(),
              );
          }
        }),
      );
    }

    const activeAsset$ = courseChapters$.pipe(
      filter((chapters: IChapter[]) => !!chapters),
      take(1),
      switchMap(() => {
        return this.#registeredCourseStore.activeAsset;
      }),
      tap((asset: IAsset) => {
        this.activeAsset = asset;
        this.setActiveState();
      }),
    );

    merge(courseChapters$, activeAsset$).pipe(takeUntil(this.#unsubscribe$)).subscribe();
  }

  async onChangeActiveAsset(asset: IAsset): Promise<void> {
    if (this.activeAsset.id === asset.id) {
      return;
    }
    this.#registeredCourseStore.setActiveAsset(asset);
  }

  ngOnDestroy(): void {
    this.#unsubscribe$.next();
    this.#unsubscribe$.complete();
  }

  private setActiveState(): void {
    if (!this.activeAsset) {
      this.activeState = new Array(this.chapters.length).fill(false).splice(0, 1, true);
    } else {
      const findIndexOfCurrentCourseTracking = this.chapters.findIndex((chapter: IChapter) =>
        chapter.assets.includes(this.activeAsset.id),
      );
      this.activeState = new Array(this.chapters.length)
        .fill(false)
        .map((item: boolean, index: number) => index === findIndexOfCurrentCourseTracking);
    }
  }
}
