import { HubsStore } from './../../core/stores/hubs/hubs.store';
import { HubsService } from './../../core/services/hubs/hubs.service';
import { UsersStore } from './../../core/stores/users/users.store';
import { Injectable } from '@angular/core';
import {
  Route,
  UrlSegment,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { UsersService } from 'src/app/core/services';

@Injectable({
  providedIn: 'root',
})
export class SystemAdminPanelGuard {
  constructor(
    private router: Router,
    private usersStore: UsersStore,
    private hubsStore: HubsStore,
    private hubsService: HubsService,
    private usersService: UsersService,
  ) {}

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    const user = await this.usersService.getOne(this.usersStore.user.id);

    if (next.params.hub) {
      return this.router.navigate([`${next.params.hub}/admin`]);
    } else if (
      user?.role === 'superAdmin' ||
      user?.role === 'internalAdmin' ||
      user?.role === 'instanceOwner' ||
      user?.role === 'softwareDeveloper'
    ) {
      const hubs = await this.hubsService.getAll();
      this.hubsStore.setHubs(hubs);
      return true;
    } else {
      return this.router.navigate(['home']);
    }
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }
}
