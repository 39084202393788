<div
  class="bell-container"
  (appClickOutside)="onCloseNotifications()">
  <i
    *ngIf="(totalUnreadNotifications$ | async) === 0"
    class="far fa-bell"
    (click)="onToggleDropdown()">
  </i>
  <i
    *ngIf="(totalUnreadNotifications$ | async) > 0"
    class="far fa-bell p-overlay-badge"
    (click)="onToggleDropdown()">
    <p-badge
      styleClass="custom-badge"
      [value]="totalUnreadNotifications$ | async">
    </p-badge>
  </i>

  <div
    *ngIf="expandDropdown"
    class="notifications-container"
    [@notifications]>
    <app-notifications></app-notifications>
  </div>
</div>
