<ng-container *ngIf="loading">
  <app-loader></app-loader>
</ng-container>

<div
  *ngIf="!loading"
  class="admin-coupon-form-container">
  <form [formGroup]="couponForm">
    <!-- Coupon name & code block -->
    <div class="form-block c-mt-8 c-mb-9">
      <div class="left-side">
        <h2 class="title">{{ 'adminCourseCouponForm.basicInfoTitle' | translate }}</h2>
        <p class="description">{{ 'adminCourseCouponForm.basicInfoDescription' | translate }}</p>
      </div>
      <div class="right-side">
        <!-- Name input -->
        <div class="form-field-block">
          <app-input-label
            labelType="required"
            for="name-input"
            text="adminCourseCouponForm.nameLabel">
          </app-input-label>
          <input
            class="custom-input-text custom-input-text-required"
            formControlName="name"
            id="name-input"
            pInputText
            type="text"
            placeholder="{{ 'adminCourseCouponForm.namePlaceholder' | translate }}" />
          <p class="input-description">
            {{ 'adminCourseCouponForm.nameInputDescription' | translate }}
          </p>
          <app-input-error-message
            [showMessage]="couponForm.controls.name.invalid && couponForm.controls.name.touched"
            errorMessage="{{ 'application.forms.required' | translate }}">
          </app-input-error-message>
        </div>

        <!-- Code Input -->
        <div class="form-field-block">
          <app-input-label
            labelType="required"
            for="code-input"
            text="adminCourseCouponForm.codeLabel">
          </app-input-label>
          <input
            class="custom-input-text"
            formControlName="code"
            id="code-input"
            pInputText
            type="text"
            placeholder="{{ 'adminCourseCouponForm.codePlaceholder' | translate }}" />
          <p class="input-description">
            {{ 'adminCourseCouponForm.codeInputDescription' | translate }}
          </p>
          <app-input-error-message
            [showMessage]="couponForm.controls.code.invalid && couponForm.controls.code.touched"
            errorMessage="{{ 'application.forms.required' | translate }}">
          </app-input-error-message>
        </div>
      </div>
    </div>

    <p-divider styleClass="custom-divider-horizontal"></p-divider>

    <!-- Coupon type block -->
    <div class="form-block c-my-9">
      <div class="left-side">
        <h2 class="title">{{ 'adminCourseCouponForm.typeTitle' | translate }}</h2>
        <p class="description">{{ 'adminCourseCouponForm.typeDescription' | translate }}</p>
      </div>
      <div class="right-side">
        <!-- Type radio buttons -->
        <div class="form-field-block">
          <p class="input-header-description">{{ 'adminCourseCouponForm.type' | translate }}</p>
          <div class="form-field-input-radio-block c-mb-4 c-mt-3 c-p-0">
            <p-radioButton
              class="custom-input-radio"
              [value]="couponDiscountTypes.PercentageDiscount"
              label="{{ 'adminCourseCouponForm.percentageLabel' | translate }}"
              formControlName="type"
              inputId="percentageId">
            </p-radioButton>
          </div>
          <div>
            <p-radioButton
              class="custom-input-radio"
              [value]="couponDiscountTypes.FixedAmountDiscount"
              label="{{ 'adminCourseCouponForm.fixedLabel' | translate }}"
              formControlName="type"
              inputId="fixedId">
            </p-radioButton>
          </div>
        </div>
        <!-- Percentage Input -->
        <div
          *ngIf="couponForm.get('type').value === couponDiscountTypes.PercentageDiscount"
          class="form-field-block c-mt-4">
          <app-input-label
            labelType="required"
            for="percentage-input"
            text="adminCourseCouponForm.percentageOff">
          </app-input-label>
          <div class="p-input-icon-right">
            <input
              class="custom-input-text custom-input-text-required"
              formControlName="discountPercentage"
              id="discount-percentage-input"
              pInputText
              type="text" />
            <i class="fa fa-percent"></i>
          </div>
          <app-input-error-message
            [showMessage]="couponForm.controls.name.invalid && couponForm.controls.name.touched"
            errorMessage="{{ 'application.forms.required' | translate }}">
          </app-input-error-message>
        </div>
        <!-- Fixed amount -->
        <div
          *ngIf="couponForm.get('type').value === couponDiscountTypes.FixedAmountDiscount"
          class="form-field-block c-mt-4">
          <!-- Discount amount input -->
          <app-input-label
            labelType="required"
            for="discount-amount-input"
            text="adminCourseCouponForm.discountAmount">
          </app-input-label>
          <div class="p-input-icon-right">
            <input
              class="custom-input-text"
              [class.custom-input-text-required]="hasValidator('discountAmount')"
              formControlName="discountAmount"
              id="discount-amount-input"
              pInputText
              type="number"
              placeholder="{{ 'adminCourseCouponForm.discountAmountPlaceholder' | translate }}" />
            <i
              class="fa-solid"
              [ngClass]="{
                'fa-euro-sign': courseCurrency === 'EUR',
                'fa-dollar-sign': courseCurrency === 'USD',
                'fa-chf-sign': courseCurrency === 'CHF',
              }"></i>
          </div>
          <app-input-error-message
            [showMessage]="
              couponForm.controls.discountAmount.invalid &&
              couponForm.controls.discountAmount.touched
            "
            [errorMessage]="
              couponForm.controls.discountAmount.hasError('min')
                ? ('adminCourseTicketForm.minAmountErrorMessage' | translate)
                : ('application.forms.required' | translate)
            ">
          </app-input-error-message>
        </div>

        <!-- Apply specific tickets -->
        <div class="form-field-input-switch-block c-mb-4">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="applyToSpecificTickets"
            inputId="apply-specific-tickets-input">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="apply-specific-tickets-input"
            text="adminCourseCouponForm.applySpecificTickets">
          </app-input-label>
        </div>

        <!-- Tickets dropdown -->
        <div
          class="form-field-block"
          *ngIf="couponForm.get('applyToSpecificTickets').value">
          <app-input-label for="tickets-input"> </app-input-label>
          <p-multiSelect
            class="custom-multi-select"
            panelStyleClass="custom-dropdown-panel"
            formControlName="tickets"
            inputId="tickets-input"
            [options]="courseTickets"
            optionLabel="name"
            optionValue="stripeProductId"
            placeholder="{{ 'adminCourseCouponForm.ticketsPlaceholder' | translate }}">
          </p-multiSelect>
        </div>
      </div>
    </div>

    <p-divider styleClass="custom-divider-horizontal"></p-divider>

    <div class="form-block c-my-9">
      <div class="left-side">
        <h2 class="title">{{ 'adminCourseCouponForm.redemptionTitle' | translate }}</h2>
        <p class="description">{{ 'adminCourseCouponForm.redemptionDescription' | translate }}</p>
      </div>
      <div class="right-side">
        <p class="input-header-description c-pl-8">
          {{ 'adminCourseCouponForm.redemptionLimitsInfo' | translate }}
        </p>
        <div class="form-field-input-checkbox-block c-mb-4">
          <p-checkbox
            class="custom-input-checkbox-bg"
            binary="true"
            formControlName="limitUsage"
            inputId="limit-usage-input">
          </p-checkbox>
          <app-input-label
            labelType="switch-checkbox"
            for="limit-usage-input"
            text="{{ 'adminCourseCouponForm.limitUsageLabel' | translate }}">
          </app-input-label>
        </div>
        <div
          class="form-field-block c-mb-4"
          *ngIf="couponForm.get('limitUsage').value">
          <input
            class="custom-input-text"
            formControlName="usageLimit"
            id="usage-limit-input"
            pInputText
            type="number"
            placeholder="{{ 'adminCourseCouponForm.limitUsagePlaceholder' | translate }}" />
        </div>
        <div class="form-field-input-checkbox-block c-mb-4">
          <p-checkbox
            class="custom-input-checkbox-bg"
            binary="true"
            formControlName="addExpirationDate"
            inputId="add-expiration-date-input">
          </p-checkbox>
          <app-input-label
            labelType="switch-checkbox"
            for="add-expiration-date-input"
            text="adminCourseCouponForm.expirationDateLabel">
          </app-input-label>
        </div>
        <div
          class="two-elements-block"
          *ngIf="couponForm.get('addExpirationDate').value">
          <!-- EndDate input -->
          <div>
            <app-input-label
              labelType="required"
              for="end-date-input"
              text="adminCourseCouponForm.expirationDatePlaceholder">
            </app-input-label>
            <p-calendar
              class="custom-calendar custom-calendar-required"
              formControlName="endDate"
              [monthNavigator]="true"
              [yearNavigator]="true"
              [minDate]="minDate"
              [showIcon]="true"
              dateFormat="dd/mm/yy"
              yearRange="1900:2050"
              inputId="end-date-input"
              icon="far fa-calendar-day"
              placeholder="{{ 'adminCourseCouponForm.expirationDatePlaceholder' | translate }}">
            </p-calendar>
            <app-input-error-message
              [showMessage]="
                couponForm.controls.endDate.invalid && couponForm.controls.endDate.touched
              "
              errorMessage="{{ 'application.forms.required' | translate }}">
            </app-input-error-message>
          </div>

          <!-- EndTime input -->
          <div>
            <app-input-label
              labelType="required"
              for="end-time-input"
              text="adminCourseCouponForm.expirationTimePlaceholder">
            </app-input-label>
            <p-calendar
              class="custom-calendar custom-calendar-time custom-calendar-required"
              formControlName="endTime"
              placeholder="{{ 'adminCourseCouponForm.expirationTimePlaceholder' | translate }}"
              inputId="end-time-input"
              [timeOnly]="true"
              [showIcon]="true"
              icon="far fa-clock">
            </p-calendar>
            <app-input-error-message
              [showMessage]="
                couponForm.controls.endTime.invalid && couponForm.controls.endTime.touched
              "
              errorMessage="{{ 'application.forms.required' | translate }}">
            </app-input-error-message>
          </div>
        </div>
      </div>
    </div>

    <div class="save-discard-actions-container">
      <app-save-discard-actions
        [isShow]="canUpdate"
        [isLoading]="isUpdating"
        (discard)="onDiscard()"
        (confirm)="onSave()">
      </app-save-discard-actions>
    </div>
  </form>
</div>

<p-toast>
  <ng-template
    let-message
    pTemplate="message">
    <app-toast
      [severity]="message.severity"
      [summary]="message.summary"
      [detail]="message.detail">
    </app-toast>
  </ng-template>
</p-toast>
