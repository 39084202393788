import { Component, OnInit } from '@angular/core';
import {
  Validators,
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
  FormGroup,
  FormControl,
} from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { ClipboardService } from 'ngx-clipboard';

import { environment } from 'src/environments/environment';
import { UsersService, BrandsService, TagsService, FormService } from 'src/app/core/services';
import { IUser, ITag, IBrand, ICountry, IHub } from 'src/app/core/models';
import { BrandsMaxLengthValidators } from 'src/app/shared';
import {
  VALID_LINK_PATTERN,
  FACEBOOK_PROFILE_LINK_PATTERN,
  INSTAGRAM_PROFILE_LINK_PATTERN,
  YOUTUBE_PROFILE_LINK_PATTERN,
  LINKEDIN_PROFILE_LINK_PATTERN,
  TWITTER_PROFILE_LINK_PATTERN,
  CALENDLY_PROFILE_LINK_PATTERN,
  XING_PROFILE_LINK_PATTERN,
  countries,
  VIRTUAL_SCROLL_ITEM_SIZE,
  asyncDelay,
} from 'src/app/core/utils';
import { Timestamp } from 'src/app/firebase';
import { BrandsStore, HubsStore, UsersStore } from 'src/app/core/stores';

@Component({
  selector: 'app-brand-profile',
  templateUrl: './brand-profile.component.html',
  styleUrls: ['./brand-profile.component.scss'],
})
export class BrandProfileComponent implements OnInit {
  loading = true;
  isUpdating = false;
  tagsLoading = true;
  usersLoading = true;
  tags: ITag[] = [];
  users: IUser[] = [];
  countriesTranslated: ICountry[] = [];
  copyIsClicked = false;
  form: FormGroup;
  virtualScrollItemSize: number = VIRTUAL_SCROLL_ITEM_SIZE;

  readonly linkPrefixLabel: string = '';
  readonly linkPrefix: string = '';

  private usersLoaded: boolean;

  constructor(
    private messageService: MessageService,
    public hubsStore: HubsStore,
    private usersStore: UsersStore,
    private tagService: TagsService,
    private brandsStore: BrandsStore,
    private brandsService: BrandsService,
    private usersService: UsersService,
    private translateService: TranslateService,
    private clipboardService: ClipboardService,
    private formService: FormService,
  ) {
    this.linkPrefixLabel = this.hubsStore.hub
      ? `${this.hubsStore.environmentBaseUrl}/${this.hubsStore.useHubUrl}`
      : `${environment.baseUrl}`;
    this.linkPrefix = `${this.hubsStore.environmentBaseUrl}brands/`;
  }

  get hub(): IHub {
    return this.hubsStore.hub;
  }

  get brand(): IBrand {
    return this.brandsStore.adminBrand;
  }

  get canUpdate(): boolean {
    return (
      !this.isUpdating && this.form.valid && this.form.dirty && this.formService.isValueChanged()
    );
  }

  get linkErrorText(): string {
    if (this.form.controls.link.hasError('pattern')) {
      return 'adminBrandProfile.brandLinkInvalidPattern';
    }

    if (this.form.controls.link.hasError('exists')) {
      return 'adminBrandProfile.linkExists';
    }

    return 'adminBrandProfile.brandLinkReq';
  }

  get tooltipText(): string {
    return !this.copyIsClicked
      ? this.translateService.instant('copyTooltip.hover')
      : this.translateService.instant('copyTooltip.action');
  }

  async ngOnInit(): Promise<void> {
    this.initForm();
    this.setInitialValueForForm();
    this.formService.setForm(this.form);
    this.countriesTranslated = countries.map((country: ICountry) => ({
      name: this.translateService.instant(country.name),
      code: country.name,
    }));
    const hubTagIds: string[] = await this.tagService.getHubTagsIdsArray(this.hub.id);
    this.tags = await this.tagService.getByIdsOrderedByTitle(hubTagIds);
    this.tagsLoading = false;
    const user: IUser = await this.usersService.getOne(this.brand.user);
    this.users = [user];
    this.usersLoading = false;
    this.loading = false;
  }

  linkExistsValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> =>
      this.brandsService.checkBrandByLinkExists(control.value, this.form.controls.id.value).pipe(
        debounceTime(500),
        distinctUntilChanged(),
        map((linkExists) => (linkExists ? { exists: linkExists } : null)),
      );
  }

  copyToClipboard(): void {
    this.clipboardService.copy(`${this.linkPrefixLabel}/${this.form.controls.link.value}`);
    this.copyIsClicked = true;
  }

  setLogoValue(value: File): void {
    this.form.controls.logo.setValue(value);
    this.makeFormControlDirty('logo');
  }

  setBannerValue(value: File): void {
    this.form.controls.banner.setValue(value);
    this.makeFormControlDirty('banner');
  }

  selectTags(tagIds: string[]): void {
    this.form.patchValue({ tags: tagIds });
    this.makeFormControlDirty('tags');
  }

  getMaxLengthValue(formControl: string): number {
    return Number(BrandsMaxLengthValidators[formControl]);
  }

  async loadUsers(): Promise<void> {
    if (this.usersLoaded) {
      return;
    }

    this.usersLoading = true;
    const subscription = this.usersService.getUsersByHub(this.hubsStore.hub.id);
    const users: IUser[] = await subscription;
    this.users = users.map((u) => ({ ...u, name: `${u.firstName} ${u.lastName}` }));
    this.usersLoading = false;
    this.usersLoaded = true;
  }

  async update(): Promise<void> {
    this.isUpdating = true;

    try {
      const brand: IBrand = this.form.getRawValue();

      brand.updatedAt = Timestamp.now();
      brand.updatedBy = this.usersStore.user.id;
      brand._name_ = brand.name.toLowerCase();

      const updatedBrand = await this.brandsService.update(this.brand.id, brand);

      if (this.brand.user && this.brand.user !== brand.user) {
        await this.usersService.assignBrand(this.brand.user, null);
        await this.brandsService.deattachOwner(this.brand.id, this.brand.user);

        const brandOnwer = await this.usersService.getOne(brand.user);
        await this.brandsService.attachOwner(
          Object.assign({}, this.brand, updatedBrand),
          brandOnwer,
        );
      } else if (!this.brand.user && brand.user) {
        this.usersService.assignBrand(brand.user, this.brand.id);
        const brandOnwer = await this.usersService.getOne(brand.user);
        await this.brandsService.attachOwner(
          Object.assign({}, this.brand, updatedBrand),
          brandOnwer,
        );
      }

      this.usersService.assignBrand(brand.user, this.brand.id);

      const newBrand = { ...this.brandsStore.adminBrand, ...brand };
      this.brandsStore.setAdminBrand(newBrand);

      this.isUpdating = false;
      this.messageService.add({
        severity: 'success',
        summary: this.translateService.instant('success'),
        detail: this.translateService.instant('adminBrandProfile.brandSuccessfullyUpdated'),
        styleClass: 'custom-toast',
      });
    } catch (error) {
      console.log(error);
      this.isUpdating = false;
      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('error'),
        detail: this.translateService.instant('adminBrandProfile.errorUpdateBrand'),
        styleClass: 'custom-toast',
      });
    }
  }

  onDiscard(): void {
    this.setInitialValueForForm();
  }

  async onConfirm(): Promise<void> {
    try {
      this.isUpdating = true;
      await asyncDelay(1);
      await this.update();
      this.formService.setForm(this.form);
    } catch (error) {
      console.error(error);
    }
  }

  private initForm(): void {
    this.form = new FormGroup({
      id: new FormControl(null),
      name: new FormControl(null, Validators.required),
      slogan: new FormControl(null, Validators.maxLength(BrandsMaxLengthValidators.slogan)),
      website: new FormControl(null, Validators.pattern(VALID_LINK_PATTERN)),
      email: new FormControl(null, Validators.email),
      user: new FormControl({ value: null, disabled: false }),
      link: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.pattern(/^\S+$/),
          Validators.pattern(/^((?!http:|www\.|https:|\/|\\).)*$/),
        ],
        asyncValidators: [this.linkExistsValidator()],
      }),
      acceptTerms: new FormControl(null, null),
      acceptNews: new FormControl(null, null),
      featuredBrand: new FormControl(null, null),
      description: new FormControl(null),
      tags: new FormControl([], null),
      logo: new FormControl(null),
      banner: new FormControl(null),
      street: new FormControl(null),
      addressLineSecond: new FormControl(null),
      zipCode: new FormControl(null),
      city: new FormControl(null),
      country: new FormControl(null),
      facebook: new FormControl(null, [
        Validators.pattern(VALID_LINK_PATTERN),
        Validators.pattern(FACEBOOK_PROFILE_LINK_PATTERN),
      ]),
      instagram: new FormControl(null, [
        Validators.pattern(VALID_LINK_PATTERN),
        Validators.pattern(INSTAGRAM_PROFILE_LINK_PATTERN),
      ]),
      youtube: new FormControl(null, [
        Validators.pattern(VALID_LINK_PATTERN),
        Validators.pattern(YOUTUBE_PROFILE_LINK_PATTERN),
      ]),
      linkedin: new FormControl(null, [
        Validators.pattern(VALID_LINK_PATTERN),
        Validators.pattern(LINKEDIN_PROFILE_LINK_PATTERN),
      ]),
      twitter: new FormControl(null, [
        Validators.pattern(VALID_LINK_PATTERN),
        Validators.pattern(TWITTER_PROFILE_LINK_PATTERN),
      ]),
      calendly: new FormControl(null, [
        Validators.pattern(VALID_LINK_PATTERN),
        Validators.pattern(CALENDLY_PROFILE_LINK_PATTERN),
      ]),
      xing: new FormControl(null, [
        Validators.pattern(VALID_LINK_PATTERN),
        Validators.pattern(XING_PROFILE_LINK_PATTERN),
      ]),
    });
  }

  private setInitialValueForForm(): void {
    this.form.patchValue({
      ...this.brand,
    });
  }

  private makeFormControlDirty(formControlName: string): void {
    this.form.controls[formControlName].markAsDirty();
  }
}
