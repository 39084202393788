import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipModule } from 'primeng/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { trackByFn } from 'src/app/core/utils';
import { CustomProgressBarComponent } from '../../custom-progress-bar/custom-progress-bar.component';

@Component({
  selector: 'app-stat-single-progress-bar',
  standalone: true,
  imports: [CommonModule, CustomProgressBarComponent, TranslateModule, TooltipModule],
  templateUrl: './stat-single-progress-bar.component.html',
  styleUrls: ['./stat-single-progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatSingleProgressBarComponent {
  @Input() statName: string;
  @Input() tooltipText: string;
  @Input() tooltipPosition: 'right' | 'left' | 'top' | 'bottom' = 'right';
  @Input() tooltipStyleClass = 'custom-tooltip';
  @Input() tooltipEvent: 'hover' | 'focus' = 'hover';
  @Input() tabIndex: number;
  @Input() progressBarValue: number;
  @Input() progressBarText: string;
  @Input() progressBarHeight = '0.875rem';
  @Input() progressBarClassName = 'progress-bar-for-single-statistic';
  @Input() progressBarBorderRadius = '0.375rem';
  @Input() listOptions: { text: string; value: string }[];

  trackByFn = trackByFn;
}
