<div class="container">
  <p class="title">
    {{ 'sidebarUser.events' | translate | uppercase }}
  </p>
  <ul class="list">
    <ng-container *ngFor="let event of userEvents; last as isLast; trackBy: trackByFn">
      <li
        class="list-item"
        [class.list-item-last]="isLast"
        (click)="onNavigateTo(event)">
        <img
          [ngSrc]="event.banner || defaultEventImage"
          class="icon"
          width="39"
          height="39"
          priority />
        <div class="text-container">
          <p class="event-name">{{ event.title }}</p>
          <span class="status"
            >{{ getDateForShowing(event.start) }} - {{ getDateForShowing(event.end) }}</span
          >
        </div>
        <i class="fa-regular fa-chevron-right chevron-icon"></i>
      </li>
      <div
        *ngIf="!isLast"
        class="divider-container">
        <p-divider styleClass="custom-divider-horizontal"></p-divider>
      </div>
    </ng-container>
  </ul>
</div>
