@if (loading()) {
  <app-loader></app-loader>
} @else {
  <div class="page-container">
    <header class="c-mb-4">
      <h1 class="admin-page-title">{{ 'adminCourseSettings.pageTitle' | translate }}</h1>
    </header>

    <p-divider styleClass="custom-divider-horizontal"></p-divider>

    <form
      [formGroup]="form"
      class="c-mt-8">
      <!-- Settings block -->
      <div class="form-block c-mt-8 c-mb-9">
        <div class="left-side">
          <h2 class="title">{{ 'adminCourseSettings.settingsTitle' | translate }}</h2>
          <p class="description">{{ 'adminCourseSettings.settingsDesc' | translate }}</p>
        </div>
        <div class="right-side">
          <!-- Title input -->
          <div class="form-field-block">
            <app-input-label
              labelType="required"
              for="title-input"
              text="adminCourseSettings.titleLabel">
            </app-input-label>
            <input
              class="custom-input-text custom-input-text-required"
              formControlName="title"
              id="title-input"
              pInputText
              type="text"
              placeholder="{{ 'adminCourseSettings.titlePlaceholder' | translate }}" />
            <app-input-error-message
              [showMessage]="form.controls.title.invalid && form.controls.title.touched"
              [errorMessage]="titleErrorMessage">
            </app-input-error-message>
          </div>

          <!-- Subtitle input -->
          <div class="form-field-block">
            <app-input-label
              for="tagline-input"
              text="adminCourseSettings.subtitleLabel">
            </app-input-label>
            <input
              class="custom-input-text custom-input-text-required"
              formControlName="tagline"
              id="tagline-input"
              pInputText
              type="text"
              placeholder="{{ 'adminCourseSettings.subtitlePlaceholder' | translate }}" />
            <app-input-error-message
              [showMessage]="form.controls.tagline.invalid && form.controls.tagline.touched"
              errorMessage="{{ 'application.forms.msxLengthErrorText' | translate }}">
            </app-input-error-message>
          </div>

          <!-- Course url input -->
          <div class="form-field-block">
            <app-input-label
              labelType="required"
              for="link-input"
              text="adminCourseSettings.linkLabel">
            </app-input-label>
            <div class="p-inputgroup custom-input-group">
              <span class="p-inputgroup-addon">{{ linkPrefix() }}</span>
              <input
                class="course-url-input"
                formControlName="link"
                id="link-input"
                pInputText
                type="text"
                placeholder="{{ 'adminCourseSettings.linkPlaceholder' | translate }}" />
              @if (form.controls.link.value.length) {
                <i
                  class="far fa-copy"
                  [pTooltip]="!copyIsClicked() ? tooltipText : null"
                  tooltipPosition="top"
                  tooltipStyleClass="custom-tooltip"
                  (mouseleave)="copyIsClicked.set(false)"
                  (click)="copyToClipboard()">
                </i>
              }
              @if (form.controls.link.value.length && copyIsClicked()) {
                <div class="tooltip-copied">
                  {{ tooltipText }}
                </div>
              }
            </div>
            <app-input-error-message
              [showMessage]="form.controls.link.invalid && form.controls.link.touched"
              [errorMessage]="linkErrorMessage">
            </app-input-error-message>
          </div>

          <!-- Owner id input -->
          <div class="form-field-block c-mb-4">
            <app-input-label
              labelType="required"
              for="owner-id-input"
              text="adminCourseSettings.ownerLabel">
            </app-input-label>
            <div class="spinner-and-element-block">
              @if (usersLoading()) {
                <i class="pi pi-spin pi-spinner icon-spinner"></i>
              }
              <p-dropdown
                class="custom-dropdown base-element custom-dropdown-required"
                panelStyleClass="custom-dropdown-panel"
                [options]="usersCanBeOwners()"
                [filter]="true"
                filterBy="firstName,lastName"
                optionValue="id"
                formControlName="ownerId"
                inputId="owner-id-input"
                placeholder="{{ 'adminCourseSettings.ownerPlaceholder' | translate }}">
                <ng-template
                  let-user
                  pTemplate="selectedItem">
                  {{ user.firstName }} {{ user.lastName }}
                </ng-template>
                <ng-template
                  let-user
                  pTemplate="item">
                  {{ user.firstName }} {{ user.lastName }}
                </ng-template>
              </p-dropdown>
            </div>
          </div>

          @if (!hub) {
            <!-- Hub id input -->
            <div class="form-field-block">
              <app-input-label
                for="hub-id-input"
                text="adminCourseSettings.hostLabel">
              </app-input-label>
              <p-dropdown
                class="custom-dropdown"
                panelStyleClass="custom-dropdown-panel"
                [options]="hubs()"
                optionLabel="title"
                optionValue="id"
                formControlName="hubId"
                inputId="hub-id-input"
                placeholder="{{ 'adminCourseSettings.hostLabel' | translate }}">
              </p-dropdown>
            </div>
          }

          <!-- Accounting number input -->
          <div class="form-field-block c-mb-4">
            <app-input-label
              for="accounting-number-input"
              text="adminCourseSettings.accountingNumberLabel">
            </app-input-label>
            <input
              class="custom-input-text custom-input-text-required"
              formControlName="accountingNumber"
              id="accounting-number-input"
              pInputText
              type="text"
              placeholder="{{ 'adminCourseSettings.accountingNumberPlaceholder' | translate }}" />
          </div>

          <!-- Featured input -->
          <div class="form-field-input-switch-block c-mb-4">
            <p-inputSwitch
              class="custom-input-switch"
              formControlName="featured"
              inputId="featured-input">
            </p-inputSwitch>
            <app-input-label
              labelType="switch-checkbox"
              for="featured-input"
              text="adminCourseSettings.featuredLabel">
            </app-input-label>
          </div>

          <!-- Currency input -->
          <div class="form-field-block">
            <app-input-label
              labelType="required"
              for="currency-input"
              text="adminCourseSettings.currency">
            </app-input-label>
            <p-dropdown
              class="custom-dropdown custom-dropdown-required"
              panelStyleClass="custom-dropdown-panel"
              [options]="currencies()"
              formControlName="currency"
              inputId="currency-input"
              placeholder="{{ 'adminCourseSettings.currency' | translate }}">
            </p-dropdown>
            <app-input-error-message
              [showMessage]="form.controls.currency.invalid && form.controls.currency.touched"
              errorMessage="{{ 'application.forms.required' | translate }}">
            </app-input-error-message>
          </div>

          <!-- Is displayed price input -->
          <div
            class="form-field-input-switch-block c-mb-4"
            [class.c-mb-8]="!form.controls.isDisplayedPrice.value">
            <p-inputSwitch
              class="custom-input-switch"
              formControlName="isDisplayedPrice"
              inputId="is-displayed-price-input">
            </p-inputSwitch>
            <app-input-label
              labelType="switch-checkbox"
              for="is-displayed-price-input"
              text="adminCourseSettings.isDisplayedPriceLabel">
            </app-input-label>
          </div>

          @if (form.controls.isDisplayedPrice.value) {
            <div class="two-elements-block c-mb-8">
              <!-- Price input -->
              <div>
                <app-input-label
                  labelType="required"
                  for="price-input"
                  text="adminCourseSettings.priceLabel">
                </app-input-label>
                <input
                  class="custom-input-text custom-input-text-required"
                  formControlName="price"
                  id="price-input"
                  pInputText
                  type="text"
                  placeholder="100" />
                <app-input-error-message
                  [showMessage]="form.controls.price.invalid && form.controls.price.touched"
                  [errorMessage]="priceErrorMessage">
                </app-input-error-message>
              </div>

              <!-- Strike price input -->
              <div>
                <app-input-label
                  for="strike-price-input"
                  text="adminCourseSettings.strikePriceLabel">
                </app-input-label>
                <p-inputNumber
                  class="custom-input-number"
                  inputId="strike-price-input"
                  formControlName="strikePrice"
                  mode="decimal"
                  [minFractionDigits]="2"
                  [maxFractionDigits]="5"
                  placeholder="{{ 'adminCourseSettings.strikePriceLabel' | translate }}">
                </p-inputNumber>
              </div>
            </div>
          }

          <p-divider styleClass="custom-divider-horizontal"></p-divider>

          <!-- Tags -->
          <div class="form-field-block c-mt-8">
            <app-tags-multi-list
              [possibleTags]="possibleTags()"
              [selectedTags]="selectedTags()"
              [adminTheme]="true"
              (onSelected)="onSelectTags($event)">
            </app-tags-multi-list>
          </div>
        </div>
      </div>

      <p-divider styleClass="custom-divider-horizontal"></p-divider>

      <!-- Date & Time block -->
      <div class="form-block c-my-9">
        <div class="left-side">
          <h2 class="title">{{ 'adminCourseSettings.dateTimeTitle' | translate }}</h2>
          <p class="description">{{ 'adminCourseSettings.dateTimeDesc' | translate }}</p>
        </div>
        <div class="right-side">
          <div class="two-elements-block c-mb-4">
            <!-- Start Date input -->
            <div>
              <app-input-label
                for="start-date-input"
                text="adminCourseSettings.startDateLabel">
              </app-input-label>
              <p-calendar
                class="custom-calendar"
                formControlName="start"
                [monthNavigator]="true"
                [yearNavigator]="true"
                [minDate]="minDate()"
                [showIcon]="true"
                dateFormat="dd/mm/yy"
                yearRange="1900:2050"
                inputId="start-date-input"
                icon="far fa-calendar-day"
                [placeholder]="currentDate()">
              </p-calendar>
            </div>

            <!-- Start Time input -->
            <div>
              <app-input-label
                for="start-time-input"
                text="adminCourseSettings.startTimeLabel">
              </app-input-label>
              <p-calendar
                class="custom-calendar custom-calendar-time"
                formControlName="startTime"
                placeholder="10:00"
                inputId="start-time-input"
                [timeOnly]="true"
                [showIcon]="true"
                icon="far fa-clock">
              </p-calendar>
            </div>
          </div>

          <div class="two-elements-block c-mb-4">
            <!-- End Date input -->
            <div>
              <app-input-label
                for="end-date-input"
                text="adminCourseSettings.endDateLabel">
              </app-input-label>
              <p-calendar
                class="custom-calendar"
                formControlName="end"
                [monthNavigator]="true"
                [yearNavigator]="true"
                [minDate]="minDate()"
                [showIcon]="true"
                dateFormat="dd/mm/yy"
                yearRange="1900:2050"
                inputId="end-date-input"
                icon="far fa-calendar-day"
                [placeholder]="currentDate()">
              </p-calendar>
            </div>

            <!-- End Time input -->
            <div>
              <app-input-label
                for="end-time-input"
                text="adminCourseSettings.endTimeLabel">
              </app-input-label>
              <p-calendar
                class="custom-calendar custom-calendar-time"
                formControlName="endTime"
                placeholder="10:00"
                inputId="end-time-input"
                [timeOnly]="true"
                [showIcon]="true"
                icon="far fa-clock">
              </p-calendar>
            </div>
          </div>
        </div>
      </div>

      <p-divider styleClass="custom-divider-horizontal"></p-divider>

      <!-- Localization & Translation block -->
      <div class="form-block c-my-9">
        <div class="left-side">
          <h2 class="title">{{ 'adminCourseSettings.localTranslTitle' | translate }}</h2>
          <p class="description">{{ 'adminCourseSettings.localTranslDescription' | translate }}</p>
        </div>
        <div class="right-side">
          <!-- Supported languages input -->
          <div class="form-field-block c-mb-4">
            <app-input-label
              for="supported-languages-input"
              text="adminCourseSettings.translationLanguageLabel">
            </app-input-label>
            <p-multiSelect
              class="custom-multi-select"
              panelStyleClass="custom-multi-select-panel-style-class"
              [options]="supportedLangs()"
              formControlName="supportedLanguages"
              inputId="supported-languages-input"
              placeholder="{{ 'adminCourseSettings.translationLanguagePlaceholder' | translate }}">
              <ng-template
                let-selectedLangs
                pTemplate="selectedItems">
                @for (selectedLang of selectedLangs; track $index; let last = $last) {
                  <span
                    class="
                      flag-icon flag-icon-squared flag-icon-circle
                      flag-icon-{{ selectedLang === 'en' ? 'gb' : selectedLang }}
                    ">
                  </span>
                  {{ 'languages.' + selectedLang | translate }}
                  @if (!last) {
                    ,
                  }
                }
              </ng-template>
              <ng-template
                let-lang
                pTemplate="item">
                <span
                  class="
                    flag-icon flag-icon-squared flag-icon-circle
                    flag-icon-{{ lang === 'en' ? 'gb' : lang }}">
                </span>
                {{ 'languages.' + lang | translate }}
              </ng-template>
            </p-multiSelect>
            <p class="input-note">
              {{ 'adminCourseSettings.translationLanguageInputNote' | translate }}
            </p>
          </div>
          <div class="flex c-px-8">
            <app-button
              buttonText="{{ 'adminCourseSettings.reviewEditTranslationsBtn' | translate }}"
              [buttonStyle]="buttonStyle().TERTIARY_PRIMARY"
              (click)="onOpenManageTranslationsDialog()" />
          </div>
        </div>
      </div>
    </form>
  </div>
}

<div class="save-discard-actions-container">
  <app-save-discard-actions
    [isShow]="canUpdate"
    [isLoading]="isUpdating()"
    (discard)="onDiscard()"
    (confirm)="onConfirm()">
  </app-save-discard-actions>
</div>

<p-toast>
  <ng-template
    let-message
    pTemplate="message">
    <app-toast
      [severity]="message.severity"
      [summary]="message.summary"
      [detail]="message.detail">
    </app-toast>
  </ng-template>
</p-toast>
