import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ISmallVimeoVideo } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class VimeoService {
  private url = 'https://vimeo.com';

  constructor(private http: HttpClient) {}

  getVideoInfoByUrl(url: string): Observable<ISmallVimeoVideo> {
    return this.http.get<ISmallVimeoVideo>(`${this.url}/api/oembed.json?url=${url}`);
  }
}
