<div class="user-card-container">
  <div class="user-card-img-box">
    <img
      class="user-card-img-box__avatar"
      [src]="userAvatar | imageSize" />
    <app-presence
      *ngIf="user?.id"
      [userId]="user.id"
      [stylesConfig]="configForPresenceStatus">
    </app-presence>
  </div>
  <div class="user-details">
    <div
      class="truncate-if-overflows full-name"
      [title]="user?.firstName + ' ' + user?.lastName">
      {{ fullName }}&nbsp;
    </div>
    <div
      *ngIf="!showPosition"
      class="user-department truncate-if-overflows"
      [title]="user?.company">
      {{ company }}&nbsp;
    </div>
    <div
      *ngIf="showPosition"
      class="user-department truncate-if-overflows"
      [title]="user?.position">
      {{ position }}&nbsp;
    </div>
  </div>
  <div
    *ngIf="user?.allowChat && isShowChatIcon && !eventsStore?.event?.hideChat"
    class="user-chat-icon"
    (click)="onOpenChat($event)"></div>
</div>
