<ng-container *ngIf="loading">
  <app-loader></app-loader>
</ng-container>

<div
  *ngIf="!loading"
  class="page-container">
  <header class="c-mb-4">
    <h1 class="admin-page-title">{{ 'adminEvent.menuItemDesign' | translate }}</h1>
  </header>

  <p-divider styleClass="custom-divider-horizontal"></p-divider>

  <form [formGroup]="form">
    <!-- Design block -->
    <div class="form-block c-my-9">
      <div class="left-side">
        <h2 class="title">{{ 'adminEventSettings.designTitle' | translate }}</h2>
        <p class="description">{{ 'adminEventSettings.designDescription' | translate }}</p>
      </div>
      <div class="right-side">
        <!-- Logo Dark Image -->
        <div class="form-field-block c-mb-4">
          <app-logo-media-form
            titleMediaForm="adminEventSettings.designLogoTitleDark"
            description="adminEventSettings.designLogoDesc"
            btnConfirm="upload"
            btnCancel="clear"
            [imgValue]="form.controls.logoDark.value"
            (changeValue)="setLogoDarkValue($event)">
          </app-logo-media-form>
        </div>

        <!-- Logo Light Image -->
        <div class="form-field-block c-mb-4">
          <app-logo-media-form
            titleMediaForm="adminEventSettings.designLogoTitleLight"
            description="adminEventSettings.designLogoDesc"
            btnConfirm="upload"
            btnCancel="clear"
            [imgValue]="form.controls.logoLight.value"
            (changeValue)="setLogoLightValue($event)">
          </app-logo-media-form>
        </div>

        <!-- Icon image -->
        <div class="form-field-block c-mb-4">
          <app-icon-media-form
            titleMediaForm="adminEventSettings.designIconTitle"
            description="adminEventSettings.designIconDesc"
            btnConfirm="upload"
            btnCancel="clear"
            [imgValue]="form.controls.icon.value"
            (changeValue)="setIconValue($event)">
          </app-icon-media-form>
        </div>

        <!-- Banner image -->
        <div class="form-field-block c-mb-4">
          <app-big-profile-picture-media-form
            titleMediaForm="adminEventSettings.designBannerTitle"
            description="adminEventSettings.designBannerDesc"
            btnConfirm="upload"
            btnCancel="clear"
            [imgValue]="form.controls.banner.value"
            (changeValue)="setBannerValue($event)">
          </app-big-profile-picture-media-form>
        </div>

        <!-- Featured image -->
        <div
          *ngIf="event.featured"
          class="form-field-block c-mb-4">
          <app-big-profile-picture-media-form
            titleMediaForm="adminEventSettings.featuredImageTitle"
            description="adminEventSettings.featuredImageDesc"
            btnConfirm="upload"
            btnCancel="delete"
            [imgValue]="form.controls.featuredImage.value"
            [isRequired]="true"
            (changeValue)="setFeaturedImageValue($event)">
          </app-big-profile-picture-media-form>
        </div>

        <!-- Color picker for primary color -->
        <div class="form-field-block c-mb-4">
          <app-color-picker
            label="adminEventSettings.primaryColorLabel"
            [colorPickerValue]="form.controls.primaryColor.value"
            (selectedColor)="onSelectPrimaryColor($event)">
          </app-color-picker>
          <p class="input-note">
            {{ 'adminEventSettings.primaryColorNote' | translate }}
          </p>
        </div>

        <!-- Color picker for accent color -->
        <div class="form-field-block">
          <app-color-picker
            label="adminEventSettings.accentColorLabel"
            [colorPickerValue]="form.controls.accentColor.value"
            (selectedColor)="onSelectAccentColor($event)">
          </app-color-picker>
          <p class="input-note">
            {{ 'adminEventSettings.accentColorNote' | translate }}
          </p>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="save-discard-actions-container">
  <app-save-discard-actions
    [isShow]="canUpdate"
    [isLoading]="isUpdating"
    (discard)="onDiscard()"
    (confirm)="onConfirm()">
  </app-save-discard-actions>
</div>

<p-toast>
  <ng-template
    let-message
    pTemplate="message">
    <app-toast
      [severity]="message.severity"
      [summary]="message.summary"
      [detail]="message.detail">
    </app-toast>
  </ng-template>
</p-toast>
