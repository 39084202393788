import * as moment from 'moment';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { merge, Observable, Subject } from 'rxjs';
import { debounceTime, filter, takeUntil, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { IAppointment, IDailyRoomConfig, IDailyRoom, IEvent, IUser } from 'src/app/core/models';
import {
  AppointmentService,
  DailyCoService,
  MixpanelService,
  UsersService,
} from 'src/app/core/services';
import { EventsStore, UsersStore } from 'src/app/core/stores';
import { asyncDelay, getUserNameAbbreviation, updateTime } from 'src/app/core/utils';
import { Timestamp } from 'src/app/firebase';

@Component({
  selector: 'app-appointment-dialog',
  templateUrl: './appointment-dialog.component.html',
  styleUrls: ['./appointment-dialog.component.scss'],
})
export class AppointmentDialogComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  selectionLimit = 5;
  currentUser: IUser;
  imageText = getUserNameAbbreviation;
  users: IUser[];
  currentEvent: IEvent;
  isUpdating = false;
  minDate: Date = moment().toDate();
  isSelectedOnlineVideoMeeting = false;
  isSelectedExternalMeeting = true;
  usersLoading = true;

  private defaultEndDate: Date;
  private isOpenFromChat: boolean;
  private unsubscribeOnline$: Subject<void> = new Subject<void>();
  private unsubscribeExternal$: Subject<void> = new Subject<void>();

  get counterOfUsers(): number {
    if (this.form?.controls?.users?.value?.length) {
      return this.selectionLimit - this.form.controls.users.value.length;
    }

    return this.selectionLimit;
  }

  get canUpdate(): boolean {
    return !this.isUpdating && this.form.valid && this.form.dirty;
  }

  get textForNote(): string {
    let result = ``;
    if (this.isSelectedOnlineVideoMeeting) {
      result = `
        <p>${this.translateService.instant('appointmentDialog.onlineVideoMeetingNoteLine1')}</p>
        <p>${this.translateService.instant('appointmentDialog.onlineVideoMeetingNoteLine2', { duration: this.currentEvent.duration })}</p>
        <p>
          ${this.translateService.instant('appointmentDialog.onlineVideoMeetingNoteLine3Part1')}
          <span>${this.translateService.instant('appointmentDialog.onlineVideoMeetingTextForSpan')}</span>
          ${this.translateService.instant('appointmentDialog.onlineVideoMeetingNoteLine3Part2')}
        </p>
      `;
    } else if (this.isSelectedExternalMeeting && this.currentEvent.onlineVideoMeetingStatus) {
      result = `
        <p>${this.translateService.instant('appointmentDialog.externalMeetingNoteLine1')}</p>
        <p>${this.translateService.instant('appointmentDialog.externalMeetingNoteLine2', { duration: this.currentEvent.duration })}</p>
        <p>
          ${this.translateService.instant('appointmentDialog.externalMeetingNoteLine3')}
          <span>${this.translateService.instant('appointmentDialog.externalMeetingTextForSpan')}</span>
        </p>
      `;
    } else if (this.isSelectedExternalMeeting && !this.currentEvent.onlineVideoMeetingStatus) {
      result = `
        <p>${this.translateService.instant('appointmentDialog.defaultExternalMeetingLine1')}</p>
        <p>${this.translateService.instant('appointmentDialog.defaultExternalMeetingLine2')}</p>
      `;
    }
    return result;
  }

  constructor(
    private fb: UntypedFormBuilder,
    private usersStore: UsersStore,
    private eventsStore: EventsStore,
    private appointmentService: AppointmentService,
    private messageService: MessageService,
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private usersService: UsersService,
    private mixpanelService: MixpanelService,
    private dailyCoService: DailyCoService,
    private translateService: TranslateService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.currentEvent = this.eventsStore.event;
    this.currentUser = this.usersStore.user;
    await this.init();
    this.isOpenFromChat = this.config.data?.isOpenFromChat;

    if (this.config.data?.user) {
      this.updateForm();
    }

    if (this.isSelectedOnlineVideoMeeting) {
      this.onLineVideoMeetingValidation();
    }

    if (this.isSelectedExternalMeeting) {
      this.externalMeetingValidation();
    }
  }

  private async init(): Promise<void> {
    this.createForm();
    this.users = await this.usersService.getAllUsersForAppointment(
      this.eventsStore.eventId,
      this.usersStore.user.id,
    );
    this.usersLoading = false;
    this.form.controls.users.enable();
  }

  private createForm(): void {
    this.defaultEndDate = this.currentEvent.onlineVideoMeetingStatus
      ? moment().add(this.currentEvent.duration, 'minutes').toDate()
      : moment().add(1, 'hours').toDate();

    this.form = this.fb.group({
      meetingName: [null, Validators.required],
      description: [null],
      location: [null, Validators.required],
      startDate: [this.minDate, Validators.required],
      endDate: [this.defaultEndDate, Validators.required],
      startTime: [this.minDate, Validators.required],
      endTime: [this.defaultEndDate, Validators.required],
      users: [{ value: null, disabled: true }],
    });
  }

  private updateForm(): void {
    const user = this.users.filter((u) => u.id === this.config.data.user.id);
    this.form.patchValue({ users: user });
  }

  private onLineVideoMeetingValidation(): void {
    this.updateDateAndTime();
    const startDateValue$: Observable<Date> = this.form.controls.startDate.valueChanges;
    const startTimeValue$: Observable<Date> = this.form.controls.startTime.valueChanges;
    const endDateValue$: Observable<Date> = this.form.controls.endDate.valueChanges;
    const endTimeValue$: Observable<Date> = this.form.controls.endTime.valueChanges;

    const start$ = merge(startDateValue$, startTimeValue$).pipe(
      debounceTime(300),
      filter((value) => !!value),
      tap(() => {
        const start: moment.Moment = updateTime(
          this.form.controls.startDate.value,
          this.form.controls.startTime.value,
        );
        const end: moment.Moment = updateTime(
          this.form.controls.endDate.value,
          this.form.controls.endTime.value,
        );
        const diff: number = Math.abs(moment.duration(end.diff(start)).asMinutes());

        if (
          diff > this.currentEvent.duration ||
          moment(start).isAfter(moment(end)) ||
          moment(start).isSame(moment(end))
        ) {
          const validEnd = moment(start).add(this.currentEvent.duration, 'minutes');
          this.form.controls.endDate.setValue(validEnd.toDate(), { emitEvent: false });
          this.form.controls.endTime.setValue(validEnd.toDate(), { emitEvent: false });
        }

        if (moment(start).valueOf() < moment.now()) {
          const validEnd = moment().add(this.currentEvent.duration, 'minutes');
          this.form.controls.startDate.setValue(moment().toDate(), { emitEvent: false });
          this.form.controls.startTime.setValue(moment().toDate(), { emitEvent: false });
          this.form.controls.endDate.setValue(validEnd.toDate(), { emitEvent: false });
          this.form.controls.endTime.setValue(validEnd.toDate(), { emitEvent: false });
        }
      }),
    );

    const end$ = merge(endDateValue$, endTimeValue$).pipe(
      debounceTime(300),
      filter((value) => !!value),
      tap(() => {
        const start: moment.Moment = updateTime(
          this.form.controls.startDate.value,
          this.form.controls.startTime.value,
        );
        const end: moment.Moment = updateTime(
          this.form.controls.endDate.value,
          this.form.controls.endTime.value,
        );
        const diff: number = Math.abs(moment.duration(end.diff(start)).asMinutes());

        if (moment(end).valueOf() <= moment.now()) {
          const validEnd = moment().add(this.currentEvent.duration, 'minutes');
          this.form.controls.startDate.setValue(moment().toDate(), { emitEvent: false });
          this.form.controls.startTime.setValue(moment().toDate(), { emitEvent: false });
          this.form.controls.endDate.setValue(validEnd.toDate(), { emitEvent: false });
          this.form.controls.endTime.setValue(validEnd.toDate(), { emitEvent: false });
        } else if (moment(start).isAfter(moment(end))) {
          const validStart =
            moment(end).subtract(this.currentEvent.duration, 'minutes').valueOf() > moment.now()
              ? moment(end).subtract(this.currentEvent.duration, 'minutes').toDate()
              : moment().toDate();
          this.form.controls.startDate.setValue(validStart, { emitEvent: false });
          this.form.controls.startTime.setValue(validStart, { emitEvent: false });
        } else if (moment(end).isAfter(moment(start)) && diff > this.currentEvent.duration) {
          const validStart =
            moment(end).subtract(this.currentEvent.duration, 'minutes').valueOf() > moment.now()
              ? moment(end).subtract(this.currentEvent.duration, 'minutes').toDate()
              : moment().toDate();
          this.form.controls.startDate.setValue(validStart, { emitEvent: false });
          this.form.controls.startTime.setValue(validStart, { emitEvent: false });
        }
      }),
    );

    merge(start$, end$).pipe(takeUntil(this.unsubscribeOnline$)).subscribe();
  }

  private externalMeetingValidation(): void {
    this.updateDateAndTime();
    const startDateValue$: Observable<Date> = this.form.controls.startDate.valueChanges;
    const startTimeValue$: Observable<Date> = this.form.controls.startTime.valueChanges;
    const endDateValue$: Observable<Date> = this.form.controls.endDate.valueChanges;
    const endTimeValue$: Observable<Date> = this.form.controls.endTime.valueChanges;

    merge(startDateValue$, startTimeValue$, endDateValue$, endTimeValue$)
      .pipe(
        debounceTime(300),
        filter((value) => !!value),
        tap(() => {
          const start: moment.Moment = updateTime(
            this.form.controls.startDate.value,
            this.form.controls.startTime.value,
          );
          const end: moment.Moment = updateTime(
            this.form.controls.endDate.value,
            this.form.controls.endTime.value,
          );

          if (moment(this.minDate).isAfter(moment(start))) {
            this.form.controls.startDate.setValue(this.minDate, { emitEvent: false });
            this.form.controls.startTime.setValue(this.minDate, { emitEvent: false });
          }

          if (moment(start).isAfter(moment(end))) {
            const validEnd = start.add(1, 'hours');
            this.form.controls.endDate.setValue(validEnd.toDate(), { emitEvent: false });
            this.form.controls.endTime.setValue(validEnd.toDate(), { emitEvent: false });
          }
        }),
        takeUntil(this.unsubscribeExternal$),
      )
      .subscribe();
  }

  private updateDateAndTime(): void {
    this.isSelectedOnlineVideoMeeting
      ? this.form.controls.location.setValue({ value: null, disabled: true })
      : this.form.controls.location.setValue(null);
    this.form.patchValue({
      startDate: this.minDate,
      endDate: this.defaultEndDate,
      startTime: this.minDate,
      endTime: this.defaultEndDate,
    });
  }

  removeUser(user: string): void {
    const updatedUsers = this.form.controls.users.value.filter((u: string) => u !== user);
    this.form.patchValue({ users: updatedUsers });
  }

  async onSendRequest(): Promise<void> {
    this.isUpdating = true;
    try {
      const {
        startDate,
        endDate,
        startTime,
        endTime,
        users: usersFromForm,
        ...others
      } = this.form.getRawValue();
      const appointmentStartDate = updateTime(startDate, startTime);
      const appointmentEndDate = updateTime(endDate, endTime);

      const dataForRequest = {
        ...others,
        eventId: this.eventsStore.eventId,
        hostId: this.usersStore.user.id,
        users: [...usersFromForm.map((user: IUser) => user.id), ...[this.usersStore.user.id]],
        startDate: Timestamp.fromMillis(appointmentStartDate.toDate().getTime()),
        endDate: Timestamp.fromMillis(appointmentEndDate.toDate().getTime()),
      };

      let userTokens: { [userId: string]: string } = null;
      if (this.isSelectedOnlineVideoMeeting) {
        const options: IDailyRoomConfig = {
          nbf: moment(appointmentStartDate).unix(),
          exp: moment(appointmentEndDate).add(5, 'minutes').unix(),
          max_participants: dataForRequest.users.length,
          enable_people_ui: true,
          enable_prejoin_ui: true,
          enable_screenshare: true,
          enable_video_processing_ui: true,
          enable_chat: true,
          start_video_off: true,
          start_audio_off: true,
          eject_at_room_exp: true,
        };
        const dailyRoom: IDailyRoom = await this.dailyCoService.createRoom(options).toPromise();
        const userTokensPayload: { id: string; token: string }[] = await Promise.all(
          [...usersFromForm, this.usersStore.user].map(async (user: IUser) => {
            const { token } = await this.dailyCoService
              .createToken(
                dailyRoom.name,
                `${user.firstName} ${user.lastName}`,
                user.id,
                user.id === this.usersStore.userId,
              )
              .toPromise();
            return { id: user.id, token };
          }),
        );
        userTokens = userTokensPayload.reduce(
          (acc: { [userId: string]: string }, curr: { id: string; token: string }) => {
            return { ...acc, [curr.id]: curr.token };
          },
          {},
        );
        dataForRequest['location'] = dailyRoom.url;
      }

      const response: IAppointment = await this.appointmentService.createAppointment(
        dataForRequest,
        userTokens,
      );
      if (response) {
        this.form.reset();
        this.isUpdating = false;
        this.showMessage('success');
        this.sendEventToMixpanel(response);
        await asyncDelay(2000);
      }
      this.ref.close();
    } catch (error) {
      this.isUpdating = false;
      this.showMessage('error');
    }
  }

  private sendEventToMixpanel(appointment: IAppointment): void {
    this.mixpanelService.scheduleMeeting(appointment, this.isOpenFromChat);
  }

  private showMessage(severity: 'success' | 'error'): void {
    this.messageService.add({
      severity,
      summary: this.translateService.instant(severity),
      detail:
        severity === 'success'
          ? this.translateService.instant('appointmentDialog.successMsg')
          : this.translateService.instant('application.toasters.error'),
      styleClass: 'custom-toast',
    });
  }

  onVideoMeetingToggle(): void {
    if (!this.isSelectedOnlineVideoMeeting) {
      this.isSelectedOnlineVideoMeeting = !this.isSelectedOnlineVideoMeeting;
      this.isSelectedExternalMeeting = !this.isSelectedExternalMeeting;
      this.unsubscribeExternal$.next();
      this.onLineVideoMeetingValidation();
    }
  }

  onExternalMeetingToggle(): void {
    if (!this.isSelectedExternalMeeting) {
      this.isSelectedOnlineVideoMeeting = !this.isSelectedOnlineVideoMeeting;
      this.isSelectedExternalMeeting = !this.isSelectedExternalMeeting;
      this.unsubscribeOnline$.next();
      this.externalMeetingValidation();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribeOnline$.next();
    this.unsubscribeExternal$.complete();
    this.unsubscribeExternal$.next();
    this.unsubscribeExternal$.complete();
  }
}
