import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UsersStore } from '../stores';
import { UserSubscriptionTypes } from 'src/app/core/enums';

@Injectable({
  providedIn: 'root',
})
export class NewHubGuard {
  constructor(private usersStore: UsersStore) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const user = this.usersStore.user;
    if (user.hasEverSubscribed) {
      switch (user.subscriptionType) {
        case UserSubscriptionTypes.BASIC:
          return user.createdHubsCount < 1 || !user.createdHubsCount;
        case UserSubscriptionTypes.PRO:
          return user.createdHubsCount < 2 || !user.createdHubsCount;
        case UserSubscriptionTypes.UNLIMITED:
          return true;
        default:
          return false;
      }
    } else {
      return false;
    }
  }
}
