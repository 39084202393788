<div
  class="ticket-button"
  [class.disabled]="isDisabled"
  (click)="onClick()">
  <div
    class="radio-btn"
    [class.selected]="isSelected"></div>
  <div class="ticket-name-container">
    <p class="ticket-name">
      {{ ticketName }}
    </p>
  </div>
  <p class="ticket-price">
    <span *ngIf="!isFreeTicket && price">
      {{ price }}
    </span>
    <i
      *ngIf="!isFreeTicket && currencyIcon"
      [class]="currencyIcon">
    </i>
    <i
      *ngIf="tooltipText"
      class="far fa-circle-info"
      [pTooltip]="tooltipText"
      [tooltipEvent]="isDesktop ? 'hover' : 'focus'"
      [tabIndex]="isDesktop ? null : 0"
      [tooltipPosition]="isDesktop ? 'right' : 'left'"
      tooltipStyleClass="custom-tooltip">
    </i>
    <span *ngIf="isFreeTicket">{{ 'shared.free' | translate }}</span>
  </p>
</div>
