<ng-container *ngIf="loading">
  <app-loader></app-loader>
</ng-container>

<div
  *ngIf="!loading"
  class="admin-users-container">
  <div class="admin-users-table-container">
    <admin-table-header-navbar [title]="'adminUsers.title' | translate">
      <app-button
        buttonText="{{ 'adminUsers.addBtnLabel' | translate }}"
        buttonIconLeft="far fa-plus"
        [buttonSize]="buttonSize.MEDIUM"
        (click)="openNewUserDialog()" />
    </admin-table-header-navbar>

    <div class="c-my-4">
      <p-divider styleClass="custom-divider-horizontal"></p-divider>
    </div>

    <admin-table-filter-navbar
      [searchActive]="!appStore.generalSystemSettings.enableEncryption"
      [filters]="tableFilters"
      (onFiltersChange)="filtersChange($event)"
      (onSearchTermChange)="onSearchTermChange($event)">
    </admin-table-filter-navbar>

    <admin-table
      [lazyLoading]="loadingEntries"
      [columns]="columns"
      [entries]="entries"
      [pageSize]="pageSize"
      [totalRecords]="totalRecords"
      scssClass="show-row-border-bottom"
      emptyStateKey="adminUsers.emptyState"
      emptyStateIcon="far fa-users"
      (lazyLoad)="fetchEntries($event)"
      (rowClicked)="onRowClicked($event)">
      <ng-template
        let-entry
        epTemplateKey="logoCell">
        <image
          [src]="entry?.profileImage"
          [text]="imageText(entry)"
          size="extra-small"></image>
      </ng-template>

      <ng-template
        let-entry
        epTemplateKey="statusCell">
        <app-tag
          [iconClass]="entry.isActive ? 'fa-regular fa-check' : 'fa-regular fa-xmark'"
          tagText="{{
            (entry.isActive ? 'adminTable.statusActive' : 'adminTable.statusInactive') | translate
          }}"
          [bgColor]="entry.isActive ? 'var(--appPrimaryColor)' : '#ff244c'">
        </app-tag>
      </ng-template>

      <ng-template
        let-entry
        epTemplateKey="actionsCell">
        <div class="table-btn-actions-container">
          <app-button
            [buttonType]="buttonType.ROUND"
            [buttonSize]="buttonSize.MEDIUM"
            [buttonStyle]="buttonStyle.FILLED_SECONDARY"
            buttonRoundIcon="far fa-pen-to-square"
            (click)="edit($event, entry)" />
          @if (hubsStore.hub) {
            <app-button
              [buttonType]="buttonType.ROUND"
              [buttonSize]="buttonSize.MEDIUM"
              [buttonStyle]="buttonStyle.FILLED_DANGER"
              buttonRoundIcon="far fa-trash-can"
              (click)="unAttachUser($event, entry)" />
          }
        </div>
      </ng-template>
    </admin-table>
  </div>
</div>

<p-sidebar
  styleClass="custom-sidebar"
  [(visible)]="sidebarVisible"
  position="right"
  [showCloseIcon]="false">
  <app-sidebar-user-info-container
    [userId]="userIdForSidebar"
    (closeSidebar)="onCloseSidebar()">
  </app-sidebar-user-info-container>
</p-sidebar>
