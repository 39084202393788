<nav>
  <ng-container *ngIf="currentMenuItem?.sidebarItems.layout === sidebarItemsLayout.DEFAULT">
    <div
      *ngFor="let navItem of currentMenuItem?.sidebarItems.menuItems; trackBy: trackByFn"
      class="group-container">
      <p
        *ngIf="navItem?.groupTitle"
        class="group-title">
        {{ navItem.groupTitle }}
      </p>
      <ul>
        <li
          *ngFor="let navItem of navItem.items; trackBy: trackByFn"
          class="item">
          <a
            [routerLink]="navItem.routerLink"
            routerLinkActive="active">
            <span>{{ navItem.label }}</span>
            <i class="fa-regular fa-chevron-right chevron-icon"></i>
          </a>
        </li>
      </ul>
    </div>
  </ng-container>
  <ng-container *ngIf="currentMenuItem?.sidebarItems.layout === sidebarItemsLayout.SETUP">
    <div
      *ngFor="let navItem of currentMenuItem?.sidebarItems.menuItems; trackBy: trackByFn"
      class="group-container">
      <p
        *ngIf="navItem?.groupTitle"
        class="group-title">
        {{ navItem.groupTitle }}
      </p>
      <ul>
        <li
          *ngFor="let navItem of navItem.items; trackBy: trackByFn"
          class="item">
          <a
            [routerLink]="navItem.routerLink"
            routerLinkActive="active">
            <div
              class="circle"
              (click)="toggleActive(navItem, $event)"
              [class.active]="checkedMenuItems?.includes(navItem.label)">
              <i class="fa-regular fa-check"></i>
            </div>
            <span>{{ navItem.label }}</span>
            <i class="fa-regular fa-chevron-right chevron-icon"></i>
          </a>
        </li>
      </ul>
    </div>
  </ng-container>
</nav>
