import { Component, OnInit } from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-calendly-dialog',
  templateUrl: './calendly-dialog.component.html',
  styleUrls: ['./calendly-dialog.component.scss'],
})
export class CalendlyDialogComponent implements OnInit {
  public loadingView = true;
  public url: SafeResourceUrl = '';

  constructor(
    private sanitizer: DomSanitizer,
    public config: DynamicDialogConfig,
  ) {}

  ngOnInit(): void {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.config.data?.url);
    this.loadingView = false;
  }
}
