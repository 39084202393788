import { Timestamp } from 'src/app/firebase';

export interface IBrandProduct {
  id: string;
  brandId: string;
  _brandName_: string;
  brandName: string;
  title: string;
  description: string;
  tagline: string;
  cta: string;
  type: string;
  file: string | File;
  videoLink: string;
  isVisible: boolean;
  featured: boolean;
  tags: string[];
  image: any;
  createdAt: Timestamp;
  createdBy: string;
  updatedAt: Timestamp;
  updatedBy: string;
  isDeleting?: boolean;
}
