import { UsersStore } from 'src/app/core/stores';
import { HubsStore } from './../../core/stores/hubs/hubs.store';
import { CoursesStore } from './../../core/stores/courses/courses.store';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Route,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { CoursesService } from 'src/app/core/services';

@Injectable({
  providedIn: 'root',
})
export class AdminCourseAccessGuard {
  constructor(
    private router: Router,
    private coursesService: CoursesService,
    private coursesStore: CoursesStore,
    private usersStore: UsersStore,
    private hubsStore: HubsStore,
  ) {}

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    let currentCourse = this.router.getCurrentNavigation().extras.state
      ? this.router.getCurrentNavigation().extras.state.course
        ? this.router.getCurrentNavigation().extras.state.course
        : null
      : null;

    if (!currentCourse) {
      currentCourse = await this.coursesService.getByUrl(next.params.courseUrl);
    }

    this.coursesStore.setAdminCourse(currentCourse);

    return currentCourse && this.hubsStore.hub && this.hubsStore.hub.academy && this.usersStore.user
      ? true
      : false;
  }
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.coursesStore.unsetAdminCourse();
    return true;
  }
  canLoad(
    route: Route,
    segments: UrlSegment[],
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
}
