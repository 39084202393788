import { Injectable } from '@angular/core';

import { Firestore, IFirestore } from 'src/app/firebase';
import { API_ROUTES as apiRoutes } from 'src/app/shared';
import { IUserRegistrationInvite } from 'src/app/core/models';

@Injectable({
  providedIn: 'root',
})
export class UserInvitesService {
  private firestore: IFirestore;

  constructor() {
    this.firestore = Firestore();
  }

  async getUserInviteByEventIdAndUserId(
    eventId: string,
    userId: string,
  ): Promise<IUserRegistrationInvite> {
    try {
      const userInvites: IUserRegistrationInvite[] = (
        await this.firestore
          .collection(apiRoutes.userInvites)
          .where('userId', '==', userId)
          .where('eventId', '==', eventId)
          .get()
      ).docs.map((doc) => doc.data() as IUserRegistrationInvite);

      return userInvites[0];
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async getUserInvitesByUserId(userId: string): Promise<IUserRegistrationInvite[]> {
    try {
      const userInvites: IUserRegistrationInvite[] = (
        await this.firestore.collection(apiRoutes.userInvites).where('userId', '==', userId).get()
      ).docs.map((doc) => doc.data() as IUserRegistrationInvite);

      return userInvites;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  async deleteUserInvite(userInviteId: string): Promise<boolean> {
    try {
      await this.firestore.collection(apiRoutes.userInvites).doc(userInviteId).delete();

      return true;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }
}
