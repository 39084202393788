export const getDefaultCoverWithChangedBgColor = (coverName: string, color: string) => {
  const inlineSvgCovers = {
    'default-image-1': `
      <svg width="100%" height="100%" viewBox="0 0 984 358" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice">
        <g clip-path="url(#clip0)">
          <rect width="984" height="358" fill="#121212"/>
          <g opacity="0.4">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M994.165 144.463C959.635 174.791 932.944 215.477 918.326 261.979C908.503 293.227 903.721 325.493 901.414 358L898.469 358C900.776 325.238 905.601 292.676 915.523 261.116C930.582 213.228 958.29 171.423 994.165 140.57L994.165 144.463"
              fill="${color}"/>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M994.165 76.5499C992.963 77.3985 991.759 78.2043 990.557 79.0679C926.11 125.386 881.233 193.641 860.782 276.428C854.119 303.431 849.447 331.14 845.81 358L842.853 358C846.491 330.928 851.204 302.98 857.924 275.736C878.544 192.252 923.818 123.433 988.844 76.7029C990.612 75.4312 992.382 74.2139 994.165 72.9815L994.165 76.5499"
              fill="${color}"/>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M778.408 358C780.174 351.732 781.917 345.477 783.627 339.306C801.941 273.515 820.876 205.486 857.487 146.289C892.145 90.2762 939.567 47.6794 994.165 6.56766L994.165 10.2334C940.558 50.7493 894.027 92.8093 859.991 147.816C823.592 206.66 804.715 274.492 786.458 340.084C784.818 345.984 783.146 351.987 781.461 358L778.408 358"
              fill="${color}"/>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M923.887 -328.322C947.96 -322.59 971.678 -313.503 994.165 -301.786L994.165 -298.519C971.495 -310.448 947.534 -319.688 923.195 -325.49C850.496 -342.828 772.958 -334.164 671.787 -297.415C582.091 -264.823 502.402 -227.717 434.927 -187.114C379.494 -153.758 334.619 -112.55 287.111 -68.9191C274.814 -57.6389 262.104 -45.9646 249.297 -34.5418C236.023 -22.6855 222.31 -10.8801 209.048 0.53915C190.482 16.5169 171.307 33.0464 153.006 49.9145C149.485 53.1838 145.776 56.511 141.985 59.9194C118.039 81.4735 90.9109 105.9 80.637 134.855C77.8338 142.778 76.3904 150.874 76.3904 158.939L76.3904 158.954C76.4194 216.526 148.564 263.623 179.584 281.199C235.513 312.885 296.339 336.263 356.271 358L347.738 358C290.14 336.985 231.919 314.201 178.126 283.732C139.04 261.583 73.4748 215.917 73.4748 158.954L73.4748 158.939C73.4748 150.549 74.9471 142.115 77.8628 133.892C88.3894 104.229 115.815 79.5491 140.016 57.7689C143.797 54.3571 147.489 51.0333 151.012 47.7779C169.339 30.8669 188.543 14.3374 207.109 -1.66935C220.372 -13.0747 234.069 -24.8766 247.344 -36.7098C260.123 -48.1151 272.831 -59.7895 285.114 -71.0685C332.736 -114.799 377.712 -156.108 433.399 -189.604C501.043 -230.304 580.903 -267.495 670.782 -300.159C772.517 -337.109 850.58 -345.799 923.887 -328.322"
              fill="${color}"/>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M682.81 358C685.245 356.019 687.635 353.897 689.957 351.575C717.554 323.923 734.265 287.85 750.426 252.966C752.679 248.141 754.913 243.3 757.177 238.503C785.624 178.143 826.31 99.9125 888.862 30.1018C899.632 18.0878 911.66 6.29751 924.397 -6.16748C950.225 -31.487 976.615 -57.3572 994.165 -86.7923L994.165 -81.1731C976.503 -53.1523 951.23 -28.3742 926.449 -4.10389C913.755 8.33559 901.767 20.0969 891.055 32.0402C828.744 101.582 788.2 179.573 759.838 239.747C757.574 244.532 755.34 249.358 753.102 254.198C736.828 289.308 720.001 325.606 692.038 353.627C690.507 355.169 688.936 356.612 687.34 358L682.81 358"
              fill="${color}"/>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M909.17 -283.474C941.138 -272.733 973.262 -251.943 994.165 -223.613L994.165 -218.545C973.773 -248.08 940.927 -269.719 908.236 -280.714C849.801 -300.371 781.803 -297.527 700.345 -272.04C624.549 -248.306 544.137 -211.017 467.817 -164.174C400.594 -122.922 348.318 -70.6464 290.507 -4.85163C287.931 -1.92435 285.242 1.10377 282.467 4.24552C245.276 46.2071 194.331 103.678 194.331 159.675L194.331 159.69C194.331 168.55 195.661 177.181 198.267 185.335C216.125 241.092 272.435 273.217 330.641 299.638C386.048 324.786 444.677 342.488 503.07 358L491.803 358C436.782 343.127 381.703 326.018 329.412 302.283C270.611 275.593 213.677 243.061 195.464 186.212C192.774 177.776 191.401 168.861 191.401 159.69C191.401 159.675 191.401 159.675 191.401 159.675C191.401 102.573 242.758 44.6223 280.262 2.32108C283.037 -0.82067 285.723 -3.84883 288.285 -6.7622C346.28 -72.768 398.741 -125.216 466.272 -166.652C542.794 -213.622 623.429 -251.025 699.466 -274.814C781.534 -300.513 850.143 -303.344 909.17 -283.474"
              fill="${color}"/>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M915.355 -148.961C915.355 -115.408 897.354 -75.5123 861.861 -30.3835C808.976 36.8511 763.151 108.433 721.503 175.768C718.998 179.816 716.491 183.92 713.987 188.036C697.84 214.586 681.126 242.025 657.818 262.603C625.099 291.472 582.671 298.675 552.839 299.623C544.591 299.877 535.574 299.808 525.273 299.382C448.556 287.296 370.296 268.46 318.756 213.085C300.244 193.174 290.848 169.808 290.848 143.642L290.848 143.628C290.848 95.4118 322.095 45.8822 345.602 12.8069C424.369 -98.0437 551.567 -182.828 703.727 -225.92C757.108 -241.034 833.728 -256.401 882.649 -215.532C904.345 -197.388 915.355 -174.999 915.355 -148.961ZM287.918 143.628L287.918 143.642C287.918 170.574 297.568 194.603 316.605 215.053C368.754 271.095 447.578 290.098 524.989 302.283C535.533 302.723 544.619 302.793 552.941 302.538C583.254 301.562 626.401 294.231 659.772 264.782C683.405 243.923 700.232 216.271 716.507 189.537C719.011 185.433 721.503 181.343 724.006 177.294C765.614 110.031 811.382 38.5356 864.179 -28.6005C900.085 -74.2405 918.298 -114.741 918.298 -148.961C918.298 -175.892 906.933 -199.044 884.546 -217.766C834.547 -259.529 756.952 -244.019 702.922 -228.722C550.123 -185.46 422.361 -100.279 343.197 11.1235C319.463 44.5084 287.918 94.5921 287.918 143.628V143.628Z"
              fill="${color}"/>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M683.9 -38.5755C683.9 -10.0308 663.905 28.9977 656.375 43.7048C638.461 78.6721 588.234 158.885 508.985 148.326C470.237 137.713 462.07 110.414 462.07 89.3842C462.056 69.3048 469.713 45.4866 483.072 24.0195C500.321 -3.70673 525.485 -27.5098 555.856 -44.815C571.507 -53.7336 625.58 -81.839 664.426 -69.2859C677.347 -65.11 683.901 -54.7805 683.9 -38.5755ZM459.128 89.3842V89.3842C459.128 111.434 467.675 140.035 508.403 151.172C589.706 162.027 640.794 80.5526 658.993 45.0194C666.606 30.1605 686.83 -9.32362 686.83 -38.5755C686.83 -56.1949 679.599 -67.46 665.333 -72.074C625.34 -84.9947 570.305 -56.4059 554.398 -47.3493C523.604 -29.803 498.073 -5.66018 480.566 22.4881C466.94 44.3969 459.128 68.7797 459.128 89.3842V89.3842Z"
              fill="${color}"/>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M375.246 62.7541C394.116 25.0224 425.151 -10.1946 447.987 -31.1062L450.013 -28.8937C427.348 -8.13865 396.584 26.7944 377.929 64.096C359.243 101.46 352.949 140.648 378.147 170.533C442.191 246.493 570.099 263.666 640.363 182.022C697.073 116.127 762.218 12.7212 787.706 -30.7585L790.294 -29.2414C764.782 14.2789 699.527 117.873 642.637 183.978C570.901 267.334 440.808 249.507 375.853 172.467C349.451 141.152 356.407 100.423 375.246 62.7541Z"
              fill="${color}"/>
          </g>
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="984" height="358" fill="white"/>
          </clipPath>
        </defs>
      </svg>`,
    'default-image-2': `
      <svg width="100%" height="100%" viewBox="0 0 984 358" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice">
        <g clip-path="url(#clip0)">
          <rect width="984" height="358" fill="#121212"/>
          <path
            d="M1045.82 443.694C1164.36 443.694 1260.46 347.598 1260.46 229.057C1260.46 110.517 1164.36 14.4204 1045.82 14.4204C927.283 14.4204 831.187 110.517 831.187 229.057C831.187 347.598 927.283 443.694 1045.82 443.694Z"
            fill="url(#paint0_linear)"/>
          <path
            d="M607.013 655.26C725.553 655.26 821.65 559.164 821.65 440.623C821.65 322.082 725.553 225.986 607.013 225.986C488.472 225.986 392.376 322.082 392.376 440.623C392.376 559.164 488.472 655.26 607.013 655.26Z"
            stroke="#F8F0E4"
            stroke-miterlimit="10"/>
          <path
            d="M1059.36 150.625C1177.9 150.625 1274 54.5285 1274 -64.0122C1274 -182.553 1177.9 -278.649 1059.36 -278.649C940.822 -278.649 844.726 -182.553 844.726 -64.0122C844.726 54.5285 940.822 150.625 1059.36 150.625Z"
            stroke="#F8F0E4"
            stroke-miterlimit="10"/>
          <path
            d="M661.143 67.9379C690.693 67.9379 714.649 43.9824 714.649 14.4318C714.649 -15.1187 690.693 -39.0742 661.143 -39.0742C631.592 -39.0742 607.637 -15.1187 607.637 14.4318C607.637 43.9824 631.592 67.9379 661.143 67.9379Z"
            fill="url(#paint1_linear)"/>
          <path
            d="M318.672 287.122C328.939 287.122 337.263 278.798 337.263 268.531C337.263 258.264 328.939 249.94 318.672 249.94C308.405 249.94 300.081 258.264 300.081 268.531C300.081 278.798 308.405 287.122 318.672 287.122Z"
            fill="${color}"/>
          <path
            d="M844.726 516.002C924.809 516.002 989.729 451.082 989.729 370.999C989.729 290.916 924.809 225.996 844.726 225.996C764.643 225.996 699.723 290.916 699.723 370.999C699.723 451.082 764.643 516.002 844.726 516.002Z"
            fill="${color}"/>
          <path
            d="M122.637 328.532C241.178 328.532 337.274 232.436 337.274 113.895C337.274 -4.64594 241.178 -100.742 122.637 -100.742C4.09624 -100.742 -92 -4.64594 -92 113.895C-92 232.436 4.09624 328.532 122.637 328.532Z"
            fill="url(#paint2_linear)"/>
          <path
            d="M255.45 65.718C255.514 48.2928 241.441 34.1146 224.016 34.05C206.59 33.9855 192.412 48.059 192.348 65.4842C192.283 82.9093 206.357 97.0875 223.782 97.1521C241.207 97.2166 255.385 83.1431 255.45 65.718Z"
            stroke="#F8F0E4"
            stroke-miterlimit="10"/>
        </g>
        <defs>
          <linearGradient id="paint0_linear" x1="1045.82" y1="443.694" x2="1045.82" y2="14.4313" gradientUnits="userSpaceOnUse">
            <stop stop-color="#3A3A3A"/>
            <stop offset="0.921875" stop-color="#181818"/>
          </linearGradient>
          <linearGradient id="paint1_linear" x1="661.143" y1="67.9362" x2="661.143" y2="-39.0739" gradientUnits="userSpaceOnUse">
            <stop stop-color="${color}" stop-opacity="0.6"/>
            <stop offset="1" stop-color="${color}"/>
          </linearGradient>
          <linearGradient id="paint2_linear" x1="122.632" y1="328.526" x2="122.632" y2="-100.737" gradientUnits="userSpaceOnUse">
            <stop stop-color="#3A3A3A"/>
            <stop offset="1"/>
          </linearGradient>
          <clipPath id="clip0">
            <rect width="984" height="358" fill="white"/>
          </clipPath>
        </defs>
      </svg>`,
    'default-image-3': `
      <svg width="100%" height="100%" viewBox="0 0 984 358" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice">
        <g clip-path="url(#clip0)">
          <rect width="984" height="358" fill="#121212"/>
          <g opacity="0.5">
            <circle cx="362.82" cy="249.82" r="165.649" fill="${color}"/>
            <circle cx="361.865" cy="248.865" r="268.02" fill="${color}" fill-opacity="0.6"/>
            <circle cx="362" cy="249" r="360" fill="${color}" fill-opacity="0.2"/>
            <circle cx="362" cy="238" r="449" fill="${color}" fill-opacity="0.2"/>
          </g>
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="984" height="358" fill="white"/>
          </clipPath>
        </defs>
      </svg>`,
    'default-image-4': `
      <svg width="100%" height="100%" viewBox="0 0 984 358" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice">
        <g clip-path="url(#clip0)">
          <rect width="984" height="358" fill="#121212"/>
          <path d="M-44 -5H1028V866H-44V-5Z" fill="url(#paint0_angular)"/>
        </g>
        <defs>
          <radialGradient id="paint0_angular" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(492 199.5) rotate(-90) scale(374.5 536.586)">
            <stop offset="0.0275041" stop-color="${color}"/>
            <stop offset="0.081535" stop-color="${color}"/>
            <stop offset="0.318391" stop-color="#181818"/>
            <stop offset="0.541868" stop-color="#181818"/>
            <stop offset="0.604436" stop-color="#181818"/>
            <stop offset="0.882876" stop-color="${color}"/>
          </radialGradient>
          <clipPath id="clip0">
            <rect width="984" height="358" fill="white"/>
          </clipPath>
        </defs>
      </svg>`,
    'default-image-5': `
      <svg width="100%" height="100%" viewBox="0 0 984 358" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice">
        <g clip-path="url(#clip0)">
          <rect width="984" height="358" fill="#121212"/>
          <rect width="2442.05" height="893.107" transform="matrix(-0.634483 -0.772936 -0.84242 0.538821 2066.99 1259.77)" fill="url(#paint0_linear)"/>
          <rect width="2887.67" height="893.107" transform="matrix(-0.634483 -0.772936 -0.84242 0.538821 1555.55 1431.98)" fill="url(#paint1_linear)"/>
          <rect width="2887.67" height="893.107" transform="matrix(-0.634483 -0.772936 -0.84242 0.538821 1300.55 1431.98)" fill="url(#paint2_linear)"/>
          <rect width="2718.8" height="893.107" transform="matrix(-0.634483 -0.772936 -0.84242 0.538821 1768.05 1366.72)" fill="url(#paint3_linear)" fill-opacity="0.7"/>
        </g>
        <defs>
          <linearGradient id="paint0_linear" x1="1221.02" y1="0" x2="1221.02" y2="893.107" gradientUnits="userSpaceOnUse">
            <stop stop-color="${color}"/>
            <stop offset="0.541667" stop-color="#121212"/>
          </linearGradient>
          <linearGradient id="paint1_linear" x1="1443.83" y1="0" x2="1443.83" y2="893.107" gradientUnits="userSpaceOnUse">
            <stop stop-color="${color}"/>
            <stop offset="0.541667" stop-color="#121212"/>
          </linearGradient>
          <linearGradient id="paint2_linear" x1="1443.83" y1="0" x2="1443.83" y2="893.107" gradientUnits="userSpaceOnUse">
            <stop stop-color="${color}"/>
            <stop offset="0.541667" stop-color="#121212"/>
          </linearGradient>
          <linearGradient id="paint3_linear" x1="1359.4" y1="0" x2="1359.4" y2="893.107" gradientUnits="userSpaceOnUse">
            <stop stop-color="${color}"/>
            <stop offset="0.541667" stop-color="#121212"/>
          </linearGradient>
          <clipPath id="clip0">
            <rect width="984" height="358" fill="white"/>
          </clipPath>
        </defs>
      </svg>`,
    'default-image-6': `
      <svg width="100%" height="100%" viewBox="0 0 984 358" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice">
        <g clip-path="url(#clip0)">
          <rect width="984" height="358" fill="#121212"/>
          <path
            opacity="0.1"
            d="M234.167 -440H1965V878.403H-380L-373.593 309.009L-221.653 265.494C59.328 265.494 16.09 118.119 95.041 -118.732C202.131 -440 139.891 -305.752 234.167 -440Z"
            fill="url(#paint0_linear)"/>
          <path
            opacity="0.1"
            d="M282.678 -440H1965V878.403H-380L-373.593 309.009L-221.653 265.494C59.328 265.494 16.09 118.119 95.041 -118.732C202.131 -440 188.402 -305.752 282.678 -440Z"
            fill="url(#paint1_linear)"/>
          <path
            opacity="0.4"
            d="M796.161 -440H1965V878.403H-380L-372 265.494H87.7185C368.7 265.494 471.986 197.254 540.792 -42.8125C563.753 -122.923 665.273 -252.053 665.273 -252.053C665.273 -252.053 772.363 -328.899 796.161 -440Z"
            fill="url(#paint2_linear)"/>
          <path
            opacity="0.2"
            d="M-88.5162 -440C-88.5162 -440 53.3087 -391.856 163.109 -271.496C220.64 -208.432 306.233 -209.692 401.009 -153.914C490.679 -101.141 605.968 70.1406 676.423 208.092C732.742 318.363 781.991 442.506 834.103 552.025C890.373 670.284 951.562 771.88 1073.17 831.527C1168.95 878.501 1339.27 858.035 1491.69 858.035C1826.58 858.035 1963.83 595.972 1963.83 595.972V878.403H-377.656V-440H-88.5162Z"
            fill="url(#paint3_linear)"/>
          <path
            opacity="0.3"
            d="M-377.656 -420.077C-377.656 -420.077 -284.51 -387.982 -219.318 -290.728C-146.098 -181.498 58.6991 -164.333 195.289 -127.809C354.536 -85.2255 470.678 13.3507 612.642 208.212C817.658 489.618 906.216 620.691 1016.27 771.024C1086.74 867.295 1461.08 878.403 1461.08 878.403H-377.656V-420.077Z"
            fill="url(#paint4_linear)"/>
          <path
            opacity="0.3"
            d="M-379.547 -118.301C-379.547 -118.301 -266.674 -134.798 -180.874 -108.95C-59.8223 -72.4822 0.334068 3.14113 105.584 31.311C228.293 64.1536 309.189 78.3969 477.056 155.676C692.362 254.794 755.624 439.506 900.275 639.109C1042.58 835.475 1088.78 883.164 1088.78 883.164H-379.547V-118.301Z"
            fill="url(#paint5_linear)"/>
        </g>
        <defs>
          <linearGradient id="paint0_linear" x1="792.5" y1="-440" x2="792.5" y2="878.403" gradientUnits="userSpaceOnUse">
            <stop stop-color="${color}"/>
            <stop offset="1" stop-color="${color}" stop-opacity="0"/>
          </linearGradient>
          <linearGradient id="paint1_linear" x1="792.5" y1="-440" x2="792.5" y2="878.403" gradientUnits="userSpaceOnUse">
            <stop stop-color="${color}"/>
            <stop offset="1" stop-color="${color}" stop-opacity="0"/>
          </linearGradient>
          <linearGradient id="paint2_linear" x1="793" y1="135.5" x2="1256.5" y2="135.5" gradientUnits="userSpaceOnUse">
            <stop stop-color="${color}"/>
            <stop offset="1" stop-color="${color}" stop-opacity="0"/>
          </linearGradient>
          <linearGradient id="paint3_linear" x1="793.086" y1="-440" x2="793.086" y2="878.403" gradientUnits="userSpaceOnUse">
            <stop stop-color="${color}"/>
            <stop offset="1" stop-color="${color}" stop-opacity="0"/>
          </linearGradient>
          <linearGradient id="paint4_linear" x1="541.71" y1="-420.077" x2="541.71" y2="379.5" gradientUnits="userSpaceOnUse">
            <stop stop-color="${color}"/>
            <stop offset="1" stop-color="${color}" stop-opacity="0"/>
          </linearGradient>
          <linearGradient id="paint5_linear" x1="354.618" y1="-123.488" x2="354.618" y2="358.5" gradientUnits="userSpaceOnUse">
            <stop stop-color="${color}"/>
            <stop offset="1" stop-color="${color}" stop-opacity="0"/>
          </linearGradient>
          <clipPath id="clip0">
            <rect width="984" height="358" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    `,
  };

  return inlineSvgCovers[coverName];
};

export const defaultCoversNames = [
  'default-image-1',
  'default-image-2',
  'default-image-3',
  'default-image-4',
  'default-image-5',
  'default-image-6',
];
