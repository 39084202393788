import { Component, OnInit } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';

import { IEvent, ITicket } from 'src/app/core/models';
import { TicketsService } from 'src/app/core/services';
import { trackByFn } from 'src/app/core/utils';

@Component({
  selector: 'app-tickets-single-select-dialog',
  templateUrl: './tickets-single-select-dialog.component.html',
  styleUrls: ['./tickets-single-select-dialog.component.scss'],
  providers: [CurrencyPipe],
})
export class TicketsSingleSelectDialogComponent implements OnInit {
  loading = true;
  tickets: ITicket[] = [];
  selectedTicket: ITicket;
  trackByFn = trackByFn;
  event: IEvent;

  get totalAmount(): string {
    const result = this.tickets.reduce((acc: number, curr: ITicket) => {
      return acc + curr.numberOfPurchaseTickets * Number(curr.price);
    }, 0);
    if (this.tickets.length) {
      return this.currencyPipe.transform(result, this.tickets[0]?.currency);
    }
    return '0';
  }

  get isBtnDisabled(): boolean {
    return !this.selectedTicket;
  }

  constructor(
    private ticketsService: TicketsService,
    private currencyPipe: CurrencyPipe,
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
  ) {}

  async ngOnInit(): Promise<void> {
    this.event = this.config.data.event;
    this.tickets = await this.ticketsService.getOnDemandTickets(this.event.id);
    this.loading = false;
  }

  async onCheckout(): Promise<void> {
    this.loading = true;
    this.ref.close(this.selectedTicket);
  }

  onTicket(ticket: ITicket): void {
    if (ticket.id == this.selectedTicket?.id) {
      this.selectedTicket = null;
    } else {
      this.selectedTicket = ticket;
    }
  }
}
