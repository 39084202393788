import * as moment from 'moment';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ChatConversation } from 'src/app/core/models/chat';
import { compareDate } from 'src/app/core/utils/utils';

@Component({
  selector: 'app-chat-conversations-list',
  templateUrl: './chat-conversations-list.component.html',
  styleUrls: ['./chat-conversations-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatConversationsListComponent {
  @Input() chatConversations?: ChatConversation[];

  @Output() chatConversationSelected = new EventEmitter<ChatConversation>();

  constructor(
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
  ) {
    // force checking to update last message elapsed time
    setInterval(() => cdr.markForCheck());
  }

  getTextForTooltip(chatConversation: ChatConversation): string {
    const result: string = !chatConversation.conversationWithUser?.allowChat
      ? 'chat.chatListConversation.tooltip'
      : '';

    return result;
  }

  chatConversationSelectedAction(chatConversation: ChatConversation): void {
    if (!chatConversation.conversationWithUser.allowChat) {
      return;
    }
    this.chatConversationSelected.emit(chatConversation);
  }

  timeToString(date: Date): string {
    const now = new Date();
    const diff = (now.getTime() - date.getTime()) / 1000 / 60;
    const currentLang = this.translateService.currentLang;

    if (diff <= 1) {
      const result: string = this.translateService.instant('chat.chatWindow.chatList.currentTime');
      return result;
    } else if (diff < 60) {
      // less than an hour
      const min = Math.floor(diff);
      const result: string = this.translateService.instant('chat.chatWindow.chatList.minAgo', {
        min,
      });
      return result;
    } else if (diff > 60 && compareDate(now, date)) {
      // less than a day
      const dateResult =
        currentLang === 'en' ? moment(date).format('LT') : moment(date).format('HH:mm');
      const result = currentLang === 'en' ? dateResult : `${dateResult} Uhr`;
      return result;
    } else {
      const result =
        currentLang === 'en' ? moment(date).format('L') : moment(date).format('DD.MM.YYYY');
      return result;
    }
  }
}
