<div class="page-container">
  <div class="edit-plan-container">
    <h1>{{ 'adminPlans.editPlanTitle' | translate }}</h1>

    <div class="c-mt-4 c-mb-8">
      <p-divider styleClass="custom-divider-horizontal"></p-divider>
    </div>

    <app-admin-plan-form></app-admin-plan-form>
  </div>
</div>
