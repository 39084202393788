import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minutesSeconds',
})
export class MinutesSecondsPipe implements PipeTransform {
  transform(value: number): string {
    const minutes: number = Math.floor(value / 60);
    const seconds: string = String(value - minutes * 60).padStart(2, '0');
    return minutes + ':' + seconds;
  }
}
