import { Injectable } from '@angular/core';

import { IReference, IFullMetadata, IStorage, Storage } from 'src/app/firebase';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private firestorage: IStorage;
  private firestorageRef: any;

  constructor() {
    this.firestorage = Storage();
    this.firestorageRef = Storage().ref();
  }

  async getOne(folderPath: string, fileName: string): Promise<any> {
    try {
      const fileRef: IReference = this.firestorage.ref(folderPath).child(fileName);
      const downloadUrl: any = fileRef.getDownloadURL();

      return downloadUrl;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async upload(file: File, folderPath: string, fileName: string): Promise<string> {
    try {
      const fileRef = this.firestorage.ref(folderPath).child(fileName);
      const metadata = { contentType: file.type };
      await fileRef.put(file, metadata);

      return await fileRef.getDownloadURL();
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  public async uploadBase64(fileString: any, folder: string = 'images'): Promise<string> {
    const base64result = fileString.split(';base64,')[1];
    const metadata = { contentType: 'image/jpeg' };
    const fileName = 'file_' + String(new Date().getTime());

    try {
      const file = await this.firestorageRef
        .child(folder)
        .child(fileName)
        .putString(base64result, 'base64', metadata);
      return await file.ref.getDownloadURL();
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public async delete(file: any): Promise<any> {
    try {
      const fileRef = this.firestorage.refFromURL(file);
      // return await this.firestorageRef.child(fileRef.fullPath).delete();
      return await fileRef.delete();
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public async getFileFormStorage(url: string): Promise<IFullMetadata> {
    try {
      const fileRef = this.firestorage.refFromURL(url);
      const file: IFullMetadata = await fileRef.getMetadata();

      return file;
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  public async getChildFileFromStorage(
    url: string,
  ): Promise<{ file: IFullMetadata; downloadURL: string }> {
    try {
      const fileRef = this.firestorage.ref(url);
      const file: IFullMetadata = await fileRef.getMetadata();
      const downloadURL: string = await fileRef.getDownloadURL();

      return { file, downloadURL };
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }
}
