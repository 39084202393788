import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';

import { IAsset, AssetStatus } from 'src/app/core/models';
import { trackByFn } from 'src/app/core/utils';
import {
  logoAvatar,
  imgPreloader,
  jpgImg,
  pdfImg,
  pngImg,
  videoImg,
  zipImg,
  SharedModule,
} from 'src/app/shared';
import { AssetEventBannerComponent, ClickOutsideDirective } from 'src/app/standalone/shared';
import { AssetType, AssetFileTypes } from 'src/app/core/enums';
import { chevronIconAnimation, dropDownAnimation } from './asset-card.animations';

@Component({
  selector: 'app-asset-card',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    ClickOutsideDirective,
    CdkDrag,
    CdkDragHandle,
    AssetEventBannerComponent,
  ],
  templateUrl: './asset-card.component.html',
  styleUrls: ['./asset-card.component.scss'],
  animations: [dropDownAnimation, chevronIconAnimation],
})
export class AssetCardComponent implements OnInit {
  @Input() asset: IAsset;
  @Output() changeAssetStatus = new EventEmitter<string>();
  @Output() editAsset = new EventEmitter<void>();
  @Output() removeAsset = new EventEmitter<void>();

  items: MenuItem[] = [
    {
      label: 'Edit',
      icon: 'fa-regular fa-pen-to-square',
      command: () => {
        this.navigateToAsset();
      },
    },
    {
      label: 'Delete',
      icon: 'fa-regular fa-trash',
      command: () => {
        this.deleteAsset();
      },
    },
  ];
  logoAvatar = logoAvatar;
  imgPreloader = imgPreloader;
  assetStatus = AssetStatus;
  assetStatuses: { value: AssetStatus; label: string }[];
  expandDropdown = false;
  trackByFn = trackByFn;
  assetType = AssetType;
  assetEventBannerContainerStyles = {
    background: '#323232',
    'border-radius': '0.25rem',
  };
  assetEventBannerMonthStyles = {
    'font-size': '0.625rem',
    'line-height': '0.625rem',
  };
  assetEventBannerDateStyle = {
    'font-size': '1.125rem',
    'line-height': '1.125rem',
  };

  constructor(private translateService: TranslateService) {}

  get getClassForCircleMark(): string {
    if (this.asset?.status === AssetStatus.PUBLISHED) {
      return 'green';
    }

    return 'orange';
  }

  get currentAssetStatus(): string {
    return this.translateService.instant(`assetCard.statuses.${this.asset?.status}`);
  }

  ngOnInit(): void {
    this.assetStatuses = Object.keys(AssetStatus).map((status: AssetStatus) => ({
      value: status,
      label: this.translateService.instant(`assetCard.statuses.${AssetStatus[status]}`),
    }));
  }

  getImagePlaceholder(asset: IAsset): string {
    if (asset.type === AssetType.VIDEO) {
      return videoImg;
    }

    switch (asset.fileType) {
      case AssetFileTypes.PNG:
        return pngImg;
      case AssetFileTypes.JPG:
        return jpgImg;
      case AssetFileTypes.PDF:
        return pdfImg;
      case AssetFileTypes.ZIP:
        return zipImg;
      default:
        return pngImg;
    }
  }

  onCloseToggleDropdown(): void {
    this.expandDropdown = false;
  }

  onToggleDropdown(): void {
    this.expandDropdown = !this.expandDropdown;
  }

  onSelectAssetStatus(status: { value: AssetStatus; label: string }): void {
    if (this.asset.status !== status.value) {
      this.changeAssetStatus.emit(status.value);
    }
    this.onCloseToggleDropdown();
  }

  navigateToAsset(event: Event = null): void {
    if (event) {
      event.stopPropagation();
    }
    this.editAsset.emit();
  }

  deleteAsset(): void {
    this.removeAsset.emit();
  }
}
