<div class="container">
  <h1>{{ 'declineMeetingDialog.declineMeeting' | translate }}</h1>
  <app-input-label
    labelType="required"
    text="declineMeetingDialog.message">
  </app-input-label>
  <textarea
    class="custom-input-textarea resize-none custom-input-textarea-required"
    [rows]="2"
    pInputTextarea
    [formControl]="reason"
    placeholder="{{ 'declineMeetingDialog.message' | translate }}">
  </textarea>
  <button
    class="btn"
    pButton
    label="{{ 'buttons.sendResponseNow' | translate }}"
    [disabled]="reason.invalid"
    (click)="onSendReason()"></button>
</div>
