import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-course-new-coupon',
  templateUrl: './course-new-coupon.component.html',
  styleUrls: ['./course-new-coupon.component.scss'],
})
export class CourseNewCouponComponent {
  constructor(private translateService: TranslateService) {}
}
