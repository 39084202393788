import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

import { IHub, ITag } from 'src/app/core/models';
import { BrandsService, HubsService } from 'src/app/core/services';
import { HubsStore, TagsStore } from 'src/app/core/stores';
import { asyncDelay } from 'src/app/core/utils';
import { AppStore } from 'src/app/app.store';
import { imgPreloader, logoAvatar } from 'src/app/shared';

export interface IHubBrandEntry {
  id: string;
  brandId: string;
  logo: string;
  name: string;
  ownerName: string;
  ownerDisplayEmail: string;
  ownerEmail: string;
}

@Component({
  templateUrl: './attach-brands-to-hub-dialog.component.html',
  styleUrls: ['./attach-brands-to-hub-dialog.component.scss'],
})
export class AttachBrandsToHubDialogComponent implements OnInit {
  public attachLoading = false;
  public loadingEntries = true;
  public entries: IHubBrandEntry[] = [];
  public selectedEntries: IHubBrandEntry[] = [];
  public totalRecords = 0;
  public pageSize = 10;
  public tags: ITag[] = [];
  public selectedTags: ITag[] = [];
  public columns: any[] = [
    { field: 'logo', template: 'logoCell', titleTranslationKey: 'adminBrands.tableColImage' },
    { field: 'name', titleTranslationKey: 'adminBrands.tableColName' },
    { field: 'ownerName', titleTranslationKey: 'adminBrands.tableColBrandOwner' },
    { field: 'ownerEmail', titleTranslationKey: 'adminBrands.tableColOwnerEmail' },
  ];
  public imgPreloader = imgPreloader;
  public logoAvatar = logoAvatar;

  private brandSearchTerm = '';
  private tagSearchTerm = '';
  private page = 0;

  public get filteredTags(): ITag[] {
    return this.tags.filter((tag) =>
      tag.title.toLowerCase().includes(this.tagSearchTerm.toLowerCase()),
    );
  }

  public get hub(): IHub {
    return this.hubStore.hub;
  }

  constructor(
    private hubsService: HubsService,
    private hubStore: HubsStore,
    private tagStore: TagsStore,
    private messageService: MessageService,
    private translateService: TranslateService,
    private brandsService: BrandsService,
    public appStore: AppStore,
  ) {}

  async ngOnInit(): Promise<void> {
    await Promise.all([this.fetchBrands()]);
    this.tags = this.tagStore.tags;
    this.loadingEntries = false;
  }

  public tagSearchChanged(value: string): void {
    this.tagSearchTerm = value;
  }

  public async brandSearchChanged(value: string): Promise<void> {
    this.loadingEntries = true;
    this.brandSearchTerm = value;
    await this.fetchBrands();
    this.loadingEntries = false;
  }

  public async fetchEntries(event: any): Promise<void> {
    this.loadingEntries = true;
    this.pageSize = event.rows;
    this.page = event.first / this.pageSize;
    await this.fetchBrands();
    this.loadingEntries = false;
  }

  public async attach(): Promise<void> {
    this.attachLoading = true;
    for (const entry of this.selectedEntries) {
      const brand = await this.brandsService.getOne(entry.id);
      await this.hubsService.addBrand(this.hub, brand);
    }

    this.messageService.add({
      key: 'user',
      severity: 'success',
      summary: this.translateService.instant('success'),
      detail: this.translateService.instant('adminBrands.brandSuccessfullyAttached', {
        count: this.selectedEntries.length,
      }),
      styleClass: 'custom-toast',
    });
    this.attachLoading = false;
    this.selectedEntries = [];
    await this.fetchBrands();
  }

  public async tagSelectionChanged(tags: ITag[]): Promise<void> {
    this.selectedTags = tags;
    await this.fetchBrands();
  }

  private async fetchBrands(): Promise<void> {
    const response = await this.hubsService.fetchUnattachedBrands(
      this.hub.id,
      this.page,
      this.pageSize,
      [...this.selectedTags.map((t) => t.id), this.brandSearchTerm].join(' '),
    );

    this.totalRecords = response.total;
    this.entries = response.results.map(
      (result: any) =>
        ({
          id: result.objectID,
          logo: result.logo,
          name: result.name,
          ownerDisplayEmail: result.owner?.displayEmail
            ? result.owner?.displayEmail
            : result.owner?.email,
          ownerEmail: result.owner?.email.toLowerCase(),
          ownerName: `${result.owner?.firstName} ${result.owner?.lastName}`,
        }) as IHubBrandEntry,
    );
  }
}
