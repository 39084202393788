<ng-container *ngIf="loading">
  <app-loader></app-loader>
</ng-container>

<div
  *ngIf="!loading"
  class="container">
  <h1 class="admin-page-title">{{ 'adminEventRegistration.pageTitle' | translate }}</h1>

  <div class="c-mt-4 c-mb-8">
    <p-divider styleClass="custom-divider-horizontal"></p-divider>
  </div>

  <!-- Edit profile information block -->
  <div class="form-block">
    <div class="left-side">
      <h2 class="title">{{ 'adminEventRegistration.editProfileTitle' | translate }}</h2>
      <p class="description">{{ 'adminEventRegistration.editProfileDescLine1' | translate }}</p>
      <p class="description">{{ 'adminEventRegistration.editProfileDescLine2' | translate }}</p>
    </div>
    <div class="right-side bg-tr">
      <!-- Allow skipping registration input -->
      <div class="form-field-input-switch-block c-px-0 c-mb-4">
        <p-inputSwitch
          class="custom-input-switch"
          [formControl]="isAllowSkipReg"
          inputId="is-allow-skip-reg-input">
        </p-inputSwitch>
        <app-input-label
          labelType="switch-checkbox"
          for="is-allow-skip-reg-input"
          text="adminEventRegistration.allowSkippingReg">
        </app-input-label>
      </div>

      <!-- Order list -->
      <div class="order-list-container">
        <p-orderList
          [value]="eventRegistrationFields"
          [dragdrop]="true"
          styleClass="list-styles-drag">
          <ng-template
            let-field
            pTemplate="item">
            <div class="field-item">
              <i class="fa-solid fa-grip-vertical grip-vertical"></i>
              <span>{{ getCorrectTranslationForField(field) }}</span>
              @if (field.fieldName !== 'divider') {
                <div class="star-container">
                  @if (field.isRequired) {
                    <div class="selected-star">
                      <app-button
                        buttonRoundIcon="fa-regular fa-star"
                        [buttonType]="buttonType.ROUND"
                        [buttonStyle]="buttonStyle.FILLED_SECONDARY"
                        [buttonSize]="buttonSize.SMALL"
                        [scssStyles]="stylesForVisibleAndRequiredField()"
                        (click)="field.isRequired = !field.isRequired" />
                    </div>
                  }

                  @if (!field.isRequired) {
                    <app-button
                      buttonRoundIcon="fa-regular fa-star"
                      [buttonType]="buttonType.ROUND"
                      [buttonStyle]="buttonStyle.FILLED_SECONDARY"
                      [buttonSize]="buttonSize.SMALL"
                      (click)="field.isRequired = !field.isRequired" />
                  }
                </div>
              }
              <div class="eye-container">
                @if (field.isVisible) {
                  <app-button
                    buttonRoundIcon="fa-regular fa-eye"
                    [buttonType]="buttonType.ROUND"
                    [buttonStyle]="buttonStyle.FILLED_SECONDARY"
                    [buttonSize]="buttonSize.SMALL"
                    [scssStyles]="stylesForVisibleAndRequiredField()"
                    (click)="field.isVisible = !field.isVisible" />
                }

                @if (!field.isVisible) {
                  <app-button
                    buttonRoundIcon="fa-regular fa-eye-slash"
                    [buttonType]="buttonType.ROUND"
                    [buttonStyle]="buttonStyle.FILLED_SECONDARY"
                    [buttonSize]="buttonSize.SMALL"
                    (click)="field.isVisible = !field.isVisible" />
                }
              </div>
            </div>
          </ng-template>
        </p-orderList>
      </div>
    </div>
  </div>

  <div class="c-mt-2">
    <p-divider styleClass="custom-divider-horizontal"></p-divider>
  </div>

  <!-- Event Consent block -->
  <div class="form-block c-my-9">
    <div class="left-side">
      <h2 class="title">{{ 'adminEventRegistration.eventConsent' | translate }}</h2>
      <p class="description">{{ 'adminEventRegistration.eventConsentDescLine1' | translate }}</p>
      <p class="description">{{ 'adminEventRegistration.eventConsentDescLine2' | translate }}</p>
    </div>
    <div class="right-side c-py-0 bg-tr">
      <!-- Group checkboxes input -->
      <div class="form-field-input-switch-block c-px-0 c-mb-4">
        <p-inputSwitch
          class="custom-input-switch"
          [formControl]="isGroupCheckboxes"
          inputId="group-checkboxes-input"
          [disabled]="!eventConsents.length">
        </p-inputSwitch>
        <app-input-label
          labelType="switch-checkbox"
          for="group-checkboxes-input"
          text="adminEventRegistration.groupCheckboxes">
        </app-input-label>
      </div>

      <!-- Consents table -->
      <div class="table-container">
        <admin-table
          [loading]="loadingEventConsents"
          [paginator]="false"
          [lazy]="false"
          [columns]="columns"
          [entries]="eventConsents"
          [enableReorder]="true"
          emptyStateKey="adminEventRegistration.emptyState"
          [isShowTotalEntries]="false"
          (rowReorder)="onRowReorder($event)">
          <ng-template
            let-eventConsent
            epTemplateKey="type">
            {{ eventConsent.type | translate }}
          </ng-template>
          <ng-template
            let-eventConsent
            epTemplateKey="required">
            <span *ngIf="eventConsent.type === eventConsentType.SINGLE">
              {{
                eventConsent.required
                  ? ('adminEventRegistration.required' | translate)
                  : ('adminEventRegistration.optional' | translate)
              }}
            </span>
            <span *ngIf="eventConsent.type === eventConsentType.MULTI">-</span>
          </ng-template>
          <ng-template
            let-eventConsentAction
            epTemplateKey="actionsCell">
            <div class="table-btn-actions-container">
              <app-button
                [buttonType]="buttonType.ROUND"
                [buttonSize]="buttonSize.MEDIUM"
                [buttonStyle]="buttonStyle.FILLED_SECONDARY"
                [loading]="eventConsentAction?.isActionBtnShowingSpinner"
                buttonRoundIcon="fa-regular fa-cloud-arrow-down"
                buttonTooltip="{{ 'adminEventRegistration.downloadTooltip' | translate }}"
                (click)="downloadConsentReport($event, eventConsentAction)" />
              <app-button
                [buttonType]="buttonType.ROUND"
                [buttonSize]="buttonSize.MEDIUM"
                [buttonStyle]="buttonStyle.FILLED_SECONDARY"
                [loading]="eventConsentAction?.isActionBtnShowingSpinner"
                buttonRoundIcon="far fa-pen-to-square"
                (click)="editConsent($event, eventConsentAction)" />
              <app-button
                [buttonType]="buttonType.ROUND"
                [buttonSize]="buttonSize.MEDIUM"
                [buttonStyle]="buttonStyle.FILLED_DANGER"
                [loading]="eventConsentAction?.isActionBtnShowingSpinner"
                buttonRoundIcon="far fa-trash-can"
                (click)="deleteConsent($event, eventConsentAction)" />
            </div>
          </ng-template>
        </admin-table>
      </div>

      <div class="text-right">
        <button
          class="primary-text-color"
          pButton
          type="button"
          label="+ {{ 'adminEventRegistration.createNewCheckbox' | translate }}"
          (click)="onCreateEventConsent()"></button>
      </div>
    </div>
  </div>

  <p-divider styleClass="custom-divider-horizontal"></p-divider>

  <form [formGroup]="form">
    <!-- Edit registration steps block -->
    <div class="form-block c-my-9">
      <div class="left-side">
        <h2 class="title">
          {{ 'adminEventRegistration.editRegistrationsStepsTitle' | translate }}
        </h2>
        <p class="description">
          {{ 'adminEventRegistration.editRegistrationsStepsDescLine1' | translate }}
        </p>
        <p class="description">
          {{ 'adminEventRegistration.editRegistrationsStepsDescLine2' | translate }}
        </p>
      </div>
      <div class="right-side">
        <!-- TODO: uncomment during future implementation ⬇ -->
        <!-- Global registration block -->
        <!-- <p class="section-title c-px-8 c-pt-8">{{ 'adminEventRegistration.globalRegistration' | translate }}</p> -->

        <!-- Global signup input -->
        <!-- <div class="form-field-input-switch-block c-mb-4">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="isGlobalSignup"
            inputId="is-global-signup-input">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="is-global-signup-input"
            text="adminEventRegistration.globalSignUpTooltip">
          </app-input-label>
        </div> -->

        <!-- Global tags input -->
        <!-- <div class="form-field-input-switch-block c-mb-4">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="isGlobalTags"
            inputId="is-global-tags-input">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="is-global-tags-input"
            text="adminEventRegistration.globalTags">
          </app-input-label>
        </div> -->
        <!-- TODO: uncomment during future implementation ⬆ -->

        <!-- Event registration block -->
        <p class="section-title c-px-8 c-pt-8">
          {{ 'adminEventRegistration.eventRegistration' | translate }}
        </p>

        <!-- Profile info and tags input -->
        <div class="form-field-input-switch-block c-mb-4">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="isShowProfileAndTags"
            inputId="is-show-profile-and-tags-input">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="is-show-profile-and-tags-input"
            text="adminEventRegistration.profileInformationTags">
          </app-input-label>
        </div>

        <!-- Event tags input -->
        <div class="form-field-input-switch-block c-mb-4">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="isShowEventTags"
            inputId="is-show-event-tags-input">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="is-show-event-tags-input"
            text="adminEventRegistration.eventTags">
          </app-input-label>
        </div>

        <!-- Accommodation & Transfer input -->
        <div class="form-field-input-switch-block c-mb-4">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="isShowAccommodationTransfer"
            inputId="is-show-accommodation-transfer-input">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="is-show-accommodation-transfer-input"
            text="adminEventRegistration.accommodationTransfer">
          </app-input-label>
        </div>

        <!-- Profile appearance input -->
        <div class="form-field-input-switch-block c-mb-4">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="isShowProfileAppearance"
            inputId="is-show-profile-appearance-input">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="is-show-profile-appearance-input"
            text="adminEventRegistration.profileAppearance">
          </app-input-label>
        </div>

        <!-- Event consents input -->
        <div class="form-field-input-switch-block c-pb-8">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="isShowConsents"
            inputId="is-show-consents-input"
            [readonly]="!eventConsents?.length">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="is-show-consents-input"
            text="adminEventRegistration.eventConsent">
          </app-input-label>
        </div>

        <p-divider
          styleClass="custom-divider-horizontal"
          *ngIf="showIsEventTagsMandatory"></p-divider>

        <!-- Event tags mandatory input -->
        <div
          class="form-field-input-switch-block c-py-8"
          *ngIf="showIsEventTagsMandatory">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="isEventTagsMandatory"
            inputId="is-event-tags-mandatory-input">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="is-event-tags-mandatory-input"
            text="adminEventRegistration.eventTagsMandatory">
          </app-input-label>
        </div>
      </div>
    </div>

    <p-divider styleClass="custom-divider-horizontal"></p-divider>

    <!-- Settings block -->
    <div class="form-block c-my-9">
      <div class="left-side">
        <h2 class="title">{{ 'adminEventRegistration.settingsTitle' | translate }}</h2>
        <p class="description">{{ 'adminEventRegistration.settingsDesc' | translate }}</p>
      </div>
      <div class="right-side">
        <!-- Event code switch input -->
        <div class="form-field-input-switch-block c-mb-4 c-pt-8">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="isEventCode"
            inputId="is-event-code-input">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="is-event-code-input"
            text="adminEventRegistration.enableEventCode">
          </app-input-label>
        </div>

        <!-- Event code input -->
        <div class="form-field-block c-mb-4">
          <app-input-label
            [labelType]="form.controls.eventCode.hasError('required') ? 'required' : 'usual'"
            for="event-code-input"
            text="adminEventRegistration.eventCode">
          </app-input-label>
          <input
            class="custom-input-text"
            [class.custom-input-text-required]="form.controls.eventCode.hasError('required')"
            formControlName="eventCode"
            id="event-code-input"
            pInputText
            type="text"
            placeholder="{{ 'adminEventRegistration.eventCode' | translate }}" />
          <app-input-error-message
            [showMessage]="form.controls.eventCode.invalid && form.controls.eventCode.touched"
            errorMessage="{{ 'application.forms.required' | translate }}">
          </app-input-error-message>
        </div>

        <p-divider styleClass="custom-divider-horizontal"></p-divider>

        <!-- RSVP registration input -->
        <div class="form-field-input-switch-block c-mt-8 c-pb-8">
          <p-inputSwitch
            class="custom-input-switch"
            formControlName="isRsvpRegistration"
            inputId="is-rsvp-registration-input">
          </p-inputSwitch>
          <app-input-label
            labelType="switch-checkbox"
            for="is-rsvp-registration-input"
            text="adminEventRegistration.rsvpRegistration">
          </app-input-label>
        </div>
      </div>
    </div>

    <div class="text-right">
      <button
        class="primary-text-color"
        pButton
        type="button"
        [label]="'adminEventRegistration.update' | translate"
        [icon]="isUpdating ? 'pi pi-spin pi-spinner' : null"
        [disabled]="isBtnDisable"
        (click)="updateRegistrationSettings()"></button>
    </div>
  </form>
</div>
