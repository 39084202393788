import { Injectable } from '@angular/core';
import { Firestore, IDocumentData, IFirestore, IQuerySnapshot } from 'src/app/firebase';
import { API_ROUTES as apiRoutes } from 'src/app/shared';
import { IUserHub, UserHubRoles } from 'src/app/core/models';
import { AlgoliaService } from 'src/app/core/services';

@Injectable({
  providedIn: 'root',
})
export class UserHubsService {
  private firestore: IFirestore;

  constructor(private algoliaService: AlgoliaService) {
    this.firestore = Firestore();
  }

  async getUserHub(hubId: string, userId: string): Promise<IUserHub> {
    try {
      const userHubs: IUserHub[] = (
        await this.firestore
          .collection(apiRoutes.userHubs)
          .where('hubId', '==', hubId)
          .where('userId', '==', userId)
          .get()
      ).docs.map((doc) => doc.data() as IUserHub);

      if (!userHubs.length) {
        return null;
      }

      return userHubs[0];
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async getUserHubsByUserId(userId: string): Promise<IUserHub[]> {
    try {
      const userHubs: IUserHub[] = (
        await this.firestore.collection(apiRoutes.userHubs).where('userId', '==', userId).get()
      ).docs.map((doc) => doc.data() as IUserHub);

      return userHubs;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async getOwnersAndAdminsUserHubs(hubId: string): Promise<IUserHub[]> {
    try {
      const userHubs: IUserHub[] = (
        await this.firestore
          .collection(apiRoutes.userHubs)
          .where('hubId', '==', hubId)
          .where('role', 'in', [UserHubRoles.OWNER, UserHubRoles.ADMIN])
          .get()
      ).docs.map((doc) => doc.data() as IUserHub);

      return userHubs;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async isUserIsHubAdmin(hubId: string, userId: string): Promise<boolean> {
    try {
      const hubUsersQuery: IQuerySnapshot<IDocumentData> = await this.firestore
        .collection(apiRoutes.userHubs)
        .where('hubId', '==', hubId)
        .where('userId', '==', userId)
        .get();

      return !!hubUsersQuery.size;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async addUserToHub(userHub: IUserHub): Promise<IUserHub> {
    try {
      const preUserHubReq = await this.firestore.collection(apiRoutes.userHubs).doc();
      await this.firestore
        .collection(apiRoutes.userHubs)
        .doc(preUserHubReq.id)
        .set({ ...userHub, id: preUserHubReq.id });
      await this.algoliaService.createUserHub({ ...userHub, id: preUserHubReq.id });

      return { ...userHub, id: preUserHubReq.id };
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async updateUserHub(userHub: IUserHub): Promise<IUserHub> {
    try {
      await this.firestore
        .collection(apiRoutes.userHubs)
        .doc(userHub.id)
        .update({ ...userHub });
      await this.algoliaService.updateUserHub({ ...userHub });
      return { ...userHub };
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  changeRoleToUserForHubUser(id: string): Promise<void> {
    return this.firestore
      .collection(apiRoutes.userHubs)
      .doc(id)
      .update({ role: UserHubRoles.USER });
  }

  async deleteUserHub(userHubId: string): Promise<boolean> {
    try {
      await this.firestore.collection(apiRoutes.userHubs).doc(userHubId).delete();

      return true;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }
}
