import { Component, Input, OnInit } from '@angular/core';

import { IEvent, ITag, IUser } from 'src/app/core/models';
import { TagsService, ThemesService, UsersService } from 'src/app/core/services';
import { EventsStore, HubsStore, UsersStore } from 'src/app/core/stores';
import { getUserNameAbbreviation, goToLink } from 'src/app/core/utils';

@Component({
  selector: 'app-default-user',
  templateUrl: './default-user.component.html',
  styleUrls: ['./default-user.component.scss'],
})
export class DefaultUserComponent implements OnInit {
  @Input() user: IUser;
  @Input() showBackBtn = true;

  currentEvent: IEvent;
  imageText = getUserNameAbbreviation;
  showBookmarkSpinner = false;
  bookmarkedIds: string[] = [];
  loadingTags = true;
  tags: ITag[] = [];

  get defaultCoversColor(): string {
    const currentTheme = localStorage.getItem('styleTheme');
    const currentThemeProps =
      this.themesService.systemAppearanceSettings[currentTheme + 'Theme'].properties;
    return (
      this.currentEvent?.accentColor ||
      this.hubsStore.hub?.primaryColor ||
      currentThemeProps['--appPrimaryColor']
    );
  }

  get isCalendlyVisible(): boolean {
    return !this.isLoggedInUser && !!this.user?.calendly;
  }

  get isLoggedInUser(): boolean {
    return this.user?.id === this.usersStore.userId;
  }

  constructor(
    private themesService: ThemesService,
    private hubsStore: HubsStore,
    private eventsStore: EventsStore,
    public usersStore: UsersStore,
    private usersService: UsersService,
    private tagService: TagsService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.currentEvent = this.eventsStore.event;
    this.tags = await this.tagService.getByIdsOrderedByTitle(this.user?.tags);
    this.bookmarkedIds = await this.usersService.getUserBookmarkedUserIds(this.usersStore.userId);
    this.loadingTags = false;
  }

  async onBookmark(): Promise<void> {
    this.showBookmarkSpinner = true;
    if (this.bookmarkedIds.includes(this.user.id)) {
      const result: boolean = await this.usersService.unBookmarkUser(
        this.usersStore.userId,
        this.user.id,
      );
      if (result) {
        const elIndex = this.bookmarkedIds.indexOf(this.user.id);
        if (elIndex > -1) this.bookmarkedIds.splice(elIndex, 1);
        this.showBookmarkSpinner = false;
      }
    } else {
      const result: boolean = await this.usersService.bookmarkUser(
        this.usersStore.userId,
        this.user.id,
      );
      if (result) {
        this.bookmarkedIds.push(this.user.id);
        this.showBookmarkSpinner = false;
      }
    }
  }

  getBookmarkedIconClass(): string {
    if (this.showBookmarkSpinner) {
      return 'fa fa-circle-o-notch fa-spin';
    } else {
      return this.bookmarkedIds.includes(this.user?.id)
        ? 'fak fa-lox-bookmark-1 active'
        : 'fak fa-lox-bookmark-1';
    }
  }

  onGoToLink(url: string): void {
    goToLink(url);
  }
}
