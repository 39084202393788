import * as moment from 'moment';
import * as mixpanel from 'mixpanel-browser';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { isSessionLive, parseToMoment } from 'src/app/shared';
import { AppStore } from 'src/app/app.store';
import { AnalyticsEventTypes } from 'src/app/core/enums';
import { EventsStore, HubsStore, UsersStore } from '../../stores';
import {
  ChatMessageAttachment,
  IAppointment,
  IAsset,
  IBrand,
  ICourse,
  IEvent,
  IEventBrand,
  IHub,
  ISession,
  IStage,
  ITag,
  IUser,
} from '../../models';
import { StagesService } from '../stages';
import { SpeakersService } from '../speakers';
import { BrandsService } from '../brands';
import { TagsService } from '../tags';
import { HubsService } from '../hubs';
import { SessionsService } from '../sessions';
import { getTime } from '../../utils';
import { UsersService } from '../users';
import { EventsService } from '../events';
import { UserEventsService } from '../user-events/user-events.service';

@Injectable({
  providedIn: 'root',
})
export class MixpanelService {
  constructor(
    private eventsStore: EventsStore,
    private stagesService: StagesService,
    private speakersService: SpeakersService,
    private brandsService: BrandsService,
    private tagsService: TagsService,
    private hubsService: HubsService,
    private usersStore: UsersStore,
    private sessionsService: SessionsService,
    private usersService: UsersService,
    private router: Router,
    private eventsService: EventsService,
    private hubsStore: HubsStore,
    private translateService: TranslateService,
    private userEventsService: UserEventsService,
    public appStore: AppStore,
  ) {
    this.init();
  }

  private init(): void {
    if (this.appStore.environment.firebase.projectId.includes('pmg-hub')) {
      mixpanel.init(this.appStore.environment.mixpanel.token, { disable_persistence: true });
      return;
    }
    mixpanel.init(this.appStore.environment.mixpanel.token);
  }

  private async identify(user?: IUser): Promise<void> {
    if (user?.id) {
      const country = await this.getUserCountry(user);
      mixpanel.identify(user.id);
      mixpanel.people.set({
        $firstName: user.firstName,
        $lastName: user.lastName,
        $email: user.email,
        $company: user?.company ? user.company : null,
        $jobTitle: user?.position ? user.position : null,
        $address: user?.street ? user.street : null,
        $postCode: user?.postcode ? user.postcode : null,
        $phone: user?.phone ? user.phone : null,
        $cityProfile: user?.city ? user.city : null,
        $countryProfile: country,
      });
    } else {
      mixpanel.identify(null);
    }
  }

  private async getUserCountry(user: IUser): Promise<string> {
    if (!user.country) {
      return null;
    }
    const translationsValues = await this.translateService.getTranslation('en').toPromise();
    const [firstKey, secondKey] = user.country.split('.');
    const country = translationsValues[firstKey][secondKey];
    return country;
  }

  pageVisit(pageTitle: string, pageURL: string): void {
    const eventName = AnalyticsEventTypes.PAGE_VISIT;
    const action = { pageTitle, pageURL };
    this.track(eventName, action);
  }

  // TODO update this method after the app supports different login options (manually, facebook, google)
  login(user: IUser, loginMethod: string): void {
    if (this.appStore.generalSystemSettings.enableEncryption) {
      user = this.usersService.decryptUserData(user) as IUser;
    }
    this.identify(user);
    const eventName = AnalyticsEventTypes.LOGIN;
    this.track(eventName, { loginMethod });
  }

  logout(): void {
    this.identify();
  }

  async signup(
    user: IUser,
    termsAccepted: boolean,
    marketingAccepted: boolean,
    tagsSelectedId: string[],
  ): Promise<void> {
    this.identify(user);
    const eventName = AnalyticsEventTypes.SIGNUP;
    const tagsSelectedName: string[] = await this.getSelectedNames(tagsSelectedId);
    const action = {
      termsAccepted,
      marketingAccepted,
      tagsSelectedId,
      tagsSelectedName,
    };
    this.track(eventName, action);
  }

  passwordResetRequested(): void {
    this.track(AnalyticsEventTypes.PASSWORD_RESET_REQUESTED);
  }

  profileVisit(): void {
    this.track(AnalyticsEventTypes.PROFILE_VISIT);
  }

  pictureUpload(
    fileName: string,
    fileType: string,
    fileSize: number,
    eventName:
      | AnalyticsEventTypes.PROFILE_PICTURE_UPLOAD
      | AnalyticsEventTypes.PROFILE_COVER_UPLOAD,
  ): void {
    this.track(eventName, { fileName, fileType, fileSize });
  }

  pictureRemove(
    fileUrl: string,
    eventName:
      | AnalyticsEventTypes.PROFILE_PICTURE_REMOVE
      | AnalyticsEventTypes.PROFILE_COVER_REMOVE,
  ): void {
    this.track(eventName, { fileUrl });
  }

  chatUpdate(action: 'Enable' | 'Disable'): void {
    const eventName = AnalyticsEventTypes.CHAT_UPDATE;
    this.track(eventName, { action });
  }

  helpCenterClick(pageTitle: string, pageURL: string): void {
    const eventName = AnalyticsEventTypes.HELP_CENTER_CLICK;
    const action = { pageTitle, pageURL };
    this.track(eventName, action);
  }

  eventDiscovery(currentEventsListed: number, invitedEvents: number): void {
    const eventName = AnalyticsEventTypes.EVENT_DISCOVERY;
    const action = { currentEventsListed, invitedEvents };
    this.track(eventName, action);
  }

  async eventView(event: IEvent): Promise<void> {
    this.identify(this.usersStore.user);
    if (!this.usersStore.user) {
      return;
    }
    const eventName = AnalyticsEventTypes.EVENT_VIEW;
    const hub: IHub = await this.hubsService.getOne(event.hubId);
    const eventBrands: IEventBrand[] = await this.brandsService.getBrandsFromEvent(event.id);
    const brandIds: string[] = eventBrands.map((eventBrand: IEventBrand) => eventBrand.brandId);
    const brandNames: string[] = eventBrands.map(
      (eventBrand: IEventBrand) => eventBrand._brand_name_,
    );
    const isUserInvitedToEvent: boolean = await this.isUserInvitedToEvent(
      event.id,
      this.usersStore.user.id,
    );

    const action = {
      eventId: event.id,
      eventName: event._title_,
      hubId: event.hubId,
      hubName: hub._title_,
      brandIds,
      brandNames,
      startDate: event.start,
      endDate: event.end,
      location: event.location,
      ticketsForSale: !!event.eventbriteSync,
      invited: isUserInvitedToEvent,
      live: this.isEventLiveNow(event),
    };

    this.track(eventName, action);
  }

  eventTicketBuy(event: IEvent): void {
    const eventName = AnalyticsEventTypes.EVENT_TICKET_BUY;
    const urlR =
      /(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])/;
    const url = event.eventbriteButton.match(urlR)[0];

    const action = {
      eventId: event.id,
      eventName: event._title_,
      url,
      live: this.isEventLiveNow(event),
    };

    this.track(eventName, action);
  }

  async eventBookmarkedOrUnBookmarked(
    event: IEvent,
    eventName: AnalyticsEventTypes.EVENT_BOOKMARKED | AnalyticsEventTypes.EVENT_UNBOOKMARKED,
  ): Promise<void> {
    const hub: IHub = await this.hubsService.getOne(event.hubId);
    const isUserInvitedToEvent: boolean = await this.isUserInvitedToEvent(
      event.id,
      this.usersStore.user.id,
    );

    const action = {
      eventId: event.id,
      eventName: event._title_,
      hubId: event.hubId,
      hubName: hub._title_,
      startDate: event.start,
      endDate: event.end,
      location: event.location,
      ticketsForSale: !!event.eventbriteSync,
      invited: isUserInvitedToEvent,
      live: this.isEventLiveNow(event),
    };

    this.track(eventName, action);
  }

  async stageView(stage: IStage): Promise<void> {
    const eventName = AnalyticsEventTypes.STAGE_VIEW;
    const hub: IHub = await this.hubsService.getOne(this.eventsStore.event.hubId);
    const sessions: ISession[] = await this.sessionsService.getEventSessionsCertainStage(
      this.eventsStore.eventId,
      stage.id,
    );
    const liveSession: ISession = sessions.length
      ? sessions.find((session: ISession) => isSessionLive(session)) || null
      : null;

    const action = {
      stageId: stage.id,
      stageName: stage._name_,
      eventId: this.eventsStore.eventId,
      eventName: this.eventsStore.event._title_,
      hubId: this.eventsStore.event.hubId,
      hubName: hub._title_,
      live: !!liveSession,
    };

    this.track(eventName, action);
  }

  async stageChatSent(eventId: string, stageId: string, message: string): Promise<void> {
    const eventName = AnalyticsEventTypes.STAGE_CHAT_SENT;
    const senderName: string = `${this.usersStore.user.firstName} ${this.usersStore.user.lastName}`;
    const stage: IStage = await this.stagesService.getOne(eventId, stageId);
    const sessions: ISession[] = await this.sessionsService.getEventSessionsCertainStage(
      eventId,
      stageId,
    );
    const liveSession: ISession = sessions.length
      ? sessions.find((session: ISession) => isSessionLive(session)) || null
      : null;
    const hub: IHub = await this.hubsService.getOne(this.eventsStore.event.hubId);

    const action = {
      senderId: this.usersStore.userId,
      senderName,
      message,
      messageLength: message.length,
      stageId,
      stageName: stage._name_,
      sessionId: liveSession?.id || null,
      sessionName: liveSession?._title_ || null,
      eventId,
      eventName: this.eventsStore.event._title_,
      hubId: hub.id,
      hubName: hub._title_,
      live: !!liveSession,
    };

    this.track(eventName, action);
  }

  async dmSent(
    receiver: IUser,
    message: { text: string; attachment?: ChatMessageAttachment },
    messageId: string,
  ): Promise<void> {
    const eventName = AnalyticsEventTypes.DM_SENT;
    const senderName: string = `${this.usersStore.user.firstName} ${this.usersStore.user.lastName}`;
    const hub: IHub = await this.hubsService.getOne(this.eventsStore.event.hubId);
    const action = {
      messageId,
      senderId: this.usersStore.userId,
      senderName,
      receiverId: receiver.id,
      receiverName: `${receiver.firstName} ${receiver.lastName}`,
      message: message?.text,
      messageLength: message?.text?.length,
      attachment: !!message?.attachment,
      attachmentType: message?.attachment?.type || null,
      attachmentSize: message?.attachment?.size || null,
      eventId: this.eventsStore.eventId,
      eventName: this.eventsStore.event._title_,
      hubId: hub.id,
      hubName: hub._title_,
      live: this.isEventLiveNow(this.eventsStore.event),
    };
    this.track(eventName, action);
  }

  async agendaView(event: IEvent): Promise<void> {
    const eventName = AnalyticsEventTypes.AGENDA_VIEW;
    const hub: IHub = await this.hubsService.getOne(this.eventsStore.event.hubId);
    const sessions: ISession[] = await this.sessionsService.getEventSessions(event.id);
    const liveSession: ISession = sessions.length
      ? sessions.find((session: ISession) => isSessionLive(session)) || null
      : null;

    const action = {
      eventId: event.id,
      eventName: event._title_,
      hubId: event.hubId,
      hubName: hub._title_,
      sessionId: liveSession?.id || null,
      sessionName: liveSession?._title_ || null,
      live: !!liveSession,
    };

    this.track(eventName, action);
  }

  async agendaFilter(
    event: IEvent,
    filterBy: string[],
    tagIds: string[],
    date: string,
  ): Promise<void> {
    const eventName = AnalyticsEventTypes.AGENDA_FILTER;
    const tagsSelectedName: string[] = await this.getSelectedNames(tagIds);
    const filterRequest: string[] = this.prepareFilterRequestProperty(tagsSelectedName, date);
    const hub: IHub = await this.hubsService.getOne(this.eventsStore.event.hubId);
    const sessions: ISession[] = await this.sessionsService.getEventSessions(event.id);
    const liveSession: ISession = sessions.length
      ? sessions.find((session: ISession) => isSessionLive(session)) || null
      : null;

    const action = {
      filterBy,
      filterRequest,
      eventId: event.id,
      eventName: event._title_,
      hubId: event.hubId,
      hubName: hub._title_,
      sessionId: liveSession?.id || null,
      sessionName: liveSession?._title_ || null,
      live: !!liveSession,
    };

    this.track(eventName, action);
  }

  private prepareFilterRequestProperty(tagNames: string[], date: string): string[] {
    const result = [];
    if (tagNames) {
      result.push(...tagNames);
    }

    if (date) {
      result.push(date);
    }

    return result;
  }

  async streamTracking(
    event: IEvent,
    session: ISession,
    startedWatching: moment.Moment,
    stoppedWatching: moment.Moment = null,
  ): Promise<void> {
    const eventName = AnalyticsEventTypes.STREAM_TRACKING;
    const stage: IStage = await this.stagesService.getOne(event.id, session.stageId);
    const sessionSpeakerNames: string[] = await this.getSessionSpeakerNames(session);
    const sessionBrandNames: string[] = await this.getSessionBrandNames(session);
    const duration: number = !!stoppedWatching
      ? stoppedWatching.diff(startedWatching, 'minutes')
      : null;

    const action = {
      eventId: event.id,
      eventName: event._title_,
      stageId: session.stageId,
      stageName: stage._name_,
      sessionId: session.id,
      sessionName: session._title_,
      sessionStart: session.start,
      sessionEnd: session.end,
      sessionTags: session.tags,
      sessionSpeakerIds: session.speakers,
      sessionSpeakerNames,
      sessionBrandIds: session.brands,
      sessionBrandNames,
      startedWatching: startedWatching.format('LLL'),
      stoppedWatching: !!stoppedWatching ? stoppedWatching.format('LLL') : null,
      duration,
    };
    this.track(eventName, action);
  }

  async sessionView(event: IEvent, session: ISession): Promise<void> {
    const eventName = AnalyticsEventTypes.SESSION_VIEW;
    const sessionSpeakerNames: string[] = await this.getSessionSpeakerNames(session);
    const sessionBrandNames: string[] = await this.getSessionBrandNames(session);
    const action = {
      sessionId: session.id,
      sessionName: session._title_,
      sessionStartDate: session.start,
      sessionEndDate: session.end,
      sessionTags: session.tags,
      sessionSpeakerIds: session.speakers,
      sessionSpeakerNames,
      sessionBrandIds: session.brands,
      sessionBrandNames,
      eventId: event.id,
      eventName: event._title_,
    };
    this.track(eventName, action);
  }

  async sessionBookmarkOrUnbookmark(
    event: IEvent,
    session: ISession,
    eventName: AnalyticsEventTypes.SESSION_BOOKMARK | AnalyticsEventTypes.SESSION_UNBOOKMARK,
  ): Promise<void> {
    const sessionSpeakerNames: string[] = await this.getSessionSpeakerNames(session);
    const sessionBrandNames: string[] = await this.getSessionBrandNames(session);
    const action = {
      sessionId: session.id,
      sessionName: session._title_,
      sessionStartDate: session.start,
      sessionEndDate: session.end,
      sessionTags: session.tags,
      sessionSpeakerIds: session.speakers,
      sessionSpeakerNames,
      sessionBrandIds: session.brands,
      sessionBrandNames,
      eventId: event.id,
      eventName: event._title_,
      live: isSessionLive(session),
    };
    this.track(eventName, action);
  }

  async sessionDownload(
    event: IEvent,
    session: ISession,
    fileName: string,
    fileType: string,
  ): Promise<void> {
    const eventName = AnalyticsEventTypes.SESSION_DOWNLOAD;
    const sessionSpeakerNames: string[] = await this.getSessionSpeakerNames(session);
    const sessionBrandNames: string[] = await this.getSessionBrandNames(session);
    const action = {
      sessionId: session.id,
      sessionName: session._title_,
      fileName,
      fileType,
      sessionStartDate: session.start,
      sessionEndDate: session.end,
      sessionTags: session.tags,
      sessionSpeakerIds: session.speakers,
      sessionSpeakerNames,
      sessionBrandIds: session.brands,
      sessionBrandNames,
      eventId: event.id,
      eventName: event._title_,
      live: isSessionLive(session),
    };
    this.track(eventName, action);
  }

  brandDiscovery(event: IEvent, recordNumber: number): void {
    const eventName = AnalyticsEventTypes.BRAND_DISCOVERY;
    const action = {
      recordNumber,
      eventId: event.id,
      eventName: event._title_,
      live: this.isEventLiveNow(event),
    };
    this.track(eventName, action);
  }

  async brandFilter(event: IEvent, tagIds: string[]): Promise<void> {
    const eventName = AnalyticsEventTypes.BRAND_FILTER;
    const tagsSelectedName: string[] = await this.getSelectedNames(tagIds);
    const hub: IHub = await this.hubsService.getOne(event.hubId);
    const action = {
      filterBy: 'Tags',
      filterRequest: tagsSelectedName,
      eventId: event.id,
      eventName: event._title_,
      hubId: event.hubId,
      hubName: hub._title_,
      live: this.isEventLiveNow(event),
    };
    this.track(eventName, action);
  }

  async brandBookmarkOrUnbookmark(
    event: IEvent,
    brand: IBrand,
    eventName: AnalyticsEventTypes.BRAND_BOOKMARK | AnalyticsEventTypes.BRAND_UNBOOKMARK,
  ): Promise<void> {
    const hub: IHub = await this.hubsService.getOne(event.hubId);
    const action = {
      brandId: brand.id,
      brandName: brand._name_,
      eventId: event.id,
      eventName: event._title_,
      hubId: event.hubId,
      hubName: hub._title_,
      live: this.isEventLiveNow(event),
    };
    this.track(eventName, action);
  }

  async brandView(event: IEvent, brand: IBrand): Promise<void> {
    const eventName = AnalyticsEventTypes.BRAND_VIEW;
    const hub: IHub = await this.hubsService.getOne(event.hubId);
    const action = {
      brandId: brand.id,
      brandName: brand._name_,
      eventId: event.id,
      eventName: event._title_,
      hubId: event.hubId,
      hubName: hub._title_,
      live: this.isEventLiveNow(event),
    };
    this.track(eventName, action);
  }

  async brandClick(event: IEvent, brand: IBrand, url: string, type: string): Promise<void> {
    const eventName = AnalyticsEventTypes.BRAND_CLICK;
    const hub: IHub = await this.hubsService.getOne(event.hubId);
    const action = {
      type,
      url,
      brandId: brand.id,
      brandName: brand._name_,
      eventId: event.id,
      eventName: event._title_,
      hubId: event.hubId,
      hubName: hub._title_,
      live: this.isEventLiveNow(event),
    };
    this.track(eventName, action);
  }

  speakerDiscovery(event: IEvent, recordNumber: number): void {
    const eventName = AnalyticsEventTypes.SPEAKER_DISCOVERY;
    const action = {
      recordNumber,
      eventId: event.id,
      eventName: event._title_,
      live: this.isEventLiveNow(event),
    };
    this.track(eventName, action);
  }

  async speakerFilter(event: IEvent, tagIds: string[]): Promise<void> {
    const eventName = AnalyticsEventTypes.SPEAKER_FILTER;
    const tagsSelectedName: string[] = await this.getSelectedNames(tagIds);
    const hub: IHub = await this.hubsService.getOne(event.hubId);
    const action = {
      filterBy: 'Tags',
      filterRequest: tagsSelectedName,
      eventId: event.id,
      eventName: event._title_,
      hubId: event.hubId,
      hubName: hub._title_,
      live: this.isEventLiveNow(event),
    };
    this.track(eventName, action);
  }

  async speakerBookmarkOrUnbookmark(
    event: IEvent,
    speaker: IUser,
    url: string,
    eventName: AnalyticsEventTypes.SPEAKER_BOOKMARK | AnalyticsEventTypes.SPEAKER_UNBOOKMARK,
  ): Promise<void> {
    const hub: IHub = await this.hubsService.getOne(event.hubId);
    const action = {
      speakerId: speaker.id,
      speakerFullName: `${speaker.firstName} ${speaker.lastName}`,
      speakerCompany: speaker.company,
      speakerPosition: speaker.position,
      speakerTags: speaker.tags,
      currentPage: url,
      eventId: event.id,
      eventName: event._title_,
      hubId: event.hubId,
      hubName: hub._title_,
      live: this.isEventLiveNow(event),
    };
    this.track(eventName, action);
  }

  async speakerView(event: IEvent, speaker: IUser): Promise<void> {
    const eventName = AnalyticsEventTypes.SPEAKER_VIEW;
    const hub: IHub = await this.hubsService.getOne(event.hubId);
    const action = {
      speakerId: speaker.id,
      speakerFullName: `${speaker.firstName} ${speaker.lastName}`,
      speakerCompany: speaker.company,
      speakerPosition: speaker.position,
      speakerTags: speaker.tags,
      eventId: event.id,
      eventName: event._title_,
      hubId: event.hubId,
      hubName: hub._title_,
      live: this.isEventLiveNow(event),
    };
    this.track(eventName, action);
  }

  async speakerClick(event: IEvent, speaker: IUser, url: string, type: string): Promise<void> {
    const eventName = AnalyticsEventTypes.SPEAKER_CLICK;
    const hub: IHub = await this.hubsService.getOne(event.hubId);
    const action = {
      type,
      url,
      speakerId: speaker.id,
      speakerFullName: `${speaker.firstName} ${speaker.lastName}`,
      speakerCompany: speaker.company,
      speakerPosition: speaker.position,
      speakerTags: speaker.tags,
      eventId: event.id,
      eventName: event._title_,
      hubId: event.hubId,
      hubName: hub._title_,
      live: this.isEventLiveNow(event),
    };
    this.track(eventName, action);
  }

  attendeeDiscovery(event: IEvent, recordNumber: number): void {
    const eventName = AnalyticsEventTypes.ATTENDEE_DISCOVERY;
    const action = {
      recordNumber,
      eventId: event.id,
      eventName: event._title_,
      live: this.isEventLiveNow(event),
    };
    this.track(eventName, action);
  }

  async attendeeFilter(event: IEvent, tagIds: string[]): Promise<void> {
    const eventName = AnalyticsEventTypes.ATTENDEE_FILTER;
    const tagsSelectedName: string[] = await this.getSelectedNames(tagIds);
    const hub: IHub = await this.hubsService.getOne(event.hubId);
    const action = {
      filterBy: 'Tags',
      filterRequest: tagsSelectedName,
      eventId: event.id,
      eventName: event._title_,
      hubId: event.hubId,
      hubName: hub._title_,
      live: this.isEventLiveNow(event),
    };
    this.track(eventName, action);
  }

  async attendeeBookmarkOrUnbookmark(
    event: IEvent,
    attendee: IUser,
    url: string,
    eventName: AnalyticsEventTypes.ATTENDEE_BOOKMARK | AnalyticsEventTypes.ATTENDEE_UNBOOKMARK,
  ): Promise<void> {
    const hub: IHub = await this.hubsService.getOne(event.hubId);
    const action = {
      attendeeId: attendee.id,
      attendeeFullName: `${attendee.firstName} ${attendee.lastName}`,
      attendeeCompany: attendee.company,
      attendeePosition: attendee.position,
      attendeeTags: attendee.tags,
      currentPage: url,
      eventId: event.id,
      eventName: event._title_,
      hubId: event.hubId,
      hubName: hub._title_,
      live: this.isEventLiveNow(event),
    };
    this.track(eventName, action);
  }

  async attendeeView(event: IEvent, attendee: IUser): Promise<void> {
    const eventName = AnalyticsEventTypes.ATTENDEE_VIEW;
    const hub: IHub = await this.hubsService.getOne(event.hubId);
    const action = {
      attendeeId: attendee.id,
      attendeeFullName: `${attendee.firstName} ${attendee.lastName}`,
      attendeeCompany: attendee.company,
      attendeePosition: attendee.position,
      attendeeTags: attendee.tags,
      eventId: event.id,
      eventName: event._title_,
      hubId: event.hubId,
      hubName: hub._title_,
      live: this.isEventLiveNow(event),
    };
    this.track(eventName, action);
  }

  async attendeeClick(event: IEvent, attendee: IUser, url: string, type: string): Promise<void> {
    const eventName = AnalyticsEventTypes.ATTENDEE_CLICK;
    const hub: IHub = await this.hubsService.getOne(event.hubId);
    const action = {
      type,
      url,
      attendeeId: attendee.id,
      attendeeFullName: `${attendee.firstName} ${attendee.lastName}`,
      attendeeCompany: attendee.company,
      attendeePosition: attendee.position,
      attendeeTags: attendee.tags,
      eventId: event.id,
      eventName: event._title_,
      hubId: event.hubId,
      hubName: hub._title_,
      live: this.isEventLiveNow(event),
    };
    this.track(eventName, action);
  }

  async eventExit(event: IEvent): Promise<void> {
    const eventName = AnalyticsEventTypes.EVENT_EXIT;
    const hub: IHub = await this.hubsService.getOne(event.hubId);
    const action = {
      eventId: event.id,
      eventName: event._title_,
      hubId: event.hubId,
      hubName: hub._title_,
      live: this.isEventLiveNow(event),
    };
    this.track(eventName, action);
  }

  topicUpdate(event: IEvent, newTags: string[], previewsTags: string[]): void {
    const eventName = AnalyticsEventTypes.TOPIC_UPDATE;
    const action = {
      newTags,
      previewsTags,
      eventId: event.id,
      eventName: event._title_,
      live: this.isEventLiveNow(event),
    };
    this.track(eventName, action);
  }

  videoDiscovery(event: IEvent, recordNumber: number): void {
    const eventName = AnalyticsEventTypes.VIDEO_DISCOVERY;
    const action = {
      recordNumber,
      eventId: event.id,
      eventName: event._title_,
      live: this.isEventLiveNow(event),
    };
    this.track(eventName, action);
  }

  async videoFilter(event: IEvent, tagIds: string[]): Promise<void> {
    const eventName = AnalyticsEventTypes.VIDEO_FILTER;
    const tagsSelectedName: string[] = await this.getSelectedNames(tagIds);
    const hub: IHub = await this.hubsService.getOne(event.hubId);
    const action = {
      filterBy: 'Tags',
      filterRequest: tagsSelectedName,
      eventId: event.id,
      eventName: event._title_,
      hubId: event.hubId,
      hubName: hub._title_,
    };
    this.track(eventName, action);
  }

  profileOrAccountUpdate(
    eventName: AnalyticsEventTypes.PROFILE_UPDATE | AnalyticsEventTypes.ACCOUNT_UPDATE,
    action,
    user: IUser,
  ): void {
    this.identify(user);
    this.track(eventName, action);
  }

  async scheduleMeeting(appointment: IAppointment, isOpenFromChat: boolean): Promise<void> {
    const meetingTotal: number = moment
      .duration(parseToMoment(appointment.endDate).diff(parseToMoment(appointment.startDate)))
      .asHours();
    const users: IUser[] = await this.getUsers(appointment.users);
    const guests: { id: string; fullName: string }[] = this.getGuests(users);
    const eventName = AnalyticsEventTypes.SCHEDULE_MEETING;
    const action = {
      meetingId: appointment.id,
      meetingName: appointment.meetingName,
      meetingFrom: isOpenFromChat ? 'Chat Bubble' : 'User Profile',
      eventId: appointment.eventId,
      eventName: this.eventsStore.event.title,
      description: appointment.description,
      location: appointment.location,
      startDate: parseToMoment(appointment.startDate).format('LL'),
      endDate: parseToMoment(appointment.endDate).format('LL'),
      startTime: getTime(appointment.startDate),
      endTime: getTime(appointment.endDate),
      meetingTotal,
      guestCount: appointment.users.length,
      guests,
      currentUrl: this.router.url,
    };
    this.track(eventName, action);
  }

  async acceptMeeting(appointment: IAppointment): Promise<void> {
    const meetingTotal: number = moment
      .duration(parseToMoment(appointment.endDate).diff(parseToMoment(appointment.startDate)))
      .asHours();
    const users: IUser[] = await this.getUsers(appointment.users);
    const guests: { id: string; fullName: string }[] = this.getGuests(users);
    const eventName = AnalyticsEventTypes.ACCEPT_MEETING;
    const event = await this.eventsService.getOne(appointment.eventId);
    const action = {
      meetingId: appointment.id,
      meetingName: appointment.meetingName,
      eventId: appointment.eventId,
      eventName: event.title,
      description: appointment.description,
      location: appointment.location,
      startDate: parseToMoment(appointment.startDate).format('LL'),
      endDate: parseToMoment(appointment.endDate).format('LL'),
      startTime: getTime(appointment.startDate),
      endTime: getTime(appointment.endDate),
      meetingTotal,
      acceptedBy: {
        id: this.usersStore.userId,
        fullName: `${this.usersStore.user.firstName} ${this.usersStore.user.lastName}`,
      },
      guestCount: appointment.users.length,
      guests,
      currentUrl: this.router.url,
    };
    this.track(eventName, action);
  }

  async declineMeeting(appointment: IAppointment, declineReason: string): Promise<void> {
    const meetingTotal: number = moment
      .duration(parseToMoment(appointment.endDate).diff(parseToMoment(appointment.startDate)))
      .asHours();
    const event = await this.eventsService.getOne(appointment.eventId);
    const users: IUser[] = await this.getUsers(appointment.users);
    const guests: { id: string; fullName: string }[] = this.getGuests(users);
    const eventName = AnalyticsEventTypes.DECLINE_MEETING;
    const action = {
      meetingId: appointment.id,
      meetingName: appointment.meetingName,
      eventId: appointment.eventId,
      eventName: event.title,
      description: appointment.description,
      location: appointment.location,
      startDate: parseToMoment(appointment.startDate).format('LL'),
      endDate: parseToMoment(appointment.endDate).format('LL'),
      startTime: getTime(appointment.startDate),
      endTime: getTime(appointment.endDate),
      meetingTotal,
      declinedBy: {
        id: this.usersStore.userId,
        fullName: `${this.usersStore.user.firstName} ${this.usersStore.user.lastName}`,
      },
      guestCount: appointment.users.length,
      guests,
      declineReason,
      currentUrl: this.router.url,
    };
    this.track(eventName, action);
  }

  async courseEvent(course: ICourse, eventName: AnalyticsEventTypes): Promise<void> {
    const hub: IHub = this.hubsStore.hub
      ? this.hubsStore.hub
      : await this.hubsService.getOne(course?.hubId);
    const action = {
      courseId: course?.id,
      courseTitle: course?.title,
      hubId: hub?.id,
      hubName: hub?.title,
    };
    this.track(eventName, action);
  }

  courseAssetEvent(course: ICourse, asset: IAsset, eventName: AnalyticsEventTypes): void {
    const action = {
      courseId: course.id,
      courseTitle: course.title,
      assetId: asset.id,
      assetTitle: asset.title,
    };
    this.track(eventName, action);
  }

  socialIconClick(event: IEvent, url: string, type: string): void {
    const eventName = AnalyticsEventTypes.SOCIAL_ICON_CLICK;
    const action = {
      eventId: event.id,
      eventName: event.title,
      socialIconType: type,
      socialIconLink: url,
    };
    this.track(eventName, action);
  }

  async userRegisteredInEvent(event: IEvent): Promise<void> {
    const eventName = AnalyticsEventTypes.USER_REGISTERED_IN_EVENT;
    const userName: string = `${this.usersStore.user.firstName} ${this.usersStore.user.lastName}`;
    const hub: IHub = await this.hubsService.getOne(event.hubId);
    const action = {
      userId: this.usersStore.userId,
      userName,
      userEmail: this.usersStore.user.email,
      eventId: event.id,
      eventName: event.title,
      hubId: hub.id,
      hubName: hub.title,
      live: this.isEventLiveNow(event),
    };
    this.track(eventName, action);
  }

  async userRegisteredInCourse(course: ICourse): Promise<void> {
    const eventName = AnalyticsEventTypes.USER_REGISTERED_IN_COURSE;
    const userName: string = `${this.usersStore.user.firstName} ${this.usersStore.user.lastName}`;
    const hub: IHub = await this.hubsService.getOne(course.hubId);
    const action = {
      userId: this.usersStore.userId,
      userName,
      userEmail: this.usersStore.user.email,
      courseId: course.id,
      courseName: course.title,
      hubId: hub.id,
      hubName: hub.title,
    };
    this.track(eventName, action);
  }

  private track(id: string, action: any = {}): void {
    mixpanel.track(id, action);
  }

  private isEventLiveNow(event: IEvent): boolean {
    return moment().isBetween(parseToMoment(event.start), parseToMoment(event.end));
  }

  private async getUsers(userIds: string[]): Promise<IUser[]> {
    const users = await Promise.all(
      userIds.map((userId: string) => this.usersService.getOne(userId)),
    );
    return users;
  }

  private getGuests(users: IUser[]): { id: string; fullName: string }[] {
    const guests: { id: string; fullName: string }[] = users.map((user: IUser) => ({
      id: user.id,
      fullName: `${user.firstName} ${user.lastName}`,
    }));
    return guests;
  }

  private async getSelectedNames(tags: string[]): Promise<string[]> {
    const selectedTags: ITag[] = tags.length
      ? await this.tagsService.getByIdsOrderedByTitle(tags)
      : null;
    const tagsSelectedName: string[] = selectedTags
      ? selectedTags.map((tag: ITag) => tag._title_)
      : null;

    return tagsSelectedName;
  }

  private async isUserInvitedToEvent(eventId: string, userId: string): Promise<boolean> {
    const isAttendee: boolean = await this.userEventsService.isAttendee(eventId, userId);

    return isAttendee;
  }

  private async getSessionSpeakerNames(session: ISession): Promise<string[]> {
    if (!session.speakers?.length) {
      return null;
    }
    const speakers: IUser[] = await Promise.all(
      session.speakers.map((speakerId: string) => this.speakersService.getOne(speakerId)),
    );
    const sessionSpeakerNames: string[] = speakers.map(
      (speaker: IUser) => `${speaker._firstName_} ${speaker._lastName_}`,
    );

    return sessionSpeakerNames;
  }

  private async getSessionBrandNames(session: ISession): Promise<string[]> {
    if (!session.brands?.length) {
      return null;
    }
    const brands: IBrand[] = await Promise.all(
      session.brands.map((brandId: string) => this.brandsService.getOne(brandId)),
    );
    const sessionBrandNames: string[] = brands.map((brand: IBrand) => `${brand._name_}`);

    return sessionBrandNames;
  }
}
