import { Component, OnInit, ElementRef, HostListener, ViewChild, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { Subject, merge } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';

import { EmailsService, UnlayerService } from 'src/app/core/services';
import { EmailModuleType, IEmail } from 'src/app/core/models';
import { Timestamp } from 'src/app/firebase';
import { CoursesStore, EventsStore, HubsStore, UsersStore } from 'src/app/core/stores';
import {
  courseMergeTags,
  systemEmailUserMergeTags,
  hubMergeTags,
  capitalizeFirstLetter,
  systemEmailEventMergeTags,
  brandMergeTags,
  asyncDelay,
} from 'src/app/core/utils';

declare const unlayer: any;

@Component({
  selector: 'app-unlayer-email-editor',
  templateUrl: './unlayer-email-editor.component.html',
  styleUrls: ['./unlayer-email-editor.component.scss'],
})
export class UnlayerEmailEditorComponent implements OnInit, OnDestroy {
  @ViewChild('sendToContainer', { static: true }) sendToContainer: ElementRef;

  editingEmail: IEmail;
  unlayerDesign: string;
  unlayerHtml: string;
  templates: any[];
  showSendTo = false;
  sendToInputValue: string;
  editMode = false;
  hovering = false;
  dropdownLabel = 'Title';
  emailTitle = new FormControl<string>(null);
  subject = new FormControl<string>(null);
  currentTemplate = new FormControl<any>({ value: null, disabled: true });
  options = ['Subject', 'Title'];
  currentOption = new FormControl<string>(this.options[1]);
  isUnlayerEditorReady = false;

  private emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private unlayerService: UnlayerService,
    private usersStore: UsersStore,
    private emailsService: EmailsService,
    private config: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private router: Router,
    private hubsStore: HubsStore,
    private coursesStore: CoursesStore,
    private messageService: MessageService,
    private translateService: TranslateService,
    private elementRef: ElementRef,
    private eventsStore: EventsStore,
  ) {}

  get emailErrorMessage(): string {
    return this.translateService.instant('adminEmailTemplate.emailNotValid');
  }

  async ngOnInit(): Promise<void> {
    this.editingEmail = this.config.data?.email ?? null;
    if (this.editingEmail) {
      this.unlayerDesign = this.editingEmail.design;
      this.unlayerHtml = this.editingEmail.html;
      this.emailTitle.setValue(this.editingEmail.title);
      this.subject.setValue(this.editingEmail.subject);
    }
    const mergeTags = [
      ...systemEmailUserMergeTags,
      ...courseMergeTags,
      ...hubMergeTags,
      ...systemEmailEventMergeTags,
      ...brandMergeTags,
    ].reduce((acc, tag) => {
      const [prefix, property] = tag.split('.');
      const mergeTagName = `${prefix}.${property}`;
      const key = `${property}${capitalizeFirstLetter(prefix)}`;
      acc[key] = {
        mergeTagName,
        value: `{{${mergeTagName}}}`,
        sample: `{{${mergeTagName}}}`,
      };
      return acc;
    }, {});

    unlayer.init({
      id: 'editor',
      projectId: 218240,
      displayMode: 'email',
      appearance: {
        theme: 'modern_dark',
      },
      tools: {
        timer: {
          enabled: true,
        },
        video: {
          enabled: true,
        },
      },
      mergeTags,
      version: 'latest',
    });

    if (!this.editingEmail) {
      this.templates = await this.unlayerService.getEmailTemplates();
    } else {
      unlayer.loadDesign(JSON.parse(this.editingEmail.design));
    }

    unlayer.addEventListener('editor:ready', () => {
      this.isUnlayerEditorReady = true;
      this.currentTemplate.enable({ emitEvent: false });
    });

    unlayer.addEventListener('design:updated', () => {
      unlayer.exportHtml((data) => {
        this.unlayerDesign = JSON.stringify(data.design);
        this.unlayerHtml = data.html;
      });
    });

    const currentTemplate$ = this.currentTemplate.valueChanges.pipe(
      tap((template) => {
        unlayer.loadDesign(template.design);
        this.saveDesign();
      }),
    );

    const currentOption$ = this.currentOption.valueChanges.pipe(
      tap((value) => {
        this.dropdownLabel = value;
      }),
    );

    merge(currentTemplate$, currentOption$).pipe(takeUntil(this.unsubscribe$)).subscribe();
  }

  onCloseSendTestEmailContainer(): void {
    if (this.showSendTo) {
      this.showSendTo = false;
    }
  }

  async onSave(): Promise<void> {
    await this.saveDesign();
    if (!this.editingEmail) {
      this.createNewEmail();
    } else {
      this.updateEmail();
    }
  }

  async updateEmail(): Promise<void> {
    try {
      const emailPayload: IEmail = {
        ...this.config.data.email,
        html: this.unlayerHtml,
        design: this.unlayerDesign,
        updateAt: Timestamp.now(),
        updateBy: this.usersStore.user.id,
        subject: this.subject.value,
        title: this.emailTitle.value,
      };

      if (this.coursesStore.adminCourse?.id) {
        await this.emailsService.updateCourseEmail(this.editingEmail.id, emailPayload);
      } else if (this.eventsStore.adminEvent) {
        await this.emailsService.updateEventEmail(this.editingEmail.id, emailPayload);
      } else {
        await this.emailsService.update(this.editingEmail.id, emailPayload);
      }
      console.log('line 180');
      this.dialogRef.close(true);
    } catch (error) {
      console.warn(error);
    }
  }

  async createNewEmail(): Promise<void> {
    try {
      const emailPayload: IEmail = {
        id: null,
        title: 'My New Email Title',
        _title_: 'my new email title',
        language: 'en',
        subject: 'Paste email subject here',
        from: 'Copy & paste from default email',
        status: null,
        audience: null,
        action: null,
        html: this.unlayerHtml || null,
        default: false,
        createAt: Timestamp.now(),
        createBy: this.usersStore.user.id,
        updateAt: null,
        updateBy: null,
        isHtmlMode: false,
        type: null,
        emailHeader: null,
        emailPreHeader: null,
        buttonText: null,
        buttonLink: null,
        description: null,
        isDeleted: false,
        isUnlayer: true,
        design: this.unlayerDesign,
        module: EmailModuleType.COURSES,
        image: null,
        color: null,
        addToCalendarActive: false,
        customUrl: null,
      };
      const newEmailResponse = await this.emailsService.create(emailPayload);
      this.router.navigate([
        `/${this.hubsStore.useHubUrl}/admin/system-settings/emails/${newEmailResponse.id}`,
      ]);
      console.log('line 223');
      this.dialogRef.close(true);
    } catch (error) {
      console.warn(error);
    }
  }

  onSendTo(event: Event): void {
    event.stopPropagation();
    this.showSendTo = !this.showSendTo;
  }

  async onSendTestEmail(): Promise<void> {
    try {
      await this.emailsService.sendTestEmail(this.sendToInputValue, this.config.data.email.id);
      this.showSendTo = false;
      this.showToastMessage('success', 'adminEmailTemplate.succesSentTest');
    } catch (error) {
      console.log(error);
      this.showToastMessage('error', 'adminEmailTemplate.errorSentTest');
    }
  }

  onCloseDialog(): void {
    console.log('line 247');
    this.dialogRef.close();
  }

  @HostListener('document:click', ['$event'])
  clickout(event): void {
    if (this.editMode && !this.elementRef.nativeElement.contains(event.target)) {
      this.saveTitle();
    }
  }

  saveTitle(): void {
    this.editMode = false;
  }

  toggleEdit(): void {
    this.editMode = true;
  }

  onMouseEnter(): void {
    this.hovering = true;
  }

  onMouseLeave(): void {
    this.hovering = false;
  }

  isValidEmail(email: string): boolean {
    return this.emailPattern.test(email);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private async saveDesign(): Promise<void> {
    await asyncDelay(100);
    unlayer.saveDesign((design: any) => {
      this.unlayerDesign = JSON.stringify(design);
    });
  }

  private showToastMessage(severity: 'success' | 'error', detail: string): void {
    this.messageService.add({
      severity,
      summary: this.translateService.instant(severity),
      detail: this.translateService.instant(detail),
      styleClass: 'custom-toast',
    });
  }
}
