import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';

import { IMG_FORMATS } from 'src/app/core/utils';
import { logoAvatar } from 'src/app/shared/utils';
import { ButtonSize, ButtonStyle } from 'src/app/standalone';
import { ButtonComponent } from 'src/app/standalone/shared/components';

@Component({
  selector: 'app-icon-media-form',
  standalone: true,
  imports: [TranslateModule, ButtonComponent],
  templateUrl: './icon-media-form.component.html',
  styleUrl: './icon-media-form.component.scss',
})
export class IconMediaFormComponent implements OnChanges {
  @Input() titleMediaForm: string;
  @Input() description: string;
  @Input() btnConfirm: string;
  @Input() btnCancel: string;
  @Input() imgValue: string | File;
  @Output() changeValue: EventEmitter<File> = new EventEmitter<File>();
  @ViewChild('iconUpload') iconUploadInput: ElementRef<HTMLInputElement>;

  iconUploadProcess = false;
  logoAvatar = logoAvatar;
  buttonStyle = ButtonStyle;
  buttonSize = ButtonSize;

  private newIconPreview: string;

  constructor(
    private messageService: MessageService,
    private translateService: TranslateService,
    private sanitizer: DomSanitizer,
  ) {}

  get iconImage(): string | ArrayBuffer | SafeUrl {
    if (this.iconUploadProcess) {
      return null;
    }

    return this.newIconPreview && typeof this.newIconPreview === 'string'
      ? this.sanitizer.bypassSecurityTrustUrl(this.newIconPreview)
      : this.imgValue;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.imgValue.previousValue !== changes.imgValue.currentValue &&
      !(this.imgValue instanceof File)
    ) {
      this.newIconPreview = null;
    }
  }

  onIconImageUpload(event: Event): void {
    this.iconUploadProcess = true;

    const onSuccess = (img: string) => {
      this.newIconPreview = img;
      this.iconUploadProcess = false;
    };

    const onError = (e) => {
      if (e === 'type_size') {
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('error'),
          detail: this.translateService.instant(
            'application.toasters.imageTypeOrSizeRuleViolation_PNG_JPEG',
            { size: 3 },
          ),
          styleClass: 'custom-toast',
        });
        this.iconUploadInput.nativeElement.value = '';
      }
      this.iconUploadProcess = false;
    };

    this.uploadImg(event, onSuccess, onError);
  }

  clearIconImage(): void {
    this.newIconPreview = null;
    this.iconUploadInput.nativeElement.value = '';
    this.changeValue.emit(null);
  }

  private uploadImg(
    event: Event,
    onSuccess: (img: string | ArrayBuffer) => void,
    onError: (e) => void,
  ): void {
    const input = event.target as HTMLInputElement;

    if (!input.files || input.files.length === 0) {
      return;
    }

    const imageFile = input.files[0];

    if (!IMG_FORMATS.includes(imageFile.type) || imageFile.size > 3_194_304) {
      onError('type_size');
      return;
    }
    this.changeValue.emit(imageFile);

    const reader = new FileReader();
    reader.readAsDataURL(imageFile);

    reader.onloadend = () => onSuccess(reader.result);

    reader.onerror = (error) => {
      console.log(error);
      onError(error);
    };
  }
}
