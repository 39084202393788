<div class="user-details-container">
  <div class="user-details-inner-container">
    <div class="user-header">
      <app-update-default-covers
        *ngIf="user?.coverImage"
        [url]="user.coverImage"
        [color]="defaultCoversColor">
      </app-update-default-covers>
      <div class="user-info-header">
        <button
          *ngIf="showBackBtn"
          class="button-back"
          pButton
          label="{{ 'shared.back' | translate }}"
          icon="far fa-arrow-left"
          (click)="onBack()"></button>
      </div>
    </div>

    <div class="user-body">
      <div class="lg:col-4 md:col-4 sm:col-12 col-12 user-look-col">
        <div
          *ngIf="user"
          class="user-main-info-box">
          <image
            [src]="user.profileImage"
            [text]="imageText(user)"
            size="small"></image>
          <h1>{{ user.firstName }} {{ user.lastName }}</h1>
          <p class="position-field">{{ user.position }}</p>
          <p class="company-field">{{ user.company }}</p>
        </div>

        <!-- TODO: hidden for now. It wll be unhidden after implement this logic -->
        <!-- <div class="user-actions-box">          
          <button
            *ngIf="usersStore.user"
            class="bookmark-btn"
            pButton
            icon="pi"
            (click)="onBookmark()">
            <i [ngClass]="getBookmarkedIconClass()"></i>
            {{ 'userDetails.bookmarkUser' | translate }}
          </button>
        </div> -->
        <div class="social-links-box">
          <span
            [ngClass]="{ 'icon-disabled': user?.website == null || user?.website == '' }"
            (click)="onGoToLink(user.website)">
            <i class="fal fa-globe"></i>
          </span>
          <span
            [ngClass]="{ 'icon-disabled': user?.facebook == null || user?.facebook == '' }"
            (click)="onGoToLink(user.facebook)">
            <i class="fab fa-facebook-f"></i>
          </span>
          <span
            [ngClass]="{ 'icon-disabled': !user?.instagram }"
            (click)="onGoToLink(user.instagram)">
            <i class="fab fa-instagram"></i>
          </span>
          <span
            [ngClass]="{ 'icon-disabled': !user?.youtube }"
            (click)="onGoToLink(user.youtube)">
            <i class="fab fa-youtube"></i>
          </span>
          <span
            [ngClass]="{ 'icon-disabled': user?.linkedin == null || user?.linkedin == '' }"
            (click)="onGoToLink(user.linkedin)">
            <i class="fab fa-linkedin-in"></i>
          </span>
          <span
            [ngClass]="{ 'icon-disabled': !user?.twitter }"
            (click)="onGoToLink(user.linkedin)">
            <i class="fab fa-twitter"></i>
          </span>
          <span
            [ngClass]="{ 'icon-disabled': user?.xing == null || user?.xing == '' }"
            (click)="onGoToLink(user.xing)">
            <i class="fab fa-xing"></i>
          </span>
        </div>
        <app-calendly-link
          *ngIf="isCalendlyVisible"
          [openInDialog]="true"
          [link]="user?.calendly"></app-calendly-link>
      </div>
      <div class="lg:col-8 md:col-8 sm:col-12 col-12 user-data-col">
        <p
          class="user-bio"
          *ngIf="user"
          [innerHTML]="user.bio"></p>
        <div class="tags-list">
          <p-progressSpinner *ngIf="loadingTags"></p-progressSpinner>
          <app-tags-multi-list
            *ngIf="!loadingTags"
            [edit]="false"
            [possibleTags]="tags"></app-tags-multi-list>
        </div>
      </div>
    </div>
  </div>
</div>
