export interface IDailyRoom {
  id: string;
  name: string;
  api_created: boolean;
  privacy: string;
  url: string;
  created_at: string;
  config: any;
}

export interface IDailyRoomToken {
  token: string;
}

export interface IDeletedRoom {
  deleted: boolean;
  name: string;
}

export interface IDailyRoomConfig {
  nbf?: number;
  exp?: number;
  max_participants?: number;
  enable_people_ui?: boolean;
  enable_prejoin_ui?: boolean;
  enable_screenshare?: boolean;
  enable_video_processing_ui?: boolean;
  enable_chat?: boolean;
  start_video_off?: boolean;
  start_audio_off?: boolean;
  owner_only_broadcast?: boolean;
  eject_at_room_exp?: boolean;
  enable_recording?: 'cloud' | 'local' | 'rtp-tracks' | 'output-byte-stream';
  enable_pip_ui?: boolean;
  enable_hand_raising?: boolean;
  enable_emoji_reactions?: boolean;
  enable_breakout_rooms?: boolean;
  enable_network_ui?: boolean;
  enable_advanced_chat?: boolean;
}

export interface IDailyRecordingList {
  total_count: number;
  data: IDailyRecord[];
}

export interface IDailyRecord {
  id: string;
  start_ts: number;
  status: string;
  max_participants: number;
  room_name: string;
  tracks: any[];
  duration: number;
  share_token: string;
}

export interface IDailyRecordAccessLink {
  download_link: string;
  expires: number;
  s3_bucket: string;
  s3_key: string;
  s3_region: string;
}

export interface IDailyMeetings {
  total_count: number;
  data: IDailyMeeting[];
}

export interface IDailyMeeting {
  id: string;
  room: string;
  start_time: number;
  duration: number;
  ongoing: boolean;
  max_participants: number;
  participants?: IDailyMeetingParticipant[];
}

export interface IDailyMeetingParticipant {
  user_id: string;
  participant_id: string;
  user_name: string;
  join_time: number;
  duration: number;
}
