import { Component } from '@angular/core';
import { Observable } from 'rxjs';

import { goToLink } from 'src/app/core/utils/utils';
import { ThemesService } from 'src/app/core/services/themes/themes.service';
import { IAppearanceSettings } from 'src/app/core/models/system/appearance-settings.model';
import { imgPreloader } from 'src/app/shared';
import { HubsStore } from '../../stores';

@Component({
  selector: 'app-imprint',
  templateUrl: './imprint.component.html',
  styleUrls: ['./imprint.component.scss'],
})
export class ImprintComponent {
  public systemAppearanceSettings$: Observable<IAppearanceSettings> = null;
  public goToLink = goToLink;
  public imgPreloader = imgPreloader;

  get logo(): string {
    if (this.themesService.activeTheme.name === 'dark') {
      return this.hubsStore.hub?.logoDark
        ? this.hubsStore.hub?.logoDark
        : this.themesService.systemAppearanceSettings?.logo;
    } else {
      return this.hubsStore.hub?.logoLight
        ? this.hubsStore.hub?.logoLight
        : this.themesService.systemAppearanceSettings?.logoLight;
    }
  }

  constructor(
    private themesService: ThemesService,
    private hubsStore: HubsStore,
  ) {
    this.systemAppearanceSettings$ = this.themesService.systemAppearanceSettings$;
  }
}
