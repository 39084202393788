import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import { ClipboardService } from 'ngx-clipboard';

import { CoursesStore, EventsStore, HubsStore, UsersStore } from 'src/app/core/stores';
import {
  basicEmailTemplateDE,
  basicEmailTemplateEN,
  basicEmailTemplateBG,
  eventAudience,
  emailsEventActions,
  capitalizeFirstLetter,
  emailsCourseActions,
  courseAudience,
  EmailCourseActionTypes,
} from 'src/app/core/utils';
import { Timestamp } from 'src/app/firebase';
import { EmailModuleType, IAudience, ICourse, IEmail, IEvent } from 'src/app/core/models';
import { EmailsService, ThemesService } from 'src/app/core/services';
import { SystemEmailPreviewComponent } from './../system-email-preview/system-email-preview.component';
import { EMAIL_TEMPLATE_DEFAULT_COLOR } from 'src/app/standalone';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-system-email-editor',
  templateUrl: './system-email-editor.component.html',
  styleUrls: ['./system-email-editor.component.scss'],
})
export class SystemEmailEditorComponent implements OnInit, OnDestroy {
  @Input() email: IEmail = null;

  loading = true;
  saving = false;
  sendingTestEmail = false;
  showMergeTags = false;
  testEmailForm: UntypedFormGroup;
  form: UntypedFormGroup;
  languages: { name: string; code: string }[] = [];
  actions: { label: string; value: string | EmailCourseActionTypes }[];
  audience: IAudience[];
  moduleTypes: string[] = Object.values(EmailModuleType);

  private unsubscribe$ = new Subject<void>();
  private basicEmailTemplateEN = basicEmailTemplateEN;
  private basicEmailTemplateDE = basicEmailTemplateDE;
  private basicEmailTemplateBG = basicEmailTemplateBG;
  private defaultLogo: string;

  constructor(
    private router: Router,
    private translateService: TranslateService,
    private dialogService: DialogService,
    private messageService: MessageService,
    private emailsService: EmailsService,
    private clipboardService: ClipboardService,
    private usersStore: UsersStore,
    public hubsStore: HubsStore,
    private eventsStore: EventsStore,
    private themesService: ThemesService,
    private coursesStore: CoursesStore,
  ) {}

  get emailErrorMessage(): string {
    if (this.testEmailForm.controls.email?.errors?.email) {
      return this.translateService.instant('adminEmailTemplate.emailNotValid');
    }

    return this.translateService.instant('adminEmailTemplate.emailRequired');
  }

  get course(): ICourse {
    return this.coursesStore.adminCourse;
  }

  get event(): IEvent {
    return this.eventsStore.adminEvent;
  }

  ngOnInit(): void {
    this.setInitialValuesForAudienceAndActions();
    this.defaultLogo = this.hubsStore.hub
      ? this.themesService.activeTheme.name === 'dark'
        ? this.hubsStore.hub.logoDark
        : this.hubsStore.hub.logoLight
      : this.themesService.activeTheme.name === 'dark'
        ? this.themesService.systemAppearanceSettings?.logo
        : this.themesService.systemAppearanceSettings?.logoLight;
    this.languages = this.translateService
      .getLangs()
      .map((l) => ({ name: this.translateService.instant('languages.' + l), code: l }));

    this.createForms();
    if (this.email) {
      this.updateForm();
    }
    this.updateHTMLTemplate(this.form.value);

    this.form.controls.module.valueChanges
      .pipe(
        tap((moduleType: EmailModuleType) => {
          this.form.patchValue({ action: null, audience: null });
          switch (moduleType) {
            case EmailModuleType.EVENTS:
              this.actions = emailsEventActions;
              this.audience = eventAudience;
              break;
            case EmailModuleType.COURSES:
              this.actions = emailsCourseActions;
              this.audience = courseAudience;
              break;
            default:
              this.actions = emailsEventActions;
              this.audience = eventAudience;
          }
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();

    this.form.valueChanges
      .pipe(
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        tap((formValues) => {
          this.updateHTMLTemplate(formValues);
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();

    this.loading = false;
  }

  copy(): void {
    const value = this.form.get('html').value ? this.form.get('html').value : '';
    this.clipboardService.copy(value);
  }

  preview(): void {
    this.dialogService.open(SystemEmailPreviewComponent, {
      width: '600px',
      height: '90%',
      dismissableMask: true,
      contentStyle: {
        overflow: 'auto',
        padding: '0',
        'border-radius': '20px',
        'background-color': 'white',
      },
      data: {
        html: this.form.controls.html.value,
        color: this.form.controls.color.value,
      },
    });
  }

  mergeTags(): void {
    this.showMergeTags = !this.showMergeTags;
  }

  async sendTestEmail(): Promise<void> {
    if (!this.testEmailForm.valid) {
      return;
    }

    try {
      this.sendingTestEmail = true;

      await this.emailsService.sendTestEmail(this.testEmailForm.value.email, this.email.id);
      this.showToastMessage('success', 'adminEmailTemplate.succesSentTest');
      this.sendingTestEmail = false;
    } catch (error) {
      console.log(error);
      this.showToastMessage('error', 'adminEmailTemplate.errorSentTest');
      this.sendingTestEmail = false;
    }
  }

  async save(): Promise<void> {
    if (!this.form.valid) {
      return;
    }

    try {
      this.saving = true;
      const form = this.form.value;

      if (this.email) {
        const emailPayload: IEmail = {
          ...this.email,
          ...form,
          _title_: form.title.toLowerCase(),
          language: form.language.code || null,
          updateAt: Timestamp.now(),
          updateBy: this.usersStore.user.id,
        };

        if (this.eventsStore.adminEvent) {
          await this.emailsService.updateEventEmail(this.email.id, emailPayload);
        } else if (this.course) {
          await this.emailsService.updateCourseEmail(this.email.id, emailPayload);
        } else {
          await this.emailsService.update(this.email.id, emailPayload);
        }
      } else {
        const newEmail: IEmail = {
          ...form,
          id: null,
          _title_: form.title.toLowerCase(),
          isHtmlMode: false,
          type: null,
          language: form.language.code,
          isDeleted: false,
          emailPreHeader: null,
          buttonLink: null,
          addToCalendarActive: null,
          updateAt: null,
          updateBy: null,
          createAt: Timestamp.now(),
          createBy: this.usersStore.user.id,
        };
        const newEmailResponse = await this.emailsService.create(newEmail);
        this.router.navigate([
          `/${this.hubsStore.useHubUrl}/admin/system-settings/emails/${newEmailResponse.id}`,
        ]);
      }

      this.showToastMessage('success', 'adminEmailTemplate.succesSave');
      this.saving = false;
    } catch (error) {
      console.log(error);
      this.showToastMessage('error', 'adminEmailTemplate.errorSave');
      this.saving = false;
    }
  }

  getModuleTypeText(moduleType: EmailModuleType): string {
    return this.translateService.instant(
      `adminEmailTemplate.moduleType${capitalizeFirstLetter(moduleType)}`,
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private createForms(): void {
    this.testEmailForm = new UntypedFormGroup({
      email: new UntypedFormControl(null, [Validators.required, Validators.email]),
    });

    this.form = new UntypedFormGroup({
      title: new UntypedFormControl('My New Email Title', Validators.required),
      language: new UntypedFormControl(null, Validators.required),
      action: new UntypedFormControl(null),
      status: new UntypedFormControl(false),
      default: new UntypedFormControl(false),
      subject: new UntypedFormControl(
        this.translateService.instant('adminEmailTemplate.subjectPlaceholder'),
        Validators.required,
      ),
      from: new UntypedFormControl(
        this.translateService.instant('adminEmailTemplate.fromPlaceholder'),
        Validators.required,
      ),
      audience: new UntypedFormControl(null),
      html: new UntypedFormControl(null, Validators.required),
      image: new UntypedFormControl(
        this.translateService.instant('adminEmailTemplate.imagePlaceholder'),
        Validators.required,
      ),
      color: new UntypedFormControl(EMAIL_TEMPLATE_DEFAULT_COLOR, Validators.required),
      emailHeader: new UntypedFormControl(
        this.translateService.instant('adminEmailTemplate.titlePlaceholder'),
        Validators.required,
      ),
      buttonText: new UntypedFormControl(
        this.translateService.instant('adminEmailTemplate.btnTextPlaceholder'),
        Validators.required,
      ),
      customUrl: new UntypedFormControl(
        this.translateService.instant('adminEmailTemplate.customTextPlaceholder'),
        Validators.required,
      ),
      description: new UntypedFormControl(null, Validators.required),
      module: new UntypedFormControl({
        value: EmailModuleType.EVENTS,
        disabled: this.course || this.event,
      }),
    });
  }

  private updateForm(): void {
    this.form.patchValue({
      ...this.email,
      language: this.languages.find(
        (lang: { name: string; code: string }) => lang.code === this.email.language,
      ),
      action: this.email.action
        ? this.actions.find(
            (action: { label: string; value: string }) => action.value === this.email.action,
          ).value
        : null,
    });
  }

  private updateHTMLTemplate(fields) {
    // @@@IMAGE@@@
    // @@@HEADLINE@@@
    // @@@DESCRIPTION@@@
    // @@@BUTTON_LINK@@@
    // @@@BUTTON_TEXT@@@
    // @@@COLOR@@@
    // @@@POWEREDBYTEXT@@@
    // @@@POWEREDBYURL@@@
    let html = '';

    switch (fields.language?.code) {
      case 'en':
        html = this.basicEmailTemplateEN;
        break;
      case 'de':
        html = this.basicEmailTemplateDE;
        break;
      case 'bg':
        html = this.basicEmailTemplateBG;
        break;
      default:
        html = this.basicEmailTemplateEN;
    }

    html = html.replace(/@@@IMAGE@@@/gi, fields.image);
    html = html.replace(/@@@HEADLINE@@@/gi, fields.emailHeader);
    html = html.replace(/@@@DESCRIPTION@@@/gi, fields.description);
    html = html.replace(/@@@BUTTON_LINK@@@/gi, fields.customUrl);
    html = html.replace(/@@@BUTTON_TEXT@@@/gi, fields.buttonText);
    html = html.replace(/@@@COLOR@@@/gi, fields.color);
    html = html.replace(/@@@POWEREDBYTEXT@@@/gi, environment.applicationName);
    html = html.replace(/@@@POWEREDBYURL@@@/gi, environment.poweredByUrl);

    html = html.replace(/@@@TERMSOFUSELINK@@@/gi, environment.termsOfUseLink);
    html = html.replace(/@@@TERMSANDCONDITIONSLINK@@@/gi, environment.termsAndConditions);
    html = html.replace(/@@@IMPRINTLINK@@@/gi, environment.imprint);

    this.form.patchValue({ html });
  }

  private setInitialValuesForAudienceAndActions(): void {
    if (this.email?.module === EmailModuleType.COURSES) {
      this.audience = courseAudience;
      this.actions = emailsCourseActions;
    } else {
      this.audience = eventAudience;
      this.actions = emailsEventActions;
    }
  }

  private showToastMessage(severity: 'success' | 'error', detail: string): void {
    this.messageService.add({
      severity,
      summary: this.translateService.instant(severity),
      detail: this.translateService.instant(detail),
      styleClass: 'custom-toast',
    });
  }
}
