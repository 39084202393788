<ng-container *ngIf="loading">
  <app-loader></app-loader>
</ng-container>

<div
  *ngIf="!loading"
  class="admin-course-brands-container">
  <admin-table-header-navbar [title]="'adminCourseBrandsList.title' | translate">
    <div class="btn-actions-container">
      <app-button
        buttonText="{{ 'adminCourseBrandsList.attachBrands' | translate }}"
        buttonIconLeft="far fa-grid-2-plus"
        [buttonStyle]="buttonStyle.FILLED_SECONDARY"
        [buttonSize]="buttonSize.MEDIUM"
        [disabled]="loadingEntries"
        (click)="openAttachBrandsDialog()" />

      <app-button
        buttonText="{{ 'adminCourseBrandsList.addBtnTitle' | translate }}"
        buttonIconLeft="far fa-plus"
        [buttonSize]="buttonSize.MEDIUM"
        [disabled]="loadingEntries"
        (click)="openNewBrandDialog()" />
    </div>
  </admin-table-header-navbar>

  <div class="c-my-4">
    <p-divider styleClass="custom-divider-horizontal"></p-divider>
  </div>
  <div class="admin-course-brands-table-container">
    <admin-table-filter-navbar
      [filters]="tableFilters"
      (onFiltersChange)="filtersChange($event)"
      (onSearchTermChange)="onSearchTermChange($event)">
    </admin-table-filter-navbar>

    <admin-table
      [loading]="loadingEntries"
      [lazy]="false"
      [columns]="columns"
      [entries]="entries"
      [pageSize]="pageSize"
      [totalRecords]="totalRecords"
      emptyStateKey="adminCourseBrandsList.emptyState"
      emptyStateIcon="fak fa-lox-crown"
      (rowClicked)="onRowClicked($event)">
      <ng-template
        let-entry
        epTemplateKey="nameCell">
        <div class="name-cell">
          <div>
            <img
              *ngIf="entry.brandLogo"
              class="banner"
              [defaultImage]="imgPreloader"
              [lazyLoad]="entry.brandLogo | imageSize" />
            <div
              *ngIf="!entry.brandLogo"
              class="placeholder-container">
              <img
                class="placeholder"
                [src]="logoAvatar | imageSize" />
            </div>
          </div>
          <p class="brand-name">{{ entry._brandName_ }}</p>
        </div>
      </ng-template>

      <ng-template
        let-entry
        epTemplateKey="actionsCell">
        <div class="table-btn-actions-container">
          <app-button
            [buttonType]="buttonType.ROUND"
            [buttonSize]="buttonSize.MEDIUM"
            [buttonStyle]="buttonStyle.FILLED_SECONDARY"
            buttonRoundIcon="far fa-pen-to-square"
            (click)="edit($event, entry)" />
          <app-button
            [buttonType]="buttonType.ROUND"
            [buttonSize]="buttonSize.MEDIUM"
            [buttonStyle]="buttonStyle.FILLED_DANGER"
            buttonRoundIcon="far fa-trash-can"
            (click)="remove($event, entry)" />
        </div>
      </ng-template>
    </admin-table>
  </div>
</div>
