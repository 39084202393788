import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, startWith, takeUntil, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';

import { HubsStore } from 'src/app/core/stores';
import { ICustomSidebarMenuItem, IHub, SidebarItemsLayout } from 'src/app/core/models';
import { FeatureHeaderComponent, FeatureMainContentComponent } from '../../components';

@Component({
  selector: 'app-system-settings-container',
  standalone: true,
  imports: [CommonModule, FeatureHeaderComponent, FeatureMainContentComponent],
  templateUrl: './system-settings-container.component.html',
  styleUrls: ['./system-settings-container.component.scss'],
})
export class SystemSettingsContainerComponent implements OnInit, OnDestroy {
  items: ICustomSidebarMenuItem[];
  activeMenuItem: ICustomSidebarMenuItem;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private translateService: TranslateService,
    private hubsStore: HubsStore,
    private router: Router,
  ) {}

  get hub(): IHub {
    return this.hubsStore.hub;
  }

  get startRoute(): string {
    return this.hub ? `/${this.hubsStore.useHubUrl}` : '';
  }

  ngOnInit(): void {
    this.items = [
      {
        label: this.translateService.instant('adminMenu.settings'),
        icon: 'far fa-gears',
        routerLink: `${this.startRoute}/admin/system-settings/general-settings`,
        sidebarItems: {
          layout: SidebarItemsLayout.DEFAULT,
          menuItems: [
            {
              items: [
                {
                  label: this.translateService.instant('adminSystemSettings.general'),
                  routerLink: `${this.startRoute}/admin/system-settings/general-settings/general`,
                },
                {
                  label: this.translateService.instant('adminSystemSettings.privacySettings'),
                  routerLink: `${this.startRoute}/admin/system-settings/general-settings/pages`,
                },
              ],
            },
          ],
        },
      },
      {
        label: this.translateService.instant('adminMenu.systemTags'),
        icon: 'far fa-tag',
        routerLink: `${this.startRoute}/admin/system-settings/system-tags`,
      },
      {
        label: this.translateService.instant('adminMenu.systemTranslations'),
        icon: 'far fa-globe',
        routerLink: `${this.startRoute}/admin/system-settings/system-translations`,
      },
      {
        label: this.translateService.instant('adminMenu.systemEmailTemplates'),
        icon: 'far fa-square-envelope',
        routerLink: `${this.startRoute}/admin/system-settings/emails`,
      },
      {
        label: this.translateService.instant('adminMenu.systemHomePages'),
        icon: 'far fa-book-open',
        routerLink: `${this.startRoute}/admin/system-settings/system-home`,
      },
      {
        label: this.translateService.instant('adminMenu.developerSettings'),
        icon: 'far fa-code',
        routerLink: `${this.startRoute}/admin/system-settings/developer-settings`,
      },
    ];

    this.router.events
      .pipe(
        filter((event: NavigationEnd) => event instanceof NavigationEnd),
        startWith(this.router),
        tap(() => {
          this.activeMenuItem = this.items.find((item: MenuItem) =>
            this.router.url.includes(item.routerLink),
          );
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
