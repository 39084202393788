import { Component, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';

import { AttachSessionUsersComponent } from 'src/app/admin';
import { IEvent, ISession, IUser, IUserSession } from 'src/app/core/models';
import { UserSessionsService, UsersService } from 'src/app/core/services';
import { EventsStore, SessionsStore } from 'src/app/core/stores';
import { Timestamp } from 'src/app/firebase';
import {
  ConfirmDialogComponent,
  IAdminTableColumn,
  IAdminTableFilterChange,
  SharedModule,
} from 'src/app/shared';
import {
  ButtonComponent,
  ButtonSize,
  ButtonStyle,
  ButtonType,
  ToastComponent,
} from 'src/app/standalone/shared';

type UserSessionEntry = IUser & {
  userSessionCreatedAt: Timestamp;
  isActionBtnShowingSpinner?: boolean;
};

@Component({
  selector: 'app-event-session-registrations',
  standalone: true,
  imports: [SharedModule, ButtonComponent, ToastComponent],
  templateUrl: './event-session-registrations.component.html',
  styleUrl: './event-session-registrations.component.scss',
})
export class EventSessionRegistrationsComponent {
  loading = signal<boolean>(true);
  loadingEntries = signal<boolean>(true);
  entries = signal<UserSessionEntry[]>(null);
  columns = signal<IAdminTableColumn[]>([
    {
      field: 'firstName',
      titleTranslationKey: 'adminSession.tableCells.firstName',
      sortable: true,
    },
    { field: 'lastName', titleTranslationKey: 'adminSession.tableCells.lastName', sortable: true },
    { field: 'email', titleTranslationKey: 'adminSession.tableCells.email', sortable: true },
    { field: 'company', titleTranslationKey: 'adminSession.tableCells.company', sortable: true },
    {
      field: 'userSessionCreatedAt',
      titleTranslationKey: 'adminSession.tableCells.time',
      template: 'timeCell',
      sortable: true,
    },
    { field: 'actions', template: 'actionsCell' },
  ]);
  searchFilterFields = signal<string[]>([
    'firstName',
    'lastName',
    'email',
    'company',
    'userSessionCreatedAt',
  ]);
  showExportSidebar = false;
  userSessions = signal<IUserSession[]>(null);
  searchValue = signal<string>('');
  selectedEntries;
  buttonSize = ButtonSize;
  buttonType = ButtonType;
  buttonStyle = ButtonStyle;

  constructor(
    private eventStore: EventsStore,
    private sessionsStore: SessionsStore,
    private userSessionsService: UserSessionsService,
    private usersService: UsersService,
    private dialogService: DialogService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  get event(): IEvent {
    return this.eventStore.adminEvent;
  }

  get session(): ISession {
    return this.sessionsStore.adminSession;
  }

  async ngOnInit(): Promise<void> {
    await this.fetchUserSessions();
    await this.getEntries();
    this.loadingEntries.set(false);
    this.loading.set(false);
  }

  toggleExportSidebar(): void {
    this.showExportSidebar = !this.showExportSidebar;
  }

  async filtersChange(value: IAdminTableFilterChange[]): Promise<void> {
    // TODO add this logic
  }

  async onAttachUsers(): Promise<void> {
    try {
      const dialogRef: DynamicDialogRef = this.dialogService.open(AttachSessionUsersComponent, {
        styleClass: 'default-dialog',
      });
      await dialogRef.onClose.pipe(take(1)).toPromise();
      this.loadingEntries.set(true);
      await this.fetchUserSessions();
      await this.getEntries();
      this.loadingEntries.set(false);
    } catch (error) {
      console.warn(error);
      throw new Error(error);
    }
  }

  edit(event: Event, entry: UserSessionEntry): void {
    event.stopPropagation();
    this.router.navigate([`../../../attendees/${entry.id}/event-details`], {
      relativeTo: this.route,
    });
  }

  async remove(event: Event, entry: UserSessionEntry): Promise<void> {
    event.stopPropagation();
    const deleteDialogRef: DynamicDialogRef = this.dialogService.open(ConfirmDialogComponent, {
      closable: false,
      styleClass: 'confirm-dialog',
      data: {
        titleKey: 'adminUsers.deleteUser',
        descriptionKey: 'adminUsers.deleteUserText',
        confirmBtnKey: 'adminUsers.confirmBtn',
        cancelBtnKey: 'adminUsers.cancelBtn',
        entry,
      },
    });

    const result: 'cancel' | 'confirm' = await deleteDialogRef.onClose.pipe(take(1)).toPromise();

    if (result === 'confirm') {
      try {
        this.entries.update((userSessionEntries: UserSessionEntry[]) => {
          return userSessionEntries.map((userSessionEntry: UserSessionEntry) => {
            if (userSessionEntry.id === entry.id) {
              return {
                ...userSessionEntry,
                isActionBtnShowingSpinner: true,
              };
            }

            return { ...userSessionEntry };
          });
        });
        await this.userSessionsService.deleteUserSessionByUserAndSessionIds(
          this.event.id,
          entry.id,
          this.session.id,
        );
        this.loadingEntries.set(true);
        await this.fetchUserSessions();
        await this.getEntries();
        this.loadingEntries.set(false);
        this.showToastMessage('success', 'adminSession.removeUserSessionSuccessMsg');
      } catch (error) {
        this.showToastMessage('error', 'application.toasters.error');
        this.entries.update((userSessionEntries: UserSessionEntry[]) => {
          return userSessionEntries.map((userSessionEntry: UserSessionEntry) => {
            if (userSessionEntry.id === entry.id) {
              return {
                ...userSessionEntry,
                isActionBtnShowingSpinner: false,
              };
            }

            return { ...userSessionEntry };
          });
        });
      }
    }
  }

  private async fetchUserSessions(): Promise<void> {
    const result: IUserSession[] = await this.userSessionsService.getUserSessionsBySessionId(
      this.event?.id,
      this.session?.id,
    );
    this.userSessions.set(result);
  }

  private async getEntries(): Promise<void> {
    const entries = await Promise.all(
      this.userSessions().map((userSession: IUserSession) => this.getEntry(userSession)),
    );
    this.entries.set(entries);
  }

  private async getEntry(userSession: IUserSession) {
    const user: IUser = await this.usersService.getOne(userSession.userId);
    return { ...user, userSessionCreatedAt: userSession.createdAt };
  }

  private showToastMessage(severity: 'success' | 'error', message: string): void {
    this.messageService.add({
      severity: severity,
      summary: this.translateService.instant(severity),
      detail: this.translateService.instant(message),
      styleClass: 'custom-toast',
    });
  }
}
