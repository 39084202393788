<ng-template #uploadErrorMessage>
  <h6 class="m-0 error">{{ 'chat.chatLoadAttachment.errorMessage' | translate }}</h6>
</ng-template>

<input
  #input
  type="file"
  accept="image/jpeg;image/jpg;image/png;application/pdf"
  (change)="onSelectImageFile($event)" />
<div
  class="chat-load-attachments"
  [ngClass]="{ 'chat-load-attachments--drag-over': isDragOver }">
  <i
    *ngIf="!isUploading"
    class="fas fa-file-upload"></i>
  <i
    *ngIf="isUploading"
    class="pi pi-spin pi-spinner"></i>
  <h5 class="m-0">{{ 'chat.chatLoadAttachment.labelOne' | translate }}</h5>
  <ng-container *ngIf="!inError; else uploadErrorMessage">
    <h6 class="m-0">{{ 'chat.chatLoadAttachment.labelTwo' | translate }}</h6>
    <h6 class="m-0">{{ 'chat.chatLoadAttachment.labelThree' | translate }}</h6>
  </ng-container>

  <div class="text-center mt-3">
    <button
      [disabled]="isUploading"
      class="p-button p-button-outlined mr-3"
      (click)="cancelAction()">
      {{ 'chat.chatLoadAttachment.cancelBtn' | translate }}
    </button>
    <button
      class="p-button p-button-primary"
      (click)="selectFileAction()">
      {{ 'chat.chatLoadAttachment.selectFileBtn' | translate }}
    </button>
  </div>
</div>
