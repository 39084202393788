export interface IEnvironment {
  env: string;
  branch: string;
  baseUrl: string;
  encryptionSecret: string;
  firebase: {
    apiKey: string;
    authDomain: string;
    databaseURL: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId: string;
  };
  apiUrl: string;
  daily: {
    dailyUrl: string;
    dailyToken: string;
  };
  intercom: {
    apiKey: string;
    identityVerificationSecret: string;
  };
  algolia: {
    appId: string;
    searchApiKey: string;
    indexPrefix: string;
  };
  envUrl: string;
  googleTagManager: {
    id: string;
  };
  reCaptcha: {
    siteKey: string;
  };
  mixpanel: {
    token: string;
  };
  salesforce: {
    susbcriptionUrl: string;
  };
  lockoutTimeout: {
    timeout: number;
  };
  microsoftSso: {
    tenantId: string;
  };
  stripe: {
    secret: string;
    client_id: string;
    apiversion: string;
    application_fee: string;
  };
  plenigo: {
    subscriptionUrl: string;
  };
  webpush: {
    private_key: string;
    public_key: string;
  };
  stripePricingTable: {
    stripeKey: string;
    stripeTable: string;
  };
}
